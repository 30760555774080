<section>
  <form [formGroup]="formGroup" *ngIf="evaluation.owner.id === me || isSuperAdminUser">
    <div
      class="display-flex justify-content-between align-items-center spacing-fix"
      [ngClass]="{
        'justify-content-end': teamOptions.length >= 0,
        'justify-content-between': teamOptions.length > 0
      }"
    >
      <app-autocomplete
        *ngIf="teamOptions.length > 0"
        [options]="teamOptions"
        formControlName="selectTeam"
        label="Select a Team"
        (selected)="teamSelected($event)"
      ></app-autocomplete>

      <button mat-raised-button aria-label="Search for Team Member" color="primary" class="mb-2" (click)="onOpenTeamSearch()" tabindex="0">
        Search for Team Member
      </button>
    </div>

    <hr class="input__divider mt-0" />
  </form>
</section>
<div class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
      <td mat-cell *matCellDef="let teamMember">
        <button mat-icon-button aria-label="Delete Icon" (click)="onRemoveMember(teamMember)" *ngIf="canDelete(teamMember)" tabindex="0">
          <mat-icon class="text-danger">close</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let teamMember">
        {{ teamMember.name }}
      </td>
    </ng-container>

    <!-- Job Title Column -->
    <ng-container matColumnDef="jobTitle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Title</th>
      <td mat-cell *matCellDef="let teamMember">
        {{ teamMember.jobTitle }}
      </td>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let teamMember">
        {{ teamMember.email }}
      </td>
    </ng-container>

    <!-- Phone Column -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
      <td mat-cell *matCellDef="let teamMember">
        {{ teamMember.phone | phone }}
      </td>
    </ng-container>

    <!-- AEA Column -->
    <ng-container matColumnDef="aea">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>AEA</th>
      <td mat-cell *matCellDef="let teamMember">
        {{ getFirstLocationName(teamMember.aeas) }}
      </td>
    </ng-container>

    <!-- District Column -->
    <ng-container matColumnDef="district">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>District</th>
      <td mat-cell *matCellDef="let teamMember">
        {{ getFirstLocationName(teamMember.districts) }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
<p *ngIf="!dataSource.data">No data yet...</p>

<div class="display-flex justify-content-end align-items-center mt-2" *ngIf="evaluation.owner.id === me">
  <button
    mat-raised-button
    aria-label="Send Team Member Questionnaire"
    color="primary"
    class="mr-3"
    (click)="onOpenSurveyInvite()"
    *ngIf="evaluation.intakeType !== 'PartC'"
    tabindex="0"
  >
    Send Team Member Questionnaire
  </button>
  <button
    mat-raised-button
    aria-label="Open Scheduler"
    color="primary"
    (click)="onScheduleMeeting(evaluation.caseId)"
    *ngIf="authService.isAllowed(permissions.ScheduleMeeting) || authService.isSuperAdmin"
    tabindex="0"
  >
    Open Scheduler
  </button>
</div>

<div *ngIf="evaluation?.id">
  <app-non-achieve-participants
    [id]="evaluation.id"
    [participantType]="nonAchieveParticipantType.Evaluation"
  ></app-non-achieve-participants>
</div>
