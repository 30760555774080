import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { CriterionProgressMonitor } from 'src/app/monitoring-progress/models/outcome-progress-monitor';
import { ProgressMonitorItem } from 'src/app/monitoring-progress/models/progress-monitor-item';
import { ProgressMonitorCService } from 'src/app/monitoring-progress/services/progress-monitor-c.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { CaseSummary } from 'src/app/shared/models/case';
import { ConfirmationDialogComponent } from 'src/app/shared/services/notification.service';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { decimalValidator } from 'src/app/shared/validators';
import { DateFormatPipe, DateToUtcPipe } from '../../../../../../shared/pipes/date-transform.pipe';

@Component({
  selector: 'app-progress-data-table',
  templateUrl: './progress-data-table.component.html',
  styleUrls: ['./progress-data-table.component.scss'],
})
export class ProgressDataTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @Input() criterion: CriterionProgressMonitor;
  @Input() caseSummary: CaseSummary;
  @Input() outcomeEnded = false;
  isEditing: boolean;
  shortDateFormat = shortDateFormat;
  @Input() minDate: Date;
  today = new Date();

  formGroup = this.fb.group({
    id: '',
    date: ['', Validators.required],
    value: [null, [Validators.required, decimalValidator, Validators.min(0)]],
    note: '',
    completed: false,
  });

  numericDisplayedColumns = ['actions', 'date', 'completed', 'value', 'note'];
  nonNumericDisplayedColumns = ['actions', 'date', 'completed', 'note'];

  isCriterionCompleted(criterion: CriterionProgressMonitor): boolean {
    return criterion.progressData.some((x) => x.completed);
  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private progressMonitorService: ProgressMonitorCService
  ) {}

  ngOnInit(): void {
    if (this.criterion.measurementType === 'nonnumeric') {
      this.formGroup.get('value').clearValidators();
      this.formGroup.get('value').updateValueAndValidity();
    }
  }

  ngAfterViewInit() {
    this.criterion.data.sort = this.sort;
  }

  onEdit(progressMonitorItem: ProgressMonitorItem) {
    this.isEditing = true;
    progressMonitorItem.isEditing = true;
    this.formGroup.patchValue(progressMonitorItem);
    this.setupNonNumericFormGroup();
    if (this.criterion.measurementType === 'nonnumeric') {
      this.watchNonNumericFormGroup();
    }
  }

  onDelete(progressMonitorItem: ProgressMonitorItem, criterion: CriterionProgressMonitor) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '728px',
      data: {
        title: 'Remove',
        message: 'Are you sure you want to remove this progress item?',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.progressMonitorService
          .delete(this.caseSummary.learnerId, progressMonitorItem.id)
          .subscribe(() => this.handleProgressMonitorItemDelete(progressMonitorItem, criterion));
      }
    });
  }

  onUpdate(progressMonitorItem: ProgressMonitorItem, criterion: CriterionProgressMonitor) {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    const dto = this.formGroup.value as ProgressMonitorItem;
    dto.date = new DateToUtcPipe().transform(new Date(dto.date));
    dto.outcomeCriteriaId = criterion.id;
    this.progressMonitorService.update(this.caseSummary.learnerId, dto).subscribe((res) => {
      progressMonitorItem.date = res.date;
      this.handleProgressMonitorItemUpdate(progressMonitorItem, criterion);
    });
  }

  onViewMore(progressMonitorItem: ProgressMonitorItem) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Date',
        value: new DateFormatPipe().transform(progressMonitorItem.date),
      },
      {
        name: 'Value',
        value: progressMonitorItem.value,
      },
      {
        name: 'Note',
        value: progressMonitorItem.note,
      },
      {
        name: 'Completed',
        value: progressMonitorItem.completed ? 'Yes' : 'No',
      },
    ];

    openViewMore(this.dialog, modalData);
  }

  onCancelEdit(progressMonitorItem: ProgressMonitorItem) {
    progressMonitorItem.isEditing = false;
    this.isEditing = false;
    this.formGroup.reset();
  }

  private handleProgressMonitorItemUpdate(progressMonitorItem: ProgressMonitorItem, criterion: CriterionProgressMonitor) {
    progressMonitorItem.value = this.formGroup.get('value').value;
    progressMonitorItem.completed = this.formGroup.get('completed').value;
    progressMonitorItem.note = this.formGroup.get('note').value;
    const index = criterion.progressData.findIndex((x) => x.id === progressMonitorItem.id);
    if (index > -1) {
      criterion.progressData[index] = progressMonitorItem;
      criterion.data.data = criterion.progressData;
      this.progressMonitorService.outcomeCriterionChanged.next(criterion.outcomeId);
    }
    this.onCancelEdit(progressMonitorItem);
  }

  private handleProgressMonitorItemDelete(progressMonitorItem: ProgressMonitorItem, criterion: CriterionProgressMonitor) {
    const index = criterion.progressData.indexOf(progressMonitorItem);
    if (index > -1) {
      criterion.progressData.splice(index, 1);
      criterion.data.data = criterion.progressData;
      this.progressMonitorService.outcomeCriterionChanged.next(criterion.outcomeId);
    }
  }

  private setupNonNumericFormGroup() {
    const completed = this.formGroup.get('completed');
    this.isCriterionCompleted(this.criterion) && !completed.value ? completed.disable() : completed.enable();
  }

  private watchNonNumericFormGroup() {
    this.formGroup.valueChanges.subscribe((formValue) => {
      if (formValue.note !== null && formValue.note !== '') {
        this.clearValidators(['completed']);
      } else if (formValue.completed) {
        this.clearValidators(['note']);
      } else {
        this.formGroup.get('completed').setValidators(Validators.requiredTrue);
        this.formGroup.get('completed').updateValueAndValidity({ emitEvent: false });
        this.formGroup.get('note').setValidators(Validators.required);
        this.formGroup.get('note').updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  private clearValidators(controls: Array<string>) {
    controls.forEach((control) => {
      this.formGroup.get(control).clearValidators();
      this.formGroup.get(control).updateValueAndValidity({ emitEvent: false });
    });
  }
}
