import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import ConfigJson from '../../../../config.json';
import { NotificationService } from '../notification.service';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private baseUrl = 'api/appconfig';

  private retryCount = 0;
  private isChecking: boolean;
  private oneMinute = 1000 * 60;

  constructor(private http: HttpClient, private notificationService: NotificationService, private spinnerService: SpinnerService) {}

  init() {
    // Gets fired on load once.
    this.spinnerService.registerIgnoredRequestUrlMatcher(/api\/appconfig/);
    this.checkServerVars();
  }

  private checkServerVars(progress: Subscription = undefined) {
    if (this.isChecking) return;

    this.isChecking = true;
    this.getServerVars().subscribe(
      (result) => {
        //api call succeeded, reset the retry count
        this.retryCount = 0;
        if (result.version !== ConfigJson.version) {
          if (progress) {
            //version has changed already, no need to check now.
            progress.unsubscribe();
          }
          console.log(`Server Version:${result.version}, Client Version:${ConfigJson.version}`);
          this.notificationService.showVersionDialog().subscribe(() => {
            window.location.reload();
          });
        } else {
          //in case this is the initial api-call, check and set interval
          this.setInterval(progress);
        }
        this.isChecking = false;
      },
      (err) => {
        //api call failed, time to retry
        if (this.retryCount < 3) {
          this.retryCount++;
          this.isChecking = false;
          //in case the initial api-call failed, check and set interval
          this.setInterval(progress);
          return;
        }

        if (progress) {
          progress.unsubscribe();
        }
        this.isChecking = false;
        // retry attempts failed. show error modals

        this.notificationService.showVersionDialog(true).subscribe(() => {
          window.location.reload();
        });
      }
    );
  }

  private setInterval(existing: Subscription) {
    if (existing) return;

    //this is the first check, so start checking periodically
    const progress = interval(this.oneMinute).subscribe((i) => {
      this.checkServerVars(progress);
    });
  }

  getServerVars() {
    return this.http.get<any>(this.baseUrl + '/getServerVars');
  }
}
