import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { EvaluationProxyService } from 'src/app/evaluation/services/evaluation-proxy.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { EvaluationIncompleteDataReportAction } from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PdfOutputs, ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { openPdfWindow } from 'src/app/shared/windowHelpers';
import { AuthService } from '../../../../auth/auth.service';
import { IntakeType } from '../../../../shared/models/case';
import { KeyValuePair } from '../../../../shared/models/key-value-pair';
import { Team } from '../../../../shared/models/team';
import { AchieveConfigService } from '../../../../shared/services/achieve-config-service/achieve-config.service';
import { ChangeTrackerService } from '../../../../shared/services/change-tracker.service';
import { CanComponentDeactivate, DeactivationStatus } from '../../../../shared/services/deactivation.service';
import { MemoryStorageService } from '../../../../shared/services/memory-storage/memory-storage.service';
import { Evaluation } from '../../../models/evaluation';
import { EvaluationUser } from '../../../models/evaluation-user';
import { EvaluationService } from '../../../services/evaluation.service';
import { SurveyResponsesComponent } from '../../../survey-responses/survey-responses.component';

@Component({
  selector: 'app-evaluation-overview',
  templateUrl: './evaluation-overview.component.html',
  styleUrls: ['./evaluation-overview.component.scss'],
  providers: [ChangeTrackerService],
})
export class EvaluationOverviewComponent implements OnInit, AfterViewInit, OnDestroy, CanComponentDeactivate {
  private subscriptions = new Subscription();

  @ViewChild(SurveyResponsesComponent, { static: false })
  private surveyResponses: SurveyResponsesComponent;

  @ViewChild('routines') routines: ElementRef;

  pdfOutputs = PdfOutputs;
  isPrinting = false;
  evaluation: Evaluation;
  teams: Team[];
  teamOptions: KeyValuePair[];
  members: EvaluationUser[];
  shouldShowRoutines = false;
  me: string;
  isExpanded = false;
  loading = { eer: false, finalization: false };
  incompleteItems = [];

  get shouldShowSurveyResults(): boolean {
    return this.evaluation?.familySurveyId && this.evaluation?.owner?.id === this.me;
  }

  get outputEvaluationAndAssessmentEnabled(): boolean {
    return this.achieveConfigService.settings.featureFlags.outputEvaluationAndAssessment;
  }

  constructor(
    private route: ActivatedRoute,
    private evaluationService: EvaluationService,
    private evaluationProxyService: EvaluationProxyService,
    private dialog: MatDialog,
    private reportingService: ReportingService,
    private readonly authService: AuthService,
    private readonly storage: MemoryStorageService,
    private readonly changeTracker: ChangeTrackerService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private achieveConfigService: AchieveConfigService
  ) {}

  ngOnInit(): void {
    this.me = this.authService.user.id;
    this.getEvaluation();
    this.onGetIncompleteDataReport();
  }

  ngAfterViewInit() {
    const section = this.route.snapshot.queryParamMap.get('section');
    setTimeout(() => {
      if (section) {
        this.isExpanded = true;
        this[section]?.nativeElement?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  canDeactivate(): Observable<DeactivationStatus> {
    return this.changeTracker.hasChanged.pipe(map((x) => (x ? DeactivationStatus.NeedsConfirmation : DeactivationStatus.Accepted)));
  }

  onCreateFinalEer() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        question: 'Are you sure?',
        subQuestion: 'Clicking Yes will finalize and lock this evaluation, preventing further changes and create Final EER.',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.evaluationService.finalizeEer(this.evaluation.id).subscribe((evaluation) => {
          this.evaluation = evaluation;
          this.evaluationProxyService.evaluationChange.next(evaluation);
          this.onViewEer();
        });
      }
    });
  }

  onViewEer() {
    if (this.evaluation.isFinalized) {
      openPdfWindow(this.evaluation.learner.id, this.evaluation.eerDocumentId);
    } else {
      this.loading.eer = true;
      this.evaluationService.draftEer(this.evaluation.id).subscribe({
        next: (evaluation: Evaluation) => {
          this.evaluation = evaluation;
          openPdfWindow(evaluation.learner.id, evaluation.eerDocumentId);
          this.loading.eer = false;
        },
        error: (err) =>
          this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
            this.notificationService.alert(err.error, "Couldn't open output")
          ),
      });
    }
  }

  onViewEvaluationReport(): void {
    this.evaluation.evaluationSummaryDocumentId ? this.viewEvalReport() : this.createEvalReport();
  }

  private viewEvalReport(): void {
    openPdfWindow(this.evaluation.learner.id, this.evaluation.evaluationSummaryDocumentId);
  }

  private createEvalReport(): void {
    this.subscriptions.add(
      this.reportingService.createPartCEvaluationSummary(this.evaluation.caseId).subscribe({
        next: (documentId: string) => this.handleOutputCreation(documentId),
        error: (err) =>
          this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
            this.notificationService.alert(err.error, "Couldn't open output")
          ),
      })
    );
  }

  private handleOutputCreation(documentId: string) {
    this.evaluation.evaluationSummaryDocumentId = documentId;
    this.viewEvalReport();
  }

  getEvaluation() {
    this.evaluation = this.storage.getKey('currentEvaluation', true);
    this.shouldShowRoutines = this.evaluation.learner.isPK || this.evaluation.intakeType === IntakeType.PartC;
  }

  onFamilySurveyChanged() {
    this.surveyResponses?.reloadData();
  }

  onNavigate(event) {
    if (event?.action === EvaluationIncompleteDataReportAction.Documentation) {
      this.router.navigate(['learner-management', this.evaluation?.learner?.id, event?.action]);
      return;
    }
    this.router.navigate(['cases', this.evaluation?.caseId, 'evaluation', this.evaluation?.id, event?.action], {
      queryParams: { section: event.section, markAsTouched: true },
    });
  }

  onGetIncompleteDataReport(refreshEvaluation = false) {
    this.evaluationService.getIncompleteDataReportPartC(this.evaluation.id).subscribe((res) => {
      this.incompleteItems = res;
      if (refreshEvaluation) {
        this.getEvaluation();
      }
    });
  }

  onFinalizeEvaluationAndAssessment() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        question: 'Are you sure?',
        subQuestion:
          'Clicking Yes will <strong>complete</strong> this evaluation/assessment and <strong>finalize</strong> the Early ACCESS Evaluation and Assessment Report.',
        hasHtml: true,
      },
    });
    dialogRef.afterClosed().subscribe((dialogRes) => {
      if (dialogRes && !!this.evaluation?.id) {
        this.loading.finalization = true;
        this.evaluationService.finalizeEvaluationPartC(this.evaluation.id).subscribe((res) => {
          if (res.succeeded) {
            this.evaluation.isFinalized = true;
            if (this.outputEvaluationAndAssessmentEnabled) {
              openPdfWindow(this.evaluation.learner.id, res.value);
            }
            this.router.navigate(['learner-management', this.evaluation.learner.id, 'evaluation']);
          } else {
            console.log(res.errors);
          }
        });
      }
    });
  }

  onViewEvaluationAndAssessment() {
    /* this.dialog.open(EvaluationAssessmentReportModalComponent, {
      data: { caseId: this.evaluation.caseId, evaluationId: this.evaluation.id, learnerId: this.evaluation.learner.id },
    }); */
    this.isPrinting = true;
    this.reportingService
      .createEvaluationAssessmentReport(this.evaluation.id)
      .pipe(finalize(() => (this.isPrinting = false)))
      .subscribe({
        next: (documentId: string) => this.handleEAOutputCreation(documentId),
        error: (err) => this.reportingService.handleOutputError(err),
      });
  }

  private handleEAOutputCreation(documentId: string) {
    openPdfWindow(this.evaluation.learner.id, documentId);
  }
}
