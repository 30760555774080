import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NonAchieveParticipant, NonAchieveParticipantType } from '../../non-achieve-participant';
import { NonAchieveParticipantsService } from '../../services/non-achieve-participants.service';

@Component({
  selector: 'app-non-achieve-participants',
  templateUrl: './non-achieve-participants.component.html',
  styleUrls: ['./non-achieve-participants.component.scss'],
})
export class NonAchieveParticipantsComponent implements OnInit {
  @ViewChild('formDirective') private formDirective: NgForm;
  @Input() id: string;
  @Input() participantType: NonAchieveParticipantType;

  editingParticipant = false;
  dataSource = new MatTableDataSource<NonAchieveParticipant>();
  displayedColumns = ['actions', 'name'];
  formGroup = this.fb.group({
    id: [''],
    name: ['', Validators.required],
  });

  constructor(private readonly fb: FormBuilder, private readonly nonAchieveParticipantsService: NonAchieveParticipantsService) {}

  ngOnInit(): void {
    this.load();
  }

  saveNonAchieveParticipant() {
    if (this.editingParticipant) {
      this.update();
    } else {
      this.add();
    }
  }

  onEditNonAchieveParticipant(participant) {
    this.editingParticipant = true;
    this.formGroup.patchValue(participant);
  }

  removeNonAchieveParticipant(participantId: string) {
    this.nonAchieveParticipantsService.remove(participantId).subscribe((res) => {
      if (res) {
        this.dataSource.data = this.dataSource.data.filter((x) => x.id !== participantId);
      }
    });
  }

  resetParticipantForm() {
    this.formDirective.resetForm();
    this.formGroup.reset();
    this.editingParticipant = false;
  }

  private load() {
    this.nonAchieveParticipantsService.get(this.id, this.participantType).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res);
    });
  }

  private add() {
    const participant = this.formGroup.value as NonAchieveParticipant;
    this.nonAchieveParticipantsService.create(this.id, this.participantType, participant).subscribe((res) => {
      this.dataSource.data = [...this.dataSource.data, res];
      this.resetParticipantForm();
    });
  }

  private update() {
    const participant = this.formGroup.value as NonAchieveParticipant;
    this.nonAchieveParticipantsService.update(participant).subscribe((res) => {
      if (res) {
        const index = this.dataSource.data.findIndex((x) => x.id == participant.id);
        if (index > -1) {
          this.dataSource.data[index] = this.formGroup.value as NonAchieveParticipant;
          this.dataSource.data = [...this.dataSource.data];
        }
        this.resetParticipantForm();
      }
    });
  }
}
