import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { Idle } from '@ng-idle/core';
import { Subscription } from 'rxjs';
import dayjs from 'dayjs';

@Component({
  selector: 'app-timeout-dialog',
  templateUrl: 'timeout-modal.component.html',
  styleUrls: ['timeout-modal.component.scss'],
})
export class TimeoutModalComponent implements OnInit, OnDestroy {
  private subscriptions = new Array<Subscription>();
  public countDown: number;
  private dayHoldThis = dayjs('2021-01-01 01:00:00');

  get countDownDisplay() {
    return this.dayHoldThis.add(this.countDown, 's').format('mm:ss');
  }

  constructor(
    private readonly dialogRef: MatDialogRef<TimeoutModalComponent>,
    private readonly idle: Idle,
    private readonly ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.countDown = this.idle.getTimeout();
    this.subscriptions.push(
      this.idle.onTimeoutWarning.subscribe((countdown) => (this.countDown = countdown)),
      this.idle.onInterrupt.subscribe(() => {
        this.ngZone.run(() => this.onNoClick());
      }),
      this.idle.onTimeout.subscribe(() => {
        this.ngZone.run(() => this.onNoClick());
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
