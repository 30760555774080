<mat-card>
  <div class="display-flex justify-content-between align-items-center flex-wrap">
    <p class="my-0">
      <strong>
        <ng-container
          *ngIf="
            evaluation.isEligibilityDecisionFinalized &&
            (evaluation.eligibilitySubmittedOnEvaluation === true || evaluation.eligibilityDecision === true)
          "
        >
          Child Determined Eligible
        </ng-container>
        <ng-container
          *ngIf="
            evaluation.isEligibilityDecisionFinalized &&
            (evaluation.eligibilitySubmittedOnEvaluation === false || evaluation.eligibilityDecision === false)
          "
        >
          Child Determined Not Eligible
        </ng-container>
        <ng-container *ngIf="!evaluation.isEligibilityDecisionFinalized"> Eligibility Not Yet Determined </ng-container>
      </strong>
    </p>

    <a (click)="onHealthInfoNavigate()" class="mr-1" target="_blank" tabindex="0" role="link" aria-labelledby="Open in new tab">
      <span class="display-flex justify-content-end align-items-center">
        Review Intake Health Information
        <mat-icon aria-labelledby="Open in new tab">open_in_new</mat-icon>
      </span>
    </a>
  </div>
</mat-card>
