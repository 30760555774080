import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FamilyArea } from '../../../shared/models/family-area';

@Injectable({
  providedIn: 'root',
})
export class FamilyAreaService {
  private baseUrl = 'api/familyarea';

  constructor(private http: HttpClient) {}

  get(): Observable<FamilyArea[]> {
    return this.http.get<FamilyArea[]>(this.baseUrl);
  }
}
