import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { EcoArea } from '../../../evaluation/models/evaluation';
import { FamilyArea } from '../../../shared/models/family-area';
import { EcoAreaService } from '../../../shared/services/eco-areas/eco-area.service';
import { FamilyAreaService } from '../../../shared/services/family-areas/family-areas.service';
import { IfspModification } from '../../models/ifsp-modification';
import { OutcomeDto } from '../../models/outcome-models';

@Component({
  selector: 'app-ifsp-outcome-view-more',
  templateUrl: './ifsp-outcome-view-more.component.html',
  styleUrls: ['./ifsp-outcome-view-more.component.scss'],
  providers: [DatePipe],
})
export class IfspOutcomeViewMoreComponent implements OnInit {
  public outcome: OutcomeDto;
  public modifications: IfspModification[];
  ecoAreas: EcoArea[] = [];
  familyAreas: FamilyArea[] = [];
  shortDateFormat = shortDateFormat;
  sources = [];
  displayedColumns = ['rating', 'description'];

  get modificationId() {
    return this.modification?.id;
  }

  get modification() {
    return this.modifications?.find((x) => !x.finalizeDate);
  }

  get modificationIsFinalized() {
    return this.modification?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.modifications && this.modifications.length > 0) {
      const latest = this.modifications.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  getCriterionMilestones(milestones) {
    return milestones.filter((x) => !x.secondary).sort((a, b) => (a.completionDate < b.completionDate ? -1 : 1));
  }

  constructor(
    private ecoAreaService: EcoAreaService,
    private familyAreaService: FamilyAreaService,
    private dialogRef: MatDialogRef<IfspOutcomeViewMoreComponent>,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.outcome = data.outcome;
    this.outcome.criterias.forEach((x) => {
      this.sources.push(new MatTableDataSource<any>(x.quantifiableData.scaleRatings));
    });
    this.modifications = data.modifications;
  }

  ngOnInit(): void {
    forkJoin([this.ecoAreaService.get(), this.familyAreaService.get()]).subscribe(([ecoAreas, familyAreas]) => {
      this.ecoAreas = ecoAreas;
      this.familyAreas = familyAreas;
    });
  }

  dismiss() {
    this.dialogRef.close();
  }

  getCriteriaPriorVersion(priorVersionId) {
    return this.outcome?.priorVersion?.criterias?.find((x) => x.id === priorVersionId);
  }

  getCriteriaText(element) {
    return `Measurement Type: ${element?.measurementType ? (element?.measurementType === 'numeric' ? 'Numeric' : 'Yes/No') : 'Unknown'}`;
  }

  getQuantifiableBaselineData(element) {
    if (!element) {
      return '';
    }
    return `Baseline: ${element.quantifiableData.baseline}`;
  }

  getQuantifiableBaselineDataPriorVersion(priorVersionId) {
    const criteriaPriorVersion = this.getCriteriaPriorVersion(priorVersionId);
    return this.getQuantifiableBaselineData(criteriaPriorVersion);
  }

  getQuantifiablePerformanceData(element) {
    if (!element) {
      return '';
    }
    return `Goal: ${element.quantifiableData.performanceMin}`;
  }

  getQuantifiablePerformanceDataPriorVersion(priorVersionId) {
    const criteriaPriorVersion = this.getCriteriaPriorVersion(priorVersionId);
    return this.getQuantifiablePerformanceData(criteriaPriorVersion);
  }

  getFamilyAreasValue(value) {
    const familyArea = this.familyAreas.find((x) => x.id === value);
    return familyArea ? familyArea.label : null;
  }

  getOutcomeAreaText(element) {
    let outcomeAreas = '';
    if (element.ecoAreas) {
      element.ecoAreas.forEach((ecoArea, index) => {
        outcomeAreas += ecoArea.ecoArea;
        if (index + 1 !== element.ecoAreas.length) {
          outcomeAreas += ', ';
        }
      });
    }
    return outcomeAreas;
  }

  getOutcomeFamilyAreaText(element) {
    let outcomeAreas = '';
    if (element.familyAreas) {
      element.familyAreas.forEach((area, index) => {
        outcomeAreas += area?.familyArea;
        if (index + 1 !== element.familyAreas.length) {
          outcomeAreas += ', ';
        }
      });
    }
    return outcomeAreas;
  }

  getStrategiesText(element) {
    if (!element) {
      return '';
    }
    return element.strategies?.map((x) => x.name).join(', ');
  }

  getOutcomeEndDateText(outcome) {
    return this.datePipe.transform(outcome.outcomeEndDate, shortDateFormat);
  }

  getProgressPriorVersion(outcomePriorVersion, priorVersionId) {
    return outcomePriorVersion?.progresses.find((x) => x.id === priorVersionId);
  }

  getMonitoringProgressText(element) {
    let monitoringProgress = '';
    monitoringProgress += element?.measuringMethod;
    if (element?.measuringMethod === 'Other') {
      monitoringProgress += ': ' + element?.otherMethodDescribe;
    }
    monitoringProgress += ' - ';
    monitoringProgress += element?.frequencyNumber;
    monitoringProgress += ' time(s) per ';
    monitoringProgress += element?.frequencyPeriod;

    return monitoringProgress;
  }
}
