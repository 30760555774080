<form class="register-form" [formGroup]="registerForm" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
  <h1 class="mat-display-1">Register</h1>
  <mat-form-field class="register-field">
    <input matInput type="text" aria-labelledby="Email" placeholder="Email" formControlName="email" />
  </mat-form-field>
  <mat-form-field class="register-field shift-right">
    <input matInput type="text" aria-labelledby="First Name" placeholder="First Name" formControlName="firstName" />
  </mat-form-field>
  <mat-form-field class="register-field shift-right">
    <input matInput type="text" aria-labelledby="Last Name" placeholder="Last Name" formControlName="lastName" />
  </mat-form-field>
  <mat-form-field class="register-field shift-right">
    <input type="password" matInput aria-labelledby="Password" placeholder="Password" formControlName="password" />
  </mat-form-field>
  <mat-form-field class="register-field shift-right">
    <input
      type="password"
      matInput
      aria-labelledby="Confirm your password"
      placeholder="Confirm Password"
      formControlName="confirmedPassword"
    />
  </mat-form-field>
  <mat-error *ngIf="registerForm.touched && registerForm.invalid">{{ getErrorMessage() }}</mat-error>
  <mat-error *ngIf="registerError">{{ registerError }}</mat-error>

  <div class="display-flex justify-content-end align-items-center">
    <a class="forget-password mr-3" routerLink="/auth/login" mat-button tabindex="0" role="link" aria-labelledby="Log In"> Log In </a>
    <button mat-flat-button aria-label="Complete" #submitButton class="buttons" type="submit" color="primary" tabindex="0">Complete</button>
  </div>
</form>
