<mat-card class="my-0">
  <mat-card class="card-spacing-bottom" *ngFor="let ecoAreaControl of ecoForm.controls">
    <h3 class="mt-0 mb-2">{{ ecoAreaControl.value.label }}</h3>
    <button
      mat-raised-button
      aria-labelledby="Launch ECO Decision Tree"
      color="primary"
      class="mr-2"
      (click)="OpenEcoMatrix(ecoAreaControl)"
      tabindex="0"
    >
      Launch ECO Decision Tree
    </button>
    <ng-container *ngIf="ecoAreaControl.get('scoreText').value">
      <hr class="input__divider my-2" />
      <h4 class="mt-0 mb-1">Comparison to Age Expectation</h4>
      <p class="my-0">
        {{ ecoAreaControl.get('scoreText').value }}
      </p>
      <br />
      <label
        class="vertical-label text-md display-block"
        [ngClass]="{
          asterisk_input: ecoAreaControl.get('ecoRatingProgress').hasError('required')
        }"
      >
        Has {{ learnerFirstName }} made progress since beginning Special Education Services?
      </label>
      <app-radio-group [formControl]="ecoAreaControl.get('ecoRatingProgress') | formControl" [options]="yesNoOptions" [hideAsterisk]="true">
      </app-radio-group>
    </ng-container>
  </mat-card>
  <div class="display-flex justify-content-end mt-2">
    <button mat-raised-button color="primary" aria-label="Finalize" [disabled]="ecoForm.invalid" (click)="finalize()" tabindex="0">
      Finalize
    </button>
  </div>
</mat-card>
