import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoalProgressMonitor } from 'src/app/monitoring-progress/models/goal-progress-monitor';
import { OutcomeProgressMonitor } from 'src/app/monitoring-progress/models/outcome-progress-monitor';

@Component({
  selector: 'app-multi-graph-view-modal',
  templateUrl: './multi-graph-view-modal.component.html',
  styleUrls: ['./multi-graph-view-modal.component.scss'],
})
export class MultiGraphViewModalComponent implements OnInit {
  isIep: boolean;
  iepGoal: GoalProgressMonitor;

  isIfsp: boolean;
  ifspOutcome: OutcomeProgressMonitor;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isIep: boolean;
      iepGoal: GoalProgressMonitor;
      isIfsp: boolean;
      ifspOutcome: OutcomeProgressMonitor;
    }
  ) {}

  ngOnInit(): void {
    if (this.data.isIep) {
      this.isIep = this.data.isIep;
      this.iepGoal = this.data.iepGoal;
    }

    if (this.data.isIfsp) {
      this.isIfsp = true;
      this.ifspOutcome = this.data.ifspOutcome;
    }
  }
}
