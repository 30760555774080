import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OutcomeProgressMonitor, ProgressMonitorPlanStatus } from 'src/app/monitoring-progress/models/outcome-progress-monitor';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { openViewMore } from 'src/app/shared/tableHelpers';

@Component({
  selector: 'app-plan-status',
  templateUrl: './plan-status.component.html',
  styleUrls: ['./plan-status.component.scss'],
})
export class PlanStatusComponent implements OnInit, OnChanges {
  @Input() planStatuses: ProgressMonitorPlanStatus[];
  @Input() outcomes: Array<OutcomeProgressMonitor> = [];
  @ViewChild(MatSort) sort: MatSort;
  shortDateFormat = shortDateFormat;
  dataSource: MatTableDataSource<ProgressMonitorPlanStatus> = new MatTableDataSource();

  constructor(private datePipe: DatePipe, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.dataSource.data = this.planStatuses;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource.data = this.planStatuses;
  }

  onViewMore(planStatus: ProgressMonitorPlanStatus) {
    const modalData: Array<ViewMoreModalData> = [
      {
        name: 'Date',
        value: this.datePipe.transform(planStatus.date),
      },
      {
        name: 'How is the plan working?',
        value: planStatus.status,
      },
    ];
    openViewMore(this.dialog, modalData);
  }

  getOutcomeNames(outcomes: Array<string>) {
    const outcomeNames = this.outcomes.filter((x) => outcomes?.includes(x.id)).map((x) => x.name);
    if (outcomeNames.length === 0) {
      return '-';
    }
    return outcomeNames.join(', ');
  }
}
