<main class="learner-management">
  <div class="card__together-container" *ngIf="canCreate">
    <mat-card class="card__together card__together--top py-2">
      <app-help-directional-text>
        <p>Click the Begin Evaluation button to begin a new one, or the Edit Evaluation button to return to the current evaluation.</p>
      </app-help-directional-text>
    </mat-card>
    <mat-card class="card__together card__together--bottom">
      <mat-card-content>
        <div
          *ngFor="let case of activeCases; let i = index"
          class="display-flex align-items-center justify-content-between"
          [class.mt-2]="i > 0"
        >
          <div>
            <ng-container *ngIf="!getActiveEvaluation(case)">
              <h3 class="mb-0">
                No Active Evaluation:
                <span *ngIf="case?.intakeType === intakeType.PartC"> Early ACCESS </span>
                <span *ngIf="case?.intakeType === intakeType.PartB"> Special Education </span>
              </h3>
            </ng-container>
            <ng-container *ngIf="getActiveEvaluation(case)?.type === evaluationType.Evaluation">
              <h3 class="mb-0">
                Active Evaluation:
                <small>
                  <ng-container *ngIf="getActiveEvaluation(case).intakeType === intakeType.PartB; else partCTitle">
                    Special Education
                  </ng-container>
                  <ng-template #partCTitle> Early ACCESS </ng-template>
                </small>
              </h3>
            </ng-container>
            <ng-container *ngIf="getActiveEvaluation(case)?.type === evaluationType.Reevaluation">
              <h3 class="mb-0">
                Active Reevaluation:
                <small>
                  <ng-container> Reevaluation </ng-container>
                </small>
              </h3>
            </ng-container>
          </div>

          <div class="display-flex justify-content-end" *ngIf="learnerHasWorkableCase && canCreate">
            <button
              *ngIf="showEvalButton(case)"
              mat-raised-button
              aria-label="Begin Evaluation"
              color="primary"
              (click)="onBeginEvaluation(case)"
              [isBusy]="case.isBusy"
              tabindex="0"
            >
              Begin Evaluation
            </button>
            <button
              *ngIf="showReevalButton(case)"
              mat-raised-button
              aria-label="Begin Reevaluation"
              color="primary"
              (click)="onBeginReevaluation(case)"
              [isBusy]="case.isBusy"
              tabindex="0"
            >
              Begin Reevaluation
            </button>
            <button
              *ngIf="showEditButton(case)"
              mat-raised-button
              attr.aria-label="{{
                getActiveEvaluation(case)?.type === evaluationType.Reevaluation ? 'Edit Reevaluation' : 'Edit Evaluation'
              }}"
              color="primary"
              (click)="onGoToEvaluation(getActiveEvaluation(case))"
              tabindex="0"
            >
              {{ getActiveEvaluation(case)?.type === evaluationType.Reevaluation ? 'Edit Reevaluation' : 'Edit Evaluation' }}
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <h3 class="card-spacing-top mb-2">Evaluation History</h3>
  <mat-card class="mb-0 pb-0">
    <app-evaluation-history-table [dataSource]="dataSource" (goToEvaluation)="onGoToEvaluation($event)"></app-evaluation-history-table>
  </mat-card>
</main>

<div class="page-bottom-leeway"></div>
