<form [formGroup]="formGroup" *ngIf="iepGoals || pses">
  <app-help-directional-text>
    <p class="card-spacing-bottom">
      Click on the accordion for each goal to document if it will be included in the ESY plan. If the goal is included, answering the
      related questions will allow you to adjust that goal for ESY.
    </p>
  </app-help-directional-text>

  <mat-accordion class="accordion accordion--short accordion--close" multi>
    <mat-expansion-panel
      *ngFor="let goal of iepGoals; let i = index"
      class="mat-elevation-z0"
      [formGroupName]="goal.id"
      [expanded]="goalSelected(goal.id)"
    >
      <mat-expansion-panel-header [ngClass]="{ completed: isGoalIncluded(goal.id) }">
        <mat-panel-title>
          <h3 class="my-0 text-normal">Goal - {{ goal.conditionIndividualWillPerform }}</h3>
        </mat-panel-title>
        <button
          *ngIf="hasOpenAmendment && !readonlyUser"
          [disabled]="getESYGoal(goal.id)?.amendingGoal"
          mat-flat-button
          aria-label="Amend"
          color="primary"
          class="mr-2"
          (click)="$event.stopPropagation(); amendGoal(goal, i)"
          tabindex="0"
        >
          Amend
        </button>
      </mat-expansion-panel-header>
      <fieldset>
        <app-radio-group
          *ngIf="allowFormEdit(true, goal); else includeGoalReadOnlyTemplate"
          [options]="yesNoOptions"
          formControlName="includeGoal"
          label="Include this goal in the ESY Plan?"
        ></app-radio-group>
      </fieldset>
      <ng-template #includeGoalReadOnlyTemplate>
        <p class="m-0">Include this goal in the ESY Plan?</p>
        <p class="text-semibold">
          {{ formGroup.get(goal.id).get('includeGoal').value | boolToYesNo }}
        </p>
      </ng-template>
      <div class="mb-2" *ngIf="showCompareOutput(true, goal)">
        <app-compare-amendment-output
          *iepAmendments="amendments"
          [priorVersionId]="getESYGoal(goal.id)?.priorVersionId"
          [isActive]="getESYGoal(goal.id)?.isActive"
          [changedVersionText]="formGroup.get(goal.id).get('includeGoal').value | boolToYesNo"
          [currentVersionText]="!!getESYGoal(goal.id)?.priorVersionId | boolToYesNo"
          [currentPrefixText]="!!getESYGoal(goal.id)?.priorVersionId ? 'Current: ' : ''"
        ></app-compare-amendment-output>
      </div>

      <ng-container *ngIf="isGoalIncluded(goal.id)">
        <fieldset>
          <app-radio-group
            *ngIf="allowFormEdit(true, goal); else significantRegressionReadOnlyTemplate"
            [options]="yesNoOptions"
            formControlName="significantRegression"
            label="In this goal area, has there been, or is there potential, for significant regression during periods of interruption that would require significant recoupment?"
          ></app-radio-group>
        </fieldset>
        <ng-template #significantRegressionReadOnlyTemplate>
          <p class="m-0">
            In this goal area, has there been, or is there potential, for significant regression during periods of interruption that would
            require significant recoupment?
          </p>
          <p class="text-semibold">
            {{ formGroup.get(goal.id).get('significantRegression').value | boolToYesNo }}
          </p>
        </ng-template>
        <div class="mb-2" *ngIf="showCompareOutput(true, goal)">
          <app-compare-amendment-output
            *iepAmendments="amendments"
            [priorVersionId]="getESYGoal(goal.id)?.priorVersionId"
            [isActive]="getESYGoal(goal.id)?.isActive"
            [changedVersionText]="formGroup.get(goal.id).get('significantRegression').value | boolToYesNo"
            [currentVersionText]="getESYGoal(goal.id)?.priorVersion?.significantRegression | boolToYesNo"
            [currentPrefixText]="!!getESYGoal(goal.id)?.priorVersionId ? 'Current: ' : ''"
          ></app-compare-amendment-output>
        </div>
        <app-textarea
          label="Explain"
          [rows]="2"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.esyGoals.significantRegressionExplanation"
          [bankableFieldChildFname]="childFirstName"
          formControlName="significantRegressionExplanation"
          *ngIf="shouldShowExplanation(goal.id, 'significantRegression') && allowFormEdit(true, goal)"
          [required]="shouldShowExplanation(goal.id, 'significantRegression')"
        ></app-textarea>
        <ng-container *ngIf="shouldShowExplanation(goal.id, 'significantRegression') && !allowFormEdit(true, goal)">
          <p class="m-0">Explain</p>
          <p class="text-semibold">
            {{ formGroup.get(goal.id).get('significantRegressionExplanation').value }}
          </p>
        </ng-container>
        <div class="mb-2" *ngIf="shouldShowExplanation(goal.id, 'significantRegression') && showCompareOutput(true, goal)">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [priorVersionId]="getESYGoal(goal.id)?.priorVersionId"
            [isActive]="getESYGoal(goal.id)?.isActive"
            [changedVersionText]="formGroup.get(goal.id).get('significantRegressionExplanation').value"
            [currentVersionText]="getESYGoal(goal.id)?.priorVersion?.significantRegressionExplanation"
            [currentPrefixText]="!!getESYGoal(goal.id)?.priorVersionId ? 'Current: ' : ''"
          ></app-compare-amendment-output>
        </div>
        <fieldset>
          <app-radio-group
            *ngIf="allowFormEdit(true, goal); else rareCircumstancesReadOnlyTemplate"
            [options]="yesNoOptions"
            formControlName="rareCircumstances"
            label="In this goal area, are there any rare and unusual circumstances?"
          ></app-radio-group>
        </fieldset>
        <ng-template #rareCircumstancesReadOnlyTemplate>
          <p class="m-0">In this goal area, are there any rare and unusual circumstances?</p>
          <p class="text-semibold">
            {{ formGroup.get(goal.id).get('rareCircumstances').value | boolToYesNo }}
          </p>
        </ng-template>
        <div class="mb-2" *ngIf="showCompareOutput(true, goal)">
          <app-compare-amendment-output
            *iepAmendments="amendments"
            [priorVersionId]="getESYGoal(goal.id)?.priorVersionId"
            [isActive]="getESYGoal(goal.id)?.isActive"
            [changedVersionText]="formGroup.get(goal.id).get('rareCircumstances').value | boolToYesNo"
            [currentVersionText]="getESYGoal(goal.id)?.priorVersion?.rareCircumstances | boolToYesNo"
            [currentPrefixText]="!!getESYGoal(goal.id)?.priorVersionId ? 'Current: ' : ''"
          ></app-compare-amendment-output>
        </div>
        <app-textarea
          label="Explain"
          [rows]="2"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.esyGoals.rareCircumstancesExplanation"
          [bankableFieldChildFname]="childFirstName"
          formControlName="rareCircumstancesExplanation"
          *ngIf="shouldShowExplanation(goal.id, 'rareCircumstances') && allowFormEdit(true, goal)"
          [required]="shouldShowExplanation(goal.id, 'rareCircumstances')"
        ></app-textarea>
        <ng-container *ngIf="shouldShowExplanation(goal.id, 'rareCircumstancesExplanation') && !allowFormEdit(true, goal)">
          <p class="m-0">Explain</p>
          <p class="text-semibold">
            {{ formGroup.get(goal.id).get('rareCircumstancesExplanation').value }}
          </p>
        </ng-container>
        <div class="mb-2" *ngIf="shouldShowExplanation(goal.id, 'rareCircumstances') && showCompareOutput(true, goal)">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [priorVersionId]="getESYGoal(goal.id)?.priorVersionId"
            [isActive]="getESYGoal(goal.id)?.isActive"
            [changedVersionText]="formGroup.get(goal.id).get('rareCircumstancesExplanation').value"
            [currentVersionText]="getESYGoal(goal.id)?.priorVersion?.rareCircumstancesExplanation"
            [currentPrefixText]="!!getESYGoal(goal.id)?.priorVersionId ? 'Current: ' : ''"
          ></app-compare-amendment-output>
        </div>
        <fieldset>
          <app-radio-group
            *ngIf="allowFormEdit(true, goal); else otherFactorsReadOnlyTemplate"
            [options]="yesNoOptions"
            formControlName="otherFactors"
            label="In this goal area, are there other factors to be considered in determining this child's need for ESY services?"
          ></app-radio-group>
        </fieldset>
        <ng-template #otherFactorsReadOnlyTemplate>
          <p class="m-0">In this goal area, are there other factors to be considered in determining this child's need for ESY services?</p>
          <p class="text-semibold">
            {{ formGroup.get(goal.id).get('otherFactors').value | boolToYesNo }}
          </p>
        </ng-template>
        <div class="mb-2" *ngIf="showCompareOutput(true, goal)">
          <app-compare-amendment-output
            *iepAmendments="amendments"
            [priorVersionId]="getESYGoal(goal.id)?.priorVersionId"
            [isActive]="getESYGoal(goal.id)?.isActive"
            [changedVersionText]="formGroup.get(goal.id).get('otherFactors').value | boolToYesNo"
            [currentVersionText]="getESYGoal(goal.id)?.priorVersion?.otherFactors | boolToYesNo"
            [currentPrefixText]="!!getESYGoal(goal.id)?.priorVersionId ? 'Current: ' : ''"
          ></app-compare-amendment-output>
        </div>
        <app-textarea
          label="Explain"
          [rows]="2"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.esyGoals.otherFactorsExplanation"
          [bankableFieldChildFname]="childFirstName"
          formControlName="otherFactorsExplanation"
          *ngIf="shouldShowExplanation(goal.id, 'otherFactors') && allowFormEdit(true, goal)"
          [required]="shouldShowExplanation(goal.id, 'otherFactors')"
        ></app-textarea>
        <ng-container *ngIf="shouldShowExplanation(goal.id, 'otherFactors') && !allowFormEdit(true, goal)">
          <p class="m-0">Explain</p>
          <p class="text-semibold">
            {{ formGroup.get(goal.id).get('otherFactorsExplanation').value }}
          </p>
        </ng-container>
        <div class="mb-2" *ngIf="shouldShowExplanation(goal.id, 'otherFactors') && showCompareOutput(true, goal)">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [priorVersionId]="getESYGoal(goal.id)?.priorVersionId"
            [isActive]="getESYGoal(goal.id)?.isActive"
            [changedVersionText]="formGroup.get(goal.id).get('otherFactorsExplanation').value"
            [currentVersionText]="getESYGoal(goal.id)?.priorVersion?.otherFactorsExplanation"
            [currentPrefixText]="!!getESYGoal(goal.id)?.priorVersionId ? 'Current: ' : ''"
          ></app-compare-amendment-output>
        </div>
      </ng-container>
    </mat-expansion-panel>

    <ng-container *ngIf="isInTransition">
      <mat-expansion-panel
        *ngFor="let pse of pses"
        class="mat-elevation-z0"
        [formGroupName]="pse.pseSummaryId + '_' + pse.expectationType"
        [expanded]="pseSelected(pse.expectationType)"
      >
        <mat-expansion-panel-header
          [ngClass]="{
            completed: isExpectationIncluded(pse.pseSummaryId + '_' + pse.expectationType)
          }"
        >
          <mat-panel-title>
            <h3 class="my-0 text-normal">Expectation - {{ pse.expectationType }}: {{ pse.expectation }}</h3>
          </mat-panel-title>
          <button
            *ngIf="hasOpenAmendment && !readonlyUser"
            [disabled]="
              getPSEGoal(pse.expectationType)?.amendingGoal ||
              formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('amendingGoal').value === true
            "
            mat-flat-button
            aria-label="Amend"
            color="primary"
            class="mr-2"
            (click)="$event.stopPropagation(); amendPSE(pse.pseSummaryId, pse.expectationType)"
            tabindex="0"
          >
            Amend
          </button>
        </mat-expansion-panel-header>
        <fieldset>
          <app-radio-group
            *ngIf="allowFormEdit(false, pse); else includeExpectationReadOnlyTemplate"
            [options]="yesNoOptions"
            formControlName="includeExpectation"
            label="Include this expectation in the ESY Plan?"
          ></app-radio-group>
        </fieldset>
        <ng-template #includeExpectationReadOnlyTemplate>
          <p class="m-0">Include this expectation in the ESY Plan?</p>
          <p class="text-semibold">
            {{ formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('includeExpectation').value | boolToYesNo }}
          </p>
        </ng-template>
        <div class="mb-2" *ngIf="showCompareOutput(false, pse)">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [priorVersionId]="getPSEGoal(pse.expectationType)?.priorVersionId"
            [isActive]="getPSEGoal(pse.expectationType)?.isActive"
            [changedVersionText]="formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('includeExpectation').value | boolToYesNo"
            [currentVersionText]="!!getPSEGoal(pse.expectationType)?.priorVersionId | boolToYesNo"
            [currentPrefixText]="!!getPSEGoal(pse.expectationType)?.priorVersionId ? 'Current: ' : ''"
          ></app-compare-amendment-output>
        </div>

        <ng-container *ngIf="isExpectationIncluded(pse.pseSummaryId + '_' + pse.expectationType)">
          <fieldset>
            <app-radio-group
              *ngIf="allowFormEdit(false, pse); else significantRegressionReadOnlyTemplate"
              [options]="yesNoOptions"
              formControlName="significantRegression"
              label="In this goal area, has there been, or is there potential, for significant regression during periods of interruption that would require significant recoupment?"
            ></app-radio-group>
          </fieldset>
          <ng-template #significantRegressionReadOnlyTemplate>
            <p class="m-0">
              In this goal area, has there been, or is there potential, for significant regression during periods of interruption that would
              require significant recoupment?
            </p>
            <p class="text-semibold">
              {{ formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('significantRegression').value | boolToYesNo }}
            </p>
          </ng-template>
          <div class="mb-2" *ngIf="showCompareOutput(false, pse)">
            <app-compare-amendment-output
              *iepAmendments="amendments"
              [priorVersionId]="getPSEGoal(pse.expectationType)?.priorVersionId"
              [isActive]="getPSEGoal(pse.expectationType)?.isActive"
              [changedVersionText]="
                formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('significantRegression').value | boolToYesNo
              "
              [currentVersionText]="getPSEGoal(pse.expectationType)?.priorVersion?.significantRegression | boolToYesNo"
              [currentPrefixText]="!!getPSEGoal(pse.expectationType)?.priorVersionId ? 'Current: ' : ''"
            ></app-compare-amendment-output>
          </div>

          <app-textarea
            label="Explain"
            [rows]="2"
            [isBankableField]="true"
            [bankableFieldId]="bankableFieldIds.esyGoals.significantRegressionExplanation"
            [bankableFieldChildFname]="childFirstName"
            formControlName="significantRegressionExplanation"
            *ngIf="
              shouldShowExplanation(pse.pseSummaryId + '_' + pse.expectationType, 'significantRegression') && allowFormEdit(false, pse)
            "
            [required]="shouldShowExplanation(pse.pseSummaryId + '_' + pse.expectationType, 'significantRegression')"
          ></app-textarea>
          <ng-container
            *ngIf="
              shouldShowExplanation(pse.pseSummaryId + '_' + pse.expectationType, 'significantRegression') && !allowFormEdit(false, pse)
            "
          >
            <p class="m-0">Explain</p>
            <p class="text-semibold">
              {{ formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('significantRegressionExplanation').value }}
            </p>
          </ng-container>
          <div
            class="mb-2"
            *ngIf="
              shouldShowExplanation(pse.pseSummaryId + '_' + pse.expectationType, 'significantRegression') && showCompareOutput(false, pse)
            "
          >
            <app-compare-amendment-output
              [iepAmendments]="amendments"
              [priorVersionId]="getPSEGoal(pse.expectationType)?.priorVersionId"
              [isActive]="getPSEGoal(pse.expectationType)?.isActive"
              [changedVersionText]="
                formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('significantRegressionExplanation').value
              "
              [currentVersionText]="getPSEGoal(pse.expectationType)?.priorVersion?.significantRegressionExplanation"
              [currentPrefixText]="!!getPSEGoal(pse.expectationType)?.priorVersionId ? 'Current: ' : ''"
            ></app-compare-amendment-output>
          </div>
          <fieldset>
            <app-radio-group
              *ngIf="allowFormEdit(false, pse); else rareCircumstancesReadOnlyTemplate"
              [options]="yesNoOptions"
              formControlName="rareCircumstances"
              label="In this goal area, are there any rare and unusual circumstances?"
            ></app-radio-group>
          </fieldset>
          <ng-template #rareCircumstancesReadOnlyTemplate>
            <p class="m-0">In this goal area, are there any rare and unusual circumstances?</p>
            <p class="text-semibold">
              {{ formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('rareCircumstances').value | boolToYesNo }}
            </p>
          </ng-template>
          <div class="mb-2" *ngIf="showCompareOutput(false, pse)">
            <app-compare-amendment-output
              *iepAmendments="amendments"
              [priorVersionId]="getPSEGoal(pse.expectationType)?.priorVersionId"
              [isActive]="getPSEGoal(pse.expectationType)?.isActive"
              [changedVersionText]="
                formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('rareCircumstances').value | boolToYesNo
              "
              [currentVersionText]="getPSEGoal(pse.expectationType)?.priorVersion?.rareCircumstances | boolToYesNo"
              [currentPrefixText]="!!getPSEGoal(pse.expectationType)?.priorVersionId ? 'Current: ' : ''"
            ></app-compare-amendment-output>
          </div>

          <app-textarea
            label="Explain"
            [rows]="2"
            [isBankableField]="true"
            [bankableFieldId]="bankableFieldIds.esyGoals.rareCircumstancesExplanation"
            [bankableFieldChildFname]="childFirstName"
            formControlName="rareCircumstancesExplanation"
            *ngIf="shouldShowExplanation(pse.pseSummaryId + '_' + pse.expectationType, 'rareCircumstances') && allowFormEdit(false, pse)"
            [required]="shouldShowExplanation(pse.pseSummaryId + '_' + pse.expectationType, 'rareCircumstances')"
          ></app-textarea>
          <ng-container
            *ngIf="shouldShowExplanation(pse.pseSummaryId + '_' + pse.expectationType, 'rareCircumstances') && !allowFormEdit(false, pse)"
          >
            <p class="m-0">Explain</p>
            <p class="text-semibold">
              {{ formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('rareCircumstancesExplanation').value }}
            </p>
          </ng-container>
          <div class="mb-2">
            <app-compare-amendment-output
              *ngIf="
                shouldShowExplanation(pse.pseSummaryId + '_' + pse.expectationType, 'significantRegression') &&
                showCompareOutput(false, pse)
              "
              [iepAmendments]="amendments"
              [priorVersionId]="getPSEGoal(pse.expectationType)?.priorVersionId"
              [isActive]="getPSEGoal(pse.expectationType)?.isActive"
              [changedVersionText]="formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('rareCircumstancesExplanation').value"
              [currentVersionText]="getPSEGoal(pse.expectationType)?.priorVersion?.rareCircumstancesExplanation"
              [currentPrefixText]="!!getPSEGoal(pse.expectationType)?.priorVersionId ? 'Current: ' : ''"
            ></app-compare-amendment-output>
          </div>
          <fieldset>
            <app-radio-group
              *ngIf="allowFormEdit(false, pse); else otherFactorsReadOnlyTemplate"
              [options]="yesNoOptions"
              formControlName="otherFactors"
              label="In this goal area, are there other factors to be considered in determining this child's need for ESY services?"
            ></app-radio-group>
          </fieldset>
          <ng-template #otherFactorsReadOnlyTemplate>
            <p class="m-0">
              In this goal area, are there other factors to be considered in determining this child's need for ESY services?
            </p>
            <p class="text-semibold">
              {{ formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('otherFactors').value | boolToYesNo }}
            </p>
          </ng-template>
          <div class="mb-2" *ngIf="showCompareOutput(false, pse)">
            <app-compare-amendment-output
              *iepAmendments="amendments"
              [priorVersionId]="getPSEGoal(pse.expectationType)?.priorVersionId"
              [isActive]="getPSEGoal(pse.expectationType)?.isActive"
              [changedVersionText]="formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('otherFactors').value | boolToYesNo"
              [currentVersionText]="getPSEGoal(pse.expectationType)?.priorVersion?.otherFactors | boolToYesNo"
              [currentPrefixText]="!!getPSEGoal(pse.expectationType)?.priorVersionId ? 'Current: ' : ''"
            ></app-compare-amendment-output>
          </div>

          <app-textarea
            label="Explain"
            [rows]="2"
            [isBankableField]="true"
            [bankableFieldId]="bankableFieldIds.esyGoals.otherFactorsExplanation"
            [bankableFieldChildFname]="childFirstName"
            formControlName="otherFactorsExplanation"
            *ngIf="shouldShowExplanation(pse.pseSummaryId + '_' + pse.expectationType, 'otherFactors') && allowFormEdit(false, pse)"
            [required]="shouldShowExplanation(pse.pseSummaryId + '_' + pse.expectationType, 'otherFactors')"
          ></app-textarea>
          <ng-container
            *ngIf="shouldShowExplanation(pse.pseSummaryId + '_' + pse.expectationType, 'otherFactors') && !allowFormEdit(false, pse)"
          >
            <p class="m-0">Explain</p>
            <p class="text-semibold">
              {{ formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('otherFactorsExplanation').value }}
            </p>
          </ng-container>
          <div class="mb-2">
            <app-compare-amendment-output
              *ngIf="shouldShowExplanation(pse.pseSummaryId + '_' + pse.expectationType, 'otherFactors') && showCompareOutput(false, pse)"
              [iepAmendments]="amendments"
              [priorVersionId]="getPSEGoal(pse.expectationType)?.priorVersionId"
              [isActive]="getPSEGoal(pse.expectationType)?.isActive"
              [changedVersionText]="formGroup.get(pse.pseSummaryId + '_' + pse.expectationType).get('otherFactorsExplanation').value"
              [currentVersionText]="getPSEGoal(pse.expectationType)?.priorVersion?.otherFactorsExplanation"
              [currentPrefixText]="!!getPSEGoal(pse.expectationType)?.priorVersionId ? 'Current: ' : ''"
            ></app-compare-amendment-output>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</form>
