import { User } from '../../shared/models/user';

export interface WeightedMatrixDto {
  id?: string;
  caseId: string;
  iepHasSDO: boolean;
  iepHasSAO: boolean;
  iepHasATO: boolean;
  iepHasNRO: boolean;
  hasRpBuilding: boolean;
  servedStatus?: ServedStatus;
  isRecommendationFinalized?: boolean;
  weightedMatrixSpecialCircumstance: WeightedMatrixSpecialCircumstanceDto;
  weightedMatrixScoreHistory: Array<WeightedMatrixScoreHistoryDto>;
}

export interface WeightedMatrixSpecialCircumstanceDto {
  id?: string;
  weightedMatrixId: string;
  iepId: string;
  assignedTeacher?: boolean;
  whatPercentage?: string;
  instructionLength?: string;
  isDeviceNew?: boolean;
  dateOfPurchase?: string;
  deviceSignificance?: string;
  deviceComment: string;
  isFinalized: boolean;
  override: boolean;
}

export interface SpecialCircumstanceOperationResponseDto {
  weightedMatrixSpecialCircumstanceId: string;
  requestOverride: boolean;
}

export interface WeightedMatrixScoreDto {
  id?: string;
  weightedMatrixId: string;
  iepId: string;
  recommendedScore?: number;
  actualScore?: number;
  weightedMatrixLevelId: string;
  comments: string;
  hasSpecialCircumstance: boolean;
}

export interface WeightedMatrixScoreHistoryDto extends WeightedMatrixScoreDto {
  iepDate: Date;
  updatedOn: Date;
  updatedBy: User;
}

export interface WeightRecommendationDto {
  iepId: string;
  reasonDescription: string;
  points: number;
  uniqueCircumstances: number;
  weightLevel: string;
}

export interface WeightedMatrixLevelDto {
  id?: string;
  level: string;
  label: string;
  minPoint?: number;
  maxPoint?: number;
  isPk?: boolean;
  isSDI: boolean;
  isSA: boolean;
}

export enum ServedStatus {
  FederalAndState = 'FederalAndState',
  StateOnly = 'StateOnly',
  FederalOnly = 'FederalOnly',
  NotApplicable = 'NotApplicable',
}

export enum DayPercentage {
  LessThan49 = 'LessThan49',
  Between50And74 = 'Between50And74',
  GreaterThan75 = 'GreaterThan75',
}

export enum InstructionLength {
  FullTimeInstruction = 'FullTimeInstruction',
  PartTimeInstruction = 'PartTimeInstruction',
}

export enum DeviceSignificance {
  Minor = 'Minor',
  Significant = 'Significant',
}
