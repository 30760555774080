<div class="backdrop">
  <div class="pic"></div>
  <div class="form" *ngIf="questionnaireInvite">
    <app-family-member-registration-form
      [registerError]="registerError"
      [questionnaireInvite]="questionnaireInvite"
      (submitted)="onSubmit($event)"
    ></app-family-member-registration-form>
  </div>
</div>
