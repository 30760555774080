import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { TransferOfRights } from '../models/transfer-of-rights';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransferOfRightsService {
  constructor(private http: HttpClient, private spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/.*\/transfer-of-rights.*/));
  }

  get(iepId: string, plaafpId: string, secondaryTransitionId: string): Observable<TransferOfRights> {
    return this.http.get<TransferOfRights>(
      `api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/transfer-of-rights`
    );
  }

  update(iepId: string, plaafpId: string, secondaryTransitionId: string, transferOfRights: TransferOfRights) {
    return this.http.put(
      `api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/transfer-of-rights/${transferOfRights.id}`,
      transferOfRights
    );
  }
}
