import { Help, HelpSection } from '../help';
import { IntakeDentalVisionHearingHelp, IntakeNutritionGrowthHelp, IntakeHavingEnoughHelp } from '../models/intake.help';

export function getIntakeDictionary(): Help[] {
  return [
    {
      title: HelpSection.Intake,
      dictionary: [
        {
          label: IntakeNutritionGrowthHelp.Peach,
          descriptor: 'Parent Nutrition/Feeding Questionnaire (for ages 0-5 years)',
        },
        {
          label: IntakeDentalVisionHearingHelp.ScreenedTested,
          descriptor: 'Includes newborn hearing screening or any done thereafter',
        },
        {
          label: IntakeHavingEnoughHelp.HavingEnough,
          descriptor: `
            Food insecurity is the primary measure of food hardship and is associated with poor health. This may be indicated in response to "having enough to eat" in which families share that they can’t afford healthy food, are forced to eat less, worry about running out of food, or not having enough to eat. This could also be addressed by asking if there were times within the past 12 months that the family ran out of food and didn’t have money to buy more.
          `,
        },
      ],
    },
  ];
}
