import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Routine } from '../../models/routine';

@Injectable({
  providedIn: 'root',
})
export class RoutinesService {
  private baseUrl = 'api/routine';

  constructor(private http: HttpClient) {}

  get(): Observable<Routine[]> {
    return this.http.get<Routine[]>(this.baseUrl);
  }
}
