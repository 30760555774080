import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ConsentModule } from 'src/app/consent/consent.module';
import { IepModule } from 'src/app/iep/iep.module';
import { AppMatModule } from 'src/app/shared/app-mat.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PostReferralConsentComponent } from '../early-access-intake/post-referral-consent/post-referral-consent.component';
import { ChildInfoFormComponent } from './components/child-info-form/child-info-form.component';
import { DocumentationInfoFormComponent } from './components/documentation-info-form/documentation-info-form.component';
import { DomainSelectorComponent } from './components/domain-selector/domain-selector.component';
import { FamilyInfoFormComponent } from './components/family-info-form/family-info-form.component';
import { AdditionalConcernsInfoComponent } from './components/health-info-form/additional-concerns-info/additional-concerns-info.component';
import { DentalVisionHearingInfoComponent } from './components/health-info-form/dental-vision-hearing-info/dental-vision-hearing-info.component';
import { HealthInfoFormComponent } from './components/health-info-form/health-info-form.component';
import { MedicalHistoryInfoComponent } from './components/health-info-form/medical-history-info/medical-history-info.component';
import { NutritionGrowthInfoComponent } from './components/health-info-form/nutrition-growth-info/nutrition-growth-info.component';
import { AgencyProgramTableComponent } from './components/health-info-form/provider-info-form/agency-program-table/agency-program-table.component';
import { ConsentReleaseExchangeDocumentComponent } from './components/health-info-form/provider-info-form/consent-release-exchange-document/consent-release-exchange-document.component';
import { ICDPhysicianLetterComponent } from './components/health-info-form/provider-info-form/icd-physician-letter/icd-physician-letter.component';
import { MedicalSpecialistTableComponent } from './components/health-info-form/provider-info-form/medical-specialist-table/medical-specialist-table.component';
import { ProviderInfoFormComponent } from './components/health-info-form/provider-info-form/provider-info-form.component';
import { InsuranceInfoFormComponent } from './components/insurance-info-form/insurance-info-form.component';
import { NextStepsFormComponent } from './components/next-steps-form/next-steps-form.component';
import { PartCConsentEvalComponent } from './components/next-steps-form/part-c-consent-eval/part-c-consent-eval.component';
import { PartCConsentPostrefScreeningResultComponent } from './components/next-steps-form/part-c-consent-postref-screening-result/part-c-consent-postref-screening-result.component';
import { PartCConsentPostrefScreeningComponent } from './components/next-steps-form/part-c-consent-postref-screening/part-c-consent-postref-screening.component';

@NgModule({
  declarations: [
    ChildInfoFormComponent,
    FamilyInfoFormComponent,
    HealthInfoFormComponent,
    ProviderInfoFormComponent,
    AgencyProgramTableComponent,
    MedicalSpecialistTableComponent,
    MedicalHistoryInfoComponent,
    AdditionalConcernsInfoComponent,
    DentalVisionHearingInfoComponent,
    NutritionGrowthInfoComponent,
    InsuranceInfoFormComponent,
    DocumentationInfoFormComponent,
    MedicalHistoryInfoComponent,
    PartCConsentEvalComponent,
    NextStepsFormComponent,
    PostReferralConsentComponent,
    ConsentReleaseExchangeDocumentComponent,
    ICDPhysicianLetterComponent,
    DomainSelectorComponent,
    PartCConsentPostrefScreeningComponent,
    PartCConsentPostrefScreeningResultComponent,
  ],
  imports: [CommonModule, AppMatModule, SharedModule, ReactiveFormsModule, RouterModule, ConsentModule, AngularSvgIconModule, IepModule],
  exports: [
    ChildInfoFormComponent,
    FamilyInfoFormComponent,
    HealthInfoFormComponent,
    ProviderInfoFormComponent,
    AgencyProgramTableComponent,
    MedicalSpecialistTableComponent,
    MedicalHistoryInfoComponent,
    AdditionalConcernsInfoComponent,
    DentalVisionHearingInfoComponent,
    NutritionGrowthInfoComponent,
    InsuranceInfoFormComponent,
    DocumentationInfoFormComponent,
    MedicalHistoryInfoComponent,
    PartCConsentEvalComponent,
    NextStepsFormComponent,
    PostReferralConsentComponent,
    DomainSelectorComponent,
  ],
})
export class EvaluationSharedModule {}
