<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Service</h3>
<div mat-dialog-content class="py-3">
  <div>
    <h3 class="mt-0 mb-1">Outcomes</h3>

    <ul class="list mb-0">
      <li class="list__item">
        <p class="my-0">
          <app-compare-amendment-output
            [amendmentId]="service.modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="service.modificationEndDate"
            [priorVersionId]="service?.priorVersionId"
            [isActive]="service.isActive"
            [changedVersionText]="getOutcomesText(service)"
            [currentVersionText]="service?.priorVersion ? getOutcomesText(service?.priorVersion) : null"
            [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </p>
      </li>
    </ul>
    <hr class="input__divider mt-1 mb-2" />
    <h3 class="mt-0 mb-2">Service Details</h3>
    <ul class="list mb-0">
      <li class="list__item">
        <strong class="text-primary">Service: </strong>
        <p class="my-0">
          <app-compare-amendment-output
            [amendmentId]="service.modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="service.modificationEndDate"
            [priorVersionId]="service?.priorVersionId"
            [isActive]="service.isActive"
            [changedVersionText]="service.typeOfService"
            [currentVersionText]="service?.priorVersion ? service?.priorVersion.typeOfService : null"
            [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </p>
      </li>
      <li class="list__item">
        <strong class="text-primary">How services are provided: </strong>
        <p class="my-0">
          <app-compare-amendment-output
            [amendmentId]="service.modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="service.modificationEndDate"
            [priorVersionId]="service?.priorVersionId"
            [isActive]="service.isActive"
            [changedVersionText]="getHowText(service)"
            [currentVersionText]="service?.priorVersion ? getHowText(service?.priorVersion) : null"
            [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </p>
      </li>
      <li class="list__item">
        <strong class="text-primary">With Whom: </strong>
        <p class="my-0">
          <app-compare-amendment-output
            [amendmentId]="service.modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="service.modificationEndDate"
            [priorVersionId]="service?.priorVersionId"
            [isActive]="service.isActive"
            [changedVersionText]="getWithWhom(service)"
            [currentVersionText]="service?.priorVersion ? getWithWhom(service?.priorVersion) : null"
            [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </p>
      </li>
      <li class="list__item">
        <strong class="text-primary"> Provider: </strong>
        <p class="my-0">
          <ng-container *ngIf="service.provider">
            <app-compare-amendment-output
              [amendmentId]="service.modificationId"
              [amendmentFinalized]="modificationIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [amendmentEndReasonValue]="service.modificationEndDate"
              [priorVersionId]="service?.priorVersionId"
              [isActive]="service.isActive"
              [changedVersionText]="getProviderText(service)"
              [currentVersionText]="service?.priorVersion ? getProviderText(service?.priorVersion) : null"
              [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
            ></app-compare-amendment-output>
          </ng-container>
          <ng-container *ngIf="!service.provider">
            <p>
              Name:
              <app-compare-amendment-output
                [amendmentId]="service.modificationId"
                [amendmentFinalized]="modificationIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [amendmentEndReasonValue]="service.modificationEndDate"
                [priorVersionId]="service?.priorVersionId"
                [isActive]="service.isActive"
                [changedVersionText]="service.otherProviderName"
                [currentVersionText]="service?.priorVersion ? service?.priorVersion.otherProviderName : null"
                [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
              ></app-compare-amendment-output>
            </p>
            <p>
              Agency:
              <app-compare-amendment-output
                [amendmentId]="service.modificationId"
                [amendmentFinalized]="modificationIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [amendmentEndReasonValue]="service.modificationEndDate"
                [priorVersionId]="service?.priorVersionId"
                [isActive]="service.isActive"
                [changedVersionText]="service.otherProviderAgency"
                [currentVersionText]="service?.priorVersion ? service?.priorVersion.otherProviderAgency : null"
                [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
              ></app-compare-amendment-output>
            </p>
            <p>
              Role:
              <app-compare-amendment-output
                [amendmentId]="service.modificationId"
                [amendmentFinalized]="modificationIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [amendmentEndReasonValue]="service.modificationEndDate"
                [priorVersionId]="service?.priorVersionId"
                [isActive]="service.isActive"
                [changedVersionText]="service.otherProviderRole"
                [currentVersionText]="service?.priorVersion ? service?.priorVersion.otherProviderRole : null"
                [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
              ></app-compare-amendment-output>
            </p>
          </ng-container>
        </p>
      </li>
      <li class="list__item">
        <strong class="text-primary">Projected Start Date: </strong>
        <p class="my-0">
          <app-compare-amendment-output
            [amendmentId]="service.modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="service.modificationEndDate"
            [priorVersionId]="service?.priorVersionId"
            [isActive]="service.isActive"
            [changedVersionText]="getServiceProjectedStartDateText(service)"
            [currentVersionText]="service?.priorVersion ? getServiceProjectedStartDateText(service?.priorVersion) : null"
            [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </p>
      </li>
      <li class="list__item">
        <strong class="text-primary">Location: </strong>
        <p class="my-0">
          <app-compare-amendment-output
            [amendmentId]="service.modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="service.modificationEndDate"
            [priorVersionId]="service.priorVersionId"
            [isActive]="service.isActive"
            [changedVersionText]="getLocationText(service)"
            [currentVersionText]="service?.priorVersion ? getLocationText(service?.priorVersion) : null"
            [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </p>
      </li>
      <li class="list__item" *ngIf="service.justificationLocation">
        <strong class="text-primary">Location Justification: </strong>
        <p class="my-0">
          <app-compare-amendment-output
            [amendmentId]="service.modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="service.modificationEndDate"
            [priorVersionId]="service.priorVersionId"
            [isActive]="service.isActive"
            [changedVersionText]="service.justificationLocation"
            [currentVersionText]="service?.priorVersion ? service?.priorVersion.justificationLocation : null"
            [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </p>
      </li>
      <li class="list__item">
        <strong class="text-primary">Length per session: </strong>
        <p class="my-0">
          <span class="display-inline-block">
            <app-compare-amendment-output
              [amendmentId]="service.modificationId"
              [amendmentFinalized]="modificationIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [amendmentEndReasonValue]="service.modificationEndDate"
              [priorVersionId]="service.priorVersionId"
              [isActive]="service.isActive"
              [changedVersionText]="service.length"
              [currentVersionText]="service?.priorVersion ? service?.priorVersion.length : null"
              [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
            ></app-compare-amendment-output>
          </span>
          <span class="display-inline-block">&nbsp;Min </span>
        </p>
      </li>
      <li class="list__item">
        <strong class="text-primary">How often: </strong>
        <p class="my-0">
          <app-compare-amendment-output
            [amendmentId]="service.modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="service.modificationEndDate"
            [priorVersionId]="service.priorVersionId"
            [isActive]="service.isActive"
            [changedVersionText]="getFrequencyText(service)"
            [currentVersionText]="service?.priorVersion ? getFrequencyText(service?.priorVersion) : null"
            [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </p>
      </li>
    </ul>
  </div>
</div>
<div mat-dialog-actions class="actions display-flex justify-content-end">
  <div>
    <button mat-button aria-label="Delete Icon" (click)="dismiss()" cdkFocusInitial tabindex="0">Close</button>
  </div>
</div>
