import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Subscription } from 'rxjs';
import { EcoArea, EvaluationDetail, EvaluationNote } from 'src/app/evaluation/models/evaluation';
import { EvaluationRoutine } from 'src/app/evaluation/models/evaluation-routine';
import { EvaluationRoutineService } from 'src/app/evaluation/services/evaluation-routine.service';
import { EvaluationService } from 'src/app/evaluation/services/evaluation.service';
import { EvaluationDomain } from 'src/app/shared/models/evaluation-domain';
import { LookupBase } from 'src/app/shared/models/lookup-base';
import { EcoAreaService } from 'src/app/shared/services/eco-areas/eco-area.service';
import { TaggedForCategory } from 'src/app/tags/tagged-category';
import { RoutineAddModalComponent } from '../../evaluation-interview/modals/routine-add-modal.component';
import { EvaluationFormService } from '../../services/evaluation-form.service';

@Component({
  selector: 'app-evaluation-tags',
  templateUrl: './evaluation-tags.component.html',
  styleUrls: ['./evaluation-tags.component.scss'],
})
export class EvaluationTagsComponent implements OnInit, OnChanges {
  @Input() hideNewTags = false;
  @Input() currentEvaluationDetail: EvaluationDetail;
  @Input() currentEvaluationNote: EvaluationNote;
  @Input() isPartB = false;
  @Input() evaluationId: string;
  @Input() caseId: string;
  @Input() isRoutine = false;
  @Input() routine: FormGroup;
  @Input() selectedEcoAreas: EcoArea[] = [];
  @Input() selectedEvaluationDomains: LookupBase[] = [];
  @Input() selectedEvaluationRoutines: EvaluationRoutine[] = [];
  @Input() preSavedNoteTags: boolean[] = [];
  @Output() updatePreSavedNoteTags = new EventEmitter<TaggedForCategory>();
  tagEcoAreaToggled: boolean;
  tagDomainsToggled: boolean;
  tagRoutineToggled: boolean;
  ecoAreas: EcoArea[];
  evaluationDomains: LookupBase[];
  evaluationRoutines: EvaluationRoutine[];
  taggedForCategory = TaggedForCategory;

  private subscription: Subscription;

  @Output() evalTagsChange: EventEmitter<void> = new EventEmitter();

  constructor(
    private ecoAreaService: EcoAreaService,
    private evaluationService: EvaluationService,
    private evaluationRoutineService: EvaluationRoutineService,
    public evaluationFormService: EvaluationFormService,
    private dialog: MatDialog,
    private readonly cd: ChangeDetectorRef
  ) {
    this.subscription = evaluationFormService.routineAdded$.subscribe(() => {
      this.evaluationRoutineService.get(this.evaluationId).subscribe(
        (res) =>
          (this.evaluationRoutines = res.sort((a, b) => {
            const aLabel = a.label === 'Other' ? a.otherLabel : a.label;
            const bLabel = b.label === 'Other' ? b.otherLabel : b.label;
            return aLabel.localeCompare(bLabel);
          }))
      );
    });
  }

  ngOnInit(): void {
    this.cd.detectChanges();
    forkJoin([
      this.ecoAreaService.get(),
      this.evaluationService.getDevelopmentalAreas(this.evaluationId),
      this.evaluationRoutineService.get(this.evaluationId),
    ]).subscribe(([ecoAreas, evaluationDomains, evaluationRoutines]) => {
      this.ecoAreas = ecoAreas;
      this.evaluationDomains = evaluationDomains;
      this.evaluationRoutines = evaluationRoutines.sort((a, b) => {
        const aLabel = a.label === 'Other' ? a.otherLabel : a.label;
        const bLabel = b.label === 'Other' ? b.otherLabel : b.label;
        return aLabel.localeCompare(bLabel);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.tagEcoAreaToggled = false;
    this.tagDomainsToggled = false;
    this.tagRoutineToggled = false;
    if (this.selectedEcoAreas && this.selectedEcoAreas.length > 0) {
      this.onTagEcoAreaToggle();
    }
    if (this.selectedEvaluationDomains && this.selectedEvaluationDomains.length > 0) {
      this.onTagEvaluationDomainToggle();
    }
    if (this.selectedEvaluationRoutines && this.selectedEvaluationRoutines.length > 0) {
      this.onTagEvaluationRoutineToggle();
    }
  }

  //#region Eco Area Tags

  onTagEcoAreaToggle() {
    this.tagEcoAreaToggled = !this.tagEcoAreaToggled;
  }

  onEcoAreaSelect(event: MatButtonToggleChange) {
    if (event.source.checked) {
      this.selectedEcoAreas.push({ id: event.value } as EcoArea);
    } else {
      const index = this.selectedEcoAreas.findIndex((x) => x.id === event.value);
      if (index > -1) {
        this.selectedEcoAreas.splice(index, 1);
      }
    }
    this.evalTagsChange.emit();
  }

  isEcoAreaChecked(id: string) {
    return this.selectedEcoAreas?.some((x) => x.id === id);
  }

  //#endregion

  //#region Evaluation Domain Tags

  onTagEvaluationDomainToggle() {
    this.tagDomainsToggled = !this.tagDomainsToggled;
  }

  onEvaluationDomainSelect(event: MatButtonToggleChange) {
    if (event.source.checked) {
      this.selectedEvaluationDomains.push({
        id: event.value,
      } as EvaluationDomain);
    } else {
      const index = this.selectedEvaluationDomains.findIndex((x) => x.id === event.value);
      if (index > -1) {
        this.selectedEvaluationDomains.splice(index, 1);
      }
    }
    this.evalTagsChange.emit();
  }

  isEvaluationDomainChecked(id: string) {
    return this.selectedEvaluationDomains?.some((x) => x.id === id);
  }

  //#endregion

  //#region Evaluation Domain Tags

  onTagEvaluationRoutineToggle() {
    this.tagRoutineToggled = !this.tagRoutineToggled;
  }

  onEvaluationRoutineSelect(event: MatButtonToggleChange) {
    if (event.source.checked) {
      this.selectedEvaluationRoutines.push({
        id: event.value,
      } as EvaluationRoutine);
    } else {
      const index = this.selectedEvaluationRoutines.findIndex((x) => x.id === event.value);
      if (index > -1) {
        this.selectedEvaluationRoutines.splice(index, 1);
      }
    }
    this.evalTagsChange.emit();
  }

  isEvaluationRoutineChecked(id: string) {
    return this.selectedEvaluationRoutines?.some((x) => x.id === id);
  }

  //#endregion

  onReset() {
    this.tagDomainsToggled = false;
    this.tagEcoAreaToggled = false;
    this.tagRoutineToggled = false;
  }

  onAddRoutine() {
    const dialogRef = this.dialog.open(RoutineAddModalComponent, {
      width: '768px',
    });

    dialogRef.afterClosed().subscribe((updatedRoutines) => {
      if (updatedRoutines) {
        this.evaluationRoutineService.get(this.evaluationId).subscribe((routines) => {
          this.evaluationRoutines = routines.sort((a, b) => {
            const aLabel = a.label === 'Other' ? a.otherLabel : a.label;
            const bLabel = b.label === 'Other' ? b.otherLabel : b.label;
            return aLabel.localeCompare(bLabel);
          });
        });
        this.evaluationFormService.addRoutine();
      }
    });
  }

  tag(tag: TaggedForCategory) {
    switch (tag) {
      case this.taggedForCategory.ServicesC:
        if (this.routine) {
          this.routine?.controls?.taggedForServicesC?.setValue(!this.routine?.value?.taggedForServicesC);
        } else if (this.currentEvaluationNote) {
          this.currentEvaluationNote.taggedForServicesC = !this.currentEvaluationNote.taggedForServicesC;
        } else if (this.currentEvaluationDetail) {
          this.updatePreSavedNoteTags.emit(this.taggedForCategory.ServicesC);
        }
        break;
      case this.taggedForCategory.Outcomes:
        if (this.routine) {
          this.routine?.controls?.taggedForOutcomes?.setValue(!this.routine?.value?.taggedForOutcomes);
        } else if (this.currentEvaluationNote) {
          this.currentEvaluationNote.taggedForOutcomes = !this.currentEvaluationNote.taggedForOutcomes;
        } else if (this.currentEvaluationDetail) {
          this.updatePreSavedNoteTags.emit(this.taggedForCategory.Outcomes);
        }

        break;
      case this.taggedForCategory.Pwn:
        if (this.routine) {
          this.routine?.controls?.taggedForPwn?.setValue(!this.routine?.value?.taggedForPwn);
        } else if (this.currentEvaluationNote) {
          this.currentEvaluationNote.taggedForPwn = !this.currentEvaluationNote.taggedForPwn;
        } else if (this.currentEvaluationDetail) {
          this.updatePreSavedNoteTags.emit(this.taggedForCategory.Pwn);
        }
        break;
    }
  }

  showTaggedIcon(category: TaggedForCategory) {
    switch (category) {
      case TaggedForCategory.ServicesC:
        if (this.currentEvaluationNote) {
          return this.currentEvaluationNote?.taggedForServicesC;
        } else if (this.routine?.value) {
          return this.routine?.value?.taggedForServicesC;
        } else if (this.currentEvaluationDetail) {
          return this.preSavedNoteTags[0];
        }
        break;
      case TaggedForCategory.Outcomes:
        if (this.currentEvaluationNote) {
          return this.currentEvaluationNote?.taggedForOutcomes;
        } else if (this.routine?.value) {
          return this.routine?.value?.taggedForOutcomes;
        } else if (this.currentEvaluationDetail) {
          return this.preSavedNoteTags[1];
        }
        break;
      case TaggedForCategory.Pwn:
        if (this.currentEvaluationNote) {
          return this.currentEvaluationNote?.taggedForPwn;
        } else if (this.routine?.value) {
          return this.routine?.value?.taggedForPwn;
        } else if (this.currentEvaluationDetail) {
          return this.preSavedNoteTags[2];
        }
        break;
    }
  }
}
