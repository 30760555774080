import { AfterViewInit, Component, Type } from '@angular/core';
import { GuardsCheckStart, NavigationStart, Router } from '@angular/router';
import { SpinnerService } from './shared/services/spinner/spinner.service';
import { ActivityWatcher } from './activity-watcher.service';
import { CanDeactivateGuard } from './shared/guards/can-deactivate.guard';
import { BaseComponent } from './shared/components/base-component/base-component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  showLoading = false;

  constructor(private spinnerService: SpinnerService, router: Router, private readonly activityWatcher: ActivityWatcher) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        activityWatcher.startNewActivity((event as NavigationStart).url);
      }
      if (event instanceof GuardsCheckStart) {
        // the following code will automatically add CanDeactivateGuard to child routes
        // if they inherit from BaseComponent
        let lastChild = event.state.root;
        while (lastChild && lastChild.firstChild) {
          lastChild = lastChild.firstChild;
        }

        if (lastChild && lastChild.routeConfig) {
          if (!lastChild.routeConfig.canDeactivate) {
            lastChild.routeConfig.canDeactivate = [];
          }

          if (!((lastChild.component as Type<any>)?.prototype instanceof BaseComponent)) {
            return;
          }
          const canDeactivateGuards = lastChild.routeConfig.canDeactivate;

          if (!canDeactivateGuards.some((o) => o === CanDeactivateGuard)) {
            canDeactivateGuards.push(CanDeactivateGuard);
          }
        }
      }
    });
  }

  ngAfterViewInit() {
    this.spinnerService.init();
    this.spinnerService.showLoading$.subscribe((showLoading) => (this.showLoading = showLoading));
  }
}
