<button
  mat-flat-button
  aria-label="Amend"
  color="primary"
  *ngIf="hasOpenAmendment"
  class="mr-2"
  (click)="$event.stopPropagation(); onAmend()"
  tabindex="0"
>
  Amend
</button>

<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-md-12">
      <ng-container *ngIf="!isAmendment || (hasAmendment && amendingPhysicalEducation)">
        <app-autocomplete [options]="kvOptions" formControlName="physicalEducation" label="Select"></app-autocomplete>
        <span *ngIf="hasAmendment && physicalEducationModel">
          <app-compare-amendment-output
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="physicalEducationModel?.priorVersionId"
            [isActive]="physicalEducationModel?.isActive"
            [changedVersionText]="getPhysicalEducationLabel(formGroup.get('physicalEducation').value)"
            [currentVersionText]="physicalEducationModel.priorVersion?.physicalEducationLabel"
            [currentPrefixText]="physicalEducationModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </span>
      </ng-container>
      <ng-container *ngIf="isAmendment && (!hasAmendment || !amendingPhysicalEducation)">
        <p class="text-semibold">Physical Education</p>
        <app-compare-amendment-output
          [amendmentId]="physicalEducationModel?.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="physicalEducationModel?.priorVersionId"
          [isActive]="physicalEducationModel?.isActive"
          [changedVersionText]="physicalEducationModel?.physicalEducationLabel"
          [currentVersionText]="physicalEducationModel?.priorVersion?.physicalEducationLabel"
          [currentPrefixText]="physicalEducationModel?.priorVersion ? 'Current: ' : ''"
          [changedPrefixText]="physicalEducationModel?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </ng-container>
    </div>
    <div *ngIf="showDescribe" class="col-md-12 col-lg-12">
      <ng-container *ngIf="!isAmendment || (hasAmendment && amendingPhysicalEducation)">
        <app-textarea
          label="Describe"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.iepSsaaPhysicalEducation.physicalEdDescription"
          formControlName="physicalEdDescription"
          [showAsterisk]="formGroup.hasError('required')"
          [rows]="4"
        ></app-textarea>
        <span *ngIf="hasAmendment && physicalEducationModel">
          <app-compare-amendment-output
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="physicalEducationModel?.priorVersionId"
            [isActive]="physicalEducationModel?.isActive"
            [changedVersionText]="formGroup.get('physicalEdDescription').value"
            [currentVersionText]="physicalEducationModel?.priorVersion?.physicalEdDescription"
            [currentPrefixText]="physicalEducationModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </span>
      </ng-container>
      <ng-container *ngIf="isAmendment && (!hasAmendment || !amendingPhysicalEducation)">
        <p class="text-semibold">Physical Education Description</p>
        <app-compare-amendment-output
          [amendmentId]="physicalEducationModel?.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="physicalEducationModel?.priorVersionId"
          [isActive]="physicalEducationModel?.isActive"
          [changedVersionText]="physicalEducationModel?.physicalEdDescription"
          [currentVersionText]="physicalEducationModel?.priorVersion?.physicalEdDescription"
          [currentPrefixText]="physicalEducationModel?.priorVersion ? 'Current: ' : ''"
          [changedPrefixText]="physicalEducationModel?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </ng-container>
    </div>
  </div>
  <div *ngIf="!hasAmendment" class="action__row mt-0">
    <div>
      <button mat-raised-button aria-label="View Summary" (click)="openSummary()" color="accent" *ngIf="!isDetailsView" tabindex="0">
        View Summary
      </button>
    </div>
  </div>
</form>
