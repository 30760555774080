import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IvrsMemberRegistrationService } from './ivrs-member-registration.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { OperationResultWithValue } from 'src/app/shared/models/operation-result';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';

const passwordsMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password')?.value;
  const confirmedPassword = control.get('confirmedPassword')?.value;

  return password !== confirmedPassword ? { passwordMismatch: true } : null;
};

const passwordsFormatValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password')?.value;
  const confirmedPassword = control.get('confirmedPassword')?.value;
  const pwdReq = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d#?!@$%^&*()-]).{8,32}$/;

  if (!pwdReq.test(password) || !pwdReq.test(confirmedPassword)) return { passwordFormat: true };
  return null;
};

@Component({
  selector: 'app-ivrs-member-registration',
  templateUrl: './ivrs-member-registration.component.html',
  styleUrls: ['./ivrs-member-registration.component.scss'],
})
export class IvrsMemberRegistrationComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  invitationStatus = 'NONE';
  isBusy = false;
  token = '';

  formGroup = new FormGroup(
    {
      password: new FormControl('', { validators: [Validators.required] }),
      confirmedPassword: new FormControl('', { validators: [Validators.required] }),
      token: new FormControl(''),
    },
    {
      validators: [passwordsMatchValidator, passwordsFormatValidator],
    }
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private registrationServices: IvrsMemberRegistrationService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.activatedRoute.paramMap.subscribe((params) => {
        this.token = params.get('token');
        this.formGroup.get('token').setValue(this.token);
        this.loadScenario();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  loadScenario() {
    this.subscriptions.add(
      this.registrationServices.getStatus(this.token).subscribe({
        next: (res: OperationResultWithValue<string>) => {
          if (res.succeeded) {
            this.invitationStatus = res.value.toString();
          }
        },
        error: (err) => {
          this.invitationStatus = 'NONE';
          this.notificationService.error(err.message);
        },
      })
    );
  }

  onRegister() {
    this.isBusy = true;
    this.subscriptions.add(
      this.registrationServices.addPassword(this.token, this.formGroup.get('password').value).subscribe({
        next: (res: OperationResultWithValue<boolean>) => {
          if (res.succeeded) {
            this.notificationService.success('Confirmation succeeded');
            timer(5000).subscribe(() => this.router.navigate(['/', 'auth', 'ivrs', 'login']));
            this.formGroup.reset();
          } else {
            this.isBusy = false;
            this.notificationService.alert('Server issue during the confirmation');
          }
        },
        error: () => this.notificationService.error('Unable to confirm invitation'),
      })
    );
  }
}
