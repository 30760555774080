import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GradeBookAdd } from '../models/grade-book';

@Injectable({
  providedIn: 'root',
})
export class GradeBookService {
  constructor(private http: HttpClient) {}

  get() {
    return this.http.get<any>('api/grade-book');
  }

  add(gradeBook: GradeBookAdd) {
    return this.http.post('api/grade-book', gradeBook);
  }
}
