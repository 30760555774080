<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-md-12 display-flex justify-content-end flex-wrap align-items-center">
      <button
        mat-raised-button
        attr.aria-label="{{ formGroup.controls?.dataPoints?.value?.length > 0 ? 'Close ' : 'Calculate ' }} Baseline"
        type="button"
        color="primary"
        (click)="calculateBaseLine()"
        *ngIf="!disableBaselineAndTarget"
        [ngClass]="{
          'mr-2': formGroup.controls?.dataPoints?.value?.length > 0
        }"
        tabindex="0"
      >
        {{ formGroup.controls?.dataPoints?.value?.length > 0 ? 'Close ' : 'Calculate ' }}
        Baseline
      </button>
      <div *ngIf="formGroup.controls?.dataPoints?.value?.length > 0" class="display-flex align-items-center flex-wrap">
        <button
          [disabled]="dataPointLimitExceeded"
          mat-flat-button
          aria-label="Add Data Point"
          color="primary"
          type="button"
          (click)="addDataPoint()"
          tabindex="0"
        >
          Add Data Point
        </button>

        <p class="my-0 ml-2" *ngIf="dataPointLimitExceeded">Data Point Limit Exceeded</p>
      </div>
    </div>
  </div>

  <div *ngIf="formGroup.controls?.dataPoints?.value?.length > 0">
    <div
      *ngFor="let dp of formGroup.controls?.dataPoints['controls']; let idx = index"
      [formGroup]="dp"
      class="row mt-0 align-items-center"
    >
      <div class="col-sm-2">
        <label for="">Data Point {{ idx + 1 }}</label>
      </div>
      <div class="col-sm-2">
        <app-number-field label="Score" id="score" formControlName="score" [min]="0"></app-number-field>
      </div>
      <div class="col-sm-2">
        <app-date-picker label="Date" id="date" controlName="date" [max]="today"></app-date-picker>
      </div>
      <div class="col-sm-1">
        <button aria-label="Delete Icon" mat-icon-button color="warn" type="button" (click)="removeDataPoint(idx)" tabindex="0">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div *ngIf="idx === formGroup.controls?.dataPoints['controls']?.length - 1" class="col-sm-2 display-flex justify-content-end"></div>
    </div>
  </div>

  <div>
    <div class="display-flex align-items-baseline mr-3 flex-wrap">
      <div class="display-flex align-items-baseline mr-2">
        <span class="mr-2">
          {{ caseSummary?.learner.firstName | firstNamePossessive }}
          <a (click)="onOpenHelp($event, helpSection.IepGoal, iepGoalHelp.Baseline)" class="text-underline" tabindex="0">
            {{ iepGoalHelp.Baseline }}
          </a>
        </span>

        <app-number-field
          *ngIf="isManualBaseline && !disableBaselineAndTarget"
          [min]="0"
          formControlName="baseline"
          class="input--small"
          id="baseline"
        ></app-number-field>

        <app-compare-amendment-output
          *ngIf="amendingGoal?.priorVersionId && isManualBaseline && !disableBaselineAndTarget"
          class="mr-1"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="amendingGoal?.priorVersionId"
          [isActive]="amendingGoal?.isActive"
          [changedVersionText]="formGroup.get('baseline').value"
          [currentVersionText]="isAdditionalQuant ? priorVersionAdditionalQuantData?.baseline : priorVersionQuantData?.baseline"
          [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        >
        </app-compare-amendment-output>
        <span *ngIf="!amendingGoal?.priorVersionId && (!isManualBaseline || disableBaselineAndTarget)">
          {{ formGroup.get('baseline').value | number : '1.0-1' }}
        </span>
      </div>
      <div class="display-flex align-items-baseline mx-4">
        <ng-container
          *ngIf="!useSameUnitAndTarget && ((!combinedObjective && !isAdditionalQuant) || (!combinedObjective && isAdditionalQuant))"
        >
          <span class="mr-2">
            <a (click)="onOpenHelp($event, helpSection.IepGoal, iepGoalHelp.UnitMeasurement)" class="text-underline" tabindex="0">
              {{ iepGoalHelp.UnitMeasurement }}
            </a>
          </span>
          <app-text-field
            [isBankableField]="true"
            [bankableFieldId]="bankableFieldIds.iepGoals.quantData.scaleLevelDescription"
            formControlName="unitOfMeasurement"
          ></app-text-field>
          <app-compare-amendment-output
            *ngIf="amendingGoal?.priorVersionId"
            class="mr-1"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="amendingGoal?.priorVersionId"
            [isActive]="amendingGoal?.isActive"
            [changedVersionText]="formGroup.get('unitOfMeasurement').value"
            [currentVersionText]="
              isAdditionalQuant ? priorVersionAdditionalQuantData?.unitOfMeasurement : priorVersionQuantData?.unitOfMeasurement
            "
            [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </ng-container>
        <ng-container *ngIf="useSameUnitAndTarget && isAdditionalQuant">
          <span class="mr-2">Unit of Measurement: {{ primaryUnitOfMeasurement }}</span>
        </ng-container>
      </div>

      <div class="display-flex align-items-baseline">
        <ng-container *ngIf="!useSameUnitAndTarget && !rubricMaxCalc">
          <span class="mr-2">
            {{ caseSummary?.learner.firstName | firstNamePossessive }}
            <a (click)="onOpenHelp($event, helpSection.IepGoal, iepGoalHelp.TargetValue)" class="text-underline" tabindex="0">{{
              iepGoalHelp.TargetValue
            }}</a>
          </span>
          <app-number-field
            *ngIf="!disableBaselineAndTarget"
            [min]="0"
            [max]="maxTargetValue"
            formControlName="learnersGoalTarget"
            class="input--small"
            label=""
            id="learnersGoalTarget"
          ></app-number-field>
          <span class="mr-2" *ngIf="disableBaselineAndTarget">
            {{ formGroup.get('learnersGoalTarget').value }}
          </span>
          <app-compare-amendment-output
            *ngIf="amendingGoal?.priorVersionId && !disableBaselineAndTarget"
            class="mr-1"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="amendingGoal?.priorVersionId"
            [isActive]="amendingGoal?.isActive"
            [changedVersionText]="formGroup.get('learnersGoalTarget').value"
            [currentVersionText]="
              isAdditionalQuant ? priorVersionAdditionalQuantData?.learnersGoalTarget : priorVersionQuantData?.learnersGoalTarget
            "
            [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </ng-container>
        <ng-container *ngIf="rubricMaxCalc">
          <span class="mr-2">
            {{ caseSummary?.learner.firstName | firstNamePossessive }}
            Target Value: {{ rubricMaxCalc }} out of {{ rubricMaxCalc }}
          </span>
        </ng-container>
        <ng-container *ngIf="useSameUnitAndTarget && isAdditionalQuant">
          <span class="mr-2">{{ caseSummary?.learner.firstName | firstNamePossessive }} Target Value: {{ primaryTargetValue }}</span>
        </ng-container>

        <span class="mx-2 text-break">{{ formGroup.controls?.unitOfMeasurement?.value }}</span>
      </div>
    </div>
  </div>
</form>
