import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FbaService } from 'src/app/behavior-discipline/fba/services/fbaService.service';
import { AnnualReviewUpdate, IfspReviewType, PeriodicReviewUpdate } from 'src/app/ifsp/models/ifsp';
import { IfspService } from 'src/app/ifsp/services/ifsp.service';
import { AppPermissions } from 'src/app/permissions';
import { AuthService } from '../../../auth/auth.service';
import { CaseSummary, IntakeType } from '../../models/case';
import { CaseService } from '../../services/case/case.service';
import { NotificationService } from '../../services/notification.service';
import { RoutingService } from '../../services/routing.service';

@Component({
  selector: 'app-test-links',
  templateUrl: './test-links.component.html',
  styleUrls: ['./test-links.component.scss'],
})
export class TestLinksComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  caseId: string;
  @Input() caseSummary: CaseSummary;
  intakeType = IntakeType;
  permissions = AppPermissions;

  get caseDescription(): string {
    return this.caseSummary?.intakeType === IntakeType.PartC || this.caseSummary?.intakeType === IntakeType.PartCLate
      ? 'Early ACCESS'
      : 'Special Education';
  }

  constructor(
    private route: ActivatedRoute,
    public authService: AuthService,
    private router: Router,
    private fbaService: FbaService,
    private dialog: MatDialog,
    private caseService: CaseService,
    private notificationService: NotificationService,
    private readonly routingService: RoutingService,
    private ifspService: IfspService
  ) {}

  ngOnInit(): void {
    this.caseId = this.caseSummary.id;
    this.subscriptions.add(this.caseService.getCaseSummary(this.caseId).subscribe((res: CaseSummary) => (this.caseSummary = res)));
  }

  beginAnnualReview() {
    const annualReviewUpdate: AnnualReviewUpdate = {
      reviewActive: true,
      annualReviewDate: new Date(),
      ifspType: IfspReviewType.Annual,
    };

    this.ifspService.updateReviewStatus(this.caseSummary.activeIfspId, annualReviewUpdate).subscribe((res) => {
      this.routingService.ifspDetails(this.caseId, this.caseSummary.activeIfspId);
    });
  }

  beginPeriodicReview() {
    const periodicReviewUpdate: PeriodicReviewUpdate = {
      periodicReviewActive: true,
      periodicReviewDate: new Date(),
    };

    this.ifspService.updatePeriodicReview(this.caseSummary.activeIfspId, periodicReviewUpdate).subscribe((res) => {
      this.router.navigate(this.routingService.ifspListPath(this.caseSummary?.learnerId));
    });
  }

  sendPeriodicReviewPoll() {
    this.ifspService.sendPeriodicReviewPoll(this.caseSummary.activeIfspId).subscribe(() => {
      this.notificationService.success('Questionnaire Sent!');
      this.router.navigate(this.routingService.ifspListPath(this.caseSummary?.learnerId));
    });
  }

  sendAnnualReviewPoll() {
    this.ifspService.sendAnnualReviewPoll(this.caseSummary.activeIfspId).subscribe(() => {
      this.notificationService.success('Questionnaire Sent!');
    });
  }

  setAnnualReviewDate() {
    this.ifspService.updateAnnualReviewDateForTesting(this.caseSummary.activeIfspId).subscribe(() => {});
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
