import { Directive, Input, OnInit, OnDestroy, DoCheck } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { BehaviorSubject, Observable } from 'rxjs';

import { ChangeTrackerService } from '../services/change-tracker.service';
import { Trackable } from '../models/trackable';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[trackFormChanges]',
})
export class TrackFormChangesDirective implements Trackable, OnInit, OnDestroy, DoCheck {
  private lastDirtyValue = false;
  private readonly changedSubject = new BehaviorSubject<boolean>(this.lastDirtyValue);
  @Input('trackFormChanges') public changesForm: AbstractControl;

  changed: Observable<boolean>;

  constructor(private changeTracker: ChangeTrackerService) {
    this.changed = this.changedSubject.asObservable();
  }

  ngOnInit(): void {
    this.changeTracker.subscribe(this);
  }

  ngOnDestroy(): void {
    this.changeTracker.unsubscribe(this);
  }

  ngDoCheck(): void {
    if (!this.changesForm) {
      return;
    }

    if (this.changesForm.dirty !== this.lastDirtyValue) {
      this.lastDirtyValue = this.changesForm.dirty;
      this.changedSubject.next(this.lastDirtyValue);
    }
  }
}
