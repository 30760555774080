import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { shortDateFormat } from '../../../../../../shared/dateTimeHelpers';
import { CaseSummary } from '../../../../../../shared/models/case';
import { KeyValuePair } from '../../../../../../shared/models/key-value-pair';
import { WeightedMatrixDto, WeightedMatrixLevelDto, ServedStatus, WeightedMatrixScoreDto } from '../../../../../models/iep-weighted-matrix';
import { IepDetailWeightedMatrixService } from '../../../../../services/iep-detail-weighted-matrix.service';

@Component({
  selector: 'app-iep-weight-recommendation',
  templateUrl: './iep-weight-recommendation.component.html',
  styleUrls: ['./iep-weight-recommendation.component.scss'],
})
export class IepWeightRecommendationComponent implements OnInit {
  @Input() caseSummary: CaseSummary;
  @Input() weightedMatrix: WeightedMatrixDto;
  @Input() weightedMatrixLevels: WeightedMatrixLevelDto[];
  @Output() historyChange = new EventEmitter();
  @Input() isRLevelScore: boolean;
  @Input() isAutoScore: boolean;

  filteredMatrixLevels: KeyValuePair[];
  showChangeWeighting = false;
  showAlternativeFields = false;
  showWeightingFields = false;
  displayedColumns = ['reasonDescription', 'points'];
  dataSource = new MatTableDataSource([]);
  formGroup = this.fb.group({
    actualScore: [null],
    comments: [null],
    level: [null],
  });
  iepId: string;
  shortDateFormat = shortDateFormat;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private weightedMatrixService: IepDetailWeightedMatrixService) {}

  get score() {
    if (this.dataSource.data.length > 0) {
      let score = 0;
      this.dataSource.data.filter((x) => x.reasonDescription !== 'Total').forEach((x) => (score += x.points));
      return score;
    } else {
      return null;
    }
  }

  get hasSpecialCircumstance() {
    return !!this.dataSource.data.find((x) => x.uniqueCircumstances > 0);
  }

  get recommendedWeight() {
    return this.getAssociatedLevelFromScore(this.score, 'level');
  }

  ngOnInit(): void {
    this.iepId = this.route.snapshot.paramMap.get('iepId');
    this.weightedMatrixService.getWeightedRecommendation(this.iepId).subscribe((val) => {
      this.dataSource.data = val.value;
      if (this.dataSource.data.length > 0) {
        this.dataSource.data.push({ reasonDescription: 'Total', points: this.score });
      }
    });

    this.populateLevelsDropdown();

    if (this.weightedMatrix.isRecommendationFinalized || this.isRLevelScore || this.isAutoScore) {
      this.showChangeWeighting = true;

      if (this.isRLevelScore && this.weightedMatrix.weightedMatrixScoreHistory?.length === 0) {
        this.toggleFields('weighting');
      }
    }

    this.weightedMatrixService.weightedMatrixSpecialCircumstancesUpdated.subscribe((x) => {
      this.weightedMatrixService.getWeightedRecommendation(this.iepId).subscribe((val) => {
        const scores =
          val.value.length > 0
            ? [
                ...val.value,
                ...[{ reasonDescription: 'Total', points: val.value.map((dto) => dto.points).reduce((prev, next) => prev + next, 0) }],
              ]
            : val.value;
        this.dataSource.data = scores;
      });
    });
  }

  populateLevelsDropdown() {
    if (this.weightedMatrix.iepHasSDO) {
      if (this.caseSummary.learner.isPK) {
        this.filteredMatrixLevels = this.weightedMatrixLevels
          .filter((x) => x.isPk && x.isSDI)
          .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
          .map((x) => new KeyValuePair(x.id, x.label));
      } else {
        this.filteredMatrixLevels = this.weightedMatrixLevels
          .filter((x) => !x.isPk && x.isSDI)
          .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
          .map((x) => new KeyValuePair(x.id, x.label));
      }
    } else if (this.isRLevelScore) {
      this.filteredMatrixLevels = this.weightedMatrixLevels
        .filter((x) => x.isSA)
        .filter((x) => (this.caseSummary.learner.isPK ? x.level !== 'R1' : true))
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
        .map((x) => new KeyValuePair(x.id, x.label));
    } else {
      this.filteredMatrixLevels = this.weightedMatrixLevels.filter((x) => x.level === 'RS').map((x) => new KeyValuePair(x.id, x.label));
    }

    if (this.weightedMatrix.hasRpBuilding) {
      const rpMatrixLevel = this.weightedMatrixLevels.find((x) => x.level === 'RP');
      if (rpMatrixLevel) {
        this.filteredMatrixLevels.push(new KeyValuePair(rpMatrixLevel.id, rpMatrixLevel.label));
      }
    }
  }

  getAssociatedLevelFromScore(score, type) {
    let level: WeightedMatrixLevelDto;
    if (this.caseSummary.learner.isPK) {
      level = this.weightedMatrixLevels.find((x) => x.isSDI && x.isPk && score >= x.minPoint && score <= x.maxPoint);
    } else {
      level = this.weightedMatrixLevels.find((x) => x.isSDI && !x.isPk && score >= x.minPoint && score <= x.maxPoint);
    }

    if (score === null || !level) {
      return null;
    }

    switch (type) {
      case 'id':
        return level.id;
      case 'level':
        return level.level;
      case 'label':
        return level.label;
    }
  }

  acceptSuggestion() {
    const matrixScore: WeightedMatrixScoreDto = {
      weightedMatrixId: this.weightedMatrix.id,
      iepId: this.iepId,
      recommendedScore: this.score,
      actualScore: this.score,
      weightedMatrixLevelId: this.getAssociatedLevelFromScore(this.score, 'id'),
      comments: null,
      hasSpecialCircumstance: this.hasSpecialCircumstance,
    };

    this.weightedMatrixService.createWeightedMatrixScore(this.caseSummary.id, matrixScore).subscribe((val) => {
      setTimeout(() => {
        this.historyChange.emit();
      }, 300);

      this.showAlternativeFields = false;
      this.showWeightingFields = false;
      this.showChangeWeighting = true;
    });
  }

  toggleFields(type = 'alternative') {
    if (type === 'alternative') {
      this.showAlternativeFields = !this.showAlternativeFields;
    } else {
      this.showWeightingFields = !this.showWeightingFields;
    }

    if (this.showWeightingFields || this.showAlternativeFields) {
      this.formGroup.get('level').setValidators([Validators.required]);
    } else {
      this.formGroup.get('level').setValidators([]);
      this.formGroup.get('level').setValue(null);
      this.formGroup.get('comments').setValue(null);
    }

    Object.keys(this.formGroup.controls).forEach((x) => {
      this.formGroup.get(x).markAsUntouched();
      this.formGroup.get(x).updateValueAndValidity();
    });
  }

  chooseAlternative() {
    const matrixScore: WeightedMatrixScoreDto = {
      weightedMatrixId: this.weightedMatrix.id,
      iepId: this.iepId,
      recommendedScore: this.score,
      actualScore: null,
      weightedMatrixLevelId: this.formGroup.get('level').value,
      comments: this.formGroup.get('comments').value,
      hasSpecialCircumstance: this.hasSpecialCircumstance,
    };

    this.weightedMatrixService.createWeightedMatrixScore(this.caseSummary.id, matrixScore).subscribe((val) => {
      setTimeout(() => {
        this.historyChange.emit();
      }, 300);

      this.toggleFields();
      this.showChangeWeighting = true;
    });
  }

  changeWeighting() {
    const matrixScore: WeightedMatrixScoreDto = {
      weightedMatrixId: this.weightedMatrix.id,
      iepId: this.iepId,
      recommendedScore: null,
      actualScore: null,
      weightedMatrixLevelId: this.formGroup.get('level').value,
      comments: this.formGroup.get('comments').value,
      hasSpecialCircumstance: this.hasSpecialCircumstance,
    };

    this.weightedMatrixService.createWeightedMatrixScore(this.caseSummary.id, matrixScore).subscribe((val) => {
      setTimeout(() => {
        this.historyChange.emit();
      }, 300);

      this.toggleFields('weighting');
      this.showChangeWeighting = true;
    });
  }
}
