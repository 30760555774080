<h1>Templates</h1>
<mat-table [dataSource]="surveyTemplates" class="mb-5">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Name </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="language">
    <mat-header-cell *matHeaderCellDef>Language</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.language }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-menu-item (click)="getSurveyLink(element.id)" title="Create">
        <mat-icon>add</mat-icon>
        <span>Create</span>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let element; columns: displayedColumns"></mat-row>
</mat-table>

<h1>Created Surveys</h1>
<mat-table [dataSource]="createdSurveys" class="mb-5">
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef>Id </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="survey">
    <mat-header-cell *matHeaderCellDef>Template</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.templateName }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="sharableLink">
    <mat-header-cell *matHeaderCellDef>Code</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.shareableLink }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.status }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-menu-item (click)="getSurveyLink(element.id)" title="View">
        <mat-icon>add</mat-icon>
        <span>View</span>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
  <mat-row *matRowDef="let element; columns: displayedColumns2"></mat-row>
</mat-table>
