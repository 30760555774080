import { Injectable } from '@angular/core';
import { AchieveConfigService } from '../../services/achieve-config-service/achieve-config.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(private achieveConfigService: AchieveConfigService) {}

  featureOff(featureName: string): boolean {
    if (this.achieveConfigService.settings.featureFlags.hasOwnProperty(featureName)) {
      return !this.achieveConfigService.settings.featureFlags[featureName];
    }
    return true; // if feature not found, default to turned off
  }

  featureOn(featureName: string) {
    return !this.featureOff(featureName);
  }
}
