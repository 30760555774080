<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  {{ rename ? 'Rename Team' : 'New Team' }}
</h3>

<div mat-dialog-content class="py-2">
  <mat-card>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field class="w-100">
          <mat-label>{{ rename ? 'Rename Team' : 'New Team Name' }}</mat-label>
          <input
            matInput
            attr.aria-label="{{ rename ? 'Rename Team' : 'New Team Name' }}"
            placeholder="Ex. Team 1"
            [(ngModel)]="teamName"
          />
        </mat-form-field>
      </div>
    </div>
  </mat-card>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button aria-label="Cancel Button" (click)="onNoClick()" tabindex="0">Cancel</button>
  <button
    mat-raised-button
    attr.aria-label="{{ rename ? 'Rename' : 'Create' }}"
    color="primary"
    [mat-dialog-close]="teamName"
    [disabled]="!teamName"
    tabindex="0"
  >
    {{ rename ? 'Rename' : 'Create' }}
  </button>
</div>
