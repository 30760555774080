import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { CaseSummary } from 'src/app/shared/models/case';
import { BooleanYesNoPipe } from 'src/app/shared/pipes/boolean-yesno.pipe';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { ESYGoalType, IepESYGoal } from '../../../../esy';
import { ESYService } from '../../../../esy-service';

@Component({
  selector: 'app-esy-view-goals',
  templateUrl: './esy-view-goals.component.html',
  styleUrls: ['./esy-view-goals.component.scss'],
})
export class EsyViewGoalsComponent implements OnInit {
  shortDateFormat = shortDateFormat;
  goals: IepESYGoal[];
  goalType = ESYGoalType;
  case: CaseSummary;
  iepId: string;

  constructor(private route: ActivatedRoute, private caseService: CaseService, private iepEsyService: ESYService) {}

  ngOnInit(): void {
    const caseId = this.route.parent.snapshot.paramMap.get('caseId');
    this.iepId = this.route.parent.snapshot.paramMap.get('iepId');
    this.caseService.getCaseSummary(caseId).subscribe(
      (res) => (this.case = res),
      (err) => console.error(err)
    );

    this.iepEsyService.getESYGoals(this.iepId, null).subscribe((res) => {
      this.goals = res;
    });
  }
}
