import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { QuantifiableDataService } from '../../shared/components/quantifiable-data/quantifiable-data.service';
import { shortDateFormat } from '../../shared/dateTimeHelpers';
import { KeyValuePair } from '../../shared/models/key-value-pair';
import { UserService } from '../../shared/services/user/user.service';
import { IepGoal } from '../models/iep';
import { GoalAreaService } from './goalarea.service';

@Injectable()
export class GoalPlanService {
  standardsOfComparisonOptions: Promise<Array<KeyValuePair>>;
  goalAreaOptions: Promise<Array<KeyValuePair>> = Promise.resolve([]);

  constructor(
    private readonly goalAreaService: GoalAreaService,
    private readonly userService: UserService,
    private readonly quantifiableService: QuantifiableDataService
  ) {
    this.standardsOfComparisonOptions = this.quantifiableService
      .getStandardsOfComparison()
      .pipe(map((collection) => collection.map((x) => new KeyValuePair(x.id, x.label))))
      .toPromise();

    this.goalAreaOptions = this.goalAreaService
      .getAllGoalAreas()
      .pipe(map((collection) => collection.map((g) => new KeyValuePair(g.id, g.label))))
      .toPromise();
  }

  /// ToDo: The following method is duplicated in these 3 places:
  /// * IepGoalsComponent.getModalData()
  /// * IepDetailsGoalsComponent.getModalData()
  /// * GoalPlanService.getModalData()
  public async getModalData(goal: any, attendingDistrictId: string, datePipe: DatePipe): Promise<any> {
    const isSecondary = (arr): any[] => arr.filter((x) => x.secondary);
    const notSecondary = (arr): any[] => arr.filter((x) => !x.secondary);
    const sortScale = (a: { rating: number }, b: { rating: number }) => {
      if (a && b) {
        if (a.rating > b.rating) return 1;
        if (a.rating < b.rating) return -1;
      }
      return 0;
    };

    const modalData: any = {
      goalArea: await this.getGoalAreas(goal),
      currentLevelDescription: goal.currentLevelDescription,
      standardOfComparison: goal.standardOfComparisonOther
        ? goal.standardOfComparisonOther
        : await this.getStandardOfComparisonLabel(goal.standardOfComparison),
      descriptionOfCondition: goal.conditionIndividualWillPerform,
      nickname: goal.goalNickName,
      primaryMilestone: [
        {
          firstMeasurement: {
            baseline: goal.primaryQuantifiableData.primaryMeasurement.baseline,
            dataPoints: goal.primaryQuantifiableData?.primaryMeasurement?.dataPoints?.map((dp, index) => ({
              name: `Data Point ${index + 1}:`,
              value: [
                {
                  name: 'Score',
                  value: dp.score,
                  nested: true,
                },
                {
                  name: 'Date',
                  value: datePipe.transform(dp.date, shortDateFormat),
                },
              ],
            })),
            unitOfMeasurement: goal.primaryQuantifiableData.primaryMeasurement.unitOfMeasurement,
            learnersGoalTarget: goal.primaryQuantifiableData.primaryMeasurement.learnersGoalTarget,
            tieredMilestones: notSecondary(goal.primaryQuantifiableData.tieredMilestones)
              ?.sort((a, b) => {
                if (a.endDate < b.endDate) {
                  return -1;
                }
                if (a.endDate > b.endDate) {
                  return 1;
                }
              })
              .map((tm) => ({
                name: `Tiered Milestone Number: ${tm.number}:`,
                value: [
                  {
                    name: 'Target',
                    value: tm.target,
                  },
                  {
                    name: 'End Date',
                    value: datePipe.transform(tm.endDate, shortDateFormat),
                  },
                ],
              })),
            scale: notSecondary(goal.primaryQuantifiableData.scaleRatings)
              ?.sort(sortScale)
              ?.map((s) => ({
                name: 'Scale:',
                value: [
                  {
                    name: 'Rating',
                    value: s.rating,
                  },
                  {
                    name: 'Description',
                    value: s.description,
                  },
                ],
              })),
          },
          secondMeasurement: {
            baseline: goal.primaryQuantifiableData.secondaryMeasurement.baseline,
            dataPoints: goal.primaryQuantifiableData?.secondaryMeasurement?.dataPoints?.map((dp, index) => ({
              name: `Data Point ${index + 1}:`,
              value: [
                {
                  name: 'Score',
                  value: dp.score,
                },
                {
                  name: 'Date',
                  value: datePipe.transform(dp.date, shortDateFormat),
                },
              ],
            })),
            unitOfMeasurement: goal.primaryQuantifiableData.secondaryMeasurement.unitOfMeasurement,
            learnersGoalsTarget: goal.primaryQuantifiableData.secondaryMeasurement.learnersGoalTarget,
            tieredMilestones: isSecondary(goal.primaryQuantifiableData.tieredMilestones)?.map((tm) => ({
              name: `Tiered Milestone Number: ${tm.number}:`,
              value: [
                {
                  name: 'Target',
                  value: tm.target,
                },
                {
                  name: 'End Date',
                  value: datePipe.transform(tm.endDate, shortDateFormat),
                },
              ],
            })),
            scale: isSecondary(goal.primaryQuantifiableData.scaleRatings)
              ?.sort(sortScale)
              ?.map((s) => ({
                name: 'Scale:',
                value: [
                  {
                    name: 'Rating',
                    value: s.rating,
                  },
                  {
                    name: 'Description',
                    value: s.description,
                  },
                ],
              })),
          },
        },
      ],
      additionalMilestones: goal.additionalQuantifiableData?.map((am) => ({
        name: `Milestone Number ${am.milestoneNumber}`,
        status: am.status,
        description: am.description,
        firstMeasurement: {
          baseline: am.primaryMeasurement.baseline,
          dataPoints: am?.primaryMeasurement?.dataPoints?.map((dp, index) => ({
            name: `Data Point ${index + 1}:`,
            value: [
              {
                name: 'Score',
                value: dp.score,
              },
              {
                name: 'Date',
                value: datePipe.transform(dp.date, shortDateFormat),
              },
            ],
          })),
          unitOfMeasurement: am.primaryMeasurement.unitOfMeasurement,
          learnersGoalTarget: am.primaryMeasurement.learnersGoalTarget,
          tieredMilestones: notSecondary(am.tieredMilestones)?.map((tm) => ({
            name: `Tiered Milestone Number: ${tm.number}:`,
            value: [
              {
                name: 'Target',
                value: tm.target,
              },
              {
                name: 'End Date',
                value: datePipe.transform(tm.endDate, shortDateFormat),
              },
            ],
          })),
          scale: notSecondary(am.scaleRatings)
            ?.sort(sortScale)
            ?.map((s) => ({
              name: 'Scale:',
              value: [
                {
                  name: 'Rating',
                  value: s.rating,
                },
                {
                  name: 'Description',
                  value: s.description,
                },
              ],
            })),
        },
        secondMeasurement: {
          baseline: am.secondaryMeasurement.baseline,
          dataPoints: am?.secondaryMeasurement?.dataPoints?.map((dp, index) => ({
            name: `Data Point ${index + 1}:`,
            value: [
              {
                name: 'Score',
                value: dp.score,
              },
              {
                name: 'Date',
                value: datePipe.transform(dp.date, shortDateFormat),
              },
            ],
          })),
          unitOfMeasurement: am.secondaryMeasurement.unitOfMeasurement,
          learnersGoalTarget: am.secondaryMeasurement.learnersGoalTarget,
          tieredMilestones: isSecondary(am.tieredMilestones)?.map((tm) => ({
            name: `Tiered Milestone Number: ${tm.number}:`,
            value: [
              {
                name: 'Target',
                value: tm.target,
              },
              {
                name: 'End Date',
                value: datePipe.transform(tm.endDate, shortDateFormat),
              },
            ],
          })),
          scale: isSecondary(am.scaleRatings)
            ?.sort(sortScale)
            ?.map((s) => ({
              name: 'Scale:',
              value: [
                {
                  name: 'Rating',
                  value: s.rating,
                },
                {
                  name: 'Description',
                  value: s.description,
                },
              ],
            })),
        },
      })),
      howOftenProgressMeasured:
        goal.frequencyAmount && goal.frequencyPeriod ? `${goal.frequencyAmount} time per ${goal.frequencyPeriod}` : null,
      responsibleForMonitoring: await this.getPeopleResponsibleLabels(goal.peopleMonitoringGoal, attendingDistrictId),
      collaborators: goal.collaborators,
      descriptionOfMonitoring: goal.monitoringProceduresDescription,
      howOftenReported: goal.sameRateAsPeersReported
        ? goal.sameRateFrequency && goal.sameRatePeriod
          ? `At the same rate as typical peers ${goal.sameRateFrequency} times per ${goal.sameRatePeriod}`
          : null
        : goal.differentRateFrequency && goal.differentRatePeriod
        ? `At the different rate as typical peers ${goal.differentRateFrequency} times per ${goal.differentRatePeriod}`
        : null,
      methodsToReport: this.getMethodsToReportLabel(goal.methodsToReport, goal.otherMethodToReport),
    };

    return modalData;
  }

  private async getStandardOfComparisonLabel(key: string): Promise<KeyValuePair> {
    const std = await this.standardsOfComparisonOptions;
    return std.find((x) => x.key === key);
  }

  private async getGoalAreas(goal: IepGoal): Promise<string> {
    if (goal) {
      const goalAreas: string[] = [];
      const opts = await this.goalAreaOptions;
      goal?.goalAreaIds?.forEach((gaId) => {
        goalAreas.push(opts.find((x) => x.key === gaId)?.value);
      });
      return goalAreas?.join(', ');
    }
  }

  private async getPeopleResponsibleLabels(userIds: string[], attendingDistrictId: string): Promise<string> {
    const users: string[] = [];
    const userOptions = await this.getUserOptions(attendingDistrictId);
    userIds.forEach((userId) => {
      users.push(userOptions.find((x) => x.key === userId)?.value);
    });
    return users.join(', ');
  }

  private getUserOptions(attendingDistrictId: string): Promise<Array<KeyValuePair>> {
    return this.userService
      .searchUsers({ schoolDistrictId: attendingDistrictId })
      .pipe(map((collection) => collection.map((u) => new KeyValuePair(u.id, u.fullName))))
      .toPromise();
  }

  private getMethodsToReportLabel(reportingMethods: string[] = [], otherMethod: string) {
    let modifiedMethods = reportingMethods.join(', ');
    if (modifiedMethods.includes('Other')) {
      if (modifiedMethods.includes(', Other')) {
        modifiedMethods = modifiedMethods.replace(', Other', `, ${otherMethod}`);
      } else {
        modifiedMethods = modifiedMethods.replace('Other', `${otherMethod}`);
      }
    }
    return modifiedMethods;
  }
}
