import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';

@Component({
  selector: 'app-st-strength-skill-preference',
  templateUrl: './st-strength-skill-preference.component.html',
  styleUrls: ['./st-strength-skill-preference.component.scss'],
})
export class StStrengthSkillPreferenceComponent implements OnInit {
  strengthSkillDisplayedColumns = ['strengthSkill', 'llwTags', 'iepTags', 'dataSource'];
  strengthSkillDataSource = new MatTableDataSource([
    {
      strengthSkill: 'Some Strength/Skill',
      llwTags: '',
      iepTags: '',
      dataSource: '',
    },
  ]);

  preferenceInterestDisplayedColumns = ['preferenceInterest', 'llwTags', 'iepTags', 'dataSource'];
  preferenceInterestDataSource = new MatTableDataSource([
    {
      preferenceInterest: 'Robert is most engaged in classroom activities such as',
      llwTags: '',
      iepTags: '',
      dataSource: '',
    },
  ]);

  dataSourceOptions: KeyValuePair[] = [new KeyValuePair('1', 'Transition Assessment 1'), new KeyValuePair('2', 'Transition Assessment 2')];

  constructor() {}

  ngOnInit(): void {}
}
