<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Edit Note</h3>
<mat-dialog-content ngForm class="py-2">
  <mat-card class="my-0">
    <form [formGroup]="formGroup">
      <app-textarea label="Note" formControlName="note" [rows]="10"></app-textarea>
    </form>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button aria-label="Delete Icon" color="primary" (click)="onClear()" tabindex="0">Remove</button>
  <button mat-raised-button aria-label="Cancel Button" (click)="onCancel()" tabindex="0">Cancel</button>
  <button
    mat-raised-button
    aria-label="Save Button"
    color="primary"
    [mat-dialog-close]="formGroup.get('note').value"
    cdkFocusInitial
    tabindex="0"
  >
    Save
  </button>
</mat-dialog-actions>
