import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import {
  IepGoalQuantifiableDatum,
  MilestoneStatus,
  QuantifiableDataPoint,
  QuantifiableDataRating,
  QuantifiableTieredMilestone,
} from 'src/app/shared/components/quantifiable-data/quantifiable-data';
import { CaseSummary } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { IepGoalHelp } from 'src/app/shared/services/help/models/iep.help';
import { BaseComponent } from '../../../../shared/components/base-component/base-component';
import { DeactivationService } from '../../../../shared/services/deactivation.service';
import { IepGoal } from '../../../models/iep';
import { IepAmendment } from '../../../models/iep-amendment';

const initializeTieredMilestone = (tieredMilestone: QuantifiableTieredMilestone, fb: FormBuilder) =>
  fb.group({
    // eslint-disable-next-line id-blacklist
    number: fb.control(tieredMilestone?.number),
    endDate: fb.control(tieredMilestone?.endDate),
    target: fb.control(tieredMilestone?.target),
    secondary: fb.control(tieredMilestone?.secondary),
  });

const initializeTieredMilestones = (tieredMilestones: QuantifiableTieredMilestone[], fb: FormBuilder) =>
  fb.array((tieredMilestones || []).map((t) => initializeTieredMilestone(t, fb)));

const initializeScale = (scale: QuantifiableDataRating, fb: FormBuilder) =>
  fb.group({
    rating: [scale?.rating, [Validators.required, Validators.min(0)]],
    description: [scale?.description, [Validators.required]],
    secondary: [scale?.secondary],
  });

const initializeScales = (scales: QuantifiableDataRating[], fb: FormBuilder) => fb.array((scales || []).map((t) => initializeScale(t, fb)));

const initializeDataPoint = (dataPoint: QuantifiableDataPoint, fb: FormBuilder) =>
  fb.group({
    score: fb.control(dataPoint?.score),
    date: fb.control(dataPoint?.date),
  });

const initializeDataPoints = (dataPoints: QuantifiableDataPoint[], fb: FormBuilder) =>
  fb.array((dataPoints || []).map((t) => initializeDataPoint(t, fb)));

@Component({
  selector: 'app-iep-quantifiable-data',
  templateUrl: './iep-quantifiable-data.component.html',
  styleUrls: ['./iep-quantifiable-data.component.scss'],
})
export class IepQuantifiableDataComponent extends BaseComponent implements OnInit, OnChanges {
  @ViewChild('quantDataForm') quantDataForm: NgForm;

  @Input() parentFormGroup: FormGroup;
  @Input() formGroup: FormGroup;
  @Input() caseSummary: CaseSummary;
  @Input() isAdditionalQuant = false;
  @Input() primaryUnitOfMeasurement: string;
  @Input() primaryTargetValue: number;
  @Input() useSameUnitAndTarget = false;
  @Input() rubricMaxCalc;
  @Input() rubricMax;
  @Input() amendmentId: string;
  @Input() amendments: IepAmendment[];
  @Input() amendingGoal: IepGoal;
  @Output() addQuantData = new EventEmitter<boolean>();

  @Input() advancedVisible = false;
  @Input() secondMeasurement = false;
  @Input() secondaryAdvancedVisible = false;

  helpSection = HelpSection;
  iepGoalHelp = IepGoalHelp;

  get priorVersionQuantData() {
    return this.priorVersion?.primaryQuantifiableData;
  }

  get priorVersionAdditionalQuantData() {
    const adltQuantData = this.formGroup.value as IepGoalQuantifiableDatum;
    return this.priorVersion?.additionalQuantifiableData.find((x) => x.id === adltQuantData.priorVersionId);
  }

  get priorVersionQuantDataMeasurement() {
    return this.priorVersion?.primaryQuantifiableData.primaryMeasurement;
  }

  get priorVersionSecondaryQuantDataMeasurement() {
    return this.priorVersion?.primaryQuantifiableData.secondaryMeasurement;
  }

  get combinedGoal() {
    return this.parentFormGroup.get('combinedGoal').value;
  }

  get hasAdditionalQuantData() {
    return (this.parentFormGroup.get('additionalQuantifiableData').value as FormArray).length > 0;
  }

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.amendments && this.amendments.length > 0) {
      const latest = this.amendments.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasAmendment() {
    return this.amendmentId && this.amendmentId !== null;
  }

  get hasPriorVersion() {
    return !!this.amendingGoal?.priorVersionId;
  }

  get priorVersion() {
    return this.amendingGoal?.priorVersion;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private helpService: HelpService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  isEditingTaskSkillMilestone = false;
  isManualBaseline = true;
  milestoneStatuses: KeyValuePair[] = [
    new KeyValuePair(MilestoneStatus.inactive, 'Pending/Inactive'),
    new KeyValuePair(MilestoneStatus.active, 'Active'),
    new KeyValuePair(MilestoneStatus.met, 'Met'),
  ];

  controlWord = 'controls';
  maxTargetValue: number;
  maxSecondaryTargetValue: number;

  get firstNamePossessive() {
    if (!this.caseSummary.learner.firstName) {
      return;
    }
    const lastLetter = this.caseSummary.learner.firstName.slice(-1);
    return lastLetter === 's' ? `${this.caseSummary.learner.firstName}'` : `${this.caseSummary.learner.firstName}'s`;
  }

  get scaleRatings() {
    return this.formGroup.get('scaleRatings').value;
  }

  set scaleRatings(val: QuantifiableDataRating[]) {
    this.formGroup.setControl('scaleRatings', initializeScales(val, this.fb));
    this.updateMaxTargetValue(val);
  }

  get tieredMilestones() {
    return this.formGroup.get('tieredMilestones').value;
  }

  set tieredMilestones(val: QuantifiableTieredMilestone[]) {
    this.formGroup.setControl('tieredMilestones', initializeTieredMilestones(val, this.fb));
  }

  get primaryMeasurement() {
    return this.formGroup.get('primaryMeasurement') as FormGroup;
  }

  get secondaryMeasurement() {
    return this.formGroup.get('secondaryMeasurement') as FormGroup;
  }

  ngOnInit(): void {
    this.cd.detectChanges();
    if (this.isAdditionalQuant) {
      this.openFields();
    }
  }

  toggleCombinedObjectives(): void {
    const callback = () => {
      this.parentFormGroup.get('combinedGoal').patchValue(!this.parentFormGroup.get('combinedGoal').value);
      this.cd.detectChanges();
      this.parentFormGroup.updateValueAndValidity();
      this.formGroup.updateValueAndValidity();
    };
    if (this.combinedGoal === false) {
      this.isEditingTaskSkillMilestone = false;
      this.resetUnitOfMeasurement();
      callback();
    } else {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        width: '450px',
        data: {
          subQuestion:
            'Clicking Yes will convert all combined objectives to regular goal objectives.  You may need to make scale adjustments for each. Are you sure?',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          callback();
        }
      });
    }
  }

  private resetUnitOfMeasurement(): void {
    this.formGroup.get('primaryMeasurement.unitOfMeasurement').setValue(null);
  }

  updateMaxTargetValue(val: QuantifiableDataRating[]) {
    this.maxTargetValue = Math.max(...val.filter((x) => !x.secondary).map((x) => x.rating));
    this.maxTargetValue =
      this.maxTargetValue === Number.POSITIVE_INFINITY || this.maxTargetValue === Number.NEGATIVE_INFINITY ? null : this.maxTargetValue;

    this.maxSecondaryTargetValue = Math.max(...val.filter((x) => x.secondary).map((x) => x.rating));
    this.maxSecondaryTargetValue =
      this.maxSecondaryTargetValue === Number.POSITIVE_INFINITY || this.maxSecondaryTargetValue === Number.NEGATIVE_INFINITY
        ? null
        : this.maxSecondaryTargetValue;

    if (this.maxTargetValue === null) {
      this.formGroup.get('primaryMeasurement.learnersGoalTarget').clearValidators();
    } else {
      this.formGroup.get('primaryMeasurement.learnersGoalTarget').setValidators([Validators.max(this.maxTargetValue)]);
    }

    if (this.maxSecondaryTargetValue === null) {
      this.formGroup.get('secondaryMeasurement.learnersGoalTarget').clearValidators();
    } else {
      this.formGroup.get('secondaryMeasurement.learnersGoalTarget').setValidators([Validators.max(this.maxSecondaryTargetValue)]);
    }
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.submitted?.currentValue && !changes.submitted.previousValue) {
      this.formGroup.markAllAsTouched();
      this.formGroup.updateValueAndValidity();
    }

    if (changes.formGroup?.currentValue !== changes.formGroup?.previousValue) {
      this.cd.detectChanges();
    }
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm) {
    e.preventDefault();

    const dictionary = this.helpService.getIepGoalDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  addSkillsMilestone() {
    this.addQuantData.emit();
  }

  onTieredMilestoneCheckbox(isSecondary: boolean) {
    const originalTieredMilestoneSelected = this.formGroup.controls.tieredMilestoneSelected.value;
    const originalSecondaryTieredMilestoneSelectedValue = this.formGroup.controls.secondaryTieredMilestoneSelected.value;

    if (isSecondary) {
      this.formGroup.controls.secondaryTieredMilestoneSelected.setValue(
        this.formGroup.controls.secondaryTieredMilestoneSelected.value ? false : true,
        {
          emit: false,
        }
      );
    } else {
      this.formGroup.controls.tieredMilestoneSelected.setValue(this.formGroup.controls.tieredMilestoneSelected.value ? false : true, {
        emit: false,
      });
    }

    const milestoneSelectedControl = this.formGroup.controls.tieredMilestoneSelected;
    const secondaryMilestoneSelectedControl = this.formGroup.controls.secondaryTieredMilestoneSelected;

    const mileStoneLength = this.tieredMilestones.filter((x) => x.secondary === isSecondary)?.length;
    if ((isSecondary ? originalSecondaryTieredMilestoneSelectedValue : originalTieredMilestoneSelected) && mileStoneLength > 0) {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        width: '450px',
        data: {
          subQuestion: 'Clicking Yes will remove all of the milestones.',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.tieredMilestones = [];
        } else {
          isSecondary
            ? secondaryMilestoneSelectedControl.setValue(!secondaryMilestoneSelectedControl.value)
            : milestoneSelectedControl.setValue(!milestoneSelectedControl.value);
          isSecondary ? secondaryMilestoneSelectedControl.updateValueAndValidity() : milestoneSelectedControl.updateValueAndValidity();
        }
      });
    }
  }

  onScalesCheckbox(isSecondary: boolean) {
    const originalScaleSelectedValue = this.formGroup.controls.scaleSelected.value;
    const originalSecondaryScalesSelectedValue = this.formGroup.controls.secondaryScalesSelected.value;

    if (isSecondary) {
      this.formGroup.controls.secondaryScalesSelected.setValue(this.formGroup.controls.secondaryScalesSelected.value ? false : true, {
        emit: false,
      });
    } else {
      this.formGroup.controls.scaleSelected.setValue(this.formGroup.controls.scaleSelected.value ? false : true, {
        emit: false,
      });
    }

    const scaleSelectedControl = this.formGroup.controls.scaleSelected;
    const secondaryScaleSelectedControl = this.formGroup.controls.secondaryScalesSelected;
    const scaleLength = this.scaleRatings.filter((x) => x.secondary === isSecondary)?.length;

    if ((isSecondary ? originalSecondaryScalesSelectedValue : originalScaleSelectedValue) && scaleLength > 0) {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        width: '450px',
        data: {
          subQuestion: 'Clicking Yes will remove all scale values.',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.scaleRatings = [];
        } else {
          isSecondary
            ? secondaryScaleSelectedControl.setValue(!secondaryScaleSelectedControl.value)
            : scaleSelectedControl.setValue(!scaleSelectedControl.value);
          isSecondary ? secondaryScaleSelectedControl.updateValueAndValidity() : scaleSelectedControl.updateValueAndValidity();
        }
      });
    }
  }

  onSecondMeasurementClick() {
    const secondaryMeasurementGroup = this.formGroup.get('secondaryMeasurement') as FormGroup;
    if (this.secondMeasurement && this.secondMeasurementHasValue(secondaryMeasurementGroup)) {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        width: '450px',
        data: {
          subQuestion: 'Clicking Yes will remove all of the second measurement datum.',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          // Remove all data from second measurement
          this.scaleRatings = this.scaleRatings.filter((x) => x.secondary !== true);
          this.tieredMilestones = this.tieredMilestones.filter((x) => x.secondary !== true);
          this.formGroup.get('secondaryTieredMilestoneSelected').setValue(null);
          this.formGroup.get('secondaryScalesSelected').setValue(null);
          secondaryMeasurementGroup.get('baseline').setValue(null);
          secondaryMeasurementGroup.get('baseline').setValidators(null);
          secondaryMeasurementGroup.get('learnersGoalTarget').setValue(null);
          secondaryMeasurementGroup.get('learnersGoalTarget').setValidators(null);
          secondaryMeasurementGroup.get('unitOfMeasurement').setValue(null);
          secondaryMeasurementGroup.get('unitOfMeasurement').setValidators(null);
          secondaryMeasurementGroup.setControl('dataPoints', initializeDataPoints(null, this.fb));

          this.secondMeasurement = !this.secondMeasurement;
          this.formGroup.markAsUntouched();
          this.formGroup.updateValueAndValidity();
          this.cd.detectChanges();
        }
      });
    } else {
      this.secondMeasurement = !this.secondMeasurement;
      secondaryMeasurementGroup.get('baseline').setValidators([Validators.required]);
      secondaryMeasurementGroup.get('learnersGoalTarget').setValidators([Validators.required]);
      secondaryMeasurementGroup.get('unitOfMeasurement').setValidators([Validators.required]);
    }
    this.cd.detectChanges();
  }

  secondMeasurementHasValue(secondaryMeasurementGroup: FormGroup) {
    return (
      this.scaleRatings?.length > 0 ||
      this.tieredMilestones?.length > 0 ||
      secondaryMeasurementGroup.get('dataPoints').value?.length > 0 ||
      secondaryMeasurementGroup.get('baseline').value ||
      secondaryMeasurementGroup.get('learnersGoalTarget').value ||
      secondaryMeasurementGroup.get('unitOfMeasurement').value
    );
  }

  resetQuantData() {
    this.formGroup.reset({ emitEvent: false });
    const primaryDataPoints = this.formGroup.controls.primaryMeasurement[this.controlWord].dataPoints as FormArray;
    const secondaryDataPoints = this.formGroup.controls.secondaryMeasurement[this.controlWord].dataPoints as FormArray;
    primaryDataPoints.clear();
    secondaryDataPoints.clear();
    this.formGroup.markAsPristine();
    this.formGroup.updateValueAndValidity();
    this.secondMeasurement = false;
    this.advancedVisible = false;
    this.secondaryAdvancedVisible = false;
  }

  openFields() {
    const primMeasDPs = this.formGroup.controls.primaryMeasurement[this.controlWord].dataPoints.controls;
    const secMeasDPs = this.formGroup.controls.secondaryMeasurement[this.controlWord].dataPoints.controls;

    if (primMeasDPs?.length > 0) {
      this.advancedVisible = true;
    }
    if (secMeasDPs?.length > 0) {
      this.advancedVisible = true;
      this.secondMeasurement = true;
    }
    if (this.tieredMilestones.filter((x) => !x.secondary).length > 0) {
      this.formGroup.controls.tieredMilestoneSelected.setValue(true, {
        emitValue: false,
      });
      this.advancedVisible = true;
    }
    if (this.tieredMilestones.filter((x) => x.secondary).length > 0) {
      this.formGroup.controls.secondaryTieredMilestoneSelected.setValue(true, {
        emitValue: false,
      });
      this.advancedVisible = true;
      this.secondaryAdvancedVisible = true;
      this.secondMeasurement = true;
    }
    if (this.scaleRatings.filter((x) => !x.secondary).length > 0) {
      this.formGroup.controls.scaleSelected.setValue(true, {
        emitValue: false,
      });
      this.advancedVisible = true;
    }
    if (this.scaleRatings.filter((x) => x.secondary).length > 0) {
      this.formGroup.controls.secondaryScalesSelected.setValue(true, {
        emitValue: false,
      });
      this.advancedVisible = true;
      this.secondaryAdvancedVisible = true;
      this.secondMeasurement = true;
    }
    this.isEditingTaskSkillMilestone = true;
  }
}
