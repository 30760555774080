import { Help, HelpSection } from '../help';
import { BipImplementationHelp, BipStrategiesHelp } from '../models/bip.help';

export function getBipDictionary(): Help[] {
  return [].concat(getBipImplementationDictionary(), getBipStrategiesDictionary());
}

export function getBipImplementationDictionary(): Help[] {
  return [
    {
      title: HelpSection.BipImplementation,
      dictionary: [
        {
          label: BipImplementationHelp.DeescalationStrategies,
          descriptor:
            'Are the strategies team members will use to defuse behaviors that are dangerous or unsafe or have the potential to escalate to levels that pose a safety risk to the student or others.',
        },
        {
          label: BipImplementationHelp.SafetyMonitoring,
          descriptor:
            'Involves a description of how teams will provide supervision and frequent checks of the student’s behavior to ensure the safety of the student and others following escalations in dangerous and unsafe behaviors.',
        },
        {
          label: BipImplementationHelp.StaffSupports,
          descriptor:
            'Describe the actions and practices that will be implemented to support the staff who are responsible for implementing this safety plan before, during, and after escalations in a student’s unsafe or dangerous behaviors.',
        },
      ],
    },
  ];
}

export function getBipStrategiesDictionary(): Help[] {
  return [
    {
      title: HelpSection.BipStrategies,
      dictionary: [
        {
          label: BipStrategiesHelp.StrategyArea,
          descriptor:
            'Prevention (Antecedent) Strategies are strategies that prevent or decrease the likelihood of the occurrence of a behavior of concern. Teaching Strategies are specially designed instructional methods used to teach a new, alternative/replacement behavior or skill. Responsive (Consequence) Strategies include how teams will respond to the behavior of concern.',
        },
        {
          label: BipStrategiesHelp.GenPlan,
          descriptor:
            'A generalization plan describes how the entire plan or specific components within the plan will be implemented across new settings, people, activities, or routines.',
        },
        {
          label: BipStrategiesHelp.FadingPlan,
          descriptor:
            'A Fading Plan generally involves the gradual removal of supports required to complete or participate in a plan or the components of a plan until the student can do so with some level of independence. Supports can include those provided by another person (e.g. Instructional prompts), added materials (e.g., visuals, manipulatives, adaptive equipment), the amount of reinforcement provided for completing or participating in the plan, or the criteria of mastery required before receiving reinforcement. Fading Plans describe (a) the gradual reduction in the level of support needed to implement the entire plan or components of the plan and (b) the rules (criteria of mastery) required for teams to move to the next level of support. Fading Plans may also include the criteria for meeting behavior goals and discontinuation of some or all components of the Behavior Intervention Plan.',
        },
      ],
    },
  ];
}
