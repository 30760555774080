import { LetterModalDocumentDto, AdditionalInformationDto } from '../../models/letter-document';
import { ReportingService } from '../../services/reporting/reporting.service';
import { StringSizes } from '../../components/form/constants/constants';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-additional-information-modal',
  templateUrl: './additional-information-modal.component.html',
  styleUrls: ['./additional-information-modal.component.scss'],
})
export class AdditionalInformationModalComponent implements OnDestroy {
  private readonly commonValidator = { validators: [Validators.required] };
  private subscriptions = new Subscription();

  headline = 'Collection of Additional Information by AEA staff';
  areasLabel = 'Additional Information Areas';
  dateProvidedLabel = 'Date Provided to Parents';

  dismissLabel = 'Cancel';
  submitLabel = 'Complete';

  formGroup = this.formBuilder.group({
    learnerId: [this.data.id],
    areas: [null, this.commonValidator],
    dateProvided: [null, this.commonValidator],
  });

  constructor(
    private formBuilder: FormBuilder,
    private reportingService: ReportingService,
    private dialogRef: MatDialogRef<AdditionalInformationModalComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: LetterModalDocumentDto
  ) {}

  get stringSizes() {
    return StringSizes;
  }

  get isDisabled(): boolean {
    return this.formGroup.status === 'INVALID' || this.formGroup.status === 'PENDING';
  }

  get isPending(): boolean {
    return this.formGroup.status === 'PENDING';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onComplete() {
    if (this.formGroup.invalid) return;
    this.formGroup.markAsPending();
    const model = this.formGroup.value as AdditionalInformationDto;

    this.subscriptions.add(
      this.reportingService.createAdditionalInformationLetter(model).subscribe((documentId: string) => {
        this.data.onSuccess(documentId);
        this.dialogRef.close();
      })
    );
  }
}
