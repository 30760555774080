<button
  mat-icon-button
  aria-label="Sentence Starter Icon"
  class="text-lg"
  color="primary"
  (click)="onOpenSentenceStarters()"
  [ngClass]="{ spacing: !isSuffix }"
  tabindex="0"
  type="button"
  *ngIf="!isPortalUser"
>
  <i class="fas fa-comments" aria-label="Sentence Starter Icon"></i>
</button>
