<div class="charts">
  <div class="charts__pie">
    <canvas
      baseChart
      [data]="pieChart.data"
      [labels]="pieChart.labels"
      [chartType]="'pie'"
      [colors]="pieChart.colors"
      [legend]="pieChart.legend"
      [options]="pieChart.options"
      role="img"
      aria-label="Label"
    ></canvas>
  </div>
</div>
