import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumDesc',
})
export class EnumDescPipe implements PipeTransform {
  transform(val: any, type?: any): any {
    if (!val) {
      return '';
    }

    if (!!type && type.hasOwnProperty(`${val}_desc`)) {
      return type[`${val}_desc`];
    }

    return val;
  }
}
