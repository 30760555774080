import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FileDocument } from 'src/app/shared/models/file-document';
import { DocumentService } from 'src/app/shared/services/document/document.service';
import { MemoryStorageService } from 'src/app/shared/services/memory-storage/memory-storage.service';

@Component({
  selector: 'app-select-documentation',
  templateUrl: './select-documentation-modal.component.html',
  styleUrls: ['./select-documentation-modal.component.scss'],
})
export class SelectDocumentationModalComponent implements OnInit {
  documents: FileDocument[] = [];
  displayedColumns: string[] = ['select', 'title', 'fileName'];
  dataSource = new MatTableDataSource<FileDocument>();
  selection = new SelectionModel<FileDocument>(true, []);

  constructor(
    private storage: MemoryStorageService,
    private documentService: DocumentService,
    @Inject(MAT_DIALOG_DATA)
    public data: { form: FormArray; extraDocuments?: FileDocument[] }
  ) {
    data?.form?.controls?.forEach((document) => this.documents.push(document.value));
  }

  ngOnInit(): void {
    const currentEvaluation = this.storage.getKey('currentEvaluation', true);
    this.documentService.getAll(currentEvaluation?.caseId).subscribe((res) => {
      this.dataSource.data = this.data.extraDocuments ? [...res, ...this.data.extraDocuments] : res;
      this.documents.forEach((document) => {
        const doc = this.dataSource.data.find((x) => x.id === document.id);
        if (doc) {
          this.selection.select(doc);
        }
      });
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: FileDocument): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.title}`;
  }
}
