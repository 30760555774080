import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IvrsMemberRegistrationService {
  private baseUrl = 'api/ivrs/member-registration';

  constructor(private http: HttpClient) {}

  getStatus = (value) => this.http.get(`${this.baseUrl}/${value}`);

  addPassword = (token: string, password: string) => this.http.put(`${this.baseUrl}`, { token: token, password: password });
}
