import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'schoolYear',
})
export class SchoolYearPipe implements PipeTransform {
  transform(val: any): any {
    if (!val) {
      return '';
    }

    return `${val - 1}-${val}`;
  }
}
