import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';

@Component({
  selector: 'app-st-course-study',
  templateUrl: './st-course-study.component.html',
  styleUrls: ['./st-course-study.component.scss'],
})
export class StCourseStudyComponent implements OnInit {
  formGroup = new FormGroup({
    workingTowards: new FormControl('', Validators.required),
    creditsNeeded: new FormControl(''),
    creditsEarned: new FormControl(''),
    comments: new FormControl(''),
    changesAdditions: new FormControl(''),
    targetDate: new FormControl(''),
  });

  workingTowardsOptions: KeyValuePair[] = [new KeyValuePair('diploma', 'Diploma'), new KeyValuePair('certificate', 'Certificate')];

  displayedColumns = ['courseActivity', 'projectedDate', 'llw', 'completedDiscontinued'];
  dataSource = new MatTableDataSource([
    {
      courseActivity: 'Welding Technology',
      projectedDate: '10th Grade',
      llw: '',
      completedDiscontinued: 'Completed 12/15/2019',
    },
  ]);

  constructor() {}

  ngOnInit(): void {}
}
