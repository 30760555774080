export interface GoalArea {
  id: string;
  domain: string;
  label: string;
  order?: number;
}

export enum GoalAreaDomain {
  Academic = 'Academic',
  Communication = 'Communication',
  SocialEmotionalBehavior = 'Social Emotional Behavior',
  Physical = 'Physical',
  Health = 'Health',
  HearingVision = 'Hearing/Vision',
  AdaptiveBehavior = 'Adaptive Behavior',
  CenturySkills = '21st Century Skills',
}
