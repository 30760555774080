import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BankableFieldsComponent } from 'src/app/shared/components/bankable-fields/bankable-fields.component';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { LoggerService } from '../../../services/logger/logger.service';
import { BaseWrapperComponent } from '../base-wrapper.component';
import { StringSizes } from '../constants/constants';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
})
export class TextFieldComponent extends BaseWrapperComponent implements OnInit, OnChanges {
  @Input()
  inputType: 'text' | 'password' = 'text';
  @Input() maxLength: number = StringSizes.small;
  @Input() noMaxLength = false;
  @Input() useInputGroup = false;
  @Input() min: number;
  @Input() max: number;
  @Input() rows: number;
  @Input() hint: string;
  @Input() showAsterisk = false;
  @Input() hideAsterisk = false;
  @Input() type = 'text';
  @Input() isBankableField = false;
  @Input() bankableFieldId: string;
  @Input() bankableFieldChildFname: string;
  // todo this component is using value accessor. We SHOULD be able to use the native blur but I couldn't get it to work
  @Output() blurMe = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog,
    logger: LoggerService,
    @Self()
    @Optional()
    public ngControl: NgControl,
    private learnerService: LearnerService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(logger, ngControl, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.noMaxLength) {
      return;
    }
    this.control.setValidators(
      this.control.validator ? [this.control.validator, Validators.maxLength(this.maxLength)] : Validators.maxLength(this.maxLength)
    );
  }

  internalBlur(event: any) {
    if (this.blurMe) {
      this.blurMe.emit(true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  openBankableFieldOptions() {
    if (this.bankableFieldId) {
      const dialogRef = this.dialog.open(BankableFieldsComponent, {
        data: {
          inputText: this.ngControl.value,
          fieldId: this.bankableFieldId,
          childFirstName: this.bankableFieldChildFname ?? this.learnerService.learnerSummary?.firstName,
        },
        width: '748px',
      });

      return dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.ngControl.control.setValue(result);
        }
      });
    }
  }
}
