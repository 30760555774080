import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';

import { AuthService } from 'src/app/auth/auth.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ProceduralSafeguardsRead } from '../../shared/components/procedural-safeguards/procedural-safeguards';
import { CaseSummary, IntakeType } from '../../shared/models/case';
import { CaseService } from '../../shared/services/case/case.service';
import { LearnerService } from '../../shared/services/learner/learner.service';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
})
export class DocumentationComponent implements OnInit {
  @ViewChild('accordionPartB') accordionPartB: MatExpansionPanel;
  @ViewChild('accordionPartC') accordionPartC: MatExpansionPanel;
  learnerId: string;
  cases: Array<CaseSummary>;
  shortDateFormat = shortDateFormat;
  learnerHasWorkableCase = false;
  isCaseOwner = false;

  get partCCase(): CaseSummary {
    return this.cases?.find((x) => x.intakeType === IntakeType.PartC || x.intakeType === IntakeType.PartCLate);
  }

  get partBCase(): CaseSummary {
    return this.cases?.find((x) => x.intakeType === IntakeType.PartB);
  }

  get isPortalUser() {
    return this.authService?.isPortalUser;
  }

  public mostRecentPartCProceduralSafeguard: ProceduralSafeguardsRead;
  public mostRecentPartBProceduralSafeguard: ProceduralSafeguardsRead;

  @Output() consentDocumentUploaded = new EventEmitter<void>();

  constructor(
    public authService: AuthService,
    private caseService: CaseService,
    private learnerService: LearnerService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.learnerId = this.route.parent?.snapshot.paramMap.get('learnerId');
    this.cases = await this.learnerService.getCases(this.learnerId).toPromise();
    this.learnerHasWorkableCase = this.cases.find((x) => x.isActive && x.exitFinalizedOn === null)?.id != null;
    this.isCaseOwner = this.partBCase?.caseOwnerId === this.authService.user.id || this.partCCase?.caseOwnerId === this.authService.user.id;
  }

  toggleAccordionByPsProvidedDate(accordion: MatExpansionPanel, ps: ProceduralSafeguardsRead) {
    if (dayjs(ps.providedOn).isBefore(dayjs().subtract(1, 'year'))) {
      accordion.open();
    } else {
      accordion.close();
    }
  }

  onPartCPsChanged(ps: ProceduralSafeguardsRead): void {
    this.mostRecentPartCProceduralSafeguard = ps;
    if (!ps) {
      this.accordionPartC.open();
    } else {
      this.toggleAccordionByPsProvidedDate(this.accordionPartC, ps);
    }
  }

  onPartBPsChanged(ps: ProceduralSafeguardsRead): void {
    this.mostRecentPartBProceduralSafeguard = ps;
    if (!ps) {
      // It shouldn't auto-expand when the PartC PS were provided.
      if (!this.mostRecentPartCProceduralSafeguard) this.accordionPartB.open();
    } else {
      this.toggleAccordionByPsProvidedDate(this.accordionPartB, ps);
    }
  }

  onConsentDocumentUpload() {
    this.consentDocumentUploaded.next();
  }
}
