import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/shared/models/user';

@Component({
  selector: 'app-non-achieve-member',
  templateUrl: './non-achieve-member.component.html',
  styleUrls: ['./non-achieve-member.component.scss'],
})
export class NonAchieveMemberComponent implements OnInit {
  @Output() addNonAchieveMember = new EventEmitter();

  constructor() {}

  formGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    position: new FormControl(null),
    email: new FormControl(null, [Validators.email]),
    phone: new FormControl(null),
  });

  ngOnInit(): void {}

  submit() {
    if (this.formGroup.invalid) {
      this.formGroup.markAsTouched();
      return;
    }

    const nonAchieveUser = {} as User;
    nonAchieveUser.fullName = this.formGroup.value.name;
    nonAchieveUser.jobTitle = this.formGroup.value.position;
    nonAchieveUser.email = this.formGroup.value.email;
    nonAchieveUser.phoneNumber = this.formGroup.value.phone;

    this.formGroup.reset();
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
    this.formGroup.controls.name.updateValueAndValidity();

    this.addNonAchieveMember.emit(nonAchieveUser);
  }
}
