import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { AuthService } from 'src/app/auth/auth.service';
import { CaseSummary } from 'src/app/shared/models/case';
import { NotificationService } from '../../../../../../shared/services/notification.service';
import { IepAmendmentEndItemModalComponent } from '../../../../../modals/iep-amendment-end-item-modal/iep-amendment-end-item-modal.component';
import { IepActivitySupportDto } from '../../../../../models/iep-activity-support';
import { IepAmendment } from '../../../../../models/iep-amendment';
import { IepServiceDto } from '../../../../../models/iep-service';
import { ESYGoalType, IepESYGoal } from '../../../../esy';
import { ESYService } from '../../../../esy-service';
import { ViewGoalModalComponent } from '../view-goal-modal/view-goal-modal.component';

@Component({
  selector: 'app-esy-table-display',
  templateUrl: './esy-table-display.component.html',
  styleUrls: ['./esy-table-display.component.scss'],
})
export class EsyTableDisplayComponent implements OnInit {
  @Input() goal: IepESYGoal;
  @Input() iepId: string;
  @Input() caseSummary: CaseSummary;
  @Input() viewOnly = false;
  @Input() amendments: IepAmendment[] = [];
  @Input() isEditing = false;
  @Input() isAmending = false;
  servicesDataSource = new MatTableDataSource<IepServiceDto>([]);
  supportsDataSource = new MatTableDataSource<IepActivitySupportDto>([]);
  @Output() editService = new EventEmitter<IepServiceDto>();
  @Output() editSupport = new EventEmitter<IepActivitySupportDto>();
  @Output() refreshGoals = new EventEmitter<boolean>();
  goalType = ESYGoalType;
  iepESYGoalId: string;
  services: IepServiceDto[];
  supports: IepActivitySupportDto[];

  servicesDisplayedColumns: string[] = [
    'service',
    'description',
    'projectedStartDate',
    'projectedEndDate',
    'location',
    'frequency',
    'schedule',
    'providers',
  ];

  supportsDisplayedColumns: string[] = ['activity', 'description', 'frequency', 'providers'];

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentId() {
    return this.amendment?.id;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.amendments && this.amendments.length > 0) {
      const latest = this.amendments.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasAmendment() {
    return this.amendmentId && this.amendmentId !== null;
  }

  get hasOpenAmendment() {
    return !!this.amendment && !this.amendmentIsFinalized;
  }

  get readonlyUser() {
    return this.authService?.user?.rolePermissions?.filter((x) => x.isReadOnly)?.length === this.authService?.user?.rolePermissions?.length;
  }

  constructor(
    private notificationService: NotificationService,
    private iepESYService: ESYService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.iepESYGoalId = this.goal.id;
    this.services = this.goal.iepESYServices;
    this.supports = this.goal.iepESYSupportActivities;
    if (this.viewOnly) {
      this.refresh();
    } else {
      this.servicesDisplayedColumns.unshift('actions');
      this.supportsDisplayedColumns.unshift('actions');
    }
    if (this.services) {
      this.servicesDataSource.data = this.services;
    }
    if (this.supports) {
      this.supportsDataSource.data = this.supports;
    }
  }

  anyEndDatesInPast(elements) {
    return dayjs(elements.amendmentEndDate).toDate() < dayjs().toDate() && elements.isActive;
  }

  canEnd(element) {
    return (
      this.hasOpenAmendment && !this.anyEndDatesInPast(element) && (element?.isActive || (!element?.isActive && !!element?.priorVersionId))
    );
  }

  canRemove(element) {
    return (
      this.hasOpenAmendment && !this.anyEndDatesInPast(element) && !element?.priorVersionId && element.amendmentId === this.amendmentId
    );
  }

  canUndo(element) {
    return this.hasOpenAmendment && !this.anyEndDatesInPast(element) && element?.priorVersionId && element.amendmentId === this.amendmentId;
  }

  refresh() {
    this.refreshGoals.emit(true);
  }

  getProviderNames(element: any) {
    if (!element) return null;
    if (element.otherProvider) return element.otherProviderName;
    if (element.providers?.length) return element.providers.map((x) => `${x.firstName} ${x.lastName || ''}`).join(', ');
    return null;
  }

  getScheduleOfServices(schedule) {
    return schedule ? schedule.map((x) => `${dayjs(x.date).format('MMM DD, YYYY')}  ${x.time || ''}`).join(', ') : null;
  }

  async onServiceEdit(service, amend = false) {
    if (amend && service.amendmentId === null) {
      const amendedService = await this.iepESYService
        .amendESYService(this.iepId, service.id, this.iepESYGoalId, this.amendment.id)
        .toPromise();
      this.editService.emit(amendedService);
    } else {
      this.editService.emit(service);
    }
  }

  onServiceRemove(service, isUndo = false) {
    this.notificationService.confirmation(`Are you sure you want to ${isUndo ? 'undo' : 'remove'} this item?`, () => {
      this.iepESYService.deleteESYService(this.iepId, service.id).subscribe(() => {
        this.servicesDataSource.data = this.servicesDataSource.data.filter((x) => x.id !== service.id);
        this.notificationService.success(`Service ${isUndo ? 'reverted' : 'deleted'}`);
        this.refresh();
      });
    });
  }

  onEnd(element, type) {
    this.dialog
      .open(IepAmendmentEndItemModalComponent, {
        width: '740px',
        data: {
          iepId: this.iepId,
          amendmentId: this.amendment?.id,
          type: type === 'Service' ? 'Service' : 'Activity/Support',
          model: element,
          isESY: true,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.refresh();
      });
  }

  async onSupportEdit(support, amend = false) {
    if (amend && support.amendmentId === null) {
      const amendedSupport = await this.iepESYService
        .amendESYSupport(this.iepId, support.id, this.iepESYGoalId, this.amendment.id)
        .toPromise();
      this.editSupport.emit(amendedSupport);
    } else {
      this.editSupport.emit(support);
    }
  }

  onSupportRemove(support, isUndo = false) {
    this.notificationService.confirmation(`Are you sure you want to ${isUndo ? 'undo' : 'delete'} this activity/support?`, () => {
      this.iepESYService.deleteESYSupportActivity(this.iepId, support.id).subscribe(() => {
        this.supportsDataSource.data = this.supportsDataSource.data.filter((x) => x.id !== support.id);
        this.notificationService.success(`Activity/Support ${isUndo ? 'reverted' : 'deleted'}`);
        this.refresh();
      });
    });
  }

  viewServiceOrActivity(type: string, data) {
    if (type === 'service') {
      this.dialog.open(ViewGoalModalComponent, {
        data: {
          service: data,
          title:
            data.esyGoalType === this.goalType.Goal
              ? `Goal - ${data.esyGoalConditionIndividualWillPerform}`
              : `Expectation - ${data.esyGoalExpectationType}`,
        },
      });
    }

    if (type === 'activity') {
      this.dialog.open(ViewGoalModalComponent, {
        data: {
          activity: data,
          title:
            data.esyGoalType === this.goalType.Goal
              ? `Goal - ${data.esyGoalConditionIndividualWillPerform}`
              : `Expectation - ${data.esyGoalExpectationType}`,
        },
      });
    }
  }
}
