export enum IepPlaafpHelp {
  Tag = 'Tag',
  StrengthSkill = 'Strengths and Skills',
  PreferenceInterest = 'Preferences and Interests',
  NonAcademicAct = 'Nonacademic Activities',
  ExtracurricularAct = 'Extracurricular Activities',
  OtherInfo = 'Other Information',
  InvolvementProgress = 'Involvement and Progress in General Education',
  Diploma = 'Diploma',
  Certificate = 'Certificate',
  AgeMajority = 'Age of Majority',
  TransferRights = 'Transfer of Rights',
  NotTransfer = 'Not Transfer',
}

export enum IepGoalHelp {
  Domain = 'Domain',
  Nickname = 'Nickname',
  Baseline = 'Baseline',
  TargetValue = 'Target Value',
  CurrentLevel = 'Current Level',
  UnitMeasurement = 'Unit of Measurement',
  Objectives = 'Objectives',
  Milestones = 'Milestones',
  Scales = 'Scales',
  SecondMeasurement = 'Second Measurement',
  CombinedObjectives = 'Combined Objectives',
}

export enum IepServiceHelp {
  Services = 'Services',
  ActivitySupport = 'Activity / Support',
  RegularChildhood = 'Regular Early Childhood Programs',
  Accommodation = 'Accommodation',
  Esy = 'ESY',
  StatewideDistrictAssessment = 'Statewide and Districtwide Assessment',
  Accommodations = 'Accommodations',
}
