<div [formGroup]="formGroup" class="paginator">
  <p>Items per page:</p>
  <app-select formControlName="pageSize" [options]="pageSizeOptions"></app-select>
  <p>{{ friendlyPageIndex }} of {{ amountOfPages }}</p>
  <button
    aria-label="Previous Page Icon"
    matTooltip="Previous page"
    mat-icon-button
    [disabled]="friendlyPageIndex <= 1"
    (click)="onPageBack()"
  >
    <mat-icon>chevron_left</mat-icon>
  </button>
  <button
    mat-icon-button
    aria-label="Next Page Icon"
    matTooltip="Next page"
    [disabled]="friendlyPageIndex >= amountOfPages"
    (click)="onPageForward()"
  >
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
