import { Component, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HubConnection } from '@microsoft/signalr';
import { Subscription } from 'rxjs';
import { AchieveConfigService } from '../../services/achieve-config-service/achieve-config.service';
import { RouteBroadcast, SignalRService } from '../../services/signal-r.service';

@Component({
  selector: 'app-page-under-edit-header',
  templateUrl: './page-under-edit-header.component.html',
  styleUrls: ['./page-under-edit-header.component.scss'],
})
export class PageUnderEditHeaderComponent implements OnDestroy {
  @Input() protectedRoutes: string[];
  @Input() public condensed: boolean;
  @Input() public backgroundColor: 'red' | 'yellow' | 'blue' | 'blueDark' | 'purple' | 'white' | 'none' = 'white';
  routingHubConnection: HubConnection;
  routerSubscription: Subscription;
  routeUsers: RouteBroadcast[] = [];
  isFeatureLoaded = false;
  configSubscription: Subscription;

  constructor(private signalRService: SignalRService, private router: Router, private achieveConfig: AchieveConfigService) {}

  ngOnInit() {
    this.configSubscription = this.achieveConfig.$achieveConfigSettings.subscribe((config) => {
      if (this.achieveConfig.settings?.featureFlags?.simulUserWarning) {
        this.loadSimulUsersFeature();
      }
    });
  }

  loadSimulUsersFeature() {
    if (this.isFeatureLoaded) {
      return;
    }

    this.isFeatureLoaded = true;

    this.routingHubConnection = this.signalRService.buildRoutingClient(this.onRoutingBroadcast.bind(this), this.onRoutingLeave.bind(this));

    this.routingHubConnection
      .start()
      .then(() => this.onRoutingHubConnected())
      .catch((error) => console.error(error.message));
  }

  onRoutingBroadcast(connectionId: string, name: string, userId: string, phoneNumber: string, email: string) {
    if (!this.routeUsers.some((x) => x.connectionId === connectionId)) {
      this.routeUsers.push({ connectionId, name, userId, phoneNumber, email });
    }
  }

  onRoutingLeave(connectionId: string) {
    this.routeUsers = this.routeUsers.filter((x) => x.connectionId !== connectionId);
  }

  onRoutingHubConnected() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    this.routerSubscription = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.onNavigated();
      }
    });

    this.onNavigated();
  }

  onNavigated() {
    this.routeUsers = [];
    const route = this.router.url.toString();
    this.routingHubConnection.send('OnNavigate', this.isProtectedPage(route) ? route : '').catch((error) => console.log(error));
  }

  isProtectedPage(page: string) {
    return !!this.protectedRoutes?.some((x) => page.indexOf(x) >= 0);
  }

  isRepeatedRouteUserElement(routeUser: RouteBroadcast) {
    return this.routeUsers.find((x) => x.userId === routeUser.userId) !== routeUser;
  }

  doesUserExistMoreThanOnce(userId: string) {
    return this.routeUsers.filter((x) => x.userId === userId).length > 1;
  }

  async ngOnDestroy(): Promise<void> {
    if (this.routingHubConnection) {
      this.routingHubConnection.stop();
    }

    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }
  }
}
