<h3 class="card-spacing-top mb-2">{{ isEditing ? 'Edit' : 'Add' }} Additional Info</h3>
<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" #formDirective="ngForm">
  <mat-card class="my-0">
    <app-textarea [maxLength]="10000" formControlName="note" label="Enter information here" [hideAsterisk]="true" [rows]="5"></app-textarea>
  </mat-card>
  <div class="action__row mb-2">
    <button type="button" class="mr-2" mat-raised-button aria-label="Cancel Button" *ngIf="isEditing" (click)="onCancel()" tabindex="0">
      Cancel
    </button>
    <button
      [isBusy]="isBusy"
      mat-raised-button
      aria-label="Complete"
      color="primary"
      type="submit"
      [disabled]="formGroup.invalid"
      tabindex="0"
    >
      Complete
    </button>
  </div>
</form>

<div class="display-flex align-items-center justify-content-end mb-2" *ngIf="ifspAdditionalInfos?.length > 1">
  <button
    mat-raised-button
    attr.aria-label="{{ isAccordionsExpanded ? 'Collapse All' : 'Expand All' }}"
    color="accent"
    (click)="toggleAccordion()"
    tabindex="0"
  >
    {{ isAccordionsExpanded ? 'Collapse All' : 'Expand All' }}
  </button>
</div>

<mat-accordion class="accordion accordion--shadow accordion--close accordion--short" multi>
  <mat-expansion-panel class="mat-elevation-z0" *ngFor="let item of ifspAdditionalInfos">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex justify-content-between align-items-center flex-1 w-100">
          <h3 class="my-0 text-normal">
            {{ item.updatedOn | dateFormat }} - {{ item.createdBy.fullName }} | {{ item.note | truncate : 50 }}
          </h3>

          <div class="display-flex align-items-center" *ngIf="item.createdBy.id === userId && !isEditing">
            <button
              mat-icon-button
              aria-label="Edit Icon"
              color="primary"
              type="button"
              (click)="$event.stopPropagation(); onEdit(item)"
              tabindex="0"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              aria-label="Delete Icon"
              color="warn"
              type="button"
              (click)="$event.stopPropagation(); onDelete(item)"
              tabindex="0"
            >
              <mat-icon aria-label="Delete Icon"> close </mat-icon>
            </button>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="display-flex align-items-center justify-content-between">
      <h4 class="text-primary mt-0 mb-2">{{ item.createdBy.fullName }}</h4>
      <p class="meta">{{ item.updatedOn | dateFormat }}</p>
    </div>
    <p class="my-0">
      {{ item.note }}
    </p>
  </mat-expansion-panel>
</mat-accordion>
