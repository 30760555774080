<mat-accordion class="accordion accordion--short">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex justify-content-between align-items-center flex-1">
          <h3 class="my-0 text-normal">Forms</h3>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="list-fit">
      <ul class="list">
        <li class="list__item" *ngIf="canFba">
          <a href="javascript:;" [attr.aria-label]="labels.fba" (click)="onFunctionalBehaviorAssessmentClicked()" tabindex="0">
            {{ labels.fba }}
          </a>
        </li>
        <li class="list__item" *ngIf="canEditMd || canReadMd">
          <a href="javascript:;" [attr.aria-label]="labels.md" (click)="onManifestationDeterminationClicked()" tabindex="0">
            {{ labels.md }}
          </a>
        </li>
        <li class="list__item" *ngIf="labels.cai">
          <a href="javascript:;" [attr.aria-label]="canCai" (click)="onAdditionalInformationdClicked($event)" tabindex="0">
            {{ labels.cai }}
          </a>
        </li>
      </ul>
    </div>
  </mat-expansion-panel>
</mat-accordion>
