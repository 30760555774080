<main *ngIf="data.printData" class="container">
  <div class="print-preview" *ngIf="isPreview">
    <h1 class="print-preview__text">DRAFT</h1>
  </div>

  <app-print-head [showLogo]="true" title="Individualized Education Program (IEP)"></app-print-head>

  <section class="output">
    <h3 class="mt-0 mb-2">Demographic Information</h3>
    <ul class="my-0 list list--print">
      <li class="list__item avoid-break">
        <div class="print__container">
          <section class="output__demographic">
            <p class="my-0">
              <strong>Name: </strong>
              Daniel Brown
            </p>

            <p class="my-0">
              <strong>School: </strong>
              ABC Elementary
            </p>

            <p class="my-0">
              <strong>Attending District: </strong>
              Pleasantville
            </p>

            <p class="my-0">
              <strong>DOB: </strong>
              12/01/2014
            </p>

            <p class="my-0">
              <strong>County: </strong>
              Warren
            </p>

            <p class="my-0">
              <strong>Resident District: </strong>
              Pleasantville
            </p>

            <p class="my-0">
              <strong>IEP Date: </strong>
              8/12/2020
            </p>

            <p class="my-0">
              <strong>IEP Due: </strong>
              8/11/2021
            </p>

            <p class="my-0">
              <strong>Reevaluation Due: </strong>
              7/15/2022
            </p>
          </section>
        </div>
      </li>

      <li class="list__item avoid-break">
        <h3 class="card-spacing-top mb-2">Present Levels of Academic Achievement and Functional Performance</h3>
        <div class="print__container">
          <div class="row">
            <div class="col-md-12 col-lg-6 mb-2">
              <h4 class="text-primary mt-0 mb-2">Strengths and Skills</h4>
              <ul>
                <li>
                  <p class="my-0">Daniel is able to read 100 sight words.</p>
                  <p class="my-0"><span class="text-primary">Data Source: </span> Fry List 8/5/2020</p>
                </li>
                <li>
                  <p class="my-0">Daniel reads 10 two-syllable words with 80% accuracy.</p>
                  <p class="my-0"><span class="text-primary">Data Source: </span> DIBELS 8/7/2020</p>
                </li>
                <li>
                  <p class="my-0">Daniel responds with 100% accuracy on Level F comprehension questions.</p>
                  <p class="my-0"><span class="text-primary">Data Source: </span> NWEA 8/6/2020</p>
                </li>
              </ul>
            </div>
            <div class="col-md-12 col-lg-6">
              <h4 class="text-primary mt-0 mb-2">Preferences and Interests</h4>
              <ul>
                <li>
                  <p class="my-0">Daniel enjoys reading with parents.</p>
                  <p class="my-0"><span class="text-primary">Data Source: </span> Parent report</p>
                </li>
                <li>
                  <p class="my-0">Daniel prefers to read non-fiction</p>
                  <p class="my-0"><span class="text-primary">Data Source: </span> Teacher report</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>

      <li class="list__item avoid-break">
        <div class="print__container spacing-normal">
          <p class="my-0">
            The team considered
            <span class="text-primary">Special Factors</span> and determined that Accessible Educational Materials, Assistive Technology,
            Behavior, Vision, Communication and Language, Health, and Limited English Proficiency are not needed.
          </p>
        </div>
      </li>

      <li class="list__item avoid-break">
        <div class="print__container spacing-normal">
          <p class="my-0">
            Information from the IEP team regarding the effect of Daniel's disability on involvement and progress in the general education
            curriculum and the functional implications of the student's skills:
          </p>
          <ul>
            <li>
              Daniel was not able to complete the re-tell or comprehension portion of DIBELS. NWEA results indicate Daniel’s relative
              weakness is in the area of applying comprehension strategies to informational and literary text. DIBELS indicates that Daniel
              specifically struggles with decoding phonetically irregular words. His disability in reading impedes his ability to progress
              in the general curriculum and toward Iowa Core Standards in reading; know and apply grade-level phonics and word analysis
              skills in decoding words, and ask and answer such questions as who, what, where, when, why, and how to demonstrate
              understanding of key details in a text. Parents expressed concerns about Daniel’s reading comprehension abilities.
            </li>
          </ul>
        </div>
      </li>

      <li class="list__item avoid-break">
        <div class="print__container spacing-normal">
          <p class="my-0 text-semibold">
            Information from the IEP team regarding how Daniel will participate in nonacademic activities with nondisabled peers, and and
            have the same opportunity to participate in extracurricular activities as nondisabled peers:
          </p>
          <ul>
            <li>
              Daniel will participate with his peers in all nonacademic activities with access to all of the accommodations and supports
              described in this IEP. Daniel will have the same opportunities to participate in extracurricular activities as all 3rd grade
              students.
            </li>
          </ul>
        </div>
      </li>

      <li class="list__item avoid-break">
        <div class="print__container spacing-normal">
          <p class="my-0 text-semibold">Areas of concern the IEP team determined will be addressed within this IEP</p>
          <ul>
            <li>
              <p class="my-0">Daniel did not make sufficient progress in reading from 2nd grade to 3rd grade.</p>
              <p class="my-0"><span class="text-primary">Data Source: </span> NWEA 8/6/2020</p>
            </li>

            <li>
              <p class="my-0">Daniel’s oral reading fluency skill is not meeting age expectations</p>
              <p class="my-0"><span class="text-primary">Data Source: </span> DIBELS 8/7/2020</p>
            </li>

            <li>
              <p class="my-0">Daniel’s reading comprehension is not meeting age expectations</p>
              <p class="my-0">
                <span class="text-primary">NWEA 8/6/2020</span>
              </p>
            </li>
          </ul>
        </div>
      </li>

      <li class="list__item avoid-break">
        <div class="print__container spacing-normal">
          <p class="my-0 text-semibold">Other information essential for the development of this IEP:</p>
          <ul>
            <li>
              The family reported that Daniel’s grandfather passed away this summer. The two were very close, and Daniel is expressing a lot
              of anxiety about coming to school when he feels so sad.
            </li>
          </ul>
        </div>
      </li>

      <li class="list__item avoid-break">
        <h3 class="card-spacing-top mb-2">Annual Measurable Goals</h3>
        <div class="print__container">
          <p class="my-0 text-semibold">
            Daniel's current level of performance in
            <span class="text-primary text-underline">Reading</span> is:
          </p>
          <ul>
            <li>
              <h4 class="text-primary my-0">Standard of Comparison</h4>
              <p class="my-0">Norm-referenced assessment</p>
            </li>
            <li>
              <h4 class="text-primary my-0">Expected Performance</h4>
              <p class="my-0">72</p>
            </li>
            <li>
              <h4 class="text-primary my-0">Daniel's Baseline</h4>
              <p class="my-0">29</p>
            </li>
            <li>
              <h4 class="text-primary my-0">Daniel's Goal</h4>
              <p class="my-0">72</p>
            </li>
          </ul>

          <h4 class="text-primary mt-2 mb-0">Goal Area Goal:</h4>
          <p class="my-0">
            When given a third grade level text (Level J-Q) Daniel will read 72 words correct per minute with 90% accuracy.
          </p>

          <h4 class="mt-2 mb-0 text-primary">Progress Monitoring and Reporting:</h4>
          <ul>
            <li>Daniel’s progress toward this goal will be monitored 4 times monthly.</li>
            <li>
              If Daniel’s progress above the aim line 3 times in a row or below the aim line 2 times in a row, the IEP team will take action
              to address progress.
            </li>
            <li>Progress will be reported to the family quarterly through progress reports.</li>
          </ul>

          <!-- INSERT GRAPH -->
        </div>
      </li>

      <li class="list__item avoid-break">
        <h3 class="card-spacing-top mb-2">Services - Option 1</h3>
        <div class="print__container">
          <ol>
            <li>
              Daniel will receive instruction for reading in the general education setting. He will also receive supplemental instruction
              for reading in the special education classroom. The focus of the instruction will be on the development of reading fluency
              skills.
            </li>
            <li>Daniel will have a personal attendant with him on the bus each day.</li>
          </ol>

          <!-- INSERT TABLE -->
          <table mat-table [dataSource]="dataSourceService" class="w-100">
            <ng-container matColumnDef="section">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">{{ element.section }}</td>
            </ng-container>

            <ng-container matColumnDef="domain">
              <th mat-header-cell *matHeaderCellDef>Domain</th>
              <td mat-cell *matCellDef="let element">
                {{ element.domain }}
              </td>
            </ng-container>

            <ng-container matColumnDef="service">
              <th mat-header-cell *matHeaderCellDef>Service</th>
              <td mat-cell *matCellDef="let element">
                {{ element.service }}
              </td>
            </ng-container>

            <ng-container matColumnDef="frequency">
              <th mat-header-cell *matHeaderCellDef>Frequency</th>
              <td mat-cell *matCellDef="let element">
                {{ element.frequency }}
              </td>
            </ng-container>

            <ng-container matColumnDef="provider">
              <th mat-header-cell *matHeaderCellDef>Provider</th>
              <td mat-cell *matCellDef="let element">
                {{ element.provider }}
              </td>
            </ng-container>

            <ng-container matColumnDef="serviceProvided">
              <th mat-header-cell *matHeaderCellDef>Service Provided in General Education Setting?</th>
              <td mat-cell *matCellDef="let element">
                {{ element.serviceProvided }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsService"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsService"></tr>
          </table>
        </div>
      </li>

      <li class="list__item avoid-break">
        <h3 class="card-spacing top mb-2">Accommodations</h3>
        <div class="print__container">
          <ul>
            <li>2 minute breaks during reading instruction as needed General Educator</li>
            <li>Read instructions aloud Daily General Educator</li>
          </ul>
        </div>
      </li>

      <li class="list__item avoid-break">
        <h3 class="card-spacing top mb-2">Activities and Supports</h3>
        <div class="print__container">
          <ul>
            <li>Participates in leveled book club during lunch monthly Special Educator</li>
          </ul>
        </div>
      </li>

      <li class="list__item avoid-break">
        <h3 class="card-spacing top mb-2">Extended School Year</h3>
        <div class="print__container">
          <p class="my-0">
            Based on Daniel's progress and the goals, services, supports, accommodations, and activities identified by the team, Daniel does
            not require ESY services in order to receive FAPE.
          </p>
        </div>
      </li>

      <li class="list__item avoid-break">
        <h3 class="card-spacing top mb-2">Physical Education</h3>
        <div class="print__container">
          <p class="my-0">
            Based on Daniel’s goals, services, supports, accommodations, and activities identified by the team related to physical activity,
            the IEP team has determined that Daniel requires
            <span class="text-primary">standard physical education</span>.
          </p>
        </div>
      </li>

      <li class="list__item avoid-break">
        <h3 class="card-spacing top mb-2">Assessment</h3>
        <div class="print__container">
          <p class="my-0">
            Based on Daniel's learning characteristics and the nature and purpose of statewide and districtwide assessments, the IEP team
            has determined that Daniel will participate in the
            <span class="text-primary">standard assessment with accommodations</span>. Details about the accommodations required for Daniel
            to participate in assessments and demonstrate learning are described in the accommodations section of this IEP. The
            accommodations outlined in this IEP do not override accommodation requirements that any specific assessment has established to
            ensure the validity of the construct being measured.
          </p>
        </div>
      </li>

      <li class="list__item avoid-break">
        <h3 class="card-spacing top mb-2">Least Restrictive Environment</h3>
        <div class="print__container">
          <p class="mt-0 mb-2">
            Daniel is currently receiving instruction in
            <span class="text-primary">general education 88% of the day</span>.
          </p>

          <p class="my-0 text-semibold">
            Information from the IEP team regarding the reasons Daniel cannot be provided all special education services in a general
            education setting:
          </p>
          <ul>
            <li>Daniel requires instruction in a small group with materials that are selected to match his instructional reading level.</li>
          </ul>

          <p class="my-2">
            Information from the IEP team regarding the supports needed to assist the teacher and other personnel in providing the services,
            including accommodations, modifications and adaptations, described in this IEP:
          </p>
          <ul>
            <li>The general education teacher requires access to books at Daniel’s reading level for the classroom library.</li>
          </ul>

          <p class="my-0">Daniel <span class="text-primary">will</span> attend the school they would attend if nondisabled.</p>
        </div>
      </li>

      <li class="list__item avoid-break">
        <h3 class="card-spacing top mb-2">The IEP Team</h3>
        <div class="print__container">
          <p class="mt-0 mb-2">This IEP was developed by a team including the individuals listed below.</p>

          <ul>
            <li>
              <p>Name, Role</p>
            </li>
          </ul>

          <p class="mt-2 mb-0">
            Jennifer Howell, General Educator, was excused from the meeting by mutual agreement. Jennifer Howell provided written input to
            all team members on 8/10/2020.
          </p>
        </div>
      </li>

      <li class="list__item avoid-break">
        <h3 class="card-spacing-top mb-2">Procedural Safeguards</h3>
        <div class="print__container">
          <p class="my-0">
            Procedural Safeguards were reviewed in person by Tiffanie Owens on 8/12/2020. A printed copy was offered, and parents
            <span class="text-primary">accepted</span> the copy.
          </p>
        </div>
      </li>

      <li class="list__item avoid-break">
        <h3 class="card-spacing top mb-2">Electronic Communication</h3>
        <div class="print__container">
          <p class="my-0">
            The family
            <span class="text-primary">has provided consent</span> for electronic communication. An electronic copy of this IEP and Prior
            Written Notice are available within the Family Portal. The IEP team has confirmed that family members know how to access the IEP
            within the Family Portal.
          </p>
        </div>
      </li>
    </ul>
  </section>

  <div class="display-flex align-items justify-content-end mt-2 hide-print">
    <button mat-raised-button aria-label="Delete Icon" (click)="onClose()" tabindex="0">Close</button>
  </div>
</main>
