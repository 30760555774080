<form [formGroup]="formGroup" class="mt-3" (ngSubmit)="onUpdateModel()">
  <h4 class="text-primary my-0">Milestones</h4>

  <div class="display-flex align-items-center flex-wrap">
    <div class="display-flex align-items-baseline mr-3 flex-wrap">
      <span class="mr-2"> Milestone Target </span>
      <app-number-field formControlName="target" [min]="0" class="input--small" id="target"></app-number-field>
      <span class="mx-2">{{ unitOfMeasurement }}</span>
    </div>

    <app-date-picker controlName="endDate" label="End Date"></app-date-picker>

    <div class="display-flex justify-content-end align-items-center flex-1">
      <button mat-raised-button aria-label="Add Button" type="submit" color="primary" tabindex="0">Add</button>
    </div>
  </div>
</form>

<div class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" class="w-100" matSort>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="text-right"></th>
      <td mat-cell *matCellDef="let element" class="action--position-fix">
        <button mat-icon-button aria-label="Quick Access Menu" [matMenuTriggerFor]="menu" color="primary" tabindex="0">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item aria-label="Edit Icon" (click)="onEdit(element)" tabindex="0">Edit</button>
          <button mat-menu-item aria-label="Delete" (click)="onDelete(element)" tabindex="0">
            <span class="text-danger"> Delete </span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Milestone Number</th>
      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          class="mr-1"
          [tableAlignment]="true"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element?.isActive"
          [changedVersionText]="element.number"
          [currentVersionText]="element?.priorVersion?.number"
          [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        >
        </app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="target">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Milestone Target</th>
      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          class="mr-1"
          [tableAlignment]="true"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element?.isActive"
          [changedVersionText]="getMilestoneTarget(element, unitOfMeasurement)"
          [currentVersionText]="element?.priorVersion ? getMilestoneTarget(element?.priorVersion, priorUnitOfMeasurement) : null"
          [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        >
        </app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
      <td mat-cell *matCellDef="let element">
        <!-- Do not use dateFormat pipe here as it produces a result a day earlier than expected -->
        <app-compare-amendment-output
          class="mr-1"
          [tableAlignment]="true"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element?.isActive"
          [changedVersionText]="element.endDate | date : shortDateFormat : 'utc'"
          [currentVersionText]="element?.priorVersion?.endDate | date : shortDateFormat : 'utc'"
          [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        >
        </app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tieredMilestoneDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tieredMilestoneDisplayedColumns"></tr>
    <tr [hidden]="dataSource?.data?.length > 0" mat-footer-row *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
