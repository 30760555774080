import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { shortDateFormat } from '../../../../../../shared/dateTimeHelpers';
import { IepAccommodationService } from '../../../../../services/iep-accommodation.service';

@Component({
  selector: 'app-iep-accommodation-info',
  templateUrl: './iep-accommodation-info.component.html',
  styleUrls: ['./iep-accommodation-info.component.scss'],
})
export class IepAccommodationInfoComponent implements OnInit {
  shortDateFormat = shortDateFormat;
  iepId: string;
  accommodationId: string;
  accommodation: any;
  displayedColumns = ['date', 'changes'];
  dataSource = new MatTableDataSource([]);

  constructor(
    public iepAccommodationService: IepAccommodationService,
    private dialogRef: MatDialogRef<IepAccommodationInfoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { accommodation: any; id: string; iepId: string }
  ) {
    this.accommodation = data.accommodation;
    this.accommodationId = data.id;
    this.iepId = data.iepId;
  }

  ngOnInit(): void {
    this.iepAccommodationService.getAuditLogs(this.iepId, this.accommodationId).subscribe((result) => {
      this.dataSource.data = result;
    });
  }

  dismiss() {
    this.dialogRef.close();
  }
}
