import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppMatModule } from 'src/app/shared/app-mat.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { EvaluationSharedModule } from '../shared/shared.module';
import { EarlyAccessIntakeRoutingModule } from './early-access-intake-routing.module';
import { EarlyAccessIntakeComponent } from './early-access-intake.component';
import { InProgressReferralModalComponent } from './modals/in-progress-referral-modal/in-progress-referral-modal.component';

@NgModule({
  declarations: [EarlyAccessIntakeComponent, InProgressReferralModalComponent],
  entryComponents: [InProgressReferralModalComponent],
  imports: [
    AppMatModule,
    CommonModule,
    EarlyAccessIntakeRoutingModule,
    EvaluationSharedModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AngularSvgIconModule,
  ],
  exports: [EarlyAccessIntakeComponent],
})
export class EarlyAccessIntakeModule {}
