import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MedicaidReport } from '../models/medicaid-report.model';

@Injectable({
  providedIn: 'root',
})
export class MedicaidReportService {
  constructor(private http: HttpClient) {}

  exportReport(model: any): Observable<Blob> {
    return this.http.post('api/medicaid-report', model, {
      responseType: 'blob',
    });
  }

  getMedicalReports() {
    return this.http.get<MedicaidReport[]>('api/medicaid-report');
  }
}
