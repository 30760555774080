import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'ageInMonths',
})
export class AgeInMonthsPipe implements PipeTransform {
  transform(value: string | Date, ...args: unknown[]): string {
    const now = dayjs();
    // convert to dayjs
    const valueAsDate = dayjs(value);
    // difference in months between value and now
    const monthsDiff = now.diff(valueAsDate, 'month');
    // in order to get the diff between days, we're creating a date with the same month and year
    // dayjs `diff(value, days)` returns a number like 245 which we can't use
    const monthMatch = now.year(valueAsDate.year()).month(valueAsDate.month());

    const daysDiff = monthMatch.diff(valueAsDate, 'day', true);
    // if the value day is like 15 and today is the 25th we can just show the days
    // however, if it's the other way around daysDiff will be negative
    if (daysDiff >= 0) {
      return `${monthsDiff} months, ${Math.floor(daysDiff)} days`;
    } else {
      // if the days are negative then just add that to the number of days in the month
      // we add because the diff is already negative so 30 + -5 = 25
      const daysInMonth = valueAsDate.daysInMonth();
      const days = Math.floor(daysInMonth + daysDiff);
      return `${monthsDiff} months, ${days} days`;
    }
  }
}
