<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>View Documents</h3>

<div class="py-2" mat-dialog-content>
  <mat-card class="mb-0">
    <table class="w-100" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let file">{{ file.document.title }}</td>
      </ng-container>

      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef>File</th>
        <td mat-cell *matCellDef="let file">
          <a
            href="{{ baseUrl }}/{{ file.document.id }}"
            target="_blank"
            *ngIf="baseUrl"
            tabindex="0"
            role="link"
            aria-labelledby="File Name"
          >
            {{ file.document.fileName }}
          </a>
          <span *ngIf="!baseUrl">{{ file.document.fileName }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <p *ngIf="!dataSource.data">No data yet...</p>
  </mat-card>
</div>

<div mat-dialog-actions class="display-flex justify-content-end align-items-center">
  <button mat-raised-button aria-label="Close Button" color="primary" (click)="closeModal()" tabindex="0">Close</button>
</div>
