import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { CanComponentDeactivate, DeactivationService } from '../services/deactivation.service';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private deactivationService: DeactivationService) {}

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const serviceObservable = this.deactivationService.canDeactivate();
    if (!this.deactivationService.isSubscribed(component)) {
      return this.deactivationService.getObservable(component).pipe(concatMap((x) => (x ? serviceObservable : of(x))));
    }

    return serviceObservable;
  }
}
