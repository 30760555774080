import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Evaluation, EvaluationDetail, EvaluationNote } from 'src/app/evaluation/models/evaluation';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { DobAgePipe } from 'src/app/shared/pipes/dob-age.pipe';
import { FormService } from 'src/app/shared/services/form.service';
import { EvaluationFormService } from '../services/evaluation-form.service';

@Component({
  selector: 'app-evaluation-test',
  templateUrl: './evaluation-test.component.html',
  styleUrls: ['./evaluation-test.component.scss'],
  providers: [DobAgePipe],
})
export class EvaluationTestComponent implements OnInit, OnDestroy {
  @Input() currentEvaluation: Evaluation;
  @Input() currentEvaluationDetail: EvaluationDetail;
  @Input() currentEvaluationNote: EvaluationNote;
  @Output() testChange = new EventEmitter();
  @Output() testValid = new EventEmitter<boolean>();
  learnerAgeInMonths: number;
  formGroup: FormGroup = this.fb.group(
    {
      testGiven: ['', Validators.required],
    },
    { updateOn: 'change' }
  );
  isAgeRange: boolean;
  locationOptions: KeyValuePair[] = [
    new KeyValuePair('home', 'Home'),
    new KeyValuePair('providerAgency', 'Provider Agency'),
    new KeyValuePair('childcare', 'Child Care'),
    new KeyValuePair('other', 'Other'),
  ];
  subscription: Subscription;

  constructor(private fb: FormBuilder, evaluationFormService: EvaluationFormService, private formService: FormService) {
    this.subscription = evaluationFormService.formSubmitted$.subscribe(() => this.formGroup.markAllAsTouched());
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      this.testValid.emit(this.formGroup.valid);
      this.testChange.emit(value);
    });

    this.formGroup.patchValue(this.currentEvaluationDetail);
    this.subscription.add(this.formService.showAllErrors$.subscribe(() => this.formGroup.markAllAsTouched()));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formGroup.reset();
  }
}
