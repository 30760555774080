import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { MedicaidReportService } from '../../services/medicaid-report.service';
import dayjs from 'dayjs';
import { MedicaidReport } from '../../models/medicaid-report.model';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-medicaid-reports',
  templateUrl: './medicaid-reports.component.html',
  styleUrls: ['./medicaid-reports.component.scss'],
})
export class MedicaidReportsComponent implements OnInit {
  formGroup: FormGroup;
  reports: MedicaidReport[] = [];
  reportOptions: KeyValuePair[] = [];
  isBusy = false;
  constructor(
    private dialogRef: MatDialogRef<MedicaidReportsComponent>,
    private formBuilder: FormBuilder,
    private reportService: MedicaidReportService,
    private notifyService: NotificationService
  ) {}

  ngOnInit(): void {
    const today = dayjs().startOf('day').toDate();
    this.formGroup = this.formBuilder.group({
      reportType: [null, [Validators.required]],
      startDate: [dayjs().startOf('month').toDate(), [Validators.required]],
      endDate: [today, [Validators.required]],
    });

    this.reportService.getMedicalReports().subscribe({
      next: (res) => {
        this.reports = res;
        this.reportOptions = res.map((x) => new KeyValuePair(x.storedProcName, x.title));
      },
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  runReport() {
    this.isBusy = true;
    this.reportService.exportReport(this.formGroup.value).subscribe({
      next: (blob) => {
        this.downloadBlob(blob, `${this.reports.find((x) => x.storedProcName === this.formGroup.value.reportType)?.fileName}.csv`);
        this.isBusy = false;
        this.notifyService.success('Success');
      },
      error: () => {
        this.isBusy = false;
        this.notifyService.error('Error: Please try again');
      },
    });
  }

  downloadBlob(blob: Blob, fileName: string) {
    // Create blob URL and hidden anchor element.
    const objectUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');

    // Make anchor link a download link and click it.
    a.href = objectUrl;
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(objectUrl);
    a.remove();
  }
}
