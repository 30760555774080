<div
  class="help-directional-text {{ !flat ? directionalClassName : '' }}"
  [ngClass]="{
    'help-directional-text': !flat,
    form__description: flat,
    'help-directional-text--outside-card': outsideCard
  }"
>
  <ng-content> </ng-content>
</div>
