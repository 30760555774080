<div *ngIf="sifUpdateAlert" class="card-spacing-bottom">
  <app-page-alert [alert]="sifUpdateAlert" aria-live="polite"></app-page-alert>
</div>
<app-learner-management-header
  *ngIf="learnerSummary$ | async"
  [learnerId]="learnerId"
  [learnerSummary]="learnerSummary$ | async"
></app-learner-management-header>

<div class="animation-container" [@routerAnimation]="routeAnimation$ | async" aria-live="polite">
  <router-outlet></router-outlet>
</div>
