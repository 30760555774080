import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DeleteReason } from 'src/app/shared/models/delete-reason';
import { TransitionCode } from '../../shared/models/transitionCode';
import { IfspModification } from '../models/ifsp-modification';

@Injectable({
  providedIn: 'root',
})
export class IfspModificationService {
  private modificationsUpdated = new Subject<void>();
  modificationsUpdated$ = this.modificationsUpdated.asObservable();

  public modificationDoneUpdating = new Subject<void>();
  modificationDoneUpdating$ = this.modificationDoneUpdating.asObservable();

  constructor(private readonly http: HttpClient) {}

  private baseUrl(ifspId: string) {
    return `api/ifsp/${ifspId}/modify`;
  }

  getModification(ifspId: string, id: string) {
    return this.http.get<IfspModification>(this.baseUrl(ifspId) + '/' + id);
  }

  getAllTransitionCodes(ifspId) {
    return this.http.get<TransitionCode[]>(this.baseUrl(ifspId) + '/getAllTransitionCodes');
  }

  getModificationsByIfspId(ifspId: string) {
    return this.http.get<IfspModification[]>(this.baseUrl(ifspId));
  }

  updateModification(ifspId: string, model: any) {
    return this.http.put<any>(this.baseUrl(ifspId), model).pipe(tap(() => this.modificationsUpdated.next()));
  }

  cancelModification(ifspId: string, modificationId: string) {
    return this.http
      .put<any>(this.baseUrl(ifspId) + '/cancelModification/' + modificationId, null)
      .pipe(tap(() => this.modificationsUpdated.next()));
  }

  deleteModification(ifspId: string, ifspModificationId: string, reason: string) {
    const deleteReason: DeleteReason = { id: ifspModificationId, reason: reason };
    return this.http.put<DeleteReason>(this.baseUrl(ifspId) + '/deleteModification/', deleteReason);
  }
}
