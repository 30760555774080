import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Evaluation, EvaluationDevelopmentalAreaStatus } from 'src/app/evaluation/models/evaluation';
import { EvaluationDetailService } from 'src/app/evaluation/services/evaluation-detail.service';
import { EvaluationProxyService } from 'src/app/evaluation/services/evaluation-proxy.service';
import { EvaluationService } from 'src/app/evaluation/services/evaluation.service';
import { DialogComingSoonComponent } from 'src/app/shared/components/coming-soon/coming-soon.component';
import { IntakeType } from 'src/app/shared/models/case';
import { LookupBase } from 'src/app/shared/models/lookup-base';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { AuthService } from '../../../../auth/auth.service';
import { AppPermissions } from '../../../../permissions';
import { EvalDetailsPartBTagDomainsService } from '../../steps/eval-details-part-b/eval-details-part-b-header/eval-details-part-b-tagged-domains/eval-details-part-b-tagged-domains.service';
import { EvalDetailsPartBService } from '../../steps/eval-details-part-b/eval-details-part-b.service';
import { EvaluationFormService } from '../../steps/evaluation-details/services/evaluation-form.service';

@Component({
  selector: 'app-evaluation-nav',
  templateUrl: './evaluation-nav.component.html',
  styleUrls: ['./evaluation-nav.component.scss'],
})
export class EvaluationNavComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  @Input()
  intakeType: string;
  @Input() currentCaseId: string;

  evaluationId: string;
  caseId: string;
  @Input() evaluation: Evaluation;
  eligibilityDecision: boolean;
  eligibilitySubmittedOnIntake: boolean;
  taggedDomains: { [domain: string]: boolean };
  evaluationDevAreas: LookupBase[] = [];
  evaluationDevAreasStatus: EvaluationDevelopmentalAreaStatus;
  permissions = AppPermissions;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private caseService: CaseService,
    private svc: EvalDetailsPartBTagDomainsService,
    private detailsPartBService: EvalDetailsPartBService,
    private evaluationDetailService: EvaluationDetailService,
    private evaluationService: EvaluationService,
    private evalFormService: EvaluationFormService,
    private evaluationProxyService: EvaluationProxyService,
    public authService: AuthService
  ) {}

  get detailsLink() {
    const path = ['/', 'cases', this.caseId, 'evaluation', this.evaluationId];
    return this.intakeType === IntakeType.PartC
      ? path.concat(['details-part-c'])
      : path.concat(['details-part-b', 'strengths-concerns-background']);
  }

  get showEligibilityDecisionStepper() {
    // #20164 Eligibility Decision page will NOT be available until the Annual Review is started
    if (!this.evaluation.isInitial) return false;
    return this.intakeType === 'PartC' && (this.allDevAreasComplete() || this.eligibilitySubmittedOnIntake) && !this.evaluation.isFinalized;
  }

  ngOnInit(): void {
    this.evaluationId = this.route.snapshot.paramMap.get('evaluationId');
    this.caseId = this.route.snapshot.paramMap.get('caseId');
    this.evaluationProxyService.evaluationChange.subscribe((evaluation) => (this.evaluation = evaluation));

    this.evalFormService.devAreasUpdated.pipe(debounceTime(1000)).subscribe(() => this.getCompletedDevAreas());

    this.caseService.getCaseSummary(this.currentCaseId).subscribe((caseReturned) => {
      this.eligibilityDecision = caseReturned.eligibilityDecision;
      this.eligibilitySubmittedOnIntake = caseReturned.learner.eligibilitySubmittedOnIntake;

      if (this.intakeType === IntakeType.PartB && caseReturned.learner.isPK) {
        this.getTaggedDomains();
        this.detailsPartBService.detailsUpdated.subscribe(() => this.getTaggedDomains());
      }

      if (this.intakeType === IntakeType.PartC) {
        this.evaluationService.getDomainAreas(this.evaluationId).subscribe((res) => {
          this.evaluationDevAreas = res;
          this.getCompletedDevAreas();
          this.evalFormService.devAreasUpdated.subscribe(() => this.getCompletedDevAreas());
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  comingSoon() {
    this.dialog.open(DialogComingSoonComponent, {
      width: '725px',
    });
  }

  //#region Evaluation Part B Domain Areas
  private getTaggedDomains() {
    this.svc.get(this.evaluationId).subscribe((res) => (this.taggedDomains = res));
  }

  allDomainsComplete() {
    if (!this.taggedDomains) {
      return false;
    }
    let complete = true;
    Object.keys(this.taggedDomains).forEach((key) => {
      if (!this.taggedDomains[key]) {
        complete = false;
      }
    });
    return complete;
  }
  //#endregion

  //#region Early Access Developmental Areas
  allDevAreasComplete() {
    return (
      this.evaluationDevAreasStatus &&
      this.evaluationDevAreasStatus.requiredDevelopmentalAreaIds.every((id) =>
        this.evaluationDevAreasStatus.completedDevelopmentalAreaIds.includes(id)
      )
    );
  }

  getCompletedDevAreas() {
    this.evaluationDetailService.getCompletedEvalDevAreas(this.evaluationId).subscribe((completedAreas) => {
      this.evaluationDevAreasStatus = completedAreas;
    });
  }
  //#endregion
}
