import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PseOutcome } from 'src/app/iep/iep-plaafp/secondary-transition/models/pse-outcome';

@Injectable({
  providedIn: 'root',
})
export class PseOutcomeService {
  private baseUrl = 'api/pseoutcome';

  constructor(private http: HttpClient) {}

  get(): Observable<PseOutcome[]> {
    return this.http.get<PseOutcome[]>(this.baseUrl);
  }
}
