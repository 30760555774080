import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { UpdateConsentFormComponent } from 'src/app/consent/family-consent/update-consent-form/update-consent-form.component';
import { CaseSummary, CaseSummaryByIepId } from 'src/app/shared/models/case';
import { ConsentForm, consentFormTitlesByType, ConsentFormType } from 'src/app/shared/models/fiie-consent/consent-form';

@Component({
  selector: 'app-iep-consents',
  templateUrl: './iep-consents.component.html',
  styleUrls: ['./iep-consents.component.scss'],
})
export class IepConsentsComponent implements OnInit {
  @Input() iepId: string;
  @Input() isModification = false;
  @Input() caseSummary: CaseSummary;
  @Input() psgProvided: boolean;
  @Input() rollCallDate: Date;

  isCaseOwner: boolean;
  types = [ConsentFormType.InitialIepServices];
  showCreateInitialIepServicesConsent: boolean;

  constructor(private authService: AuthService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.isCaseOwner = this.caseSummary.caseOwnerId === this.authService.user.id;
  }

  onConsentFormsLoaded(consentForms: ConsentForm[]) {
    this.showCreateInitialIepServicesConsent = !consentForms.some(
      (cf) => cf.type === ConsentFormType.InitialIepServices && cf.iepId === this.iepId
    );
  }

  createConsentForSpecialEducationRelatedServices() {
    this.dialog.open(UpdateConsentFormComponent, {
      width: '400px',
      data: {
        caseId: this.caseSummary.id,
        title: consentFormTitlesByType[ConsentFormType.InitialIepServices],
        iepId: this.iepId,
        consentFormType: ConsentFormType.InitialIepServices,
        createdOn: this.rollCallDate,
      },
    });
  }
}
