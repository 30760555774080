import { FileDocument } from 'src/app/shared/models/file-document';

export interface LearnerProgress {
  id: string;
  evaluationId: string;
  helpText: string;
  summaryAndImplications: string;
  learnerProgressItems: LearnerProgressItem[];
}

export interface LearnerProgressItem {
  id: string;
  createdById: string;
  isSubmitted: boolean;
  currentProgress: string;
  expectedProgress: string;
  learnerProgressId: string;
  evaluationTiming: string;
  concernsAddressedStrategiesUsed: string;
  limitationsToThePlan: string;
  howMonitored: string;
  howLearnerProgressMonitored: string;
  notEvaluatedReasonId: string;
  notEvaluatedExplanation: string;
  implementedStrategies: ImplementedStrategy[];
  domainAreas: LearnerProgressItemDomain[];
  evaluationTimingPrior: boolean;
  evaluationTimingDuring: boolean;
  isEditing?: boolean;
  documents: LearnerProgressDocument[];
}

export interface LearnerProgressItemDomain {
  id: string;
  label?: string;
}

export interface LearnerProgressDocument extends FileDocument {
  url: string;
}

export enum EvaluationTiming {
  IsPriorToEvaluation = 'IsPriorToEvaluation',
  IsDuringEvaluation = 'IsDuringEvaluation',
  NotBeingEvaluated = 'NotBeingEvaluated',
}

export interface ImplementedStrategy {
  id: string;
  instructionInterventionId: any;
  personsResponsible: string;
  name?: string;
  description?: string;
  routineId?: string;
  isMostEffective?: boolean;
  isEditing: boolean;
  autoFilled: boolean;
  learnerProgressItemId?: string;
}
