import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ProgressMonitorLookup } from '../models/progress-monitor-lookup';
import { ProgressMonitorLookupService } from '../services/progress-monitor-lookup.service';

@Injectable({
  providedIn: 'root',
})
export class ProgressMonitorLookupsResolver implements Resolve<Observable<ProgressMonitorLookup[]>> {
  constructor(private readonly progressMonitorLookupService: ProgressMonitorLookupService) {}

  resolve(): Observable<ProgressMonitorLookup[]> {
    return this.progressMonitorLookupService.get();
  }
}
