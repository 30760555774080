import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { KeyValuePair } from '../../models/key-value-pair';
import { CaseService } from '../../services/case/case.service';
import { StringSizes } from '../form/constants/constants';
import { BankableFieldEntryDto } from './bankable-fields-models';
import { BankableFieldsService } from './bankable-fields.service';

@Component({
  selector: 'app-bankable-fields',
  templateUrl: './bankable-fields.component.html',
  styleUrls: ['./bankable-fields.component.scss'],
})
export class BankableFieldsComponent implements OnInit {
  shortDateFormat = shortDateFormat;
  dataSource: MatTableDataSource<BankableFieldEntryDto>;
  bankableFields: BankableFieldEntryDto[] = [];
  orginalBankableFields: BankableFieldEntryDto[] = [];
  selectedBankableField: BankableFieldEntryDto;
  isEditing = false;
  textToBeReplaced: string;
  displayedColumns = ['actions', 'text'];
  textOptions: KeyValuePair[] = [
    new KeyValuePair('Overwrite', 'Replace existing text'),
    new KeyValuePair('Append', 'Append to existing text'),
  ];
  formGroup = this.fb.group({
    text: [null, [Validators.required]],
    userId: [null],
    fieldId: [null],
    createdOn: [null],
  });

  searchFormGroup = this.fb.group({
    filter: [null],
  });

  textFormGroup = this.fb.group({
    text: null,
    textAction: 'Overwrite',
  });

  stringSizes = StringSizes;

  get isValid() {
    return this.formGroup.valid;
  }

  get inputedTextIsValid() {
    return this.textFormGroup.valid;
  }

  private emptyRow = {
    id: null,
    text: null,
    fieldId: null,
    userId: null,
    createdOn: null,
  };

  get randomGuid() {
    const generate = (appendDash: boolean) => {
      const part = `${Math.random().toString(16)}000000000`.substr(2, 8);
      return appendDash ? `-${part.substr(0, 4)}-${part.substr(4, 4)}` : part;
    };
    return `${generate(false)}${generate(true)}${generate(true)}${generate(false)}`;
  }

  constructor(
    private fb: FormBuilder,
    private bankableFieldsService: BankableFieldsService,
    private caseService: CaseService,
    public cd: ChangeDetectorRef,
    public dialog: MatDialogRef<BankableFieldsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (!this.data.childFirstName) {
      this.data.childFirstName = this.caseService.caseSummary?.learner?.firstName;
      this.caseService.caseSummaryChange$.subscribe((caseSummary) => {
        this.data.childFirstName = caseSummary?.learner?.firstName;
      });
    }

    if (this.data.inputText && this.data.childFirstName) {
      this.data.inputText = this.data.inputText.replace(new RegExp(this.data.childFirstName, 'g'), '****');
    }

    this.textFormGroup.patchValue({
      text: this.data.inputText,
      textAction: 'Overwrite',
    });
    this.cd.detectChanges();
    this.getBankableFields(false);
  }

  getBankableFields(resetForm, isAdding = false) {
    this.bankableFieldsService.getBankableFields(this.data.fieldId).subscribe((bf) => {
      this.bankableFields = bf;
      this.orginalBankableFields = bf;

      const fieldToSelect = this.orginalBankableFields.find((x) => x.text === this.textFormGroup.value.text);

      if (fieldToSelect) {
        this.selectedBankableField = fieldToSelect;
        this.textToBeReplaced = this.selectedBankableField.text;
      }
      if (resetForm) {
        this.resetForm(isAdding);
      }
    });
  }

  textActionChanged() {
    if (this.textFormGroup.value.textAction === 'Append') {
      this.textToBeReplaced = this.textFormGroup.value.text + ' ' + this.selectedBankableField.text;
      this.textFormGroup.get('textAction').setValue('Append');
    } else {
      this.textToBeReplaced = this.selectedBankableField.text;
      this.textFormGroup.get('textAction').setValue('Overwrite');
    }
  }

  select(field: any) {
    if (field.isEditing) {
      return;
    }
    if (this.selectedBankableField === field) {
      this.selectedBankableField = null;
      this.textToBeReplaced = null;
    } else {
      this.selectedBankableField = field;
      this.textToBeReplaced = this.selectedBankableField.text;
      this.textFormGroup.get('textAction').patchValue('Overwrite');
    }
  }

  applyFilter(filterEvt: any) {
    const filterValue = (filterEvt.target as HTMLInputElement).value;
    if (filterValue) {
      this.bankableFields = this.orginalBankableFields.filter((ele, i, array) => {
        const arrayelement = ele.text.toLowerCase();
        return arrayelement.includes(filterValue.trim().toLowerCase());
      });
    } else {
      this.getBankableFields(false);
    }
  }

  clearFilter() {
    this.searchFormGroup.reset();
    this.getBankableFields(false);
  }

  updateTouchedAndValidity() {
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();
  }

  updateInputedTextTouchedAndValidity() {
    this.textFormGroup.updateValueAndValidity();
    this.textFormGroup.markAllAsTouched();
  }

  saveEnteredText() {
    this.updateInputedTextTouchedAndValidity();
    if (this.inputedTextIsValid) {
      const newField = this.emptyRow as BankableFieldEntryDto;
      newField.text = this.textFormGroup.value.text;
      newField.fieldId = this.data.fieldId;

      this.bankableFieldsService
        .saveBankableField(newField)
        .pipe(
          catchError(() => {
            console.log('Error');
            return of(undefined);
          })
        )
        .subscribe(() => {
          this.getBankableFields(true, false);
        });
    }
  }

  addNewField() {
    if (this.bankableFields.find((x) => x.text === null)) {
      return;
    }
    const newField = this.emptyRow as BankableFieldEntryDto;
    this.bankableFields.push(newField);
    this.setEdit(newField);
    this.select(newField);
  }

  setEdit(_field: any, editing = true) {
    if (!_field.userId && !editing) {
      this.getBankableFields(false);
      this.isEditing = editing;
      return;
    }
    this.isEditing = editing;
    _field.isEditing = editing;
    if (!editing) {
      this.formGroup.patchValue({});
      return;
    }
    this.formGroup.patchValue({
      text: _field.text,
      fieldId: this.data.fieldId,
      userId: _field.userId,
      createdOn: _field.createdOn,
    });
  }

  addOrUpdate(_field) {
    this.updateTouchedAndValidity();
    if (this.isValid) {
      const newField = this.formGroup.value as BankableFieldEntryDto;
      newField.id = _field.id;
      this.bankableFieldsService
        .saveBankableField(newField)
        .pipe(
          catchError(() => {
            console.log('Error');
            return of(undefined);
          })
        )
        .subscribe(() => {
          this.setEdit(_field, false);
          this.getBankableFields(true, false);
        });
    }
  }

  remove(field: BankableFieldEntryDto) {
    this.bankableFieldsService
      .deleteBankableField(field.id)
      .pipe(
        catchError(() => {
          console.log('Error');
          return of(undefined);
        })
      )
      .subscribe(() => {
        this.getBankableFields(true, false);
      });
  }

  resetForm(isAdding) {
    if (isAdding) {
      this.formGroup.reset();
    }
  }

  useText() {
    if (this.textToBeReplaced) {
      this.textToBeReplaced = this.textToBeReplaced.replace(/\*\*\*\*/g, this.data.childFirstName);
    }

    this.dialog.close(this.textToBeReplaced);
  }

  closeModal() {
    this.dialog.close(null);
  }
}
