import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { IfspServicesService } from '../../services/ifsp-service.service';

@Component({
  selector: 'app-ifsp-service-info',
  templateUrl: './ifsp-service-info.component.html',
  styleUrls: ['./ifsp-service-info.component.scss'],
})
export class IfspServiceInfoComponent implements OnInit {
  shortDateFormat = shortDateFormat;
  ifspId: string;
  serviceId: string;
  service: any;
  displayedColumns = ['date', 'changes'];
  dataSource = new MatTableDataSource([]);

  constructor(
    public ifspServicesService: IfspServicesService,
    private dialogRef: MatDialogRef<IfspServiceInfoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { service: any; id: string; ifspId: string }
  ) {
    this.ifspId = data.ifspId;
    this.serviceId = data.id;
  }

  ngOnInit(): void {
    this.ifspServicesService.getAuditLogs(this.ifspId, this.serviceId).subscribe((result) => {
      this.dataSource.data = result;
    });
  }

  dismiss() {
    this.dialogRef.close();
  }
}
