import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ConfirmationDialogComponent } from 'src/app/shared/services/notification.service';
import { CanComponentDeactivate, DeactivationStatus } from '../../../shared/services/deactivation.service';
import { IfspAdditionalInfo } from '../../models/ifsp-additional-info';
import { IfspService } from '../../services/ifsp.service';

@Component({
  selector: 'app-ifsp-additional-info',
  templateUrl: './ifsp-additional-info.component.html',
  styleUrls: ['./ifsp-additional-info.component.scss'],
})
export class IfspAdditionalInfoComponent implements OnInit, CanComponentDeactivate {
  @ViewChild('formDirective') private formDirective: NgForm;
  @ViewChild(MatAccordion) additionalInfoAccordion: MatAccordion;
  isAccordionsExpanded = false;
  formGroup = this.fb.group({
    id: '',
    note: ['', Validators.required],
  });
  ifspId: string;
  userId: string;
  ifspAdditionalInfos: IfspAdditionalInfo[];
  isEditing = false;
  shortDateFormat = shortDateFormat;
  isBusy = false;

  constructor(
    private fb: FormBuilder,
    private ifspService: IfspService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService
  ) {}

  canDeactivate(): DeactivationStatus | Observable<DeactivationStatus> | Promise<DeactivationStatus> {
    return this.formGroup.dirty ? DeactivationStatus.NeedsConfirmation : DeactivationStatus.Accepted;
  }

  ngOnInit(): void {
    this.ifspId = this.route.parent?.snapshot.paramMap.get('ifspId') || this.route.snapshot.paramMap.get('ifspId');
    this.userId = this.authService.user.id;
    this.loadData();
  }

  private loadData() {
    this.ifspService.getIfspAdditionalInfos(this.ifspId).subscribe((response) => {
      this.ifspAdditionalInfos = response;
      this.ifspAdditionalInfos = this.ifspAdditionalInfos.sort((a, b) => {
        const aUpdatedOn = new Date(a.updatedOn);
        const bUpdatedOn = new Date(b.updatedOn);
        return bUpdatedOn.getTime() - aUpdatedOn.getTime();
      });
    });
  }

  onEdit(item: IfspAdditionalInfo) {
    this.isEditing = true;
    this.formGroup.patchValue(item);
  }

  onDelete(item: IfspAdditionalInfo) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '728px',
      data: {
        title: 'Remove',
        message: 'Are you sure you want to remove this additional information?',
      },
    });

    dialogRef.afterClosed().subscribe((yesImSure) => {
      if (yesImSure) {
        this.ifspService.deleteIfspAdditionalInfo(this.ifspId, item.id).subscribe((res: any) => {
          if (res.succeeded) {
            this.loadData();
          }
        });
      }
    });
  }

  onCancel() {
    this.isEditing = false;
    this.formGroup.reset();
    this.formDirective.resetForm();
  }

  onSubmit() {
    this.isBusy = true;
    if (this.formGroup.controls.note.value === null || this.formGroup.controls.note.value.trim() === '') {
      this.onCancel();
      return;
    }

    this.ifspService.addOrUpdateIfspAdditionalInfo(this.ifspId, this.formGroup.value).subscribe((res: any) => {
      if (res.succeeded) {
        this.isEditing = false;
        this.loadData();
        this.formGroup.reset();
        this.formDirective.resetForm();
        this.isBusy = false;
      }
    });
  }

  toggleAccordion() {
    this.isAccordionsExpanded = !this.isAccordionsExpanded;
    this.isAccordionsExpanded ? this.additionalInfoAccordion.openAll() : this.additionalInfoAccordion.closeAll();
  }
}
