<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-md-12">
      <fieldset>
        <app-radio-group
          label="Is Robert working towards a diploma or certificate?"
          formControlName="workingTowards"
          [options]="workingTowardsOptions"
        ></app-radio-group>
      </fieldset>
    </div>
    <div class="col-md-12">
      <div class="display-flex align-items-baseline">
        <div class="mr-4">
          <span class="mr-3">Total number of credits required for graduation</span>
          <app-number-field formControlName="creditsNeeded" class="input--small" [min]="0" id="creditsNeeded"></app-number-field>
        </div>
        <div>
          <span class="mr-3">Credits earned to date towards graduation</span>
          <app-number-field formControlName="creditsEarned" class="input--small" [min]="0" id="creditsEarned"></app-number-field>
        </div>
      </div>
    </div>
    <div class="col-md-12 position-fix--col">
      <app-textarea label="Comments" formControlName="comments" [rows]="4"></app-textarea>
    </div>
    <div class="col-md-12 position-fix--col">
      <app-textarea
        label="Changes/additions to meet requirements based on [LEARNER'S NAME]'s disability"
        formControlName="changesAdditions"
        [rows]="4"
      ></app-textarea>
    </div>
    <div class="col-md-12 col-lg-6 position-fix--col">
      <label class="vertical-label display-block position-fix--label" for="targetDate">
        Targeted date for graduation/completion for Roberto
      </label>
      <app-date-picker controlName="targetDate" id="targetDate"></app-date-picker>
    </div>
  </div>
</form>

<div class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">
    <ng-container matColumnDef="courseActivity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="column--name">Course / Activity</th>
      <td mat-cell *matCellDef="let element" class="column--name">
        {{ element.courseActivity }}
      </td>
    </ng-container>

    <ng-container matColumnDef="projectedDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Projected Date/Year of School</th>
      <td mat-cell *matCellDef="let element">
        {{ element.projectedDate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="llw">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Living, Learning, Working</th>
      <td mat-cell *matCellDef="let element">
        {{ element.llw }}
      </td>
    </ng-container>

    <ng-container matColumnDef="completedDiscontinued">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed / Discontinued</th>
      <td mat-cell *matCellDef="let element">
        {{ element.completedDiscontinued }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
