import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Evaluation } from 'src/app/evaluation/models/evaluation';
import { EvaluationRoutine } from 'src/app/evaluation/models/evaluation-routine';
import { EvaluationRoutineService } from 'src/app/evaluation/services/evaluation-routine.service';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { MemoryStorageService } from 'src/app/shared/services/memory-storage/memory-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RoutinesService } from 'src/app/shared/services/routines/routines.service';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';

@Component({
  selector: 'app-routine-add-modal',
  templateUrl: './routine-add-modal.component.html',
  styleUrls: ['./routine-add-modal.component.scss'],
})
export class RoutineAddModalComponent implements OnInit {
  editFormGroup = new FormGroup({
    routine: new FormControl(''),
    other: new FormControl(''),
    nickName: new FormControl(''),
    id: new FormControl(''),
  });
  evaluation: Evaluation;
  routines: KeyValuePair[];
  otherKey: string;

  get isValid() {
    return this.editFormGroup.valid;
  }

  constructor(
    private readonly evaluationRoutineService: EvaluationRoutineService,
    private readonly notificationService: NotificationService,
    private readonly routineService: RoutinesService,
    private readonly storage: MemoryStorageService,
    public dialogRef: MatDialogRef<any>,
    private spinner: SpinnerService
  ) {}

  ngOnInit(): void {
    this.evaluation = this.storage.getKey('currentEvaluation', true);
    this.routineService.get().subscribe((routines) => {
      this.routines = routines
        .filter((x) => this.evaluation.learner.isPK === x.isPk)
        .map((routine) => new KeyValuePair(routine.id, routine.label))
        .sort((a, b) => {
          if (a.value === 'Other') {
            return 1;
          }
          if (b.value === 'Other') {
            return -1;
          }
          return a.value.localeCompare(b.value);
        });
      this.otherKey = this.routines.find((x) => x.value === 'Other')?.key;
    });
  }

  updateTouchedAndValidity() {
    this.editFormGroup.updateValueAndValidity();
    this.editFormGroup.markAllAsTouched();
  }

  saveRoutine() {
    this.updateTouchedAndValidity();
    if (this.isValid) {
      this.spinner.incrementLoading();
      this.evaluationRoutineService
        .add(this.evaluation.id, {
          nickName: this.editFormGroup.controls.nickName.value,
          otherLabel: this.editFormGroup.get('other') ? this.editFormGroup.get('other').value : 'Other',
          routineId: this.editFormGroup.controls.routine.value,
        } as EvaluationRoutine)
        .subscribe(() => {
          this.notificationService.success('Saved');
          this.spinner.decrementLoading();
          this.dialogRef.close(true);
        });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
