import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FamilySurvey, FamilySurveyQuestionBank, FamilySurveyResponse, FamilySurveyResult } from '../models/family-survey';

@Injectable({
  providedIn: 'root',
})
export class FamilySurveyService {
  constructor(private readonly http: HttpClient) {}

  get(evaluationId: string, id: string): Observable<FamilySurvey> {
    return this.http.get<FamilySurvey>(this.baseSurveyUrl(evaluationId, id));
  }

  add(evaluationId: string, model: FamilySurvey) {
    return this.http.post<FamilySurvey>(this.baseUrl(evaluationId), model);
  }

  update(evaluationId: string, id: string, model: FamilySurvey) {
    return this.http.put(this.baseSurveyUrl(evaluationId, id), model);
  }

  remove(evaluationId: string, id: string) {
    return this.http.put(`${this.baseSurveyUrl(evaluationId, id)}/delete`, null);
  }

  response(evaluationId: string, id: string): Observable<FamilySurveyResult> {
    return this.http.get<FamilySurveyResult>(this.baseSurveyUrl(evaluationId, id) + 'response');
  }

  responses(evaluationId: string, id: string): Observable<FamilySurveyResult> {
    return this.http.get<FamilySurveyResult>(this.baseSurveyUrl(evaluationId, id) + 'responses');
  }

  answer(evaluationId: string, id: string, model: FamilySurveyResponse) {
    return this.http.put(this.baseSurveyUrl(evaluationId, id) + 'answer', model);
  }

  getQuestionBank(evaluationId: string): Observable<FamilySurveyQuestionBank[]> {
    return this.http.get<FamilySurveyQuestionBank[]>(this.baseUrl(evaluationId) + 'questions-bank');
  }

  private baseSurveyUrl(evaluationId: string, surveyId: string) {
    return `${this.baseUrl(evaluationId)}${surveyId}/`;
  }

  private baseUrl(evaluationId: string) {
    return `api/evaluations/${evaluationId}/survey/`;
  }
}
