import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PunctuationPipe } from '../../pipes/punctuation.pipe';

@Component({
  selector: 'app-incomplete-data-report',
  templateUrl: './incomplete-data-report.component.html',
  styleUrls: ['./incomplete-data-report.component.scss'],
  providers: [PunctuationPipe],
})
export class IncompleteDataReportComponent implements OnChanges {
  @Input() incompleteItems: any[];
  @Input() displayHeader = true;
  @Output() navigateToAction = new EventEmitter<any>();
  @Output() getIncompleteDataReport = new EventEmitter<any>();

  dataSource = new MatTableDataSource<any>();
  displayedColumns = ['action', 'notice'];

  constructor() {}

  ngOnChanges(): void {
    this.dataSource.data = this.incompleteItems;
  }

  navigate(dataSourceElement: any) {
    this.navigateToAction.emit(dataSourceElement);
  }

  refreshIncompleteItems(e: MouseEvent) {
    this.getIncompleteDataReport.emit();
  }
}
