<form *ngIf="lateIfspReasonOptions && !readOnly" [formGroup]="formGroup">
  <div class="row">
    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-select formControlName="lateReasonId" [options]="lateIfspReasonOptions"></app-select>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-8">
      <app-text-field
        *ngIf="formGroup.controls.lateReasonId.value === otherLateIfspReasonId"
        formControlName="lateReasonOther"
        label="Other Reason"
      ></app-text-field>
    </div>
  </div>
</form>
<div *ngIf="readOnly">
  <p>{{ getLateReasonLabel() }}</p>
</div>
