import { Aea } from './models/aea';
import { Building } from './models/building';
import { District } from './models/district';
import { TeamUser } from './models/team';
import { User } from './models/user';

export function getLocation(user: User | TeamUser) {
  const buildings = user.buildings.filter((x) => x.id);
  const leas = user.districts.filter((x) => x.id);
  const aeas = user.aeas.filter((x) => x.id);

  if (buildings.length > 0) {
    return buildings[0];
  }
  if (leas.length > 0) {
    return leas[0];
  }
  if (aeas.length > 0) {
    return aeas[0];
  }
}

export function getFirstLocationName(a: Array<Aea> | Array<District> | Array<Building>) {
  return !!a && a.length > 0 ? a[0].name : '';
}
