export enum NAYesNo {
  NA = 'NA',
  Yes = 'Yes',
  No = 'No',
}

export enum UnknownYesNo {
  Unknown = 'Unknown',
  Yes = 'Yes',
  No = 'No',
}

export enum NotSureYesNo {
  NotSure = 'NotSure',
  Yes = 'Yes',
  No = 'No',
}
