<ng-container *ngIf="domains">
  <h3 class="mt-0 mb-2">EA Domains</h3>
  <mat-accordion displayMode="flat" multi class="accordion accordion--shadow">
    <mat-expansion-panel class="mat-elevation-z0" *ngFor="let domain of domains">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="my-0 text-normal">{{ domain.domain }}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-accordion displayMode="flat" multi class="accordion accordion--close accordion--plain accordion--no-shadow accordion--no-border">
        <mat-expansion-panel class="mat-elevation-z0" *ngFor="let evaluator of domain.evaluators">
          <mat-expansion-panel-header class="py-4 px-3">
            <mat-panel-title>
              <div class="display-flex flex-column w-100">
                <div class="display-flex align-items-center justify-content-between w-100 mt-2">
                  <h3 class="my-0 text-normal">Evaluator: {{ evaluator.fullName }}</h3>

                  <p class="my-0 mr-4 riot__title--delay">
                    {{ evaluator.delay + '% Delay' }}
                  </p>
                </div>

                <div class="display-flex align-items-center w-100 mt-3 mb-2">
                  <p class="my-0 riot__title--method">Methods:</p>
                  <div class="my-0 ml-2 display-flex align-items-center">
                    <p
                      class="riot__circle"
                      [ngClass]="{
                        'riot__circle--highlight': hasMethod(evaluator.methods, 'Review')
                      }"
                    >
                      <span class="riot__circle-text">R</span>
                    </p>
                    <p
                      class="riot__circle"
                      [ngClass]="{
                        'riot__circle--highlight': hasMethod(evaluator.methods, 'Interview')
                      }"
                    >
                      <span class="riot__circle-text">I</span>
                    </p>
                    <p
                      class="riot__circle"
                      [ngClass]="{
                        'riot__circle--highlight': hasMethod(evaluator.methods, 'Observe')
                      }"
                    >
                      <span class="riot__circle-text">O</span>
                    </p>
                    <p
                      class="riot__circle"
                      [ngClass]="{
                        'riot__circle--highlight': hasMethod(evaluator.methods, 'Test')
                      }"
                    >
                      <span class="riot__circle-text">T</span>
                    </p>
                  </div>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <hr class="input__divider mt-0" />

          <ul class="riot__list">
            <li class="riot__item" *ngFor="let method of evaluator.methods">
              <div class="display-flex align-items-baseline">
                <div class="display-flex flex-column" class="riot__title">
                  <h4>
                    <strong>{{ method.method }}</strong>
                  </h4>
                  <p class="my-0" *ngIf="method.tools">Tool(s): {{ method.tools }}</p>
                </div>

                <div class="riot__body">
                  <ng-container *ngIf="method.dates">
                    <p class="riot__date">
                      <span *ngFor="let date of method.dates; last as isLast">
                        {{ date + (!isLast ? ' | ' : '') }}
                      </span>
                    </p>
                  </ng-container>
                  <p class="my-0">
                    {{ method.description }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
