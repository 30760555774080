/* eslint-disable prettier/prettier */
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { ContactAttempt } from 'src/app/shared/models/contact-attempt';
import { FamilyContactHistoryService } from '../services/family-contact-history.service';
import { FamilyContactLogAddComponent } from './modals/family-contact-log-add.component';
import { AppPermissions } from '../../../permissions';
import { AuthService } from '../../../auth/auth.service';
import { DatePipe } from '@angular/common';
import { LearnerSummary } from '../../../shared/models/learner';
import { IntakeType } from '../../../shared/models/case';

@Component({
  selector: 'app-family-contact-log',
  templateUrl: './family-contact-log.component.html',
  styleUrls: ['./family-contact-log.component.scss'],
})
export class FamilyContactLogComponent implements OnInit {
  @Input() learner: LearnerSummary;
  @Input() showTitle = false;
  @Input() caseId: string;

  permissions = AppPermissions;
  dataSource = new MatTableDataSource<ContactAttempt>();
  displayedColumns: string[];
  addContactLogRequirements = new Map<string, string[]>([['CaseOwner', ['LeaProviderEdit']]]);

  constructor(
    private dialog: MatDialog,
    private fchService: FamilyContactHistoryService,
    public authService: AuthService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.initDataSource();
    this.displayedColumns = ['actions', 'dateTime', 'personMakingContact', 'personBeingContacted', 'method', 'purpose', 'comments'];
    this.getCaseId();
  }

  initDataSource() {
    this.fchService.getForLearner(this.learner.id).subscribe((res) => {
      this.dataSource.data = res;
    });
  }

  get canViewAddButton() {
    return (
      this.learner?.hasWorkableCase &&
      this.authService.isAllowedByCaseId(
        this.caseId,
        new Map<string, string[]>([['CaseOwner', ['LeaProviderEdit']]]),
        AppPermissions.FamilyContactLogAdd
      )
    );
  }

  onViewMore(family: any): void {
    const dialogData = [
      {
        name: 'Date/Time',
        value: this.datePipe.transform(family.dateTime, 'MM/dd/yyyy hh:mm aa'),
      },
      {
        name: 'Person Making Contact',
        value: family.user?.fullName,
      },
      {
        name: 'Person Being Contacted',
        value: family.contactee,
      },
      {
        name: 'Method',
        value: this.getMethodName(family.method),
      },
      {
        name: 'Purpose',
        value: family.purpose,
      },
      {
        name: 'Comments',
        value: family.comment,
      },
    ];
    openViewMore(this.dialog, dialogData);
  }

  getMethodName(method: string) {
    if (method === 'InPerson') {
      return 'In Person';
    } else if (method === 'WrittenNote') {
      return 'Written Note/Letter';
    }
    return method;
  }

  getCaseId() {
    this.caseId = this.learner.activeCases[0]?.id;
  }

  async onAddNewContactLog(): Promise<void> {
    const dialogRef = this.dialog.open(FamilyContactLogAddComponent, {
      data: this.learner,
      disableClose: true,
      width: '450px',
    });
    const contactAttempt = await dialogRef.afterClosed().toPromise();
    if (contactAttempt) {
      let caseId = this.learner.activeCases[0].id;
      if (this.learner.activeCases.length > 1) {
        caseId = this.learner.activeCases.find((x) => x.intakeType === IntakeType.PartB).id;
      }
      await this.fchService.create(caseId, contactAttempt).toPromise();
      this.initDataSource();
    }
  }
}
