import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { yesNoOptions } from 'src/app/shared/formHelpers';
import { IfspAddUpdate, IfspStatus, IfspType } from '../models/ifsp';
import { IfspService } from '../services/ifsp.service';

@Component({
  selector: 'app-interim-plan-modal',
  templateUrl: './interim-plan-modal.component.html',
  styleUrls: ['./interim-plan-modal.component.scss'],
})
export class InterimPlanModalComponent implements OnInit {
  formGroup: FormGroup;
  yesNoOptions = yesNoOptions;
  caseId: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private dialogRef: MatDialogRef<InterimPlanModalComponent>,
    private readonly ifspService: IfspService,
    @Inject(MAT_DIALOG_DATA) private readonly data: any
  ) {
    this.caseId = data.caseId;
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      needForEarlyIntervention: new FormControl(null, [Validators.required]),
      rationale: new FormControl(null, [Validators.required]),
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    const newIFSP = {
      caseId: this.caseId,
      ifspType: IfspType.Interim,
      ifspStatus: IfspStatus.Draft,
      needForEarlyIntervention: this.formGroup.controls.needForEarlyIntervention.value,
      rationale: this.formGroup.controls.rationale.value,
    } as IfspAddUpdate;
    this.ifspService.createIFSP(newIFSP).subscribe((newIfsp) => {
      this.dialogRef.close();
      this.goToIfsp(newIfsp.id);
    });
  }

  goToIfsp(ifspId: string) {
    this.router.navigate(['/', 'cases', this.caseId, 'ifsp', ifspId, 'profile']);
  }
}
