import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RecommendationFollowUp } from './recommendation-follow-up';

@Injectable({
  providedIn: 'root',
})
export class RecommendationFollowUpService {
  baseUrl = 'api/early-access-intakes/recommendations';

  constructor(private http: HttpClient) {}

  getRecommendations(): Observable<RecommendationFollowUp[]> {
    return this.http.get<RecommendationFollowUp[]>(this.baseUrl);
  }
}
