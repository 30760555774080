<form [formGroup]="formGroup" class="mat-content">
  <!-- date -->
  <span class="mat-cell mat-cell--short" (click)="preventToggle($event)">
    <ng-container *ngIf="formGroup.get('goal')?.get('secondaryMeasurement')?.value === null">
      <app-date-picker label="Date" controlName="date" [min]="formGroup.get('finalizationDate')?.value" [max]="today"></app-date-picker>
    </ng-container>
  </span>
  <!-- targetValue -->
  <span class="mat-cell mat-cell--shorter" (click)="preventToggle($event)">
    <ng-container *ngIf="formGroup.get('goal')?.get('secondaryMeasurement')?.value === null">
      <app-number-field label="Value" formControlName="value" [min]="0" [idPrefix]="idPrefix"></app-number-field>
    </ng-container>
  </span>
  <!-- monitoring Notes -->
  <span class="mat-cell" (click)="preventToggle($event)">
    <ng-container *ngIf="formGroup.get('goal')?.get('secondaryMeasurement')?.value === null">
      <app-textarea
        (keydown.Space)="$event.stopImmediatePropagation()"
        label="Monitoring Notes"
        formControlName="note"
        [maxLength]="10000"
        [idPrefix]="idPrefix"
      ></app-textarea>
    </ng-container>
  </span>
</form>
