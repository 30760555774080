<form [formGroup]="formGroup">
  <div class="display-flex align-items-center">
    <app-autocomplete [options]="locationOptions" formControlName="location" label="Location" class="w-100"></app-autocomplete>
    <app-text-field
      *ngIf="formGroup.get('location').value === 'other'"
      formControlName="descriptionOfLocation"
      label="Description of Other"
    ></app-text-field>
  </div>
</form>
