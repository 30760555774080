import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable, Subject, forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { EvaluationNote } from 'src/app/evaluation/models/evaluation';
import { EvaluationNoteService } from 'src/app/evaluation/services/evaluation-note.service';
import { IfspModificationModalComponent } from 'src/app/ifsp/modals/ifsp-modification-modal/ifsp-modification-modal.component';
import { AnnualReviewUpdate, IfspPeriodicAnnualReview, IfspReviewType, PeriodicReviewUpdate } from 'src/app/ifsp/models/ifsp';
import { IfspSurvey } from 'src/app/ifsp/models/ifsp-survey';
import { OutcomeReviewDto, TypeOfOutcomeReview } from 'src/app/ifsp/models/outcome-models';
import { IfspModificationService } from 'src/app/ifsp/services/ifsp-modification.service';
import { IfspService } from 'src/app/ifsp/services/ifsp.service';
import { AppPermissions } from 'src/app/permissions';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { yesNoOptions } from 'src/app/shared/formHelpers';
import { GeneralSurveyGeneralSurveyQuestion } from 'src/app/shared/models/general-survey/general-survey-general-survey-question';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { DeleteConfirmationComponent } from '../../modals/delete-confirmation-modal/delete-confirmation-modal.component';
import { DeactivationService } from '../../services/deactivation.service';
import { ReportingService } from '../../services/reporting/reporting.service';
import { openPdfWindow } from '../../windowHelpers';
import { BaseComponent } from '../base-component/base-component';

@Component({
  selector: 'app-annual-review-questionnaire',
  templateUrl: './annual-review-questionnaire.component.html',
  styleUrls: ['./annual-review-questionnaire.component.scss'],
})
export class AnnualReviewQuestionnaireComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() isPeriodicReview = false;
  @Input() learnerName: string;
  @Input() ifspId: string;
  @Input() ifspReviewId: string;
  @Input() evaluationId: string;
  @Input() hasFinalizedEvaluation = false;
  @Input() caseId: string;
  @Input() surveys: IfspSurvey[];
  @Input() surveysByQuestion: IfspSurvey[];
  @Input() totalSurveysSent: number;
  @Input() outcomeReviews: OutcomeReviewDto[] = [];
  @Input() learnerId: string;
  @Output() annualReviewCompleted = new EventEmitter();
  @Output() reviewCancelled = new EventEmitter();

  basedOnOptions: KeyValuePair[] = [
    new KeyValuePair('QuarterOrMore', '25% Delay or Greater'),
    new KeyValuePair('DocumentedDelay', 'Documented Delay or Diagnosis'),
    new KeyValuePair('InformedClinicalOpinion', 'Informed Clinical Opinion'),
  ];
  yesNoOptions: KeyValuePair[] = yesNoOptions;
  viewByOptions: KeyValuePair[] = [
    new KeyValuePair('teamMember', 'Team Member', true),
    new KeyValuePair('questionnaireQuestion', 'Question'),
  ];
  outcomeReviewOptions: KeyValuePair[] = [
    new KeyValuePair(TypeOfOutcomeReview.Continue, 'Continue as planned'),
    new KeyValuePair(TypeOfOutcomeReview.Discontinue, 'Discontinue this outcome'),
    new KeyValuePair(TypeOfOutcomeReview.Met, 'Outcome met'),
    new KeyValuePair(TypeOfOutcomeReview.Revise, 'Revise this outcome'),
  ];
  finalizeDisabled = false;
  formGroup = new FormGroup({
    periodicReviewSummary: new FormControl('', Validators.required),
    closedOn: new FormControl(null),
  });
  displayedColumns = ['question', 'response'];
  dataSource = new MatTableDataSource([
    {
      question: 'Some Question',
      response: 'Some Response',
    },
  ]);
  notSubmittedSurveys = [];
  latestReview: IfspPeriodicAnnualReview;
  periodicReviewOptions: KeyValuePair[] = [
    new KeyValuePair(false, 'No concerns and no IFSP modifications needed'),
    new KeyValuePair(true, 'IFSP modifications complete'),
  ];
  viewById = 'teamMember';
  notes: EvaluationNote[] = [];
  hasOpenNotes = false;
  completedSurveys = 0;
  reviewName = 'Annual';
  dataLoaded = false;

  save = new Observable<boolean>((observer) => {
    this.cd.detectChanges();
    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };
    if (!this.formGroup.dirty || this.isSaving) {
      done();
      return;
    }
    this.isSaving = true;
    this.latestReview = { ...this.latestReview, ...this.formGroup.value };
    this.latestReview.ifspPeriodicAnnualReviewOutcomes?.forEach((outcomeReview) => {
      outcomeReview.typeOfOutcomeReview = this.formGroup.controls[outcomeReview.ifspOutcomeId].value;
    });
    this.ifspService.updateAnnualReview(this.ifspReviewId, this.latestReview).subscribe(() => {
      this.formGroup.markAsPristine();
      done();
    });
  });

  private surveyDataSources = new Map<string, MatTableDataSource<GeneralSurveyGeneralSurveyQuestion>>();
  private validateForm = new Subject();

  get canView() {
    return this.authService.isReadOnlyByCaseId(this.caseId, AppPermissions.ViewPeriodicReview);
  }

  constructor(
    private ifspService: IfspService,
    private dialog: MatDialog,
    private readonly router: Router,
    private routingService: RoutingService,
    private evaluationNoteService: EvaluationNoteService,
    private ifspModificationService: IfspModificationService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private readonly cd: ChangeDetectorRef,
    private reportingService: ReportingService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    if (this.isPeriodicReview) {
      this.reviewName = 'Periodic';
    }
    this.setControlsBasedOnReviewType();
    this.getReview();
  }

  setControlsBasedOnReviewType() {
    if (this.isPeriodicReview) {
      this.formGroup.addControl('ifspModificationsComplete', new FormControl('', Validators.required));
    }
  }

  goToOutcomes(event: MouseEvent) {
    event.stopPropagation();

    this.ifspModificationService.getModificationsByIfspId(this.ifspId).subscribe((modifcations) => {
      const modification = modifcations.find((x) => !x.finalizeDate);
      if (!modification) {
        // does not have modifications
        const dialogRef = this.dialog.open(IfspModificationModalComponent, {
          width: '760px',
          data: {
            ifspId: this.ifspId,
            caseId: this.caseId,
          },
        });
        dialogRef.afterClosed().subscribe((closeResult) => {
          if (closeResult) {
            this.router.navigate(['cases', this.caseId, 'ifsp', this.ifspId, 'details']);
          }
        });
      } else {
        const queryParams = { expanded: 'Outcomes' };
        this.router.navigate(['/', 'cases', this.caseId, 'ifsp', this.ifspId, 'details'], { queryParams });
      }
    });
  }
  modifyIfspOutcome(outcomeId: string) {
    this.ifspModificationService.getModificationsByIfspId(this.ifspId).subscribe((modifcations) => {
      const modification = modifcations.find((x) => !x.finalizeDate);
      if (!modification) {
        // does not have modifications
        const dialogRef = this.dialog.open(IfspModificationModalComponent, {
          width: '760px',
          data: {
            ifspId: this.ifspId,
            caseId: this.caseId,
          },
        });
        dialogRef.afterClosed().subscribe((closeResult) => {
          if (closeResult) {
            this.router.navigate(['cases', this.caseId, 'ifsp', this.ifspId, 'details']);
          }
        });
      } else {
        this.router.navigate(['/', 'cases', this.caseId, 'ifsp', this.ifspId, 'child-family-outcomes', outcomeId]);
      }
    });
  }

  modifyIfsp() {
    this.ifspModificationService.getModificationsByIfspId(this.ifspId).subscribe((modifcations) => {
      const modification = modifcations.find((x) => !x.finalizeDate);
      if (!modification) {
        // does not have modifications
        const dialogRef = this.dialog.open(IfspModificationModalComponent, {
          width: '760px',
          data: {
            ifspId: this.ifspId,
            caseId: this.caseId,
          },
        });
        dialogRef.afterClosed().subscribe((closeResult) => {
          if (closeResult) {
            this.router.navigate(['cases', this.caseId, 'ifsp', this.ifspId, 'details']);
          }
        });
      } else {
        this.router.navigate(['cases', this.caseId, 'ifsp', this.ifspId, 'details']);
      }
    });
  }

  openPartCTeamMembersSurvey() {
    this.reportingService.createPartCTeamMembersSurvey(this.caseId).subscribe({
      next: (documentId: string) => openPdfWindow(this.learnerId, documentId),
      error: (err) => this.reportingService.handleOutputError(err),
    });
  }

  getSurveys(): IfspSurvey[] {
    return this.viewById === 'teamMember' ? this.surveys : this.surveysByQuestion;
  }

  public getOrCreateSurveyDataSource(
    surveyId: string,
    dtos: GeneralSurveyGeneralSurveyQuestion[]
  ): MatTableDataSource<GeneralSurveyGeneralSurveyQuestion> {
    if (this.surveyDataSources.has(surveyId)) {
      return this.surveyDataSources.get(surveyId);
    } else {
      const ds = this.convertSurveyDtos(dtos);
      this.surveyDataSources.set(surveyId, ds);
      return ds;
    }
  }

  convertSurveyDtos(dtos: GeneralSurveyGeneralSurveyQuestion[]) {
    dtos.sort((x, y) => (x.generalSurveyQuestionDto.order > y.generalSurveyQuestionDto.order ? 1 : -1));
    dtos.forEach((x) => {
      x.generalSurveyQuestionDto.question = this.replaceVars(x.generalSurveyQuestionDto.question);
    });
    return new MatTableDataSource(
      dtos.filter(
        (x) =>
          (x.generalSurveyAnswerDto?.answer?.trim() !== '' && x.generalSurveyAnswerDto?.answer !== null) ||
          !x.generalSurveyQuestionDto.enableCondition
      )
    );
  }

  updateTotalSurveysSent() {
    this.totalSurveysSent = this.latestReview.generalSurveyUserFullNameDtos.filter(
      (surveySent, index, survey) => index === survey.findIndex((t) => t.userFullName === surveySent.userFullName)
    )?.length;
  }

  updateSurveyResults() {
    forkJoin([this.ifspService.getReview(this.ifspReviewId)]).subscribe(([review]) => {
      this.latestReview = review;
      if (this.latestReview) {
        this.formGroup.patchValue(this.latestReview);
        this.updateServiceOutcomes();
      }

      this.updateTotalSurveysSent();

      this.completedSurveys = this.latestReview.generalSurveyUserFullNameDtos.filter((x) => x.generalSurveyDto.completedDateTime).length;

      this.surveys = this.latestReview.generalSurveyUserFullNameDtos.filter((x) => x.generalSurveyDto.completedDateTime);

      this.surveysByQuestion = this.ifspService.convertSurveysToByQuestion(this.surveys, this.learnerName);
      // Remove duplicate questions
      this.surveysByQuestion = this.surveysByQuestion.filter(
        (questionFromSecondArray, index, questionFromFirstArray) =>
          index === questionFromFirstArray.findIndex((t) => t.generalSurveyDto.id === questionFromSecondArray.generalSurveyDto.id)
      );
    });
  }

  updateServiceOutcomes() {
    this.latestReview.ifspPeriodicAnnualReviewOutcomes?.forEach((reviewOutcome) => {
      const serviceOutcome = this.outcomeReviews.find((x) => x.id === reviewOutcome.ifspOutcomeId);
      if (serviceOutcome) {
        reviewOutcome.outcomeTitle = serviceOutcome.title;
      }
      this.formGroup.addControl(reviewOutcome.ifspOutcomeId, new FormControl(reviewOutcome.typeOfOutcomeReview, Validators.required));
    });
  }

  getReview() {
    this.ifspService.getReview(this.ifspReviewId).subscribe((review) => {
      this.latestReview = review;
      if (this.latestReview) {
        this.updateServiceOutcomes();
        this.formGroup.patchValue(this.latestReview);
        if (this.latestReview.closedOn) {
          this.formGroup.disable();
          this.finalizeDisabled = true;
        }
        this.completedSurveys = this.latestReview.generalSurveyUserFullNameDtos.filter((x) => x.generalSurveyDto.completedDateTime).length;
        this.surveys = this.latestReview.generalSurveyUserFullNameDtos.filter((x) => x.generalSurveyDto.completedDateTime);
        this.surveysByQuestion = this.ifspService.convertSurveysToByQuestion(this.surveys, this.learnerName);

        // Remove duplicate survey questions
        this.surveysByQuestion = this.surveysByQuestion.filter(
          (surveyQuestionSecondArray, index, surveyQuestionFirstArray) =>
            index === surveyQuestionFirstArray.findIndex((t) => t.generalSurveyDto.id === surveyQuestionSecondArray.generalSurveyDto.id)
        );

        // Remove duplicates of the same survey
        this.latestReview.generalSurveyUserFullNameDtos = this.latestReview.generalSurveyUserFullNameDtos.filter(
          (surveySent, index, survey) => index === survey.findIndex((t) => t.userFullName === surveySent.userFullName)
        );

        // Remove duplicate survey questions
        this.surveysByQuestion = this.surveysByQuestion.filter(
          (surveyQuestionSecondArray, index, surveyQuestionFirstArray) =>
            index === surveyQuestionFirstArray.findIndex((t) => t.generalSurveyDto.id === surveyQuestionSecondArray.generalSurveyDto.id)
        );

        // Remove duplicates of the same survey
        this.latestReview.generalSurveyUserFullNameDtos = this.latestReview.generalSurveyUserFullNameDtos.filter(
          (surveySent, index, survey) => index === survey.findIndex((t) => t.userFullName === surveySent.userFullName)
        );

        this.updateTotalSurveysSent();

        this.notSubmittedSurveys = this.latestReview.generalSurveyUserFullNameDtos
          .filter((x) => x.generalSurveyDto.completedDateTime === null)
          .map((x) => x.userFullName);

        this.saveSubscription = this.formGroup.valueChanges;
        this.startAutosaving();
      }

      if (this.isPeriodicReview) {
        if (!this.canView) {
          this.formGroup.get('periodicReviewSummary')?.disable();
          this.formGroup.get('ifspModificationsComplete')?.disable();
          this.latestReview?.ifspPeriodicAnnualReviewOutcomes.forEach((outcomeReview) => {
            this.formGroup.get(outcomeReview.ifspOutcomeId)?.disable();
          });
        }
      }
      this.dataLoaded = true;
      this.cd.detectChanges();
    });
  }

  replaceVars(template: string): string {
    let firstname = 'child name';
    const nameSplit = this.learnerName.split(' ');
    if (nameSplit && nameSplit.length > 0) {
      firstname = nameSplit[0].trim();
    }
    return template.replace('{ChildName}', firstname);
  }

  getFinalReviewData(): IfspPeriodicAnnualReview {
    const updatedReview: IfspPeriodicAnnualReview = {
      ...this.latestReview,
      ...this.formGroup.value,
    };
    updatedReview.ifspPeriodicAnnualReviewOutcomes?.forEach((outcomeReview) => {
      outcomeReview.typeOfOutcomeReview = this.formGroup.controls[outcomeReview.ifspOutcomeId].value;
    });
    return updatedReview;
  }

  getFinalAnnualReviewStatusUpdate(): AnnualReviewUpdate {
    const reviewUpdate: AnnualReviewUpdate = {
      reviewActive: false,
      annualReviewDate: new Date(),
      ifspType: IfspReviewType.Annual,
    };
    return reviewUpdate;
  }

  getFinalPeriodicReviewStatusUpdate(): PeriodicReviewUpdate {
    const reviewUpdate: PeriodicReviewUpdate = {
      periodicReviewActive: false,
      periodicReviewDate: new Date(),
    };
    return reviewUpdate;
  }

  saveFinalAnnualReview() {
    const review = this.getFinalReviewData();
    this.ifspService.updateAnnualReview(this.ifspReviewId, review).subscribe((res) => {
      const statusUpdate = this.getFinalAnnualReviewStatusUpdate();
      this.ifspService.updateReviewStatus(this.ifspId, statusUpdate).subscribe(() => {
        this.annualReviewCompleted.emit();
        this.stopAutosaving();
        if (this.latestReview) {
          this.formGroup.disable();
          this.formGroup.markAsPristine();
          this.isSaving = false;
          this.finalizeDisabled = true;
          this.latestReview.closedOn = new Date();
        }
      });
    });
  }

  saveFinalPeriodicReview() {
    const review = this.getFinalReviewData();
    review.closedOn = new Date();
    this.ifspService.updateAnnualReview(this.ifspReviewId, review).subscribe((res) => {
      const statusUpdate = this.getFinalPeriodicReviewStatusUpdate();
      this.ifspService.updatePeriodicReview(this.ifspId, statusUpdate).subscribe(() => {
        this.stopAutosaving();
        if (this.latestReview) {
          this.formGroup.disable();
          this.formGroup.markAsPristine();
          this.isSaving = false;
          this.finalizeDisabled = true;
          this.latestReview.closedOn = new Date();
        }
      });
    });
  }

  closeReview() {
    this.isSaving = true;

    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion: `Clicking Yes will Finalize and lock the ${this.reviewName} Review.`,
      },
    });
    dialogRef.afterClosed().subscribe((ok) => {
      if (ok) {
        if (this.isPeriodicReview) {
          this.saveFinalPeriodicReview();
        } else {
          this.saveFinalAnnualReview();
        }
      } else {
        this.isSaving = false;
      }
    });
  }

  get canCloseAnnualReview() {
    return this.formGroup.get('additionalInformationNeeded').valid && this.formGroup.get('stillEligible').valid;
  }

  sendPeriodicReviewPoll() {
    this.ifspService.sendPeriodicReviewPoll(this.ifspId).subscribe(() => {
      this.notificationService.success('Questionnaire Sent!');
    });
  }

  sendAnnualReviewPoll() {
    this.ifspService.sendAnnualReviewPoll(this.ifspId).subscribe(() => {
      this.notificationService.success('Questionnaire Sent!');
    });
  }

  scheduleMeeting(): void {
    this.routingService.openScheduleMeeting(this.learnerId);
  }

  refreshSurveys(): void {
    this.updateSurveyResults();
  }

  onDelete() {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      width: '550px',
      data: {
        question: `Clicking Yes will delete this ${this.reviewName.toLowerCase()} review. Please fill in the reason for the deletion to continue.`,
      },
    });
    dialogRef.afterClosed().subscribe((val) => {
      if (val?.delete) {
        this.stopAutosaving();
        this.ifspService.cancelReview(this.ifspReviewId, val?.cancelReason).subscribe(() => {
          this.reviewCancelled.emit();
        });
      }
    });
  }

  onReviewEvaluationAndAssessmentData() {
    this.router.navigate(['cases', this.caseId, 'evaluation', this.evaluationId, 'overview']);
  }
}
