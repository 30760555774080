import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OperationResult } from 'src/app/behavior-discipline/models/bip';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { ServiceActivityType } from '../../shared/models/service-activity-type';
import { IepGoal } from '../models/iep';

@Injectable({
  providedIn: 'root',
})
export class IepGoalService {
  private goalsUpdated = new Subject<void>();
  goalsUpdated$ = this.goalsUpdated.asObservable();

  public goalClose = new Subject<void>();
  goalClose$ = this.goalClose.asObservable();

  public goalAddNew = new Subject<void>();
  goalAddNew$ = this.goalAddNew.asObservable();

  public setAmendingGoal = new Subject<any>();
  setAmendingGoal$ = this.setAmendingGoal.asObservable();

  public setAmendingGoalTest = new Subject<any>();
  setAmendingGoalTest$ = this.setAmendingGoalTest.asObservable();

  constructor(private readonly http: HttpClient, spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/.*\/iepgoal.*/));
  }

  private baseUrl(iepId: string, id = '') {
    return `api/${iepId}/iepgoal/${id}`;
  }

  getIepGoal(iepId: string, id: string) {
    return this.http.get<IepGoal[]>(this.baseUrl(iepId, id));
  }

  getSASTypes(iepId: string) {
    return this.http.get<ServiceActivityType[]>(this.baseUrl(iepId) + 'getSASTypes');
  }

  getAllIepGoals(iepId: string) {
    return this.http.get<IepGoal[]>(this.baseUrl(iepId));
  }

  getCompletedIepGoals(iepId: string) {
    return this.http.get<IepGoal[]>(`${this.baseUrl(iepId)}completed`);
  }

  getAllForAmendment(iepId: string, amendmentId = '', isCompleted?: boolean) {
    amendmentId ||= '';

    let params = new HttpParams();
    if (isCompleted) params = params.set('isCompleted', isCompleted);

    return this.http.get<IepGoal[]>(this.baseUrl(iepId) + `amend/${amendmentId}`, { params });
  }

  getAuditLogs(iepId: string, id: string) {
    return this.http.get<any[]>(this.baseUrl(iepId) + `${id}/getAuditLogs`);
  }

  update(iepId: string, model: any) {
    return this.http.put<OperationResult>(this.baseUrl(iepId), model).pipe(tap(() => this.goalsUpdated.next()));
  }

  updateProviders(iepId: string, id: string, model: any) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/updateProviders', model);
  }

  amend(iepId: string, id: string, amendmentId: string) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/amend/' + amendmentId, null).pipe(tap(() => this.goalsUpdated.next()));
  }

  endGoal(iepId: string, id: string, model: any) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/end', model).pipe(tap(() => this.goalsUpdated.next()));
  }

  delete(iepId: string, id: string, isAmendment = false) {
    return this.http.put(this.baseUrl(iepId, id) + '/' + isAmendment, null).pipe(tap(() => this.goalsUpdated.next()));
  }
}
