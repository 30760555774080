<div class="display-flex align-items-center justify-content-end mb-2">
  <button
    *ngIf="showCreateInitialIfspServicesConsent || showCreateNewIfspServicesConsent"
    mat-flat-button
    aria-label="Add Consent for Early ACCESS Services"
    color="accent"
    (click)="createConsentForIfspServices(consentFormTypeEnum.EarlyAccessServices)"
    tabindex="0"
  >
    Add Consent for Early ACCESS Services
  </button>
</div>
<section
  [ngClass]="{
    'mt-2': !showCreateInitialIfspServicesConsent && !showCreateNewIfspServicesConsent
  }"
>
  <app-family-consent
    [isCaseOwner]="isCaseOwner"
    [learnerId]="caseSummary.learnerId"
    [caseId]="caseSummary.caseId"
    [types]="types"
    (consentFormsLoaded)="onConsentFormsLoaded($event)"
    [canAddNew]="false"
    [inCard]="true"
    [psgProvided]="psgProvided"
    [maxAmount]="maxAmount"
  ></app-family-consent>
</section>
