<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Share Case</h3>

<div mat-dialog-content>
  <h3>Learner Information</h3>
  <app-learner-info-header [learnerId]="currentCase?.learnerId"></app-learner-info-header>

  <ng-container *ngIf="!showSharedUsers">
    <div
      [ngClass]="{
        'mt-5': currentCase && currentCase.intakeType === intakeType.PartC
      }"
    >
      <app-service-coordinators-transfer
        *ngIf="currentCase && currentCase.intakeType === intakeType.PartC"
        [currentCase]="currentCase"
        [multiSelect]="true"
        [hideHeaderCard]="true"
        (selected)="onScSelectionChanged($event)"
      ></app-service-coordinators-transfer>
    </div>
    <app-aea-transfer
      *ngIf="aeas && districts"
      [multiSelect]="true"
      [aeas]="aeas"
      [districts]="districts"
      [buildings]="buildings"
      [aeaUsers]="aeaUsers"
      (search)="onSearch($event)"
      (selected)="onAeaSelectionChanged($event)"
    ></app-aea-transfer>

    <ng-container *ngIf="currentCase && currentCase.caseUsers.length > 0">
      <app-shared-users
        [caseId]="currentCase.caseId"
        [caseUsers]="currentCase.caseUsers"
        [intakeType]="currentCase.intakeType"
      ></app-shared-users>
    </ng-container>
    <div class="divider mb-4"></div>
  </ng-container>

  <ng-container *ngIf="showSharedUsers">
    <h3 class="mb-2 card-spacing-top">This case has been shared with the following users:</h3>
    <mat-card class="mt-0">
      <ul class="list">
        <li class="list__item" *ngFor="let user of sharedUsersNames">
          {{ user }}
        </li>
      </ul>
    </mat-card>
  </ng-container>
</div>

<div mat-dialog-action class="display-flex justify-content-end align-items-center">
  <div class="display-flex mt-4">
    <ng-container *ngIf="!showSharedUsers">
      <button mat-raised-button aria-label="Cancel" class="background-color--muted mr-2" (click)="onClose()" cdkFocusInitial tabindex="0">
        Cancel
      </button>
      <button
        *ngIf="!!currentCase && currentCase.intakeType === intakeType.PartC && !dmpsChscShareHidden && !!dmpsUser"
        mat-raised-button
        aria-label="Share with DMPS"
        color="primary"
        class="mr-2"
        [disabled]="dmpsChscShareDisabled"
        (click)="onDMPSShare()"
        cdkFocusInitial
        tabindex="0"
      >
        Share with DMPS
      </button>
      <button
        mat-raised-button
        aria-label="Share Case"
        color="primary"
        (click)="onShare()"
        [isBusy]="activeCall"
        [disabled]="!selectedAeaUsers?.length && !selectedScUsers?.length"
        tabindex="0"
      >
        Share Case
      </button>
    </ng-container>

    <ng-container *ngIf="showSharedUsers">
      <button mat-raised-button aria-label="Ok Button" color="primary" (click)="onClose()" tabindex="0">Ok</button>
    </ng-container>
  </div>
</div>
