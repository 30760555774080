import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { AdditionalConcernsInformation } from '../../../models/health-information';

@Component({
  selector: 'app-additional-concerns-info',
  templateUrl: './additional-concerns-info.component.html',
  styleUrls: ['./additional-concerns-info.component.scss'],
})
export class AdditionalConcernsInfoComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() locked: boolean;
  @Input() additionalConcernsInfo: AdditionalConcernsInformation;

  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  constructor() {}

  ngOnInit(): void {
    this.initializeControls();
    if (this.additionalConcernsInfo) {
      this.formGroup.patchValue(this.additionalConcernsInfo);
    }
    this.setFormChanges('additionalHealthConcern', 'additionalHealthConcernDescription');
    this.setFormChanges('additionalChildConcern', 'additionalChildConcernDescription');
    this.setFormChanges('specialEquipment', 'specialEquipmentDescription');
  }

  setFormChanges(firstControl: string, secondControl: string) {
    this.formGroup.controls[firstControl].valueChanges.subscribe((change) => {
      if (change) {
        this.setRequiredValidator(secondControl);
      } else {
        this.setNullToControl(secondControl);
      }
    });
  }

  setNullToControl(controlName: string) {
    this.formGroup.controls[controlName].setValue(null);
    this.formGroup.controls[controlName].setValidators(null);
    this.formGroup.controls[controlName].updateValueAndValidity();
  }

  setRequiredValidator(controlName: string) {
    this.formGroup.controls[controlName].setValidators(Validators.required);
    this.formGroup.controls[controlName].updateValueAndValidity();
  }

  private initializeControls() {
    this.formGroup.addControl(
      'additionalHealthConcern',
      new FormControl(null, {
        updateOn: 'change',
        validators: Validators.required,
      })
    );
    this.formGroup.addControl('additionalHealthConcernDescription', new FormControl(null));
    this.formGroup.addControl(
      'additionalChildConcern',
      new FormControl(null, {
        updateOn: 'change',
        validators: Validators.required,
      })
    );
    this.formGroup.addControl('additionalChildConcernDescription', new FormControl(null));
    this.formGroup.addControl(
      'specialEquipment',
      new FormControl(null, {
        updateOn: 'change',
        validators: Validators.required,
      })
    );
    this.formGroup.addControl('specialEquipmentDescription', new FormControl(null));
  }
}
