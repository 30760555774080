import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveSystemMessage, SystemMessage } from '../models/system-message';

@Injectable({
  providedIn: 'root',
})
export class SystemMessageService {
  baseUrl = 'api/system-messages';
  constructor(private http: HttpClient) {}

  getAll(): Observable<SystemMessage[]> {
    return this.http.get<SystemMessage[]>(`${this.baseUrl}`);
  }

  getActive(): Observable<ActiveSystemMessage> {
    return this.http.get<ActiveSystemMessage>(`${this.baseUrl}/active`);
  }

  add(msg: SystemMessage): Observable<SystemMessage> {
    return this.http.post<SystemMessage>(this.baseUrl, msg);
  }

  update(msg: SystemMessage): Observable<SystemMessage> {
    return this.http.put<SystemMessage>(`${this.baseUrl}/${msg.id}`, msg);
  }

  delete(id: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/delete/${id}`, null);
  }
}
