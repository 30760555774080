import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin } from 'rxjs';
import { WeightedMatrixLevelDto, WeightedMatrixScoreHistoryDto } from '../../../iep/models/iep-weighted-matrix';
import { IepDetailWeightedMatrixService } from '../../../iep/services/iep-detail-weighted-matrix.service';
import { shortDateFormat } from '../../dateTimeHelpers';
import { ViewMoreModalData } from '../../modals/view-more-modal/view-more-modal.component';
import { User } from '../../models/user';
import { NotificationService } from '../../services/notification.service';
import { openViewMore } from '../../tableHelpers';

@Component({
  selector: 'app-weight-matrix-score-history',
  templateUrl: './weight-matrix-score-history.component.html',
  styleUrls: ['./weight-matrix-score-history.component.scss'],
})
export class WeightMatrixScoreHistoryComponent implements OnInit, OnChanges {
  @Input() scoreHistory: WeightedMatrixScoreHistoryDto[] = [];
  @Input() weightedMatrixLevels: WeightedMatrixLevelDto[] = [];
  @Input() caseId: string;
  @Input() readOnly = false;
  @Input() isLmIepPage = false;

  constructor(
    private notificationService: NotificationService,
    private weightedMatrixService: IepDetailWeightedMatrixService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  dataSource = new MatTableDataSource([]);
  displayedColumns = ['actions', 'iepDate', 'actualScore', 'level', 'updatedBy', 'updatedOn', 'comments'];
  shortDateFormat = shortDateFormat;

  ngOnInit(): void {
    if (!this.caseId) {
      this.caseId = this.route.snapshot.paramMap.get('caseId');
    }

    if (this.isLmIepPage) {
      this.displayedColumns = ['iepDate', 'level'];
    }

    if (this.caseId && (!this.scoreHistory || this.scoreHistory.length === 0)) {
      forkJoin([
        this.weightedMatrixService.getWeightedMatrixScoreHistory(this.caseId),
        this.weightedMatrixService.getWeightedLevels(this.caseId),
      ]).subscribe(([scoreHistoryResponse, levelResponse]) => {
        this.scoreHistory = scoreHistoryResponse.value;
        this.weightedMatrixLevels = levelResponse.value;
        this.dataSource.data = this.scoreHistory;
      });
    }

    this.dataSource.data = this.scoreHistory;
    this.dataSource.sortingDataAccessor = (item: WeightedMatrixScoreHistoryDto, columnId) => {
      switch (columnId) {
        case 'level':
          return this.getLevel(item.weightedMatrixLevelId);
        case 'updatedBy':
          return this.getUpdatedBy(item.updatedBy);
        default:
          return item[columnId];
      }
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scoreHistory) {
      this.dataSource.data = this.scoreHistory;
    }
  }

  getLevel(weightedMatrixLevelId: string) {
    return this.weightedMatrixLevels?.find((level) => level.id === weightedMatrixLevelId)?.level;
  }

  getUpdatedBy(updatedBy: User) {
    return updatedBy.firstName + ' ' + updatedBy.lastName;
  }

  refreshHistory() {
    this.weightedMatrixService.getWeightedMatrixScoreHistory(this.caseId).subscribe((scoreHistoryResponse) => {
      if (scoreHistoryResponse.succeeded) {
        this.scoreHistory = scoreHistoryResponse.value;
        this.dataSource.data = this.scoreHistory;
      } else {
        this.notificationService.error('Failed to retrieve score history.');
      }
    });
  }

  onViewMore(item: WeightedMatrixScoreHistoryDto) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'IEP Date',
        value: item.iepDate ? dayjs(item.iepDate).format('M/D/YYYY') : '',
      },
      {
        name: 'Score',
        value: item.hasSpecialCircumstance ? 'Special Circumstance' : item.actualScore === 0 ? '0' : item.actualScore,
      },
      {
        name: 'Level',
        value: this.getLevel(item.weightedMatrixLevelId),
      },
      {
        name: 'Updated By',
        value: this.getUpdatedBy(item.updatedBy),
      },
      {
        name: 'Updated On',
        value: item.updatedOn ? dayjs(item.updatedOn).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        name: 'Comments',
        value: item.comments,
      },
    ];
    openViewMore(this.dialog, modalData);
  }
}
