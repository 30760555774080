<mat-card>
  <p>
    <strong>
      <a class="text-underline" (click)="onOpenHelp($event)" tabindex="0" role="link" aria-labelledby="Domains to be evaluated">Domains</a>
      to be evaluated:
    </strong>
    {{ evaluation.assignedDomainNames.length > 0 ? evaluation.assignedDomainNames.join(', ') : 'Please add domain assignments below...' }}
  </p>
  <ng-container *ngIf="showSldCheckbox()">
    <mat-checkbox [formControl]="consideringSld" aria-labelledby="Check me!"> </mat-checkbox>
    The learner is also being evaluated to determine if a
    <a
      class="text-underline"
      (click)="onOpenHelpText($event, helpSection.EvaluationAf, evaluationAfHelp.Sld)"
      tabindex="0"
      role="link"
      aria-labelledby="Evaluation Help"
    >
      {{ evaluationAfHelp.Sld }}</a
    >
    contributes to their eligibility for special education.
  </ng-container>
</mat-card>
