import { Component, Input, OnChanges } from '@angular/core';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { FamilyMeetingRead, IntakeType } from 'src/app/shared/models/case';
import { LearnerSummary } from 'src/app/shared/models/learner';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { FamilyMeetingService } from 'src/app/learner-management/family-meeting/family-meeting.service';
import dayjs from 'dayjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-ifsp-learner-info-header',
  templateUrl: './ifsp-learner-info-header.component.html',
  styleUrls: ['./ifsp-learner-info-header.component.scss'],
})
export class IfspLearnerInfoHeaderComponent implements OnChanges {
  @Input()
  learnerId: string;

  @Input()
  learnerSummary: LearnerSummary;

  @Input()
  noCard = false;

  @Input()
  fewFields = false;

  @Input()
  condensed = false;

  @Input() isPrint = false;

  shortDateFormat = shortDateFormat;
  nextMeeting: { label: string; date: string } = {
    label: '--',
    date: '--',
  };

  get serviceCoordinator(): string {
    return (
      this.learnerSummary?.activeCases.find((x) => x.intakeType === IntakeType.PartC || x.intakeType === IntakeType.PartCLate)?.owner ??
      'not assigned'
    );
  }

  constructor(
    private readonly learnerService: LearnerService,
    private readonly datePipe: DatePipe,
    private readonly familyMeetingService: FamilyMeetingService
  ) {}

  ngOnChanges() {
    if (this.learnerId && this.learnerId !== this.learnerSummary?.id) {
      this.learnerService.getLearnerSummary(this.learnerId).subscribe((res) => {
        this.learnerSummary = res;
        this.familyMeetingService.getMeetings(this.learnerId).subscribe((meetings) => {
          this.calculateNextMeeting(meetings);
        });
      });
    }
  }

  private calculateNextMeeting(meetings: FamilyMeetingRead[]) {
    const initialifspMtng = meetings.find((m) => m.isInitialIfsp);
    if (initialifspMtng) {
      const initialMeetingDate = dayjs(initialifspMtng.dateAndTime);
      const monthsSinceInitial = dayjs().diff(initialMeetingDate, 'month');
      const nextMeeting =
        monthsSinceInitial <= 0
          ? {
              label: 'Initial',
              date: this.datePipe.transform(initialMeetingDate.toDate(), shortDateFormat),
            }
          : monthsSinceInitial < 6
          ? {
              label: 'Periodic',
              date: this.datePipe.transform(initialMeetingDate.add(6, 'month').toDate(), shortDateFormat),
            }
          : monthsSinceInitial < 12
          ? {
              label: 'Annual',
              date: this.datePipe.transform(initialMeetingDate.add(12, 'month').toDate(), shortDateFormat),
            }
          : monthsSinceInitial < 18
          ? {
              label: 'Periodic',
              date: this.datePipe.transform(initialMeetingDate.add(18, 'month').toDate(), shortDateFormat),
            }
          : monthsSinceInitial < 24
          ? {
              label: 'Annual',
              date: this.datePipe.transform(initialMeetingDate.add(24, 'month').toDate(), shortDateFormat),
            }
          : monthsSinceInitial < 30
          ? {
              label: 'Annual',
              date: this.datePipe.transform(initialMeetingDate.add(30, 'month').toDate(), shortDateFormat),
            }
          : null;

      const thirdBirthday = dayjs(this.learnerSummary.dateOfBirth).add(3, 'year');
      if (!nextMeeting || dayjs(nextMeeting.date).toDate() > thirdBirthday.toDate()) {
        this.nextMeeting = { label: 'None (Aged Out)', date: null };
      } else {
        this.nextMeeting = {
          label: nextMeeting.label,
          date: this.datePipe.transform(nextMeeting.date, shortDateFormat),
        };
      }
    } else {
      this.nextMeeting = {
        label: 'Initial',
        date: this.datePipe.transform(this.learnerSummary.initialDueDate, shortDateFormat),
      };
    }
  }
}
