import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaseService } from '../shared/services/case/case.service';

@Component({
  selector: 'app-monitoring-progress',
  templateUrl: './monitoring-progress.component.html',
  styleUrls: ['./monitoring-progress.component.scss'],
})
export class MonitoringProgressComponent implements OnInit {
  pageTitle: string;
  caseId: string;

  constructor(private route: ActivatedRoute, private caseService: CaseService) {}

  ngOnInit(): void {
    const caseId = this.route.parent.snapshot.paramMap.get('caseId');
    if (caseId) {
      this.caseService.getCaseLearnerName(caseId).subscribe((caseLearner) => {
        this.pageTitle = 'Monitoring for Progress - ' + caseLearner.value;
      });
    } else {
      this.pageTitle = 'Monitoring for Progress';
    }
  }
}
