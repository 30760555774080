import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import {
  AddConsentDialogResult,
  FamilyConsentAddComponent,
} from 'src/app/consent/family-consent/modals/family-consent-add/family-consent-add.component';
import { TodoCreate } from 'src/app/dashboard/user-todo-list/todo-models';
import { TodoService } from 'src/app/dashboard/user-todo-list/todo.service';
import { IntakeSections } from 'src/app/evaluation/early-access-intake/intake-sections/intake-sections';
import { EarlyAccessQuestionnaire } from 'src/app/evaluation/models/early-access-questionnaire';
import { Intake } from 'src/app/evaluation/models/intake';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { StringSizes } from 'src/app/shared/components/form/constants/constants';
import {
  DialogData,
  UploadDocumentationModalComponent,
} from 'src/app/shared/modals/upload-documentation-modal/upload-documentation-modal.component';
import { ConsentForm, consentFormTitlesByType, ConsentFormType } from 'src/app/shared/models/fiie-consent/consent-form';
import { ConsentStatus } from 'src/app/shared/models/fiie-consent/consent-status';
import { FamilyConsentStatus } from 'src/app/shared/models/fiie-consent/family-consent-status';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { usStates } from 'src/app/shared/models/us-states';
import { ConsentFormService } from 'src/app/shared/services/consent-form/consent-form.service';
import { LocationService } from 'src/app/shared/services/location/location.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { noNumbersValidator, phoneValidator } from 'src/app/shared/validators';
import { openPdfWindow } from 'src/app/shared/windowHelpers';
import { SignedFormService } from 'src/app/signed-forms/signed-form.service';
import { ProviderInfoFormService } from '../provider-info-form.service';
import { MedicalSpecialistRead } from './medical-specialist-read';

@Component({
  selector: 'app-medical-specialist-table',
  templateUrl: './medical-specialist-table.component.html',
  styleUrls: ['./medical-specialist-table.component.scss'],
})
export class MedicalSpecialistTableComponent implements OnInit, OnChanges {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('formDirective') private formDirective: NgForm;

  @Input() intake: Intake;
  @Input() questionnaire: EarlyAccessQuestionnaire;
  @Input() isQuestionnaire: boolean;
  @Input() locked: boolean;
  @Input() releaseAndExchangeConsents: ConsentForm[];
  @Input() importQuestionnaireSelected: boolean;
  @Output() documentUploaded = new EventEmitter();

  isEditing = false;

  tableInfo: MedicalSpecialistRead[] = [];
  dataSource: MatTableDataSource<MedicalSpecialistRead>;
  submitAttempted: boolean;
  usStates = usStates.map((x) => new KeyValuePair(x, x));
  questionnaireMedSpecsNotImported: MedicalSpecialistRead[];
  stringSizes = StringSizes;
  get isValid() {
    return this.formGroup.valid;
  }

  constructor(
    private fb: FormBuilder,
    private providerService: ProviderInfoFormService,
    private locationService: LocationService,
    private dialog: MatDialog,
    private consentFormService: ConsentFormService,
    private readonly notificationService: NotificationService,
    private todoService: TodoService,
    private authService: AuthService,
    private reportingService: ReportingService,
    private signedFormService: SignedFormService,
    private cd: ChangeDetectorRef
  ) {}

  displayedColumns = ['name', 'areaOfSpecialty', 'address', 'zipCode', 'city', 'state', 'phoneNumber'];

  formGroup = this.fb.group({
    name: [null, [Validators.required]],
    areaOfSpecialty: [null, noNumbersValidator],
    address: [null],
    city: [null],
    state: [null],
    zipCode: [null, Validators.pattern(/(^\d{5}$)|(^\d{5}-\d{4}$)/)],
    phoneNumber: [null, phoneValidator],
  });

  private emptyRow = {
    id: null,
    learnerId: null,
    earlyAccessQuestionnaireId: null,
    name: null,
    areaOfSpecialty: null,
    address: null,
    city: null,
    state: null,
    zipCode: null,
    phoneNumber: null,
    medicalSpecialistDocument: null,
    receivedSignedConsentForm: null,
    receivedSignedConsentFormUser: null,
    receivedSignedConsentFormDate: null,
    recordsReceived: null,
    isEditing: false,
  };

  ngOnInit() {
    if (!this.locked) {
      this.displayedColumns.unshift('actions');
    }
    if (this.isQuestionnaire) {
      this.providerService.getQuestionnaireMedicalSpecialist(this.questionnaire?.id).subscribe((res) => {
        res.push(this.emptyRow);
        this.tableInfo = res;
        this.dataSource = new MatTableDataSource<MedicalSpecialistRead>(this.tableInfo);
      });
    } else {
      this.providerService.getIntakeMedicalSpecialist(this.intake?.learner.id).subscribe((res) => {
        if (res && res.length > 0 && res.some((specialist) => specialist.recordsReceived !== null)) {
          this.displayedColumns.push('recordsReceived');
        }
        res.push(this.emptyRow);
        this.tableInfo = res;
        this.dataSource = new MatTableDataSource<MedicalSpecialistRead>(this.tableInfo);
        this.getQuestionnaireSpecialistsNotImported();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.importQuestionnaireSelected?.currentValue !== changes.importQuestionnaireSelected?.previousValue &&
      this.importQuestionnaireSelected
    ) {
      // Cannot append questionnaire until it has patched the parent formgroup
      setTimeout(() => {
        this.appendQuestionnaireMedicalSpecialist();
      }, 1000);
    }
  }

  getQuestionnaireSpecialistsNotImported() {
    if (this.questionnaire?.id) {
      this.providerService.getQuestionnaireMedicalSpecialist(this.questionnaire?.id).subscribe((questionnaireRes) => {
        if (questionnaireRes.length > 0) {
          this.questionnaireMedSpecsNotImported = questionnaireRes.filter(
            (specialistRec) =>
              !this.dataSource.data.some(
                (specialist) =>
                  specialist.name === specialistRec.name &&
                  specialist.address === specialistRec.address &&
                  specialist.city === specialistRec.city &&
                  specialist.state === specialistRec.state &&
                  specialist.zipCode === specialistRec.zipCode &&
                  specialistRec.areaOfSpecialty === specialistRec.areaOfSpecialty
              )
          );
        }
      });
    }
  }

  updateTouchedAndValidity() {
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();
  }

  setEdit(_specialist: any, editing = true) {
    this.isEditing = editing;
    _specialist.isEditing = editing;
    if (!editing) {
      this.formGroup.patchValue({});
      return;
    }
    this.formGroup.patchValue({
      name: _specialist.name,
      areaOfSpecialty: _specialist.areaOfSpecialty,
      address: _specialist.address,
      city: _specialist.city,
      state: _specialist.state,
      zipCode: _specialist.zipCode,
      phoneNumber: _specialist.phoneNumber,
    });
  }

  addOrUpdate(_specialist) {
    this.submitAttempted = true;
    this.updateTouchedAndValidity();
    if (this.isValid) {
      const newSpecialist = this.formGroup.value as MedicalSpecialistRead;
      newSpecialist.id = _specialist.id;
      newSpecialist.learnerId = this.intake?.learner?.id;
      const observables = [];
      if (this.isQuestionnaire) {
        newSpecialist.earlyAccessQuestionnaireId = this.questionnaire.id;
        observables.push(
          this.providerService.addOrUpdateQuestionnaireMedicalSpecialist(newSpecialist).pipe(
            catchError(() => {
              console.log('Error');
              return of(undefined);
            })
          )
        );
      } else {
        observables.push(
          this.providerService.addOrUpdateIntakeMedicalSpecialist(newSpecialist).pipe(
            catchError(() => {
              console.log('Error');
              return of(undefined);
            })
          )
        );
      }
      this.setEdit(_specialist, false);
      this.resetData(observables, true);
    }
  }

  removeRow(medicalSpecialistId: string) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Clicking Yes will remove the medical specialist.',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        const observables = [];
        observables.push(
          this.providerService.deleteMedicalSpecialist(medicalSpecialistId).pipe(
            catchError(() => {
              console.log('Error');
              return of(undefined);
            })
          )
        );
        this.resetData(observables, false);
      }
    });
  }

  resetData(observables, isAdding) {
    if (this.isQuestionnaire) {
      forkJoin(observables).subscribe(() => {
        this.providerService.getQuestionnaireMedicalSpecialist(this.questionnaire?.id).subscribe((res) => {
          res.push(this.emptyRow);
          this.dataSource.data = res;
          this.resetForm(isAdding);
          this.cd.detectChanges();
        });
      });
    } else {
      forkJoin(observables).subscribe(() => {
        this.providerService.getIntakeMedicalSpecialist(this.intake.learner.id).subscribe((intakeMedSpecs) => {
          intakeMedSpecs.push(this.emptyRow);
          this.dataSource.data = intakeMedSpecs;
          this.resetForm(isAdding);
          this.cd.detectChanges();
        });
      });
    }
  }

  resetForm(isAdding) {
    if (isAdding) {
      this.formGroup.reset();
    }
  }

  populateLocationFromZipCode() {
    const zipCodeControl = this.formGroup.get('zipCode');
    const zipCode = zipCodeControl.value;
    if (!zipCodeControl.valid) {
      return;
    }

    this.locationService.getLocationData(zipCode?.substring(0, 5)).subscribe(
      (res) => {
        if (res) {
          this.formGroup.patchValue({
            city: res.city,
            state: 'IA',
          });
        }
      },
      () => {
        this.formGroup.patchValue({
          city: null,
          state: null,
        });
      }
    );
  }

  appendQuestionnaireMedicalSpecialist() {
    if (this.questionnaireMedSpecsNotImported) {
      const observables = [];
      this.questionnaireMedSpecsNotImported.forEach((specialist) => {
        specialist.id = null; // To create a new specialist for intake
        specialist.learnerId = this.intake.learner.id;
        observables.push(
          this.providerService.addOrUpdateIntakeMedicalSpecialist(specialist).pipe(
            catchError(() => {
              console.log('Error');
              return of(undefined);
            })
          )
        );
        this.questionnaireMedSpecsNotImported = null; // Resets import button
      });
      this.resetData(observables, false);
    }
  }

  getConsentToReleaseLabel(specialist: MedicalSpecialistRead): string {
    return this.hasConsentToRelease(specialist)
      ? 'Download Consent to Release and Exchange Information'
      : 'Create Consent to Release and Exchange Information';
  }

  hasConsentToRelease(specialist: MedicalSpecialistRead): boolean {
    const existing = this.findConsentToRelease(specialist);
    return !!existing;
  }

  findConsentToRelease(specialist: MedicalSpecialistRead) {
    if (!specialist || !this.releaseAndExchangeConsents) return null;
    //Try to find a soft match for existing consent to release and exchange for the phcp.
    return this.consentFormService
      .findConsentsForAgency(this.releaseAndExchangeConsents, ConsentFormType.ReleaseAndExchangeInformation, {
        contact: specialist.name,
      })
      .find((cf) => cf.signedFormId);
  }

  getOrCreateConsentToRelease(specialist: MedicalSpecialistRead) {
    const rex = ConsentFormType.ReleaseAndExchangeInformation;

    const existing = this.findConsentToRelease(specialist);
    if (existing) {
      this.viewSignedForm(existing.signedFormId);
      return;
    }

    const dialogRef: MatDialogRef<FamilyConsentAddComponent, AddConsentDialogResult> = this.dialog.open(FamilyConsentAddComponent, {
      width: '800px',
      data: {
        defaultValues: {
          type: { value: rex },
          agency: {
            contact: specialist.name,
            phone: specialist.phoneNumber,
            address: specialist.address,
            city: specialist.city,
            state: specialist.state,
            zipCode: specialist.zipCode,
          },
        },
        availConsentFormTypeOptions: [new KeyValuePair({ value: rex, caseId: this.intake.caseId }, consentFormTitlesByType[rex])],
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const consentForm: ConsentForm = {
          caseId: this.intake.caseId,
          type: data.type.value,
          aeaId: data.aea?.aeaId,
          districtId: data.district?.districtId,
          agency: data.agency,
          statuses: [
            {
              status: data.status,
              dateReceived: data.dateReceived,
              dateSigned: data.dateSigned,
              signedBy: data.signedBy,
              documents: [],
              comments: data.notes,
            },
          ],
          createdOn: new Date(),
          isComplete: false,
        };
        this.consentFormService.createConsentForms(this.intake.caseId, consentForm).subscribe(async (consents) => {
          this.releaseAndExchangeConsents.push(...consents);
          const consent = consents[0];
          const signedFormId = consents && consents[0]?.signedFormId;
          if (signedFormId) {
            this.viewSignedForm(consent.signedFormId);
          }
        });
      }
    });
  }

  async viewSignedForm(signedFormId: string) {
    const pdf = await this.signedFormService.getSignedFormPdf(signedFormId);
    window.open(pdf.url);
  }

  downloadIcdPhysicianLetter(specialist: MedicalSpecialistRead) {
    if (!specialist.medicalSpecialistDocument) {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        data: {
          question: 'Signed Consent Form',
          subQuestion: 'Have you received the signed consent form?',
        },
        width: '450px',
      });

      dialogRef.afterClosed().subscribe((returnedData) => {
        const answer = returnedData;
        if (answer) {
          // Update specialist with records received answer
          const today = new Date();
          specialist.receivedSignedConsentForm = answer;
          specialist.receivedSignedConsentFormDate = today;
          specialist.receivedSignedConsentFormUser = this.authService.user.id;
          this.providerService.addOrUpdateIntakeMedicalSpecialist(specialist).subscribe(() => {
            // Add a to do list item to upload the consent form
            const reminder: TodoCreate = {
              userId: this.authService.user.id,
              caseId: this.intake.caseId,
              title: 'Upload Consent to Release and Exchange',
              showOnCalendar: true,
              dueDate: new Date(today.setDate(today.getDate() + 7)),
            };
            this.todoService.create(reminder).subscribe();
          });

          this.reportingService.createIcdPhysicianLetter(specialist.id).subscribe({
            next: (documentId: string) => openPdfWindow(this.intake.learner.id, documentId),
            error: (err) =>
              this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
                this.notificationService.alert(err.error, "Couldn't open output")
              ),
          });
        }
      });
    }
  }

  indicateRecordsReceived(specialist: MedicalSpecialistRead) {
    specialist.recordsReceived = true;
    this.providerService.addOrUpdateIntakeMedicalSpecialist(specialist).subscribe();
  }

  onOpenUpload(medicalSpecialist: MedicalSpecialistRead) {
    const dialogRef = this.dialog.open(UploadDocumentationModalComponent, {
      data: {
        fileName: 'Consent to Release and Exchange Information',
        type: ConsentFormType.ReleaseAndExchangeInformation,
      } as DialogData,
      width: '728px',
    });

    dialogRef.afterClosed().subscribe((result: Array<any>) => {
      if (result) {
        const formData = new FormData();
        result.forEach((element) => {
          formData.append('titles', element.title);
          formData.append('documents', element.file, element.file.name);
          formData.append('types', element.type);
          formData.append('sections', IntakeSections.ProviderInfo);
          formData.append('consents', 'true');
          formData.append('isPHCPs', 'false');
        });
        this.createConsent(formData, medicalSpecialist.id);
      }
    });
  }

  createConsent(formData: FormData, specialistId: string) {
    const consentForm = {} as ConsentForm;
    consentForm.type = ConsentFormType.ReleaseAndExchangeInformation;
    consentForm.caseId = this.intake?.caseId;
    this.consentFormService.createConsentForm(this.intake?.caseId, consentForm).subscribe((consentFormAdded) => {
      const consentStatus = {} as ConsentStatus;
      consentStatus.consentFormId = consentFormAdded.id;
      consentStatus.status = FamilyConsentStatus.Approved;
      consentStatus.dateSigned = new Date();
      this.consentFormService.addConsentFormStatus(this.intake?.caseId, consentStatus).subscribe();
      this.onUpload(formData, specialistId);
    });
  }

  onUpload(formData: FormData, specialistId: string) {
    const upload = () =>
      this.providerService.uploadMedicalDocumentation(formData, specialistId, this.intake.learner.id).subscribe(
        () => {
          this.notificationService.success('Upload complete');

          // Update UI
          this.documentUploaded.emit();
          this.providerService.getIntakeMedicalSpecialist(this.intake.learner.id).subscribe((res) => {
            this.dataSource.data = res;
            this.resetForm(false);
          });
        },
        (err) => {
          console.log(err);
        }
      );

    if (!!this.intake?.caseId) {
      upload();
    }
  }
}
