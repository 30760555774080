import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { LoggerService } from 'src/app/shared/services/logger/logger.service';
import { TodoRead } from '../todo-models';
import { TodoService } from '../todo.service';

@Component({
  selector: 'app-edit-todo',
  templateUrl: './edit-todo.component.html',
  styleUrls: ['./edit-todo.component.scss'],
})
export class EditTodoComponent implements OnInit {
  today: Date;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditTodoComponent>,
    private todoService: TodoService,
    private authService: AuthService,
    private loggerService: LoggerService,
    @Inject(MAT_DIALOG_DATA) public data: TodoRead
  ) {}

  formGroup = this.fb.group({
    id: [this.data.id],
    userId: [this.authService.user.id],
    title: [this.data.title, [Validators.required]],
    dueDate: [this.data.dueDate, [Validators.required]],
    showOnCalendar: [this.data.showOnCalendar],
  });

  ngOnInit(): void {
    this.today = new Date();
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.todoService.update(this.formGroup.value).subscribe(
        () => this.dialogRef.close(true),
        (errors) => {
          // TODO: should never get here. Wire up global error modal when we
          // have it.
          this.loggerService.error('Error updating User TODO item:');
          this.loggerService.error(errors);
        }
      );
    }
  }
}
