<div class="prefill" *ngIf="useOverwrite">
  <div class="prefill__input">
    <ng-container *ngTemplateOutlet="basic"></ng-container>
  </div>

  <div class="prefill__text" *ngIf="dataSourceLabel">QR: {{ dataSourceLabel }}</div>

  <div class="prefill__container prefill__container--offset" (click)="replaceWithSourceValue()" *ngIf="dataSourceLabel && !undoValue">
    <div class="prefill__icon">
      <mat-icon aria-label="QR Arrow Symbol">call_merge</mat-icon>
    </div>
  </div>

  <div class="prefill__container prefill__container--undo prefill__container--offset" *ngIf="undoValue">
    <p class="prefill__icon mb-0" (click)="undoReplaceWithSourceValue()">Undo</p>
  </div>
</div>

<ng-container *ngIf="!useOverwrite">
  <ng-container *ngTemplateOutlet="basic"></ng-container>
</ng-container>
<ng-template #basic>
  <!--suppress XmlDuplicatedId -->
  <mat-form-field class="w-100">
    <mat-label
      [ngClass]="{
        asterisk_input: control.hasError('required')
      }"
      attr.aria-label="{{ control.hasError('required') && 'Control is Required' }}"
      >{{ label }}</mat-label
    >
    <mat-select
      *ngIf="!multiple"
      [class]="klass"
      id="{{ controlName }}"
      [formControl]="control"
      [attr.readonly]="readOnly ? true : null"
      [attr.disabled]="disabledState ? true : null"
      (selectionChange)="onChanged($event)"
      disableOptionCentering
      attr.aria-required="{{ control.hasError('required') ? 'true' : 'false' }}"
    >
      <mat-option *ngIf="addEmpty && !emptyNullable" value="" role="option">Select</mat-option>
      <mat-option *ngIf="addEmpty && emptyNullable" [value]="null" role="option">Select</mat-option>
      <mat-option *ngFor="let option of optionsBacking" [value]="option.key" role="option">
        {{ option.value }}
      </mat-option>
    </mat-select>

    <mat-select
      *ngIf="multiple"
      multiple
      [class]="klass"
      id="{{ controlName }}"
      [formControl]="control"
      [attr.readonly]="readOnly ? true : null"
      [attr.disabled]="disabledState ? true : null"
      (selectionChange)="onChange($event)"
    >
      <mat-option *ngFor="let option of optionsBacking" [value]="option.key" role="combobox">
        {{ option.value }}
      </mat-option>
    </mat-select>

    <mat-error>
      <app-field-error-display [control]="control"></app-field-error-display>
    </mat-error>
  </mat-form-field>
</ng-template>
