import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { shortDateFormat } from '../../../../../../shared/dateTimeHelpers';
import { IepGoal } from '../../../../../models/iep';
import { IepGoalService } from '../../../../../services/iep-goal.service';

@Component({
  selector: 'app-iep-goals-info',
  templateUrl: './iep-goals-info.component.html',
  styleUrls: ['./iep-goals-info.component.scss'],
})
export class IepGoalsInfoComponent implements OnInit {
  shortDateFormat = shortDateFormat;
  iepId: string;
  goalId: string;
  goal: any;
  displayedColumns = ['date', 'changes'];
  dataSource = new MatTableDataSource([]);

  constructor(
    public iepGoalService: IepGoalService,
    private dialogRef: MatDialogRef<IepGoalsInfoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { goal: any; id: string; iepId: string }
  ) {
    this.goal = data.goal;
    this.goalId = data.id;
    this.iepId = data.iepId;
  }

  ngOnInit(): void {
    this.iepGoalService.getAuditLogs(this.iepId, this.goalId).subscribe((result) => {
      this.dataSource.data = result;
    });
  }

  dismiss() {
    this.dialogRef.close();
  }
}
