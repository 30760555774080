import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-st-effect-disability',
  templateUrl: './st-effect-disability.component.html',
  styleUrls: ['./st-effect-disability.component.scss'],
})
export class StEffectDisabilityComponent implements OnInit {
  formGroup = new FormGroup({
    involvement: new FormControl(''),
    participate: new FormControl(''),
  });

  constructor() {}

  ngOnInit(): void {}
}
