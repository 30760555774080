<section class="card-spacing-top">
  <div class="row">
    <div class="col-md-12 col-xl-9">
      <mat-card *ngIf="notFinalized" class="mt-0 py-2 card-spacing-bottom">
        <div class="display-flex align-items-center justify-content-between">
          <div class="display-flex align-items-center">
            <div class="display-flex align-items-baseline mr-2">
              <h3 class="my-0 mr-1">Service Coordinator:</h3>
              <span>{{ caseSummary?.caseOwner.fullName }}</span>
            </div>
          </div>
          <button
            mat-raised-button
            aria-label="Transfer"
            color="primary"
            (click)="onTransferCase()"
            *ngIf="
              authService.isAllowed(permissions.TransferAllCases) ||
              (caseSummary?.isCaseUser && authService.isAllowed(permissions.TransferOwnCases))
            "
            tabindex="0"
          >
            Transfer
            <i class="fas fa-exchange-alt ml-2" aria-labelledby="Transfer"></i>
          </button>
        </div>
      </mat-card>
    </div>

    <div class="col-md-12 col-xl-3">
      <mat-card *ngIf="authService.isAllowed(permissions.ScheduleMeeting)" class="mt-0 py-2 card-spacing-bottom">
        <div class="display-flex flex-column">
          <button mat-raised-button aria-label="Open Scheduler" color="primary" (click)="scheduleMeeting()" tabindex="0">
            Open Scheduler
          </button>
        </div>
      </mat-card>
    </div>
  </div>

  <mat-accordion *ngIf="caseSummary" class="accordion accordion--short" displayMode="flat" multi>
    <mat-expansion-panel class="mat-elevation-z0" #proceduralSafeguardsPanel *ngIf="notFinalized">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span *ngIf="mostRecentProceduralSafeguard">
            The <i>Procedural Safeguards Manual for Parents</i> was last reviewed and provided to the parent(s) on
            {{ mostRecentProceduralSafeguard.providedOn | dateFormat }}. The parent(s)
            {{ mostRecentProceduralSafeguard.acceptedPrintedCopy ? 'accepted' : 'declined' }}
            the printed copy.
          </span>

          <span
            *ngIf="!mostRecentProceduralSafeguard"
            [ngClass]="{
              'text-danger': !mostRecentProceduralSafeguard
            }"
          >
            The parent has not yet been provided a printed copy of the
            <i>Procedural Safeguards Manual for Parents</i>
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-procedural-safeguards-list
        #proceduralSafeguardsList
        (mostRecentChanged)="onMostRecentProceduralSafeguardChanged($event)"
        [formGroup]="proceduralSafeguards"
        [caseId]="caseId"
        [learnerId]="caseSummary.learnerId"
        [showProvidedOn]="false"
        [showTable]="false"
        [trackFormChanges]="proceduralSafeguardsList.formGroup"
      ></app-procedural-safeguards-list>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion class="accordion accordion--short" displayMode="flat" multi>
    <mat-expansion-panel *ngIf="ifspMeetings?.length > 0" class="mat-elevation-z0">
      <mat-expansion-panel-header
        ><mat-panel-title
          ><h3 class="my-0">{{ ifspMeetings?.length === 1 ? 'Meeting' : 'Meetings' }}</h3></mat-panel-title
        >
      </mat-expansion-panel-header>
      <app-ifsp-meeting
        [ifspMeetings]="ifspMeetings"
        [ifspCaseSummary]="caseSummary"
        (refreshMeetings)="getIfspMeetings()"
      ></app-ifsp-meeting>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="notFinalized" class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="display-flex justify-content-between align-items-center w-100">
            <span class="mr-2 text-normal">Incomplete Data Report</span>

            <div class="display-flex align-items-center">
              <span class="text-md position-fix-notice" [ngClass]="{ 'text-danger': incompleteItems?.length > 0 }">{{
                incompleteItemsAmount
              }}</span>

              <button
                mat-icon-button
                aria-label="Refresh Icon"
                color="primary"
                (click)="refreshIncompleteItems($event)"
                class="ml-2"
                matTooltip="Refresh"
                tabindex="0"
              >
                <mat-icon aria-label="Refresh Icon"> refresh </mat-icon>
              </button>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-ifsp-completion
        [incompleteItems]="incompleteItems"
        [evaluationId]="evaluationId"
        [caseSummary]="caseSummary"
        (gotoProceduralSafeguards)="scrollAndOpenProceduralSafeguards()"
        (gotoConsent)="scrollAndOpenConsent()"
        (gotoLateReason)="scrollAndOpenLateReason()"
        (refreshData)="refreshData()"
      ></app-ifsp-completion>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="notFinalized && ifsp.isLate" class="mat-elevation-z0" #lateReason>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="display-flex justify-content-between align-items-center w-100">
            <h3 class="my-0 mr-2 text-normal">Late IFSP Rationale</h3>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-help-directional-text>
        <p class="card-spacing-bottom">Provide the reason why the initial IFSP meeting was not held within 45 days of the referral.</p>
      </app-help-directional-text>
      <app-late-ifsp-reason
        *ngIf="ifsp"
        [ifspId]="ifsp.id"
        [lateReasonId]="ifsp.lateReasonId"
        [lateReasonOther]="ifsp.lateReasonOther"
      ></app-late-ifsp-reason>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="notFinalized" class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="display-flex align-items-baseline justify-content-between w-100">
            <h3 class="my-0 mr-2 text-normal">Outcomes List</h3>
            <span class="text-danger text-md" *ngIf="outcomesListCount === 0 && ifsp.ifspType !== ifspType.Interim">
              No Outcomes Added Yet
            </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-ifsp-outcomes-list
        [caseId]="caseId"
        [ifspId]="ifspId"
        [learnerId]="caseSummary?.learnerId"
        (editOutcome)="onEditOutcome($event)"
        (outcomesCount)="outcomesListCount = $event"
      ></app-ifsp-outcomes-list>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="notFinalized" class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="display-flex align-items-baseline justify-content-between w-100">
            <h3 class="my-0 mr-2 text-normal">Services List</h3>
            <span class="text-danger text-md" *ngIf="serviceListCount === 0"> No Services Added Yet </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-ifsp-service-list
        #ifspServiceList
        [isProfilePage]="true"
        [ifspId]="ifspId"
        (servicesCount)="serviceListCount = $event"
        (editService)="onEditService($event)"
        [trackFormChanges]="ifspServiceList.medicalServiceFormGroup"
      ></app-ifsp-service-list>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="notFinalized" class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title><h3 class="my-0 text-normal">Services Setting(s)</h3></mat-panel-title>
      </mat-expansion-panel-header>
      <app-ifsp-setting [ifspId]="ifspId"></app-ifsp-setting>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="notFinalized" class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="display-flex align-items-baseline justify-content-between w-100">
            <h3 class="my-0 mr-2 text-normal">Upload Documentation</h3>
            <span class="text-primary text-md"> {{ documents?.length }} Documents</span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-documentation-upload
        [documents]="documents"
        [activeCall]="activeCall"
        [baseUrl]="baseUrl"
        (upload)="onUpload($event)"
        (deleteDocument)="onDeleteDocument($event)"
      ></app-documentation-upload>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="notFinalized" class="mat-elevation-z0 hide-print" #consent>
      <mat-expansion-panel-header>
        <mat-panel-title><h3 class="my-0 text-normal">Consent</h3></mat-panel-title>
      </mat-expansion-panel-header>
      <app-ifsp-consents
        *ngIf="caseSummary"
        [needsNewConsent]="needsNewConsent"
        [caseSummary]="caseSummary"
        [ifspId]="ifspId"
        [psgProvided]="!!mostRecentProceduralSafeguard"
        [maxAmount]="3"
      ></app-ifsp-consents>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0 hide-print">
      <mat-expansion-panel-header>
        <mat-panel-title><h3 class="my-0 text-normal">IFSP / Reports</h3></mat-panel-title>
      </mat-expansion-panel-header>
      <app-help-directional-text>
        <p class="mb-2">The IFSP is built as PLOD, Outcomes, and Service details are added by the IFSP team.</p>
      </app-help-directional-text>

      <h4 class="text-primary mt-0 mb-2">Current/Active IFSP(s)</h4>
      <app-ifsp-reports
        *ngIf="caseSummary && incompleteItems && ifsp"
        [pwnId]="caseSummary?.pwnId"
        [learner]="caseSummary?.learner"
        [canFinalize]="incompleteItems?.length === 0"
        [finalized]="!!ifsp.activatedOn"
        [learnerId]="caseSummary.learnerId"
        [documentId]="ifsp.outputDocumentId"
        [isCaseOwner]="isCaseOwner"
        [showLockWarning]="caseSummary?.learner?.isMigrated && ifsp.isFirstIfsp"
      ></app-ifsp-reports>

      <h4 class="text-primary mt-3 mb-0">Archived IFSP(s)</h4>
      <app-past-ifsps [pastIfsps]="pastIfsps"></app-past-ifsps>
    </mat-expansion-panel>
  </mat-accordion>
</section>

<div class="page-bottom-leeway"></div>
