<section class="help" *ngIf="search">
  <ng-container *ngTemplateOutlet="helpContainer"> </ng-container>
</section>

<ng-template #helpContainer>
  <!-- Accessible Education Materials -->
  <ng-container *ngIf="search === sections.AEM + '.accommodations'">
    <ng-container *ngTemplateOutlet="aemAccommodations"></ng-container>
  </ng-container>

  <ng-container *ngIf="search === sections.AEM + '.print'">
    <ng-container *ngTemplateOutlet="aemPrint"></ng-container>
  </ng-container>

  <ng-container *ngIf="search === sections.AEM + '.accessible'">
    <ng-container *ngTemplateOutlet="aemAccessible"></ng-container>
  </ng-container>

  <!-- Assistive Technology -->
  <ng-container *ngIf="search === sections.AssistiveTech + '.device'">
    <ng-container *ngTemplateOutlet="assistiveTechDevice"></ng-container>
  </ng-container>

  <ng-container *ngIf="search === sections.AssistiveTech + '.assistive'">
    <ng-container *ngTemplateOutlet="assistiveTechAssistive"></ng-container>
  </ng-container>

  <!-- Vision -->
  <ng-container *ngIf="search === sections.Vision + '.evidence'">
    <ng-container *ngTemplateOutlet="visionEvidence"></ng-container>
  </ng-container>

  <ng-container *ngIf="search === sections.Vision + '.accessibility'">
    <ng-container *ngTemplateOutlet="visionAccessibility"></ng-container>
  </ng-container>

  <ng-container *ngIf="search === sections.Vision + '.expanded'">
    <ng-container *ngTemplateOutlet="visionExpanded"></ng-container>
  </ng-container>

  <!-- Communication -->
  <ng-container *ngIf="search === sections.Communication + '.communication'">
    <ng-container *ngTemplateOutlet="communicationCommunication"></ng-container>
  </ng-container>

  <ng-container *ngIf="search === sections.Communication + '.mode'">
    <ng-container *ngTemplateOutlet="communicationMode"></ng-container>
  </ng-container>

  <ng-container *ngIf="search === sections.Communication + '.participation'">
    <ng-container *ngTemplateOutlet="communicationParticipation"></ng-container>
  </ng-container>

  <!-- Health -->
  <ng-container *ngIf="search === sections.Health + '.require'">
    <ng-container *ngTemplateOutlet="healthRequire"></ng-container>
  </ng-container>

  <!-- Hearing -->
  <ng-container *ngIf="search === sections.Hearing + '.evidence'">
    <ng-container *ngTemplateOutlet="hearingEvidence"></ng-container>
  </ng-container>

  <ng-container *ngIf="search === sections.Hearing + '.communication'">
    <ng-container *ngTemplateOutlet="hearingCommunication"></ng-container>
  </ng-container>

  <ng-container *ngIf="search === sections.Hearing + '.supports'">
    <ng-container *ngTemplateOutlet="hearingSupports"></ng-container>
  </ng-container>

  <ng-container *ngIf="search === sections.Hearing + '.additionalSupports'">
    <ng-container *ngTemplateOutlet="hearingAdditionalSupports"></ng-container>
  </ng-container>

  <ng-container *ngIf="search === sections.Hearing + '.expanded'">
    <ng-container *ngTemplateOutlet="hearingExpanded"></ng-container>
  </ng-container>

  <!-- Social Emotional Behavior -->
  <ng-container *ngIf="search === sections.SocialEmotional + '.impact'">
    <ng-container *ngTemplateOutlet="socialEmotionalImpact"></ng-container>
  </ng-container>

  <ng-container *ngIf="search === sections.SocialEmotional + '.risk'">
    <ng-container *ngTemplateOutlet="socialEmotionalRisk"></ng-container>
  </ng-container>

  <ng-container *ngIf="search === sections.SocialEmotional + '.safety'">
    <ng-container *ngTemplateOutlet="socialEmotionalSafety"></ng-container>
  </ng-container>

  <ng-container *ngIf="search === sections.SocialEmotional + '.loss'">
    <ng-container *ngTemplateOutlet="socialEmotionalLoss"></ng-container>
  </ng-container>
</ng-template>

<!-- Accessible Education Materials -->
<ng-template #aemAccommodations>
  <h4 class="text-primary mt-0 mb-2">
    Does the learner have accommodations listed in the IEP that require an adult reader at specific times?
  </h4>
  <ul class="my-0">
    <li>Testing accommodations</li>
    <li>Worksheet(s)</li>
    <li>Chapter book(s)</li>
  </ul>
</ng-template>
<ng-template #aemPrint>
  <h4 class="text-primary mt-0 mb-2">
    Does the learner have hearing, vision or physical difficulties that prevent them from independently utilizing print or digitally-based
    materials?
  </h4>
  <ul class="my-0">
    <li>
      A specialized format
      <ul class="my-0">
        <li>Closed captioning</li>
        <li>Vision</li>
        <li>Large print</li>
        <li>Digital text</li>
        <li>Tactile graphics</li>
        <li>Real objects or tactile symbols</li>
      </ul>
    </li>
    <li>Personalized technology tools for access, such as a text reader or screen reader</li>
    <li>Printed material that is presented auditorily</li>
  </ul>
</ng-template>
<ng-template #aemAccessible>
  <h4 class="text-primary mt-0 mb-2">Does the learner require accessible materials for working or living?</h4>
  <ul class="my-0">
    <li>Portable devices to support reading</li>
    <li>Enlarged print</li>
    <li>Adult reader</li>
  </ul>
</ng-template>

<!-- Assistive Technology -->
<ng-template #assistiveTechDevice>
  <h4 class="text-primary mt-0 mb-2">
    Is there a device, software, product or strategy that may allow the learner to perform this task with greater accuracy, ease or
    independence?
  </h4>
  <ul class="my-0">
    <li>Hearing assistive technology (HAT)</li>
    <li>Captioning services</li>
    <li>Speech to text</li>
    <li>Screen reader</li>
    <li>Text reader</li>
    <li>Electronic braille display or notetaker</li>
    <li>Magnification</li>
    <li>Augmentative or alternative communication device (AAC)</li>
    <li>Word processing</li>
    <li>Dictation/Speech-to-text</li>
    <li>Walkers</li>
    <li>Cane</li>
    <li>Light board</li>
    <li>Slantboard</li>
    <li>Positioning supports</li>
    <li>Adaptive switches</li>
  </ul>
</ng-template>
<ng-template #assistiveTechAssistive>
  <h4 class="text-primary mt-0 mb-2">Does the learner require assistive technology for working or living?</h4>
  <ul class="my-0">
    <li>Text reader for job duties</li>
    <li>Apps</li>
    <li>Augmentative or alternative communication device (AAC)</li>
    <li>Timer or device for reminders</li>
    <li>Magnification</li>
  </ul>
</ng-template>

<!-- Vision -->
<ng-template #visionEvidence>
  <h4 class="text-primary mt-0 mb-2">Is there evidence of a vision loss?</h4>
  <ul class="my-0">
    <li>Medical report</li>
    <li>Functional Vision Assessment</li>
    <li>Low Vision Clinic report</li>
  </ul>
</ng-template>
<ng-template #visionAccessibility>
  <h4 class="text-primary mt-0 mb-2">
    Does the learner have accessibility needs due to their vision loss that will not be met by the general education environment? Has a
    Learning Media Assessment been completed?
  </h4>
  <ul class="my-0">
    <li>Braille</li>
    <li>Large print</li>
    <li>Auditory</li>
    <li>Progressive loss</li>
  </ul>
</ng-template>
<ng-template #visionExpanded>
  <h4 class="text-primary mt-0 mb-2">
    Does the learner have Expanded Core Curriculum needs that will not be met by the general education curriculum?
  </h4>
  <ul class="my-0">
    <li>Compensatory</li>
    <li>Assistive Technology</li>
    <li>Career Education</li>
    <li>Independent Living</li>
    <li>Orientation and Mobility</li>
    <li>Recreation and Leisure</li>
    <li>Self-Determination</li>
    <li>Social Interaction</li>
    <li>Visual Efficiency</li>
  </ul>
</ng-template>

<!-- Communication -->
<ng-template #communicationCommunication>
  <h4 class="text-primary mt-0 mb-2">Does the learner’s communication or language skills interfere with their education?</h4>
  <ul class="my-0">
    <li>Consider oral and written language skills</li>
    <li>Ability to follow directions</li>
    <li>Level of intelligibility</li>
    <li>Vocabulary needs</li>
    <li>Social communication</li>
    <li>Augmentative or alternative communication device (AAC)</li>
  </ul>
</ng-template>
<ng-template #communicationMode>
  <h4 class="text-primary mt-0 mb-2">Does the learner’s mode of communication impact their academic progress?</h4>
  <ul class="my-0">
    <li>Use of augmentative or alternative communication device (AAC)</li>
    <li>Sign Language</li>
    <li>Gestures</li>
    <li>Non-verbal</li>
  </ul>
</ng-template>
<ng-template #communicationParticipation>
  <h4 class="text-primary mt-0 mb-2">
    Does the learner’s communication and language interfere with participation in social and extracurricular opportunities?
  </h4>
  <ul class="my-0">
    <li>Direct communication with peers</li>
    <li>Direct communication with professionals</li>
  </ul>
</ng-template>

<!-- Health -->
<ng-template #healthRequire>
  <h4 class="text-primary mt-0 mb-2">Due to the health condition, does the learner require</h4>
  <ul class="my-0">
    <li>Safety precautions (bus, playground, gym, lunchroom)</li>
    <li>Assistance with activities of daily living (dressing, toileting, feeding)</li>
    <li>Medication</li>
    <li>Medical supplies</li>
    <li>Specific equipment or appliances</li>
    <li>Prosthetics including limbs and devices</li>
    <li>Mobility devices</li>
    <li>Emergency Evacuation Plan</li>
    <li>Modified dietary needs</li>
  </ul>
</ng-template>

<!-- Hearing -->
<ng-template #hearingEvidence>
  <h4 class="text-primary mt-0 mb-2">Is there evidence of a hearing loss?</h4>
  <ul class="my-0">
    <li>Audiogram/ABR from an audiologist</li>
    <li>Medical report</li>
  </ul>
</ng-template>
<ng-template #hearingCommunication>
  <h4 class="text-primary mt-0 mb-2">
    What impact does the learner's primary communication mode, expressively and receptively, have on their educational experience?
  </h4>
  <ul class="my-0">
    <li>Spoken</li>
    <li>Signed</li>
    <li>Total communication</li>
    <li>Gestures</li>
    <li>Pictures</li>
    <li>Augmentative or alternative communication device (AAC)</li>
    <li>Non-verbal</li>
  </ul>
</ng-template>
<ng-template #hearingSupports>
  <h4 class="text-primary mt-0 mb-2">
    What supports are needed to increase the learner's proficiency in their language and communication to acquire grade-level concepts?
  </h4>
  <ul class="my-0">
    <li>Support Services</li>
    <li>Continuum of Service options</li>
    <li>Hearing Assistive Technology</li>
    <li>Accommodations</li>
    <li>Modifications</li>
  </ul>
</ng-template>
<ng-template #hearingAdditionalSupports>
  <h4 class="text-primary mt-0 mb-2">
    Are additional supports needed to follow for opportunities for direct communication with peers and staff in the learner's primary mode
    of communication, expressively and receptively?
  </h4>
  <ul class="my-0">
    <li>Trainings such as use of picture book, sign language</li>
    <li>Small group rather than large group</li>
    <li>Quiet setting rather than noisy setting</li>
    <li>Social peer groups, such as small group lunch</li>
  </ul>
</ng-template>
<ng-template #hearingExpanded>
  <h4 class="text-primary mt-0 mb-2">
    Does the learner have Expanded Core Curriculum needs that will not be met by the general education curriculum?
  </h4>
  <ul class="my-0">
    <li>Audiology</li>
    <li>Career Education</li>
    <li>Communication</li>
    <li>Family Education</li>
    <li>Functional Skills for Educational Success</li>
    <li>Self-Determination and Advocacy</li>
    <li>Social-Emotional Skills</li>
    <li>Technology</li>
  </ul>
</ng-template>

<!-- Social Emotional Behavior -->
<ng-template #socialEmotionalImpact>
  <h4 class="text-primary mt-0 mb-2">
    What impact does the learner's social-emotional needs and/or behavior have on their daily functioning?
  </h4>
  <ul class="my-0">
    <li>Attendance</li>
    <li>Engagement</li>
    <li>Relationships</li>
    <li>Social awareness</li>
    <li>Self-awareness</li>
    <li>Self-management</li>
    <li>Responsible decision making</li>
  </ul>
</ng-template>
<ng-template #socialEmotionalRisk>
  <h4 class="text-primary mt-0 mb-2">
    Does the learner’s social-emotional needs, social skill development, and/or behavior make them or other learners at risk for loss of
    instructional time?
  </h4>
  <ul class="my-0">
    <li>
      Consider if social-emotional needs, social skill development, and/or behavior affects the learner’s ability to attend to or complete
      academic activities
    </li>
    <li>
      Consider whether the learner’s social-emotional behavioral needs are resulting in them not being in the classroom during instructional
      time (e.g. counselor, nurse’s office, quiet room)
    </li>
    <li>Consider whether the learner or peers are removed from classroom instruction and activities as a result of behavior</li>
    <li>
      Consider amount of time adults and peers attend to learner about behavior or social-emotional needs rather than academic activities
    </li>
    <li>Consider what disciplinary actions have been taken in the past (e.g. detentions, suspensions)</li>
  </ul>
</ng-template>
<ng-template #socialEmotionalSafety>
  <h4 class="text-primary mt-0 mb-2">
    Does the learner’s social-emotional needs and/or behavior create a safety risk for themselves or others?
  </h4>
  <ul class="my-0">
    <li>Consider risk for learner to physically harm themselves or others</li>
    <li>Consider risk for learner to damage property</li>
    <li>Consider risk for learner to emotionally harm others</li>
  </ul>
</ng-template>
<ng-template #socialEmotionalLoss>
  <h4 class="text-primary mt-0 mb-2">
    Does the learner’s social-emotional needs, behavior, or social skills development make them at risk for or result in loss of other
    opportunities?
  </h4>
  <ul class="my-0">
    <li>Consider loss of opportunities for interacting with peers and adults</li>
    <li>Consider need for instruction on social skills and/or appropriate behaviors that takes away from other instruction</li>
    <li>Consider loss of involvement in extracurricular, community, vocational and post-secondary transition activities</li>
  </ul>
</ng-template>
