import { Component, Input, OnInit } from '@angular/core';
import { IepAmendment } from '../../../../models/iep-amendment';

@Component({
  selector: 'app-iep-details-plaafp',
  templateUrl: './iep-details-plaafp.component.html',
  styleUrls: ['./iep-details-plaafp.component.scss'],
})
export class IepDetailsPlaafpComponent implements OnInit {
  @Input() amendments: IepAmendment[] = [];
  @Input() amending: boolean;

  ngOnInit(): void {}
}
