<mat-card class="p-0">
  <div class="mat-card__icon">
    <i class="material-icons">
      {{ icon }}
    </i>
  </div>

  <div class="mat-card__body">
    <h3>{{ title }}</h3>
    <p>{{ amount }}</p>
  </div>
</mat-card>
