import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-secondary-transition',
  templateUrl: './secondary-transition.component.html',
  styleUrls: ['./secondary-transition.component.scss'],
})
export class SecondaryTransitionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
