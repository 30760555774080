<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ headline }}</h3>

<div mat-dialog-content class="pt-3 pb-5">
  <form [formGroup]="formGroup">
    <div class="mb-4">
      <app-textarea
        formControlName="areas"
        [label]="areasLabel"
        attr.aria-label="areasLabel"
        [maxLength]="stringSizes.large"
        aria-required="true"
        [rows]="3"
      ></app-textarea>
    </div>
    <app-date-picker
      controlName="dateProvided"
      [label]="dateProvidedLabel"
      attr.aria-label="dateProvidedLabel"
      aria-required="true"
    ></app-date-picker>
  </form>
</div>

<div mat-dialog-actions class="actions">
  <button mat-button mat-dialog-close attr.aria-label="dismissLabel" class="background-color--muted" tabindex="0">
    {{ dismissLabel }}
  </button>
  <button
    mat-flat-button
    attr.aria-label="submitLabel"
    color="primary"
    (click)="onComplete()"
    tabindex="1"
    [isBusy]="isPending"
    [disabled]="isDisabled"
  >
    {{ submitLabel }}
  </button>
</div>
