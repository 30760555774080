<section class="output">
  <ul class="my-0 list list--print">
    <ng-container *ngIf="ecoAreas && plodModel">
      <li class="list__item">
        <div class="mr-2 ml-2 display-flex justify-content-between align-items-center flex-1">
          <h3 class="card-spacing-top mb-2">Early Childhood Outcomes Areas</h3>
          <h3 class="card-spacing-top mb-2">{{ getIfspDate }}</h3>
        </div>

        <div class="print__container">
          <ul class="list pl-0">
            <li class="list__item" *ngFor="let ea of ecoAreas; last as isLast">
              <div class="avoid-break">
                <div class="display-flex align-items-center justify-content-between mb-2">
                  <h4 class="text-primary my-0">
                    {{ ea.label }}
                  </h4>
                </div>
                <p class="my-0"></p>
                <p class="my-2">
                  {{ scoreData(ea) }}
                </p>
                <hr class="input__divider hide-print" />
              </div>
            </li>

            <li class="list__item">
              <div class="avoid-break">
                <div class="display-flex align-items-center justify-content-between mb-2">
                  <h4 class="text-primary my-0">Present Levels of Development</h4>
                </div>
                <p class="my-0"></p>
                <p class="my-2">
                  {{ scoreData() }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ng-container>
  </ul>
</section>
