import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';

@Component({
  selector: 'app-compare-amendment-output',
  templateUrl: './compare-amendment-output.component.html',
  styleUrls: ['./compare-amendment-output.component.scss'],
})
export class CompareAmendmentOutputComponent implements OnInit {
  @Input() tableData = false;
  @Input() isIfsp = false;
  @Input() amendmentId: string;
  @Input() amendmentEndReasonValue?: any;
  @Input() priorVersionId?: string;
  @Input() isActive: boolean;
  @Input() changedVersionText: any;
  @Input() currentVersionText: any;
  @Input() changedPrefixText = 'Proposed: ';
  @Input() currentPrefixText;
  @Input() amendmentDate: Date;
  @Input() amendmentFinalized: boolean;
  @Input() tableAlignment = false;
  shortDateFormat = shortDateFormat;

  get currentVersionTextIsDifferent() {
    return this.changedVersionText !== this.currentVersionText;
  }

  get hasAmendmentEndValue() {
    return !!this.amendmentEndReasonValue;
  }

  get isNew() {
    return !this.priorVersionId && !this.isActive;
  }

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.isIfsp = this.router.url.includes('ifsp');
  }
}
