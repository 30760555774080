import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MedicalCondition } from 'src/app/shared/models/medical-condition';

@Injectable({
  providedIn: 'root',
})
export class MedicalConditionService {
  private baseIntakeUrl = 'api/early-access-intakes';
  private baseQuestionnaireUrl = 'api/early-access-questionnaires';
  private baseReferralUrl = 'api/early-access-referrals';

  constructor(private http: HttpClient) {}

  getIntakeMedicalConditions(caseId: string) {
    return this.http.get<MedicalCondition[]>(`${this.baseIntakeUrl}/${caseId}/health-info/medical-conditions`);
  }

  addIntakeMedicalCondition(condition: MedicalCondition, caseId: string) {
    return this.http.post<MedicalCondition>(`${this.baseIntakeUrl}/${caseId}/health-info/medical-conditions`, condition);
  }

  deleteIntakeMedicalCondition(caseId: string, id: string) {
    return this.http.put<string>(`${this.baseIntakeUrl}/${caseId}/health-info/medical-conditions/${id}`, null);
  }

  deleteAllIntakeMedicalCondition(caseId: string) {
    return this.http.put<string>(`${this.baseIntakeUrl}/${caseId}/health-info/medical-conditions`, null);
  }

  getQuestionnaireMedicalConditions(questionnaireId: string) {
    return this.http.get<MedicalCondition[]>(`${this.baseQuestionnaireUrl}/${questionnaireId}/health-info/medical-conditions`);
  }

  addQuestionnaireMedicalCondition(condition: MedicalCondition, questionnaireId: string) {
    return this.http.post<MedicalCondition>(`${this.baseQuestionnaireUrl}/${questionnaireId}/health-info/medical-conditions`, condition);
  }

  deleteQuestionnaireMedicalCondition(questionnaireId: string, id: string) {
    return this.http.put<string>(`${this.baseQuestionnaireUrl}/${questionnaireId}/health-info/medical-conditions/${id}`, null);
  }

  getReferralMedicalConditions(referralId: string) {
    return this.http.get<MedicalCondition[]>(`${this.baseReferralUrl}/${referralId}/health-info/medical-conditions`);
  }

  addReferralMedicalCondition(condition: MedicalCondition, referralId: string) {
    return this.http.post<MedicalCondition>(`${this.baseReferralUrl}/${referralId}/health-info/medical-conditions`, condition);
  }

  deleteReferralMedicalCondition(referralId: string, id: string) {
    return this.http.put<string>(`${this.baseReferralUrl}/${referralId}/health-info/medical-conditions/${id}`, null);
  }
}
