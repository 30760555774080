<ng-container *ngIf="!validateFba">
  <div mat-dialog-content class="py-2">
    <p>Validating Recent FBA...</p>
  </div>
</ng-container>

<ng-container *ngIf="validateFba">
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Create Behavior Intervention Plan</h3>

  <div mat-dialog-content class="py-2">
    <mat-card>
      <form [formGroup]="createBipFormGroup">
        <div class="row mt-2">
          <div class="col-md-12">
            <fieldset>
              <app-radio-group
                label="{{ importFromFbaMessage }}"
                formControlName="importFromFba"
                [options]="yesNoOptions"
                [useUndo]="false"
              ></app-radio-group>
            </fieldset>
          </div>
        </div>
      </form>
    </mat-card>
  </div>

  <div mat-dialog-actions align="end">
    <button class="mr-2" mat-raised-button aria-label="Cancel Button" (click)="onCancel()" tabindex="0">Cancel</button>
    <button
      cdkFocusInitial
      mat-raised-button
      aria-label="Create BIP"
      color="primary"
      [disabled]="!createBipFormGroup.valid"
      (click)="onCreate()"
      tabindex="0"
    >
      Create BIP
    </button>
  </div>
</ng-container>
