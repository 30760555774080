import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';

export interface RouteBroadcast {
  connectionId: string;
  name: string;
  userId: string;
  phoneNumber: string;
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  buildRoutingClient(
    onBroadcastPing: (connectionId: string, name: string, userId: string, phoneNumber: string, email: string) => void,
    onLeave: (connectionId: string) => void
  ) {
    const connection: signalR.HubConnection = new signalR.HubConnectionBuilder().withUrl('/simulusersv2').withAutomaticReconnect().build();

    connection.on('RequestPing', () => {
      connection.send('ReceivePing');
    });

    connection.on('BroadcastPing', onBroadcastPing);
    connection.on('OnLeave', onLeave);

    return connection;
  }
}
