import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ThisReceiver } from '@angular/compiler';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { AppPermissions } from '../permissions';
import { CaseService } from '../shared/services/case/case.service';
import { IepService } from './services/iep.service';

@Component({
  selector: 'app-iep',
  templateUrl: './iep.component.html',
  styleUrls: ['./iep.component.scss'],
})
export class IepComponent {
  constructor(
    private route: ActivatedRoute,
    private caseService: CaseService,
    private iepService: IepService,
    private router: Router,
    private authService: AuthService
  ) {
    this.route.params.subscribe(async (params) => {
      await this.caseService.refreshCaseSummary(params.caseId);
      if (!this.authService.isAllowedByCaseId(params.caseId, undefined, AppPermissions.ViewIEP) || this.authService.IsReadOnly) {
        this.router.navigate(['/learner-management', this.caseService.caseSummary.learnerId, 'iep']);
      }
      await this.iepService
        .get(params.iepId)
        .toPromise()
        .catch((error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.NotFound) this.router.navigate(['/not-found']);
        });
    });
  }
}
