import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GoalArea } from '../models/goal-area';

@Injectable({
  providedIn: 'root',
})
export class GoalAreaService {
  constructor(private readonly http: HttpClient) {}

  private baseUrl() {
    return 'api/goalarea';
  }

  getAllGoalAreas() {
    return this.http.get<GoalArea[]>(`${this.baseUrl()}`);
  }
}
