<div class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more">Actions</th>
      <td mat-cell *matCellDef="let routine" class="mat-column-actions--more">
        <ng-container *ngIf="routine.id && !isEditing">
          <button aria-label="Edit Icon" mat-icon-button color="primary" (click)="setEdit(routine)" tabindex="0">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button aria-label="Delete Icon" color="primary" (click)="onRemove(routine)" tabindex="0">
            <mat-icon class="text-danger">close</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="routine.isEditing">
          <button aria-label="Save Icon" mat-icon-button color="primary" (click)="saveRoutine(routine)" title="Save" tabindex="0">
            <mat-icon>save</mat-icon>
          </button>
          <button aria-label="Clear Icon" mat-icon-button (click)="setEdit(routine, false)" title="Cancel" color="warn" tabindex="0">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item aria-label="Save Button" (click)="saveRoutine(routine)" tabindex="0">Save</button>
            <button mat-menu-item aria-label="Cancel Button" class="text-danger" (click)="setEdit(routine, false)" tabindex="0">
              Cancel
            </button>
          </mat-menu>

          <button mat-icon-button aria-label="Quick Access Menu" color="primary" [matMenuTriggerFor]="actionMenu" tabindex="0">
            <mat-icon>more_vert</mat-icon>
          </button>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <ng-container *ngIf="addingNew">
          <button aria-label="Save Icon" color="primary" mat-icon-button title="Save" (click)="saveRoutine(null)" tabindex="0">
            <mat-icon>save</mat-icon>
          </button>
          <button aria-label="Clear Icon" color="warn" mat-icon-button (click)="setEdit(null, false)" title="Cancel" tabindex="0">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item (click)="saveRoutine(null)" tabindex="0" aria-label="Save">Save</button>
            <button mat-menu-item class="text-danger" (click)="setEdit(null, false)" tabindex="0" aria-label="Cancel">Cancel</button>
          </mat-menu>

          <button color="primary" mat-icon-button aria-label="Quick Access Menu" [matMenuTriggerFor]="actionMenu" tabindex="0">
            <mat-icon>more_vert</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="!addingNew && !isEditing">
          <button color="primary" mat-icon-button aria-label="Add Icon" (click)="addingNew = true" tabindex="0">
            <mat-icon>add_circle</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>

    <!-- Routines Column -->
    <ng-container matColumnDef="routines">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Routines</th>
      <td mat-cell *matCellDef="let routine">
        <app-inline-edit [editing]="routine.isEditing">
          <ng-container viewMode>
            <ng-container *ngIf="routine.otherLabel">
              {{ routine.otherLabel }}
            </ng-container>
            <ng-container *ngIf="!routine.otherLabel">
              {{ routine.label }}
            </ng-container>
          </ng-container>
          <ng-container editMode [formGroup]="editFormGroup">
            <app-autocomplete
              formControlName="routine"
              [options]="routines"
              class="w-25 mr-3 cell-spacing-fix"
              label="Routine"
              [required]="true"
            >
            </app-autocomplete>
            <app-text-field
              class="mr-3 mb-1 cell-spacing-fix"
              formControlName="other"
              label="Other Routine"
              [required]="true"
              *ngIf="editFormGroup.controls.routine.value === otherKey"
            >
            </app-text-field>
          </ng-container>
        </app-inline-edit>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <span
          [ngClass]="{
            'pb-2 display-block': editFormGroup.get('other').hasError('required') && editFormGroup.get('other').touched
          }"
        >
          <ng-container *ngIf="addingNew" [formGroup]="editFormGroup">
            <app-autocomplete
              formControlName="routine"
              [options]="routines"
              class="w-25 mr-3 cell-spacing-fix"
              label="Routine"
              [required]="true"
            >
            </app-autocomplete>
            <app-text-field
              class="mr-3 cell-spacing-fix"
              formControlName="other"
              label="Other Routine"
              [required]="true"
              *ngIf="editFormGroup.controls.routine.value === otherKey"
            >
            </app-text-field>
          </ng-container>
        </span>
      </td>
    </ng-container>

    <!-- Short Name Column -->
    <ng-container matColumnDef="shortName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Short Name</th>
      <td mat-cell *matCellDef="let routine">
        <app-inline-edit [editing]="routine.isEditing">
          <ng-container viewMode>
            {{ routine.nickName }}
          </ng-container>
          <ng-container editMode [formGroup]="editFormGroup">
            <app-text-field label="Short Name" formControlName="nickName"></app-text-field>
          </ng-container>
        </app-inline-edit>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <ng-container *ngIf="addingNew" [formGroup]="editFormGroup">
          <app-text-field label="Short Name" formControlName="nickName"></app-text-field>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
<p *ngIf="!dataSource.data">No data yet...</p>

<!--<button class="add-button" mat-icon-button aria-label="Add Icon" (click)="addNewRoutine()" tabindex="0">
  <mat-icon>add_circle</mat-icon>
</button>-->
<!--<form [formGroup]="formGroup">
  <div class="display-flex align-items-center mt-3 justify-content-end">
    <app-autocomplete
      formControlName="routine"
      [options]="routines"
      class="w-25 mr-3"
    >
    </app-autocomplete>
    <app-text-field
      class="mr-3"
      formControlName="other"
      placeholder="Other"
      *ngIf="formGroup.controls.routine.value === otherKey"
    >
    </app-text-field>
    <button
      mat-raised-button
      aria-label="Add Routine"
      color="accent"
      (click)="onAddRoutine()"
      [disabled]="addDisabled"
    >
      Add Routine
    </button>
  </div>
</form>-->
