import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EcoMatrixSummary } from 'src/app/evaluation/evaluation/steps/eval-eco-matrix/models/eco-matrix-summary';
import { ResetDate } from 'src/app/shared/components/ifsp-iep-reset-date/ifsp-iep-reset-date.component';
import { IncompleteDataReportItem } from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { FamilyMeetingRead } from 'src/app/shared/models/case';
import { DeleteReason } from 'src/app/shared/models/delete-reason';
import { GeneralSurvey } from 'src/app/shared/models/general-survey/general-survey';
import { GeneralSurveyGeneralSurveyQuestion } from 'src/app/shared/models/general-survey/general-survey-general-survey-question';
import { Learner } from 'src/app/shared/models/learner';
import { UserLimitedDto } from 'src/app/shared/models/user';
import {
  AnnualReviewUpdate,
  IfspAddUpdate,
  IfspPeriodicAnnualReview,
  IfspView,
  LateIfspReason,
  PeriodicReviewUpdate,
} from '../../ifsp/models/ifsp';
import { IfspPrimarySettingView } from '../../ifsp/models/ifsp-setting';
import { CreateOutcomeDto, Outcome, OutcomeDto } from '../../ifsp/models/outcome-models';
import { MeetingParticipantRead } from '../../learner-management/family-meeting/participants-list/meeting-participants';
import { IfspAdditionalInfo } from '../models/ifsp-additional-info';
import { IfspSurvey } from '../models/ifsp-survey';
import { Plod } from '../models/plod';
@Injectable({
  providedIn: 'root',
})
export class IfspService {
  private ifspUpdated = new Subject<void>();
  ifspUpdated$ = this.ifspUpdated.asObservable();

  public openPlodAccordion = new Subject<void>();
  openPlodAccordion$ = this.openPlodAccordion.asObservable();

  constructor(private readonly http: HttpClient) {}

  private baseUrl(id = '') {
    return `api/ifsp/${id}`;
  }

  private plodUrl(iepId = '') {
    return `${this.baseUrl(iepId)}/plod`;
  }

  get(ifspId: string) {
    return this.http.get<IfspView>(`${this.baseUrl(ifspId)}`);
  }

  getByCaseId(caseId: string) {
    return this.http.get<IfspView[]>(`${this.baseUrl('')}getByCaseId/${caseId}`);
  }

  getByLearnerId(learnerId: string) {
    return this.http.get<IfspView[]>(`api/learners/${learnerId}/ifsps`);
  }

  getOverallPrimarySetting(ifspId: string, modificationId: string) {
    return this.http.get<IfspPrimarySettingView>(`${this.baseUrl(ifspId)}/getOverallPrimarySetting/${modificationId}`);
  }

  getPastIfsps(ifspId: string) {
    return this.http.get<IfspView[]>(`${this.baseUrl(ifspId)}/getPastIfsps`);
  }

  getTeamMembers(caseId: string) {
    return this.http.get<MeetingParticipantRead[]>(`${this.baseUrl()}getTeamMembers/${caseId}`);
  }

  getPersonContactedUsers(caseId: string) {
    return this.http.get<UserLimitedDto[]>(`${this.baseUrl()}getPersonContactedUsers/${caseId}`);
  }

  getAnnualReview(ifspId: string) {
    return this.http.get<IfspPeriodicAnnualReview>(`${this.baseUrl(ifspId)}/annual-review`);
  }

  updateAnnualReview(ifspReviewId: string, annualReview: IfspPeriodicAnnualReview) {
    return this.http.put<IfspPeriodicAnnualReview>(`${this.baseUrl(ifspReviewId)}/annual-review`, annualReview);
  }

  updateReviewStatus(ifspId: string, annualReviewUpdate: AnnualReviewUpdate) {
    return this.http.put<void>(`${this.baseUrl(ifspId)}/review-status`, annualReviewUpdate);
  }

  createIFSP(model: IfspAddUpdate) {
    return this.http.post<IfspView>(this.baseUrl(''), model);
  }

  deleteIfsp(ifspId: string) {
    return this.http.put(this.baseUrl(ifspId), null);
  }

  createOutcome(ifspId: string) {
    return this.http.post<OutcomeDto>(this.baseUrl(ifspId) + '/createOutcome', {
      ifspId,
    } as CreateOutcomeDto);
  }

  updateOutcome(ifspId: string, model: Outcome) {
    return this.http.put<OutcomeDto>(this.baseUrl(ifspId) + '/updateOutcome', model);
  }

  deleteOutcome(ifspId: string, id: string) {
    return this.http.put(this.baseUrl(ifspId) + '/deleteOutcome/' + id, null);
  }

  getOutcomes(ifspId: string) {
    return this.http.get<Array<OutcomeDto>>(this.baseUrl(ifspId) + '/getOutcomes');
  }

  getOutcomeById(ifspId: string, id: string) {
    return this.http.get<OutcomeDto>(this.baseUrl(ifspId) + '/getOutcomeById/' + id);
  }

  autoFillEcoMatrixTags(ifspId: string) {
    return this.http.get<EcoMatrixSummary>(this.baseUrl(ifspId) + '/autoFillEcoMatrixTags');
  }

  getPlod(ifspId: string, modificationId = '') {
    return this.http.get<Plod>(this.plodUrl(ifspId) + '/' + modificationId);
  }
  getCurrentPlod(ifspId: string) {
    return this.http.get<Plod>(this.plodUrl(ifspId) + '/current_plod');
  }

  getMostRecentEvaluationByIfspId(ifspId: string) {
    return this.http.get<any>(this.baseUrl(ifspId) + '/getMostRecentEvaluationByIfspId');
  }

  createPlod(ifspId: string, model: any) {
    return this.http.post<Plod>(this.plodUrl(ifspId), model).pipe(tap(() => this.ifspUpdated.next()));
  }

  updatePlod(ifspId: string, model: any) {
    return this.http.put<void>(this.plodUrl(ifspId), model).pipe(tap(() => this.ifspUpdated.next()));
  }

  getIncompleteItemsReport(ifspId: string, modificationId = '') {
    return this.http.get<IncompleteDataReportItem[]>(`${this.baseUrl(ifspId)}/incomplete-items/${modificationId}`);
  }

  getIfspMeetings(caseId: string) {
    return this.http.get<FamilyMeetingRead[]>(`${this.baseUrl(caseId)}/meetings`);
  }

  getIfspSixMonthReviewMeeting(caseId: string) {
    return this.http.get<FamilyMeetingRead>(`${this.baseUrl(caseId)}/meetings/six-month-review`);
  }

  getOldestIncompleteIfspMeeting(caseId: string, modificationId: string) {
    return this.http.get<FamilyMeetingRead[]>(
      `${this.baseUrl(caseId)}/meetings/oldest-incomplete${modificationId ? '/' + modificationId : ''}`
    );
  }

  finalize(ifspId: string, modificationId = '') {
    modificationId = modificationId ?? '';
    return this.http.post<void>(`${this.baseUrl(ifspId)}/finalization/${modificationId}`, {}).pipe(tap(() => this.ifspUpdated.next()));
  }

  getIfspAdditionalInfos(ifspId: string) {
    return this.http.get<any>(`${this.baseUrl(ifspId)}/additional-info`);
  }

  addOrUpdateIfspAdditionalInfo(ifspId: string, dto: IfspAdditionalInfo) {
    if (dto.id) {
      return this.http.put(`${this.baseUrl(ifspId)}/additional-info/${dto.id}`, dto);
    } else {
      return this.http.post(`${this.baseUrl(ifspId)}/additional-info`, dto);
    }
  }

  updateOverallPrimarySetting(ifspId: string, dto: any) {
    return this.http.put(`${this.baseUrl(ifspId)}/updateOverallPrimarySetting`, dto);
  }

  deleteIfspAdditionalInfo(ifspId: string, ifspAdditionalInfoId: string) {
    return this.http.put(`${this.baseUrl(ifspId)}/additional-info/delete/${ifspAdditionalInfoId}`, null);
  }

  getLateIfspReasons() {
    return this.http.get<LateIfspReason[]>(`${this.baseUrl()}late-reasons`);
  }

  updateLateIfspReason(val: { ifspId: string; lateReasonId: string; lateReasonOther: string }) {
    return this.http.put<void>(`${this.baseUrl(val.ifspId)}/late-reasons`, val);
  }

  updatePeriodicReview(ifspId: string, model: PeriodicReviewUpdate) {
    return this.http.put<void>(`${this.baseUrl(ifspId)}/periodicReview`, model);
  }

  sendPeriodicReviewPoll(ifspId: string) {
    return this.http.put<void>(`${this.baseUrl(ifspId)}/sendPeriodicReviewPoll`, {});
  }

  sendAnnualReviewPoll(ifspId: string) {
    return this.http.put<void>(`${this.baseUrl(ifspId)}/sendAnnualReviewPoll`, {});
  }

  updateAnnualReviewDateForTesting(ifspId: string) {
    return this.http.put<void>(`${this.baseUrl(ifspId)}/changeAnnualReviewDate`, {});
  }

  getSurvey(ifspReviewId: string) {
    return this.http.get<GeneralSurvey>(`${this.baseUrl(ifspReviewId)}/survey`);
  }

  getSurveys(ifspId: string, surveyName: string) {
    return this.http.get<IfspSurvey[]>(`${this.baseUrl(ifspId)}/surveys/${surveyName}`);
  }

  closeSurvey(ifspId: string, surveyName: string) {
    return this.http.put<void>(`${this.baseUrl(ifspId)}/survey/${surveyName}`, {});
  }

  getReviews(ifspId: string) {
    return this.http.get<IfspPeriodicAnnualReview[]>(`${this.baseUrl(ifspId)}/reviews`);
  }

  getReview(ifspReviewId: string) {
    return this.http.get<IfspPeriodicAnnualReview>(`${this.baseUrl(ifspReviewId)}/review`);
  }

  getLearnerFromIfspReviewId(ifspReviewId: string) {
    return this.http.get<Learner>(`${this.baseUrl(ifspReviewId)}/learner`);
  }

  resetDates(ifspId: string, resetDate: ResetDate) {
    return this.http.put(`${this.baseUrl(ifspId)}/reset-dates`, resetDate);
  }

  cancelReview(ifspReviewId: string, reason: string) {
    const deleteReason: DeleteReason = { id: ifspReviewId, reason: reason };
    return this.http.put<DeleteReason>(`${this.baseUrl()}review`, deleteReason);
  }

  cleanReviews(reviews: IfspPeriodicAnnualReview[]): IfspPeriodicAnnualReview[] {
    for (const review of reviews) {
      review.totalSurveysSent = review.generalSurveyUserFullNameDtos.length;
      review.generalSurveyUserFullNameDtos = review.generalSurveyUserFullNameDtos.filter((x) => x.generalSurveyDto.completedDateTime);
    }
    return reviews;
  }

  replaceVars(template: string, fullName: string): string {
    let firstname = 'child name';
    const nameSplit = fullName.split(' ');
    if (nameSplit && nameSplit.length > 0) {
      firstname = nameSplit[0].trim();
    }
    return template.replace('{ChildName}', firstname);
  }

  convertSurveysToByQuestion(surveys: IfspSurvey[], fullName: string): IfspSurvey[] {
    const newSurveys: IfspSurvey[] = [];
    const questions = new Map<string, number>();
    surveys.forEach((s) => {
      s.generalSurveyDto.generalSurveyGeneralSurveyQuestionDtos.forEach((gs) => {
        const q = this.replaceVars(gs.generalSurveyQuestionDto.question, fullName);
        if (!questions.has(gs.generalSurveyQuestionDto.id)) {
          questions[gs.generalSurveyQuestionDto.id] = gs.generalSurveyQuestionDto.order;
          const newSurvey: IfspSurvey = {
            userFullName: q,
            userId: null,
            generalSurveyDto: {
              id: gs.generalSurveyQuestionDto.id,
              surveyName: '',
              completedDateTime: 'now',
              generalSurveyGeneralSurveyQuestionDtos: [],
            },
          };
          surveys.forEach((s2) => {
            s2.generalSurveyDto.generalSurveyGeneralSurveyQuestionDtos.forEach((og) => {
              if (
                og.generalSurveyQuestionDto.id === gs.generalSurveyQuestionDto.id &&
                og.generalSurveyAnswerDto !== undefined &&
                ((og.generalSurveyAnswerDto?.answer?.trim() !== '' && og.generalSurveyAnswerDto.answer !== null) ||
                  !og.generalSurveyQuestionDto.enableCondition)
              ) {
                const newGs: GeneralSurveyGeneralSurveyQuestion = {
                  generalSurveyId: '',
                  generalSurveyAnswerDto: { ...og.generalSurveyAnswerDto },
                  generalSurveyQuestionDto: {
                    ...og.generalSurveyQuestionDto,
                  },
                };
                newGs.generalSurveyQuestionDto.question = s2.userFullName;
                newSurvey.generalSurveyDto.generalSurveyGeneralSurveyQuestionDtos.push(newGs);
              }
            });
          });
          if (
            newSurvey.generalSurveyDto.generalSurveyGeneralSurveyQuestionDtos &&
            newSurvey.generalSurveyDto.generalSurveyGeneralSurveyQuestionDtos.length > 0
          ) {
            newSurveys.push(newSurvey);
          }
        }
      });
    });
    return newSurveys;
  }
}
