import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LookupBase } from '../../models/lookup-base';
import { Profession } from '../../models/profession';

@Injectable({
  providedIn: 'root',
})
export class ProfessionService {
  private baseUrl = 'api/professions/';

  constructor(private http: HttpClient) {}

  getProfessions() {
    return this.http.get<Profession[]>(this.baseUrl);
  }

  add(model): Observable<LookupBase> {
    return this.http.post<LookupBase>(this.baseUrl, model);
  }

  update(model): Observable<void> {
    return this.http.put<void>(this.baseUrl + model.id, model);
  }

  delete(id: string): Observable<void> {
    return this.http.put<void>(this.baseUrl + id, null);
  }
}
