import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  private baseUrl = '/api/utility';
  constructor(private http: HttpClient) {}

  public getPing() {
    return this.http.get<any>(`${this.baseUrl}/ping`);
  }

  public getPingDb() {
    return this.http.get<any>(`${this.baseUrl}/ping-db`);
  }
}
