import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ProgressMonitorComment } from 'src/app/monitoring-progress/models/comment';
import { CriterionProgressMonitor } from 'src/app/monitoring-progress/models/outcome-progress-monitor';
import { ProgressMonitorPhaseLine } from 'src/app/monitoring-progress/models/phase-line';
import { ProgressMonitorItem } from 'src/app/monitoring-progress/models/progress-monitor-item';
import { ProgressMonitorLookupService } from 'src/app/monitoring-progress/services/progress-monitor-lookup.service';
import { decimalValidator } from 'src/app/shared/validators';
import { DateToUtcPipe } from '../../../../../shared/pipes/date-transform.pipe';

@Component({
  selector: 'app-add-outcome-progress',
  templateUrl: './add-outcome-progress.component.html',
  styleUrls: ['./add-outcome-progress.component.scss'],
})
export class AddOutcomeProgressComponent implements OnInit, OnDestroy {
  @Input() outcomeEnded: boolean;
  @Input() criterion: CriterionProgressMonitor;
  @Output() submitEvent = new EventEmitter();
  @Output() phaseLineSubmitEvent = new EventEmitter();
  @Output() commentSubmitEvent = new EventEmitter();
  private subscriptions = new Subscription();
  @Input() minDate: Date;
  today = new Date();
  phaseLineVisible = false;
  commentsVisible = false;

  formGroup = this.fb.group({
    date: [null, Validators.required],
    value: null,
    completed: false,
    note: null,
    commentNote: [''],
    commentNoteOther: [null],
    phaseLineNote: [''],
    phaseLineNoteOther: [null],
  });

  get isOtherCommentSelected() {
    const commentNote = this.formGroup.get('commentNote');
    return (
      commentNote && commentNote.value && this.progressMonitorLookupService.lookupOptions.find((x) => x.id === commentNote.value)?.isOther
    );
  }

  get isOtherPhaseLineSelected() {
    const phaseLineNote = this.formGroup.get('phaseLineNote');
    return (
      phaseLineNote &&
      phaseLineNote.value &&
      this.progressMonitorLookupService.lookupOptions.find((x) => x.id === phaseLineNote.value)?.isOther
    );
  }

  get criterionCompleted() {
    return this.criterion.progressData.some((x) => x.completed);
  }

  get phaseLineOptions() {
    return this.progressMonitorLookupService.phaseLineOptions;
  }

  get commentOptions() {
    return this.progressMonitorLookupService.commentOptions;
  }

  constructor(private readonly fb: FormBuilder, private progressMonitorLookupService: ProgressMonitorLookupService) {}

  ngOnInit(): void {
    if (this.criterion.measurementType === 'numeric') {
      this.setupFormGroupValidation();
      this.watchFormGroup();
      this.watchPhaseLineControl();
      this.watchCommentControl();
    }
    if (this.criterion.measurementType === 'nonnumeric') {
      this.setupNonNumericFormGroupValidation();
      this.watchNonNumericFormGroup();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onSubmit() {
    if (this.criterion.measurementType === 'numeric') {
      if (this.formGroup.get('value').value !== null) {
        this.onDataPointSubmit();
      }
      if (this.formGroup.get('phaseLineNote').value) {
        this.onPhaseLineSubmit();
      }
      if (this.formGroup.get('commentNote').value) {
        this.onCommentSubmit();
      }
    } else {
      const dataPoint = {
        date: new DateToUtcPipe().transform(new Date(this.formGroup.get('date').value)),
        note: this.formGroup.get('note').value,
        completed: this.formGroup.get('completed').value,
        outcomeCriteriaId: this.criterion.id,
      } as ProgressMonitorItem;
      this.submitEvent.emit(dataPoint);
    }
    this.formGroup.reset({
      completed: false,
    });
  }

  private setupFormGroupValidation() {
    this.formGroup.get('value').setValidators([Validators.required, decimalValidator, Validators.min(0)]);
    this.formGroup.get('phaseLineNote').setValidators(Validators.required);
    this.formGroup.get('commentNote').setValidators(Validators.required);
  }

  private watchFormGroup() {
    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe((formValue) => {
        if (formValue.value !== null) {
          this.clearValidators(['phaseLineNote', 'commentNote']);
        } else if (formValue.phaseLineNote) {
          this.clearValidators(['value', 'commentNote']);
        } else if (formValue.commentNote) {
          this.clearValidators(['value', 'phaseLineNote']);
        } else {
          this.formGroup.get('value').setValidators([Validators.required, decimalValidator, Validators.min(0)]);
          this.formGroup.get('value').updateValueAndValidity({ emitEvent: false });

          this.formGroup.get('phaseLineNote').setValidators([Validators.required]);
          this.formGroup.get('phaseLineNote').updateValueAndValidity({ emitEvent: false });

          this.formGroup.get('commentNote').setValidators([Validators.required]);
          this.formGroup.get('commentNote').updateValueAndValidity({ emitEvent: false });
        }
      })
    );
  }

  private setupNonNumericFormGroupValidation() {
    this.formGroup.get('completed').setValidators(Validators.requiredTrue);
    this.formGroup.get('note').setValidators(Validators.required);
  }

  private watchNonNumericFormGroup() {
    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe((formValue) => {
        if (formValue.note !== null && formValue.note !== '') {
          this.clearValidators(['completed']);
        } else if (formValue.completed) {
          this.clearValidators(['note']);
        } else {
          this.formGroup.get('completed').setValidators(Validators.requiredTrue);
          this.formGroup.get('completed').updateValueAndValidity({ emitEvent: false });
          this.formGroup.get('note').setValidators(Validators.required);
          this.formGroup.get('note').updateValueAndValidity({ emitEvent: false });
        }
      })
    );
  }

  private watchPhaseLineControl() {
    this.formGroup.get('phaseLineNote').valueChanges.subscribe((value) => {
      const control = this.formGroup.get('phaseLineNoteOther');
      if (value === 'Other') {
        control.setValidators(Validators.required);
      } else {
        control.setValue(null);
        control.clearValidators();
      }
      control.updateValueAndValidity({ emitEvent: false });
    });
  }

  private watchCommentControl() {
    this.formGroup.get('commentNote').valueChanges.subscribe((value) => {
      const control = this.formGroup.get('commentNoteOther');
      if (value === 'Other') {
        control.setValidators(Validators.required);
      } else {
        control.setValue(null);
        control.clearValidators();
      }
      control.updateValueAndValidity({ emitEvent: false });
    });
  }

  private clearValidators(controls: Array<string>) {
    controls.forEach((control) => {
      this.formGroup.get(control).clearValidators();
      this.formGroup.get(control).updateValueAndValidity({ emitEvent: false });
    });
  }

  private onDataPointSubmit() {
    const dataPoint = {
      date: new DateToUtcPipe().transform(new Date(this.formGroup.get('date').value)),
      value: this.formGroup.get('value').value,
      completed: this.formGroup.get('completed').value,
      note: this.formGroup.get('note').value,
      outcomeCriteriaId: this.criterion.id,
    } as ProgressMonitorItem;
    this.submitEvent.emit(dataPoint);
  }

  private onPhaseLineSubmit() {
    const phaseLine = {
      date: new DateToUtcPipe().transform(new Date(this.formGroup.get('date').value)),
      progressMonitorLookupId: this.formGroup.get('phaseLineNote').value,
      noteOther: this.formGroup.get('phaseLineNoteOther').value,
      outcomeCriteriaId: this.criterion.id,
    } as ProgressMonitorPhaseLine;
    phaseLine.label = this.progressMonitorLookupService.lookupOptions.find((x) => x.id === phaseLine.progressMonitorLookupId)?.label;
    this.phaseLineSubmitEvent.emit(phaseLine);
  }

  private onCommentSubmit() {
    const comment = {
      date: new DateToUtcPipe().transform(new Date(this.formGroup.get('date').value)),
      progressMonitorLookupId: this.formGroup.get('commentNote').value,
      noteOther: this.formGroup.get('commentNoteOther').value,
      outcomeCriteriaId: this.criterion.id,
    } as ProgressMonitorComment;
    comment.label = this.progressMonitorLookupService.lookupOptions.find((x) => x.id === comment.progressMonitorLookupId)?.label;
    this.commentSubmitEvent.emit(comment);
  }
}
