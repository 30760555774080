<div #outcomesFormArea>
  <button
    mat-raised-button
    aria-label="Add Icon"
    color="primary"
    *ngIf="hasOpenModification"
    (click)="$event.stopPropagation(); addNew()"
    tabindex="0"
  >
    Add New
  </button>
</div>
<mat-card class="mt-2" *ngIf="showOutcomesForm">
  <app-ifsp-child-family-outcomes
    #outcomesComponent
    [modifyingOutcome]="modifyingOutcome"
    [modificationId]="modification?.id"
    [modifications]="modifications"
  ></app-ifsp-child-family-outcomes>
</mat-card>

<div class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>

      <td mat-cell *matCellDef="let element" [ngClass]="{ 'mat-column-actions--more': isEditing }">
        <div class="display-flex align-items-center justify-content-start">
          <button mat-icon-button aria-label="Quick Access Menu" color="primary" (click)="onViewMore(element)" tabindex="0">
            <mat-icon> remove_red_eye </mat-icon>
          </button>
          <ng-container *ngIf="!isEditing">
            <button
              *ngIf="showMenu(element)"
              aria-label="More option"
              mat-icon-button
              color="primary"
              [matMenuTriggerFor]="actionMenu"
              tabindex="0"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
              <button
                mat-menu-item
                aria-label="Add Progress Monitoring"
                *ngIf="canAddProgressMonitoring(element)"
                (click)="openComingSoon()"
                tabindex="0"
              >
                Add Progress Monitoring
              </button>
              <button mat-menu-item aria-label="View Graphs" *ngIf="element.displayGraph" (click)="onOpenGraph(element)" tabindex="0">
                View Graphs
              </button>
              <button mat-menu-item aria-label="Modify" *ngIf="canModify(element)" (click)="onEdit(element)" tabindex="0">Modify</button>
              <button *ngIf="canUndo(element)" mat-menu-item aria-label="Undo" (click)="onRemove(element, true)" tabindex="0">Undo</button>
              <button *ngIf="canRemove(element)" mat-menu-item aria-label="Delete Icon" (click)="onRemove(element)" tabindex="0">
                Remove
              </button>
              <button mat-menu-item aria-label="End Item" (click)="onEnd(element)" *ngIf="canEnd(element)" tabindex="0">
                <span class="text-danger"> End Item </span>
              </button>
            </mat-menu>

            <ng-container *ngIf="hasOpenModification">
              <div class="fake-icon-button" *ngIf="!element.isComplete">
                <mat-icon class="icon-incomplete" aria-label="Warning">warning</mat-icon>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="anyEndDatesEqualOrPast(element)">
            <div class="fake-icon-button">
              <i class="fas fa-calendar-times icon-incomplete"></i>
            </div>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="outcome">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Outcome</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.modificationId"
          [amendmentFinalized]="modificationIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.modificationEndDate || anyEndDatesInPast(element)"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="element.title"
          [currentVersionText]="element?.priorVersion ? element.priorVersion.title : null"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="outcomeArea">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Outcome Area(s)</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.modificationId"
          [amendmentFinalized]="modificationIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.modificationEndDate || anyEndDatesInPast(element)"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="getOutcomeAreaText(element)"
          [currentVersionText]="element?.priorVersion ? getOutcomeAreaText(element.priorVersion) : null"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="criteriaForAccomplishment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Criteria for Accomplishment</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.modificationId"
          [amendmentFinalized]="modificationIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.modificationEndDate || anyEndDatesInPast(element)"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="getCriteriaForAccomplishmentText(element)"
          [currentVersionText]="element?.priorVersion ? getCriteriaForAccomplishmentText(element.priorVersion) : null"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="monitoringForProgress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Monitoring for Progress</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.modificationId"
          [amendmentFinalized]="modificationIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.modificationEndDate || anyEndDatesInPast(element)"
          [priorVersionId]="element.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="getMonitoringProgressText(element)"
          [currentVersionText]="element?.priorVersion ? getMonitoringProgressText(element.priorVersion) : null"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="outcomeEndDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Timeline</th>
      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.modificationId"
          [amendmentFinalized]="modificationIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.modificationEndDate || anyEndDatesInPast(element)"
          [priorVersionId]="element.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="getOutcomeEndDateText(element)"
          [currentVersionText]="element?.priorVersion ? getOutcomeEndDateText(element.priorVersion) : null"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
