<div class="table-overflow app-table" *ngIf="this.dataSource">
  <table mat-table [dataSource]="dataSource" matSort class="w-100" #agencyTable>
    <ng-container *ngIf="!locked" matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left">Actions</th>
      <td mat-cell *matCellDef="let agency" class="text-left">
        <ng-container *ngIf="agency.id && !isEditing">
          <button aria-label="Edit Icon" mat-icon-button (click)="setEdit(agency)" tabindex="0">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button aria-label="Quick Access Menu" [matMenuTriggerFor]="menu" tabindex="0">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              *ngIf="!isQuestionnaire"
              (click)="getOrCreateConsentToRelease(agency)"
              mat-menu-item
              attr.aria-label="getConsentToReleaseLabel(agency)"
              tabindex="0"
            >
              {{ getConsentToReleaseLabel(agency) }}
            </button>
            <button
              *ngIf="!isQuestionnaire"
              (click)="downloadIcdPhysicianLetter(agency)"
              mat-menu-item
              aria-label="Download ICD 10 Physician Letter"
              tabindex="0"
            >
              Download ICD 10 Physician Letter
            </button>
            <button
              *ngIf="!isQuestionnaire"
              [disabled]="agency.recordsReceived"
              (click)="indicateRecordsReceived(agency)"
              mat-menu-item
              aria-label="Indicate Records Received"
              tabindex="0"
            >
              Indicate Records Received
            </button>
            <button
              [disabled]="agency.agencyProgramDocument"
              (click)="removeRow(agency)"
              [ngClass]="agency.agencyProgramDocument === null ? 'text-danger' : null"
              mat-menu-item
              aria-label="Delete Icon"
              tabindex="0"
            >
              Remove
            </button>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="agency.isEditing">
          <button aria-label="Save Icon" mat-icon-button (click)="addOrUpdate(agency)" title="Save" tabindex="0">
            <mat-icon>save</mat-icon>
          </button>
          <button aria-label="Clear Icon" mat-icon-button (click)="setEdit(agency, false)" title="Cancel" tabindex="0">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item aria-label="Save Button" (click)="addOrUpdate(agency)" tabindex="0">Save</button>
            <button mat-menu-item aria-label="Cancel Button" (click)="setEdit(agency, false)" tabindex="0">Cancel</button>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="!agency.id && !isEditing">
          <mat-icon class="ml-2" (click)="setEdit(agency)" aria-label="Add Icon">add_circle</mat-icon>
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Agency / Program</th>
      <td mat-cell *matCellDef="let agency">
        <app-inline-edit [editing]="agency.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ agency.name }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-text-field
              class="w-100"
              formControlName="name"
              [isBankableField]="true"
              [bankableFieldId]="bankableFieldIds.providerInfoForm.agencyName"
              [bankableFieldChildFname]="childFirstName"
              placeholder="Agency / Program"
              (keydown)="$event.stopPropagation()"
            ></app-text-field>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
      <td mat-cell *matCellDef="let agency">
        <app-inline-edit [editing]="agency.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ agency.address }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-text-field
              formControlName="address"
              aria-labelledby="Address"
              [maxLength]="stringSizes.small"
              placeholder="Address"
              (keydown)="$event.stopPropagation()"
            ></app-text-field>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>City/Town</th>
      <td mat-cell *matCellDef="let agency">
        <app-inline-edit [editing]="agency.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ agency.city }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-text-field
              formControlName="city"
              aria-labelledby="Agency City"
              [maxLength]="stringSizes.small"
              placeholder="City / Town"
              (keydown)="$event.stopPropagation()"
            ></app-text-field>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
      <td mat-cell *matCellDef="let agency">
        <app-inline-edit [editing]="agency.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ agency.state }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-autocomplete
              [options]="usStates"
              formControlName="state"
              label="State"
              (keydown)="$event.stopPropagation()"
            ></app-autocomplete>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="zipCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Zip Code</th>
      <td mat-cell *matCellDef="let agency">
        <app-inline-edit [editing]="agency.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ agency.zipCode }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-text-field
              formControlName="zipCode"
              aria-labelledby="Zip Code"
              placeholder="Zip Code"
              (blurMe)="populateLocationFromZipCode()"
              (keydown)="$event.stopPropagation()"
            ></app-text-field>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="contact">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact Person</th>
      <td mat-cell *matCellDef="let agency">
        <app-inline-edit [editing]="agency.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ agency.contact }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-text-field
              formControlName="contact"
              aria-labelledby="Agency Contact"
              [maxLength]="stringSizes.small"
              placeholder="Contact Person"
              (keydown)="$event.stopPropagation()"
            ></app-text-field>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="phoneNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
      <td mat-cell *matCellDef="let agency">
        <app-inline-edit [editing]="agency.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ agency.phoneNumber | phone }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-masked-input label="Phone Number" formControlName="phoneNumber" (keydown)="$event.stopPropagation()" mask="(000) 000-0000">
            </app-masked-input>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="faxNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fax</th>
      <td mat-cell *matCellDef="let agency">
        <app-inline-edit [editing]="agency.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ agency.faxNumber | phone }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-masked-input label="Fax Number" formControlName="faxNumber" (keydown)="$event.stopPropagation()" mask="(000) 000-0000">
            </app-masked-input>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="recordsReceived" *ngIf="!isQuestionnaire">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Record Received</th>
      <td mat-cell *matCellDef="let agency">
        <svg-icon
          *ngIf="agency.recordsReceived"
          src="assets/img/icons/Record-Received.svg"
          svgClass="icon--complete text-center"
        ></svg-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr
      [hidden]="dataSource?.data?.length > 0"
      mat-footer-row
      *matFooterRowDef="dataSource?.data?.length === 0 ? ['noResults'] : []"
      class="example-second-footer-row"
    ></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
  <p *ngIf="!dataSource.data">No data yet...</p>

  <span class="mat-cell position-fix mr-4">
    <button
      class="mr-1 mt-3"
      *ngIf="questAgenciesNotImported?.length > 0"
      type="button"
      mat-raised-button
      aria-label="Import from Questionnaire"
      color="accent"
      (click)="appendQuestionnaireAgencyOrOtherPrograms()"
      tabindex="0"
    >
      Import from Questionnaire
    </button>
  </span>
</div>
