<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Transfer Case</h3>

<div mat-dialog-content>
  <h3>Learner Information</h3>
  <app-learner-info-header [learnerId]="currentCase?.learnerId"></app-learner-info-header>
  <mat-card *ngIf="scheduledTransfer" class="mt-4">
    <p>
      <strong>NOTE:</strong> This case already has a scheduled transfer to <strong>{{ scheduledTransfer.user.fullName }}</strong> on
      <strong>{{ scheduledTransfer.date | dateFormat }}</strong
      >.
    </p>
    <p>Any changes made by submitting this form will cancel that transfer.</p>
  </mat-card>

  <h3 class="card-spacing-top">Transfer Case</h3>
  <app-service-coordinators-transfer
    *ngIf="!!currentCase && currentCase.intakeType === intakeType.PartC && !authService.isDmpsAdmin"
    [currentCase]="currentCase"
    [formGroup]="formGroup"
    [currentServiceCoordinator]="currentServiceCoordinator"
    (selected)="onServiceCoordinatorSelectionChanged($event)"
  ></app-service-coordinators-transfer>
  <app-aea-transfer
    *ngIf="districts && aeas"
    [aeas]="aeas"
    [districts]="districts"
    [aeaUsers]="aeaUsers"
    [buildings]="buildings"
    [multiSelect]="false"
    (search)="onSearch($event)"
    (selected)="onAeaSelectionChanged($event)"
  ></app-aea-transfer>

  <div class="divider mb-4"></div>
</div>

<div mat-dialog-action class="display-flex justify-content-end align-items-center mt-4">
  <div class="display-flex direction-column">
    <mat-error *ngIf="bothSelectedError" class="text-right mb-4">Select <strong>either</strong> an SC or AEA user only</mat-error>
    <div [hidden]="!areYouSureTransfer">
      <span class="mr-3">Are you sure?</span>
      <button mat-raised-button aria-label="Cancel Button" class="background-color--muted mr-1" (click)="cancelTransferToDMPS()">
        Cancel
      </button>
      <button
        *ngIf="!dmpsChscTransferDisabled"
        mat-raised-button
        aria-label="Yes"
        class="mr-2"
        color="primary"
        (click)="beginTransferToDMPS()"
      >
        Yes
      </button>
    </div>
    <div [hidden]="areYouSureTransfer">
      <button mat-raised-button aria-label="Cancel Button" class="background-color--muted mr-2" (click)="onClose()" cdkFocusInitial>
        Cancel
      </button>

      <button
        *ngIf="!dmpsChscTransferDisabled"
        mat-raised-button
        aria-label="Transfer to DMPS"
        class="mr-2"
        color="primary"
        [isBusy]="activeCall"
        (click)="clickedTransferToDMPS()"
        tabindex="0"
      >
        Transfer to DMPS
      </button>
      <button
        mat-raised-button
        aria-label="Transfer"
        color="primary"
        [disableAfterBusy]="true"
        [disabled]="
          (!selectedAeaUser && !selectedServiceCoordinator) ||
          (!!selectedAeaUser && !!selectedServiceCoordinator) ||
          this.formGroup.get('transferDate').hasError('required')
        "
        [isBusy]="activeCall"
        (click)="onTransfer()"
        tabindex="0"
      >
        Transfer
      </button>
    </div>
  </div>
</div>
