import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-family-dashboard',
  templateUrl: './family-dashboard.component.html',
  styleUrls: ['./family-dashboard.component.scss'],
})
export class FamilyDashboardComponent implements OnInit {
  todoList: any;
  questionnaireIds: string[] = [];

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.authService.user?.claims.forEach((element) => {
      if (element.type === 'family') {
        this.questionnaireIds.push(element.value.toString());
      }
    });
    if (this.questionnaireIds.length === 1) {
      this.router.navigate(['/evaluation/early-access-questionnaire', this.questionnaireIds[0]]);
    }
  }
}
