import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { EvaluationDetail, EvaluationNote } from 'src/app/evaluation/models/evaluation';
import { EvaluationDetailInterviewQuestion } from 'src/app/evaluation/models/evaluation-detail-interview-question';
import { EvaluationNoteService } from 'src/app/evaluation/services/evaluation-note.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { FormService } from 'src/app/shared/services/form.service';
import { MemoryStorageService } from 'src/app/shared/services/memory-storage/memory-storage.service';
import { TaggedForCategory } from 'src/app/tags/tagged-category';
import { conditionalValidator } from '../../../../../shared/validators';
import { EvaluationFormService } from '../services/evaluation-form.service';
import { EvaluationTagsComponent } from './evaluation-tags/evaluation-tags.component';

@Component({
  selector: 'app-evaluation-note',
  templateUrl: './evaluation-note.component.html',
  styleUrls: ['./evaluation-note.component.scss'],
})
export class EvaluationNoteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentEvaluationDetail: EvaluationDetail;
  @Input() currentEvaluationNote: EvaluationNote;
  @Input() currentEvaluationNotes: EvaluationNote[];
  @Input() interviewQuestion: EvaluationDetailInterviewQuestion;
  @Input() isInterview: boolean;
  currentInterviewNote: EvaluationNote;
  @ViewChild(FormGroupDirective) formRef: FormGroupDirective;
  private subscription: Subscription;
  @ViewChild(EvaluationTagsComponent) evaluationTagRef: EvaluationTagsComponent;
  @Output() noteSubmit = new EventEmitter<boolean>();
  isEligible: boolean;
  isAgeRange: boolean;
  learnerAgeInMonths: number;
  caseId: string;
  formGroup: FormGroup = this.fb.group(
    {
      note: [null],
      ageEquivalentLow: ['', Validators.min(0)],
      ageEquivalentHigh: ['', Validators.min(0)],
      calculatedPercentDelayLow: [''],
      calculatedPercentDelayHigh: [''],
    },
    { updateOn: 'blur' }
  );

  get isValid() {
    if (
      !this.isEligible &&
      ((this.currentEvaluationDetail.interview && !this.currentEvaluationDetail.observation && !this.currentEvaluationDetail.test) ||
        (this.currentEvaluationDetail.review && !this.currentEvaluationDetail.observation && !this.currentEvaluationDetail.test))
    ) {
      return true;
    }
    if (!this.isEligible && !this.isInterview && !this.currentEvaluationNote?.domainAreas) {
      return false;
    }
    if (!this.isEligible && !this.isInterview && this.currentEvaluationNote?.domainAreas?.length === 0) {
      return false;
    }
    return true;
  }

  constructor(
    private fb: FormBuilder,
    private evaluationNoteService: EvaluationNoteService,
    private evaluationFormService: EvaluationFormService,
    private memoryService: MemoryStorageService,
    private caseService: CaseService,
    private dialog: MatDialog,
    private formService: FormService
  ) {
    this.subscription = evaluationFormService.formSubmitted$.subscribe(() => {
      if (this.currentEvaluationDetail?.notes?.length === 0) {
        this.formGroup.markAllAsTouched();
      } else {
        this.formGroup.controls.note.markAsUntouched();
      }
    });
  }

  ngOnInit(): void {
    const currentEvaluation = this.memoryService.getKey('currentEvaluation', true);
    this.learnerAgeInMonths =
      this.currentEvaluationDetail && this.currentEvaluationDetail.dateOfEvaluation
        ? dayjs(this.currentEvaluationDetail?.dateOfEvaluation).diff(currentEvaluation.learner.dateOfBirth, 'month')
        : -1;
    this.caseId = currentEvaluation.caseId;
    this.setupAgeEquivalentSubscription();
    this.caseService.getEligibility(currentEvaluation?.caseId).subscribe((res) => (this.isEligible = res));
    if (!this.currentEvaluationNote && !this.isInterview) {
      this.reset();
    }
    if (!this.currentInterviewNote && this.isInterview) {
      this.reset(null, true);
    }
    if (!this.isInterview) {
      this.formGroup.get('note').setValidators(Validators.required);
    } else {
      this.formGroup
        .get('note')
        .setValidators(conditionalValidator(() => !this.hasAtLeastOneNoteForInterviewQuestion, Validators.required));
    }

    this.subscription.add(
      this.formService.showAllErrors$.subscribe(() => {
        this.formGroup.markAllAsTouched();
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.currentEvaluationNote) {
      if (this.currentEvaluationNote.evaluationDetailInterviewQuestionId) {
        this.currentInterviewNote = { ...this.currentEvaluationNote };
      }
      this.formGroup.patchValue(this.currentEvaluationNote);
    }
  }

  get hasAtLeastOneNoteForInterviewQuestion() {
    return this.currentEvaluationNotes?.filter((x) => x.interviewQuestion === this.interviewQuestion?.label)?.length > 0;
  }

  onRangeClick() {
    const ageEquivalentHigh = this.formGroup.get('ageEquivalentHigh').value;
    if (ageEquivalentHigh) {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        width: '450px',
        data: {
          subQuestion: 'Clicking Yes will continue this action.',
        },
      });
      dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.isAgeRange = !this.isAgeRange;
          this.formGroup.patchValue({
            ageEquivalentHigh: null,
            calculatedPercentDelayHigh: null,
          });
        }
      });
    } else {
      this.isAgeRange = !this.isAgeRange;
    }
  }

  refresh() {
    this.learnerAgeInMonths =
      this.currentEvaluationDetail && this.currentEvaluationDetail.dateOfEvaluation
        ? dayjs(this.currentEvaluationDetail?.dateOfEvaluation).diff(
            this.memoryService.getKey('currentEvaluation', true).learner.dateOfBirth,
            'month'
          )
        : -1;
    this.calculatePercentDelay(
      this.learnerAgeInMonths,
      this.formGroup.controls.ageEquivalentHigh.value,
      this.formGroup.get('ageEquivalentHigh').value
    );
    this.calculatePercentDelay(
      this.learnerAgeInMonths,
      this.formGroup.controls.ageEquivalentLow.value,
      this.formGroup.get('ageEquivalentLow').value
    );
  }

  private reset(note?: EvaluationNote, isInterview?: boolean) {
    if (isInterview) {
      this.currentInterviewNote = {
        ecoAreas: [],
        domainAreas: [],
        evaluationRoutines: [],
        taggedForOutcomes: null,
        taggedForPwn: null,
        taggedForServicesC: null,
      } as EvaluationNote;
    } else {
      this.currentEvaluationNote = {
        ecoAreas: [],
        domainAreas: [],
        evaluationRoutines: [],
        taggedForOutcomes: null,
        taggedForPwn: null,
        taggedForServicesC: null,
      } as EvaluationNote;
    }
    if (note) {
      this.evaluationFormService.submitNote(note);
      this.formRef.resetForm();
      this.formGroup.reset();
      if (this.evaluationTagRef) {
        this.evaluationTagRef.onReset();
      }
    }
  }

  onEditNote(note: EvaluationNote) {
    this.evaluationFormService.editNote(note);
    this.currentInterviewNote = note;
    this.formGroup.patchValue(note);
  }

  onRemoveNote(note: EvaluationNote) {
    this.evaluationNoteService.delete(this.currentEvaluationDetail.evaluationId, this.currentEvaluationDetail.id, note.id).subscribe(() => {
      this.evaluationFormService.deleteNote(note);
      this.formGroup.get('note').updateValueAndValidity();
    });
  }

  async onSubmit() {
    if (
      (this.currentEvaluationDetail.observation || this.currentEvaluationDetail.test) &&
      this.currentEvaluationNote?.domainAreas?.length === 0
    ) {
      this.noteSubmit.emit(true);
      this.formGroup.markAllAsTouched();
      return;
    }
    if (this.formGroup.valid && this.isValid) {
      const note = {
        ...this.currentEvaluationNote,
        ...this.formGroup.value,
      } as EvaluationNote;
      const res = await this.evaluationNoteService
        .save(this.currentEvaluationDetail.evaluationId, this.currentEvaluationDetail.id, note)
        .toPromise();
      this.reset(res);
    }

    this.noteSubmit.emit(true);
    this.formGroup.markAllAsTouched();
    return true;
  }

  async onInterviewNoteSubmit() {
    const note = {
      ...this.currentInterviewNote,
      ...this.formGroup.value,
    } as EvaluationNote;
    if (this.interviewQuestion) {
      note.evaluationDetailInterviewQuestionId = this.interviewQuestion.id;
    }
    const res = await this.evaluationNoteService
      .save(this.currentEvaluationDetail.evaluationId, this.currentEvaluationDetail.id, note)
      .toPromise();
    this.reset(res, true);
    return true;
  }

  updatePreSavedNoteTags(tagsFor: TaggedForCategory) {
    if (tagsFor === TaggedForCategory.Pwn) {
      this.currentEvaluationNote.taggedForPwn = !this.currentEvaluationNote?.taggedForPwn;
    } else if (tagsFor === TaggedForCategory.ServicesC) {
      this.currentEvaluationNote.taggedForServicesC = !this.currentEvaluationNote?.taggedForServicesC;
    } else if (tagsFor === TaggedForCategory.Outcomes) {
      this.currentEvaluationNote.taggedForOutcomes = !this.currentEvaluationNote?.taggedForOutcomes;
    }
  }

  //#region Form Subscriptions
  private setupAgeEquivalentSubscription() {
    this.formGroup.controls.ageEquivalentLow.valueChanges.subscribe((value: number) => {
      if (value) {
        this.calculatePercentDelay(this.learnerAgeInMonths, value, this.formGroup.get('ageEquivalentHigh').value);
      }
    });

    this.formGroup.controls.ageEquivalentHigh.valueChanges.subscribe((value: number) => {
      if (value) {
        this.calculatePercentDelay(this.learnerAgeInMonths, this.formGroup.get('ageEquivalentLow').value, value);
      }
    });
  }

  private calculatePercentDelay(learnerCurrentAge: number, ageEquivalentLow: number, ageEquivalentHigh?: number) {
    if (!learnerCurrentAge || learnerCurrentAge < 0) {
      return;
    }

    const calculatedPercentDelayLow = (learnerCurrentAge - ageEquivalentLow) / learnerCurrentAge;

    let calculatedPercentDelayHigh;
    if (ageEquivalentHigh) {
      calculatedPercentDelayHigh = (learnerCurrentAge - ageEquivalentHigh) / learnerCurrentAge;
    }

    this.formGroup.patchValue({
      calculatedPercentDelayLow,
      calculatedPercentDelayHigh,
    });
  }
  //#endregion
}
