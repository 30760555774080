import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { EcoRatingType } from '../../../../evaluation/evaluation/steps/eco-wizard/eco-rating';
import { EcoRatingService } from '../../../../evaluation/evaluation/steps/eco-wizard/eco-rating.service';
import { EcoWizardData } from '../../../../evaluation/evaluation/steps/eco-wizard/eco-wizard.component';
import { EcoMatrixModalComponent } from '../../../../evaluation/modals/eco-matrix-modal/eco-matrix-modal.component';
import { EcoArea } from '../../../../evaluation/models/evaluation';
import { CaseSummary, IntakeType } from '../../../models/case';
import { KeyValuePair } from '../../../models/key-value-pair';
import { DeactivationService } from '../../../services/deactivation.service';
import { EcoAreaService } from '../../../services/eco-areas/eco-area.service';
import { LearnerService } from '../../../services/learner/learner.service';
import { NotificationService } from '../../../services/notification.service';
import { BaseComponent } from '../../base-component/base-component';

@Component({
  selector: 'app-case-eco-rating',
  templateUrl: './case-eco-rating.component.html',
  styleUrls: ['./case-eco-rating.component.scss'],
})
export class CaseEcoRatingComponent extends BaseComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() caseSummary: CaseSummary;
  @Input() ecoRatingProgresses: KeyValuePair[];

  @Output() ecoRatingUpdateEvent = new EventEmitter();

  ecoAreas: EcoArea[];
  yesNoOptions = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];
  hasBeenPrompted = false;
  intakeTypes = IntakeType;

  get ecoAreaForm(): FormArray {
    return this.formGroup.get('ecoAreas') as FormArray;
  }

  constructor(
    private ecoAreaService: EcoAreaService,
    private dialog: MatDialog,
    private readonly ecoRatingService: EcoRatingService,
    private readonly notificationService: NotificationService,
    deactivationService: DeactivationService,
    private readonly caseService: CaseService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.ecoAreaService.get().subscribe((res) => {
        this.ecoAreas = res;
        this.initEcoRatingControls();
      })
    );
  }

  async initEcoRatingControls() {
    this.ecoAreaForm?.clear({ emitEvent: false });

    const ecoRatingProgess = await this.caseService.getCaseEcoRatingProgress(this.caseSummary?.id).toPromise();
    this.ecoRatingProgresses = ecoRatingProgess.value;

    this.ecoAreas.forEach((ecoArea) => {
      this.ecoRatingService.get(this.caseSummary.id, ecoArea.id, EcoRatingType.Case).subscribe((result) => {
        const ecoRatingFormGroup = new FormGroup({
          id: new FormControl(ecoArea.id),
          ecoRatingId: new FormControl(result?.id),
          score: new FormControl(result ? result.score : null),
          scoreText: new FormControl(
            result ? this.ecoRatingService.getSuggestedEcoRatingText(result.score, this.caseSummary.learner.firstName) : null
          ),
          label: new FormControl(ecoArea.label),
          type: new FormControl(ecoArea.type),
          madeProgress: new FormControl(this.ecoRatingProgresses?.find((x) => x.key === result?.id)?.value),
        });
        this.ecoAreaForm.insert(ecoArea.order, ecoRatingFormGroup);
      });
    });
  }

  launchEcoDecisionTree(ecoArea: AbstractControl) {
    // prompt the user once per page load so it's not annoying
    if (this.hasBeenPrompted) {
      this.openEcoWizard(ecoArea);
    } else {
      this.notificationService.confirmation(
        `The ${
          this.caseSummary?.intakeType === this.intakeTypes.PartB ? 'Special Education' : 'Early ACCESS'
        } Team will only determine the exit ECO rating if this child is not eligible for special education and/or will not have an IEP finalized on the child's third birthday.`,
        () => {
          this.hasBeenPrompted = true;
          this.openEcoWizard(ecoArea);
        }
      );
    }
  }

  private async openEcoWizard(ecoArea: AbstractControl): Promise<void> {
    const modalRef = this.dialog.open(EcoMatrixModalComponent, {
      width: '1200px',
      data: {
        ecoAreaId: ecoArea.get('id').value,
        learnersFirstName: this.caseSummary.learner.firstName,
        intakeType: IntakeType.PartB,
        type: EcoRatingType.Case,
        caseId: this.caseSummary?.id,
      } as EcoWizardData,
    });

    const result = await modalRef.afterClosed().toPromise();
    if (result) {
      const newEcoRatings = this.ecoAreaForm.value?.filter((x) => x.ecoRatingId === null);
      if (newEcoRatings.length > 0) {
        this.initEcoRatingControls();
      } else {
        ecoArea.get('score').setValue(result.ecoRatingScore);
        ecoArea
          .get('scoreText')
          .setValue(this.ecoRatingService.getSuggestedEcoRatingText(result.ecoRatingScore, this.caseSummary.learner.firstName));
      }

      this.ecoRatingUpdateEvent.emit();
    }
  }

  progressQuestionRequired(madeProgressControl: AbstractControl) {
    if (this.caseSummary?.exiting) {
      return madeProgressControl.value == null || madeProgressControl.value === '';
    }
    return false;
  }
}
