<mat-accordion class="accordion accordion--short">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class="my-0 text-normal">{{ title }} Details</h3>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div *ngIf="allowFormEdit; else readOnlyTemplate">
      <form [formGroup]="formGroup" (submit)="onSubmit()">
        <div class="row">
          <ng-container *ngIf="isPartC">
            <div class="col-md-12 col-lg-6">
              <app-date-picker label="Date of Referral" controlName="dateOfReferral" [min]="learnersBirthDate"></app-date-picker>
            </div>

            <div class="col-md-12 col-lg-6">
              <app-date-picker
                label="Date of Consent for Evaluation"
                controlName="dateofConsentForEvaluation"
                [max]="today"
              ></app-date-picker>
            </div>

            <div class="col-md-12">
              <fieldset>
                <app-radio-group
                  label="Reason for Eligibility"
                  formControlName="reasonForEligibility"
                  [options]="reasonForEligibilityOptions"
                  [useUndo]="true"
                >
                  ></app-radio-group
                >
              </fieldset>
            </div>

            <div class="col-md-12 col-lg-9">
              <app-textarea formControlName="description" label="Description" [rows]="4" [maxLength]="10000"></app-textarea>
            </div>
          </ng-container>
          <ng-container *ngIf="!isPartC">
            <div class="col-md-12 col-lg-6">
              <app-date-picker label="Date of Eligibility" controlName="dateOfEligibility" [max]="today"></app-date-picker>
            </div>

            <div class="col-md-12 col-lg-6" *ngIf="!eligibleInLegacySystem">
              <app-date-picker label="Current IEP Start Date" controlName="currentIepStartDate" [max]="today"></app-date-picker>
            </div>

            <div class="col-md-12 col-lg-6">
              <app-date-picker label="Reevaluation Due Date" controlName="reevaluationDueDate" [min]="today"></app-date-picker>
            </div>

            <div class="col-md-12">
              <app-checkbox
                controlName="haveAnyFollowing"
                [label]="haveAnyFollowingLabel"
                [options]="haveAnyFollowingOptions"
              ></app-checkbox>
            </div>
          </ng-container>
          <div class="col-md-12 display-flex justify-content-end">
            <button mat-raised-button color="primary" aria-label="Cancel" type="button" *ngIf="canCancel" (click)="onCancel()" class="mr-2">
              Remove {{ title }} Details
            </button>
            <button mat-raised-button color="primary" aria-label="Complete" type="submit" [disabled]="formGroup.invalid || submitting">
              Complete
            </button>
          </div>
        </div>
      </form>
    </div>
    <ng-template #readOnlyTemplate>
      <div class="row">
        <ng-container *ngIf="isPartC">
          <div class="col-md-12 col-lg-6">
            <p class="text-semibold">Date of Referral</p>
            <p>{{ (formGroup.get('dateOfReferral').value | dateFormat) || '-' }}</p>
          </div>
          <div class="col-md-12 col-lg-6">
            <p class="text-semibold">Date of Consent for Evaluation</p>
            <p>{{ (formGroup.get('dateofConsentForEvaluation').value | dateFormat) || '-' }}</p>
          </div>

          <div class="col-md-12 col-lg-6">
            <p class="text-semibold">Reason for Eligibility</p>
            <p>{{ getReasonForEligibilityLabel(formGroup.get('reasonForEligibility').value) || '-' }}</p>
          </div>

          <div class="col-md-12 col-lg-6">
            <p class="text-semibold">Description</p>
            <p>{{ formGroup.get('description').value || '-' }}</p>
          </div>
        </ng-container>
        <ng-container *ngIf="!isPartC">
          <div class="col-md-12 col-lg-6">
            <p class="text-semibold">Date of Eligibility</p>
            <p>{{ (formGroup.get('dateOfEligibility').value | dateFormat) || '-' }}</p>
          </div>

          <div class="col-md-12 col-lg-6" *ngIf="!eligibleInLegacySystem">
            <p class="text-semibold">Current IEP Start Date</p>
            <p>{{ (formGroup.get('currentIepStartDate').value | dateFormat) || '-' }}</p>
          </div>

          <div class="col-md-12 col-lg-6">
            <p class="text-semibold">Reevaluation Due Date</p>
            <p>{{ (formGroup.get('reevaluationDueDate').value | dateFormat) || '-' }}</p>
          </div>

          <div class="col-md-12 col-lg-9">
            <p class="text-semibold">{{ haveAnyFollowingLabel }}</p>
            <p *ngFor="let option of haveAnyFollowingOptions">
              <span>{{ option.value }} - </span><span class="text-semibold">{{ findHaveAnyValues(option.key) | boolToYesNo }}</span>
            </p>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
