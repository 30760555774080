import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { Observable } from 'rxjs';
import { GeneralSurveyQuestion } from 'src/app/shared/models/general-survey/general-survey-question';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { IepService } from 'src/app/iep/services/iep.service';
import { GeneralSurveyGeneralSurveyQuestion } from 'src/app/shared/models/general-survey/general-survey-general-survey-question';
import { GeneralSurvey } from 'src/app/shared/models/general-survey/general-survey';
import { GeneralSurveyAnswer } from 'src/app/shared/models/general-survey/general-survey-answer';
import { GeneralSurveyService } from 'src/app/shared/services/general-survey/general-survey.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared/services/notification.service';
import { DeactivationService } from '../../../shared/services/deactivation.service';
import { CaseService } from '../../../shared/services/case/case.service';
import { CaseSummaryByIepId } from '../../../shared/models/case';

@Component({
  selector: 'app-iep-survey',
  templateUrl: './iep-survey.component.html',
  styleUrls: ['./iep-survey.component.scss'],
})
export class IepSurveyComponent extends BaseComponent implements OnInit, OnDestroy {
  iepId: string;
  caseId: string;
  reviewType = '';
  formGroup: FormGroup = new FormGroup({});
  generalSurvey: GeneralSurvey;
  questions: GeneralSurveyQuestion[] = [];
  activeCall = false;
  pageTitle = 'IEP';
  caseSummary: CaseSummaryByIepId;
  dataLoadCompleted = false;
  save = new Observable<boolean>((observer) => {
    this.cd.detectChanges();
    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };
    if (!this.formGroup.dirty || this.isSaving) {
      done();
      return;
    }
    this.isSaving = true;
    this.saveForm(true).subscribe(() => {
      done();
    });
  });

  yesNoOptions: KeyValuePair[] = [new KeyValuePair('Yes', 'Yes'), new KeyValuePair('No', 'No')];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private iepService: IepService,
    private generalSurveyService: GeneralSurveyService,
    private readonly dialog: MatDialog,
    private readonly cd: ChangeDetectorRef,
    deactivationService: DeactivationService,
    private caseService: CaseService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.iepId = this.route.snapshot.paramMap.get('iepId');
    this.reviewType = 'iep';

    this.iepService.getSurvey(this.iepId).subscribe(
      (data) => {
        this.generalSurvey = data;
        this.questions = data.generalSurveyGeneralSurveyQuestionDtos
          .sort((left, right) => this.sortByOrder(left, right))
          .map((x) => x.generalSurveyQuestionDto);
        const group = {};
        data.generalSurveyGeneralSurveyQuestionDtos.forEach((question) => {
          group[question.generalSurveyQuestionDto.id] = question.generalSurveyAnswerDto.answer ?? '';
        });

        this.formGroup = this.fb.group(group);
        if (this.generalSurvey.completedDateTime) {
          this.formGroup.disable();
        } else {
          this.saveSubscription = this.formGroup.valueChanges;
          this.startAutosaving();
        }
        this.dataLoadCompleted = true;
      },
      () => {
        this.dataLoadCompleted = true;
      }
    );

    this.caseService.getCaseSummaryByIep(this.iepId).subscribe((caseSummary) => {
      this.caseSummary = caseSummary;
    });
  }

  sortByOrder(left: GeneralSurveyGeneralSurveyQuestion, right: GeneralSurveyGeneralSurveyQuestion): number {
    if (left.generalSurveyQuestionDto.order < right.generalSurveyQuestionDto.order) {
      return -1;
    }

    if (left.generalSurveyQuestionDto.order > right.generalSurveyQuestionDto.order) {
      return 1;
    }
    return 0;
  }

  saveForm(autoSave: boolean): Observable<void> {
    const answers: GeneralSurveyAnswer[] = [];
    Object.keys(this.formGroup.controls).forEach((key) => {
      this.generalSurvey.generalSurveyGeneralSurveyQuestionDtos.forEach((y) => {
        if (key.toLocaleLowerCase() === y.generalSurveyQuestionDto.id.toLocaleLowerCase()) {
          answers.push({
            id: y.generalSurveyAnswerDto.id,
            answer: this.formGroup.controls[key].value.toLocaleLowerCase(),
          });
        }
      });
    });
    if (autoSave) {
      return this.generalSurveyService.updateSurveyAnswers(this.generalSurvey.id, answers);
    }
    return this.generalSurveyService.submitSurvey(this.generalSurvey.id, answers);
  }

  submit() {
    this.activeCall = true;
    this.saveForm(false).subscribe(() => {
      this.dialog.open(AlertDialogComponent, {
        data: {
          title: 'Questionnaire Complete',
          message: 'Thank you for participating in our questionnaire. You may close this window.',
        },
      });
      this.activeCall = false;
      this.formGroup.disable();
      this.stopAutosaving();
    });
  }

  // TODO: Refactor
  checkEnabled(question: GeneralSurveyQuestion): boolean {
    if (!question.enableCondition) {
      return true;
    }

    const conditionSplit = question.enableCondition.split('==');
    if (conditionSplit.length !== 2) {
      return true;
    }

    const cont = this.formGroup.get(conditionSplit[0].trim().toLocaleLowerCase());
    if (cont && cont.value && cont.value.toLocaleLowerCase() === conditionSplit[1].trim().toLocaleLowerCase()) {
      return true;
    }
  }
}
