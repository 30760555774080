import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LookupBase } from 'src/app/shared/models/lookup-base';

@Component({
  selector: 'app-domain-selector',
  templateUrl: './domain-selector.component.html',
  styleUrls: ['./domain-selector.component.scss'],
})
export class DomainSelectorComponent {
  @Input()
  domainOptions: LookupBase[] = [];

  @Input()
  selectedDomains: LookupBase[] = [];

  @Input() disableDomainSelector: boolean;

  @Output()
  selected = new EventEmitter<LookupBase[]>();

  toggleDomain(domain) {
    if (this.disableDomainSelector) {
      return;
    }
    const idx = this.selectedDomains.findIndex((x) => x.id === domain.id && x.label === domain.label);
    let newlySelectedDomainIds = [];
    if (idx > -1) {
      newlySelectedDomainIds = [...this.selectedDomains.slice(0, idx), ...this.selectedDomains.slice(idx + 1)];
    } else {
      newlySelectedDomainIds = [...this.selectedDomains, domain];
    }
    this.selected.emit(newlySelectedDomainIds);
  }

  getDomainChecked(domain) {
    const domainMatches = this.selectedDomains.find((x) => x.id === domain.id);
    const labelMatches = this.selectedDomains.find((x) => x.label === domain.label);
    return domainMatches && labelMatches ? true : false;
  }
}
