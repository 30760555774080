<mat-card class="mt-4 transfer-case__table">
  <h3>Filter By</h3>
  <form [formGroup]="formGroup" (submit)="onSearch()">
    <div class="row">
      <div class="col-md-12 col-lg-4">
        <app-autocomplete [options]="aeasOptions" label="AEA" formControlName="aeaId"></app-autocomplete>
      </div>
      <div class="col-md-12 col-lg-4">
        <app-text-field label="First Name" formControlName="firstName"></app-text-field>
      </div>

      <div class="col-md-12 col-lg-4">
        <app-text-field label="Last Name" formControlName="lastName"></app-text-field>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-xl-4">
        <app-autocomplete [options]="districtsOptions" label="School District" formControlName="schoolDistrictId"></app-autocomplete>
      </div>
      <div class="col-md-12 col-xl-4">
        <app-autocomplete [options]="buildingOptions" label="Building" formControlName="buildingId"></app-autocomplete>
      </div>

      <div class="col-md-12 col-xl-4">
        <app-autocomplete [options]="professionOptions" label="Position" formControlName="professionId"></app-autocomplete>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="display-flex justify-content-end">
          <span matTooltip="At least one filter is required to search." [matTooltipDisabled]="canSearch">
            <button mat-raised-button aria-label="Search Button" color="primary" tabindex="0" [disabled]="!canSearch">Search</button></span
          >
        </div>
      </div>
    </div>
  </form>

  <section class="table-overflow mt-3">
    <table mat-table matSort [dataSource]="dataSource" class="w-100">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
        <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="profession">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Position</th>
        <td mat-cell *matCellDef="let element">{{ element.jobTitle }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
    </table>
    <p *ngIf="!dataSource.data">No data yet...</p>

    <mat-paginator [dataSource]="dataSource"></mat-paginator>
  </section>
</mat-card>
