import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EcoWizardComponent, EcoWizardData } from '../../evaluation/steps/eco-wizard/eco-wizard.component';

@Component({
  selector: 'app-eco-matrix-modal',
  templateUrl: './eco-matrix-modal.component.html',
  styleUrls: ['./eco-matrix-modal.component.scss'],
})
export class EcoMatrixModalComponent implements OnInit {
  saving = false;

  @ViewChild(EcoWizardComponent) ecoWizard: EcoWizardComponent;
  constructor(public dialog: MatDialogRef<EcoMatrixModalComponent>, @Inject(MAT_DIALOG_DATA) public data: EcoWizardData) {}

  ngOnInit(): void {}

  onSaveClose() {
    this.ecoWizard.queueSave();
    this.dialog.close(this.data);
  }

  onCancel() {
    this.dialog.close(null);
  }

  suggestedEcoRatingScore(event) {
    this.data.suggestedEcoRatingScore = event;
  }

  ecoRatingScore(event) {
    this.data.ecoRatingScore = event;
  }

  setSaveStatus(isSaving: boolean) {
    this.saving = isSaving;
  }
}
