<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Medicaid Reports</h3>

<div mat-dialog-content class="pt-3 pb-5">
  <mat-card>
    <form [formGroup]="formGroup">
      <div class="d-flex">
        <div class="flex-fill">
          <app-autocomplete [options]="reportOptions" formControlName="reportType" label="Select a Report"></app-autocomplete>
        </div>
      </div>
      <div class="d-flex">
        <div class="p-2 flex-fill">
          <app-date-picker label="Start Date" controlName="startDate"></app-date-picker>
        </div>
        <div class="p-2 pt-4">to</div>
        <div class="p-2 flex-fill">
          <app-date-picker label="End Date" controlName="endDate"></app-date-picker>
        </div>
      </div>
    </form>
  </mat-card>
</div>

<div mat-dialog-actions class="actions">
  <button mat-button aria-label="Cancel Button" class="background-color--muted" (click)="onNoClick()" tabindex="0">Cancel</button>
  <button
    mat-flat-button
    aria-label="Export Report"
    color="primary"
    (click)="runReport()"
    [disabled]="formGroup.invalid || isBusy"
    tabindex="0"
  >
    Export Report
  </button>
</div>
