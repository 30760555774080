<form [formGroup]="formGroup">
  <mat-card>
    <mat-card-title>Primary Health Care Provider Information</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg">
          <app-text-field
            *ngIf="isQuestionnaire"
            [disabledState]="locked"
            label="Clinic / Agency Name"
            formControlName="phcpClinicAgencyName"
            [isBankableField]="!isQuestionnaire"
            [bankableFieldId]="bankableFieldIds.providerInfoForm.phcpClinicAgencyName"
            [bankableFieldChildFname]="intake?.learner?.firstName"
          ></app-text-field>
          <app-overwrite-text-field
            *ngIf="!isQuestionnaire"
            [disabledState]="locked"
            label="Clinic / Agency Name"
            formControlName="phcpClinicAgencyName"
            [sourceData]="questionnaire.healthInfo.providerInformation"
            [isBankableField]="!isQuestionnaire"
            [bankableFieldId]="bankableFieldIds.providerInfoForm.phcpClinicAgencyName"
            [bankableFieldChildFname]="intake?.learner?.firstName"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg">
          <app-masked-input
            [disabledState]="locked"
            label="Phone"
            formControlName="phcpPhone"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.healthInfo.providerInformation"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input
            [disabledState]="locked"
            label="Fax"
            formControlName="phcpFax"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.healthInfo.providerInformation"
            mask="(000) 000-0000"
          >
          </app-masked-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Address"
            formControlName="phcpStreetAddress"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Address"
            formControlName="phcpStreetAddress"
            [sourceData]="questionnaire.healthInfo.providerInformation"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Zip Code"
            formControlName="phcpZipCode"
            (blurMe)="populateLocationFromZipCode()"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Zip Code"
            formControlName="phcpZipCode"
            (blurMe)="populateLocationFromZipCode()"
            [sourceData]="questionnaire.healthInfo.providerInformation"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg">
          <app-text-field [disabledState]="locked" *ngIf="isQuestionnaire" label="City / Town" formControlName="phcpCity"></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="City / Town"
            formControlName="phcpCity"
            [sourceData]="questionnaire.healthInfo.providerInformation"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg">
          <app-autocomplete
            [disabledState]="locked"
            [useOverwrite]="!isQuestionnaire"
            [options]="usStates"
            formControlName="phcpState"
            label="State"
            [sourceData]="questionnaire.healthInfo.providerInformation"
          ></app-autocomplete>
        </div>
      </div>
      <div class="row">
        <div *ngIf="!locked" class="col-md-2">
          <app-autocomplete
            [useOverwrite]="!isQuestionnaire"
            [options]="phcpTitleOptions"
            formControlName="phcpTitle"
            label="Title"
            [sourceData]="questionnaire.healthInfo.providerInformation"
          ></app-autocomplete>
        </div>
        <div class="col-lg">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Name"
            formControlName="phcpName"
            [isBankableField]="!isQuestionnaire"
            [bankableFieldId]="bankableFieldIds.providerInfoForm.phcpName"
            [bankableFieldChildFname]="intake?.learner?.firstName"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Name"
            formControlName="phcpName"
            [sourceData]="questionnaire.healthInfo.providerInformation"
            [isBankableField]="!isQuestionnaire"
            [bankableFieldId]="bankableFieldIds.providerInfoForm.phcpName"
            [bankableFieldChildFname]="intake?.learner?.firstName"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Email"
            formControlName="phcpEmail"
            [isBankableField]="!isQuestionnaire"
            [bankableFieldId]="bankableFieldIds.providerInfoForm.phcpEmail"
            [bankableFieldChildFname]="intake?.learner?.firstName"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Email"
            formControlName="phcpEmail"
            [sourceData]="questionnaire.healthInfo.providerInformation"
            [isBankableField]="!isQuestionnaire"
            [bankableFieldId]="bankableFieldIds.providerInfoForm.phcpEmail"
            [bankableFieldChildFname]="intake?.learner?.firstName"
          ></app-overwrite-text-field>
        </div>
        <div class="col-md-2">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Phone Ext"
            formControlName="phcpPhoneExtension"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Phone Ext"
            formControlName="phcpPhoneExtension"
            [sourceData]="questionnaire.healthInfo.providerInformation"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg mt-2 ml-1">
          <app-checkbox-single formControlName="phcpUnknown" aria-labelledby="Check Unknown" label="Unknown"></app-checkbox-single>
        </div>
      </div>
      <div class="row" *ngIf="!isQuestionnaire">
        <div class="col-lg-5 display-flex align-items-center">
          <button mat-raised-button aria-label="Quick Actions" color="accent" [matMenuTriggerFor]="quickMenu" tabindex="0">
            Quick Actions
          </button>
          <mat-menu #quickMenu="matMenu" panelClass="menu--long">
            <button mat-menu-item attr.aria-label="getConsentToReleaseLabel" (click)="getOrCreateConsentToRelease()" tabindex="0">
              {{ getConsentToReleaseLabel }}
            </button>
            <button
              [disabled]="disableIcdDownload()"
              mat-menu-item
              aria-label="Download ICD 10 Physician Letter"
              (click)="downloadIcdPhysicianLetter()"
              tabindex="0"
            >
              Download ICD 10 Physician Letter
            </button>
            <button
              mat-menu-item
              aria-label="Indicate Records Received"
              [disabled]="formGroup.controls.phcpRecordsReceived.value"
              (click)="indicateRecordsReceived()"
              tabindex="0"
            >
              Indicate Records Received
            </button>
          </mat-menu>
          <ng-container *ngIf="formGroup.controls.phcpRecordsReceived.value">
            <svg-icon src="assets/img/icons/Record-Received.svg" svgClass="icon icon--received"></svg-icon>
          </ng-container>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top">
    <mat-card-title>Medical Specialists</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoOptions"
              formControlName="haveMedicalSpecialists"
              label="Does the child see any medical specialists?"
              [sourceData]="questionnaire.healthInfo.providerInformation"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="row" *ngIf="formGroup.get('haveMedicalSpecialists').value">
        <div class="col-lg">
          <!-- TODO: check how/if overwrite app-medical-specialist-table. -->
          <app-medical-specialist-table
            (documentUploaded)="refreshIntakeAfterDocumentUpload()"
            [isQuestionnaire]="isQuestionnaire"
            [questionnaire]="questionnaire"
            [intake]="intake"
            [locked]="locked"
            [importQuestionnaireSelected]="importQuestionnaireSelected"
            [releaseAndExchangeConsents]="releaseAndExchangeConsents"
          ></app-medical-specialist-table>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top">
    <mat-card-title>Other Agencies / Programs Family Involved With</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoOptions"
              formControlName="involvedWithOtherAgency"
              label="Is the family involved with any other agencies or programs, such as Early Head Start, home visiting, WIC, private therapy?"
              [sourceData]="questionnaire.healthInfo.providerInformation"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="row" *ngIf="formGroup.get('involvedWithOtherAgency').value">
        <div class="col-lg">
          <app-agency-program-table
            [locked]="locked"
            *ngIf="intake || questionnaire"
            [isQuestionnaire]="isQuestionnaire"
            [questionnaire]="questionnaire"
            [intake]="intake"
            [importQuestionnaireSelected]="importQuestionnaireSelected"
            [releaseAndExchangeConsents]="releaseAndExchangeConsents"
            (documentUploaded)="refreshIntakeAfterDocumentUpload()"
          ></app-agency-program-table>
        </div>
      </div>
    </div>
  </mat-card>
</form>

<div class="card-spacing-top">
  <mat-card class="mb-3">
    <mat-card-title class="mb-1">Relevant Form(s)</mat-card-title>
    <div class="display-flex justify-content-between align-items-center">
      <a class="mb-2 mt-2" (click)="openEmptyReleaseForm()" role="link" aria-labelledby="Authorization to Release and Exchange Information"
        >Authorization to Release and Exchange Information</a
      >
    </div>
  </mat-card>
</div>
