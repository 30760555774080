import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FamilyMeetingService } from 'src/app/learner-management/family-meeting/family-meeting.service';
import { FamilyMeetingRead, FamilyMeetingUpdate, IntakeType } from '../../models/case';
import { KeyValuePair } from '../../models/key-value-pair';
import { AchieveConfigService } from '../../services/achieve-config-service/achieve-config.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-reschedule-meeting-modal',
  templateUrl: './reschedule-meeting-modal.component.html',
  styleUrls: ['./reschedule-meeting-modal.component.scss'],
})
export class RescheduleMeetingModalComponent implements OnInit {
  today: Date;
  familyMeeting: FamilyMeetingRead;
  caseId: string;
  rescheduleReasonOptions: KeyValuePair[] = [];
  isOther: boolean;
  activeCall = false;

  formGroup = this.fb.group({
    date: [null, [Validators.required]],
    time: [null, [Validators.required]],
    location: [null, [Validators.required]],
    rescheduledReasonId: [null],
    otherDescription: [null],
  });

  constructor(
    private fb: FormBuilder,
    private readonly familyMeetingService: FamilyMeetingService,
    private dialogRef: MatDialogRef<RescheduleMeetingModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { caseId: string; familyMeeting: FamilyMeetingRead },
    private notificationService: NotificationService,
    private readonly achieveConfigService: AchieveConfigService
  ) {
    this.caseId = data.caseId;
    this.familyMeeting = data.familyMeeting;
  }

  ngOnInit(): void {
    this.today = new Date();
    this.familyMeetingService.getRescheduledReasons(this.caseId).subscribe((reasons) => {
      this.rescheduleReasonOptions = reasons.map((val) => new KeyValuePair(val.id, val.label));
    });

    this.formGroup
      .get('rescheduledReasonId')
      .setValidators(this.familyMeeting.intakeType === IntakeType.PartB ? [] : [Validators.required]);

    this.formGroup.get('rescheduledReasonId').valueChanges.subscribe((value) => {
      const reason = this.rescheduleReasonOptions.find((x) => x.key === value);
      const control = this.formGroup.get('otherDescription');
      if (reason && reason.value === 'Other') {
        control.setValidators(Validators.required);
        this.isOther = true;
      } else {
        control.setValidators(null);
        this.isOther = false;
      }
      control.updateValueAndValidity();
    });
  }

  onClose(newRescheduledMeeting?: FamilyMeetingRead) {
    this.dialogRef.close(newRescheduledMeeting);
  }

  async onSubmit() {
    this.activeCall = true;
    const dateAndTime = await this.achieveConfigService
      .getDateTimeFromTimeString(this.formGroup.controls.date.value, this.formGroup.controls.time.value)
      .toPromise();

    const rescheduleMeeting = () => {
      const rescheduledMeeting: FamilyMeetingUpdate = {
        id: this.familyMeeting.id,
        dateAndTime: dateAndTime,
        location: this.formGroup.controls.location.value,
        rescheduledReasonId: this.formGroup.controls.rescheduledReasonId.value,
      };
      this.familyMeetingService.rescheduleMeeting(this.caseId, rescheduledMeeting).subscribe(
        (newRescheduledMeeting) => {
          this.onClose(newRescheduledMeeting);
          this.activeCall = false;
        },
        (error) => (this.activeCall = false)
      );
    };
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      if (this.familyMeeting.isIep) {
        this.notificationService.confirmation(
          'Rescheduling this meeting will clear all the existing dates for any planned services of this IEP. If you had any custom dates, they will be set to null and you will need to reset those dates.',
          rescheduleMeeting,
          'Warning: Service Dates Will Reset'
        );
      } else {
        rescheduleMeeting();
      }
    }
  }
}
