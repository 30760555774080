<p class="form__description" *ngIf="showIvrs"><b>Action Needed:</b> The Learner age 14 or above, needs to get a signed IVRS consent</p>
<div *ngIf="incompleteItems" class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="cell--actions"></th>
      <td mat-cell *matCellDef="let element" class="cell--actions">
        <button
          color="primary"
          (click)="navigateToAction(element)"
          *ngIf="element.action"
          mat-flat-button
          aria-label="Resolve"
          tabindex="0"
        >
          Resolve
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="notice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Incomplete Data</th>
      <td mat-cell *matCellDef="let element">
        {{ element.notice | punctuation }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
<p *ngIf="!dataSource?.data">All items complete.</p>
