<ng-container *ngIf="showProvidedOn">
  <p *ngIf="mostRecentProceduralSafeguard; else notPS" class="form__description">
    The <i>Procedural Safeguards Manual for Parents</i> was last reviewed and provided to the parent(s) on
    <span class="text-primary text-semibold"> {{ mostRecentProceduralSafeguard.providedOn | dateFormat }} </span>. The parent(s)
    {{ mostRecentProceduralSafeguard.acceptedPrintedCopy ? 'accepted' : 'declined' }} the printed copy.
  </p>
  <ng-template #notPS>
    <p class="form__description">
      The parent has
      <span class="text-primary text-semibold">not yet</span> been provided a printed copy of the
      <i>Procedural Safeguards Manual for Parents</i>.
    </p>
  </ng-template>
</ng-container>

<ng-container [formGroup]="formGroup" *ngIf="hasEditPermission">
  <label class="vertical-label display-block w-100 mb-1" [ngClass]="{ 'mt-3': showProvidedOn }" for="providedById">
    Document the provision and review of the
    <i>Procedural Safeguards Manual for Parents</i>.
  </label>

  <div class="row">
    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-autocomplete
        label="Provided By"
        formControlName="providedById"
        id="providedById"
        [options]="teamMemberOptions"
      ></app-autocomplete>
    </div>

    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-date-picker label="Date Provided" [max]="today" [min]="dsDate" controlName="providedOn"></app-date-picker>
    </div>

    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-autocomplete label="How" [options]="providedMethodOptions" formControlName="howProvidedMethod"></app-autocomplete>
    </div>

    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-text-field *ngIf="isOtherProvidedMethod" label="Alternate Method" formControlName="otherHowProvided"></app-text-field>
    </div>

    <div class="col-md-12">
      <div class="vertical-control">
        <label
          class="text-md vertical-label"
          [ngClass]="{
            asterisk_input: formGroup.get('acceptedPrintedCopy').hasError('required')
          }"
        >
          Parent
        </label>
        <mat-radio-group formControlName="acceptedPrintedCopy" aria-labelledby="Parent">
          <mat-radio-button
            class="wrap"
            [value]="true"
            aria-labelledby="Parent accepted printed copy of Procedural Safeguards Manual for Parents."
          >
            Parent accepted printed copy of
            <i>Procedural Safeguards Manual for Parents</i>.
          </mat-radio-button>
          <mat-radio-button
            class="wrap"
            [value]="false"
            aria-labelledby="Parent declined printed copy of Procedural Safeguards Manual for Parents."
          >
            Parent declined printed copy of
            <i>Procedural Safeguards Manual for Parents</i>.
          </mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="formGroup.get('acceptedPrintedCopy').touched">
          <app-field-error-display [control]="formGroup.get('acceptedPrintedCopy')"></app-field-error-display>
        </mat-error>
      </div>
    </div>
  </div>
  <div class="display-flex justify-content-end align-items-center flex-wrap mt-2">
    <a
      [attr.href]="manualLink"
      target="_blank"
      class="mr-2"
      tabindex="0"
      role="link"
      aria-label="Procedural Safeguards Manual for Parents PDF link"
      >Procedural Safeguards Manual for Parents PDF</a
    >
    <button
      *ngIf="!caseExited"
      (click)="submit()"
      [disabled]="formGroup.invalid"
      [disableAfterBusy]="formGroup.invalid"
      [isBusy]="isBusy"
      mat-raised-button
      aria-label="Complete"
      color="primary"
      type="button"
      tabindex="0"
    >
      Complete
    </button>
  </div>
</ng-container>

<hr class="input__divider mb-2" *ngIf="showTable" />

<div *ngIf="showTable && hasViewPermission" class="row">
  <div class="col-md-12">
    <div class="table-overflow app-table">
      <table mat-table [dataSource]="dataSource" class="w-100" matSort>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let ps" class="mat-column-actions--more">
            <div class="display-flex align-items-center justify-content-start" *ngIf="hasEditPermission">
              <ng-container *ngIf="canEdit(ps.id)">
                <button aria-label="Edit Icon" mat-icon-button color="primary" (click)="onEdit(ps)" tabindex="0">
                  <mat-icon>edit</mat-icon>
                </button>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="providedOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Provided On</th>
          <td mat-cell *matCellDef="let ps">
            {{ ps.providedOn | dateFormat }}
          </td>
        </ng-container>
        <ng-container matColumnDef="providedBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Provided By</th>
          <td mat-cell *matCellDef="let ps">
            {{ ps.providedBy.fullName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="howProvidedString">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>How Provided</th>
          <td mat-cell *matCellDef="let ps">
            {{ ps.howProvidedString }}
          </td>
        </ng-container>
        <ng-container matColumnDef="acceptedPrintedCopy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Accepted Printed Copy</th>
          <td mat-cell *matCellDef="let ps">
            {{ ps.acceptedPrintedCopy ? 'Yes' : 'No' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No results</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          mat-footer-row
          *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
          class="example-second-footer-row"
          [hidden]="dataSource.data?.length > 0"
        ></tr>
      </table>
      <mat-paginator [dataSource]="dataSource"></mat-paginator>
    </div>
  </div>
</div>
