<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Confirm Deletion</h3>
<div mat-dialog-content class="py-3">
  <mat-card class="mb-0 white-space-pre-line"> {{ data.question }} </mat-card>
  <form [formGroup]="formGroup" [hidden]="data?.skipReason">
    <mat-card class="mb-0 card--plain">
      <div class="row">
        <div class="col-md">
          <app-textarea formControlName="cancelReason" [rows]="4" [maxLength]="10000" label="Cancellation Rationale"></app-textarea>
        </div>
      </div>
    </mat-card>
  </form>
</div>
<div mat-dialog-actions class="actions display-flex justify-content-end">
  <button mat-button aria-label="Cancel Button" (click)="dismiss()" cdkFocusInitial tabindex="0">Cancel</button>
  <button mat-raised-button aria-label="Delete" color="primary" (click)="deleteItem()" [disabled]="formGroup.invalid" tabindex="0">
    Delete
  </button>
</div>
