<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Add Early Childhood Program Contact</h3>

<div mat-dialog-content class="pt-3 pb-5">
  <mat-card>
    <form [formGroup]="formGroup">
      <app-text-field formControlName="searchTerm" label="Search" (keyup)="onFilter()"></app-text-field>
    </form>
  </mat-card>
  <mat-card class="mt-3 p-0 member__card" *ngIf="availableEcpContacts.length === 0">
    <div class="display-flex justify-content-between align-items-center">
      <span class="text-sm text-muted">No Early Childhood Program Contacts Available. Please add before selecting.</span>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top p-0 availableEcpContacts" *ngIf="availableEcpContacts && availableEcpContacts.length > 0">
    <ul class="member__list">
      <ng-container *ngFor="let member of availableEcpContacts">
        <li class="member__item">
          <div class="display-flex justify-content-between align-items-center">
            <span>
              {{ member?.fullName }}
            </span>
            <button mat-flat-button aria-label="Add Button" color="accent" (click)="addEcpContact(member)" tabindex="0">Add</button>
          </div>
        </li>
      </ng-container>
    </ul>
  </mat-card>

  <mat-card class="card-spacing-top p-0 member__card" *ngIf="selectedEcpContacts && selectedEcpContacts.length > 0">
    <ul class="member__list">
      <ng-container *ngFor="let participant of selectedEcpContacts">
        <li class="member__item">
          <div class="display-flex justify-content-between align-items-center">
            <span>
              {{ participant.fullName }}
            </span>
            <button mat-flat-button aria-label="Delete Icon" color="accent" (click)="removeEcpContact(participant)" tabindex="0">
              Remove
            </button>
          </div>
        </li>
      </ng-container>
    </ul>
  </mat-card>
</div>

<div mat-dialog-actions class="actions">
  <button mat-button aria-label="Cancel Button" class="background-color--muted" (click)="onNoClick()" tabindex="0">Cancel</button>
  <button mat-flat-button aria-label="Add to List" color="primary" *ngIf="selectedEcpContacts" (click)="onAccept()">Add to List</button>
</div>
