<mat-accordion class="accordion accordion--close accordion--short" multi>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Diagnosis</h3></mat-panel-title>
    </mat-expansion-panel-header>
    <app-data-history-part-c-diagnosis [learnerId]="case.learnerId"></app-data-history-part-c-diagnosis>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Early Childhood Outcomes Description</h3></mat-panel-title>
    </mat-expansion-panel-header>
    <app-data-history-part-c-eco-description [caseId]="case.id"></app-data-history-part-c-eco-description>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Eligibility</h3></mat-panel-title>
    </mat-expansion-panel-header>
    <app-data-history-part-c-eligibility [case]="case"></app-data-history-part-c-eligibility>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Evaluation / Assessment</h3></mat-panel-title>
    </mat-expansion-panel-header>
    <app-data-history-evaluation [case]="case"></app-data-history-evaluation>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Monitoring for Progress</h3></mat-panel-title>
    </mat-expansion-panel-header>
    <app-data-history-part-c-progress-monitoring [case]="case" [caseId]="case.id"></app-data-history-part-c-progress-monitoring>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Summary of Services</h3></mat-panel-title>
    </mat-expansion-panel-header>
    <app-data-history-part-c-other-services [caseId]="case.id"></app-data-history-part-c-other-services>
  </mat-expansion-panel>
</mat-accordion>
