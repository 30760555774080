<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Enter End Reason</h3>
<div *ngIf="showWeightingWarning" class="text-danger my-2">
  Ending this service may impact the weighting for this IEP. Please be sure to notify the following authorized individuals to review the
  weighting score: [logic for the list is pending future development]
</div>
<mat-dialog-content class="mat-typography" *ngIf="formControl">
  <app-autocomplete [options]="endReasonOptions" [formControl]="formControl" label="End Reason"></app-autocomplete>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button aria-label="Cancel Button" mat-dialog-close tabindex="0">Cancel</button>
  <button
    mat-raised-button
    aria-label="Complete/Save"
    color="primary"
    [disabled]="formControl?.invalid"
    [mat-dialog-close]="formControl?.value"
    cdkFocusInitial
    tabindex="0"
  >
    Complete/Save
  </button>
</mat-dialog-actions>
