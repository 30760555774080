import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-newly-added-stat',
  templateUrl: './newly-added-stat.component.html',
  styleUrls: ['./newly-added-stat.component.scss'],
})
export class NewlyAddedStatComponent implements OnInit {
  @Input()
  icon: string;

  @Input()
  title: string;

  @Input()
  amount: string | number;

  constructor() {}

  ngOnInit(): void {}
}
