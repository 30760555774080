import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { AppMatModule } from '../shared/app-mat.module';
import { SharedModule } from '../shared/shared.module';
import { SurveyListComponent } from './components/survey-list/survey-list.component';
import { ReportExportComponent } from './components/survey-reporting-dashboard/report-export/report-export.component';
import { SurveyReportingChartComponent } from './components/survey-reporting-dashboard/survey-reporting-chart/survey-reporting-chart.component';
import { SurveyReportingDashboardComponent } from './components/survey-reporting-dashboard/survey-reporting-dashboard.component';
import { SurveyReportingTableComponent } from './components/survey-reporting-dashboard/survey-reporting-table/survey-reporting-table.component';
import { SurveyRoutingModule } from './survey-routing.module';
import { SurveyAdminComponent } from './components/suvey-admin/survey-admin.component';

@NgModule({
  imports: [SurveyRoutingModule, AppMatModule, ReactiveFormsModule, FormsModule, CommonModule, ChartsModule, SharedModule],
  declarations: [
    SurveyListComponent,
    SurveyReportingDashboardComponent,
    SurveyReportingChartComponent,
    ReportExportComponent,
    SurveyReportingTableComponent,
    SurveyAdminComponent,
  ],
  exports: [],
  providers: [],
})
export class SurveyModule {}
