import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SignatoryType, SignedFormSignatory } from '@idoe/electronic-signature';

export enum SingleSignatorySelection {
  Existing,
  New,
}

@Component({
  selector: 'app-select-signatories',
  templateUrl: './select-signatories.component.html',
  styleUrls: ['./select-signatories.component.scss'],
})
export class SelectSignatoriesComponent {
  @Input() caseId: string;
  @Input() isHomeschoolConsent: boolean;

  signedFormType: string;
  learnerId: string;
  parentId: string;

  signatories: SignedFormSignatory[] = new Array<SignedFormSignatory>();
  users: SignedFormSignatory[];
  userLabel: string;
  @ViewChild('addSignatoriesForm') addSignatoriesForm: NgForm;
  isSingleSignatory = false;
  SingleSignatorySelection = SingleSignatorySelection;
  signatory: SignedFormSignatory;
  singleSelectionMode: SingleSignatorySelection;
  dialogTitle: string;

  isPublicAgencyRep: boolean;

  @ViewChild('signatoriesDialog') signatoriesDialog: TemplateRef<any>;

  @Output() signatoriesAdded = new EventEmitter<SignedFormSignatory[]>();

  constructor(private dialog: MatDialog) {}

  buildView() {
    this.signatory = new SignedFormSignatory();
    this.signatories = [];
    this.isSingleSignatory = true;

    this.userLabel = 'Team Members';
    this.dialogTitle = 'Add Signatures';
  }

  onSingleSignatorySelectionChanged() {
    this.signatory = new SignedFormSignatory();
  }

  save() {
    if (this.isSingleSignatory) {
      if (this.addSignatoriesForm.form.invalid) {
        this.addSignatoriesForm.form.markAllAsTouched();

        return;
      }
      this.signatory.signatoryType = this.isPublicAgencyRep ? SignatoryType.PublicAgencyRep : SignatoryType.Family;
      this.signatories = [this.signatory];
    }

    this.signatoriesAdded.emit(this.signatories);
    this.isPublicAgencyRep = false;
  }

  // parentId is your form's model's ID, rest self-explanatory
  open(learnerId: string, parentId: string, signedFormType: string) {
    this.learnerId = learnerId;
    this.parentId = parentId;
    this.signedFormType = signedFormType;

    this.buildView();

    this.dialog
      .open(this.signatoriesDialog, {
        width: '100%',
        maxWidth: '700px',
      })
      .afterClosed()
      .subscribe(() => {});
  }
}
