<div class="display-flex align-items-baseline">
  <label class="text-md horizontal-label"> View By </label>
  <mat-radio-group aria-labelledby="View By" [(ngModel)]="viewById" class="ml-5">
    <ng-container *ngFor="let option of viewByOptions">
      <mat-radio-button [value]="option.key" class="w-100 mb-4" attr.aria-label="{{ option.value }}">
        {{ option.value }}
      </mat-radio-button>
    </ng-container>
  </mat-radio-group>
</div>

<div class="divider mt-3"></div>

<mat-accordion class="accordion accordion--close accordion--short" multi *ngFor="let survey of getSurveys()">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex align-items-center justify-content-between flex-1">
          <h3 class="my-0 text-normal">{{ survey.userFullName }}</h3>
          <span *ngIf="viewById === 'teamMember'">Submitted on {{ survey.generalSurveyDto.completedDateTime | dateFormat }}</span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <!-- BODY 2 -->

    <div class="table-overflow app-table">
      <table
        mat-table
        [dataSource]="
          getOrCreateSurveyDataSource(survey.generalSurveyDto.id, survey.generalSurveyDto.generalSurveyGeneralSurveyQuestionDtos)
        "
        class="w-100"
        matSort
        #surveyTable="matTable"
      >
        <ng-container matColumnDef="question">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Question</th>
          <td mat-cell *matCellDef="let element">
            {{ element.generalSurveyQuestionDto.question }}
          </td>
        </ng-container>

        <ng-container matColumnDef="response">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Response</th>
          <td mat-cell *matCellDef="let element">
            {{ element.generalSurveyAnswerDto.answer }}
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No results...</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          [hidden]="dataSource?.data?.length > 0"
          mat-footer-row
          *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
          class="example-second-footer-row"
        ></tr>
      </table>
      <mat-paginator [dataSource]="surveyTable.dataSource"></mat-paginator>
    </div>
  </mat-expansion-panel>
</mat-accordion>
