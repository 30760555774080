<div
  *ngIf="isPortalUser && (goal.primaryMeasurement.targetValue || goal.primaryMeasurement.baseline)"
  class="display-flex align-items-baseline"
>
  <p class="my-0 mr-2">Target Value: {{ goal.primaryMeasurement.targetValue }}</p>
  <p class="my-0 mr-2">Baseline: {{ goal.primaryMeasurement.baseline }}</p>
</div>

<table mat-table class="w-100" matSort [dataSource]="goal.data">
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more">Actions</th>
    <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
      <ng-container *ngIf="!isEditing && enableForms">
        <button aria-label="Edit Icon" mat-icon-button color="primary" (click)="onEdit(element)" tabindex="0">
          <mat-icon>edit</mat-icon>
        </button>
        <button aria-label="Delete Icon" mat-icon-button color="warn" (click)="onDelete(element, goal)" tabindex="0">
          <mat-icon>close</mat-icon>
        </button>
        <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewMore(element)" tabindex="0">
          <mat-icon>remove_red_eye</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="element.isEditing">
        <button aria-label="Save Icon" color="primary" mat-icon-button (click)="onUpdate(element, goal)" title="Save" tabindex="0">
          <mat-icon>save</mat-icon>
        </button>
        <button aria-label="Clear Icon" color="warn" mat-icon-button (click)="onCancelEdit(element)" title="Cancel" tabindex="0">
          <mat-icon>clear</mat-icon>
        </button>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
    <td mat-cell *matCellDef="let element">
      <app-inline-edit [editing]="element.isEditing">
        <ng-container viewMode>
          {{ element.date | dateFormat }}
        </ng-container>
        <ng-container editMode [formGroup]="formGroup">
          <app-date-picker label="Date" controlName="date" [min]="minDate" [max]="today"></app-date-picker>
        </ng-container>
      </app-inline-edit>
    </td>
  </ng-container>

  <ng-container matColumnDef="primaryValue">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Value 1</th>
    <td mat-cell *matCellDef="let element">
      <app-inline-edit [editing]="element.isEditing">
        <ng-container viewMode>
          {{ element.primaryValue }}
        </ng-container>
        <ng-container editMode [formGroup]="formGroup">
          <app-number-field label="Value" formControlName="primaryValue"></app-number-field>
        </ng-container>
      </app-inline-edit>
    </td>
  </ng-container>

  <ng-container matColumnDef="primaryNote">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Monitoring Notes 1</th>
    <td mat-cell *matCellDef="let element">
      <app-inline-edit [editing]="element.isEditing">
        <ng-container viewMode>
          {{ element.primaryNote }}
        </ng-container>
        <ng-container editMode [formGroup]="formGroup">
          <app-textarea label="Monitoring Notes" formControlName="primaryNote"></app-textarea>
        </ng-container>
      </app-inline-edit>
    </td>
  </ng-container>

  <ng-container matColumnDef="secondaryValue">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Value 2</th>
    <td mat-cell *matCellDef="let element">
      <app-inline-edit [editing]="element.isEditing">
        <ng-container viewMode>
          {{ element.secondaryValue }}
        </ng-container>
        <ng-container editMode [formGroup]="formGroup">
          <app-number-field label="Value" formControlName="secondaryValue"></app-number-field>
        </ng-container>
      </app-inline-edit>
    </td>
  </ng-container>

  <ng-container matColumnDef="secondaryNote">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Monitoring Notes 2</th>
    <td mat-cell *matCellDef="let element">
      <app-inline-edit [editing]="element.isEditing">
        <ng-container viewMode>
          {{ element.secondaryNote }}
        </ng-container>
        <ng-container editMode [formGroup]="formGroup">
          <app-textarea label="Monitoring Notes" formControlName="secondaryNote"></app-textarea>
        </ng-container>
      </app-inline-edit>
    </td>
  </ng-container>

  <ng-container matColumnDef="noResults">
    <td mat-footer-cell *matFooterCellDef colspan="7">
      <i>No results...</i>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr [hidden]="goal.data?.data?.length > 0" mat-footer-row *matFooterRowDef="goal.data?.data?.length === 0 ? ['noResults'] : []"></tr>
</table>
<mat-paginator [dataSource]="goal.data"></mat-paginator>
