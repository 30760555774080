import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { IntakeType } from '../../../shared/models/case';
import { EvaluationDomain } from '../../../shared/models/evaluation-domain';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { MemoryStorageService } from '../../../shared/services/memory-storage/memory-storage.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { Evaluation } from '../../models/evaluation';
import { EvaluationAssignment } from '../../models/evaluation-assignment';
import { EvaluationUser } from '../../models/evaluation-user';
import { EvaluationProxyService } from '../../services/evaluation-proxy.service';
import { EvaluationUserService } from '../../services/evaluation-user.service';

interface DomainAreaAssignmentUser {
  id: string;
  name: string;
  domainAreas: DomainAreaAssignment[];
}

interface DomainAreaAssignment {
  domainAreaId: string;
  assignmentId: string;
  userId: string;
  name: string;
  isAssigned: boolean;
  canBeToggled: boolean;
  gotUnassigned: boolean;
}

@Component({
  selector: 'app-domain-assign-modal-partb',
  templateUrl: './domain-assign-modal-partb.component.html',
  styleUrls: ['./domain-assign-modal-partb.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DomainAssignModalComponentPartB implements OnInit {
  domains: EvaluationDomain[] = [];
  assignments: EvaluationAssignment[] = [];
  evaluation: Evaluation;
  domainAssignmentUsers: DomainAreaAssignmentUser[] = [];
  private completeStatus: KeyValuePair;
  private pendingStatus: KeyValuePair;

  constructor(
    public dialogRef: MatDialogRef<DomainAssignModalComponentPartB>,
    private dialog: MatDialog,
    private readonly evaluationUserService: EvaluationUserService,
    private readonly storage: MemoryStorageService,
    private readonly notificationService: NotificationService,
    private readonly evaluationProxyService: EvaluationProxyService,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: DomainAssignModalData
  ) {}

  ngOnInit(): void {
    this.evaluation = this.storage.getKey('currentEvaluation', true);
    this.completeStatus = this.data.statuses.find((x) => x.value === 'Complete');
    this.pendingStatus = this.data.statuses.find((x) => x.value === 'Pending');
    const assignedDomainIds = this.data.assignments.map((x) => x.domainArea.id);

    if (this.evaluation.intakeType === IntakeType.PartB) {
      this.domains = this.data.domains.filter(
        (domain) => this.evaluation.consentDomains.includes(domain.id) || assignedDomainIds.includes(domain.id)
      );
    } else {
      this.domains = this.data.domains;
    }

    this.assignments = this.data.assignments.sort((a, b) =>
      a.domainArea.evaluationDomain.label > b.domainArea.evaluationDomain.label
        ? 1
        : a.domainArea.evaluationDomain.label < b.domainArea.evaluationDomain.label
        ? -1
        : 0
    );
    this.setupData();
    this.evaluationProxyService.teamChange.subscribe((x) => {
      this.data.teamMembers = x;
      this.setupData();
    });
  }

  setupData() {
    this.domainAssignmentUsers = [];
    const availableDomainAreas = [];
    this.assignments
      .map((x) => x.domainArea)
      .forEach((domainArea) => {
        const domainAreaExists = availableDomainAreas.find((x) => x.id === domainArea.id);
        if (!domainAreaExists) {
          availableDomainAreas.push(domainArea);
        }
      });

    this.data.teamMembers.forEach((member) => {
      const domainAreaAssignmentUser = {
        id: member.userId,
        name: member.name,
        domainAreas: [],
      } as DomainAreaAssignmentUser;

      availableDomainAreas.forEach((domainArea) => {
        domainAreaAssignmentUser.domainAreas.push({
          domainAreaId: domainArea.id,
          assignmentId: null,
          userId: null,
          name: domainArea.label,
          canBeToggled: false,
          isAssigned: false,
          gotUnassigned: false,
        });
      });

      domainAreaAssignmentUser.domainAreas.forEach((domainArea) => {
        const myAssignment = this.assignments.find((x) => x.domainAreaId === domainArea.domainAreaId && x.userId === member.userId);
        if (myAssignment) {
          domainArea.assignmentId = myAssignment.id;
          domainArea.userId = myAssignment.userId;
          domainArea.isAssigned = true;
          domainArea.canBeToggled = myAssignment.status.id === this.pendingStatus.key && !myAssignment.date && !myAssignment.startTime;
        } else {
          domainArea.canBeToggled = true;
        }
      });

      this.domainAssignmentUsers.push(domainAreaAssignmentUser);
    });
  }

  onToggleDomain(id: string, domain: DomainAreaAssignment): void {
    if (domain.isAssigned) {
      domain.gotUnassigned = true;
    } else {
      domain.gotUnassigned = false;
    }

    const isAssigned = !domain.isAssigned;

    if (isAssigned) {
      const domainAreaId = domain.domainAreaId;
      const alreadyAssigned = this.domainAssignmentUsers.find(
        (user) => user.id !== id && user.domainAreas.some((y) => y.isAssigned && y.domainAreaId === domainAreaId)
      );

      if (alreadyAssigned) {
        const question =
          this.evaluation.intakeType === IntakeType.PartC
            ? `${alreadyAssigned.name} has already been assigned the ${domain.name} developmental area.`
            : `${alreadyAssigned.name} has already been assigned the ${domain.name} domain.`;
        const dialogRef = this.dialog.open(AreYouSureComponent, {
          data: { subQuestion: question },
        });

        dialogRef.afterClosed().subscribe((value) => {
          if (value) {
            const availableAssignment = this.assignments.find((x) => x.domainAreaId === domain.domainAreaId && !x.userId);

            if (availableAssignment) {
              domain.userId = availableAssignment.userId;
            } else {
              domain.userId = undefined;
            }
            domain.isAssigned = isAssigned;
          }
        });
      } else {
        domain.isAssigned = isAssigned;
        domain.userId = this.assignments.find((x) => x.domainAreaId === domain.domainAreaId).userId;
      }
    } else {
      domain.isAssigned = isAssigned;
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave() {
    const prepForSave = () => {
      const assignments: EvaluationAssignment[] = [];
      this.domainAssignmentUsers.forEach((user) => {
        user.domainAreas
          .filter((assignment) => assignment.isAssigned || assignment.gotUnassigned)
          .forEach((assignment) => {
            let statusId = this.pendingStatus.key;
            const existingAssignment = this.assignments.find((a) => a.userId === assignment.userId);
            if (existingAssignment) {
              statusId = existingAssignment.status.id;
            }
            assignments.push({
              id: assignment.assignmentId,
              domainAreaId: assignment.domainAreaId,
              userId: !assignment.gotUnassigned ? user.id : null,
              evaluationId: this.evaluation.id,
              statusId,
            } as EvaluationAssignment);
          });
      });
      return assignments;
    };
    const userUnassigned = this.domainAssignmentUsers.flatMap((x) => x.domainAreas).some((x) => x.gotUnassigned);

    if (userUnassigned) {
      this.notificationService.confirmation(
        this.evaluation.intakeType === IntakeType.PartC
          ? 'You have unassigned a user from a developmental area, continue?'
          : 'You have unassigned a user from a domain, continue?',
        () => {
          this.dialogRef.close(prepForSave());
        }
      );
    } else {
      this.dialogRef.close(prepForSave());
    }
  }

  onOpenTeamSearch() {
    this.evaluationProxyService.openTeamSearch.next(undefined);
  }
}

export interface DomainAssignmentUser {
  id: string;
  name: string;
  domains: DomainAssignment[];
}

export interface DomainAssignment {
  id: string;
  assignmentId: string;
  name: string;
  isAssigned: boolean;
  canBeToggled: boolean;
  gotUnassigned: boolean;
  area?: string;
}

export interface DomainAssignModalData {
  teamMembers: EvaluationUser[];
  statuses: KeyValuePair[];
  domains: EvaluationDomain[];
  assignments: EvaluationAssignment[];
}
