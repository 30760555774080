import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IepService } from '../services/iep.service';

@Injectable({
  providedIn: 'root',
})
export class IepFinalizeGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private iepService: IepService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const iepId = route.params.iepId;
    const caseId = route.params.caseId;

    return this.redirectIfFinalized(iepId, caseId);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const iepId = childRoute.parent.params.iepId;
    const caseId = childRoute.parent.params.caseId;

    return this.redirectIfFinalized(iepId, caseId);
  }

  redirectIfFinalized(iepId: string, caseId: string) {
    return this.iepService.getFinalized(iepId).pipe(
      map((res) => {
        if (res) {
          this.router.navigate(['cases', caseId, 'iep', iepId, 'details']);
          return false;
        }
        return true;
      }),
      catchError((error) => of(false))
    );
  }
}
