<div
  *ngIf="isPortalUser && (goal.primaryMeasurement.targetValue || goal.primaryMeasurement.baseline)"
  class="display-flex align-items-baseline"
>
  <p class="my-0 mr-2">Target Value: {{ goal.primaryMeasurement.targetValue }}</p>
  <p class="my-0 mr-2">Baseline: {{ goal.primaryMeasurement.baseline }}</p>
</div>

<div class="table-overflow">
  <table mat-table class="w-100" matSort [dataSource]="goal.data">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more"></th>
      <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
        <ng-container *ngIf="!isEditing && enableForms">
          <button
            mat-icon-button
            aria-label="Edit Icon"
            color="primary"
            (click)="onEdit(element)"
            *ngIf="!hideEdit || !element.value"
            tabindex="0"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button aria-label="Delete Icon" color="warn" (click)="onDelete(element, goal)" *ngIf="!hideDelete" tabindex="0">
            <mat-icon>close</mat-icon>
          </button>
          <button mat-icon-button aria-label="View Icon" color="primary" (click)="onViewMore(element)" tabindex="0">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="element.isEditing">
          <button color="primary" mat-icon-button aria-label="Save Icon" (click)="onUpdate(element, goal)" title="Save" tabindex="0">
            <mat-icon>save</mat-icon>
          </button>
          <button color="warn" mat-icon-button aria-label="Clear Icon" (click)="onCancelEdit(element)" title="Cancel" tabindex="0">
            <mat-icon>clear</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let element">
        <app-inline-edit [editing]="element.isEditing">
          <ng-container viewMode>
            <!-- Do not use dateFormat pipe here as it produces a result a day earlier than expected -->
            {{ element.date | date : shortDateFormat : 'utc' }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-date-picker label="Date" controlName="date" [min]="minDate" [max]="today"></app-date-picker>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>
      <td mat-cell *matCellDef="let element">
        <app-inline-edit [editing]="element.isEditing">
          <ng-container viewMode>
            {{ element.value | number }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-number-field label="Value" formControlName="value"></app-number-field>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Monitoring Notes</th>
      <td mat-cell *matCellDef="let element">
        <app-inline-edit [editing]="element.isEditing">
          <ng-container viewMode>
            {{ element.note }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-textarea label="Monitoring Notes" formControlName="note"></app-textarea>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr [hidden]="goal.data?.data?.length > 0" mat-footer-row *matFooterRowDef="goal.data?.data?.length === 0 ? ['noResults'] : []"></tr>
  </table>
  <mat-paginator [dataSource]="goal.data"></mat-paginator>
</div>
