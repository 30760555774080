import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TodoCreate, TodoRead, TodoUpdate } from './todo-models';

@Injectable({
  providedIn: 'root',
})
export class TodoService {
  private baseUrl = 'api/todos';
  private todosUpdated = new Subject<void>();

  todosUpdated$ = this.todosUpdated.asObservable();

  constructor(private http: HttpClient) {}

  get() {
    return this.http.get<TodoRead[]>(this.baseUrl);
  }

  create(todo: TodoCreate) {
    return this.http.post<TodoRead>(this.baseUrl, todo).pipe(tap(() => this.todosUpdated.next()));
  }

  update(todo: TodoUpdate) {
    return this.http.put<TodoRead>(`${this.baseUrl}/${todo.id}`, todo).pipe(tap(() => this.todosUpdated.next()));
  }

  delete(todoId: string) {
    return this.http.put(`${this.baseUrl}/delete/${todoId}`, null).pipe(tap(() => this.todosUpdated.next()));
  }

  markComplete(todo: TodoRead) {
    return this.http
      .put<TodoRead>(`${this.baseUrl}/${todo.id}`, {
        id: todo.id,
        isCompleted: true,
      } as TodoUpdate)
      .pipe(tap(() => this.todosUpdated.next()));
  }
}
