import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { openPdfWindow } from 'src/app/shared/windowHelpers';

@Component({
  selector: 'app-evaluation-assessment-report-modal',
  templateUrl: './evaluation-assessment-report-modal.component.html',
  styleUrls: ['./evaluation-assessment-report-modal.component.scss'],
})
export class EvaluationAssessmentReportModalComponent implements OnInit {
  caseId: string;
  evaluationId: string;
  learnerId: string;
  eligibilityDates: Array<KeyValuePair> = [];
  formGroup = this.fb.group({
    startDate: ['', Validators.required],
    endDate: [new Date(), Validators.required],
  });
  isPrinting = false;

  get startDate() {
    return this.formGroup.get('startDate').value;
  }

  get endDate() {
    return this.formGroup.get('endDate').value;
  }

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { caseId: string; evaluationId: string; learnerId: string },
    private readonly caseService: CaseService,
    private readonly fb: FormBuilder,
    private readonly reportingService: ReportingService
  ) {
    console.log(data);
    this.caseId = data?.caseId;
    this.evaluationId = data?.evaluationId;
    this.learnerId = data?.learnerId;
  }

  ngOnInit(): void {
    this.load();
  }

  onCancel() {
    this.dialogRef.close();
  }

  onGenerateReport() {
    this.isPrinting = true;
    this.reportingService
      .createEvaluationAssessmentReport(this.evaluationId, this.startDate, this.endDate)
      .pipe(finalize(() => (this.isPrinting = false)))
      .subscribe({
        next: (documentId: string) => this.handleOutputCreation(documentId),
        error: (err) => this.reportingService.handleOutputError(err),
      });
  }

  private handleOutputCreation(documentId: string) {
    openPdfWindow(this.learnerId, documentId);
    this.dialogRef.close();
  }

  private load() {
    this.caseService.getEligibilityDecisionDates(this.caseId).subscribe((res) => {
      this.eligibilityDates = res;
    });
  }
}
