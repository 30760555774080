import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Intake } from 'src/app/evaluation/models/intake';
import { IntakeService } from 'src/app/evaluation/services/intake.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { Aea } from 'src/app/shared/models/aea';
import { CaseOwner } from 'src/app/shared/models/case';
import { AeaService } from 'src/app/shared/services/aea/aea.service';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { ProviderInfoFormService } from '../provider-info-form.service';

export enum ProviderSections {
  Provider = 'provider',
  Agency = 'agency',
  MedicalSpecialist = 'medical-specialist',
}

@Component({
  selector: 'app-consent-release-exchange-document',
  templateUrl: './consent-release-exchange-document.component.html',
  styleUrls: ['./consent-release-exchange-document.component.scss'],
})
export class ConsentReleaseExchangeDocumentComponent implements OnInit {
  intake: Intake;
  learnersName: string;
  today: Date;
  caseOwner: CaseOwner;
  agency: any;
  aea: Aea;
  shortDateFormat = shortDateFormat;

  constructor(
    private route: ActivatedRoute,
    private intakeService: IntakeService,
    private caseService: CaseService,
    private providerService: ProviderInfoFormService,
    private readonly aeaService: AeaService
  ) {}

  ngOnInit(): void {
    this.today = new Date();
    const caseId = this.route.snapshot.paramMap.get('caseId');

    this.intakeService.getIntake(caseId).subscribe((intake) => {
      this.intake = intake;
      this.prePopulateForms(caseId);
      this.learnersName = `${intake.childInfo.lastName}, ${intake.childInfo.firstName} ${
        intake.childInfo.middleName ? intake.childInfo.middleName[0] : ''
      }`;
      this.caseService.getCaseSummary(intake.caseId).subscribe((caseRes) => {
        this.caseOwner = caseRes.caseOwner;
        this.aeaService.getAllAeas().subscribe((aeas) => {
          this.aea = aeas.find((aea) => aea.id === this.caseOwner.aeaId);
        });
      });
    });
  }

  prePopulateForms(caseId: string) {
    const routeParameter = this.route.snapshot.firstChild?.params;

    if (routeParameter) {
      switch (routeParameter.section) {
        case ProviderSections.Provider: {
          const phcpInfo = this.intake.healthInfo.providerInformation;
          this.agency = {
            title: phcpInfo.phcpTitle,
            name: phcpInfo.phcpClinicAgencyName,
            contact: phcpInfo.phcpName,
            address: phcpInfo.phcpStreetAddress,
            city: phcpInfo.phcpCity,
            state: phcpInfo.phcpState,
            zipCode: phcpInfo.phcpZipCode,
            faxNumber: phcpInfo.phcpFax,
            phoneNumber: phcpInfo.phcpPhone,
          };
          break;
        }
        case ProviderSections.Agency: {
          this.providerService.getIntakeAgencyOrOtherPrograms(this.intake.learner.id).subscribe((agencies) => {
            this.agency = agencies.find((agency) => agency.id === routeParameter.id);
          });
          break;
        }
        case ProviderSections.MedicalSpecialist: {
          this.providerService.getIntakeMedicalSpecialist(this.intake.learner.id).subscribe((specialists) => {
            this.agency = specialists.find((specialist) => specialist.id === routeParameter.id);
          });
          break;
        }
        default: {
          break;
        }
      }
    }
  }
}
