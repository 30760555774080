<div class="backdrop">
  <div class="pic"></div>
  <div class="form">
    <form class="reset-password-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
      <h1 class="mat-display-1">IVRS Reset Password</h1>

      <app-text-field type="password" label="Password" formControlName="password"></app-text-field>

      <app-text-field type="password" label="Confirmed Password" formControlName="confirmedPassword"></app-text-field>

      <div *ngIf="formGroup.touched && (formGroup.hasError('passwordFormat') || formGroup.hasError('passwordMismatch'))">
        <mat-error *ngIf="formGroup.hasError('passwordFormat')">Password doesn't follow the requirements.</mat-error>
        <mat-error *ngIf="formGroup.hasError('passwordMismatch')">Password and Confirm Password do not match.</mat-error>
      </div>
      <mat-error *ngFor="let err of serverErrors"> {{ err }}</mat-error>

      <button
        mat-flat-button
        aria-label="Reset Password"
        class="buttons"
        type="submit"
        color="primary"
        [isBusy]="isBusy"
        tabindex="0"
        [disabled]="!formGroup.valid"
      >
        Submit
      </button>
      <a class="back-to-login" routerLink="/auth/ivrs/login">Back to login</a>

      <p class="mt-4">
        <strong>Password requirements</strong><br />
        <small>
          Must be between 8 and 32 characters<br />
          Must have at least one upper-case letter<br />
          Must have at least one lower-case letter<br />
          Must have at least one number or special character (#?!@$%^&*-)
        </small>
      </p>
    </form>
  </div>
</div>
