import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseSummary } from '../../../../shared/models/case';
import { KeyValuePair } from '../../../../shared/models/key-value-pair';
import { CaseService } from '../../../../shared/services/case/case.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { UserService } from '../../../../shared/services/user/user.service';
import { conditionalValidator } from '../../../../shared/validators';
import { IfspServicesService } from '../../../services/ifsp-service.service';

@Component({
  selector: 'app-ifsp-change-provider',
  templateUrl: './ifsp-change-provider.component.html',
  styleUrls: ['./ifsp-change-provider.component.scss'],
})
export class IfspChangeProviderComponent implements OnInit {
  providerOptions: KeyValuePair[] = [];
  formGroup = new FormGroup({
    provider: new FormControl(
      '',
      conditionalValidator(() => !this.isOtherProvider, Validators.required)
    ),
    otherProvider: new FormControl(null),
    otherProviderName: new FormControl(
      null,
      conditionalValidator(() => this.isOtherProvider, Validators.required)
    ),
    otherProviderAgency: new FormControl(
      null,
      conditionalValidator(() => this.isOtherProvider, Validators.required)
    ),
    otherProviderRole: new FormControl(
      null,
      conditionalValidator(() => this.isOtherProvider, Validators.required)
    ),
  });
  caseId: string;
  caseSummary: CaseSummary;

  get isOtherProvider() {
    return this.formGroup.get('otherProvider').value;
  }
  constructor(
    public dialogRef: MatDialogRef<IfspChangeProviderComponent>,
    private notificationService: NotificationService,
    private userService: UserService,
    private ifspService: IfspServicesService,
    private caseService: CaseService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.caseService.getCaseSummary(this.data.caseId).subscribe((caseSummary) => {
      this.caseSummary = caseSummary;
      this.userService
        .searchUsers({
          aeaId: this.caseSummary?.learner?.attendingAeaId,
          buildingId: this.caseSummary?.learner?.building?.id,
          schoolDistrictId: this.caseSummary?.learner?.attendingDistrictId,
        })
        .subscribe((users) => {
          this.providerOptions = users.map((u) => new KeyValuePair(u.id, u.fullName));
          this.formGroup.patchValue({
            provider: this.data.dataElement.provider,
            otherProvider: this.data.dataElement.otherProvider,
            otherProviderName: this.data.dataElement.otherProviderName,
            otherProviderAgency: this.data.dataElement.otherProviderAgency,
            otherProviderRole: this.data.dataElement.otherProviderRole,
          });
          this.cd.detectChanges();
        });
    });

    this.formGroup.get('otherProvider').valueChanges.subscribe((isOther) => {
      if (isOther) {
        this.formGroup.get('provider').setValue(null);
      } else {
        this.formGroup.get('otherProviderName').setValue(null);
        this.formGroup.get('otherProviderAgency').setValue(null);
        this.formGroup.get('otherProviderRole').setValue(null);
      }
    });
  }

  onSubmit() {
    const model = {
      ...this.formGroup.value,
    };
    if (!this.formGroup.get('otherProvider').value) {
      model.otherProvider = false;
    }
    this.ifspService.updateProviders(this.data.ifspId, this.data.id, model).subscribe(() => {
      this.notificationService.success('Successfully changed providers');
      this.dialogRef.close();
    });
  }
}
