import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OperationResult, OperationResultWithValue } from 'src/app/shared/models/operation-result';
import { PostSecondaryCommunityContact, PostSecondarySummary, ResourceOption } from './post-secondary-summary';

@Injectable({
  providedIn: 'root',
})
export class PostSecondarySummaryService {
  private baseUrl = (caseId: string) => `api/case/${caseId}/postSecondarySummary/`;

  constructor(private http: HttpClient) {}

  getPostSecondarySummary(caseId: string, postSecondarySummaryId: string): Observable<OperationResultWithValue<PostSecondarySummary>> {
    return this.http.get<OperationResultWithValue<PostSecondarySummary>>(`${this.baseUrl(caseId)}${postSecondarySummaryId}`);
  }

  addPostSecondarySummary(postSecondarySummary: PostSecondarySummary): Observable<OperationResultWithValue<PostSecondarySummary>> {
    return this.http.post<OperationResultWithValue<PostSecondarySummary>>(
      `${this.baseUrl(postSecondarySummary.caseId)}null`,
      postSecondarySummary
    );
  }

  updatePostSecondarySummary(postSecondarySummary: PostSecondarySummary): Observable<OperationResultWithValue<PostSecondarySummary>> {
    return this.http.put<OperationResultWithValue<PostSecondarySummary>>(
      `${this.baseUrl(postSecondarySummary.caseId)}${postSecondarySummary.id}`,
      postSecondarySummary
    );
  }

  deletePostSecondarySummary(postSecondarySummary: PostSecondarySummary): Observable<OperationResult> {
    return this.http.put<OperationResult>(`${this.baseUrl(postSecondarySummary.caseId)}${postSecondarySummary.id}/delete`, null);
  }

  getResourceOptions(): Observable<OperationResultWithValue<ResourceOption[]>> {
    return this.http.get<OperationResultWithValue<ResourceOption[]>>('api/resourceOptions');
  }

  getPostSecondaryCommunityContacts(
    caseId: string,
    postSecondarySummaryId: string,
    communityContactId: string
  ): Observable<OperationResultWithValue<PostSecondaryCommunityContact>> {
    return this.http.get<OperationResultWithValue<PostSecondaryCommunityContact>>(
      `${this.baseUrl(caseId)}${postSecondarySummaryId}/communityContacts/${communityContactId}`
    );
  }

  addPostSecondaryCommunityContact(
    caseId: string,
    communityContact: PostSecondaryCommunityContact
  ): Observable<OperationResultWithValue<PostSecondaryCommunityContact[]>> {
    return this.http.post<OperationResultWithValue<PostSecondaryCommunityContact[]>>(
      `${this.baseUrl(caseId)}${communityContact.postSecondarySummaryId}/communityContacts/${communityContact.id}`,
      communityContact
    );
  }

  updatePostCommunityContact(
    caseId: string,
    communityContact: PostSecondaryCommunityContact
  ): Observable<OperationResultWithValue<PostSecondaryCommunityContact[]>> {
    return this.http.put<OperationResultWithValue<PostSecondaryCommunityContact[]>>(
      `${this.baseUrl(caseId)}${communityContact.postSecondarySummaryId}/communityContacts/${communityContact.id}`,
      communityContact
    );
  }

  deletePostCommunityContact(caseId: string, communityContact: PostSecondaryCommunityContact): Observable<OperationResult> {
    return this.http.put<OperationResult>(
      `${this.baseUrl(caseId)}${communityContact.postSecondarySummaryId}/communityContacts/${communityContact.id}/delete`,
      null
    );
  }
}
