<h2 *ngIf="!hasOpenModification && !isModification" class="my-0 text-primary">Present Levels of Development (PLOD)</h2>
<div class="display-flex justify-content-between align-items-center mb-2">
  <button
    mat-raised-button
    attr.aria-label="{{ modification?.modifyingPlod ? 'In Modification' : 'Modify' }}"
    color="primary"
    *ngIf="hasOpenModification"
    (click)="$event.stopPropagation(); onModify()"
    tabindex="0"
  >
    <ng-container *ngIf="modification?.modifyingPlod">In Modification</ng-container>
    <ng-container *ngIf="!modification?.modifyingPlod">Modify</ng-container>
  </button>

  <h3 class="my-0">Early Childhood Outcomes (ECO) Areas</h3>
  <button
    [isBusy]="activeCall"
    mat-raised-button
    aria-label="Autofill with ECO Summaries"
    color="primary"
    *ngIf="!isModification"
    (click)="autoFillEcoMatrixSummaries()"
    tabindex="0"
  >
    Autofill with ECO Summaries
  </button>
</div>
<app-help-directional-text [outsideCard]="true">
  <p class="mb-2">
    Complete the information required for each area or click the Autofill with ECO Summaries button to pull statements from previous
    decision.
  </p>
</app-help-directional-text>
<form [formGroup]="formGroup">
  <mat-accordion displayMode="flat" multi class="accordion accordion--short accordion--has-form" *ngIf="plodInitialized">
    <mat-expansion-panel
      *ngFor="let ecoAreaFormGroup of ecoAreaFormGroups; let i = index"
      [formGroup]="ecoAreaFormGroup"
      class="mat-elevation-z0 expanded--close"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="my-0 text-normal">{{ ecoAreaFormGroup.get('label').value }}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="display-flex justify-content-between align-items-start">
        <div class="flex-grow-1">
          <ng-container *ngIf="!isModification || (hasOpenModification && modification.modifyingPlod)">
            <app-textarea
              formControlName="description"
              [showAsterisk]="showAsterisk(ecoAreaFormGroup.get('type').value)"
              label="Identify the learner's skills and abilities during routines and activities"
              [rows]="4"
            ></app-textarea>
            <span *ngIf="hasOpenModification">
              <app-compare-amendment-output
                [amendmentId]="modification?.id"
                [amendmentFinalized]="modificationIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [priorVersionId]="plodModel?.priorVersionId"
                [changedVersionText]="ecoAreaFormGroup.get('description').value"
                [currentVersionText]="findPriorEcoAreaDescription(ecoAreaFormGroup.get('type').value)"
                [currentPrefixText]="findPriorEcoAreaDescription(ecoAreaFormGroup.get('type').value) ? 'Current: ' : 'Proposed: '"
                changedPrefixText="Proposed: "
              ></app-compare-amendment-output>
            </span>
          </ng-container>
          <ng-container *ngIf="isModification && (!hasOpenModification || !modification.modifyingPlod)">
            <h4 class="mt-0 mb-1">Description</h4>
            <div class="mt-1 mb-2">
              <app-compare-amendment-output
                [amendmentId]="plodModel?.modificationId"
                [amendmentFinalized]="modificationIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [priorVersionId]="plodModel?.priorVersionId"
                [changedVersionText]="ecoAreaFormGroup.get('description').value"
                [currentVersionText]="findPriorEcoAreaDescription(ecoAreaFormGroup.get('type').value)"
                [currentPrefixText]="findPriorEcoAreaDescription(ecoAreaFormGroup.get('type').value) ? 'Current: ' : 'Proposed: '"
                changedPrefixText="Proposed: "
              ></app-compare-amendment-output>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="row align-items-center flex-wrap">
        <div class="col-md-12 col-lg-7 col-xl-9">
          <ng-container>
            <h4 class="mt-0 mb-1">Comparison to Age Expectation</h4>
            <p class="my-0" *ngIf="ecoAreaFormGroup.get('scoreText').value || ecoAreaFormGroup.get('priorVersionScoreText').value">
              <span *ngIf="!isModification">
                {{ ecoAreaFormGroup.get('scoreText').value }}
              </span>
              <span *ngIf="isModification">
                <app-compare-amendment-output
                  [amendmentId]="plodModel?.modificationId"
                  [amendmentFinalized]="modificationIsFinalized"
                  [amendmentDate]="lastFinalizedDate"
                  [priorVersionId]="ecoAreaFormGroup.get('priorVersionId').value"
                  [changedVersionText]="ecoAreaFormGroup.get('scoreText').value"
                  [currentVersionText]="ecoAreaFormGroup.get('priorVersionScoreText').value"
                  [currentPrefixText]="ecoAreaFormGroup.get('priorVersionScoreText').value ? 'Current: ' : 'Proposed: '"
                  changedPrefixText="Proposed: "
                ></app-compare-amendment-output>
              </span>
            </p>
            <p *ngIf="!ecoAreaFormGroup.get('scoreText').value">
              <span> Not yet determined </span>
            </p>
            <mat-button-toggle
              *ngIf="!isModification || (hasOpenModification && modification.modifyingPlod)"
              [checked]="tagDomainToggled(ecoAreaFormGroup.get('type').value)"
              (change)="onTagEvaluationDomainToggle(ecoAreaFormGroup.get('type').value)"
              >Tag Developmental Areas</mat-button-toggle
            >

            <div class="display-block mt-2">
              <ng-container
                *ngIf="
                  tagDomainToggled(ecoAreaFormGroup.get('type').value) &&
                  (!isModification || (hasOpenModification && modification.modifyingPlod))
                "
              >
                <mat-button-toggle
                  *ngFor="let evalDomain of evaluationDomains"
                  [value]="evalDomain.key"
                  (change)="onEvaluationDomainSelect($event, ecoAreaFormGroup.get('type').value)"
                  [checked]="isEvaluationDomainChecked(ecoAreaFormGroup.get('type').value, evalDomain.key)"
                  class="mr-2 mb-2 button-toggle--development"
                  >{{ evalDomain.value }}</mat-button-toggle
                >
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="action__row action__row--between my-0">
        <app-help-directional-text>
          <p>Click the Launch ECO Decision Tree button to determine and document comparison to age expectation.</p>
        </app-help-directional-text>
        <div>
          <span class="text-danger mr-2" *ngIf="!ecoAreaFormGroup.get('scoreText').value">ECO Ratings are <strong>required</strong></span>
          <button
            mat-raised-button
            aria-label="Launch ECO Decision Tree"
            color="primary"
            *ngIf="
              !isModification ||
              (hasOpenModification &&
                modification.modifyingPlod &&
                (ecoAreaFormGroup.get('description').value !== ecoAreaFormGroup.get('savedDescription').value ||
                  ecoAreaFormGroup.get('description').value !== ecoAreaFormGroup.get('priorVersionDescription').value))
            "
            (click)="launchEcoDecisionTree(ecoAreaFormGroup.get('id').value)"
            tabindex="0"
          >
            Launch ECO Decision Tree
          </button>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="domainsNotTagged" [formGroup]="formGroup" class="mat-elevation-z0 expanded--close">
      <mat-expansion-panel-header>
        <mat-panel-title> <h3 class="my-0 text-normal">Present Levels of Development (PLOD)</h3> </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="display-flex justify-content-between align-items-start">
        <div class="flex-grow-1">
          <ng-container *ngIf="!isModification || (hasOpenModification && modification.modifyingPlod); else develDescription">
            <mat-label class="vertical-label text-md display-block position-fix-label" [ngClass]="{ asterisk_input: showAsterisk() }"
              >Identify the learner's skills and abilities in the following developmental areas: {{ domainsNotTagged }}</mat-label
            >
            <app-textarea formControlName="notTaggedAreasDescription" [rows]="4"></app-textarea>
          </ng-container>
          <ng-template #develDescription>
            {{ formGroup.value?.notTaggedAreasDescription }}
          </ng-template>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</form>

<div class="display-flex align-items-center justify-content-end mt-2" *ngIf="!!plodModel?.id">
  <app-create-output-button
    [apiId]="ifspId"
    [apiAdditionalId]="plodModel?.id"
    [learnerId]="learnerId"
    [documentId]="plodModel?.documentId"
    [output]="pdfOutputs.PlodOutput"
    [isDraft]="true"
    buttonColor="accent"
    buttonText="Present Levels Report"
  ></app-create-output-button>

  <!-- <button mat-raised-button color="accent" class="mr-2" [disabled]="formGroup.invalid" (click)="onPrint()" [isBusy]="isBusy" tabindex="0">
    Present Levels Report
  </button> -->
</div>
