import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaluationRoutine } from '../models/evaluation-routine';

@Injectable({
  providedIn: 'root',
})
export class EvaluationRoutineService {
  constructor(private readonly http: HttpClient) {}

  private baseUrl(evaluationId: string, id = '') {
    return `api/evaluations/${evaluationId}/routine/${id}`;
  }

  get(evaluationId: string): Observable<EvaluationRoutine[]> {
    return this.http.get<EvaluationRoutine[]>(this.baseUrl(evaluationId));
  }

  add(evaluationId: string, model: EvaluationRoutine) {
    return this.http.post<EvaluationRoutine>(this.baseUrl(evaluationId), model);
  }

  update(evaluationId: string, id: string, model: any) {
    return this.http.put<EvaluationRoutine>(this.baseUrl(evaluationId, id), model);
  }

  remove(evaluationId: string, id: string) {
    return this.http.put<EvaluationRoutine>(`${this.baseUrl(evaluationId, id)}/delete`, null);
  }
}
