import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { yesNoOptions } from 'src/app/shared/formHelpers';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Fba, FbaStatus } from '../../../behavior-discipline/fba/fba';
import { FbaService } from '../../../behavior-discipline/fba/services/fbaService.service';
import { DateFormatPipe } from '../../../shared/pipes/date-transform.pipe';
import { IepService } from '../../services/iep.service';

@Component({
  selector: 'app-iep-create-bip-modal',
  templateUrl: './iep-create-bip-modal.component.html',
  styleUrls: ['./iep-create-bip-modal.component.scss'],
  providers: [DateFormatPipe],
})
export class IepCreateBipModalComponent implements OnInit {
  iepId: string;
  bipId: string;
  fbaId: string;
  caseId: string;
  yesNoOptions = yesNoOptions;
  fba: Fba;
  validateFba: boolean;

  createBipFormGroup = new FormGroup({
    importFromFba: new FormControl(null, [Validators.required]),
  });

  get importFromFbaMessage() {
    return `Do you want to import Functional Behavior Assessment (FBA) data from the most recent FBA? The most recent FBA was finalized on ${this.dateFormat.transform(
      this.fba?.finalizedOn
    )}.
      Note: If an additional FBA is finalized while drafting the BIP, the BIP will use the information from the most recently finalized FBA only.`;
  }

  constructor(
    private iepService: IepService,
    private fbaService: FbaService,
    private notificationService: NotificationService,
    private router: Router,
    private dateFormat: DateFormatPipe,
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      caseId: string;
      iepId: string;
      bipId: string;
      fbaId: string;
    }
  ) {
    this.caseId = data.caseId;
    this.iepId = data.iepId;
    this.bipId = data.bipId;
    this.fbaId = data.fbaId;
  }

  ngOnInit() {
    this.fbaService.getFba(this.fbaId).subscribe(
      (fbaResponse) => {
        if (fbaResponse.status === FbaStatus.Draft) {
          this.onCancel();
          this.notificationService.alert('The current active FBA must be finalized in order to create BIP.');
        } else {
          this.fba = fbaResponse;
          this.validateFba = true;
        }
      },
      (failedResponse) => {
        this.notificationService.error(failedResponse.error?.title + ': Unable to find record for FBA ID: ' + this.fbaId);
      }
    );
  }

  onCreate() {
    if (this.iepId && !this.bipId) {
      this.iepService.createBip(this.iepId, this.createBipFormGroup.controls.importFromFba.value).subscribe(
        (operationResponse) => {
          if (operationResponse.succeeded) {
            this.dialog.closeAll();

            this.bipId = operationResponse.value.id;
            this.router.navigateByUrl(`/cases/${this.caseId}/behavior-discipline/bip/${this.bipId}/overview`);
          } else {
            this.notificationService.error('Failed to create BIP.');
          }
        },
        (failedResponse) => {
          this.notificationService.error(failedResponse.error?.title + ': Unable to create BIP.');
        }
      );
    }
  }

  onCancel() {
    this.dialog.closeAll();
  }
}
