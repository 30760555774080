import { Help, HelpSection } from '../help';
import { ReferralReasonHelp, ReferralSourceInfoHelp } from '../models/referral.help';

export function getReferralDictionary(): Help[] {
  return [
    {
      title: HelpSection.Referral,
      dictionary: [
        {
          label: ReferralSourceInfoHelp.SignedRelease,
          descriptor: 'This may be a form from your agency. If you do not have a signed release, you can still submit the referral.',
        },
        {
          label: ReferralReasonHelp.Capta,
          descriptor: 'Child Abuse Prevention and Treatment Act',
        },
      ],
    },
  ];
}
