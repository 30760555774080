<div
  *ngIf="messages.length > 0"
  class="alert mb-1"
  [ngClass]="{
    'alert--info': status === 'info',
    'alert--warning': status === 'warning',
    'alert--success': status === 'success'
  }"
>
  <div class="alert__icon">
    <i class="fas fa-info-circle" *ngIf="status === 'info'"></i>
    <i class="fas fa-exclamation" *ngIf="status === 'warning'"></i>
  </div>
  <div class="alert__message">
    <p *ngFor="let message of messages">
      <span>{{ message }}</span>
    </p>
  </div>
</div>
