export enum IntakeNutritionGrowthHelp {
  Peach = 'PEACH',
}

export enum IntakeDentalVisionHearingHelp {
  ScreenedTested = 'Screened or Tested',
}

export enum IntakeHavingEnoughHelp {
  HavingEnough = 'Having Enough',
}
