export interface EcoMatrixItem {
  id?: string;
  description: string;
  isPlod?: boolean;
  ageExpect?: AgeExpect;
  ageExpectWarning?: boolean;
  ecoAreaId: string;
  evaluationRoutineId?: string;
  evaluationId: string;
}

export enum AgeExpect {
  AE = 'AE',
  F = 'F',
  IF = 'IF',
}
