import {
  Directive,
  EmbeddedViewRef,
  Host,
  Input,
  OnInit,
  OnDestroy,
  Optional,
  SkipSelf,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ifControlName]',
})
export class IfControlNameDirective implements OnInit, OnDestroy {
  private valueChangeSubscription?: Subscription;
  private embeddedView?: EmbeddedViewRef<any>;

  @Input('ifControlName') public validatorControlName: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('ifControlNameValue') public validatorControlValue = true;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('ifControlNameTarget') public targetName: string;
  @Input('ifControlNameReset') public reset = true;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('ifControlNameResetValue') public resetValue = null;

  constructor(
    @Optional() @Host() @SkipSelf() private parent: ControlContainer,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    if (!this.parent) {
      return;
    }

    this.setVisibility();
    this.valueChangeSubscription = this.parent.control?.valueChanges.subscribe((x) => this.setVisibility());
  }

  ngOnDestroy(): void {
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
  }

  private setVisibility(): void {
    const group = this.parent.control as FormGroup;
    if (!group) {
      return;
    }

    const validationControl = group.controls[this.validatorControlName] as FormControl;
    if (!validationControl) {
      return;
    }

    const isValid = validationControl.value === this.validatorControlValue;
    this.setTarget(isValid, group);

    if (isValid && !this.embeddedView) {
      this.embeddedView = this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (!isValid && this.embeddedView) {
      const viewIndex = this.viewContainerRef.indexOf(this.embeddedView);
      this.viewContainerRef.remove(viewIndex);
      this.embeddedView = undefined;
    }
  }

  private setTarget(value: boolean, formGroup: FormGroup): void {
    if (!this.targetName) {
      return;
    }

    const targetControl = formGroup.controls[this.targetName] as FormControl;
    if (!targetControl) {
      return;
    }

    if (value) {
      targetControl.enable({ emitEvent: false });
    } else {
      targetControl.disable({ emitEvent: false });
      if (this.reset) {
        targetControl.setValue(this.resetValue, { emitEvent: false });
      }
    }
  }
}
