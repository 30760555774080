import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LearnerService } from '../../../shared/services/learner/learner.service';

@Component({
  selector: 'app-pk-tok-transition-modal',
  templateUrl: './pk-tok-transition-modal.component.html',
  styleUrls: ['./pk-tok-transition-modal.component.scss'],
})
export class PkTokTransitionModalComponent implements OnInit {
  kindergartenFormGroup: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private learnerService: LearnerService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.kindergartenFormGroup = this.fb.group({
      startDate: [this.data.kindergartenStartDate, Validators.required],
    });
  }

  async yesImSure() {
    if (this.kindergartenFormGroup.valid) {
      const kindergartenStartDateFormValue = this.kindergartenFormGroup.get('startDate').value;
      if (kindergartenStartDateFormValue) {
        await this.learnerService.updateKindergartenStartDate(this.data.learnerId, kindergartenStartDateFormValue, false).toPromise();
      }
      this.dialogRef.close(true);
    }
  }

  no() {
    this.dialogRef.close(false);
  }
}
