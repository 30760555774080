import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaseSummary } from '../../../shared/models/case';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { CaseService } from '../../../shared/services/case/case.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { UserService } from '../../../shared/services/user/user.service';
import { conditionalValidator } from '../../../shared/validators';
import { IepAccommodationService } from '../../services/iep-accommodation.service';
import { IepActivitySupportService } from '../../services/iep-activity-support.service';
import { IepGoalService } from '../../services/iep-goal.service';
import { IepServiceService } from '../../services/iep-service.service';

@Component({
  selector: 'app-iep-change-provider',
  templateUrl: './iep-change-provider.component.html',
  styleUrls: ['./iep-change-provider.component.scss'],
})
export class IepChangeProviderComponent implements OnInit {
  providerOptions: KeyValuePair[] = [];
  formGroup = new FormGroup({
    providers: new FormControl(
      '',
      conditionalValidator(() => !this.isOtherProvider, Validators.required)
    ),
    otherProvider: new FormControl(null),
    otherProviderName: new FormControl(
      null,
      conditionalValidator(() => this.isOtherProvider, Validators.required)
    ),
    otherProviderAgency: new FormControl(
      null,
      conditionalValidator(() => this.isOtherProvider, Validators.required)
    ),
    otherProviderRole: new FormControl(
      null,
      conditionalValidator(() => this.isOtherProvider, Validators.required)
    ),
  });
  caseId: string;
  caseSummary: CaseSummary;
  isReady = false;

  get isOtherProvider() {
    return this.formGroup.get('otherProvider').value;
  }

  get isGoal() {
    return this.data?.type === 'IEPGoal';
  }
  constructor(
    public dialogRef: MatDialogRef<IepChangeProviderComponent>,
    private notificationService: NotificationService,
    private userService: UserService,
    private goalService: IepGoalService,
    private accommodationService: IepAccommodationService,
    private activitySupportService: IepActivitySupportService,
    private iepServiceService: IepServiceService,
    private caseService: CaseService,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.caseService.getCaseSummary(this.data.caseId).subscribe((caseSummary) => {
      this.caseSummary = caseSummary;
      if (this.data.type === 'IEPService') {
        this.userService.getServiceActivityProviders(this.caseSummary?.learnerId).subscribe((users) => {
          this.providerOptions = users
            .filter((u) => u.services.some((s) => s.id === this.data?.dataElement?.serviceTypeId))
            .map((u) => new KeyValuePair(u.id, u.fullName));
          const providersToPatch = this.data.dataElement.providers
            .map((x) => x.userId)
            .filter((x) => this.providerOptions.some((y) => y.key === x));
          this.formGroup.patchValue({ providers: providersToPatch });
          this.isReady = true;
        });
      } else {
        this.userService
          .searchUsers({
            aeaId: this.caseSummary?.learner?.attendingAeaId,
            buildingId: this.caseSummary?.learner?.building?.id,
            schoolDistrictId: this.caseSummary?.learner?.attendingDistrictId,
          })
          .subscribe((users) => {
            this.providerOptions = users.map((u) => new KeyValuePair(u.id, u.fullName));
            if (this.data.type === 'IEPGoal') {
              const providersToPatch = this.data.dataElement.peopleMonitoringGoal
                .map((x) => x.userId)
                .filter((x) => this.providerOptions.some((y) => y.key === x));
              this.formGroup.patchValue({ providers: providersToPatch });
            } else {
              const providersToPatch = this.data.dataElement.providers
                .map((x) => x.userId)
                .filter((x) => this.providerOptions.some((y) => y.key === x));
              this.formGroup.patchValue({ providers: providersToPatch });
            }
            this.isReady = true;
          });
      }
    });

    this.watchOtherProvider();
    this.patchOtherProvider();
    this.cd.detectChanges();
  }

  private watchOtherProvider() {
    this.formGroup.get('otherProvider').valueChanges.subscribe((isOther) => {
      const providerControl = this.formGroup.get('providers');
      const otherProviderNameControl = this.formGroup.get('otherProviderName');
      if (isOther) {
        providerControl.removeValidators(Validators.required);
        otherProviderNameControl.addValidators(Validators.required);
      } else {
        providerControl.addValidators(Validators.required);
        otherProviderNameControl.removeValidators(Validators.required);
      }
      providerControl.updateValueAndValidity({ emitEvent: false });
      otherProviderNameControl.updateValueAndValidity({ emitEvent: false });
    });
  }

  private patchOtherProvider() {
    this.formGroup.patchValue({
      otherProvider: this.data.dataElement.otherProvider,
      otherProviderName: this.data.dataElement.otherProviderName,
      otherProviderAgency: this.data.dataElement.otherProviderAgency,
      otherProviderRole: this.data.dataElement.otherProviderRole,
    });
  }

  onSubmit() {
    const callback = () => {
      this.notificationService.success('Successfully changed providers');
      this.dialogRef.close(true);
    };
    switch (this.data.type) {
      case 'IEPGoal':
        this.goalService.updateProviders(this.data.iepId, this.data.id, this.formGroup.get('providers').value).subscribe(() => {
          callback();
        });
        break;
      case 'IEPService':
        this.iepServiceService.updateProviders(this.data.iepId, this.data.id, this.formGroup.value).subscribe(() => {
          callback();
        });
        break;
      case 'IEPAccommodation':
        this.accommodationService.updateProviders(this.data.iepId, this.data.id, this.formGroup.value).subscribe(() => {
          callback();
        });
        break;
      case 'IEPActivitySupport':
        this.activitySupportService.updateProviders(this.data.iepId, this.data.id, this.formGroup.value).subscribe(() => {
          callback();
        });
        break;
    }
  }
}
