import { ChangeDetectorRef, Component, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { merge, Subject } from 'rxjs';
import { CaseSummary } from 'src/app/shared/models/case';
import { TimeUnit } from 'src/app/shared/models/time';
import { BaseComponent } from '../../../../../../shared/components/base-component/base-component';
import { LearnerSummary } from '../../../../../../shared/models/learner';
import { DeactivationService } from '../../../../../../shared/services/deactivation.service';
import { HelpSection, HelpTerm } from '../../../../../../shared/services/help/help';
import { HelpModalConfig, HelpService } from '../../../../../../shared/services/help/help.service';
import { IepServiceHelp } from '../../../../../../shared/services/help/models/iep.help';
import { IepServiceService } from '../../../../../services/iep-service.service';
import { IepService } from '../../../../../services/iep.service';

@Component({
  selector: 'app-iep-ssaa-lre-stats',
  templateUrl: './iep-ssaa-lre-stats.component.html',
  styleUrls: ['./iep-ssaa-lre-stats.component.scss'],
})
export class IepSsaaLreStatsComponent extends BaseComponent implements OnInit {
  @Input() iepId: string;
  @Input() previousIepId: string;
  @Input() amendmentId: string;
  @Input() isPK = false;
  @Input() learner: LearnerSummary;
  @Input() caseSummary: CaseSummary;
  @Input() ecpsHoursInWeek = 0;
  @Input() showK: boolean;
  ecpsMinutesInWeek = 0;
  testMode = false;
  startingKDuringIep = false;
  today = dayjs().startOf('day').toDate();

  get hasAmendment() {
    return this.amendmentId && this.amendmentId !== null;
  }

  @Input() lreData: any = null;
  @Output() stats = new Subject();
  displayedColumns = ['generalEducationEnv', 'specialEducationEnv'];
  dataSource: MatTableDataSource<any>;
  dataSourcePKOnly: MatTableDataSource<any>;
  dataSourceKOnly: MatTableDataSource<any>;
  showCharts = true;
  pieChart = {
    labels: ['', ''],
    colors: [
      {
        backgroundColor: ['rgba(41, 99, 28, 0.3)', 'rgba(38, 63, 140, 0.2)'],
      },
    ],
    legend: false,
  };

  currentIepChart = {
    data: [],
  };

  previousIepChart = {
    data: [],
  };

  currentIepChartPK = {
    data: [],
  };

  previousIepChartPK = {
    data: [],
  };

  currentIepChartK = {
    data: [],
  };

  previousIepChartK = {
    data: [],
  };

  generalEducationEnv = 0;
  specialEducationEnv = 0;
  pkGeneralEducationEnv = 0;
  pkSpecialEducationEnv = 0;
  kGeneralEducationEnv = 0;
  kSpecialEducationEnv = 0;
  hasRecps = false;
  helpSection = HelpSection;
  iepServiceHelp = IepServiceHelp;

  get showIncompatibleDataCombinationError() {
    return (
      this.isPK &&
      ((this.ecpsHoursInWeek === 0 && (this.isPK ? this.pkGeneralEducationEnv > 0 : this.generalEducationEnv > 0)) ||
        this.ecpsMinutesInWeek * 4 < this.lreData?.totalServices - this.lreData?.totalServicesRemoved)
    );
  }

  constructor(
    private iepService: IepService,
    private iepServiceService: IepServiceService,
    private readonly helpService: HelpService,
    deactivationService: DeactivationService,
    public cd: ChangeDetectorRef
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.startingKDuringIep = this.caseSummary?.activeIepIsPKToK;
    this.initData(this.lreData);
    this.subscriptions.add(
      merge(this.iepServiceService.servicesUpdated$, this.iepService.lreUpdated$).subscribe(() => {
        this.initData(null, true);
      })
    );
  }

  initData(lreData, isUpdate = false) {
    if (lreData) {
      this.setLREData(lreData);
    } else {
      if (this.startingKDuringIep) {
        this.loadCustomDateServices();
      } else {
        this.iepService.getLREFromIep(this.iepId, '', this.amendmentId ? this.amendmentId : '').subscribe((x) => {
          if (x) {
            this.setLREData(x);
          }
        });
      }
    }
    if (this.previousIepId || this.hasAmendment) {
      this.getPreviousLRE();
    }

    if (isUpdate) {
      this.iepService
        .updateLREPercentages(this.iepId, {
          amendmentId: this.amendmentId,
          isPkToK: this.startingKDuringIep,
          pkGeneralEducationEnv: this.pkGeneralEducationEnv,
          pkSpecialEducationEnv: this.pkSpecialEducationEnv,
          kGeneralEducationEnv: this.kGeneralEducationEnv,
          kSpecialEducationEnv: this.kSpecialEducationEnv,
          generalEducationEnv: this.generalEducationEnv,
          specialEducationEnv: this.specialEducationEnv,
        })
        .subscribe(() => {});
    }
  }

  getPreviousLRE() {
    this.iepService.getLREFromIep(this.hasAmendment ? this.iepId : this.previousIepId, '').subscribe((x) => {
      this.previousIepChart = {
        data: [x.specialEducationEnv, x.generalEducationEnv],
      };
    });
  }

  setLREData(lreData) {
    this.lreData = lreData;
    this.hasRecps =
      this.lreData?.earlyChildhoodPrograms?.filter(
        (recp) => recp.endDate === null || (recp.endDate !== null && dayjs(recp.endDate).toDate() >= this.today)
      )?.length > 0;

    this.setRECPS(this.lreData?.earlyChildhoodPrograms);
    this.generalEducationEnv = lreData.generalEducationEnv;
    this.specialEducationEnv = lreData.specialEducationEnv;
    this.stats.next({
      generalEducationEnv: this.generalEducationEnv,
      specialEducationEnv: this.specialEducationEnv,
      pkGeneralEducationEnv: 0,
      pkSpecialEducationEnv: 0,
    });
    this.dataSource = new MatTableDataSource([
      {
        generalEducationEnv: this.generalEducationEnv + '%',
        specialEducationEnv: this.specialEducationEnv + '%',
      },
    ]);
    this.currentIepChart = {
      data: [this.specialEducationEnv, this.generalEducationEnv],
    };

    if (this.previousIepId || this.hasAmendment) {
      this.previousIepChart = {
        data: [lreData?.priorVersion?.specialEducationEnv, lreData?.priorVersion?.generalEducationEnv],
      };
    }

    this.lreData.totalServicesRemoved = lreData.totalServicesRemoved;
    this.lreData.totalServices = lreData.totalServices;
    this.cd.detectChanges();
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm): void {
    e.preventDefault();
    const dictionary = this.helpService.getIepServiceDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  loadCustomDateServices(type = null) {
    if (type === 'pk' || type === null) {
      this.iepService.getLREFromIep(this.iepId, 'PKOnly', this.amendmentId ? this.amendmentId : '').subscribe((res) => {
        this.setLREDataPKOnly(res);
        this.setRECPS(res.earlyChildhoodPrograms);
      });
    }
    if (type === 'k' || type === null) {
      this.iepService.getLREFromIep(this.iepId, 'KOnly', this.amendmentId ? this.amendmentId : '').subscribe((res) => {
        this.setLREDataKOnly(res);
      });
    }
  }

  private setRECPS(earlyChildhoodPrograms) {
    let totalMinutes = 0;
    const filteredRecps = earlyChildhoodPrograms?.filter((recp) => recp.attendRecp);
    filteredRecps?.forEach((x) => {
      if (x.timeUnit === TimeUnit.Hours) {
        totalMinutes += x.timeQuantity * 60;
      } else if (x.timeUnit === TimeUnit.Minutes) {
        totalMinutes += x.timeQuantity;
      }
    });
    this.ecpsHoursInWeek = totalMinutes / 60;
    this.ecpsMinutesInWeek = totalMinutes;
  }

  private setLREDataPKOnly(lreData) {
    this.dataSourcePKOnly = new MatTableDataSource([
      {
        generalEducationEnv: lreData.generalEducationEnv + '%',
        specialEducationEnv: lreData.specialEducationEnv + '%',
      },
    ]);
    this.currentIepChartPK = {
      data: [lreData.specialEducationEnv, lreData.generalEducationEnv],
    };
    this.previousIepChartPK = {
      data: [lreData?.priorVersion?.specialEducationEnv, lreData?.priorVersion?.generalEducationEnv],
    };
    this.lreData = {};
    this.lreData.totalServicesRemoved = lreData.totalServicesRemoved;
    this.lreData.totalServices = lreData.totalServices;

    this.pkGeneralEducationEnv = lreData.generalEducationEnv;
    this.pkSpecialEducationEnv = lreData.specialEducationEnv;
    this.stats.next({
      generalEducationEnv: this.generalEducationEnv,
      specialEducationEnv: this.specialEducationEnv,
      pkGeneralEducationEnv: lreData.generalEducationEnv,
      pkSpecialEducationEnv: lreData.specialEducationEnv,
    });
    this.cd.detectChanges();
  }

  private setLREDataKOnly(lreData) {
    this.dataSourceKOnly = new MatTableDataSource([
      {
        generalEducationEnv: lreData.generalEducationEnv + '%',
        specialEducationEnv: lreData.specialEducationEnv + '%',
      },
    ]);
    this.kGeneralEducationEnv = lreData.generalEducationEnv;
    this.kSpecialEducationEnv = lreData.specialEducationEnv;
    this.currentIepChartK = {
      data: [lreData.specialEducationEnv, lreData.generalEducationEnv],
    };
    this.previousIepChartK = {
      data: [lreData?.priorVersion?.specialEducationEnv, lreData?.priorVersion?.generalEducationEnv],
    };
    this.cd.detectChanges();
  }
}
