import { LookupBase } from 'src/app/shared/models/lookup-base';
import { IfspSurvey } from './ifsp-survey';

export enum IfspStatus {
  Complete = 'Complete',
  Active = 'Active',
  Draft = 'Draft',
  Inactive = 'Inactive',
  Legacy = 'Legacy',
}

export enum IfspType {
  Initial = 'Initial',
  Periodic = 'Periodic',
  Annual = 'Annual',
  Interim = 'Interim',
}

export interface IfspView {
  id: string;
  caseId: string;
  ifspType: IfspType;
  ifspStatus: IfspStatus;
  createdOn: Date;
  activatedOn: Date;
  annualReviewActive: boolean;
  annualReviewStartDate: Date;
  canBeginAnnualReview: boolean;
  needForEarlyIntervention: boolean;
  rationale: string;
  lateReasonId: string;
  lateReasonOther: string;
  isLate: boolean;
  periodicReviewActive: boolean;
  periodicReviewDate: Date;
  canBeginPeriodicReview: boolean;
  canSendPeriodicReviewPoll: boolean;
  canSendAnnualReviewPoll: boolean;
  sentPeriodicReviewPoll: boolean;
  sentAnnualReviewPoll: boolean;
  startDate: Date;
  endDate: Date;
  isModifying: boolean;
  outputDocumentId?: string;
  currentModificationHasAnnualReview: boolean;
  modifications: any[];
  documents: any[];
  isFirstIfsp?: boolean;
}

export interface IfspAddUpdate {
  id?: string;
  caseId: string;
  ifspType: IfspType;
  ifspStatus: IfspStatus;
  needForEarlyIntervention: boolean;
  rationale: string;
}

export interface IfspPeriodicAnnualReview {
  id: string;
  ifspId: string;
  additionalInformationNeeded: boolean;
  stillEligible: boolean;
  eligibilityBasedOn: boolean;
  eligibilityBasedOnDescription: string;
  ifspModificationsComplete: boolean;
  periodicReviewSummary: string;
  closedOn?: Date;
  additionalInformationEnteredOn?: Date;
  createdOn: Date;
  ifspReviewType: string;
  generalSurveyUserFullNameDtos: IfspSurvey[];
  surveysByQuestion: IfspSurvey[];
  totalSurveysSent: number;
  ifspModificationId?: string;
  ifspPeriodicAnnualReviewOutcomes: IfspPeriodicAnnualReviewOutcome[];
}

export interface IfspPeriodicAnnualReviewOutcome {
  ifspPeriodicAnnualReviewId: string;
  ifspOutcomeId: string;
  typeOfOutcomeReview: TypeOfOutcomeReview;
  outcomeTitle: string;
  outcomeEndDate?: Date;
}

export enum TypeOfOutcomeReview {
  Continue = 'Continue',
  Discontinue = 'Discontinue',
  Met = 'Met',
  Revise = 'Revise',
}

export interface LateIfspReason extends LookupBase {
  isOther: boolean;
}

export interface PeriodicReviewUpdate {
  periodicReviewActive: boolean;
  periodicReviewDate: Date;
}

export interface AnnualReviewUpdate {
  reviewActive: boolean;
  annualReviewDate: Date;
  ifspType: IfspReviewType;
}

export enum IfspReviewType {
  Periodic = 'Periodic',
  Annual = 'Annual',
}
