<mat-form-field *ngIf="!readOnly || !showReadOnlyControl; else readOnlyTemplate" class="w-100 position-relative" #matFormField>
  <mat-label
    [ngClass]="{
      asterisk_input: (control.hasError('required') || showAsterisk) && !hideAsterisk
    }"
    >{{ label }}</mat-label
  >
  <mat-chip-list #chipList [disabled]="disabledState">
    <mat-chip
      *ngFor="let option of chipListCtrl?.value; let index = index"
      [selectable]="true"
      [removable]="true"
      (removed)="remove(option)"
      aria-live="polite"
      attr.aria-label="{{ option.value }}"
      tabindex="0"
    >
      {{ option.value }}
      <mat-icon matChipRemove *ngIf="!disabledState">cancel</mat-icon>
    </mat-chip>
    <input
      #optionInput
      matInput
      aria-label="Option"
      attr.aria-required="{{ (control.hasError('required') || showAsterisk) && !hideAsterisk ? 'true' : 'false' }}"
      [formControl]="optionAutocompleteChipsCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (blur)="onBlur($event)"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedChip($event)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option" role="combobox">
      {{ option.value }}
    </mat-option>
  </mat-autocomplete>

  <div class="form__carrot" *ngIf="!disabledState" (click)="autoComplete.openPanel()"></div>
</mat-form-field>
<mat-error class="position-fix--error">
  <app-field-error-display [control]="control"></app-field-error-display>
</mat-error>

<ng-template #readOnlyTemplate>
  <p class="read-only-container">
    <strong>{{ label }}</strong
    >{{ selectedOptions }}
  </p>
</ng-template>
