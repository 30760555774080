import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { FileDocument } from 'src/app/shared/models/file-document';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { Intake } from '../models/intake';

@Injectable({
  providedIn: 'root',
})
export class IntakeService {
  private baseUrl = 'api/early-access-intakes/';

  constructor(private http: HttpClient, private spinnerService: SpinnerService) {
    // Hide spinner for all but sending nutritionist emails
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(`^${this.baseUrl}((?!nutritionist).)*$`));
  }

  getIntake(caseId: string) {
    return this.http.get<Intake>(`${this.baseUrl}${caseId}`);
  }

  saveIntake(intake: Intake) {
    return this.http.put<Intake>(this.baseUrl + intake.caseId, intake);
  }

  uploadDocumentation(uploadFile: any, caseId: string) {
    this.spinnerService.incrementLoading();
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    return this.http
      .post<FileList>(this.baseUrl + caseId + '/documents', uploadFile, this.getMultipartRequestHeaders())
      .pipe(tap(turnOffSpinner, turnOffSpinner));
  }

  deleteDocument(caseId: string, documentId: string) {
    return this.http.put<FileDocument[]>(this.baseUrl + caseId + '/documents/' + documentId, null);
  }

  sendNutritionistEmail(caseId: string) {
    return this.http.post<void>(`${this.baseUrl}${caseId}/nutritionist-notifications`, {});
  }

  protected getMultipartRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      Accept: 'application/json, text/plain, */*',
    });

    return { headers };
  }
}
