<button mat-raised-button aria-label="Get Current" color="primary" (click)="getTestData()" *ngIf="testMode">Get Current</button>
<button mat-raised-button aria-label="Get Previous" color="primary" (click)="getTestData(true)" *ngIf="testMode">Get Previous</button>
<div class="row">
  <div class="col-md-6">
    <p class="text-center">
      <strong>
        <ng-container *ngIf="!!modificationId">Modified IFSP</ng-container>
        <ng-container *ngIf="!modificationId">Current IFSP</ng-container>
      </strong>
    </p>
    <table mat-table [dataSource]="dataSource" class="w-100 bordered">
      <ng-container matColumnDef="naturalEnvironment">
        <th mat-header-cell *matHeaderCellDef>Natural Environment</th>
        <td mat-cell *matCellDef="let element">
          {{ element.naturalEnvironment }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nonNaturalEnvironment">
        <th mat-header-cell *matHeaderCellDef>Non-Natural Environment</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nonNaturalEnvironment }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div class="charts__container mt-2">
      <div class="display-flex align-items-start justify-content-center">
        <div class="charts">
          <div class="charts__pie text-center">
            <canvas
              baseChart
              [data]="pieChart.data"
              [labels]="pieChart.labels"
              [chartType]="'pie'"
              [colors]="pieChart.colors"
              [legend]="pieChart.legend"
              role="img"
              attr.aria-label="Overall Primary Setting: {{ overallPrimarySetting }}"
            >
            </canvas>
            <label>Overall Primary Setting: {{ overallPrimarySetting }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6" *ngIf="!!modificationId">
    <p class="text-center"><strong>Current IFSP</strong></p>
    <table mat-table [dataSource]="dataSourcePrevious" class="w-100 bordered">
      <ng-container matColumnDef="naturalEnvironment">
        <th mat-header-cell *matHeaderCellDef>Natural Environment</th>
        <td mat-cell *matCellDef="let element">
          {{ element.naturalEnvironment }}
        </td>
      </ng-container>

      <ng-container matColumnDef="nonNaturalEnvironment">
        <th mat-header-cell *matHeaderCellDef>Non-Natural Environment</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nonNaturalEnvironment }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div class="charts__container mt-2">
      <div class="display-flex align-items-start justify-content-center">
        <div class="charts">
          <div class="charts__pie text-center">
            <canvas
              baseChart
              [data]="pieChartPrevious.data"
              [labels]="pieChartPrevious.labels"
              [chartType]="'pie'"
              [colors]="pieChart.colors"
              [legend]="pieChart.legend"
              role="img"
              attr.aria-label="Overall Primary Setting: {{ overallPrimarySettingPrevious }}"
            >
            </canvas>
            <label>Overall Primary Setting: {{ overallPrimarySettingPrevious }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
