import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { insertProgressMonitorData } from 'src/app/monitoring-progress/helpers/progressMonitorHelpers';
import { OutcomeProgressMonitor, ProgressMonitorPlanStatus } from 'src/app/monitoring-progress/models/outcome-progress-monitor';
import { ProgressMonitorPlanStatusService } from 'src/app/monitoring-progress/services/progress-monitor-plan-status.service';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { CaseSummary } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { ConfirmationDialogComponent } from 'src/app/shared/services/notification.service';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { DateFormatPipe, DateToUtcPipe } from '../../../../../shared/pipes/date-transform.pipe';

@Component({
  selector: 'app-plan-status',
  templateUrl: './plan-status.component.html',
  styleUrls: ['./plan-status.component.scss'],
})
export class PlanStatusComponent implements OnInit, OnChanges {
  @ViewChild(MatSort) sort: MatSort;
  @Input() caseSummary: CaseSummary;
  @Input() outcomes: Array<OutcomeProgressMonitor> = [];
  @Output() planStatusAdded = new EventEmitter();
  @Output() planStatusUpdated = new EventEmitter();
  @Output() planStatusDeleted = new EventEmitter();
  @Input() enableForms: boolean;
  isEditing: boolean;

  planStatusFormGroup = this.fb.group({
    id: '',
    date: ['', Validators.required],
    status: ['', Validators.required],
    outcomes: [[]],
  });

  filteredOutcomes: Array<OutcomeProgressMonitor> = [];
  outcomeOptions: Array<KeyValuePair> = [];

  @Input() minDate: Date;
  today = new Date();
  displayedColumns = ['actions', 'date', 'status', 'outcomes'];
  dataSource: MatTableDataSource<ProgressMonitorPlanStatus>;
  planStatuses: Array<ProgressMonitorPlanStatus> = [];

  getOutcomeNames(outcomes: Array<string>) {
    const outcomeNames = this.outcomes.filter((x) => outcomes?.includes(x.id)).map((x) => x.name);
    if (outcomeNames.length === 0) {
      return '-';
    }
    return outcomeNames.join(', ');
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly planStatusService: ProgressMonitorPlanStatusService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.load();
  }

  ngOnChanges() {
    this.setOutcomeOptions();
  }

  onPlanStatusSubmit() {
    const dto = this.planStatusFormGroup.value as ProgressMonitorPlanStatus;
    dto.date = new DateToUtcPipe().transform(new Date(dto.date));
    if (dto.id) {
      this.planStatusService.update(this.caseSummary.learnerId, dto).subscribe((res) => {
        dto.date = res.date;
        this.handlePlanStatusUpdate(dto);
      });
    } else {
      this.planStatusService.add(this.caseSummary.learnerId, dto).subscribe((res) => {
        dto.date = res.date;
        this.handlePlanStatusAdd(res.id, dto);
      });
    }
    this.planStatusFormGroup.reset();
    this.isEditing = false;
  }

  onPlanStatusCancel() {
    this.isEditing = false;
    this.planStatusFormGroup.reset();
  }

  onPlanStatusEdit(planStatus: ProgressMonitorPlanStatus) {
    this.isEditing = true;
    this.planStatusFormGroup.patchValue(planStatus);
  }

  onPlanStatusDelete(planStatus: ProgressMonitorPlanStatus) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '728px',
      data: {
        title: 'Remove',
        message: 'Are you sure you want to remove this item?',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.planStatusService.delete(this.caseSummary.learnerId, planStatus.id).subscribe(() => this.handlePlanStatusDelete(planStatus));
      }
    });
  }

  onViewMorePlanStatus(planStatus: ProgressMonitorPlanStatus) {
    const modalData: Array<ViewMoreModalData> = [
      {
        name: 'Date',
        value: new DateFormatPipe().transform(planStatus.date),
      },
      {
        name: 'How is the plan working?',
        value: planStatus.status,
      },
      {
        name: 'Outcomes',
        value: this.outcomes
          .filter((x) => planStatus.outcomes.includes(x.id))
          .map((x) => x.name)
          .join(', '),
      },
    ];
    openViewMore(this.dialog, modalData);
  }

  private load() {
    this.planStatusService.get(this.caseSummary.learnerId).subscribe((res) => {
      this.planStatuses = res;
      this.dataSource = new MatTableDataSource(this.planStatuses);
      this.dataSource.sort = this.sort;
    });
  }

  private handlePlanStatusAdd(id: string, planStatus) {
    planStatus.id = id;
    planStatus.date = planStatus.date;
    insertProgressMonitorData(this.planStatuses, planStatus);
    this.dataSource.data = this.planStatuses;
    if (planStatus.outcomes) {
      this.planStatusAdded.emit(planStatus);
    }
  }

  private handlePlanStatusUpdate(planStatus: ProgressMonitorPlanStatus) {
    const existingPlanStatus = this.planStatuses.find((x) => x.id === planStatus.id);
    existingPlanStatus.date = planStatus.date;
    existingPlanStatus.status = planStatus.status;
    existingPlanStatus.outcomes = planStatus.outcomes;
    if (existingPlanStatus.outcomes) {
      this.planStatusUpdated.emit(existingPlanStatus);
    }
  }

  private handlePlanStatusDelete(planStatus: ProgressMonitorPlanStatus) {
    const index = this.planStatuses.indexOf(planStatus);
    if (index > -1) {
      this.planStatuses.splice(index, 1);
      this.dataSource.data = this.planStatuses;
      if (planStatus.outcomes) {
        this.planStatusDeleted.emit(planStatus);
      }
    }
  }

  private setOutcomeOptions() {
    this.outcomeOptions = this.outcomes.filter((x) => !x.ended).map((outcome) => new KeyValuePair(outcome.id, outcome.name));
  }
}
