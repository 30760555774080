import { Component, Input, OnDestroy } from '@angular/core';
import { OnInit } from '@angular/core/core';
import { AbstractControl, ControlContainer, FormControl, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { validationKeys } from 'src/app/shared/validators';

@Component({
  selector: 'app-field-error-display',
  templateUrl: './field-error-display.component.html',
  styleUrls: ['./field-error-display.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class FieldErrorDisplayComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl = new FormControl();
  subscription: Subscription;
  uncoveredError: string[] = [];
  validationKeys = validationKeys;
  shortDateFormat = shortDateFormat;

  constructor() {}

  get errors(): ValidationErrors {
    return this.control?.errors ?? [];
  }

  ngOnInit() {
    // these errors have messages in the html so we'll ignore them here. The main purpose of this section is to get
    // errors like "unique: 'Email is in use'" so we can display the text to the user.
    const blackList = ['zeroValue', 'required', 'zeroValue', 'maxlength', 'Mask error', 'email', 'dateLessThan', 'typeahead'];
    this.subscription = this.control.statusChanges.subscribe((status) => {
      this.uncoveredError = [];
      if (status === 'VALID') {
        return;
      }
      Object.keys(this.errors).forEach((error: string) => {
        if (blackList.indexOf(error) > 0) {
          return;
        }
        const errorValue = this.errors[error];
        // ReSharper disable once TypeGuardProducesNeverType
        if (typeof errorValue === 'string') {
          this.uncoveredError.push(errorValue);
        }
      });
    });
  }

  ngOnDestroy(): void {
    try {
      this.subscription.unsubscribe();
    } catch (e) {}
  }
}
