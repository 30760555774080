<header>
  <app-page-header [pageTitle]="'ECO Ratings'" [backgroundColor]="'blue'"></app-page-header>
</header>

<app-help-directional-text [outsideCard]="true">
  <p class="mb-2">Use the interactive decision tree to complete the team's decision of the ECO statement.</p>
</app-help-directional-text>

<mat-accordion displayMode="flat" multi class="accordion">
  <mat-expansion-panel class="mat-elevation-z0 matrix gray" expanded>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex align-items-center justify-content-between flex-wrap w-100">
          <h3 class="my-0 text-normal">{{ ecoArea?.label }}</h3>
          <span class="text-sm" *ngIf="false">
            if you turn this back on, make sure transition still works (AE =
            {{ aeCount }} / IF = {{ ifCount }} / F = {{ fCount }})
          </span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="tree">
      <div class="tree__item tree__item--header">
        <p>Does the child ever function in ways that would be considered age expected with regard to this outcome?</p>
      </div>

      <div class="tree__group tree__group--header">
        <div
          class="tree__item tree__item--one tree__item--header-one mr-3"
          [ngClass]="{
            'background-color--muted': ageExpectedRegardOutcome === true,
            'background-color--success': ageExpectedRegardOutcome === false
          }"
        >
          <button
            color="primary"
            mat-flat-button
            aria-label="No (Consider rating 1-3)"
            [disabled]="ageExpectedRegardOutcome === true"
            (click)="onNo('ageExpectedRegardOutcome')"
            tabindex="0"
          >
            No (consider rating 1-3)
            <i
              class="fas fa-undo-alt text-sm pl-2"
              *ngIf="ageExpectedRegardOutcome === false"
              aria-labelledby="No (Consider rating 1-3)"
            ></i>
            <!-- {{
              ageExpectedRegardOutcome === null ||
              ageExpectedRegardOutcome !== false
                ? 'No (consider rating 1-3)'
                : 'Undo'
            }} -->
          </button>
        </div>

        <div
          class="tree__item tree__item--one"
          [ngClass]="{
            'background-color--muted': ageExpectedRegardOutcome === false,
            'background-color--success': ageExpectedRegardOutcome === true
          }"
        >
          <button
            color="primary"
            mat-flat-button
            aria-label="Yes (consider rating 4-7)"
            [disabled]="ageExpectedRegardOutcome === false"
            (click)="onYes('ageExpectedRegardOutcome')"
            tabindex="0"
          >
            Yes (consider rating 4-7)
            <i
              class="fas fa-undo-alt text-sm pl-2"
              *ngIf="ageExpectedRegardOutcome === true"
              aria-labelledby="Yes (consider rating 4-7)"
            ></i>
          </button>
        </div>
      </div>

      <div class="tree__group">
        <div class="tree__group-sub w-50 mr-5">
          <div
            class="tree__item tree__item--two mr-3"
            [ngClass]="{
              'background-color--muted': ageExpectedRegardOutcome === true
            }"
          >
            <p>
              Does the child use any immediate foundational skills related to this outcome upon which to build age-expected functioning
              across settings and situations?
            </p>
          </div>

          <div class="tree__group">
            <div
              class="tree__item tree__item--one"
              [ngClass]="{
                'background-color--muted': immediateFoundationalSkills === true || ageExpectedRegardOutcome === true,
                'background-color--success': immediateFoundationalSkills === false
              }"
            >
              <button
                color="primary"
                mat-flat-button
                aria-label="No"
                [disabled]="immediateFoundationalSkills === true || ageExpectedRegardOutcome === true || ageExpectedRegardOutcome === null"
                (click)="onNo('immediateFoundationalSkills')"
                tabindex="0"
              >
                No
                <i class="fas fa-undo-alt text-sm pl-2" *ngIf="immediateFoundationalSkills === false" aria-label="No"></i>
              </button>
            </div>

            <div
              class="tree__item tree__item--one mr-3"
              [ngClass]="{
                'background-color--muted': immediateFoundationalSkills === false || ageExpectedRegardOutcome === true,
                'background-color--success': immediateFoundationalSkills === true
              }"
            >
              <button
                color="primary"
                mat-flat-button
                aria-label="Yes"
                [disabled]="immediateFoundationalSkills === false || ageExpectedRegardOutcome === true || ageExpectedRegardOutcome === null"
                (click)="onYes('immediateFoundationalSkills')"
                tabindex="0"
              >
                Yes
                <i class="fas fa-undo-alt text-sm pl-2" *ngIf="immediateFoundationalSkills === true" aria-label="Yes"></i>
              </button>
            </div>
          </div>

          <div class="tree__group">
            <div
              class="tree__item mr-2"
              [ngClass]="{
                'background-color--muted': immediateFoundationalSkills === true || ageExpectedRegardOutcome === true,
                'background-color--success': usesSkillsThatAreNotImmediate === true
              }"
            >
              <button
                class="text"
                [disabled]="
                  immediateFoundationalSkills === true || ageExpectedRegardOutcome === true || immediateFoundationalSkills === null
                "
                (click)="onYes('usesSkillsThatAreNotImmediate')"
                aria-label="Uses skills that are not yet immediate foundational (1)"
                tabindex="0"
              >
                Uses skills that are not yet immediate foundational<sup>(1)</sup>

                <i
                  class="fas fa-undo-alt text-sm pl-2"
                  *ngIf="usesSkillsThatAreNotImmediate === true"
                  aria-labelledby="Uses skills that are not yet immediate foundational (1)"
                ></i>
              </button>
            </div>

            <div class="tree__group-sub">
              <div
                class="tree__item tree__item--two mr-3"
                [ngClass]="{
                  'background-color--muted': immediateFoundationalSkills === false || ageExpectedRegardOutcome === true
                }"
              >
                <p>To what extent is the child using immediate foundational skills across settings and situations?</p>
              </div>

              <div class="tree__group" style="margin-top: -20px">
                <div
                  class="tree__item mr-1"
                  [ngClass]="{
                    'background-color--muted':
                      immediateFoundationalSkills === false || ageExpectedRegardOutcome === true || usesImmediateSkillsAll === true,
                    'background-color--success': occasionalUseOfSkill === true
                  }"
                >
                  <button
                    class="text"
                    (click)="onYes('occasionalUseOfSkill')"
                    [disabled]="
                      ageExpectedRegardOutcome === true ||
                      consideredAgeExpectedSetting === undefined ||
                      ageExpectedRegardOutcome === null ||
                      immediateFoundationalSkills === false ||
                      immediateFoundationalSkills !== true ||
                      usesImmediateSkillsAll === true
                    "
                    aria-label="Occasional use of immediate foundational skills (2)"
                    tabindex="0"
                  >
                    Occasional use of immediate foundational skills<sup>(2)</sup>

                    <i
                      class="fas fa-undo-alt text-sm pl-2"
                      *ngIf="occasionalUseOfSkill === true"
                      aria-labelledby="Occasional use of immediate foundational skills (2)"
                    ></i>
                  </button>
                </div>

                <div
                  class="tree__item mr-3"
                  [ngClass]="{
                    'background-color--muted':
                      immediateFoundationalSkills === false || ageExpectedRegardOutcome === true || occasionalUseOfSkill === true,
                    'background-color--success': usesImmediateSkillsAll === true
                  }"
                >
                  <button
                    class="text"
                    [disabled]="
                      ageExpectedRegardOutcome === true ||
                      consideredAgeExpectedSetting === undefined ||
                      ageExpectedRegardOutcome === null ||
                      immediateFoundationalSkills === false ||
                      immediateFoundationalSkills !== true ||
                      occasionalUseOfSkill === true
                    "
                    aria-label="Uses immediate foundational skills most or all of the time (3)"
                    (click)="onYes('usesImmediateSkillsAll')"
                    tabindex="0"
                  >
                    Uses immediate foundational skills most or all of the time<sup>(3)</sup>
                    <i
                      class="fas fa-undo-alt text-sm pl-2"
                      *ngIf="usesImmediateSkillsAll === true"
                      aria-labelledby="Uses immediate foundational skills most or all of the time (3)"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tree__group-sub w-50 ml-5">
          <div
            class="tree__item tree__item--two"
            [ngClass]="{
              'background-color--muted': ageExpectedRegardOutcome === false
            }"
          >
            <p>Does the child function in ways that would be considered age-expected across all or almost all settings and situations?</p>
          </div>

          <div class="tree__group">
            <div
              class="tree__item tree__item--one"
              [ngClass]="{
                'background-color--muted': ageExpectedRegardOutcome === false || consideredAgeExpectedSetting === true,
                'background-color--success': consideredAgeExpectedSetting === false
              }"
            >
              <button
                color="primary"
                mat-flat-button
                [disabled]="
                  ageExpectedRegardOutcome === false ||
                  consideredAgeExpectedSetting === true ||
                  consideredAgeExpectedSetting === undefined ||
                  ageExpectedRegardOutcome === null
                "
                aria-label="No"
                (click)="onNo('consideredAgeExpectedSetting')"
                tabindex="0"
              >
                No

                <i class="fas fa-undo-alt text-sm pl-2" *ngIf="consideredAgeExpectedSetting === false" aria-label="No"></i>
              </button>
            </div>

            <div
              class="tree__item tree__item--one"
              [ngClass]="{
                'background-color--muted': ageExpectedRegardOutcome === false || consideredAgeExpectedSetting === false,
                'background-color--success': consideredAgeExpectedSetting === true
              }"
            >
              <button
                color="primary"
                mat-flat-button
                [disabled]="
                  ageExpectedRegardOutcome === false ||
                  consideredAgeExpectedSetting === false ||
                  consideredAgeExpectedSetting === undefined ||
                  ageExpectedRegardOutcome === null
                "
                aria-label="Yes"
                (click)="onYes('consideredAgeExpectedSetting')"
                tabindex="0"
              >
                Yes
                <i class="fas fa-undo-alt text-sm pl-2" *ngIf="consideredAgeExpectedSetting === true" aria-label="Yes"></i>
              </button>
            </div>
          </div>

          <div class="tree__group">
            <div class="tree__group-sub">
              <div
                class="tree__item tree__item--two mr-2"
                [ngClass]="{
                  'background-color--muted': ageExpectedRegardOutcome === false || consideredAgeExpectedSetting === true
                }"
              >
                <p>To what extent does the child function in ways that are age-expected across settings and situations?</p>
              </div>
              <div class="tree__group">
                <div
                  class="tree__item mr-2"
                  [ngClass]="{
                    'background-color--muted':
                      ageExpectedRegardOutcome === false || consideredAgeExpectedSetting === true || whatExtentFunctionSetting === true,
                    'background-color--success': whatExtentFunctionSetting === false
                  }"
                >
                  <button
                    class="text"
                    [disabled]="
                      ageExpectedRegardOutcome === false ||
                      consideredAgeExpectedSetting === true ||
                      consideredAgeExpectedSetting === null ||
                      whatExtentFunctionSetting === true
                    "
                    (click)="onNo('whatExtentFunctionSetting')"
                    aria-label="Occasional use of age-expected skills; more behavior that is not age-expected (4)"
                    tabindex="0"
                  >
                    Occasional use of age-expected skills; more behavior that is not age-expected<sup>(4)</sup>
                    <i
                      class="fas fa-undo-alt text-sm pl-2"
                      *ngIf="whatExtentFunctionSetting === false"
                      aria-labelledby="Occasional use of age-expected skills; more behavior that is not age-expected (4)"
                    ></i>
                  </button>
                </div>

                <div
                  class="tree__item mr-2"
                  [ngClass]="{
                    'background-color--muted':
                      ageExpectedRegardOutcome === false || consideredAgeExpectedSetting === true || whatExtentFunctionSetting === false,
                    'background-color--success': whatExtentFunctionSetting === true
                  }"
                >
                  <button
                    class="text"
                    (click)="onYes('whatExtentFunctionSetting')"
                    [disabled]="
                      ageExpectedRegardOutcome === false ||
                      consideredAgeExpectedSetting === true ||
                      consideredAgeExpectedSetting === null ||
                      whatExtentFunctionSetting === false
                    "
                    aria-label="Uses a mix of age-expected skills more behavior that is not age-expected (5)"
                    tabindex="0"
                  >
                    Uses a mix of age-expected skills more behavior that is not age-expected<sup>(5)</sup>
                    <i
                      class="fas fa-undo-alt text-sm pl-2"
                      *ngIf="whatExtentFunctionSetting === true"
                      aria-labelledby="Uses a mix of age-expected skills more behavior that is not age-expected (5)"
                    ></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="tree__group-sub">
              <div
                class="tree__item tree__item--two"
                [ngClass]="{
                  'background-color--muted': ageExpectedRegardOutcome === false || consideredAgeExpectedSetting === false
                }"
              >
                <p>Does anyone have concerns about the child's functioning with regard to the outcome area?</p>
              </div>

              <div class="tree__group">
                <div class="tree__group">
                  <div
                    class="tree__item mr-4"
                    [ngClass]="{
                      'background-color--muted':
                        ageExpectedRegardOutcome === false || consideredAgeExpectedSetting === false || doesAnyoneHaveConcerns === true,
                      'background-color--success': doesAnyoneHaveConcerns === false
                    }"
                  >
                    <button
                      color="primary"
                      mat-flat-button
                      [disabled]="
                        ageExpectedRegardOutcome === false ||
                        consideredAgeExpectedSetting === false ||
                        consideredAgeExpectedSetting === undefined ||
                        ageExpectedRegardOutcome === null ||
                        consideredAgeExpectedSetting === null ||
                        doesAnyoneHaveConcerns === true
                      "
                      aria-label="Yes"
                      (click)="onNo('doesAnyoneHaveConcerns')"
                      tabindex="0"
                    >
                      Yes <sup>(6)</sup>
                      <i class="fas fa-undo-alt text-sm pl-2" *ngIf="doesAnyoneHaveConcerns === false" aria-label="Yes"></i>
                    </button>
                  </div>

                  <div
                    class="tree__item"
                    [ngClass]="{
                      'background-color--muted':
                        ageExpectedRegardOutcome === false || consideredAgeExpectedSetting === false || doesAnyoneHaveConcerns === false,
                      'background-color--success': doesAnyoneHaveConcerns === true
                    }"
                  >
                    <button
                      color="primary"
                      mat-flat-button
                      [disabled]="
                        consideredAgeExpectedSetting === undefined ||
                        ageExpectedRegardOutcome === false ||
                        consideredAgeExpectedSetting === false ||
                        ageExpectedRegardOutcome === null ||
                        consideredAgeExpectedSetting === null ||
                        doesAnyoneHaveConcerns === false
                      "
                      aria-label="No (7)"
                      (click)="onYes('doesAnyoneHaveConcerns')"
                      tabindex="0"
                    >
                      No <sup>(7)</sup>
                      <i class="fas fa-undo-alt text-sm pl-2" *ngIf="doesAnyoneHaveConcerns === true" aria-labelledby="No (7)"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Team Decision ECO Description</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="formGroup">
      <mat-radio-group ngDefaultControl aria-labelledby="Select a decision" formControlName="score" class="example-radio-group">
        <mat-radio-button
          *ngFor="let decision of ecoDescriptionOptions"
          [value]="decision.key"
          [ngClass]="{
            'highlight highlight--radio': sameAsSuggested(decision.key)
          }"
          class="wrap mb-4 position-fix--radio-sup"
          attr.aria-label="{{ decision.value }} - ({{ decision.key }})"
          >{{ decision.value }} <sup>({{ decision.key }})</sup></mat-radio-button
        >
      </mat-radio-group>
    </form>
  </mat-expansion-panel>
</mat-accordion>

<div *ngIf="!data" class="actions">
  <button mat-raised-button aria-label="Save Button" color="primary" [isBusy]="saving" (click)="queueSave(true)" tabindex="0">Save</button>
</div>

<div class="page-bottom-leeway"></div>
