<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>ECO Decision Tree</h3>

<div mat-dialog-content class="py-3">
  <app-eco-wizard
    #ecoWizard
    [data]="data"
    (suggestedEcoRatingScore)="suggestedEcoRatingScore($event)"
    (ecoRatingScore)="ecoRatingScore($event)"
    (saveStatusChange)="setSaveStatus($event)"
  ></app-eco-wizard>
</div>

<div mat-dialog-actions class="actions">
  <div>
    <button mat-button aria-label="Cancel Button" class="background-color--muted" (click)="onCancel()" [isBusy]="saving" tabindex="0">
      Cancel
    </button>
    <button mat-raised-button aria-label="Save Button" color="primary" (click)="onSaveClose()" [isBusy]="saving" tabindex="0">Save</button>
  </div>
</div>
