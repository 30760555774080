import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from '../../services/document/document.service';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  learnerId = this.route.snapshot.paramMap.get('learnerId');
  documentId = this.route.snapshot.paramMap.get('documentId');
  pdfSrc;

  constructor(private route: ActivatedRoute, private documentService: DocumentService) {}

  ngOnInit(): void {
    if (!this.learnerId) {
      this.documentService.getGenericDocument(this.documentId).subscribe((res) => {
        this.pdfSrc = res;
      });
    } else {
      this.documentService.getDocument(this.learnerId, this.documentId).subscribe((res) => {
        this.pdfSrc = res;
      });
    }
  }
}
