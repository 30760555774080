import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { LookupBase } from '../../shared/models/lookup-base';
import { Referral, ReferralDocument } from './referral';
import { ReferralHowHearAboutUs } from './referral-how-hear-about-us';
import { ReferralSourceCategory } from './referral-source-category';
import { ScreeningTool } from './screening-tool';

@Injectable({
  providedIn: 'root',
})
export class ReferralService {
  private baseUrl = 'api/early-access-referrals/';

  constructor(private http: HttpClient, private spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(`^${this.baseUrl}`));
  }

  getReferral(referralId: string) {
    return this.http.get<Referral>(`${this.baseUrl}${referralId}`);
  }

  getAeaReferrals(aeaIds: string[]) {
    if (!!aeaIds && aeaIds.length > 0) {
      let params = new HttpParams();
      aeaIds.forEach((id) => (params = params.append('aeaIds', id)));
      return this.http.get<Referral[]>(this.baseUrl, { params });
    } else {
      return this.http.get<Referral[]>(this.baseUrl);
    }
  }

  getInProgressReferrals(): Observable<Referral[]> {
    const params = new HttpParams().set('in-progress', 'true');
    return this.http.get<Referral[]>(this.baseUrl, { params });
  }

  saveReferral(referral: Referral) {
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    if (referral.isSubmitted) {
      this.spinnerService.incrementLoading();
      if (!referral.id) {
        return this.http.post<Referral>(this.baseUrl, referral).pipe(tap(turnOffSpinner, turnOffSpinner));
      } else {
        return this.http.put(this.baseUrl + referral.id, referral).pipe(tap(turnOffSpinner, turnOffSpinner));
      }
    } else {
      if (!referral.id) {
        return this.http.post<Referral>(this.baseUrl, referral);
      } else {
        return this.http.put(this.baseUrl + referral.id, referral);
      }
    }
  }

  addDocuments(documents: FormData) {
    this.spinnerService.incrementLoading();
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    return this.http
      .post<ReferralDocument[]>(`${this.baseUrl}documents`, documents, this.getMultipartRequestHeaders())
      .pipe(tap(turnOffSpinner, turnOffSpinner));
  }

  deleteReferral(referralId: string) {
    return this.http.put<string>(`${this.baseUrl}delete/${referralId}`, null);
  }

  getReferralSourceCategories(includeDeleted = false): Observable<ReferralSourceCategory[]> {
    return this.http.get<ReferralSourceCategory[]>(`api/ReferralSourceCategories?includeDeleted=${includeDeleted}`);
  }

  getScreeningTools(): Observable<ScreeningTool[]> {
    return this.http.get<ScreeningTool[]>('api/ScreeningTools');
  }

  addCategory(model): Observable<ReferralSourceCategory> {
    return this.http.post<ReferralSourceCategory>('api/ReferralSourceCategories', model);
  }

  removeCategory(id: string): Observable<void> {
    return this.http.put<void>(`api/ReferralSourceCategories/${id}`, null);
  }

  editCategory(model): Observable<void> {
    return this.http.put<void>(`api/ReferralSourceCategories/${model.id}`, model);
  }

  getReferralSourceHowHearAboutUs(includeDeleted = false): Observable<ReferralHowHearAboutUs[]> {
    return this.http.get<ReferralHowHearAboutUs[]>(`api/ReferralSourceHowHearAboutUs?includeDeleted=${includeDeleted}`);
  }

  addHear(model): Observable<LookupBase> {
    return this.http.post<LookupBase>('api/ReferralSourceHowHearAboutUs', model);
  }

  removeHear(id: string): Observable<void> {
    return this.http.put<void>(`api/ReferralSourceHowHearAboutUs/${id}`, null);
  }

  editHear(model): Observable<void> {
    return this.http.put<void>(`api/ReferralSourceHowHearAboutUs/${model.id}`, model);
  }

  private getMultipartRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      Accept: 'application/json, text/plain, */*',
    });

    return { headers };
  }
}
