import { Directive, ElementRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input',
})
export class NoAutocompleteDirective {
  constructor(el: ElementRef) {
    el.nativeElement.setAttribute('autocomplete', 'chrome-bug-468153');
  }
}
