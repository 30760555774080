import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { TransitionAssessment, TransitionAssessmentDocument } from '../models/transition-assessment';

@Injectable({
  providedIn: 'root',
})
export class TransitionAssessmentService {
  private transitionAssessmentsUpdatedSource = new Subject<void>();
  transitionAssessmentsUpdated = this.transitionAssessmentsUpdatedSource.asObservable();

  constructor(private http: HttpClient, private spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/.*\/secondary-transitions.*/));
  }

  announceUpdated() {
    this.transitionAssessmentsUpdatedSource.next();
  }

  getAll(iepId: string, plaafpId: string, secondaryTransitionId: string): Observable<TransitionAssessment[]> {
    return this.http.get<TransitionAssessment[]>(
      `api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/transition-assessments`
    );
  }

  getHistorical(learnerId: string) {
    return this.http.get<TransitionAssessment[]>(`api/learners/${learnerId}/transition-assessments`);
  }

  create(iepId: string, plaafpId: string, secondaryTransitionId: string, dto) {
    return this.http.post(`api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/transition-assessments`, dto);
  }

  update(iepId: string, plaafpId: string, secondaryTransitionId: string, dto) {
    return this.http.put(
      `api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/transition-assessments/${dto.id}`,
      dto
    );
  }

  delete(iepId: string, plaafpId: string, secondaryTransitionId: string, taId: string) {
    return this.http.put(
      `api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/transition-assessments/delete/${taId}`,
      null
    );
  }

  //#region Documents
  addDocuments(iepId: string, plaafpId: string, secondaryTransitionId: string, documents: FormData) {
    this.spinnerService.incrementLoading();
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    return this.http
      .post<TransitionAssessmentDocument[]>(
        `api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/transition-assessments/documents`,
        documents,
        this.getMultipartRequestHeaders()
      )
      .pipe(tap(turnOffSpinner, turnOffSpinner));
  }

  removeDocument(iepId: string, plaafpId: string, secondaryTransitionId: string, taId: string, docId: string) {
    return this.http.put(
      `api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/transition-assessments/${taId}/documents/${docId}`,
      null
    );
  }

  // TODO: move this to a shared helper
  protected getMultipartRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      Accept: 'application/json, text/plain, */*',
    });

    return { headers };
  }
  //#endregion
}
