<div class="display-flex align-items-center justify-content-between flex-wrap">
  <h4 class="text-primary mr-2 my-0">
    Courses and activities to pursue
    {{ learnerFirstName | firstNamePossessive }} postsecondary expectations
  </h4>
  <button
    mat-raised-button
    aria-label="Add New Course/Activity"
    color="primary"
    type="button"
    *ngIf="!readonly"
    (click)="onAddNewActivity()"
    [disabled]="disableModifications"
    tabindex="0"
  >
    Add New Course/Activity
  </button>
</div>
<section *ngIf="isEditing" class="mt-2 mb-2">
  <form [formGroup]="formGroup" (ngSubmit)="onSubmitActivity(true)">
    <div class="row">
      <div class="col">
        <app-text-field
          formControlName="name"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.secondaryTransition.pseActivitiesCourseActivities.name"
          label="Course/Activity"
        ></app-text-field>
      </div>
      <div class="col">
        <app-text-field
          formControlName="projectedDate"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.secondaryTransition.pseActivitiesCourseActivities.projectedDate"
          label="Projected Date/Year of School"
        ></app-text-field>
      </div>
    </div>
    <div class="display-flex align-items-center justify-content-between flex-wrap">
      <div class="display-flex align-items-center">
        <mat-button-toggle-group [multiple]="true" aria-labelledby="Select an option">
          <mat-button-toggle
            (change)="toggleEnvironment($event)"
            [checked]="formGroup.get('living').value"
            value="living"
            aria-labelledby="Living"
          >
            Living
          </mat-button-toggle>
          <mat-button-toggle
            (change)="toggleEnvironment($event)"
            [checked]="formGroup.get('learning').value"
            value="learning"
            aria-labelledby="Learning"
          >
            Learning
          </mat-button-toggle>
          <mat-button-toggle
            (change)="toggleEnvironment($event)"
            [checked]="formGroup.get('working').value"
            value="working"
            aria-labelledby="Working"
          >
            Working
          </mat-button-toggle>
        </mat-button-toggle-group>
        <mat-error class="text-sm ml-3" *ngIf="!livingLearningWorkingChecked"> Field is <strong>required</strong> </mat-error>
      </div>
      <div class="display-flex flex-wrap align-self-end">
        <button mat-raised-button aria-label="Cancel Button" type="button" class="mr-2" (click)="onCancel()" tabindex="0">Cancel</button>
        <button
          mat-raised-button
          aria-label="Complete and Add Another"
          color="primary"
          [disabled]="formGroup.invalid || !livingLearningWorkingChecked"
          type="button"
          class="mr-2"
          [disableAfterBusy]="formGroup.invalid || !livingLearningWorkingChecked"
          [isBusy]="activeCall"
          (click)="onSubmitActivity(false)"
          tabindex="0"
        >
          Complete and Add Another
        </button>
        <button
          mat-raised-button
          aria-label="Complete and Close"
          color="primary"
          [disabled]="formGroup.invalid || !livingLearningWorkingChecked"
          type="submit"
          [disableAfterBusy]="formGroup.invalid || !livingLearningWorkingChecked"
          [isBusy]="activeCall"
          tabindex="0"
        >
          Complete and Close
        </button>
      </div>
    </div>
  </form>
</section>
<div class="table-overflow">
  <table mat-table matSort class="mat-elevation-z0 w-100 mt-2 mb-2" [dataSource]="dataSource">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more">Actions</th>
      <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
        <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewMore(element)" tabindex="0">
          <mat-icon>remove_red_eye</mat-icon>
        </button>
        <ng-container *ngIf="element.id && !isEditing && !disableModifications && !readonly">
          <button
            *ngIf="!element.status"
            aria-label="Edit Icon"
            color="primary"
            mat-icon-button
            (click)="onEdit(element, true)"
            tabindex="0"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button aria-label="Delete Icon" color="warn" mat-icon-button (click)="onDelete(element)" tabindex="0">
            <mat-icon>close</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Course / Activity</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name | truncate : 150 }}
      </td>
    </ng-container>
    <ng-container matColumnDef="projectedDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Projected Date / Year of School</th>
      <td mat-cell *matCellDef="let element">{{ element.projectedDate }}</td>
    </ng-container>
    <ng-container matColumnDef="llw">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="pr-3">Living, Learning, Working</th>
      <td mat-cell *matCellDef="let element" class="pr-3">
        <span class="mr-2" *ngIf="element.living">Living</span>
        <span class="mr-2" *ngIf="element.learning">Learning</span>
        <span class="mr-2" *ngIf="element.working">Working</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="completed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="cell--buttons">Completed / Discontinued</th>
      <td mat-cell *matCellDef="let element" class="cell--buttons">
        <ng-container *ngIf="!element.status">
          <button
            mat-raised-button
            aria-label="Complete"
            type="button"
            class="mr-2"
            (click)="onCompleteDiscontinue(element, true)"
            [disabled]="disableModifications"
            tabindex="0"
          >
            Complete
          </button>
          <button
            mat-raised-button
            aria-label="Discontinue"
            type="button"
            (click)="onCompleteDiscontinue(element, false)"
            [disabled]="disableModifications"
            tabindex="0"
          >
            Discontinue
          </button>
        </ng-container>
        <ng-container *ngIf="element.status"> {{ element.status }} {{ element.statusDate | dateFormat }} </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr
      [hidden]="dataSource?.data?.length > 0"
      mat-footer-row
      *matFooterRowDef="dataSource?.data?.length === 0 ? ['noResults'] : []"
      class="example-second-footer-row"
    ></tr>
  </table>
</div>
