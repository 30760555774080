<!--suppress HtmlFormInputWithoutLabel -->
<div class="prefill" *ngIf="useOverwrite">
  <div class="prefill__input">
    <ng-container *ngTemplateOutlet="basic"></ng-container>
  </div>
  <div class="prefill__text" *ngIf="dataSourceValue !== null">
    QR:
    <span *ngIf="dataSourceValue !== true && dataSourceValue !== false">{{ sourceFieldShownLabel || dataSourceValue }}</span>
    <span *ngIf="dataSourceValue === true || dataSourceValue === false">{{ dataSourceValue === true ? 'Yes' : 'No' }}</span>
  </div>
  <div class="prefill__container" (click)="replaceWithSourceValue()" *ngIf="dataSourceValue !== null && !undoValue">
    <div class="prefill__icon">
      <mat-icon aria-label="QR Arrow Symbol">call_merge</mat-icon>
    </div>
  </div>
  <div class="prefill__container prefill__container--undo" *ngIf="undoValue">
    <p class="prefill__icon mb-0" (click)="undoReplaceWithSourceValue()">Undo</p>
  </div>
</div>

<ng-container *ngIf="!useOverwrite">
  <ng-container *ngTemplateOutlet="basic"></ng-container>
</ng-container>
<ng-template #basic>
  <mat-label
    [ngClass]="{
      asterisk_input: (control.hasError('required') || showAsterisk) && hideAsterisk === false
    }"
    >{{ label }}</mat-label
  >
  <div>
    <mat-checkbox
      [formControl]="checkAllControl"
      [ngClass]="{
        wrap: wrap,
        column: column
      }"
      class="select-all"
      *ngIf="showSelectAll"
      [checked]="selectAll"
      (change)="checkAll($event)"
      aria-labelledby="Select All"
      attr.aria-required="{{ (control.hasError('required') || showAsterisk) && hideAsterisk === false ? 'true' : 'false' }}"
      >Select all
    </mat-checkbox>
    <div [formGroup]="internalFormGroup">
      <mat-checkbox
        *ngFor="let control of groupArray; index as i"
        [formControl]="control"
        [ngClass]="{
          'checkbox-even': (i + 1) % 2 === 0,
          wrap: wrap,
          column: column
        }"
        attr.aria-label="{{ getLabel(i) }}"
        >{{ getLabel(i) }}</mat-checkbox
      >
    </div>
  </div>
  <mat-error *ngIf="internalFormGroup.touched">
    <app-field-error-display [control]="control"></app-field-error-display>
  </mat-error>
</ng-template>
