<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ settings.title }}</h3>

<div mat-dialog-content class="py-2">
  <form [formGroup]="formGroup">
    <mat-card class="my-0 card--plain card--shadow">
      <div class="row">
        <div class="col-md-6">
          <app-text-field label="Name" formControlName="name"></app-text-field>
        </div>
        <div class="col-md-6">
          <app-text-field label="Role / Title" formControlName="role"></app-text-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <app-text-field label="Email" formControlName="email"></app-text-field>
        </div>
        <div class="col-md-6" *ngIf="!data?.noPin">
          <app-text-field label="PIN" formControlName="pin"></app-text-field>
        </div>
      </div>
    </mat-card>

    <mat-card class="card-spacing-top mb-0 card--plain card--shadow">
      <fieldset>
        <app-radio-group
          label="Do you agree with above findings?"
          [options]="agreeDisagree"
          [wrap]="true"
          [column]="true"
          formControlName="agree"
        ></app-radio-group>
      </fieldset>
    </mat-card>
  </form>
</div>

<div mat-dialog-actions class="w-100 display-flex justify-content-end align-items-center">
  <button mat-button aria-label="Cancel Button" class="background-color--muted" (click)="onNoClick()" cdkFocusInitial tabindex="0">
    Cancel
  </button>
  <button
    mat-raised-button
    [attr.aria-label]="settings.button"
    color="primary"
    [disabled]="formGroup.invalid"
    (click)="onSubmit()"
    tabindex="0"
    aria-label="Add Signature"
  >
    {{ settings.button }}
  </button>
</div>
