<form [formGroup]="formGroup">
  <mat-card class="mb-3">
    <mat-card-title class="mb-3">Nutrition and Growth</mat-card-title>
    <div class="container container--not-centered container--form">
      <mat-label>Child’s weight at last primary care provider visit?</mat-label>

      <div class="row">
        <div class="col-md-4">
          <app-select
            [disabledState]="locked"
            [useOverwrite]="!isQuestionnaire"
            [options]="lastVisitWeightUnitsOptions"
            formControlName="lastVisitWeightUnits"
            (changeEvent)="weightTypeChanged()"
            label="Units"
            [sourceData]="questionnaire.healthInfo.nutritionGrowthInformation"
          ></app-select>
        </div>
        <ng-container [ngSwitch]="formGroup.get('lastVisitWeightUnits').value">
          <ng-template [ngSwitchCase]="'lbOz'">
            <div class="col-md-2">
              <app-number-field
                [disabledState]="locked"
                *ngIf="isQuestionnaire"
                label="lb"
                formControlName="lastVisitWeightInLb"
                id="lastVisitWeightInLb"
              ></app-number-field>
              <app-overwrite-number-field
                [disabledState]="locked"
                *ngIf="!isQuestionnaire"
                label="lb"
                formControlName="lastVisitWeightInLb"
                [sourceData]="questionnaire.healthInfo.nutritionGrowthInformation"
                id="lastVisitWeightInLb"
              ></app-overwrite-number-field>
              <mat-error *ngIf="formGroup.get('lastVisitWeightInLb').hasError('min')"> Value cannot be negative </mat-error>
            </div>
            <div class="col-md-2">
              <app-number-field
                [disabledState]="locked"
                *ngIf="isQuestionnaire"
                label="oz"
                formControlName="lastVisitWeightInOz"
                id="lastVisitWeightInOz"
              ></app-number-field>
              <app-overwrite-number-field
                [disabledState]="locked"
                *ngIf="!isQuestionnaire"
                label="oz"
                formControlName="lastVisitWeightInOz"
                [sourceData]="questionnaire.healthInfo.nutritionGrowthInformation"
                id="lastVisitWeightInOz"
              ></app-overwrite-number-field>
              <mat-error *ngIf="formGroup.get('lastVisitWeightInOz').hasError('min')"> Value cannot be negative </mat-error>
            </div>
          </ng-template>

          <ng-template [ngSwitchCase]="'g'">
            <div class="col-md-2">
              <app-number-field
                [disabledState]="locked"
                *ngIf="isQuestionnaire"
                label="g"
                formControlName="lastVisitWeightInGrams"
                id="lastVisitWeightInGrams"
              ></app-number-field>
              <app-overwrite-number-field
                [disabledState]="locked"
                *ngIf="!isQuestionnaire"
                label="g"
                formControlName="lastVisitWeightInGrams"
                [sourceData]="questionnaire.healthInfo.nutritionGrowthInformation"
                id="lastVisitWeightInGrams"
              ></app-overwrite-number-field>
              <mat-error *ngIf="formGroup.get('lastVisitWeightInGrams').hasError('min')"> Value cannot be negative </mat-error>
            </div>
          </ng-template>

          <ng-template [ngSwitchCase]="'kg'">
            <div class="col-md-2">
              <app-number-field
                [disabledState]="locked"
                *ngIf="isQuestionnaire"
                label="kg"
                formControlName="lastVisitWeightInKilograms"
                id="lastVisitWeightInKilograms"
              ></app-number-field>
              <app-overwrite-number-field
                [disabledState]="locked"
                *ngIf="!isQuestionnaire"
                label="kg"
                formControlName="lastVisitWeightInKilograms"
                [sourceData]="questionnaire.healthInfo.nutritionGrowthInformation"
                id="lastVisitWeightInKilograms"
              ></app-overwrite-number-field>
              <mat-error *ngIf="formGroup.get('lastVisitWeightInKilograms').hasError('min')"> Value cannot be negative </mat-error>
            </div>
          </ng-template>

          <ng-template *ngSwitchDefault> </ng-template>
        </ng-container>
      </div>

      <mat-label>Child’s height/length at last primary care provider visit?</mat-label>
      <div class="row">
        <div class="col-md-4">
          <app-select
            [disabledState]="locked"
            [useOverwrite]="!isQuestionnaire"
            [options]="birthLengthOptions"
            formControlName="lastVisitLengthUnits"
            (changeEvent)="lengthUnitsChanged()"
            label="Units"
            [sourceData]="questionnaire.healthInfo.nutritionGrowthInformation"
          ></app-select>
        </div>
        <div class="col-md-4" *ngIf="formGroup.get('lastVisitLengthUnits').value">
          <app-number-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            [label]="formGroup.get('lastVisitLengthUnits').value"
            formControlName="lastVisitLength"
            id="lastVisitLength"
          ></app-number-field>
          <app-overwrite-number-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            [label]="formGroup.get('lastVisitLengthUnits').value"
            formControlName="lastVisitLength"
            [sourceData]="questionnaire.healthInfo.nutritionGrowthInformation"
            id="lastVisitLength"
          ></app-overwrite-number-field>
          <mat-error *ngIf="formGroup.get('lastVisitLength').hasError('min')"> Value cannot be negative </mat-error>
        </div>
      </div>

      <div *ngIf="!isQuestionnaire">
        <hr class="input__divider" />

        <div class="row">
          <div class="col-lg-12">
            <label
              class="vertical-label display-block text-md"
              [ngClass]="{
                asterisk_input: formGroup.get('nutritionConcern').hasError('required')
              }"
            >
              Do you have any concerns about your child’s growth, eating, or
              <a
                (click)="onOpenHelp($event, helpSection.Intake, intakeHavingEnoughHelp.HavingEnough)"
                class="text-lower text-underline"
                tabindex="0"
                role="link"
              >
                {{ intakeHavingEnoughHelp.HavingEnough }}
              </a>
              to eat?
            </label>
            <fieldset>
              <app-radio-group
                [disabledState]="locked"
                [options]="yesNoOptions"
                [hideAsterisk]="true"
                formControlName="nutritionConcern"
              ></app-radio-group>
            </fieldset>
          </div>
        </div>
        <div class="row mb-4" *ngIf="formGroup.get('nutritionConcern').value">
          <div class="col-lg-12">
            <app-textarea [disabledState]="locked" label="Describe concern(s)" formControlName="nutritionConcernDescription"></app-textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 col-xl-4">
            <label
              class="vertical-label display-block text-md"
              [ngClass]="{
                asterisk_input: formGroup.get('peachCompleted').hasError('required')
              }"
            >
              <a
                (click)="onOpenHelp($event, helpSection.Intake, intakeNutritionGrowthHelp.Peach)"
                class="text-underline"
                role="link"
                tabindex="0"
                aria-required="true"
                attr.aria-label="{{ intakeNutritionGrowthHelp.Peach }}"
              >
                {{ intakeNutritionGrowthHelp.Peach }}</a
              >
              Completed
            </label>
            <fieldset>
              <app-radio-group
                [disabledState]="locked"
                [options]="yesNoOptions"
                [hideAsterisk]="true"
                formControlName="peachCompleted"
              ></app-radio-group>
            </fieldset>
          </div>
          <ng-container *ngIf="formGroup.get('peachCompleted').value">
            <div class="col-md-4">
              <app-number-field
                [disabledState]="locked"
                label="PEACH Score"
                formControlName="peachScore"
                (blurMe)="evaluatePeachScore()"
                id="peachScore"
              ></app-number-field>
            </div>
          </ng-container>
        </div>
        <div class="row mb-4" *ngIf="formGroup.get('peachCompleted').value !== null">
          <ng-container *ngIf="formGroup.get('peachCompleted').value">
            <div class="col-md-12">
              <app-textarea [disabledState]="locked" label="PEACH Summary" formControlName="peachSummary"></app-textarea>
            </div>
          </ng-container>

          <div class="col-md-12" *ngIf="formGroup.get('peachCompleted').value === false">
            <app-textarea [disabledState]="locked" label="Reason Not Completed" formControlName="peachNotCompletedReason"></app-textarea>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-8">
            <fieldset>
              <app-radio-group
                *ngIf="formGroup.get('peachScore').value >= 4"
                [disabledState]="locked"
                [options]="yesNoOptions"
                formControlName="agreeToRefer"
                label="Does the family agree to be referred to CHSC? - If unknown, leave blank"
              ></app-radio-group>
            </fieldset>
          </div>

          <div *ngIf="formGroup.get('agreeToRefer').value && formGroup.get('peachScore').value >= 4" class="col-md-4">
            <a
              mat-raised-button
              color="accent"
              href="mailto:"
              aria-label="Send Email"
              tabindex="0"
              class="button-background--yellow"
              target="_blank"
              >Send Email
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <app-textarea
              [disabledState]="locked"
              label="Additional Information"
              formControlName="nutritionAdditionalInformation"
            ></app-textarea>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</form>

<app-documentation-info-form
  *ngIf="!isQuestionnaire"
  [formGroup]="documentationInfo"
  [documents]="intake?.documentationInfo"
  [caseId]="intake?.caseId"
  [locked]="locked"
  [section]="intakeSections.Nutrition"
  [learnersName]="childsName"
  documentType="Nutrition"
  title="Relevant Form(s)"
  uploadCardTitle="Upload Completed Form"
  uploadButtonTitle="Upload Completed Form"
  [documentsToDisplay]="documentsToDisplay"
  (upload)="onUpload($event)"
  (deleteDocument)="onDeleteDocument($event)"
></app-documentation-info-form>
