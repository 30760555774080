<mat-label
  [ngClass]="{
    asterisk_input: (control.hasError('required') || showAsterisk) && hideAsterisk === false
  }"
  >{{ label }}</mat-label
>
<div>
  <mat-checkbox
    [formControl]="checkAllControl"
    [ngClass]="{
      wrap: wrap,
      column: column
    }"
    class="select-all"
    *ngIf="showSelectAll"
    [checked]="selectAll"
    (change)="checkAll($event)"
    aria-labelledby="Select All"
    attr.aria-required="{{ (control.hasError('required') || showAsterisk) && hideAsterisk === false ? 'true' : 'false' }}"
    >Select all</mat-checkbox
  >
  <div [formGroup]="internalFormGroup">
    <mat-checkbox
      *ngFor="let control of groupArray; index as i"
      [formControl]="control"
      [ngClass]="{
        'checkbox-even': (i + 1) % 2 === 0,
        wrap: wrap,
        column: column
      }"
      attr.aria-label="{{ getLabel(i) }}"
      >{{ getLabel(i) }}</mat-checkbox
    >
  </div>
</div>
<mat-error *ngIf="internalFormGroup?.touched">
  <app-field-error-display [control]="control"></app-field-error-display>
</mat-error>
