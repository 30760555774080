<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Start New Interim Plan</h3>

<div mat-dialog-content class="py-2">
  <mat-card class="my-0">
    <ng-container [formGroup]="formGroup">
      <div class="row">
        <div class="col-md-12">
          <fieldset>
            <app-radio-group
              label="Does the child have an obvious need for early intervention prior to completing a full evaluation?"
              formControlName="needForEarlyIntervention"
              [options]="yesNoOptions"
            ></app-radio-group>
          </fieldset>
        </div>

        <div class="col-md-12">
          <app-textarea formControlName="rationale" label="Rationale" [rows]="4"></app-textarea>
        </div>
      </div>
    </ng-container>
  </mat-card>
</div>

<div mat-dialog-actions align="end">
  <div class="display-flex" cdkFocusInitial>
    <button mat-raised-button aria-label="Cancel Button" class="background-color--muted mr-2" (click)="onCancel()" tabindex="0">
      Cancel
    </button>
    <button mat-raised-button aria-label="Complete" color="primary" (click)="onSubmit()" [disabled]="formGroup.invalid" tabindex="0">
      Complete
    </button>
  </div>
</div>
