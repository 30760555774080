<form [formGroup]="planStatusFormGroup" *ngIf="enableForms">
  <mat-card class="py-2">
    <div class="row">
      <div class="col-md-12 col-lg-4">
        <app-date-picker controlName="date" label="Date" [min]="minDate" [max]="today"></app-date-picker>
      </div>
      <div class="col-md-12 col-lg-4">
        <app-autocomplete-chips label="IFSP Outcomes" formControlName="outcomes" [options]="outcomeOptions"></app-autocomplete-chips>
      </div>
      <div class="col-md-12">
        <app-textarea label="How is the plan working?" formControlName="status" [rows]="2"></app-textarea>
      </div>
    </div>
  </mat-card>
  <div class="action__row card-spacing-bottom">
    <button mat-raised-button aria-label="Cancel Button" type="button" (click)="onPlanStatusCancel()" tabindex="0">Cancel</button>
    <button
      mat-raised-button
      aria-label="Complete"
      color="primary"
      [disabled]="planStatusFormGroup.invalid"
      (click)="onPlanStatusSubmit()"
      tabindex="0"
    >
      Complete
    </button>
  </div>
</form>

<mat-accordion class="accordion accordion--short" multi>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">How is the plan working?</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="table-overflow">
      <table mat-table class="w-100" [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more"></th>
          <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
            <ng-container *ngIf="!isEditing && enableForms">
              <button mat-icon-button aria-label="View Icon" color="primary" (click)="onViewMorePlanStatus(element)" tabindex="0">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
              <button mat-icon-button aria-label="Edit Icon" color="primary" (click)="onPlanStatusEdit(element)" tabindex="0">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button aria-label="Delete Icon" color="warn" (click)="onPlanStatusDelete(element)" tabindex="0">
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let element">
            <ng-container viewMode>
              {{ element.date | dateFormat }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>How is the plan working?</th>
          <td mat-cell *matCellDef="let element">
            <ng-container viewMode>
              {{ element.status | truncate : 150 }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="outcomes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Outcome(s)</th>
          <td mat-cell *matCellDef="let element">
            <ng-container viewMode>
              {{ getOutcomeNames(element.outcomes) }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No results...</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          [hidden]="dataSource?.data.length > 0"
          mat-footer-row
          *matFooterRowDef="dataSource?.data.length === 0 ? ['noResults'] : []"
        ></tr>
      </table>

      <mat-paginator [dataSource]="dataSource"></mat-paginator>
    </div>
  </mat-expansion-panel>
</mat-accordion>
