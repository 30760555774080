<!--suppress XmlDuplicatedId -->
<div class="prefill" *ngIf="useOverwrite">
  <div class="prefill__input">
    <ng-container *ngTemplateOutlet="basic"></ng-container>
  </div>
  <div class="prefill__text" *ngIf="dataSourceValue">QR: {{ dataSourceValue }}</div>
  <div class="prefill__container" (click)="replaceWithSourceValue()" *ngIf="dataSourceValue && !undoValue">
    <div class="prefill__icon">
      <mat-icon aria-label="QR Arrow Symbol">call_merge</mat-icon>
    </div>
  </div>
  <div class="prefill__container prefill__container--undo" *ngIf="undoValue">
    <p class="prefill__icon mb-0" (click)="undoReplaceWithSourceValue()">Undo</p>
  </div>
</div>
<ng-container *ngIf="!useOverwrite">
  <ng-container *ngTemplateOutlet="basic"></ng-container>
</ng-container>
<ng-template #basic>
  <mat-form-field class="w-100">
    <mat-label
      [ngClass]="{
        asterisk_input: control.hasError('required') || showAsterisk
      }"
      attr.aria-label="{{ (control.hasError('required') || showAsterisk) && 'Control is Required' }}"
      >{{ label }}</mat-label
    >
    <input
      matInput
      type="text"
      [class]="klass"
      id="{{ controlId }}"
      [formControl]="control"
      [placeholder]="placeholder"
      [attr.maxLength]="maxLength > 0 ? maxLength : null"
      [attr.readonly]="readOnly"
      [attr.sourceData]="sourceData"
      [attr.disabled]="disabledState ? true : null"
      [mask]="mask"
      (keydown)="internalKeydown($event)"
      (blur)="internalBlur($event)"
      attr.aria-required="{{ control.hasError('required') || showAsterisk ? 'true' : 'false' }}"
    />
    <span matPrefix *ngIf="useInputGroup">
      <ng-content select="[prepend]"></ng-content>
    </span>
    <span matSuffix *ngIf="useInputGroup">
      <ng-content select="[append]"></ng-content>
    </span>
    <mat-error>
      <app-field-error-display [control]="control"></app-field-error-display>
    </mat-error>
  </mat-form-field>
</ng-template>
