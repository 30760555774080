import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  AddFamilyMeetingParticipant,
  FamilyMeetingNotice,
  FamilyMeetingRead,
  FamilyMeetingUpdate,
  IntakeType,
  RescheduleReason,
} from 'src/app/shared/models/case';
import { MeetingStatus } from '../../shared/models/family-meeting-status';
import { MeetingAttendance, MeetingParticipantRead, MeetingParticipantUpdate } from './participants-list/meeting-participants';

@Injectable({
  providedIn: 'root',
})
export class FamilyMeetingService {
  private baseUrl = 'api/cases/';

  public hasPartBPurposeSelected = new Subject<boolean>();
  hasPartBPurposeSelected$ = this.hasPartBPurposeSelected.asObservable();

  public meetingPurposeIntakeType: IntakeType;

  constructor(private http: HttpClient) {}

  updateStatus(id: string, caseId: string, status: MeetingStatus, participants?: MeetingParticipantUpdate[]) {
    return this.http.post(`${this.baseUrl}${caseId}/family-meetings/status`, {
      id,
      status,
      participants,
    });
  }

  getMeetings(learnerId: string) {
    return this.http.get<FamilyMeetingRead[]>(`api/family-meetings/${learnerId}/meetings`);
  }

  getMeeting(caseId: string, meetingId: string) {
    return this.http.get<FamilyMeetingRead>(`${this.baseUrl}${caseId}/family-meetings/meeting/${meetingId}`);
  }

  rescheduleMeeting(caseId: string, rescheduledMeeting: FamilyMeetingUpdate) {
    return this.http.post<FamilyMeetingRead>(`${this.baseUrl}${caseId}/family-meetings/reschedule`, rescheduledMeeting);
  }

  getRescheduledReasons(caseId: string) {
    return this.http.get<RescheduleReason[]>(`${this.baseUrl}${caseId}/family-meetings/rescheduled-reasons`);
  }

  addParticipants(caseId: string, newParticipants: AddFamilyMeetingParticipant) {
    return this.http.post<FamilyMeetingRead>(`${this.baseUrl}${caseId}/family-meetings/add-new-participants`, newParticipants);
  }

  getMeetingNotices(caseId: string) {
    return this.http.get<FamilyMeetingNotice[]>(`${this.baseUrl}${caseId}/family-meetings/meeting-notice`);
  }

  deleteMeeting(caseId, meetingId: string) {
    return this.http.put(`${this.baseUrl}${caseId}/family-meetings/${meetingId}`, null);
  }

  getMeetingAttendances(caseId: string) {
    return this.http.get<MeetingAttendance[]>(`${this.baseUrl}${caseId}/family-meetings/meeting-attendances`);
  }

  updateAttendances(caseId: string, meetingId: string, updatedParticipants: MeetingParticipantUpdate[]) {
    return this.http.put<FamilyMeetingRead>(
      `${this.baseUrl}${caseId}/family-meetings/${meetingId}/update-attendances`,

      updatedParticipants
    );
  }

  updateExcusal(caseId: string, meetingId: string, participant: MeetingParticipantRead) {
    return this.http.put<MeetingParticipantRead>(
      `${this.baseUrl}${caseId}/family-meetings/${meetingId}/excusal/${participant.id}`,
      participant
    );
  }
}
