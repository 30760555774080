import { AfterViewInit, ContentChild, Directive, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';

import { EditViewDirective } from './edit-view.directive';
import { ReadOnlyViewDirective } from './read-only-view.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[doubleView]',
})
export class DoubleViewDirective implements OnChanges, AfterViewInit {
  @ContentChild(EditViewDirective, { static: false })
  private editViewDirective: EditViewDirective;
  @ContentChild(ReadOnlyViewDirective, { static: false })
  private readonlyView: ReadOnlyViewDirective;

  @Input() public readOnly = false;
  @Input() public context: any;

  constructor(private readonly viewContainerRef: ViewContainerRef) {}

  ngOnChanges(changes: SimpleChanges) {
    this.refreshView(this.readOnly);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.refreshView(this.readOnly), 0);
  }

  private refreshView(readOnly: boolean): void {
    this.viewContainerRef.clear();
    const template = readOnly ? this.readonlyView?.template : this.editViewDirective?.template;
    if (template) {
      const ctx = this.context && typeof this.context === 'object' ? this.context : { $implicit: this.context };
      this.viewContainerRef.createEmbeddedView(template, ctx);
    }
  }
}
