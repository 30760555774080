import { Component, Input, OnInit } from '@angular/core';

export type AlertConfig = {
  status: 'info' | 'warning' | 'success';
  title?: string;
  message: string;
  showRefreshMessage?: boolean;
};

@Component({
  selector: 'app-page-alert',
  templateUrl: './page-alert.component.html',
  styleUrls: ['./page-alert.component.scss'],
})
export class PageAlertComponent implements OnInit {
  @Input() alert: AlertConfig;
  constructor() {}

  ngOnInit(): void {}
}
