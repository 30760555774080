import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { OutcomeService } from '../../services/outcome.service';

@Component({
  selector: 'app-ifsp-outcome-info',
  templateUrl: './ifsp-outcome-info.component.html',
  styleUrls: ['./ifsp-outcome-info.component.scss'],
})
export class IfspOutcomeInfoComponent implements OnInit {
  shortDateFormat = shortDateFormat;
  ifspId: string;
  outcomeId: string;
  outcome: any;
  displayedColumns = ['date', 'changes'];
  dataSource = new MatTableDataSource([]);

  constructor(
    public ifspOutcomeService: OutcomeService,
    private dialogRef: MatDialogRef<IfspOutcomeInfoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { outcome: any; id: string; ifspId: string }
  ) {
    this.outcome = data.outcome;
    this.outcomeId = data.id;
    this.ifspId = data.ifspId;
  }

  ngOnInit(): void {
    this.ifspOutcomeService.getAuditLogs(this.ifspId, this.outcomeId).subscribe((result) => {
      this.dataSource.data = result;
    });
  }

  dismiss() {
    this.dialogRef.close();
  }
}
