<div class="container container--not-centered container--form">
  <app-document-upload-button
    [buttonText]="buttonText"
    [allowedFileTypes]="allowedFileTypes"
    [documentationTypes]="documentationTypes"
    (uploaded)="onUploaded($event)"
    *ngIf="(!singleFile || !documents?.length) && !disableUpload"
  ></app-document-upload-button>

  <app-document-upload-list
    [documents]="documents"
    [canDelete]="!disableDelete"
    (deleted)="onDeleted($event)"
    [requestFiles]="requestFiles"
    (fileRequested)="fileRequested.emit($event)"
  ></app-document-upload-list>
</div>
