import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MedicaidReportsComponent } from '../components/medicaid-reports/medicaid-reports.component';

@Injectable({
  providedIn: 'root',
})
export class MedicaidReportModalService {
  constructor(private dialog: MatDialog) {}

  openReportModal() {
    const dialogRef = this.dialog.open(MedicaidReportsComponent, {
      autoFocus: false,
      hasBackdrop: false,
      width: '600px',
    });
  }
}
