import { Component, OnInit, Input } from '@angular/core';
import { IfspService } from '../../../services/ifsp.service';
import { MatTableDataSource } from '@angular/material/table';
import { IfspServicesService } from '../../../services/ifsp-service.service';

@Component({
  selector: 'app-ifsp-setting',
  templateUrl: './ifsp-setting.component.html',
})
export class IfspSettingComponent implements OnInit {
  @Input() ifspId: string;
  overallPrimarySetting: string;
  overallPrimarySettingPrevious: string;
  dataSource: MatTableDataSource<any>;
  dataSourcePrevious: MatTableDataSource<any>;
  displayedColumns = ['naturalEnvironment', 'nonNaturalEnvironment'];
  pieChart = {
    data: [],
    labels: ['Natural Environment', 'Non-Natural Environment'],
    colors: [
      {
        backgroundColor: ['rgb(38, 63, 140, 0.2)', '#F5D68E', 'rgba(41, 99, 28, 0.3)'],
      },
    ],
    legend: true,
  };
  pieChartPrevious = {
    data: [],
    labels: ['Natural Environment', 'Non-Natural Environment'],
    colors: [
      {
        backgroundColor: ['rgb(38, 63, 140, 0.2)', '#F5D68E', 'rgba(41, 99, 28, 0.3)'],
      },
    ],
    legend: true,
  };
  @Input() modificationId: string;
  testMode = false;
  nonNaturalEnvironment: number;
  naturalEnvironment: number;

  constructor(private ifspService: IfspService, private ifspServicesService: IfspServicesService) {}

  async ngOnInit() {
    this.loadData();
    this.ifspServicesService.servicesUpdated$.subscribe(() => {
      this.loadData(true);
    });
  }

  async getTestData(isPrevious = false) {
    if (isPrevious) {
      await this.getPreviousSetting();
    } else {
      await this.getCurrentSetting();
    }
  }

  async loadData(isUpdate = false) {
    await this.getCurrentSetting();
    if (isUpdate) {
      this.ifspService
        .updateOverallPrimarySetting(this.ifspId, {
          modificationId: this.modificationId,
          naturalEnvironment: this.naturalEnvironment,
          nonNaturalEnvironment: this.nonNaturalEnvironment,
        })
        .subscribe(() => {});
    }
    if (!!this.modificationId) {
      await this.getPreviousSetting();
    }
  }

  async getCurrentSetting() {
    const setting = await this.getOverallPrimarySetting(this.modificationId ?? '');
    this.dataSource = new MatTableDataSource([
      {
        naturalEnvironment: setting.naturalEnvironment + '%',
        nonNaturalEnvironment: setting.nonNaturalEnvironment + '%',
      },
    ]);

    this.naturalEnvironment = setting.naturalEnvironment;
    this.nonNaturalEnvironment = setting.nonNaturalEnvironment;

    this.pieChart.data = [];
    this.pieChart.data.push(setting.naturalEnvironment || 0);
    this.pieChart.data.push(setting.nonNaturalEnvironment || 0);
    this.overallPrimarySetting = setting.overallPrimarySetting || '-';
  }

  async getPreviousSetting() {
    const settingPrevious = await this.getOverallPrimarySetting('');
    this.dataSourcePrevious = new MatTableDataSource([
      {
        naturalEnvironment: settingPrevious.naturalEnvironment + '%',
        nonNaturalEnvironment: settingPrevious.nonNaturalEnvironment + '%',
      },
    ]);
    this.pieChartPrevious.data = [];
    this.pieChartPrevious.data.push(settingPrevious.naturalEnvironment || 0);
    this.pieChartPrevious.data.push(settingPrevious.nonNaturalEnvironment || 0);
    this.overallPrimarySettingPrevious = settingPrevious.overallPrimarySetting || '-';
  }

  async getOverallPrimarySetting(modificationId) {
    return await this.ifspService.getOverallPrimarySetting(this.ifspId, modificationId).toPromise();
  }
}
