import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { Aea } from 'src/app/shared/models/aea';
import { Building } from 'src/app/shared/models/building';
import {
  CaseSummary,
  CaseUserRole,
  CaseUserSummary,
  IntakeType,
  ScheduledCaseTransferRead,
  ScheduledCaseTransferUpdate,
} from 'src/app/shared/models/case';
import { District } from 'src/app/shared/models/district';
import { ServiceCoordinator } from 'src/app/shared/models/service-coordinator';
import { User } from 'src/app/shared/models/user';
import { DateToUtcPipe } from 'src/app/shared/pipes/date-transform.pipe';
import { AeaService } from 'src/app/shared/services/aea/aea.service';
import { BuildingService } from 'src/app/shared/services/building/building.service';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { DistrictService } from 'src/app/shared/services/district/district.service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-transfer-case',
  templateUrl: './transfer-case.component.html',
  styleUrls: ['./transfer-case.component.scss'],
})
export class TransferCaseComponent implements OnInit {
  caseId: string;
  @ViewChild('notifyFamilyCheckbox') notifyFamilyCheckbox: MatCheckbox;
  currentCase: CaseSummary;
  aeas: Array<Aea>;
  aeaUsers: Array<User>;
  buildings: Array<Building>;
  serviceCoordinators: Array<ServiceCoordinator>;
  dmpsUser: User;
  chscUser: User;
  currentServiceCoordinator: CaseUserSummary;
  selectedAeaUser: User;
  selectedServiceCoordinator: ServiceCoordinator;
  scTransferDate = new Date();
  scheduledTransfer: ScheduledCaseTransferRead;
  districts: Array<District>;
  formGroup = this.fb.group({
    transferDate: [new Date(), Validators.required],
  });
  areYouSureTransfer = false;
  shortDateFormat = shortDateFormat;
  activeCall = false;

  orderBy = 'FullName';
  orderByDescending = false;
  searchCriteria = '';
  currentPage = 0;
  pageSize = 20;
  totalRows = 0;

  get intakeType() {
    return IntakeType;
  }

  get bothSelectedError() {
    return !!this.selectedAeaUser && !!this.selectedServiceCoordinator;
  }

  get dmpsChscTransferDisabled() {
    if (!!this.currentCase && this.currentCase.intakeType !== IntakeType.PartC) {
      return true;
    }
    return this.authService.isDmpsAdmin || this.authService.isDmpsUser || this.authService.isChscUser;
  }

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private caseService: CaseService,
    private userService: UserService,
    private aeaService: AeaService,
    private districtService: DistrictService,
    private buildingService: BuildingService,
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.caseId = data;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    forkJoin([
      this.caseService.getCaseSummary(this.caseId),
      this.userService.getDefaultDmpsCaseUser().pipe(
        catchError(() => {
          return of(undefined);
        })
      ),
      this.userService.getDefaultChscCaseUser().pipe(
        catchError(() => {
          return of(undefined);
        })
      ),
      this.districtService.getDistricts(),
      this.buildingService.getAllBuildings(),
    ]).subscribe(([caseModel, dmpsUser, chscUser, districts, buildings]) => {
      this.currentCase = caseModel;

      if (this.currentCase.intakeType === IntakeType.PartB) {
        this.aeaService.getPublicAeas().subscribe((aeas) => (this.aeas = aeas));
      } else {
        this.aeaService.getAllAeas().subscribe((aeas) => (this.aeas = aeas));
      }

      if (caseModel.scheduledTransfers.length > 0) {
        this.scheduledTransfer = caseModel.scheduledTransfers[0];
      }

      this.dmpsUser = dmpsUser;
      this.chscUser = chscUser;
      this.districts = districts;
      this.buildings = buildings;
      this.setCurrentUsers();
    });
  }

  setCurrentUsers() {
    this.currentServiceCoordinator = this.currentCase.caseUsers.find((x) => x.role === CaseUserRole.ServiceCoordinator && x.isOwner);
  }

  onSearch(formGroup: FormGroup) {
    this.userService.searchUsers(formGroup.value).subscribe((res) => {
      this.aeaUsers = res.filter(
        (x) =>
          !this.currentCase.caseUsers
            .filter((x) => x.isOwner)
            .map((x) => x.id)
            .includes(x.id)
      );
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  onAeaSelectionChanged(selectedAeas: Array<User>) {
    // This will always be a single selection
    this.selectedAeaUser = selectedAeas[0];
  }

  onServiceCoordinatorSelectionChanged(selectedServiceCoordinator: ServiceCoordinator[]) {
    this.selectedServiceCoordinator = selectedServiceCoordinator[0];
  }

  private doTransfer(id: string, role: CaseUserRole) {
    this.activeCall = true;
    this.formGroup.markAllAsTouched();

    const transferDate = new DateToUtcPipe().transform(new Date(this.formGroup.get('transferDate')?.value));

    const scheduledTransfer: ScheduledCaseTransferUpdate = {
      userId: id,
      role,
      date: transferDate,
    };
    this.caseService.scheduleTransfer(this.currentCase.id, scheduledTransfer).subscribe(() => {
      this.activeCall = false;
      this.dialogRef.close(this.currentCase);
      if (transferDate.toDateString() === new Date().toDateString()) {
        this.router.navigate(['/']);
      }
    });
  }

  onTransfer() {
    if (!!this.selectedServiceCoordinator) {
      this.doTransfer(this.selectedServiceCoordinator.id, CaseUserRole.ServiceCoordinator);
    } else {
      this.doTransfer(this.selectedAeaUser.id, CaseUserRole.Aea);
    }
  }

  clickedTransferToDMPS() {
    this.areYouSureTransfer = true;
  }

  cancelTransferToDMPS() {
    this.areYouSureTransfer = false;
  }

  beginTransferToDMPS() {
    this.doTransfer(this.dmpsUser.id, CaseUserRole.Aea);
    this.areYouSureTransfer = false;
  }
}
