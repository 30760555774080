import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LookupBase } from '../../shared/models/lookup-base';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EvaluationStatusService {
  private baseUrl = 'api/evaluationStatus';

  constructor(private http: HttpClient) {}

  get(): Observable<LookupBase[]> {
    return this.http.get<LookupBase[]>(this.baseUrl);
  }
}
