import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { RegisterModel } from '../../auth-models';

const passwordsMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password').value;
  const confirmedPassword = control.get('confirmedPassword').value;

  return password !== confirmedPassword ? { passwordMismatch: true } : null;
};

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent implements OnInit {
  @Input() registerError: string;
  @Output() submitted = new EventEmitter<RegisterModel>();

  registerForm = this.fb.group(
    {
      email: [
        'test1@example.com',
        {
          validators: [Validators.required, Validators.email],
        },
      ],
      firstName: ['Test', { validators: [Validators.required] }],
      lastName: ['User1', { validators: [Validators.required] }],
      password: ['Password1!', { validators: [Validators.required] }],
      confirmedPassword: ['Password1!', { validators: [Validators.required] }],
      rememberMe: false,
    },
    { updateOn: 'blur', validators: passwordsMatchValidator }
  );

  get email() {
    return this.registerForm.get('email');
  }

  get password() {
    return this.registerForm.get('password');
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit() {}

  onSubmit() {
    this.registerForm.markAllAsTouched();
    this.registerForm.updateValueAndValidity();

    if (this.registerForm.valid) {
      this.submitted.emit(this.registerForm.value);
    }
  }

  getErrorMessage() {
    return this.email.hasError('required')
      ? 'You must enter a value'
      : this.email.hasError('email')
      ? 'Not a valid email'
      : this.registerForm.hasError('passwordMismatch')
      ? 'Passwords must match'
      : '';
  }
}
