<form [formGroup]="filterFormGroup">
  <app-help-directional-text>
    <p class="mb-2">
      Use the filter feature to narrow your search for a specific goal. You may filter by goal area or use a keyword to search each annual
      measurable goal statement.
    </p>
  </app-help-directional-text>
  <h3 class="text-primary mb-2">Filter By</h3>
  <mat-card class="card-spacing-bottom py-2 card--plain">
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <app-autocomplete label="Goal Area" formControlName="goalArea" [options]="goalAreas"></app-autocomplete>
      </div>
      <div class="col-md-12 col-lg-6">
        <app-text-field label="Search" formControlName="search"></app-text-field>
      </div>
    </div>
  </mat-card>
  <p *ngIf="!isPortalUser" class="card-spacing-top">Click on the accordion(s) to record progress for each goal.</p>
</form>

<app-help-directional-text>
  <p class="mb-2">Click below to view progress monitoring data.</p>
</app-help-directional-text>

<ng-container *ngIf="filteredGoals.length > 0">
  <mat-accordion class="accordion accordion--short" multi *ngFor="let goal of filteredGoals">
    <mat-expansion-panel class="mat-elevation-z0" [id]="goal.id">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="my-0 text-normal">{{ goal.name }}</h3></mat-panel-title
        >
      </mat-expansion-panel-header>

      <app-add-goal-progress
        *ngIf="!goal.isCombinedObjective && enableForms && !goal.isEnded"
        [goal]="goal"
        [minDate]="minDate"
        (submitEvent)="onAddProgressData($event, goal)"
        (phaseLineSubmitEvent)="onPhaseLineAdd($event, goal)"
        (commentSubmitEvent)="onCommentAdd($event, goal)"
      ></app-add-goal-progress>

      <app-add-combined-objective-progress
        *ngIf="goal.isCombinedObjective && enableForms && !goal.isEnded"
        [goal]="goal"
        [minDate]="minDate"
        (goalSubmitEvent)="onAddProgressData($event, goal)"
        (objectiveSubmitEvent)="onAddCombinedProgressData($event, goal)"
        (phaseLineSubmitEvent)="onPhaseLineAdd($event, goal)"
        (commentSubmitEvent)="onCommentAdd($event, goal)"
      ></app-add-combined-objective-progress>

      <mat-expansion-panel class="mat-elevation-z0" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title><h3 class="my-0 text-normal">Previously Entered Data and Graph</h3></mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container *ngIf="!goal.secondaryMeasurement">
          <app-progress-data-table-b
            [enableForms]="enableForms && !goal.isEnded"
            [caseSummary]="caseSummary"
            [goal]="goal"
            [minDate]="minDate"
            (progressMonitorItemMultipleDeleteEvent)="getCombinedProgressDataSource(goal)"
          ></app-progress-data-table-b>
        </ng-container>

        <ng-container *ngIf="goal.secondaryMeasurement">
          <app-combined-progress-data-table-b
            [enableForms]="enableForms && !goal.isEnded"
            [caseSummary]="caseSummary"
            [goal]="goal"
            [minDate]="minDate"
          ></app-combined-progress-data-table-b>
        </ng-container>

        <div class="row">
          <div class="col-md-12 col-lg-9 col-xl-9 chart-viewer">
            <app-chart-viewer *ngIf="!goal.isCombinedObjective" [params]="{ IepGoalId: goal.id }" [graph]="'iep_graph'"></app-chart-viewer>
            <app-chart-viewer
              *ngIf="goal.isCombinedObjective"
              [params]="{ IepGoalId: goal.id }"
              [graph]="'iep_goal_combined'"
            ></app-chart-viewer>
          </div>
        </div>
        <div class="row" *ngIf="goal.secondaryMeasurement">
          <div class="col-md-12 col-lg-9 col-xl-9 chart-viewer">
            <app-chart-viewer
              [params]="{
                IepGoalQuantifiableMeasurementId: goal.secondaryMeasurement.iepGoalQuantifiableMeasurementId,
                IepGoalId: goal.id
              }"
              [graph]="'iep_objective'"
            ></app-chart-viewer>
          </div>
        </div>
        <mat-expansion-panel class="mat-elevation-z0 mt-2">
          <mat-expansion-panel-header>
            <mat-panel-title><h3 class="my-0 text-normal">Phase Lines</h3></mat-panel-title>
          </mat-expansion-panel-header>
          <app-phase-line-table
            [enableForms]="enableForms && !goal.isEnded"
            [caseSummary]="caseSummary"
            [criterion]="goal"
            [minDate]="minDate"
          ></app-phase-line-table>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title><h3 class="my-0 text-normal">Comments</h3></mat-panel-title>
          </mat-expansion-panel-header>
          <app-comment-table
            [enableForms]="enableForms && !goal.isEnded"
            [caseSummary]="caseSummary"
            [criterion]="goal"
            [minDate]="minDate"
          ></app-comment-table>
        </mat-expansion-panel>

        <div class="action__row my-0 mt-2">
          <p *ngIf="!isPortalUser" class="mr-4 pt-2">Enter progress on each goal before reporting progress to the family.</p>
          <button
            mat-raised-button
            aria-label="Enter Progress and Report to Family"
            color="accent"
            *ngIf="enableForms && !goal.isEnded"
            (click)="onReportProgressToFamily()"
            tabindex="0"
          >
            Enter Progress and Report to Family
          </button>
        </div>
      </mat-expansion-panel>

      <h3 class="mt-0 mb-2" *ngIf="goal?.objectives?.length">Objectives</h3>
      <mat-expansion-panel *ngFor="let objective of goal.objectives" [id]="objective.id" class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="display-flex flex-1 flex-wrap justify-content-between align-items-center">
              <h3 class="my-0 text-normal">{{ objective.name }}</h3>
              <span style="font-size: medium"> Status: {{ objective.status | titlecase }} </span>
            </div></mat-panel-title
          >
        </mat-expansion-panel-header>

        <app-add-goal-progress
          *ngIf="objective && !goal.isCombinedObjective && enableForms && !goal.isEnded"
          [goal]="objective"
          [minDate]="minDate"
          (submitEvent)="onAddProgressData($event, objective)"
          (phaseLineSubmitEvent)="onPhaseLineAdd($event, objective)"
          (commentSubmitEvent)="onCommentAdd($event, objective)"
          (statusChangeEvent)="onStatusChangeEvent($event, objective)"
        ></app-add-goal-progress>

        <mat-expansion-panel class="mat-elevation-z0 mt-2 mb-0" expanded>
          <mat-expansion-panel-header>
            <mat-panel-title><h3 class="my-0 text-normal">Previously Entered Data and Graph</h3></mat-panel-title>
          </mat-expansion-panel-header>

          <app-progress-data-table-b
            [caseSummary]="caseSummary"
            [goal]="objective"
            [minDate]="minDate"
            [enableForms]="enableForms && !goal.isEnded && filterForActiveObjectives(objective)"
            (progressMonitorItemUpdateEvent)="getCombinedProgressDataSource(goal)"
          ></app-progress-data-table-b>

          <div class="row">
            <div class="col-md-12 col-lg-9 col-xl-9 chart-viewer">
              <app-chart-viewer
                *ngIf="goal.isCombinedObjective"
                [params]="{
                  IepGoalQuantifiableMeasurementId: objective.primaryMeasurement.iepGoalQuantifiableMeasurementId,
                  IepGoalId: goal.id
                }"
                [graph]="'combined_iep_objective'"
              ></app-chart-viewer>

              <app-chart-viewer
                *ngIf="!goal.isCombinedObjective"
                [params]="{
                  IepGoalQuantifiableMeasurementId: objective.primaryMeasurement.iepGoalQuantifiableMeasurementId
                }"
                [graph]="'iep_objective'"
              ></app-chart-viewer>
            </div>
          </div>

          <mat-expansion-panel class="mat-elevation-z0 mt-2" *ngIf="!goal.isCombinedObjective">
            <mat-expansion-panel-header>
              <mat-panel-title><h3 class="my-0 text-normal">Phase Lines</h3></mat-panel-title>
            </mat-expansion-panel-header>
            <app-phase-line-table
              [caseSummary]="caseSummary"
              [criterion]="objective"
              [minDate]="minDate"
              [enableForms]="enableForms && !goal.isEnded && filterForActiveObjectives(objective)"
            ></app-phase-line-table>
          </mat-expansion-panel>

          <mat-expansion-panel class="mat-elevation-z0" *ngIf="!goal.isCombinedObjective">
            <mat-expansion-panel-header>
              <mat-panel-title><h3 class="my-0 text-normal">Comments</h3></mat-panel-title>
            </mat-expansion-panel-header>
            <app-comment-table
              [caseSummary]="caseSummary"
              [criterion]="objective"
              [minDate]="minDate"
              [enableForms]="enableForms && !goal.isEnded && filterForActiveObjectives(objective)"
            ></app-comment-table>
          </mat-expansion-panel>

          <div class="action__row my-0">
            <p *ngIf="!isPortalUser" class="mr-4 pt-2">Enter progress on each goal before reporting progress to the family.</p>
            <button
              mat-raised-button
              aria-label="Enter Progress and Report to Family"
              color="accent"
              *ngIf="enableForms && !goal.isEnded"
              (click)="onReportProgressToFamily()"
              tabindex="0"
            >
              Enter Progress and Report to Family
            </button>
          </div>
        </mat-expansion-panel>
      </mat-expansion-panel>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

<ng-container>
  <app-reporting-progress-to-family [learnerId]="caseSummary.learnerId" [enableForms]="enableForms"></app-reporting-progress-to-family>
</ng-container>

<div #reporting></div>
