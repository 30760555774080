import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { PseActivity } from '../models/pse-activity';

@Injectable({
  providedIn: 'root',
})
export class PseActivityService {
  constructor(private http: HttpClient, private spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/.*\/secondary-transitions.*/));
  }

  get(iepId: string, plaafpId: string, secondaryTransitionId: string) {
    return this.http.get(`api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/pse-activities`);
  }

  update(iepId: string, plaafpId: string, secondaryTransitionId: string, dto: PseActivity) {
    return this.http.put(
      `api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/pse-activities/${dto.id}`,
      dto
    );
  }
}
