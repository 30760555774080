<section class="not-found">
  <div class="not-found__container">
    <main class="not-found__content">
      <div class="display-flex flex-column justify-content-between align-items-center">
        <section class="not-found__info">
          <h2 class="mt-0">Health Check - {{ currentTime.myDateTime }}</h2>
        </section>
      </div>
    </main>
  </div>
</section>
