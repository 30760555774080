<form class="container" [formGroup]="formGroup" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Edit To-Do Item</h3>

  <div mat-dialog-content></div>

  <div class="row mt-5">
    <div class="col-lg-12">
      <app-text-field
        label="Description"
        placeholder="Description"
        formControlName="title"
        attr.aria-required="{{ formGroup.controls.title.hasError('required') ? 'true' : 'false' }}"
      ></app-text-field>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-8">
      <app-date-picker label="Due Date" controlName="dueDate" [min]="today"></app-date-picker>
    </div>
    <div class="col-lg-4 display-flex direction-column justify-content-center">
      <app-checkbox-single
        formControlName="showOnCalendar"
        aria-labelledby="Check Show on calendar"
        label="Show on calendar"
      ></app-checkbox-single>
    </div>
  </div>
  <div mat-dialog-action class="display-flex justify-content-end align-items-center mt-4">
    <button
      mat-raised-button
      aria-label="Cancel Button"
      class="background-color--muted mr-2"
      (click)="onClose()"
      type="button"
      cdkFocusInitial
      tabindex="0"
    >
      Cancel
    </button>
    <button mat-raised-button aria-label="Update Button" class="mr-2" color="primary" tabindex="0">Update</button>
  </div>
</form>
