<div class="display-flex justify-content-between align-items-center">
  <div *ngIf="domainOptions?.length === 0">
    <p class="my-0">
      The evaluation facilitator has not yet assigned you any domains to evaluate. Please contact your evaluation facilitator with any
      questions or concerns.
    </p>
  </div>
  <mat-button-toggle-group multiple="true" aria-labelledby="Domains" [disabled]="disableDomainSelector" aria-labelledby="Select an option">
    <mat-button-toggle
      *ngFor="let domain of domainOptions"
      [value]="domain"
      [checked]="getDomainChecked(domain)"
      (click)="toggleDomain(domain)"
      attr.aria-label="{{ domain.label }}"
    >
      {{ domain.label }}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <ng-content></ng-content>
</div>
