import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IfspService } from 'src/app/ifsp/services/ifsp.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PdfOutputs, ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { NewWindowConfig, openPdfWindow, openNewWindow } from 'src/app/shared/windowHelpers';
import { AuthService } from '../../../../auth/auth.service';
import { AppPermissions } from '../../../../permissions';
import { LearnerSummary } from '../../../../shared/models/learner';
import { RoutingService } from '../../../../shared/services/routing.service';

@Component({
  selector: 'app-ifsp-reports',
  templateUrl: './ifsp-reports.component.html',
  styleUrls: ['./ifsp-reports.component.scss'],
})
export class IfspReportsComponent implements OnInit {
  @Input() learner: LearnerSummary;
  @Input() canFinalize: boolean;
  @Input() isCaseOwner: boolean;
  @Input() finalized: boolean;
  @Input() pwnId: string;
  @Input() learnerId: string;
  @Input() documentId: string;
  @Input() showLockWarning: boolean;

  pdfOutputs = PdfOutputs;
  caseId: string;
  ifspId: string;
  activeCall = false;
  canFinalizeIfsp = false;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private ifspService: IfspService,
    private notificationService: NotificationService,
    private readonly reportingService: ReportingService,
    private readonly routingService: RoutingService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.caseId = this.route.snapshot.parent.paramMap.get('caseId');
    this.ifspId = this.route.parent.snapshot.paramMap.get('ifspId');
    this.canFinalizeIfsp =
      this.authService.isSuperAdmin ||
      (this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.FinalizeIFSP) && this.isCaseOwner);
  }

  printOutcomesServices() {
    const config: NewWindowConfig = {
      path: `cases/${this.caseId}/ifsp/${this.ifspId}/services-outcomes/report`,
      popup: true,
      isPreview: true,
      width: '1450px',
    };
    openNewWindow(config);
  }

  openPWNPopUp() {
    const config: NewWindowConfig = {
      path: `cases/${this.caseId}/pwns${this.pwnId ? '/' + this.pwnId : ''}`,
      popup: true,
    };
    openNewWindow(config);
  }

  finalizeIfsp() {
    let question = 'Are you sure you want to finalize? This action cannot be undone.';
    if (this.showLockWarning) {
      question = question.concat(
        '\n\nFinalizing this IFSP will remove the ability to update any data for the learner in the legacy (WebIEP/IFSP) system. '
      );
    }
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion: question,
      },
    });
    dialogRef.afterClosed().subscribe((yesImSure) => {
      if (yesImSure) {
        this.activeCall = true;
        this.ifspService.finalize(this.ifspId).subscribe(
          () => {
            this.notificationService.success('IFSP Finalized');
            this.onCreateOutput();
          },
          (error) => console.log(error)
        );
      }
    });
  }

  onCreateOutput() {
    this.reportingService.createIfspOutput(this.ifspId, true).subscribe({
      next: (documentId: string) => openPdfWindow(this.learnerId, documentId),
      error: (err) => this.reportingService.handleOutputError(err),
      complete: () => this.routingService.ifspList(this.learnerId),
    });
  }
}
