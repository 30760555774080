import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MeetingRollCallComponent } from 'src/app/shared/components/meeting-roll-call/meeting-roll-call.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { CaseSummaryByIfspId, FamilyMeetingRead } from 'src/app/shared/models/case';
import { MeetingStatus } from 'src/app/shared/models/family-meeting-status';

@Component({
  selector: 'app-ifsp-meeting',
  templateUrl: './ifsp-meeting.component.html',
  styleUrls: ['./ifsp-meeting.component.scss'],
})
export class IfspMeetingComponent implements OnInit, OnChanges {
  @Input() ifspMeetings: FamilyMeetingRead[];
  @Input() ifspCaseSummary: CaseSummaryByIfspId;
  @Output() refreshMeetings = new EventEmitter();

  meetingDataSource: MatTableDataSource<FamilyMeetingRead>;
  displayedColumns = ['purpose', 'location', 'date', 'status', 'rollCall'];
  displayFooter: boolean;

  meetingStatus = MeetingStatus;
  shortDateFormat = shortDateFormat;
  today: Date = new Date();

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.setUpTableData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ifspMeetings?.currentValue !== changes.ifspMeetings?.previousValue) {
      this.meetingDataSource = new MatTableDataSource<FamilyMeetingRead>(this.ifspMeetings);
    }
  }

  getPurposesOfMeeting(purposes: string[]) {
    return purposes.join(', ');
  }

  isDateCurrentOrPast(meetingDate: string) {
    this.today.setHours(23, 59, 59, 999); // Set's date to 11:59pm tonight
    return new Date(meetingDate) <= this.today;
  }

  openRollCallModal(meeting: FamilyMeetingRead) {
    const dialogRef = this.dialog.open(MeetingRollCallComponent, {
      width: '1100px',
      data: {
        meeting,
        caseSummary: this.ifspCaseSummary,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refreshMeetings.emit();
    });
  }

  private setUpTableData() {
    this.meetingDataSource = new MatTableDataSource<FamilyMeetingRead>(this.ifspMeetings);
  }
}
