import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';

import { Observable } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'table[mat-table][matSort]',
})
export class TableWithSortDirective<T> implements OnChanges {
  @Input() dataSource: DataSource<T> | Observable<ReadonlyArray<T> | T[]> | ReadonlyArray<T> | T[];

  constructor(private matSort: MatSort) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource && changes.dataSource.currentValue instanceof MatTableDataSource) {
      changes.dataSource.currentValue.sort = this.matSort;
    }
  }
}
