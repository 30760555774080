import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin } from 'rxjs';
import { EcoArea, EcoAreaType } from 'src/app/evaluation/models/evaluation';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { CaseSummary, FamilyMeetingRead } from 'src/app/shared/models/case';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { EcoAreaService } from 'src/app/shared/services/eco-areas/eco-area.service';
import { PdfOutputs } from 'src/app/shared/services/reporting/reporting.service';
import { EcoRatingType } from '../../../evaluation/evaluation/steps/eco-wizard/eco-rating';
import { EcoRatingService } from '../../../evaluation/evaluation/steps/eco-wizard/eco-rating.service';
import { EcoWizardData } from '../../../evaluation/evaluation/steps/eco-wizard/eco-wizard.component';
import { EcoMatrixModalComponent } from '../../../evaluation/modals/eco-matrix-modal/eco-matrix-modal.component';
import { BaseComponent } from '../../../shared/components/base-component/base-component';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { DeactivationService } from '../../../shared/services/deactivation.service';
import { EvaluationDomainService } from '../../../shared/services/evaluation-domains/evaluation-domain.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { IfspModification } from '../../models/ifsp-modification';
import { Plod } from '../../models/plod';
import { IfspModificationService } from '../../services/ifsp-modification.service';
import { IfspService } from '../../services/ifsp.service';

@Component({
  selector: 'app-plod',
  templateUrl: './plod.component.html',
  styleUrls: ['./plod.component.scss'],
})
export class PlodComponent extends BaseComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() modifications: IfspModification[];
  @Input() ifspSixMonthReviewMeeting: FamilyMeetingRead;
  pdfOutputs = PdfOutputs;
  activeCall = false;
  plodInitialized = false;

  get modification() {
    return this.modifications?.find((x) => !x.finalizeDate);
  }

  get modificationIsFinalized() {
    return this.modification?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.modifications && this.modifications.length > 0) {
      const latest = this.modifications.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  @Input() isModification = false;

  plodModel: Plod;

  get hasOpenModification() {
    return this.modification;
  }

  private caseId: string;
  private plodId: string;
  private evaluationId: string;
  private intakeType: string;
  public ifspId: string;
  public learnerId: string;

  evaluationDomains: KeyValuePair[];
  acquisitonTagDomainToggle = false;
  appropriateTagDomainToggle = false;
  positiveTagDomainToggle = false;
  ecoAreas: EcoArea[] = [];
  formGroup = new FormGroup({
    ecoAreas: this.fb.array([]),
    notTaggedAreasDescription: this.fb.control([null]),
  });
  learnersFirstName: string;

  get ecoAreasArray(): FormArray {
    return this.formGroup.get('ecoAreas') as FormArray;
  }

  get ecoAreaFormGroups(): FormGroup[] {
    return this.ecoAreasArray.controls as FormGroup[];
  }

  constructor(
    private readonly ifspService: IfspService,
    private readonly ifspModificationService: IfspModificationService,
    private readonly ecoAreaService: EcoAreaService,
    private readonly notificationService: NotificationService,
    private readonly ecoRatingService: EcoRatingService,
    private readonly route: ActivatedRoute,
    private readonly caseService: CaseService,
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly evaluationDomainService: EvaluationDomainService,
    private readonly router: Router,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  async ngOnInit(): Promise<void> {
    this.caseId = this.route.parent.snapshot.params.caseId;
    this.ifspId = this.route.parent.snapshot.params.ifspId;
    if (!this.ifspId) {
      this.ifspId = this.route.snapshot.paramMap.get('ifspId');
    }
    if (!this.caseId) {
      this.caseId = this.route.snapshot.paramMap.get('caseId');
    }

    if (this.modification?.modifyingPlod === null) {
      this.modification.modifyingPlod = false;
    }

    // TODO: Get learnerId a less expensive way
    this.caseService.getCaseSummary(this.caseId).subscribe((c: CaseSummary) => (this.learnerId = c.learnerId));

    await this.getInitData();
    if (!this.modification) {
      this.ifspModificationService.modificationDoneUpdating$.subscribe(() => {
        this.getInitData();
      });
    }
    this.initializeFromFormGroup(this.formGroup, (x) => this.onSave());
    this.plodInitialized = true;
    this.startAutosaving();
  }

  get domainsNotTagged(): string {
    const acquisitionSkillsFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AcquisitionSkills);
    const appropriateNeedsFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AppropriateBehaviors);
    const positiveSkillsFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.PositiveSocialEmotionalSkills);

    if (acquisitionSkillsFormGroup && appropriateNeedsFormGroup && positiveSkillsFormGroup) {
      return (
        this.evaluationDomains
          .filter(
            (x) =>
              !acquisitionSkillsFormGroup.value.taggedAreas.includes(x.key) &&
              !appropriateNeedsFormGroup.value.taggedAreas.includes(x.key) &&
              !positiveSkillsFormGroup.value.taggedAreas.includes(x.key)
          )
          .map((x) => x.value)
          .join(', ') || null
      );
    }
    return null;
  }

  onModify() {
    if (
      this.ifspSixMonthReviewMeeting !== null &&
      this.ifspSixMonthReviewMeeting.dateAndTime >= dayjs().startOf('day').subtract(30, 'day').toDate()
    ) {
      this.router.navigate(['ifsp', this.ifspId]);
      return;
    }
    if (!this.modification.modifyingPlod) {
      this.modification.modifyingPlod = true;
      this.ifspModificationService.updateModification(this.ifspId, this.modification).subscribe(() => {});
    }
  }

  tagDomainToggled(ecoAreaType: EcoAreaType) {
    switch (ecoAreaType) {
      case EcoAreaType.AcquisitionSkills:
        return this.acquisitonTagDomainToggle;
      case EcoAreaType.AppropriateBehaviors:
        return this.appropriateTagDomainToggle;
      case EcoAreaType.PositiveSocialEmotionalSkills:
        return this.positiveTagDomainToggle;
    }
  }

  showAsterisk(ecoAreaType?: EcoAreaType) {
    const acquisitionSkillsFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AcquisitionSkills);
    const appropriateNeedsFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AppropriateBehaviors);
    const positiveSkillsFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.PositiveSocialEmotionalSkills);

    switch (ecoAreaType) {
      case EcoAreaType.AcquisitionSkills:
        return !acquisitionSkillsFormGroup.get('description').value;
      case EcoAreaType.AppropriateBehaviors:
        return !appropriateNeedsFormGroup.get('description').value;
      case EcoAreaType.PositiveSocialEmotionalSkills:
        return !positiveSkillsFormGroup.get('description').value;
      default:
        return !this.formGroup.get('notTaggedAreasDescription').value;
    }
  }

  async getInitData(): Promise<void> {
    const [plodResult, evaluationResult, ecoAreaResult, domains] = await forkJoin([
      this.ifspService.getPlod(this.ifspId, this.modification ? this.modification?.id : ''),
      this.ifspService.getMostRecentEvaluationByIfspId(this.ifspId),
      this.ecoAreaService.get(),
      this.evaluationDomainService.get(),
    ]).toPromise();

    this.plodModel = plodResult;
    this.plodId = plodResult.id;
    this.ecoAreas = ecoAreaResult;
    this.evaluationDomains = domains.filter((x) => !x.isPartB).map((x) => new KeyValuePair(x.id, x.label));
    this.evaluationId = evaluationResult.evaluationId;
    this.intakeType = evaluationResult.intakeType;
    this.learnersFirstName = evaluationResult.learnersFirstName;
    this.ecoAreasArray.clear();
    this.formGroup.controls.notTaggedAreasDescription.setValue(plodResult.notTaggedAreasDescription, { emitEvent: false });

    for (const ecoArea of this.ecoAreas) {
      const result = await this.ecoRatingService.get(this.ifspId, ecoArea.id, EcoRatingType.Ifsp, this.modification?.id).toPromise();

      const ecoRatingFormGroup = this.ecoRatingService.createFormGroup(result, ecoArea, plodResult, this.learnersFirstName);
      if (ecoRatingFormGroup.get('taggedAreas').value.length > 0) {
        this.onTagEvaluationDomainToggle(ecoRatingFormGroup.get('type').value);
      }
      this.ecoAreasArray.insert(ecoArea.order, ecoRatingFormGroup);
    }
  }

  findPriorEcoAreaDescription(type: EcoAreaType) {
    if (type === EcoAreaType.AcquisitionSkills) {
      return this.plodModel?.priorVersion?.acquisitionSkills;
    }
    if (type === EcoAreaType.AppropriateBehaviors) {
      return this.plodModel?.priorVersion?.appropriateNeeds;
    }
    if (type === EcoAreaType.PositiveSocialEmotionalSkills) {
      return this.plodModel?.priorVersion?.positiveSkills;
    }
  }

  onTagEvaluationDomainToggle(ecoAreaType: EcoAreaType) {
    switch (ecoAreaType) {
      case EcoAreaType.AcquisitionSkills:
        this.acquisitonTagDomainToggle = !this.acquisitonTagDomainToggle;
        break;
      case EcoAreaType.AppropriateBehaviors:
        this.appropriateTagDomainToggle = !this.appropriateTagDomainToggle;
        break;
      case EcoAreaType.PositiveSocialEmotionalSkills:
        this.positiveTagDomainToggle = !this.positiveTagDomainToggle;
        break;
    }
  }

  onEvaluationDomainSelect(event: MatButtonToggleChange, ecoAreaType: EcoAreaType) {
    const acquisitionSkillsFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AcquisitionSkills);
    const appropriateNeedsFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AppropriateBehaviors);
    const positiveSkillsFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.PositiveSocialEmotionalSkills);

    acquisitionSkillsFormGroup.markAsDirty();
    appropriateNeedsFormGroup.markAsDirty();
    positiveSkillsFormGroup.markAsDirty();

    if (event.source.checked) {
      switch (ecoAreaType) {
        case EcoAreaType.AcquisitionSkills:
          acquisitionSkillsFormGroup.get('taggedAreas').setValue(acquisitionSkillsFormGroup.value.taggedAreas.concat(event.value));
          break;
        case EcoAreaType.AppropriateBehaviors:
          appropriateNeedsFormGroup.get('taggedAreas').setValue(appropriateNeedsFormGroup.value.taggedAreas.concat(event.value));
          break;
        case EcoAreaType.PositiveSocialEmotionalSkills:
          positiveSkillsFormGroup.get('taggedAreas').setValue(positiveSkillsFormGroup.value.taggedAreas.concat(event.value));
          break;
      }
    } else {
      switch (ecoAreaType) {
        case EcoAreaType.AcquisitionSkills:
          acquisitionSkillsFormGroup
            .get('taggedAreas')
            .setValue(acquisitionSkillsFormGroup.value.taggedAreas.filter((x) => x !== event.value));
          break;
        case EcoAreaType.AppropriateBehaviors:
          appropriateNeedsFormGroup
            .get('taggedAreas')
            .setValue(appropriateNeedsFormGroup.value.taggedAreas.filter((x) => x !== event.value));
          break;
        case EcoAreaType.PositiveSocialEmotionalSkills:
          positiveSkillsFormGroup.get('taggedAreas').setValue(positiveSkillsFormGroup.value.taggedAreas.filter((x) => x !== event.value));
          break;
      }
    }
  }

  isEvaluationDomainChecked(ecoAreaType: EcoAreaType, domainId) {
    const acquisitionSkillsFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AcquisitionSkills);
    const appropriateNeedsFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AppropriateBehaviors);
    const positiveSkillsFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.PositiveSocialEmotionalSkills);

    switch (ecoAreaType) {
      case EcoAreaType.AcquisitionSkills:
        return acquisitionSkillsFormGroup?.value.taggedAreas.includes(domainId);
      case EcoAreaType.AppropriateBehaviors:
        return appropriateNeedsFormGroup?.value.taggedAreas.includes(domainId);
      case EcoAreaType.PositiveSocialEmotionalSkills:
        return positiveSkillsFormGroup?.value.taggedAreas.includes(domainId);
    }
  }

  async autoFillEcoMatrixSummaries(): Promise<void> {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        subQuestion: 'Clicking Yes will overwrite your current responses with the summaries from the ECO Matrix.',
      },
    });

    if (!(await dialogRef.afterClosed().toPromise())) {
      return;
    }

    const ecoSummaries = await this.ifspService.autoFillEcoMatrixTags(this.ifspId).toPromise();
    let ecoAreaFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AcquisitionSkills);
    ecoAreaFormGroup.get('description').setValue(ecoSummaries?.acquisitionSkillsSummary);
    ecoAreaFormGroup.get('score').setValue(ecoSummaries?.acquisitionSkillsScore);
    if (ecoSummaries?.acquisitionSkillsScore) {
      ecoAreaFormGroup
        .get('scoreText')
        .setValue(this.ecoRatingService.getSuggestedEcoRatingText(ecoSummaries.acquisitionSkillsScore, this.learnersFirstName));
    }

    ecoAreaFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AppropriateBehaviors);
    ecoAreaFormGroup.get('description').setValue(ecoSummaries?.appropriateNeedsSummary);
    ecoAreaFormGroup.get('score').setValue(ecoSummaries?.appropriateNeedsScore);
    if (ecoSummaries?.acquisitionSkillsScore) {
      ecoAreaFormGroup
        .get('scoreText')
        .setValue(this.ecoRatingService.getSuggestedEcoRatingText(ecoSummaries.appropriateNeedsScore, this.learnersFirstName));
    }

    ecoAreaFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.PositiveSocialEmotionalSkills);
    ecoAreaFormGroup.get('description').setValue(ecoSummaries?.positiveSkillsSummary);
    ecoAreaFormGroup.get('score').setValue(ecoSummaries?.positiveSkillsScore);
    if (ecoSummaries?.positiveSkillsScore) {
      ecoAreaFormGroup
        .get('scoreText')
        .setValue(this.ecoRatingService.getSuggestedEcoRatingText(ecoSummaries.positiveSkillsScore, this.learnersFirstName));
    }

    await this.onSave();
    this.accordion.openAll();
    this.notificationService.success('Auto filled successfully');
  }

  launchEcoDecisionTree(ecoAreaId: string) {
    const modalRef = this.dialog.open(EcoMatrixModalComponent, {
      width: '1200px',
      data: {
        type: EcoRatingType.Ifsp,
        ifspId: this.ifspId,
        amendmentId: this.modification?.id,
        evaluationId: this.evaluationId,
        ecoAreaId,
        learnersFirstName: this.learnersFirstName,
        intakeType: this.intakeType,
      } as EcoWizardData,
    });

    modalRef.afterClosed().subscribe((result: EcoWizardData) => {
      if (result) {
        const ecoArea = this.ecoAreaFormGroups.find((x) => x.get('id').value === result.ecoAreaId);
        ecoArea.get('score').setValue(result.ecoRatingScore);
        ecoArea.get('scoreText').setValue(this.ecoRatingService.getSuggestedEcoRatingText(result.ecoRatingScore, this.learnersFirstName));
      }
    });
  }

  private async onSave(): Promise<void> {
    const formValues = {
      id: this.plodId,
      ifspId: this.ifspId,
      acquisitionSkills: this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AcquisitionSkills).value.description,
      acquisitionSkillsTaggedAreas: this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AcquisitionSkills).value
        .taggedAreas,
      appropriateNeeds: this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AppropriateBehaviors).value.description,
      appropriateNeedsTaggedAreas: this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AppropriateBehaviors).value
        .taggedAreas,
      positiveSkills: this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.PositiveSocialEmotionalSkills).value
        .description,
      positiveSkillsTaggedAreas: this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.PositiveSocialEmotionalSkills).value
        .taggedAreas,
      modificationId: this.modification?.id,
      notTaggedAreasDescription: this.formGroup.get('notTaggedAreasDescription').value,
    };

    this.activeCall = true;
    if (this.plodId) {
      await this.ifspService.updatePlod(this.ifspId, formValues).toPromise();
    } else {
      this.plodModel = await this.ifspService.createPlod(this.ifspId, formValues).toPromise();
      this.plodId = this.plodModel.id;
    }
    this.activeCall = false;
  }
}
