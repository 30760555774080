import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { IfspService } from 'src/app/ifsp/services/ifsp.service';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';

@Component({
  selector: 'app-late-ifsp-reason',
  templateUrl: './late-ifsp-reason.component.html',
  styleUrls: ['./late-ifsp-reason.component.scss'],
})
export class LateIfspReasonComponent implements OnInit, AfterViewInit {
  @Input() ifspId: string;
  @Input() lateReasonId: string;
  @Input() lateReasonOther: string;
  @Input() readOnly: boolean;
  @Output() lateReasonUpdated = new EventEmitter();

  lateIfspReasonOptions: KeyValuePair[];
  otherLateIfspReasonId: string;

  formGroup = this.fb.group({
    ifspId: null,
    lateReasonId: null,
    lateReasonOther: null,
  });

  constructor(private fb: FormBuilder, private ifspService: IfspService) {}

  ngOnInit(): void {
    this.formGroup.patchValue({
      ifspId: this.ifspId,
      lateReasonId: this.lateReasonId,
      lateReasonOther: this.lateReasonOther,
    });

    this.ifspService.getLateIfspReasons().subscribe((reasons) => {
      this.lateIfspReasonOptions = reasons.map((x) => new KeyValuePair(x.id, x.label));
      this.otherLateIfspReasonId = reasons.find((x) => x.isOther)?.id;
    });

    this.formGroup.controls.lateReasonId.valueChanges.subscribe((val) => {
      const ctrl = this.formGroup.controls.lateReasonOther;
      if (val === this.otherLateIfspReasonId) {
        ctrl.setValidators([Validators.required]);
      } else {
        ctrl.clearValidators();
      }
      ctrl.updateValueAndValidity();
    });
  }

  ngAfterViewInit() {
    this.formGroup.valueChanges.pipe(debounceTime(3000)).subscribe(async (val) => {
      await this.ifspService.updateLateIfspReason(val).toPromise();
      this.lateReasonUpdated.emit();
    });
  }

  getLateReasonLabel() {
    if (this.formGroup.controls.lateReasonId.value === this.otherLateIfspReasonId) {
      return 'Reason: ' + this.formGroup.controls?.lateReasonOther?.value;
    } else if (this.formGroup.controls?.lateReasonId?.value) {
      return 'Reason: ' + this.lateIfspReasonOptions?.find((x) => x.key === this.formGroup.controls?.lateReasonId?.value).value;
    } else {
      return 'Reason: ';
    }
  }
}
