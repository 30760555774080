<div class="row align-items-center">
  <div class="col">
    <mat-form-field *ngIf="teams?.length > 0">
      <mat-label>Select a Team</mat-label>
      <mat-select (selectionChange)="updateTeamSelection($event.value)">
        <mat-option *ngFor="let team of teams" [value]="team" role="combobox">
          {{ team.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="actions">
      <button mat-raised-button aria-label="Add Team Member" color="primary" type="button" (click)="openSearchDialog()" tabindex="0">
        Add Team Member
      </button>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="table-overflow">
      <table mat-table matSort [dataSource]="dataSourceTeamUsers" *ngIf="dataSourceTeamUsers?.data?.length > 0">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button aria-label="Delete Icon" mat-icon-button (click)="removeTeamUser(row)" color="warn" tabindex="0">
              <mat-icon>close</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="fullName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let teamUser">{{ teamUser?.fullName }}</td>
        </ng-container>

        <!-- Job Title Column -->
        <ng-container matColumnDef="jobTitle">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Title</th>
          <td mat-cell *matCellDef="let teamUser">{{ teamUser?.jobTitle }}</td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let teamUser">{{ teamUser?.email }}</td>
        </ng-container>

        <!-- Phone Column -->
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
          <td mat-cell *matCellDef="let teamUser">
            {{ teamUser?.phone | phone }}
          </td>
        </ng-container>

        <!-- Area Education Agency Column -->
        <ng-container matColumnDef="aea">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>AEA</th>
          <td mat-cell *matCellDef="let teamUser">
            {{ teamUser?.aea }}
          </td>
        </ng-container>

        <!-- District Column -->
        <ng-container matColumnDef="district">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>District</th>
          <td mat-cell *matCellDef="let teamUser">{{ teamUser.district }}</td>
        </ng-container>

        <!-- Primary Office Column -->
        <ng-container matColumnDef="buildings">
          <!-- TODO This needs to be updated when we get building information -->
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Primary Office</th>
          <td mat-cell *matCellDef="let teamUser">
            {{ teamUser.buildings[0]?.name }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selectionTeamUser.toggle(row)"></tr>
      </table>
      <p *ngIf="!dataSourceTeamUsers">No data yet...</p>
    </div>
  </div>
</div>
