<main class="dashboard">
  <header>
    <app-page-header [pageTitle]="'Eligible Family Dashboard'" [helpLinks]="false"></app-page-header>
  </header>

  <section class="mt-3">
    <!-- todo add intake type -->
    <app-learner-info-header [learnerSummary]="child"></app-learner-info-header>
  </section>

  <section>
    <mat-horizontal-stepper class="stepper" #stepper labelPosition="bottom" [disableRipple]="true">
      <!-- Learner Dashboard -->
      <mat-step>
        <ng-template matStepLabel>
          <div class="stepper__label">
            <mat-icon aria-labelledby="Overview"> dashboard </mat-icon>
            <p>Learner Dashboard</p>
          </div>
        </ng-template>

        <h3>Learner Dashboard</h3>
      </mat-step>

      <!-- Family Contact and Consent -->
      <mat-step>
        <ng-template matStepLabel>
          <div class="stepper__label">
            <mat-icon aria-labelledby="Home"> home </mat-icon>
            <p>Family Contact and Consent</p>
          </div>
        </ng-template>

        <h3>Family Contact and Consent</h3>
      </mat-step>

      <!-- Evaluation Assessment -->
      <mat-step>
        <ng-template matStepLabel>
          <div class="stepper__label">
            <mat-icon aria-labelledby="Search"> search </mat-icon>
            <p>Evaluation Assessment</p>
            <p class="stepper__label-meta" *ngIf="true">Active Evaluation</p>
          </div>
        </ng-template>

        <app-evaluation-assessment-info [evaluation]="evaluation"></app-evaluation-assessment-info>

        <app-evaluation-assessment-table [teamMembers]="assessmentTeam"></app-evaluation-assessment-table>
      </mat-step>
    </mat-horizontal-stepper>
  </section>
</main>
