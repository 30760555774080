import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GenericPermissionsGuard } from '../auth/auth.guard';
import { CanDeactivateGuard } from '../shared/guards/can-deactivate.guard';
import { IfspComponent } from './ifsp.component';
import { IfspReviewType } from './models/ifsp';
import { IfspPeriodicPollComponent } from './shared/components/ifsp-periodic-poll/ifsp-periodic-poll.component';
import { IfspAdditionalInfoComponent } from './steps/ifsp-additional-info/ifsp-additional-info.component';
import { IfspChildFamilyOutcomesComponent } from './steps/ifsp-child-family-outcomes/ifsp-child-family-outcomes.component';
import { IfspDetailsComponent } from './steps/ifsp-details/ifsp-details.component';
import { IfspProfileComponent } from './steps/ifsp-profile/ifsp-profile.component';
import { IfspOutcomesServicesPrintComponent } from './steps/ifsp-profile/ifsp-reports/ifsp-outcomes-services-print/ifsp-outcomes-services-print.component';
import { IfspServicesComponent } from './steps/ifsp-services/ifsp-services.component';
import { PlodOutputComponent } from './steps/plod/plod-output/plod-output.component';
import { PlodComponent } from './steps/plod/plod.component';

const routes: Routes = [
  {
    path: ':ifspId',
    component: IfspComponent,
    canActivate: [GenericPermissionsGuard],
    data: {
      permission: 'EditIFSP',
      additionalRequirement: false,
    },
    children: [
      {
        path: 'profile',
        component: IfspProfileComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'plod',
        component: PlodComponent,
      },
      {
        path: 'child-family-outcomes',
        component: IfspChildFamilyOutcomesComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'child-family-outcomes/:outcomeId',
        component: IfspChildFamilyOutcomesComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'services',
        component: IfspServicesComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'services/:serviceId',
        component: IfspServicesComponent,
      },
      {
        path: 'services/outcome/:outcomeId',
        component: IfspServicesComponent,
      },
      {
        path: 'services-outcomes/report',
        component: IfspOutcomesServicesPrintComponent,
      },
      {
        path: 'additional-info',
        component: IfspAdditionalInfoComponent,
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
  {
    path: ':ifspId/plod-output',
    component: PlodOutputComponent,
  },
  {
    path: ':ifspId/details',
    component: IfspDetailsComponent,
  },
  {
    path: ':ifspReviewId/review',
    component: IfspPeriodicPollComponent,
    data: { reviewType: IfspReviewType.Periodic },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IfspRoutingModule {}
