import { AfterContentInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { clpIepServiceDeliveryFormat } from 'src/app/iep/models/clp-iep-service-delivery-format';
import { clpIepServiceFrequency } from 'src/app/iep/models/clp-iep-service-frequency';
import { TrialPlacementTarget } from 'src/app/iep/models/iep';
import { IepServiceDto } from 'src/app/iep/models/iep-service';
import { IepSpecialEducationServicePlan } from 'src/app/iep/models/iep-special-education-service-plan';
import { IepSpecialEducationServicePlanDeliveryFormat } from 'src/app/iep/models/iep-special-education-service-plan-delivery-format';
import { IepServiceService } from 'src/app/iep/services/iep-service.service';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { conditionalValidator } from 'src/app/shared/validators';

@Component({
  selector: 'app-special-education-service-plan',
  templateUrl: './special-education-service-plan.component.html',
  styleUrls: ['./special-education-service-plan.component.scss'],
})
export class SpecialEducationServicePlanComponent implements OnInit, OnDestroy, AfterContentInit {
  specialEducationService: IepServiceDto;
  @Input() specialEducationServices: IepServiceDto[];
  @Input() form: FormGroup;
  @Input() frequencyOptions: clpIepServiceFrequency[] = [];
  @Input() deliveryFormatOptions: clpIepServiceDeliveryFormat[] = [];
  @Input() readonly: boolean;
  @Input() iepTrialPlacement: { iepIncludesTrialPlacement: boolean; iepTrialPlacementTarget: TrialPlacementTarget };

  private subscriptions: Subscription = new Subscription();

  frequencyDictionary: KeyValuePair[] = [];
  deliveryFormatDictionary: KeyValuePair[] = [];
  showDeliveryFormatDetailsField = false;

  public get isFrequencyDescribeFieldRequired() {
    const frequencyId = this.form.get('serviceClpFrequencyId').value;
    if (!frequencyId) return false;

    return this.frequencyOptions.find((x) => x.id === frequencyId)?.requiresDescription;
  }

  public get formValues(): IepSpecialEducationServicePlan {
    return this.form.value;
  }

  constructor(private dialog: MatDialog, private iepServiceService: IepServiceService) {}

  ngOnInit(): void {
    this.specialEducationService = this.specialEducationServices.find((x) => x.id === this.form.value.iepServiceId);
  }

  viewDetails() {
    openViewMore(
      this.dialog,
      this.iepServiceService.getIepDevelopmentServiceViewMoreData(
        this.specialEducationService,
        this.iepTrialPlacement.iepIncludesTrialPlacement,
        this.iepTrialPlacement.iepTrialPlacementTarget
      )
    );
  }

  ngAfterContentInit(): void {
    this.generateMutiDropdownControl();
    this.getDropDownOptions();
    this.setConditionalValidators();
    this.formValuesChange();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  generateMutiDropdownControl() {
    const deliveryFormatIdsControl = new FormControl();
    const deliveryFormatDetailsControl = new FormControl();

    // Getting values
    const deliveryFormatIds = this.formValues.iepServicePlanDeliveryFormats.map((x) => x.iepServiceClpDeliveryFormatId);
    let deliveryFormatDetails = '';

    if (this.doesDeliveryFormatsRequiresDetails(deliveryFormatIds))
      deliveryFormatDetails = this.formValues.iepServicePlanDeliveryFormats.find(
        (x) => x.deliveryFormatDescription
      )?.deliveryFormatDescription;

    this.form.addControl('deliveryFormatIds', deliveryFormatIdsControl);
    this.form.addControl('deliveryFormatDetails', deliveryFormatDetailsControl);

    deliveryFormatIdsControl.setValue(deliveryFormatIds);
    deliveryFormatDetailsControl.setValue(deliveryFormatDetails);
  }

  private getDropDownOptions() {
    this.deliveryFormatDictionary = KeyValuePair.createFromLookups(this.deliveryFormatOptions.sort((a, b) => a.order - b.order));
    this.frequencyDictionary = KeyValuePair.createFromLookups(this.frequencyOptions.sort((a, b) => a.order - b.order));
  }

  setConditionalValidators() {
    const checkFrequenciesDescribeRequired = (id: string) => this.frequencyOptions.find((x) => x.id === id)?.requiresDescription;

    this.form
      .get('deliveryFormatDetails')
      .addValidators(conditionalValidator(() => this.showDeliveryFormatDetailsField, Validators.required));

    this.form
      .get('frequencyDescription')
      .addValidators(
        conditionalValidator(() => checkFrequenciesDescribeRequired(this.form.get('serviceClpFrequencyId').value), Validators.required)
      );

    this.form.get('deliveryFormatIds').addValidators(conditionalValidator(() => !this.isPaused(), Validators.required));
  }

  isPaused() {
    const frequencyId = this.form.get('serviceClpFrequencyId').value;
    const label = this.frequencyOptions.find((x) => x.id === frequencyId)?.label;
    const isPaused = label === 'Paused';
    return isPaused;
  }

  formValuesChange() {
    const frequencyComponent = this.form.get('serviceClpFrequencyId');
    const deliveryFormatIdsControl = this.form.get('deliveryFormatIds');
    const deliveryFormatDetailsControl = this.form.get('deliveryFormatDetails');

    const deliveryFormatIdsControlValuesChanges = (ids: string[]) => {
      const iepServicePlanId = this.formValues.id;
      const deliveryFormats = this.deliveryFormatOptions
        .filter((x) => ids.includes(x.id))
        .map(
          (x) =>
            ({
              deliveryFormatDescription: x.requiresDetails ? this.form.controls.deliveryFormatDetails.value : null,
              iepServiceClpDeliveryFormatId: x.id,
              iepServicePlanId: iepServicePlanId,
            } as IepSpecialEducationServicePlanDeliveryFormat)
        );
      this.form.controls.iepServicePlanDeliveryFormats.setValue(deliveryFormats, { emitEvent: false });
      this.showDeliveryFormatDetailsField = this.doesDeliveryFormatsRequiresDetails(ids);
    };

    const deliveryFormatDetailsControlValuesChange = (details: string) => {
      const deliveryFormats = this.formValues.iepServicePlanDeliveryFormats;
      deliveryFormats.forEach((x) => {
        x.deliveryFormatDescription = this.doesDeliveryFormatsRequiresDetails([x.iepServiceClpDeliveryFormatId]) ? details : null;
      });

      this.form.controls.iepServicePlanDeliveryFormats.setValue(deliveryFormats, { emitEvent: false });
    };

    const subDFIds = deliveryFormatIdsControl.valueChanges.subscribe(deliveryFormatIdsControlValuesChanges);
    const subDfDetails = deliveryFormatDetailsControl.valueChanges.subscribe(deliveryFormatDetailsControlValuesChange);

    if (!this.readonly) {
      const subFrequency = frequencyComponent.valueChanges.subscribe(() => {
        // this.form.get('deliveryFormatIds').updateValueAndValidity();
        this.form.get('frequencyDescription').setValue(null);
        this.form.get('frequencyDescription').updateValueAndValidity();
      });
      this.subscriptions.add(subFrequency);
    }

    this.subscriptions.add(subDFIds);
    this.subscriptions.add(subDfDetails);

    // Force checking if the description field should show.
    deliveryFormatIdsControl.updateValueAndValidity();
  }

  doesDeliveryFormatsRequiresDetails(ids: string[]) {
    return this.deliveryFormatOptions.filter((x) => ids.includes(x.id))?.some((x) => x.requiresDetails);
  }
}
