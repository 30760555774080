import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { DisabilitySuspectForm, DisabilitySuspectFormNonAchieveParticipant } from '../models/disability-suspect-form';

@Injectable({
  providedIn: 'root',
})
export class DisabilitySuspectService {
  baseUrl = 'api/disability-suspect-form/';

  constructor(private http: HttpClient, spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(`^${this.baseUrl}`));
  }

  getForm(id: string): Observable<DisabilitySuspectForm> {
    return this.http.get<DisabilitySuspectForm>(this.baseUrl + `${id}`);
  }

  openedForm(id: string) {
    return this.http.put<void>(this.baseUrl + `${id}/opened`, {});
  }

  saveForm(dsForm: DisabilitySuspectForm, isSubmit = false) {
    if (isSubmit) {
      dsForm.isSubmitted = true;
    }
    if (dsForm.id === undefined) {
      return this.http.post<DisabilitySuspectForm>(this.baseUrl, dsForm);
    } else {
      return this.http.put<DisabilitySuspectForm>(this.baseUrl + dsForm.id, dsForm);
    }
  }

  deleteTeamUser(dsFormId: string, userId: string) {
    return this.http.put<any>(this.baseUrl + `${dsFormId}/${userId}`, null);
  }

  saveNonAchieveParticipant(dsFormId: string, nonAchieveParticipant: DisabilitySuspectFormNonAchieveParticipant) {
    return this.http.post<any>(this.baseUrl + `${dsFormId}/nonAchieveParticipant`, nonAchieveParticipant);
  }

  deleteNonAchieveParticipant(dsFormId: string, participantId: string) {
    return this.http.put<any>(this.baseUrl + `${dsFormId}/nonAchieveParticipant/${participantId}`, null);
  }
}
