import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BankableFieldEntryDto } from 'src/app/shared/components/bankable-fields/bankable-fields-models';

@Injectable({
  providedIn: 'root',
})
export class BankableFieldsService {
  baseUrl = 'api/bankablefieldentries';
  constructor(private http: HttpClient) {}

  getBankableFields(fieldId): Observable<BankableFieldEntryDto[]> {
    return this.http.get<BankableFieldEntryDto[]>(`${this.baseUrl}/${fieldId}`);
  }

  createBankableField(bf: BankableFieldEntryDto) {
    return this.http.post<BankableFieldEntryDto>(`${this.baseUrl}`, bf);
  }

  saveBankableField(bf: BankableFieldEntryDto): Observable<BankableFieldEntryDto> {
    if (bf.id) {
      return this.http.put<BankableFieldEntryDto>(`${this.baseUrl}`, bf);
    } else {
      return this.createBankableField(bf);
    }
  }

  deleteBankableField(id: string) {
    return this.http.put(`${this.baseUrl}/${id}`, null);
  }
}
