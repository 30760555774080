import { Aea } from './aea';
import { Building } from './building';
import { District } from './district';
import { Learner, LearnerSummary } from './learner';
import { User } from './user';

export interface Team {
  id?: string;
  name?: string;
  users?: TeamUser[];
}

export interface TeamUser {
  userId: string;
  fullName?: string;
  email?: string;
  phone?: string;
  jobTitle?: string;
  isByResident: boolean;
  aeas?: Array<Aea>;
  districts?: Array<District>;
  buildings?: Array<Building>;
}

export interface Agency {
  aeaId: string;
  /**
   * This is this AEA.Name
   */
  aea: string;
  districtId: string;
  /**
   * This is this District.Name
   */
  district: string;
}

/**
 * Attempts to find the most appropriate "agency" to display
 * by checking for multiple LEAs/AEAs on teamUser and using the learner's
 * district to match if needed
 */
const getAgency = (user: User | TeamUser, learner?: Learner | LearnerSummary): string => {
  const buildings = user.buildings.filter((x) => x.id);
  const leas = user.districts.filter((x) => x.id);
  const aeas = user.aeas.filter((x) => x.id);
  if (buildings?.length + leas?.length + aeas?.length === 1) {
    if (buildings?.length === 1) {
      return buildings[0].name;
    }
    if (leas?.length === 1) {
      return leas[0].name;
    }
    if (aeas?.length === 1) {
      return aeas[0].name;
    }
  }
  if (!learner) {
    return '';
  }
  const buildingLearnerMatch = buildings.find((x) => learner.attendingDistrictId === x.id);
  if (buildings.length > 1 && buildingLearnerMatch) {
    return buildingLearnerMatch.name;
  }
  const leaLearnerMatch = leas.find((x) => learner.attendingDistrictId === x.id);
  if (leas.length > 1 && leaLearnerMatch) {
    return leaLearnerMatch.name;
  }
  const aeaLearnerMatch = aeas.find((x) => learner.attendingAeaId === x.id);
  if (leas.length > 1 && aeaLearnerMatch) {
    return aeaLearnerMatch.name;
  }
  // todo I feel like this should throw an error
  return '';
};

export { getAgency };
