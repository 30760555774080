import { FileDocument } from 'src/app/shared/models/file-document';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { LookupBase } from 'src/app/shared/models/lookup-base';
import { UserNameOnly } from 'src/app/shared/models/user-name-only';
import { IntakeType } from '../../shared/models/case';
import { Learner } from '../../shared/models/learner';
import { User } from '../../shared/models/user';
import { EvaluationRoutine } from './evaluation-routine';

export interface Evaluation {
  id: string;
  caseId: string;
  intakeType: IntakeType;
  learner: Learner;
  evaluationDetails?: EvaluationDetail[];
  consentDomains: string[];
  strengthsConcernsBackgroundId?: string;
  owner: User;
  familySurveyId: string;
  isActive: boolean;
  assignedDomainNames: string[];
  assignedDomainIds: string[];
  progressComplete: number;
  total: number;
  caseEligibilityDecision?: boolean;
  eligibilitySubmittedOnEvaluation?: boolean;
  eligibilityDecision?: boolean;
  eligibilityDetermination?: boolean;
  daysLeft: number;
  learnerProgressId?: string;
  learnerPerformanceSummary?: string;
  educationalNeedsSummary?: string;
  eligibilityBasedOn: string;
  twentyFiveDelayAreas: string[];
  documentedDelayConditions: string;
  eligibilityIcoSources: EligibilityIcoSource[];
  eligibilityIcoReasons: EligibilityIcoReason[];
  eligibilityWorksheet: EligibilityWorksheet;
  consideringSld?: boolean;
  grade: string;
  educationalNeedsId?: string;
  isFinalized: boolean;
  isInitial: boolean;
  eerDocumentId?: string;
  evaluationSummaryDocumentId?: string;
  evaluationAssessmentDocumentId?: string;
  createdOn: Date;
  disabilitySuspectFormId: string;
  eligibilityMeetingComplete?: boolean;
  delayed: boolean;
  diagnosisFromIntake: string;
  isEligibilityDecisionFinalized: boolean;
  ifspHasActiveAnnualReview: boolean;
  continuedEligibilityDetermination?: boolean;
  hasConsentFormApproval: boolean;
}

export interface EligibilityInformation {
  eligibilityBasedOn: string;
  twentyFiveDelayAreas: string;
  documentedDelayConditions: string;
  eligibilityIcoSources: Array<EligibilityIcoSource>;
  eligibilityIcoReasons: Array<EligibilityIcoReason>;
  isEligible: boolean;
}

export interface ContinuedEligibilityInformation {
  eligibilityDetermination?: boolean;
  eligibilityDecision?: boolean;
  isEligibilityDecisionFinalized: boolean;
  eligibilityBasedOn: string;
  twentyFiveDelayAreas: string[];
  eligibilityIcoSources: EligibilityIcoSource[];
  eligibilityIcoReasons: EligibilityIcoReason[];
}

export interface EligibilityIcoSource {
  id: string;
  source: string;
  summary: string;
  evaluationId: string;
}

export interface EligibilityIcoReason {
  id: string;
  reason: string;
  summary: string;
  evaluationId: string;
}

export interface EvaluationDetail {
  id?: string;
  evaluationId?: string;
  notes?: EvaluationNote[];
  documents?: FileDocument[];
  dateOfEvaluation?: Date;
  createdBy?: UserNameOnly;
  updatedBy?: UserNameOnly;
  isSubmitted?: boolean;
  location?: string;
  descriptionOfLocation?: string;

  //#region Review
  review?: boolean;
  reviewedDocuments?: FileDocument[];
  documentationNotes?: string;
  //#endregion

  //#region Observation
  observation?: boolean;
  //#endregion

  //#region Interview
  interview?: boolean;
  interviewType?: string;
  interviewee?: string;
  toolUsed?: string;
  toolOtherDescription?: string;
  childFamilyStrengths?: string;
  familyResources?: string;
  childFamilyConcerns?: string;
  familyPriorities?: string;
  otherInformation?: string;
  toolAdditionalResults?: string;
  typicalDay?: string;
  routines?: EvaluationDetailRoutine[];
  //#endregion

  //#region Test
  test?: boolean;
  testGiven?: string;
  //#endregion
}

export const InterviewTypes: KeyValuePair[] = [
  new KeyValuePair('General', 'General'),
  new KeyValuePair('Family', 'Family: Resources, Priorities, and Concerns'),
  new KeyValuePair('Routines', 'Routines'),
];

export interface EvaluationNote {
  id?: string;
  evaluationDetailId?: string;
  createdBy?: UserNameOnly;
  updatedBy?: UserNameOnly;
  ageEquivalentLow?: number;
  ageEquivalentHigh?: number;
  calculatedPercentDelayLow?: number;
  calculatedPercentDelayHigh?: number;
  note?: string;
  ecoAreas?: EcoArea[];
  domainAreas?: LookupBase[];
  evaluationRoutines?: EvaluationRoutine[];
  evaluationDetailInterviewQuestionId?: string;
  interviewQuestion?: string;
  taggedForPwn?: boolean;
  taggedForOutcomes?: boolean;
  taggedForServicesC?: boolean;
}

export interface EvaluationDetailRoutine {
  id?: string;
  evaluationRoutineId?: string;
  label?: string;
  nickName?: string;
  otherLabel?: string;
  satisfaction?: number;
  workOnTogether?: boolean;
  workingWell?: string;
  routineDifficulty?: string;
  routineParticipation?: string;
  routineLooksLike?: string;
  partWorkingWell?: string;
  wouldRoutineImproveHelp?: string;
  ecoAreas?: EcoArea[];
  domainAreas?: LookupBase[];
  evaluationRoutines?: EvaluationRoutine[];
  taggedForPwn?: boolean;
  taggedForOutcomes?: boolean;
  taggedForServicesC?: boolean;
  taggedItemId?: string;
}

export interface EligibilityWorksheet {
  evaluationId: string;
  evaluation: Evaluation;

  hasDisability: boolean;
  doesLearningDisabilityContribute: boolean;
  part1Comments: string;

  needsAdaptedContent: boolean;
  part2Comments: string;

  specialEdDesignation: boolean;
  part3Comments: string;

  part4Comments: string;

  eligibilityWorksheetSignatures: EligibilityWorksheetSignature[];
  learnerPerformanceSummary: string;
  learnerProgressSummary: string;
  strengthsSummary: string;
  concernsSummary: string;
  relevantBackgroundSummary: string;
  educationalNeedsSummary: string;

  lackOfAppropriateInstruction: boolean;
  lackOfAppropriateInstructionEvidences: string[];
  lackOfAppropriateInstructionExplanation: string;

  cultureAndEnvironmentalEconomicDisadvantage: boolean;
  cultureAndEnvironmentalEconomicDisadvantageEvidences: string[];
  cultureAndEnvironmentalEconomicDisadvantageExplanation: string;

  limitedEnglishProficiency: boolean;
  limitedEnglishProficiencyEvidences: string[];
  limitedEnglishProficiencyExplanation: string;

  isSLD: boolean;
  sldFactors: string[];
  factorsInSchoolSuccessExplanation: string;
  learnerPerformanceSummaries: LearnerPerformanceSummary[];
  isCompleted: boolean;
  transitionDelayReasonId: string;
}

export interface LearnerPerformanceSummary {
  domains: string;
  summary: string;
}

export interface EligibilityWorksheetSignature {
  name: string;
  role: string;
  email: string;
  pin: string;
  agree: boolean;
}

export interface EcoArea extends LookupBase {
  order: number;
  type: EcoAreaType;
}

export interface EvaluationDevelopmentalAreaStatus {
  completedDevelopmentalAreaIds: string[];
  requiredDevelopmentalAreaIds: string[];
}

export enum EcoAreaType {
  PositiveSocialEmotionalSkills = 'PositiveSocialEmotionalSkills',
  AppropriateBehaviors = 'AppropriateBehaviors',
  AcquisitionSkills = 'AcquisitionSkills',
}

export enum EligibilityBasedOn {
  QuarterOrMore = 'QuarterOrMore',
  DocumentedDelay = 'DocumentedDelay',
  InformedClinicalOpinion = 'InformedClinicalOpinion',
}
