import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { forbiddenRedirect } from 'src/app/auth/auth.guard';
import { AuthService } from 'src/app/auth/auth.service';
import { EvaluationService } from 'src/app/evaluation/services/evaluation.service';
import { AppPermissions } from 'src/app/permissions';
import { IntakeType } from 'src/app/shared/models/case';

@Injectable({
  providedIn: 'root',
})
export class EvalDetailsPartBGuard implements CanActivate {
  constructor(private router: Router, private evaluationService: EvaluationService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const evaluationId = route.parent.params.evaluationId;
    if (!evaluationId) {
      return of(false);
    }

    return this.evaluationService.get(evaluationId).pipe(
      map((response) => {
        if (response.isFinalized) {
          this.router.navigate(['evaluation', evaluationId, 'overview']);
          return false;
        } else {
          return true;
        }
      }),
      catchError((error) => of(false))
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class EvaluationGuard implements CanActivate {
  constructor(private router: Router, private evaluationService: EvaluationService, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const evaluationId = route.params.evaluationId;
    if (!evaluationId) {
      return of(false);
    }

    return this.evaluationService.get(evaluationId).pipe(
      map((response) => {
        if (response.intakeType === IntakeType.PartB) {
          return this.goToEvaluationPartB(response);
        } else {
          return this.goToEvaluationPartC(response);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case HttpStatusCode.NotFound:
            this.router.navigate(['/not-found']);
            break;
          default:
            return of(false);
            break;
        }
      })
    );
  }

  private goToEvaluationPartB(evaluation) {
    const hasEditPermission = this.authService.isAllowedByCaseId(
      evaluation.caseId,
      new Map<string, string[]>([['TeamMember', ['ServiceCoordinator']]]),
      AppPermissions.EditEvaluationPartB
    );
    const hasViewPermission = this.authService.isAllowedByCaseId(evaluation.caseId, undefined, AppPermissions.ViewEvaluationPartB);

    if (evaluation.isFinalized && (hasEditPermission || hasViewPermission)) {
      this.router.navigate(['/', 'learner-management', evaluation.learner.id, 'evaluation']);
      return false;
    }

    if (hasEditPermission) {
      return true;
    }

    if (hasViewPermission) {
      this.router.navigate(['/', 'learner-management', evaluation.learner.id, 'evaluation']);
      return false;
    }

    return forbiddenRedirect(this.router);
  }

  private goToEvaluationPartC(evaluation) {
    const hasEditPermission =
      this.authService.isAllowedByCaseId(evaluation.caseId, undefined, AppPermissions.EditEvaluationPartC) ||
      this.authService.isAllowedBySpecificPermissions(evaluation.id, AppPermissions.EditEvaluationPartC);
    const hasViewPermission = this.authService.isAllowedByCaseId(evaluation.caseId, undefined, AppPermissions.ViewEvaluationPartC);

    if (hasEditPermission) {
      return true;
    }

    if (hasViewPermission) {
      this.router.navigate(['/', 'learner-management', evaluation.learner.id, 'evaluation']);
      return false;
    }
    return forbiddenRedirect(this.router);
  }
}
