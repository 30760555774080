import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { Referral } from 'src/app/child-find/early-access-referral/referral';
import { EarlyAccessQuestionnaire } from 'src/app/evaluation/models/early-access-questionnaire';
import { Intake } from 'src/app/evaluation/models/intake';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { usStates } from 'src/app/shared/models/us-states';
import { FamilyRelationshipService } from 'src/app/shared/services/family-relationship/family-relationship.service';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { LocationService } from 'src/app/shared/services/location/location.service';
import { noNumbersLimitedSpecialCharsValidator, noNumbersValidator, phoneValidator } from 'src/app/shared/validators';
import { FamilyInformation } from '../../models/family-information';

//#region Custom Validation Functions
const phoneEmailValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  const livesWith1Email = formGroup.get('livesWith1Email');
  const livesWith1HomePhone = formGroup.get('livesWith1HomePhone');
  const livesWith1CellPhone = formGroup.get('livesWith1CellPhone');
  const livesWith1WorkPhone = formGroup.get('livesWith1WorkPhone');

  const livesWith2Email = formGroup.get('livesWith2Email');
  const livesWith2HomePhone = formGroup.get('livesWith2HomePhone');
  const livesWith2CellPhone = formGroup.get('livesWith2CellPhone');
  const livesWith2WorkPhone = formGroup.get('livesWith2WorkPhone');

  const parent1Email = formGroup.get('parent1Email');
  const parent1HomePhone = formGroup.get('parent1HomePhone');
  const parent1CellPhone = formGroup.get('parent1CellPhone');
  const parent1WorkPhone = formGroup.get('parent1WorkPhone');
  const parent2Email = formGroup.get('parent2Email');
  const parent2HomePhone = formGroup.get('parent2HomePhone');
  const parent2CellPhone = formGroup.get('parent2CellPhone');
  const parent2WorkPhone = formGroup.get('parent2WorkPhone');

  const noPhoneOrEmail =
    !livesWith1Email?.value &&
    !livesWith1HomePhone?.value &&
    !livesWith1CellPhone?.value &&
    !livesWith1WorkPhone?.value &&
    !livesWith2Email?.value &&
    !livesWith2HomePhone?.value &&
    !livesWith2CellPhone?.value &&
    !livesWith2WorkPhone?.value &&
    !parent1Email?.value &&
    !parent1HomePhone?.value &&
    !parent1CellPhone?.value &&
    !parent1WorkPhone?.value &&
    !parent2Email?.value &&
    !parent2HomePhone?.value &&
    !parent2CellPhone?.value &&
    !parent2WorkPhone?.value;

  if (noPhoneOrEmail) {
    return { atLeastOnePhoneRequired: true };
  }
  return null;
};

const parent2LivesWithValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const parent2Name = control.get('parent2Name').value;
  const parent2LivesWithChild = control.get('parent2LivesWithChild').value;

  return parent2Name && typeof parent2LivesWithChild !== 'boolean' ? { parent2LivesWithChildRequired: true } : null;
};

const livesWith2RelationshipValidator: ValidatorFn = (control: FormGroup) => {
  const livesWith2Name = control.get('livesWith2Name').value;
  const livesWith2Relationship = control.get('livesWith2RelationshipId').value;
  return !!livesWith2Name && !livesWith2Relationship ? { livesWith2RelationshipRequired: true } : null;
};

const bestWayToReachValidator =
  (parent: string): ValidatorFn =>
  (control: FormGroup): ValidationErrors | null => {
    const homePhone = control.get(`${parent}HomePhone`);
    const homePhoneIsBest = control.get(`${parent}BestWayToReachHomePhone`);
    const cellPhone = control.get(`${parent}CellPhone`);
    const cellPhoneIsBest = control.get(`${parent}BestWayToReachCellPhone`);
    const textIsBest = control.get(`${parent}BestWayToReachText`);
    const workPhone = control.get(`${parent}WorkPhone`);
    const workPhoneIsBest = control.get(`${parent}BestWayToReachWorkPhone`);
    const email = control.get(`${parent}Email`);
    const emailIsBest = control.get(`${parent}BestWayToReachWorkEmail`);

    if (
      (!!homePhoneIsBest && !homePhone) ||
      (!!workPhoneIsBest && !workPhone) ||
      (!!cellPhoneIsBest && !cellPhone) ||
      (!!cellPhoneIsBest && !textIsBest) ||
      (!!emailIsBest && !email)
    ) {
      const errors = {};
      errors[`${parent}OnePhoneNumberContactRequired`] = true;
      return errors;
    } else {
      return null;
    }
  };

//#endregion

@Component({
  selector: 'app-family-info-form',
  templateUrl: './family-info-form.component.html',
  styleUrls: ['./family-info-form.component.scss'],
})
export class FamilyInfoFormComponent implements OnInit, OnChanges, OnDestroy {
  private subscription: Subscription = new Subscription();

  @Input() formGroup: FormGroup;
  @Input() intake: Intake;
  @Input() referral: Referral;
  @Input() questionnaire: EarlyAccessQuestionnaire;
  @Input() isQuestionnaire: boolean;
  @Input() questionnaireLocked: boolean;
  @Input() intakeLocked: boolean;
  @Input() importQuestionnaireSelected: boolean;
  @Output() formInitialized = new EventEmitter();
  formInitializationFlags = {
    controlsInitialized: false,
    dataLoaded: false,
    eventEmitted: false,
  };
  familyInfo: FamilyInformation;
  locked = false;
  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  helpSection = HelpSection;

  relationshipOptions: KeyValuePair[];
  usStateOptions = usStates.map((s) => new KeyValuePair(s, s));

  shownFields = {
    canDisplayFamilyInfo: true,
  };

  constructor(
    private fb: FormBuilder,
    private familyMemberRelationshipService: FamilyRelationshipService,
    private locationService: LocationService,
    private helpService: HelpService
  ) {}

  ngOnInit() {
    if (this.isQuestionnaire) {
      this.familyInfo = this.questionnaire?.familyInfo;
    } else {
      this.familyInfo = this.intake?.familyInfo;
    }
    this.initializeControls();
    this.loadData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.importQuestionnaireSelected?.currentValue !== changes.importQuestionnaireSelected?.previousValue &&
      this.importQuestionnaireSelected
    ) {
      this.importFamilyInfoFromQuestionnaire();
      this.formGroup.markAsDirty();
    }
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm) {
    e.preventDefault();

    const dictionary = this.helpService.getReferralDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  importFamilyInfoFromQuestionnaire() {
    const questionnaireFamilyInfo = this.questionnaire?.familyInfo;
    for (const prop in questionnaireFamilyInfo) {
      if (questionnaireFamilyInfo[prop] !== null && questionnaireFamilyInfo[prop] !== '') {
        this.familyInfo[prop] = questionnaireFamilyInfo[prop];
      }
    }
    this.formGroup.patchValue(this.familyInfo);
  }

  private loadData() {
    forkJoin([this.familyMemberRelationshipService.get()]).subscribe(([relationships]) => {
      this.relationshipOptions = relationships
        .sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        })
        .map((x) => new KeyValuePair(x.id, x.label));
      setTimeout(() => this.updateForm(), 0);
    });
  }

  setLock() {
    this.locked = this.isQuestionnaire ? this.questionnaireLocked : this.intakeLocked;

    if (this.locked) {
      setTimeout(() => this.lockCheckboxes(), 0);
    }
  }

  lockCheckboxes() {
    this.formGroup.controls.parent1BestWayToReachCellPhone.disable();
    this.formGroup.controls.parent2BestWayToReachCellPhone.disable();
    this.formGroup.controls.livesWith1BestWayToReachCellPhone.disable();
    this.formGroup.controls.livesWith2BestWayToReachCellPhone.disable();
    this.formGroup.controls.parent1BestWayToReachWorkPhone.disable();
    this.formGroup.controls.parent2BestWayToReachWorkPhone.disable();
    this.formGroup.controls.livesWith1BestWayToReachWorkPhone.disable();
    this.formGroup.controls.livesWith2BestWayToReachWorkPhone.disable();
    this.formGroup.controls.parent1BestWayToReachHomePhone.disable();
    this.formGroup.controls.parent2BestWayToReachHomePhone.disable();
    this.formGroup.controls.livesWith1BestWayToReachHomePhone.disable();
    this.formGroup.controls.livesWith2BestWayToReachHomePhone.disable();
    this.formGroup.controls.parent1BestWayToReachEmail.disable();
    this.formGroup.controls.parent2BestWayToReachEmail.disable();
    this.formGroup.controls.parent1BestWayToReachText.disable();
    this.formGroup.controls.parent2BestWayToReachText.disable();
    this.formGroup.controls.livesWith1BestWayToReachEmail.disable();
    this.formGroup.controls.livesWith2BestWayToReachEmail.disable();
    this.formGroup.controls.livesWith1BestWayToReachText.disable();
    this.formGroup.controls.livesWith2BestWayToReachText.disable();
  }

  setUpLivesWithValueChange() {
    this.addressAutoFill('parent1LivesWithChild', 'parent1StreetAddress', 'parent1City', 'parent1State', 'parent1ZipCode');
    this.addressAutoFill('parent2LivesWithChild', 'parent2StreetAddress', 'parent2City', 'parent2State', 'parent2ZipCode');
  }

  addressAutoFill(formControl: string, address: string, city: string, state: string, zipCode: string) {
    const form = this.isQuestionnaire ? 'questionnaire' : 'intake';

    // Initially Set
    if (this.formGroup.get(formControl).value) {
      this.formGroup.get(address).patchValue(this[form].childInfo.streetAddress);
      this.formGroup.get(city).patchValue(this[form].childInfo.city);
      this.formGroup.get(state).patchValue(this[form].childInfo.state);
      this.formGroup.get(zipCode).patchValue(this[form].childInfo.zipCode);
    }

    // On `LivesWith` Change
    this.formGroup.get(formControl).valueChanges.subscribe((livesWithChild) => {
      if (livesWithChild) {
        this.formGroup.get(address).setValue(this[form].childInfo.streetAddress);
        this.formGroup.get(city).setValue(this[form].childInfo.city);
        this.formGroup.get(state).setValue(this[form].childInfo.state);
        this.formGroup.get(zipCode).setValue(this[form].childInfo.zipCode);
      }
    });
  }

  clearParent2LivesWith() {
    const ctrl = this.formGroup.get('parent2LivesWithChild');
    ctrl.setValue(null);
    ctrl.updateValueAndValidity();
  }

  private initializeControls() {
    //#region Parent1
    this.formGroup.addControl(
      'parent1Name',
      new FormControl('', !this.isQuestionnaire ? [Validators.required, noNumbersValidator] : [noNumbersValidator])
    );
    this.formGroup.addControl('parent1Email', new FormControl('', [Validators.email]));
    this.formGroup.addControl(
      'parent1LivesWithChild',
      new FormControl(null, {
        updateOn: 'change',
        validators: !this.isQuestionnaire ? [Validators.required] : null,
      })
    );

    this.formGroup.addControl('parent1StreetAddress', new FormControl(''));
    this.formGroup.addControl('parent1City', new FormControl('', [noNumbersLimitedSpecialCharsValidator]));
    this.formGroup.addControl('parent1State', new FormControl(''));
    this.formGroup.addControl('parent1ZipCode', new FormControl('', [Validators.pattern(/(^\d{5}$)|(^\d{5}-\d{4}$)/)]));
    this.formGroup.addControl('parent1HomePhone', new FormControl('', phoneValidator));
    this.formGroup.addControl('parent1WorkPhone', new FormControl('', phoneValidator));
    this.formGroup.addControl('parent1CellPhone', new FormControl('', phoneValidator));
    this.formGroup.addControl('parent1BestWayToReachHomePhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent1BestWayToReachWorkPhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent1BestWayToReachCellPhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent1BestWayToReachEmail', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent1BestWayToReachText', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent1BestTimeToContact', new FormControl(null));
    //#endregion

    //#region Parent2
    this.formGroup.addControl('parent2Name', new FormControl('', noNumbersValidator));
    this.formGroup.addControl('parent2Email', new FormControl('', [Validators.email]));
    this.formGroup.addControl('parent2LivesWithChild', new FormControl(null, { updateOn: 'change' }));

    this.formGroup.addControl('parent2StreetAddress', new FormControl(''));
    this.formGroup.addControl('parent2City', new FormControl('', [noNumbersLimitedSpecialCharsValidator]));
    this.formGroup.addControl('parent2State', new FormControl(''));
    this.formGroup.addControl('parent2ZipCode', new FormControl('', [Validators.pattern(/(^\d{5}$)|(^\d{5}-\d{4}$)/)]));
    this.formGroup.addControl('parent2HomePhone', new FormControl('', phoneValidator));
    this.formGroup.addControl('parent2WorkPhone', new FormControl('', phoneValidator));
    this.formGroup.addControl('parent2CellPhone', new FormControl('', phoneValidator));
    this.formGroup.addControl('parent2BestWayToReachHomePhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent2BestWayToReachWorkPhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent2BestWayToReachCellPhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent2BestWayToReachEmail', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent2BestWayToReachText', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('parent2BestTimeToContact', new FormControl(null));
    //#endregion

    this.formGroup.addControl('othersInHome', new FormControl(''));

    //#region LivesWith1
    this.formGroup.addControl(
      'livesWith1Name',
      new FormControl('', !this.isQuestionnaire ? [Validators.required, noNumbersValidator] : [noNumbersValidator])
    );

    const setLivesWith1NameValidators = (parent1Name: string) => {
      const nameControl = this.formGroup.get('livesWith1Name');
      if (!parent1Name && !this.isQuestionnaire) {
        nameControl.setValidators([Validators.required, noNumbersValidator]);
      } else {
        nameControl.setValidators([noNumbersValidator]);
      }
      nameControl.updateValueAndValidity();
    };

    setLivesWith1NameValidators(this.formGroup.get('parent1Name').value);
    this.formGroup.get('parent1Name').valueChanges.subscribe(setLivesWith1NameValidators);

    this.formGroup.addControl('livesWith1Email', new FormControl('', [Validators.email]));
    this.formGroup.addControl('livesWith1RelationshipId', new FormControl(null, !this.isQuestionnaire ? [Validators.required] : null));

    const setLivesWith1Validators = (livesWith1Name: string) => {
      if (livesWith1Name) {
        this.formGroup.get('livesWith1RelationshipId').setValidators([Validators.required]);
      } else {
        this.formGroup.get('livesWith1RelationshipId').clearValidators();
      }
    };

    setLivesWith1Validators(this.formGroup.get('livesWith1Name').value);
    this.formGroup.get('livesWith1Name').valueChanges.subscribe(setLivesWith1Validators);

    this.formGroup.addControl('livesWith1StreetAddress', new FormControl(''));
    this.formGroup.addControl('livesWith1City', new FormControl('', [noNumbersLimitedSpecialCharsValidator]));
    this.formGroup.addControl('livesWith1State', new FormControl(''));
    this.formGroup.addControl('livesWith1ZipCode', new FormControl('', [Validators.pattern(/(^\d{5}$)|(^\d{5}-\d{4}$)/)]));
    this.formGroup.addControl('livesWith1HomePhone', new FormControl('', [phoneValidator]));
    this.formGroup.addControl('livesWith1WorkPhone', new FormControl('', [phoneValidator]));
    this.formGroup.addControl('livesWith1CellPhone', new FormControl('', [phoneValidator]));
    this.formGroup.addControl('livesWith1BestWayToReachHomePhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith1BestWayToReachWorkPhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith1BestWayToReachCellPhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith1BestWayToReachEmail', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith1BestWayToReachText', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith1BestTimeToContact', new FormControl(null));
    //#endregion

    //#region LivesWith2
    this.formGroup.addControl('livesWith2Name', new FormControl('', [noNumbersValidator]));
    this.formGroup.addControl('livesWith2Email', new FormControl('', [Validators.email]));
    this.formGroup.addControl('livesWith2RelationshipId', new FormControl(null));
    this.formGroup.addControl('livesWith2StreetAddress', new FormControl(''));
    this.formGroup.addControl('livesWith2City', new FormControl('', [noNumbersLimitedSpecialCharsValidator]));
    this.formGroup.addControl('livesWith2State', new FormControl(''));
    this.formGroup.addControl('livesWith2ZipCode', new FormControl('', [Validators.pattern(/(^\d{5}$)|(^\d{5}-\d{4}$)/)]));
    this.formGroup.addControl('livesWith2HomePhone', new FormControl('', [phoneValidator]));
    this.formGroup.addControl('livesWith2WorkPhone', new FormControl('', [phoneValidator]));
    this.formGroup.addControl('livesWith2CellPhone', new FormControl('', [phoneValidator]));
    this.formGroup.addControl('livesWith2BestWayToReachHomePhone', new FormControl('', { updateOn: 'change' }));
    this.formGroup.addControl('livesWith2BestWayToReachWorkPhone', new FormControl('', { updateOn: 'change' }));
    this.formGroup.addControl('livesWith2BestWayToReachCellPhone', new FormControl('', { updateOn: 'change' }));
    this.formGroup.addControl('livesWith2BestWayToReachEmail', new FormControl('', { updateOn: 'change' }));
    this.formGroup.addControl('livesWith2BestWayToReachText', new FormControl('', { updateOn: 'change' }));
    this.formGroup.addControl('livesWith2BestTimeToContact', new FormControl(null));
    //#endregion

    const formGroupValidators = !this.isQuestionnaire
      ? [
          phoneEmailValidator,
          parent2LivesWithValidator,
          bestWayToReachValidator('parent1'),
          bestWayToReachValidator('parent2'),
          livesWith2RelationshipValidator,
          bestWayToReachValidator('livesWith1'),
          bestWayToReachValidator('livesWith2'),
        ]
      : null;

    this.formGroup.setValidators(formGroupValidators);
    this.formGroup.updateValueAndValidity();
    this.formInitializationFlags.controlsInitialized = true;
    this.evaluateFormInitialization();
  }

  updateAddressFromZipCode(person: 'parent1' | 'parent2' | 'livesWith1' | 'livesWith2') {
    const zipCodeControl = this.formGroup.get(`${person}ZipCode`);
    const zipCode = zipCodeControl.value;
    if (!zipCodeControl.value || !zipCodeControl.valid) {
      return;
    }

    this.locationService.getLocationData(zipCode.substring(0, 5)).subscribe((res) => {
      if (res) {
        const update = {};
        update[`${person}City`] = res.city;
        update[`${person}State`] = 'IA';
        this.formGroup.patchValue(update);
      }
    });
  }

  clearEmail(person: string) {
    this.formGroup.get(`${person}Email`).setValue(null);
    this.formGroup.get(`${person}BestWayToReachEmail`).setValue(false);
  }

  private updateForm() {
    this.formGroup.patchValue(this.familyInfo);
    if (this.isQuestionnaire) {
      this.updateFieldsValueAndVisibility();
    }
    this.formInitializationFlags.dataLoaded = true;
    this.evaluateFormInitialization();
    this.setUpLivesWithValueChange();
    this.setLock();
  }

  private updateFieldsValueAndVisibility() {
    const referral = this.questionnaire.referral;
    /* If the questionnaire respondent is the same as the referral source,
       the API provides us with the referral record so we can hide certain fields. */
    if (referral) {
      this.formGroup.patchValue({});
      this.formGroup.setValidators([]);
      this.shownFields.canDisplayFamilyInfo = false;
    }
  }

  private evaluateFormInitialization() {
    if (this.formInitializationFlags.eventEmitted) {
      return;
    }
    if (this.formInitializationFlags.controlsInitialized && this.formInitializationFlags.dataLoaded) {
      this.formInitializationFlags.eventEmitted = true;
      this.formInitialized.emit();
    }
  }
}
