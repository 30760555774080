import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { TransitionCodesService } from 'src/app/shared/services/transition-codes/transition-codes.service';

@Component({
  selector: 'app-end-service',
  templateUrl: './end-service.component.html',
  styleUrls: ['./end-service.component.scss'],
})
export class EndServiceComponent implements OnInit {
  formControl: FormControl;
  endReasonOptions: KeyValuePair[] = [];
  isPK = false;

  get showWeightingWarning() {
    const codes = this.data?.serviceCodes?.map((x) => x.code);
    return codes?.includes('SD0') || codes?.includes('SA0');
  }

  constructor(private transitionCodesService: TransitionCodesService, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit(): void {
    this.isPK = this.data.isPk;
    this.transitionCodesService.get().subscribe((res) => {
      this.endReasonOptions = res
        .filter((x) => x.codeCategory === 'ServiceEnd' && x.codeType === 'Service' && x.transitionCodeDesignation === 'PartB')
        .map((x) => new KeyValuePair(x.id, x.label));

      let defaultValue = null;
      if (this.isPK) {
        defaultValue = res.find((x) => x.code === 'PKK').id;
      }
      this.formControl = new FormControl(defaultValue, Validators.required);
    });
  }
}
