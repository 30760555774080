import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { IfspServicesService } from 'src/app/ifsp/services/ifsp-service.service';
import { IfspRemovalComponent } from 'src/app/ifsp/shared/ifsp-removal/ifsp-removal.component';
import { ProgressMonitorCService } from 'src/app/monitoring-progress/services/progress-monitor-c.service';
import { MultiGraphViewModalComponent } from 'src/app/shared/components/multi-graph-view-modal/multi-graph-view-modal.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { DialogComingSoonComponent } from '../../../../../shared/components/coming-soon/coming-soon.component';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { IfspModification } from '../../../../models/ifsp-modification';
import { OutcomeDto } from '../../../../models/outcome-models';
import { OutcomeService } from '../../../../services/outcome.service';
import { IfspOutcomeInfoComponent } from '../../../../shared/ifsp-outcome-info/ifsp-outcome-info.component';
import { IfspOutcomeViewMoreComponent } from '../../../../shared/ifsp-outcome-view-more/ifsp-outcome-view-more.component';
import { IfspChildFamilyOutcomesComponent } from '../../../ifsp-child-family-outcomes/ifsp-child-family-outcomes.component';

@Component({
  selector: 'app-ifsp-details-outcomes',
  templateUrl: './ifsp-details-outcomes.component.html',
  styleUrls: ['./ifsp-details-outcomes.component.scss'],
})
export class IfspDetailsOutcomesComponent implements OnInit {
  @Input() modifications: IfspModification[];
  @Input() ifspStartDate;
  @Input() learnerId;

  get modification() {
    return this.modifications?.find((x) => !x.finalizeDate);
  }

  get modificationIsFinalized() {
    return this.modification?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.modifications && this.modifications.length > 0) {
      const latest = this.modifications.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  @ViewChild('outcomesComponent')
  outcomesComponent: IfspChildFamilyOutcomesComponent;

  @ViewChild('outcomesFormArea') outcomesFormArea: ElementRef;

  ifspId: string;
  dataSource = new MatTableDataSource([]);
  shortDateFormat = shortDateFormat;
  showOutcomesForm = false;
  modifyingOutcome: OutcomeDto;
  isEditing = false;

  displayedColumns = ['actions', 'outcome', 'outcomeArea', 'criteriaForAccomplishment', 'monitoringForProgress', 'outcomeEndDate'];

  get hasOpenModification() {
    return this.modification && this.modification !== null;
  }

  get modificationId() {
    return this.modification?.id;
  }

  constructor(
    private dialog: MatDialog,
    private ifspOutcomeService: OutcomeService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private ifspServiceService: IfspServicesService,
    private progressMonitorCService: ProgressMonitorCService
  ) {}

  ngOnInit(): void {
    this.ifspId = this.route.snapshot.paramMap.get('ifspId');
    this.dataSource = new MatTableDataSource([]);
    this.getOutcomes();
    this.ifspOutcomeService.outcomesUpdated$.subscribe(() => this.getOutcomes());
    this.ifspOutcomeService.outcomeAddNew$.subscribe(() => {
      this.showOutcomesForm = true;
      this.isEditing = true;
      this.modifyingOutcome = null;
      this.outcomesFormArea?.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    });
    this.ifspOutcomeService.outcomeClose$.subscribe(() => {
      this.showOutcomesForm = false;
      this.isEditing = false;
    });
    this.dataSource.sortingDataAccessor = (item: OutcomeDto, columnId) => {
      switch (columnId) {
        case 'criteriaForAccomplishment':
          return this.getCriteriaForAccomplishmentText(item);
        case 'outcomeArea':
          return this.getOutcomeAreaText(item);
        case 'outcome':
          return item.title;
        case 'monitoringForProgress':
          return this.getMonitoringProgressText(item);
        default:
          return item[columnId];
      }
    };
  }

  getOutcomes() {
    this.ifspOutcomeService.getAllForModification(this.ifspId, this.modification?.id).subscribe((result) => {
      this.dataSource.data = result;
    });
  }

  viewAuditLog(outcome) {
    const dialogRef = this.dialog.open(IfspOutcomeInfoComponent, {
      width: '758px',
      data: {
        outcome,
        id: outcome.id,
        ifspId: this.ifspId,
      },
    });
  }

  addNew() {
    this.ifspOutcomeService.outcomeAddNew.next();
  }

  onEdit(outcome: OutcomeDto) {
    const callback = (_outcome: OutcomeDto) => {
      this.showOutcomesForm = true;
      this.modifyingOutcome = _outcome;
      this.ifspOutcomeService.setModifyingOutcome.next(_outcome);
      this.isEditing = true;
    };
    if (outcome.modificationId === null || outcome.modificationId !== this.modificationId) {
      this.ifspOutcomeService.modify(this.ifspId, outcome.id, this.modificationId).subscribe((modifyResult) => {
        callback(modifyResult);
      });
    } else {
      callback(outcome);
    }
  }

  canModify(element) {
    return this.hasOpenModification && !element.modificationEndDate && !this.isEditing;
  }

  canEnd(element) {
    return (
      this.hasOpenModification &&
      !this.anyEndDatesEqualOrPast(element) &&
      (element?.isActive || (!element?.isActive && !!element?.priorVersionId))
    );
  }

  canRemove(element) {
    return (
      this.hasOpenModification &&
      !this.anyEndDatesInPast(element) &&
      !element?.priorVersionId &&
      element.modificationId === this.modificationId
    );
  }

  canUndo(element) {
    return (
      this.hasOpenModification &&
      !this.anyEndDatesInPast(element) &&
      element?.priorVersionId &&
      element.modificationId === this.modificationId
    );
  }

  canViewAuditLog(element) {
    return (
      this.hasOpenModification &&
      !this.anyEndDatesInPast(element) &&
      !this.isEditing &&
      (element?.isActive || element?.priorVersionId) &&
      !element?.modificationEndDate
    );
  }

  canViewGraphs(element) {
    return this.hasOpenModification && !this.isEditing && (element.isActive || element?.priorVersionId) && !element.modificationEndDate;
  }

  canAddProgressMonitoring(element) {
    return this.hasOpenModification && !this.isEditing && (element.isActive || element?.priorVersionId) && !element.modificationEndDate;
  }

  anyEndDatesInPast(outcome) {
    const outcomeModificationDate = dayjs(outcome.modificationEndDate).toDate().setHours(23, 59, 59, 9999);
    const today = dayjs().toDate().setHours(23, 59, 59, 9999);
    return outcomeModificationDate < today && outcome.isActive;
  }

  anyEndDatesEqualOrPast(outcome) {
    const outcomeModificationDate = dayjs(outcome.modificationEndDate).toDate().setHours(23, 59, 59, 9999);
    const today = dayjs().toDate().setHours(23, 59, 59, 9999);
    return outcomeModificationDate <= today && outcome.isActive;
  }

  getServicesForOutcome(outcome) {
    return this.ifspServiceService.currentServicesForModification
      .getValue()
      .some((x) => x.outcomes?.filter((y) => y.outcomeId === outcome.id).length > 0);
  }

  onRemove(outcome, isUndo = false) {
    if (!isUndo && this.getServicesForOutcome(outcome)) {
      this.notificationService.alert('Please remove outcome from service before removing.');
      return;
    }
    this.notificationService.confirmation(`Are you sure you want to ${isUndo ? 'undo' : 'delete'} this outcome?`, () => {
      this.ifspOutcomeService.deleteOutcome(this.ifspId, outcome.id, true).subscribe(() => {
        this.notificationService.success(`Outcome ${isUndo ? 'reverted' : 'deleted'}`);
      });
    });
  }

  showMenu(element) {
    return (
      this.canAddProgressMonitoring(element) ||
      element.displayGraph ||
      this.canModify(element) ||
      this.canUndo(element) ||
      this.canRemove(element) ||
      this.canViewAuditLog(element) ||
      this.canEnd(element)
    );
  }

  onViewMore(el) {
    const dialogRef = this.dialog.open(IfspOutcomeViewMoreComponent, {
      width: '758px',
      data: {
        outcome: el,
        modifications: this.modifications,
      },
    });
  }

  onEnd(outcome: OutcomeDto) {
    const dialogRef = this.dialog.open(IfspRemovalComponent, {
      data: {
        id: outcome.id,
        modificationId: this.modificationId,
        ifspId: this.ifspId,
        type: 'Outcome',
        title: outcome.title,
        startDate: this.ifspStartDate,
        subTitle: outcome.typeOfOutcome + ' Outcome',
        entity: outcome,
      },
    });
  }

  getOutcomeAreaText(element) {
    if (!element) return '';

    const outcomeAreas: string[] = [];
    outcomeAreas.push(...element.ecoAreas?.map((x) => x.ecoArea));
    outcomeAreas.push(...element.familyAreas?.map((x) => x.familyArea));

    return outcomeAreas.sort().join(', ');
  }

  getMonitoringProgressText(element) {
    if (!element) {
      return '';
    }
    return element.progresses?.map((x) => x.measuringMethod + ' - ' + x.frequencyNumber + ' per ' + x.frequencyPeriod).join(', ');
  }

  getCriteriaForAccomplishmentText(element) {
    if (!element) {
      return '';
    }
    element.criterias.sort((a, b) =>
      a.criteriaForAccomplishment > b.criteriaForAccomplishment ? 1 : b.criteriaForAccomplishment > a.criteriaForAccomplishment ? -1 : 0
    );
    return element.criterias?.map((x) => x.criteriaForAccomplishment.trim()).join(', ');
  }

  getOutcomeEndDateText(outcome) {
    return this.datePipe.transform(outcome.outcomeEndDate, shortDateFormat);
  }

  openComingSoon() {
    this.dialog.open(DialogComingSoonComponent, {
      width: '990px',
    });
  }

  onOpenGraph(outcome) {
    this.progressMonitorCService.getSingle(this.learnerId, outcome.id).subscribe({
      next: (res) => this.dialog.open(MultiGraphViewModalComponent, { data: { isIfsp: true, ifspOutcome: res }, width: '728px' }),
      error: (err) =>
        this.notificationService.errorWithAction("Couldn't open graph", 'Why?', () =>
          this.notificationService.alert(err.error, "Couldn't open graph")
        ),
    });
  }
}
