import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaluationUser } from '../models/evaluation-user';

@Injectable({
  providedIn: 'root',
})
export class EvaluationUserService {
  constructor(private readonly http: HttpClient) {}

  get(evaluationId: string): Observable<EvaluationUser[]> {
    return this.http.get<EvaluationUser[]>(this.baseUrl(evaluationId));
  }

  put(evaluationId: string, users: string[]) {
    return this.http.put(this.baseUrl(evaluationId), { users });
  }

  remove(evaluationId: string, userId: string) {
    return this.http.put(this.baseUrl(evaluationId) + userId, null);
  }

  private baseUrl(evaluationId: string) {
    return `api/evaluations/${evaluationId}/team/`;
  }
}
