<div class="mb-3">
  <h3 class="mt-0 mb-2" *ngIf="!isRLevelScore">
    ACHIEVE Recommendation
    {{ recommendedWeight ? ' - The recommended weighting for ' + caseSummary?.learner.firstName + ' is ' + recommendedWeight : '' }}
  </h3>

  <div *ngIf="dataSource.data.length > 0 && !isRLevelScore" class="table-overflow app-table">
    <table mat-table [dataSource]="dataSource" matSort class="w-100">
      <ng-container matColumnDef="reasonDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Recommendations</th>
        <td [ngClass]="{ total: element.reasonDescription === 'Total' }" mat-cell *matCellDef="let element">
          {{ element.reasonDescription }}
        </td>
      </ng-container>
      <ng-container matColumnDef="points">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Calculated Points</th>
        <td [ngClass]="{ total: element.reasonDescription === 'Total' }" mat-cell *matCellDef="let element">
          {{
            (hasSpecialCircumstance && element.reasonDescription === 'Total') || element.uniqueCircumstances
              ? 'Special Circumstance'
              : element.points ?? 'N/A'
          }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <div class="action__row">
    <div *ngIf="!showChangeWeighting && !isRLevelScore">
      <button
        mat-flat-button
        aria-label="Accept Suggestion"
        color="primary"
        class="mr-2"
        (click)="$event.stopPropagation(); acceptSuggestion()"
        tabindex="0"
      >
        Accept Suggestion
      </button>
      <button
        mat-raised-button
        aria-label="Choose Alternative"
        color="primary"
        *ngIf="!showAlternativeFields"
        class="mr-2"
        (click)="$event.stopPropagation(); toggleFields()"
        tabindex="0"
      >
        Choose Alternative
      </button>
    </div>
    <div *ngIf="showChangeWeighting && !showWeightingFields">
      <button
        mat-flat-button
        aria-label="Change Weighting"
        color="primary"
        class="mr-2"
        (click)="$event.stopPropagation(); toggleFields('weighting')"
        tabindex="0"
      >
        Change Weighting
      </button>
    </div>
  </div>

  <form [formGroup]="formGroup">
    <div *ngIf="showAlternativeFields || showWeightingFields">
      <app-autocomplete [options]="filteredMatrixLevels" formControlName="level" label="Select Level"></app-autocomplete>

      <app-textarea label="Comments" formControlName="comments"> </app-textarea>

      <div class="action__row">
        <button
          mat-raised-button
          aria-label="Cancel Button"
          color="accent"
          *ngIf="showAlternativeFields"
          class="mr-2"
          (click)="$event.stopPropagation(); toggleFields()"
          tabindex="0"
        >
          Cancel
        </button>

        <button
          *ngIf="showChangeWeighting && showWeightingFields"
          mat-flat-button
          aria-label="Cancel Button"
          color="accent"
          class="mr-2"
          (click)="$event.stopPropagation(); toggleFields('weighting')"
          tabindex="0"
        >
          Cancel
        </button>

        <button
          mat-raised-button
          aria-label="Submit"
          color="primary"
          [disabled]="formGroup.invalid"
          (click)="showAlternativeFields ? chooseAlternative() : changeWeighting()"
          tabindex="0"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
