import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SignedForm, SignedFormModel, SignedFormSignatory, SignedFormValue } from '@idoe/electronic-signature';

@Injectable({
  providedIn: 'root',
})
export class SignedFormService {
  private baseUrl = 'api/signedforms';

  constructor(private http: HttpClient) {}

  create(parentId: string, signedFormType: string, signatories: SignedFormSignatory[]) {
    return this.http.post<string>(`${this.baseUrl}/${parentId}/${signedFormType}`, signatories);
  }

  getFormForSignatory(token: string) {
    return this.http.get<SignedFormModel>(`${this.baseUrl}/signatory/${token}`);
  }

  getSignedFormPdf(signedFormId: string, token?: string) {
    let url = `${this.baseUrl}/${signedFormId}/pdf`;
    if (token) {
      url = url + `?token=${token}`;
    }
    return this.http.get<{ url: string }>(url).toPromise();
  }

  addSignature(signedFormId: string, signatoryToken: string, values: SignedFormValue[]) {
    return this.http.put(`${this.baseUrl}/add-signature/${signedFormId}/${signatoryToken}`, values);
  }

  getForm(signedFormId: string) {
    return this.http.get<SignedForm>(`${this.baseUrl}/${signedFormId}`);
  }

  revokeSignatureInvitation(signatoryId: string) {
    return this.http.put(`${this.baseUrl}/revoke/${signatoryId}`, null);
  }

  addSignatory(signatory: SignedFormSignatory) {
    return this.http.post<SignedFormSignatory>(`${this.baseUrl}/signatory`, signatory);
  }

  resendSignatureEmail(signatoryId: string) {
    return this.http.put(`${this.baseUrl}/resend/${signatoryId}`, null);
  }
}
