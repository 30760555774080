import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CaseSummary } from '../../../../../../shared/models/case';
import { ActivatedRoute } from '@angular/router';
import { CaseService } from '../../../../../../shared/services/case/case.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-data-history-part-b-progress-monitoring',
  templateUrl: './data-history-part-b-progress-monitoring.component.html',
  styleUrls: ['./data-history-part-b-progress-monitoring.component.scss'],
})
export class DataHistoryPartBProgressMonitoringComponent implements OnInit, OnDestroy {
  @Input() caseId = '';
  @Input() public case: CaseSummary;
  private subscriptions = new Subscription();
  constructor() {}

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
