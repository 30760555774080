import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LearnerManagementModule } from '../learner-management/learner-management.module';
import { AppMatModule } from '../shared/app-mat.module';
import { SharedModule } from '../shared/shared.module';
import { EarlyAccessIntakeModule } from './early-access-intake/early-access-intake.module';
import { EvaluationRoutingModule } from './evaluation-routing.module';
import { EerComponent } from './evaluation/eer/eer.component';
import { EvaluationAssessmentInfoComponent } from './evaluation/evaluation-assessment-info/evaluation-assessment-info.component';
import { EligibilityInfoComponent } from './evaluation/evaluation-header/eligibility-info/eligibility-info.component';
import { EvaluationDomainsComponent } from './evaluation/evaluation-header/evaluation-domains/evaluation-domains.component';
import { EvaluationHeaderComponent } from './evaluation/evaluation-header/evaluation-header.component';
import { EvaluationNavComponent } from './evaluation/evaluation-header/evaluation-nav/evaluation-nav.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { ContinuedEligibilityComponent } from './evaluation/steps/continued-eligibility/continued-eligibility.component';
import { EcoWizardComponent } from './evaluation/steps/eco-wizard/eco-wizard.component';
import { LearnerPerformanceService } from './evaluation/steps/eval-details-part-b/learner-performance/learner-performance.service';
import { EditMatrixItemComponent } from './evaluation/steps/eval-eco-matrix/edit-matrix-item/edit-matrix-item.component';
import { EvalEcoMatrixComponent } from './evaluation/steps/eval-eco-matrix/eval-eco-matrix.component';
import { EvaluationDetailsComponent } from './evaluation/steps/evaluation-details/evaluation-details.component';
import { EvaluationDocumentationComponent } from './evaluation/steps/evaluation-details/evaluation-documentation/evaluation-documentation.component';
import { SelectDocumentationModalComponent } from './evaluation/steps/evaluation-details/evaluation-documentation/modals/select-documentation-modal.component';
import { EvaluationInterviewComponent } from './evaluation/steps/evaluation-details/evaluation-interview/evaluation-interview.component';
import { RoutineAddModalComponent } from './evaluation/steps/evaluation-details/evaluation-interview/modals/routine-add-modal.component';
import { EvaluationNoteComponent } from './evaluation/steps/evaluation-details/evaluation-note/evaluation-note.component';
import { EvaluationTagsComponent } from './evaluation/steps/evaluation-details/evaluation-note/evaluation-tags/evaluation-tags.component';
import { EvaluationObservationComponent } from './evaluation/steps/evaluation-details/evaluation-observation/evaluation-observation.component';
import { EvaluationTestComponent } from './evaluation/steps/evaluation-details/evaluation-test/evaluation-test.component';
import { EvaluationEligibilityWorksheetComponent } from './evaluation/steps/evaluation-eligibility-worksheet/evaluation-eligibility-worksheet.component';
import { EvaluationOverviewComponent } from './evaluation/steps/evaluation-overview/evaluation-overview.component';
import { EvaluationAssessmentReportModalComponent } from './evaluation/steps/evaluation-overview/modals/evaluation-assessment-report-modal/evaluation-assessment-report-modal.component';
import { EvaluationSummaryDomainsComponent } from './evaluation/steps/evaluation-summary/evaluation-summary-domains/evaluation-summary-domains.component';
import { EvaluationSummaryComponent } from './evaluation/steps/evaluation-summary/evaluation-summary.component';
import { EvaluationAssessmentTableComponent } from './evaluation/tables/evaluation-assessment-table/evaluation-assessment-table.component';
import { EvaluationAssignmentsTableComponent } from './evaluation/tables/evaluation-assignments-table/evaluation-assignments-table.component';
import { RemoveAssignmentComponent } from './evaluation/tables/evaluation-assignments-table/remove-assignment/remove-assignment.component';
import { EvaluationRoutinesTableComponent } from './evaluation/tables/evaluation-routines-table/evaluation-routines-table.component';
import { EvaluationTeamTableComponent } from './evaluation/tables/evaluation-team-table/evaluation-team-table.component';
import { FamilySurveyComponent } from './family-survey/family-survey.component';
import { DomainAssignModalComponentPartB } from './modals/domain-assign-modal-partb/domain-assign-modal-partb.component';
import { DomainAssignModalComponent } from './modals/domain-assign-modal/domain-assign-modal.component';
import { EcoMatrixModalComponent } from './modals/eco-matrix-modal/eco-matrix-modal.component';
import { ReassignFacilitatorComponent } from './modals/reassign-facilitator/reassign-facilitator.component';
import { SurveyInviteCreatorModalComponent } from './modals/survey-invite-creator-modal/survey-invite-creator-modal.component';
import { EvaluationMethodComponent } from './shared/components/evaluation-method/evaluation-method.component';
import { SurveyResponsesComponent } from './survey-responses/survey-responses.component';

@NgModule({
  imports: [
    AppMatModule,
    EvaluationRoutingModule,
    CommonModule,
    DragDropModule,
    EarlyAccessIntakeModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    LearnerManagementModule,
    AngularSvgIconModule,
  ],
  declarations: [
    EvaluationComponent,
    EvaluationHeaderComponent,
    EvaluationNavComponent,
    EvaluationAssessmentTableComponent,
    EvaluationAssessmentInfoComponent,
    EvaluationDomainsComponent,
    EvaluationTeamTableComponent,
    EvaluationOverviewComponent,
    EvaluationRoutinesTableComponent,
    EligibilityInfoComponent,
    EvaluationAssignmentsTableComponent,
    DomainAssignModalComponent,
    DomainAssignModalComponentPartB,
    SurveyInviteCreatorModalComponent,
    EvaluationDetailsComponent,
    EvaluationMethodComponent,
    EvaluationTagsComponent,
    EvaluationNoteComponent,
    EvaluationDocumentationComponent,
    EvaluationObservationComponent,
    EvaluationInterviewComponent,
    SelectDocumentationModalComponent,
    EvaluationTestComponent,
    RoutineAddModalComponent,
    ReassignFacilitatorComponent,
    EvaluationSummaryComponent,
    EvaluationSummaryDomainsComponent,
    EvaluationEligibilityWorksheetComponent,
    EvalEcoMatrixComponent,
    EditMatrixItemComponent,
    EcoWizardComponent,
    EerComponent,
    RemoveAssignmentComponent,
    EcoMatrixModalComponent,
    FamilySurveyComponent,
    SurveyResponsesComponent,
    ContinuedEligibilityComponent,
    EvaluationAssessmentReportModalComponent,
  ],
  exports: [
    EvaluationAssessmentTableComponent,
    EvaluationAssessmentInfoComponent,
    EvaluationMethodComponent,
    EligibilityInfoComponent,
    EvaluationDomainsComponent,
    EvaluationTagsComponent,
  ],
  providers: [LearnerPerformanceService, DatePipe],
})
export class EvaluationModule {}
