import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';

@Component({
  selector: 'app-st-area-of-need',
  templateUrl: './st-area-of-need.component.html',
  styleUrls: ['./st-area-of-need.component.scss'],
})
export class StAreaOfNeedComponent implements OnInit {
  preferenceInterestDisplayedColumns = ['preferenceInterest', 'llwTags', 'iepTags', 'dataSource'];
  preferenceInterestDataSource = new MatTableDataSource([
    {
      preferenceInterest: 'Robert is most engaged in classroom activities such as',
      llwTags: '',
      iepTags: '',
      dataSource: '',
    },
  ]);

  dataSourceOptions: KeyValuePair[] = [new KeyValuePair('1', 'Transition Assessment 1'), new KeyValuePair('2', 'Transition Assessment 2')];

  constructor() {}

  ngOnInit(): void {}
}
