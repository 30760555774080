import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  NgControl,
  ValidatorFn,
  ValidationErrors,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { KeyValuePair } from '../../../models/key-value-pair';
import { LoggerService } from '../../../services/logger/logger.service';
import { BaseWrapperComponent } from '../base-wrapper.component';
import { TextFieldComponent } from '../text-field/text-field.component';
import { MatDialog } from '@angular/material/dialog';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { WTimeDialogComponent } from '@candidosales/material-time-picker';
import { ITime } from '@candidosales/material-time-picker/lib/w-clock/w-clock.component';

const timeFormatValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9] ([AaPp][Mm])$/i;

  return control.value && !timePattern.test(control.value) ? { invalidTimeFormat: true } : null;
};
@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class TimepickerComponent extends TextFieldComponent implements OnInit, OnChanges {
  @Output() changeTime: EventEmitter<ITime> = new EventEmitter<ITime>();

  @Input() userTime: ITime;

  constructor(
    public dialog: MatDialog,
    logger: LoggerService,
    @Self()
    @Optional()
    ngControl: NgControl,
    learnerService: LearnerService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(dialog, logger, ngControl, learnerService, changeDetectorRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (!this.userTime) {
      this.userTime = {
        hour: 12,
        minute: 0,
        meriden: 'AM',
        format: 12,
      };
    }

    const errors: any = this.control.validator && this.control.validator(new FormControl());
    const controlIsRequired = errors !== null && errors.required;
    if (controlIsRequired) {
      this.control.setValidators([timeFormatValidator, Validators.required]);
    } else {
      this.control.setValidators(timeFormatValidator);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  onBlurEvt(event) {
    const noSpacePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]([AaPp][Mm])$/i;
    const noTimePeriodPattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i;
    if (noSpacePattern.test(this.control.value)) {
      this.control.setValue(this.control.value.replace(this.control.value.slice(-2), ' ' + this.control.value.slice(-2)?.toUpperCase()));
    }

    if (noTimePeriodPattern.test(this.control.value)) {
      this.control.setValue(this.control.value.replace(this.control.value, this.control.value + ' PM'));
    }

    // Seems the pm/am needs to be in caps to prevent bug 6173 so do a final check and if not uppercase set it to uppercase
    if (this.control.value && this.control.value !== this.control.value.toUpperCase()) {
      this.control.setValue(this.control.value.toUpperCase());
    }
  }

  showPicker() {
    const dialogRef = this.dialog.open(WTimeDialogComponent, {
      data: {
        time: {
          hour: this.userTime.hour,
          minute: this.userTime.minute,
          meriden: this.userTime.meriden,
          format: this.userTime.format,
        },
        color: 'primary',
        revertLabel: 'Cancel',
        submitLabel: 'Ok',
      },
    });

    dialogRef.afterClosed().subscribe((result: ITime | -1) => {
      // result will be update userTime object or -1 or undefined (closed dialog w/o clicking cancel)
      if (result === undefined) {
        return;
      } else if (result !== -1) {
        const minute = result.minute < 10 ? `0${result.minute}` : `${result.minute}`;
        this.control.setValue(`${result.hour}:${minute} ${result.meriden}`);
        this.emitChange(result);
      }
    });
    return false;
  }

  private emitChange(data) {
    this.changeTime.emit(data);
  }
}
