<a class="text-underline" (click)="onReopenCase()" *ngIf="!isButton && !activeCall" tabindex="0" role="link" aria-labelledby="Reopen Case">
  Reopen Case
</a>
<button
  mat-raised-button
  aria-labelledby="Reopen Case"
  color="primary"
  (click)="onReopenCase()"
  *ngIf="isButton"
  tabindex="0"
  [disabled]="activeCall"
>
  Reopen Case
</button>

<ng-template #reopenModal>
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Reopen Case Confirmation</h3>

  <div mat-dialog-content class="py-2">
    <mat-card class="my-0 card--plain card--shadow">
      <p class="my-0">Are you sure you want to reopen the ACHIEVE case for {{ caseSummary?.learner?.fullName }}?</p>
      <hr class="input__divider my-2" />

      <ng-container *ngIf="isMskBasedReopen">
        <ng-container *ngIf="isMskBasedReopen">
          <form [formGroup]="formGroup">
            <fieldset>
              <app-radio-group
                label="Please select the option below that best describes the status of this case"
                formControlName="reopenStatus"
                [options]="reopenStatusOptions"
                [wrap]="true"
                [column]="true"
              ></app-radio-group>
            </fieldset>
          </form>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isTimeBasedReopen || (isMskBasedReopen && !formGroup.invalid && isMskTimeBasedReopen)">
        <hr class="input__divider my-2" />

        <h3 class="card-spacing-top mb-2">Reminder(s) for Team if Case is Reopened</h3>
        <ng-container *ngIf="daysSinceExited < daysInAYear">
          <p class="my-0">Review the current IEP and consider whether changes are necessary.</p>
        </ng-container>

        <ng-container *ngIf="daysSinceExited > daysInAYear - 1 && daysSinceExited < reevaluationDueOn + 1">
          <p class="my-0">Review the previous IEP. Complete a new IEP or interim IEP and determine whether a reevaluation is necessary.</p>
        </ng-container>

        <ng-container *ngIf="daysSinceExited > reevaluationDueOn">
          <p class="my-0">Complete a reevaluation and develop a new IEP if student is determined eligible.</p>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isMskBasedReopen && formGroup.controls.reopenStatus.value === reopenStatus.ActiveIepInAnotherState">
        <hr class="input__divider my-2" />

        <h3 class="card-spacing-top mb-2">Reminder(s) for Team if Case is Reopened</h3>
        <p class="my-0">Review the current IEP and consider whether changes are necessary.</p>
      </ng-container>

      <ng-container *ngIf="isMskBasedReopen && formGroup.controls.reopenStatus.value === reopenStatus.NotEligibleInAnotherState">
        <hr class="input__divider my-2" />

        <h3 class="card-spacing-top mb-2">Reminder(s) for Team if Case is Reopened</h3>
        <p class="my-0">Need to begin the Disability Suspected process again.</p>
      </ng-container>

      <ng-container *ngIf="isEligibilityBasedReopen">
        <h3 class="card-spacing-top mb-2">Reminder(s) for Team if Case is Reopened</h3>
        <p class="my-0">Need to begin the Disability Suspected process again.</p>
      </ng-container>

      <ng-container *ngIf="contactSuperAdmin">
        <hr class="input__divider my-2" *ngIf="isMskBasedReopen" />

        <h3 class="card-spacing-top mb-2">Reminder(s) for Team if Case is Reopened</h3>
        <p class="my-0">
          Please contact Super Admin to request correction.
          <a href="mailTo:super-admin@iowaachieve.com">Email Super Admin</a>
        </p>
      </ng-container>
    </mat-card>
  </div>

  <div mat-dialog-actions class="w-100 display-flex justify-content-end align-items-center">
    <button mat-button aria-label="Cancel Button" class="background-color--muted" [mat-dialog-close]="true" cdkFocusInitial tabindex="0">
      Cancel
    </button>
    <button
      mat-raised-button
      aria-label="Confirm"
      color="primary"
      (click)="reopen()"
      [disabled]="formGroup.invalid || activeCall"
      *ngIf="!contactSuperAdmin"
      tabindex="0"
    >
      Confirm
    </button>
  </div>
</ng-template>
