import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ViewMoreModalComponent, ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';

export const needsLabelOffset = (obj: any): boolean => {
  let response = false;

  if (obj) {
    for (const [key, value] of Object.entries(obj)) {
      if (value && value.toString().length > 50) {
        response = true;
      }
    }
  }

  return response;
};

export const openViewMore = (dialog: MatDialog, data: ViewMoreModalData[]): MatDialogRef<ViewMoreModalComponent, any> => {
  return dialog.open(ViewMoreModalComponent, {
    data,
    width: '990px',
  });
};
