import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Building } from 'src/app/shared/models/building';
import { IntakeType } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { LearnerSummary } from 'src/app/shared/models/learner';
import { BuildingService } from 'src/app/shared/services/building/building.service';
import { DistrictService } from 'src/app/shared/services/district/district.service';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-learner-info',
  templateUrl: './learner-info.component.html',
  styleUrls: ['./learner-info.component.scss'],
})
export class LearnerInfoComponent implements OnInit, DoCheck, OnDestroy {
  private sub = new Subscription();
  @Input() learner: LearnerSummary;
  isPartB: boolean;
  districts: KeyValuePair[];
  private allBuildings: Building[];
  buildings: KeyValuePair[];
  formGroup: FormGroup;
  isEditable = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly districtService: DistrictService,
    private readonly buildingService: BuildingService,
    private readonly learnerService: LearnerService,
    private readonly notificationService: NotificationService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isPartB = this.learner.activeCases?.some((x) => x?.intakeType === IntakeType.PartB);
    this.isEditable = this.authService.isSuperAdmin || this.authService.isDataLead || this.authService.isDataTechnician;
    if (this.isPartB) {
      this.districtService.getDistricts().subscribe((list) => (this.districts = list.map((x) => new KeyValuePair(x.id, x.name))));
      this.buildingService.getAllBuildings().subscribe((list) => {
        this.allBuildings = list;
        this.setDomicileBuildings(this.learner.domicileDistrictId);
      });

      this.formGroup = this.fb.group({
        domicileDistrictId: [this.learner.domicileDistrictId, Validators.required],
        domicileBuildingId: [this.learner.domicileBuildingId, Validators.required],
        hostDistrictId: this.learner.hostDistrictId,
      });

      if (!this.isEditable) {
        this.formGroup.controls['domicileDistrictId'].disable();
        this.formGroup.controls['hostDistrictId'].disable();
      }

      this.sub.add(this.formGroup.controls.domicileDistrictId.valueChanges.subscribe((v) => this.setDomicileBuildings(v)));
    }
  }

  ngDoCheck() {
    if (!this.isEditable) {
      this.hideAutocompleteCarets();
    }
  }

  private setDomicileBuildings(districtId: string) {
    const dbCtl = this.formGroup.controls.domicileBuildingId;
    if (districtId !== this.formGroup.value.domicileDistrictId) {
      dbCtl.patchValue(null);
    }
    if (!districtId) {
      dbCtl.disable();
    } else {
      this.buildings = this.allBuildings.filter((x) => x.districtId === districtId).map((x) => new KeyValuePair(x.id, x.name));
      dbCtl.enable();
    }
    if (!this.isEditable) {
      dbCtl.disable();
    }
  }

  hideAutocompleteCarets() {
    [].forEach.call(document.querySelectorAll('.form__carrot'), function (el) {
      el.style.display = 'none';
    });
  }

  save() {
    if (!this.formGroup.valid) return;

    const v = this.formGroup.value;
    this.learnerService.updateDomicileAndHostLocation(this.learner.id, v).subscribe((res) => {
      if (res.succeeded) {
        this.learner.domicileDistrictId = v.domicileDistrictId;
        this.learner.domicileBuildingId = v.domicileBuildingId;
        this.learner.hostDistrictId = v.hostDistrictId;
        this.notificationService.success('Saved successfully.');
        this.cancel();
        return;
      }

      const errorMsg = (res.errors?.length ? res.errors[0].description : null) ?? 'Something went wrong!';
      this.notificationService.error(errorMsg);
    });
  }

  cancel() {
    this.formGroup.patchValue({
      domicileDistrictId: this.learner.domicileDistrictId,
      domicileBuildingId: this.learner.domicileBuildingId,
      hostDistrictId: this.learner.hostDistrictId,
    });
    this.formGroup.markAsUntouched();
    this.formGroup.markAsPristine();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
