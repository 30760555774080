import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { RegisterModel } from '../auth-models';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  registerError: string;
  constructor(private breakpointObserver: BreakpointObserver, private authService: AuthService) {}

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  ngOnInit() {}

  onSubmit(creds: RegisterModel) {
    this.registerError = undefined;
    this.authService.register(creds).subscribe(
      () => {
        if (this.authService.isAuthenticated) {
          this.authService.navigatePostAuth();
        }
      },
      (error: HttpErrorResponse) => {
        this.registerError = error.error.complexity ? error.error.complexity : '';
      }
    );
  }
}
