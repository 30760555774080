import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EcoArea, EcoAreaType } from 'src/app/evaluation/models/evaluation';
import { Plod } from 'src/app/ifsp/models/plod';
import { EcoRating, EcoRatingType } from './eco-rating';

@Injectable({
  providedIn: 'root',
})
export class EcoRatingService {
  constructor(private http: HttpClient) {}

  get(typeId: string, ecoAreaId: string, type: EcoRatingType, amendmentId = '') {
    let url = '';
    switch (type) {
      case EcoRatingType.Iep:
        url = `api/iep/${typeId}/plaafp/eco-ratings/${ecoAreaId}/${amendmentId}`;
        break;
      case EcoRatingType.Ifsp:
        url = `api/ifsp/${typeId}/plod/eco-ratings/${ecoAreaId}/${amendmentId}`;
        break;
      case EcoRatingType.Evaluation:
        url = `api/evaluations/${typeId}/eco-ratings/${ecoAreaId}`;
        break;
      case EcoRatingType.Transition:
        url = `api/TransitionPlanning/${typeId}/eco-ratings/${ecoAreaId}`;
        break;
      case EcoRatingType.Case:
        url = `api/cases/${typeId}/eco-ratings/${ecoAreaId}`;
        break;
    }

    return this.http.get<EcoRating>(url);
  }

  getAll(typeId: string, type: EcoRatingType) {
    let url = '';
    switch (type) {
      case EcoRatingType.Iep:
        url = `api/iep/${typeId}/plaafp/eco-ratings`;
        break;
      case EcoRatingType.Ifsp:
        url = `api/ifsp/${typeId}/plod/eco-ratings`;
        break;
      case EcoRatingType.Evaluation:
        url = `api/evaluations/${typeId}/eco-ratings`;
        break;
      case EcoRatingType.FinalEcoPkCase:
        url = `api/cases/${typeId}/eco-ratings/final-eco-pk`;
    }

    return this.http.get<EcoRating[]>(url);
  }

  save(typeId: string, ecoAreaId: string, ecoRating: EcoRating, type: EcoRatingType) {
    let createUrl = '';
    let updateUrl = '';
    switch (type) {
      case EcoRatingType.Iep:
        createUrl = `api/iep/${typeId}/plaafp/eco-ratings/${ecoAreaId}`;
        updateUrl = `api/iep/${typeId}/plaafp/eco-ratings/${ecoRating.id}`;
        ecoRating.iepId = typeId;
        break;
      case EcoRatingType.Ifsp:
        createUrl = `api/ifsp/${typeId}/plod/eco-ratings/${ecoAreaId}`;
        updateUrl = `api/ifsp/${typeId}/plod/eco-ratings/${ecoRating.id}`;
        ecoRating.ifspId = typeId;
        break;
      case EcoRatingType.Evaluation:
        createUrl = `api/evaluations/${typeId}/eco-ratings/${ecoAreaId}`;
        updateUrl = `api/evaluations/${typeId}/eco-ratings/${ecoRating.id}`;
        ecoRating.evaluationId = typeId;
        break;
      case EcoRatingType.Transition:
        createUrl = `api/TransitionPlanning/${typeId}/eco-ratings/${ecoAreaId}`;
        updateUrl = `api/TransitionPlanning/${typeId}/eco-ratings/${ecoRating.id}`;
        break;
      case EcoRatingType.Case:
        createUrl = `api/cases/${typeId}/eco-ratings/${ecoAreaId}`;
        updateUrl = `api/cases/${typeId}/eco-ratings/${ecoRating.id}`;
        ecoRating.caseId = typeId;
        break;
      case EcoRatingType.FinalEcoPkCase:
        createUrl = `api/cases/${typeId}/eco-ratings/${ecoAreaId}/final-eco-pk`;
        updateUrl = `api/cases/${typeId}/eco-ratings/${ecoRating.id}/final-eco-pk`;
        ecoRating.finalEcoPkCaseId = typeId;
    }

    if (!ecoRating.id) {
      return this.http.post<EcoRating>(createUrl, ecoRating);
    } else {
      return this.http.put<EcoRating>(updateUrl, ecoRating);
    }
  }

  getSuggestedEcoRatingText(suggestedScore, learnersName) {
    switch (suggestedScore) {
      case 1:
        return `${learnersName} has very early skills in this area, meaning that ${learnersName} has the skills we would expect of a much younger child.`;
      case 2:
        return `${learnersName} has skills like that of a much younger child in this area, but is beginning to show some of the early skills that are necessary for development of more advanced skills.`;
      case 3:
        return `${learnersName} uses many early skills in this area that are necessary for development of more advanced skills, but is not yet using skills expected for this age.`;
      case 4:
        return `${learnersName} shows occasional use of some age-expected skills in this area, but has more skills of a younger child.`;
      case 5:
        return `${learnersName} shows many age-expected skills in this area, but continues to show some skills of a younger child.`;
      case 6:
        return `${learnersName} has the skills that we would expect for this age in this area, but there are some concerns that should be monitored.`;
      case 7:
        return `${learnersName} has all of the skills that we would expect for this age in this area and there are no concerns.`;
      default:
        return '';
    }
  }

  createFormGroup(result: EcoRating, ecoArea: EcoArea, plod: Plod, learnersFirstName: string) {
    return new FormGroup({
      id: new FormControl(ecoArea.id),
      score: new FormControl(result ? result.score : null),
      priorVersionId: new FormControl(result?.priorVersionId),
      priorVersionScore: new FormControl(result ? result?.priorVersion?.score : null),
      scoreText: new FormControl(result ? this.getSuggestedEcoRatingText(result.score, learnersFirstName) : null),
      priorVersionScoreText: new FormControl(
        result ? this.getSuggestedEcoRatingText(result?.priorVersion?.score, learnersFirstName) : null
      ),
      label: new FormControl(ecoArea.label),
      type: new FormControl(ecoArea.type),
      taggedAreas: new FormControl(this.getTaggedAreas(ecoArea.type, plod) || []),
      ecoAreaType: new FormControl(ecoArea.type),
      savedDescription: new FormControl(this.getDescriptionValue(ecoArea.type, plod)),
      description: new FormControl(this.getDescriptionValue(ecoArea.type, plod)),
      priorVersionDescription: new FormControl(plod.priorVersionId ? this.getDescriptionValue(ecoArea.type, plod.priorVersion) : null),
    });
  }

  getTaggedAreas(type: string, plod: Plod) {
    if (type === EcoAreaType.AcquisitionSkills) {
      return plod.acquisitionSkillsTaggedAreas;
    }

    if (type === EcoAreaType.AppropriateBehaviors) {
      return plod.appropriateNeedsTaggedAreas;
    }

    if (type === EcoAreaType.PositiveSocialEmotionalSkills) {
      return plod.positiveSkillsTaggedAreas;
    }
  }

  getDescriptionValue(type: string, plod: Plod) {
    if (type === EcoAreaType.AcquisitionSkills) {
      return plod.acquisitionSkills;
    }

    if (type === EcoAreaType.AppropriateBehaviors) {
      return plod.appropriateNeeds;
    }

    if (type === EcoAreaType.PositiveSocialEmotionalSkills) {
      return plod.positiveSkills;
    }
  }
}
