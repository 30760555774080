import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EvaluationProxyService } from 'src/app/evaluation/services/evaluation-proxy.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { AlertConfig } from 'src/app/shared/components/page-alert/page-alert.component';
import { EvaluationDomain } from 'src/app/shared/models/evaluation-domain';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { EvaluationDomainService } from 'src/app/shared/services/evaluation-domains/evaluation-domain.service';
import {
  EarlyAccessPriorWrittenNotice,
  PriorWrittenNoticeType,
} from '../../../../shared/models/prior-written-notices/early-access-prior-written-notice';
import { CanComponentDeactivate, DeactivationService } from '../../../../shared/services/deactivation.service';
import { MemoryStorageService } from '../../../../shared/services/memory-storage/memory-storage.service';
import { PriorWrittenNoticeService } from '../../../../shared/services/prior-written-notices/prior-written-notice.service';
import { NewWindowConfig, openNewWindow } from '../../../../shared/windowHelpers';
import { EligibilityBasedOn, EligibilityIcoReason, EligibilityIcoSource, Evaluation } from '../../../models/evaluation';
import { EvaluationService } from '../../../services/evaluation.service';
import { reasonsValidator, sourcesValidator, summaryValidator } from './evaluation-summary.validators';

@Component({
  selector: 'app-evaluation-summary',
  templateUrl: './evaluation-summary.component.html',
  styleUrls: ['./evaluation-summary.component.scss'],
})
export class EvaluationSummaryComponent extends BaseComponent implements OnInit, CanComponentDeactivate {
  currentEvaluation: Evaluation;
  eligibilityBasedOn = EligibilityBasedOn;
  caseEligibility?: boolean;
  @Input() caseId: string;
  @Input() areas: EvaluationDomain[];
  selectedAreas: string[] = [];
  loading = false;
  activeCall = false;
  disableUndoButton = false;
  eligibleConditionAlert: AlertConfig = {
    status: 'info',
    message: 'If the child is Eligible based on a Diagnosed Condition, the Intake - Medical Diagnosis section must be completed.',
  };

  basedOnOptions: KeyValuePair[] = [
    new KeyValuePair('QuarterOrMore', '25% Delay or Greater'),
    new KeyValuePair('DocumentedDelay', 'Documented Delay or Diagnosis'),
    new KeyValuePair('InformedClinicalOpinion', 'Informed Clinical Opinion'),
  ];

  eligibilityDecisionOptions: KeyValuePair[] = [new KeyValuePair(true, 'Eligible'), new KeyValuePair(false, 'Not Eligible')];

  formGroup = this.formBuilder.group(
    {
      eligibilitySubmittedOnEvaluation: [''],
      eligibilityBasedOn: [''],
      twentyFiveDelayAreas: [''],
      eligibilityIcoSources: this.getIcoSources(),
      eligibilityIcoReasons: this.getIcoReasons(),
      isEligibilityDecisionFinalized: false,
    },
    {
      validators: [sourcesValidator, reasonsValidator],
    }
  );

  save = new Observable<boolean>((observer) => {
    this.cd.detectChanges();
    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };
    if (!this.formGroup.dirty || this.isSaving) {
      done();
      return;
    }
    this.isSaving = true;
    this.saveForm().subscribe((x) => {
      this.currentEvaluation = x;
      this.formGroup.reset(this.formGroup.value);
      this.storage.setKey('currentEvaluation', this.currentEvaluation);
      done();
    });
  });

  get eligibilityIcoSources() {
    return (this.formGroup.controls.eligibilityIcoSources as FormArray).controls as FormGroup[];
  }
  get eligibilityIcoReasons() {
    return (this.formGroup.controls.eligibilityIcoReasons as FormArray).controls as FormGroup[];
  }

  get rawEligibilityBasedOn() {
    const rawValues = this.formGroup.getRawValue();
    return rawValues['eligibilityBasedOn'];
  }

  get documentedDelayItems() {
    return this.currentEvaluation.diagnosisFromIntake;
  }

  constructor(
    private readonly pwnService: PriorWrittenNoticeService,
    private readonly storage: MemoryStorageService,
    private readonly formBuilder: FormBuilder,
    private readonly evaluationService: EvaluationService,
    private readonly evaluationProxyService: EvaluationProxyService,
    private readonly cd: ChangeDetectorRef,
    deactivationService: DeactivationService,
    private readonly evaluationDomainService: EvaluationDomainService,
    private readonly router: Router,
    private dialog: MatDialog
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.currentEvaluation = this.storage.getKey('currentEvaluation', true);
    this.caseId = this.currentEvaluation.caseId;
    this.caseEligibility = this.currentEvaluation.caseEligibilityDecision;
    this.watchEligibilityBasedOn();
    this.watchEligibilityBasedOnOption();
    this.patchForm();

    if (!!this.caseEligibility && !this.currentEvaluation.eligibilitySubmittedOnEvaluation) {
      this.formGroup.get('eligibilitySubmittedOnEvaluation').setValue(true);
      this.formGroup.get('eligibilitySubmittedOnEvaluation').disable();
      this.formGroup.get('eligibilityBasedOn').setValue('DocumentedDelay');
      this.formGroup.get('eligibilityBasedOn').disable();
    }

    this.evaluationDomainService.get().subscribe((res) => {
      this.areas = res.filter((x) => !x.isPartB && x.isRequired);
      const areaIds = res.map((x) => x.id);
      areaIds.forEach((areaId) => {
        if (this.currentEvaluation.twentyFiveDelayAreas.includes(areaId)) {
          this.selectedAreas.push(areaId);
        }
      });
    });

    if (this.currentEvaluation.isEligibilityDecisionFinalized) {
      this.disableOnFinalization();
    } else {
      this.saveSubscription = this.formGroup.valueChanges;
      this.startAutosaving();
    }
  }

  private patchForm() {
    this.formGroup.get('eligibilitySubmittedOnEvaluation').patchValue(this.currentEvaluation.eligibilitySubmittedOnEvaluation);
    this.formGroup.get('eligibilityBasedOn').patchValue(this.currentEvaluation.eligibilityBasedOn);
    this.formGroup.get('twentyFiveDelayAreas').patchValue(this.currentEvaluation.twentyFiveDelayAreas);
    const sources = this.formGroup.get('eligibilityIcoSources') as FormArray;
    if (this.currentEvaluation.eligibilityIcoSources) {
      for (let i = 0; i < this.currentEvaluation.eligibilityIcoSources.length; i++) {
        for (let j = 0; j < sources.controls.length; j++) {
          const group = sources.controls[j] as FormGroup;
          if (group.get('label').value === this.currentEvaluation.eligibilityIcoSources[i].source) {
            group.get('source').patchValue(true);
            group.get('summary').patchValue(this.currentEvaluation.eligibilityIcoSources[i].summary);
          }
        }
      }
    }
    const reasons = this.formGroup.get('eligibilityIcoReasons') as FormArray;
    if (this.currentEvaluation.eligibilityIcoReasons) {
      for (let i = 0; i < this.currentEvaluation.eligibilityIcoReasons.length; i++) {
        for (let j = 0; j < reasons.controls.length; j++) {
          const group = reasons.controls[j] as FormGroup;
          if (group.get('label').value === this.currentEvaluation.eligibilityIcoReasons[i].reason) {
            group.get('reason').patchValue(true);
            group.get('summary').patchValue(this.currentEvaluation.eligibilityIcoReasons[i].summary);
          }
        }
      }
    }
  }

  private watchEligibilityBasedOn() {
    this.formGroup.get('eligibilitySubmittedOnEvaluation').valueChanges.subscribe((value) => {
      const eligibilityControl = this.formGroup.get('eligibilityBasedOn');
      if (value) {
        eligibilityControl.setValidators(Validators.required);
      } else {
        eligibilityControl.clearValidators();
        eligibilityControl.setValue(null);
        this.formGroup.get('twentyFiveDelayAreas').setValue([]);
        (this.formGroup.get('eligibilityIcoSources') as FormArray).controls.forEach((control) => {
          control.get('source').setValue(null);
          control.get('summary').setValue(null);
        });
        (this.formGroup.get('eligibilityIcoReasons') as FormArray).controls.forEach((control) => {
          control.get('reason').setValue(null);
          control.get('summary').setValue(null);
        });
        this.selectedAreas = [];
      }
      eligibilityControl.updateValueAndValidity();
    });
  }

  private watchEligibilityBasedOnOption() {
    this.formGroup.get('eligibilityBasedOn').valueChanges.subscribe((value) => {
      if (value === 'QuarterOrMore') {
        this.formGroup.get('twentyFiveDelayAreas').addValidators(Validators.required);
      } else {
        this.formGroup.get('twentyFiveDelayAreas').removeValidators(Validators.required);
        this.formGroup.get('twentyFiveDelayAreas').setValue([]);
        this.selectedAreas = [];
      }
      if (value !== 'InformedClinicalOpinion') {
        (this.formGroup.get('eligibilityIcoSources') as FormArray).controls.forEach((control) => {
          control.get('source').setValue(null);
          control.get('summary').setValue(null);
        });
        (this.formGroup.get('eligibilityIcoReasons') as FormArray).controls.forEach((control) => {
          control.get('reason').setValue(null);
          control.get('summary').setValue(null);
        });
      }
      this.formGroup.get('twentyFiveDelayAreas').updateValueAndValidity();
    });
  }

  private getIcoSources(): FormArray {
    const icoSources = [
      'Review (medical or early intervention records from other agencies/states)',
      'Interview (evaluator, parent, or physician statements)',
      'Observations',
      'Tests (evaluation results)',
    ].map((x) =>
      this.formBuilder.group(
        {
          label: [x],
          source: [null],
          summary: [null],
        },
        {
          validators: [summaryValidator('source')],
        }
      )
    );
    return this.formBuilder.array(icoSources);
  }

  private getIcoReasons(): FormArray {
    const icoReasons = [
      'Atypical Development or Atypical Behaviors',
      'Behavior not easily captured by screening or evaluation methods',
      'Lack of progress/regression of skills',
      'Other',
    ].map((x) =>
      this.formBuilder.group(
        {
          label: [x],
          reason: [null],
          summary: [null],
        },
        {
          validators: [summaryValidator('reason')],
        }
      )
    );

    return this.formBuilder.array(icoReasons);
  }

  private setEligibility(isEligible: boolean): any {
    const values = { ...this.formGroup.value };
    values.isEligible = isEligible;

    if (this.formGroup.get('eligibilityBasedOn').value === 'InformedClinicalOpinion') {
      values.eligibilityIcoSources = values.eligibilityIcoSources
        .filter((group) => group.source)
        .map((x) => {
          return {
            evaluationId: this.currentEvaluation.id,
            source: x.label,
            summary: x.summary,
          } as EligibilityIcoSource;
        });

      values.eligibilityIcoReasons = values.eligibilityIcoReasons
        .filter((group) => group.reason)
        .map((x) => {
          return {
            evaluationId: this.currentEvaluation.id,
            reason: x.label,
            summary: x.summary,
          } as EligibilityIcoReason;
        });
    }
    return values;
  }

  saveForm(setCaseEligibility = false): Observable<Evaluation> {
    const eligibility = this.formGroup.get('eligibilitySubmittedOnEvaluation').value;
    if (setCaseEligibility) {
      this.caseEligibility = eligibility;
    }
    const values = {
      ...this.formGroup.getRawValue(),
      ...this.setEligibility(eligibility),
    };
    return this.evaluationService.saveEligibilityInformation(this.currentEvaluation.id, values);
  }

  async createPwn() {
    try {
      const result = await this.pwnService
        .createEarlyAccessPwn(this.caseId, {
          caseId: this.caseId,
          pwnType: PriorWrittenNoticeType.Ineligible,
        } as EarlyAccessPriorWrittenNotice)
        .toPromise();

      const config: NewWindowConfig = {
        path: `cases/${this.caseId}/pwns/${result.id}`,
        popup: true,
        width: '1450px',
      };
      openNewWindow(config);
    } catch (error) {
      const httpError = error as HttpErrorResponse;
      if (httpError?.error?.error === 'PWN of this type already exists for this case.') {
        const config: NewWindowConfig = {
          path: `cases/${this.caseId}/pwns/${httpError.error.id}`,
          popup: true,
          width: '1450px',
        };
        openNewWindow(config);
      } else {
        throw error;
      }
    }
  }

  isAreaChecked(id: string) {
    return this.selectedAreas?.some((x) => x === id);
  }

  onAreaSelect(event: MatButtonToggleChange) {
    if (event.source.checked) {
      this.selectedAreas.push(event.value);
    } else {
      const index = this.selectedAreas.findIndex((x) => x === event.value);
      if (index > -1) {
        this.selectedAreas.splice(index, 1);
      }
    }
    this.formGroup.get('twentyFiveDelayAreas').setValue(this.selectedAreas, { emitEvent: true });
    this.formGroup.markAsDirty();
  }

  finalizeEligibility() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: { subQuestion: 'Clicking Yes will finalize the eligibility decision and prevent further changes from being made.' },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!!res) {
        this.activeCall = true;
        this.formGroup.get('isEligibilityDecisionFinalized').setValue(true);
        this.stopAutosaving();
        this.saveForm(true).subscribe(
          (saveRes) => {
            this.currentEvaluation = saveRes;
            this.formGroup.reset(this.formGroup.getRawValue());
            this.storage.setKey('currentEvaluation', this.currentEvaluation);
            this.activeCall = false;
            this.disableOnFinalization();
            this.evaluationProxyService.eligibilityFinalized.next(this.currentEvaluation.eligibilitySubmittedOnEvaluation);
          },
          (err) => {
            this.activeCall = false;
            this.formGroup.get('isEligibilityDecisionFinalized').setValue(false);
            this.currentEvaluation.isEligibilityDecisionFinalized = false;
            this.startAutosaving();
          }
        );
      }
    });
  }

  updateMedicalDiagnoses() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/cases', this.caseId, 'evaluation', 'early-access-intake', this.caseId])
    );
    window.open(url, '_blank');
  }

  getLatestMedicalDiagnoses() {
    this.loading = true;
    this.evaluationService.get(this.currentEvaluation.id).subscribe((res) => {
      this.currentEvaluation.diagnosisFromIntake = res.diagnosisFromIntake;
      this.loading = false;
    });
  }

  private disableOnFinalization() {
    this.formGroup.disable();
    this.disableUndoButton = true;
  }
}
