export enum FamilyConsentStatus {
  Approved = 'Approved',
  Denied = 'Denied',
  Declined = 'Declined',
  Revoked = 'Revoked',
  Requested = 'Requested',
  Editing = 'Editing',
  Pending = 'Pending',
  Inactive = 'Inactive',
  Override = 'Override',
}
