import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin, Subscription } from 'rxjs';
import { IfspStatus, IfspView } from 'src/app/ifsp/models/ifsp';
import { IfspModification } from 'src/app/ifsp/models/ifsp-modification';
import { IfspServicesService } from 'src/app/ifsp/services/ifsp-service.service';
import { IfspService } from 'src/app/ifsp/services/ifsp.service';
import { ProgressMonitorLookup } from 'src/app/monitoring-progress/models/progress-monitor-lookup';
import { ProgressMonitorLookupService } from 'src/app/monitoring-progress/services/progress-monitor-lookup.service';
import { AppPermissions } from 'src/app/permissions';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { IncompleteDataReportItem } from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { timeDurationInMinutes } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { CaseSummary } from 'src/app/shared/models/case';
import { FileDocument } from 'src/app/shared/models/file-document';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { AuthService } from '../../../../auth/auth.service';
import { IfspServiceLogFirstDeliveryDto, IfspServiceLookupData } from '../../../../ifsp/models/ifsp-service-models';
import { BaseComponent } from '../../../../shared/components/base-component/base-component';
import { DeactivationService } from '../../../../shared/services/deactivation.service';
import {
  ServiceLogDto,
  ServiceLogFamilyGuidedInterventionPlanDto,
  ServiceLogLookupDto,
  ServiceLogServiceDetailDto,
} from '../../models/service-log';
import { ServiceLogService } from '../../services/service-log.service';

@Component({
  templateUrl: './ifsp-add-service-log.component.html',
  styleUrls: ['./ifsp-add-service-log.component.scss'],
})
export class IfspAddServiceLogComponent extends BaseComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  caseSummary: CaseSummary;
  caseId: string;
  serviceLogId: string;
  ifspId: string;
  ifspLookupData: IfspServiceLookupData;
  serviceLog: ServiceLogDto;
  serviceDetails: ServiceLogServiceDetailDto[] = [];
  sessionMaxDate: Date;
  serviceDetailMinDate: Date;
  activeCall = false;
  documents: FileDocument[] = [];
  readOnly: boolean;
  editing: boolean;
  serviceDetailRequired: boolean;
  timeDuration: string;
  selectedOutcomeTitles: string[];
  incompleteItems: IncompleteDataReportItem[];
  modification: IfspModification = null;
  hideLate = true;
  ifsp: IfspView;

  serviceLogFormGroup: FormGroup = new FormGroup({
    id: new FormControl(null),
    caseId: new FormControl(null),
    ifspId: new FormControl(null),
    sessionDate: new FormControl(null, Validators.required),
    childLocation: new FormControl(null, Validators.required),
    childLocationOther: new FormControl(null),
    appointmentDidNotHappen: new FormControl(null),
    appointmentDidNotHappenReason: new FormControl(null),
    appointmentDidNotHappenReasonOther: new FormControl(null),
    providerId: new FormControl(null),
  });

  serviceDetailFormGroup: FormGroup = new FormGroup({
    id: new FormControl(null),
    serviceLogId: new FormControl(null),
    serviceId: new FormControl(null, Validators.required),
    startTime: new FormControl(null, Validators.required),
    endTime: new FormControl(null, Validators.required),
    nextVisit: new FormControl(null),
    howServiceProvided: new FormControl(null, Validators.required),
    howServiceProvidedOther: new FormControl(null),
    providerLocation: new FormControl(null),
    platform: new FormControl(null),
    comments: new FormControl(null),
    providerId: new FormControl(this.userId),
    isFirstDelivery: new FormControl(false),
    lateReasonId: new FormControl(null),
    lateReasonOther: new FormControl(null),
  });

  serviceDetailDisplayedColumns = ['actions', 'serviceId', 'startTime', 'endTime', 'nextVisit', 'howServiceProvided', 'comments'];
  serviceDetaildataSource = new MatTableDataSource<ServiceLogServiceDetailDto>();

  interventionFormGroup = new FormGroup({
    id: new FormControl(null),
    serviceLogId: new FormControl(null),
    outcomesToWorkOn: new FormControl(null),
    what: new FormControl(null),
    why: new FormControl(null),
    when: new FormControl(null),
    how: new FormControl(null),
    howWorking: new FormControl(null),
  });

  childLocationOptions: KeyValuePair[];

  appointmentDidNotHappenReasonOptions: KeyValuePair[] = [
    new KeyValuePair('Family Not at Visit'),
    new KeyValuePair('Family Cancelled - Scheduling Conflict'),
    new KeyValuePair('Family Illness'),
    new KeyValuePair('Provider Cancelled - Scheduling Conflict'),
    new KeyValuePair('Provider Illness'),
    new KeyValuePair("Provider's Agency Closed"),
    new KeyValuePair('Weather'),
    new KeyValuePair('Public Health Emergency'),
    new KeyValuePair('Other'),
  ];

  servicesOptions: KeyValuePair[] = [];

  howServiceProvidedOptions: KeyValuePair[] = [
    new KeyValuePair('Face-to-Face, In-Person Visit'),
    new KeyValuePair('Phone'),
    new KeyValuePair('Virtual Conferencing'),
    new KeyValuePair('Consultation'),
    new KeyValuePair('Joint Visit'),
    new KeyValuePair('Other'),
  ];

  outcomesToWorkOnOptions: KeyValuePair[] = [];
  lateReasonOptions: KeyValuePair[];
  otherLateReasonId: string;

  get appointmentDidNotHappen() {
    return this.serviceLogFormGroup.get('appointmentDidNotHappen').value;
  }
  constructor(
    private caseService: CaseService,
    private route: ActivatedRoute,
    private serviceLogService: ServiceLogService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private authService: AuthService,
    private ifspService: IfspService,
    private progressMonitorLookupService: ProgressMonitorLookupService,
    private readonly ifspServicesService: IfspServicesService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.sessionMaxDate = new Date();
    this.serviceDetailMinDate = this.sessionMaxDate;
    this.caseId = this.route.snapshot.paramMap.get('caseId');
    this.serviceLogId = this.route.snapshot.paramMap.get('serviceLogId');
    this.searchServiceOnChange();
    const readOnlyParam = this.route.snapshot.queryParams?.readOnly;
    this.readOnly = readOnlyParam === 'true' ? true : false;
    const serviceDetailRequiredParam = this.route.snapshot.queryParams?.serviceDetailRequired;
    if (serviceDetailRequiredParam === 'true') {
      this.serviceDetailRequired = true;
    }

    this.ifspService.getLateIfspReasons().subscribe((reasons) => {
      this.lateReasonOptions = reasons.map((x) => new KeyValuePair(x.id, x.label));
      this.otherLateReasonId = reasons.find((x) => x.isOther)?.id;
    });

    this.serviceDetailFormGroup.controls.lateReasonId.valueChanges.subscribe((val) => {
      const ctrl = this.serviceDetailFormGroup.controls.lateReasonOther;
      if (val === this.otherLateReasonId) {
        ctrl.setValidators([Validators.required]);
      } else {
        ctrl.clearValidators();
      }
      ctrl.updateValueAndValidity();
    });

    this.serviceLogFormGroup.controls.appointmentDidNotHappen.valueChanges.subscribe((checked) => {
      const appointmentDidNotHappenReason = this.serviceLogFormGroup.controls.appointmentDidNotHappenReason;
      const endTime = this.serviceDetailFormGroup.controls.endTime;
      const howServiceProvided = this.serviceDetailFormGroup.controls.howServiceProvided;
      if (checked) {
        endTime.clearValidators();
        endTime.setValue(null);
        howServiceProvided.clearValidators();
        howServiceProvided.setValue(null);
        appointmentDidNotHappenReason.setValidators([Validators.required]);
        this.serviceDetailDisplayedColumns = ['actions', 'serviceId', 'startTime', 'nextVisit', 'comments'];
      } else {
        endTime.setValidators([Validators.required]);
        howServiceProvided.setValidators([Validators.required]);
        appointmentDidNotHappenReason.setValue(null);
        appointmentDidNotHappenReason.clearValidators();
        this.serviceDetailDisplayedColumns = [
          'actions',
          'serviceId',
          'startTime',
          'endTime',
          'nextVisit',
          'howServiceProvided',
          'comments',
        ];
      }
      endTime.updateValueAndValidity();
      howServiceProvided.updateValueAndValidity();
      appointmentDidNotHappenReason.updateValueAndValidity();
    });

    this.serviceLogFormGroup.controls.appointmentDidNotHappenReason.valueChanges.subscribe((value) => {
      const appointmentDidNotHappenReasonOther = this.serviceLogFormGroup.controls.appointmentDidNotHappenReasonOther;
      if (value === 'Other') {
        appointmentDidNotHappenReasonOther.setValidators([Validators.required]);
      } else {
        appointmentDidNotHappenReasonOther.clearValidators();
      }
      appointmentDidNotHappenReasonOther.updateValueAndValidity();
    });

    this.serviceDetailFormGroup.controls.howServiceProvided.valueChanges.subscribe((value) => {
      const providerLocation = this.serviceDetailFormGroup.controls.providerLocation;
      const platform = this.serviceDetailFormGroup.controls.platform;
      if (value === 'Virtual Conferencing') {
        providerLocation.setValidators([Validators.required]);
        platform.setValidators([Validators.required]);
      } else {
        providerLocation.clearValidators();
        platform.clearValidators();
      }

      const howServiceProvidedOther = this.serviceDetailFormGroup.controls.howServiceProvidedOther;
      if (value === 'Other') {
        howServiceProvidedOther.setValidators([Validators.required]);
      } else {
        howServiceProvidedOther.clearValidators();
      }
      providerLocation.updateValueAndValidity();
      platform.updateValueAndValidity();
      howServiceProvidedOther.updateValueAndValidity();
    });

    this.serviceLogFormGroup.controls.sessionDate.valueChanges.subscribe((value) => {
      this.validateNextVisit(value);
    });

    this.serviceDetailFormGroup.controls.startTime.valueChanges.subscribe(() => {
      this.validateEndTime();
    });

    this.serviceDetailFormGroup.controls.endTime.valueChanges.subscribe(() => {
      this.validateEndTime();
    });

    this.caseService.refreshCaseSummary(this.caseId);
    this.caseService.caseSummaryChange$.subscribe((caseSummary) => {
      this.caseSummary = caseSummary;
    });

    if (this.serviceLogId) {
      forkJoin([
        this.serviceLogService.getServiceLog(this.caseId, this.serviceLogId),
        this.serviceLogService.getServiceLogLookups(this.caseId),
      ]).subscribe(([serviceLog, serviceLogLookups]) => {
        this.mapLookupOption(serviceLogLookups.value);
        this.serviceLog = serviceLog.value;
        if (this.serviceLog.serviceDetails.filter((x) => x.lateReasonId !== null).length > 0) {
          this.hideLate = false;
        }

        this.serviceLog.providerId = serviceLog.value.serviceLogProvider.id;
        this.serviceDetails = serviceLog.value.serviceDetails;

        this.serviceDetaildataSource.data = this.serviceDetails;

        this.ifspId = serviceLogLookups.value?.ifsps?.find((f) => f.ifspStatus === IfspStatus.Active)?.id;

        // Patch form data: 100 milliseconds delay was needed because the app-autocomplete options appears to be not ready otherwise.
        setTimeout(() => {
          this.setUpServiceLogData();
        }, 100);
      });
    } else {
      forkJoin([
        this.serviceLogService.startNewServiceLog(this.caseId),
        this.serviceLogService.getServiceLogLookups(this.caseId),
      ]).subscribe(([serviceLog, serviceLogLookups]) => {
        this.mapLookupOption(serviceLogLookups.value);

        this.serviceLog = serviceLog.value;
        this.serviceLog.providerId = serviceLog.value.serviceLogProvider.id;
        this.serviceDetails = serviceLog.value.serviceDetails;

        this.serviceDetaildataSource.data = this.serviceDetails;

        this.ifspId = serviceLogLookups.value.ifsps.reduce((a, b) => {
          return new Date(a.createdOn) > new Date(b.createdOn) ? a : b;
        }).id;

        // Patch form data: 100 milliseconds delay was needed because the app-autocomplete options appears to be not ready otherwise.
        setTimeout(() => {
          this.setUpServiceLogData();
        }, 100);
      });
      // this.onServiceChange();
    }

    const lookups = (this.route.snapshot.data.data as ProgressMonitorLookup[]) || [];
    if (lookups.length > 0) {
      this.progressMonitorLookupService.setupLookups(lookups);
    }
  }

  searchServiceOnChange() {
    let serviceId: string;
    let service: IfspServiceLogFirstDeliveryDto;
    this.serviceDetailFormGroup.get('serviceId').valueChanges.subscribe(async (result) => {
      serviceId = result;
      if (serviceId != null) {
        this.getIfsp();
        service = await this.ifspServicesService.getWithService(this.ifspId, serviceId).toPromise();
        if (service.isFirstDelivery && !this.appointmentDidNotHappen) {
          this.serviceDetailFormGroup.get('isFirstDelivery').setValue(true);
          if (!!service.consent.dateSigned) {
            const dateSigned = dayjs(service.consent.dateSigned);
            const difference = dayjs(this.serviceLogFormGroup.get('sessionDate').value).diff(dateSigned, 'day');
            if (difference > 30) {
              this.hideLate = false;
            }
          }
        }
      }
    });
  }

  lateReasonUpdated() {
    this.getIncompleteItems();
  }
  async getIncompleteItems() {
    this.incompleteItems = await this.ifspService.getIncompleteItemsReport(this.ifspId, this.modification?.id).toPromise();
  }

  setUpServiceLogData() {
    if (this.serviceLog) {
      this.serviceLog.sessionDate = dayjs(new Date(this.serviceLog.sessionDate).toISOString().slice(0, -1)).toDate();
      this.serviceLogFormGroup.patchValue(this.serviceLog);
    }

    if (!this.readOnly && (!this.serviceDetails || this.serviceDetails.length === 0 || this.serviceDetailRequired)) {
      this.onAddNewServiceDetail();
    }

    if (this.serviceLog.interventionPlan) {
      this.interventionFormGroup.patchValue(this.serviceLog.interventionPlan);
      if (this.serviceLog.interventionPlan.documents) {
        this.documents = this.serviceLog.interventionPlan.documents;
      }

      this.setSelectedOutcomeTitles(this.serviceLog.interventionPlan.outcomesToWorkOn);
    }

    if (!this.canEditServiceSession) {
      this.serviceLogFormGroup.disable();
      this.interventionFormGroup.disable();
    }
  }

  mapLookupOption(lookups: ServiceLogLookupDto) {
    // Services
    this.servicesOptions = [];
    if (lookups.ifspServiceTypes) {
      this.servicesOptions = lookups.ifspServiceTypes.map((val) => new KeyValuePair(val.id, val.typeOfService));
    }

    // Locations
    this.childLocationOptions = [];
    if (lookups.ifspServiceLocations) {
      this.childLocationOptions = lookups.ifspServiceLocations.map((val) => new KeyValuePair(val.id, val.label));
    }

    // Outcomes
    this.outcomesToWorkOnOptions = [];
    if (lookups.ifspOutcomes) {
      this.outcomesToWorkOnOptions = lookups.ifspOutcomes.map((val) => new KeyValuePair(val.id, val.title));
    }
  }

  validateNextVisit(sessionDate: Date) {
    if (sessionDate) {
      this.serviceDetailMinDate = sessionDate;
      const nextVisit = this.serviceDetailFormGroup.controls.nextVisit;
      if (nextVisit.value) {
        const formatedSessionDate = dayjs(sessionDate);
        const formatedNextVisit = dayjs(nextVisit.value);
        const difference = formatedNextVisit.diff(formatedSessionDate);
        if (difference < 0) {
          nextVisit.setValue(null);
        }
      }
    }
  }

  validateEndTime() {
    const startTime = this.serviceDetailFormGroup.controls.startTime.value;
    const endTime = this.serviceDetailFormGroup.controls.endTime.value;
    if ((startTime && startTime.indexOf(':') < 0) || (endTime && endTime.indexOf(':') < 0)) {
      return;
    }
    const duration = timeDurationInMinutes(startTime, endTime);
    if (duration < 0) {
      this.timeDuration = '';
    } else {
      this.timeDuration = `${duration} min.`;
    }
  }

  get userId() {
    return this.authService.user?.id;
  }

  getIfsp() {
    this.ifspService.get(this.ifspId).subscribe((result) => {
      this.ifsp = result;
    });
  }

  get canEditServiceSession() {
    return !this.readOnly && this.userId === this.serviceLog?.providerId;
  }

  canEditServiceDetail(serviceDetail: ServiceLogServiceDetailDto) {
    return !this.editing && !this.readOnly && this.userId === serviceDetail.serviceDetailProvider.id;
  }

  getTimeDuration(element: ServiceLogServiceDetailDto) {
    const appointmentDidnotHappen = this.serviceLogFormGroup.controls.appointmentDidNotHappen.value;
    if (appointmentDidnotHappen === true) {
      return '';
    }
    return `${timeDurationInMinutes(element.startTime, element.endTime)} min.`;
  }

  get baseUrl() {
    return `api/serviceLog/${this.caseSummary?.learnerId}`;
  }

  setSelectedOutcomeTitles(selectedOutcomes) {
    this.selectedOutcomeTitles = [];
    if (selectedOutcomes) {
      selectedOutcomes.forEach((observation) => {
        const label = this.outcomesToWorkOnOptions.find((opt) => opt.key === observation)?.value;
        this.selectedOutcomeTitles.push(label);
      });
    }
  }

  onSubmitServiceLogForm() {
    if (!this.serviceLogFormGroup.valid) {
      this.notificationService.error('Please complete all required fields for the Session Detail.');
      return;
    }

    this.activeCall = true;
    this.serviceLog = this.serviceLogFormGroup.value as ServiceLogDto;
    this.serviceLog.caseId = this.caseId;
    this.subscriptions.add(
      this.serviceLogService.saveServiceLog(this.caseId, this.serviceLog).subscribe(
        (operationResult) => {
          if (operationResult.succeeded) {
            this.notificationService.success('Successfully saved.');
          } else {
            this.serviceLogService.handleError('An error occured while saving Session Detail', operationResult);
          }
          this.activeCall = false;
        },
        (error) => {
          this.serviceLogService.handleError('An error occured while saving Session Detail', error);
          this.activeCall = false;
        }
      )
    );
  }

  onAddNewServiceDetail() {
    this.resetServiceDetailForm();
    this.editing = true;
  }

  onEditServiceDetail(serviceDetail: ServiceLogServiceDetailDto) {
    this.resetServiceDetailForm();
    this.serviceDetailFormGroup.patchValue(serviceDetail);
    const sessionDate = this.serviceLogFormGroup.controls.sessionDate.value;
    this.validateNextVisit(sessionDate);
    this.editing = true;
  }

  onSaveServiceDetail(keepFormOpen = false, close = false) {
    if (!this.serviceDetailFormGroup.valid) {
      this.notificationService.error('Please complete all required fields for the Service Detail.');
      return;
    }

    this.activeCall = true;
    const serviceDetail = this.serviceDetailFormGroup.getRawValue() as ServiceLogServiceDetailDto;
    serviceDetail.serviceLogId = this.serviceLog.id;

    this.subscriptions.add(
      this.serviceLogService.saveServiceDetail(this.serviceLog.id, serviceDetail).subscribe(
        (operationResult) => {
          if (operationResult.succeeded) {
            if (close) {
              window.close();
              return;
            }
            this.updateServiceDetailRecords(operationResult.value);
            this.resetServiceDetailForm();
            this.notificationService.success('Successfully saved.');
          } else {
            this.serviceLogService.handleError('An error occured while saving Service Detail', operationResult);
          }
          this.activeCall = false;
          this.editing = keepFormOpen;
        },
        (error) => {
          this.serviceLogService.handleError('An error occured while saving Service Detail', error);
          this.activeCall = false;
        }
      )
    );
  }

  onRemoveServiceDetail(serviceDetailId: string) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Clicking Yes will remove the selected Service Detail.',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.activeCall = true;
        this.subscriptions.add(
          this.serviceLogService.deleteServiceDetail(serviceDetailId).subscribe(
            () => {
              this.removeServiceDetailRecord(serviceDetailId);
              this.notificationService.success('Deleted!');
              this.activeCall = false;
            },
            (error) => {
              this.serviceLogService.handleError('An error occured while deleting Service Detail', error);
              this.activeCall = false;
            }
          )
        );
      }
    });
  }

  onCancelServiceDetail() {
    this.resetServiceDetailForm();
    this.editing = false;
  }

  resetServiceDetailForm() {
    this.serviceDetailFormGroup.reset();
  }

  updateServiceDetailRecords(serviceDetail: ServiceLogServiceDetailDto) {
    const serviceDetailToUpdate = this.serviceDetails.find((s) => s.id === serviceDetail.id);

    if (serviceDetailToUpdate) {
      serviceDetailToUpdate.serviceId = serviceDetail.serviceId;
      serviceDetailToUpdate.startTime = serviceDetail.startTime;
      serviceDetailToUpdate.endTime = serviceDetail.endTime;
      serviceDetailToUpdate.nextVisit = serviceDetail.nextVisit;
      serviceDetailToUpdate.howServiceProvided = serviceDetail.howServiceProvided;
      serviceDetailToUpdate.howServiceProvidedOther = serviceDetail.howServiceProvidedOther;
      serviceDetailToUpdate.providerLocation = serviceDetail.providerLocation;
      serviceDetailToUpdate.platform = serviceDetail.platform;
      serviceDetailToUpdate.comments = serviceDetail.comments;
      serviceDetailToUpdate.lateReasonId = serviceDetail.lateReasonId;
      serviceDetailToUpdate.lateReasonOther = serviceDetail.lateReasonOther;
    } else {
      this.serviceDetails.push(serviceDetail);
    }

    this.serviceDetaildataSource.data = this.serviceDetails;
  }

  removeServiceDetailRecord(serviceDetailId: string) {
    this.serviceDetails = this.serviceDetails.filter((sd) => sd.id !== serviceDetailId);
    this.serviceDetaildataSource.data = this.serviceDetails;
  }

  serviceName(serviceId: string) {
    const ifspService = this.servicesOptions.find((s) => s.key === serviceId);
    if (ifspService) {
      return ifspService.value;
    }
    return '';
  }

  onSaveFamilyGuidedInterventionPlan() {
    this.activeCall = true;
    const interventionFormGroup = this.interventionFormGroup.value as ServiceLogFamilyGuidedInterventionPlanDto;
    interventionFormGroup.serviceLogId = this.serviceLog.id;

    this.subscriptions.add(
      this.serviceLogService.saveFamilyGuidedInterventionPlan(this.serviceLog.id, interventionFormGroup).subscribe(
        (operationResult) => {
          if (operationResult.succeeded) {
            if (!this.serviceLog.interventionPlan) {
              this.interventionFormGroup.controls.id.setValue(operationResult.value.id);
              this.serviceLog.interventionPlan = operationResult.value;
            }
            this.notificationService.success('Successfully saved.');
          } else {
            this.notificationService.error('Failed to save Family Guided Intervention Plan.');
          }
          this.activeCall = false;
        },
        (error) => {
          this.serviceLogService.handleError('An error occured while saving Family Guided Intervention Plan.', error);
          this.activeCall = false;
        }
      )
    );
  }

  onUploadDocument(formData: FormData) {
    this.activeCall = true;
    this.subscriptions.add(
      this.serviceLogService
        .uploadInterventionPlanDocument(formData, this.serviceLog.interventionPlan.id, this.caseSummary?.learnerId)
        .subscribe(
          (operationResult) => {
            if (operationResult.succeeded) {
              this.documents.push(...operationResult.value);
              this.notificationService.success('Uploaded!');
            } else {
              this.serviceLogService.handleError('An error occured while uploading the file.', operationResult);
            }
            this.activeCall = false;
          },
          (error) => {
            this.serviceLogService.handleError('An error occured while uploading the file.', error);
            this.activeCall = false;
          }
        )
    );
  }

  onDeleteDocument(documentId: string) {
    this.subscriptions.add(
      this.serviceLogService.deleteInterventionPlanDocument(this.serviceLog.interventionPlan.id, documentId).subscribe(
        () => {
          this.documents = this.documents.filter((d) => d.id !== documentId);
          this.notificationService.success('Deleted!');
          this.activeCall = false;
        },
        (error) => {
          this.serviceLogService.handleError('An error occured while deleting the document.', error);
          this.activeCall = false;
        }
      )
    );
  }

  onViewMore(serviceDetail: ServiceLogServiceDetailDto) {
    const appointmentDidnotHappen = this.serviceLogFormGroup.controls.appointmentDidNotHappen.value;
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Service',
        value: this.serviceName(serviceDetail.serviceId),
      },
      {
        name: 'Start Time',
        value: serviceDetail.startTime,
      },
    ];

    if (appointmentDidnotHappen === true) {
      modalData.push({
        name: 'Next Visit',
        value: serviceDetail.nextVisit ? dayjs(serviceDetail.nextVisit).format('M/D/YYYY') : '',
      });
    } else {
      modalData.push({
        name: 'End Time',
        value: serviceDetail.endTime,
      });
      modalData.push({
        name: 'Duration',
        value: this.getTimeDuration(serviceDetail),
      });
      modalData.push({
        name: 'Next Visit',
        value: serviceDetail.nextVisit ? dayjs(serviceDetail.nextVisit).format('M/D/YYYY') : '',
      });

      if (serviceDetail.howServiceProvidedOther) {
        modalData.push({
          name: 'How Service Provided',
          value: serviceDetail.howServiceProvidedOther,
        });
      } else if (serviceDetail.howServiceProvided === 'Virtual Conferencing') {
        modalData.push({
          name: 'How Service Provided',
          value: serviceDetail.howServiceProvided + ', ' + serviceDetail.providerLocation + ', ' + serviceDetail.platform,
        });
      } else {
        modalData.push({
          name: 'How Service Provided',
          value: serviceDetail.howServiceProvided,
        });
      }
    }

    modalData.push({
      name: 'Comments',
      value: serviceDetail.comments,
    });

    openViewMore(this.dialog, modalData);
  }

  submitAndClose() {
    if (!this.serviceLogFormGroup.valid) {
      this.notificationService.error('Please complete all required fields for the Session Detail.');
      return;
    }

    if (this.serviceDetailFormGroup.dirty && !this.serviceDetailFormGroup.valid) {
      this.notificationService.error('Please complete all required fields for the Service Detail.');
      return;
    } else if (this.serviceDetailFormGroup.dirty) {
      this.onSaveServiceDetail(false, !this.canEditServiceSession);
      if (!this.canEditServiceSession) {
        return;
      }
    }

    if (!this.canEditServiceSession) {
      window.close();
      return;
    }

    this.activeCall = true;

    this.serviceLog = this.serviceLogFormGroup.value as ServiceLogDto;
    this.serviceLog.caseId = this.caseId;

    const serviceDetail = this.serviceDetailFormGroup.value as ServiceLogServiceDetailDto;
    serviceDetail.serviceLogId = this.serviceLog.id;

    const interventionFormGroup = this.interventionFormGroup.value as ServiceLogFamilyGuidedInterventionPlanDto;
    interventionFormGroup.serviceLogId = this.serviceLog.id;

    forkJoin([
      this.serviceLogService.saveServiceLog(this.caseId, this.serviceLog),
      this.serviceLogService.saveFamilyGuidedInterventionPlan(this.serviceLog.id, interventionFormGroup),
    ]).subscribe(
      ([sessionOperationResponse, intervenOperationResponse]) => {
        if (sessionOperationResponse.succeeded && intervenOperationResponse.succeeded) {
          window.close();
          return;
        } else {
          this.serviceLogService.handleError('An error occured while submitting the service log.', sessionOperationResponse);
          this.activeCall = false;
        }
      },
      (error) => {
        this.serviceLogService.handleError('An error occured while submitting the service log.', error);
        this.activeCall = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  canCreateEditProgressMonitoring() {
    return this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.CreateEditProgressMonitoring);
  }
}
