<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>In Progress Referrals</h3>
<div mat-dialog-content>
  <app-text-field label="Filter" (keyup)="applyFilter($event)" placeholder="Ex. John" aria-labelledby="Filter"></app-text-field>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let referral">{{ referral.childInfo?.firstName }} {{ referral.childInfo?.lastName }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="createdOn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
        <td mat-cell *matCellDef="let referral">
          {{ referral.createdOn | dateFormat }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="updatedOn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated On</th>
        <td mat-cell *matCellDef="let referral">
          {{ referral.updatedOn | dateFormat }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
    </table>
    <p *ngIf="!dataSource.data">No data yet...</p>
  </div>
</div>
<div mat-dialog-actions class="actions">
  <button mat-button aria-label="Cancel Button" (click)="onNoClick()" cdkFocusInitial tabindex="0">Cancel</button>
  <button
    mat-raised-button
    aria-label="Continue"
    color="primary"
    [mat-dialog-close]="selection.selected[0]"
    [disabled]="selection.isEmpty()"
    tabindex="0"
  >
    Continue
  </button>
</div>
