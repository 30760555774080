import { User } from 'src/app/shared/models/user';

export interface PostSecondarySummary {
  id: string;
  caseId: string;
  currentFunctionalAchievement: string;
  livingLearningWorkingExpectations: PostSecondaryExpectations[];
  communityContacts: PostSecondaryCommunityContact[];
  highSchoolContacts: string[];
  contributingMembers: string[];
  additionalMembers: User[];
  finalizedOn?: Date;
}

export interface PostSecondaryExpectations {
  id: string;
  postSecondarySummaryId: string;
  working?: boolean;
  living?: boolean;
  learning?: boolean;
  changesToExpectation: string;
  describeFunctionalImpact: string;
  describeResponseToInstruction: string;
  describeCurrentLevelOfPerformance: string;
  servicesMostEffective: string;
  thoughtsPrepareLeave: string;
  ideaFutureSteps: string;
  workBasedExperience: string;
}

export interface PostSecondaryCommunityContact {
  id: string;
  postSecondarySummaryId: string;
  resourceOption: string;
  resourceOther: string;
  agencyResource: string;
  status: string;
  namePosition: string;
  phone: string;
  dateConnected?: Date;
  website: string;
  focusServiceNeeds: string;
  identifiedNextSteps: string;
}

export interface ResourceOption {
  id: string;
  label: string;
}

export interface ResourceConnections {
  id: string;
  label: string;
}

export enum CommunityContactStatus {
  Active = 'Active',
  NotApplied = 'Not Applied',
  InProgress = 'Application in Progress',
  WaitList = 'Wait List',
  Unknown = 'Unknown',
  NA = 'N/A',
}
