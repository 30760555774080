import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LookupBase } from '../../models/lookup-base';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  baseUrl = 'api/languages';

  constructor(private http: HttpClient) {}

  getLanguages(): Observable<LookupBase[]> {
    return this.http.get<LookupBase[]>(this.baseUrl);
  }
}
