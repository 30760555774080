import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LearnerCalenderDto } from 'src/app/evaluation/models/learner-calender-dto';
import { IncompleteDataReportItem } from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { FamilyMeetingRead } from 'src/app/shared/models/case';
import { DeleteReason } from 'src/app/shared/models/delete-reason';
import { OperationResultWithValue } from '../../shared/models/operation-result';
import { IepReevalAddOrUpdateDto, IepReevalConsentForm, Reevaluation, ReevaluationConsentOptions } from '../models/reevaluation';

@Injectable({
  providedIn: 'root',
})
export class ReevaluationService {
  private baseUrl = 'api/iep/reevaluations';
  constructor(private http: HttpClient) {}

  get(reevaluationId: string) {
    return this.http.get<Reevaluation>(`${this.baseUrl}/${reevaluationId}`);
  }

  getAll(caseId) {
    return this.http.get<OperationResultWithValue<Reevaluation[]>>(`${this.baseUrl}/${caseId}/by-case`);
  }

  add(caseId) {
    return this.http.post<string>(`${this.baseUrl}/${caseId}`, null);
  }

  updateFacilitator(reevaluationId: string, userId: string) {
    return this.http.get<Reevaluation>(`${this.baseUrl}/${reevaluationId}/facilitator/${userId}`);
  }

  getCalender(reevaluationId: string) {
    return this.http.get<LearnerCalenderDto>(`${this.baseUrl}/${reevaluationId}/learner-calender`, {});
  }

  getIncompleteDataReport(reevaluationId: string) {
    return this.http.get<IncompleteDataReportItem[]>(`${this.baseUrl}/${reevaluationId}/incomplete-data-report`);
  }

  getOldestIncompleteReevaluationMeeting(reevaluationId: string, caseId: string) {
    return this.http.get<FamilyMeetingRead[]>(`${this.baseUrl}/${reevaluationId}/reevaluation-meetings/oldest-incomplete/${caseId}`);
  }

  getReevaluationOptions(reevaluationId) {
    return this.http.get<ReevaluationConsentOptions>(`${this.baseUrl}/${reevaluationId}/consent/options`);
  }

  getReevaluationConsent(reevaluationId, reevaluationConsentId) {
    if (reevaluationConsentId) {
      return this.http.get<IepReevalConsentForm>(`${this.baseUrl}/${reevaluationId}/consent/${reevaluationConsentId}`);
    } else {
      return this.http.get<IepReevalConsentForm>(`${this.baseUrl}/${reevaluationId}/consent`);
    }
  }

  updateReevaluationConsent(reevaluationId, model: IepReevalAddOrUpdateDto) {
    return this.http.put<IepReevalConsentForm>(`${this.baseUrl}/${reevaluationId}/consent`, model);
  }

  finalizeReevaluationConsent(reevaluationId, model: IepReevalAddOrUpdateDto) {
    return this.http.put<OperationResultWithValue<string>>(`${this.baseUrl}/${reevaluationId}/consent/finalize`, model);
  }

  createReevaluationConsent(reevaluationId) {
    return this.http.post<void>(`${this.baseUrl}/${reevaluationId}/consent`, null);
  }

  getReevaluationConsentOrNotice(reevaluationId: string) {
    return this.http.get(`${this.baseUrl}/${reevaluationId}/consent/pdf`, {
      responseType: 'blob',
    });
  }

  updateSld(reevaluationId: string, consideringSld: boolean) {
    return this.http.put<Reevaluation>(`${this.baseUrl}/${reevaluationId}/sld`, {
      consideringSld,
    });
  }

  finalize(reevaluationId: string) {
    return this.http.post<Reevaluation>(`${this.baseUrl}/${reevaluationId}/finalize`, {});
  }

  cancelReevaluation(reevaluationId: string, reason: string) {
    const deleteReason: DeleteReason = { id: reevaluationId, reason: reason };
    return this.http.put<DeleteReason>(`${this.baseUrl}`, deleteReason);
  }
}
