import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, Subscription, merge } from 'rxjs';
import { debounceTime, pairwise, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { DialogComingSoonComponent } from 'src/app/shared/components/coming-soon/coming-soon.component';
import { StringSizes } from 'src/app/shared/components/form/constants/constants';
import { EvaluationIncompleteDataReportAction } from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { SignatureModalComponent } from 'src/app/shared/modals/signature-modal/signature-modal.component';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { TransitionPlanningService } from '../../../../learner-management/transition-planning/services/transition-planning.service';
import { MeetingRollCallComponent } from '../../../../shared/components/meeting-roll-call/meeting-roll-call.component';
import { NotifySave } from '../../../../shared/decorators/notify-save.decorator';
import { CaseSummary } from '../../../../shared/models/case';
import { DateFormatPipe } from '../../../../shared/pipes/date-transform.pipe';
import { CaseService } from '../../../../shared/services/case/case.service';
import { EvaluationDomainService } from '../../../../shared/services/evaluation-domains/evaluation-domain.service';
import { MemoryStorageService } from '../../../../shared/services/memory-storage/memory-storage.service';
import { AlertDialogComponent, ConfirmationDialogComponent, NotificationService } from '../../../../shared/services/notification.service';
import { ValidationExtensions, conditionalValidator } from '../../../../shared/validators';
import { EligibilityWorksheet, EligibilityWorksheetSignature, Evaluation } from '../../../models/evaluation';
import { EvaluationProxyService } from '../../../services/evaluation-proxy.service';
import { EvaluationService } from '../../../services/evaluation.service';

@Component({
  selector: 'app-evaluation-eligibility-worksheet',
  templateUrl: './evaluation-eligibility-worksheet.component.html',
  styleUrls: ['./evaluation-eligibility-worksheet.component.scss'],
})
export class EvaluationEligibilityWorksheetComponent implements OnInit, OnDestroy {
  @ViewChild('eligibilityQuestions') eligibilityQuestions: ElementRef;
  private subscriptions = new Subscription();
  private submitted$ = new Subject();
  currentEvaluation: Evaluation;
  caseSummary: CaseSummary;
  shortDateFormat = shortDateFormat;
  incompleteItems = [];

  formGroup = this.fb.group({
    hasDisability: [false, Validators.required],
    doesLearningDisabilityContribute: [false, [conditionalValidator(() => this.currentEvaluation.consideringSld, Validators.required)]],
    part1Comments: [''],
    needsAdaptedContent: ['', Validators.required],
    part2Comments: [''],
    specialEdDesignation: ['', Validators.required],
    part3Comments: [''],
    part4Comments: [''],
    signatures: [[]],
    transitionDelayReasonId: [{ value: null, disabled: true }, Validators.required],
  });

  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  specialEducationDesignationIs: KeyValuePair[] = [
    new KeyValuePair(true, 'Eligible Individual (EI)'),
    new KeyValuePair(false, 'Not eligible for special education'),
  ];

  domainOptions: KeyValuePair[] = [];

  delayReasonOptions: KeyValuePair[] = [];

  // Table
  agreeDataSource = new MatTableDataSource<EligibilityWorksheetSignature>([]);
  disagreeDataSource = new MatTableDataSource<EligibilityWorksheetSignature>([]);
  displayedColumns = ['actions', 'name', 'role', 'signedOn'];
  worksheet: EligibilityWorksheet;
  academicId: string;
  stringSizes = StringSizes;
  autosaveSubscription: Subscription;
  activeCall = false;

  get displayPart3() {
    const partOne = this.formGroup.get('hasDisability').value;
    const partTwo = this.formGroup.get('needsAdaptedContent').value;
    return partOne !== null && partTwo !== null;
  }

  get displayWarning() {
    const hasDisability = this.formGroup.get('hasDisability').value;
    const needsAdaptedContent = this.formGroup.get('needsAdaptedContent').value;

    const specialEdDesignation = this.formGroup.get('specialEdDesignation').value;
    if (hasDisability === null || needsAdaptedContent === null || specialEdDesignation === null) {
      return false;
    }

    if (hasDisability === true && needsAdaptedContent === true && specialEdDesignation === true) {
      return false;
    }

    if (hasDisability === false && needsAdaptedContent === false && specialEdDesignation === false) {
      return false;
    }

    if (hasDisability === false && needsAdaptedContent === true && specialEdDesignation === false) {
      return false;
    }

    return true;
  }

  constructor(
    private readonly dialog: MatDialog,
    private readonly storage: MemoryStorageService,
    private readonly evaluationDomainService: EvaluationDomainService,
    private readonly evaluationService: EvaluationService,
    private readonly evaluationProxyService: EvaluationProxyService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly routingService: RoutingService,
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe,
    private readonly caseService: CaseService,
    private readonly transitionPlanningService: TransitionPlanningService
  ) {}

  async ngOnInit(): Promise<void> {
    // TODO: we should probably remove the Memory Storage and just use EvalProxyService
    this.currentEvaluation = this.storage.getKey('currentEvaluation', true);

    this.evaluationProxyService.evaluationChange.subscribe((evaluation) => {
      this.currentEvaluation = evaluation;
      this.loadEvaluationInfo();
      this.setDelayReasonAvailability();
    });

    this.loadEvaluationInfo();
    this.setDelayReasonAvailability();

    const updatePart4CommentsRequired = () => {
      this.formGroup.updateValueAndValidity();
      const val = this.formGroup.value;
      const part4Comments = this.formGroup.get('part4Comments');
      if (val.hasDisability === false && val.needsAdaptedContent && val.specialEdDesignation === false) {
        part4Comments.setValidators([Validators.required]);
      } else {
        part4Comments.clearValidators();
        part4Comments.setValue(null);
      }
      part4Comments.updateValueAndValidity();
    };

    this.subscriptions.add(
      merge(
        this.formGroup.get('hasDisability').valueChanges,
        this.formGroup.get('specialEdDesignation').valueChanges,
        this.formGroup.get('needsAdaptedContent').valueChanges
      ).subscribe(updatePart4CommentsRequired)
    );

    this.subscriptions.add(
      this.formGroup.get('signatures').valueChanges.subscribe((val) => {
        this.agreeDataSource = new MatTableDataSource<EligibilityWorksheetSignature>(val.filter((v) => v.agree === true));
        this.disagreeDataSource = new MatTableDataSource<EligibilityWorksheetSignature>(val.filter((v) => v.agree === false));
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.autosaveSubscription?.unsubscribe();
  }

  onAddRow(e: Event) {
    e.stopPropagation();

    const dialogRef = this.dialog.open(SignatureModalComponent, {
      width: '728px',
      data: { noPin: true, title: 'SLD Documentation', button: 'Add Documentation' },
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(
        (data) => {
          const ctrl = this.formGroup.get('signatures');
          if (data) {
            ctrl.setValue([...ctrl.value, data]);
            ctrl.updateValueAndValidity();
          }
        },
        (err) => console.error(err)
      )
    );
  }

  removeSignature(signature: EligibilityWorksheetSignature) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '728px',
      data: {
        title: 'Remove Documentation',
        message: 'Are you sure you want to remove this documentation?',
      },
    });

    dialogRef.afterClosed().subscribe((yesImSure) => {
      if (yesImSure) {
        const sigsCtrl = this.formGroup.get('signatures');
        const sigs = sigsCtrl.value;
        const idx = sigs.indexOf(signature);
        sigsCtrl.setValue([...sigs.slice(0, idx), ...sigs.slice(idx + 1)]);
        sigsCtrl.updateValueAndValidity();
      }
    });
  }

  onFinalize() {
    if (this.formGroup.invalid) {
      ValidationExtensions.validateAll(this.formGroup);
      return;
    }
    const formValues = this.formGroup.value;
    this.submitted$.next(undefined);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '728px',
      data: {
        title: 'Finalize Evaluation',
        message: 'Are you sure you want to finalize this evaluation?',
        buttons: { cancel: 'No', ok: 'Yes' },
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.activeCall = true;
        this.evaluationService
          .saveEligibilityWorksheet(this.currentEvaluation.id, formValues)
          .pipe(switchMap(() => this.evaluationService.finalizeEvaluationPartB(this.currentEvaluation.id)))
          .subscribe(
            () => {
              this.notificationService.success('Saved!');
              this.currentEvaluation.isFinalized = true;
              this.evaluationProxyService.evaluationChange.next(this.currentEvaluation);
              this.router.navigate(['/', 'learner-management', this.currentEvaluation.learner.id, 'evaluation']);
            },
            (err) => {
              this.activeCall = false;
              let errorMessage = 'Failed to save Eligibility.';
              const errorDescription = err.error[0]?.description;
              if (errorDescription && errorDescription.split(':').length === 2) {
                errorMessage += ` ${errorDescription.split(':')[1]}`;
              }
              this.notificationService.error(errorMessage);
            }
          );
      }
    });
  }

  private loadEvaluationInfo(): void {
    if (!this.currentEvaluation) {
      return;
    }

    this.autosaveSubscription?.unsubscribe();

    this.evaluationService.getEligibilityWorksheet(this.currentEvaluation.id).subscribe((worksheet) => {
      this.worksheet = worksheet;
      this.formGroup.patchValue(this.worksheet);
      if (!this.currentEvaluation.isFinalized) {
        this.startAutosaving();
        this.subscriptions.add(
          this.formGroup.statusChanges.pipe(debounceTime(2000), startWith('' as string), pairwise()).subscribe(([prev, next]) => {
            if (prev !== next) {
              this.onGetIncompleteDataReport();
            }
          })
        );
      }
    });

    this.caseService.getCaseSummary(this.currentEvaluation.caseId).subscribe((caseSummary) => {
      this.caseSummary = caseSummary;
      this.transitionPlanningService
        .delayReasons(this.caseSummary?.learner?.isMigrated)
        .subscribe((res) => (this.delayReasonOptions = res));
    });

    this.evaluationService.getIncompleteDataReportPartB(this.currentEvaluation.id).subscribe((res) => (this.incompleteItems = res));
  }

  private setDelayReasonAvailability(): void {
    if (this.currentEvaluation.delayed) {
      this.formGroup.get('transitionDelayReasonId').enable();
    } else {
      this.formGroup.get('transitionDelayReasonId').disable();
    }
  }

  private startAutosaving() {
    this.autosaveSubscription = this.formGroup.valueChanges.pipe(debounceTime(500), takeUntil(this.submitted$)).subscribe(async (val) => {
      console.log('');
      await this.saveEligibilityWorksheet();
    });
  }

  @NotifySave
  private async saveEligibilityWorksheet(): Promise<void> {
    await this.evaluationService.saveEligibilityWorksheet(this.currentEvaluation.id, this.formGroup.value).toPromise();
  }

  comingSoon() {
    this.dialog.open(DialogComingSoonComponent, {
      width: '725px',
    });
  }

  onNavigate(event) {
    switch (event.action) {
      case EvaluationIncompleteDataReportAction.Meeting:
        this.routingService.openEvaluationScheduleMeeting(this.currentEvaluation.learner.id, this.currentEvaluation.id);
        break;
      case EvaluationIncompleteDataReportAction.RollCall:
        this.openRollCallModal();
        break;
      case EvaluationIncompleteDataReportAction.FutureRollCall:
        this.dialog.open(AlertDialogComponent, {
          data: {
            title: 'Meeting Roll Call',
            message: `Meeting Roll Call cannot be edited until the date of the meeting - ${new DateFormatPipe().transform(
              event.meetingDate
            )}`,
          },
        });
        break;
      case EvaluationIncompleteDataReportAction.Overview:
      case EvaluationIncompleteDataReportAction.EcoMatrix:
        this.router.navigate(['cases', this.currentEvaluation?.id, 'evaluation', this.currentEvaluation.id, event.action], {
          queryParams: { section: event.section },
        });
        break;
      case EvaluationIncompleteDataReportAction.Summary:
        this.eligibilityQuestions?.nativeElement?.scrollIntoView({
          behavior: 'smooth',
        });
        this.formGroup.markAllAsTouched();
        break;
      default:
        this.router.navigate(
          ['cases', this.currentEvaluation?.id, 'evaluation', this.currentEvaluation?.id, 'details-part-b', event?.action],
          {
            queryParams: { section: event.section, markAsTouched: true },
          }
        );
        break;
    }
  }

  onGetIncompleteDataReport(refreshEvaluation = false) {
    this.evaluationService.getIncompleteDataReportPartB(this.currentEvaluation.id).subscribe((res) => {
      this.incompleteItems = res;
      if (refreshEvaluation) {
        this.getEvaluation();
      }
    });
  }

  private openRollCallModal() {
    this.evaluationService
      .getOldestIncompleteEligibilityMeeting(this.currentEvaluation.id, this.currentEvaluation.caseId)
      .subscribe((meeting) => {
        if (!meeting) {
          return;
        }
        const dialogRef = this.dialog.open(MeetingRollCallComponent, {
          width: '1100px',
          data: {
            meeting,
            caseSummary: this.caseSummary,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          this.onGetIncompleteDataReport(true);
        });
      });
  }

  private getEvaluation() {
    this.evaluationService.get(this.currentEvaluation.id).subscribe((res) => {
      this.evaluationProxyService.evaluationChange.next(res);
    });
  }
}
