<section *ngIf="servicesDataSource.data.length > 0 && services">
  <h4 class="mt-0 mb-2 text-primary">
    {{ viewOnly ? 'ESY Added Services' : 'Summary of Services' }}
  </h4>
  <div class="table-overflow app-table">
    <table mat-table [dataSource]="servicesDataSource" class="w-100" matSort>
      <ng-container *ngIf="!viewOnly" matColumnDef="actions">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-actions mat-column-actions--more">Actions</th>
        <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
          <div class="display-flex align-items-center justify-content-start">
            <button mat-icon-button aria-label="View Icon" color="primary" (click)="viewServiceOrActivity('service', element)" tabindex="0">
              <mat-icon>remove_red_eye</mat-icon>
            </button>

            <ng-container *ngIf="!hasOpenAmendment && caseSummary.activeIepStatus === 'Draft' && !readonlyUser">
              <button mat-icon-button aria-label="Edit Icon" color="primary" (click)="onServiceEdit(element)" tabindex="0">
                <mat-icon>edit</mat-icon>
              </button>
              <button
                mat-icon-button
                aria-label="Delete Icon"
                color="warn"
                *ngIf="!isEditing"
                (click)="onServiceRemove(element)"
                tabindex="0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>

            <ng-container *ngIf="hasOpenAmendment && goal.amendingGoalDetails && !readonlyUser">
              <button mat-icon-button aria-label="Quick Access Menu" color="primary" [matMenuTriggerFor]="actionMenu" tabindex="0">
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #actionMenu="matMenu">
                <button mat-menu-item aria-label="Amend" *ngIf="!isEditing" (click)="onServiceEdit(element, true)" tabindex="0">
                  Amend
                </button>
                <button *ngIf="canUndo(element)" mat-menu-item aria-label="Undo" (click)="onServiceRemove(element, true)" tabindex="0">
                  Undo
                </button>
                <button *ngIf="canRemove(element)" mat-menu-item aria-label="Delete Icon" (click)="onServiceRemove(element)" tabindex="0">
                  Remove
                </button>

                <button *ngIf="canEnd(element)" mat-menu-item aria-label="End" (click)="onEnd(element, 'Service')" tabindex="0">End</button>
              </mat-menu>

              <div class="fake-icon-button" *ngIf="!element.isComplete">
                <mat-icon class="icon-incomplete" aria-label="Warning">warning</mat-icon>
              </div>

              <ng-container *ngIf="element.amendmentEndDate">
                <div class="fake-icon-button">
                  <i class="fas fa-calendar-times icon-incomplete"></i>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="service">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Service</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="element.amendmentEndDate"
            [priorVersionId]="element.priorVersionId"
            [isActive]="element.isActive"
            [changedVersionText]="element?.serviceType?.label"
            [currentVersionText]="element?.priorVersion?.serviceType?.label"
          >
          </app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="element.amendmentEndDate"
            [priorVersionId]="element.priorVersionId"
            [isActive]="element.isActive"
            [changedVersionText]="element?.description | truncate : 150"
            [currentVersionText]="element?.priorVersion?.description | truncate : 150"
          ></app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="projectedStartDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Projected Start Date</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="element.amendmentEndDate"
            [priorVersionId]="element.priorVersionId"
            [isActive]="element.isActive"
            [changedVersionText]="element?.projectedStartDate | dateFormat"
            [currentVersionText]="element?.priorVersion?.projectedStartDate | dateFormat"
          ></app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="projectedEndDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Projected End Date</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="element.amendmentEndDate"
            [priorVersionId]="element.priorVersionId"
            [isActive]="element.isActive"
            [changedVersionText]="element?.projectedEndDate | dateFormat"
            [currentVersionText]="element?.priorVersion?.projectedEndDate | dateFormat"
          ></app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="element.amendmentEndDate"
            [priorVersionId]="element.priorVersionId"
            [isActive]="element.isActive"
            [changedVersionText]="element?.location | truncate : 150"
            [currentVersionText]="element?.priorVersion?.location | truncate : 150"
          ></app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="frequency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Frequency</th>

        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="element.amendmentEndDate"
            [priorVersionId]="element.priorVersionId"
            [isActive]="element.isActive"
            [changedVersionText]="
              element?.frequencyNumber + ' ' + element?.frequencyPeriod + ' ' + element?.frequencyIncrement + ' Time(s)'
            "
            [currentVersionText]="
              element?.priorVersion?.frequencyNumber +
              ' ' +
              element?.priorVersion?.frequencyPeriod +
              ' ' +
              element?.priorVersion?.frequencyIncrement +
              ' Time(s)'
            "
          ></app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="schedule">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Schedule Of Services</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="element.amendmentEndDate"
            [priorVersionId]="element.priorVersionId"
            [isActive]="element.isActive"
            [changedVersionText]="getScheduleOfServices(element.scheduleServices) | truncate : 150"
            [currentVersionText]="getScheduleOfServices(element?.priorVersion?.scheduleServices) | truncate : 150"
          ></app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="providers">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Provider(s)</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="element.amendmentEndDate"
            [priorVersionId]="element.priorVersionId"
            [isActive]="element.isActive"
            [changedVersionText]="getProviderNames(element) | truncate : 150"
            [currentVersionText]="getProviderNames(element?.priorVersion) | truncate : 150"
          ></app-compare-amendment-output>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="servicesDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: servicesDisplayedColumns"></tr>
    </table>
    <mat-paginator [dataSource]="servicesDataSource"></mat-paginator>
  </div>
  <p *ngIf="!servicesDataSource">No data yet...</p>
</section>

<br />

<section *ngIf="supportsDataSource.data.length > 0 && supports">
  <h4 class="mt-0 mb-2 text-primary">
    {{ viewOnly ? 'ESY Added Supports and Activities' : 'Summary of Supports and Activities' }}
  </h4>
  <div class="table-overflow app-table">
    <table mat-table [dataSource]="supportsDataSource" class="w-100" matSort>
      <ng-container *ngIf="!viewOnly" matColumnDef="actions">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-actions mat-column-actions--more">Actions</th>
        <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
          <div class="display-flex align-items-center justify-content-start">
            <button
              mat-icon-button
              color="primary"
              aria-label="View Icon"
              (click)="viewServiceOrActivity('activity', element)"
              tabindex="0"
            >
              <mat-icon>remove_red_eye</mat-icon>
            </button>

            <ng-container *ngIf="!hasOpenAmendment && caseSummary.activeIepStatus === 'Draft' && !readonlyUser">
              <button mat-icon-button aria-label="Edit Icon" color="primary" (click)="onSupportEdit(element)" tabindex="0">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button aria-label="Delete Icon" color="warn" (click)="onSupportRemove(element)" tabindex="0">
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>

            <ng-container *ngIf="hasOpenAmendment && goal.amendingGoalDetails && !readonlyUser">
              <button mat-icon-button aria-label="Quick Access Menu" color="primary" [matMenuTriggerFor]="actionMenu" tabindex="0">
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #actionMenu="matMenu">
                <button mat-menu-item *ngIf="!isEditing" aria-label="Amend" (click)="onSupportEdit(element, true)" tabindex="0">
                  Amend
                </button>
                <button *ngIf="canUndo(element)" mat-menu-item aria-label="Undo" (click)="onSupportRemove(element, true)" tabindex="0">
                  Undo
                </button>
                <button *ngIf="canRemove(element)" mat-menu-item aria-label="Delete Icon" (click)="onSupportRemove(element)" tabindex="0">
                  Remove
                </button>

                <button *ngIf="canEnd(element)" mat-menu-item aria-label="End" (click)="onEnd(element, 'Activity/Support')" tabindex="0">
                  End
                </button>
              </mat-menu>

              <ng-container *ngIf="hasOpenAmendment">
                <div class="fake-icon-button" *ngIf="!element.isComplete">
                  <mat-icon class="icon-incomplete" aria-label="Warning">warning</mat-icon>
                </div>
              </ng-container>

              <ng-container *ngIf="element.amendmentEndDate">
                <div class="fake-icon-button">
                  <i class="fas fa-calendar-times icon-incomplete"></i>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="activity">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Activity</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [priorVersionId]="element.priorVersionId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="element.amendmentEndDate"
            [isActive]="element.isActive"
            [changedVersionText]="element?.activitySupportType?.label | truncate : 150"
            [currentVersionText]="element?.priorVersion?.activitySupportType?.label | truncate : 150"
          >
          </app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="element.amendmentEndDate"
            [priorVersionId]="element.priorVersionId"
            [isActive]="element.isActive"
            [changedVersionText]="element?.description | truncate : 150"
            [currentVersionText]="element?.priorVersion?.description | truncate : 150"
          ></app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="frequency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Frequency Period</th>

        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="element.amendmentEndDate"
            [priorVersionId]="element.priorVersionId"
            [isActive]="element.isActive"
            [changedVersionText]="element?.frequencyPeriod | truncate : 150"
            [currentVersionText]="element?.priorVersion?.frequencyPeriod | truncate : 150"
          ></app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="providers">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Provider(s)</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            [iepAmendments]="amendments"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="element.amendmentEndDate"
            [priorVersionId]="element.priorVersionId"
            [isActive]="element.isActive"
            [changedVersionText]="getProviderNames(element) | truncate : 150"
            [currentVersionText]="getProviderNames(element?.priorVersion) | truncate : 150"
          ></app-compare-amendment-output>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="supportsDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: supportsDisplayedColumns"></tr>
    </table>
    <mat-paginator [dataSource]="supportsDataSource"></mat-paginator>
  </div>
  <p *ngIf="!supportsDataSource">No data yet...</p>
</section>
