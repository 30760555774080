<div class="row">
  <div class="col">
    <div class="table-overflow">
      <table mat-table [dataSource]="dataSource" matSort class="w-100">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Diagnosis</th>
          <td mat-cell *matCellDef="let condition">
            {{ condition.name }}
          </td>
        </ng-container>

        <!-- ICD Code Column -->
        <ng-container matColumnDef="icdCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>ICD Code</th>
          <td mat-cell *matCellDef="let condition">
            {{ condition.icdCode }}
          </td>
        </ng-container>

        <!-- Diagnosis Column -->
        <ng-container matColumnDef="diagnosisQualifies">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Is this a diagnosis that qualifies the child for Early ACCESS?</th>
          <td mat-cell *matCellDef="let condition">
            <ng-container viewMode *ngIf="condition.name">
              {{ condition.diagnosisQualifies === true ? 'Yes' : condition.diagnosisQualifies === false ? 'No' : '' }}
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <p *ngIf="!dataSource.data">No data yet...</p>
    </div>
  </div>
</div>
