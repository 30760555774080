<div class="table-overflow app-table" *ngIf="dataSource">
  <table mat-table [dataSource]="dataSource" class="w-100" matSort>
    <ng-container matColumnDef="sessionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.sessionDate | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
      <td mat-cell *matCellDef="let element">
        <a class="text-underline" (click)="getFgrbiOutput(element)" tabindex="0"> Family Intervention Plan </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="2">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr
      [hidden]="dataSource?.data?.length > 0"
      mat-footer-row
      *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
      class="example-second-footer-row"
    ></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
