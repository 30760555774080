<h3 class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>View More</h3>

<div class="mat-dialog-content py-2">
  <mat-card *ngIf="accommodation">
    <ul class="list">
      <li class="list__item" *ngIf="accommodation.includesTrialPlacement">
        <h3 class="text-primary mt-0 mb-2">Included in Trial Placement</h3>
      </li>
      <li class="list__item">
        <h3 class="text-primary mt-0 mb-2">Description</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="accommodation.amendmentEndDate"
          [priorVersionId]="accommodation.priorVersionId"
          [isActive]="accommodation.isActive"
          [changedVersionText]="accommodation.description"
          [currentVersionText]="accommodation?.priorVersion ? accommodation?.priorVersion?.description : accommodation.description"
          [changedPrefixText]="accommodation?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item">
        <h3 class="text-primary mt-0 mb-2">Frequency</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="accommodation.amendmentEndDate"
          [priorVersionId]="accommodation.priorVersionId"
          [isActive]="accommodation.isActive"
          [changedVersionText]="accommodation.frequencyPeriod"
          [currentVersionText]="accommodation?.priorVersion ? accommodation?.priorVersion?.frequencyPeriod : accommodation.frequencyPeriod"
          [changedPrefixText]="accommodation?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item" *ngIf="accommodation.otherProvider">
        <h3 class="text-primary mt-0 mb-2">Provider Name</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="accommodation.amendmentEndDate"
          [priorVersionId]="accommodation.priorVersionId"
          [isActive]="accommodation.isActive"
          [changedVersionText]="accommodation.otherProviderName"
          [currentVersionText]="
            accommodation?.priorVersion ? accommodation.priorVersion?.otherProviderName : accommodation.otherProviderName
          "
          [changedPrefixText]="accommodation?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>

        <h3 class="text-primary mt-0 mb-2">Provider Agency</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="accommodation.amendmentEndDate"
          [priorVersionId]="accommodation.priorVersionId"
          [isActive]="accommodation.isActive"
          [changedVersionText]="accommodation.otherProviderAgency"
          [currentVersionText]="
            accommodation?.priorVersion ? accommodation.priorVersion?.otherProviderAgency : accommodation.otherProviderAgency
          "
          [changedPrefixText]="accommodation?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>

        <h3 class="text-primary mt-0 mb-2">Provider Role</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="accommodation.amendmentEndDate"
          [priorVersionId]="accommodation.priorVersionId"
          [isActive]="accommodation.isActive"
          [changedVersionText]="accommodation.otherProviderRole"
          [currentVersionText]="
            accommodation?.priorVersion ? accommodation.priorVersion?.otherProviderRole : accommodation.otherProviderRole
          "
          [changedPrefixText]="accommodation?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item" *ngIf="accommodation.providers">
        <h3 class="text-primary mt-0 mb-2">Providers(s)</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="accommodation.amendmentEndDate"
          [priorVersionId]="accommodation.priorVersionId"
          [isActive]="accommodation.isActive"
          [changedVersionText]="getProviders(accommodation.providers)"
          [currentVersionText]="
            accommodation?.priorVersion ? getProviders(accommodation.priorVersion?.providers) : getProviders(accommodation.providers)
          "
          [changedPrefixText]="accommodation?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
    </ul>
  </mat-card>
</div>

<div class="mat-dialog-actions justify-content-end">
  <button mat-raised-button aria-label="Delete Icon" (click)="onClose()" cdkFocusInitial tabindex="0">Close</button>
</div>
