<mat-form-field class="w-100" *ngIf="!readOnly || showReadOnlyControl; else readOnlyTemplate">
  <mat-label
    [ngClass]="{
      asterisk_input: (control.hasError('required') || showAsterisk) && !hideAsterisk
    }"
    >{{ label }}</mat-label
  >
  <input
    matInput
    [class]="klass"
    [matDatepicker]="controlId"
    [placeholder]="placeholder"
    [formControlName]="controlName"
    [max]="max"
    [min]="min"
    [matDatepickerFilter]="dateFilter"
    [attr.readonly]="readOnly ? true : null"
    [attr.disabled]="disabled ? true : null"
    (click)="!readOnly ? controlId.open() : null"
    (blur)="onBlur($event)"
    autocomplete="off"
    attr.aria-required="{{ (control.hasError('required') || showAsterisk) && !hideAsterisk ? 'true' : 'false' }}"
  />
  <mat-datepicker-toggle *ngIf="!readOnly" matSuffix [for]="controlId"> </mat-datepicker-toggle>
  <mat-datepicker #controlId [startView]="startView" [startAt]="startAt"></mat-datepicker>
  <mat-error>
    <app-field-error-display [control]="control"></app-field-error-display>
  </mat-error>
</mat-form-field>

<ng-template #readOnlyTemplate>
  <p class="read-only-container">
    <strong>{{ label }}</strong
    >{{ control.value | dateFormat }}
  </p>
</ng-template>
