<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Add Personal Sticky Note</h3>
<mat-dialog-content>
  <form [formGroup]="stickyNoteForm">
    <mat-card class="mb-4 mt-4 card--plain card--shadow">
      <div class="row">
        <div class="col-md-12">
          <app-textarea label="Sticky Note" [rows]="4" formControlName="note" [maxLength]="stringSizes.small"></app-textarea>
        </div>
        <hr *ngIf="!isFamily" class="input__divider" />
        <div *ngIf="!isFamily" class="col-md-12">
          <app-autocomplete label="Link to Case" formControlName="caseId" [options]="learnerOptions" [panelWidth]="''"></app-autocomplete>
        </div>
      </div>
    </mat-card>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    class="background-color--muted"
    type="button"
    mat-raised-button
    aria-label="Cancel Creating Sticky Note"
    [mat-dialog-close]="true"
    tabindex="0"
  >
    Close
  </button>
  <button
    color="primary"
    type="button"
    mat-raised-button
    aria-label="Save Sticky Note"
    (click)="onSave()"
    [disabled]="stickyNoteForm.invalid"
    [isBusy]="activeCall"
    tabindex="0"
  >
    Save
  </button>
  <button
    color="primary"
    type="button"
    mat-raised-button
    aria-label="Save Sticky Note"
    (click)="onSaveAndAdd()"
    [disabled]="stickyNoteForm.invalid"
    [isBusy]="activeCall"
    tabindex="0"
  >
    Save and Add Another
  </button>
</mat-dialog-actions>
