import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { shortDateFormat } from '../../dateTimeHelpers';
import { CaseSummary, IntakeType } from '../../models/case';
import { KeyValuePair } from '../../models/key-value-pair';
import { CaseService } from '../../services/case/case.service';

@Component({
  selector: 'app-case-exit-banner',
  templateUrl: './case-exit-banner.component.html',
  styleUrls: ['./case-exit-banner.component.scss'],
})
export class CaseExitBannerComponent implements OnInit, OnDestroy {
  @Input() caseSummary: CaseSummary;

  subscription = new Subscription();
  selectedExitReason: KeyValuePair;
  intakeTypeEnum = IntakeType;
  shortDateFormat = shortDateFormat;

  constructor(private readonly caseService: CaseService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.caseService.getCaseExitReason(this.caseSummary.id).subscribe((exitReason) => {
        if (exitReason.succeeded) {
          this.selectedExitReason = exitReason.value;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
