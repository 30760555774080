<ng-template #signatoriesDialog let-data>
  <h2 mat-dialog-title>{{ dialogTitle }}</h2>
  <mat-dialog-content class="py-3">
    <mat-card class="mb-0">
      <app-page-validation></app-page-validation>
      <form #addSignatoriesForm="ngForm">
        <div *ngIf="signatories && isSingleSignatory">
          <div class="row">
            <div class="col-md-12">
              <mat-radio-group
                aria-labelledby="Select goal based on"
                [(ngModel)]="singleSelectionMode"
                layout="row"
                (change)="onSingleSignatorySelectionChanged()"
                name="singleSelectionMode"
              >
                <!-- <mat-radio-button [value]="SingleSignatorySelection.Existing">Existing Signatory </mat-radio-button> -->
                <mat-radio-button [value]="SingleSignatorySelection.New">New Signatory </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="row" *ngIf="singleSelectionMode === SingleSignatorySelection.Existing">
            <div class="col-md-12">
              <mat-form-field>
                <mat-label>{{ userLabel }}</mat-label>
                <mat-select [(ngModel)]="signatory" name="signatory" required>
                  <mat-option *ngFor="let element of signatories" [value]="element" role="combobox">
                    {{ element.firstName }} {{ element.lastName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="singleSelectionMode === SingleSignatorySelection.New">
            <div class="row">
              <div class="col-md-12 col-lg-4">
                <mat-form-field>
                  <mat-label>First Name</mat-label>
                  <input matInput aria-labelledby="First Name" [(ngModel)]="signatory.firstName" name="firstName" required />
                </mat-form-field>
              </div>
              <div class="col-md-12 col-lg-4">
                <mat-form-field>
                  <mat-label>Last Name</mat-label>
                  <input matInput aria-labelledby="Last Name" [(ngModel)]="signatory.lastName" name="lastName" required />
                </mat-form-field>
              </div>
            </div>
            <div class="row" *ngIf="isHomeschoolConsent">
              <div class="col-md-12 col-lg-12">
                <mat-checkbox [(ngModel)]="isPublicAgencyRep" name="publicAgencyRep">Public Agency Rep/Designee</mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-card>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="primary" type="button" mat-raised-button aria-label="Cancel Button" [mat-dialog-close]="true" tabindex="0">
      Cancel
    </button>

    <button
      color="primary"
      type="button"
      mat-raised-button
      aria-label="Save Button"
      (click)="save()"
      [mat-dialog-close]="true"
      tabindex="0"
    >
      Save
    </button>
  </mat-dialog-actions>
</ng-template>
