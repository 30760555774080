<form [formGroup]="formGroup">
  <section>
    <h3 class="mt-0 mb-2">Evaluation Summary</h3>

    <mat-card class="mb-0 card--plain card--shadow p-0">
      <ul class="summary__list">
        <li class="summary__item">
          <h3 class="my-0">Summary of Strengths, Concerns, and Relevant Background</h3>
          <ul class="summary__list">
            <li class="summary__item">
              <h4 class="my-0">Strengths</h4>
              <p class="mt-1 mb-0 white-space-pre-line" *ngIf="worksheet?.strengthsSummary">
                {{ worksheet?.strengthsSummary }}
              </p>
            </li>
            <li class="summary__item">
              <h4 class="my-0">Concerns</h4>
              <p class="mt-1 mb-0 white-space-pre-line" *ngIf="worksheet?.concernsSummary">
                {{ worksheet?.concernsSummary }}
              </p>
            </li>
            <li class="summary__item">
              <h4 class="my-0">Relevant Background</h4>
              <p class="mt-1 mb-0 white-space-pre-line" *ngIf="worksheet?.relevantBackgroundSummary">
                {{ worksheet?.relevantBackgroundSummary }}
              </p>
            </li>
          </ul>
        </li>
        <li class="summary__item">
          <h3 class="my-0">Summary of Learner Performance</h3>
          <p class="mt-1 mb-0 white-space-pre-line" *ngIf="worksheet?.learnerPerformanceSummary">
            {{ worksheet?.learnerPerformanceSummary }}
          </p>
        </li>
        <li class="summary__item">
          <h3 class="my-0">Summary of Learner Progress</h3>
          <p class="mt-1 mb-0 white-space-pre-line" *ngIf="worksheet?.learnerProgressSummary">
            {{ worksheet?.learnerProgressSummary }}
          </p>
        </li>
        <li class="summary__item">
          <h3 class="my-0">Summary of Educational Needs</h3>
          <p class="mt-1 mb-0 white-space-pre-line" *ngIf="worksheet?.educationalNeedsSummary">
            {{ worksheet?.educationalNeedsSummary }}
          </p>
        </li>
        <li class="summary__item">
          <h3 class="my-0">Eligibility Factors</h3>
          <!-- <h3 class="my-0">Summary of Exclusionary Factors</h3> -->
          <ul class="summary__list">
            <li class="summary__item" *ngIf="worksheet?.lackOfAppropriateInstruction !== null">
              <h4 class="my-0">
                {{
                  worksheet.lackOfAppropriateInstruction
                    ? 'Lack of appropriate instruction is a factor responsible for the concern.'
                    : 'Lack of appropriate instruction is not a factor responsible for the concern.'
                }}
              </h4>
              <div *ngIf="worksheet.lackOfAppropriateInstructionEvidences.length > 0">
                <p class="my-0">Evidence contributing to the decision</p>
                <ul>
                  <li class="white-space-pre-line" *ngFor="let evidence of worksheet.lackOfAppropriateInstructionEvidences">
                    {{ evidence }}
                  </li>
                </ul>
              </div>
              <p class="mt-2 mb-0 white-space-pre-line" *ngIf="worksheet.lackOfAppropriateInstructionExplanation">
                {{ worksheet.lackOfAppropriateInstructionExplanation }}
              </p>
            </li>

            <li class="summary__item" *ngIf="worksheet?.cultureAndEnvironmentalEconomicDisadvantage !== null">
              <h4 class="my-0">
                {{
                  worksheet.cultureAndEnvironmentalEconomicDisadvantage
                    ? 'Culture and/or environmental/economic disadvantage is a factor responsible for the  concern.'
                    : 'Culture and/or environmental/economic disadvantage is not a factor responsible for the  concern.'
                }}
              </h4>
              <div *ngIf="worksheet.cultureAndEnvironmentalEconomicDisadvantageEvidences.length > 0">
                <p class="my-0">Evidence contributing to the decision</p>
                <ul>
                  <li class="white-space-pre-line" *ngFor="let evidence of worksheet.cultureAndEnvironmentalEconomicDisadvantageEvidences">
                    {{ evidence }}
                  </li>
                </ul>
              </div>
              <p class="mt-2 mb-0 white-space-pre-line" *ngIf="worksheet.cultureAndEnvironmentalEconomicDisadvantageExplanation">
                {{ worksheet.cultureAndEnvironmentalEconomicDisadvantageExplanation }}
              </p>
            </li>

            <li class="summary__item" *ngIf="worksheet?.limitedEnglishProficiency !== null">
              <h4 class="my-0">
                {{
                  worksheet.limitedEnglishProficiency
                    ? 'Limited English proficiency is a factor responsible for the concern.'
                    : 'Limited English proficiency is not a factor responsible for the concern.'
                }}
              </h4>
              <div *ngIf="worksheet.limitedEnglishProficiencyEvidences.length > 0">
                <p class="my-0">Evidence contributing to the decision</p>
                <ul>
                  <li class="white-space-pre-line" *ngFor="let evidence of worksheet.limitedEnglishProficiencyEvidences">
                    {{ evidence }}
                  </li>
                </ul>
              </div>
              <p class="mt-2 mb-0 white-space-pre-line" *ngIf="worksheet.limitedEnglishProficiencyExplanation">
                {{ worksheet.limitedEnglishProficiencyExplanation }}
              </p>
            </li>

            <li class="summary__item" *ngIf="worksheet?.isSLD && worksheet?.sldFactors?.length > 0">
              <h4 class="my-0">The following factor(s) may be responsible for the learner's academic concern</h4>
              <ul>
                <li class="white-space-pre-line" *ngFor="let factor of worksheet.sldFactors">
                  {{ factor }}
                </li>
              </ul>
              <p class="mt-2 mb-0 white-space-pre-line" *ngIf="worksheet.factorsInSchoolSuccessExplanation">
                {{ worksheet.factorsInSchoolSuccessExplanation }}
              </p>
            </li>
          </ul>
        </li>
      </ul>
    </mat-card>
  </section>

  <div class="card-spacing-top">
    <app-help-directional-text [outsideCard]="true">
      <p *ngIf="currentEvaluation.eligibilityMeetingComplete; else eligibilityNotComplete">
        To determine eligibility for special education, the team must consider the following:
      </p>

      <ng-template #eligibilityNotComplete>
        <p>
          Eligibility cannot be determined until the meeting roll call for the Eligibility for Special Education Services has been
          completed. Meeting roll call can be accessed on the Learner Dashboard.
        </p>
      </ng-template>
    </app-help-directional-text>
  </div>

  <div #eligibilityQuestions></div>
  <section class="card-spacing-top box-shadow--normal" *ngIf="currentEvaluation.eligibilityMeetingComplete">
    <mat-accordion displayMode="flat" multi class="accordion accordion--close accordion--short accordion--shadow">
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title><h3 class="my-0 text-normal">Part 1: Disability Decision</h3> </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="form__description mb-2">
          To determine if {{ currentEvaluation?.learner?.firstName }} has a disability the team must consider the following criteria:
          <ul class="mt-2 mb-0">
            <li>
              Are there age/grade standards or expectations that the learner is not meeting AND if so do any of the deficits adversely
              affect child's involvement in age/grade activities and curriculum, interpersonal relationships, and/or are they interfering
              with other educational performance?
            </li>

            <li>When provided scientific, research-based instruction, does the learner make less progress than expected?</li>

            <li>
              Are the concerns PRIMARILY due to the other factors such as lack of instruction (for young children lack of age appropriate
              opportunities from home/childcare/preschool), English Language Proficiency and/or disadvantage?
            </li>
            <li *ngIf="currentEvaluation.consideringSld">
              Are there academic concerns that are PRIMARILY due to one or more of the following: visual impairment, hearing impairment,
              motor impairment, intellectual disability, emotional disturbance, medical condition?
            </li>
          </ul>
        </div>
        <fieldset>
          <app-radio-group
            label="Does the learner have a disability?"
            [options]="yesNoOptions"
            formControlName="hasDisability"
          ></app-radio-group>
        </fieldset>

        <ng-container *ngIf="currentEvaluation.consideringSld">
          <hr class="input__divider my-2" />
          <fieldset>
            <app-radio-group
              label="Does a specific learning disability contribute to this disability?"
              [options]="yesNoOptions"
              formControlName="doesLearningDisabilityContribute"
            ></app-radio-group>
          </fieldset>
        </ng-container>

        <hr class="input__divider mt-2 mb-1" />

        <app-textarea
          label="Comments"
          [rows]="4"
          [maxLength]="stringSizes.extraLarge"
          formControlName="part1Comments"
          conditionallyRequired="doesLearningDisabilityContribute"
        ></app-textarea>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title><h3 class="my-0 text-normal">Part 2: Instruction Need Decision</h3> </mat-panel-title>
        </mat-expansion-panel-header>
        <fieldset>
          <app-radio-group
            label="Does the learner have educational needs that required specially designed instruction?"
            [options]="yesNoOptions"
            formControlName="needsAdaptedContent"
          ></app-radio-group>
        </fieldset>

        <hr class="input__divider mt-3 mb-1" />

        <app-textarea label="Comments" [rows]="4" [maxLength]="stringSizes.extraLarge" formControlName="part2Comments"></app-textarea>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0" *ngIf="displayPart3">
        <mat-expansion-panel-header>
          <mat-panel-title><h3 class="my-0 text-normal">Part 3: Special Education Eligibility Decision</h3> </mat-panel-title>
        </mat-expansion-panel-header>
        <fieldset>
          <app-radio-group
            label="The learner's special education designation is:"
            [options]="specialEducationDesignationIs"
            formControlName="specialEdDesignation"
          ></app-radio-group>
        </fieldset>

        <mat-error *ngIf="displayWarning"> Previously answered eligibility questions do not support this designation. </mat-error>

        <hr class="input__divider mt-2 mb-0" />

        <app-textarea label="Comments" [rows]="4" [maxLength]="stringSizes.extraLarge" formControlName="part3Comments"></app-textarea>
      </mat-expansion-panel>

      <mat-expansion-panel
        class="mat-elevation-z0"
        *ngIf="
          formGroup.get('hasDisability').value === false &&
          formGroup.get('needsAdaptedContent').value &&
          formGroup.get('specialEdDesignation').value === false
        "
      >
        <mat-expansion-panel-header>
          <mat-panel-title><h3 class="my-0 text-normal">Part 4: Next Steps to Address the Learner's Needs</h3> </mat-panel-title>
        </mat-expansion-panel-header>

        <app-textarea
          label="If the learner is not eligible for special education and has specific instructional needs, what are the next steps to meeting the learner's needs?"
          [rows]="4"
          [maxLength]="stringSizes.extraLarge"
          formControlName="part4Comments"
        ></app-textarea>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0" *ngIf="currentEvaluation.consideringSld">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="display-flex align-items-center justify-content-between flex-1 flex-wrap my-1">
              <h3 class="my-0 text-normal">SLD Documentation</h3>

              <button mat-raised-button aria-label="Add Response" color="accent" (click)="onAddRow($event)" tabindex="0">
                Add Response
              </button>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-help-directional-text>
          <p>
            Each required team member must certify in writing whether the report reflects the member's conclusions. If it does not reflect
            the member's conclusions, the group member is encouraged to submit a separate statement of their conclusions.
          </p>
        </app-help-directional-text>
        <div class="display-flex align-items-center justify-content-between mb-1">
          <p class="my-0">
            <strong>I agree</strong>
            with the above findings and results:
          </p>
        </div>

        <div class="table-overflow app-table">
          <table mat-table [dataSource]="agreeDataSource" matSort class="w-100">
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let signature">
                <button mat-icon-button aria-label="Delete Icon" color="warn" (click)="removeSignature(signature)" tabindex="0">
                  <mat-icon>close</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let signature">
                {{ signature.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Role / Job Title</th>
              <td mat-cell *matCellDef="let signature">
                {{ signature.role }}
              </td>
            </ng-container>

            <ng-container matColumnDef="signedOn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Signed</th>
              <td mat-cell *matCellDef="let signature">
                {{ signature.signedOn | dateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="noResults">
              <td mat-footer-cell *matFooterCellDef colspan="7">
                <i>No signatures yet...</i>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="!agreeDataSource.data?.length ? ['noResults'] : []" class="example-second-footer-row"></tr>
          </table>
          <mat-paginator [dataSource]="agreeDataSource"></mat-paginator>
        </div>

        <div class="display-flex justify-content-between align-items-center mt-4 mb-1">
          <p class="my-0">
            <strong>I do not agree</strong>
            with the above findings and results (these individuals are encouraged to submit a statement of other findings):
          </p>
        </div>
        <div class="table-overflow app-table">
          <table mat-table [dataSource]="disagreeDataSource" matSort class="w-100">
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let signature">
                <button aria-label="Delete Icon" mat-icon-button color="warn" (click)="removeSignature(signature)" tabindex="0">
                  <mat-icon>close</mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let signature">
                {{ signature.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Role / Job Title</th>
              <td mat-cell *matCellDef="let signature">
                {{ signature.role }}
              </td>
            </ng-container>

            <ng-container matColumnDef="signedOn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Signed</th>
              <td mat-cell *matCellDef="let signature">
                {{ signature.signedOn | dateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="noResults">
              <td mat-footer-cell *matFooterCellDef colspan="7">
                <i>No signatures yet...</i>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr
              mat-footer-row
              *matFooterRowDef="!disagreeDataSource.data?.length ? ['noResults'] : []"
              class="example-second-footer-row"
            ></tr>
          </table>
          <mat-paginator [dataSource]="disagreeDataSource"></mat-paginator>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>

  <ng-container>
    <mat-card class="card-spacing-top">
      <app-incomplete-data-report
        [incompleteItems]="incompleteItems"
        (navigateToAction)="onNavigate($event)"
        (getIncompleteDataReport)="onGetIncompleteDataReport()"
      ></app-incomplete-data-report>
    </mat-card>
  </ng-container>

  <div class="action__row">
    <button
      [disabled]="formGroup.invalid || !worksheet?.isCompleted || incompleteItems.length > 0 || currentEvaluation.isFinalized"
      (click)="onFinalize()"
      [disableAfterBusy]="true"
      [isBusy]="activeCall"
      mat-raised-button
      aria-label="Finalize"
      color="primary"
      tabindex="0"
    >
      Finalize
    </button>
  </div>
</form>
<div class="page-bottom-leeway"></div>
