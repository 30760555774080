import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-clear-form-button',
  templateUrl: './clear-form-button.component.html',
  styleUrls: ['./clear-form-button.component.scss'],
})
export class ClearFormButtonComponent {
  @Input() formGroup: FormGroup;
  @Output() formCleared = new EventEmitter<void>();

  get formGroupKeys(): Array<string> {
    return Object.keys(this.formGroup.controls);
  }

  get isDisabled(): boolean {
    if (!this.formGroup) return true;

    // This should use typeof to check more in depth on if the field is actually empty.
    // But for just the CaseList POC this should be fine.
    return this.formGroupKeys
      .map((controlName: string) => this.formGroup.get(controlName).value)
      .every((value) => (value === null || value === '' ? true : false));
  }

  constructor() {}

  onClearForm(e: Event): void {
    e.preventDefault();
    e.stopPropagation();

    this.formGroupKeys.forEach((key: string) => this.formGroup.get(key).reset());
    this.formCleared.emit();
  }
}
