import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProgressMonitorPlanStatus } from '../models/outcome-progress-monitor';

@Injectable({
  providedIn: 'root',
})
export class ProgressMonitorPlanStatusService {
  constructor(private http: HttpClient) {}

  get(learnerId: string) {
    return this.http.get<Array<ProgressMonitorPlanStatus>>(`api/learners/${learnerId}/progress-monitoring-c/plan-statuses`);
  }

  add(learnerId: string, dto: ProgressMonitorPlanStatus) {
    return this.http.post<ProgressMonitorPlanStatus>(`api/learners/${learnerId}/progress-monitoring-c/plan-statuses`, dto);
  }

  update(learnerId: string, dto: ProgressMonitorPlanStatus) {
    return this.http.put<ProgressMonitorPlanStatus>(`api/learners/${learnerId}/progress-monitoring-c/plan-statuses/${dto.id}`, dto);
  }

  delete(learnerId: string, id: string) {
    return this.http.put(`api/learners/${learnerId}/progress-monitoring-c/plan-statuses/delete/${id}`, null);
  }
}
