<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Reassign Facilitator</h3>
<mat-dialog-content>
  <form [formGroup]="formGroup">
    <app-autocomplete label="Available Team Members" [options]="teamUsers" formControlName="user"></app-autocomplete>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button aria-label="Cancel Button" mat-dialog-close cdkFocusInitial tabindex="0">Cancel</button>
  <button
    mat-raised-button
    aria-label="Complete"
    [mat-dialog-close]="formGroup.get('user').value"
    [disabled]="!formGroup.valid"
    tabindex="0"
    color="primary"
  >
    Complete
  </button>
</mat-dialog-actions>
