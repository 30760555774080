import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GoalProgressMonitor, ObjectiveProgressMonitor } from 'src/app/monitoring-progress/models/goal-progress-monitor';
import { CriterionProgressMonitor } from 'src/app/monitoring-progress/models/outcome-progress-monitor';
import { ProgressMonitorPhaseLine } from 'src/app/monitoring-progress/models/phase-line';
import { ProgressMonitorLookupService } from 'src/app/monitoring-progress/services/progress-monitor-lookup.service';
import { ProgressMonitorPhaseLineService } from 'src/app/monitoring-progress/services/progress-monitor-phase-line.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { CaseSummary } from 'src/app/shared/models/case';
import { ConfirmationDialogComponent } from 'src/app/shared/services/notification.service';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { AuthService } from '../../../../../auth/auth.service';
import { DateFormatPipe, DateToUtcPipe } from '../../../../../shared/pipes/date-transform.pipe';

@Component({
  selector: 'app-phase-line-table',
  templateUrl: './phase-line-table.component.html',
  styleUrls: ['./phase-line-table.component.scss'],
})
export class PhaseLineTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() criterion: CriterionProgressMonitor | GoalProgressMonitor | ObjectiveProgressMonitor;
  @Input() caseSummary: CaseSummary;
  @Input() enableForms: boolean;
  @Input() minDate: Date;
  isEditing: boolean;
  shortDateFormat = shortDateFormat;
  today = new Date();
  displayedColumns = ['actions', 'date', 'note'];

  formGroup = this.fb.group({
    id: '',
    date: ['', Validators.required],
    progressMonitorLookupId: ['', Validators.required],
    noteOther: [null],
  });

  get isOther() {
    return this.progressMonitorLookupService.lookupOptions.find((x) => x.id === this.formGroup.get('progressMonitorLookupId').value)
      ?.isOther;
  }

  get phaseLineOptions() {
    return this.progressMonitorLookupService.phaseLineOptions;
  }

  get isPortalUser() {
    return this.authService?.isPortalUser;
  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private phaseLineService: ProgressMonitorPhaseLineService,
    private progressMonitorLookupService: ProgressMonitorLookupService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.isPortalUser) {
      this.displayedColumns = ['date', 'note'];
    }
  }

  ngAfterViewInit() {
    if (this.criterion.phaseLineData) {
      this.criterion.phaseLineData.sort = this.sort;
      this.criterion.phaseLineData.paginator = this.paginator;
    }
  }

  onPhaseLineUpdate(phaseLine, criterion) {
    if (this.formGroup.invalid) {
      return;
    }
    const dto = this.formGroup.value as ProgressMonitorPhaseLine;
    dto.date = new DateToUtcPipe().transform(new Date(dto.date));
    this.phaseLineService.update(this.caseSummary.learnerId, criterion.id, dto).subscribe((res) => {
      phaseLine.date = res.date;
      this.handlePhaseLineUpdate(phaseLine, criterion);
    });
  }

  onPhaseLineEdit(phaseLine) {
    this.isEditing = true;
    phaseLine.isEditing = true;
    this.formGroup.patchValue(phaseLine);
    if (this.isOther) {
      this.formGroup.get('noteOther').setValidators(Validators.required);
      this.formGroup.get('noteOther').updateValueAndValidity();
    }
  }

  onPhaseLineDelete(phaseLine: ProgressMonitorPhaseLine, criterion) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '728px',
      data: {
        title: 'Remove',
        message: 'Are you sure you want to remove this phase line?',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.phaseLineService
          .delete(this.caseSummary.learnerId, criterion.id, phaseLine.id)
          .subscribe(() => this.handlePhaseLineDelete(phaseLine.id, criterion));
      }
    });
  }

  onCancelPhaseLineEdit(phaseLine) {
    phaseLine.isEditing = false;
    this.isEditing = false;
    this.formGroup.reset();
  }

  phaseLineOptionSelected(value) {
    const noteOtherControl = this.formGroup.get('noteOther');
    if (!this.isOther) {
      noteOtherControl.setValue('');
      noteOtherControl.clearValidators();
    } else {
      noteOtherControl.setValidators(Validators.required);
    }
    noteOtherControl.updateValueAndValidity();
  }

  onViewMore(phaseLine: ProgressMonitorPhaseLine) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Date',
        value: new DateFormatPipe().transform(phaseLine.date),
      },
      {
        name: 'Phase Line',
        value: phaseLine.label,
      },
    ];

    if (phaseLine.noteOther) {
      modalData.push({ name: 'Other', value: phaseLine.noteOther });
    }

    openViewMore(this.dialog, modalData);
  }

  private handlePhaseLineUpdate(phaseLine, criterion) {
    phaseLine.progressMonitorLookupId = this.formGroup.get('progressMonitorLookupId').value;
    phaseLine.noteOther = this.formGroup.get('noteOther').value;
    phaseLine.label = this.progressMonitorLookupService.lookupOptions.find((x) => x.id === phaseLine.progressMonitorLookupId).label;
    const index = criterion.phaseLines.findIndex((x) => x.id === phaseLine.id);
    if (index > -1) {
      criterion.phaseLines[index] = phaseLine;
      this.sortPhaseLines(criterion);
      criterion.phaseLineData.data = criterion.phaseLines;
      this.onCancelPhaseLineEdit(phaseLine);
    }
  }

  private handlePhaseLineDelete(id, criterion) {
    const index = criterion.phaseLines.findIndex((x) => x.id === id);
    if (index > -1) {
      criterion.phaseLines.splice(index, 1);
      criterion.phaseLineData.data = criterion.phaseLines;
    }
  }

  private sortPhaseLines(criterion) {
    criterion.phaseLines = criterion.phaseLines.sort((a, b) => Date.parse(b.date.toString()) - Date.parse(a.date.toString()));
  }
}
