import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-temporary-help',
  template: `<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Coming Soon</h3>
    <mat-dialog-content>
      <div style="text-align:center;">
        <img src="../../../assets/img/content-coming-soon.svg" alt="Content coming soon" />
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button type="button" mat-raised-button [mat-dialog-close]="true" tabindex="0" aria-label="Cancel">Cancel</button>
    </mat-dialog-actions> `,
})
export class DialogComingSoonComponent {}
