import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CaseSummary } from '../../../../shared/models/case';
import { ReportingService } from '../../../../shared/services/reporting/reporting.service';
import { openPdfWindow } from '../../../../shared/windowHelpers';
import { ServiceLogSessionDto } from '../../models/service-log';
import { ServiceLogService } from '../../services/service-log.service';

@Component({
  selector: 'app-ifsp-fgi-view',
  templateUrl: './ifsp-fgi-view.component.html',
  styleUrls: ['./ifsp-fgi-view.component.scss'],
})
export class IfspFgiViewComponent implements OnInit {
  @Input() caseSummary: CaseSummary;

  dataSource = new MatTableDataSource<ServiceLogSessionDto>([]);
  displayedColumns = ['sessionDate', 'title'];

  constructor(private serviceLogService: ServiceLogService, private reportingService: ReportingService) {}

  ngOnInit(): void {
    this.serviceLogService.getServiceLogSessions(this.caseSummary.id).subscribe(
      (result) => {
        if (result.succeeded) {
          this.dataSource.data = result.value;
        } else {
          this.serviceLogService.handleError('An error occured while retrieving service log sessions', result);
        }
      },
      (error) => {
        this.serviceLogService.handleError('An error occured while removing survey', error);
      }
    );
  }

  getFgrbiOutput(serviceLogSession: ServiceLogSessionDto): void {
    if (serviceLogSession.documentId) {
      openPdfWindow(this.caseSummary.learnerId, serviceLogSession.documentId);
    } else {
      this.reportingService.createFgrbiOutput(serviceLogSession.serviceLogId).subscribe({
        next: (documentId: string) => openPdfWindow(this.caseSummary.learnerId, documentId),
        error: (err) => this.reportingService.handleOutputError(err),
      });
    }
  }
}
