import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest } from 'rxjs';
import { EvaluationDetailService } from 'src/app/evaluation/services/evaluation-detail.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { AuthService } from '../../../../auth/auth.service';
import { KeyValuePair } from '../../../../shared/models/key-value-pair';
import { MemoryStorageService } from '../../../../shared/services/memory-storage/memory-storage.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { RoutinesService } from '../../../../shared/services/routines/routines.service';
import { Evaluation, EvaluationDetail } from '../../../models/evaluation';
import { EvaluationRoutine } from '../../../models/evaluation-routine';
import { EvaluationRoutineService } from '../../../services/evaluation-routine.service';

@Component({
  selector: 'app-evaluation-routines-table',
  templateUrl: './evaluation-routines-table.component.html',
  styleUrls: ['./evaluation-routines-table.component.scss'],
})
export class EvaluationRoutinesTableComponent implements OnInit, AfterContentChecked {
  @ViewChild(MatSort) sort: MatSort;
  evaluation: Evaluation;

  editFormGroup = new FormGroup({
    routine: new FormControl(''),
    other: new FormControl(''),
    nickName: new FormControl(''),
    id: new FormControl(''),
  });

  get isValid() {
    return this.editFormGroup.valid;
  }

  displayedColumns: string[] = ['actions', 'routines', 'shortName'];
  dataSource = new MatTableDataSource<RoutineRow>();
  routines: KeyValuePair[];
  evaluationRoutines = [];
  otherKey: string;
  me: string;
  isEditing = false;
  addDisabled = true;
  addingNew = false;
  evaluationDetails: EvaluationDetail[] = [];

  constructor(
    private dialog: MatDialog,
    private readonly storage: MemoryStorageService,
    private readonly routinesService: RoutinesService,
    private readonly evaluationRoutineService: EvaluationRoutineService,
    private readonly notificationService: NotificationService,
    private readonly evaluationDetailService: EvaluationDetailService,
    private readonly authService: AuthService,
    private readonly cdRef: ChangeDetectorRef,
    private spinner: SpinnerService
  ) {}

  ngOnInit(): void {
    this.me = this.authService.user.id;
    this.evaluation = this.storage.getKey('currentEvaluation', true);
    combineLatest([
      this.routinesService.get(), // gets all of the routines for ddl
      this.evaluationDetailService.get(this.evaluation.id),
      this.evaluationRoutineService.get(this.evaluation.id), // gets routines used in this eval for grid
    ]).subscribe(([routines, evaluationDetails, evaluationRoutines]) => {
      this.evaluationDetails = evaluationDetails;

      this.routines = routines
        .filter((x) => this.evaluation.learner.isPK === x.isPk)
        .map((routine) => new KeyValuePair(routine.id, routine.label))
        .sort((a, b) => {
          if (a.value === 'Other') {
            return 1;
          }
          if (b.value === 'Other') {
            return -1;
          }
          return a.value.localeCompare(b.value);
        });

      this.evaluationRoutines = evaluationRoutines;
      this.dataSource.data = this.evaluationRoutines.map((x: RoutineRow) => {
        x.isEditing = false;
        return x as RoutineRow;
      });
      this.otherKey = this.routines.find((x) => x.value === 'Other')?.key;
      this.setupTableSorting();
    });
    this.editFormGroup.controls.routine.valueChanges.subscribe((change) => {
      if (change === this.otherKey) {
        this.editFormGroup.controls.other.setValidators(Validators.required);
        this.editFormGroup.controls.other.updateValueAndValidity();
      } else {
        this.editFormGroup.controls.other.setValidators(null);
        this.editFormGroup.controls.other.updateValueAndValidity();
      }
    });
  }

  private setupTableSorting() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'routines':
          return item.label;
        case 'shortName':
          return item.nickName;
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  getRoutines() {
    this.evaluationRoutineService.get(this.evaluation.id).subscribe((evaluationRoutines) => {
      this.evaluationRoutines = evaluationRoutines;
      this.dataSource.data = this.evaluationRoutines.map((x: RoutineRow) => {
        x.isEditing = false;
        return x as RoutineRow;
      });
    });
  }

  routineChanged(routineKey: string) {
    if (!routineKey) {
      this.addDisabled = true;
      return;
    }

    if (routineKey !== this.otherKey) {
      this.editFormGroup.controls.other.setValue('');
      this.addDisabled = false;
      return;
    }

    if (!this.editFormGroup.controls.other.value) {
      this.addDisabled = true;
    }
  }

  private otherChanged(value: string) {
    if (value) {
      this.addDisabled = false;
    }
  }

  onRemove(item) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        subQuestion: 'Clicking Yes will remove this item.',
      },
    });

    dialogRef.afterClosed().subscribe((yesImSure) => {
      if (yesImSure) {
        this.evaluationRoutineService.remove(this.evaluation.id, item.id).subscribe(
          () => {
            this.editFormGroup.reset();
            this.getRoutines();
            this.notificationService.success('Saved');
          },
          (error) => {
            // TODO: Get wording for this
            this.notificationService.error('Routine is associated with an active Evaluation Detail Note, and cannot be removed.', 5000);
          }
        );
      }
    });
  }

  updateTouchedAndValidity() {
    this.editFormGroup.updateValueAndValidity();
    this.editFormGroup.markAllAsTouched();
  }

  saveRoutine(routine: RoutineRow) {
    this.updateTouchedAndValidity();
    if (this.isValid) {
      this.spinner.incrementLoading();
      if (routine === null || routine.id === null || routine.id === '') {
        this.evaluationRoutineService
          .add(this.evaluation.id, {
            nickName: this.editFormGroup.controls.nickName.value,
            otherLabel: this.editFormGroup.get('other') ? this.editFormGroup.get('other').value : 'Other',
            routineId: this.editFormGroup.controls.routine.value,
          } as EvaluationRoutine)
          .subscribe((evaluationRoutine) => {
            this.editFormGroup.reset();
            this.notificationService.success('Saved');
            this.getRoutines();
            this.setEdit(routine, false);
            this.spinner.decrementLoading();
          });
      } else {
        if (this.editFormGroup.valid) {
          this.evaluationRoutineService
            .update(this.evaluation.id, this.editFormGroup.controls.id.value, {
              nickName: this.editFormGroup.controls.nickName.value,
              otherLabel: this.editFormGroup.controls.other.value,
              routineId: this.editFormGroup.controls.routine.value,
            })
            .subscribe(() => {
              this.editFormGroup.reset();
              this.getRoutines();
              this.notificationService.success('Saved');
              routine.isEditing = false;
              this.isEditing = false;
              this.editFormGroup.get('routine').enable();
              this.patchFormValues();
              this.spinner.decrementLoading();
            });
        }
      }
    }
  }

  patchFormValues() {
    this.editFormGroup.setValue({
      id: '',
      routine: '',
      other: '',
      nickName: '',
    });
  }

  setEdit(routine: RoutineRow, editing = true) {
    this.addingNew = false;
    this.isEditing = editing;
    if (routine !== null) {
      routine.isEditing = editing;
    }
    if (!editing) {
      this.patchFormValues();
      this.editFormGroup.reset();
      this.editFormGroup.get('routine').enable();
      return;
    }

    const detailHasRoutine = this.evaluationDetails.flatMap((x) => x.routines).some((x) => x.evaluationRoutineId === routine.id);

    const noteHasRoutine = this.evaluationDetails
      .flatMap((x) => x.notes)
      .flatMap((x) => x.evaluationRoutines)
      .some((x) => x.id === routine.id);

    this.editFormGroup.patchValue({
      routine: routine.routineId,
      other: routine.otherLabel,
      nickName: routine.nickName,
      id: routine.id,
    });

    if (detailHasRoutine || noteHasRoutine) {
      this.editFormGroup.get('routine').disable();
    }
  }
}

interface RoutineRow extends EvaluationRoutine {
  isEditing: boolean;
}
