import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { KeyValuePair } from '../../shared/models/key-value-pair';
import { Clp } from '../models/clp';
import { clpIepServiceDeliveryFormat } from '../models/clp-iep-service-delivery-format';
import { clpIepServiceFrequency } from '../models/clp-iep-service-frequency';
import { OperationResult } from 'src/app/behavior-discipline/models/bip';
import { OperationResultWithValue } from 'src/app/shared/models/operation-result';

@Injectable({
  providedIn: 'root',
})
export class ClpService {
  private readonly baseUrl = 'api/continuous-learning-plan';

  constructor(private readonly httpClient: HttpClient) {}

  public frequencyOptions: KeyValuePair[] = [
    new KeyValuePair('PerformOriginal', 'As listed in the IEP'),
    new KeyValuePair('AddOrDecrease', 'Adjusted'),
    new KeyValuePair('Pause', 'Paused'),
  ];

  public getClp(iepId: string): Observable<OperationResultWithValue<Clp>> {
    return this.httpClient.get<OperationResultWithValue<Clp>>(`${this.baseUrl}/by-iep/${iepId}`);
  }

  public unlockClp(iepId: string): Observable<OperationResult> {
    return this.httpClient.get<OperationResult>(`${this.baseUrl}/unlock/by-iep/${iepId}`);
  }

  public updateClp(iepId: string, clp: Clp): Observable<OperationResult> {
    return this.httpClient.put<OperationResult>(`${this.baseUrl}/by-iep/${iepId}`, clp);
  }

  public getClpSpecialEducationServicesFrequencies() {
    return this.httpClient.get<clpIepServiceFrequency[]>(`${this.baseUrl}/frequencies`).pipe(take(1));
  }

  public getClpSpecialEducationServicesDeliveryFormats() {
    return this.httpClient.get<clpIepServiceDeliveryFormat[]>(`${this.baseUrl}/delivery-formats`).pipe(take(1));
  }
}
