import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { shortDateFormat } from '../../../../../../shared/dateTimeHelpers';
import { IepAccommodationService } from '../../../../../services/iep-accommodation.service';
import { IepActivitySupportService } from '../../../../../services/iep-activity-support.service';
import { IepAccommodationInfoComponent } from '../../iep-details-accommodations/iep-accommodation-info/iep-accommodation-info.component';

@Component({
  selector: 'app-iep-activities-info',
  templateUrl: './iep-activities-info.component.html',
  styleUrls: ['./iep-activities-info.component.scss'],
})
export class IepActivitiesInfoComponent implements OnInit {
  shortDateFormat = shortDateFormat;
  iepId: string;
  activitiesSupportId: string;
  activitiesSupport: any;
  displayedColumns = ['date', 'changes'];
  dataSource = new MatTableDataSource([]);

  constructor(
    public iepActivitySupportService: IepActivitySupportService,
    private dialogRef: MatDialogRef<IepAccommodationInfoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { activitySupport: any; id: string; iepId: string }
  ) {
    this.activitiesSupport = data.activitySupport;
    this.activitiesSupportId = data.id;
    this.iepId = data.iepId;
  }

  ngOnInit(): void {
    this.iepActivitySupportService.getAuditLogs(this.iepId, this.activitiesSupportId).subscribe((result) => {
      this.dataSource.data = result;
    });
  }

  dismiss() {
    this.dialogRef.close();
  }
}
