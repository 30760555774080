<section class="eer" *ngIf="isReady">
  <div class="print-preview">
    <h1 class="print-preview__text">DRAFT</h1>
  </div>

  <!--
    TODO:
    Don't have:
    - Building
    - Attending Building
   -->

  <div class="print__heading">
    <div class="display-flex justify-content-between align-items-center">
      <img src="../../../../assets/img/achieve-logo.svg" class="print__logo print__logo--main" alt="ACHIEVE Logo" />

      <div *ngIf="showActions">
        <button
          mat-raised-button
          color="accent"
          title="Close"
          aria-label="Delete Icon"
          class="hide-print"
          (click)="closeWindow()"
          tabindex="0"
        >
          Close
        </button>
        <button
          mat-raised-button
          color="primary"
          aria-label="Print"
          title="Print"
          type="button"
          class="ml-2 hide-print"
          (click)="onPrint()"
          tabindex="0"
        >
          Print
        </button>
      </div>
    </div>
  </div>

  <section class="printable">
    <h1 class="mb-2">Educational Evaluation Report</h1>
    <div class="print__container">
      <div class="row avoid-break mb-3">
        <div class="col-md-12">
          <p>
            The purpose of the evaluation is to collect information to determine if a learner has an educational disability and identify
            {{ eer.learner.firstName | firstNamePossessive }} educational needs. This report summarizes information gathered during the
            evaluation process and will be used by the eligibility team to determine if the learner is eligible for special education
            services.
          </p>
        </div>
      </div>

      <p *ngIf="eer.eligibilityMeetingDate">
        <strong>Eligibility Meeting Date: </strong>
        {{ eer.eligibilityMeetingDate | dateFormat }}
      </p>
      <p *ngIf="!eer.eligibilityMeetingDate"><strong>Eligibility Meeting Date: </strong><i>Not Yet Scheduled</i></p>

      <div class="learner__header avoid-break" *ngIf="eer.learner">
        <p *ngIf="eer.learner.fullName">
          <strong>Name: </strong>
          {{ eer.learner?.fullName }}
        </p>

        <p *ngIf="eer.learner.dateOfBirth">
          <strong>DOB: </strong>
          {{ eer.learner?.dateOfBirth | dateFormat }}
        </p>

        <p *ngIf="eer.learner.gender.label">
          <strong>Gender: </strong>
          {{ eer.learner?.gender?.label }}
        </p>

        <p *ngIf="eer.learner.grade || eer.learner.gradeDescription">
          <strong>Grade: </strong>
          {{ eer.learner?.grade ? eer.learner?.grade : eer.learner?.gradeDescription }}
        </p>
      </div>

      <section class="avoid-break">
        <h3 *ngIf="eer.learner" class="mt-0 mb-2">Districts / Agency</h3>
        <div class="row flex-wrap" *ngIf="eer.learner">
          <div class="col" *ngIf="eer.learner.residentDistrict">
            <div class="printable__question">
              <p><strong>Resident District: </strong></p>
              <p class="printable__answer">
                {{ eer.learner?.residentDistrict?.name }}
              </p>
            </div>
          </div>

          <div class="col" *ngIf="eer.learner.attendingDistrict">
            <div class="printable__question">
              <p><strong>Attending District: </strong></p>
              <p class="printable__answer">
                {{ eer.learner?.attendingDistrict?.name }}
              </p>
            </div>
          </div>

          <div class="col" *ngIf="eer.learner.attendingAea.name">
            <div class="printable__question">
              <p><strong>Area Education Agency: </strong></p>
              <p class="printable__answer">
                {{ eer.learner?.attendingAea?.name }}
              </p>
            </div>
          </div>
          <!-- TODO Attending Building Phone -->
          <!-- <div class="col" *ngIf="eer.learner.attendingbuilding.phone..." >
        <div class="printable__question">
          <p>
            <strong>Attending Building Phone: </strong>
          </p>
          <p class="printable__answer">
            {{eer.learner.attendingbuild.phone}}
          </p>
        </div>
      </div> -->
        </div>
      </section>

      <hr class="input__divider my-2" />

      <section class="avoid-break" *ngIf="eer.learner && eer.learner.familyMembers && eer.learner.familyMembers.length > 0">
        <h3 class="mt-0 mb-2">Family Member(s)</h3>
        <div class="row" *ngFor="let member of eer.learner.familyMembers; last as isLast">
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngIf="member.familyRelationship">
            <div class="printable__question">
              <p><strong>Relationship: </strong></p>
              <p class="printable__answer">
                {{ member?.familyRelationship }}
              </p>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngIf="member.fullName">
            <div class="printable__question">
              <p><strong>Name: </strong></p>
              <p class="printable__answer">
                {{ member.fullName }}
              </p>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngIf="member.email">
            <div class="printable__question">
              <p><strong>Email: </strong></p>
              <p class="printable__answer">
                {{ member.email }}
              </p>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngIf="getAddress(member)">
            <div class="printable__question">
              <p><strong>Address: </strong></p>
              <p class="printable__answer">
                {{ getAddress(member) }}
              </p>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngIf="member?.livesWithChild !== null">
            <div class="printable__question">
              <p><strong>Student Lives at this Address: </strong></p>
              <p class="printable__answer">
                {{ member?.livesWithChild ? 'Yes' : 'No' }}
              </p>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngIf="member.homePhone">
            <div class="printable__question">
              <p><strong>Home Phone: </strong></p>
              <p class="printable__answer">
                {{ member?.homePhone | phone }}
              </p>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngIf="member.cellPhone">
            <div class="printable__question">
              <p><strong>Cell Phone: </strong></p>
              <p class="printable__answer">
                {{ member?.cellPhone | phone }}
              </p>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngIf="member.workPhone">
            <div class="printable__question">
              <p><strong>Work Phone: </strong></p>
              <p class="printable__answer">
                {{ member?.workPhone | phone }}
              </p>
            </div>
          </div>

          <hr class="input__divider my-2" *ngIf="!isLast" />
        </div>
      </section>

      <hr *ngIf="eer.evaluationFacilitator?.fullName" class="input__divider my-2" />

      <section class="avoid-break" *ngIf="eer.evaluationFacilitator">
        <h3 class="mt-0 mb-4">Contact Person for this report</h3>
        <div class="row">
          <div class="col" *ngIf="eer.evaluationFacilitator?.fullName">
            <div class="printable__question">
              <p><strong>Name: </strong></p>
              <p class="printable__answer">
                {{ eer.evaluationFacilitator.fullName }}
              </p>
            </div>
          </div>

          <div class="col" *ngIf="eer.evaluationFacilitator?.phoneNumber">
            <div class="printable__question">
              <p><strong>Phone Number: </strong></p>
              <p class="printable__answer">
                {{ eer.evaluationFacilitator.phoneNumber | phone }}
              </p>
            </div>
          </div>

          <div class="col" *ngIf="eer.evaluationFacilitator?.email">
            <div class="printable__question">
              <p><strong>Email: </strong></p>
              <p class="printable__answer">
                {{ eer.evaluationFacilitator.email }}
              </p>
            </div>
          </div>
        </div>
      </section>

      <hr class="input__divider my-2" />

      <section id="part-1" class="avoid-break">
        <h3 class="mt-0 mb-2">Part 1: Family and School Concerns</h3>
        <p class="mt-0 mb-2">
          This section describes the referral concern/s, areas of suspected disability (and related concerns) and questions to be answered
          by the evaluation.
        </p>

        <p class="mt-1 mb-2">The following areas of suspected disability and related concerns were evaluated:</p>
        <ul>
          <li *ngFor="let label of eer.assignedDomainNames">{{ label }}</li>
        </ul>
        <h4 class="my-0 text-primary" *ngIf="eer.strengthsConcernsBackground.strengthsSummary">Strengths Summary</h4>
        <p>
          {{ eer.strengthsConcernsBackground.strengthsSummary }}
        </p>
        <h4 class="mt-3 mb-0 text-primary" *ngIf="eer.strengthsConcernsBackground.concernsSummary">Concerns Summary</h4>
        <p>
          {{ eer.strengthsConcernsBackground.concernsSummary }}
        </p>
      </section>

      <hr class="input__divider my-2" />

      <section id="part-2" class="avoid-break">
        <h3 class="mt-0 mb-2">Part 2: Background Information</h3>
        <p class="mt-0 mb-2">
          This section describes relevant background information. Information may include factors relevant from family history, child
          development, language acquisition, medical history, prior evaluation and prior educational intervention/supports put in place
          through family and/or school.
        </p>
        <h4 class="my-0 text-primary" *ngIf="eer.strengthsConcernsBackground.shortSummary">Short Summary</h4>
        <p class="mt-0 mb-2">
          {{ eer.strengthsConcernsBackground.shortSummary }}
        </p>

        <ng-container *ngIf="eer.strengthsConcernsBackground.historicalSynthesis && !eer.strengthsConcernsBackground.shortSummary">
          <h4 class="my-0">Historical Synthesis</h4>
          <p>
            {{ eer.strengthsConcernsBackground.historicalSynthesis }}
          </p>
        </ng-container>
      </section>

      <hr class="input__divider my-2" />

      <section id="part-3" class="avoid-break">
        <h3 class="mt-0 mb-2">Part 3: Present Levels of Performance</h3>
        <p class="mt-0 mb-4">
          This section describes
          {{ eer.learner.firstName | firstNamePossessive }} strengths and weaknesses across the range of age/grade based
          standards/expectations related to the concern/s.
        </p>

        <div *ngFor="let domain of domains">
          <mat-card *ngIf="getLearnerPerformanceDataSource(domain)?.data?.length > 0" class="card-spacing-top">
            <h4 class="text-primary mb-2">{{ domain.label }}</h4>
            <table mat-table class="w-100 column-padding" [dataSource]="getLearnerPerformanceDataSource(domain)">
              <ng-container matColumnDef="areaOfConcern">
                <th mat-header-cell *matHeaderCellDef>Area of Concern</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.areaOfConcern }}
                </td>
              </ng-container>

              <ng-container matColumnDef="performanceNarrative">
                <th mat-header-cell *matHeaderCellDef>
                  {{ eer.learner.firstName | firstNamePossessive }}
                  Performance
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.performanceNarrative }}
                </td>
              </ng-container>

              <ng-container matColumnDef="expectedPerformanceNarrative">
                <th mat-header-cell *matHeaderCellDef>Expected Performance</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.expectedPerformanceNarrative }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </mat-card>
        </div>
        <div *ngFor="let group of learnerPerformanceGroups | keyvalue">
          <mat-card *ngIf="getGroupValue(group.value)?.data?.length > 0" class="card-spacing-top">
            <h4 class="text-primary mb-2">{{ getDomainLabel(group.key) }}</h4>
            <table mat-table class="w-100 column-padding" [dataSource]="getGroupValue(group.value)">
              <ng-container matColumnDef="areaOfConcern">
                <th mat-header-cell *matHeaderCellDef>Area of Concern</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.areaOfConcern }}
                </td>
              </ng-container>

              <ng-container matColumnDef="performanceNarrative">
                <th mat-header-cell *matHeaderCellDef>
                  {{ eer.learner.firstName | firstNamePossessive }}
                  Performance
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.performanceNarrative }}
                </td>
              </ng-container>

              <ng-container matColumnDef="expectedPerformanceNarrative">
                <th mat-header-cell *matHeaderCellDef>Expected Performance</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.expectedPerformanceNarrative }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </mat-card>
        </div>
        <h4 class="text-primary" *ngIf="eer?.learnerPerformanceSummary">Learner Performance Summary</h4>
        <p *ngIf="eer?.learnerPerformanceSummary">
          {{ eer.learnerPerformanceSummary }}
        </p>
      </section>

      <hr class="input__divider my-2" />

      <section id="part-4" class="avoid-break">
        <h3 class="mt-0 mb-2">Part 4: Instructional/Intervention Plan(s)</h3>
        <p class="mt-0 mb-4">
          This section will describe
          {{ eer.learner.firstName | firstNamePossessive }} progress based on the most recent intervention/s or instructional plan.
        </p>

        <div>
          <ng-container *ngFor="let domain of domains; let i = index">
            <ng-container *ngIf="getLearnerProgressByDomain(domain)?.length > 0">
              <div *ngFor="let lp of getLearnerProgressByDomain(domain); let last = last">
                <h3 class="text-primary mt-0 mb-2">{{ domain.label }}</h3>
                <h4 class="text-primary mt-0 mb-1">
                  {{ getEvaluationTimingOptionLabel(lp.evaluationTiming) }}
                </h4>
                <hr class="input__divider my-2" />
                <ul class="list mt-0" *ngIf="lp.evaluationTiming === evaluationTimingEnum.IsPriorToEvaluation">
                  <li class="list__item">
                    <h4 class="text-primary mt-0 mb-1">
                      {{ concernsAddressedLabel }}
                    </h4>
                    <p class="my-0">{{ lp.concernsAddressedStrategiesUsed }}</p>
                  </li>

                  <li class="list__item" *ngIf="lp.implementedStrategies.length > 0">
                    <h4 class="text-primary mt-0 mb-1">Strategies</h4>

                    <table mat-table [dataSource]="lp.implementedStrategies" class="mat-elevation-z0 w-100">
                      <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Strategy</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.name }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>Description (what and when)</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.description }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="personsResponsible">
                        <th mat-header-cell *matHeaderCellDef>Person(s) Responsible</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.personsResponsible }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="isMostEffective">
                        <th mat-header-cell *matHeaderCellDef>Which of the key instruction intervention strategies was most effective?</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.isMostEffective ? 'Among the most effective' : 'Not among the most effective' }}
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="strategyColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: strategyColumns"></tr>
                      <tr
                        mat-footer-row
                        [hidden]="lp.implementedStrategies.length !== 0"
                        *matFooterRowDef="lp.implementedStrategies.length === 0 ? ['noResults'] : []"
                        class="example-second-footer-row"
                      ></tr>
                    </table>
                  </li>

                  <li class="list__item" *ngIf="lp.howLearnerProgressMonitored">
                    <h4 class="text-primary mt-0 mb-1">
                      How was
                      {{ eer.learner.firstName | firstNamePossessive }} progress monitored:
                    </h4>
                    <p class="my-0">
                      {{ lp.howLearnerProgressMonitored }}
                    </p>
                  </li>

                  <li class="list__item" *ngIf="lp.howMonitored">
                    <h4 class="text-primary mt-0 mb-1">How was intervention implementation monitored:</h4>
                    <p class="my-0">{{ lp.howMonitored }}</p>
                  </li>

                  <li class="list__item" *ngIf="lp.limitationsToThePlan">
                    <h4 class="text-primary mt-0 mb-1">
                      Are there any limitations regarding this plan that influences the use of this data as a part of the evaluation:
                    </h4>
                    <p class="my-0">{{ lp.limitationsToThePlan }}</p>
                  </li>
                </ul>

                <ul class="list mt-0" *ngIf="lp.evaluationTiming === evaluationTimingEnum.NotBeingEvaluated">
                  <li class="list__item">
                    <h4 class="text-primary mt-0 mb-1">
                      {{ getTimingLabel(lp.notEvaluatedReasonId) }}
                    </h4>
                    <p class="my-0">{{ lp.notEvaluatedExplanation }}</p>
                  </li>
                </ul>
                <ng-container *ngIf="!last">
                  <hr class="input__divider my-2" />
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngFor="let lpGroup of learnerProgressGroups | keyvalue; let i = index">
            <div *ngFor="let group of getLpGroupValue(lpGroup)">
              <h3 class="text-primary mt-0 mb-2">
                {{ getDomainLabel(lpGroup.key) }}
              </h3>
              <h4 class="text-primary mt-0 mb-1">
                {{ getEvaluationTimingOptionLabel(group.evaluationTiming) }}
              </h4>
              <hr class="input__divider my-2" />
              <ul class="list" *ngIf="group.evaluationTiming === evaluationTimingEnum.IsPriorToEvaluation">
                <li class="list__item">
                  <h4 class="text-primary mt-0 mb-1">
                    {{ concernsAddressedLabel }}
                  </h4>
                  <p class="my-0">
                    {{ group.concernsAddressedStrategiesUsed }}
                  </p>
                </li>

                <ng-container *ngFor="let strategy of group.implementedStrategies">
                  <li class="list__item" *ngIf="strategy.name">
                    <h4 class="text-primary mt-0 mb-1">Strategy Name:</h4>
                    <p class="my-0">{{ strategy.name }}</p>
                  </li>

                  <li class="list__item">
                    <h4 class="text-primary mt-0 mb-1">Strategy Effectiveness:</h4>
                    <p class="my-0">
                      {{ strategy.isMostEffective ? 'Among the most effective' : 'Not among the most effective' }}
                    </p>
                  </li>
                </ng-container>

                <li class="list__item">
                  <h4 class="text-primary mt-0 mb-1">
                    How was
                    {{ eer.learner.firstName | firstNamePossessive }}
                    progress monitored?
                  </h4>
                  <p class="my-0">
                    {{ group.howLearnerProgressMonitored }}
                  </p>
                </li>
                <li class="list__item">
                  <h4 class="text-primary mt-0 mb-1">How was intervention implementation monitored?</h4>
                  <p class="my-0">
                    {{ group.howMonitored }}
                  </p>
                </li>
                <li class="list__item">
                  <h4 class="text-primary mt-0 mb-1">
                    Are there any limitations regarding this plan that influences the use of this data as part of the evaluation?
                  </h4>
                  <p class="my-0">
                    {{ group.limitationsToThePlan }}
                  </p>
                </li>
              </ul>

              <ul class="list mt-0" *ngIf="group.evaluationTiming === evaluationTimingEnum.NotBeingEvaluated">
                <li class="list__item">
                  <h4 class="text-primary mt-0 mb-1">
                    {{ getTimingLabel(group.notEvaluatedReasonId) }}
                  </h4>
                  <p class="my-0">{{ group.notEvaluatedExplanation }}</p>
                </li>
              </ul>
            </div>
          </ng-container>
        </div>
      </section>

      <hr class="input__divider my-2" />

      <section id="part-5" class="avoid-break">
        <h3 class="mt-0 mb-2">Part 5: Progress Results</h3>
        <p class="mt-0 mb-4">
          This section will describe
          {{ eer.learner.firstName | firstNamePossessive }} progress based on the most recent intervention/s or instructional plan.
        </p>

        <ng-container *ngFor="let domain of domains; let i = index">
          <mat-card class="card-spacing-top" *ngIf="getLearnerProgressDataSource(domain, i).data.length > 0">
            <h4 class="mt-0 mb-2 text-primary">
              {{ domain.label }}
            </h4>
            <table mat-table class="w-100 card-spacing-bottom column-padding" [dataSource]="getLearnerProgressDataSource(domain, i)">
              <ng-container matColumnDef="currentProgress">
                <th mat-header-cell *matHeaderCellDef>{{ eer.learner.firstName | firstNamePossessive }} Progress</th>
                <td mat-cell *matCellDef="let p">
                  {{ p.currentProgress }}
                </td>
              </ng-container>

              <ng-container matColumnDef="expectedProgress">
                <th mat-header-cell *matHeaderCellDef>Expected Progress</th>
                <td mat-cell *matCellDef="let p">
                  {{ p.expectedProgress }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsProgress"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsProgress"></tr>
            </table>
            <div class="mt-3 display-flex">
              <div *ngFor="let doc of getLearnerProgressDocuments(domain)">
                <ng-container *ngIf="doc.fileName.includes('pdf')">
                  <object [data]="doc.url | safe" style="width: 300px; height: 300px" type="application/pdf"></object>
                </ng-container>
                <ng-container *ngIf="!doc.fileName.includes('pdf')">
                  <div class="graph">
                    <img class="graph__img" [src]="doc.url" />
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-card>
        </ng-container>
        <ng-container *ngFor="let learnerProgressGroup of learnerProgressGroups | keyvalue; let i = index">
          <mat-card class="card-spacing-top" *ngIf="getLearnerProgressGroupDataSource(learnerProgressGroup, i).data.length > 0">
            <h4 class="mt-0 mb-2 text-primary">
              {{ getDomainLabel(learnerProgressGroup.key) }}
            </h4>
            <table
              mat-table
              class="w-100 card-spacing-bottom column-padding"
              [dataSource]="getLearnerProgressGroupDataSource(learnerProgressGroup, i)"
            >
              <ng-container matColumnDef="currentProgress">
                <th mat-header-cell *matHeaderCellDef>{{ eer.learner.firstName | firstNamePossessive }} Progress</th>
                <td mat-cell *matCellDef="let p">
                  {{ p.currentProgress }}
                </td>
              </ng-container>

              <ng-container matColumnDef="expectedProgress">
                <th mat-header-cell *matHeaderCellDef>Expected Progress</th>
                <td mat-cell *matCellDef="let p">
                  {{ p.expectedProgress }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsProgress"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsProgress"></tr>
            </table>
            <div class="mt-3 display-flex">
              <div *ngFor="let doc of getLearnerProgressGroupDocuments(learnerProgressGroup)">
                <ng-container *ngIf="doc.fileName.includes('pdf')">
                  <object [data]="doc.url | safe" style="width: 300px; height: 300px" type="application/pdf"></object>
                </ng-container>
                <ng-container *ngIf="!doc.fileName.includes('pdf')">
                  <div class="graph">
                    <img class="graph__img" [src]="doc.url" />
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-card>
        </ng-container>
        <!-- PASTED HERE -->
        <h4 class="mt-4 mb-0 text-primary">Progress Summary and Implications</h4>
        <p>
          {{ eer.learnerProgress?.summaryAndImplications }}
        </p>
      </section>

      <hr class="input__divider my-2" />

      <section id="part-6" class="avoid-break">
        <h3 class="mt-0 mb-2">Part 6: Educational Needs</h3>
        <p class="mt-0 mb-4">
          This section describes
          {{ eer.learner.firstName | firstNamePossessive }} educational needs based on their response to instruction/intervention and
          assessments reviewed/completed as part of the evaluation
        </p>

        <mat-card class="card-spacing-top" *ngIf="educationalNeedsItemsDataSource?.data?.length > 0">
          <h4 class="mt-0 mb-2 text-primary">Curricular Standards and Skills Needed</h4>
          <p class="mt-0 mb-4">
            What are the knowledge/skills/behaviors that should be the focus of instruction? How does this compare to the
            knowledge/skills/behaviors expected of same age/grade learners?
          </p>
          <table mat-table class="w-100 card-spacing-bottom column-padding" [dataSource]="educationalNeedsItemsDataSource">
            <ng-container matColumnDef="domains">
              <th mat-header-cell *matHeaderCellDef>Domains</th>
              <td mat-cell *matCellDef="let p">
                {{ p.domainLabels }}
              </td>
            </ng-container>

            <ng-container matColumnDef="conceptsAndSkillsExpectations">
              <th mat-header-cell *matHeaderCellDef>{{ eer.learner.firstName | firstNamePossessive }} Needs</th>
              <td mat-cell *matCellDef="let p">
                {{ p.conceptsAndSkillsNeeds }}
              </td>
            </ng-container>

            <ng-container matColumnDef="conceptsAndSkillsNeeds">
              <th mat-header-cell *matHeaderCellDef>Grade Expectations</th>
              <td mat-cell *matCellDef="let p">
                {{ p.conceptsAndSkillsExpectations }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedEducationalItemsColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedEducationalItemsColumns" class="label-offset-fix label-offset-fix--top"></tr>
          </table>
        </mat-card>

        <mat-card class="card-spacing-top" *ngIf="educationalNeedsInstructionsDataSource?.data?.length > 0">
          <h4 class="mt-0 mb-2 text-primary">Method and Delivery of Instruction</h4>
          <p class="mt-0 mb-4">
            What are the characteristics of the instruction that
            {{ eer.learner.firstName }} needs? How does this compare to typical instruction for the age/grade?
          </p>
          <table mat-table class="w-100 card-spacing-bottom column-padding" [dataSource]="educationalNeedsInstructionsDataSource">
            <ng-container matColumnDef="domains">
              <th mat-header-cell *matHeaderCellDef>Domains</th>
              <td mat-cell *matCellDef="let p">
                {{ p.domainLabels }}
              </td>
            </ng-container>

            <ng-container matColumnDef="methodAndDeliveryNeeds">
              <th mat-header-cell *matHeaderCellDef>{{ eer.learner.firstName | firstNamePossessive }} Needs</th>
              <td mat-cell *matCellDef="let p">
                {{ p.methodAndDeliveryNeeds }}
              </td>
            </ng-container>

            <ng-container matColumnDef="methodAndDeliveryInstruction">
              <th mat-header-cell *matHeaderCellDef>Grade Instruction</th>
              <td mat-cell *matCellDef="let p">
                {{ p.methodAndDeliveryInstruction }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedEducationalInstructionColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedEducationalInstructionColumns"></tr>
          </table>
        </mat-card>

        <mat-card class="card-spacing-top" *ngIf="educationalNeedsAccommodationsDataSource?.data?.length > 0">
          <h4 class="mt-0 mb-2 text-primary">Accommodations and Learning Supports</h4>
          <p class="mt-0 mb-2">
            What accommodations/learning supports does
            {{ eer.learner.firstName }} need?
          </p>
          <table
            mat-table
            class="w-100 card-spacing-bottom column-padding"
            [dataSource]="educationalNeedsAccommodationsDataSource"
            *ngIf="educationalNeedsAccommodationsDataSource.data"
          >
            <ng-container matColumnDef="domains">
              <th mat-header-cell *matHeaderCellDef>Domains</th>
              <td mat-cell *matCellDef="let p">
                {{ p.domainLabels }}
              </td>
            </ng-container>

            <ng-container matColumnDef="accommodations">
              <th mat-header-cell *matHeaderCellDef>Accommodations/Learning Supports</th>
              <td mat-cell *matCellDef="let p">
                {{ p.accommodationNames.join(', ') + (p.otherAccommodation ? ': ' + p.otherAccommodation : '') }}
              </td>
            </ng-container>

            <ng-container matColumnDef="needs">
              <th mat-header-cell *matHeaderCellDef>{{ eer.learner.firstName | firstNamePossessive }} Needs</th>
              <td mat-cell *matCellDef="let p">
                {{ p.needs }}
              </td>
            </ng-container>

            <ng-container matColumnDef="routinelyAvailable">
              <th mat-header-cell *matHeaderCellDef>Is this routinely available through general instruction for learner's age/grade?</th>
              <td mat-cell *matCellDef="let p">
                {{ p.isRoutinelyAvailable | boolToYesNo }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedEducationalAccommodationColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedEducationalAccommodationColumns"></tr>
          </table>
        </mat-card>
        <h4 class="mb-0 text-primary">Educational Needs Summary</h4>
        <p>
          {{ eer.educationalNeedsSummary }}
        </p>
      </section>

      <hr class="input__divider my-2" />

      <section id="part-7" class="avoid-break">
        <h3 class="mt-0 mb-2">Part 7: Other Factors</h3>
        <p class="mt-0 mb-3">This section considers factors, other than a disability, that may cause/contribute to learning concerns.</p>

        <div>
          <p class="mt-0 mb-2">
            <strong>
              Lack of appropriate instruction
              {{ eer.otherFactors.lackOfAppropriateInstruction ? 'may be' : 'is not' }}
              a factor responsible for the concern.
            </strong>
          </p>
          <p *ngIf="eer.otherFactors.lackOfAppropriateInstructionEvidences.length > 0">Evidence contributing to this decision:</p>
          <ul>
            <li *ngFor="let evidence of eer.otherFactors.lackOfAppropriateInstructionEvidences">
              <div *ngIf="evidence.label !== 'Other'">
                {{ evidence.label }}
              </div>
              <div *ngIf="evidence.label === 'Other'">
                {{ evidence.label }} -
                {{ eer.otherFactors.lackOfAppropriateInstructionEvidenceOther }}
              </div>
            </li>
          </ul>
          <p *ngIf="eer.otherFactors.lackOfAppropriateInstructionEvidenceExplanation">
            <strong>Team Comments: </strong>
            {{ eer.otherFactors.lackOfAppropriateInstructionEvidenceExplanation }}
          </p>
        </div>

        <hr class="input__divider my-2" />

        <div>
          <p class="mt-0 mb-2">
            <strong>
              Culture and/or environmental/economic disadvantage
              {{ eer.otherFactors.cultureAndEnvironmentalEconomicDisadvantage ? 'may be' : 'are not' }}
              a factor responsible for the concern.
            </strong>
          </p>
          <p *ngIf="eer.otherFactors.cultureAndEnvironmentalEconomicDisadvantageEvidences.length > 0">
            Evidence contributing to this decision:
          </p>
          <ul>
            <li *ngFor="let evidence of eer.otherFactors.cultureAndEnvironmentalEconomicDisadvantageEvidences">
              <div *ngIf="evidence.label !== 'Other'">
                {{ evidence.label }}
              </div>
              <div *ngIf="evidence.label === 'Other'">
                {{ evidence.label }} -
                {{ eer.otherFactors.cultureAndEnvironmentalEconomicDisadvantageEvidenceOther }}
              </div>
            </li>
          </ul>
          <p *ngIf="eer.otherFactors.cultureAndEnvironmentalEconomicDisadvantageEvidenceExplanation">
            <strong>Team Comments: </strong>
            {{ eer.otherFactors.cultureAndEnvironmentalEconomicDisadvantageEvidenceExplanation }}
          </p>
        </div>

        <hr class="input__divider my-2" />

        <div>
          <p class="mt-0 mb-2">
            <strong>
              The learner is an English Language Learner with Limited English Proficiency, but LEP
              {{ eer.otherFactors.limitedEnglishProficiency ? 'may be' : 'are not' }}
              a factor responsible for the concern.
            </strong>
          </p>
          <p *ngIf="eer.otherFactors.limitedEnglishProficiencyEvidences.length > 0">Evidence contributing to this decision:</p>
          <ul>
            <li *ngFor="let evidence of eer.otherFactors.limitedEnglishProficiencyEvidences">
              <div
                *ngIf="
                  evidence.label !==
                  'Other: (e.g., fluency in first language, previous educational experience, attitude toward school/learning English etc.)'
                "
              >
                {{ evidence.label }}
              </div>
              <div
                *ngIf="
                  evidence.label ===
                  'Other: (e.g., fluency in first language, previous educational experience, attitude toward school/learning English etc.)'
                "
              >
                Other -
                {{ eer.otherFactors.limitedEnglishProficiencyOther }}
              </div>
            </li>
          </ul>
          <p *ngIf="eer.otherFactors.limitedEnglishProficiencyExplanation">
            <strong>Team Comments: </strong>
            {{ eer.otherFactors.limitedEnglishProficiencyExplanation }}
          </p>
        </div>

        <hr class="input__divider my-2" />

        <p>
          <strong>
            Other Factors Specific to Evaluations of Specific Learning Disabilities: This section considers factors, other than a Specific
            Learning Disability, that may cause/contribute to academic concerns.
          </strong>
        </p>

        <p class="mt-2 mb-1" *ngIf="!eer?.consideringSld">
          The following factors were evaluated and are not responsible for the academic concern: visual, hearing, or motor disability, an
          intellectual disability, emotional disability, or medical condition.
        </p>
        <p class="mt-2 mb-1" *ngIf="eer?.consideringSld">
          The following factors were evaluated for whether they are responsible for the academic concern: visual, hearing, or motor
          disability, an intellectual disability, emotional disability, or medical condition.
        </p>
        <p *ngIf="academicConcerns">
          The following may be responsible for the academic concern:
          {{ academicConcerns }}
        </p>

        <p class="mt-3" *ngIf="eer.otherFactors.factorsInSchoolSuccessExplanation">
          <strong>Team Comments: </strong>
          {{ eer.otherFactors.factorsInSchoolSuccessExplanation }}
        </p>
      </section>

      <hr class="input__divider my-2" />

      <section id="part-8" class="avoid-break">
        <h3 class="mt-0 mb-2">Part 8: Assessment Methods</h3>

        <h4 class="mt-0 mb-2">Sources of Information Used in the Evaluation</h4>

        <p *ngIf="eer.otherFactors.reviewMethods.length > 0">Reviews of:</p>
        <ul class="mt-0">
          <li *ngFor="let method of eer.otherFactors.reviewMethods">
            <div *ngIf="method.label !== 'Other'">
              {{ method.label }}
            </div>
            <div *ngIf="method.label === 'Other'">
              {{ method.label }} -
              {{ eer.otherFactors.otherReviewMethod }}
            </div>
          </li>
        </ul>

        <p *ngIf="eer.otherFactors.interviewMethods.length > 0">Interviews With:</p>
        <ul class="mt-0">
          <li *ngFor="let method of eer.otherFactors.interviewMethods">
            <div *ngIf="method.label !== 'Other'">
              {{ method.label }}
            </div>
            <div *ngIf="method.label === 'Other'">
              {{ method.label }} -
              {{ eer.otherFactors.otherInterviewMethod }}
            </div>
          </li>
        </ul>

        <p *ngIf="eer.otherFactors.observationsMethods.length > 0">Observations</p>
        <ul class="mt-0">
          <li *ngFor="let method of eer.otherFactors.observationsMethods">
            <div *ngIf="method.label !== 'Other'">
              {{ method.label }}
            </div>
            <div *ngIf="method.label === 'Other'">
              {{ method.label }} -
              {{ eer.otherFactors.otherObservationsMethod }}
            </div>
          </li>
        </ul>

        <ng-container *ngIf="eer.otherFactors.testsAndOtherAssessmentsMethods">
          <p>Tests or Other Assessments</p>
          <ul class="mt-0">
            <li>
              {{ eer.otherFactors.testsAndOtherAssessmentsMethods }}
            </li>
          </ul>
        </ng-container>

        <hr class="input__divider my-2" />

        <h3 class="mt-0 mb-2">Methods to Ensure a Fair Evaluation</h3>

        <ul>
          <li *ngIf="eer.otherFactors.assessmentNonDiscriminatory">
            Materials were selected and administered so as not to be discriminatory on a racial or cultural basis.
          </li>
          <li *ngIf="eer.otherFactors.assessmentNativeLanguageAccurate">
            Assessments were administered in the learner’s native language or mode of communication most likely to be accurate.
          </li>
          <li *ngIf="eer.otherFactors.assessmentReflectedPerformance">
            Assessments were selected with consideration of concerns that may interfere with performance (such as any hearing, vision,
            speaking, and/or motor/mobility concerns).
          </li>
        </ul>

        <p *ngIf="eer.otherFactors.assessmentExplanation" class="mt-2">
          <strong>Team Comments: </strong>
          {{ eer.otherFactors.assessmentExplanation }}
        </p>
      </section>

      <hr class="input__divider my-2" />

      <h3 class="mt-0 mb-2">Description of Assessments Used in this Report</h3>
      <p>{{ eer.otherFactors.testsAndOtherAssessmentsMethods }}</p>
    </div>
  </section>
</section>
