import { Help, HelpSection } from '../help';
import { DsDecisionHelp, DsFormHelp } from '../models/ds.help';

export function getDsDictionary(): Help[] {
  return [].concat(getDsFormDictionary());
}

export function getDsFormDictionary(): Help[] {
  return [
    {
      title: HelpSection.DsForm,
      dictionary: [
        {
          label: DsDecisionHelp.TeamMembersInvolved,
          descriptor:
            'Participants involved in making the decision can be documented by selecting an existing team (if a team has previously been created) or by clicking the Add Team Member button, which causes a window to pop up listing possible team members. You can filter the list by job title or building or search to narrow it down. Select team members by clicking the Add button, then by clicking Add to List. To remove a team member, click the Remove button next to the team member’s name. The Teams area appears when you have opted to update your settings; it is found at the bottom of the page. Teams allows you to create custom team lists for users you frequently work with.',
        },
        {
          label: DsFormHelp.DataToSuggest,
          descriptor:
            'Formative assessment or other screening information; performance monitoring from supplementary instruction; educational, health, and family history; reports from outside providers; observations; progress toward achieving IFSP outcomes; interviews with teachers, parents, the child, and others familiar with the individual; and any additional child-specific records.',
        },
        {
          label: DsFormHelp.DiagnosisOfCondition,
          descriptor:
            'IDEA identifies categories of disability, not specific disabilities (autism, deafblindness, deafness, emotional disturbance, hearing impairment, intellectual disability, orthopedic impairment, other health impairment, specific learning disability, speech or language impairment, traumatic brain injury, visual impairment including blindness). IDEA’s disability definitions are purposefully broad and potentially include a wide range of diagnoses from medical and mental heath professionals. Diagnoses from mental or medical health professional will often meet the criteria for an IDEA condition, but do not check “yes” on the basis of a diagnosed condition, alone. A diagnosed condition is suspected to be an IDEA disability only if it creates a discernible effect on educational performance.',
        },
        {
          label: DsFormHelp.DiscernibleEffect,
          descriptor:
            '“Discernible” means that the effects of the diagnosed condition are noticeable and may impede educational progress or performance, or may impede participation in or access to educational settings and opportunities.',
        },
        {
          label: DsFormHelp.Unique,
          descriptor:
            'Consider any information that reflects whether or not the child’s performance is different from peers in the school or community with similar developmental and educational experiences. The purpose of this information is to assist in determining if the concerns relate to the child (i.e., are indicative of disability) or the concerns are shared by a significant number of others with similar backgrounds and may be indicative of a problem with the general education system of supports and instruction.',
        },
        {
          label: DsFormHelp.NotMeetingStandards,
          descriptor:
            'Consider any information that reflects whether or not the child’s performance is different from typical expectations for age and grade. Progress and status related to the expectations of the Iowa Core or other accepted standards and assessments allowing comparison to statewide or nationwide peers assists in this consideration.',
        },
        {
          label: DsFormHelp.ProvisionOfServices,
          descriptor:
            '“Child find … must include … a child who is suspected of being a child with a disability and in need of special education, even though the child is advancing from grade to grade” and “if interventions are demonstrated to be effective but require continued and substantial effort that may include the provision of special education and related services, the agency shall then conduct a full and individual initial evaluation.” [281-41.111(3) and 281-41.312(3)] Therefore, it is possible that an individual’s performance is not different from standards or expectations, but that disability is suspected because of the nature or extent of the supports needed to maintain/improve the individual’s performance.',
        },
        {
          label: DsDecisionHelp.SelectingTeam,
          descriptor:
            'Participants involved in making the decision can be documented by selecting an existing team (if a team has previously been created) or by clicking the Add Team Member button.',
        },
      ],
    },
  ];
}
