import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OperationResult } from '../../shared/models/operation-result';
import { SpinnerService } from '../../shared/services/spinner/spinner.service';
import { Lookup, MDBehavioralBackground, MDDetermination, MDDocuments, MDNonAchieveMeetingParticipants, MDOverview, MDSummary } from './md';

@Injectable({
  providedIn: 'root',
})
export class MdService {
  constructor(private readonly http: HttpClient, private spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp('^api/md'));
  }

  private baseUrl(id = null) {
    return id ? `api/md/${id}` : 'api/md';
  }

  createMD(mdCaseId: string) {
    return this.http.post<MDOverview>(`${this.baseUrl()}`, {
      caseId: mdCaseId,
    });
  }

  activeMD(caseId) {
    return this.http.get<MDOverview>(`${this.baseUrl()}/${caseId}/active`);
  }

  getMDOverview(mdId) {
    return this.http.get<MDOverview>(`${this.baseUrl(mdId)}/overview`);
  }

  updateMeetingParticipants(mdId: string, md: MDOverview) {
    return this.http.put<MDOverview>(`${this.baseUrl(mdId)}/overview/meeting`, md);
  }

  addMeetingParticipants(mdId: string, userIds: string[]) {
    return this.http.post<any>(`${this.baseUrl(mdId)}/meetingParticipants`, userIds);
  }

  removeMeetingParticipants(mdId: string, userId: string) {
    return this.http.put(`${this.baseUrl(mdId)}/meetingParticipants/${userId}`, null);
  }

  addNonAchieveMeetingParticipant(mdId: string, nonAchieveMeetingParticipantDto: MDNonAchieveMeetingParticipants) {
    return this.http.post<any>(`${this.baseUrl(mdId)}/nonAchieveMeetingParticipant`, nonAchieveMeetingParticipantDto);
  }

  deleteNonAchieveMeetingParticipant(mdId: string, id: string) {
    return this.http.put<OperationResult>(`${this.baseUrl(mdId)}/nonAchieveMeetingParticipant/${id}`, null);
  }

  updateMeetingDate(mdId: string, meetingDate: Date) {
    return this.http.post<OperationResult>(`${this.baseUrl(mdId)}/meeting`, meetingDate);
  }

  updateConductViolations(mdId: string, md: MDOverview) {
    return this.http.put<MDOverview>(`${this.baseUrl(mdId)}/overview/conduct`, md);
  }

  getDetermination(mdId) {
    return this.http.get<MDDetermination>(`${this.baseUrl(mdId)}/determination`);
  }

  updateDetermination(mdId: string, md: MDDetermination) {
    return this.http.put<MDDetermination>(`${this.baseUrl(mdId)}/determination`, md);
  }

  getSpecialCircumstances() {
    return this.http.get<Lookup[]>(`${this.baseUrl()}/determination/circumstances`);
  }

  getSummary(mdId) {
    return this.http.get<MDSummary>(`${this.baseUrl(mdId)}/summary`);
  }

  updateSummary(mdId: string, md: MDSummary) {
    return this.http.put<MDSummary>(`${this.baseUrl(mdId)}/summary`, md);
  }

  getPostMDOptions() {
    return this.http.get<Lookup[]>(`${this.baseUrl()}/summary/options`);
  }

  getBehavioralBackground(mdId) {
    return this.http.get<MDBehavioralBackground>(`${this.baseUrl(mdId)}/behavioral-background`);
  }

  updateBehavioralBackground(mdId: string, md: MDBehavioralBackground) {
    return this.http.put<MDBehavioralBackground>(`${this.baseUrl(mdId)}/behavioral-background`, md);
  }

  getDocument(documentId) {
    return this.http.get<MDBehavioralBackground>(`${this.baseUrl()}/documents/${documentId}`);
  }

  addFbaDocuments(mdId: string, documents: FormData) {
    return this.http.post<MDDocuments[]>(`${this.baseUrl(mdId)}/documents/fba`, documents, this.getMultipartRequestHeaders());
  }

  addBipDocuments(mdId: string, documents: FormData) {
    return this.http.post<MDDocuments[]>(`${this.baseUrl(mdId)}/documents/bip`, documents, this.getMultipartRequestHeaders());
  }

  finalize(mdId: string) {
    return this.http.put<void>(`${this.baseUrl(mdId)}/finalize`, {});
  }

  cancel(mdId: string, reason: string) {
    const deleteReason = { id: mdId, reason: reason };
    return this.http.put(`${this.baseUrl(mdId)}/cancel`, deleteReason);
  }

  private getMultipartRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      Accept: 'application/json, text/plain, */*',
    });

    return { headers };
  }
}
