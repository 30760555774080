import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evaluation-summary-domains',
  templateUrl: './evaluation-summary-domains.component.html',
  styleUrls: ['./evaluation-summary-domains.component.scss'],
})
export class EvaluationSummaryDomainsComponent implements OnInit {
  domains: any;

  constructor() {}

  ngOnInit(): void {
    this.domains = this.getDomains();
  }

  getDomains() {
    return [
      {
        id: '0',
        domain: 'Adaptive',
        evaluators: [
          {
            id: '0',
            fullName: 'Felicia Day',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description:
                  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae vero minima sapiente magnam, aut dolor. Enim, laborum accusamus unde fugiat nostrum maxime repudiandae, minus aliquam quas at rem corrupti ad a odio. Harum molestias eaque, a aspernatur pariatur rem placeat deserunt eum reiciendis quod tenetur consequuntur voluptatum quis iusto vel.',
                dates: null,
                tools: null,
              },
              {
                id: '1',
                method: 'Observe',
                description: 'Lore ipsum dolor sit amet',
                dates: ['11/15/2019', '11/20/2019'],
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
          {
            id: '1',
            fullName: 'Jenson Ackles',
            delay: 24,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
        ],
      },
      {
        id: '1',
        domain: 'Cognitive',
        evaluators: [
          {
            id: '0',
            fullName: 'Felicia Day',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '1',
                method: 'Observe',
                description: 'Lore ipsum dolor sit amet',
                dates: ['11/15/2019', '11/20/2019'],
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
          {
            id: '1',
            fullName: 'Jenson Ackles',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
        ],
      },
      {
        id: '2',
        domain: 'Communication',
        evaluators: [
          {
            id: '0',
            fullName: 'Felicia Day',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '1',
                method: 'Observe',
                description: 'Lore ipsum dolor sit amet',
                dates: ['11/15/2019', '11/20/2019'],
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
          {
            id: '1',
            fullName: 'Jenson Ackles',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
        ],
      },
      {
        id: '3',
        domain: 'Physical',
        evaluators: [
          {
            id: '0',
            fullName: 'Felicia Day',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '1',
                method: 'Observe',
                description: 'Lore ipsum dolor sit amet',
                dates: ['11/15/2019', '11/20/2019'],
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
          {
            id: '1',
            fullName: 'Jenson Ackles',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
        ],
      },
      {
        id: '4',
        domain: 'Social / Emotional',
        evaluators: [
          {
            id: '0',
            fullName: 'Felicia Day',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '1',
                method: 'Observe',
                description: 'Lore ipsum dolor sit amet',
                dates: ['11/15/2019', '11/20/2019'],
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
          {
            id: '1',
            fullName: 'Jenson Ackles',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
        ],
      },
      {
        id: '5',
        domain: 'Health',
        evaluators: [
          {
            id: '0',
            fullName: 'Felicia Day',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '1',
                method: 'Observe',
                description: 'Lore ipsum dolor sit amet',
                dates: ['11/15/2019', '11/20/2019'],
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
          {
            id: '1',
            fullName: 'Jenson Ackles',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
        ],
      },
      {
        id: '6',
        domain: 'Hearing',
        evaluators: [
          {
            id: '0',
            fullName: 'Felicia Day',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '1',
                method: 'Observe',
                description: 'Lore ipsum dolor sit amet',
                dates: ['11/15/2019', '11/20/2019'],
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
          {
            id: '1',
            fullName: 'Jenson Ackles',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
        ],
      },
      {
        id: '7',
        domain: 'Nutrition',
        evaluators: [
          {
            id: '0',
            fullName: 'Felicia Day',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '1',
                method: 'Observe',
                description: 'Lore ipsum dolor sit amet',
                dates: ['11/15/2019', '11/20/2019'],
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
          {
            id: '1',
            fullName: 'Jenson Ackles',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
        ],
      },
      {
        id: '8',
        domain: 'Vision',
        evaluators: [
          {
            id: '0',
            fullName: 'Felicia Day',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '1',
                method: 'Observe',
                description: 'Lore ipsum dolor sit amet',
                dates: ['11/15/2019', '11/20/2019'],
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
          {
            id: '1',
            fullName: 'Jenson Ackles',
            delay: 27,
            methods: [
              {
                id: '0',
                method: 'Review',
                description: 'lorem ipsum dolor sit amet,',
                dates: null,
                tools: null,
              },
              {
                id: '2',
                method: 'Test',
                description: 'Consectetur adispicing elit',
                dates: null,
                tools: 'BDI / BDI-2',
              },
            ],
          },
        ],
      },
    ];
  }

  hasMethod(methods: any[], method: string) {
    if (methods.filter((e) => e.method === method).length > 0) {
      return true;
    }
    return false;
  }
}
