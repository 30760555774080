import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ProgressMonitorComment } from 'src/app/monitoring-progress/models/comment';
import { GoalProgressMonitor, ObjectiveProgressMonitor } from 'src/app/monitoring-progress/models/goal-progress-monitor';
import { CriterionProgressMonitor } from 'src/app/monitoring-progress/models/outcome-progress-monitor';
import { ProgressMonitorCommentService } from 'src/app/monitoring-progress/services/progress-monitor-comment.service';
import { ProgressMonitorLookupService } from 'src/app/monitoring-progress/services/progress-monitor-lookup.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { CaseSummary } from 'src/app/shared/models/case';
import { ConfirmationDialogComponent } from 'src/app/shared/services/notification.service';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { AuthService } from '../../../../../auth/auth.service';
import { DateFormatPipe, DateToUtcPipe } from '../../../../../shared/pipes/date-transform.pipe';

@Component({
  selector: 'app-comment-table',
  templateUrl: './comment-table.component.html',
  styleUrls: ['./comment-table.component.scss'],
})
export class CommentTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() criterion: CriterionProgressMonitor | GoalProgressMonitor | ObjectiveProgressMonitor;
  @Input() caseSummary: CaseSummary;
  @Input() enableForms: boolean;
  @Input() minDate: Date;
  isEditing: boolean;
  shortDateFormat = shortDateFormat;
  today = new Date();
  displayedColumns = ['actions', 'date', 'note'];

  formGroup = this.fb.group({
    id: '',
    date: ['', Validators.required],
    progressMonitorLookupId: ['', Validators.required],
    noteOther: [null],
  });

  get isOther() {
    return this.progressMonitorLookupService.lookupOptions.find((x) => x.id === this.formGroup.get('progressMonitorLookupId').value)
      ?.isOther;
  }

  get commentOptions() {
    return this.progressMonitorLookupService.commentOptions;
  }

  get isPortalUser() {
    return this.authService?.isPortalUser;
  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private commentService: ProgressMonitorCommentService,
    private progressMonitorLookupService: ProgressMonitorLookupService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.isPortalUser) {
      this.displayedColumns = ['date', 'note'];
    }
  }

  ngAfterViewInit() {
    if (this.criterion.commentData) {
      this.criterion.commentData.sort = this.sort;
      this.criterion.commentData.paginator = this.paginator;
    }
  }

  onCommentUpdate(comment, criterion) {
    if (this.formGroup.invalid) {
      return;
    }
    const dto = this.formGroup.value as ProgressMonitorComment;
    dto.date = new DateToUtcPipe().transform(new Date(dto.date));
    this.commentService.update(this.caseSummary.learnerId, criterion.id, dto).subscribe((res) => {
      comment.date = res.date;
      this.handleCommentUpdate(comment, criterion);
    });
  }

  onCommentEdit(comment) {
    this.isEditing = true;
    comment.isEditing = true;
    this.formGroup.patchValue(comment);
    if (this.isOther) {
      this.formGroup.get('noteOther').setValidators(Validators.required);
      this.formGroup.get('noteOther').updateValueAndValidity();
    }
  }

  onCommentDelete(comment: ProgressMonitorComment, criterion) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '728px',
      data: {
        title: 'Remove',
        message: 'Are you sure you want to remove this comment?',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.commentService
          .delete(this.caseSummary.learnerId, criterion.id, comment.id)
          .subscribe(() => this.handleCommentDelete(comment.id, criterion));
      }
    });
  }

  onCancelCommentEdit(comment: ProgressMonitorComment) {
    comment.isEditing = false;
    this.isEditing = false;
    this.formGroup.reset();
  }

  onViewMore(comment: ProgressMonitorComment) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Date',
        value: new DateFormatPipe().transform(comment.date),
      },
      {
        name: 'Comment',
        value: comment.label,
      },
    ];

    if (comment.noteOther) {
      modalData.push({ name: 'Other', value: comment.noteOther });
    }

    openViewMore(this.dialog, modalData);
  }

  commentOptionSelected(value) {
    const noteOtherControl = this.formGroup.get('noteOther');
    if (value !== 'Other') {
      noteOtherControl.setValue('');
      noteOtherControl.clearValidators();
    } else {
      noteOtherControl.setValidators(Validators.required);
    }
    noteOtherControl.updateValueAndValidity();
  }

  private handleCommentUpdate(comment: ProgressMonitorComment, criterion) {
    comment.progressMonitorLookupId = this.formGroup.get('progressMonitorLookupId').value;
    comment.noteOther = this.formGroup.get('noteOther').value;
    comment.label = this.progressMonitorLookupService.lookupOptions.find((x) => x.id === comment.progressMonitorLookupId).label;
    const index = criterion.comments.findIndex((x) => x.id === comment.id);
    if (index > -1) {
      criterion.comments[index] = comment;
      this.sortComments(criterion);
      criterion.commentData.data = criterion.comments;
      this.onCancelCommentEdit(comment);
    }
  }

  private handleCommentDelete(id: string, criterion) {
    const index = criterion.comments.findIndex((x) => x.id === id);
    if (index > -1) {
      criterion.comments.splice(index, 1);
      criterion.commentData.data = criterion.comments;
    }
  }

  private sortComments(criterion) {
    criterion.comments = criterion.comments.sort((a, b) => Date.parse(b.date.toString()) - Date.parse(a.date.toString()));
  }
}
