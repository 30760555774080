<app-learner-info
  [learnerSummary]="learnerSummary"
  [condensed]="condensed"
  [fewFields]="fewFields"
  [isPrint]="isPrint"
  [showAgeInMonths]="true"
  [showFamilyMembersInPrimary]="true"
>
  <ng-template #primaryInfo>
    <app-key-value-label key="Service Coordinator" [value]="serviceCoordinator"> </app-key-value-label>
    <!-- <app-key-value-label
      key="Type of Meeting"
      [value]="nextMeeting.label"
    ></app-key-value-label>
    <app-key-value-label
      key="Due Date"
      [value]="nextMeeting.date"
    ></app-key-value-label> -->
  </ng-template>
  <ng-template #secondaryInfo>
    <app-key-value-label *ngIf="learnerSummary?.stateAssignedId" class="text-md" key="State ID" [value]="learnerSummary?.stateAssignedId">
    </app-key-value-label>
    <app-key-value-label *ngIf="learnerSummary?.gender" class="text-md" key="Gender" [value]="learnerSummary?.gender">
    </app-key-value-label>
    <app-key-value-label *ngIf="learnerSummary?.attendingAea" class="text-md" key="AEA" [value]="learnerSummary?.attendingAea">
    </app-key-value-label>
    <app-key-value-label *ngIf="learnerSummary?.county" class="text-md" key="County" [value]="learnerSummary?.county">
    </app-key-value-label>
    <app-key-value-label
      *ngIf="learnerSummary?.residentDistrict"
      class="text-md"
      key="Resident District"
      [value]="learnerSummary?.residentDistrict"
    >
    </app-key-value-label>
    <app-key-value-label
      *ngIf="learnerSummary?.attendingDistrict"
      class="text-md"
      key="Attending District"
      [value]="learnerSummary?.attendingDistrict"
    >
    </app-key-value-label>
  </ng-template>
</app-learner-info>
