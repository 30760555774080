import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { usStateOptions, yesNoOptions } from 'src/app/shared/formHelpers';
import { LocationService } from 'src/app/shared/services/location/location.service';
import { KeyValuePair } from '../../../models/key-value-pair';
import { FamilyMemberType } from '../../../models/learner';

@Component({
  selector: 'app-referral-source-info-form',
  templateUrl: './referral-source-info-form.component.html',
  styleUrls: [
    '../../../../child-find/early-access-referral/early-access-referral.component.scss',
    './referral-source-info-form.component.scss',
  ],
})
export class ReferralSourceInfoFormComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() parentForm: FormGroup;
  @Input() referralSourceCategoryOptions: KeyValuePair[];
  @Input() referralHowHearAboutUsOptions: KeyValuePair[];
  @Input() howHearAboutUsOtherId: string;

  usStateOptions = usStateOptions;
  yesNoOptions = yesNoOptions;

  get isParentReferralSource() {
    return (
      this.parentForm.get('referralSource').value === FamilyMemberType.Parent1 ||
      this.parentForm.get('referralSource').value === FamilyMemberType.Parent2
    );
  }

  get referralSourceHowHearAboutUsId() {
    return this.parentForm.controls.referralSourceHowHearAboutUsId as FormControl;
  }

  get referralSourceEarlyAccessOtherText() {
    return this.parentForm.controls.referralSourceEarlyAccessOtherText as FormControl;
  }

  get legalGuardian() {
    return this.parentForm.controls.legalGuardian as FormControl;
  }

  get exchangeInformation() {
    return this.parentForm.controls.exchangeInformation as FormControl;
  }

  constructor(private locationService: LocationService) {}

  ngOnInit() {}

  populateCityStateFromZipCode() {
    const zipCodeCtrl = this.formGroup.get('referralSourceZipCode');
    if (zipCodeCtrl.valid && zipCodeCtrl.value) {
      this.locationService.getLocationData(zipCodeCtrl.value).subscribe((res) => {
        if (res) {
          this.formGroup.patchValue({
            referralSourceCity: res.city,
            referralSourceState: 'IA',
          });
        }
      });
    }
  }
}
