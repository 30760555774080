import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseWrapperComponent } from '../../../shared/components/form/base-wrapper.component';
import { LoggerService } from '../../../shared/services/logger/logger.service';

export enum IepTagBarOptions {
  IepGoals = '0',
  IepServices = '1',
  IepSupportsAccommodations = '3',
  PlaafpPrint = '4',
}
@Component({
  selector: 'app-tag-bar',
  templateUrl: './tag-bar.component.html',
  styleUrls: ['./tag-bar.component.scss'],
})
export class TagBarComponent extends BaseWrapperComponent implements OnInit, OnDestroy {
  @Input() showPrint = false;
  @Input() showLabels = false;
  @Input() isPartC = false;
  selectedTags: string[] = [];
  private toggling: boolean;
  private subscription = new Subscription();
  iepTagBarOptions = IepTagBarOptions;

  constructor(
    logger: LoggerService,
    @Self()
    @Optional()
    ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(logger, ngControl, changeDetectorRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.control.valueChanges.subscribe((newValues) => {
        if (this.toggling) {
          return;
        }
        this.selectedTags = newValues;
      })
    );
  }

  onSelect(id: IepTagBarOptions): void {
    if (this.readOnly || this.control.disabled) {
      return;
    }
    this.toggling = true;
    if (this.selectedTags?.indexOf(id) > -1) {
      const tagIndex = this.selectedTags.findIndex((i) => i === id);
      this.selectedTags.splice(tagIndex, 1);
    } else {
      this.selectedTags.push(id);
    }

    this.control.patchValue(this.selectedTags);
    this.control.markAsTouched();
    this.control.markAsDirty();
    this.toggling = false;
  }

  isSelected(id: string): boolean {
    return this.selectedTags ? this.selectedTags.includes(id) : false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
