<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>New Family Contact Log Entry</h3>

<div mat-dialog-content class="py-2">
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-md-12">
        <app-date-picker label="Choose a date" [max]="maxDate" controlName="date"></app-date-picker>
      </div>
      <div class="col-md-12">
        <app-timepicker label="Choose a time" formControlName="time"> </app-timepicker>
      </div>
      <div class="col-md-12">
        <mat-form-field class="w-100">
          <mat-label>Person being contacted</mat-label>
          <input type="text" aria-labelledby="Person being contacted" [matAutocomplete]="contactee" matInput formControlName="contactee" />
          <mat-autocomplete #contactee>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.fullName" role="combobox">
              {{ option.fullName }}
            </mat-option>
          </mat-autocomplete>

          <div class="form__carrot"></div>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field class="w-100">
          <mat-label>Method</mat-label>
          <mat-select formControlName="method">
            <mat-option value="phone" role="option">Phone</mat-option>
            <mat-option value="email" role="option">Email</mat-option>
            <mat-option value="text" role="option">Text</mat-option>
            <mat-option value="writtenNote" role="option">Written Note/Letter</mat-option>
            <mat-option value="inPerson" role="option">In Person</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field *ngIf="!hasPartBActiveCase" class="w-100">
          <mat-label>Purpose</mat-label>
          <mat-select formControlName="purpose">
            <mat-option value="consent" role="option">Consent</mat-option>
            <mat-option value="initialhomevisit" role="option">Home Visit</mat-option>
            <mat-option value="meeting" role="option">Meeting</mat-option>
            <mat-option value="referral" role="option">Referral Follow-Up (Introduction & EA Information)</mat-option>
            <mat-option value="other" role="option">Other</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <app-textarea label="Comment" formControlName="comment" [rows]="4"></app-textarea>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-action class="display-flex justify-content-end align-items-center mt-4">
  <div class="display-flex">
    <button mat-raised-button aria-label="Cancel Button" class="background-color--muted mr-2" (click)="onNoClick()" tabindex="0">
      Cancel
    </button>
    <button mat-raised-button aria-label="Save Button" color="primary" (click)="onSave()" [disabled]="formGroup.invalid" tabindex="0">
      Save
    </button>
  </div>
</div>
