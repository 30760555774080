<form [formGroup]="formGroup">
  <mat-card class="mb-3">
    <mat-card-title class="mb-3">Special Equipment and Modifications</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [options]="yesNoOptions"
              formControlName="specialEquipment"
              label="Does your child use any special equipment to help them communicate, eat, move, or interact in the home or community? Please consider all medical equipment or assistive technology purchased or homemade."
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="row" *ngIf="formGroup.get('specialEquipment').value">
        <div class="col-md-12 col-lg-6">
          <app-textarea [disabledState]="locked" label="Describe" formControlName="specialEquipmentDescription"></app-textarea>
        </div>
      </div>
    </div>
  </mat-card>
  <mat-card class="mb-3">
    <mat-card-title class="mb-3">Additional Concerns</mat-card-title>
    <fieldset>
      <app-radio-group
        [disabledState]="locked"
        [options]="yesNoOptions"
        formControlName="additionalHealthConcern"
        label="Is there anything else about your child’s health that the team should know about to better plan and provide services to your child and family?"
      ></app-radio-group>
    </fieldset>
    <div class="mt-3"></div>
    <app-textarea
      [disabledState]="locked"
      *ngIf="formGroup.get('additionalHealthConcern').value"
      label="Describe"
      formControlName="additionalHealthConcernDescription"
    ></app-textarea>

    <div *ngIf="formGroup.get('additionalHealthConcern').value" class="mt-3"></div>
    <fieldset>
      <app-radio-group
        [disabledState]="locked"
        [options]="yesNoOptions"
        formControlName="additionalChildConcern"
        label="Do you have any concerns about your child that the team should know about as we plan for screening / evaluation / assessment?"
      ></app-radio-group>
    </fieldset>
    <div class="mt-3"></div>

    <app-textarea
      [disabledState]="locked"
      *ngIf="formGroup.get('additionalChildConcern').value"
      label="Describe"
      formControlName="additionalChildConcernDescription"
    ></app-textarea>
  </mat-card>
</form>
