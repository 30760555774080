<form [formGroup]="formGroup" #formDirective="ngForm">
  <div class="row">
    <div class="col-md-12 col-xl-9">
      <app-text-field label="Non-ACHIEVE Team Member Name" formControlName="name"></app-text-field>
    </div>
    <div class="col-md-12 col-xl-3 display-flex justify-content-end align-items-center">
      <button
        *ngIf="editingParticipant"
        class="mr-2"
        (click)="resetParticipantForm()"
        mat-raised-button
        aria-label="Cancel Button"
        tabindex="0"
      >
        Cancel
      </button>
      <button
        mat-raised-button
        attr.aria-label="{{ !editingParticipant ? 'Add' : 'Update' }}"
        color="primary"
        [disabled]="formGroup.invalid"
        (click)="saveNonAchieveParticipant()"
        tabindex="0"
      >
        {{ !editingParticipant ? 'Add' : 'Update' }}
      </button>
    </div>
  </div>
</form>

<div class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" class="w-100" matSort>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="cell--actions"></th>
      <td mat-cell *matCellDef="let element; let i = index" class="cell--actions">
        <button
          mat-icon-button
          aria-label="Edit Icon"
          color="primary"
          *ngIf="!editingParticipant"
          (click)="onEditNonAchieveParticipant(element)"
          tabindex="0"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          aria-label="Delete Icon"
          color="warn"
          *ngIf="!editingParticipant"
          (click)="removeNonAchieveParticipant(element.id)"
          tabindex="0"
        >
          <mat-icon>close</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Non-ACHIEVE Team Member</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr [hidden]="dataSource?.data?.length > 0" mat-footer-row *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
