import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProgressMonitorPhaseLine } from '../models/phase-line';

@Injectable({
  providedIn: 'root',
})
export class ProgressMonitorPhaseLineService {
  constructor(private http: HttpClient) {}

  create(learnerId: string, id: string, phaseLine: ProgressMonitorPhaseLine) {
    return this.http.post<ProgressMonitorPhaseLine>(`api/learners/${learnerId}/progress-monitoring/${id}/phase-lines`, phaseLine);
  }

  update(learnerId: string, id: string, phaseLine: ProgressMonitorPhaseLine) {
    return this.http.put<ProgressMonitorPhaseLine>(
      `api/learners/${learnerId}/progress-monitoring/${id}/phase-lines/${phaseLine.id}`,
      phaseLine
    );
  }

  delete(learnerId: string, id: string, phaseLineId: string) {
    return this.http.put(`api/learners/${learnerId}/progress-monitoring/${id}/phase-lines/delete/${phaseLineId}`, null);
  }
}
