import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { EarlyAccessQuestionnaire } from 'src/app/evaluation/models/early-access-questionnaire';
import { Intake } from 'src/app/evaluation/models/intake';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { MedicalCondition, MedicalConditionDto } from 'src/app/shared/models/medical-condition';
import { MedicalConditionService } from 'src/app/shared/services/medical-condition/medical-condition-service';
import { AreYouSureComponent } from '../are-you-sure-modal/are-you-sure.component';

@Component({
  selector: 'app-medical-diagnosis-table',
  templateUrl: './medical-diagnosis-table.component.html',
  styleUrls: ['./medical-diagnosis-table.component.scss'],
})
export class MedicalDiagnosisTableComponent implements OnInit, AfterViewChecked {
  showPaginator = false;

  @Input() dataSource = new MatTableDataSource<MedicalCondition>([]);
  @Input() intake: Intake;
  @Input() questionnaire: EarlyAccessQuestionnaire;
  @Input() isQuestionnaire = false;
  @Output() medicalConditionUpdated = new EventEmitter();
  @Output() refreshDataSource = new EventEmitter();

  conditionFormGroup: FormGroup;
  isEditingMedicalCondition = false;
  displayedColumns = ['actions', 'name'];
  questionnaireMedicalCondition: MedicalCondition[];

  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder,
    private medicalConditionService: MedicalConditionService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (!this.isQuestionnaire) {
      this.displayedColumns.push('icdCode');
    }
    if (!this.isQuestionnaire) {
      this.displayedColumns.push('diagnosisQualifies');
    }

    this.conditionFormGroup = this.fb.group({
      name: [null, this.isQuestionnaire ? null : [Validators.required]],
      icdCode: [null],
      diagnosisQualifies: [null, this.isQuestionnaire ? null : [Validators.required]],
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  addMedicalCondition(formDirective: any, condition: MedicalConditionDto, resetForm = true) {
    if (this.isQuestionnaire) {
      this.medicalConditionService
        .addQuestionnaireMedicalCondition(formDirective != null ? formDirective.form.value : condition, this.questionnaire?.id)
        .subscribe(() => {
          this.refreshTableData();
        });
    } else {
      this.medicalConditionService
        .addIntakeMedicalCondition(formDirective != null ? formDirective.form.value : condition, this.intake?.caseId)
        .subscribe(() => {
          this.refreshTableData();
        });
    }
    if (resetForm) {
      formDirective.resetForm();
    }
  }

  setMedicalConditionEdit(condition: MedicalConditionDto, editing = true) {
    this.isEditingMedicalCondition = editing;
    condition.isEditing = editing;
    if (!editing) {
      this.conditionFormGroup.patchValue({});
      return;
    }

    this.conditionFormGroup.patchValue({
      name: condition.name,
      icdCode: condition.icdCode,
      diagnosisQualifies: condition.diagnosisQualifies,
    });
  }

  saveMedicalCondition(condition: MedicalConditionDto, formDirective: any, isNew = false) {
    const formObj = {
      form: { value: formDirective },
    };
    if (!formObj.form.value.name || (formObj.form.value.diagnosisQualifies === null && !this.isQuestionnaire)) {
      this.conditionFormGroup.markAllAsTouched();
      return;
    }
    if (!isNew) {
      this.onRemoveMedicalCondition(condition, true);
      this.addMedicalCondition(formObj, condition, false);
    } else {
      this.addMedicalCondition(formObj, condition, false);
    }
    this.isEditingMedicalCondition = false;
  }

  onRemoveMedicalCondition(condition: MedicalConditionDto, skipQuestion = false) {
    const deleteMedicalCondition = () => {
      if (this.isQuestionnaire) {
        this.medicalConditionService.deleteQuestionnaireMedicalCondition(this.questionnaire?.id, condition.id).subscribe(() => {
          this.refreshTableData();
        });
      } else {
        this.medicalConditionService.deleteIntakeMedicalCondition(this.intake?.caseId, condition.id).subscribe(() => {
          this.refreshTableData();
        });
      }
    };

    if (skipQuestion) {
      deleteMedicalCondition();
    } else {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        width: '450px',
        data: {
          question: 'Are you sure?',
          subQuestion: 'Clicking Yes will remove the medical condition.',
        },
      });
      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          deleteMedicalCondition();
        }
      });
    }
  }

  refreshTableData() {
    this.refreshDataSource.emit(true);
    this.medicalConditionUpdated.emit(true);
  }
}
