import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  IfspServiceAddDto,
  IfspServiceDto,
  IfspServiceLogFirstDeliveryDto,
  IfspServiceLookupData,
  IfspServiceSummaryDto,
} from '../models/ifsp-service-models';
@Injectable({
  providedIn: 'root',
})
export class IfspServicesService {
  private servicesUpdated = new Subject<void>();
  servicesUpdated$ = this.servicesUpdated.asObservable();

  public setModifyingService = new Subject<any>();
  setModifyingService$ = this.setModifyingService.asObservable();

  public serviceClose = new Subject<void>();
  serviceClose$ = this.serviceClose.asObservable();

  public serviceAddNew = new Subject<void>();
  serviceAddNew$ = this.serviceAddNew.asObservable();

  public openServicesAccordion = new Subject<void>();
  openServicesAccordion$ = this.openServicesAccordion.asObservable();

  public currentServicesForModification = new BehaviorSubject<IfspServiceDto[]>([]);
  currentServicesForModification$ = this.currentServicesForModification.asObservable();

  constructor(private readonly http: HttpClient) {}

  private baseUrl(ifspId = '') {
    return `api/${ifspId}/ifspservice`;
  }

  createService(ifspId: string, model: IfspServiceAddDto) {
    return this.http.post<IfspServiceDto>(this.baseUrl(ifspId), model).pipe(tap(() => this.servicesUpdated.next()));
  }

  updateService(ifspId: string, model: any) {
    return this.http.put<IfspServiceDto>(this.baseUrl(ifspId), model).pipe(tap(() => this.servicesUpdated.next()));
  }

  updateProviders(ifspId: string, id: string, model: any) {
    return this.http.put<any>(this.baseUrl(ifspId) + '/' + id + '/updateProviders', model);
  }

  modify(ifspId: string, id: string, modificationId: string) {
    return this.http
      .put<any>(this.baseUrl(ifspId) + '/' + id + '/modify/' + modificationId, null)
      .pipe(tap(() => this.servicesUpdated.next()));
  }

  updateServiceProviders(ifspId: string, primaryServiceProviderId: string, secondaryServiceProviderId: string = null) {
    return this.http
      .post<IfspServiceDto>(this.baseUrl(ifspId) + '/updateServiceProviders', {
        primaryServiceProviderId,
        secondaryServiceProviderId,
      })
      .pipe(tap(() => this.servicesUpdated.next()));
  }

  endService(ifspId: string, id: string, model: any) {
    return this.http.put<any>(this.baseUrl(ifspId) + '/' + id + '/end', model).pipe(tap(() => this.servicesUpdated.next()));
  }

  deleteService(ifspId: string, id: string, isAmendment = false) {
    return this.http.put(this.baseUrl(ifspId) + `/${id}/` + isAmendment, null).pipe(tap(() => this.servicesUpdated.next()));
  }

  getAllForModification(ifspId: string, modificationId = '') {
    return this.http.get<IfspServiceDto[]>(this.baseUrl(ifspId) + `/modify/${modificationId}`);
  }

  getAuditLogs(ifspId: string, id: string) {
    return this.http.get<any[]>(this.baseUrl(ifspId) + `/${id}/getAuditLogs`);
  }

  getServices(ifspId: string) {
    return this.http.get<Array<IfspServiceSummaryDto>>(this.baseUrl(ifspId));
  }

  getFinalizedServices(ifspId: string) {
    return this.http.get<Array<IfspServiceSummaryDto>>(this.baseUrl(ifspId) + '/getFinalized');
  }

  getServiceById(ifspId: string, id: string) {
    return this.http.get<IfspServiceDto>(this.baseUrl(ifspId) + '/' + id);
  }

  getWithService(ifspId: string, id: string) {
    return this.http.get<IfspServiceLogFirstDeliveryDto>(this.baseUrl(ifspId) + `/getWithService/${id}`);
  }

  getLookupData(ifspId: string) {
    return this.http.get<IfspServiceLookupData>(this.baseUrl(ifspId) + '/getLookupData');
  }
}
