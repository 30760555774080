<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Close Case</h3>
<div mat-dialog-content class="py-3">
  <form [formGroup]="formGroup">
    <mat-card class="mb-0 card--plain">
      <div class="row">
        <div class="col-md">
          <fieldset>
            <app-radio-group
              [wrap]="true"
              [column]="true"
              [options]="caseClousureReasonOptions"
              formControlName="caseClosureReason"
              label="Close Reason"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
    </mat-card>
  </form>
</div>
<div mat-dialog-actions class="actions display-flex justify-content-end">
  <button mat-button aria-label="Cancel Button" (click)="dismiss()" cdkFocusInitial tabindex="0">Cancel</button>
  <button mat-raised-button aria-label="Close Case" color="primary" (click)="closeCase()" [disabled]="formGroup.invalid" tabindex="0">
    Close Case
  </button>
</div>
