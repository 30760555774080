import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-graph-modal',
  templateUrl: './graph-modal.component.html',
  styleUrls: ['./graph-modal.component.scss'],
})
export class GraphModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      iepGoalId: string;
      iepGoalQuantifiableMeasurementId: string;
      isObjective: boolean;
    }
  ) {}

  ngOnInit(): void {}
}
