import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AchieveConfig } from '../../models/achieve-config';

@Injectable()
export class AchieveConfigService {
  private baseUrl = '/api/achieveconfig';
  private helperBaseUrl = '/api/helper';
  private achieveConfigSettings: AchieveConfig = null;
  public readonly $achieveConfigSettings = new BehaviorSubject<AchieveConfig>(null);

  constructor(private http: HttpClient) {}

  get settings() {
    return this.achieveConfigSettings;
  }

  public load(): Promise<any> {
    return new Promise((resolve) => {
      this.http.get<AchieveConfig>(this.baseUrl + '/get-config').subscribe((response) => {
        this.achieveConfigSettings = response;
        this.$achieveConfigSettings.next(this.achieveConfigSettings);
        resolve(true);
      });
    });
  }

  public getServerDateTime() {
    return this.http.get<Date>(`${this.helperBaseUrl}/ServerDateTime`);
  }

  public getServerTime() {
    return this.http.get<Date>(`${this.helperBaseUrl}/ServerDate`);
  }

  public getServerDate() {
    return this.http.get<Date>(`${this.helperBaseUrl}/ServerTime`);
  }

  public getDateTimeFromTimeString(date: Date, time: string) {
    return this.http.get<Date>(
      `${this.helperBaseUrl}/DateTimeFromTimeString?date=${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}&time=${time}`
    );
  }
}
