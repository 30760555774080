<div class="prefill prefill--checkbox">
  <div class="prefill__input">
    <mat-checkbox [formControlName]="controlName" attr.aria-label="{{ label }}">
      {{ label }}
    </mat-checkbox>
  </div>

  <div class="prefill__text" *ngIf="dataSourceValue !== undefined">QR: {{ dataSourceValue ? 'Check' : 'Uncheck' }}</div>

  <div class="prefill__container" (click)="replaceWithSourceValue()" *ngIf="dataSourceValue !== undefined && !undoValue && !disabled">
    <div class="prefill__icon">
      <mat-icon aria-label="QR Arrow Symbol">call_merge</mat-icon>
    </div>
  </div>

  <div class="prefill__container prefill__container--undo" *ngIf="undoValue">
    <p class="prefill__icon mb-0" (click)="undoReplaceWithSourceValue()">Undo</p>
  </div>
</div>
