import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MeetingParticipantRead } from 'src/app/learner-management/family-meeting/participants-list/meeting-participants';

export interface ECPSearchModalData {
  ecpContacts: MeetingParticipantRead[];
  existingContacts: MeetingParticipantRead[];
}

@Component({
  selector: 'app-ecp-search-modal',
  templateUrl: './ecp-search-modal.component.html',
  styleUrls: ['./ecp-search-modal.component.scss'],
})
export class ECPSearchModalComponent implements OnInit {
  initialEcpContacts: MeetingParticipantRead[] = [];
  availableEcpContacts: MeetingParticipantRead[] = [];
  selectedEcpContacts: MeetingParticipantRead[] = [];

  formGroup = new FormGroup({
    searchTerm: new FormControl(''),
  });

  constructor(
    private dialogRef: MatDialogRef<ECPSearchModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ECPSearchModalData
  ) {
    if (data.existingContacts?.length > 0) {
      // todo this could cause problems if two people have the same name. - John Smith 2021
      // the goal was to prevent the options from showing existing contacts
      const fullNames = data.existingContacts.map((x) => x.fullName);
      this.initialEcpContacts = data.ecpContacts.filter((x) => !fullNames.includes(x.fullName));
    } else {
      this.initialEcpContacts = data.ecpContacts;
    }
  }

  ngOnInit() {
    this.availableEcpContacts = this.initialEcpContacts.slice();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onAccept() {
    this.dialogRef.close(this.selectedEcpContacts);
  }

  addEcpContact(member: MeetingParticipantRead) {
    this.selectedEcpContacts.push(member);
    this.filterOutMember('availableEcpContacts', member);
  }

  removeEcpContact(member: MeetingParticipantRead) {
    this.availableEcpContacts.push(member);
    this.filterOutMember('selectedEcpContacts', member);
  }

  filterOutMember(containerName: string, member: MeetingParticipantRead) {
    this[containerName] = this[containerName].filter((fp) => fp !== member);
  }

  onFilter() {
    this.availableEcpContacts = [];
    this.availableEcpContacts = this.initialEcpContacts.filter((fp) => {
      return !this.selectedEcpContacts?.includes(fp);
    });
    if (this.formGroup.controls.searchTerm.value) {
      const filterValue = this.formGroup.controls.searchTerm.value;
      this.availableEcpContacts = this.availableEcpContacts.filter((tm) => tm.fullName.toLowerCase().includes(filterValue));
    }
  }

  displayProviderName(participant: MeetingParticipantRead) {
    return participant.agency ? `${participant.fullName} - ${participant.agency}` : `${participant.fullName}`;
  }
}
