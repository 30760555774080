import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { OutcomeProgressMonitor, ProgressMonitorPlanStatus } from 'src/app/monitoring-progress/models/outcome-progress-monitor';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { DateFormatPipe } from '../../../../../../shared/pipes/date-transform.pipe';

@Component({
  selector: 'app-plan-status-table',
  templateUrl: './plan-status-table.component.html',
  styleUrls: ['./plan-status-table.component.scss'],
})
export class PlanStatusTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @Input() outcome: OutcomeProgressMonitor;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.outcome.data.sort = this.sort;
  }

  onViewMore(planStatus: ProgressMonitorPlanStatus) {
    const modalData: Array<ViewMoreModalData> = [
      {
        name: 'Date',
        value: new DateFormatPipe().transform(planStatus.date),
      },
      {
        name: 'How is the plan working?',
        value: planStatus.status,
      },
    ];
    openViewMore(this.dialog, modalData);
  }
}
