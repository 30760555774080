<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Bankable Fields</h3>
<mat-dialog-content>
  <mat-card class="mb-0 card-spacing-top">
    <form [formGroup]="textFormGroup">
      <app-textarea label="Save Entered Text to Bank" formControlName="text" [useInputGroup]="true" [maxLength]="2000">
        <ng-container append>
          <mat-icon
            *ngIf="textFormGroup.get('text').value !== null && textFormGroup.get('text').value !== '' && !isEditing"
            matSuffix
            (click)="saveEnteredText()"
            class="cursor-pointer"
            >save</mat-icon
          >
        </ng-container>
      </app-textarea>

      <div class="display-flex align-items-end justify-content-end" *ngIf="data.inputText && selectedBankableField">
        <app-toggle-buttons controlName="textAction" [options]="textOptions" (click)="textActionChanged()"></app-toggle-buttons>
      </div>

      <ng-container *ngIf="selectedBankableField?.text">
        <hr class="input__divider mt-2 mb-3" />

        <label class="vertical-label display-block"> Preview </label>
        <span>
          {{ textToBeReplaced }}
        </span>
      </ng-container>
    </form>
    <app-help-directional-text class="mt-2">
      <p>
        <small
          >You can dynamically use the learners first name by adding four asterisks in the text. Once the text is autofilled the asterisks
          will be replaced with the learners first name</small
        >
      </p>
    </app-help-directional-text>
  </mat-card>

  <div class="card__together-container">
    <mat-card class="card__together card__together--top card-spacing-top pt-2 pb-1">
      <mat-form-field class="w-100" [formGroup]="searchFormGroup">
        <mat-label>Filter</mat-label>
        <input matInput aria-labelledby="Filter" (keyup)="applyFilter($event)" formControlName="filter" />
        <mat-icon
          *ngIf="searchFormGroup.get('filter').value !== null && searchFormGroup.get('filter').value !== ''"
          matSuffix
          (click)="clearFilter()"
          class="cursor-pointer"
          >close</mat-icon
        >
      </mat-form-field>
    </mat-card>

    <mat-card class="card__together card__together--bottom card-spacing-bottom">
      <ul class="list" *ngIf="bankableFields.length === 0">
        <li class="list__item">
          <small><em>No Results...</em></small>
        </li>
      </ul>

      <ul class="list" *ngIf="bankableFields.length > 0">
        <li class="list__item" *ngFor="let field of bankableFields">
          <div
            class="bank-text"
            [ngClass]="{
              'bank-text--active': selectedBankableField?.id === field?.id && !isEditing
            }"
          >
            <div class="bank-text__content" (click)="select(field)">
              <app-inline-edit [editing]="field.isEditing">
                <ng-container viewMode>
                  <small> Date Saved: {{ field.createdOn | dateFormat }} </small>
                  <p class="my-0">
                    {{ field.text | truncate : stringSizes.small }}
                  </p>
                </ng-container>
                <ng-container editMode [formGroup]="formGroup">
                  <app-textarea formControlName="text" label="Text" [maxLength]="2000"></app-textarea>
                </ng-container>
              </app-inline-edit>
            </div>
            <div class="bank-text__actions">
              <ng-template [ngIf]="!isEditing">
                <button
                  type="button"
                  mat-icon-button
                  aria-label="Edit Icon"
                  color="primary"
                  (click)="setEdit(field)"
                  title="Edit"
                  tabindex="0"
                >
                  <mat-icon>edit</mat-icon>
                </button>
                <button
                  type="button"
                  mat-icon-button
                  aria-label="Delete Icon"
                  color="warn"
                  title="Delete"
                  (click)="remove(field)"
                  tabindex="0"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </ng-template>

              <ng-template [ngIf]="field.isEditing">
                <button
                  type="button"
                  mat-icon-button
                  aria-label="Save Icon"
                  color="primary"
                  (click)="addOrUpdate(field)"
                  title="Save"
                  tabindex="0"
                >
                  <mat-icon>save</mat-icon>
                </button>
                <button type="button" mat-icon-button aria-label="Clear Icon" title="Clear" (click)="setEdit(field, false)" tabindex="0">
                  <mat-icon>clear</mat-icon>
                </button>
              </ng-template>
            </div>
          </div>
        </li>
      </ul>
    </mat-card>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    color="primary"
    type="button"
    mat-raised-button
    aria-label="Use Selected Text"
    (click)="useText()"
    [disabled]="!selectedBankableField || isEditing"
    tabindex="0"
  >
    Use Selected Text
  </button>
  <button type="button" mat-raised-button aria-label="Add New Bankable Text" [disabled]="isEditing" (click)="addNewField()" tabindex="0">
    Add New Bankable Text
  </button>
  <button class="background-color--muted" type="button" mat-raised-button aria-label="Cancel Button" (click)="closeModal()" tabindex="0">
    Close
  </button>
</mat-dialog-actions>
