import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GenericPermissionsGuard } from '../auth/auth.guard';
import { CanDeactivateGuard } from '../shared/guards/can-deactivate.guard';
import { EnterLearnerProgressComponent } from './enter-learner-progress/enter-learner-progress.component';
import { GradeBookComponent } from './grade-book/grade-book.component';
import { MonitoringProgressComponent } from './monitoring-progress.component';
import { ProgressMonitorLookupsResolver } from './resolvers/progress-monitor-lookups.resolver';

const routes: Routes = [
  {
    path: '',
    component: MonitoringProgressComponent,
    children: [
      {
        path: 'grade-book',
        component: GradeBookComponent,
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
  {
    path: ':caseId/enter-progress',
    component: EnterLearnerProgressComponent,
    canActivate: [GenericPermissionsGuard],
    data: { permission: 'ViewProgressMonitoring' },
    resolve: {
      data: ProgressMonitorLookupsResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MonitoringProgressRoutingModule {}
