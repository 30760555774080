<div class="table-overflow mb-2">
  <table mat-table class="w-100" matSort [dataSource]="criterion.commentData">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more"></th>
      <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
        <ng-container *ngIf="!isEditing && enableForms">
          <button mat-icon-button aria-label="Edit Icon" color="primary" (click)="onCommentEdit(element)" tabindex="0">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button aria-label="Delete Icon" color="warn" (click)="onCommentDelete(element, criterion)" tabindex="0">
            <mat-icon>close</mat-icon>
          </button>
          <button mat-icon-button aria-label="View Icon" color="primary" (click)="onViewMore(element)" tabindex="0">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="element.isEditing">
          <button
            color="primary"
            mat-icon-button
            aria-label="Save Icon"
            (click)="onCommentUpdate(element, criterion)"
            title="Save"
            tabindex="0"
          >
            <mat-icon>save</mat-icon>
          </button>
          <button color="warn" mat-icon-button aria-label="Clear Icon" (click)="onCancelCommentEdit(element)" title="Cancel" tabindex="0">
            <mat-icon>clear</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let element">
        <app-inline-edit [editing]="element.isEditing">
          <ng-container viewMode>
            {{ element.date | dateFormat }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-date-picker [min]="minDate" [max]="today" label="Date" controlName="date"></app-date-picker>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Comment</th>
      <td mat-cell *matCellDef="let element">
        <app-inline-edit [editing]="element.isEditing">
          <ng-container viewMode>
            {{ (element.noteOther ? element.noteOther : element.label) | truncate : 150 }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-autocomplete
              label="Comment"
              formControlName="progressMonitorLookupId"
              [options]="commentOptions"
              (selected)="commentOptionSelected($event)"
            ></app-autocomplete>
            <ng-container *ngIf="isOther">
              <app-textarea label="Other" formControlName="noteOther"></app-textarea>
            </ng-container>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr
      [hidden]="criterion.comments.length > 0"
      mat-footer-row
      *matFooterRowDef="criterion.comments.length === 0 ? ['noResults'] : []"
    ></tr>
  </table>

  <mat-paginator [dataSource]="criterion.commentData"></mat-paginator>
</div>
