import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IepView } from '../../models/iep';
import { IepService } from '../../services/iep.service';

@Component({
  selector: 'app-iep-nav',
  templateUrl: './iep-nav.component.html',
  styleUrls: ['./iep-nav.component.scss'],
})
export class IepNavComponent implements OnInit {
  @Input() learnerId: string;
  iepId: string;
  caseId: string;
  iep: IepView;
  notFinalized: boolean;
  constructor(private route: ActivatedRoute, private iepService: IepService) {}

  ngOnInit(): void {
    this.iepId = this.route.snapshot.paramMap.get('iepId');
    this.caseId = this.route.snapshot.paramMap.get('caseId');
    this.getIep();
    this.iepService.iepUpdated$.subscribe(() => this.getIep());
  }

  private getIep() {
    this.iepService.get(this.iepId).subscribe((iep) => {
      this.iep = iep;
      this.notFinalized = !iep.activatedOn;
    });
  }
}
