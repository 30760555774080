<ng-container [ngSwitch]="(learnerSummary$ | async)?.progress">
  <app-learner-info-header *ngSwitchCase="'ChildFind'" [learnerSummary]="learnerSummary$ | async" [condensed]="true">
  </app-learner-info-header>
  <app-iep-learner-info-header *ngSwitchCase="'Iep'" [learnerSummary]="learnerSummary$ | async"></app-iep-learner-info-header>
  <app-ifsp-learner-info-header
    *ngSwitchCase="'Ifsp'"
    [learnerSummary]="learnerSummary$ | async"
    [condensed]="true"
  ></app-ifsp-learner-info-header>
</ng-container>
