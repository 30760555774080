import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Referral } from 'src/app/child-find/early-access-referral/referral';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';

@Component({
  selector: 'app-in-progress-referral-modal',
  templateUrl: './in-progress-referral-modal.component.html',
  styleUrls: ['./in-progress-referral-modal.component.scss'],
})
export class InProgressReferralModalComponent implements OnInit {
  selectedReferral: Referral;
  displayedColumns: string[] = ['select', 'name', 'createdOn', 'updatedOn'];
  dataSource: MatTableDataSource<Referral>;
  selection = new SelectionModel<Referral>(false, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  shortDateFormat = shortDateFormat;
  constructor(public dialogRef: MatDialogRef<InProgressReferralModalComponent>, @Inject(MAT_DIALOG_DATA) public referrals: Referral[]) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.referrals);
    this.dataSource.filterPredicate = (data: Referral, filter: string) => {
      filter.toLowerCase().trim();
      const fullName = data.childInfo.firstName.concat(' ').concat(data.childInfo.lastName).toLowerCase().trim();

      return !filter || fullName.includes(filter);
    };
    this.dataSource.sortingDataAccessor = (data: Referral, property: string) => {
      switch (property) {
        case 'name':
          return data.childInfo.firstName.concat(' ').concat(data.childInfo.lastName).toLowerCase().trim();
        default:
          return data[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Referral): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
