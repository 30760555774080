import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Evaluation } from '../models/evaluation';
import { EvaluationAssignment } from '../models/evaluation-assignment';
import { EvaluationUser } from '../models/evaluation-user';

@Injectable({
  providedIn: 'root',
})
export class EvaluationProxyService {
  evaluationChange = new Subject<Evaluation>();
  assignmentChange = new Subject<[string, EvaluationAssignment[]]>();
  teamChange = new Subject<EvaluationUser[]>();
  openTeamSearch = new Subject();
  eligibilityFinalized = new Subject<boolean>();
}
