<nav class="nav">
  <ul class="nav__list">
    <li class="nav__item">
      <a tabindex="0" role="link" aria-labelledby="ACHIEVE Iowa Logo">
        <img src="/assets/img/achieve-logo.svg" class="logo" alt="ACHIEVE Iowa Logo" />
      </a>
    </li>
  </ul>
</nav>

<section class="not-found">
  <div class="not-found__container">
    <main class="not-found__content">
      <div class="display-flex flex-column justify-content-between align-items-center">
        <section class="not-found__info">
          <h2 class="mt-0">You have been automatically logged out, returning you to IA Education Security portal.</h2>
        </section>
      </div>
    </main>
  </div>
</section>
