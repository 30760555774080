import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, Self, Optional, ChangeDetectorRef } from '@angular/core';
import { ControlContainer, FormGroupDirective, NgControl } from '@angular/forms';
import { BaseWrapperComponent } from '../base-wrapper.component';
import { LoggerService } from '../../../services/logger/logger.service';

// component based on https://github.com/JsDaddy/ngx-mask
@Component({
  selector: 'app-masked-input',
  templateUrl: './masked-input.component.html',
  styleUrls: ['./masked-input.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class MaskedInputComponent extends BaseWrapperComponent implements OnInit, OnChanges {
  @Input() mask: string;
  @Input() maxLength: number;
  @Input() useInputGroup = false;
  @Input() hint: string;
  @Output() blurMe = new EventEmitter<any>();
  @Output() keyDownMe = new EventEmitter<any>();
  @Input() readonly = false;

  constructor(
    logger: LoggerService,
    @Self()
    @Optional()
    ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(logger, ngControl, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  internalKeydown(event: any) {
    if (this.keyDownMe) {
      this.keyDownMe.emit(true);
    }
  }
  internalBlur(event: any) {
    if (this.blurMe) {
      this.blurMe.emit(true);
    }
  }
}
