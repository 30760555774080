<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-md-12 col-lg-6 col-xl-3">
      <app-text-field label="Name" formControlName="name"></app-text-field>
    </div>

    <div class="col-md-12 col-lg-6 col-xl-3">
      <app-text-field label="Position" formControlName="position"></app-text-field>
    </div>

    <div class="col-md-12 col-lg-6 col-xl-3">
      <app-text-field label="Email" formControlName="email"></app-text-field>
    </div>

    <div class="col-md-12 col-lg-6 col-xl-3">
      <app-masked-input label="Phone" formControlName="phone" mask="(000) 000-0000"></app-masked-input>
    </div>
  </div>

  <div class="action__row">
    <button mat-raised-button aria-label="Add Icon" (click)="submit()" color="primary" tabindex="0">Add New</button>
  </div>
</form>
