import dayjs, { Dayjs } from 'dayjs';

export function insertProgressMonitorData(array: any[], item: any): void {
  if (array.length === 0) {
    array.push(item);
    return;
  }

  for (let i = 0; i < array.length; i++) {
    const prev = array[i - 1];
    const current = array[i];

    if (!prev) {
      if (dayjs(item.date) >= dayjs(current.date)) {
        array.unshift(item);
        break;
      }

      if (dayjs(item.date) <= dayjs(current.date) && array.length === i + 1) {
        array.push(item);
        break;
      }
    } else {
      if (dayjs(item.date) <= dayjs(prev.date) && dayjs(item.date) >= dayjs(current.date)) {
        array.splice(i, 0, item);
        break;
      }

      if (dayjs(item.date) <= dayjs(current.date) && array.length === i + 1) {
        array.push(item);
        break;
      }
    }
  }
}

export function getMinDate(startDate: Dayjs) {
  const minDate = dayjs().subtract(30, 'day');
  // TODO: Re-enable when IEP bugs are fixed...
  if (startDate) {
    return startDate.toDate();
  }
  // if (iepStartDate && iepStartDate.isAfter(minDate)) {
  //   return iepStartDate.toDate();
  // }
  return minDate.toDate();
}
