import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { GoalArea } from 'src/app/iep/models/goal-area';
import { GoalAreaService } from 'src/app/iep/services/goalarea.service';
import { getMinDate, insertProgressMonitorData } from 'src/app/monitoring-progress/helpers/progressMonitorHelpers';
import { GoalProgressMonitor, ObjectiveProgressMonitor } from 'src/app/monitoring-progress/models/goal-progress-monitor';
import { ProgressMonitorItem } from 'src/app/monitoring-progress/models/progress-monitor-item';
import { ProgressMonitorReportItem } from 'src/app/monitoring-progress/models/progress-monitor-report-item';
import { ProgressMonitorBService } from 'src/app/monitoring-progress/services/progress-monitor-b.service';
import { ProgressMonitorCommentService } from 'src/app/monitoring-progress/services/progress-monitor-comment.service';
import { ProgressMonitorPhaseLineService } from 'src/app/monitoring-progress/services/progress-monitor-phase-line.service';
import { ProgressMonitorReportService } from 'src/app/monitoring-progress/services/progress-monitor-report.service';
import { MilestoneStatus } from 'src/app/shared/components/quantifiable-data/quantifiable-data';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { CaseSummary } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { AuthService } from '../../../../auth/auth.service';
import { DateFormatPipe } from '../../../../shared/pipes/date-transform.pipe';

@Component({
  selector: 'app-enter-progress-part-b',
  templateUrl: './enter-progress-part-b.component.html',
  styleUrls: ['./enter-progress-part-b.component.scss'],
})
export class EnterProgressPartBComponent implements OnInit, OnDestroy {
  @Input() caseSummary: CaseSummary;
  @Input() enableForms: boolean;
  @ViewChildren(MatExpansionPanel, { read: ElementRef })
  expansionPanelElements: QueryList<ElementRef>;
  @ViewChildren(MatExpansionPanel)
  expansionPanels: QueryList<MatExpansionPanel>;
  private subscriptions = new Subscription();
  allGoals: GoalProgressMonitor[] = [];
  filteredGoals: GoalProgressMonitor[] = [];
  shortDateFormat = shortDateFormat;
  goalAreas: KeyValuePair[] = [];
  progressMonitorReports: ProgressMonitorReportItem[] = [];
  showReportForm: boolean;

  filterFormGroup = new FormGroup({
    goalArea: new FormControl(null),
    search: new FormControl(null),
  });

  formGroupDataGraph = new FormGroup({
    valuesToShow: new FormControl(null, Validators.required),
  });

  chart = {
    data: [],
    labels: [''],
    colors: [
      {
        backgroundColor: ['rgba(38, 63, 140, 0.2)', 'rgba(245, 214, 142, 1)', 'rgba(41, 99, 28, 0.3)'],
      },
    ],
    legend: false,
  };

  goalQueryParam: string;
  objectiveQueryParam: string;
  minDate: Date;

  get isPortalUser() {
    return this.authService?.isPortalUser;
  }

  constructor(
    private readonly progressMonitorService: ProgressMonitorBService,
    private readonly phaseLineService: ProgressMonitorPhaseLineService,
    private readonly commentService: ProgressMonitorCommentService,
    private readonly goalAreaService: GoalAreaService,
    private readonly progressMonitorReportService: ProgressMonitorReportService,
    private readonly dialog: MatDialog,
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe,
    private readonly cdr: ChangeDetectorRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.goalQueryParam = params.goalId;
        this.objectiveQueryParam = params.objectiveId;
      })
    );
    this.subscriptions.add(this.filterFormGroup.valueChanges.subscribe((value) => this.filterGoals(value)));
    this.load();
    if (this.caseSummary && this.caseSummary.activeIepStartDate) {
      this.minDate = getMinDate(dayjs(this.caseSummary.activeIepStartDate));
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  //#region Progress Data

  onAddProgressData(event, goal: GoalProgressMonitor | ObjectiveProgressMonitor) {
    const primaryDto = event.primary;
    if (event.secondary) {
      const secondaryDto = event.secondary;
      this.subscriptions.add(
        this.progressMonitorService.add(this.caseSummary.learnerId, primaryDto, secondaryDto).subscribe((res) => {
          primaryDto.id = res.primaryId;
          secondaryDto.id = res.secondaryId;
          primaryDto.date = res.primaryDate;
          secondaryDto.date = res.secondaryDate;
          insertProgressMonitorData(goal.primaryMeasurement.progressData, primaryDto);
          insertProgressMonitorData(goal.secondaryMeasurement.progressData, secondaryDto);
          if (!goal.isObjective) {
            this.getProgressDataSource(goal);
          } else {
            goal.data.data = goal.primaryMeasurement.progressData;
          }
        })
      );
    } else {
      this.subscriptions.add(
        this.progressMonitorService.add(this.caseSummary.learnerId, primaryDto).subscribe((res) => {
          primaryDto.id = res.primaryId;
          primaryDto.date = res.primaryDate;
          insertProgressMonitorData(goal.primaryMeasurement.progressData, primaryDto);
          goal.data.data = goal.primaryMeasurement.progressData;
          if (goal.isCombinedObjective) {
            this.getCombinedProgressDataSource(goal as GoalProgressMonitor);
          }
        })
      );
    }
  }

  filterForActiveObjectives(objective: ObjectiveProgressMonitor) {
    return objective.status === MilestoneStatus.active;
  }

  async onAddCombinedProgressData(dataPoints, goal: GoalProgressMonitor) {
    let i = 0;
    for (const dataPoint of dataPoints) {
      const dto = {
        id: null,
        iepGoalQuantifiableMeasurementId: dataPoint.iepGoalQuantifiableMeasurementId,
        date: dataPoint.date,
        note: dataPoint.note,
        value: dataPoint.value,
      };
      const res = await this.progressMonitorService.add(this.caseSummary.learnerId, dto).toPromise();
      dto.id = res.primaryId;
      dto.date = res.primaryDate;
      const objective = goal.objectives.find((x) => x.id === dataPoint.objectiveId);
      insertProgressMonitorData(objective.primaryMeasurement.progressData, dto);
      objective.data.data = objective.primaryMeasurement.progressData;
      if (i === dataPoints.length - 1) {
        this.getCombinedProgressDataSource(goal);
      }
      i++;
    }
  }

  //#endregion

  //#region Phase Lines

  onPhaseLineAdd(phaseLine, goal) {
    this.subscriptions.add(
      this.phaseLineService.create(this.caseSummary.learnerId, goal.id, phaseLine).subscribe((res) => {
        phaseLine.id = res.id;
        phaseLine.date = res.date;
        this.handlePhaseLineAdd(phaseLine, goal);
      })
    );
  }

  private handlePhaseLineAdd(phaseLine, goal: GoalProgressMonitor | ObjectiveProgressMonitor) {
    insertProgressMonitorData(goal.phaseLines, phaseLine);
    goal.phaseLineData.data = goal.phaseLines;
  }

  //#endregion

  //#region Graph Note

  onCommentAdd(comment, goal) {
    this.subscriptions.add(
      this.commentService.create(this.caseSummary.learnerId, goal.id, comment).subscribe((res) => {
        comment.id = res.id;
        comment.date = res.date;
        this.handleCommentAdd(comment, goal);
      })
    );
  }

  private handleCommentAdd(comment, goal) {
    insertProgressMonitorData(goal.comments, comment);
    goal.commentData.data = goal.comments;
  }

  //#endregion

  onStatusChangeEvent(event, goal) {
    goal.status = event;
    this.subscriptions.add(this.progressMonitorService.updateObjectiveStatus(this.caseSummary.learnerId, goal).subscribe());
  }

  onReportProgressToFamily() {
    this.progressMonitorReportService.showForm.next();
  }

  onViewMore(progressMonitorItem, hasSecondaryMeasurement = false) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Date',
        value: new DateFormatPipe().transform(progressMonitorItem.date),
      },
    ];
    if (!hasSecondaryMeasurement) {
      modalData.push({ name: 'Value', value: progressMonitorItem.value });
      modalData.push({
        name: 'Monitoring Notes',
        value: progressMonitorItem.note,
      });
    } else {
      modalData.push({
        name: 'Value 1',
        value: progressMonitorItem.primaryValue,
      });
      modalData.push({
        name: 'Monitoring Notes 1',
        value: progressMonitorItem.primaryNote,
      });
      modalData.push({
        name: 'Value 2',
        value: progressMonitorItem.secondaryValue,
      });
      modalData.push({
        name: 'Monitoring Notes 2',
        value: progressMonitorItem.secondaryNote,
      });
    }

    openViewMore(this.dialog, modalData);
  }

  private getProgressDataSource(goal: GoalProgressMonitor | ObjectiveProgressMonitor) {
    const primaryMeasurementProgressData = goal.primaryMeasurement?.progressData || [];
    const secondaryMeasurementProgressData = goal.secondaryMeasurement?.progressData || [];
    if (!secondaryMeasurementProgressData || secondaryMeasurementProgressData.length === 0) {
      goal.data.data = primaryMeasurementProgressData;
      return;
    }
    const data = [];
    primaryMeasurementProgressData.forEach((pItem, pIdx) => {
      const sItem = secondaryMeasurementProgressData.find((secondaryItem, sIdx) => pIdx === sIdx);
      data.push({
        date: pItem.date,
        primaryId: pItem.id,
        value: pItem.value,
        note: pItem.note,
        primaryValue: pItem.value,
        primaryNote: pItem.note,
        secondaryId: sItem?.id,
        secondaryValue: sItem?.value,
        secondaryNote: sItem?.note,
      });
    });
    goal.data.data = data;
  }

  getCombinedProgressDataSource(goal: GoalProgressMonitor) {
    const goalProgressData = goal.primaryMeasurement.progressData.map((pd) => ({
      id: pd.id,
      date: pd.date,
      note: pd.note,
    }));
    const objectiveProgressData = goal.objectives
      .flatMap((x) => x.primaryMeasurement.progressData)
      .map((pd) => ({
        id: pd.id,
        date: pd.date,
        value: pd.value,
        note: pd.note,
      }));
    const array: Array<ProgressMonitorItem> = [];
    objectiveProgressData.forEach((pd) => {
      const existingValue = array.find((x) => x.date === pd.date);
      if (existingValue) {
        existingValue.value += pd.value;
        existingValue.id = `${existingValue.id}/${pd.id}`;
      } else {
        array.push(pd);
      }
    });
    goalProgressData.forEach((pd) => array.push(pd));
    array.sort((a, b) => (dayjs(a.date) > dayjs(b.date) ? -1 : 1));
    goal.data.data = array;
  }

  private filterGoals(filters) {
    let filteredGoals = [...this.allGoals];
    if (filters.goalArea) {
      filteredGoals = filteredGoals.filter((x) => x.goalAreas.some((y) => y.id === filters.goalArea));
    }

    if (filters.search) {
      filteredGoals = filteredGoals.filter(
        (x) =>
          x.name.toLocaleLowerCase().includes(filters.search.toLocaleLowerCase()) ||
          x.objectives.flatMap((y) => y.name).find((z) => z.toLocaleLowerCase().includes(filters.search.toLocaleLowerCase()))
      );
    }

    this.filteredGoals = filteredGoals;
  }

  private load() {
    this.subscriptions.add(
      forkJoin({
        goalAreas: this.goalAreaService.getAllGoalAreas(),
        goals: this.progressMonitorService.get(this.caseSummary.learnerId).pipe(
          catchError(() => {
            return of([]);
          })
        ),
        // reports: this.progressMonitorReportService.get(
        //   this.caseSummary.learnerId
        // ),
      }).subscribe((res) => {
        this.setGoalAreas(res.goalAreas);
        this.setGoalProgressData(res.goals);
        // this.progressMonitorReports = res.reports;
      })
    );
  }

  private setGoalAreas(goalAreas: Array<GoalArea>) {
    this.goalAreas = goalAreas.map((x) => new KeyValuePair(x.id, x.label));
    this.goalAreas.unshift(new KeyValuePair('', 'All'));
  }

  private setGoalProgressData(goals: Array<GoalProgressMonitor>) {
    this.allGoals = goals;
    this.allGoals.forEach((goal) => {
      goal.data = new MatTableDataSource();
      if (!goal.isCombinedObjective) {
        this.getProgressDataSource(goal);
      } else {
        this.getCombinedProgressDataSource(goal);
      }
      goal.phaseLineData = new MatTableDataSource(goal.phaseLines);
      goal.commentData = new MatTableDataSource(goal.comments);
      goal.objectives.forEach((objective) => {
        objective.data = new MatTableDataSource(objective.primaryMeasurement.progressData);
        objective.phaseLineData = new MatTableDataSource(objective.phaseLines);
        objective.commentData = new MatTableDataSource(objective.comments);
      });
    });

    this.filteredGoals = goals;

    if (this.filteredGoals.length > 0 && (this.goalQueryParam || this.objectiveQueryParam)) {
      this.openExpansionPanelFromQueryParam();
    }
  }

  private openExpansionPanelFromQueryParam() {
    this.subscriptions.add(
      this.expansionPanels.changes.subscribe((c) => {
        const panelElements = this.expansionPanelElements.toArray();

        const goalIndex = panelElements.findIndex((x) => x.nativeElement.id === this.goalQueryParam);
        const goalElement = panelElements.find((x) => x.nativeElement.id === this.goalQueryParam);

        const objectiveIndex = panelElements.findIndex((x) => x.nativeElement.id === this.objectiveQueryParam);
        const objectiveElement = panelElements.find((x) => x.nativeElement.id === this.objectiveQueryParam);

        const panels = c.toArray();

        if (goalIndex > -1) {
          this.subscriptions.add(
            panels[goalIndex].opened.pipe(delay(500)).subscribe((x) => goalElement.nativeElement.scrollIntoView({ behavior: 'smooth' }))
          );
          panels[goalIndex].open();
        }

        if (objectiveIndex > -1) {
          this.subscriptions.add(
            panels[objectiveIndex].opened.pipe(delay(500)).subscribe((x) =>
              objectiveElement.nativeElement.scrollIntoView({
                behavior: 'smooth',
              })
            )
          );
          panels[objectiveIndex].open();
        }
        this.cdr.detectChanges();
      })
    );
  }
}
