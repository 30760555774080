<ng-container *ngIf="control && (control.touched || control.dirty)">
  <div class="error__container">
    <div *ngIf="control.hasError('matDatepickerParse')">Invalid date format</div>
    <div *ngIf="control.hasError('matDatepickerMin')">
      Must be on or after
      {{ errors.matDatepickerMin.min | dateFormatNoConversion }}
    </div>
    <div *ngIf="control.hasError('matDatepickerMax')">
      Must be on or before
      {{ errors.matDatepickerMax.max | dateFormatNoConversion }}
    </div>
    <div *ngIf="control.hasError('required')" aria-label="Field is required" aria-live="polite">Field is <strong>required</strong></div>
    <div *ngIf="errors.maxlength">Field has a max length of {{ errors.maxlength.requiredLength }}</div>
    <div *ngIf="errors.minlength">Field has a min length of {{ errors.minlength.requiredLength }}</div>
    <div *ngIf="errors.min">Field has a min value of {{ errors.min.min }}</div>
    <div *ngIf="errors.max">Field has a max value of {{ errors.max.max }}</div>
    <div *ngIf="errors['Mask error'] || errors.pattern || errors['pattern']">Input not in correct format</div>
    <div *ngIf="errors[validationKeys.noNumbers]">Numbers are <strong>not allowed</strong></div>
    <div *ngIf="errors[validationKeys.noNumbersLimitedSpecialChars]">Numbers and special characters are <strong>not allowed</strong></div>
    <div *ngIf="errors[validationKeys.zipCode]">Input not in correct format</div>
    <div *ngIf="errors[validationKeys.uniqueIdentifier]">Input not in correct format</div>
    <div *ngIf="errors.email || errors['email']">Please enter a valid email</div>
    <div *ngFor="let error of uncoveredError">
      {{ error }}
    </div>
    <div *ngIf="errors['dateLessThan']">Date must be after start date</div>
    <div *ngIf="errors['typeahead']">A value must be selected from the list</div>
    <div *ngIf="errors['noFutureDates']">No future dates allowed</div>
    <div *ngIf="errors['invalidTimeFormat']">Invalid time format. Please enter the time in the format 'hh:mm PM'. Ex: 3:00 PM</div>
    <div *ngIf="errors['elaaAssessmentScaleScoreInvalid']">Invalid score value</div>
  </div>
</ng-container>
