export const abbreviationDictionary = {
  AEA: 'Area Education Agency',
  AEPS: 'Assessment Evaluation and Programming System',
  ALJ: 'Administrative Law Judge',
  APR: 'Annual Performance Report',
  ASD: 'Autism Spectrum Disorder',
  AT: 'Assistive Technology',
  AU: 'Administrative Unit',
  BIP: 'Behavior Intervention Plan',
  BOCES: 'Board of Cooperative Education Services',

  CAPTA: 'Child Abuse Prevention and Treatment Act',
  CAP: 'Corrective Action Plan',
  CAS: 'Colorado Academic Standards',
  CCB: 'Community Centered Boards',
  CCEIS: 'Comprehensive Coordinated Early Intervening Services',
  CCI: 'Constituent Concern Inspection',
  CDE: 'Colorado Department of Education',
  CFR: 'Code of Federal Regulations',
  CHSC: 'Child Health Speciality Clinic',
  CLP: 'Contingency Learning Plan',
  COMAR: 'Code of Maryland Regulations',
  COS: 'Child Outcomes Summary',
  DB: 'Deaf-Blindness',
  DD: 'Developmental Delay',

  DEISES: 'Division of Early Intervention and Special Education Services - MSDE (DEI/SES)',

  DHH: 'Deaf/Hard of Hearing or Hearing Impairment, Including Deafness',
  DLLR: 'Department of Labor, Licensing, and Regulation - MSDE',
  DLM: 'Dynamic Learning Maps',
  DMPS: 'Des Moines Public Schools',
  DMS: 'Data Management System or Data Monitoring System',
  DOB: 'Date of Birth',
  DOE: 'Department of Education',
  DPC: 'Due Process Complaint',
  DR: 'Dispute Resolution',
  ECEA: "Exceptional Children's Educational Act",
  ECM: 'Evaluation Case Manager',
  ECO: 'Early Childhood Outcomes',
  EDR: 'Eligibility Decision Worksheet',
  EEO: 'Extended Evidence Outcomes',
  EER: 'Educational Evaluation Report',
  EIS: 'Early Intervention Services',

  ELL: 'English Language Learner',
  EL: 'English Learner',

  EOY: 'End of Year',
  ESY: 'Extended School Year',
  FAPE: 'Free Appropriate Public Education',
  FBA: 'Functional Behavioral Assessment',
  FIIE: 'Full Individual Initial Evaluation',
  FM: 'Family Member',
  FSA: 'Facilitated Self Assessment',
  GOLD: 'Teaching Strategies GOLD Objectives for Development and Learning',
  HSA: 'High School Assessment',
  IAES: 'Interim Alternative Education Program',
  IC: 'Infinite Campus',

  IDEA: 'Individuals with Disabilities Education Act',
  IDE: 'Iowa Department of Education',
  IDR: 'Incomplete Data Report',
  ID: 'Intellectual Disability',

  IEP: 'Individualized Education Program',
  IFSN: 'Iowa Family Service Network',
  IFSP: 'Individualized Family Services Plan',
  ISASP: 'Iowa Statewide Assessment of learner Progress',
  ITP: 'Infants & Toddlers Program',
  JSES: 'Juvenile Services Education System - MSDE',
  KITS: 'Knowledge & Information Teaming System - South Carolina',
  LEA: 'Local Education Agency',
  LEP: 'Limited English Proficiency',
  LITP: 'Local Infants and Toddlers Program',
  LRE: 'Least Restrictive Environment',
  LSS: 'Local School System',
  MCIE: 'Maryland Coalition for Inclusive Education',
  MD: 'Multiple Disabilities',
  MOIEP: 'Maryland Online Individualized Education Plan',
  MSA: 'Maryland State Assessment',
  MSB: 'Maryland School for the Blind',
  MSDE: 'Maryland State Department of Education',
  MSD: 'Maryland School for the Deaf',
  MTSS: 'Multi Tiered System of Support',
  NDE: 'Nevada Department of Education',
  OHI: 'Other Health Impairment',

  OIE: 'Office of Inclusive Education',
  OI: 'Orthopedic Impairment',

  OSEP: 'Office of Special Education Programs',
  PA: 'Public Agency',
  EA: 'Early ACCESS',
  PLAAFP: 'Present Levels of Academic Achievement and Functional Performance',
  PM: 'Progress Monitoring',
  PPRA: 'Protection of Individuals from Restraint and Seclusion Act',
  PSO: 'Post-Secondary Outcomes',

  PWNSA: 'Prior Written Notice of Special Education Action',
  PWN: 'Prior Written Notice',
  PNSA: 'Prior Written Notice of Special Education Action',

  RS: 'Restraint and Seclusion (R&S)',
  RDA: 'Results Driven Accountability',

  RIOTSCI: 'Review, Interview, Observe, Test / Setting, Curriculum, Instruction, Learner (RIOT/SCIL)',

  RTC: 'Residential Treatment Center',
  RTI: 'Response to Intervention',

  SASID: 'State Assigned Student Identifier',
  SA: 'Self Assessment',

  SCKITS: 'South Carolina Knowledge & Information Teaming System',
  SCO: 'State Complaints Office',
  SDCCEIS: 'Significant Disproportionality - Comprehensive Coordinated Early Intervening Services (SD-CCEIS)',
  SDI: 'Specially Designed Instruction',
  SEA: 'State Education Agency',
  SED: 'Serious Emotional Disability',
  SEED: 'School for Educational Evolution and Development - MSDE',
  SIF: 'Schools Interoperability Framework',
  SIOP: 'Sheltered Instruction Observation Protocol',

  SLDS: 'Statewide Longitudinal Data System',
  SLD: 'Specific Learning Disability',

  SLI: 'Speech or Language Impairment',
  SLP: 'Speech-Language Pathology',

  SPEDEOY: 'Special Education End of Year data submission (SPED EOY)',
  SPEDSIS: 'Special Education Data Services and Information Systems',
  SPED: 'Special Education',

  SPP: 'State Performance Plan',
  SRR: 'Standard Record Review',
  SSIP: 'State Systemic Improvement Plans',
  SSIS: 'Special Services Information System Codes',
  SSP: 'Strategic Support Plan',
  SWIS: 'School-Wide Information System',
  SY: 'School Year',
  TBI: 'Traumatic Brain Injury',
  TEA: 'Texas Education Agency',
  TM: 'Team Member',
  TVI: 'Teacher of the Visually Impaired',
  UDL: 'Universal Design for Learning',
  UETA: 'Uniform Electronic Transaction Act',
  UPOD: 'Utah Preschool Outcomes Data',
  USBE: 'Utah State Board of Education',

  VIB: 'Visual Impairment, including Blindness',
  VI: 'Visual Impairment, including Blindness',
  VBI: 'Visual Impairment, including Blindness',

  YIC: 'Youth-In-Care / Youth-In-Custody',
};
