<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  {{ dialogData?.versionCheckFailed ? 'Version Error' : 'Version Mismatch' }}
</h3>

<mat-dialog-content class="py-2">
  <span *ngIf="dialogData?.versionCheckFailed; else noError">
    The application failed to check for the latest version. Please refresh your browser or click the refresh button below.
  </span>
  <ng-template #noError> Please refresh your browser to get the latest changes. Or click the refresh button below. </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="refresh()" cdkFocusInitial tabindex="0">Refresh Now</button>
</mat-dialog-actions>
