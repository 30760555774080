<button
  mat-flat-button
  aria-label="Amend"
  color="primary"
  *ngIf="hasOpenAmendment"
  class="mr-2"
  (click)="$event.stopPropagation(); onAmend()"
  tabindex="0"
>
  Amend
</button>
<main class="card-spacing-top">
  <form [formGroup]="formGroup">
    <ng-container *ngIf="isSecondaryTransition && !learnerIsPk">
      <h3 class="mb-2">Transition Assessments</h3>
      <mat-card class="pb-2 card--shadow">
        <app-transition-assessment
          *ngIf="secondaryTransitionId"
          [learnerId]="learnerId"
          [iepId]="iepId"
          [plaafpId]="plaafpId"
          [secondaryTransitionId]="secondaryTransitionId"
          [isFirstSecondaryTransition]="caseSummary.isFirstSecondaryTransition"
          [readonly]="readonly"
          [amendments]="amendments"
        ></app-transition-assessment>
      </mat-card>

      <h3 class="mb-2 card-spacing-top">Postsecondary Expectations (PSE)</h3>
      <mat-card class="pb-2 card-spacing-top card--shadow">
        <app-pse-summary
          *ngIf="secondaryTransitionId"
          [iepId]="iepId"
          [plaafpId]="plaafpId"
          [secondaryTransitionId]="secondaryTransitionId"
          [hasAmendment]="hasAmendment"
          [amendmentId]="amendmentId"
          [amendmentIsFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [readonly]="readonly"
        ></app-pse-summary>
      </mat-card>
    </ng-container>

    <ng-container *ngIf="iepIsPk">
      <div class="display-flex justify-content-between align-items-center my-0">
        <h3 class="my-0">Early Childhood Outcomes (ECO) Areas</h3>
        <button
          mat-raised-button
          aria-label="Autofill with ECO Summaries"
          color="primary"
          *ngIf="showAutofill"
          (click)="autoFillEcoMatrixSummaries()"
          tabindex="0"
        >
          Autofill with ECO Summaries
        </button>
      </div>
      <app-help-directional-text [outsideCard]="true">
        <p class="my-2">
          Complete the information required for each area or click the Autofill with ECO Summaries button to pull statements from previous
          decision. Any developmental areas that have not been tagged on this page must be addressed under the Present Levels of Development
          section.
        </p>
      </app-help-directional-text>
      <form [formGroup]="formGroup">
        <mat-accordion displayMode="flat" multi class="accordion accordion--short accordion--has-form">
          <ng-container *ngFor="let ecoAreaFormGroup of ecoAreaFormGroups; let i = index" [formGroup]="ecoAreaFormGroup">
            <mat-expansion-panel class="mat-elevation-z0 expanded--close">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h3 class="my-0 text-normal">{{ ecoAreaFormGroup.get('label').value }}</h3>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="display-flex justify-content-between align-items-start">
                <div class="flex-grow-1">
                  <ng-container *ngIf="!isAmendment || (hasOpenAmendment && amendment.amendingPlaafp && iepIsPk && learnerIsPk)">
                    <app-textarea
                      formControlName="description"
                      [isBankableField]="true"
                      [bankableFieldId]="bankableFieldIds.iepPlaafp.description"
                      [bankableFieldChildFname]="firstName"
                      label="Identify the learner's skills and abilities during routines and activities"
                      [maxLength]="stringSizes.extraLarge"
                      [rows]="4"
                    ></app-textarea>
                    <span *ngIf="hasOpenAmendment" class="my-2">
                      <app-compare-amendment-output
                        [amendmentId]="amendment?.id"
                        [amendmentFinalized]="amendmentIsFinalized"
                        [amendmentDate]="lastFinalizedDate"
                        [priorVersionId]="plaafpModel?.priorVersionId"
                        [changedVersionText]="ecoAreaFormGroup.get('description').value"
                        [currentVersionText]="findPriorEcoAreaDescription(ecoAreaFormGroup.get('type').value)"
                        [currentPrefixText]="findPriorEcoAreaDescription(ecoAreaFormGroup.get('type').value) ? 'Current: ' : 'Proposed: '"
                        changedPrefixText="Proposed: "
                      ></app-compare-amendment-output>
                    </span>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      (isAmendment && (!hasOpenAmendment || !amendment.amendingPlaafp)) ||
                      (hasOpenAmendment && amendment.amendingPlaafp && iepIsPk && !learnerIsPk)
                    "
                  >
                    <h4 class="mt-0 mb-1">Description</h4>
                    <div class="mt-1 mb-2">
                      {{ ecoAreaFormGroup.get('description').value }}
                    </div>
                  </ng-container>
                </div>
              </div>

              <app-help-directional-text>
                <p class="mb-2">Click the Launch ECO Decision Tree button to determine and document comparison to age expectation.</p>
              </app-help-directional-text>

              <div class="row align-items-center">
                <div class="col-md-12 col-lg-7 col-xl-9">
                  <ng-container>
                    <h4 class="mt-0 mb-1">Comparison to Age Expectation</h4>
                    <p class="my-0" *ngIf="ecoAreaFormGroup.get('scoreText').value">
                      {{ ecoAreaFormGroup.get('scoreText').value }}
                      <span *ngIf="hasOpenAmendment" class="my-2">
                        <app-compare-amendment-output
                          [amendmentId]="amendment?.id"
                          [amendmentFinalized]="amendmentIsFinalized"
                          [amendmentDate]="lastFinalizedDate"
                          [priorVersionId]="plaafpModel?.priorVersionId"
                          [changedVersionText]="ecoAreaFormGroup.get('scoreText').value"
                          [currentVersionText]="ecoAreaFormGroup.get('priorScoreText')?.value"
                          [currentPrefixText]="ecoAreaFormGroup.get('priorScoreText')?.value ? 'Current: ' : 'Proposed: '"
                          changedPrefixText="Proposed: "
                        ></app-compare-amendment-output>
                      </span>
                    </p>
                    <p class="my-0" *ngIf="!ecoAreaFormGroup.get('scoreText').value">Not yet determined</p>
                  </ng-container>
                </div>
              </div>
              <div
                class="action__row my-0"
                *ngIf="
                  !isAmendment ||
                  (hasOpenAmendment && ecoAreaFormGroup.get('description').value !== ecoAreaFormGroup.get('savedDescription').value)
                "
              >
                <span class="text-danger mr-2" *ngIf="!ecoAreaFormGroup.get('scoreText').value"
                  >ECO Ratings are <strong>required</strong></span
                >
                <button
                  mat-raised-button
                  color="primary"
                  aria-label="Launch ECO Decision Tree"
                  (click)="launchEcoDecisionTree(ecoAreaFormGroup.get('id').value)"
                  tabindex="0"
                >
                  Launch ECO Decision Tree
                </button>
              </div>
            </mat-expansion-panel>
          </ng-container>
        </mat-accordion>
      </form>
    </ng-container>

    <ng-container *ngIf="!iepIsPk">
      <mat-card class="mt-2 py-2 card-spacing-bottom">
        <div class="display-flex align-items-baseline mr-2">
          <h4 class="my-0 mr-1 mb-3">Present Levels of Academic Achievement and Functional Performance (PLAAFP)</h4>
        </div>
      </mat-card>
      <section>
        <h3 class="card-spacing-top mb-2 mt-2" [ngClass]="{ 'card-spacing-top': isSecondaryTransition }">Strengths and Skills</h3>
        <mat-card class="my-0 card--shadow">
          <div class="display-flex align-items-center justify-content-between flex-wrap mb-2">
            <app-help-directional-text>
              <p>
                Identify and
                <a
                  class="text-underline text-lower"
                  (click)="onOpenHelp($event, iepPlaafpHelp.Tag)"
                  tabindex="0"
                  role="link"
                  attr.aria-label="{{ iepPlaafpHelp.Tag }}"
                  >{{ iepPlaafpHelp.Tag }}</a
                >
                existing
                <a
                  class="text-underline text-lower"
                  (click)="onOpenHelp($event, iepPlaafpHelp.StrengthSkill)"
                  tabindex="0"
                  role="link"
                  attr.aria-label="{{ iepPlaafpHelp.StrengthSkill }}"
                  >{{ iepPlaafpHelp.StrengthSkill }}</a
                >
                on which to build
              </p>
            </app-help-directional-text>
            <button
              mat-raised-button
              aria-label="Add Icon"
              color="primary"
              class="py-1 ml-2"
              title="Add New"
              *ngIf="isAmendment && hasOpenAmendment && amendment.amendingPlaafp"
              (click)="isEditingStrengthSkill = true"
              tabindex="0"
            >
              Add New
            </button>
          </div>
          <ng-container *ngIf="isEditingStrengthSkill || !isAmendment">
            <form [formGroup]="strengthSkillFormGroup" #strengthSkillForm="ngForm">
              <div>
                <div class="row">
                  <div class="col-xl-8 col-md-12">
                    <div class="display-flex flex-column">
                      <app-textarea
                        formControlName="comments"
                        label="Strengths and Skills"
                        [useInputGroup]="true"
                        [isBankableField]="true"
                        [bankableFieldId]="bankableFieldIds.iepPlaafp.strengthSkillsComments"
                        [bankableFieldChildFname]="firstName"
                        [maxLength]="stringSizes.extraLarge"
                        [rows]="4"
                        [hideAsterisk]="strengths?.length > 0"
                      >
                        <ng-container append>
                          <app-sentence-starters-button
                            [sentenceStarters]="strengthsSkillsSentenceStarters"
                            [isSuffix]="true"
                            (selectedQuestion)="onSelectedQuestion($event, strengthSkillFormGroup.get('comments'))"
                          ></app-sentence-starters-button>
                        </ng-container>
                      </app-textarea>
                      <div class="align-self-start" *ngIf="isSecondaryTransition">
                        <div class="display-flex align-items-center justify-content-between flex-wrap">
                          <div class="display-flex">
                            <mat-button-toggle-group [multiple]="true" aria-labelledby="Select an option">
                              <mat-button-toggle
                                (change)="toggleEnvironment($event, strengthSkillFormGroup.get('living'))"
                                [checked]="strengthSkillFormGroup.get('living')?.value"
                                value="living"
                                aria-labelledby="Living"
                              >
                                Living
                              </mat-button-toggle>
                              <mat-button-toggle
                                (change)="toggleEnvironment($event, strengthSkillFormGroup.get('learning'))"
                                [checked]="strengthSkillFormGroup.get('learning')?.value"
                                value="learning"
                                aria-labelledby="Learning"
                              >
                                Learning
                              </mat-button-toggle>
                              <mat-button-toggle
                                (change)="toggleEnvironment($event, strengthSkillFormGroup.get('working'))"
                                [checked]="strengthSkillFormGroup.get('working')?.value"
                                value="working"
                                aria-labelledby="Working"
                              >
                                Working
                              </mat-button-toggle>
                            </mat-button-toggle-group>
                          </div>
                        </div>
                      </div>
                      <div class="align-self-end tags__bar">
                        <div class="display-flex align-items-center">
                          <app-tag-bar formControlName="tags"></app-tag-bar>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-md-12">
                    <div class="display-flex flex-column">
                      <app-text-field
                        formControlName="dataSource"
                        label="Data Source"
                        [isBankableField]="true"
                        [bankableFieldId]="bankableFieldIds.iepPlaafp.strengthSkillsDataSource"
                        [hideAsterisk]="strengths?.length > 0"
                      ></app-text-field>
                      <div class="align-self-end">
                        <button
                          mat-raised-button
                          aria-label="Cancel Button"
                          class="mr-1"
                          *ngIf="isAmendment"
                          (click)="onCancel('StrengthSkill')"
                          color="warn"
                          title="Cancel"
                          tabindex="0"
                        >
                          Cancel
                        </button>
                        <button
                          mat-raised-button
                          aria-label="Save Strength/Skill"
                          (click)="onSubmit('StrengthSkill', strengthSkillFormGroup, strengthSkillForm)"
                          color="primary"
                          [disabled]="strengthSkillFormGroup.invalid"
                          title="Complete"
                          [disableAfterBusy]="strengthSkillFormGroup.invalid"
                          [isBusy]="activeCall"
                          tabindex="0"
                        >
                          Save Strength/Skill
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ng-container>
          <div class="row mt-3" *ngIf="strengthSkillDataSource.data.length > 0">
            <div class="col-md-12">
              <div class="table-overflow app-table">
                <table mat-table [dataSource]="strengthSkillDataSource" matSort class="w-100">
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef [hidden]="isDetailsView && !isAmendment" class="mat-column-actions--more">
                      Actions
                    </th>
                    <td mat-cell *matCellDef="let strengthSkill" [hidden]="isDetailsView && !isAmendment" class="mat-column-actions--more">
                      <ng-container *ngIf="!isAmendment">
                        <button
                          aria-label="View Icon"
                          mat-icon-button
                          color="primary"
                          (click)="onViewMore('StrengthSkill', strengthSkill)"
                          tabindex="0"
                        >
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                        <button
                          aria-label="Edit Icon"
                          mat-icon-button
                          color="primary"
                          (click)="onEdit('StrengthSkill', strengthSkill)"
                          tabindex="0"
                        >
                          <mat-icon>edit</mat-icon>
                        </button>
                        <button
                          aria-label="Delete Icon"
                          mat-icon-button
                          (click)="onDelete('StrengthSkill', strengthSkill)"
                          color="warn"
                          tabindex="0"
                        >
                          <mat-icon>close</mat-icon>
                        </button>
                      </ng-container>
                      <ng-container *ngIf="isAmendment && hasOpenAmendment && amendment.amendingPlaafp">
                        <button
                          aria-label="View Icon"
                          mat-icon-button
                          color="primary"
                          (click)="onViewMore('StrengthSkill', strengthSkill)"
                          tabindex="0"
                        >
                          <mat-icon>remove_red_eye</mat-icon>
                        </button>
                        <button
                          aria-label="Edit Icon"
                          mat-icon-button
                          color="primary"
                          (click)="onEdit('StrengthSkill', strengthSkill)"
                          tabindex="0"
                        >
                          <mat-icon>edit</mat-icon>
                        </button>
                        <button
                          *ngIf="canDelete(strengthSkillDataSource.data.length, strengthSkill)"
                          mat-icon-button
                          aria-label="Delete Icon"
                          (click)="onDelete('StrengthSkill', strengthSkill)"
                          color="warn"
                          tabindex="0"
                        >
                          <mat-icon>close</mat-icon>
                        </button>
                        <button
                          *ngIf="canUndoDelete(strengthSkill)"
                          mat-icon-button
                          aria-label="Clear Icon"
                          (click)="undoDelete('StrengthSkill', strengthSkill)"
                          color="warn"
                          tabindex="0"
                        >
                          <i class="fas fa-undo" aria-label="Clear Icon"></i>
                        </button>
                      </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="comments">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Strengths and Skills</th>
                    <td mat-cell *matCellDef="let strengthSkill">
                      <app-compare-amendment-output
                        [amendmentId]="amendmentId"
                        [amendmentFinalized]="amendmentIsFinalized"
                        [amendmentDate]="lastFinalizedDate"
                        [amendmentEndReasonValue]="strengthSkill.removedByAmendment"
                        [priorVersionId]="strengthSkill.priorVersionId"
                        [changedVersionText]="strengthSkill.comments"
                        [tableData]="true"
                        [currentVersionText]="getCommentPriorVersion('StrengthSkill', strengthSkill)"
                        [changedPrefixText]="strengthSkill?.priorVersionId ? 'Proposed: ' : ''"
                      ></app-compare-amendment-output>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="dataSource">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Source</th>
                    <td mat-cell *matCellDef="let strengthSkill">
                      <app-compare-amendment-output
                        [amendmentId]="amendmentId"
                        [amendmentFinalized]="amendmentIsFinalized"
                        [amendmentDate]="lastFinalizedDate"
                        [amendmentEndReasonValue]="strengthSkill.removedByAmendment"
                        [priorVersionId]="strengthSkill.priorVersionId"
                        [changedVersionText]="strengthSkill.dataSource"
                        [tableData]="true"
                        [currentVersionText]="getDatasourcePriorVersion('StrengthSkill', strengthSkill)"
                        [changedPrefixText]="strengthSkill?.priorVersionId ? 'Proposed: ' : ''"
                      ></app-compare-amendment-output>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="tags">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="isDetailsView && !isAmendment">
                      {{ isSecondaryTransition ? 'IEP Tags' : 'Tags' }}
                    </th>
                    <td mat-cell *matCellDef="let strengthSkill" [hidden]="isDetailsView && !isAmendment">
                      <ng-container *ngFor="let tag of strengthSkill.tags">
                        <i *ngIf="tag === '0'" class="fas fa-bullseye tag__icon text-success mr-1" aria-label="Goal Tagging Icon"></i>
                        <i
                          *ngIf="tag === '1'"
                          class="fas fa-chalkboard-teacher tag__icon text-success mr-1"
                          aria-label="Service Tagging Icon"
                        ></i>
                        <i *ngIf="tag === '2'" class="fas fa-handshake tag__icon text-success mr-1"></i>
                        <i *ngIf="tag === '3'" class="fas fa-print tag__icon text-success mr-1"></i>
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="llwTags" *ngIf="isSecondaryTransition">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>L/L/W Tags</th>
                    <td mat-cell *matCellDef="let strengthSkill">
                      <span class="mr-2" *ngIf="strengthSkill.living">Living</span>
                      <span class="mr-2" *ngIf="strengthSkill.learning">Learning</span>
                      <span class="mr-2" *ngIf="strengthSkill.working">Working</span>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedStrengthSkillColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedStrengthSkillColumns"></tr>
                </table>
                <mat-paginator [dataSource]="strengthSkillDataSource"></mat-paginator>
              </div>
            </div>
          </div>
        </mat-card>
      </section>
    </ng-container>

    <section>
      <h3 class="card-spacing-top mb-2">Preferences and Interests</h3>
      <mat-card class="card--shadow mb-0">
        <div class="display-flex align-items-center justify-content-between flex-wrap mb-2">
          <app-help-directional-text>
            <p>
              Identify and
              <a class="text-underline mr-1 text-lower" (click)="onOpenHelp($event, iepPlaafpHelp.Tag)">{{ iepPlaafpHelp.Tag }}</a>
              <a
                class="text-underline text-lower"
                (click)="onOpenHelp($event, iepPlaafpHelp.PreferenceInterest)"
                tabindex="0"
                role="link"
                >{{ iepPlaafpHelp.PreferenceInterest }}</a
              >
              for learning, materials, and instructional methods and strategies
            </p>
          </app-help-directional-text>
          <button
            mat-raised-button
            color="primary"
            aria-label="Add Icon"
            class="py-1 ml-2"
            title="Add New"
            *ngIf="isAmendment && hasOpenAmendment && amendment.amendingPlaafp"
            (click)="isEditingPreference = true"
            tabindex="0"
          >
            Add New
          </button>
        </div>
        <ng-container *ngIf="isEditingPreference || !isAmendment">
          <form [formGroup]="preferenceFormGroup" #preferenceForm="ngForm">
            <div class="row">
              <div class="col-xl-8 col-md-12">
                <div class="display-flex flex-column">
                  <app-textarea
                    formControlName="comments"
                    label="Preferences and Interests"
                    [rows]="4"
                    [isBankableField]="true"
                    [bankableFieldId]="bankableFieldIds.iepPlaafp.preferenceComments"
                    [bankableFieldChildFname]="firstName"
                    [useInputGroup]="true"
                    [maxLength]="stringSizes.extraLarge"
                    [hideAsterisk]="preferences.length > 0"
                  >
                    <ng-container append>
                      <app-sentence-starters-button
                        [sentenceStarters]="preferencesSentenceStarters"
                        [isSuffix]="true"
                        (selectedQuestion)="onSelectedQuestion($event, preferenceFormGroup.get('comments'))"
                      ></app-sentence-starters-button>
                    </ng-container>
                  </app-textarea>
                  <div class="align-self-start" *ngIf="isSecondaryTransition">
                    <div class="display-flex align-items-center justify-content-between flex-wrap">
                      <div class="display-flex">
                        <mat-button-toggle-group [multiple]="true" aria-labelledby="Select an option">
                          <mat-button-toggle
                            (change)="toggleEnvironment($event, preferenceFormGroup.get('living'))"
                            [checked]="preferenceFormGroup.get('living')?.value"
                            value="living"
                            aria-labelledby="Living"
                          >
                            Living
                          </mat-button-toggle>
                          <mat-button-toggle
                            (change)="toggleEnvironment($event, preferenceFormGroup.get('learning'))"
                            [checked]="preferenceFormGroup.get('learning')?.value"
                            value="learning"
                            aria-labelledby="Learning"
                          >
                            Learning
                          </mat-button-toggle>
                          <mat-button-toggle
                            (change)="toggleEnvironment($event, preferenceFormGroup.get('working'))"
                            [checked]="preferenceFormGroup.get('working')?.value"
                            value="working"
                            aria-labelledby="Working"
                          >
                            Working
                          </mat-button-toggle>
                        </mat-button-toggle-group>
                      </div>
                    </div>
                  </div>
                  <div class="align-self-end tags__bar">
                    <div class="display-flex align-items-center">
                      <app-tag-bar formControlName="tags"></app-tag-bar>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-md-12">
                <div class="display-flex flex-column">
                  <app-text-field
                    formControlName="dataSource"
                    label="Data Source"
                    [isBankableField]="true"
                    [bankableFieldId]="bankableFieldIds.iepPlaafp.preferenceDataSource"
                    [hideAsterisk]="preferences.length > 0"
                  ></app-text-field>
                  <div class="align-self-end">
                    <button
                      mat-raised-button
                      aria-label="Delete Icon"
                      class="mr-1"
                      *ngIf="isAmendment"
                      (click)="onCancel('Preference')"
                      color="warn"
                      title="Cancel"
                      tabindex="0"
                    >
                      Cancel
                      <mat-icon>close</mat-icon>
                    </button>
                    <button
                      mat-raised-button
                      aria-label="Save Preference/Interest"
                      color="primary"
                      [disabled]="preferenceFormGroup.invalid"
                      [disableAfterBusy]="preferenceFormGroup.invalid"
                      [isBusy]="activeCall"
                      (click)="onSubmit('Preference', preferenceFormGroup, preferenceForm)"
                      tabindex="0"
                    >
                      Save Preference/Interest
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-container>
        <div class="row mt-3" *ngIf="preferenceDataSource.data.length > 0">
          <div class="col-md-12">
            <div class="table-overflow app-table">
              <table mat-table [dataSource]="preferenceDataSource" matSort class="w-100">
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef [hidden]="isDetailsView && !isAmendment" class="mat-column-actions--more">
                    Actions
                  </th>
                  <td class="mat-column-actions--more" mat-cell *matCellDef="let preference" [hidden]="isDetailsView && !isAmendment">
                    <ng-container *ngIf="!isAmendment">
                      <button
                        aria-label="View Icon"
                        mat-icon-button
                        color="primary"
                        (click)="onViewMore('Preference', preference)"
                        tabindex="0"
                      >
                        <mat-icon>remove_red_eye</mat-icon>
                      </button>
                      <button
                        aria-label="Edit Icon"
                        mat-icon-button
                        color="primary"
                        (click)="onEdit('Preference', preference)"
                        tabindex="0"
                      >
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button
                        aria-label="Delete Icon"
                        mat-icon-button
                        (click)="onDelete('Preference', preference)"
                        color="warn"
                        tabindex="0"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="isAmendment && hasOpenAmendment && amendment.amendingPlaafp">
                      <button
                        aria-label="View Icon"
                        mat-icon-button
                        color="primary"
                        (click)="onViewMore('Preference', preference)"
                        tabindex="0"
                      >
                        <mat-icon>remove_red_eye</mat-icon>
                      </button>
                      <button
                        aria-label="Edit Icon"
                        mat-icon-button
                        color="primary"
                        (click)="onEdit('Preference', preference)"
                        tabindex="0"
                      >
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button
                        *ngIf="canDelete(preferenceDataSource.data.length, preference)"
                        mat-icon-button
                        aria-label="Delete Icon"
                        (click)="onDelete('Preference', preference)"
                        color="warn"
                        tabindex="0"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                      <button
                        *ngIf="canUndoDelete(preference)"
                        mat-icon-button
                        aria-label="Clear Icon"
                        (click)="undoDelete('Preference', preference)"
                        color="warn"
                        tabindex="0"
                      >
                        <i class="fas fa-undo" aria-label="Clear Icon"></i>
                      </button>
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="comments">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Preferences and Interests</th>
                  <td mat-cell *matCellDef="let preference">
                    <app-compare-amendment-output
                      [amendmentId]="amendmentId"
                      [amendmentFinalized]="amendmentIsFinalized"
                      [amendmentDate]="lastFinalizedDate"
                      [amendmentEndReasonValue]="preference.removedByAmendment"
                      [priorVersionId]="preference.priorVersionId"
                      [changedVersionText]="preference.comments"
                      [tableData]="true"
                      [currentVersionText]="getCommentPriorVersion('Preference', preference)"
                      [changedPrefixText]="preference?.priorVersionId ? 'Proposed: ' : ''"
                    ></app-compare-amendment-output>
                  </td>
                </ng-container>
                <ng-container matColumnDef="dataSource">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Source</th>
                  <td mat-cell *matCellDef="let preference">
                    <app-compare-amendment-output
                      [amendmentId]="amendmentId"
                      [amendmentFinalized]="amendmentIsFinalized"
                      [amendmentDate]="lastFinalizedDate"
                      [amendmentEndReasonValue]="preference.removedByAmendment"
                      [priorVersionId]="preference.priorVersionId"
                      [changedVersionText]="preference.dataSource"
                      [tableData]="true"
                      [currentVersionText]="getDatasourcePriorVersion('Preference', preference)"
                      [changedPrefixText]="preference?.priorVersionId ? 'Proposed: ' : ''"
                    ></app-compare-amendment-output>
                  </td>
                </ng-container>
                <ng-container matColumnDef="tags">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="isDetailsView && !isAmendment">Tags</th>
                  <td mat-cell *matCellDef="let preference" [hidden]="isDetailsView && !isAmendment">
                    <ng-container *ngFor="let tag of preference.tags">
                      <i *ngIf="tag === '0'" class="fas fa-bullseye tag__icon text-success mr-1" aria-label="Goal Tagging Icon"></i>
                      <i
                        *ngIf="tag === '1'"
                        class="fas fa-chalkboard-teacher tag__icon text-success mr-1"
                        aria-label="Service Tagging Icon"
                      ></i>
                      <i *ngIf="tag === '2'" class="fas fa-handshake tag__icon text-success mr-1"></i>
                      <i *ngIf="tag === '3'" class="fas fa-print tag__icon text-success mr-1"></i>
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="llwTags" *ngIf="isSecondaryTransition">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>L/L/W Tags</th>
                  <td mat-cell *matCellDef="let preference">
                    <span class="mr-2" *ngIf="preference.living">Living</span>
                    <span class="mr-2" *ngIf="preference.learning">Learning</span>
                    <span class="mr-2" *ngIf="preference.working">Working</span>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedPreferenceColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedPreferenceColumns"></tr>
              </table>
              <mat-paginator [dataSource]="preferenceDataSource"></mat-paginator>
            </div>
          </div>
        </div>
      </mat-card>
    </section>

    <section class="card-spacing-top" *ngIf="caseSummary">
      <app-iep-special-factors-bar
        [formGroup]="formGroup"
        [isAmendment]="isAmendment"
        [hasOpenAmendment]="hasOpenAmendment"
        [amendmentIsFinalized]="amendmentIsFinalized"
        [lastFinalizedDate]="lastFinalizedDate"
        [amendingPlaafp]="amendment?.amendingPlaafp"
        [caseId]="caseSummary.caseId"
        [bipId]="caseSummary.bipId"
        [fbaId]="caseSummary.fbaId"
        [bipNeeded]="caseSummary.bipNeeded"
        [specialFactorHelpers]="plaafpModel?.specialFactorHelpers"
        [plaafpModel]="plaafpModel"
      ></app-iep-special-factors-bar>
    </section>

    <section class="card-spacing-top">
      <h3 class="mb-2 mt-0">Effect of the Disability</h3>
      <mat-card class="my-0 card--shadow">
        <app-help-directional-text>
          <p *ngIf="!learnerIsPk">
            Describe {{ firstName | firstNamePossessive }}
            <a
              class="text-underline text-lower"
              (click)="onOpenHelp($event, iepPlaafpHelp.InvolvementProgress)"
              tabindex="0"
              role="link"
              attr.aria-label="{{ iepPlaafpHelp.InvolvementProgress }}"
              >{{ iepPlaafpHelp.InvolvementProgress }}</a
            >
          </p>
          <p *ngIf="learnerIsPk">Describe {{ firstName | firstNamePossessive }} participation and progress in appropriate activities</p>
        </app-help-directional-text>

        <div class="display-flex flex-column mt-2">
          <div>
            <label
              *ngIf="learnerIsPk"
              class="vertical-label display-block position-fix--label"
              [ngClass]="{
                asterisk_input: formGroup.get('describeStudentInvolvement').hasError('required')
              }"
            >
              Provide specific and individualized statements regarding the impact of {{ firstName | firstNamePossessive }} disability in
              settings where activities occur for children of similar age without disabilities.
            </label>
            <label
              *ngIf="!learnerIsPk"
              class="vertical-label display-block position-fix--label"
              [ngClass]="{
                asterisk_input: formGroup.get('describeStudentInvolvement').hasError('required')
              }"
            >
              Provide specific and individualized statements regarding the impact of
              {{ firstName | firstNamePossessive }} disability both in the classroom and community that reflect the identified needs
              documented throughout the IEP
            </label>
            <ng-container *ngIf="!isAmendment || (hasOpenAmendment && amendment.amendingPlaafp)">
              <app-textarea
                [hideAsterisk]="true"
                formControlName="describeStudentInvolvement"
                [isBankableField]="true"
                [bankableFieldId]="bankableFieldIds.iepPlaafp.describeStudentInvolvement"
                [bankableFieldChildFname]="firstName"
                [noMaxLength]="true"
                [maxLength]="0"
                [allowTextOverflow]="true"
                [rows]="4"
                attr.aria-required="{{ formGroup.get('describeStudentInvolvement').hasError('required') ? 'true' : 'false' }}"
              ></app-textarea>
              <span *ngIf="hasOpenAmendment">
                <app-compare-amendment-output
                  [amendmentId]="amendmentId"
                  [amendmentFinalized]="amendmentIsFinalized"
                  [amendmentDate]="lastFinalizedDate"
                  [priorVersionId]="plaafpModel?.priorVersionId"
                  [changedVersionText]="formGroup.get('describeStudentInvolvement').value"
                  [currentVersionText]="plaafpModel?.priorVersion?.describeStudentInvolvement"
                  [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : 'Proposed: '"
                  changedPrefixText="Proposed: "
                ></app-compare-amendment-output>
              </span>
            </ng-container>
            <div *ngIf="isAmendment && (!hasOpenAmendment || !amendment.amendingPlaafp)">
              <app-compare-amendment-output
                [amendmentId]="amendmentId"
                [amendmentFinalized]="amendmentIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [priorVersionId]="plaafpModel?.priorVersionId"
                [changedVersionText]="formGroup.get('describeStudentInvolvement').value"
                [currentVersionText]="plaafpModel?.priorVersion?.describeStudentInvolvement"
                [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : 'Proposed: '"
                changedPrefixText="Proposed: "
              ></app-compare-amendment-output>
            </div>
            <div class="display-flex align-items-center justify-content-end my-2"></div>
          </div>
        </div>

        <div class="display-flex flex-column">
          <div>
            <label
              class="vertical-label display-block position-fix--label"
              [ngClass]="{
                asterisk_input: formGroup.get('effectOfDisability').hasError('required')
              }"
            >
              Will {{ firstName }} participate in
              <a
                class="text-underline text-lower"
                (click)="onOpenHelp($event, iepPlaafpHelp.NonAcademicAct)"
                tabindex="0"
                role="link"
                attr.aria-label="{{ iepPlaafpHelp.NonAcademicAct }}"
                >{{ iepPlaafpHelp.NonAcademicAct }}</a
              >
              with nondisabled peers and have the same opportunity to participate in
              <a
                class="text-underline text-lower"
                (click)="onOpenHelp($event, iepPlaafpHelp.ExtracurricularAct)"
                tabindex="0"
                role="link"
                >{{ iepPlaafpHelp.ExtracurricularAct }}</a
              >
              as nondisabled peers?
            </label>
            <ng-container *ngIf="!isAmendment || (hasOpenAmendment && amendment.amendingPlaafp)">
              <fieldset>
                <app-radio-group formControlName="effectOfDisability" [hideAsterisk]="true" [options]="yesNoOptions"></app-radio-group>
              </fieldset>
            </ng-container>
            <span *ngIf="hasOpenAmendment || (isAmendment && (!hasOpenAmendment || !amendment.amendingPlaafp))">
              <app-compare-amendment-output
                [amendmentId]="amendmentId"
                [amendmentFinalized]="amendmentIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [priorVersionId]="plaafpModel?.priorVersionId"
                [changedVersionText]="formGroup.get('effectOfDisability').value | boolToYesNo"
                [currentVersionText]="plaafpModel?.priorVersion?.effectOfDisability | boolToYesNo"
                [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
                changedPrefixText="Proposed: "
              >
              </app-compare-amendment-output>
            </span>
          </div>
          <div *ngIf="formGroup.get('effectOfDisability').value === false">
            <label
              class="vertical-label display-block position-fix--label"
              [ngClass]="{
                asterisk_input: formGroup.get('describeHowParticipate').hasError('required')
              }"
            >
              Please describe
            </label>
            <ng-container *ngIf="!isAmendment || (hasOpenAmendment && amendment.amendingPlaafp)">
              <app-textarea
                [hideAsterisk]="true"
                formControlName="describeHowParticipate"
                [rows]="4"
                [isBankableField]="true"
                [bankableFieldId]="bankableFieldIds.iepPlaafp.describeHowParticipate"
                [bankableFieldChildFname]="firstName"
                [maxLength]="stringSizes.extraLarge"
                attr.aria-required="{{ formGroup.get('describeHowParticipate').hasError('required') ? 'true' : 'false' }}"
              ></app-textarea>
              <span *ngIf="hasOpenAmendment">
                <app-compare-amendment-output
                  [amendmentId]="amendmentId"
                  [amendmentFinalized]="amendmentIsFinalized"
                  [amendmentDate]="lastFinalizedDate"
                  [priorVersionId]="plaafpModel?.priorVersionId"
                  [changedVersionText]="formGroup.get('describeHowParticipate').value"
                  [currentVersionText]="plaafpModel?.priorVersion?.describeHowParticipate"
                  [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
                  changedPrefixText="Proposed: "
                ></app-compare-amendment-output>
              </span>
            </ng-container>
            <div class="mt-3" *ngIf="isAmendment && (!hasOpenAmendment || !amendment.amendingPlaafp)">
              <app-compare-amendment-output
                [amendmentId]="amendmentId"
                [amendmentFinalized]="amendmentIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [priorVersionId]="plaafpModel?.priorVersionId"
                [changedVersionText]="formGroup.get('describeHowParticipate').value"
                [currentVersionText]="plaafpModel?.priorVersion?.describeHowParticipate"
                [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
                changedPrefixText="Proposed: "
              ></app-compare-amendment-output>
            </div>
          </div>
        </div>
      </mat-card>
    </section>

    <section class="card-spacing-top">
      <h3 class="mb-2 mt-0">Needs and Concerns</h3>
      <mat-card class="card--shadow mb-0">
        <div class="display-flex align-items-center justify-content-between flex-wrap mb-2">
          <app-help-directional-text>
            <p>
              Identify and
              <a
                (click)="onOpenHelp($event, iepPlaafpHelp.Tag)"
                class="text-underline text-lower"
                tabindex="0"
                role="link"
                attr.aria-label="{{ iepPlaafpHelp.Tag }}"
                >{{ iepPlaafpHelp.Tag }}</a
              >
              needs and concerns determined by the IEP team
            </p>
          </app-help-directional-text>
          <button
            mat-raised-button
            aria-label="Add Icon"
            color="primary"
            class="py-1 ml-2"
            title="Add New"
            *ngIf="isAmendment && hasOpenAmendment && amendment.amendingPlaafp"
            (click)="isEditingConcern = true"
            tabindex="0"
          >
            Add New
          </button>
        </div>
        <ng-container *ngIf="isEditingConcern || !isAmendment">
          <form [formGroup]="concernFormGroup" #concernForm="ngForm">
            <div class="row">
              <div class="col-xl-8 col-md-12">
                <div class="display-flex flex-column">
                  <app-textarea
                    formControlName="comments"
                    [rows]="4"
                    [useInputGroup]="true"
                    label="Needs and Concerns"
                    [isBankableField]="true"
                    [bankableFieldId]="bankableFieldIds.iepPlaafp.concernsComments"
                    [bankableFieldChildFname]="firstName"
                    [maxLength]="stringSizes.extraLarge"
                    [hideAsterisk]="concerns.length > 0"
                  >
                    <ng-container append>
                      <app-sentence-starters-button
                        [sentenceStarters]="concernsSentenceStarters"
                        [isSuffix]="true"
                        (selectedQuestion)="onSelectedQuestion($event, concernFormGroup.get('comments'))"
                      ></app-sentence-starters-button>
                    </ng-container>
                  </app-textarea>
                  <div class="align-self-start" *ngIf="isSecondaryTransition">
                    <div class="display-flex align-items-center justify-content-between flex-wrap">
                      <div class="display-flex">
                        <mat-button-toggle-group [multiple]="true" aria-labelledby="Select an option">
                          <mat-button-toggle
                            (change)="toggleEnvironment($event, concernFormGroup.get('living'))"
                            [checked]="concernFormGroup.get('living')?.value"
                            value="living"
                            aria-labelledby="Living"
                          >
                            Living
                          </mat-button-toggle>
                          <mat-button-toggle
                            (change)="toggleEnvironment($event, concernFormGroup.get('learning'))"
                            [checked]="concernFormGroup.get('learning')?.value"
                            value="learning"
                            aria-labelledby="Learning"
                          >
                            Learning
                          </mat-button-toggle>
                          <mat-button-toggle
                            (change)="toggleEnvironment($event, concernFormGroup.get('working'))"
                            [checked]="concernFormGroup.get('working')?.value"
                            value="working"
                            aria-labelledby="Working"
                          >
                            Working
                          </mat-button-toggle>
                        </mat-button-toggle-group>
                      </div>
                    </div>
                  </div>
                  <div class="align-self-end tags__bar">
                    <div class="display-flex align-items-center">
                      <app-tag-bar formControlName="tags"></app-tag-bar>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-md-12">
                <div class="display-flex flex-column">
                  <app-text-field
                    formControlName="dataSource"
                    label="Data Source"
                    [isBankableField]="true"
                    [bankableFieldId]="bankableFieldIds.iepPlaafp.concernsDataSource"
                    [hideAsterisk]="concerns.length > 0"
                  ></app-text-field>
                  <div class="align-self-end">
                    <button
                      mat-raised-button
                      aria-label="Delete Icon"
                      class="mr-1"
                      *ngIf="isAmendment"
                      (click)="onCancel('Concern')"
                      color="warn"
                      title="Cancel"
                      tabindex="0"
                    >
                      Cancel
                      <mat-icon>close</mat-icon>
                    </button>
                    <button
                      mat-raised-button
                      aria-label="Save Need/Concern"
                      color="primary"
                      [disabled]="concernFormGroup.invalid"
                      [disableAfterBusy]="concernFormGroup.invalid"
                      [isBusy]="activeCall"
                      (click)="onSubmit('Concern', concernFormGroup, concernForm)"
                      tabindex="0"
                    >
                      Save Need/Concern
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ng-container>
        <div class="row mt-3" *ngIf="concernDataSource.data.length > 0">
          <div class="col-md-12">
            <div class="table-overflow app-table">
              <table mat-table [dataSource]="concernDataSource" matSort class="w-100">
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef [hidden]="isDetailsView && !isAmendment" class="mat-column-actions--more">
                    Actions
                  </th>
                  <td mat-cell *matCellDef="let concern" [hidden]="isDetailsView && !isAmendment" class="mat-column-actions--more">
                    <ng-container *ngIf="!isAmendment">
                      <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewMore('Concern', concern)" tabindex="0">
                        <mat-icon>remove_red_eye</mat-icon>
                      </button>
                      <button aria-label="Edit Icon" mat-icon-button color="primary" (click)="onEdit('Concern', concern)" tabindex="0">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button aria-label="Delete Icon" mat-icon-button (click)="onDelete('Concern', concern)" color="warn" tabindex="0">
                        <mat-icon>close</mat-icon>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="isAmendment && hasOpenAmendment && amendment.amendingPlaafp">
                      <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewMore('Concern', concern)" tabindex="0">
                        <mat-icon>remove_red_eye</mat-icon>
                      </button>
                      <button aria-label="Edit Icon" mat-icon-button color="primary" (click)="onEdit('Concern', concern)" tabindex="0">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button
                        *ngIf="canDelete(concernDataSource.data.length, concern)"
                        mat-icon-button
                        aria-label="Delete Icon"
                        (click)="onDelete('Concern', concern)"
                        color="warn"
                        tabindex="0"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                      <button
                        *ngIf="canUndoDelete(concern)"
                        mat-icon-button
                        aria-label="Clear Icon"
                        (click)="undoDelete('Concern', concern)"
                        color="warn"
                        tabindex="0"
                      >
                        <i class="fas fa-undo" aria-label="Clear Icon"></i>
                      </button>
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="comments">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Needs and Concerns</th>
                  <td mat-cell *matCellDef="let concern">
                    <app-compare-amendment-output
                      [amendmentId]="amendmentId"
                      [amendmentFinalized]="amendmentIsFinalized"
                      [amendmentDate]="lastFinalizedDate"
                      [amendmentEndReasonValue]="concern.removedByAmendment"
                      [priorVersionId]="concern.priorVersionId"
                      [changedVersionText]="concern.comments"
                      [tableData]="true"
                      [currentVersionText]="getCommentPriorVersion('Concern', concern)"
                      [changedPrefixText]="concern?.priorVersionId ? 'Proposed: ' : ''"
                    ></app-compare-amendment-output>
                  </td>
                </ng-container>
                <ng-container matColumnDef="dataSource">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Source</th>
                  <td mat-cell *matCellDef="let concern">
                    <app-compare-amendment-output
                      [amendmentId]="amendmentId"
                      [amendmentFinalized]="amendmentIsFinalized"
                      [amendmentDate]="lastFinalizedDate"
                      [amendmentEndReasonValue]="concern.removedByAmendment"
                      [priorVersionId]="concern.priorVersionId"
                      [changedVersionText]="concern.dataSource"
                      [tableData]="true"
                      [currentVersionText]="getDatasourcePriorVersion('Concern', concern)"
                      [changedPrefixText]="concern?.priorVersionId ? 'Proposed: ' : ''"
                    ></app-compare-amendment-output>
                  </td>
                </ng-container>
                <ng-container matColumnDef="tags">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header [hidden]="isDetailsView && !isAmendment">Tags</th>
                  <td mat-cell *matCellDef="let concern" [hidden]="isDetailsView && !isAmendment">
                    <ng-container *ngFor="let tag of concern.tags">
                      <i *ngIf="tag === '0'" class="fas fa-bullseye tag__icon text-success mr-1" aria-label="Goal Tagging Icon"></i>
                      <i
                        *ngIf="tag === '1'"
                        class="fas fa-chalkboard-teacher tag__icon text-success mr-1"
                        aria-label="Service Tagging Icon"
                      ></i>
                      <i *ngIf="tag === '2'" class="fas fa-handshake tag__icon text-success mr-1"></i>
                      <i *ngIf="tag === '3'" class="fas fa-print tag__icon text-success mr-1"></i>
                    </ng-container>
                  </td>
                </ng-container>
                <ng-container matColumnDef="llwTags" *ngIf="isSecondaryTransition">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>L/L/W Tags</th>
                  <td mat-cell *matCellDef="let concern">
                    <span class="mr-2" *ngIf="concern.living">Living</span>
                    <span class="mr-2" *ngIf="concern.learning">Learning</span>
                    <span class="mr-2" *ngIf="concern.working">Working</span>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedConcernColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedConcernColumns"></tr>
              </table>
              <mat-paginator [dataSource]="concernDataSource"></mat-paginator>
            </div>
          </div>
        </div>
      </mat-card>
    </section>

    <section class="card-spacing-top">
      <h3 class="mb-2 mt-0">Other Essential Information</h3>
      <mat-card class="card--shadow mb-0 pt-2 pb-1">
        <ng-container *ngIf="!isAmendment || (hasOpenAmendment && amendment.amendingPlaafp)">
          <label class="vertical-label display-block pb-0" for="otherInformation">
            <a
              class="text-underline"
              (click)="onOpenHelp($event, iepPlaafpHelp.OtherInfo)"
              tabindex="0"
              role="link"
              attr.aria-label="{{ iepPlaafpHelp.OtherInfo }}"
              >{{ iepPlaafpHelp.OtherInfo }}</a
            >
            essential for the development of this IEP
          </label>
          <app-textarea
            label=""
            id="otherInformation"
            formControlName="otherInformation"
            [isBankableField]="true"
            [bankableFieldId]="bankableFieldIds.iepPlaafp.otherInformation"
            [bankableFieldChildFname]="firstName"
            [rows]="4"
          ></app-textarea>
          <span *ngIf="hasOpenAmendment">
            <app-compare-amendment-output
              [amendmentId]="amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('otherInformation').value"
              [currentVersionText]="plaafpModel?.priorVersion?.otherInformation"
              [currentPrefixText]="plaafpModel?.priorVersion?.otherInformation ? 'Current: ' : 'Proposed: '"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>
          </span>
        </ng-container>
        <ng-container *ngIf="isAmendment && (!hasOpenAmendment || !amendment.amendingPlaafp)">
          <label class="vertical-label display-block position-fix--label">
            Other information essential for the development of this IEP
          </label>
          <div class="mt-3">
            <app-compare-amendment-output
              [amendmentId]="amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('otherInformation').value"
              [currentVersionText]="plaafpModel?.priorVersion?.otherInformation"
              [currentPrefixText]="plaafpModel?.priorVersion?.otherInformation ? 'Current: ' : 'Proposed: '"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>
          </div>
        </ng-container>
      </mat-card>
    </section>

    <ng-container *ngIf="isSecondaryTransition && !learnerIsPk">
      <h3 class="mb-2 card-spacing-top">Course of Study</h3>
      <mat-card class="pb-2 card-spacing-top card-spacing-bottom card--shadow">
        <app-pse-activities
          *ngIf="secondaryTransitionId"
          [iepId]="iepId"
          [plaafpId]="plaafpId"
          [secondaryTransitionId]="secondaryTransitionId"
          [learnerId]="learnerId"
          [learnerFirstName]="firstName"
          [isFirstSecondaryTransition]="caseSummary.isFirstSecondaryTransition"
          [readonly]="readonly"
        ></app-pse-activities>
      </mat-card>
    </ng-container>

    <section *ngIf="isSecondaryTransition && plaafpId" class="card-spacing-top">
      <h3 class="mb-2">Transfer of Rights</h3>
      <mat-card class="pb-2 card--shadow">
        <app-transfer-of-rights
          [iepId]="iepId"
          [plaafpId]="plaafpId"
          [secondaryTransitionId]="secondaryTransitionId"
          [caseSummary]="caseSummary"
          [readonly]="readonly"
          [amendments]="amendments"
        ></app-transfer-of-rights>
      </mat-card>
    </section>
  </form>
</main>

<div *ngIf="!isAmendment" class="page-bottom-leeway"></div>
