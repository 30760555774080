<ng-container *ngIf="!goal.isObjective || (goal.isObjective && isActive)">
  <form [formGroup]="formGroup">
    <ng-container *ngIf="goal.primaryMeasurement && !goal.secondaryMeasurement">
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-date-picker label="Date" controlName="date" [min]="minDate" [max]="today"></app-date-picker>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-8">
          <div class="display-flex align-items-baseline">
            <app-number-field
              label="Current Value"
              formControlName="primaryValue"
              [min]="0"
              [idPrefix]="goal.primaryMeasurement?.iepGoalQuantifiableMeasurementId"
            ></app-number-field>
            <p class="my-0 mr-2">Target Value: {{ goal.primaryMeasurement?.targetValue }}</p>
            <p class="my-0 mr-2">Baseline: {{ goal.primaryMeasurement?.baseline }}</p>
          </div>
        </div>
        <div class="col-md-12">
          <app-textarea
            label="Monitoring Notes"
            formControlName="primaryNote"
            [maxLength]="10000"
            [idPrefix]="goal.primaryMeasurement?.iepGoalQuantifiableMeasurementId"
          ></app-textarea>
        </div>
      </div>
      <mat-accordion
        class="accordion accordion--short"
        multi
        *ngIf="goal.primaryMeasurement?.scaleRatings.length > 0 || goal.primaryMeasurement?.milestones?.length > 0"
      >
        <mat-expansion-panel class="mat-elevation-z0 mt-2">
          <mat-expansion-panel-header>
            <mat-panel-title> <h3 class="my-0 text-normal">Scale Rating / Milestones</h3></mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col" *ngIf="goal.primaryMeasurement?.scaleRatings.length > 0">
              <h4>Scale Rating</h4>
              <table mat-table class="w-100" [dataSource]="goal.primaryMeasurement?.scaleRatings">
                <ng-container matColumnDef="rating">
                  <th mat-header-cell *matHeaderCellDef>Rating</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.rating }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef>Description</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.description }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsScaleRatings"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsScaleRatings"></tr>
              </table>
            </div>
            <div class="col" *ngIf="goal.primaryMeasurement?.milestones?.length > 0">
              <h4>Milestones</h4>
              <table mat-table class="w-100" [dataSource]="goal.primaryMeasurement?.milestones">
                <ng-container matColumnDef="endDate">
                  <th mat-header-cell *matHeaderCellDef>End Date</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.endDate | dateFormat }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="target">
                  <th mat-header-cell *matHeaderCellDef>Target</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.target }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsMilestones"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsMilestones"></tr>
              </table>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>

    <ng-container *ngIf="goal.secondaryMeasurement && goal.secondaryMeasurement">
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-date-picker label="Date" controlName="date" [min]="minDate" [max]="today"></app-date-picker>
        </div>
      </div>
      <h3 class="my-0">Unit of Measurement: {{ goal.primaryMeasurement?.unitOfMeasurement }}</h3>
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-8">
          <div class="display-flex align-items-baseline">
            <p class="my-0 mr-2">Target Value: {{ goal.primaryMeasurement?.targetValue }}</p>
            <p class="my-0 mr-2">Baseline: {{ goal.primaryMeasurement?.baseline }}</p>
            <app-number-field
              label="Value"
              formControlName="primaryValue"
              [idPrefix]="goal.primaryMeasurement?.iepGoalQuantifiableMeasurementId"
            ></app-number-field>
          </div>
        </div>
        <div class="col-md-12">
          <app-textarea
            label="Monitoring Notes"
            formControlName="primaryNote"
            [maxLength]="10000"
            [idPrefix]="goal.primaryMeasurement.iepGoalQuantifiableMeasurementId"
          ></app-textarea>
        </div>
      </div>
      <mat-accordion
        class="accordion accordion--short"
        multi
        *ngIf="goal.primaryMeasurement.scaleRatings.length > 0 || goal.primaryMeasurement.milestones.length > 0"
      >
        <mat-expansion-panel class="mat-elevation-z0 mt-2">
          <mat-expansion-panel-header>
            <mat-panel-title> <h3 class="my-0 text-normal">Scale Rating / Milestones</h3></mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col" *ngIf="goal.primaryMeasurement.scaleRatings.length > 0">
              <h4>Scale Rating</h4>
              <table mat-table class="w-100" [dataSource]="goal.primaryMeasurement.scaleRatings">
                <ng-container matColumnDef="rating">
                  <th mat-header-cell *matHeaderCellDef>Rating</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.rating }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef>Description</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.description }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsScaleRatings"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsScaleRatings"></tr>
              </table>
            </div>
            <div class="col" *ngIf="goal.primaryMeasurement.milestones.length > 0">
              <h4>Milestones</h4>
              <table mat-table class="w-100" [dataSource]="goal.primaryMeasurement.milestones">
                <ng-container matColumnDef="endDate">
                  <th mat-header-cell *matHeaderCellDef>End Date</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.endDate | dateFormat }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="target">
                  <th mat-header-cell *matHeaderCellDef>Target</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.target }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsMilestones"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsMilestones"></tr>
              </table>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <h3 class="card-spacing-top mb-0">
        Unit of Measurement:
        {{ goal.secondaryMeasurement.unitOfMeasurement }}
      </h3>

      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-8">
          <div class="display-flex align-items-baseline">
            <p class="my-0 mr-2">Target Value: {{ goal.secondaryMeasurement.targetValue }}</p>
            <p class="my-0 mr-2">Baseline: {{ goal.secondaryMeasurement?.baseline }}</p>
            <app-number-field
              label="Value"
              formControlName="secondaryValue"
              [idPrefix]="goal.secondaryMeasurement.iepGoalQuantifiableMeasurementId"
            ></app-number-field>
          </div>
        </div>
        <div class="col-md-12">
          <app-textarea
            label="Monitoring Notes"
            formControlName="secondaryNote"
            [maxLength]="10000"
            [idPrefix]="goal.secondaryMeasurement.iepGoalQuantifiableMeasurementId"
          ></app-textarea>
        </div>
      </div>
      <mat-accordion
        class="accordion accordion--short"
        multi
        *ngIf="goal.secondaryMeasurement.scaleRatings.length > 0 || goal.secondaryMeasurement.milestones.length > 0"
      >
        <mat-expansion-panel class="mat-elevation-z0 mt-2">
          <mat-expansion-panel-header>
            <mat-panel-title> <h3 class="my-0 text-normal">Scale Rating / Milestones</h3></mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col" *ngIf="goal.secondaryMeasurement.scaleRatings.length > 0">
              <h4>Scale Rating</h4>
              <table mat-table class="w-100" [dataSource]="goal.secondaryMeasurement.scaleRatings">
                <ng-container matColumnDef="rating">
                  <th mat-header-cell *matHeaderCellDef>Rating</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.rating }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef>Description</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.description }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsScaleRatings"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsScaleRatings"></tr>
              </table>
            </div>
            <div class="col" *ngIf="goal.secondaryMeasurement.milestones.length > 0">
              <h4>Milestones</h4>
              <table mat-table class="w-100" [dataSource]="goal.secondaryMeasurement.milestones">
                <ng-container matColumnDef="endDate">
                  <th mat-header-cell *matHeaderCellDef>End Date</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.endDate | dateFormat }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="target">
                  <th mat-header-cell *matHeaderCellDef>Target</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.target }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsMilestones"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsMilestones"></tr>
              </table>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>

    <div class="row">
      <div class="col-md-12 col-lg-6 col-xl-4">
        <app-autocomplete
          [showAsterisk]="formGroup.get('phaseLineNote').hasError('required')"
          label="Phase Line"
          formControlName="phaseLineNote"
          [options]="phaseLineOptions"
        ></app-autocomplete>
      </div>
      <div class="col-md-12">
        <div *ngIf="isOtherPhaseLineSelected">
          <app-textarea label="Other" formControlName="phaseLineNoteOther" [maxLength]="10000"></app-textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-6 col-xl-4">
        <app-autocomplete
          [showAsterisk]="formGroup.get('commentNote').hasError('required')"
          label="Comment"
          formControlName="commentNote"
          [options]="commentOptions"
        ></app-autocomplete>
      </div>
      <div class="col-md-12">
        <div *ngIf="isOtherCommentSelected">
          <app-textarea label="Other" formControlName="commentNoteOther" [maxLength]="10000"></app-textarea>
        </div>
      </div>
    </div>

    <ng-container>
      <div class="display-flex justify-content-end mb-2">
        <button
          mat-raised-button
          aria-label="Complete"
          color="primary"
          (click)="onSubmit()"
          [disabled]="formGroup.invalid || goal.status === milestoneStatus.met || goal.status === milestoneStatus.inactive"
          tabindex="0"
        >
          Complete
        </button>
      </div>
    </ng-container>
  </form>
</ng-container>

<ng-container *ngIf="goal.isObjective" class="mt-2">
  <form [formGroup]="milestoneStatusFormGroup">
    <app-select
      formControlName="status"
      label="Status"
      [options]="statusOptions"
      (changeEvent)="statusChangeEvent.emit($event.value)"
    ></app-select>
  </form>
</ng-container>
