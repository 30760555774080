import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-evaluation-assessment-table',
  templateUrl: './evaluation-assessment-table.component.html',
  styleUrls: ['./evaluation-assessment-table.component.scss'],
})
export class EvaluationAssessmentTableComponent implements OnInit {
  @Input() teamMembers: any;

  evaluationDataSource: MatTableDataSource<any>;
  evaluationDisplayedColumns: string[] = ['fullName', 'jobTitle', 'email', 'aea', 'district', 'primaryOffice'];

  constructor() {}

  ngOnInit(): void {
    this.evaluationDataSource = new MatTableDataSource(this.teamMembers);
  }
}
