import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, forkJoin } from 'rxjs';
import { EcoRatingType } from 'src/app/evaluation/evaluation/steps/eco-wizard/eco-rating';
import { EcoRatingService } from 'src/app/evaluation/evaluation/steps/eco-wizard/eco-rating.service';
import { EcoArea, EcoAreaType } from 'src/app/evaluation/models/evaluation';
import { IfspModification } from 'src/app/ifsp/models/ifsp-modification';
import { Plod } from 'src/app/ifsp/models/plod';
import { IfspModificationService } from 'src/app/ifsp/services/ifsp-modification.service';
import { IfspService } from 'src/app/ifsp/services/ifsp.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { EcoAreaService } from 'src/app/shared/services/eco-areas/eco-area.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { CaseUserSummary } from '../../../../shared/models/case';
import { CaseService } from '../../../../shared/services/case/case.service';

@Component({
  selector: 'app-plod-output',
  templateUrl: './plod-output.component.html',
  styleUrls: ['./plod-output.component.scss'],
})
export class PlodOutputComponent implements OnInit, AfterViewInit {
  shortDateFormat = shortDateFormat;
  isPreview: boolean;
  caseId: string;
  learnerId: string;
  caseOwner: CaseUserSummary;
  private ifspId: string;
  modification: IfspModification = null;
  isPopup = true;
  ecoAreas: EcoArea[] = [];
  formGroup = new FormGroup({
    ecoAreas: this.fb.array([], {}),
  });
  plodModel: Plod;

  learnersFirstName: string;
  constructor(
    public caseService: CaseService,
    private readonly ifspService: IfspService,
    private readonly ifspModificationService: IfspModificationService,
    private readonly ecoAreaService: EcoAreaService,
    private readonly ecoRatingService: EcoRatingService,
    private readonly route: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  async ngOnInit(): Promise<void> {
    this.caseId = this.route.parent.snapshot.params.caseId;
    this.ifspId = this.route.parent.snapshot.params.ifspId;
    if (!this.ifspId) {
      this.ifspId = this.route.snapshot.paramMap.get('ifspId');
    }
    if (!this.caseId) {
      this.caseId = this.route.snapshot.paramMap.get('caseId');
    }
    const result = await this.caseService.getCaseSummary(this.caseId).toPromise();
    this.caseOwner = result.caseUsers.find((x) => x.isOwner);
    this.learnerId = result.learnerId;
    await this.getInitData();
    setTimeout(() => window.print(), 0);
  }

  ngAfterViewInit(): void {}

  get ecoAreaFormGroups(): FormGroup[] {
    return (this.formGroup.get('ecoAreas') as FormArray).controls as FormGroup[];
  }

  get ecoControls(): FormArray {
    return this.formGroup.get('ecoAreas') as FormArray;
  }

  onClose() {
    window.close();
  }

  async getInitData(): Promise<void> {
    const [ifspModifications, plodResult, evaluationResult, ecoAreaResult] = await forkJoin([
      this.ifspModificationService.getModificationsByIfspId(this.ifspId),
      this.ifspService.getPlod(this.ifspId, this.modification ? this.modification?.id : ''),
      this.ifspService.getMostRecentEvaluationByIfspId(this.ifspId),
      this.ecoAreaService.get(),
    ]).toPromise();
    const modification = ifspModifications.find((x) => !x.finalizeDate);
    if (modification) {
      this.modification = modification;
    }
    this.plodModel = plodResult;
    this.ecoAreas = ecoAreaResult;
    this.learnersFirstName = evaluationResult.learnersFirstName;
    this.formGroup.controls.ecoAreas = this.fb.array([], {});
    await Promise.all(
      this.ecoAreas.map(async (ecoArea) => {
        const result = await this.ecoRatingService.get(this.ifspId, ecoArea.id, EcoRatingType.Ifsp, this.modification?.id).toPromise();

        const ecoRatingFormGroup = this.ecoRatingService.createFormGroup(result, ecoArea, plodResult, this.learnersFirstName);

        (this.formGroup.get('ecoAreas') as FormArray).insert(ecoArea.order, ecoRatingFormGroup);
      })
    );
  }
}
