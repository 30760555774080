<mat-accordion class="accordion accordion--short" multi>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Transition Assessments and Postsecondary Expectations</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <!-- BODY -->
    <app-st-assessment-expectation></app-st-assessment-expectation>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Strengths, Skills and Preferences</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <!-- BODY -->
    <app-st-strength-skill-preference></app-st-strength-skill-preference>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Special Factors</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <!-- BODY -->
    <app-st-special-factors></app-st-special-factors>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Effect of the Disability</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <!-- BODY -->
    <app-st-effect-disability></app-st-effect-disability>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Course of Study</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <!-- BODY -->
    <app-st-course-study></app-st-course-study>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Areas of Need and Other Information</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <!-- BODY -->
    <app-st-area-of-need></app-st-area-of-need>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Transfer of Rights</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <!-- BODY -->
    <app-st-transfer-of-rights></app-st-transfer-of-rights>
  </mat-expansion-panel>
</mat-accordion>

<div class="page-bottom-leeway"></div>
