import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';
import { CaseSummary } from '../../shared/models/case';
import { LearnerService } from '../../shared/services/learner/learner.service';

@Component({
  selector: 'app-family-contact-consent',
  templateUrl: './family-contact-consent.component.html',
  styleUrls: ['./family-contact-consent.component.scss'],
})
export class FamilyContactConsentComponent implements OnInit {
  learnerId = this.route.parent.snapshot.paramMap.get('learnerId');
  permissions = AppPermissions;
  cases: CaseSummary[];
  me = this.authService.user.id;

  get learnerSummary$() {
    return this.learnerService.learnerSummaryChange$;
  }

  constructor(private route: ActivatedRoute, public authService: AuthService, private readonly learnerService: LearnerService) {}

  async ngOnInit(): Promise<void> {
    this.cases = await this.learnerService.getCases(this.learnerId).toPromise();
  }
}
