import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { getMinDate, insertProgressMonitorData } from 'src/app/monitoring-progress/helpers/progressMonitorHelpers';
import { ProgressMonitorComment } from 'src/app/monitoring-progress/models/comment';
import {
  CriterionProgressMonitor,
  OutcomeProgressMonitor,
  ProgressMonitorPlanStatus,
} from 'src/app/monitoring-progress/models/outcome-progress-monitor';
import { ProgressMonitorItem } from 'src/app/monitoring-progress/models/progress-monitor-item';
import { ProgressMonitorCService } from 'src/app/monitoring-progress/services/progress-monitor-c.service';
import { ProgressMonitorCommentService } from 'src/app/monitoring-progress/services/progress-monitor-comment.service';
import { ProgressMonitorLookupService } from 'src/app/monitoring-progress/services/progress-monitor-lookup.service';
import { ProgressMonitorPhaseLineService } from 'src/app/monitoring-progress/services/progress-monitor-phase-line.service';
import { CaseSummary } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';

@Component({
  selector: 'app-enter-progress-part-c',
  templateUrl: './enter-progress-part-c.component.html',
  styleUrls: ['./enter-progress-part-c.component.scss'],
})
export class EnterProgressPartCComponent implements OnInit {
  @Input() caseSummary: CaseSummary;
  @Input() readOnly = false;
  @Input() showPlanStatus = true;
  @Input() enableForms: boolean;
  outcomes: Array<OutcomeProgressMonitor> = [];
  filteredOutcomes: Array<OutcomeProgressMonitor> = [];
  // minDate = dayjs().subtract(30, 'day').toDate();
  minDate: Date;

  // Use this to store the outcomes before adding in various stuff like
  // the data property (which causes a circular dependency)
  jsonOutcomes: string;

  displayedColumnsMilestones = ['completionDate', 'description', 'value'];
  displayedColumnsScaleRatings = ['rating', 'description'];

  chart = {
    data: [],
    labels: [''],
    colors: [
      {
        backgroundColor: ['rgba(38, 63, 140, 0.2)', 'rgba(245, 214, 142, 1)', 'rgba(41, 99, 28, 0.3)'],
      },
    ],
    legend: false,
  };

  // #region Filter Related Variables
  ifspOptions: KeyValuePair[] = [];
  serviceOptions: KeyValuePair[] = [];
  statusOptions: KeyValuePair[] = [
    new KeyValuePair('all', 'All Outcomes'),
    new KeyValuePair('complete', 'Completed Outcomes'),
    new KeyValuePair('active', 'Active Outcomes'),
  ];
  providerOptions: KeyValuePair[] = [];
  transitionOptions: KeyValuePair[] = [];
  searchString: string;

  // #endregion

  constructor(
    private progressMonitorService: ProgressMonitorCService,
    private phaseLineService: ProgressMonitorPhaseLineService,
    private commentService: ProgressMonitorCommentService,
    private progressMonitorLookupService: ProgressMonitorLookupService
  ) {}

  ngOnInit(): void {
    if (this.caseSummary && this.caseSummary.activeIfspStartDate) {
      this.minDate = getMinDate(dayjs(this.caseSummary.activeIfspStartDate));
    }
    this.load();
  }

  criterionComplete(criterion: CriterionProgressMonitor) {
    return criterion.progressData.some((x) => x.completed);
  }

  // #region Progress Data Item

  onAdd(progressMonitorItem: ProgressMonitorItem, criterion: CriterionProgressMonitor) {
    this.progressMonitorService.add(this.caseSummary.learnerId, progressMonitorItem).subscribe((res) => {
      progressMonitorItem.id = res.id;
      progressMonitorItem.date = res.date;
      this.handleProgressMonitorItemAdd(progressMonitorItem, criterion);
    });
  }

  private handleProgressMonitorItemAdd(progressMonitorItem: ProgressMonitorItem, criterion: CriterionProgressMonitor) {
    insertProgressMonitorData(criterion.progressData, progressMonitorItem);
    criterion.data.data = criterion.progressData;
    this.progressMonitorService.outcomeCriterionChanged.next(criterion.outcomeId);
  }

  // #endregion

  // #region Plan Status

  handlePlanStatusAdd(planStatus: ProgressMonitorPlanStatus) {
    planStatus.outcomes.forEach((outcomeId) => {
      const outcome = this.outcomes.find((x) => x.id === outcomeId);
      if (outcome) {
        insertProgressMonitorData(outcome.planStatuses, planStatus);
        outcome.data.data = outcome.planStatuses;
      }
    });
  }

  handlePlanStatusUpdate(planStatus: ProgressMonitorPlanStatus) {
    planStatus.outcomes.forEach((outcomeId) => {
      const outcome = this.outcomes.find((x) => x.id === outcomeId);
      const index = outcome.planStatuses.findIndex((x) => x.id === planStatus.id);
      if (index > -1) {
        outcome.planStatuses[index] = planStatus;
        outcome.data.data = outcome.planStatuses;
      }
    });
  }

  handlePlanStatusDelete(planStatus: ProgressMonitorPlanStatus) {
    planStatus.outcomes.forEach((outcomeId) => {
      const outcome = this.outcomes.find((x) => x.id === outcomeId);
      const index = outcome.planStatuses.findIndex((x) => x.id === planStatus.id);
      if (index > -1) {
        outcome.planStatuses.splice(index, 1);
        outcome.data.data = outcome.planStatuses;
      }
    });
  }

  // #endregion

  // #region Phase Line

  onPhaseLineAdd(phaseLine, criterion) {
    this.phaseLineService.create(this.caseSummary.learnerId, criterion.id, phaseLine).subscribe((res) => {
      phaseLine.id = res;
      phaseLine.date = res.date;
      this.handlePhaseLineAdd(phaseLine, criterion);
    });
  }

  private handlePhaseLineAdd(phaseLine, criterion) {
    insertProgressMonitorData(criterion.phaseLines, phaseLine);
    criterion.phaseLineData.data = criterion.phaseLines;
  }

  // #endregion

  // #region Graph Note

  onCommentAdd(comment, criterion) {
    this.commentService.create(this.caseSummary.learnerId, criterion.id, comment).subscribe((res) => {
      comment.id = res;
      comment.date = res.date;
      this.handleCommentAdd(comment, criterion);
    });
  }

  private handleCommentAdd(comment: ProgressMonitorComment, criterion: CriterionProgressMonitor) {
    insertProgressMonitorData(criterion.comments, comment);
    criterion.commentData.data = criterion.comments;
  }

  // #endregion

  // #region Private Methods

  private load() {
    this.progressMonitorService.get(this.caseSummary.learnerId).subscribe(
      (outcomes) => {
        this.outcomes = outcomes;
        this.filteredOutcomes = outcomes;
        this.outcomes.forEach((outcome) => {
          outcome.data = this.getPlanStatusDataSource(outcome);
          outcome.criteria.forEach((criterion) => {
            criterion.data = this.getProgressDataSource(criterion);
            criterion.phaseLineData = this.getPhaseLineDataSource(criterion);
            criterion.commentData = this.getCommentDataSource(criterion);
          });
          this.determineOutcomeCriteriaComplete(outcome.id);
        });
        this.progressMonitorService.outcomeCriterionChanged.subscribe((outcomeId) => {
          this.determineOutcomeCriteriaComplete(outcomeId);
        });
      },
      (error) => console.log(error)
    );
  }

  private determineOutcomeCriteriaComplete(outcomeId: string) {
    const outcome = this.outcomes.find((x) => x.id === outcomeId);
    if (outcome) {
      outcome.allCriteriaComplete = outcome.criteria.every((criterion) => criterion.progressData.some((item) => item.completed));
    }
  }

  private getProgressDataSource(criterion: CriterionProgressMonitor) {
    const progressData = criterion.progressData;
    return new MatTableDataSource(progressData);
  }

  private getPhaseLineDataSource(criterion: CriterionProgressMonitor) {
    const phaseLines = criterion.phaseLines;
    return new MatTableDataSource(phaseLines);
  }

  private getCommentDataSource(criterion: CriterionProgressMonitor) {
    const comments = criterion.comments;
    return new MatTableDataSource(comments);
  }

  private getPlanStatusDataSource(outcome: OutcomeProgressMonitor) {
    const planStatuses = outcome.planStatuses;
    return new MatTableDataSource(planStatuses);
  }
  // #endregion
}
