<section class="card-spacing-top">
  <h3 class="mb-2 mt-0">Team Eligibility Decision</h3>
  <mat-card [formGroup]="formGroup">
    <hr class="input__divider" />

    <div class="display-flex align-items-center">
      <h3 class="mr-4 my-0">Child is...</h3>
      <fieldset>
        <app-radio-group
          formControlName="eligibilitySubmittedOnEvaluation"
          [options]="eligibilityDecisionOptions"
          [useUndo]="!disableUndoButton"
        ></app-radio-group>
      </fieldset>

      <ng-container *ngIf="!currentEvaluation.isEligibilityDecisionFinalized">
        <button
          [disabled]="
            formGroup.disabled ||
            formGroup.invalid ||
            formGroup.get('eligibilitySubmittedOnEvaluation').value === '' ||
            formGroup.get('eligibilitySubmittedOnEvaluation').value === null ||
            formGroup.get('eligibilitySubmittedOnEvaluation').value === undefined ||
            (rawEligibilityBasedOn === 'DocumentedDelay' && documentedDelayItems?.length === 0)
          "
          (click)="finalizeEligibility()"
          color="primary"
          mat-raised-button
          aria-label="Finalize Eligibility"
          class="ml-3"
          tabindex="0"
          [disableAfterBusy]="
            formGroup.disabled ||
            formGroup.invalid ||
            formGroup.get('eligibilitySubmittedOnEvaluation').value === '' ||
            formGroup.get('eligibilitySubmittedOnEvaluation').value === null ||
            formGroup.get('eligibilitySubmittedOnEvaluation').value === undefined ||
            (rawEligibilityBasedOn === 'DocumentedDelay' && documentedDelayItems?.length === 0)
          "
          [isBusy]="activeCall"
        >
          Finalize Eligibility
        </button>
        <button (click)="createPwn()" mat-raised-button aria-label="Create PWN" class="ml-3" tabindex="0">Create PWN</button>
      </ng-container>
    </div>

    <div
      *ngIf="!currentEvaluation.isEligibilityDecisionFinalized && formGroup.get('eligibilitySubmittedOnEvaluation').value === false"
      class="mt-2"
    >
      <app-help-directional-text>
        <p>
          If decision is ineligible, please return to Intake Health Information to verify there is not a qualifying medical diagnosis before
          finalizing.
        </p>
      </app-help-directional-text>
    </div>

    <hr class="input__divider" />
    <ng-container *ngIf="formGroup.get('eligibilitySubmittedOnEvaluation').value">
      <div class="display-flex align-items-center">
        <fieldset>
          <app-radio-group
            [options]="basedOnOptions"
            formControlName="eligibilityBasedOn"
            label="Based on..."
            [useUndo]="!disableUndoButton"
          ></app-radio-group>
        </fieldset>
      </div>

      <ng-container *ngIf="formGroup.get('eligibilityBasedOn').value === eligibilityBasedOn.QuarterOrMore">
        <div class="pb-2">
          <mat-label
            [ngClass]="{
              asterisk_input: formGroup.get('twentyFiveDelayAreas').hasError('required')
            }"
            >Select areas</mat-label
          >
        </div>
        <div>
          <mat-button-toggle
            *ngFor="let area of areas"
            [value]="area.id"
            (change)="onAreaSelect($event)"
            [checked]="isAreaChecked(area.id)"
            class="mr-2 mb-2 button-toggle--development"
            attr.aria-label="{{ area.label }} Button Toggle"
            [disabled]="currentEvaluation.isEligibilityDecisionFinalized"
            >{{ area.label }}
          </mat-button-toggle>
        </div>
      </ng-container>

      <ng-container *ngIf="rawEligibilityBasedOn === 'DocumentedDelay'">
        <div class="mb-2" *ngIf="documentedDelayItems?.length === 0">
          <app-page-alert [alert]="eligibleConditionAlert" role="status" aria-live="polite"></app-page-alert>
        </div>
        <div>
          <p>
            List Diagnosis(es):
            <ng-container *ngIf="!currentEvaluation.isEligibilityDecisionFinalized">
              <button
                mat-icon-button
                color="primary"
                (click)="getLatestMedicalDiagnoses()"
                class="ml-2"
                matTooltip="Refresh"
                aria-label="Refresh Icon"
                tabindex="0"
                [disabled]="loading"
              >
                <mat-icon aria-label="Refresh Icon"> refresh </mat-icon>
              </button>
              <button
                (click)="updateMedicalDiagnoses()"
                color="primary"
                mat-raised-button
                aria-label="Update Medical Diagnosis(es)"
                class="ml-3"
                tabindex="0"
              >
                Update Medical Diagnosis(es)
              </button>
            </ng-container>
          </p>
          <p>{{ documentedDelayItems }}</p>
        </div>
      </ng-container>

      <ng-container *ngIf="formGroup.get('eligibilityBasedOn').value === 'InformedClinicalOpinion'">
        <h3
          class="mb-2"
          [ngClass]="{
            asterisk_input: formGroup.hasError('icoSourcesRequired')
          }"
        >
          Sources
          <span class="text-sm text-black">(Select all that apply)</span>:
        </h3>
        <div class="row" *ngFor="let group of eligibilityIcoSources; last as isLast" [ngClass]="{ 'mb-2': !isLast }">
          <div class="col-12" [formGroup]="group">
            <mat-checkbox formControlName="source" attr.aria-label="{{ group.get('label').value }}">
              {{ group.get('label').value }}
            </mat-checkbox>
            <app-textarea
              [rows]="4"
              label="Summary"
              formControlName="summary"
              [showAsterisk]="group.hasError('summaryRequired')"
              *ngIf="group.get('source').value"
              attr.aria-required="{{ group.hasError('summaryRequired') ? 'true' : 'false' }}"
            ></app-textarea>
            <mat-error *ngIf="group.get('summary').touched && group.hasError('summaryRequired')">
              Field is <strong>required</strong>
            </mat-error>
          </div>
        </div>
        <mat-error *ngIf="formGroup.get('eligibilityIcoSources').touched && formGroup.hasError('icoSourcesRequired')">
          At least one source <strong>required</strong>
        </mat-error>

        <hr class="input__divider" />

        <h3
          class="mb-2"
          [ngClass]="{
            asterisk_input: formGroup.hasError('icoReasonsRequired')
          }"
        >
          Reasons
          <span class="text-sm text-black">(Select all that apply)</span>:
        </h3>
        <div class="row" *ngFor="let group of eligibilityIcoReasons; last as isLast" [ngClass]="{ 'mb-2': !isLast }">
          <div class="col-12" [formGroup]="group">
            <mat-checkbox formControlName="reason" attr.aria-label="{{ group.get('label').value }}">
              {{ group.get('label').value }}
            </mat-checkbox>
            <app-textarea
              [rows]="4"
              label="Summary"
              formControlName="summary"
              [showAsterisk]="group.hasError('summaryRequired')"
              *ngIf="group.get('reason').value"
              attr.aria-required="{{ group.hasError('summaryRequired') ? 'true' : 'false' }}"
            ></app-textarea>
            <mat-error *ngIf="group.get('summary').touched && group.hasError('summaryRequired')">
              Field is <strong>required</strong>
            </mat-error>
          </div>
        </div>
        <mat-error *ngIf="formGroup.get('eligibilityIcoReasons').touched && formGroup.hasError('icoReasonsRequired')">
          At least one reason <strong>required</strong>
        </mat-error>
      </ng-container>
    </ng-container>
  </mat-card>
</section>
<div class="page-bottom-leeway"></div>
