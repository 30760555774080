import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CalendarOptions } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Subscription } from 'rxjs';
import { ReevaluationService } from 'src/app/reevaluation/services/reevaluation.service';
import { EvaluationService } from '../../../evaluation/services/evaluation.service';

@Component({
  selector: 'app-learner-calendar',
  templateUrl: './learner-calendar.component.html',
  styleUrls: ['./learner-calendar.component.scss'],
})
export class LearnerCalendarComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  private baseTitle = 'ACHIEVE';

  events: { title: string; date: Date }[];
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prevYear, prev, today, next, nextYear',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay',
    },
    plugins: [dayGridPlugin, timeGridPlugin],
    initialView: 'dayGridMonth',
    weekends: true,
    editable: false,
    selectable: false,
    selectMirror: false,
    dayMaxEvents: false,
    height: 'auto',
    allDaySlot: true,
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short',
    },
    displayEventTime: true,
  };
  learner: string;
  facilitator: string;
  evaluationId: string = this.route.snapshot.paramMap.get('evaluationId');
  reevaluationId: string = this.route.snapshot.paramMap.get('reevaluationId');

  calendarHeader = {
    left: 'prevYear, prev, today, next, nextYear',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
  };

  eventTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
    meridiem: 'short',
  };

  constructor(
    private route: ActivatedRoute,
    private evaluationService: EvaluationService,
    private reevaluationService: ReevaluationService,
    private readonly title: Title
  ) {}

  ngOnInit(): void {
    if (this.evaluationId) {
      this.populateEvaluationAssignments();
    }
    if (this.reevaluationId) {
      this.populateReevaluationAssignments();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  populateEvaluationAssignments() {
    this.subscriptions.add(
      this.evaluationService.getCalender(this.evaluationId).subscribe(
        (data) => {
          if (data) {
            this.learner = data.learnerName;
            this.facilitator = data.facilitatorName;
            this.calendarOptions.events = data.assignments.map((a) => ({
              title: `${a.startTime ? a.startTime + ' - ' : ''} Eval/Access in ${a.evaluationDomain}, ${this.facilitator}, ${a.startTime}`,
              date: a.date,
            }));
          }
        },
        (err) => {},
        () => this.setTitle()
      )
    );
  }

  populateReevaluationAssignments() {
    this.subscriptions.add(
      this.reevaluationService.getCalender(this.reevaluationId).subscribe(
        (data) => {
          if (data) {
            this.learner = data.learnerName;
            this.facilitator = data.facilitatorName;
            this.calendarOptions.events = data.assignments.map((a) => ({
              title: `${a.startTime ? a.startTime + ' - ' : ''} Eval/Access in ${a.evaluationDomain}, ${this.facilitator}, ${a.startTime}`,
              date: a.date,
            }));
          }
        },
        (err) => {},
        () => this.setTitle()
      )
    );
  }

  setTitle() {
    let newTitle = this.learner + "'s Calendar";
    if (newTitle) {
      newTitle += ` - ${this.baseTitle}`;
    } else {
      newTitle = this.baseTitle;
    }
    this.title.setTitle(newTitle);
  }
}
