import { GradeLevelWhenAssessed } from './elpa.model';

export interface IsaspDto {
  schoolYear: string;
  grade: string;
  mathScore: string;
  mathProficiency: string;
  scienceScore: string;
  scienceProficiency: string;
  elaScore: string;
  elaProficiency: string;
}

export enum IsaspProficiency {
  A = 'Advanced',
  P = 'Proficient',
  N = 'Not Yet Proficient',
  O = 'N/A',
}
