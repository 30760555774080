<div *ngIf="dataSource?.data">
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col-xl-9 col-md-12">
        <app-quick-date
          label="Label"
          formControlName="quickDate"
          [showError]="formGroup.hasError('endDateBeforeStartingDate')"
        ></app-quick-date>
      </div>
      <div class="col-md-12 col-lg-6 col-xl-3">
        <app-select label="Type:" formControlName="type" [options]="typeOptions"> </app-select>
      </div>
      <div class="col-md-12 text-right">
        <button
          mat-raised-button
          attr.aria-label="{{ showActivity ? 'Hide Activities' : 'View All Activities' }}"
          color="primary"
          class="show_activity position-fix--btn ml-2"
          (click)="toggleActivity()"
          tabindex="0"
        >
          {{ showActivity ? 'Hide Activities' : 'View All Activities' }}
        </button>
      </div>
    </div>
  </form>

  <div class="table-overflow app-table">
    <table class="w-100" mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="dateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date and Time</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dateTime | dateTimeFormat }}
        </td>
      </ng-container>
      <ng-container matColumnDef="eventType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Event Type</th>
        <td mat-cell *matCellDef="let element">
          {{ element.eventDescription }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [dataSource]="dataSource"></mat-paginator>
  </div>
</div>

<ng-container *ngIf="dataSource?.data.length === 0">
  <hr class="input__divider" />
  <p class="my-0">No case history available...</p>
</ng-container>
