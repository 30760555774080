import { LookupBase } from '../../models/lookup-base';

export interface QuantifiableDatum {
  areaOfConcern: string;
  assessmentEvaluationActivity: string;
  measurement: string;
  trials: QuantifiableDataTrial[];
  scaleRatings: QuantifiableDataRating[];
  milestones: QuantifiableDataMilestone[];
  baseline: number;
  isExpectedNarrative: boolean;
  expectedPerformanceMin: number;
  expectedPerformanceMax: number;
  expectedPerformanceNarrative: string;
  isNarrative: boolean;
  performanceMin: number;
  performanceNarrative: string;
  standardOfComparisonId?: string;
  standardOfComparison: StandardOfComparison;
  secondMeasurementDescription: string;
  secondIsExpectedNarrative: boolean;
  secondExpectedPerformanceMin: number;
  secondExpectedPerformanceMax: number;
  secondExpectedPerformanceNarrative: string;
  secondIsNarrative: boolean;
  secondPerformanceMin: number;
  secondPerformanceMax: number;
  secondPerformanceNarrative: string;
  comments: string;
  isAdvanced: boolean;
  isActive: boolean;
  priorVersionId?: string;
  priorVersion?: QuantifiableDatum;
}

export interface AddOrUpdateQuantifiableDatum {
  areaOfConcern: string;
  assessmentEvaluationActivity: string;
  measurement: string;
  trials: QuantifiableDataTrial[];
  scaleRatings: QuantifiableDataRating[];
  milestones: QuantifiableDataMilestone[];
  baseline: number;
  isExpectedNarrative: boolean;
  expectedPerformanceMin: number;
  expectedPerformanceMax: number;
  expectedPerformanceNarrative: string;
  isNarrative: boolean;
  performanceMin: number;
  performanceNarrative: string;
  standardOfComparisonId: string;
  comments: string;
  isAdvanced: boolean;
  isActive: boolean;
  priorVersionId?: string;
}

export interface QuantifiableDataTrial {
  id: string;
  score: number;
  date: Date | string;
  isActive: boolean;
  priorVersionId?: string;
  priorVersion?: QuantifiableDataTrial;
}

export interface QuantifiableDataRating {
  id: string;
  rating?: number;
  description: string;
  secondary: boolean;
  isActive: boolean;
  priorVersionId?: string;
  priorVersion?: QuantifiableDataRating;
}

export interface QuantifiableDataMilestone {
  id: string;
  // eslint-disable-next-line id-blacklist
  number?: number;
  completionDate?: Date;
  benchmarkObjectiveMilestone: string;
  value?: number;
  secondary: boolean;
  isActive: boolean;
  priorVersionId?: string;
  priorVersion?: QuantifiableDataMilestone;
}

export interface StandardOfComparison extends LookupBase {
  isOther: boolean;
}

// #Region IEP Goal

export interface IepGoalQuantifiableDatum {
  id: string;
  description: string;
  status?: MilestoneStatus;
  milestoneNumber: number;
  submitted?: boolean;
  tieredMilestones: QuantifiableTieredMilestone[];
  scaleRatings: QuantifiableDataRating[];
  primaryMeasurement: QuantifiableDataMeasurement;
  secondaryMeasurement: QuantifiableDataMeasurement;
  priorVersionId?: string;
  priorVersion?: IepGoalQuantifiableDatum;
  amendmentId?: string;
  amendmentStartDate?: Date;
  amendmentEndDate?: Date;
  amendmentComments?: string;
}

export interface AddOrUpdateIepGoalQuantifiableDatum {
  id: string;
  description: string;
  status?: MilestoneStatus;
  milestoneNumber: number;
  submitted?: boolean;
  tieredMilestones: QuantifiableTieredMilestone[];
  scaleRatings: QuantifiableDataRating[];
  primaryMeasurement: QuantifiableDataMeasurement;
  secondaryMeasurement: QuantifiableDataMeasurement;
}

export enum MilestoneStatus {
  inactive = 'inactive',
  active = 'active',
  met = 'met',
}

export interface QuantifiableTieredMilestone {
  // eslint-disable-next-line id-blacklist
  number: number;
  endDate?: Date;
  target: number;
  secondary: boolean;
}

export interface QuantifiableDataMeasurement {
  id?: string;
  baseline?: number;
  learnersGoalTarget?: number;
  unitOfMeasurement: string;
  dataPoints: QuantifiableDataPoint[];
  priorVersionId?: string;
  priorVersion?: QuantifiableDataMeasurement;
  amendmentId?: string;
  amendmentStartDate?: Date;
  amendmentEndDate?: Date;
  amendmentComments?: string;
}

export interface QuantifiableDataPoint {
  score: number;
  date: Date | string;
}

// #endregion
