import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { HttpClient } from '@angular/common/http';
import { AchieveConfigService } from '../../services/achieve-config-service/achieve-config.service';

@Component({
  selector: 'app-chart-viewer',
  templateUrl: './chart-viewer.component.html',
  styleUrls: ['./chart-viewer.component.scss'],
})
export class ChartViewerComponent implements OnInit {
  public chart: Chart = null;
  public showSpinner = false;
  @Input() public params: any = {};
  @Input() public graph = '';
  constructor(private httpClient: HttpClient, private achieveConfigService: AchieveConfigService) {}

  async ngOnInit(): Promise<void> {
    await this.refresh();
  }
  async refresh() {
    const config: any = this.achieveConfigService.settings;
    if (!config || !config.valerian) {
      return;
    }
    this.showSpinner = true;
    this.httpClient
      .get(
        `${config.externalReportsUrl}api/external_report_view/${config.valerian.apiKey}/${config.environment}/${
          this.graph
        }?${this.getParams()}`,
        {
          headers: {
            auth_token: config.valerian.apiSecret,
          },
        }
      )
      .subscribe((res: any) => {
        this.chart = new Chart(res);
        this.showSpinner = false;
      });
  }
  getParams() {
    return Object.keys(this.params)
      .map((param) => {
        return `${param}=${this.params[param]}`;
      })
      .join('&');
  }
}
