export enum TaggedCategory {
  ReevaluationSummary,
  LearnerPerformance,
  Plaafp,
  Goals,
  ActivitySupports,
  Accommodations,
  Services,
  EndedIepServices,
  PostReferral,
  EvaluationDetails,
  Outcomes,
  ServicesC,
  EndedIfspServices,
}

export enum TaggedForCategory {
  Goals = 'goals',
  Services = 'services',
  Pwn = 'pwn',
  Outcomes = 'outcomes',
  ServicesC = 'servicesc',
}
