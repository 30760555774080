import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { Correlation, CorrelationService } from '../services/logger/correlation.service';

@Injectable()
export class LoggerInterceptor implements HttpInterceptor {
  constructor(private correlationService: CorrelationService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.correlationService.begin();

    const httpRequest = req.clone({
      headers: req.headers.set('X-Correlation-Id', this.correlationService.current.id),
    });
    return next.handle(httpRequest).pipe(finalize(() => this.correlationService.end()));
  }
}
