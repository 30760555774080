import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { RoutingService } from '../../../../shared/services/routing.service';
import { NewWindowConfig, openNewWindow } from '../../../../shared/windowHelpers';
import { CaseSummary } from '../../../../shared/models/case';
import { DeactivationService } from '../../../../shared/services/deactivation.service';

@Component({
  selector: 'app-data-history-part-b',
  templateUrl: './data-history-part-b.component.html',
  styleUrls: ['./data-history-part-b.component.scss'],
})
export class DataHistoryPartBComponent extends BaseComponent implements OnInit {
  @Input() stateAssignedId: string;
  @Input() case: CaseSummary = {} as CaseSummary;
  @Input() caseId: string;
  @Input() learnerId: string;
  @Input() secondaryTransitionEnabled: boolean;

  constructor(private routingService: RoutingService, deactivationService: DeactivationService) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.stateAssignedId = this.case?.learner.stateAssignedId;
    this.secondaryTransitionEnabled = this.case?.learner.isSecondaryTransition;
    this.learnerId = this.case?.learnerId;
    this.caseId = this.case?.id;
  }

  get isCaseWorkable(): boolean {
    return this.case?.isActive && this.case?.exitFinalizedOn === null;
  }

  enterProgress() {
    const config: NewWindowConfig = {
      path: this.routingService.enterProgressPath(this.caseId).join('/'),
      popup: true,
      width: '1280px',
    };
    openNewWindow(config);
  }
}
