export interface PseOutcome {
  id: string;
  area: OutcomeArea;
  label: string;
  isOther: boolean;
}

export enum OutcomeArea {
  Living = 'Living',
  Learning = 'Learning',
  Working = 'Working',
}
