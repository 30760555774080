<div #editingOutcome></div>
<form [formGroup]="formGroup" #formDirective="ngForm" (keydown.enter)="$event.preventDefault()">
  <h3 class="mb-2 w-fit">
    <span
      class="position-relative"
      [ngClass]="{
        asterisk_input: formGroup.get('typeOfOutcome').hasError('required')
      }"
      attr.aria-label="{{ formGroup.get('typeOfOutcome').hasError('required') && 'Control is Required' }}"
    >
      Select one or more Outcome Areas for a child or family outcome
    </span>
  </h3>
  <button class="ml-1" mat-raised-button color="accent" aria-label="Open tags in new window Button" (click)="openTags()" tabindex="0">
    View IFSP Outcome Tags
  </button>
  <mat-card class="my-0 card--shadow white-space-pre-line">
    <div class="w-100 mb-2">
      <mat-button-toggle-group formControlName="typeOfOutcome" aria-labelledby="Select an option">
        <mat-button-toggle value="Child" aria-label="Child Outcome Button Toggle">Child Outcome</mat-button-toggle>
        <mat-button-toggle value="Family" aria-label="Family Outcome Button Toggle">Family Outcome</mat-button-toggle>
      </mat-button-toggle-group>

      <button
        mat-icon-button
        aria-label="Clear Icon"
        type="button"
        color="primary"
        class="text-sm vertical-label mr-2"
        *ngIf="formGroup.get('typeOfOutcome').value !== null"
        (click)="onClearControl(formGroup.get('typeOfOutcome'))"
        tabindex="0"
      >
        <i class="fas fa-undo" aria-label="Clear Icon"></i>
      </button>
    </div>
    <app-compare-amendment-output
      *ngIf="modifyingOutcome"
      [amendmentId]="modificationId"
      [amendmentFinalized]="modificationIsFinalized"
      [amendmentDate]="lastFinalizedDate"
      [priorVersionId]="modifyingOutcome?.priorVersionId"
      [isActive]="modifyingOutcome.isActive"
      [changedVersionText]="formGroup.get('typeOfOutcome').value"
      [currentVersionText]="modifyingOutcome?.priorVersion?.typeOfOutcome"
      [currentPrefixText]="modifyingOutcome?.priorVersion ? 'Current: ' : ''"
      changedPrefixText="Proposed: "
    ></app-compare-amendment-output>
    <div class="w-100 mb-2" *ngIf="formGroup.get('typeOfOutcome').value === 'Child'">
      <div>
        <mat-button-toggle-group
          formControlName="ecoAreas"
          multiple="true"
          [ngClass]="{
            asterisk_input: formGroup.get('ecoAreas').hasError('required')
          }"
          aria-labelledby="Select an Option"
        >
          <ng-container *ngFor="let ecoArea of ecoAreas">
            <mat-button-toggle [value]="ecoArea.id" attr.aria-label="{{ ecoArea.label }}">
              {{ ecoArea.label }}
            </mat-button-toggle>
          </ng-container>
        </mat-button-toggle-group>
        <app-compare-amendment-output
          *ngIf="modifyingOutcome"
          [amendmentId]="modificationId"
          [amendmentFinalized]="modificationIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="modifyingOutcome?.priorVersionId"
          [isActive]="modifyingOutcome.isActive"
          [changedVersionText]="getEcoAreasText(formGroup.get('ecoAreas').value)"
          [currentVersionText]="getEcoAreasText(modifyingOutcome.priorVersion?.ecoAreas, true)"
          [currentPrefixText]="modifyingOutcome?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>

      <mat-error class="text-sm" *ngIf="formGroup.get('ecoAreas').touched && formGroup.get('ecoAreas').hasError('required')"
        >Must select an ECO Area</mat-error
      >
    </div>

    <div class="w-100 mb-2" *ngIf="formGroup.get('typeOfOutcome').value === 'Family'">
      <mat-button-toggle-group
        formControlName="familyAreas"
        multiple="true"
        aria-labelledby="Select an option"
        [ngClass]="{
          asterisk_input: formGroup.get('familyAreas').hasError('required')
        }"
      >
        <ng-container *ngFor="let familyArea of familyAreas">
          <mat-button-toggle [value]="familyArea.id" attr.aria-label="{{ familyArea.label }} Button Toggle">
            {{ familyArea.label }}
          </mat-button-toggle>
        </ng-container>
      </mat-button-toggle-group>
      <app-compare-amendment-output
        *ngIf="modifyingOutcome"
        [amendmentId]="modificationId"
        [amendmentFinalized]="modificationIsFinalized"
        [amendmentDate]="lastFinalizedDate"
        [priorVersionId]="modifyingOutcome?.priorVersionId"
        [isActive]="modifyingOutcome.isActive"
        [changedVersionText]="getFamilyAreasText(formGroup.get('familyAreas').value)"
        [currentVersionText]="getFamilyAreasText(modifyingOutcome.priorVersion?.familyAreas)"
        [currentPrefixText]="modifyingOutcome?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      ></app-compare-amendment-output>
    </div>

    <div class="w-100">
      <mat-button-toggle-group formControlName="relatedTo" (click)="toggleRelatedTo($event)" aria-labelledby="Select an option">
        <mat-button-toggle [checked]="formGroup.get('relatedTo').value" aria-label="Related to Transition Button">
          Related to Transition</mat-button-toggle
        >
      </mat-button-toggle-group>
      <app-compare-amendment-output
        *ngIf="modifyingOutcome"
        [amendmentId]="modificationId"
        [amendmentFinalized]="modificationIsFinalized"
        [amendmentDate]="lastFinalizedDate"
        [priorVersionId]="modifyingOutcome?.priorVersionId"
        [isActive]="modifyingOutcome.isActive"
        [changedVersionText]="relatedToChecked ? 'Yes' : 'No'"
        [currentVersionText]="modifyingOutcome.priorVersion?.relatedTo ? 'Yes' : 'No'"
        [currentPrefixText]="modifyingOutcome?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      ></app-compare-amendment-output>
    </div>

    <ng-container
      *ngIf="
        formGroup.get('typeOfOutcome').value &&
        ((formGroup.get('ecoAreas').value && formGroup.get('ecoAreas').value.length > 0) ||
          (formGroup.get('familyAreas').value && formGroup.get('familyAreas').value.length > 0))
      "
    >
      <hr class="input__divider mb-2" />
      <div class="row">
        <div class="col-md-12">
          <div class="hint my-2">
            <p>
              <ng-container *ngIf="formGroup.get('typeOfOutcome').value === 'Child' || formGroup.get('typeOfOutcome').value === 'Family'">
                Outcome: The outcome should be family identified, useful and participation-based, meaningful, and measurable (also known as
                FUMM)
              </ng-container>
            </p>
          </div>
          <app-textarea formControlName="title" inputType="text" label="Outcome"></app-textarea>
          <app-compare-amendment-output
            *ngIf="modifyingOutcome"
            [amendmentId]="modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="modifyingOutcome?.priorVersionId"
            [isActive]="modifyingOutcome.isActive"
            [changedVersionText]="formGroup.get('title').value"
            [currentVersionText]="modifyingOutcome.priorVersion?.title"
            [currentPrefixText]="modifyingOutcome?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </div>
      <div class="hint my-2">
        <p>
          <ng-container *ngIf="formGroup.get('typeOfOutcome').value === 'Child'">
            Strategies: What strategies, activities, learning opportunities will be used within daily routines to accomplish the outcome and
            who are the team members responsible?
          </ng-container>
          <ng-container *ngIf="formGroup.get('typeOfOutcome').value === 'Family'">
            Strategies: What strategies, activities, learning opportunities will be used to accomplish the outcome and who are the team
            members responsible?
          </ng-container>
        </p>
      </div>
      <div
        class="row align-items-center"
        *ngFor="let strategy of strategiesFormArray; let i = index; last as isLast"
        [formGroup]="strategy"
      >
        <div
          [ngClass]="{
            'col-md-12': strategiesFormArray.length <= 1,
            'col-md-11': strategiesFormArray.length > 1
          }"
        >
          <app-textarea
            formControlName="name"
            inputType="text"
            label="Strategies"
            [isBankableField]="true"
            [bankableFieldId]="bankableFieldIds.outcomes.strategiesName"
            [bankableFieldChildFname]="caseSummary?.learner?.firstName"
            (blurMe)="onStrategiesBlur($event, strategy, i)"
            [showAsterisk]="formGroup.hasError('strategiesRequired')"
          ></app-textarea>
        </div>
        <div class="col-md-1 text-right">
          <button
            mat-icon-button
            aria-label="Delete Icon"
            color="warn"
            (click)="onRemoveStrategies(i)"
            *ngIf="strategiesFormArray.length > 1"
            tabindex="0"
          >
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <app-compare-amendment-output
        *ngIf="modifyingOutcome"
        [amendmentId]="modificationId"
        [amendmentFinalized]="modificationIsFinalized"
        [amendmentDate]="lastFinalizedDate"
        [priorVersionId]="modifyingOutcome.priorVersionId"
        [isActive]="modifyingOutcome.isActive"
        [changedVersionText]="getStrategyText(strategiesFormArray, true)"
        [currentVersionText]="getStrategyText(modifyingOutcome.priorVersion?.strategies)"
        [currentPrefixText]="modifyingOutcome?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      ></app-compare-amendment-output>
      <mat-error *ngIf="formGroup.get('strategies').touched && formGroup.hasError('strategiesRequired')" class="my-1">
        * At least one strategy is <strong>required</strong>
      </mat-error>
    </ng-container>
  </mat-card>
  <div [hidden]="!showCriteriaSection">
    <div class="display-flex align-items-center justify-content-between mb-2 card-spacing-top">
      <h3 class="my-0">Criteria: Measurable / Observable Action or Behavior</h3>
      <mat-error *ngIf="criterionFormGroup.touched && formGroup.hasError('criterionRequired')" class="my-1">
        * At least one criterion is <strong>required</strong>
      </mat-error>
    </div>

    <mat-card class="my-0 card--plain card--shadow">
      <form [formGroup]="criterionFormGroup" #criterionForm="ngForm" (submit)="onCriterionSubmit(criterionForm)">
        <p class="form__description">How will we know that progress is being made?</p>

        <div class="row">
          <div class="col-md-8">
            <app-textarea
              formControlName="criteriaForAccomplishment"
              inputType="text"
              [isBankableField]="true"
              [bankableFieldId]="bankableFieldIds.outcomes.criteriaForAccomplishment"
              [bankableFieldChildFname]="caseSummary?.learner?.firstName"
              label="Criterion for Accomplishment"
            ></app-textarea>
            <app-compare-amendment-output
              *ngIf="criterion && modificationId"
              [amendmentId]="modificationId"
              [amendmentFinalized]="modificationIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="criterion.priorVersionId"
              [isActive]="criterion.isActive"
              [changedVersionText]="criterionFormGroup.get('criteriaForAccomplishment').value"
              [currentVersionText]="criterion.priorVersion?.criteriaForAccomplishment"
              [currentPrefixText]="criterion?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>
          </div>

          <div class="col-md-4">
            <fieldset>
              <app-radio-group [options]="measurementTypes" formControlName="measurementType" label="Measurement Type"></app-radio-group>
            </fieldset>
            <app-compare-amendment-output
              *ngIf="criterion && modificationId"
              [amendmentId]="modificationId"
              [amendmentFinalized]="modificationIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="criterion.priorVersionId"
              [isActive]="criterion.isActive"
              [changedVersionText]="criterionFormGroup.get('measurementType').value"
              [currentVersionText]="criterion.priorVersion?.measurementType"
              [currentPrefixText]="criterion?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>
          </div>
        </div>
        <div
          [hidden]="!criterionFormGroup.get('measurementType').value || criterionFormGroup.get('measurementType').value === 'nonnumeric'"
        >
          <hr class="input__divider" />
          <div class="mt-2">
            <app-quantifiable-data
              #quantdata
              [model]="criterion?.quantifiableData"
              [modificationId]="modificationId"
              [learnerFirstName]="caseSummary?.learner.firstName"
              [isIfsp]="true"
              (formChange)="onQuantifiedDataFormChange($event)"
              [submitted]="criterionSubmitAttempted"
              [typeOfOutcome]="formGroup.get('typeOfOutcome').value"
            ></app-quantifiable-data>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button
              class="mt-2"
              mat-raised-button
              aria-label="Complete Criterion Button"
              color="primary"
              [disabled]="!criterionFormGroup.valid"
              tabindex="0"
            >
              Complete Criterion
            </button>
          </div>
        </div>
      </form>
      <div class="row" *ngIf="criterionDataSource.data.length">
        <div class="col-md-12">
          <div class="table-overflow app-table">
            <table mat-table [dataSource]="criterionDataSource" matSort class="w-100">
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more"></th>
                <td mat-cell *matCellDef="let element; let i = index" class="mat-column-actions--more">
                  <button aria-label="View Icon" mat-icon-button (click)="onCriterionView(element)" class="icon-view" tabindex="0">
                    <mat-icon>remove_red_eye</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    aria-label="Edit Icon"
                    color="primary"
                    [disabled]="!element.id"
                    (click)="onCriterionEdit(element)"
                    tabindex="0"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    aria-label="Delete Icon"
                    (click)="onCriterionDelete(i, element)"
                    [disabled]="!element.id"
                    class="icon-delete"
                    tabindex="0"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="criterionForAccomplishment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Criterion for Accomplishment</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="!modificationId">
                    {{ element.criteriaForAccomplishment }}
                  </span>
                  <app-compare-amendment-output
                    *ngIf="modificationId"
                    [amendmentId]="modificationId"
                    [amendmentFinalized]="modificationIsFinalized"
                    [amendmentDate]="lastFinalizedDate"
                    [priorVersionId]="element?.priorVersionId"
                    [isActive]="element?.isActive"
                    [changedVersionText]="element.criteriaForAccomplishment"
                    [currentVersionText]="element?.priorVersion?.criteriaForAccomplishment"
                    [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
                    changedPrefixText="Proposed: "
                  ></app-compare-amendment-output>
                </td>
              </ng-container>
              <ng-container matColumnDef="measurementType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Measurement Type</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="!modificationId">
                    <ng-container *ngIf="element.measurementType === 'nonnumeric'"> Yes/No </ng-container>
                    <ng-container *ngIf="element.measurementType === 'numeric'"> Numeric </ng-container>
                  </span>
                  <app-compare-amendment-output
                    *ngIf="modificationId"
                    [amendmentId]="modificationId"
                    [amendmentFinalized]="modificationIsFinalized"
                    [amendmentDate]="lastFinalizedDate"
                    [priorVersionId]="element?.priorVersionId"
                    [isActive]="element?.isActive"
                    [changedVersionText]="getMeasurmentTypeText(element.measurementType)"
                    [currentVersionText]="getMeasurmentTypeText(element?.priorVersion?.measurementType)"
                    [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
                    changedPrefixText="Proposed: "
                  ></app-compare-amendment-output>
                </td>
              </ng-container>
              <ng-container matColumnDef="baseline">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Baseline</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="!modificationId">
                    {{ element.quantifiableData?.baseline !== null ? element.quantifiableData?.baseline : '-' }}
                  </span>
                  <app-compare-amendment-output
                    *ngIf="modificationId"
                    [amendmentId]="modificationId"
                    [amendmentFinalized]="modificationIsFinalized"
                    [amendmentDate]="lastFinalizedDate"
                    [priorVersionId]="element?.priorVersionId"
                    [isActive]="element?.isActive"
                    [changedVersionText]="element?.quantifiableData?.baseline"
                    [currentVersionText]="element?.priorVersion?.quantifiableData?.baseline"
                    [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
                    changedPrefixText="Proposed: "
                  ></app-compare-amendment-output>
                </td>
              </ng-container>
              <ng-container matColumnDef="goal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Goal</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="!modificationId">
                    {{ getGoalText(element.quantifiableData) }}
                  </span>
                  <app-compare-amendment-output
                    *ngIf="modificationId"
                    [amendmentId]="modificationId"
                    [amendmentFinalized]="modificationIsFinalized"
                    [amendmentDate]="lastFinalizedDate"
                    [priorVersionId]="element?.priorVersionId"
                    [isActive]="element?.isActive"
                    [changedVersionText]="getGoalText(element.quantifiableData)"
                    [currentVersionText]="getGoalText(element?.priorVersion?.quantifiableData)"
                    [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
                    changedPrefixText="Proposed: "
                  ></app-compare-amendment-output>
                </td>
              </ng-container>
              <ng-container matColumnDef="measurements">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Scale / Milestone</th>
                <td mat-cell *matCellDef="let element">
                  <span *ngIf="!modificationId">
                    {{ getScaleMilestoneText(element.quantifiableData) }}
                  </span>
                  <app-compare-amendment-output
                    *ngIf="modificationId"
                    [amendmentId]="modificationId"
                    [amendmentFinalized]="modificationIsFinalized"
                    [amendmentDate]="lastFinalizedDate"
                    [priorVersionId]="element?.priorVersionId"
                    [isActive]="element?.isActive"
                    [changedVersionText]="getScaleMilestoneText(element.quantifiableData)"
                    [currentVersionText]="getScaleMilestoneText(element?.priorVersion?.quantifiableData)"
                    [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
                    changedPrefixText="Proposed: "
                  ></app-compare-amendment-output>
                </td>
              </ng-container>

              <ng-container matColumnDef="noResults">
                <td mat-footer-cell *matFooterCellDef colspan="7">
                  <i>No results...</i>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedCriterionColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedCriterionColumns"></tr>
              <tr
                [hidden]="criterionDataSource?.data?.length > 0"
                mat-footer-row
                *matFooterRowDef="criterionDataSource.data?.length === 0 ? ['noResults'] : []"
              ></tr>
            </table>
            <mat-paginator [dataSource]="criterionDataSource"></mat-paginator>
          </div>
        </div>
      </div>
    </mat-card>

    <h3 class="mb-2 card-spacing-top">Timeline</h3>
    <mat-card class="my-0 pt-2 pb-1 card--plain card--shadow">
      <app-help-directional-text>
        <p class="mb-2">When do we anticipate this outcome will be achieved?</p>
      </app-help-directional-text>

      <div class="row">
        <div class="col-md-5 col-xl-3">
          <mat-form-field>
            <mat-label>Outcome End Date</mat-label>
            <input
              matInput
              aria-labelledby="Date"
              formControlName="outcomeEndDate"
              [matDatepicker]="picker"
              [min]="caseSummary?.ifspStartDate"
              autocomplete="off"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <app-compare-amendment-output
            *ngIf="modifyingOutcome"
            [amendmentId]="modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="modifyingOutcome.priorVersionId"
            [isActive]="modifyingOutcome.isActive"
            [changedVersionText]="getOutcomeEndDateText(formGroup.get('outcomeEndDate').value)"
            [currentVersionText]="getOutcomeEndDateText(modifyingOutcome.priorVersion?.outcomeEndDate)"
            [currentPrefixText]="modifyingOutcome?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </div>
    </mat-card>
    <ng-container>
      <div class="display-flex align-items-center justify-content-between">
        <h3 class="mb-2 card-spacing-top">Monitoring for Progress</h3>
        <mat-error *ngIf="formGroup.hasError('progressesRequired') && showProgressRequiredError" class="my-1">
          * At least one monitoring detail is <strong>required</strong>
        </mat-error>
      </div>

      <mat-card class="my-0 card--plain card--shadow">
        <form id="progressForm" [formGroup]="progressFormGroup" (submit)="addProgress(progressForm)" #progressForm="ngForm">
          <label class="vertical-label display-block mb-2" for="measuringMethod">
            <span class="text-semibold">Method:</span> How does the team plan on measuring progress? (check all that apply)
          </label>
          <div
            [ngClass]="{
              'mb-2': progressFormGroup.controls.measuringMethod.value === 'otherMethod'
            }"
          >
            <app-select label="Measuring Method" formControlName="measuringMethod" id="measuringMethod" [options]="measuringMethodOptions">
            </app-select>
          </div>
          <app-textarea
            *ngIf="progressFormGroup.get('measuringMethod').value === 'Other'"
            formControlName="otherMethodDescribe"
            label="Describe Other Method"
          ></app-textarea>

          <label
            class="vertical-label display-block"
            [ngClass]="{
              'mb-2': progressFormGroup.controls.frequencyNumber.value || progressFormGroup.controls.frequencyPeriod.value
            }"
          >
            <span class="text-semibold">Frequency:</span>
            How often does the team plan on measuring progress?
          </label>
          <div class="row">
            <div class="col-md-4 col-xl-2">
              <app-number-field formControlName="frequencyNumber" label="How often?" [min]="0" id="frequencyNumber"></app-number-field>
            </div>
            <div class="col-md-4 col-xl-2">
              <app-select label="Time Period" formControlName="frequencyPeriod" [options]="frequencyPeriodOptions"> </app-select>
            </div>
            <div class="col-md-4 col-xl-8 justify-content-end display-flex align-items-center">
              <button
                type="submit"
                mat-raised-button
                aria-label="Complete Monitoring Details"
                color="primary"
                tabindex="0"
                [disabled]="progressFormGroup.invalid"
              >
                Complete Monitoring Details
              </button>
            </div>
          </div>
        </form>
        <div class="row" *ngIf="progressDataSource.data.length">
          <div class="col-md-8">
            <div class="table-overflow app-table">
              <table mat-table [dataSource]="progressDataSource" matSort class="w-100">
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let progress; let i = index">
                    <ng-container *ngIf="!isEditingProgress">
                      <button aria-label="Edit Icon" mat-icon-button color="primary" (click)="setEditProgress(progress)" tabindex="0">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button aria-label="Delete Icon" mat-icon-button color="warn" (click)="removeProgress(i)" tabindex="0">
                        <mat-icon>close</mat-icon>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="progress.isEditing">
                      <button aria-label="Save Icon" mat-icon-button (click)="updateProgress(progress, i)" title="Save" tabindex="0">
                        <mat-icon>save</mat-icon>
                      </button>
                      <button
                        aria-label="Clear Icon"
                        mat-icon-button
                        (click)="setEditProgress(progress, false)"
                        title="Cancel"
                        tabindex="0"
                      >
                        <mat-icon>clear</mat-icon>
                      </button>
                    </ng-container>
                  </td>
                </ng-container>

                <ng-container matColumnDef="method">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Method</th>
                  <td mat-cell *matCellDef="let progress">
                    <app-inline-edit [editing]="progress.isEditing">
                      <ng-container viewMode>
                        <app-compare-amendment-output
                          *ngIf="modifyingOutcome"
                          [amendmentId]="modificationId"
                          [amendmentFinalized]="modificationIsFinalized"
                          [amendmentDate]="lastFinalizedDate"
                          [priorVersionId]="modifyingOutcome.priorVersionId"
                          [isActive]="progress.isActive"
                          [changedVersionText]="getMonitoringProgressText(progress)"
                          [currentVersionText]="
                            getMonitoringProgressText(getProgressPriorVersion(modifyingOutcome.priorVersion, progress?.priorVersionId))
                          "
                          [changedPrefixText]="progress.priorVersionId ? 'Proposed: ' : ''"
                        ></app-compare-amendment-output>
                        <span *ngIf="!modifyingOutcome">
                          {{ getMonitoringProgressText(progress) }}
                        </span>
                      </ng-container>
                      <ng-container editMode [formGroup]="progressInlineFormGroup">
                        <app-select formControlName="measuringMethod" [options]="measuringMethodOptions"> </app-select>

                        <app-textarea
                          *ngIf="progressInlineFormGroup.get('measuringMethod').value === 'Other'"
                          formControlName="otherMethodDescribe"
                          label="Describe Other"
                        ></app-textarea>
                      </ng-container>
                    </app-inline-edit>
                  </td>
                </ng-container>
                <ng-container matColumnDef="frequency">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Frequency</th>
                  <td mat-cell *matCellDef="let progress">
                    <app-inline-edit [editing]="progress.isEditing">
                      <ng-container viewMode>
                        <app-compare-amendment-output
                          *ngIf="modifyingOutcome"
                          [amendmentId]="modificationId"
                          [amendmentFinalized]="modificationIsFinalized"
                          [amendmentDate]="lastFinalizedDate"
                          [priorVersionId]="modifyingOutcome.priorVersionId"
                          [isActive]="progress.isActive"
                          [changedVersionText]="getProgressFrequencyText(progress)"
                          [currentVersionText]="
                            progress.priorVersionId
                              ? getProgressFrequencyText(getProgressPriorVersion(modifyingOutcome.priorVersion, progress?.priorVersionId))
                              : null
                          "
                          [changedPrefixText]="progress.priorVersionId ? 'Proposed: ' : ''"
                        ></app-compare-amendment-output>
                        <span *ngIf="!modifyingOutcome">
                          {{ getProgressFrequencyText(progress) }}
                        </span>
                      </ng-container>
                      <ng-container editMode [formGroup]="progressInlineFormGroup">
                        <app-number-field formControlName="frequencyNumber" id="frequencyNumber"></app-number-field>

                        <app-select formControlName="frequencyPeriod" [options]="frequencyPeriodOptions"> </app-select>
                      </ng-container>
                    </app-inline-edit>
                  </td>
                </ng-container>

                <ng-container matColumnDef="noResults">
                  <td mat-footer-cell *matFooterCellDef colspan="7">
                    <i>No results...</i>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedProgressColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedProgressColumns"></tr>
                <tr
                  [hidden]="progressDataSource?.data?.length > 0"
                  mat-footer-row
                  *matFooterRowDef="progressDataSource.data?.length === 0 ? ['noResults'] : []"
                ></tr>
              </table>
              <mat-paginator [dataSource]="progressDataSource"></mat-paginator>
            </div>
          </div>
        </div>
      </mat-card>
    </ng-container>
  </div>
  <div class="action__row">
    <div class="mr-1" *ngIf="outcome">
      <button (click)="tag(outcome, taggedForCategory.ServicesC)" mat-icon-button aria-label="Tag Button" class="text-lg mr-1" tabindex="0">
        <i
          *ngIf="!outcome?.taggedItem?.taggedForServicesC"
          class="fas fa-chalkboard-teacher tag__icon"
          aria-label="Service Tagging Icon"
        ></i>
        <i
          *ngIf="outcome?.taggedItem?.taggedForServicesC"
          class="fas fa-chalkboard-teacher tag__icon text-success mr-1"
          aria-label="Service Tagging Icon"
        ></i>
      </button>
      <button
        (click)="tag(outcome, taggedForCategory.Pwn)"
        mat-raised-button
        attr.aria-label="{{
          !outcome?.taggedItem?.taggedForPwn ? 'Tag for Prior Written Notice' : 'Untag for Prior Written Notice'
        }} Button"
        tabindex="0"
      >
        {{ !outcome?.taggedItem?.taggedForPwn ? 'Tag for Prior Written Notice' : 'Untag for Prior Written Notice' }}
      </button>
    </div>
    <button
      mat-raised-button
      attr.aria-label="Cancel Button"
      color="warn"
      (click)="onDeleteOrCancelOutcome(outcome?.id)"
      type="button"
      tabindex="0"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      aria-label="Save Button"
      color="primary"
      (click)="saveAndClose()"
      [disabled]="!outcome"
      [isBusy]="isBusy"
      tabindex="0"
    >
      Save
    </button>
    <button
      mat-raised-button
      aria-label="Add New Services for this Outcome Button"
      color="primary"
      [disabled]="formGroup.invalid"
      *ngIf="!hasModification"
      (click)="onSubmit(true)"
      tabindex="0"
    >
      Add New Services for this Outcome
    </button>
    <button
      mat-raised-button
      aria-label="Add Existing Services for this Outcome Button"
      color="primary"
      [disabled]="formGroup.invalid"
      *ngIf="!hasModification && !isAddingExistingOutcome && services?.length > 0"
      (click)="isAddingExistingOutcome = true"
      tabindex="0"
    >
      Add Existing Services for this Outcome
    </button>
    <button
      mat-raised-button
      aria-label="Complete and Close Button"
      color="primary"
      [disabled]="formGroup.invalid"
      (click)="onSubmit()"
      [disableAfterBusy]="formGroup.invalid"
      [isBusy]="isBusy"
      tabindex="0"
    >
      Complete and Close
    </button>
    <button mat-raised-button aria-label="Show Required Button" color="accent" (click)="showAllErrors()" tabindex="0">Show Required</button>
  </div>

  <div *ngIf="isAddingExistingOutcome" class="mb-2">
    <h3 class="mt-2 mb-2">Add Existing Services</h3>
    <mat-card class="my-0">
      <app-autocomplete-chips
        [options]="servicesKeyValues"
        formControlName="currentServicesForOutcome"
        label="Select Services"
      ></app-autocomplete-chips>

      <div class="action__row">
        <button mat-raised-button aria-label="Close Button" color="primary" (click)="isAddingExistingOutcome = false" tabindex="0">
          Close
        </button>
        <button mat-raised-button aria-label="Save Button" color="primary" (click)="saveExistingServicesSelection()" tabindex="0">
          Save
        </button>
      </div>
    </mat-card>
  </div>
</form>

<ng-container *ngIf="!hasModification">
  <h3 class="mt-0 mb-2">Outcomes List</h3>
  <mat-card class="my-0">
    <app-ifsp-outcomes-list
      (editOutcome)="onEditOutcome($event)"
      [caseId]="caseId"
      [ifspId]="ifspId"
      [learnerId]="caseSummary?.learnerId"
    ></app-ifsp-outcomes-list>
  </mat-card>
  <div class="page-bottom-leeway"></div>
</ng-container>
