export interface FamilySurveyQuestionBank {
  id: string;
  question: string;
  questionBankCategory: QuestionBankCategory;
}

export interface FamilySurveyQuestion {
  id: string;
  question: string;
  questionBankId: string;
  questionCategory: QuestionBankCategory;
}

export interface FamilySurvey {
  id: string;
  isComplete: boolean;
  evaluationId: string;
  questions: FamilySurveyQuestion[];
  userIds: string[];
}

export interface FamilySurveyResponse {
  familySurveyId: string;
  isSubmitted: boolean;
  questions: FamilySurveyQuestion[];
  answers: FamilySurveyAnswer[];
}

export interface FamilySurveyAnswer {
  answer: string;
  familySurveyQuestionId: string;
}

export enum QuestionBankCategory {
  K12 = 'K12',
  Transition = 'Transition',
  Preschool = 'Preschool',
  Custom = 'Custom',
}

export interface FamilySurveyResult {
  questions: FamilySurveyQuestion[];
  responses: FamilySurveyResponses[];
  responders: Array<string>;
}

export interface FamilySurveyResponses {
  answered: Date;
  user: string;
  answers: FamilySurveyAnswer[];
}
