import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConsentStatusNotesUpdate } from 'src/app/shared/models/fiie-consent/consent-status';
import { FamilyConsentStatus } from '../../../shared/models/fiie-consent/family-consent-status';
import { FiieConsentUpdateForm } from '../../../shared/models/fiie-consent/fiie-consent-update';
import { ConsentFormService } from '../../../shared/services/consent-form/consent-form.service';
@Component({
  selector: 'app-update-consent-status-notes',
  templateUrl: './update-consent-status-notes.component.html',
  styleUrls: ['./update-consent-status-notes.component.scss'],
})
export class UpdateConsentStatusNotesComponent implements OnInit {
  formGroup = this.fb.group({
    comments: [],
  });

  updatedForm: FiieConsentUpdateForm;
  statuses = FamilyConsentStatus;

  constructor(
    private fb: FormBuilder,
    private consentFormService: ConsentFormService,
    private dialogRef: MatDialogRef<UpdateConsentStatusNotesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConsentStatusNotesUpdate
  ) {}

  ngOnInit(): void {
    this.formGroup.controls.comments.setValue(this.data.comments);
  }

  submit() {
    const statusUpdate = {
      caseId: this.data.caseId,
      id: this.data.id,
      comments: this.formGroup.controls.comments.value,
    };
    this.consentFormService.updateStatusNotes(statusUpdate).subscribe(() => {
      this.dialogRef.close();
    });
  }
}
