<main class="dashboard">
  <header>
    <app-page-header [pageTitle]="'Family Dashboard'" [helpLinks]="false"></app-page-header>
  </header>

  <section class="todo-list mt-2">
    <div *ngFor="let id of questionnaireIds">
      <a [routerLink]="['/evaluation/early-access-questionnaire', id]" tabindex="0" role="link" aria-labelledby="Questionnarie Id">{{
        id
      }}</a>
    </div>
  </section>
</main>
