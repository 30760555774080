export enum TimeUnit {
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days',
}

export enum Frequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}
