<main class="container">
  <div class="print-preview" *ngIf="isPreview">
    <h1 class="print-preview__text">DRAFT</h1>
  </div>

  <app-print-head [showLogo]="true" title="Present Levels of Development (PLOD)"></app-print-head>

  <section class="output">
    <h3 class="mt-0 mb-2">Child and Family Information</h3>
    <app-dynamic-learner-header [learnerId]="learnerId"></app-dynamic-learner-header>
    <ul class="my-0 list list--print">
      <li class="list__item avoid-break">
        <h3 class="card-spacing-top mb-2">Service Coordinator Information</h3>
        <div class="print__container">
          <section class="output__demographic">
            <p class="my-0">
              <strong>Name:</strong>
              {{ caseOwner?.fullName }}
            </p>

            <p class="my-0">
              <strong>Email:</strong>
              {{ caseOwner?.email }}
            </p>

            <p class="my-0">
              <strong>Phone:</strong>
              {{ caseOwner?.phoneNumber | phone }}
            </p>

            <p class="my-0">
              <strong>Agency:</strong>
              {{ caseOwner?.aea }}
            </p>
          </section>
        </div>
      </li>
      <!--let ea of data.printData.controls.ecoAreas.controls;-->

      <ng-container *ngIf="formGroup.controls?.ecoAreas">
        <li class="list__item">
          <h3 class="card-spacing-top mb-2">ECO Areas</h3>
          <div class="print__container">
            <ul class="list pl-0">
              <li class="list__item" *ngFor="let ea of ecoAreaFormGroups; last as isLast">
                <div class="avoid-break">
                  <div class="display-flex align-items-center justify-content-between mb-2">
                    <h4 class="text-primary my-0">
                      {{ ea.controls.label.value }}
                    </h4>
                  </div>
                  <p class="my-0" *ngIf="ea.controls.description.value">
                    {{ ea.controls.description.value }}
                  </p>

                  <p class="my-2" *ngIf="ea.controls.scoreText.value">
                    <span class="text-tertiary text-md text-semibold"> Comparison to Age Expectation </span><br />
                    {{ ea.controls.scoreText.value }}
                  </p>
                  <hr class="input__divider hide-print" *ngIf="!isLast" />
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ng-container>
    </ul>
  </section>

  <div class="display-flex align-items-center justify-content-end mt-2">
    <button mat-raised-button aria-label="Delete Icon" (click)="onClose()" class="hide-print" tabindex="0">Close</button>
  </div>
</main>
