import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LookupBase } from '../../models/lookup-base';

@Injectable({
  providedIn: 'root',
})
export class QuantifiableDataService {
  constructor(private readonly http: HttpClient) {}

  getStandardsOfComparison() {
    return this.http.get<LookupBase[]>('api/evaluations/standards-of-comparison');
  }
}
