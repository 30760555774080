<ng-container *ngIf="servicesDataSource.data.length === 0">
  <div class="display-flex align-items-center justify-content-between flex-wrap">
    <p class="my-0">No Services added yet</p>
    <button mat-raised-button aria-label="Add New Service" color="primary" class="my-0" (click)="goToServices()" *ngIf="isProfilePage">
      Add New Service
    </button>
  </div>
</ng-container>

<section *ngIf="servicesDataSource.data.length > 0">
  <ng-container *ngIf="!isPortalUser">
    <h4 class="mt-0 mb-2 text-primary">Summary of Early ACCESS Services</h4>
    <button
      mat-raised-button
      aria-label="Clear Primary Service Provider Selection"
      color="primary"
      class="my-0"
      (click)="clearServiceProviders()"
      *ngIf="hasServiceProviders()"
      tabindex="0"
    >
      Clear Primary Service Provider Selection
    </button>
  </ng-container>

  <div class="table-overflow app-table">
    <table mat-table [dataSource]="servicesDataSource" class="w-100" [formGroup]="providerFormGroup" matSort>
      <ng-container matColumnDef="actions" *ngIf="!isReadOnly">
        <th mat-header-cell *matHeaderCellDef class="table-actions mat-column-actions--more">Actions</th>
        <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
          <div class="display-flex align-items-center justify-content-start" *ngIf="!isEditing">
            <button mat-icon-button aria-label="View Icon" color="primary" (click)="onServiceView(element)" tabindex="0">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button mat-icon-button aria-label="Edit Icon" color="primary" (click)="onServiceEdit(element)" tabindex="0">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button aria-label="Delete Icon" color="warn" (click)="onServiceRemove(element)" tabindex="0">
              <mat-icon>close</mat-icon>
            </button>
            <div class="fake-icon-button" *ngIf="element.status !== 'Complete'">
              <mat-icon class="icon-incomplete" aria-label="Warning">warning</mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="typeOfService">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Type of Service</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.typeOfService | truncate : 150) || '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Projected Start Date</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.startDate | dateFormat) || '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="provider">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Provider</th>
        <td mat-cell *matCellDef="let element">
          {{ (!element.otherProvider ? (element.provider | truncate : 150) : (element.otherProviderName | truncate : 150)) || '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="agency">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Agency</th>
        <td mat-cell *matCellDef="let element">
          {{ (!element.otherProvider ? (element.agency | truncate : 150) : (element.otherProviderAgency | truncate : 150)) || '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="length">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Length</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.length"> {{ element.length }} Min </ng-container>
          <ng-container *ngIf="!element.length"> - </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="howOften">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="cell--often">How often and with whom</th>
        <td mat-cell *matCellDef="let element" class="cell--often">
          <span *ngIf="element.frequencyNumber && element.frequencyPeriod && element.who">
            <span *ngIf="element.frequencyNumber > 1">{{ element.frequencyNumber }} time(s)</span>
            {{ element.frequencyPeriod }}<br />
            {{ element.who }}
          </span>
          <span *ngIf="!element.frequencyNumber && !element.frequencyPeriod && !element.who">-</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="familyDeclined">
        <th mat-header-cell *matHeaderCellDef class="text-center">Family Declined</th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <mat-checkbox
            [checked]="element.familyDeclined"
            (change)="onFamilyDeclinedChange(element.id, $event)"
            aria-labelledby="Check me!"
          ></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="primaryServiceProvider">
        <th mat-header-cell *matHeaderCellDef class="text-center">Primary Service Provider</th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <mat-radio-group
            formControlName="primaryServiceProvider_{{ element.id }}"
            (change)="onPSPChanged(element.id)"
            aria-labelledby="Primary Service Provider Id"
          >
            <mat-radio-button value="1" aria-labelledby="1"></mat-radio-button>
          </mat-radio-group>
        </td>
      </ng-container>

      <ng-container matColumnDef="tags">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tags</th>
        <td mat-cell *matCellDef="let element">
          <i
            *ngIf="element?.taggedItem?.taggedForOutcomes"
            class="fas fa-bullseye tag__icon text-success mr-1"
            aria-label="Goal Tagging Icon"
          ></i>
          <button class="ml-1" *ngIf="element?.taggedItem?.taggedForPwn" mat-raised-button tabindex="0" aria-label="Prior Written Notice">
            Prior Written Notice
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="servicesDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: servicesDisplayedColumns"></tr>
    </table>
    <mat-paginator [dataSource]="servicesDataSource"></mat-paginator>
  </div>
  <p *ngIf="!servicesDataSource">No data yet...</p>
</section>

<hr class="input__divider" />

<section *ngIf="!isPortalUser">
  <h4 class="mt-0 mb-2 text-primary">Medical or Other Service (Non-Early ACCESS Services)</h4>

  <div class="table-overflow app-table">
    <table mat-table [dataSource]="medicalDataSource" class="w-100" matSort>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more"></th>
        <td mat-cell *matCellDef="let service" class="mat-column-actions--more">
          <ng-container *ngIf="service.id && !isEditingMedicalService && !isReadOnly">
            <div class="display-flex align-items-center justify-content-start">
              <button aria-label="View Icon" color="primary" mat-icon-button type="button" (click)="onViewMore(service)" tabindex="0">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
              <button aria-label="Edit Icon" mat-icon-button (click)="setMedicalServiceEdit(service)" color="primary" tabindex="0">
                <mat-icon>edit</mat-icon>
              </button>
              <button aria-label="Delete Icon" mat-icon-button (click)="onMedicalServiceRemove(service)" color="warn" tabindex="0">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </ng-container>
          <div class="display-flex align-items-center">
            <ng-container *ngIf="service.isEditing">
              <button
                mat-icon-button
                aria-label="Save Icon"
                (click)="saveMedicalService(service)"
                color="primary"
                title="Save"
                tabindex="0"
              >
                <mat-icon>save</mat-icon>
              </button>
              <button
                mat-icon-button
                aria-label="Delete Icon"
                (click)="setMedicalServiceEdit(service, false)"
                color="warn"
                title="Cancel"
                tabindex="0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="addingNew">
            <button aria-label="Save Icon" mat-icon-button title="Save" color="primary" (click)="saveMedicalService(null)" tabindex="0">
              <mat-icon>save</mat-icon>
            </button>
            <button
              mat-icon-button
              aria-label="Clear Icon"
              color="warn"
              (click)="setMedicalServiceEdit(null, false)"
              title="Cancel"
              tabindex="0"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="!addingNew && !isEditingMedicalService">
            <button mat-icon-button aria-label="Add Icon" color="primary" (click)="addingNew = true" tabindex="0">
              <mat-icon>add_circle</mat-icon>
            </button>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="typeOfService">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Type of Service</th>
        <td mat-cell *matCellDef="let service">
          <app-inline-edit [editing]="service.isEditing">
            <ng-container viewMode>
              {{ service.typeOfService | truncate : 150 }}
            </ng-container>
            <ng-container editMode [formGroup]="medicalServiceFormGroup">
              <app-text-field formControlName="typeOfService"></app-text-field>
            </ng-container>
          </app-inline-edit>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="addingNew" [formGroup]="medicalServiceFormGroup">
            <app-text-field formControlName="typeOfService"></app-text-field>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="agencyName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Agency Name</th>
        <td mat-cell *matCellDef="let service">
          <app-inline-edit [editing]="service.isEditing">
            <ng-container viewMode>
              {{ service.agencyName | truncate : 150 }}
            </ng-container>
            <ng-container editMode [formGroup]="medicalServiceFormGroup">
              <app-text-field formControlName="agencyName"> </app-text-field>
            </ng-container>
          </app-inline-edit>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="addingNew" [formGroup]="medicalServiceFormGroup">
            <app-text-field formControlName="agencyName"></app-text-field>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="dateRequested">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Date Requested</th>
        <td mat-cell *matCellDef="let service">
          <app-inline-edit [editing]="service.isEditing">
            <ng-container viewMode>
              {{ service.dateRequested | dateFormat }}
            </ng-container>
            <ng-container editMode [formGroup]="medicalServiceFormGroup">
              <app-date-picker controlName="dateRequested"></app-date-picker>
            </ng-container>
          </app-inline-edit>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="addingNew" [formGroup]="medicalServiceFormGroup">
            <app-date-picker controlName="dateRequested"></app-date-picker>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="estimatedCompletionDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Estimated Completion Date</th>
        <td mat-cell *matCellDef="let service">
          <app-inline-edit [editing]="service.isEditing">
            <ng-container viewMode>
              {{ service.estimatedCompletionDate | dateFormat }}
            </ng-container>
            <ng-container editMode [formGroup]="medicalServiceFormGroup">
              <app-date-picker
                controlName="estimatedCompletionDate"
                [min]="medicalServiceFormGroup.get('dateRequested').value"
              ></app-date-picker>
            </ng-container>
          </app-inline-edit>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="addingNew" [formGroup]="medicalServiceFormGroup">
            <app-date-picker
              controlName="estimatedCompletionDate"
              [min]="medicalServiceFormGroup.get('dateRequested').value"
            ></app-date-picker>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="referralDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Referral Date</th>
        <td mat-cell *matCellDef="let service">
          <app-inline-edit [editing]="service.isEditing">
            <ng-container viewMode>
              {{ service.referralDate | dateFormat }}
            </ng-container>
            <ng-container editMode [formGroup]="medicalServiceFormGroup">
              <app-date-picker controlName="referralDate"></app-date-picker>
            </ng-container>
          </app-inline-edit>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="addingNew" [formGroup]="medicalServiceFormGroup">
            <app-date-picker controlName="referralDate"></app-date-picker>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="medicalDisplayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: medicalDisplayedColumns"
        [ngClass]="{
          'label-offset-fix label-offset-fix--top': checkLabelOffset(row)
        }"
      ></tr>
      <tfoot *ngIf="!isReadOnly">
        <tr mat-footer-row *matFooterRowDef="medicalDisplayedColumns"></tr>
      </tfoot>
    </table>
    <mat-paginator [dataSource]="medicalDataSource"></mat-paginator>
  </div>
  <p *ngIf="!medicalDataSource">No data yet...</p>
</section>
