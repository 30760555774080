<form [formGroup]="formGroup" #quantDataForm="ngForm">
  <app-textarea
    *ngIf="isAdditionalQuant"
    formControlName="description"
    [isBankableField]="true"
    [bankableFieldId]="bankableFieldIds.iepGoals.quantData.description"
    label="Objective Description"
    [rows]="2"
    [maxLength]="10000"
  ></app-textarea>
  <app-compare-amendment-output
    *ngIf="amendingGoal?.priorVersionId && isAdditionalQuant"
    class="mr-1"
    [amendmentId]="amendmentId"
    [amendmentFinalized]="amendmentIsFinalized"
    [amendmentDate]="lastFinalizedDate"
    [priorVersionId]="amendingGoal?.priorVersionId"
    [isActive]="amendingGoal?.isActive"
    [changedVersionText]="formGroup.get('description').value"
    [currentVersionText]="priorVersionAdditionalQuantData?.description"
    [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
    changedPrefixText="Proposed: "
  >
  </app-compare-amendment-output>
  <app-iep-data-points
    [isAdditionalQuant]="isAdditionalQuant"
    [primaryUnitOfMeasurement]="primaryUnitOfMeasurement"
    [primaryTargetValue]="primaryTargetValue"
    [caseSummary]="caseSummary"
    [maxTargetValue]="maxTargetValue"
    [formGroup]="primaryMeasurement"
    [rubricMaxCalc]="rubricMaxCalc"
    [combinedObjective]="combinedGoal"
    [secondMeasurement]="false"
    [amendingGoal]="amendingGoal"
    [amendmentId]="amendmentId"
    [amendmentFinalized]="amendmentIsFinalized"
    [lastFinalizedDate]="lastFinalizedDate"
  >
  </app-iep-data-points>

  <app-select *ngIf="isAdditionalQuant && !combinedGoal" label="Objective Status" formControlName="status" [options]="milestoneStatuses">
  </app-select>
  <div class="mt-2">
    <div
      class="display-flex align-items-center flex-wrap"
      [ngClass]="{
        'justify-content-end': !advancedVisible,
        'justify-content-between mb-2': advancedVisible
      }"
    >
      <h3 class="my-0" *ngIf="advancedVisible">Advanced Options</h3>
      <div>
        <button
          *ngIf="!isEditingTaskSkillMilestone && combinedGoal && !isAdditionalQuant && advancedVisible"
          mat-raised-button
          aria-label="Add Objective(s)"
          type="button"
          class="mb-2 mr-2"
          color="primary"
          (click)="addSkillsMilestone()"
          tabindex="0"
        >
          Add Objective(s)
        </button>
        <button
          *ngIf="advancedVisible && combinedGoal && !isAdditionalQuant"
          class="mb-2"
          mat-raised-button
          attr.aria-label="{{ combinedGoal === true ? 'Cancel' : '' }} Add Combined Objectives"
          color="{{ combinedGoal === true ? 'warn' : 'primary' }}"
          (click)="toggleCombinedObjectives()"
          tabindex="0"
        >
          {{ combinedGoal === true ? 'Cancel' : '' }}
          Add Combined Objectives
        </button>
        <button
          mat-raised-button
          attr.aria-label="{{ advancedVisible ? 'Hide ' : 'Open ' }} Advanced Options"
          *ngIf="!combinedGoal"
          type="button"
          color="accent"
          (click)="advancedVisible = !advancedVisible"
          tabindex="0"
        >
          {{ advancedVisible ? 'Hide ' : 'Open ' }}
          Advanced Options
        </button>
      </div>
    </div>
    <div
      *ngIf="maxTargetValue !== null && formGroup.get('primaryMeasurement.learnersGoalTarget').value > maxTargetValue"
      class="text-danger"
    >
      *Scale rating level must be greater than or equal to target value.
    </div>

    <div
      *ngIf="maxSecondaryTargetValue !== null && formGroup.get('secondaryMeasurement.learnersGoalTarget').value > maxSecondaryTargetValue"
      class="text-danger"
    >
      *Scale rating level must be greater than or equal to target value.
    </div>

    <mat-card *ngIf="advancedVisible && !combinedGoal" class="card--plain card--shadow py-2 my-2">
      <app-help-directional-text>
        <p class="mb-2">
          Advanced options may include adding a
          <a
            (click)="onOpenHelp($event, helpSection.IepGoal, iepGoalHelp.SecondMeasurement)"
            class="text-lower text-underline"
            tabindex="0"
            role="link"
          >
            {{ iepGoalHelp.SecondMeasurement }} </a
          >,
          <a
            (click)="onOpenHelp($event, helpSection.IepGoal, iepGoalHelp.Scales)"
            class="text-lower text-underline"
            tabindex="0"
            role="link"
          >
            {{ iepGoalHelp.Scales }} </a
          >,
          <a
            (click)="onOpenHelp($event, helpSection.IepGoal, iepGoalHelp.Milestones)"
            class="text-lower text-underline"
            tabindex="0"
            role="link"
          >
            {{ iepGoalHelp.Milestones }} </a
          >,
          <a
            (click)="onOpenHelp($event, helpSection.IepGoal, iepGoalHelp.Objectives)"
            class="text-lower text-underline"
            tabindex="0"
            role="link"
          >
            {{ iepGoalHelp.Objectives }}
          </a>
          , and/or
          <a
            (click)="onOpenHelp($event, helpSection.IepGoal, iepGoalHelp.CombinedObjectives)"
            class="text-lower text-underline"
            tabindex="0"
            role="link"
          >
            {{ iepGoalHelp.CombinedObjectives }} </a
          >.
        </p>
      </app-help-directional-text>
      <div class="display-flex align-items-center justify-content-start">
        <button
          class="mb-2 mr-2"
          mat-raised-button
          attr.aria-label="{{ secondMeasurement ? 'Cancel ' : 'Add' }} Second Measurement"
          *ngIf="!combinedGoal && !isAdditionalQuant"
          color="primary"
          (click)="onSecondMeasurementClick()"
          color="{{ secondMeasurement ? 'warn' : 'primary' }}"
          tabindex="0"
        >
          {{ secondMeasurement ? 'Cancel ' : 'Add' }}
          Second Measurement
        </button>
        <button
          class="mr-2 mb-2"
          mat-raised-button
          attr.aria-label="{{ formGroup.controls.scaleSelected.value ? 'Cancel' : 'Add' }} Scale"
          *ngIf="!combinedGoal"
          type="button"
          color="{{ formGroup.controls.scaleSelected.value ? 'warn' : 'primary' }}"
          (click)="onScalesCheckbox(false)"
          tabindex="0"
        >
          {{ formGroup.controls.scaleSelected.value ? 'Cancel' : 'Add' }}
          Scale
        </button>
        <button
          class="mr-2 mb-2"
          mat-raised-button
          attr.aria-label="{{ formGroup.controls.tieredMilestoneSelected.value ? 'Cancel' : 'Add' }} Milestone(s)"
          *ngIf="!combinedGoal && !isAdditionalQuant && !hasAdditionalQuantData"
          type="button"
          color="{{ formGroup.controls.tieredMilestoneSelected.value ? 'warn' : 'primary' }}"
          (click)="onTieredMilestoneCheckbox(false)"
          tabindex="0"
        >
          {{ formGroup.controls.tieredMilestoneSelected.value ? 'Cancel' : 'Add' }}
          Milestone(s)
        </button>
        <button
          *ngIf="
            advancedVisible &&
            !isEditingTaskSkillMilestone &&
            !isAdditionalQuant &&
            !formGroup.controls.tieredMilestoneSelected.value &&
            !formGroup.controls.secondaryTieredMilestoneSelected.value
          "
          mat-raised-button
          aria-labelledby="Add Objective(s)"
          type="button"
          class="mr-2 mb-2"
          color="primary"
          (click)="addSkillsMilestone()"
          tabindex="0"
        >
          Add Objective(s)
        </button>
        <button
          *ngIf="
            !formGroup.controls.scaleSelected.value &&
            !formGroup.controls.tieredMilestoneSelected.value &&
            !isAdditionalQuant &&
            !secondMeasurement &&
            !hasAdditionalQuantData
          "
          class="mb-2"
          mat-raised-button
          attr.aria-label="{{ combinedGoal === true ? 'Cancel' : '' }} Add Combined Objectives"
          color="{{ combinedGoal === true ? 'warn' : 'primary' }}"
          (click)="toggleCombinedObjectives()"
          tabindex="0"
        >
          {{ combinedGoal === true ? 'Cancel' : '' }}
          Add Combined Objectives
        </button>
      </div>

      <ng-container *ngIf="formGroup.get('tieredMilestoneSelected').value === true">
        <app-iep-milestones
          [secondary]="false"
          [tieredMilestones]="tieredMilestones"
          [unitOfMeasurement]="primaryMeasurement.get('unitOfMeasurement')?.value"
          [priorUnitOfMeasurement]="priorVersionQuantDataMeasurement?.unitOfMeasurement"
          (updated)="tieredMilestones = $event"
        ></app-iep-milestones>
      </ng-container>

      <ng-container *ngIf="formGroup.get('scaleSelected').value === true && !combinedGoal">
        <h4
          class="text-primary mb-0"
          [ngClass]="{
            'mt-2': formGroup.get('tieredMilestoneSelected').value === true && formGroup.get('scaleSelected').value === true,
            'mt-0': formGroup.get('tieredMilestoneSelected').value === false && formGroup.get('scaleSelected').value === true
          }"
        >
          Scale
        </h4>
        <app-iep-scale-levels
          [caseSummary]="caseSummary"
          [scaleRatings]="scaleRatings"
          [secondary]="false"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [lastFinalizedDate]="lastFinalizedDate"
          (updated)="scaleRatings = $event"
        ></app-iep-scale-levels>
      </ng-container>
    </mat-card>
  </div>
</form>

<div *ngIf="secondMeasurement && !isAdditionalQuant && !combinedGoal">
  <h4 class="text-primary my-0">Second Measurement</h4>
  <app-iep-data-points
    [isAdditionalQuant]="isAdditionalQuant"
    [useSameUnitAndTarget]="useSameUnitAndTarget"
    [primaryUnitOfMeasurement]="primaryUnitOfMeasurement"
    [primaryTargetValue]="primaryTargetValue"
    [secondMeasurement]="true"
    [caseSummary]="caseSummary"
    [formGroup]="secondaryMeasurement"
    [amendingGoal]="amendingGoal"
    [amendmentId]="amendmentId"
    [amendmentFinalized]="amendmentIsFinalized"
    [lastFinalizedDate]="lastFinalizedDate"
  ></app-iep-data-points>
  <div class="display-flex justify-content-end align-items-center flex-wrap">
    <button
      *ngIf="!isAdditionalQuant && combinedGoal"
      mat-raised-button
      attr.aria-label="{{ isEditingTaskSkillMilestone ? 'Cancel ' : 'Add ' }} Objective"
      type="button"
      color="primary"
      (click)="isEditingTaskSkillMilestone = !isEditingTaskSkillMilestone"
      tabindex="0"
    >
      {{ isEditingTaskSkillMilestone ? 'Cancel ' : 'Add ' }}
      Objective
    </button>
    <button
      mat-raised-button
      attr.aria-label="{{ secondaryAdvancedVisible ? 'Hide ' : 'Open ' }} Advanced Options"
      *ngIf="!isAdditionalQuant && !combinedGoal"
      type="button"
      color="accent"
      class="mr-2"
      (click)="secondaryAdvancedVisible = !secondaryAdvancedVisible"
      tabindex="0"
    >
      {{ secondaryAdvancedVisible ? 'Hide ' : 'Open ' }}
      Advanced Options
    </button>
  </div>

  <form *ngIf="secondaryAdvancedVisible" [formGroup]="formGroup">
    <mat-card class="card--plain card--shadow pt-2 mt-2">
      <div class="row">
        <div class="col-md-12 col-lg-6 display-flex align-items-center">
          <button
            class="mr-2 mb-2"
            mat-raised-button
            attr.aria-label="{{ formGroup.controls.secondaryTieredMilestoneSelected.value ? 'Cancel' : 'Add' }} Milestone(s)"
            *ngIf="!isAdditionalQuant && !hasAdditionalQuantData"
            type="button"
            color="{{ formGroup.controls.secondaryTieredMilestoneSelected.value ? 'warn' : 'primary' }}"
            (click)="onTieredMilestoneCheckbox(true)"
            tabindex="0"
          >
            {{ formGroup.controls.secondaryTieredMilestoneSelected.value ? 'Cancel' : 'Add' }}
            Milestone(s)
          </button>
          <button
            class="mr-2 mb-2"
            mat-raised-button
            attr.aria-label="{{ formGroup.controls.secondaryScalesSelected.value ? 'Cancel' : 'Add' }} Scale(s)"
            type="button"
            color="{{ formGroup.controls.secondaryScalesSelected.value ? 'warn' : 'primary' }}"
            (click)="onScalesCheckbox(true)"
            tabindex="0"
          >
            {{ formGroup.controls.secondaryScalesSelected.value ? 'Cancel' : 'Add' }}
            Scale(s)
          </button>
        </div>
      </div>

      <ng-container *ngIf="formGroup.get('secondaryTieredMilestoneSelected').value === true">
        <app-iep-milestones
          [secondary]="true"
          [tieredMilestones]="tieredMilestones"
          [unitOfMeasurement]="secondaryMeasurement.get('unitOfMeasurement')?.value"
          [priorUnitOfMeasurement]="priorVersionSecondaryQuantDataMeasurement?.unitOfMeasurement"
          (updated)="tieredMilestones = $event"
        ></app-iep-milestones>
      </ng-container>

      <ng-container *ngIf="formGroup.get('secondaryScalesSelected').value === true">
        <app-iep-scale-levels
          [caseSummary]="caseSummary"
          [scaleRatings]="scaleRatings"
          [secondary]="true"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [lastFinalizedDate]="lastFinalizedDate"
          (updated)="scaleRatings = $event"
        ></app-iep-scale-levels>
      </ng-container>
    </mat-card>
  </form>
</div>

<ng-container *ngIf="combinedGoal">
  <h4
    class="text-primary mb-0"
    [ngClass]="{
      'mt-2': formGroup.get('secondaryTieredMilestoneSelected').value === true && formGroup.get('secondaryScalesSelected').value === true,
      'mt-0': formGroup.get('secondaryTieredMilestoneSelected').value === false && formGroup.get('secondaryScalesSelected').value === true
    }"
  >
    Scale
  </h4>
  <app-iep-scale-levels
    [caseSummary]="caseSummary"
    [rubricMax]="rubricMax"
    [combinedGoal]="true"
    [scaleRatings]="scaleRatings"
    [secondary]="false"
    [amendmentId]="amendmentId"
    [amendmentFinalized]="amendmentIsFinalized"
    [lastFinalizedDate]="lastFinalizedDate"
    (updated)="scaleRatings = $event"
  ></app-iep-scale-levels
></ng-container>
