import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogComingSoonComponent } from 'src/app/shared/components/coming-soon/coming-soon.component';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { AuthService } from '../../../auth/auth.service';
import { AppPermissions } from '../../../permissions';
import { CaseSummary, CaseUserRole, IntakeType, ScheduledCaseTransferUpdate } from '../../../shared/models/case';
import { AchieveConfigService } from '../../../shared/services/achieve-config-service/achieve-config.service';
import { CaseService } from '../../../shared/services/case/case.service';
import { ReassignFacilitatorComponent } from '../../modals/reassign-facilitator/reassign-facilitator.component';
import { Evaluation } from '../../models/evaluation';
import { EvaluationProxyService } from '../../services/evaluation-proxy.service';
import { EvaluationService } from '../../services/evaluation.service';

@Component({
  selector: 'app-evaluation-assessment-info',
  templateUrl: './evaluation-assessment-info.component.html',
  styleUrls: ['./evaluation-assessment-info.component.scss'],
})
export class EvaluationAssessmentInfoComponent implements OnInit {
  @Input() evaluation: Evaluation;
  me = '';
  isPartB: boolean;
  permissions = AppPermissions;
  caseSummary: CaseSummary;

  constructor(
    public router: Router,
    readonly authService: AuthService,
    private readonly evaluationService: EvaluationService,
    private readonly evaluationProxyService: EvaluationProxyService,
    private readonly caseService: CaseService,
    private dialog: MatDialog,
    private readonly achieveConfigService: AchieveConfigService
  ) {}

  async ngOnInit() {
    this.me = this.authService.user.id;
    this.isPartB = this.evaluation.intakeType === IntakeType.PartB;
    this.evaluationProxyService.evaluationChange.subscribe((evaluation) => (this.evaluation = evaluation));
    this.evaluationProxyService.assignmentChange.subscribe(([field, assignments]) => {
      this.evaluation.progressComplete = assignments.filter((x) => x.status.label === 'Complete').length;
      this.evaluation.total = assignments.length;
    });
    this.caseSummary = await this.caseService.getCaseSummary(this.evaluation.caseId).toPromise();
  }

  onOpenLearnerCalendar() {
    const config: NewWindowConfig = {
      path: `cases/${this.evaluation.caseId}/evaluation/${this.evaluation.id}/learner-calendar`,
      popup: true,
      width: '1280px',
    };
    openNewWindow(config);
  }

  calculateWidth() {
    return `${(this.evaluation.progressComplete / this.evaluation.total) * 100}%`;
  }

  reassignFacilitator() {
    const dialogRef = this.dialog.open(ReassignFacilitatorComponent, {
      width: '768px',
      data: {
        evaluationId: this.evaluation.id,
        ownerId: this.evaluation.owner.id,
      },
    });
    dialogRef.afterClosed().subscribe(async (selectedUser) => {
      if (selectedUser) {
        await this.doTransfer(selectedUser, CaseUserRole.Aea);
      }
    });
  }

  private async doTransfer(id: string, role: CaseUserRole) {
    const transferDate = await this.achieveConfigService.getDateTimeFromTimeString(new Date(), '').toPromise();
    const scheduledTransfer: ScheduledCaseTransferUpdate = {
      userId: id,
      role,
      date: transferDate,
    };
    this.caseService.scheduleTransfer(this.evaluation.caseId, scheduledTransfer).subscribe(() => {
      this.evaluationService
        .get(this.evaluation.id)
        .subscribe((evaluation) => this.evaluationProxyService.evaluationChange.next(evaluation));
    });
  }

  comingSoon() {
    this.dialog.open(DialogComingSoonComponent, {
      width: '725px',
    });
  }

  canViewCalendar() {
    if (this.isPartB) {
      return this.authService.isAllowedByCaseId(this.caseSummary?.id, undefined, AppPermissions.ViewCalendarPartB);
    }
    return (
      this.authService.isAllowedByCaseId(this.caseSummary?.id, undefined, AppPermissions.ViewCalendarPartC) && this.evaluation?.isInitial
    );
  }
}
