import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Help, HelpArea, HelpDictionary } from '../../services/help/help';
import { HelpModalConfig } from '../../services/help/help.service';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
})
export class HelpModalComponent implements OnInit {
  help: Help[];
  area: HelpArea;
  helpArea = HelpArea;
  canBrowse: boolean;
  searchText = '';
  filteredResults: Help[];
  selectedItem: HelpDictionary = {} as HelpDictionary;
  nestedTerm: string;
  selected;
  iFrameLoaded = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: HelpModalConfig,
    private dialogRef: MatDialogRef<HelpModalComponent>,
    private sanitizer: DomSanitizer
  ) {
    this.help = this.dialogData.help;
    this.canBrowse = this.dialogData.canBrowse;
    this.nestedTerm = this.dialogData.nestedTerm || null;
    this.area = this.dialogData.area || null;
    this.selected = {
      item: this.dialogData.item,
      section: this.dialogData.section,
    };
  }

  ngOnInit(): void {
    this.filteredResults = this.help;
    this.searchText = this.selectedItem.label || '';
    this.getInitialItem();
  }

  filterDictionary() {
    if (this.searchText !== '') {
      this.filteredResults = this.filteredResults.map((help: Help) => ({
        title: help.title,
        dictionary: help.dictionary.filter((d) => d.label.toUpperCase().indexOf(this.searchText.toUpperCase()) > -1),
      }));
    } else {
      this.filteredResults = this.help;
    }
  }

  getInitialItem() {
    if (this.selected.item && this.selected.section) {
      const match = this.filteredResults
        .filter((r: Help) => r.title === this.selected.section)[0]
        .dictionary.filter((d: HelpDictionary) => d.label === this.selected.item)[0];
      this.selectedItem = match;
      this.searchText = match.label;
      this.filterDictionary();
    }
  }

  onIframeLoaded(): void {
    this.iFrameLoaded = true;
  }

  onSelectHelp(term: HelpDictionary) {
    this.selectedItem = term;
  }

  clearSearch() {
    this.searchText = '';
    this.filteredResults = this.help;
  }

  sanitizeUrl(link: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  onClose() {
    this.dialogRef.close();
  }
}
