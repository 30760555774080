import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges } from '@angular/core';
import { TextFieldComponent } from '../text-field/text-field.component';
import { LoggerService } from '../../../services/logger/logger.service';
import { ControlContainer, FormGroupDirective, NgControl } from '@angular/forms';
import { NumberFieldComponent } from '../number-field/number-field.component';

@Component({
  selector: 'app-overwrite-number-field',
  templateUrl: './overwrite-number-field.component.html',
  styleUrls: ['./overwrite-number-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class OverwriteNumberFieldComponent extends NumberFieldComponent implements OnInit, OnChanges {
  @Input()
  sourceData: { [key: string]: any };
  @Output() blurMe = new EventEmitter<any>();

  // Optional input if source field name different from dest field name
  @Input()
  sourceFieldName: string;

  dataSourceValue: any;
  undoValue: string;

  replaceWithSourceValue() {
    this.undoValue = this.control.value;
    this.control.setValue(this.dataSourceValue);
    this.control.markAsTouched();
    this.control.markAsDirty();
    this.control.updateValueAndValidity();

    const undoClearer = this.control.valueChanges.subscribe(() => {
      this.undoValue = null;
      undoClearer.unsubscribe();
    });
  }

  undoReplaceWithSourceValue() {
    this.control.setValue(this.undoValue);
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
    this.undoValue = null;
  }

  constructor(
    logger: LoggerService,
    @Self()
    @Optional()
    ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(logger, ngControl, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();
    const key = this.sourceFieldName || this.controlName;
    this.dataSourceValue = this.sourceData[key];
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  internalBlur(event: any) {
    if (this.blurMe) {
      this.blurMe.emit(true);
    }
  }
}
