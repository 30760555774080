import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IntakeType } from 'src/app/shared/models/case';
import { PurposeOfMeeting, PurposeOfMeetingManagement } from './purpose-of-meeting';

@Injectable({
  providedIn: 'root',
})
export class PurposeOfMeetingService {
  private baseUrl(id = '') {
    return `api/purposes-of-meetings/${id}`;
  }

  constructor(private http: HttpClient) {}

  get(intakeType: IntakeType) {
    return this.http.get<PurposeOfMeeting[]>(this.baseUrl() + intakeType);
  }

  getForManagement() {
    return this.http.get<PurposeOfMeetingManagement[]>(this.baseUrl());
  }

  add(purpose: PurposeOfMeetingManagement) {
    return this.http.post<PurposeOfMeetingManagement[]>(this.baseUrl(), purpose);
  }

  update(id: string, purpose: PurposeOfMeetingManagement) {
    return this.http.put<PurposeOfMeetingManagement>(this.baseUrl(id), purpose);
  }
}
