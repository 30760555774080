import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { CaseSummary } from '../../../../shared/models/case';
import { DeactivationService } from '../../../../shared/services/deactivation.service';

@Component({
  selector: 'app-data-history-part-c',
  templateUrl: './data-history-part-c.component.html',
  styleUrls: ['./data-history-part-c.component.scss'],
})
export class DataHistoryPartCComponent extends BaseComponent implements OnInit {
  @Input() case: CaseSummary = {} as CaseSummary;
  constructor(deactivationService: DeactivationService) {
    super(deactivationService);
  }

  ngOnInit(): void {}
}
