import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataSource } from '@angular/cdk/collections';

import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-paginator[dataSource]',
})
export class PaginatorDataSourceDirective<T> implements OnChanges {
  private dataSourceSubscription: Subscription;
  @Input() pageSize = 5;
  @Input() pageSizeOptions = [5, 10, 20];
  @Input() dataSource: DataSource<T> | Observable<ReadonlyArray<T> | T[]> | ReadonlyArray<T> | T[];

  @HostBinding('class.read-only') readonly = false;

  constructor(private paginator: MatPaginator) {
    this.paginator.pageSize = this.pageSize;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource) {
      if (this.dataSourceSubscription) {
        this.dataSourceSubscription.unsubscribe();
      }

      if (changes.dataSource.currentValue && changes.dataSource.currentValue instanceof MatTableDataSource) {
        changes.dataSource.currentValue.paginator = this.paginator;
        this.subscribeToDataSource(changes.dataSource.currentValue);
      }
    }
  }

  private subscribeToDataSource(dataSource: MatTableDataSource<unknown>): void {
    this.dataSourceSubscription = dataSource
      .connect()
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.paginator.length = dataSource.data?.length ?? 0;
        this.setPageSizeOptions();
        this.paginator.hidePageSize = this.paginator.pageSizeOptions.length === 0;
        this.readonly = this.paginator.pageSize >= this.paginator.length;
      });
  }

  private setPageSizeOptions(): void {
    const dataSource = this.dataSource as MatTableDataSource<T>;
    const numberOfItems = dataSource?.filteredData?.length || dataSource?.data?.length;
    const pzo = [];
    if (this.pageSizeOptions?.length) {
      let inList = false;
      for (const option of this.pageSizeOptions) {
        if (numberOfItems >= option) {
          pzo.push(option);
        }
        inList = inList || numberOfItems === option;
      }
      if (!inList && this.pageSizeOptions.length > 0 && numberOfItems > this.pageSizeOptions[0]) pzo.push(numberOfItems);
    }
    this.paginator.pageSizeOptions = pzo;

    if (this.paginator.pageSizeOptions.length && !this.paginator.pageSizeOptions.includes(this.paginator.pageSize)) {
      const options = this.paginator.pageSizeOptions.filter((x) => x <= this.paginator.pageSize);
      if (options.length > 0) this.paginator.pageSize = options[options.length - 1];
    }

    const largestOption = this.paginator.pageSizeOptions[this.paginator.pageSizeOptions.length - 1];
    if (this.paginator.pageSize && this.paginator.pageSize > largestOption) {
      this.paginator.pageSize = largestOption;
    }
  }
}
