import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactAttempt } from 'src/app/shared/models/contact-attempt';

@Injectable({
  providedIn: 'root',
})
export class FamilyContactHistoryService {
  constructor(private http: HttpClient) {}

  get(caseId: string) {
    return this.http.get<Array<ContactAttempt>>(`api/cases/${caseId}/family-contact-history`);
  }

  create(caseId: string, contactAttempt: ContactAttempt) {
    return this.http.post(`api/cases/${caseId}/family-contact-history`, contactAttempt);
  }

  getForLearner(learnerId: string) {
    return this.http.get<Array<ContactAttempt>>(`api/learners/${learnerId}/family-contact-history`);
  }
}
