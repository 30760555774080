<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Goals Information</h3>
<div mat-dialog-content class="py-3">
  <h3 class="mt-0 mb-2">Goals</h3>

  <mat-accordion class="accordion accordion--close">
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="display-flex align-items-center justify-content-between flex-1">
            <h3 class="my-0 text-primary text-normal">History</h3>

            <span> {{ dataSource.data.length }} Amendments </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="table-overflow app-table">
        <table mat-table [dataSource]="dataSource" matSort class="w-100">
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Amended</th>

            <td mat-cell *matCellDef="let element">
              {{ element.changedOn | dateFormat }}
            </td>
          </ng-container>

          <ng-container matColumnDef="changes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Changes Made</th>

            <td mat-cell *matCellDef="let element">{{ element.changedByName }} - {{ element.change }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [dataSource]="dataSource"></mat-paginator>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div mat-dialog-actions class="actions display-flex justify-content-end">
  <div>
    <button mat-button aria-label="Delete Icon" (click)="dismiss()" cdkFocusInitial tabindex="0">Close</button>
  </div>
</div>
