import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import {
  AddConsentDialogResult,
  FamilyConsentAddComponent,
} from 'src/app/consent/family-consent/modals/family-consent-add/family-consent-add.component';
import { TodoCreate } from 'src/app/dashboard/user-todo-list/todo-models';
import { TodoService } from 'src/app/dashboard/user-todo-list/todo.service';
import { EarlyAccessQuestionnaire } from 'src/app/evaluation/models/early-access-questionnaire';
import { Intake } from 'src/app/evaluation/models/intake';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import {
  DialogData,
  UploadDocumentationModalComponent,
} from 'src/app/shared/modals/upload-documentation-modal/upload-documentation-modal.component';
import { ConsentForm, ConsentFormType, consentFormTitlesByType } from 'src/app/shared/models/fiie-consent/consent-form';
import { ConsentStatus } from 'src/app/shared/models/fiie-consent/consent-status';
import { FamilyConsentStatus } from 'src/app/shared/models/fiie-consent/family-consent-status';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { usStates } from 'src/app/shared/models/us-states';
import { ConsentFormService } from 'src/app/shared/services/consent-form/consent-form.service';
import { LocationService } from 'src/app/shared/services/location/location.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { openPdfWindow } from 'src/app/shared/windowHelpers';
import { SignedFormService } from 'src/app/signed-forms/signed-form.service';
import { DeactivationService } from '../../../../../shared/services/deactivation.service';
import { ProviderInformation } from '../../../models/health-information';
import { IntakeSections } from './../../../../early-access-intake/intake-sections/intake-sections';
import { Title } from './../../../../models/intake';

@Component({
  selector: 'app-provider-info-form',
  templateUrl: './provider-info-form.component.html',
  styleUrls: ['./provider-info-form.component.scss'],
})
export class ProviderInfoFormComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() formGroup: FormGroup;
  @Input() intake: Intake;
  @Input() questionnaire: EarlyAccessQuestionnaire;
  @Input() documentationInfo: FormGroup;
  @Input() isQuestionnaire: boolean;
  @Input() providerInfo: ProviderInformation;
  @Input() locked: boolean;
  @Input() importQuestionnaireSelected: boolean;
  @Output() upload = new EventEmitter();
  @Output() documentUploaded = new EventEmitter();
  @Output() deleteDocument = new EventEmitter();
  intakeSections = IntakeSections;
  quickActionChange: string;
  consentFormType = ConsentFormType;
  releaseAndExchangeConsents: ConsentForm[];

  usStates = usStates.map((x) => new KeyValuePair(x, x));

  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  phcpTitleOptions: KeyValuePair[] = [
    new KeyValuePair(Title.Dr, 'Dr'),
    new KeyValuePair(Title.MD, 'MD'),
    new KeyValuePair(Title.DO, 'DO'),
    new KeyValuePair(Title.PA, 'PA'),
    new KeyValuePair(Title.ARNP, 'ARNP'),
  ];

  unknownCheckboxOption: KeyValuePair[] = [new KeyValuePair(true, 'Yes')];

  constructor(
    private readonly locationService: LocationService,
    private dialog: MatDialog,
    private readonly consentFormService: ConsentFormService,
    private readonly todoService: TodoService,
    private readonly authService: AuthService,
    private readonly reportingService: ReportingService,
    private readonly notificationService: NotificationService,
    private readonly signedFormService: SignedFormService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.initializeControls();
    this.formGroup.patchValue(this.providerInfo);
    if (!this.isQuestionnaire) {
      if (this.providerInfo.phcpUnknown) {
        this.formGroup.controls.phcpName.setValidators(null);
      } else {
        this.formGroup.controls.phcpName.setValidators(Validators.required);
      }
      this.formGroup.controls.phcpName.updateValueAndValidity();
    }

    if (this.locked) {
      this.formGroup.controls.phcpUnknown.disable();
      this.formGroup.controls.phcpTitle.disable();
    }

    if (!this.isQuestionnaire) {
      this.formGroup.controls.phcpUnknown.valueChanges.subscribe((change) => {
        if (change) {
          this.formGroup.controls.phcpName.setValidators(null);
        } else {
          this.formGroup.controls.phcpName.setValidators(Validators.required);
        }
        this.formGroup.controls.phcpName.updateValueAndValidity();
      });
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      changes.importQuestionnaireSelected?.currentValue !== changes.importQuestionnaireSelected?.previousValue &&
      this.importQuestionnaireSelected
    ) {
      this.importProviderInfoFromQuestionnaire();
      this.formGroup.markAsDirty();
    }
    if (changes.intake?.currentValue != changes.intake?.previousValue) {
      if (changes.intake?.currentValue) {
        const consents = await this.consentFormService.getConsentForms(this.intake.caseId).toPromise();
        this.releaseAndExchangeConsents = consents;
      }
    }
  }

  importProviderInfoFromQuestionnaire() {
    const questionnaireProviderInfo = this.questionnaire?.healthInfo?.providerInformation;
    for (const prop in questionnaireProviderInfo) {
      if (questionnaireProviderInfo[prop] !== null && questionnaireProviderInfo[prop] !== '') {
        this.providerInfo[prop] = questionnaireProviderInfo[prop];
      }
    }
    this.formGroup.patchValue(this.providerInfo);
  }

  private initializeControls() {
    this.formGroup.addControl('phcpUnknown', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('phcpClinicAgencyName', new FormControl(null));
    this.formGroup.addControl('phcpPhone', new FormControl(null));
    this.formGroup.addControl('phcpFax', new FormControl(null));
    this.formGroup.addControl('phcpStreetAddress', new FormControl(null));
    this.formGroup.addControl('phcpCity', new FormControl(null));
    this.formGroup.addControl('phcpState', new FormControl(null));
    this.formGroup.addControl('phcpZipCode', new FormControl(null));
    this.formGroup.addControl('phcpTitle', new FormControl(null));
    this.formGroup.addControl('phcpName', new FormControl(null, !this.isQuestionnaire ? Validators.required : null));
    this.formGroup.addControl('phcpEmail', new FormControl(null));
    this.formGroup.addControl('phcpPhoneExtension', new FormControl(null));
    this.formGroup.addControl('phcpQuickActions', new FormControl({ value: null, disabled: this.locked }));
    this.formGroup.addControl('haveMedicalSpecialists', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('involvedWithOtherAgency', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('phcpReceivedSignedConsentForm', new FormControl(null));
    this.formGroup.addControl('phcpReceivedSignedConsentFormUserId', new FormControl(null));
    this.formGroup.addControl('phcpReceivedSignedConsentFormDate', new FormControl(null));
    this.formGroup.addControl('phcpRecordsReceived', new FormControl(null));
    this.formGroup.addControl('medicalSpecialists', new FormArray([]));
    this.formGroup.addControl('otherAgencies', new FormArray([]));
  }

  onUpload(formData: FormData) {
    this.upload.emit(formData);
  }

  onDeleteDocument(documentId: string) {
    this.deleteDocument.emit(documentId);
  }

  populateLocationFromZipCode() {
    const zipCodeControl = this.formGroup.get('phcpZipCode');
    const zipCode = zipCodeControl.value;

    if (!zipCodeControl.valid) {
      return;
    }

    if (zipCode) {
      this.locationService.getLocationData(zipCode.substring(0, 5)).subscribe(
        (res) => {
          if (res) {
            this.formGroup.patchValue({
              phcpCity: res.city,
              phcpState: 'IA',
            });
          }
        },
        () => {
          this.formGroup.patchValue({
            phcpCity: null,
            phcpState: null,
          });
        }
      );
    }
  }

  async openEmptyReleaseForm() {
    const rex = ConsentFormType.ReleaseAndExchangeInformation;
    const consentForm: ConsentForm = {
      caseId: this.intake.caseId,
      type: rex,
      aeaId: null,
      districtId: null,
      agency: null,
      statuses: null,
      createdOn: new Date(),
      isComplete: false,
    };
    const pdf = await this.consentFormService.renderEmptyPdf(this.intake.caseId, consentForm);
    console.log('got blank pdf:', pdf);
    const url = URL.createObjectURL(pdf);
    window.open(url);
  }

  get getConsentToReleaseLabel(): string {
    return this.hasConsentToRelease()
      ? 'Download Consent to Release and Exchange Information'
      : 'Create Consent to Release and Exchange Information';
  }

  hasConsentToRelease(): boolean {
    const existing = this.findConsentToRelease();
    return !!existing;
  }

  findConsentToRelease() {
    const phcp = this.formGroup?.value;
    if (!phcp || !this.releaseAndExchangeConsents) return null;
    //Try to find a soft match for existing consent to release and exchange for the phcp.
    return this.consentFormService
      .findConsentsForAgency(this.releaseAndExchangeConsents, ConsentFormType.ReleaseAndExchangeInformation, {
        name: phcp.phcpClinicAgencyName,
        contact: phcp.phcpName,
      })
      .find((cf) => cf.signedFormId);
  }

  getOrCreateConsentToRelease() {
    const rex = ConsentFormType.ReleaseAndExchangeInformation;

    const phcp = this.formGroup.value;
    const existing = this.findConsentToRelease();
    if (existing) {
      this.viewSignedForm(existing.signedFormId);
      return;
    }

    const dialogRef: MatDialogRef<FamilyConsentAddComponent, AddConsentDialogResult> = this.dialog.open(FamilyConsentAddComponent, {
      width: '800px',
      data: {
        defaultValues: {
          type: { value: rex },
          agency: {
            name: phcp.phcpClinicAgencyName,
            contact: phcp.phcpName,
            phone: phcp.phcpPhone,
            address: phcp.phcpStreetAddress,
            email: phcp.phcpEmail,
            city: phcp.phcpCity,
            state: phcp.phcpState,
            zipCode: phcp.phcpZipCode,
          },
        },
        availConsentFormTypeOptions: [new KeyValuePair({ value: rex, caseId: this.intake.caseId }, consentFormTitlesByType[rex])],
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const consentForm: ConsentForm = {
          caseId: this.intake.caseId,
          type: data.type?.value,
          aeaId: data.aea?.aeaId,
          districtId: data.district?.districtId,
          agency: data.agency,
          statuses: [
            {
              status: data.status,
              dateReceived: data.dateReceived,
              dateSigned: data.dateSigned,
              signedBy: data.signedBy,
              documents: [],
              comments: data.notes,
            },
          ],
          createdOn: new Date(),
          isComplete: false,
        };
        this.consentFormService.createConsentForms(this.intake.caseId, consentForm).subscribe(async (consents) => {
          this.releaseAndExchangeConsents.push(...consents);
          const consent = consents[0];
          const signedFormId = consents && consents[0]?.signedFormId;
          if (signedFormId) {
            this.viewSignedForm(consent.signedFormId);
          }
        });
      }
    });
  }

  async viewSignedForm(signedFormId: string) {
    const pdf = await this.signedFormService.getSignedFormPdf(signedFormId);
    window.open(pdf.url);
  }

  hasPHCPDocument() {
    return this.intake?.documentationInfo?.intakeDocuments.some((x) => x.isPHCP === true);
  }

  downloadIcdPhysicianLetter() {
    if (!this.hasPHCPDocument()) {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        data: {
          question: 'Signed Consent Form',
          subQuestion: 'Have you received the signed consent form?',
        },
        width: '450px',
      });
      dialogRef.afterClosed().subscribe((answer) => {
        if (answer) {
          // Update intake with records received answer
          const today = new Date();
          this.formGroup.controls.phcpReceivedSignedConsentForm.patchValue(answer);
          this.formGroup.controls.phcpReceivedSignedConsentFormDate.patchValue(today);
          this.formGroup.controls.phcpReceivedSignedConsentFormUserId.patchValue(this.authService.user.id);

          // Add a to do list item to upload the consent form
          const reminder: TodoCreate = {
            userId: this.authService.user.id,
            caseId: this.intake.caseId,
            title: 'Upload Consent to Release and Exchange',
            showOnCalendar: true,
            dueDate: new Date(today.setDate(today.getDate() + 7)),
          };
          this.todoService.create(reminder).subscribe();

          this.reportingService.createIcdPhysicianLetter(this.intake.learner.id).subscribe({
            next: (documentId: string) => openPdfWindow(this.intake.learner.id, documentId),
            error: (err) =>
              this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
                this.notificationService.alert(err.error, "Couldn't open output")
              ),
          });
        }
      });
    }
  }

  indicateRecordsReceived() {
    if (!this.formGroup.controls.phcpRecordsReceived.value) {
      this.formGroup.controls.phcpRecordsReceived.setValue(true);
      this.formGroup.markAsDirty();
    }
  }

  onOpenUpload() {
    if (!this.hasPHCPDocument()) {
      const dialogRef = this.dialog.open(UploadDocumentationModalComponent, {
        data: {
          type: ConsentFormType.ReleaseAndExchangeInformation,
          fileName: 'Consent to Release and Exchange Information',
          learnersName: this.isQuestionnaire
            ? this.questionnaire?.childInfo?.firstName + ' ' + this.questionnaire?.childInfo?.lastName
            : this.intake?.childInfo?.firstName + ' ' + this.intake?.childInfo?.lastName,
        } as DialogData,
        width: '728px',
      });

      dialogRef.afterClosed().subscribe((result: Array<any>) => {
        if (result) {
          const formData = new FormData();
          result.forEach((element) => {
            formData.append('titles', element.title);
            formData.append('documents', element.file, element.file.name);
            formData.append('types', element.type);
            formData.append('sections', IntakeSections.ProviderInfo);
            formData.append('consents', 'true');
            formData.append('isPHCPs', 'true');
          });
          this.createConsent(formData);
        }
      });
    }
  }

  createConsent(formData: FormData) {
    const consentForm = {} as ConsentForm;
    consentForm.caseId = this.intake?.caseId;
    consentForm.type = ConsentFormType.ReleaseAndExchangeInformation;
    this.consentFormService.createConsentForm(this.intake?.caseId, consentForm).subscribe((consentFormAdded) => {
      const consentStatus = {} as ConsentStatus;
      consentStatus.consentFormId = consentFormAdded.id;
      consentStatus.status = FamilyConsentStatus.Approved;
      consentStatus.dateSigned = new Date();
      this.consentFormService.addConsentFormStatus(this.intake?.caseId, consentStatus).subscribe();
      this.onUpload(formData);
    });
  }

  refreshIntakeAfterDocumentUpload() {
    this.documentUploaded.emit();
  }

  disableIcdDownload() {
    const phcpName = this.formGroup.get('phcpName');
    if (phcpName && !phcpName.value) {
      return true;
    }
    const phcpClientAgencyName = this.formGroup.get('phcpClientAgencyName');
    if (phcpClientAgencyName && !phcpClientAgencyName.value) {
      return true;
    }
    return false;
  }
}
