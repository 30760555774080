import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { StringSizes } from '../../../../../../shared/components/form/constants/constants';
import { yesNoOptions } from '../../../../../../shared/formHelpers';
import { CaseSummary } from '../../../../../../shared/models/case';
import { KeyValuePair } from '../../../../../../shared/models/key-value-pair';
import { ConfirmationDialogComponent, NotificationService } from '../../../../../../shared/services/notification.service';
import {
  DayPercentage,
  DeviceSignificance,
  InstructionLength,
  WeightedMatrixDto,
  WeightedMatrixSpecialCircumstanceDto,
} from '../../../../../models/iep-weighted-matrix';
import { IepDetailWeightedMatrixService } from '../../../../../services/iep-detail-weighted-matrix.service';

@Component({
  selector: 'app-iep-weight-additional-questions',
  templateUrl: './iep-weight-additional-questions.component.html',
  styleUrls: ['./iep-weight-additional-questions.component.scss'],
})
export class IepWeightAdditionalQuestionsComponent implements OnInit {
  @Input() weightedMatrix: WeightedMatrixDto;
  @Input() iepId: string;
  @Input() readOnly = false;
  @Input() caseSummary: CaseSummary;

  @Output() questionsSubmitted = new EventEmitter<WeightedMatrixSpecialCircumstanceDto>();

  isSaving = false;
  yesNoOptions = yesNoOptions;
  percentageOptions: KeyValuePair[] = [
    new KeyValuePair(DayPercentage.LessThan49, 'Less than 49%'),
    new KeyValuePair(DayPercentage.Between50And74, 'Between 50 and 74%'),
    new KeyValuePair(DayPercentage.GreaterThan75, 'Greater than 75%'),
  ];
  instructionLengthOptions: KeyValuePair[] = [
    new KeyValuePair(InstructionLength.FullTimeInstruction, 'Full-time instruction'),
    new KeyValuePair(InstructionLength.PartTimeInstruction, 'Part-time instruction'),
  ];
  deviceSignificanceOptions: KeyValuePair[] = [
    new KeyValuePair(DeviceSignificance.Minor, 'Minor'),
    new KeyValuePair(DeviceSignificance.Significant, 'Significant'),
  ];
  saveSubscription: Observable<any>;
  stringSizes = StringSizes;

  formGroup = new FormGroup({
    id: new FormControl(null),
    weightedMatrixId: new FormControl(null),
    iepId: new FormControl(null),
    assignedTeacher: new FormControl(null),
    whatPercentage: new FormControl(null),
    instructionLength: new FormControl(null),
    isDeviceNew: new FormControl(null),
    dateOfPurchase: new FormControl(null),
    deviceSignificance: new FormControl(null),
    deviceComment: new FormControl(null),
    override: new FormControl(false),
  });

  get hasSpeciallyDesignedInstruction() {
    return this.weightedMatrix?.iepHasSDO;
  }

  get hasAssistiveTechnology() {
    return this.weightedMatrix?.iepHasATO;
  }

  constructor(
    private notificationService: NotificationService,
    private weightedMatrixService: IepDetailWeightedMatrixService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (!this.caseSummary?.id || !this.iepId) {
      this.iepId = this.route.snapshot.paramMap.get('iepId');
      this.caseSummary.id = this.route.snapshot.paramMap.get('caseId');
    }

    if (!this.weightedMatrix) {
      this.weightedMatrixService.getWeightedMatrix(this.caseSummary?.id).subscribe((result) => {
        if (result.succeeded) {
          this.weightedMatrix = result.value;
          this.setUpForm();
        } else {
          this.notificationService.error('Failed to retrieve Weighted Matrix data');
        }
      });
    } else {
      this.setUpForm();
    }
  }

  setUpForm() {
    this.formGroup.controls.assignedTeacher.valueChanges.subscribe((value) => {
      if (value) {
        this.formGroup.controls.whatPercentage.setValidators(Validators.required);
      } else {
        this.formGroup.controls.whatPercentage.setValue(null);
        this.formGroup.controls.whatPercentage.clearValidators();
      }
      this.formGroup.controls.whatPercentage.updateValueAndValidity();
    });

    this.formGroup.controls.isDeviceNew.valueChanges.subscribe((value) => {
      if (value) {
        this.formGroup.controls.dateOfPurchase.setValidators(Validators.required);
      } else {
        this.formGroup.controls.dateOfPurchase.clearValidators();
      }
      this.formGroup.controls.dateOfPurchase.updateValueAndValidity();
    });

    if (this.hasSpeciallyDesignedInstruction) {
      this.formGroup.controls.assignedTeacher.setValidators(Validators.required);
      this.formGroup.controls.instructionLength.setValidators(Validators.required);
    }
    if (this.hasAssistiveTechnology) {
      this.formGroup.controls.isDeviceNew.setValidators(Validators.required);
      this.formGroup.controls.deviceSignificance.setValidators(Validators.required);
    }

    this.formGroup.controls.assignedTeacher.updateValueAndValidity();
    this.formGroup.controls.instructionLength.updateValueAndValidity();
    this.formGroup.controls.isDeviceNew.updateValueAndValidity();
    this.formGroup.controls.deviceSignificance.updateValueAndValidity();

    this.formGroup.patchValue(this.weightedMatrix.weightedMatrixSpecialCircumstance);

    if (this.readOnly) {
      this.formGroup.disable();
    }
  }

  onSubmit() {
    this.isSaving = true;

    const wmSpecialCircumstanceFormData = this.formGroup.value as WeightedMatrixSpecialCircumstanceDto;
    wmSpecialCircumstanceFormData.iepId = this.iepId;
    wmSpecialCircumstanceFormData.weightedMatrixId = this.weightedMatrix.id;
    wmSpecialCircumstanceFormData.isFinalized = true;

    this.weightedMatrixService.saveWeightedMatrixSpecialCircumstance(this.caseSummary?.id, wmSpecialCircumstanceFormData).subscribe(
      (operationResponse) => {
        if (operationResponse.succeeded) {
          if (operationResponse.value.requestOverride) {
            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
              width: '728px',
              data: {
                title: 'Please confirm',
                message:
                  'This information has already been entered for this IEP.  Clicking "Yes" to continue will overwrite the previous values. Do you wish to continue?',
                buttons: { cancel: 'No', ok: 'Yes' },
              },
            });

            dialogRef.afterClosed().subscribe((res) => {
              if (res) {
                // update form and resubmit
                this.formGroup.controls.id.setValue(operationResponse.value.weightedMatrixSpecialCircumstanceId);
                this.formGroup.controls.override.setValue(true);

                this.onSubmit();
              } else {
                window.location.reload();
              }
            });
          } else {
            if (!wmSpecialCircumstanceFormData.id) {
              wmSpecialCircumstanceFormData.id = operationResponse.value.weightedMatrixSpecialCircumstanceId;
              this.weightedMatrix.weightedMatrixSpecialCircumstance = wmSpecialCircumstanceFormData;
              this.formGroup.patchValue(this.weightedMatrix.weightedMatrixSpecialCircumstance);
            }
            this.questionsSubmitted.emit(wmSpecialCircumstanceFormData);
            this.isSaving = false;
          }
        } else {
          this.notificationService.error('Failed to save.');
        }
      },
      () => {
        this.notificationService.error('Failed to save.');
      }
    );
  }
}
