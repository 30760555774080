<app-page-header [pageTitle]="pageTitle" backgroundColor="yellow" [helpLinks]="true" [condensed]="true" [pageTitleColor]="'blue'">
</app-page-header>
<app-dynamic-learner-header></app-dynamic-learner-header>

<ng-container *ngIf="dataLoadCompleted && !generalSurvey">
  <mat-card> You do not have access to this survey. </mat-card>
</ng-container>

<form [formGroup]="formGroup" *ngIf="generalSurvey">
  <mat-card>
    <div class="container-fluid container--not-centered container--form ml-3">
      <div class="row">
        <div class="col-md-12">
          The {{ caseSummary?.learner?.isPK ? 'Early ACCESS' : 'Special Education' }} team has been working on the following goals or
          outcomes for {{ caseSummary?.learner?.firstName }}:
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <ul class="list">
            <li class="list__item" *ngFor="let question of questions">
              <span *ngIf="checkEnabled(question)">
                <h4 class="text-primary my-0">{{ question.question }}</h4>
                <app-textarea *ngIf="question.controlType === 'TextArea'" [formControlName]="question.id" label="Answer"> </app-textarea>
                <fieldset>
                  <app-radio-group
                    *ngIf="question.controlType === 'Radio'"
                    [options]="yesNoOptions"
                    [formControlName]="question.id"
                  ></app-radio-group>
                </fieldset>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <button
        mat-raised-button
        aria-label="Complete"
        color="primary"
        (click)="submit()"
        *ngIf="this.formGroup.disabled === false"
        [isBusy]="activeCall"
        class="mr-2"
        tabindex="0"
      >
        Complete
      </button>
    </div>
  </mat-card>
</form>
