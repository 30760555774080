import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { CaseSummary, IntakeType } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthService } from '../../../auth/auth.service';
import { StringSizes } from '../../../shared/components/form/constants/constants';
import { shortDateFormat } from '../../../shared/dateTimeHelpers';
import { DeleteConfirmationComponent } from '../../../shared/modals/delete-confirmation-modal/delete-confirmation-modal.component';
import { RoutingService } from '../../../shared/services/routing.service';
import { MoveInDetailUpdateDto } from './move-in-detail';
import { MoveInDetailService } from './move-in-detail.service';
import { MoveInDetailsReasonModalComponent } from './move-in-details-reason-modal/move-in-details-reason-modal.component';

@Component({
  selector: 'app-move-in-details',
  templateUrl: './move-in-details.component.html',
  styleUrls: ['./move-in-details.component.scss'],
})
export class MoveInDetailsComponent implements OnInit {
  @Input() caseSummary: CaseSummary;
  submitting = false;
  formGroup: FormGroup;
  shortDateFormat = shortDateFormat;
  stringSizes = StringSizes;
  isNewRecord = false;
  haveAnyFollowingOptions: KeyValuePair[] = [
    new KeyValuePair('fba', 'Functional Behavior Assessment'),
    new KeyValuePair('bip', 'Behavior Intervention Plan'),
    new KeyValuePair('healthPlan', 'Health Plan'),
  ];
  reasonForEligibilityOptions: KeyValuePair[] = [
    new KeyValuePair('QuarterOrMore', '25% Delay or Greater'),
    new KeyValuePair('DocumentedDelay', 'Documented Delay or Diagnosis'),
    new KeyValuePair('InformedClinicalOpinion', 'Informed Clinical Opinion'),
  ];
  get haveAnyFollowingLabel(): string {
    return `Does ${this?.caseSummary?.learner?.firstName} have any of the following?`;
  }

  get today() {
    return dayjs().toDate();
  }

  get movingFromOutOfState() {
    return this.caseSummary?.learner?.movingFromOutOfState;
  }

  get eligibleInLegacySystem() {
    return this.caseSummary?.learner?.eligibleInLegacySystem;
  }

  get eligibleInLegacySystemPartC() {
    return this.caseSummary?.learner?.eligibleInLegacySystemPartC;
  }

  get allowFormEdit() {
    return (
      (this.isNewRecord && this.authService.canEditCase(this.caseSummary)) ||
      this.authService.isSuperAdmin ||
      this.authService.isAchieveDataLead
    );
  }

  get isPartC() {
    return this.caseSummary.intakeType === IntakeType.PartC;
  }

  get learnersBirthDate() {
    return dayjs(this.caseSummary.learner.dateOfBirth).toDate();
  }

  get canCancel() {
    if (!this.formGroup.get('id').value) return false;
    if (!this.authService.isSuperAdmin) return false;
    return this.movingFromOutOfState || this.eligibleInLegacySystem;
  }

  get title() {
    return this.movingFromOutOfState ? 'Move-in' : 'Migration';
  }

  constructor(
    public moveInDetailsService: MoveInDetailService,
    public authService: AuthService,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private readonly routingService: RoutingService
  ) {}

  ngOnInit(): void {
    if (this.isPartC) {
      this.formGroup = new FormGroup({
        id: new FormControl(null),
        dateOfReferral: new FormControl(null, Validators.required),
        dateofConsentForEvaluation: new FormControl(null, Validators.required),
        reasonForEligibility: new FormControl(null, Validators.required),
        description: new FormControl(null, Validators.required),
      });
    } else {
      this.formGroup = new FormGroup({
        id: new FormControl(null),
        dateOfEligibility: new FormControl(null),
        reevaluationDueDate: new FormControl(null, Validators.required),
        currentIepStartDate: new FormControl(null, this.eligibleInLegacySystem ? null : Validators.required),
        haveAnyFollowing: new FormControl(),
      });
    }

    this.moveInDetailsService.get(this.caseSummary.caseId).subscribe((moveInDetails) => {
      this.isNewRecord = !moveInDetails;
      if (moveInDetails) {
        if (this.isPartC) {
          this.formGroup.patchValue({
            id: moveInDetails.id,
            dateOfReferral: moveInDetails.dateofReferral,
            dateofConsentForEvaluation: moveInDetails.dateofConsentForEvaluation,
            reasonForEligibility: moveInDetails.reasonForEligibility,
            description: moveInDetails.description,
          });
        } else {
          const haveAnyFollowingArray = [];
          if (moveInDetails.hasFBA) {
            haveAnyFollowingArray.push('fba');
          }
          if (moveInDetails.hasBIP) {
            haveAnyFollowingArray.push('bip');
          }
          if (moveInDetails.hasHealthPlan) {
            haveAnyFollowingArray.push('healthPlan');
          }

          this.formGroup.patchValue({
            id: moveInDetails.id,
            dateOfEligibility: moveInDetails.dateOfEligibility,
            reevaluationDueDate: moveInDetails.reevaluationDueDate,
            currentIepStartDate: moveInDetails.currentIEPStartDate,
            haveAnyFollowing: haveAnyFollowingArray,
          });
        }
      }
    });
  }

  findHaveAnyValues(key) {
    return this.formGroup.get('haveAnyFollowing').value?.includes(key);
  }

  getReasonForEligibilityLabel(val) {
    return this.reasonForEligibilityOptions.find((x) => x.key === val)?.value;
  }

  onSubmit(): void {
    this.submitting = true;
    const doSave = (reasonForEdit: string) => {
      const haveAnyFollowing = this.formGroup.get('haveAnyFollowing')?.value;
      const model: MoveInDetailUpdateDto = {
        id: this.formGroup.get('id').value,
        dateOfEligibility: this.formGroup.get('dateOfEligibility')?.value,
        reevaluationDueDate: this.formGroup.get('reevaluationDueDate')?.value,
        currentIEPStartDate: this.formGroup.get('currentIepStartDate')?.value,
        hasFBA: !!haveAnyFollowing && !!haveAnyFollowing.includes('fba'),
        hasBIP: !!haveAnyFollowing && !!haveAnyFollowing.includes('bip'),
        hasHealthPlan: !!haveAnyFollowing && !!haveAnyFollowing.includes('healthPlan'),
        reasonForEdit: reasonForEdit,
        dateofReferral: this.formGroup.get('dateOfReferral')?.value,
        dateofConsentForEvaluation: this.formGroup.get('dateofConsentForEvaluation')?.value,
        reasonForEligibility: this.formGroup.get('reasonForEligibility')?.value,
        description: this.formGroup.get('description')?.value,
      };
      this.moveInDetailsService.update(this.caseSummary.caseId, model).subscribe((moveInDetail) => {
        this.formGroup.get('id').patchValue(moveInDetail.id);
        this.submitting = false;
        this.moveInDetailsService.moveInDetailsUpdated.next(this.caseSummary.learnerId);
      });
    };

    if (!this.isNewRecord && (this.authService.isSuperAdmin || this.authService.isAchieveDataLead)) {
      this.dialog
        .open(MoveInDetailsReasonModalComponent, {
          width: '740px',
          data: {
            type: this.movingFromOutOfState ? 'Move-in Details' : 'Migration Details',
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result.didSubmit) {
            doSave(result.reason);
          }
        });
    } else if (this.isNewRecord) {
      doSave('new record');
      this.isNewRecord = false;
    }
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      width: '550px',
      data: {
        question: 'Clicking Delete will delete the move-in details. Please fill in the reason for the deletion to continue.',
      },
    });
    dialogRef.afterClosed().subscribe((val) => {
      if (val?.delete) {
        this.moveInDetailsService.cancel(this.caseSummary.caseId, this.caseSummary.learnerId, val?.cancelReason).subscribe(() => {
          this.notificationService.success('Move-in/Migration cancelled successfully');
          this.moveInDetailsService.moveInDetailsUpdated.next(this.caseSummary.learnerId);
          this.routingService.learnerDashboard(this.caseSummary.learnerId);
        });
      }
    });
  }
}
