import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { shortDateFormat } from '../../../../../../shared/dateTimeHelpers';
import { IepActivitySupportDto } from '../../../../../models/iep-activity-support';
import { IepServiceDto } from '../../../../../models/iep-service';

@Component({
  selector: 'app-view-goal-modal',
  templateUrl: './view-goal-modal.component.html',
  styleUrls: ['./view-goal-modal.component.scss'],
})
export class ViewGoalModalComponent implements OnInit {
  shortDateFormat = shortDateFormat;
  constructor(
    public dialogRef: MatDialogRef<ViewGoalModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      service: IepServiceDto;
      activity: IepActivitySupportDto;
      title: string;
    }
  ) {}
  ngOnInit(): void {}

  getProviderNames(data: any) {
    if (data.otherProvider) {
      return data.otherProviderName;
    } else {
      return data.providers.map((x) => `${x.firstName} ${x.lastName || ''}`).join(', ');
    }
  }

  getScheduleOfServices(schedule) {
    return schedule.map((x) => `${dayjs(x.date).format('MMM DD, YYYY')}  ${x.time || ''}`).join(', ');
  }

  onClose() {
    this.dialogRef.close();
  }
}
