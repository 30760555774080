<h3 class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>View More</h3>

<div class="mat-dialog-content py-2">
  <mat-card *ngIf="plaafp">
    <ul class="list">
      <li class="list__item">
        <h3 class="text-primary mt-0 mb-2">{{ name }}</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="entity.removedByAmendment"
          [priorVersionId]="entity.priorVersionId"
          [changedVersionText]="entity.comments"
          [currentVersionText]="getCommentPriorVersion(entity)"
          [changedPrefixText]="entity?.priorVersionId ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item">
        <h3 class="text-primary mt-0 mb-2">Data Source</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="entity.removedByAmendment"
          [priorVersionId]="entity.priorVersionId"
          [changedVersionText]="entity.dataSource"
          [currentVersionText]="getDatasourcePriorVersion(entity)"
          [changedPrefixText]="entity?.priorVersionId ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
    </ul>
  </mat-card>
</div>

<div class="mat-dialog-actions justify-content-end">
  <button mat-raised-button aria-label="Delete Icon" (click)="onClose()" cdkFocusInitial tabindex="0">Close</button>
</div>
