import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Grade } from 'src/app/shared/models/learner';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { TaggedItem } from 'src/app/tags/tagged-item';
import { TaggingService } from 'src/app/tags/taggingService.service';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { IepAccommodationService } from '../../services/iep-accommodation.service';
import { IepActivitySupportService } from '../../services/iep-activity-support.service';
import { IepAmendmentService } from '../../services/iep-amendment.service';
import { IepGoalService } from '../../services/iep-goal.service';
import { IepServiceService } from '../../services/iep-service.service';

@Component({
  selector: 'app-iep-amendment-end-item-modal',
  templateUrl: './iep-amendment-end-item-modal.component.html',
  styleUrls: ['./iep-amendment-end-item-modal.component.scss'],
})
export class IepAmendmentEndItemModalComponent implements OnInit {
  formGroup = new FormGroup({
    endDate: new FormControl(null, [Validators.required]),
    endReason: new FormControl(null),
    comments: new FormControl(null),
    tagForPwn: new FormControl(null),
  });

  shortDateFormat: any;
  amendmentTag: TaggedItem;
  submitting = false;
  endReasonOptions: KeyValuePair[] = [];

  get showWeightingWarning() {
    if (this.data.type === 'Service') {
      const codes = this.data?.model?.serviceCodes?.map((x) => x.code);
      return codes?.includes('SD0') || codes?.includes('SA0');
    } else {
      return false;
    }
  }

  get goalAreas() {
    return this.data.model.goalAreas.map((x) => x.label).join(', ');
  }

  constructor(
    private iepGoalService: IepGoalService,
    private iepAccommodationService: IepAccommodationService,
    private iepActivitySupportService: IepActivitySupportService,
    private iepServiceService: IepServiceService,
    private iepAmendmentService: IepAmendmentService,
    private learnerService: LearnerService,
    private readonly taggingService: TaggingService,
    public dialogRef: MatDialogRef<IepAmendmentEndItemModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.formGroup.patchValue({
      endDate: this.data.model.amendmentEndDate,
      endReason: this.data.model?.amendmentReasonId,
      comments: this.data.model.amendmentComments,
    });

    if (this.data.type === 'Service') {
      this.formGroup.get('endReason').setValidators(Validators.required);

      this.iepAmendmentService.getAllIepCodes(this.data.iepId).subscribe((iepCodes) => {
        this.endReasonOptions = [];
        if (iepCodes) {
          iepCodes.forEach((iepCode) => {
            this.endReasonOptions.push(new KeyValuePair(iepCode.id, iepCode.label));
          });
        }
        this.learnerService.getByCaseId(this.data?.caseId).subscribe((learner) => {
          if (learner.gradeDescription !== Grade.Kindergarten && learner.gradeDescription !== Grade.PreK) {
            this.endReasonOptions = this.endReasonOptions.filter((x) => x.value !== 'PK to K transition (PKK)');
          }
        });
      });
    }

    this.taggingService.getIepAmendmentonTag(this.data.amendmentId).subscribe((tag) => {
      this.amendmentTag = tag;
      this.formGroup.controls.tagForPwn.setValue(tag.taggedForPwn);
    });
  }

  onSubmit() {
    const callback = (result) => {
      this.submitting = false;
      this.dialogRef.close(result);
    };

    if (this.formGroup.valid) {
      this.submitting = true;
      if (this.data.type === 'Goal') {
        const model = {
          ...this.formGroup.value,
          amendmentId: this.data.amendmentId,
        };
        this.iepGoalService.endGoal(this.data.iepId, this.data.model.id, model).subscribe((x) => {
          callback(x);
        });
      }

      if (this.data.type === 'Service') {
        const model = {
          ...this.formGroup.value,
          amendmentId: this.data.amendmentId,
        };

        this.tagAndEndService(model);
      }

      if (this.data.type === 'Activity/Support') {
        const model = {
          ...this.formGroup.value,
          amendmentId: this.data.amendmentId,
        };
        this.iepActivitySupportService.endActivitySupport(this.data.iepId, this.data.model.id, model).subscribe((x) => {
          callback(x);
        });
      }

      if (this.data.type === 'Accommodation') {
        const model = {
          ...this.formGroup.value,
          amendmentId: this.data.amendmentId,
        };
        this.iepAccommodationService.endAccommodation(this.data.iepId, this.data.model.id, model).subscribe((x) => {
          callback(x);
        });
      }
    }
  }

  onTag() {
    this.formGroup.controls.tagForPwn.setValue(!this.formGroup.controls.tagForPwn.value);

    if (this.amendmentTag) {
      this.amendmentTag.taggedForPwn = this.formGroup.controls.tagForPwn.value;
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  private tagAndEndService(model: any) {
    if (this.amendmentTag) {
      this.taggingService.updateTag(this.amendmentTag).subscribe(() => {
        this.iepServiceService.endService(this.data.iepId, this.data.model.id, model).subscribe((x) => {
          this.submitting = false;
          this.dialogRef.close(x);
        });
      });
    } else {
      this.taggingService
        .addTag({
          caseId: this.data.caseId,
          iepAmendmentId: this.data.amendmentId,
          taggedForPwn: true,
        } as TaggedItem)
        .subscribe(() => {
          this.iepServiceService.endService(this.data.iepId, this.data.model.id, model).subscribe((x) => {
            this.submitting = false;
            this.dialogRef.close(x);
          });
        });
    }
  }
}
