<section class="card-spacing-top">
  <mat-accordion class="accordion" displayMode="flat" multi>
    <mat-expansion-panel class="mat-elevation-z0 no-body-padding" *ngIf="partCCase" #accordionPartC>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="my-0 text-normal mr-3">Early ACCESS</h3>
          <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: mostRecentPartCProceduralSafeguard }"> </ng-container>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-procedural-safeguards-list
        (mostRecentChanged)="onPartCPsChanged($event)"
        [showProvidedOn]="false"
        [learnerId]="learnerId"
        [caseId]="partCCase.id"
      ></app-procedural-safeguards-list>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-elevation-z0 no-body-padding" *ngIf="partBCase" #accordionPartB>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="my-0 text-normal mr-3">Special Education</h3>
          <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: mostRecentPartBProceduralSafeguard }"> </ng-container>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-procedural-safeguards-list
        (mostRecentChanged)="onPartBPsChanged($event)"
        [showProvidedOn]="false"
        [learnerId]="learnerId"
        [caseId]="partBCase.id"
      ></app-procedural-safeguards-list>
    </mat-expansion-panel>
  </mat-accordion>
</section>

<section class="card-spacing-top" *ngIf="cases?.length > 0">
  <app-family-consent
    [isCaseOwner]="isCaseOwner"
    [learnerId]="learnerId"
    [draftReevaluationId]="partBCase?.draftReevaluationId"
    [psgProvided]="!!mostRecentPartCProceduralSafeguard"
    [cases]="cases"
    (documentUploaded)="onConsentDocumentUpload()"
  ></app-family-consent>
</section>

<section class="card-spacing-top">
  <app-family-documents
    [learnerId]="learnerId"
    [learnerHasWorkableCase]="learnerHasWorkableCase"
    [documentUpdated]="consentDocumentUploaded"
  ></app-family-documents>
</section>

<section class="card-spacing-top" *ngIf="partBCase && !isPortalUser && !authService.isVrUser">
  <app-downloadable-forms-list [learnerId]="learnerId" [caseSummary]="partBCase"></app-downloadable-forms-list>
</section>

<div class="page-bottom-leeway"></div>

<ng-template #headerTemplate let-sp>
  <span *ngIf="sp; else partCPSNotProvided">
    The <i>Procedural Safeguards Manual for Parents</i> was last reviewed and provided to the parent(s) on {{ sp.providedOn | dateFormat }}.
    The parent(s) {{ sp.acceptedPrintedCopy ? 'accepted' : 'declined' }} the printed copy.
  </span>
  <ng-template #partCPSNotProvided>
    <span>
      The parent has not yet been provided a printed copy of the
      <i>Procedural Safeguards Manual for Parents</i>.
    </span>
  </ng-template>
</ng-template>
