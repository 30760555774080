import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { BaseComponent } from '../../../../../../shared/components/base-component/base-component';
import { DeactivationService } from '../../../../../../shared/services/deactivation.service';

@Component({
  selector: 'app-adjust-esy-modal',
  templateUrl: './adjust-esy-modal.component.html',
  styleUrls: ['./adjust-esy-modal.component.scss'],
})
export class AdjustEsyModalComponent extends BaseComponent implements OnInit {
  showDescribeOtherMethodField = false;

  formGroup = new FormGroup({
    monitoringFrequencyTime: new FormControl(null, Validators.required),
    monitoringFrequencyPeriod: new FormControl(null, Validators.required),
    methodsToReport: new FormControl(null, Validators.required),
    otherMethodToReport: new FormControl(null),
    sameRateAsPeersReported: new FormControl(null, [Validators.required]),
    sameRateFrequency: new FormControl(null, Validators.min(1)),
    sameRatePeriod: new FormControl(null),
    differentRateFrequency: new FormControl(null, Validators.min(1)),
    differentRatePeriod: new FormControl(null),
  });

  methodsToReportOptions: KeyValuePair[] = [
    new KeyValuePair('An IEP report with report cards and progress reports', 'An IEP report with report cards and progress reports'),
    new KeyValuePair('Updated copies of IEP goal pages', 'Updated copies of IEP goal pages'),
    new KeyValuePair('Family Portal', 'Family Portal'),
    new KeyValuePair('Other', 'Other'),
  ];

  frequencyPeriodOptions: KeyValuePair[] = [
    new KeyValuePair('Day', 'Day'),
    new KeyValuePair('Week', 'Week'),
    new KeyValuePair('Month', 'Month'),
    new KeyValuePair('Quarter', 'Quarter'),
    new KeyValuePair('Year', 'Year'),
  ];

  frequencySelectOptions: KeyValuePair[] = [
    new KeyValuePair('day', 'per day'),
    new KeyValuePair('week', 'per week'),
    new KeyValuePair('month', 'per month'),
    new KeyValuePair('year', 'per year'),
  ];

  get showCompareOutput() {
    return this.dialogData.showCompareOutput;
  }

  get amendments() {
    return this.dialogData.amendments;
  }

  get amendmentId() {
    return this.dialogData.amendmentId;
  }

  get amendmentIsFinalized() {
    return this.dialogData.amendmentIsFinalized;
  }

  get lastFinalizedDate() {
    return this.dialogData.lastFinalizedDate;
  }

  get hasAmendment() {
    return this.dialogData.hasAmendment;
  }

  get hasOpenAmendment() {
    return this.dialogData.hasOpenAmendment;
  }

  get esyGoal() {
    return this.dialogData.esyGoal;
  }

  childFirstName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private dialogRef: MatDialogRef<AdjustEsyModalComponent>,
    deactivationService: DeactivationService,
    private changeDetector: ChangeDetectorRef
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.childFirstName = this.dialogData.childFirstName;
    this.patchValues();
    this.addValueChangeHandlers();
    this.setReportingProgressValueChanges();
  }

  addValueChangeHandlers() {
    const sub = this.formGroup.get('methodsToReport').valueChanges.subscribe((values) => {
      this.showDescribeOtherMethodField = values?.includes('Other');
      this.changeDetector.detectChanges();
    });

    this.subscriptions.add(sub);
  }

  private setReportingProgressValueChanges() {
    this.formGroup.controls.sameRateAsPeersReported.valueChanges.subscribe((change) => {
      if (change) {
        this.setControlsToRequired(['sameRatePeriod', 'sameRateFrequency']);
        this.resetControlsValueValidatorAndMarkAsUntounched(['differentRateFrequency', 'differentRatePeriod']);
      } else if (change === false) {
        this.setControlsToRequired(['differentRateFrequency', 'differentRatePeriod']);
        this.resetControlsValueValidatorAndMarkAsUntounched(['sameRatePeriod', 'sameRateFrequency']);
      }
      this.formGroup.controls.sameRateFrequency.setValidators(Validators.min(1));
      this.formGroup.controls.differentRateFrequency.setValidators(Validators.min(1));
      this.formGroup.controls.sameRateFrequency.updateValueAndValidity();
      this.formGroup.controls.differentRateFrequency.updateValueAndValidity();
    });
  }

  private setControlsToRequired(controls: string[]) {
    controls.forEach((control) => {
      this.formGroup.controls[control].setValidators(Validators.required);
      this.formGroup.controls[control].updateValueAndValidity();
    });
  }

  private resetControlsValueValidatorAndMarkAsUntounched(controls: string[]) {
    controls.forEach((control) => {
      this.formGroup.controls[control].setValue(null);
      this.formGroup.controls[control].setValidators(null);
      this.formGroup.controls[control].markAsUntouched();
      this.formGroup.controls[control].updateValueAndValidity();
    });
  }

  getFrequencyText(useFormGroup = false) {
    let monitoringFrequencyTime = useFormGroup
      ? this.formGroup.get('monitoringFrequencyTime').value
      : this.esyGoal?.priorVersion?.monitoringFrequencyTime;
    let monitoringFrequencyPeriod = useFormGroup
      ? this.formGroup.get('monitoringFrequencyPeriod').value
      : this.esyGoal?.priorVersion?.monitoringFrequencyPeriod;

    if (!monitoringFrequencyTime) {
      monitoringFrequencyTime = this.esyGoal.iepGoal?.frequencyAmount;
    }

    if (!monitoringFrequencyPeriod) {
      monitoringFrequencyPeriod = this.esyGoal.iepGoal?.frequencyPeriod;
    }

    return `${monitoringFrequencyTime || '-'} times ${monitoringFrequencyPeriod || '-'}`;
  }

  getHowOftenProgressReportedText(useFormGroup = false) {
    let returnVar = '';

    const sameRateAsPeersReported = useFormGroup
      ? this.formGroup.get('sameRateAsPeersReported').value
      : this.esyGoal?.priorVersion?.sameRateAsPeersReported ?? this.esyGoal?.iepGoal.sameRateAsPeersReported;

    const sameRateFrequency = useFormGroup
      ? this.formGroup.get('sameRateFrequency').value
      : this.esyGoal?.priorVersion?.sameRateFrequency ?? this.esyGoal?.iepGoal?.sameRateFrequency;

    const sameRatePeriod = useFormGroup
      ? this.formGroup.get('sameRatePeriod').value
      : this.esyGoal?.priorVersion?.sameRatePeriod ?? this.esyGoal?.iepGoal?.sameRatePeriod;

    const differentRateFrequency = useFormGroup
      ? this.formGroup.get('differentRateFrequency').value
      : this.esyGoal?.priorVersion?.differentRateFrequency ?? this.esyGoal?.iepGoal?.differentRateFrequency;

    const differentRatePeriod = useFormGroup
      ? this.formGroup.get('differentRatePeriod').value
      : this.esyGoal?.priorVersion?.differentRatePeriod ?? this.esyGoal?.iepGoal?.differentRatePeriod;

    returnVar += sameRateAsPeersReported ? 'At the same rate as non-disabled peers' : 'At a different rate than non-disabled peers';

    if (sameRateAsPeersReported) {
      returnVar += ' ' + sameRateFrequency + ' times per ' + sameRatePeriod;
    }

    if (!sameRateAsPeersReported) {
      returnVar += ' ' + differentRateFrequency + ' times per ' + differentRatePeriod;
    }

    return returnVar;
  }

  getMethodsToReportText(isFormGroup, reportingMethods: any, otherMethod: string) {
    let modifiedMethods = !isFormGroup ? reportingMethods : reportingMethods?.join(', ');
    if (modifiedMethods && modifiedMethods?.includes('Other')) {
      if (modifiedMethods?.includes(', Other')) {
        modifiedMethods = modifiedMethods.replace(', Other', `, ${otherMethod}`);
      } else {
        modifiedMethods = modifiedMethods.replace('Other', `${otherMethod}`);
      }
    }
    return modifiedMethods;
  }

  patchValues() {
    this.formGroup.patchValue({
      monitoringFrequencyTime: this.dialogData.esyGoal.monitoringFrequencyTime ?? this.dialogData.esyGoal.iepGoal?.frequencyAmount,
      monitoringFrequencyPeriod: this.dialogData.esyGoal.monitoringFrequencyPeriod ?? this.dialogData.esyGoal.iepGoal?.frequencyPeriod,
      sameRateAsPeersReported: this.dialogData.esyGoal.sameRateAsPeersReported ?? this.dialogData.esyGoal.iepGoal?.sameRateAsPeersReported,
      sameRateFrequency: this.dialogData.esyGoal.sameRateFrequency ?? this.dialogData.esyGoal.iepGoal?.sameRateFrequency,
      sameRatePeriod: this.dialogData.esyGoal.sameRatePeriod ?? this.dialogData.esyGoal.iepGoal?.sameRatePeriod,
      differentRateFrequency: this.dialogData.esyGoal.differentRateFrequency ?? this.dialogData.esyGoal.iepGoal?.differentRateFrequency,
      differentRatePeriod: this.dialogData.esyGoal.differentRatePeriod ?? this.dialogData.esyGoal.iepGoal?.differentRatePeriod,
      methodsToReport: this.dialogData.esyGoal.methodsToReport?.split(', ') ?? this.dialogData.esyGoal.iepGoal?.methodsToReport,
      otherMethodToReport: this.dialogData.esyGoal.otherMethodToReport ?? this.dialogData.esyGoal.iepGoal?.otherMethodToReport,
    });
  }

  onSave() {
    const goal = this.dialogData.esyGoal;
    goal.monitoringFrequencyTime = this.formGroup.get('monitoringFrequencyTime').value;
    goal.monitoringFrequencyPeriod = this.formGroup.get('monitoringFrequencyPeriod').value;
    goal.sameRateAsPeersReported = this.formGroup.get('sameRateAsPeersReported').value;
    goal.sameRateFrequency = this.formGroup.get('sameRateFrequency').value;
    goal.sameRatePeriod = this.formGroup.get('sameRatePeriod').value;
    goal.differentRateFrequency = this.formGroup.get('differentRateFrequency').value;
    goal.differentRatePeriod = this.formGroup.get('differentRatePeriod').value;
    goal.methodsToReport = this.formGroup.get('methodsToReport').value?.join(', ');
    goal.otherMethodToReport = this.formGroup.get('otherMethodToReport').value;

    if (this.formGroup.valid) {
      this.dialogRef.close(goal);
    }
  }

  onClearRadio(formControlName): void {
    this.formGroup.get(formControlName).setValue(null);
  }

  onClose(): void {
    this.onSave();
    this.dialogRef.close();
  }

  get currentPMFrequencyTime() {
    return this.dialogData.esyGoal.monitoringFrequencyTime ?? this.dialogData.esyGoal.iepGoal?.frequencyAmount;
  }

  get currentPMFrequencyPeriod() {
    return this.dialogData.esyGoal.monitoringFrequencyPeriod ?? this.dialogData.esyGoal.iepGoal?.frequencyPeriod;
  }
}
