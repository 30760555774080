<mat-accordion class="accordion accordion--short" multi>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Overview</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <app-esy-view-overview></app-esy-view-overview>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Goals</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <app-esy-view-goals></app-esy-view-goals>
  </mat-expansion-panel>
</mat-accordion>

<div class="page-bottom-leeway"></div>
