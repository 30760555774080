import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EligibilityBasedOn, EligibilityIcoReason, EligibilityIcoSource, Evaluation } from 'src/app/evaluation/models/evaluation';
import { EvaluationProxyService } from 'src/app/evaluation/services/evaluation-proxy.service';
import { EvaluationService } from 'src/app/evaluation/services/evaluation.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { AlertConfig } from 'src/app/shared/components/page-alert/page-alert.component';
import { EvaluationDomain } from 'src/app/shared/models/evaluation-domain';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { CanComponentDeactivate, DeactivationService } from 'src/app/shared/services/deactivation.service';
import { EvaluationDomainService } from 'src/app/shared/services/evaluation-domains/evaluation-domain.service';
import { MemoryStorageService } from 'src/app/shared/services/memory-storage/memory-storage.service';
import { reasonsValidator, sourcesValidator, summaryValidator } from '../evaluation-summary/evaluation-summary.validators';

@Component({
  selector: 'app-continued-eligibility',
  templateUrl: './continued-eligibility.component.html',
  styleUrls: ['./continued-eligibility.component.scss'],
})
export class ContinuedEligibilityComponent extends BaseComponent implements OnInit, CanComponentDeactivate {
  areas: EvaluationDomain[];
  formGroup = this.fb.group(
    {
      eligibilityDetermination: ['', Validators.required],
      eligibilityDecision: ['', Validators.required],
      eligibilityBasedOn: [''],
      twentyFiveDelayAreas: [''],
      eligibilityIcoSources: this.getIcoSources(),
      eligibilityIcoReasons: this.getIcoReasons(),
      isEligibilityDecisionFinalized: false,
    },
    {
      validators: [sourcesValidator, reasonsValidator],
    }
  );
  evaluation: Evaluation;
  disableUndoButton = false;
  loading = false;
  isFinalizing = false;
  eligibilityBasedOn = EligibilityBasedOn;
  selectedAreas: string[] = [];
  eligibleConditionAlert: AlertConfig = {
    status: 'info',
    message: 'If the child is Eligible based on a Diagnosed Condition, the Intake - Medical Diagnosis section must be completed.',
  };

  eligibilityDecisionOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];
  basedOnOptions: KeyValuePair[] = [
    new KeyValuePair('QuarterOrMore', '25% Delay or Greater'),
    new KeyValuePair('DocumentedDelay', 'Documented Delay or Diagnosis'),
    new KeyValuePair('InformedClinicalOpinion', 'Informed Clinical Opinion'),
  ];

  save = new Observable<boolean>((observer) => {
    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };
    if (!this.formGroup.dirty || this.isSaving) {
      done();
      return;
    }
    this.isSaving = true;
    this.saveForm().subscribe((res) => {
      this.evaluation = res;
      this.storage.setKey('currentEvaluation', this.evaluation);
      done();
    });
  });

  get eligibilityDeterminationLabel() {
    return `Based on the review of existing information by the IFSP Team, is an evaluation needed to determine if ${this.evaluation?.learner?.firstName} continues to be eligible for Early ACCESS?`;
  }

  get eligibilityDecisionLabel() {
    return `Does ${this.evaluation?.learner?.firstName} continues to be eligible for Early ACCESS?`;
  }

  get eligibleBasedOnLabel() {
    return `${this.evaluation?.learner?.firstName} continues to be eligible based on...`;
  }

  get documentedDelayItems() {
    return this.evaluation.diagnosisFromIntake;
  }

  get eligibilityIcoSources() {
    return (this.formGroup.controls.eligibilityIcoSources as FormArray).controls as FormGroup[];
  }

  get eligibilityIcoReasons() {
    return (this.formGroup.controls.eligibilityIcoReasons as FormArray).controls as FormGroup[];
  }

  get rawEligibilityBasedOn() {
    const rawValues = this.formGroup.getRawValue();
    return rawValues['eligibilityBasedOn'];
  }

  constructor(
    private readonly storage: MemoryStorageService,
    private readonly fb: FormBuilder,
    private readonly evaluationService: EvaluationService,
    private readonly evaluationDomainService: EvaluationDomainService,
    private readonly evaluationProxyService: EvaluationProxyService,
    private readonly router: Router,
    deactivationService: DeactivationService,
    private dialog: MatDialog
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.evaluation = this.storage.getKey('currentEvaluation', true);
    this.watchEligibilityDecision();
    this.watchEligibilityBasedOn();
    this.patchForm();
    this.getDomainAreas();

    if (this.evaluation.isEligibilityDecisionFinalized) {
      this.disableOnFinalization();
    } else {
      this.saveSubscription = this.formGroup.valueChanges;
      this.startAutosaving();
    }
  }

  private patchForm() {
    this.formGroup.get('eligibilityDetermination').patchValue(this.evaluation.eligibilityDetermination);
    this.formGroup.get('eligibilityDecision').patchValue(this.evaluation.eligibilityDecision);
    this.formGroup.get('eligibilityBasedOn').patchValue(this.evaluation.eligibilityBasedOn);
    this.formGroup.get('twentyFiveDelayAreas').patchValue(this.evaluation.twentyFiveDelayAreas);
    const sources = this.formGroup.get('eligibilityIcoSources') as FormArray;
    if (this.evaluation.eligibilityIcoSources) {
      for (let i = 0; i < this.evaluation.eligibilityIcoSources.length; i++) {
        for (let j = 0; j < sources.controls.length; j++) {
          const group = sources.controls[j] as FormGroup;
          if (group.get('label').value === this.evaluation.eligibilityIcoSources[i].source) {
            group.get('source').patchValue(true);
            group.get('summary').patchValue(this.evaluation.eligibilityIcoSources[i].summary);
          }
        }
      }
    }
    const reasons = this.formGroup.get('eligibilityIcoReasons') as FormArray;
    if (this.evaluation.eligibilityIcoReasons) {
      for (let i = 0; i < this.evaluation.eligibilityIcoReasons.length; i++) {
        for (let j = 0; j < reasons.controls.length; j++) {
          const group = reasons.controls[j] as FormGroup;
          if (group.get('label').value === this.evaluation.eligibilityIcoReasons[i].reason) {
            group.get('reason').patchValue(true);
            group.get('summary').patchValue(this.evaluation.eligibilityIcoReasons[i].summary);
          }
        }
      }
    }
  }

  getDomainAreas() {
    this.evaluationDomainService.get().subscribe((res) => {
      this.areas = res.filter((x) => !x.isPartB && x.isRequired);
      const areaIds = res.map((x) => x.id);
      areaIds.forEach((areaId) => {
        if (this.evaluation.twentyFiveDelayAreas.includes(areaId)) {
          this.selectedAreas.push(areaId);
        }
      });
    });
  }

  onAddConsentForEvaluation() {
    this.router.navigate(['learner-management', this.evaluation?.learner?.id, 'documentation']);
  }

  isAreaChecked(id: string) {
    return this.selectedAreas?.some((x) => x === id);
  }

  onAreaSelect(event: MatButtonToggleChange) {
    if (event.source.checked) {
      this.selectedAreas.push(event.value);
    } else {
      const index = this.selectedAreas.findIndex((x) => x === event.value);
      if (index > -1) {
        this.selectedAreas.splice(index, 1);
      }
    }
    this.formGroup.get('twentyFiveDelayAreas').setValue(this.selectedAreas, { emitEvent: true });
    this.formGroup.markAsDirty();
  }

  updateMedicalDiagnoses() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/cases', this.evaluation?.caseId, 'evaluation', 'early-access-intake', this.evaluation?.caseId])
    );
    window.open(url, '_blank');
  }

  getLatestMedicalDiagnoses() {
    this.loading = true;
    this.evaluationService.get(this.evaluation.id).subscribe((res) => {
      this.evaluation.diagnosisFromIntake = res.diagnosisFromIntake;
      this.loading = false;
    });
  }

  finalizeEligibility() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: { subQuestion: 'Clicking Yes will finalize the eligibility decision and prevent further changes from being made.' },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (!!res) {
        this.isFinalizing = true;
        this.formGroup.get('isEligibilityDecisionFinalized').setValue(true);
        this.stopAutosaving();
        this.saveForm().subscribe(
          (saveRes) => {
            this.evaluation = saveRes;
            this.formGroup.reset(this.formGroup.value);
            this.storage.setKey('currentEvaluation', this.evaluation);
            this.isFinalizing = false;
            this.disableOnFinalization();
            this.evaluationProxyService.eligibilityFinalized.next(this.evaluation.eligibilityDecision);
          },
          (err) => {
            this.isFinalizing = false;
            this.formGroup.get('isEligibilityDecisionFinalized').setValue(false);
            this.evaluation.isEligibilityDecisionFinalized = false;
            this.startAutosaving();
          }
        );
      }
    });
  }

  private watchEligibilityDecision() {
    this.formGroup.get('eligibilityDecision').valueChanges.subscribe((value) => {
      const eligibilityBasedOnControl = this.formGroup.get('eligibilityBasedOn');
      if (value) {
        eligibilityBasedOnControl.addValidators(Validators.required);
      } else {
        eligibilityBasedOnControl.clearValidators();
        eligibilityBasedOnControl.setValue(null);
        this.formGroup.get('twentyFiveDelayAreas').setValue([]);
        (this.formGroup.get('eligibilityIcoSources') as FormArray).controls.forEach((control) => {
          control.get('source').setValue(null);
          control.get('summary').setValue(null);
        });
        (this.formGroup.get('eligibilityIcoReasons') as FormArray).controls.forEach((control) => {
          control.get('reason').setValue(null);
          control.get('summary').setValue(null);
        });
        this.selectedAreas = [];
      }
      eligibilityBasedOnControl.updateValueAndValidity();
    });
  }

  private watchEligibilityBasedOn() {
    this.formGroup.get('eligibilityBasedOn').valueChanges.subscribe((value) => {
      if (value === 'QuarterOrMore') {
        this.formGroup.get('twentyFiveDelayAreas').addValidators(Validators.required);
      } else {
        this.formGroup.get('twentyFiveDelayAreas').removeValidators(Validators.required);
        this.formGroup.get('twentyFiveDelayAreas').setValue([]);
        this.selectedAreas = [];
      }
      if (value !== 'InformedClinicalOpinion') {
        (this.formGroup.get('eligibilityIcoSources') as FormArray).controls.forEach((control) => {
          control.get('source').setValue(null);
          control.get('summary').setValue(null);
        });
        (this.formGroup.get('eligibilityIcoReasons') as FormArray).controls.forEach((control) => {
          control.get('reason').setValue(null);
          control.get('summary').setValue(null);
        });
      }
      this.formGroup.get('twentyFiveDelayAreas').updateValueAndValidity();
    });
  }

  private getIcoSources(): FormArray {
    const icoSources = [
      'Review (medical or early intervention records from other agencies/states)',
      'Interview (evaluator, parent, or physician statements)',
      'Observations',
      'Tests (evaluation results)',
    ].map((x) =>
      this.fb.group(
        {
          label: [x],
          source: [null],
          summary: [null],
        },
        {
          validators: [summaryValidator('source')],
        }
      )
    );
    return this.fb.array(icoSources);
  }

  private getIcoReasons(): FormArray {
    const icoReasons = [
      'Atypical Development or Atypical Behaviors',
      'Behavior not easily captured by screening or evaluation methods',
      'Lack of progress/regression of skills',
      'Other',
    ].map((x) =>
      this.fb.group(
        {
          label: [x],
          reason: [null],
          summary: [null],
        },
        {
          validators: [summaryValidator('reason')],
        }
      )
    );

    return this.fb.array(icoReasons);
  }

  private disableOnFinalization() {
    this.formGroup.disable();
    this.disableUndoButton = true;
  }

  private saveForm() {
    const values = { ...this.formGroup.value };
    if (this.formGroup.get('eligibilityBasedOn').value === 'InformedClinicalOpinion') {
      this.getIcoDataForSave(values);
    }
    return this.evaluationService.saveContinuedEligibilityInformation(this.evaluation.id, values);
  }

  private getIcoDataForSave(values) {
    values.eligibilityIcoSources = values.eligibilityIcoSources
      .filter((group) => group.source)
      .map((x) => {
        return {
          evaluationId: this.evaluation.id,
          source: x.label,
          summary: x.summary,
        } as EligibilityIcoSource;
      });

    values.eligibilityIcoReasons = values.eligibilityIcoReasons
      .filter((group) => group.reason)
      .map((x) => {
        return {
          evaluationId: this.evaluation.id,
          reason: x.label,
          summary: x.summary,
        } as EligibilityIcoReason;
      });
  }
}
