import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { LoggerService } from '../../../services/logger/logger.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { AchieveConfigService } from '../../../services/achieve-config-service/achieve-config.service';

@Component({
  selector: 'app-overwrite-date-picker',
  templateUrl: './overwrite-date-picker.component.html',
  styleUrls: ['./overwrite-date-picker.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class OverwriteDatePickerComponent extends DatePickerComponent implements OnInit, OnChanges {
  @Input()
  sourceData: { [key: string]: any };

  // Optional input if source field name different from dest field name
  @Input()
  sourceFieldName: string;

  dataSourceValue: any;
  undoValue: string;
  shortDateFormat = shortDateFormat;
  replaceWithSourceValue() {
    const key = this.sourceFieldName || this.controlName;
    this.undoValue = this.control.value;
    this.control.setValue(this.dataSourceValue);
    this.control.markAsTouched();
    this.control.markAsDirty();
    this.control.updateValueAndValidity();

    const undoClearer = this.control.valueChanges.subscribe(() => {
      this.undoValue = null;
      undoClearer.unsubscribe();
    });
  }

  undoReplaceWithSourceValue() {
    this.control.setValue(this.undoValue);
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
    this.undoValue = null;
  }

  constructor(parentForm: FormGroupDirective, logger: LoggerService, achieveConfigService: AchieveConfigService) {
    super(parentForm, logger, achieveConfigService);
  }

  ngOnInit() {
    super.ngOnInit();
    const key = this.sourceFieldName || this.controlName;
    this.dataSourceValue = this.sourceData[key];
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }
}
