import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { ConsentModule } from '../consent/consent.module';
import { IfspModule } from '../ifsp/ifsp.module';
import { AppMatModule } from '../shared/app-mat.module';
import { SharedModule } from '../shared/shared.module';
import { ClpComponent } from './clp/clp.component';
import { GoalPlanComponent } from './clp/components/goal-plan/goal-plan.component';
import { EsyHeaderComponent } from './esy/esy-header/esy-header.component';
import { EsyNavComponent } from './esy/esy-header/esy-nav/esy-nav.component';
import { EsyComponent } from './esy/esy.component';
import { AdjustEsyModalComponent } from './esy/steps/esy-goals/components/adjust-esy-modal/adjust-esy-modal.component';
import { EsyChooseGoalModalComponent } from './esy/steps/esy-goals/components/esy-choose-goal-modal/esy-choose-goal-modal.component';
import { EsyTableDisplayComponent } from './esy/steps/esy-goals/components/esy-table-display/esy-table-display.component';
import { ViewGoalModalComponent } from './esy/steps/esy-goals/components/view-goal-modal/view-goal-modal.component';
import { EsyGoalsComponent } from './esy/steps/esy-goals/esy-goals.component';
import { EsyIncompleteDataReportComponent } from './esy/steps/esy-overview/esy-incomplete-data-report/esy-incomplete-data-report.component';
import { EsyOverviewComponent } from './esy/steps/esy-overview/esy-overview.component';
import { EsyViewGoalsComponent } from './esy/steps/esy-view/components/esy-view-goals/esy-view-goals.component';
import { EsyViewOverviewComponent } from './esy/steps/esy-view/components/esy-view-overview/esy-view-overview.component';
import { EsyViewComponent } from './esy/steps/esy-view/esy-view.component';
import { IepHeaderComponent } from './iep-header/iep-header.component';
import { IepNavComponent } from './iep-header/iep-nav/iep-nav.component';
import { IepPlaafpComponent } from './iep-plaafp/iep-plaafp.component';
import { CourseActivitiesComponent } from './iep-plaafp/secondary-transition/pse-activities/course-activities/course-activities.component';
import { PseActivitiesComponent } from './iep-plaafp/secondary-transition/pse-activities/pse-activities.component';
import { PseSummaryComponent } from './iep-plaafp/secondary-transition/pse-summary/pse-summary.component';
import { TransferOfRightsComponent } from './iep-plaafp/secondary-transition/transfer-of-rights/transfer-of-rights.component';
import { TransitionAssessmentComponent } from './iep-plaafp/secondary-transition/transition-assessment/transition-assessment.component';
import { IepRoutingModule } from './iep-routing.module';
import { IepSpecialFactorsBarComponent } from './iep-special-factors-bar/iep-special-factors-bar.component';
import { IepComponent } from './iep.component';
import { IepAccommodationsViewMoreComponent } from './modals/iep-accommodations-view-more/iep-accommodations-view-more.component';
import { IepActivitySupportsViewMoreComponent } from './modals/iep-activity-supports-view-more/iep-activity-supports-view-more.component';
import { IepAmendmentEndItemModalComponent } from './modals/iep-amendment-end-item-modal/iep-amendment-end-item-modal.component';
import { IepAmendmentModalComponent } from './modals/iep-amendment-modal/iep-amendment-modal.component';
import { IepCreateBipModalComponent } from './modals/iep-create-bip-modal/iep-create-bip-modal.component';
import { IepServicesViewMoreComponent } from './modals/iep-services-view-more/iep-services-view-more.component';
import { IepSurveyInviteCreatorModalComponent } from './modals/iep-survey-invite-creator-modal/iep-survey-invite-creator-modal.component';
import { IepChangeProviderComponent } from './shared/iep-change-provider/iep-change-provider.component';
import { IepOutputComponent } from './shared/iep-output/iep-output.component';
import { IepSurveyComponent } from './shared/iep-survey/iep-survey.component';
import { SentenceStartersButtonComponent } from './shared/sentence-starters-button/sentence-starters-button.component';
import { TagBarComponent } from './shared/tag-bar/tag-bar.component';
import { IepAdditionalInfoComponent } from './steps/iep-additional-info/iep-additional-info.component';
import { IepAccommodationInfoComponent } from './steps/iep-details/components/iep-details-accommodations/iep-accommodation-info/iep-accommodation-info.component';
import { IepDetailsAccommodationsComponent } from './steps/iep-details/components/iep-details-accommodations/iep-details-accommodations.component';
import { IepActivitiesInfoComponent } from './steps/iep-details/components/iep-details-activities-supports/iep-activities-info/iep-activities-info.component';
import { IepDetailsActivitiesSupportsComponent } from './steps/iep-details/components/iep-details-activities-supports/iep-details-activities-supports.component';
import { IepDetailsGoalsComponent } from './steps/iep-details/components/iep-details-goals/iep-details-goals.component';
import { IepGoalsInfoComponent } from './steps/iep-details/components/iep-details-goals/iep-goals-info/iep-goals-info.component';
import { IepDetailsPlaafpComponent } from './steps/iep-details/components/iep-details-plaafp/iep-details-plaafp.component';
import { EndServiceComponent } from './steps/iep-details/components/iep-details-services/end-service/end-service.component';
import { IepDetailsServicesComponent } from './steps/iep-details/components/iep-details-services/iep-details-services.component';
import { IepServiceInfoComponent } from './steps/iep-details/components/iep-details-services/iep-service-info/iep-service-info.component';
import { IepDetailsSummaryComponent } from './steps/iep-details/components/iep-details-summary/iep-details-summary.component';
import { IepWeightAdditionalQuestionsComponent } from './steps/iep-details/components/iep-weight-enrollment/iep-weight-additional-questions/iep-weight-additional-questions.component';
import { IepWeightRecommendationComponent } from './steps/iep-details/components/iep-weight-enrollment/iep-weight-recommendation/iep-weight-recommendation.component';
import { IepWeightSummaryComponent } from './steps/iep-details/components/iep-weight-enrollment/iep-weight-summary/iep-weight-summary.component';
import { IepDetailsComponent } from './steps/iep-details/iep-details.component';
import { IepGoalsViewMoreComponent } from './steps/iep-goals/iep-goals-view-more/iep-goals-view-more.component';
import { IepGoalsComponent } from './steps/iep-goals/iep-goals.component';
import { IepDataPointsComponent } from './steps/iep-goals/iep-quantifiable-data/iep-data-points/iep-data-points.component';
import { IepMilestonesComponent } from './steps/iep-goals/iep-quantifiable-data/iep-milestones/iep-milestones.component';
import { IepQuantifiableDataComponent } from './steps/iep-goals/iep-quantifiable-data/iep-quantifiable-data.component';
import { IepScaleLevelsComponent } from './steps/iep-goals/iep-quantifiable-data/iep-scale-levels/iep-scale-levels.component';
import { IepAlignmentComponent } from './steps/iep-profile/iep-alignment/iep-alignment.component';
import { IepCompletionComponent } from './steps/iep-profile/iep-completion/iep-completion.component';
import { IepConsentsComponent } from './steps/iep-profile/iep-consents/iep-consents.component';
import { IepMeetingComponent } from './steps/iep-profile/iep-meeting/iep-meeting.component';
import { IepProfileComponent } from './steps/iep-profile/iep-profile.component';
import { IepReportsComponent } from './steps/iep-profile/iep-reports/iep-reports.component';
import { IepSurveyResultsComponent } from './steps/iep-profile/iep-survey-results/iep-survey-results.component';
import { IepSsaaAccommodationsComponent } from './steps/iep-services-supports-activities/components/iep-ssaa-accommodations/iep-ssaa-accommodations.component';
import { IepSsaaActivitiesSupportsComponent } from './steps/iep-services-supports-activities/components/iep-ssaa-activities-supports/iep-ssaa-activities-supports.component';
import { IepSsaaAssessmentComponent } from './steps/iep-services-supports-activities/components/iep-ssaa-assessment/iep-ssaa-assessment.component';
import { IepSsaaExtendedSchoolComponent } from './steps/iep-services-supports-activities/components/iep-ssaa-extended-school/iep-ssaa-extended-school.component';
import { IepSsaaLeastRestrictiveEnvComponent } from './steps/iep-services-supports-activities/components/iep-ssaa-least-restrictive-env/iep-ssaa-least-restrictive-env.component';
import { IepSsaaLreStatsComponent } from './steps/iep-services-supports-activities/components/iep-ssaa-least-restrictive-env/iep-ssaa-lre-stats/iep-ssaa-lre-stats.component';
import { IepSsaaPhysicalEducationComponent } from './steps/iep-services-supports-activities/components/iep-ssaa-physical-education/iep-ssaa-physical-education.component';
import { IepSsaaServicesComponent } from './steps/iep-services-supports-activities/components/iep-ssaa-services/iep-ssaa-services.component';
import { IepSsaaSummaryComponent } from './steps/iep-services-supports-activities/components/iep-ssaa-summary/iep-ssaa-summary.component';
import { IepAccommodationsTableComponent } from './steps/iep-services-supports-activities/components/shared/iep-accommodations-table/iep-accommodations-table.component';
import { IepActivitiesSupportsTableComponent } from './steps/iep-services-supports-activities/components/shared/iep-activities-supports-table/iep-activities-supports-table.component';
import { IepServicesTableComponent } from './steps/iep-services-supports-activities/components/shared/iep-services-table/iep-services-table.component';
import { IepSsaaSummaryModalComponent } from './steps/iep-services-supports-activities/components/shared/modals/iep-ssaa-summary-modal/iep-ssaa-summary-modal.component';
import { IepServicesSupportsActivitiesComponent } from './steps/iep-services-supports-activities/iep-services-supports-activities.component';
import { IepPlaafpViewMoreComponent } from './modals/iep-plaafp-view-more/iep-plaafp-view-more.component';
import { SpecialEducationServicePlanComponent } from './clp/components/special-education-service-plan/special-education-service-plan.component';
import { SupportActivityAccommodationPlanComponent } from './clp/components/support-activity-accommodation-plan/support-activity-accommodation-plan.component';
import { OtherSupportsComponent } from './clp/components/other-supports/other-supports.component';
import { IepPrintModalComponent } from './modals/iep-print-modal/iep-print-modal.component';

@NgModule({
  declarations: [
    IepComponent,
    IepPlaafpComponent,
    IepHeaderComponent,
    IepNavComponent,
    IepSpecialFactorsBarComponent,
    TagBarComponent,
    IepProfileComponent,
    SentenceStartersButtonComponent,
    IepAlignmentComponent,
    IepCompletionComponent,
    IepReportsComponent,
    IepGoalsComponent,
    IepServicesSupportsActivitiesComponent,
    IepSsaaServicesComponent,
    IepSsaaAccommodationsComponent,
    IepSsaaActivitiesSupportsComponent,
    IepSsaaExtendedSchoolComponent,
    IepSsaaPhysicalEducationComponent,
    IepSsaaAssessmentComponent,
    IepSsaaLeastRestrictiveEnvComponent,
    IepSsaaSummaryComponent,
    IepOutputComponent,
    IepServicesTableComponent,
    IepAccommodationsTableComponent,
    IepActivitiesSupportsTableComponent,
    IepSsaaSummaryModalComponent,
    IepSsaaLreStatsComponent,
    IepDetailsComponent,
    IepDetailsPlaafpComponent,
    IepDetailsGoalsComponent,
    IepDetailsServicesComponent,
    IepAdditionalInfoComponent,
    IepAmendmentModalComponent,
    IepDetailsAccommodationsComponent,
    IepDetailsActivitiesSupportsComponent,
    IepDetailsSummaryComponent,
    IepMeetingComponent,
    EsyComponent,
    EsyHeaderComponent,
    EsyNavComponent,
    EsyOverviewComponent,
    TransitionAssessmentComponent,
    IepAmendmentEndItemModalComponent,
    IepQuantifiableDataComponent,
    IepScaleLevelsComponent,
    IepMilestonesComponent,
    PseActivitiesComponent,
    PseSummaryComponent,
    CourseActivitiesComponent,
    EsyViewComponent,
    EsyViewOverviewComponent,
    EsyViewGoalsComponent,
    TransferOfRightsComponent,
    EsyGoalsComponent,
    EsyChooseGoalModalComponent,
    EsyTableDisplayComponent,
    AdjustEsyModalComponent,
    IepDataPointsComponent,
    EsyIncompleteDataReportComponent,
    IepGoalsViewMoreComponent,
    ClpComponent,
    GoalPlanComponent,
    SpecialEducationServicePlanComponent,
    ViewGoalModalComponent,
    IepCreateBipModalComponent,
    EndServiceComponent,
    IepSurveyInviteCreatorModalComponent,
    IepSurveyComponent,
    IepSurveyResultsComponent,
    IepChangeProviderComponent,
    IepConsentsComponent,
    IepAccommodationsViewMoreComponent,
    IepActivitySupportsViewMoreComponent,
    IepServiceInfoComponent,
    IepAccommodationInfoComponent,
    IepActivitiesInfoComponent,
    IepGoalsInfoComponent,
    IepServicesViewMoreComponent,
    IepWeightAdditionalQuestionsComponent,
    IepWeightRecommendationComponent,
    IepWeightSummaryComponent,
    IepPlaafpViewMoreComponent,
    SupportActivityAccommodationPlanComponent,
    OtherSupportsComponent,
    IepPrintModalComponent,
  ],
  imports: [
    CommonModule,
    IepRoutingModule,
    SharedModule,
    AppMatModule,
    ReactiveFormsModule,
    FormsModule,
    IfspModule,
    ChartsModule,
    ConsentModule,
  ],
  exports: [TagBarComponent, IepSpecialFactorsBarComponent, SentenceStartersButtonComponent],
})
export class IepModule {}
