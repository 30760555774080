import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OperationResult, OperationResultWithValue } from '../../shared/models/operation-result';
import { SpinnerService } from '../../shared/services/spinner/spinner.service';
import {
  SpecialCircumstanceOperationResponseDto,
  WeightedMatrixDto,
  WeightedMatrixLevelDto,
  WeightedMatrixScoreDto,
  WeightedMatrixScoreHistoryDto,
  WeightedMatrixSpecialCircumstanceDto,
  WeightRecommendationDto,
} from '../models/iep-weighted-matrix';

@Injectable({
  providedIn: 'root',
})
export class IepDetailWeightedMatrixService {
  private weightedMatrixScoreUpdated = new Subject<void>();
  weightedMatrixScoreUpdated$ = this.weightedMatrixScoreUpdated.asObservable();

  weightedMatrixSpecialCircumstancesUpdated = new Subject<void>();

  constructor(private readonly http: HttpClient, spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/WeightedMatrix.*/));
  }

  get baseUrl() {
    return 'api/WeightedMatrix/';
  }

  getWeightedMatrix(caseId: string) {
    return this.http.get<OperationResultWithValue<WeightedMatrixDto>>(`${this.baseUrl}${caseId}`);
  }

  updateWeightedMatrix(id: string, weightedMatrix: WeightedMatrixDto) {
    return this.http.put<OperationResultWithValue<WeightedMatrixDto>>(`${this.baseUrl}${id}`, weightedMatrix);
  }

  saveWeightedMatrixSpecialCircumstance(caseId: string, WeightedMatrixSpecialCircumstance: WeightedMatrixSpecialCircumstanceDto) {
    return this.http.post<OperationResultWithValue<SpecialCircumstanceOperationResponseDto>>(
      `${this.baseUrl}${caseId}/specialCircumstance`,
      WeightedMatrixSpecialCircumstance
    );
  }

  getWeightedRecommendation(iepId: string) {
    return this.http.get<OperationResultWithValue<WeightRecommendationDto[]>>(`${this.baseUrl}${iepId}/recommendations`);
  }

  getWeightedLevels(caseId: string) {
    return this.http.get<OperationResultWithValue<WeightedMatrixLevelDto[]>>(`${this.baseUrl}${caseId}/levels`);
  }

  createWeightedMatrixScore(caseId: string, weightedMatrixScore: WeightedMatrixScoreDto) {
    return this.http
      .post<OperationResult>(`${this.baseUrl}${caseId}/score`, weightedMatrixScore)
      .pipe(tap(() => this.weightedMatrixScoreUpdated.next()));
  }

  updateWeightedMatrixScore(caseId: string, weightedMatrixScore: WeightedMatrixScoreDto) {
    return this.http
      .put<OperationResult>(`${this.baseUrl}${caseId}/score`, weightedMatrixScore)
      .pipe(tap(() => this.weightedMatrixScoreUpdated.next()));
  }

  getWeightedMatrixScoreHistory(caseId: string) {
    return this.http.get<OperationResultWithValue<WeightedMatrixScoreHistoryDto[]>>(`${this.baseUrl}${caseId}/scoreHistory`);
  }
}
