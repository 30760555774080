import { Pipe, PipeTransform } from '@angular/core';

class Constants {
  static DATE_TIME_FORMATS = ['short', 'medium', 'long', 'full'];
}

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(dateValue: any, dateStyle?: any): any {
    if (!dateValue) {
      return '';
    }

    if (!dateStyle || !Constants.DATE_TIME_FORMATS.some((x) => x === dateStyle)) {
      dateStyle = 'short';
    }

    if (dateStyle === 'short') {
      return new Date(dateValue).toLocaleString('en-US', {
        day: 'numeric',
        year: 'numeric',
        month: 'numeric',
        timeZone: 'America/Chicago',
      });
    }

    const dateOption = { dateStyle: dateStyle, timeZone: 'America/Chicago' };
    return new Date(dateValue).toLocaleString('en-US', dateOption);
  }
}

@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
  transform(dateValue: any, dateStyle?: any): any {
    if (!dateValue) {
      return '';
    }

    const timeOption = { hour12: true, timeStyle: 'short', timeZone: 'America/Chicago' };
    return new Date(dateValue).toLocaleString('en-US', timeOption);
  }
}

@Pipe({
  name: 'dateTimeFormat',
})
export class DateTimeFormatPipe implements PipeTransform {
  transform(dateValue: any, dateStyle?: any, preference?: any) {
    if (!dateValue) {
      return '';
    }

    if (!dateStyle || !Constants.DATE_TIME_FORMATS.some((x) => x === dateStyle)) {
      dateStyle = 'short';
    }

    const timeOption = { hour12: true, timeStyle: 'short', timeZone: 'America/Chicago' };
    const timeVal = new Date(dateValue).toLocaleString('en-US', timeOption);
    const dateVal = new DateFormatPipe().transform(dateValue, dateStyle);

    if (preference === 'DT') return `${dateVal} at ${timeVal}`;

    if (preference === 'TD') return `${timeVal} on ${dateVal}`;

    return `${dateVal} ${timeVal}`;
  }
}

@Pipe({
  name: 'dateFormatNoConversion',
})
export class DateFormatNoConversionPipe implements PipeTransform {
  transform(dateValue: any, dateStyle?: any): any {
    if (!dateValue) {
      return '';
    }

    if (!dateStyle || !Constants.DATE_TIME_FORMATS.some((x) => x === dateStyle)) {
      dateStyle = 'short';
    }

    if (dateStyle === 'short') {
      return new Date(dateValue).toLocaleString('en-US', {
        day: 'numeric',
        year: 'numeric',
        month: 'numeric',
      });
    }

    return new Date(dateValue).toLocaleString('en-US');
  }
}

@Pipe({
  name: 'dateToUtcPipe',
})
export class DateToUtcPipe implements PipeTransform {
  transform(dateValue: Date): Date {
    if (!dateValue) {
      return null;
    }
    return new Date(Date.UTC(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate()));
  }
}

/**
 * Converts a date parameter into UTC by considering CST time zone as base of reference
 */
@Pipe({
  name: 'dateToUtcWithDaylightPipe',
})
export class DateToUtcWithDaylightPipe implements PipeTransform {
  transform(dateValue: Date): Date {
    if (!dateValue) {
      return null;
    }
    const offsetVal = this.isDST(dateValue) ? 5 : 6;
    return new Date(Date.UTC(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate(), offsetVal));
  }

  private isDST(dateVal: Date) {
    const jan = new Date(dateVal.getFullYear(), 0, 1).getTimezoneOffset();
    const jul = new Date(dateVal.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(jan, jul) !== dateVal.getTimezoneOffset();
  }
}

@Pipe({
  name: 'stringDateTimeToDateTime',
})
export class StringDateTimeToDateTimePipe implements PipeTransform {
  transform(dateValue: string): Date {
    if (!dateValue) {
      return null;
    }

    const date = new Date(dateValue);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
  }
}
