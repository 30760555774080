import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { EligibilityBasedOn } from '../../../models/evaluation';

export const twentyFiveDelayAreasRequired: ValidatorFn = (formGroup: FormGroup) =>
  formGroup.controls.eligibilityBasedOn.value === EligibilityBasedOn.QuarterOrMore && !formGroup.controls.twentyFiveDelayAreas.value
    ? { twentyFiveDelayAreasRequired: true }
    : null;

export const documentedDelayConditionsRequired: ValidatorFn = (formGroup: FormGroup) =>
  formGroup.controls.eligibilityBasedOn.value === EligibilityBasedOn.DocumentedDelay && !formGroup.controls.documentedDelayConditions.value
    ? { documentedDelayConditionsRequired: true }
    : null;

export const sourcesValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null =>
  formGroup.controls.eligibilityBasedOn.value === EligibilityBasedOn.InformedClinicalOpinion &&
  !formGroup.controls.eligibilityIcoSources.value.some((source) => source.source)
    ? { icoSourcesRequired: true }
    : null;

export const reasonsValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null =>
  formGroup.controls.eligibilityBasedOn.value === 'InformedClinicalOpinion' &&
  !formGroup.controls.eligibilityIcoReasons.value.some((reason) => reason.reason)
    ? { icoReasonsRequired: true }
    : null;

export const summaryValidator =
  (field: string): ValidatorFn =>
  (formGroup: FormGroup): ValidationErrors | null => {
    if (formGroup.get(field).value && !formGroup.get('summary').value) {
      return { summaryRequired: true };
    }
    return null;
  };
