import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin } from 'rxjs';
import { IepAmendment } from '../../../../models/iep-amendment';
import { IepService } from '../../../../services/iep.service';
import { LearnerSummary } from '../../../../../shared/models/learner';

@Component({
  selector: 'app-iep-details-summary',
  templateUrl: './iep-details-summary.component.html',
  styleUrls: ['./iep-details-summary.component.scss'],
})
export class IepDetailsSummaryComponent implements OnInit {
  @Input() amendments: IepAmendment[];
  @Input() learner: LearnerSummary;

  constructor(private iepService: IepService, public route: ActivatedRoute) {}

  @Input() caseId: string;
  @Input() iepId: string;

  esy: string;
  physEd: string;
  assessment: string;
  ngOnInit(): void {
    this.iepId = this.route.snapshot.paramMap.get('iepId');
    this.caseId = this.route.snapshot.paramMap.get('caseId');
    if (!this.caseId) {
      this.caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    }
    if (!this.iepId) {
      this.iepId = this.route.parent?.snapshot.paramMap.get('iepId');
    }

    this.initData();
    this.iepService.physicalEducationsUpdated$.subscribe(() => this.initData());
    this.iepService.extendedSchoolYearUpdated$.subscribe(() => this.initData());
    this.iepService.assessmentUpdated$.subscribe(() => this.initData());
    this.iepService.lreUpdated$.subscribe(() => this.initData());
  }
  initData() {
    forkJoin([
      this.iepService.getExtendedSchoolYearFromIep(this.iepId),
      this.iepService.getExtendedSchoolYearOptions(),
      this.iepService.getPhysicalEducationFromIep(this.iepId),
      this.iepService.getPhysicalEducations(),
      this.iepService.getAssessmentFromIep(this.iepId),
    ]).subscribe(([esyFromIep, esyOptions, physEdFromIep, physEdOptions, assessment]) => {
      if (esyFromIep) {
        this.esy = esyFromIep.extendedSchoolYearDate
          ? `ESY ${esyOptions.find((x) => x.id === esyFromIep.extendedSchoolYear).label}: ${dayjs(esyFromIep.extendedSchoolYearDate).format(
              'MM-DD-YYYY'
            )} `
          : `ESY ${esyOptions.find((x) => x.id === esyFromIep.extendedSchoolYear).label}`;
      }
      if (physEdFromIep) {
        this.physEd = physEdFromIep.physicalEdDescription
          ? `Physical Education: ${physEdOptions.find((x) => x.id === physEdFromIep.physicalEducation).label} - ${
              physEdFromIep.physicalEdDescription
            }`
          : `Physical Education: ${physEdOptions.find((x) => x.id === physEdFromIep.physicalEducation).label}`;
      }

      if (assessment) {
        switch (assessment.howParticipate) {
          case 'noAccommodations':
            this.assessment = 'Standard administration, no accommodations';
            break;
          case 'withAccommodation':
            this.assessment = 'Standard administration with accommodations';
            break;
          case 'alternate':
            this.assessment = 'Alternate';
            break;
        }
      } else {
        this.assessment = '-';
      }
    });
  }
}
