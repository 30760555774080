import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { KeyValuePair } from '../../models/key-value-pair';

@Component({
  selector: 'app-add-goal-progress-form',
  templateUrl: './add-goal-progress-form.component.html',
  styleUrls: ['./add-goal-progress-form.component.scss'],
})
export class AddGoalProgressFormComponent implements OnInit {
  @Input() hasPhaseGraph = false;
  @Output() submitEvent = new EventEmitter<FormGroup>();

  phaseLineVisible = false;
  commentsVisible = false;

  formGroup = new FormGroup({
    date: new FormControl(null, Validators.required),
    value: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(0)]),
    notes: new FormControl(null, Validators.required),
  });

  options = [new KeyValuePair('test', 'test'), new KeyValuePair('test2', 'test2')];

  phaseLineOptions: KeyValuePair[] = [];
  commentOptions: KeyValuePair[] = [];

  get phaseLineGroup(): FormGroup {
    return this.formGroup.get('phaseLineGroup') as FormGroup;
  }

  get commentsGroup(): FormGroup {
    return this.formGroup.get('commentsGroup') as FormGroup;
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {}

  togglePhaseLineForm() {
    this.phaseLineVisible = !this.phaseLineVisible;

    if (this.phaseLineVisible) {
      this.formGroup.addControl(
        'phaseLineGroup',
        this.fb.group({
          phaseLine: new FormControl([], Validators.required),
          other: new FormControl(null, Validators.required),
        })
      );
    } else {
      this.formGroup.removeControl('phaseLineGroup');
    }
  }

  toggleCommentsForm() {
    this.commentsVisible = !this.commentsVisible;

    if (this.commentsVisible) {
      this.formGroup.addControl(
        'commentsGroup',
        this.fb.group({
          notes: new FormControl(null, Validators.required),
        })
      );
    } else {
      this.formGroup.removeControl('commentsGroup');
    }
  }

  isOtherSelected(): boolean {
    return (
      this.phaseLineGroup.controls.phaseLine.value.includes('OtherComment') ||
      this.phaseLineGroup.controls.phaseLine.value.includes('OtherPhase')
    );
  }

  onSubmit() {
    this.submitEvent.emit(this.formGroup);
  }
}
