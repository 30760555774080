import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EvaluationNote } from 'src/app/evaluation/models/evaluation';
import { EvaluationRoutine } from 'src/app/evaluation/models/evaluation-routine';

@Injectable({
  providedIn: 'root',
})
export class EvaluationFormService {
  private formSubmitSource = new Subject<void>();
  formSubmitted$ = this.formSubmitSource.asObservable();

  private noteSubmitSource = new Subject<EvaluationNote>();
  noteSubmitted$ = this.noteSubmitSource.asObservable();

  private noteEditSource = new Subject<EvaluationNote>();
  noteEdit$ = this.noteEditSource.asObservable();

  private noteDeleteSource = new Subject<EvaluationNote>();
  noteDelete$ = this.noteDeleteSource.asObservable();

  private routineAddSource = new Subject<void>();
  routineAdded$ = this.routineAddSource.asObservable();

  private devAreasUpdatedSource = new Subject<void>();
  devAreasUpdated = this.devAreasUpdatedSource.asObservable();

  constructor() {}

  submitForm() {
    this.formSubmitSource.next();
  }

  submitNote(event, isMain?: boolean) {
    this.noteSubmitSource.next(event);
  }

  editNote(event) {
    this.noteEditSource.next(event);
  }

  deleteNote(event) {
    this.noteDeleteSource.next(event);
  }

  addRoutine() {
    this.routineAddSource.next();
  }

  getRoutineLabel(routine: EvaluationRoutine): string {
    if (routine.label === 'Other' && !routine.otherLabel) {
      return routine.label;
    }
    if (routine.label === 'Other') {
      if (routine.nickName) {
        return `${routine.otherLabel}: ${routine.nickName}`;
      } else {
        return routine.otherLabel;
      }
    } else {
      if (routine.nickName) {
        return `${routine.label}: ${routine.nickName}`;
      } else {
        return routine.label;
      }
    }
  }

  devAreasAnnounceUpdate() {
    this.devAreasUpdatedSource.next();
  }
}
