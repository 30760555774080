import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MeetingRollCallComponent } from 'src/app/shared/components/meeting-roll-call/meeting-roll-call.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { CaseSummary, FamilyMeetingRead } from 'src/app/shared/models/case';
import { MeetingStatus } from 'src/app/shared/models/family-meeting-status';

@Component({
  selector: 'app-iep-meeting',
  templateUrl: './iep-meeting.component.html',
  styleUrls: ['./iep-meeting.component.scss'],
})
export class IepMeetingComponent implements OnInit, OnChanges {
  @Input() iepMeetings: FamilyMeetingRead[];
  @Input() caseSummary: CaseSummary;
  @Output() refreshMeetings = new EventEmitter();
  meetingDataSource: MatTableDataSource<FamilyMeetingRead>;
  displayedColumns = ['purpose', 'location', 'date', 'status', 'rollCall'];
  displayFooter: boolean;
  meetingStatus = MeetingStatus;
  shortDateFormat = shortDateFormat;
  today: Date = new Date();
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.setUpTableData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.iepMeetings?.currentValue !== changes.iepMeetings?.previousValue) {
      this.meetingDataSource = new MatTableDataSource<FamilyMeetingRead>(this.iepMeetings);
    }
  }

  getPurposesOfMeeting(purposes: string[]) {
    return purposes.join(', ');
  }

  isDateCurrentOrPast(meetingDate: string) {
    this.today.setHours(23, 59, 59, 999); // Set's date to 11:59pm tonight
    return new Date(meetingDate) <= this.today;
  }

  openRollCallModal(meeting: FamilyMeetingRead) {
    const dialogRef = this.dialog.open(MeetingRollCallComponent, {
      width: '1100px',
      data: {
        meeting,
        caseSummary: this.caseSummary,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refreshMeetings.emit();
    });
  }

  forCurrentIep(meeting: FamilyMeetingRead) {
    return meeting.meetingRollCallDate > this.caseSummary.activeIepCreatedOn;
  }

  private setUpTableData() {
    this.meetingDataSource = new MatTableDataSource<FamilyMeetingRead>(this.iepMeetings);
  }
}
