import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IfspServiceDto } from 'src/app/ifsp/models/ifsp-service-models';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';

@Component({
  selector: 'app-ifsp-service-view',
  templateUrl: './ifsp-service-view.component.html',
  styleUrls: ['./ifsp-service-view.component.scss'],
})
export class IfspServiceViewComponent implements OnInit {
  shortDateFormat = shortDateFormat;

  constructor(
    private dialogRef: MatDialogRef<IfspServiceViewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      service: any;
    }
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }
}
