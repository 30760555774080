import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { shortDateFormat } from '../../../../../../shared/dateTimeHelpers';
import { IepServiceService } from '../../../../../services/iep-service.service';

@Component({
  selector: 'app-iep-service-info',
  templateUrl: './iep-service-info.component.html',
  styleUrls: ['./iep-service-info.component.scss'],
})
export class IepServiceInfoComponent implements OnInit {
  shortDateFormat = shortDateFormat;
  iepId: string;
  serviceId: string;
  service: any;
  displayedColumns = ['date', 'changes'];
  dataSource = new MatTableDataSource([]);

  constructor(
    public iepServiceService: IepServiceService,
    private dialogRef: MatDialogRef<IepServiceInfoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { service: any; id: string; iepId: string }
  ) {
    this.service = data.service;
    this.serviceId = data.id;
    this.iepId = data.iepId;
  }

  ngOnInit(): void {
    this.iepServiceService.getAuditLogs(this.iepId, this.serviceId).subscribe((result) => {
      this.dataSource.data = result;
    });
  }

  dismiss() {
    this.dialogRef.close();
  }
}
