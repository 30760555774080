import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EvaluationAssignment } from '../models/evaluation-assignment';

@Injectable({
  providedIn: 'root',
})
export class EvaluationAssignmentService {
  constructor(private readonly http: HttpClient) {}

  private baseUrl(evaluationId: string, id = '') {
    return `api/evaluations/${evaluationId}/assignment/${id}`;
  }

  get(evaluationId: string) {
    return this.http.get<EvaluationAssignment[]>(this.baseUrl(evaluationId));
  }

  add(evaluationId: string, group: EvaluationAssignment[], single: EvaluationAssignment = null) {
    const body: any = {};

    if (group) {
      body.assignments = group;
    }

    if (single) {
      body.assignment = single;
    }

    return this.http.post<EvaluationAssignment[]>(this.baseUrl(evaluationId), body);
  }

  update(evaluationId: string, assignment: EvaluationAssignment) {
    return this.http.put<EvaluationAssignment[]>(this.baseUrl(evaluationId, assignment.id), assignment);
  }

  remove(evaluationId: string, assignment: EvaluationAssignment) {
    return this.http.put<EvaluationAssignment[]>(`${this.baseUrl(evaluationId, assignment.id)}/delete`, null);
  }
}
