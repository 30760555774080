import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges } from '@angular/core';
import { ControlContainer, FormGroupDirective, NgControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { LoggerService } from '../../../services/logger/logger.service';
import { BankableFieldsComponent } from '../../bankable-fields/bankable-fields.component';
import { TextFieldComponent } from '../text-field/text-field.component';

@Component({
  selector: 'app-overwrite-text-field',
  templateUrl: './overwrite-text-field.component.html',
  styleUrls: ['./overwrite-text-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class OverwriteTextFieldComponent extends TextFieldComponent implements OnInit, OnChanges {
  @Input() sourceData: { [key: string]: any };
  @Output() blurMe = new EventEmitter<any>();
  // Optional input if source field name different from dest field name
  @Input() sourceFieldName: string;
  @Input() isBankableField = false;
  @Input() bankableFieldId: string;
  @Input() bankableFieldChildFname: string;

  dataSourceValue: any;
  undoValue: string;

  replaceWithSourceValue() {
    this.undoValue = this.control.value;
    this.control.setValue(this.dataSourceValue);
    this.control.markAsTouched();
    this.control.markAsDirty();
    this.control.updateValueAndValidity();

    const undoClearer = this.control.valueChanges.subscribe(() => {
      this.undoValue = null;
      undoClearer.unsubscribe();
    });
  }

  undoReplaceWithSourceValue() {
    this.control.setValue(this.undoValue);
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
    this.undoValue = null;
  }

  constructor(
    public dialog: MatDialog,
    logger: LoggerService,
    @Self()
    @Optional()
    ngControl: NgControl,
    learnerService: LearnerService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(dialog, logger, ngControl, learnerService, changeDetectorRef);
  }

  ngOnInit() {
    super.ngOnInit();
    const key = this.sourceFieldName || this.controlName;
    this.dataSourceValue = this.sourceData[key];
    this.control.setValidators(
      this.control.validator ? [this.control.validator, Validators.maxLength(this.maxLength)] : Validators.maxLength(this.maxLength)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  internalBlur(event: any) {
    if (this.blurMe) {
      this.blurMe.emit(true);
    }
  }

  openBankableFieldOptions() {
    const dialogRef = this.dialog.open(BankableFieldsComponent, {
      data: {
        inputText: this.control.value,
        fieldId: this.bankableFieldId,
        childFirstName: this.bankableFieldChildFname,
      },
      width: '748px',
    });

    return dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.control.setValue(result);
      }
    });
  }
}
