import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { notSureYesNoOptions } from 'src/app/shared/formHelpers';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { ReferralReasonHelp } from 'src/app/shared/services/help/models/referral.help';
import { KeyValuePair } from '../../../models/key-value-pair';
import { UnknownYesNo } from '../../../models/yes-no';

@Component({
  selector: 'app-referral-reason-info-form',
  templateUrl: './referral-reason-info-form.component.html',
  styleUrls: [
    '../../../../child-find/early-access-referral/early-access-referral.component.scss',
    './referral-reason-info-form.component.scss',
  ],
})
export class ReferralReasonInfoFormComponent {
  @Input() formGroup: FormGroup;
  @Input() isThreeToFive = false;
  @Input() screeningToolOptions: KeyValuePair[];
  @Input() screeningToolOtherId: string;
  unknownYesNo = UnknownYesNo;
  notSureYesNoOptions = notSureYesNoOptions;

  helpSection = HelpSection;
  referralReasonHelp = ReferralReasonHelp;

  constructor(private helpService: HelpService) {}

  weightTypeChanged() {
    this.formGroup.patchValue({
      referralReasonBirthInLb: null,
      referralReasonBirthInOz: null,
      referralReasonBirthInGrams: null,
      referralReasonBirthInKilograms: null,
    });
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm) {
    e.preventDefault();

    const dictionary = this.helpService.getReferralDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }
}
