<h4 class="text-primary mt-0 mb-2">How is the plan working?</h4>
<div class="table-overflow mb-2">
  <table mat-table class="w-100" matSort [dataSource]="outcome.data">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more"></th>
      <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
        <button mat-icon-button aria-label="View Icon" color="primary" (click)="onViewMore(element)" tabindex="0">
          <mat-icon>remove_red_eye</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.date | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">
        {{ element.status | truncate : 150 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['actions', 'date', 'status']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['actions', 'date', 'status']"></tr>
    <tr
      [hidden]="outcome.planStatuses.length > 0"
      mat-footer-row
      *matFooterRowDef="outcome.planStatuses.length === 0 ? ['noResults'] : []"
    ></tr>
  </table>

  <mat-paginator [dataSource]="outcome.data"></mat-paginator>
</div>
