import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-other-supports',
  templateUrl: './other-supports.component.html',
  styleUrls: ['./other-supports.component.scss'],
})
export class OtherSupportsComponent {
  @Input() form: FormGroup;
  @Input() readonly: boolean;

  isTechnologySupportInvalid = false;
  isParentSupportInvalid = false;

  isInvalidSwitchObject = {
    parentSupport: (isInvalid) => {
      if (this.isParentSupportInvalid !== isInvalid) {
        this.isParentSupportInvalid = isInvalid;
        this.changeDetector.detectChanges();
      }
      return this.isParentSupportInvalid;
    },
    technologySupport: (isInvalid) => {
      if (this.isTechnologySupportInvalid !== isInvalid) {
        this.isTechnologySupportInvalid = isInvalid;
        this.changeDetector.detectChanges();
      }
      return this.isTechnologySupportInvalid;
    },
  };

  constructor(private changeDetector: ChangeDetectorRef) {}

  //Commented out to fix bug for demo
  //canDeactivate(): DeactivationStatus | Observable<DeactivationStatus> | Promise<DeactivationStatus> {
  //  return (super.canDeactivate() as Observable<DeactivationStatus>)?.pipe(
  //    map((status) => (status === DeactivationStatus.Accepted && this.form?.dirty ? DeactivationStatus.NeedsConfirmation : status))
  //  );
  //}

  checkForAsteriskInput(formControlName: string) {
    const isControlInvalid = this.form.get(formControlName)?.invalid;
    return this.isInvalidSwitchObject[formControlName](isControlInvalid);
  }
}
