import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SentenceStartersModalComponent } from 'src/app/shared/modals/sentence-starters-modal/sentence-starters-modal.component';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-sentence-starters-button',
  templateUrl: './sentence-starters-button.component.html',
  styleUrls: ['./sentence-starters-button.component.scss'],
})
export class SentenceStartersButtonComponent implements OnInit {
  @Input() sentenceStarters: string[];
  @Input() isSuffix = false;
  @Output() selectedQuestion = new EventEmitter<any>();

  get isPortalUser() {
    return this.authService?.isPortalUser;
  }

  constructor(private dialog: MatDialog, private readonly authService: AuthService) {}

  ngOnInit(): void {}

  onOpenSentenceStarters() {
    const dialogRef = this.dialog.open(SentenceStartersModalComponent, {
      width: '728px',
      data: { responses: this.sentenceStarters },
    });
    dialogRef.afterClosed().subscribe(
      (data) => this.selectedQuestion.emit(data),
      (err) => console.error(err)
    );
  }
}
