<div class="table-overflow">
  <table mat-table [dataSource]="pastIfsps" matSort class="mat-elevation-z0 w-100">
    <!-- Date Column -->
    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdOn | dateFormat }}
      </td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element">
        <a
          href="cases/{{ element.caseId }}/ifsp/{{ element.id }}/profile"
          tabindex="0"
          role="link"
          attr.aria-label="{{ element.ifspType }}"
        >
          {{ element.ifspType }} IFSP
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div>
          <a
            mat-raised-button
            color="primary"
            aria-labelledby="View IFSP Details"
            class="mr-2 shift-right"
            href="cases/{{ element.caseId }}/ifsp/{{ element.id }}/details"
          >
            View Details
          </a>
        </div>
        <div>
          <a
            mat-raised-button
            color="primary"
            aria-labelledby="Download IFSP PDF"
            class="mr-2 shift-right"
            (click)="onComingSoon()"
            target="blank"
          >
            View PDF
          </a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<p *ngIf="pastIfsps.length === 0">
  <i>No Past IFSPs...</i>
</p>
