import { moveItemInArray } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { debounceTime, pairwise, startWith } from 'rxjs/operators';
import { EcoMatrixService } from 'src/app/evaluation/evaluation/steps/eval-eco-matrix/services/eco-matrix.service';
import { EcoArea, Evaluation } from 'src/app/evaluation/models/evaluation';
import { EvaluationRoutine } from 'src/app/evaluation/models/evaluation-routine';
import { EvaluationRoutineService } from 'src/app/evaluation/services/evaluation-routine.service';
import { EvaluationUserService } from 'src/app/evaluation/services/evaluation-user.service';
import { EvaluationService } from 'src/app/evaluation/services/evaluation.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { AutocompleteGroup } from 'src/app/shared/models/autocomplete-group';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { LookupBase } from 'src/app/shared/models/lookup-base';
import { EcoAreaService } from 'src/app/shared/services/eco-areas/eco-area.service';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { EvalAssessmentDetailsHelp } from 'src/app/shared/services/help/models/evaluation.help';
import { MemoryStorageService } from 'src/app/shared/services/memory-storage/memory-storage.service';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { NotifySave } from '../../../../shared/decorators/notify-save.decorator';
import { NotificationService } from '../../../../shared/services/notification.service';
import { EcoRating, EcoRatingType } from '../eco-wizard/eco-rating';
import { EcoRatingService } from '../eco-wizard/eco-rating.service';
import { EvaluationFormService } from '../evaluation-details/services/evaluation-form.service';
import { EcoMatrixItem } from './models/eco-matrix-item';
import { EcoMatrixNote } from './models/eco-matrix-note';
import { EcoMatrixSummary } from './models/eco-matrix-summary';

@Component({
  selector: 'app-eval-eco-matrix',
  templateUrl: './eval-eco-matrix.component.html',
  styleUrls: ['./eval-eco-matrix.component.scss'],
})
export class EvalEcoMatrixComponent implements OnInit {
  evaluation: Evaluation;
  ageExpectWarning: boolean;
  ecoAreas: EcoArea[] = [];
  routines: EvaluationRoutine[] = [];
  ecoRatings: EcoRating[] = [];
  autoOptions: KeyValuePair[] = [
    new KeyValuePair('AE', 'Age-Expected'),
    new KeyValuePair('IF', 'Immediate Foundational'),
    new KeyValuePair('F', 'Foundational'),
  ];

  stateOptions: KeyValuePair[] = [
    new KeyValuePair('all', 'All'),
    new KeyValuePair('hidden', 'Only Hidden'),
    new KeyValuePair('unhidden', 'Only Unhidden'),
  ];

  // #region Form Groups
  formGroupFilter = new FormGroup({
    tags: new FormControl([]),
    teamMember: new FormControl(null),
    state: new FormControl(null),
  });

  formGroupMatrix = this.fb.group({});

  formGroupSummaries = new FormGroup({
    id: new FormControl(null),
    evaluationId: new FormControl(null),
    acquisitionSkillsSummary: new FormControl(null),
    positiveSkillsSummary: new FormControl(null),
    appropriateNeedsSummary: new FormControl(null),
  });
  // #endregion

  // #region List Setup
  connectedTo: string[] = [];
  tagOptions: AutocompleteGroup[] = [];

  notes: EcoMatrixNote[] = [];
  allNotes: EcoMatrixNote[] = [];
  evalUsers: KeyValuePair[] = [];
  ecoMatrixItems: EcoMatrixItem[] = [];
  // #endregion
  shortDateFormat = shortDateFormat;
  collapsedRoutines: string[] = [];

  helpSection = HelpSection;
  evalAssessmentDetailsHelp = EvalAssessmentDetailsHelp;

  getId(ecoArea: EcoArea, routine: EvaluationRoutine) {
    return `${ecoArea.id}+${routine.id}`;
  }

  getFormGroup(ecoArea: EcoArea, routine: EvaluationRoutine) {
    return this.formGroupMatrix.get(this.getId(ecoArea, routine)) as FormGroup;
  }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ecoMatrixService: EcoMatrixService,
    private evaluationRoutineService: EvaluationRoutineService,
    private ecoAreaService: EcoAreaService,
    private evaluationUserService: EvaluationUserService,
    private evaluationService: EvaluationService,
    private dialog: MatDialog,
    private storage: MemoryStorageService,
    public evaluationFormService: EvaluationFormService,
    public notificationService: NotificationService,
    private ecoRatingService: EcoRatingService,
    private ngZone: NgZone,
    private datePipe: DatePipe,
    private helpService: HelpService
  ) {}

  ngOnInit(): void {
    this.evaluation = this.storage.getKey('currentEvaluation', true);
    this.formGroupSummaries.get('evaluationId').setValue(this.evaluation.id);
    this.loadData();
    this.loadEcoRatings();
    this.filterFormGroupSubscriptions();
  }

  onToggleRoutine(id: string): void {
    if (this.collapsedRoutines.includes(id)) {
      this.collapsedRoutines = this.collapsedRoutines.filter((cr) => cr !== id);
    } else {
      this.collapsedRoutines.push(id);
    }
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm): void {
    e.preventDefault();

    const dictionary = this.helpService.getEvalAssessmentDetailsDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  loadData() {
    this.evaluation.id = this.route.snapshot.parent.paramMap.get('evaluationId');
    forkJoin([
      this.evaluationUserService.get(this.evaluation.id),
      this.ecoMatrixService.getNotes(this.evaluation.id),
      this.ecoMatrixService.getAllItems(this.evaluation.id),
      this.evaluationRoutineService.get(this.evaluation.id),
      this.ecoAreaService.get(),
      this.evaluationService.getDevelopmentalAreas(this.evaluation.id),
    ]).subscribe(([users, notes, matrixItems, routines, ecoAreas, evalDomains]) => {
      this.evalUsers = users.map((user) => new KeyValuePair(user.userId, user.name));
      this.evalUsers.unshift(new KeyValuePair('all', 'All'));
      this.notes = notes;
      this.allNotes = notes;
      this.ecoMatrixItems = matrixItems;
      this.setTagOptions(routines, ecoAreas, evalDomains);
      this.ecoAreas = ecoAreas;
      this.routines = routines;
      this.routines.push({
        label: 'Other',
        id: null,
      } as EvaluationRoutine);
      this.initializeMatrix();

      this.ecoMatrixService.getSummaries(this.evaluation.id).subscribe((summariesResult) => {
        if (summariesResult) {
          this.formGroupSummaries.patchValue({
            id: summariesResult?.id,
            evaluationId: summariesResult.evaluationId,
            acquisitionSkillsSummary: summariesResult.acquisitionSkillsSummary,
            appropriateNeedsSummary: summariesResult.appropriateNeedsSummary,
            positiveSkillsSummary: summariesResult.positiveSkillsSummary,
          });
        }

        this.formGroupSummaries.valueChanges.pipe(pairwise(), debounceTime(3000)).subscribe(async ([prev, next]) => {
          await this.saveMatrixSummaries(next);
        });
      });
    });
  }

  private setTagOptions(routines: EvaluationRoutine[], ecoAreas: EcoArea[], evalDomains: LookupBase[]) {
    this.tagOptions.push(
      {
        label: 'ECO Areas',
        options: ecoAreas.map((x) => new KeyValuePair(x.id, x.label)),
      },
      {
        label: 'Developmental Areas',
        options: evalDomains.map((x) => new KeyValuePair(x.id, x.label)),
      }
    );

    if (routines.length > 0) {
      this.tagOptions.push({
        label: 'Routines',
        options: routines
          .sort((a, b) => {
            const aLabel = a.label === 'Other' ? a.otherLabel : a.label;
            const bLabel = b.label === 'Other' ? b.otherLabel : b.label;
            return aLabel.localeCompare(bLabel);
          })
          .map((x) => new KeyValuePair(x.id, this.evaluationFormService.getRoutineLabel(x))),
      });
    }
  }

  filterFormGroupSubscriptions() {
    this.formGroupFilter.valueChanges.subscribe((value) => {
      this.notes = this.allNotes;
      if (value.teamMember && value.teamMember !== 'all') {
        this.notes = this.notes.filter((x) => x.createdBy.id === value.teamMember);
      }
      if (value.state) {
        switch (value.state) {
          case 'hidden':
            this.notes = this.notes.filter((x) => x.hidden);
            break;
          case 'unhidden':
            this.notes = this.notes.filter((x) => !x.hidden);
            break;
          default:
            break;
        }
      } else {
        this.notes = this.notes.filter((x) => !x.hidden);
      }

      if ((value.tags as Array<string>).length > 0) {
        this.notes = this.notes.filter(
          (note) =>
            note.ecoAreas.some((ecoArea) => value.tags.includes(ecoArea.id)) ||
            note.routines.some((routine) => value.tags.includes(routine.id)) ||
            note.domainAreas.some((domain) => value.tags.includes(domain.id))
        );
      }
    });
  }

  initializeMatrix(): void {
    this.routines.forEach((routine) => {
      this.ecoAreas.forEach((ecoArea) => {
        const item = this.ecoMatrixItems.find((x) => x.ecoAreaId === ecoArea.id && x.evaluationRoutineId === routine.id);
        const formGroup = new FormGroup({
          description: new FormControl(item?.description || ''),
          isPlod: new FormControl(item?.isPlod || false),
          ageExpect: new FormControl(item?.ageExpect || null),
          ecoAreaId: new FormControl(ecoArea.id),
          evaluationRoutineId: new FormControl(routine.id),
          evaluationId: new FormControl(this.evaluation.id),
          id: new FormControl(item?.id || null),
          ageExpectWarning: new FormControl(item?.ageExpectWarning || false),
        });
        this.formGroupMatrix.addControl(`${ecoArea.id}+${routine.id}`, formGroup);
        formGroup.valueChanges.pipe(startWith({}), pairwise(), debounceTime(300)).subscribe(async ([prev, next]) => {
          await this.saveMatrixItem(next);
          if (prev.description !== next.description && next.ageExpect) {
            formGroup.get('ageExpectWarning').setValue(true);
          }
        });
        this.connectedTo.push(`${ecoArea.id}+${routine.id}`);
      });
    });
  }

  autoFillNotes() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion: 'Clicking Yes will autofill notes.',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.allNotes.forEach((note) => {
          if (!note.autoFilled) {
            const ecoAreas = note.ecoAreas;
            const routines = note.routines;
            ecoAreas.forEach((ecoArea) => {
              routines.forEach((routine) => {
                const formGroup = this.getFormGroup(ecoArea, routine);
                formGroup.get('description').setValue(formGroup.get('description').value.concat(`\n${note.note}`));
                note.autoFilled = true;
              });
            });
            if (note.autoFilled) {
              this.ecoMatrixService.setNoteAsAutoFilled(this.evaluation.id, note).subscribe();
            }
          }
        });
      }
    });
  }

  onAgeExpectSelected(formGroup: FormGroup) {
    formGroup.get('ageExpectWarning').setValue(false);
  }

  dropNote(event: any, formControlName?: string) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const formGroup = this.formGroupMatrix.get(formControlName);
      if (!formGroup.get('description').value) {
        formGroup.patchValue({
          ecoAreaId: formControlName.split('+')[0],
          evaluationRoutineId: formControlName.split('+')[1] !== 'null' ? formControlName.split('+')[1] : null,
          description: event.previousContainer.data[event.previousIndex].note,
        });
      } else {
        formGroup
          .get('description')
          .setValue(formGroup.get('description').value.concat('\n\n' + event.previousContainer.data[event.previousIndex].note));
      }
    }
  }

  clearMatrixItem(combinedId) {
    this.notificationService.confirmation('Are you sure you want to remove this ECO Matrix item?', () => {
      const control = this.formGroupMatrix.get(combinedId);
      control.get('description').setValue(null);
      control.get('isPlod').setValue(false);
      control.get('ageExpect').setValue(null);
      control.get('ageExpectWarning').setValue(false);
    });
  }

  @NotifySave
  private async saveMatrixItem(item: EcoMatrixItem): Promise<void> {
    const res = await this.ecoMatrixService.saveEcoMatrixItem(this.evaluation.id, item).toPromise();
    if (!item.id && res.id) {
      this.formGroupMatrix.get(item.ecoAreaId + '+' + item.evaluationRoutineId).patchValue({ id: res.id });
    }
  }

  @NotifySave
  private async saveMatrixSummaries(item: EcoMatrixSummary): Promise<void> {
    const res = await this.ecoMatrixService.saveEcoMatrixSummary(this.evaluation.id, item).toPromise();
    if (!item.id && res.id) {
      this.formGroupSummaries.patchValue({ id: res.id });
    }
  }

  onToggleHide(e: MatSlideToggleChange, id: string) {
    this.ecoMatrixService.setNoteAsHidden(this.evaluation.id, id).subscribe((res) => {
      this.notes.find((n) => n.id === id).hidden = !this.notes.find((n) => n.id === id).hidden;
      this.formGroupFilter.updateValueAndValidity();
    });
  }

  areAnyNotes(): { visible: boolean; msg?: string } {
    if (!this.notes || this.notes.length === 0) {
      if (this.allNotes.every((n) => n.hidden === true)) {
        return {
          visible: true,
          msg: 'No cards to show.',
        };
      }
      return { visible: true };
    }
  }

  togglePlod(combinedId: string) {
    const plodControl = this.formGroupMatrix.get(combinedId).get('isPlod');
    const plodValue = plodControl.value;
    plodControl.setValue(!plodValue);
  }

  getAgeControl(formControlName: string) {
    const formGroup = this.formGroupMatrix.get(formControlName) as FormGroup;
    return formGroup.get('ageExpect') as FormControl;
  }

  getEcoMatrixItem(combinedId: string) {
    const ecoAreaId = combinedId.split('+')[0];
    const evaluationRoutineId = combinedId.split('+')[1];
    return this.ecoMatrixItems.find((x) => x.ecoAreaId === ecoAreaId && x.evaluationRoutineId === evaluationRoutineId);
  }

  calculateSummaryTotals(ecoAreaId: string) {
    let AE = 0;
    let IF = 0;
    let F = 0;
    Object.keys(this.formGroupMatrix.controls).forEach((control) => {
      if (control.split('+')[0] === ecoAreaId) {
        const ageExpect = this.formGroupMatrix.get(control).get('ageExpect').value;
        switch (ageExpect) {
          case 'AE':
            AE++;
            break;
          case 'IF':
            IF++;
            break;
          case 'F':
            F++;
            break;
          default:
            break;
        }
      }
    });
    return `AE = ${AE} / IF = ${IF} / F = ${F}`;
  }

  launchEcoDecisionTree(ecoAreaId: string) {
    const config: NewWindowConfig = {
      path: `cases/${this.evaluation.caseId}/evaluation/${this.evaluation.id}/eco-wizard/${ecoAreaId}`,
      popup: true,
      width: '1600px',
    };
    const window = openNewWindow(config);

    window.addEventListener('beforeunload', (ev) => {
      this.ngZone.run(() => this.loadEcoRatings());
    });
    // TODO: Possibly in the Eco Wizard, store the value for use with PLOD (not sure where)
    // Also not sure if button should only be accessible once.
    // Potentially will need to store the rating with the eco area id?
  }

  loadEcoRatings() {
    this.ecoRatingService.getAll(this.evaluation.id, EcoRatingType.Evaluation).subscribe((res) => {
      this.ecoRatings = res;
    });
  }

  getEcoRatingDescription(ecoAreaId: string) {
    const ecoRating = this.ecoRatings.find((x) => x.ecoAreaId === ecoAreaId);
    if (!ecoRating || !ecoRating.score) {
      return;
    }

    const scoreDate = this.datePipe.transform(ecoRating.updatedOn, 'MM/dd/yyyy');

    const scoreText = this.ecoRatingService.getSuggestedEcoRatingText(ecoRating.score, this.evaluation.learner.firstName);

    return {
      date: scoreDate,
      text: scoreText,
    };
  }
}
