import { Component, Inject, OnInit } from '@angular/core';
import { IfspServiceDto } from '../../models/ifsp-service-models';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IfspModification } from '../../models/ifsp-modification';

@Component({
  selector: 'app-ifsp-service-view-more',
  templateUrl: './ifsp-service-view-more.component.html',
  styleUrls: ['./ifsp-service-view-more.component.scss'],
})
export class IfspServiceViewMoreComponent implements OnInit {
  public service: IfspServiceDto;
  public modifications: IfspModification[];
  shortDateFormat = shortDateFormat;
  isModificationView: boolean;

  get modification() {
    return this.modifications?.find((x) => !x.finalizeDate);
  }

  get modificationIsFinalized() {
    return this.modification?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.modifications && this.modifications.length > 0) {
      const latest = this.modifications.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  constructor(
    private dialogRef: MatDialogRef<IfspServiceViewMoreComponent>,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.service = data.service;
    this.isModificationView = data.isModificationView;
    this.modifications = data.modifications;
  }

  ngOnInit(): void {}

  getFrequencyText(element) {
    let monitoringProgress = '';
    if (element?.frequencyNumber > 1) {
      monitoringProgress += element?.frequencyNumber + ' time(s) ';
    }
    monitoringProgress += element?.frequencyPeriod;

    return monitoringProgress;
  }

  dismiss() {
    this.dialogRef.close();
  }

  getServiceProjectedStartDateText(service) {
    return this.datePipe.transform(service?.startDate, shortDateFormat);
  }

  getHowText(service) {
    if (this.isModificationView) {
      return service.howText;
    } else {
      return service.how.join(', ');
    }
  }

  getWithWhom(service) {
    if (this.isModificationView) {
      return service.withText;
    } else {
      return service.who;
    }
  }

  getProviderText(service) {
    if (this.isModificationView) {
      return service.providerText;
    } else {
      return service.provider;
    }
  }

  getLocationText(service) {
    if (this.isModificationView) {
      return service.locationText;
    } else {
      return service.location;
    }
  }

  getOutcomesText(service) {
    let outcomes = '';
    if (service.outcomes) {
      if (this.isModificationView) {
        service.outcomes.forEach((outcome, index) => {
          outcomes += outcome.outcome.title;
          if (index + 1 !== service.outcomes.length) {
            outcomes += ', ';
          }
        });
      } else {
        outcomes = service.outcomes.map((x) => x.title).join(', ');
      }
    }
    return outcomes;
  }
}
