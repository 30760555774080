<div class="row justify-content-end mt-3">
  <div class="col-1">
    <mat-icon (click)="showGoalInfo()">remove_red_eye</mat-icon>
  </div>
  <div class="col-11" [formGroup]="form">
    <div class="row align-items-end">
      <div class="col-md-12 col-xl-6">
        <div class="d-flex justify-content-between">
          <strong> {{ goal.conditionIndividualWillPerform }} </strong>
        </div>
        <ng-container formGroupName="goalPlan">
          <app-autocomplete
            label="Plan for Goal"
            formControlName="action"
            [options]="frequencyOptions"
            [readOnly]="readOnly"
            [disabledState]="readOnly"
            (selected)="onActionSelectionChange($event, 'goalPlan')"
          >
          </app-autocomplete>
        </ng-container>
      </div>
      <ng-container formGroupName="progressMonitoring">
        <app-autocomplete
          class="col-md-12 col-lg-6"
          label="Progress Monitoring"
          formControlName="action"
          [options]="frequencyOptions"
          [readOnly]="readOnly"
          [disabledState]="readOnly"
          (selected)="onActionSelectionChange($event, 'progressMonitoring')"
        ></app-autocomplete>
      </ng-container>
    </div>
    <div class="row align-items-end">
      <ng-container formGroupName="goalPlan">
        <app-textarea
          class="col-md-12 col-lg-6"
          label="Describe"
          *ngIf="checkDescriptionVisibility('goalPlan')"
          formControlName="description"
          [readOnly]="readOnly"
          [disabledState]="readOnly"
        ></app-textarea>
      </ng-container>
      <ng-container formGroupName="progressMonitoring">
        <app-textarea
          class="col-md-12 col-lg-6 ml-auto"
          label="Describe"
          formControlName="description"
          *ngIf="checkDescriptionVisibility('progressMonitoring')"
          [readOnly]="readOnly"
          [disabledState]="readOnly"
        ></app-textarea>
      </ng-container>
    </div>
  </div>
</div>
