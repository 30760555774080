<div class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">
    <ng-container matColumnDef="eligDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Eligibility Date</th>

      <td mat-cell *matCellDef="let element">
        {{ element.eligDate | dateFormat }}
      </td>
    </ng-container>
    <ng-container matColumnDef="eligible">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Eligibility Decision</th>
      <td mat-cell *matCellDef="let element">
        {{ element.eligible === true ? 'Eligible' : element.eligible === false ? 'Not Eligible' : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="basedOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Based On</th>

      <td mat-cell *matCellDef="let element">
        {{ element.basedOn }}
      </td>
    </ng-container>
    <ng-container matColumnDef="basedonDesc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>

      <td mat-cell *matCellDef="let element">
        {{ element.basedOnDesc }}
      </td>
    </ng-container>
    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr [hidden]="dataSource?.data?.length > 0" mat-footer-row *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
