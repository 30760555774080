import { AlertConfig } from './components/page-alert/page-alert.component';

type Tab = {
  message: string;
  check: boolean;
};

export function checkForAlert(tabs: Tab[]): string[] {
  const invalid = tabs.filter((tab) => tab.check).map((tab) => tab.message);

  if (invalid.length > 0) {
    return invalid;
  }
}

export function createAlertConfig(
  messageStart: string,
  status: 'warning' | 'success',
  alerts: string[],
  showRefreshMessage = false
): AlertConfig {
  let message = messageStart;

  if (alerts && alerts.length > 0) {
    alerts.forEach((alert, index: number) => {
      const trail = ', ';
      message += alerts.length - 1 === index ? alert : alert + trail;
    });

    return {
      status,
      message,
      showRefreshMessage,
    };
  } else {
    return {
      status: null,
      message: null,
      showRefreshMessage: false,
    };
  }
}
