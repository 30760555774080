<div>
  <app-iep-ssaa-lre-stats
    [iepId]="iepId"
    [isPK]="isPK"
    [learner]="learner"
    [previousIepId]="previousIepId"
    [caseSummary]="caseSummary"
    [showK]="false"
  >
  </app-iep-ssaa-lre-stats>
  <mat-card class="mt-0 mb-2">
    <h3 class="mt-0 mb-2">Services</h3>
    <app-iep-services-table [iepId]="iepId" [isTransitionPK]="caseSummary?.activeIepIsPKToK" [isEditing]="true"> </app-iep-services-table>
  </mat-card>
  <mat-card class="my-2">
    <h3 class="mt-0 mb-2">Activities and Supports</h3>
    <app-iep-activities-supports-table [iepId]="iepId" [isEditing]="true"> </app-iep-activities-supports-table>
  </mat-card>
  <mat-card class="my-2">
    <h3 class="mt-0 mb-2">Accommodations</h3>
    <app-iep-accommodations-table [iepId]="iepId" [isEditing]="true"> </app-iep-accommodations-table>
  </mat-card>
  <mat-card class="my-2">
    <h3 class="mt-0 mb-2">Services+ Details</h3>
    <div class="otherSSAADetails">
      <ul class="list">
        <li class="list__item" *ngIf="esy">
          <div class="display-flex flex-column">
            <h4 class="text-primary mt-0 mb-1">Extended School Year</h4>
            <p class="my-0">{{ esy }}</p>
          </div>
        </li>
        <li class="list__item" *ngIf="physEd">
          <div class="display-flex flex-column">
            <h4 class="text-primary mt-0 mb-1">Physical Education</h4>
            <p class="my-0">{{ physEd }}</p>
          </div>
        </li>
        <li class="list__item" *ngIf="assessment">
          <div class="display-flex flex-column">
            <h4 class="text-primary mt-0 mb-1">Assessment</h4>
            <p class="my-0">{{ assessment }}</p>
            <p class="my-0 pl-5" *ngIf="assessmentBarriers">
              {{ assessmentBarriers }}
            </p>
            <p class="my-0 pl-5" *ngIf="assessmentAlternate">
              {{ assessmentAlternate }}
            </p>
          </div>
        </li>
        <li class="list__item" *ngIf="learner">
          <div class="display-flex flex-column">
            <h4 class="text-primary mt-0 mb-1">Least Restrictive Environment</h4>
            <app-iep-ssaa-least-restrictive-env
              *ngIf="caseSummary"
              [learner]="learner"
              [previousIepId]="previousIepId"
              [caseSummary]="caseSummary"
              [iepMeetings]="iepMeetings"
              [isAmendment]="false"
              [summaryView]="true"
            ></app-iep-ssaa-least-restrictive-env>
          </div>
        </li>
      </ul>
    </div>
  </mat-card>
</div>
