export enum BipImplementationHelp {
  DeescalationStrategies = 'De-escalation Strategies',
  SafetyMonitoring = 'Safety Monitoring',
  StaffSupports = 'Staff Supports',
}

export enum BipStrategiesHelp {
  StrategyArea = 'Strategy Area',
  GenPlan = 'Generalization Plan',
  FadingPlan = 'Fading Plan',
}
