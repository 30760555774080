<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Adjust for ESY</h3>

<div mat-dialog-content class="py-2">
  <form [formGroup]="formGroup" class="card-spacing-top">
    <mat-card>
      <p>
        <strong>Describe how the team will use data to monitor progress and make instructional decision</strong>
      </p>

      <label class="vertical-label display-block w-100" for="monitoringFrequencyTime"> Monitoring Frequency: </label>
      <div class="display-flex align-items-baseline flex-wrap">
        <div class="display-flex align-items-baseline">
          <app-number-field
            id="monitoringFrequencyTime"
            formControlName="monitoringFrequencyTime"
            [min]="0"
            label="Times"
          ></app-number-field>
          <span class="ml-3 mr-2">times</span>
        </div>

        <app-select label="Time Period" formControlName="monitoringFrequencyPeriod" [options]="frequencyPeriodOptions"> </app-select>
      </div>

      <div *ngIf="showCompareOutput" class="display-flex align-items-baseline">
        <app-compare-amendment-output
          [iepAmendments]="amendments"
          [priorVersionId]="esyGoal?.priorVersionId"
          [isActive]="esyGoal?.isActive"
          [changedVersionText]="getFrequencyText(true)"
          [currentVersionText]="getFrequencyText()"
          [currentPrefixText]="esyGoal?.priorVersion ? 'Current: ' : ''"
        ></app-compare-amendment-output>
      </div>

      <div class="display-flex align-items-baseline position-fix position-fix--label position-fix--label-frequency">
        <label
          [ngClass]="{
            asterisk_input: formGroup.controls.sameRateAsPeersReported.value === null
          }"
          class="vertical-label display-block"
        >
          How often will progress be reported to
          {{ childFirstName | firstNamePossessive }} family?
        </label>

        <button
          mat-icon-button
          aria-label="Clear Icon"
          type="button"
          color="primary"
          class="text-sm vertical-label"
          (click)="onClearRadio('sameRateAsPeersReported')"
          tabindex="0"
        >
          <i class="fas fa-undo" aria-label="Clear Icon"></i>
        </button>
      </div>
      <mat-radio-group formControlName="sameRateAsPeersReported" aria-labelledby="Same Rate as Peers Reported">
        <mat-radio-button [value]="true" class="column wrap w-fit" aria-labelledby="At the same rate as non-disabled peers">
          <div class="display-flex align-items-baseline flex-wrap">
            <span class="mr-2">At the same rate as non-disabled peers</span>
            <app-number-field
              formControlName="sameRateFrequency"
              [min]="1"
              class="input--small"
              label=""
              id="sameRateFrequency"
            ></app-number-field>
            <span class="mx-2">times per</span>
            <app-autocomplete formControlName="sameRatePeriod" [options]="frequencyPeriodOptions"></app-autocomplete>
          </div>
        </mat-radio-button>
        <mat-radio-button [value]="false" class="column wrap w-fit" aria-labelledby="At a different rate than non-disabled peers">
          <div class="display-flex align-items-baseline flex-wrap">
            <span class="mr-2">At a different rate than non-disabled peers</span>
            <app-number-field
              formControlName="differentRateFrequency"
              [min]="1"
              class="input--small"
              label=""
              id="differentRateFrequency"
            ></app-number-field>
            <span class="mx-2">times per</span>
            <app-autocomplete formControlName="differentRatePeriod" [options]="frequencyPeriodOptions"></app-autocomplete>
          </div> </mat-radio-button
      ></mat-radio-group>
      <mat-error
        class="error--fix"
        *ngIf="formGroup.controls.sameRateAsPeersReported.value === null && formGroup.controls.sameRateAsPeersReported.touched"
      >
        Field is <strong>required</strong>
      </mat-error>

      <div *ngIf="showCompareOutput" class="display-flex align-items-baseline">
        <app-compare-amendment-output
          [iepAmendments]="amendments"
          [priorVersionId]="esyGoal?.priorVersionId"
          [isActive]="esyGoal?.isActive"
          [changedVersionText]="getHowOftenProgressReportedText(true)"
          [currentVersionText]="getHowOftenProgressReportedText()"
          [currentPrefixText]="esyGoal?.priorVersion ? 'Current: ' : ''"
        ></app-compare-amendment-output>
      </div>

      <label
        class="vertical-label display-block w-100 mt-2"
        [ngClass]="{
          asterisk_input: formGroup.get('methodsToReport')?.hasError('required')
        }"
      >
        Method(s) to report progress to family
      </label>
      <app-checkbox [options]="methodsToReportOptions" [hideAsterisk]="true" controlName="methodsToReport"> </app-checkbox>
      <app-text-field
        label="Describe Other"
        formControlName="otherMethodToReport"
        aria-required="true"
        [isBankableField]="true"
        [bankableFieldId]="bankableFieldIds.esyAdjust.otherMethodToReport"
        [bankableFieldChildFname]="childFirstName"
        *ngIf="showDescribeOtherMethodField"
        [required]="showDescribeOtherMethodField"
      ></app-text-field>

      <div *ngIf="showCompareOutput" class="display-flex align-items-baseline">
        <app-compare-amendment-output
          [iepAmendments]="amendments"
          [priorVersionId]="esyGoal?.priorVersionId"
          [isActive]="esyGoal?.isActive"
          [changedVersionText]="
            getMethodsToReportText(true, formGroup.get('methodsToReport').value, formGroup.get('otherMethodToReport').value)
          "
          [currentVersionText]="getMethodsToReportText(false, esyGoal.methodsToReport, esyGoal.otherMethodToReport)"
          [currentPrefixText]="esyGoal?.priorVersion ? 'Current: ' : ''"
        ></app-compare-amendment-output>
      </div>
    </mat-card>
  </form>
</div>

<div mat-dialog-actions class="display-flex justify-content-end align-items-center">
  <div class="display-flex my-2">
    <button mat-button aria-label="Delete Icon" class="background-color--muted" (click)="onClose()" tabindex="0">Close</button>
    <button mat-raised-button aria-label="Save Button" color="primary" (click)="onSave()" [disabled]="formGroup.invalid" tabindex="0">
      Save
    </button>
  </div>
</div>
