<h3 class="mt-0 mb-2">Data History</h3>

<ng-template #filterForm>
  <form [formGroup]="formGroup">
    <div class="row align-items-center">
      <div class="col-md-12 col-xl-4">
        <app-toggle-buttons [options]="rangeOptions" controlName="range" (click)="rangeChanged()" [fullWidth]="true"></app-toggle-buttons>
      </div>

      <div class="col-md-12 col-xl-4">
        <app-date-picker label="Start Date" controlName="startDate"></app-date-picker>
      </div>

      <div class="col-md-12 col-xl-4">
        <app-date-picker label="End Date" controlName="endDate"></app-date-picker>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #partCHistory let-data>
  <ng-container *ngIf="data.case.intakeType === intakeTypes.PartC">
    <h3 *ngIf="hasMultipleActiveCases" class="card-spacing-top mb-2">Early ACCESS</h3>
    <app-data-history-part-c [case]="data.case"> </app-data-history-part-c>
  </ng-container>
</ng-template>

<ng-template #partBHistory let-data>
  <ng-container *ngIf="data.case.intakeType === intakeTypes.PartB">
    <h3 *ngIf="hasMultipleActiveCases" class="card-spacing-top mb-2">Special Education</h3>
    <app-data-history-part-b [case]="data.case" [learnerId]="learnerId"></app-data-history-part-b>
  </ng-container>
</ng-template>

<ng-container *ngIf="hasMultipleActiveCases">
  <div>
    <mat-card>
      <ng-container *ngTemplateOutlet="filterForm"></ng-container>
    </mat-card>

    <ng-container *ngFor="let case of cases">
      <ng-container *ngTemplateOutlet="partBHistory; context: { $implicit: { case: case } }"></ng-container>

      <ng-container *ngTemplateOutlet="partCHistory; context: { $implicit: { case: case } }"></ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="!hasMultipleActiveCases">
  <div class="card__together-container">
    <mat-card class="card__together card__together--top">
      <ng-container *ngTemplateOutlet="filterForm"></ng-container>
    </mat-card>

    <mat-card class="p-0 card__together card__together--bottom">
      <mat-accordion
        class="accordion accordion--close accordion--no-border accordion--short accordion--in-card"
        displayMode="flat"
        *ngFor="let case of cases"
        multi
      >
        <ng-container *ngTemplateOutlet="partBHistory; context: { $implicit: { case: case } }"></ng-container>

        <ng-container *ngTemplateOutlet="partCHistory; context: { $implicit: { case: case } }"></ng-container>
      </mat-accordion>
    </mat-card>
  </div>
</ng-container>

<app-eco-rating-history [learnerId]="learnerId"></app-eco-rating-history>

<div class="page-bottom-leeway"></div>
