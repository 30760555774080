import { Component, Input, OnInit } from '@angular/core';
import { PieChart } from '../survey-reporting-dashboard.component';

@Component({
  selector: 'app-survey-reporting-chart',
  templateUrl: './survey-reporting-chart.component.html',
  styleUrls: ['./survey-reporting-chart.component.scss'],
})
export class SurveyReportingChartComponent implements OnInit {
  @Input() completionPercentage: number;
  @Input() pieChart: PieChart;

  constructor() {}

  ngOnInit(): void {}
}
