import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GoalProgressMonitor } from '../models/goal-progress-monitor';

@Injectable({
  providedIn: 'root',
})
export class ProgressMonitorBService {
  constructor(private http: HttpClient) {}

  get(learnerId: string) {
    return this.http.get<GoalProgressMonitor[]>(`api/learners/${learnerId}/progress-monitoring`);
  }

  getSingle(learnerId: string, iepGoalId: string) {
    return this.http.get<GoalProgressMonitor>(`api/learners/${learnerId}/progress-monitoring/${iepGoalId}`);
  }

  add(learnerId: string, primaryDto, secondaryDto = null) {
    const dto = {
      primary: primaryDto,
      secondary: secondaryDto,
    };
    return this.http.post<{ primaryId; primaryDate; secondaryId; secondaryDate }>(`api/learners/${learnerId}/progress-monitoring`, dto);
  }

  update(learnerId: string, primaryDto, secondaryDto = null) {
    const dto = {
      primary: primaryDto,
      secondary: secondaryDto,
    };
    return this.http.put<{ primaryId; primaryDate; secondaryId; secondaryDate }>(`api/learners/${learnerId}/progress-monitoring`, dto);
  }

  delete(learnerId: string, id: string) {
    return this.http.put(`api/learners/${learnerId}/progress-monitoring/delete/${id}`, null);
  }

  updateObjectiveStatus(learnerId: string, dto) {
    return this.http.put(`api/learners/${learnerId}/progress-monitoring/${dto.id}`, { status: dto.status });
  }
}
