import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { FamilyMeetingRead } from 'src/app/shared/models/case';
import { LearnerSummary } from 'src/app/shared/models/learner';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';

@Component({
  selector: 'app-notice-of-meeting-print',
  templateUrl: './notice-of-meeting-print.component.html',
  styleUrls: ['./notice-of-meeting-print.component.scss'],
})
export class NoticeOfMeetingPrintComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  shortDateFormat = shortDateFormat;
  learnerId: string;

  familyMeeting: FamilyMeetingRead;
  learner: LearnerSummary;
  currentDate = new Date();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      printData: {
        learnerId: string;
        familyMeeting: FamilyMeetingRead;
      };
    },
    private learnerService: LearnerService,
    public dialogRef: MatDialogRef<NoticeOfMeetingPrintComponent>
  ) {
    this.learnerId = data.printData.learnerId;
    this.familyMeeting = data.printData.familyMeeting;
  }

  ngOnInit(): void {
    this.getLearner();
  }

  getLearner() {
    this.subscriptions.add(this.learnerService.getLearnerSummary(this.learnerId).subscribe((res) => (this.learner = res)));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onClose() {
    this.dialogRef.close();
  }
}
