import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GoalProgressMonitor, ObjectiveProgressMonitor } from 'src/app/monitoring-progress/models/goal-progress-monitor';
import { ProgressMonitorItem } from 'src/app/monitoring-progress/models/progress-monitor-item';
import { ProgressMonitorBService } from 'src/app/monitoring-progress/services/progress-monitor-b.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { CaseSummary } from 'src/app/shared/models/case';
import { ConfirmationDialogComponent } from 'src/app/shared/services/notification.service';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { decimalValidator } from 'src/app/shared/validators';
import { AuthService } from '../../../../../../auth/auth.service';
import { DateFormatPipe, DateToUtcPipe } from '../../../../../../shared/pipes/date-transform.pipe';

@Component({
  selector: 'app-progress-data-table-b',
  templateUrl: './progress-data-table-b.component.html',
  styleUrls: ['./progress-data-table-b.component.scss'],
})
export class ProgressDataTableBComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() goal: GoalProgressMonitor | ObjectiveProgressMonitor;
  @Input() minDate: Date;
  @Input() enableForms: boolean;
  @Input() caseSummary: CaseSummary;
  @Output() progressMonitorItemMultipleDeleteEvent = new EventEmitter();
  @Output() progressMonitorItemUpdateEvent = new EventEmitter();
  isEditing: boolean;
  shortDateFormat = shortDateFormat;
  today = new Date();
  displayedColumns = ['actions', 'date', 'value', 'note'];

  formGroup = this.fb.group({
    id: '',
    date: [null, Validators.required],
    value: [null, [Validators.required, decimalValidator, Validators.min(0)]],
    note: '',
  });

  get hideEdit() {
    if (this.goal.isCombinedObjective) {
      return (this.goal.isObjective ? false : true) || !this.enableForms;
    }
    return false;
  }

  get hideDelete() {
    if (this.goal.isCombinedObjective) {
      return this.goal.isObjective ? true : false;
    }
    return false;
  }

  get isPortalUser() {
    return this.authService?.isPortalUser;
  }

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private progressMonitorService: ProgressMonitorBService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.isPortalUser) {
      this.displayedColumns = ['date', 'value', 'note'];
    }
  }

  ngAfterViewInit() {
    if (this.goal.data) {
      this.goal.data.sort = this.sort;
      this.goal.data.paginator = this.paginator;
    }
  }

  onEdit(progressMonitorItem: ProgressMonitorItem) {
    progressMonitorItem.value === null ? this.formGroup.get('value').disable() : this.formGroup.get('value').enable();
    this.isEditing = true;
    progressMonitorItem.isEditing = true;
    this.formGroup.patchValue(progressMonitorItem);
  }

  onDelete(progressMonitorItem: ProgressMonitorItem, goal: GoalProgressMonitor | ObjectiveProgressMonitor) {
    const ids = progressMonitorItem.id.split('/');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '728px',
      data: {
        title: 'Remove',
        message:
          !goal.isCombinedObjective || ids.length === 1
            ? 'Are you sure you want to remove this progress item?'
            : 'Removing this progress item will also remove progress items for the related combined objectives. Are you sure you want to remove this progress item?',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (!goal.isCombinedObjective) {
          this.progressMonitorService
            .delete(this.caseSummary.learnerId, progressMonitorItem.id)
            .subscribe(() => this.handleProgressMonitorItemDelete(progressMonitorItem, goal));
        } else {
          const observables = [];
          ids.forEach((id) => {
            observables.push(this.progressMonitorService.delete(this.caseSummary.learnerId, id));
          });
          forkJoin(observables)
            .pipe(finalize(() => this.handleProgressMonitorItemMultipleDelete(progressMonitorItem, goal)))
            .subscribe();
        }
      }
    });
  }

  onUpdate(progressMonitorItem: ProgressMonitorItem, goal: GoalProgressMonitor | ObjectiveProgressMonitor) {
    if (this.formGroup.invalid) {
      return;
    }
    const dto = this.formGroup.value as ProgressMonitorItem;
    dto.iepGoalQuantifiableMeasurementId = goal.id;
    dto.date = new DateToUtcPipe().transform(new Date(dto.date));
    this.progressMonitorService.update(this.caseSummary.learnerId, dto).subscribe((res) => {
      progressMonitorItem.date = res.primaryDate;
      this.handleProgressMonitorItemUpdate(progressMonitorItem, goal);
    });
  }

  onViewMore(progressMonitorItem: ProgressMonitorItem) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Date',
        value: new DateFormatPipe().transform(progressMonitorItem.date),
      },
      {
        name: 'Value',
        value: progressMonitorItem.value,
      },
      {
        name: 'Note',
        value: progressMonitorItem.note,
      },
    ];

    openViewMore(this.dialog, modalData);
  }

  onCancelEdit(progressMonitorItem: ProgressMonitorItem) {
    progressMonitorItem.isEditing = false;
    this.isEditing = false;
    this.formGroup.reset();
  }

  private handleProgressMonitorItemUpdate(progressMonitorItem: ProgressMonitorItem, goal: GoalProgressMonitor | ObjectiveProgressMonitor) {
    progressMonitorItem.value = this.formGroup.get('value').value;
    progressMonitorItem.note = this.formGroup.get('note').value;
    const index = goal.primaryMeasurement.progressData.findIndex((x) => x.id === progressMonitorItem.id);
    if (index > -1) {
      goal.primaryMeasurement.progressData[index] = progressMonitorItem;
      goal.data.data = goal.primaryMeasurement.progressData;
    }
    if (goal.isCombinedObjective) {
      this.progressMonitorItemUpdateEvent.emit();
    }
    this.onCancelEdit(progressMonitorItem);
  }

  private handleProgressMonitorItemDelete(progressMonitorItem: ProgressMonitorItem, goal: GoalProgressMonitor | ObjectiveProgressMonitor) {
    const index = goal.primaryMeasurement.progressData.indexOf(progressMonitorItem);
    if (index > -1) {
      goal.primaryMeasurement.progressData.splice(index, 1);
      goal.data.data = goal.primaryMeasurement.progressData;
    }
  }

  private handleProgressMonitorItemMultipleDelete(progressMonitorItem, goal) {
    const ids = progressMonitorItem.id.split('/');
    if (ids.length === 1 && !progressMonitorItem.value) {
      const index = goal.primaryMeasurement.progressData.findIndex((x) => x.id === ids[0]);
      if (index > -1) {
        goal.primaryMeasurement.progressData.splice(index, 1);
      }
    } else {
      ids.forEach((id) => {
        goal.objectives.forEach((objective) => {
          const index = objective.primaryMeasurement?.progressData.findIndex((x) => x.id === id);
          if (index > -1) {
            objective.primaryMeasurement?.progressData.splice(index, 1);
            objective.data.data = objective.primaryMeasurement?.progressData;
          }
        });
      });
    }
    this.progressMonitorItemMultipleDeleteEvent.emit();
  }
}
