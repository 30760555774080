<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>End {{ data.type }}</h3>
<form [formGroup]="formGroup" #formDirective="ngForm">
  <div mat-dialog-content class="py-3">
    <ng-container *ngIf="data.type === 'Goal'"> Goal Area(s) - {{ goalAreas }} </ng-container>
    <ng-container *ngIf="data.type === 'Service'"> Service - {{ data.model.serviceType.label }} </ng-container>
    <ng-container *ngIf="data.type === 'Accommodation'"> Accommodation - {{ data.model.description }} </ng-container>
    <ng-container *ngIf="data.type === 'ActivitySupport'"> Activity Support - {{ data.model.description }} </ng-container>
    <div *ngIf="showWeightingWarning" class="text-danger my-2">
      Ending this service may impact the weighting for this IEP. Please be sure to notify the following authorized individuals to review the
      weighting score: [logic for the list is pending future development]
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-date-picker label="{{ data.type }} End Date" controlName="endDate"></app-date-picker>
      </div>
      <div class="col-md-12" *ngIf="data.type === 'Service'">
        <app-select label="End Reason" formControlName="endReason" [options]="endReasonOptions"> </app-select>
      </div>
      <div class="col-md-12">
        <app-textarea label="Comments" formControlName="comments"> </app-textarea>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="display-flex justify-content-end align-items-center">
    <button
      (click)="onTag()"
      mat-raised-button
      attr.aria-label="{{
        amendmentTag === null || amendmentTag === undefined
          ? formGroup.value.tagForPwn
            ? 'Untag for Prior Written Notice'
            : 'Tag for Prior Written Notice'
          : amendmentTag?.taggedForPwn
          ? 'Untag for Prior Written Notice'
          : 'Tag for Prior Written Notice'
      }}"
      *ngIf="data.type === 'Service'"
      tabindex="0"
    >
      {{
        amendmentTag === null || amendmentTag === undefined
          ? formGroup.value.tagForPwn
            ? 'Untag for Prior Written Notice'
            : 'Tag for Prior Written Notice'
          : amendmentTag?.taggedForPwn
          ? 'Untag for Prior Written Notice'
          : 'Tag for Prior Written Notice'
      }}
    </button>
    <button mat-raised-button aria-label="Cancel Button" color="warn" (click)="onCancel()" type="button" class="ml-2" tabindex="0">
      Cancel
    </button>
    <button
      mat-raised-button
      aria-label="Complete"
      color="primary"
      class="ml-2"
      [disabled]="!formGroup.valid || submitting"
      (click)="onSubmit()"
      tabindex="0"
    >
      Complete
    </button>
  </div>
</form>
