export interface IepExtendedSchoolYear {
  id: string;
  order: number;
  showDateField: boolean;
  type: ExtendedSchoolYearOptions;
  label: string;
}

export enum ExtendedSchoolYearOptions {
  DevelopNow = 'DevelopNow',
  DevelopLater = 'DevelopLater',
  NotRequired = 'NotRequired',
  NotDeterminedEligible = 'NotDeterminedEligible',
}
