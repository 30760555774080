<button
  mat-raised-button
  aria-label="Add Icon"
  color="primary"
  *ngIf="!readOnly && hasOpenModification"
  (click)="$event.stopPropagation(); addNew()"
  tabindex="0"
>
  Add New
</button>
<button
  mat-raised-button
  aria-label="Clear Primary Service Provider Selection"
  color="primary"
  class="my-0"
  (click)="clearServiceProviders()"
  *ngIf="!readOnly && hasServiceProviders()"
  tabindex="0"
>
  Clear Primary Service Provider Selection
</button>
<div #servicesFormArea></div>
<mat-card class="mt-2" *ngIf="showServicesForm">
  <div #servicesFormArea></div>
  <app-ifsp-services
    [modifyingService]="modifyingService"
    [modificationId]="modification?.id"
    [modifications]="modifications"
  ></app-ifsp-services>
</mat-card>
<div class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" [formGroup]="providerFormGroup" matSort class="w-100">
    <ng-container matColumnDef="actions" *ngIf="!readOnly">
      <th mat-header-cell *matHeaderCellDef class="actions--md">Actions</th>

      <td mat-cell *matCellDef="let element" class="actions--md">
        <div class="display-flex align-items-center justify-content-start">
          <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewMore(element)" tabindex="0">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
          <ng-container *ngIf="!isEditing">
            <button
              *ngIf="showMenu(element)"
              aria-label="More option"
              mat-icon-button
              color="primary"
              [matMenuTriggerFor]="actionMenu"
              tabindex="0"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #actionMenu="matMenu">
              <button
                mat-menu-item
                aria-label="Add Progress Monitoring"
                *ngIf="canAddProgressMonitoring(element)"
                (click)="openComingSoon()"
                tabindex="0"
              >
                Add Progress Monitoring
              </button>
              <button mat-menu-item aria-label="View Graphs" *ngIf="canViewGraphs(element)" (click)="openComingSoon()" tabindex="0">
                View Graphs
              </button>
              <button mat-menu-item aria-label="Modify" *ngIf="canModify(element)" (click)="onEdit(element)" tabindex="0">Modify</button>
              <button *ngIf="canUndo(element)" mat-menu-item aria-label="Undo" (click)="onRemove(element, true)" tabindex="0">Undo</button>
              <button *ngIf="canRemove(element)" mat-menu-item aria-label="Delete Icon" (click)="onRemove(element)" tabindex="0">
                Remove
              </button>
              <button
                mat-menu-item
                aria-label="Change Providers"
                *ngIf="!anyEndDatesEqualOrPast(element)"
                (click)="onChangeProviders(element)"
                tabindex="0"
              >
                Change Providers
              </button>
              <button mat-menu-item aria-label="End Item" *ngIf="canEnd(element)" (click)="onEnd(element)" tabindex="0">
                <span class="text-danger"> End Item </span>
              </button>
            </mat-menu>
            <ng-container *ngIf="hasOpenModification">
              <div class="fake-icon-button" *ngIf="element.status === 'InComplete'">
                <mat-icon class="icon-incomplete" aria-label="Warning">warning</mat-icon>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="element.modificationEndDate">
            <div class="fake-icon-button">
              <i class="fas fa-calendar-times icon-incomplete"></i>
            </div>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="typeOfService">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type of Service</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.modificationId"
          [amendmentFinalized]="modificationIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.modificationEndDate"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="element.typeOfService"
          [currentVersionText]="element?.priorVersion ? element?.priorVersion.typeOfService : null"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="outcomeSupported">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Outcome(s) Supported</th>

      <td mat-cell *matCellDef="let element">
        <span class="display-block">
          <app-compare-amendment-output
            [amendmentId]="element.modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="element.modificationEndDate"
            [priorVersionId]="element?.priorVersionId"
            [isActive]="element.isActive"
            [changedVersionText]="getOutcomesText(element)"
            [currentVersionText]="element?.priorVersion ? getOutcomesText(element?.priorVersion) : null"
            [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="provider">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Provider(s)</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.modificationId"
          [amendmentFinalized]="modificationIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.modificationEndDate"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="(element.otherProvider ? element.otherProviderName : element.providerText) || '-'"
          [currentVersionText]="
            element?.priorVersion
              ? element.priorVersion.otherProvider
                ? element.priorVersion.otherProviderName
                : element.priorVersion.providerText
              : null
          "
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="agency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Agency</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.modificationId"
          [amendmentFinalized]="modificationIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.modificationEndDate"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="element.otherProvider ? element.otherProviderAgency : element.agencyText"
          [currentVersionText]="element?.priorVersion ? element?.priorVersion.agencyText : null"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.modificationId"
          [amendmentFinalized]="modificationIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.modificationEndDate"
          [priorVersionId]="element?.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="element.locationText"
          [currentVersionText]="element?.priorVersion ? element?.priorVersion.locationText : null"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="howOften">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>How Often</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.modificationId"
          [amendmentFinalized]="modificationIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.modificationEndDate"
          [priorVersionId]="element.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="getFrequencyText(element)"
          [currentVersionText]="element?.priorVersion ? getFrequencyText(element?.priorVersion) : null"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <ng-container matColumnDef="familyDeclined">
      <th mat-header-cell *matHeaderCellDef class="text-center">Family Declined</th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <mat-checkbox
          [disabled]="readOnly || !hasOpenModification"
          [checked]="element.familyDeclined"
          (change)="onFamilyDeclinedChange(element.id, element.modificationId, $event)"
          aria-labelledby="Check me!"
        ></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="primaryServiceProvider">
      <th mat-header-cell *matHeaderCellDef class="text-center">Primary Service Provider</th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <mat-radio-group
          [disabled]="readOnly || !hasOpenModification"
          formControlName="primaryServiceProvider_{{ element.id }}"
          (change)="onPSPChanged(element.id, element.modificationId)"
          aria-labelledby="Modification Id"
        >
          <mat-radio-button value="1" aria-labelledby="1"></mat-radio-button>
        </mat-radio-group>
      </td>
    </ng-container>

    <ng-container matColumnDef="length">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Length</th>

      <td mat-cell *matCellDef="let element">
        <app-compare-amendment-output
          [amendmentId]="element.modificationId"
          [amendmentFinalized]="modificationIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [amendmentEndReasonValue]="element.modificationEndDate"
          [priorVersionId]="element.priorVersionId"
          [isActive]="element.isActive"
          [changedVersionText]="getLengthText(element)"
          [currentVersionText]="element?.priorVersion ? getLengthText(element?.priorVersion) : null"
          [changedPrefixText]="element?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>

<hr class="input__divider" />

<section>
  <h4 class="mt-0 mb-2 text-primary">Medical or Other Service</h4>

  <div class="table-overflow app-table">
    <table mat-table [dataSource]="medicalDataSource" class="w-100" matSort>
      <ng-container matColumnDef="actions" *ngIf="!readOnly">
        <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more"></th>
        <td mat-cell *matCellDef="let service" class="mat-column-actions--more">
          <ng-container *ngIf="service.id && !isEditingMedicalService">
            <div class="display-flex align-items-center justify-content-start">
              <button aria-label="View Icon" color="primary" mat-icon-button type="button" (click)="onViewMore(service)" tabindex="0">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
              <button aria-label="Edit Icon" mat-icon-button (click)="setMedicalServiceEdit(service)" color="primary" tabindex="0">
                <mat-icon>edit</mat-icon>
              </button>
              <button aria-label="Delete Icon" mat-icon-button (click)="onMedicalServiceRemove(service)" color="warn" tabindex="0">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </ng-container>
          <div class="display-flex align-items-center">
            <ng-container *ngIf="service.isEditing">
              <button
                mat-icon-button
                aria-label="Save Icon"
                (click)="saveMedicalService(service)"
                color="primary"
                title="Save"
                tabindex="0"
              >
                <mat-icon>save</mat-icon>
              </button>
              <button
                mat-icon-button
                aria-label="Delete Icon"
                (click)="setMedicalServiceEdit(service, false)"
                color="warn"
                title="Cancel"
                tabindex="0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="addingNew">
            <button aria-label="Save Icon" mat-icon-button title="Save" color="primary" (click)="saveMedicalService(null)" tabindex="0">
              <mat-icon>save</mat-icon>
            </button>
            <button
              mat-icon-button
              aria-label="Clear Icon"
              color="warn"
              (click)="setMedicalServiceEdit(null, false)"
              title="Cancel"
              tabindex="0"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="!addingNew && !isEditingMedicalService">
            <button mat-icon-button aria-label="Add Icon" color="primary" (click)="addingNew = true" tabindex="0">
              <mat-icon>add_circle</mat-icon>
            </button>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="typeOfService">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Type of Service</th>
        <td mat-cell *matCellDef="let service">
          <app-inline-edit [editing]="service.isEditing">
            <ng-container viewMode>
              {{ service.typeOfService | truncate : 150 }}
            </ng-container>
            <ng-container editMode [formGroup]="medicalServiceFormGroup">
              <app-text-field formControlName="typeOfService"></app-text-field>
            </ng-container>
          </app-inline-edit>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="addingNew" [formGroup]="medicalServiceFormGroup">
            <app-text-field formControlName="typeOfService"></app-text-field>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="agencyName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Agency Name</th>
        <td mat-cell *matCellDef="let service">
          <app-inline-edit [editing]="service.isEditing">
            <ng-container viewMode>
              {{ service.agencyName | truncate : 150 }}
            </ng-container>
            <ng-container editMode [formGroup]="medicalServiceFormGroup">
              <app-text-field formControlName="agencyName"> </app-text-field>
            </ng-container>
          </app-inline-edit>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="addingNew" [formGroup]="medicalServiceFormGroup">
            <app-text-field formControlName="agencyName"></app-text-field>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="dateRequested">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Date Requested</th>
        <td mat-cell *matCellDef="let service">
          <app-inline-edit [editing]="service.isEditing">
            <ng-container viewMode>
              {{ service.dateRequested | dateFormat }}
            </ng-container>
            <ng-container editMode [formGroup]="medicalServiceFormGroup">
              <app-date-picker controlName="dateRequested"></app-date-picker>
            </ng-container>
          </app-inline-edit>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="addingNew" [formGroup]="medicalServiceFormGroup">
            <app-date-picker controlName="dateRequested"></app-date-picker>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="estimatedCompletionDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Estimated Completion Date</th>
        <td mat-cell *matCellDef="let service">
          <app-inline-edit [editing]="service.isEditing">
            <ng-container viewMode>
              {{ service.estimatedCompletionDate | dateFormat }}
            </ng-container>
            <ng-container editMode [formGroup]="medicalServiceFormGroup">
              <app-date-picker
                controlName="estimatedCompletionDate"
                [min]="medicalServiceFormGroup.get('dateRequested').value"
              ></app-date-picker>
            </ng-container>
          </app-inline-edit>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="addingNew" [formGroup]="medicalServiceFormGroup">
            <app-date-picker
              controlName="estimatedCompletionDate"
              [min]="medicalServiceFormGroup.get('dateRequested').value"
            ></app-date-picker>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="referralDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Referral Date</th>
        <td mat-cell *matCellDef="let service">
          <app-inline-edit [editing]="service.isEditing">
            <ng-container viewMode>
              {{ service.referralDate | dateFormat }}
            </ng-container>
            <ng-container editMode [formGroup]="medicalServiceFormGroup">
              <app-date-picker controlName="referralDate"></app-date-picker>
            </ng-container>
          </app-inline-edit>
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <ng-container *ngIf="addingNew" [formGroup]="medicalServiceFormGroup">
            <app-date-picker controlName="referralDate"></app-date-picker>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="medicalDisplayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: medicalDisplayedColumns"
        [ngClass]="{
          'label-offset-fix label-offset-fix--top': checkLabelOffset(row)
        }"
      ></tr>
      <tr mat-footer-row *matFooterRowDef="medicalDisplayedColumns"></tr>
    </table>
    <mat-paginator [dataSource]="medicalDataSource"></mat-paginator>
  </div>
  <p *ngIf="!medicalDataSource">No data yet...</p>
</section>
