import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { EvaluationService } from 'src/app/evaluation/services/evaluation.service';
import { NotificationService } from '../../services/notification.service';
import { NewWindowConfig, openNewWindow } from '../../windowHelpers';

@Component({
  selector: 'app-navigate-to-eval-button',
  templateUrl: './navigate-to-eval-button.component.html',
  styleUrls: ['./navigate-to-eval-button.component.scss'],
})
export class NavigateToEvalButtonComponent implements OnInit {
  @Input() activeEvaluationId: string;
  @Input() caseId: string;
  @Input() disabled: boolean;
  buttonTitle: string;

  constructor(private readonly evaluationService: EvaluationService, private readonly notificationService: NotificationService) {}

  ngOnInit(): void {
    this.buttonTitle = this.activeEvaluationId ? 'Evaluation' : 'Begin Evaluation';
  }

  navigateToEval() {
    if (this.activeEvaluationId) {
      const config: NewWindowConfig = {
        path: `cases/${this.caseId}/evaluation/${this.activeEvaluationId}/overview`,
      };
      openNewWindow(config);
    } else {
      this.buttonTitle = 'Evaluation';
      this.evaluationService.add(this.caseId).subscribe(
        (result) => {
          this.activeEvaluationId = result.id;
          const config: NewWindowConfig = {
            path: `cases/${this.caseId}/evaluation/${result.id}/overview`,
          };
          openNewWindow(config);
        },
        (response: HttpErrorResponse) => {
          const noConsent = 'Consent not provided';
          if (response.error?.find((x) => x.description === noConsent)) {
            this.notificationService.error(noConsent);
          }
        }
      );
    }
  }
}
