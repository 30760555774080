<div *ngIf="dataSource?.data.length !== 0" class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let evaluation">
        {{ evaluation.date | dateFormat }}
      </td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let evaluation">
        <ng-container
          *ngIf="evaluation.intakeType === intakeType.PartB && evaluation.type !== 'Reevaluation' && evaluation.isFinalized; else isLink"
        >
          Educational Evaluation Report
        </ng-container>
        <ng-template #isLink>
          <a (click)="goToEvaluation.emit(evaluation)">
            <ng-container *ngIf="evaluation.intakeType === intakeType.PartB && evaluation.type !== 'Reevaluation'">
              Full and Individual Initial Evaluation
            </ng-container>
            <ng-container *ngIf="evaluation.type === 'Reevaluation'"> Reevaluation </ng-container>
          </a>
          <ng-container *ngIf="evaluation.intakeType !== intakeType.PartB && evaluation.type !== 'Reevaluation'">
            Evaluation and Assessment
          </ng-container>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
<p *ngIf="dataSource.data?.length === 0">No data yet...</p>
