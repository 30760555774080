import { Component, Input, OnInit } from '@angular/core';
import { Plod } from '../../../../../../../ifsp/models/plod';
import { EcoArea, EcoAreaType } from '../../../../../../../evaluation/models/evaluation';
import { KeyValuePair } from '../../../../../../../shared/models/key-value-pair';
import { IfspService } from '../../../../../../../ifsp/services/ifsp.service';
import { IfspModificationService } from '../../../../../../../ifsp/services/ifsp-modification.service';
import { EcoAreaService } from '../../../../../../../shared/services/eco-areas/eco-area.service';
import { EvaluationDomainService } from '../../../../../../../shared/services/evaluation-domains/evaluation-domain.service';
import { forkJoin } from 'rxjs';
import { IfspView } from '../../../../../../../ifsp/models/ifsp';
import { DateFormatPipe } from '../../../../../../../shared/pipes/date-transform.pipe';

@Component({
  selector: 'app-eco-output-by-ifsp',
  templateUrl: './eco-output-by-ifsp.component.html',
  styleUrls: ['./eco-output-by-ifsp.component.scss'],
})
export class EcoOutputByIfspComponent implements OnInit {
  @Input() ifsp: IfspView;
  plodModel: Plod;
  ecoAreas: EcoArea[] = [];
  evaluationDomains: KeyValuePair[];
  constructor(
    private readonly ifspService: IfspService,
    private readonly ifspModificationService: IfspModificationService,
    private readonly ecoAreaService: EcoAreaService,
    private evaluationDomainService: EvaluationDomainService
  ) {}

  ngOnInit(): void {
    forkJoin([this.ifspService.getCurrentPlod(this.ifsp.id), this.ecoAreaService.get(), this.evaluationDomainService.get()]).subscribe(
      ([plodResult, ecoAreaResult, domains]) => {
        this.plodModel = plodResult;
        this.ecoAreas = ecoAreaResult;
        this.evaluationDomains = domains.filter((x) => !x.isPartB).map((x) => new KeyValuePair(x.id, x.label));
      }
    );
  }

  scoreData(ea: EcoArea = null) {
    if (!ea) {
      return this.plodModel?.notTaggedAreasDescription;
    }
    if (ea.type === EcoAreaType.AcquisitionSkills) {
      return this.plodModel?.acquisitionSkills;
    }
    if (ea.type === EcoAreaType.AppropriateBehaviors) {
      return this.plodModel?.appropriateNeeds;
    }
    if (ea.type === EcoAreaType.PositiveSocialEmotionalSkills) {
      return this.plodModel?.positiveSkills;
    }
    return this.plodModel?.notTaggedAreasDescription;
  }

  get getIfspDate() {
    if (this.ifsp.modifications && this.ifsp.modifications.some((x) => x.finalizeDate !== null)) {
      const finalizeDate = this.ifsp.modifications.reduce((a, b) => {
        return new Date(a.finalizeDate) > new Date(b.finalizeDate) ? a : b;
      }).finalizeDate;

      return 'Modified on: ' + new DateFormatPipe().transform(finalizeDate);
    }

    return 'IFSP start date: ' + new DateFormatPipe().transform(this.ifsp.startDate);
  }
}
