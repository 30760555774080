<form [formGroup]="formGroup" *ngIf="currentEvaluationDetail">
  <ng-container *ngIf="!currentEvaluationNotes">
    <h3 class="text-primary mt-0 mb-1" [ngClass]="{ asterisk_input: !this.hasAtLeastOneNoteForInterviewQuestion && !!interviewQuestion }">
      {{ interviewQuestion ? interviewQuestion.label : 'Note' }}
    </h3>
    <app-textarea formControlName="note" [rows]="6" [label]="'Note'" [maxLength]="10000"></app-textarea>

    <ng-container *ngIf="currentEvaluationDetail.test">
      <div class="display-flex justify-content-between align-items-center flex-wrap w-fit">
        <div class="display-flex align-items-center flex-wrap">
          <div class="">
            <app-number-field
              label="Age Equivalent (months)"
              formControlName="ageEquivalentLow"
              [min]="0"
              id="ageEquivalentLow"
            ></app-number-field>
          </div>
          <ng-container *ngIf="isAgeRange">
            <p class="mb-0 mx-3">to</p>
            <div class="">
              <app-number-field
                label="Age Equivalent (months)"
                formControlName="ageEquivalentHigh"
                [min]="0"
                id="ageEquivalentHigh"
              ></app-number-field>
            </div>
          </ng-container>

          <div class="display-flex flex-column ml-3" *ngIf="formGroup.controls.ageEquivalentLow.value !== null">
            <p class="mb-1">
              <strong>Calculated Percent Delay</strong>
            </p>
            <ng-container *ngIf="!isAgeRange">
              {{ formGroup.get('calculatedPercentDelayLow').value | percent }}
            </ng-container>
            <ng-container *ngIf="isAgeRange">
              {{ formGroup.get('calculatedPercentDelayHigh').value | percent }}
              -
              {{ formGroup.get('calculatedPercentDelayLow').value | percent }}
            </ng-container>
          </div>
        </div>

        <button
          mat-raised-button
          attr.aria-label="{{ isAgeRange ? 'Remove Range' : 'Indicate Age Level Range' }}"
          class="ml-3"
          color="accent"
          type="button"
          (click)="onRangeClick()"
          tabindex="0"
        >
          {{ isAgeRange ? 'Remove Range' : 'Indicate Age Level Range' }}
        </button>
      </div>
    </ng-container>

    <div class="mt-3"></div>

    <app-help-directional-text>
      <p class="mb-2">Click the buttons below to expand options for tagging.</p>
    </app-help-directional-text>

    <app-evaluation-tags
      [caseId]="caseId"
      [currentEvaluationDetail]="currentEvaluationDetail"
      [evaluationId]="currentEvaluationDetail?.evaluationId"
      [selectedEcoAreas]="currentEvaluationNote?.ecoAreas || []"
      [selectedEvaluationDomains]="currentEvaluationNote?.domainAreas || []"
      [selectedEvaluationRoutines]="currentEvaluationNote?.evaluationRoutines || []"
      [preSavedNoteTags]="[
        currentEvaluationNote?.taggedForServicesC,
        currentEvaluationNote?.taggedForOutcomes,
        currentEvaluationNote?.taggedForPwn
      ]"
      (updatePreSavedNoteTags)="updatePreSavedNoteTags($event)"
    ></app-evaluation-tags>

    <div class="mt-3">
      <mat-error
        *ngIf="
          (formGroup.touched && !isValid && !isInterview) ||
          (formGroup.touched &&
            (currentEvaluationDetail.test || currentEvaluationDetail.observation) &&
            currentEvaluationNote?.domainAreas?.length === 0)
        "
        class="text-sm"
        >At least one Developmental Area is required</mat-error
      >
    </div>

    <div class="action__row">
      <ng-content select="[cancelNoteAndCloseButton]"></ng-content>
      <button
        mat-raised-button
        aria-label="Complete Note and Add Another"
        color="primary"
        type="button"
        (click)="onSubmit()"
        class="ml-2 mr-0"
        tabindex="0"
      >
        Complete Note and Add Another
      </button>
      <ng-content select="[doneButton]"></ng-content>
    </div>
  </ng-container>

  <ng-container *ngIf="currentEvaluationNotes">
    <h3 class="text-primary mt-0 mb-1" [ngClass]="{ asterisk_input: !this.hasAtLeastOneNoteForInterviewQuestion && !!interviewQuestion }">
      {{ interviewQuestion ? interviewQuestion.label : 'Note' }}
    </h3>
    <app-textarea formControlName="note" [rows]="4" [label]="'Note'" [maxLength]="10000"></app-textarea>

    <div class="mt-3"></div>

    <app-evaluation-tags
      [caseId]="caseId"
      [evaluationId]="currentEvaluationDetail?.evaluationId"
      [currentEvaluationNote]="currentInterviewNote"
      [selectedEcoAreas]="currentInterviewNote?.ecoAreas || []"
      [selectedEvaluationDomains]="currentInterviewNote?.domainAreas || []"
      [selectedEvaluationRoutines]="currentInterviewNote?.evaluationRoutines || []"
    ></app-evaluation-tags>

    <div class="mb-2" *ngFor="let note of currentEvaluationNotes">
      <hr class="input__divider" *ngIf="note.evaluationDetailInterviewQuestionId === interviewQuestion.id" />

      <div class="display-flex align-items-start" *ngIf="note.evaluationDetailInterviewQuestionId === interviewQuestion.id">
        <a aria-label="Edit Icon" (click)="onEditNote(note)" class="icon--muted mr-3" tabindex="0" role="link">
          <mat-icon>edit</mat-icon>
        </a>
        <a aria-label="Delete Icon" (click)="onRemoveNote(note)" class="icon--muted mr-3" tabindex="0" role="link">
          <mat-icon>close</mat-icon>
        </a>
        <p class="mb-0">{{ note.note }}</p>
      </div>
    </div>
    <div class="display-flex justify-content-end align-items-center">
      <button
        mat-raised-button
        aria-label="Complete Note"
        color="primary"
        type="button"
        (click)="onInterviewNoteSubmit()"
        [disabled]="!formGroup.get('note').value"
        tabindex="0"
      >
        Complete Note
      </button>
    </div>
  </ng-container>
</form>
