<h3 class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Print IEP</h3>

<div class="mat-dialog-content">
  <mat-card class="py-2"> Please select which sections of the IEP you would like to print. </mat-card>
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0 w-100">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Id Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Section</th>
      <td mat-cell *matCellDef="let element">
        {{ element }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
    <tr
      mat-footer-row
      [hidden]="dataSource.data?.length !== 0"
      *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
      class="example-second-footer-row"
    ></tr>
  </table>
</div>

<div class="mat-dialog-actions justify-content-end">
  <button mat-raised-button aria-label="Delete Icon" (click)="onClose()" cdkFocusInitial tabindex="0">Close</button>
  <button
    mat-raised-button
    aria-label="Print"
    color="primary"
    (click)="onPrint()"
    [disabled]="selection.selected.length === 0"
    tabindex="0"
    [isBusy]="printingDoc"
  >
    Print
  </button>
</div>
