<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Update Notes</h3>
<mat-dialog-content>
  <div class="row mt-4 mb-4" [formGroup]="formGroup">
    <div class="col-md-12">
      <app-textarea label="Notes" formControlName="comments" [rows]="4"></app-textarea>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" mat-raised-button aria-label="Cancel Button" [mat-dialog-close]="true" tabindex="0">Cancel</button>
  <button
    color="primary"
    type="button"
    mat-raised-button
    aria-label="Ok Button"
    (click)="submit()"
    [disabled]="formGroup.invalid"
    tabindex="0"
  >
    Ok
  </button>
</mat-dialog-actions>
