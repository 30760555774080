import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements OnInit, AfterViewInit {
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;

  @Input() height = '500';
  @Input() width = '500';

  private lastX = 0;
  private lastY = 0;
  private canvasContext: any;
  private canvasNative: any;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.canvasNative = this.canvas.nativeElement;
    this.canvasContext = this.canvasNative.getContext('2d');
    this.canvasNative.addEventListener('mousedown', this.setLastCoords.bind(this)); // fires before mouse left btn is released
    this.canvasNative.addEventListener('mousemove', this.freeForm.bind(this));
  }

  getCanvas(): string {
    return this.canvasNative.toDataURL();
  }

  clearCanvas() {
    this.canvasContext.clearRect(0, 0, this.canvasNative.width, this.canvasNative.height);
  }

  private setLastCoords(e) {
    const { x, y } = this.canvasNative.getBoundingClientRect();
    this.lastX = e.clientX - x;
    this.lastY = e.clientY - y;
  }

  private freeForm(e) {
    if (e.buttons !== 1) {
      return;
    } // left button is not pushed
    this.penTool(e);
  }

  private penTool(e) {
    const { x, y } = this.canvasNative.getBoundingClientRect();
    const newX = e.clientX - x;
    const newY = e.clientY - y;

    this.canvasContext.beginPath();
    this.canvasContext.lineWidth = 5;
    this.canvasContext.moveTo(this.lastX, this.lastY);
    this.canvasContext.lineTo(newX, newY);
    this.canvasContext.strokeStyle = 'black';
    this.canvasContext.stroke();
    this.canvasContext.closePath();

    this.lastX = newX;
    this.lastY = newY;
  }
}
