<div #serviceForEdit></div>
<div class="display-flex align-items-end justify-content-between mb-2">
  <h3
    class="my-0"
    [ngClass]="{
      asterisk_input: formGroup.get('outcomes').hasError('required')
    }"
  >
    Select one or more Outcomes
  </h3>
  <div>
    <button
      mat-raised-button
      attr.aria-label="{{ allOutcomesSelected ? 'Unselect ' : 'Select ' }} All Outcomes"
      color="accent"
      *ngIf="outcomes && outcomes.length > 0"
      (click)="onSelectAll()"
      tabindex="0"
    >
      {{ allOutcomesSelected ? 'Unselect ' : 'Select ' }}
      All Outcomes
    </button>
    <button class="ml-1" mat-raised-button aria-label="View IFSP Service Tags" color="accent" (click)="openTags()" tabindex="0">
      View IFSP Service Tags
    </button>
  </div>
</div>

<form [formGroup]="formGroup" #formDirective="ngForm">
  <mat-card class="my-0">
    <mat-button-toggle-group
      multiple="true"
      class="display-flex flex-1 flex-column"
      formControlName="outcomes"
      aria-labelledby="Select an option"
    >
      <mat-button-toggle
        *ngFor="let outcome of outcomes; let i = index"
        [value]="outcome.id"
        class="display-flex flex-column toggle-spacer toggle--outcome"
        [id]="outcome.id"
        attr.aria-label="{{ i + 1 }} : {{ outcome.title }}"
      >
        <p class="my-0">
          <strong>Outcome {{ i + 1 }}: </strong>
          {{ outcome.title }}
        </p>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <app-help-directional-text>
      <p>
        Select at least one outcome to add a service; add Service Coordination with or without a corresponding outcome by checking the
        Service Coordination box.
      </p>
    </app-help-directional-text>
    <div *ngIf="!outcomes.length">No Completed Outcomes</div>
    <app-compare-amendment-output
      *ngIf="modifyingService"
      [amendmentId]="modifyingService.modificationId"
      [amendmentFinalized]="modificationIsFinalized"
      [amendmentDate]="lastFinalizedDate"
      [priorVersionId]="modifyingService?.priorVersionId"
      [isActive]="service?.isActive"
      [changedVersionText]="getOutcomesText(formGroup.value, false)"
      [currentVersionText]="service?.priorVersion ? getOutcomesText(service?.priorVersion, true) : null"
      [currentPrefixText]="modifyingService?.priorVersion ? 'Current: ' : ''"
      changedPrefixText="Proposed: "
    ></app-compare-amendment-output>
    <app-checkbox-single class="mt-2 mb-2" formControlName="isServiceCoordinatorService" label="Service Coordination">
    </app-checkbox-single>
    <app-compare-amendment-output
      *ngIf="modifyingService"
      [amendmentId]="modifyingService.modificationId"
      [amendmentFinalized]="modificationIsFinalized"
      [amendmentDate]="lastFinalizedDate"
      [priorVersionId]="modifyingService?.priorVersionId"
      [isActive]="service?.isActive"
      [changedVersionText]="formGroup.get('isServiceCoordinatorService').value | boolToYesNo"
      [currentVersionText]="service?.priorVersion ? (service?.priorVersion?.isServiceCoordinatorService | boolToYesNo) : null"
      [currentPrefixText]="modifyingService?.priorVersion ? 'Current: ' : ''"
      changedPrefixText="Proposed: "
    ></app-compare-amendment-output>
  </mat-card>

  <div class="row">
    <div class="col-lg">
      <mat-error *ngIf="submitAttempted && !formGroup.get('outcomes').valid">
        At least one Outcome is <strong>required</strong> in order to submit.
      </mat-error>
    </div>
  </div>
  <ng-container *ngIf="!formGroup.get('outcomes').hasError('required') && patchingDone">
    <h3 class="card-spacing-top mb-2">Service Details</h3>

    <mat-card class="card--shadow" [ngClass]="{ 'py-1': !showServiceDetails && !outcomeNotRequired }">
      <div class="row">
        <div class="col-md-12 col-xl-6">
          <app-autocomplete
            *ngIf="!outcomeNotRequired"
            [options]="serviceTypeOptions"
            formControlName="service"
            label="Service"
          ></app-autocomplete>
          <app-compare-amendment-output
            *ngIf="modifyingService"
            [amendmentId]="modifyingService.modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="modifyingService?.priorVersionId"
            [isActive]="service?.isActive"
            [changedVersionText]="getTypeOfServiceText(formGroup.get('service').value)"
            [currentVersionText]="service?.priorVersion ? getTypeOfServiceText(service?.priorVersion.service) : null"
            [currentPrefixText]="modifyingService?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </div>
      <ng-container *ngIf="showServiceDetails || outcomeNotRequired">
        <hr *ngIf="!outcomeNotRequired" class="input__divider mt-2 mb-3" />
        <app-help-directional-text>
          <p class="mb-2">
            Select the regular, purposeful, ongoing set of actions delivered to or on behalf of a student over time. Provide a description
            of the
            <a (click)="openIThree()">service</a>
            including the number of minutes and frequency, the setting where services are provided, and the persons responsible.
          </p>
        </app-help-directional-text>

        <h4 class="text-primary mb-0">Service Details:</h4>
        <div class="row">
          <div class="col-md-12 col-xl-6">
            <app-autocomplete-chips
              *ngIf="howOptions"
              [options]="howOptions"
              formControlName="howIds"
              label="How services are provided (choose all that apply)"
            ></app-autocomplete-chips>
            <app-compare-amendment-output
              *ngIf="modifyingService"
              [amendmentId]="modifyingService.modificationId"
              [amendmentFinalized]="modificationIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="modifyingService?.priorVersionId"
              [isActive]="service?.isActive"
              [changedVersionText]="getHowText(formGroup.get('howIds').value)"
              [currentVersionText]="service?.priorVersion ? getHowText(service?.priorVersion.how) : null"
              [currentPrefixText]="modifyingService?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>
            <app-text-field
              *ngIf="showHowExplanation"
              [required]="showHowExplanation"
              label="Explain how"
              formControlName="howExplanation"
            ></app-text-field>
            <app-autocomplete [options]="whoOptions" formControlName="with" label="With Whom?"></app-autocomplete>
            <app-compare-amendment-output
              *ngIf="modifyingService"
              [amendmentId]="modifyingService.modificationId"
              [amendmentFinalized]="modificationIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="modifyingService?.priorVersionId"
              [isActive]="service?.isActive"
              [changedVersionText]="getWhoText(formGroup.get('with').value)"
              [currentVersionText]="service?.priorVersion ? getWhoText(service?.priorVersion.with) : null"
              [currentPrefixText]="modifyingService?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>
            <app-autocomplete
              label="Provider"
              formControlName="provider"
              [options]="providerOptions"
              [allowInvalidModelValue]="false"
              *ngIf="!formGroup.get('otherProvider').value && displayProvider"
            ></app-autocomplete>
            <app-compare-amendment-output
              *ngIf="modifyingService"
              [amendmentId]="modifyingService.modificationId"
              [amendmentFinalized]="modificationIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="modifyingService?.priorVersionId"
              [isActive]="service?.isActive"
              [changedVersionText]="getProviderText(formGroup.get('provider').value)"
              [currentVersionText]="service?.priorVersion ? getProviderText(service?.priorVersion.provider) : null"
              [currentPrefixText]="modifyingService?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>
            <app-checkbox-single
              class="mt-2 mb-2"
              formControlName="otherProvider"
              aria-labelledby="Provider is outside of ACHIEVE"
              label=" Provider is outside of ACHIEVE"
            >
            </app-checkbox-single>
            <ng-container *ngIf="formGroup.get('otherProvider').value">
              <app-text-field label="Provider Name" formControlName="otherProviderName"></app-text-field>
              <app-compare-amendment-output
                *ngIf="modifyingService"
                [amendmentId]="modifyingService.modificationId"
                [amendmentFinalized]="modificationIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [priorVersionId]="modifyingService?.priorVersionId"
                [isActive]="service?.isActive"
                [changedVersionText]="formGroup.get('otherProviderName').value"
                [currentVersionText]="service?.priorVersion ? service?.priorVersion.otherProviderName : null"
                [currentPrefixText]="modifyingService?.priorVersion ? 'Current: ' : ''"
                changedPrefixText="Proposed: "
              ></app-compare-amendment-output>
              <app-text-field label="Provider Agency" formControlName="otherProviderAgency"></app-text-field>
              <app-compare-amendment-output
                *ngIf="modifyingService"
                [amendmentId]="modifyingService.modificationId"
                [amendmentFinalized]="modificationIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [priorVersionId]="modifyingService?.priorVersionId"
                [isActive]="service?.isActive"
                [changedVersionText]="formGroup.get('otherProviderAgency').value"
                [currentVersionText]="service?.priorVersion ? service?.priorVersion.otherProviderAgency : null"
                [currentPrefixText]="modifyingService?.priorVersion ? 'Current: ' : ''"
                changedPrefixText="Proposed: "
              ></app-compare-amendment-output>
              <app-text-field label="Provider Role" formControlName="otherProviderRole"></app-text-field>
              <app-compare-amendment-output
                *ngIf="modifyingService"
                [amendmentId]="modifyingService.modificationId"
                [amendmentFinalized]="modificationIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [priorVersionId]="modifyingService?.priorVersionId"
                [isActive]="service?.isActive"
                [changedVersionText]="formGroup.get('otherProviderRole').value"
                [currentVersionText]="service?.priorVersion ? service?.priorVersion.otherProviderRole : null"
                [currentPrefixText]="modifyingService?.priorVersion ? 'Current: ' : ''"
                changedPrefixText="Proposed: "
              ></app-compare-amendment-output>
            </ng-container>
            <app-date-picker *ngIf="hasModification" label="Projected Start Date" controlName="startDate"></app-date-picker>
            <app-date-picker
              *ngIf="!hasModification"
              label="Projected Start Date"
              controlName="startDate"
              [min]="ifspCreatedOn"
            ></app-date-picker>
            <app-compare-amendment-output
              *ngIf="modifyingService"
              [amendmentId]="modifyingService.modificationId"
              [amendmentFinalized]="modificationIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="modifyingService?.priorVersionId"
              [isActive]="service?.isActive"
              [changedVersionText]="getServiceProjectedStartDateText(formGroup.get('startDate').value)"
              [currentVersionText]="service?.priorVersion ? getServiceProjectedStartDateText(service?.priorVersion.startDate) : null"
              [currentPrefixText]="modifyingService?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>
          </div>

          <div class="col-md-12 col-xl-6">
            <fieldset>
              <app-radio-group
                label="Choose the primary location where the services will be delivered"
                [column]="true"
                [wrap]="true"
                formControlName="location"
                [options]="locationOptions"
              ></app-radio-group>
            </fieldset>
            <div class="my-2">
              <app-compare-amendment-output
                *ngIf="modifyingService"
                [amendmentId]="modifyingService.modificationId"
                [amendmentFinalized]="modificationIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [priorVersionId]="modifyingService?.priorVersionId"
                [isActive]="service?.isActive"
                [changedVersionText]="getLocationText(formGroup.get('location').value)"
                [currentVersionText]="service?.priorVersion ? getLocationText(service?.priorVersion.location) : null"
                [currentPrefixText]="modifyingService?.priorVersion ? 'Current: ' : ''"
                changedPrefixText="Proposed: "
              ></app-compare-amendment-output>
            </div>

            <div class="mt-2" *ngIf="showJustificationQuestion">
              <mat-label> Provide Justification for Not Choosing a Natural Environment for the Location </mat-label>

              <app-textarea label="Justification" formControlName="justificationLocation"> </app-textarea>
              <app-compare-amendment-output
                *ngIf="modifyingService"
                [amendmentId]="modifyingService.modificationId"
                [amendmentFinalized]="modificationIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [priorVersionId]="modifyingService.priorVersionId"
                [isActive]="service?.isActive"
                [changedVersionText]="formGroup.get('justificationLocation').value"
                [currentVersionText]="service?.priorVersion ? service?.priorVersion.justificationLocation : null"
                [currentPrefixText]="modifyingService?.priorVersion ? 'Current: ' : ''"
                changedPrefixText="Proposed: "
              ></app-compare-amendment-output>
            </div>

            <div class="display-flex align-items-center">
              <label class="vertical-label" for="length">Length per session:</label>
              <div class="input--small ml-3 mr-2">
                <app-number-field formControlName="length" id="length" [min]="0"></app-number-field>
              </div>
              <label class="vertical-label">minutes</label>
            </div>
            <app-compare-amendment-output
              *ngIf="modifyingService"
              [amendmentId]="modifyingService.modificationId"
              [amendmentFinalized]="modificationIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="modifyingService.priorVersionId"
              [isActive]="service?.isActive"
              [changedVersionText]="formGroup.get('length').value"
              [currentVersionText]="service?.priorVersion ? service?.priorVersion.length : null"
              [currentPrefixText]="modifyingService?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>

            <div class="display-flex align-items-center">
              <label class="vertical-label" for="frequencyNumber">How often?</label>
              <div class="input--small ml-3 mr-2">
                <app-number-field formControlName="frequencyNumber" id="frequencyNumber" [min]="0"></app-number-field>
              </div>
              <label class="vertical-label" for="frequencyPeriod">time(s)</label>
              <app-select class="ml-3" [options]="frequencyPeriodOptions" formControlName="frequencyPeriod" label="Frequency"></app-select>
            </div>
            <app-compare-amendment-output
              *ngIf="modifyingService"
              [amendmentId]="modifyingService.modificationId"
              [amendmentFinalized]="modificationIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="modifyingService.priorVersionId"
              [isActive]="service?.isActive"
              [changedVersionText]="getFrequencyText(formGroup.value)"
              [currentVersionText]="service?.priorVersion ? getFrequencyText(service?.priorVersion) : null"
              [currentPrefixText]="modifyingService?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>
          </div>
        </div>
      </ng-container>
    </mat-card>
  </ng-container>

  <div class="display-flex justify-content-end align-items-center flex-wrap mt-2">
    <div class="mr-1" *ngIf="service?.id">
      <button
        (click)="tag(service, taggedForCategory.Outcomes)"
        mat-icon-button
        aria-label="Goal Tagging Icon"
        class="text-lg mr-1"
        tabindex="0"
      >
        <i *ngIf="!service?.taggedItem?.taggedForOutcomes" class="fas fa-bullseye tag__icon"></i>
        <i *ngIf="service?.taggedItem?.taggedForOutcomes" class="fas fa-bullseye tag__icon text-success mr-1"></i>
      </button>
      <button
        (click)="tag(service, taggedForCategory.Pwn)"
        mat-raised-button
        attr.aria-label="{{ !service?.taggedItem?.taggedForPwn ? 'Tag for Prior Written Notice' : 'Untag for Prior Written Notice' }}"
        type="button"
        tabindex="0"
      >
        {{ !service?.taggedItem?.taggedForPwn ? 'Tag for Prior Written Notice' : 'Untag for Prior Written Notice' }}
      </button>
    </div>

    <button mat-raised-button attr.aria-label="Cancel" color="warn" (click)="deleteOrCancel()" type="button" tabindex="0">Cancel</button>
    <button
      mat-raised-button
      aria-label="Save Button"
      class="ml-2"
      color="primary"
      (click)="saveAndClose()"
      [disabled]="!service"
      [disableAfterBusy]="formGroup.invalid"
      [isBusy]="isBusy"
      tabindex="0"
    >
      Save
    </button>
    <button
      mat-raised-button
      aria-label="Complete"
      class="ml-2"
      color="primary"
      (click)="submit(false)"
      [disabled]="formGroup.invalid"
      type="submit"
      [disableAfterBusy]="formGroup.invalid"
      [isBusy]="isBusy"
      tabindex="0"
    >
      Complete
    </button>
    <button
      mat-raised-button
      aria-label="Complete and Clone"
      color="accent"
      class="ml-2"
      (click)="submit(true)"
      *ngIf="!hasModification"
      [disabled]="formGroup.invalid"
      tabindex="0"
    >
      Complete and Clone
    </button>
  </div>
</form>
<ng-container *ngIf="!hasModification">
  <h3 class="mt-0 mb-2">Services List</h3>
  <mat-card class="my-0">
    <app-ifsp-service-list
      #servicelist
      (editService)="onEditService($event)"
      [ifspId]="ifspId"
      [isEditing]="!!this.service"
      [trackFormChanges]="servicelist.medicalServiceFormGroup"
    >
    </app-ifsp-service-list>
  </mat-card>

  <div class="page-bottom-leeway"></div>
</ng-container>
