import { LookupBase } from './lookup-base';

export class KeyValuePair {
  /**
   * Creates a key value pair object. If value is left empty then
   * both key and value will be the same
   * @param key key
   * @param value if undefined the value of key will be used
   * @param selected selected or not
   * @param enabled allows for individual options to be disabled some controls
   */
  constructor(key: any, value: any = null, selected = false, enabled = true) {
    this.key = key;
    if (value === null) {
      value = key;
    }
    this.value = value;
    this.selected = selected;
    this.enabled = enabled;
  }

  key: any;
  value: string;
  selected: any;
  enabled: boolean;

  /**
   * Gets the display value from a KeyValuePair based on the key
   */
  public static getValue(options: KeyValuePair[], key: any): any {
    return options.find((x) => x.key === key)?.value;
  }

  /**
   * Gets the display values from a KeyValuePair based on an array of keys
   */
  public static getValues(options: KeyValuePair[], keys: any[]): any[] {
    return options.filter((x) => keys.includes(x.key)).map((x) => x.value);
  }

  public static sortByValue(options: KeyValuePair[]): KeyValuePair[] {
    return options.sort((left, right) => {
      return left.value.toUpperCase() < right.value.toUpperCase() ? -1 : 1;
    });
  }

  public static createFromArray<T>(array: Array<T>, keyResolver: (item: T) => any, valueResolver: (item: T) => any): Array<KeyValuePair> {
    return array.map((x) => new KeyValuePair(keyResolver(x), valueResolver(x)));
  }

  public static createFromLookups<T extends LookupBase>(array: Array<T>): Array<KeyValuePair> {
    return this.createFromArray(
      array,
      (x) => x.id,
      (x) => x.label
    );
  }
}
