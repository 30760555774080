import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EcoMatrixItem } from '../models/eco-matrix-item';
import { EcoMatrixNote } from '../models/eco-matrix-note';
import { EcoMatrixSummary } from '../models/eco-matrix-summary';

@Injectable({
  providedIn: 'root',
})
export class EcoMatrixService {
  constructor(private http: HttpClient) {}

  getNotes(evaluationId: string) {
    return this.http.get<EcoMatrixNote[]>(`api/evaluations/${evaluationId}/eco-matrix/notes`);
  }

  getSummaries(evaluationId: string) {
    return this.http.get<EcoMatrixSummary>(`api/evaluations/${evaluationId}/eco-matrix/summaries`);
  }

  getAllItems(evaluationId: string) {
    return this.http.get<EcoMatrixItem[]>(`api/evaluations/${evaluationId}/eco-matrix/items`);
  }

  saveEcoMatrixItem(evaluationId: string, ecoMatrixItem: EcoMatrixItem) {
    if (ecoMatrixItem.id) {
      return this.http.put<EcoMatrixItem>(`api/evaluations/${evaluationId}/eco-matrix/items`, ecoMatrixItem);
    } else {
      return this.http.post<EcoMatrixItem>(`api/evaluations/${evaluationId}/eco-matrix/items`, ecoMatrixItem);
    }
  }

  saveEcoMatrixSummary(evaluationId: string, ecoMatrixSummary: EcoMatrixSummary) {
    if (ecoMatrixSummary.id) {
      return this.http.put<EcoMatrixSummary>(`api/evaluations/${evaluationId}/eco-matrix/summaries`, ecoMatrixSummary);
    } else {
      return this.http.post<EcoMatrixSummary>(`api/evaluations/${evaluationId}/eco-matrix/summaries`, ecoMatrixSummary);
    }
  }

  setNoteAsHidden(evaluationId: string, noteId: string) {
    return this.http.put(`api/evaluations/${evaluationId}/eco-matrix/notes/${noteId}/visibility`, null);
  }

  setNoteAsAutoFilled(evaluationId: string, note: EcoMatrixNote) {
    return this.http.put(`api/evaluations/${evaluationId}/eco-matrix/notes/${note.id}`, note);
  }
}
