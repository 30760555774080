<form [formGroup]="formGroup">
  <div class="row">
    <div class="col">
      <label
        class="vertical-label display-block w-100"
        [ngClass]="{
          asterisk_input: formGroup.get('activity').hasError('required')
        }"
      >
        Is {{ learnerFirstName }} working towards a
        <a
          (click)="onOpenHelp($event, helpSections.IepPlaafp, iepPlaafpHelp.Diploma)"
          class="text-underline text-lower"
          tabindex="0"
          role="link"
          attr.aria-label="{{ iepPlaafpHelp.Diploma }}"
          >{{ iepPlaafpHelp.Diploma }}</a
        >
        or
        <a
          (click)="onOpenHelp($event, helpSections.IepPlaafp, iepPlaafpHelp.Certificate)"
          class="text-underline text-lower"
          tabindex="0"
          role="link"
          attr.aria-label="{{ iepPlaafpHelp.Certificate }}"
          >{{ iepPlaafpHelp.Certificate }}</a
        >?
      </label>
      <fieldset>
        <app-radio-group
          [options]="activities"
          formControlName="activity"
          [hideAsterisk]="true"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
        ></app-radio-group>
      </fieldset>
    </div>
  </div>
  <div class="row" *ngIf="formGroup.get('activity').value === 'Diploma'">
    <div class="col-6">
      <app-number-field
        label="Total number of credit district requires for graduation."
        formControlName="totalCreditsNeeded"
        [max]="900"
        [readOnly]="readonly"
        [showReadOnlyControl]="false"
        id="totalCreditsNeeded"
      ></app-number-field>
    </div>
    <div class="col-6">
      <app-number-field
        label="Credits earned to date towards graduation:"
        formControlName="creditsEarned"
        [max]="900"
        [readOnly]="readonly"
        [showReadOnlyControl]="false"
        id="creditsEarned"
      ></app-number-field>
      <mat-error style="color: black" *ngIf="creditsEarnedValidation"
        >Credits earned is higher than credits needed. Check if credits earned is correct</mat-error
      >
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-textarea
        label="Comments"
        [isBankableField]="true"
        [bankableFieldId]="bankableFieldIds.secondaryTransition.pseActivities.comments"
        formControlName="comments"
        [maxLength]="10000"
        [readOnly]="readonly"
        [showReadOnlyControl]="false"
      ></app-textarea>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-textarea
        [label]="changesAdditionsLabel"
        [isBankableField]="true"
        [bankableFieldId]="bankableFieldIds.secondaryTransition.pseActivities.changesAdditions"
        formControlName="changesAdditions"
        [maxLength]="10000"
        [readOnly]="readonly"
        [showReadOnlyControl]="false"
      ></app-textarea>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-date-picker
        [label]="graduationDateLabel"
        controlName="targetedDateForGraduation"
        [readOnly]="readonly"
        [showReadOnlyControl]="false"
      ></app-date-picker>
    </div>
  </div>
  <section class="mt-2 mb-2">
    <app-course-activities
      [learnerId]="learnerId"
      [learnerFirstName]="learnerFirstName"
      (coursesModified)="coursesModified()"
      [courseListReviewed]="this.formGroup.get('courseListReviewed').value"
      [readonly]="readonly"
    ></app-course-activities>
  </section>
  <div class="row" *ngIf="!isFirstSecondaryTransition && !readonly">
    <div class="col">
      <mat-button-toggle
        (change)="toggleReview()"
        [checked]="formGroup.get('courseListReviewed').value"
        [disabled]="formGroup.get('coursesModified').value"
      >
        Reviewed, no changes
      </mat-button-toggle>
    </div>
  </div>
</form>
