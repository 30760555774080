<mat-card class="py-2">
  <div class="row align-items-center">
    <div class="col">
      <mat-form-field>
        <mat-label>Select a Team</mat-label>
        <mat-select (selectionChange)="onTeamSelectionChange($event.value)" [disabled]="teams?.length === 0" [value]="selectedTeam">
          <mat-option *ngFor="let team of teams" [value]="team" role="combobox">
            {{ team.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-card>
<div class="action__row">
  <button
    mat-raised-button
    aria-label="Delete Team"
    color="warn"
    type="button"
    (click)="onDeleteTeam()"
    [disabled]="!selectedTeam"
    tabindex="0"
  >
    Delete Team
  </button>
  <button
    mat-raised-button
    aria-label="Rename Team"
    color="accent"
    type="button"
    (click)="onRenameTeam(selectedTeam)"
    [disabled]="!selectedTeam"
    tabindex="0"
  >
    Rename Team
  </button>
  <button
    mat-raised-button
    aria-label="Add Team Member"
    color="primary"
    type="button"
    (click)="openTeamMemberSearchDialog()"
    [disabled]="!selectedTeam"
    tabindex="0"
  >
    Add Team Member
  </button>
  <button mat-raised-button aria-label="Create Team" color="primary" type="button" (click)="openCreateTeamDialog()" tabindex="0">
    Create Team
  </button>
</div>

<mat-card class="card-spacing-top" *ngIf="dataSource.data.length > 0">
  <div class="row">
    <div class="col">
      <div class="table-overflow">
        <table mat-table [dataSource]="dataSource">
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>Remove</th>
            <td mat-cell *matCellDef="let row">
              <button aria-label="Delete Icon" mat-icon-button (click)="onDeleteTeamUser(row, $event)" tabindex="0">
                <mat-icon>close</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let teamUser">{{ teamUser.fullName }}</td>
          </ng-container>

          <!-- Job Title Column -->
          <ng-container matColumnDef="jobTitle">
            <th mat-header-cell *matHeaderCellDef>Job Title</th>
            <td mat-cell *matCellDef="let teamUser">{{ teamUser.jobTitle }}</td>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let teamUser">{{ teamUser.email }}</td>
          </ng-container>

          <!-- Phone Column -->
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>Phone</th>
            <td mat-cell *matCellDef="let teamUser">
              {{ teamUser.phone | phone }}
            </td>
          </ng-container>

          <!-- Area Education Agency Column -->
          <ng-container matColumnDef="aea">
            <th mat-header-cell *matHeaderCellDef>AEA</th>
            <td mat-cell *matCellDef="let teamUser">
              {{ teamUser.aea }}
            </td>
          </ng-container>

          <!-- District Column -->
          <ng-container matColumnDef="district">
            <th mat-header-cell *matHeaderCellDef>District</th>
            <td mat-cell *matCellDef="let teamUser">{{ teamUser.district }}</td>
          </ng-container>

          <!-- Primary Office Column -->
          <ng-container matColumnDef="buildings">
            <th mat-header-cell *matHeaderCellDef>Primary Office</th>
            <!-- TODO This needs to be updated when we get building information -->
            <td mat-cell *matCellDef="let teamUser">
              {{ teamUser.buildings[0]?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="noResults">
            <td mat-footer-cell *matFooterCellDef colspan="7">
              <i>No data yet...</i>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            [hidden]="dataSource?.data?.length > 0"
            mat-footer-row
            *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
          ></tr>
        </table>
      </div>
    </div>
  </div>
</mat-card>
