import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { PseOutcomeService } from 'src/app/shared/services/pse-outcomes/pse-outcome.service';
import { OutcomeArea, PseOutcome } from '../models/pse-outcome';
import { PseSummary } from '../models/pse-summary';
import { PseSummaryService } from '../services/pse-summary.service';
import { DeactivationService } from '../../../../shared/services/deactivation.service';

const livingOutcomesValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  return control && control.value.length === 0 ? { required: true } : null;
};

const learningOutcomesValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  return control && control.value.length === 0 ? { required: true } : null;
};

const workingOutcomesValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  return control && control.value.length === 0 ? { required: true } : null;
};
@Component({
  selector: 'app-pse-summary',
  templateUrl: './pse-summary.component.html',
  styleUrls: ['./pse-summary.component.scss'],
})
export class PseSummaryComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() iepId: string;
  @Input() plaafpId: string;
  @Input() secondaryTransitionId: string;
  @Input() hasAmendment: boolean;
  @Input() amendmentId: string;
  @Input() amendmentIsFinalized: boolean;
  @Input() amendmentDate: Date;

  formGroup = this.fb.group({
    id: '',
    livingSummaryAssessment: ['', Validators.required],
    livingExpectations: ['', Validators.required],
    livingOutcomes: [[], livingOutcomesValidator],
    livingOtherDescription: ['', Validators.required],
    learningSummaryAssessment: ['', Validators.required],
    learningExpectations: ['', Validators.required],
    learningOutcomes: [[], learningOutcomesValidator],
    learningOtherDescription: ['', Validators.required],
    workingSummaryAssessment: ['', Validators.required],
    workingExpectations: ['', Validators.required],
    workingOutcomes: [[], workingOutcomesValidator],
    workingOtherDescription: ['', Validators.required],
  });

  allOutcomes: PseOutcome[] = [];
  livingOutcomes: KeyValuePair[] = [];
  learningOutcomes: KeyValuePair[] = [];
  workingOutcomes: KeyValuePair[] = [];

  pseSummary: PseSummary;

  save = new Observable<boolean>((observer) => {
    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };

    if (!this.formGroup.dirty || this.isSaving) {
      done();
      return;
    }

    this.isSaving = true;
    this.pseSummaryService
      .update(this.iepId, this.plaafpId, this.secondaryTransitionId, this.formGroup.value, this.amendmentId)
      .subscribe(() => done());
  });

  constructor(
    private fb: FormBuilder,
    private pseSummaryService: PseSummaryService,
    private pseOutcomeService: PseOutcomeService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.load();
  }

  ngOnChanges() {
    if (this.readonly) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
      this.setOutcomes(this.allOutcomes);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getOutcomeLabels(outcomes: any) {
    if (!outcomes) {
      return;
    }
    const labels: Array<PseOutcome> = [];
    outcomes.forEach((outcome) => {
      labels.push(this.allOutcomes.find((x) => x.id === outcome));
    });
    return labels.length > 0
      ? labels
          .map((x) => x.label)
          .sort((a, b) => (a < b ? -1 : 1))
          .join(', ')
      : null;
  }

  private load() {
    forkJoin([
      this.pseSummaryService.get(this.iepId, this.plaafpId, this.secondaryTransitionId, this.amendmentId),
      this.pseOutcomeService.get(),
    ]).subscribe(([pseSummary, pseOutcomes]) => {
      this.pseSummary = pseSummary;
      this.formGroup.patchValue(pseSummary);
      this.allOutcomes = pseOutcomes;
      this.setOutcomes(pseOutcomes);
      this.saveSubscription = this.formGroup.valueChanges;
      this.startAutosaving();
    });
  }

  hasOtherSelected(outcomes: string[]) {
    let found = false;
    outcomes.forEach((outcome) => {
      const item = this.allOutcomes.find((x) => x.id === outcome && x.isOther);
      if (item) {
        found = true;
        return;
      }
    });
    return found;
  }

  private setOutcomes(pseOutcomes: Array<PseOutcome>) {
    this.livingOutcomes = pseOutcomes.filter((x) => x.area === OutcomeArea.Living).map((x) => new KeyValuePair(x.id, x.label));
    this.learningOutcomes = pseOutcomes.filter((x) => x.area === OutcomeArea.Learning).map((x) => new KeyValuePair(x.id, x.label));
    this.workingOutcomes = pseOutcomes.filter((x) => x.area === OutcomeArea.Working).map((x) => new KeyValuePair(x.id, x.label));
  }
}
