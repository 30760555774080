import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { CaseSummary, IntakeType } from 'src/app/shared/models/case';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { ProgressMonitorLookup } from '../models/progress-monitor-lookup';
import { ProgressMonitorLookupService } from '../services/progress-monitor-lookup.service';

@Component({
  selector: 'app-enter-learner-progress',
  templateUrl: './enter-learner-progress.component.html',
  styleUrls: ['./enter-learner-progress.component.scss'],
})
export class EnterLearnerProgressComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  shortDateFormat = shortDateFormat;
  caseSummary: CaseSummary;
  intakeType: IntakeType;
  pageTitle: string;
  displayedColumns = ['actions', 'date', 'value', 'notes'];
  dataSource = new MatTableDataSource<any>();

  formGroupPartC = new FormGroup({
    date: new FormControl(null, Validators.required),
    planWorking: new FormControl(null, Validators.required),
    outcomes: new FormControl(null, Validators.required),
  });

  formGroupPartB = new FormGroup({
    goalArea: new FormControl(null),
    search: new FormControl(null),
  });

  formGroupDataGraph = new FormGroup({
    valuesToShow: new FormControl(null, Validators.required),
  });

  chart = {
    data: [],
    labels: [''],
    colors: [
      {
        backgroundColor: ['rgba(38, 63, 140, 0.2)', 'rgba(245, 214, 142, 1)', 'rgba(41, 99, 28, 0.3)'],
      },
    ],
    legend: false,
  };

  constructor(
    private route: ActivatedRoute,
    private caseService: CaseService,
    private authService: AuthService,
    private progressMonitorLookupService: ProgressMonitorLookupService
  ) {}

  ngOnInit(): void {
    const lookups = (this.route.snapshot.data.data as ProgressMonitorLookup[]) || [];
    if (lookups.length > 0) {
      this.progressMonitorLookupService.setupLookups(lookups);
    }

    this.subscriptions.add(
      this.caseService.getCaseSummary(this.route.snapshot.paramMap.get('caseId')).subscribe((res: CaseSummary) => {
        this.caseSummary = res;
        this.intakeType = res.intakeType;
        this.pageTitle = 'Enter Progress - ' + res.learner.fullName;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  canCreateEditProgressMonitoring() {
    return this.authService.isAllowedByCaseId(this.caseSummary?.id, undefined, AppPermissions.CreateEditProgressMonitoring);
  }
}
