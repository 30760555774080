import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ResetPasswordViewModel } from '../../auth-models';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthService } from '../../auth.service';
import { ActivatedRoute, Router } from '@angular/router';

const passwordsMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password')?.value;
  const confirmedPassword = control.get('confirmedPassword')?.value;

  return password !== confirmedPassword ? { passwordMismatch: true } : null;
};

const passwordsFormatValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password')?.value;
  const confirmedPassword = control.get('confirmedPassword')?.value;
  const pwdReq = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d#?!@$%^&*()-]).{8,32}$/;

  if (!pwdReq.test(password) || !pwdReq.test(confirmedPassword)) return { passwordFormat: true };
  return null;
};

@Component({
  selector: 'app-ivrs-reset-password',
  templateUrl: './ivrs-reset-password.component.html',
  styleUrls: ['./ivrs-reset-password.component.scss'],
})
export class IvrsResetPasswordComponent implements OnInit {
  isBusy = false;
  private token: string;
  private userId: string;
  serverErrors: string[];

  formGroup: FormGroup;

  constructor(
    private notificationService: NotificationService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
    this.userId = this.route.snapshot.paramMap.get('userId');

    this.formGroup = new FormGroup(
      {
        password: new FormControl('', { validators: [Validators.required] }),
        confirmedPassword: new FormControl('', { validators: [Validators.required] }),
      },
      {
        validators: [passwordsMatchValidator, passwordsFormatValidator],
      }
    );

    if (!this.token || !this.userId) {
      this.routeToLoginPage();
    }
  }

  onSubmit() {
    if (this.formGroup.invalid) return;

    const model = this.formGroup.value as ResetPasswordViewModel;
    model.token = this.token;
    model.id = this.userId;

    this.isBusy = true;
    this.serverErrors = [];
    this.authService.ivrsResetPassword(model).subscribe(
      (response) => {
        this.isBusy = false;
        if (response.succeeded) {
          this.notificationService.success('Password has been reset successfully.');
          this.routeToLoginPage();
        } else {
          this.serverErrors = response.errors.map((x) => x.description);
        }
      },
      (error) => {
        this.notificationService.error('Something went wrong! Please try again later.');
        this.isBusy = false;
      }
    );
  }

  private routeToLoginPage() {
    this.router.navigateByUrl('/auth/ivrs/login');
  }
}
