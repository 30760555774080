import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, forkJoin } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EvaluationDetail, EvaluationNote, InterviewTypes } from 'src/app/evaluation/models/evaluation';
import { EvaluationDetailInterviewQuestion } from 'src/app/evaluation/models/evaluation-detail-interview-question';
import { EvaluationRoutine } from 'src/app/evaluation/models/evaluation-routine';
import { EvaluationDetailService } from 'src/app/evaluation/services/evaluation-detail.service';
import { EvaluationRoutineService } from 'src/app/evaluation/services/evaluation-routine.service';
import { EvaluationService } from 'src/app/evaluation/services/evaluation.service';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { EcoAreaService } from 'src/app/shared/services/eco-areas/eco-area.service';
import { FormService } from 'src/app/shared/services/form.service';
import { BaseComponent } from '../../../../../shared/components/base-component/base-component';
import { DeactivationService } from '../../../../../shared/services/deactivation.service';
import { MemoryStorageService } from '../../../../../shared/services/memory-storage/memory-storage.service';
import { EvaluationNoteComponent } from '../evaluation-note/evaluation-note.component';
import { EvaluationFormService } from '../services/evaluation-form.service';
import { RoutineAddModalComponent } from './modals/routine-add-modal.component';

const toolValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  const interviewType = control.parent?.get('interviewType');
  if (!interviewType) {
    return null;
  }
  if (interviewType && interviewType.value === 'Family' && control.value === null) {
    return { required: true };
  }
  return null;
};

const routineValidator: ValidatorFn = (controls: FormArray): ValidationErrors | null => {
  const interviewType = controls.parent?.get('interviewType');
  if (interviewType && interviewType.value === 'Family') {
    controls.controls.forEach((control) => {
      const satisfaction = control.get('satisfaction');
      if (satisfaction && !satisfaction.value) {
        satisfaction.setErrors({ required: true });
      }
    });
  }

  if (interviewType && interviewType.value !== 'Family') {
    controls.controls.forEach((control) => {
      const satisfaction = control.get('satisfaction');
      if (satisfaction) {
        satisfaction.setErrors(null);
      }
    });
  }
  return null;
};

@Component({
  selector: 'app-evaluation-interview',
  templateUrl: './evaluation-interview.component.html',
  styleUrls: ['./evaluation-interview.component.scss'],
})
export class EvaluationInterviewComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() caseId: string;
  @Input() currentEvaluationDetail: EvaluationDetail;
  @Input() currentEvaluationNote: EvaluationNote;
  @Input() isEligible: boolean;
  @Input() learnerFirstName: string;
  @Input() isEditing = false;
  @Input() routineEditingId: string;
  @Output() interviewChange = new EventEmitter();
  @Output() interviewValid = new EventEmitter<boolean>();
  @ViewChildren(EvaluationNoteComponent) evaluationNoteComponents;
  @ViewChild('isEligibleScript') isEligibleScript: TemplateRef<any>;
  @ViewChild('isNotEligibleScript') isNotEligibleScript: TemplateRef<any>;
  @ViewChild('routinesScript') routinesScript: TemplateRef<any>;
  @ViewChildren('interviewQuestion')
  interviewQuestionDivs: QueryList<ElementRef>;
  private formSubscription: Subscription;
  private noteSubscription: Subscription;
  private routineSubscription: Subscription;
  interviewTypes = InterviewTypes;
  intervieweeOptions: KeyValuePair[];
  interviewQuestions: EvaluationDetailInterviewQuestion[];
  mainInterviewQuestions: EvaluationDetailInterviewQuestion[];
  additionalInterviewQuestions: EvaluationDetailInterviewQuestion[];
  routineInterviewQuestions: EvaluationDetailInterviewQuestion[];
  currentNoteEdit: EvaluationNote;
  toolOptions: KeyValuePair[] = [
    new KeyValuePair('facs2', 'Family Assessment Conversation Starters Revised (FACS-2)'),
    new KeyValuePair('other', 'Other'),
  ];
  subscription = new Subscription();

  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  ecoAreas: KeyValuePair[];
  evaluationDomains: KeyValuePair[];
  tagEcoAreaToggled: boolean;
  tagDomainsToggled: boolean;

  formGroup: FormGroup = this.fb.group(
    {
      interviewType: ['', Validators.required],
      interviewee: [''],
      toolUsed: ['', { validators: toolValidator, updateOn: 'change' }],
      toolOtherDescription: [''],
      childFamilyStrengths: [''],
      familyResources: [''],
      childFamilyConcerns: [''],
      familyPriorities: [''],
      otherInformation: [''],
      toolAdditionalResults: [''],
      typicalDay: [''],
      routines: this.fb.array([], {
        validators: routineValidator,
        updateOn: 'change',
      }),
    },
    { updateOn: 'blur' }
  );

  get routineFormArray() {
    return (this.formGroup.get('routines') as FormArray).controls as FormGroup[];
  }

  get interviewTypeControl() {
    return this.formGroup.get('interviewType') as FormControl;
  }

  get mainQuestionsHaveNotes() {
    if (this.interviewTypeControl.value !== 'Family') return true;
    const noteQuestionIds = this.currentEvaluationDetail?.notes?.map((x) => x.evaluationDetailInterviewQuestionId);
    const mainQuestionIds = this.mainInterviewQuestions?.map((x) => x.id);
    return mainQuestionIds?.every((x) => noteQuestionIds?.some((y) => y === x));
  }

  get routineQuestionsHaveNotes() {
    if (this.interviewTypeControl.value !== 'Routines') return true;
    const noteQuestionIds = this.currentEvaluationDetail?.notes?.map((x) => x.evaluationDetailInterviewQuestionId);
    const routineQuestionIds = this.routineInterviewQuestions?.map((x) => x.id);
    return routineQuestionIds?.every((x) => noteQuestionIds?.some((y) => y === x));
  }

  constructor(
    private fb: FormBuilder,
    public evaluationFormService: EvaluationFormService,
    private evaluationRoutineService: EvaluationRoutineService,
    private dialog: MatDialog,
    private evaluationDetailService: EvaluationDetailService,
    private changeDetectorRef: ChangeDetectorRef,
    private ecoAreaService: EcoAreaService,
    private evaluationService: EvaluationService,
    private memoryStorage: MemoryStorageService,
    private formService: FormService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
    this.formSubscription = evaluationFormService.formSubmitted$.subscribe(() => {
      this.formGroup.markAllAsTouched();
    });

    this.noteSubscription = evaluationFormService.noteDelete$.subscribe((note) => {
      const index = this.currentEvaluationDetail.notes.findIndex((x) => x.id === note.id);
      this.currentEvaluationDetail.notes.splice(index, 1);
    });

    this.routineSubscription = evaluationFormService.routineAdded$.subscribe(() => {
      this.getRoutines(this.formGroup.get('routines') as FormArray);
    });
  }

  ngOnInit(): void {
    this.fetchData();

    this.mainInterviewQuestions = this.memoryStorage.getKey('mainInterviewQuestions', true);
    if (!this.mainInterviewQuestions) {
      this.evaluationDetailService.getInterviewQuestions().subscribe((res) => {
        this.interviewQuestions = res;
        this.mainInterviewQuestions = res.filter((x) => !x.isAdditionalQuestion && !x.isRoutineQuestion).sort((a, b) => a.order - b.order);
        this.additionalInterviewQuestions = res.filter((x) => x.isAdditionalQuestion);
        this.routineInterviewQuestions = res.filter((x) => x.isRoutineQuestion);
        this.memoryStorage.setKey('mainInterviewQuestions', this.mainInterviewQuestions);
        this.memoryStorage.setKey('routineInterviewQuestions', this.routineInterviewQuestions);
      });
    }

    this.subscription.add(
      this.formService.showAllErrors$.subscribe(() => {
        this.formGroup.markAllAsTouched();
        this.isEditing = true;
      })
    );

    this.evaluationDetailService.getInterviewQuestions().subscribe((res) => {
      this.mainInterviewQuestions = res.filter((x) => !x.isAdditionalQuestion && !x.isRoutineQuestion).sort((a, b) => a.order - b.order);
      this.additionalInterviewQuestions = res.filter((x) => x.isAdditionalQuestion);
      this.routineInterviewQuestions = res.filter((x) => x.isRoutineQuestion);
    });
    this.setupFormSubscriptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.formGroup.patchValue(this.currentEvaluationDetail, { emitEvent: false });
  }

  ngOnDestroy() {
    this.formGroup.reset();
    this.formSubscription.unsubscribe();
    this.noteSubscription.unsubscribe();
    this.subscription.unsubscribe();
  }

  private fetchData() {
    forkJoin([this.ecoAreaService.get(), this.evaluationService.getDomainAreas(this.currentEvaluationDetail.evaluationId)]).subscribe(
      ([ecoAreas, evaluationDomains]) => {
        this.ecoAreas = ecoAreas.map((x) => new KeyValuePair(x.id, x.label));
        this.evaluationDomains = evaluationDomains.map((x) => new KeyValuePair(x.id, x.label));
      }
    );
  }

  viewResourcesPrioritiesConcernsScript(event: MouseEvent) {
    event.stopPropagation();
    if (this.isEligible) {
      this.dialog.open(this.isEligibleScript, { width: '1024px' });
    } else {
      this.dialog.open(this.isNotEligibleScript, { width: '1024px' });
    }
  }

  viewRoutinesScript(event: MouseEvent) {
    event.stopPropagation();
    this.dialog.open(this.routinesScript, { width: '1024px' });
  }

  private getRoutines(formArray: FormArray, setupFormSubscriptions?: boolean) {
    this.evaluationRoutineService.get(this.currentEvaluationDetail.evaluationId).subscribe((routines) => {
      formArray.clear();
      routines
        .sort((a, b) => {
          const aLabel = a.label === 'Other' ? a.otherLabel : a.label;
          const bLabel = b.label === 'Other' ? b.otherLabel : b.label;
          return aLabel.localeCompare(bLabel);
        })
        .forEach((routine) => this.addRoutine(routine));

      // If this is a new EvalDetail or new Routines have been added
      // since this EvalDetail was last worked on we need to manually
      // add the EvalRoutine records into it now so they can save.
      // (eg we pass these arrays down to the routine tags component...
      // so they have to exist in the EvalDetail record so they get
      // properly updated)
      const allEvalRoutines = this.formGroup.get('routines').value;
      const evalDetailCurrentRoutines = this.currentEvaluationDetail.routines || [];
      allEvalRoutines.forEach((evalRoutine) => {
        if (!evalDetailCurrentRoutines.find((r) => r.evaluationRoutineId === evalRoutine.evaluationRoutineId)) {
          this.currentEvaluationDetail.routines.push(evalRoutine);
        }
      });

      if (this.currentEvaluationDetail.routines.length > 0) {
        this.patchFormArrayValues();
      }
      if (setupFormSubscriptions) {
        this.setupFormSubscriptions();
      }
    });
  }

  onAddRoutine() {
    const dialogRef = this.dialog.open(RoutineAddModalComponent, {
      width: '768px',
    });

    dialogRef.afterClosed().subscribe((updatedRoutines) => {
      if (updatedRoutines) {
        this.evaluationFormService.addRoutine();
      }
    });
  }

  setupFormSubscriptions() {
    this.formGroup.controls.toolUsed.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (value === 'other') {
        this.formGroup.controls.toolOtherDescription.markAsUntouched();
        this.formGroup.controls.toolOtherDescription.setValidators([Validators.required]);
      } else {
        this.formGroup.controls.toolOtherDescription.setValidators([]);
        this.formGroup.controls.toolOtherDescription.setValue(null);
      }
    });

    this.formGroup.controls.interviewType.valueChanges.subscribe((value) => {
      // TODO: Find out what to do with notes when the interview type changes from General to another type
      this.changeDetectorRef.detectChanges();
      if (value === 'General') {
        this.formGroup.get('toolUsed').setValue(null);
        this.formGroup.get('toolOtherDescription').setValue(null);
        // (this.formGroup.get('routines') as FormArray).clear();
      }
      if (value === 'Routines') {
        this.getRoutines(this.formGroup.get('routines') as FormArray);
      }
    });
    this.formGroup.patchValue(this.currentEvaluationDetail);

    this.formGroup.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((value) => {
      this.interviewValid.emit(this.formGroup.valid);
      this.interviewChange.emit(value);
    });

    this.interviewValid.emit(this.formGroup.valid);
  }

  addRoutine(routine: EvaluationRoutine) {
    const formGroup = this.createRoutineFormGroup();
    formGroup.patchValue({
      evaluationRoutineId: routine.id,
      label: routine.label,
      otherLabel: routine.otherLabel,
      nickName: routine.nickName,
      taggedForServicesC: routine.taggedForServicesC,
      taggedForPwn: routine.taggedForPwn,
      taggedForOutcomes: routine.taggedForOutcomes,
    });
    (this.formGroup.get('routines') as FormArray).push(formGroup);
  }

  getCurrentQuestionNote(question: EvaluationDetailInterviewQuestion) {
    if (this.currentEvaluationNote?.evaluationDetailInterviewQuestionId === question.id) {
      return this.currentEvaluationNote;
    }
  }

  onEditQuestionNote(note) {
    this.currentNoteEdit = note;
  }

  patchFormArrayValues() {
    const formArray = this.formGroup.get('routines') as FormArray;
    formArray.controls.forEach((control) => {
      const controlId = control.get('evaluationRoutineId').value;
      const routine = this.currentEvaluationDetail.routines.find((x) => x.evaluationRoutineId === controlId);
      if (routine) {
        control.patchValue(routine);
        if (routine.ecoAreas.length > 0) {
          this.onTagEcoAreaToggle();
        }
        if (routine.domainAreas.length > 0) {
          this.onTagEvaluationDomainToggle();
        }
      }
    });
  }

  private createRoutineFormGroup() {
    const isFamilyInterview = this.formGroup.get('interviewType').value === 'Family';
    return this.fb.group(
      {
        id: '',
        evaluationRoutineId: '',
        label: '',
        otherLabel: '',
        nickName: '',
        satisfaction: ['', isFamilyInterview ? Validators.required : null],
        workOnTogether: [null, { updateOn: 'change' }],
        workingWell: '',
        routineDifficulty: '',
        routineParticipation: '',
        routineLooksLike: '',
        partWorkingWell: '',
        wouldRoutineImproveHelp: '',
        ecoAreas: [[], { updateOn: 'change' }],
        domainAreas: [[], { updateOn: 'change' }],
        taggedForPwn: false,
        taggedForServicesC: false,
        taggedForOutcomes: false,
      },
      { updateOn: 'blur' }
    );
  }

  onTagEcoAreaToggle() {
    this.tagEcoAreaToggled = !this.tagEcoAreaToggled;
  }

  onTagEvaluationDomainToggle() {
    this.tagDomainsToggled = !this.tagDomainsToggled;
  }

  tagsChanged() {
    this.formGroup.markAsDirty();
    this.interviewChange.emit(this.formGroup.value);
  }
}
