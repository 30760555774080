import { PwnLinkTo } from './prior-written-notice';

export interface EarlyAccessPriorWrittenNotice {
  id: string;

  postReferralScreening?: boolean;
  evaluationAndOrAssessment?: boolean;
  eligibility?: boolean;
  services?: boolean;
  exitingEarlyAccess?: boolean;

  descriptionOfAction: string;
  reasonForAction: string;
  noticeProvidedOn: Date;
  implementedOn: Date;
  actionsImplemented: string;
  print: boolean;
  eDelivery: boolean;
  isSubmitted: boolean;
  submittedOn?: Date;
  pwnType: string;
  caseId: string;
  completedOn: Date;
  linkToId: string;
  linkTo: PwnLinkTo;
  pwnOutputDocumentId?: string;
}

export enum PriorWrittenNoticeType {
  Ineligible = 'Ineligible',
  FamilyWithdrew = 'FamilyWithdrew',
  DisabilitySuspectIneligible = 'DisabilitySuspectIneligible',
}
