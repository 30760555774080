import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sentence-starters-modal',
  templateUrl: './sentence-starters-modal.component.html',
  styleUrls: ['./sentence-starters-modal.component.scss'],
})
export class SentenceStartersModalComponent {
  response: any = null;

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onClose() {
    this.dialogRef.close();
  }
}
