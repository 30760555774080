<button
  mat-flat-button
  aria-label="Amend"
  color="primary"
  *ngIf="hasOpenAmendment"
  class="mr-2"
  (click)="$event.stopPropagation(); onAmend()"
  tabindex="0"
>
  Amend
</button>

<form [formGroup]="formGroup">
  <app-help-directional-text>
    <p>
      Based on the goals, services, supports, activities, and accommodations identified by the team, please indicate whether
      {{ learner.firstName }} requires
      <a
        (click)="onOpenHelp($event, helpSection.IepService, iepServiceHelp.Esy)"
        class="text-underline"
        tabindex="0"
        role="link"
        attr.aria-label="{{ iepServiceHelp.Esy }}"
        >{{ iepServiceHelp.Esy }}</a
      >
      services:
    </p>
  </app-help-directional-text>
  <div class="row">
    <div class="col-md-12 col-xl-6">
      <ng-container *ngIf="!isAmendment || (hasAmendment && amendingESY)">
        <app-autocomplete
          *ngIf="determinationOptions"
          label="Select Determination"
          formControlName="extendedSchoolYear"
          [options]="determinationOptions"
        ></app-autocomplete>
        <span *ngIf="hasAmendment">
          <app-compare-amendment-output
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="extendedSchoolYearModel?.priorVersionId"
            [isActive]="extendedSchoolYearModel?.isActive"
            [changedVersionText]="getExtendedSchoolYearLabel(formGroup.get('extendedSchoolYear').value)"
            [currentVersionText]="extendedSchoolYearModel?.priorVersion?.extendedSchoolYearLabel"
            [currentPrefixText]="extendedSchoolYearModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </span>
      </ng-container>
      <ng-container *ngIf="isAmendment && (!hasAmendment || !amendingESY)">
        <p class="text-semibold">Determination</p>
        {{ extendedSchoolYearModel?.extendedSchoolYearLabel }}
      </ng-container>
    </div>

    <div class="col-md-12 col-xl-6">
      <ng-container *ngIf="showDate && (!isAmendment || (hasAmendment && amendingESY))">
        <app-date-picker
          label="{{ planDevelopedByWording ? 'Plan developed by' : 'Date' }}"
          controlName="extendedSchoolYearDate"
          [min]="minDate"
          [max]="iepEndDate"
        ></app-date-picker>
        <span *ngIf="hasAmendment">
          <app-compare-amendment-output
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="extendedSchoolYearModel?.priorVersionId"
            [isActive]="extendedSchoolYearModel?.isActive"
            [changedVersionText]="formGroup.get('extendedSchoolYearDate').value | dateFormat"
            [currentVersionText]="extendedSchoolYearModel?.priorVersion?.extendedSchoolYearDate | dateFormat"
            [currentPrefixText]="extendedSchoolYearModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </span>
      </ng-container>
      <ng-container *ngIf="showDate && isAmendment && (!hasAmendment || !amendingESY)">
        <p class="text-semibold">Extended School Year Date</p>
        {{ extendedSchoolYearModel?.extendedSchoolYearDate | dateFormat }}
      </ng-container>
    </div>
  </div>

  <div class="action__row action__row--between mt-0">
    <div>
      <button mat-raised-button aria-label="View Summary" color="accent" (click)="openSummary()" *ngIf="!isDetailsView" tabindex="0">
        View Summary
      </button>

      <a
        mat-raised-button
        [routerLink]="['/', 'cases', caseId, 'iep', iepId, 'esy', 'overview']"
        class="ml-2"
        color="primary"
        *ngIf="showESY"
        tabindex="0"
        role="link"
      >
        ESY Plan
      </a>
    </div>
  </div>
</form>
