<!-- Material table data needs to be w/o div for proper alignment. -->

<div class="my-1" *ngIf="!tableAlignment; else tableAlignment">
  <ng-container *ngTemplateOutlet="compareAmendmentOutput"></ng-container>
</div>

<ng-template #tableAlignment>
  <ng-container *ngTemplateOutlet="compareAmendmentOutput"></ng-container>
</ng-template>

<ng-template #compareAmendmentOutput>
  <ng-container *ngIf="amendmentId && !amendmentFinalized">
    <div class="text-green" *ngIf="isNew">
      <span class="changed-prefix">New: </span>{{ tableData ? (changedVersionText | truncate : 150) : changedVersionText }}
    </div>
    <ng-container *ngIf="!isNew">
      <div
        [ngClass]="{
          'ended-item': hasAmendmentEndValue
        }"
      >
        <span *ngIf="currentPrefixText">{{ currentPrefixText }}</span>
        <span *ngIf="!isActive">
          {{ tableData ? (currentVersionText | truncate : 150) : currentVersionText }}
        </span>
        <span *ngIf="isActive">
          {{ tableData ? (changedVersionText | truncate : 150) : changedVersionText }}
        </span>
      </div>
      <div *ngIf="currentVersionTextIsDifferent && !isActive" class="text-green">
        <span class="changed-prefix" *ngIf="changedPrefixText">
          {{ changedPrefixText }}
        </span>
        {{ tableData ? (changedVersionText | truncate : 150) : changedVersionText }}
      </div>
    </ng-container>
  </ng-container>
  <div
    *ngIf="amendmentFinalized"
    [ngClass]="{
      'ended-item': hasAmendmentEndValue
    }"
  >
    {{ tableData ? (changedVersionText | truncate : 150) : changedVersionText }}
    <ng-container *ngIf="currentVersionTextIsDifferent && amendmentId">
      <div
        [ngClass]="{
          'ended-item': hasAmendmentEndValue,
          'modified-item': !hasAmendmentEndValue
        }"
      >
        <div>
          <ng-container *ngIf="!isIfsp">Amended</ng-container>
          <ng-container *ngIf="isIfsp">Modified</ng-container> on
          {{ amendmentDate | dateFormat }}
        </div>
        <div>
          Previous:
          {{ tableData ? (currentVersionText | truncate : 150 | notapplicable) : (currentVersionText | notapplicable) }}
        </div>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="!amendmentId && !amendmentFinalized">
    <div
      [ngClass]="{
        'ended-item': hasAmendmentEndValue
      }"
    >
      {{ tableData ? (changedVersionText | truncate : 150 | notapplicable) : (changedVersionText | notapplicable) }}
    </div>
  </ng-container>
</ng-template>
