import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FileDocument } from 'src/app/shared/models/file-document';

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.scss'],
})
export class ViewDocumentComponent implements OnInit {
  fileDocuments: FileDocument[] = [];
  dataSource = new MatTableDataSource<FileDocument>();
  displayedColumns = ['title', 'fileName'];
  baseUrl: string;

  constructor(public dialogRef: MatDialogRef<ViewDocumentComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dataSource.data = data.documents;
    this.baseUrl = data.baseUrl;
  }

  ngOnInit(): void {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
