import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  transform(value: Array<string>, separator = ', ', lastSeparator = ' and '): string {
    const result = value.join(separator);
    if (!lastSeparator) {
      return result;
    }

    const lastIndex = result.lastIndexOf(separator);
    return lastIndex === -1 ? result : `${result.substring(0, lastIndex)}${lastSeparator}${result.substring(lastIndex + separator.length)}`;
  }
}
