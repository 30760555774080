<form [formGroup]="formGroup">
  <div>
    <div class="row">
      <div class="col-md-12 col-lg-2 col-xl-1">
        <h4 class="text-primary mt-2 mr-2 mb-0">Living</h4>
      </div>
      <div class="col-md-12 col-lg-10 col-xl-11">
        <app-textarea
          label="Summary of assessments related to Living"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.secondaryTransition.pseSummary.livingSummaryAssessment"
          formControlName="livingSummaryAssessment"
          [maxLength]="10000"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
        ></app-textarea>
        <app-compare-amendment-output
          *ngIf="!readonly && amendmentId"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="amendmentDate"
          [priorVersionId]="pseSummary?.priorVersionId"
          [changedVersionText]="formGroup.get('livingSummaryAssessment').value"
          [currentVersionText]="pseSummary?.priorVersion?.livingSummaryAssessment"
          [currentPrefixText]="formGroup.get('livingSummaryAssessment').value ? 'Current: ' : 'Proposed: '"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
        <app-textarea
          label="PSE for Living"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.secondaryTransition.pseSummary.livingExpectations"
          formControlName="livingExpectations"
          [maxLength]="10000"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
        ></app-textarea>
        <app-compare-amendment-output
          *ngIf="!readonly && amendmentId"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="amendmentDate"
          [priorVersionId]="pseSummary?.priorVersionId"
          [changedVersionText]="formGroup.get('livingExpectations').value"
          [currentVersionText]="pseSummary?.priorVersion?.livingExpectations"
          [currentPrefixText]="formGroup.get('livingExpectations').value ? 'Current: ' : 'Proposed: '"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
        <app-autocomplete-chips
          [options]="livingOutcomes"
          formControlName="livingOutcomes"
          label="PSE Category"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
        ></app-autocomplete-chips>
        <app-compare-amendment-output
          *ngIf="!readonly && amendmentId"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="amendmentDate"
          [priorVersionId]="pseSummary?.priorVersionId"
          [changedVersionText]="getOutcomeLabels(formGroup.get('livingOutcomes').value)"
          [currentVersionText]="getOutcomeLabels(pseSummary?.priorVersion?.livingOutcomes)"
          [currentPrefixText]="formGroup.get('livingOutcomes').value ? 'Current: ' : 'Proposed: '"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>
    </div>
    <div class="row" *ngIf="hasOtherSelected(formGroup.get('livingOutcomes').value)">
      <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
        <app-textarea
          label="Other Description"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.secondaryTransition.pseSummary.livingOtherDescription"
          formControlName="livingOtherDescription"
          [maxLength]="10000"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
        ></app-textarea>
        <app-compare-amendment-output
          *ngIf="!readonly && amendmentId"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="amendmentDate"
          [priorVersionId]="pseSummary?.priorVersionId"
          [changedVersionText]="formGroup.get('livingOtherDescription').value"
          [currentVersionText]="pseSummary?.priorVersion?.livingOtherDescription"
          [currentPrefixText]="formGroup.get('livingOtherDescription').value ? 'Current: ' : 'Proposed: '"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>
    </div>
  </div>

  <hr class="input__divider my-2" />

  <div>
    <div class="row">
      <div class="col-md-12 col-lg-2 col-xl-1">
        <h4 class="text-primary mt-2 mr-2 mb-0">Learning</h4>
      </div>
      <div class="col-md-12 col-lg-10 col-xl-11">
        <app-textarea
          label="Summary of assessments related to Learning"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.secondaryTransition.pseSummary.learningSummaryAssessment"
          formControlName="learningSummaryAssessment"
          [maxLength]="10000"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
        ></app-textarea>
        <app-compare-amendment-output
          *ngIf="!readonly && amendmentId"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="amendmentDate"
          [priorVersionId]="pseSummary?.priorVersionId"
          [changedVersionText]="formGroup.get('learningSummaryAssessment').value"
          [currentVersionText]="pseSummary?.priorVersion?.learningSummaryAssessment"
          [currentPrefixText]="formGroup.get('learningSummaryAssessment').value ? 'Current: ' : 'Proposed: '"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
        <app-textarea
          label="PSE for Learning"
          [isBankableField]="true"
          formControlName="learningExpectations"
          [bankableFieldId]="bankableFieldIds.secondaryTransition.pseSummary.learningExpectations"
          [maxLength]="10000"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
        ></app-textarea>
        <app-compare-amendment-output
          *ngIf="!readonly && amendmentId"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="amendmentDate"
          [priorVersionId]="pseSummary?.priorVersionId"
          [changedVersionText]="formGroup.get('learningExpectations').value"
          [currentVersionText]="pseSummary?.priorVersion?.learningExpectations"
          [currentPrefixText]="formGroup.get('learningExpectations').value ? 'Current: ' : 'Proposed: '"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
        <app-autocomplete-chips
          [options]="learningOutcomes"
          formControlName="learningOutcomes"
          label="PSE Category"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
        ></app-autocomplete-chips>
        <app-compare-amendment-output
          *ngIf="!readonly && amendmentId"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="amendmentDate"
          [priorVersionId]="pseSummary?.priorVersionId"
          [changedVersionText]="getOutcomeLabels(formGroup.get('learningOutcomes').value)"
          [currentVersionText]="getOutcomeLabels(pseSummary?.priorVersion?.learningOutcomes)"
          [currentPrefixText]="formGroup.get('learningOutcomes').value ? 'Current: ' : 'Proposed: '"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>
    </div>
    <div class="row" *ngIf="hasOtherSelected(formGroup.get('learningOutcomes').value)">
      <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
        <app-textarea
          label="Other Description"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.secondaryTransition.pseSummary.learningOtherDescription"
          formControlName="learningOtherDescription"
          [maxLength]="10000"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
        ></app-textarea>
        <app-compare-amendment-output
          *ngIf="!readonly && amendmentId"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="amendmentDate"
          [priorVersionId]="pseSummary?.priorVersionId"
          [changedVersionText]="formGroup.get('learningOtherDescription').value"
          [currentVersionText]="pseSummary?.priorVersion?.learningOtherDescription"
          [currentPrefixText]="formGroup.get('learningOtherDescription').value ? 'Current: ' : 'Proposed: '"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>
    </div>
  </div>

  <hr class="input__divider my-2" />

  <div>
    <div class="row">
      <div class="col-md-12 col-lg-2 col-xl-1">
        <h4 class="text-primary mt-2 mr-2 mb-0">Working</h4>
      </div>
      <div class="col-md-12 col-lg-10 col-xl-11">
        <app-textarea
          label="Summary of assessments related to Working"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.secondaryTransition.pseSummary.workingSummaryAssessment"
          formControlName="workingSummaryAssessment"
          [maxLength]="10000"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
        ></app-textarea>
        <app-compare-amendment-output
          *ngIf="!readonly && amendmentId"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="amendmentDate"
          [priorVersionId]="pseSummary?.priorVersionId"
          [changedVersionText]="formGroup.get('workingSummaryAssessment').value"
          [currentVersionText]="pseSummary?.priorVersion?.workingSummaryAssessment"
          [currentPrefixText]="formGroup.get('workingSummaryAssessment').value ? 'Current: ' : 'Proposed: '"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
        <app-textarea
          label="PSE for Working"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.secondaryTransition.pseSummary.workingExpectations"
          formControlName="workingExpectations"
          [maxLength]="10000"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
        ></app-textarea>
        <app-compare-amendment-output
          *ngIf="!readonly && amendmentId"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="amendmentDate"
          [priorVersionId]="pseSummary?.priorVersionId"
          [changedVersionText]="formGroup.get('workingExpectations').value"
          [currentVersionText]="pseSummary?.priorVersion?.workingExpectations"
          [currentPrefixText]="formGroup.get('workingExpectations').value ? 'Current: ' : 'Proposed: '"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
        <app-autocomplete-chips
          [options]="workingOutcomes"
          formControlName="workingOutcomes"
          label="PSE Category"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
        ></app-autocomplete-chips>
        <app-compare-amendment-output
          *ngIf="!readonly && amendmentId"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="amendmentDate"
          [priorVersionId]="pseSummary?.priorVersionId"
          [changedVersionText]="getOutcomeLabels(formGroup.get('workingOutcomes').value)"
          [currentVersionText]="getOutcomeLabels(pseSummary?.priorVersion?.workingOutcomes)"
          [currentPrefixText]="formGroup.get('workingOutcomes').value ? 'Current: ' : 'Proposed: '"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>
    </div>
    <div class="row" *ngIf="hasOtherSelected(formGroup.get('workingOutcomes').value)">
      <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
        <app-textarea
          label="Other Description"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.secondaryTransition.pseSummary.workingOtherDescription"
          formControlName="workingOtherDescription"
          [maxLength]="10000"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
        ></app-textarea>
        <app-compare-amendment-output
          *ngIf="!readonly && amendmentId"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="amendmentDate"
          [priorVersionId]="pseSummary?.priorVersionId"
          [changedVersionText]="formGroup.get('workingOtherDescription').value"
          [currentVersionText]="pseSummary?.priorVersion?.workingOtherDescription"
          [currentPrefixText]="formGroup.get('workingOtherDescription').value ? 'Current: ' : 'Proposed: '"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>
    </div>
  </div>
</form>
