<h3 class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>View More</h3>

<div class="mat-dialog-content py-2">
  <mat-card *ngIf="service">
    <ul class="list">
      <li class="list__item" *ngIf="service.includesTrialPlacement">
        <h3 class="text-primary mt-0 mb-2">Included in Trial Placement</h3>
      </li>
      <li class="list__item">
        <h3 class="text-primary mt-0 mb-2">Service</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="service.amendmentEndDate"
          [priorVersionId]="service.priorVersionId"
          [isActive]="service.isActive"
          [changedVersionText]="service?.serviceType?.label"
          [currentVersionText]="service?.priorVersion ? service?.priorVersion?.serviceType?.label : service?.serviceType?.label"
          [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item" *ngIf="showServiceType">
        <h3 class="text-primary mt-0 mb-2">Service Type</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="service.amendmentEndDate"
          [priorVersionId]="service.priorVersionId"
          [isActive]="service.isActive"
          [changedVersionText]="getServiceType(service.serviceType)"
          [currentVersionText]="
            service?.priorVersion ? getServiceType(service?.priorVersion?.serviceType) : getServiceType(service.serviceType)
          "
          [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item">
        <h3 class="text-primary mt-0 mb-2">Description</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="service.amendmentEndDate"
          [priorVersionId]="service.priorVersionId"
          [isActive]="service.isActive"
          [changedVersionText]="service.description"
          [currentVersionText]="service?.priorVersion ? service?.priorVersion?.description : service.description"
          [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item">
        <h3 class="text-primary mt-0 mb-2">Service Codes</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="service.amendmentEndDate"
          [priorVersionId]="service.priorVersionId"
          [isActive]="service.isActive"
          [changedVersionText]="getServiceCodes(service.serviceCodes)"
          [currentVersionText]="
            service?.priorVersion ? getServiceCodes(service?.priorVersion?.serviceCodes) : getServiceCodes(service.serviceCodes)
          "
          [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item">
        <h3 class="text-primary mt-0 mb-2">Other Information</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="service.amendmentEndDate"
          [priorVersionId]="service.priorVersionId"
          [isActive]="service.isActive"
          [changedVersionText]="getOtherInformationText(service)"
          [currentVersionText]="service?.priorVersion ? getOtherInformationText(service?.priorVersion) : getOtherInformationText(service)"
          [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item">
        <h3 class="text-primary mt-0 mb-2">Frequency</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="service.amendmentEndDate"
          [priorVersionId]="service.priorVersionId"
          [isActive]="service.isActive"
          [changedVersionText]="getFrequencyText(service)"
          [currentVersionText]="service?.priorVersion ? getFrequencyText(service?.priorVersion) : getFrequencyText(service)"
          [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item">
        <h3 class="text-primary mt-0 mb-2">Provider(s)</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="service.amendmentEndDate"
          [priorVersionId]="service.priorVersionId"
          [isActive]="service.isActive"
          [changedVersionText]="getProviderString(service.providers, service.otherProviderName)"
          [currentVersionText]="
            service?.priorVersion
              ? getProviderString(service?.priorVersion?.providers, service?.priorVersion?.otherProviderName)
              : getProviderString(service.providers, service.otherProviderName)
          "
          [changedPrefixText]="service?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item" *ngIf="canServiceMethod(service)">
        <h3 class="text-primary mt-0 mb-2">Service Method</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="service.amendmentEndDate"
          [priorVersionId]="service.priorVersionId"
          [isActive]="service.isActive"
          [changedVersionText]="getServiceMethod(service)"
          [currentVersionText]="service.priorVersion ? getServiceMethod(service.priorVersion) : getServiceMethod(service)"
          [changedPrefixText]="service.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
    </ul>
  </mat-card>
</div>

<div class="mat-dialog-actions justify-content-end">
  <button mat-raised-button aria-label="Delete Icon" (click)="onClose()" cdkFocusInitial tabindex="0">Close</button>
</div>
