import { Pipe, PipeTransform } from '@angular/core';
import { Gender } from '../models/learner';

@Pipe({
  name: 'firstNamePossessive',
})
export class FirstNamePossessivePipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return '';
    }
    const lastLetter = value.slice(-1);
    return lastLetter === 's' ? `${value}'` : `${value}'s`;
  }
}

@Pipe({
  name: 'possessivePronoun',
})
export class PossessivePronounPipe implements PipeTransform {
  transform(value: Gender) {
    if (!value) {
      return 'their';
    }
    return value === Gender.Male ? 'his' : 'her';
  }
}
