<ng-container [formGroup]="formGroup">
  <div class="container--not-centered container--form">
    <div class="row">
      <div class="col-lg">
        <mat-tab-group class="tab-group--above-card" #matTabGroup (selectedTabChange)="selectedTabChange($event)">
          <mat-tab label="Provider(s) Information">
            <app-provider-info-form
              *ngIf="healthInfo && healthInfo.providerInformation"
              [formGroup]="providerInformation"
              [providerInfo]="healthInfo.providerInformation"
              [isQuestionnaire]="isQuestionnaire"
              [questionnaire]="questionnaire"
              [intake]="intake"
              [locked]="locked"
              [documentationInfo]="documentationInfo"
              [importQuestionnaireSelected]="importQuestionnaireSelected"
              (upload)="onUpload($event)"
              (documentUploaded)="refreshIntakeAfterDocumentUpload()"
              (deleteDocument)="onDeleteDocument($event)"
            ></app-provider-info-form>
          </mat-tab>
          <mat-tab label="Birth / Medical History">
            <app-medical-history-info
              *ngIf="healthInfo && healthInfo.medicalHistoryInformation"
              [formGroup]="medicalHistoryInformation"
              [medicalHistoryInfo]="healthInfo.medicalHistoryInformation"
              [intake]="intake"
              [locked]="locked"
              [questionnaire]="questionnaire"
              [isQuestionnaire]="isQuestionnaire"
              [documentationInfo]="documentationInfo"
              [importQuestionnaireSelected]="importQuestionnaireSelected"
              (upload)="onUpload($event)"
              (deleteDocument)="onDeleteDocument($event)"
              (medicalConditionUpdated)="medicalConditionUpdatedEmit($event)"
              (pauseAutosave)="pauseAutosaveEmit($event)"
            ></app-medical-history-info>
          </mat-tab>
          <mat-tab label="Nutrition and Growth">
            <app-nutrition-growth-info
              *ngIf="healthInfo && healthInfo.nutritionGrowthInformation"
              [formGroup]="nutritionGrowthInformation"
              [documentationInfo]="documentationInfo"
              [nutritionGrowthInfo]="healthInfo.nutritionGrowthInformation"
              [intake]="intake"
              [locked]="locked"
              [importQuestionnaireSelected]="importQuestionnaireSelected"
              [questionnaire]="questionnaire"
              [isQuestionnaire]="isQuestionnaire"
              (upload)="onUpload($event)"
              (deleteDocument)="onDeleteDocument($event)"
            ></app-nutrition-growth-info>
          </mat-tab>
          <mat-tab label="Dental / Vision / Hearing">
            <app-dental-vision-hearing-info
              *ngIf="healthInfo && healthInfo.dentalVisionHearingInformation"
              [formGroup]="dentalVisionHearingInformation"
              [dentalVisionHearingInfo]="healthInfo.dentalVisionHearingInformation"
              [intake]="intake"
              [locked]="locked"
              [questionnaire]="questionnaire"
              [isQuestionnaire]="isQuestionnaire"
              [documentationInfo]="documentationInfo"
              [importQuestionnaireSelected]="importQuestionnaireSelected"
              (deleteDocument)="onDeleteDocument($event)"
              (upload)="onUpload($event)"
            ></app-dental-vision-hearing-info>
          </mat-tab>
          <mat-tab label="Health Concerns / Overall Current Health" *ngIf="!isQuestionnaire">
            <app-additional-concerns-info
              *ngIf="healthInfo && healthInfo.additionalConcernsInformation"
              [additionalConcernsInfo]="healthInfo?.additionalConcernsInformation"
              [locked]="locked"
              [formGroup]="additionalConcernsInformation"
            ></app-additional-concerns-info>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</ng-container>
