<mat-card class="mt-2 card--shadow">
  <div class="display-flex align-items-center justify-content-between flex-wrap">
    <div class="display-flex align-items-center justify-content-between flex-wrap">
      <h3 class="mr-3 mb-0">
        {{ isFba ? 'Select method(s) to begin documenting data collection' : 'Select method(s) to begin entering Evaluation Details' }}:
      </h3>

      <mat-button-toggle-group [multiple]="true" aria-labelledby="Select an option">
        <mat-button-toggle
          [checked]="isReview"
          (change)="reviewToggled.emit(true)"
          [disabled]="reviewDisabled"
          (click)="toggleMethod(reviewDisabled)"
          aria-label="Review Button Toggle"
          >Review</mat-button-toggle
        >
        <mat-button-toggle
          [checked]="isInterview"
          (change)="interviewToggled.emit(true)"
          [disabled]="interviewDisabled"
          (click)="toggleMethod(interviewDisabled)"
          attr.aria-label="Interview {{ isFba ? ' and Rating Scale' : '' }} Button Toggle"
          >Interview {{ isFba ? ' and Rating Scale' : '' }}</mat-button-toggle
        >
        <mat-button-toggle
          [checked]="isObservation"
          (change)="observationToggled.emit(true)"
          [disabled]="observationDisabled"
          (click)="toggleMethod(observationDisabled)"
          aria-label="Observation Button Toggle"
          >Observation</mat-button-toggle
        >
        <mat-button-toggle
          [checked]="isTest"
          (change)="testToggled.emit(true)"
          [disabled]="testDisabled"
          (click)="toggleMethod(testDisabled)"
          aria-label="Test Button Toggle"
          >Test</mat-button-toggle
        >
      </mat-button-toggle-group>
    </div>
    <div *ngIf="isReview || isInterview || isObservation || isTest" class="btn--reset">
      <button mat-raised-button aria-label="Reset/Delete Method Button" color="primary" (click)="resetDeleteMethod.emit()" tabindex="0">
        Reset/Delete Method
      </button>
    </div>
  </div>
</mat-card>
