import { EvaluationDomain } from 'src/app/shared/models/evaluation-domain';
import { FileDocument } from 'src/app/shared/models/file-document';
import { UnknownYesNo } from 'src/app/shared/models/yes-no';
import { TaggedItem } from 'src/app/tags/tagged-item';
import { IntakeType } from '../../shared/models/case';
import { Learner } from '../../shared/models/learner';
import { ChildInformation } from '../shared/models/child-information';
import { FamilyInformation } from '../shared/models/family-information';
import { HealthInformation } from '../shared/models/health-information';
import { InsuranceInformation } from '../shared/models/insurance-information';

export interface Intake {
  id?: string;
  intakeType: IntakeType;
  intakeModified: boolean;
  childInfo?: ChildInformation;
  familyInfo?: FamilyInformation;
  insuranceInfo?: InsuranceInformation;
  healthInfo?: HealthInformation;
  nextSteps?: NextStepsInformation;
  documentationInfo?: DocumentationInformation;
  serviceCoordinatorId?: string;
  activeEvaluationId: string;
  learner?: Learner;
  lockedOn?: Date;
  createdOn?: Date;
  createdById?: string;
  updatedOn?: Date;
  caseId?: string;
  referral?: {
    id: string;
    submittedOn: Date;
  };
}

export interface NextStepsInformation {
  preReferralScreeningToolUsed?: UnknownYesNo;
  preReferralScreeningTools: ScreeningTool[];
  preReferralScreeningToolOther: string;

  postReferralScreeningTools: ScreeningTool[];
  postReferralScreeningToolOther: string;
  postReferralScreeningCompleted: Date;
  postReferralScreeningConductedBy: string;
  postReferralScreeningResults: ScreeningResult;
  postReferralScreeningConcerns: Array<EvaluationDomain>;
  postReferralScreeningRecommendationFollowUp: string;
  postReferralScreeningSummary: string;
  eligibilityDecision?: boolean;
  eligibilitySubmittedOnIntake?: boolean;
  eligibilitySubmittedOnEvaluation?: boolean;
  taggedItem?: TaggedItem;
}

export interface ScreeningTool {
  id: string;
  name: string;
}

export interface DocumentationInformation {
  intakeDocuments: IntakeDocuments[];
}

export interface IntakeDocuments {
  document: FileDocument;
  consent?: boolean;
  section: string;
  isPHCP?: boolean;
}

export enum DeterminedBy {
  Parent = 'Parent',
  Observer = 'Observer',
}

export enum Title {
  Dr = 'Dr',
  MD = 'MD',
  DO = 'DO',
  PA = 'PA',
  ARNP = 'ARNP',
}

export enum ProviderQuickActions {
  DownloadConsent = 'Download Consent to Release and Exchange Information',
  UploadConsent = 'Upload Consent to Release and Exchange Information',
  DownloadIcd = 'Download ICD 10 Physician Letter',
  IndicateRecordsReceived = 'Indicate Records Received',
}

export enum ScreeningResult {
  NoConcerns = 'No Concerns',
  MayHaveConcerns = 'May Have Concerns',
}

export enum DocumentationType {
  MedicalRecords = 'Medical Records',
  ScreeningResults = 'Screening Results',
}
