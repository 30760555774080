<div class="display-flex align-items-center justify-content-end mb-2">
  <div *ngIf="notFinalized" [formGroup]="formGroup" class="flex-1 mb-0">
    <app-checkbox-single
      class="display-inline-block"
      formControlName="includesTrialPlacement"
      aria-labelledby="This IEP will include a trial placement"
      label="This IEP will include a trial placement"
    >
    </app-checkbox-single>
    <ng-container *ngIf="formGroup.get('includesTrialPlacement').value === true">
      <app-date-picker
        class="ml-3 display-inline-block"
        controlName="trialPlacementDate"
        label="Trial Placement End Date"
        [min]="tenDaysPrior"
      ></app-date-picker>
      <fieldset>
        <app-radio-group
          class="ml-3 display-inline-block"
          [options]="trialPlacementOptions"
          [inline]="true"
          formControlName="trialPlacementTarget"
          label="Trial placement for:"
          conditionallyRequired="includesTrialPlacement"
        ></app-radio-group>
      </fieldset>
    </ng-container>
    <ng-container *ngIf="iep.delayed">
      <app-autocomplete
        class="ml-5 display-inline-block"
        label="Reason for delay"
        formControlName="transitionDelayReasonId"
        [options]="delayReasonOptions"
      >
      </app-autocomplete>
    </ng-container>

    <ng-container *ngIf="iep.showLateEvaluationRationale">
      <app-textarea
        formControlName="lateEvaluationRationale"
        label="Provide reason the evaluation meeting was held late."
        [maxLength]="stringSizes.extraLarge"
        [rows]="4"
      ></app-textarea>
    </ng-container>
  </div>
  <button
    mat-raised-button
    attr.aria-label="{{ isAccordionsExpanded ? 'Collapse All' : 'Expand All' }}"
    color="accent"
    (click)="toggleAccordion()"
    class="mr-2"
    tabindex="0"
  >
    {{ isAccordionsExpanded ? 'Collapse All' : 'Expand All' }}
    <mat-icon class="ml-2" attr.aria-label="{{ isAccordionsExpanded ? 'expand less' : 'expand more' }}">
      {{ isAccordionsExpanded ? 'expand_less' : 'expand_more' }}
    </mat-icon>
  </button>
  <button
    mat-raised-button
    aria-label="Open Scheduler"
    color="primary"
    *ngIf="authService.isAllowed(permissions.ScheduleMeeting)"
    (click)="scheduleMeeting()"
    tabindex="0"
  >
    Open Scheduler
  </button>
  <button mat-flat-button aria-label="Team Member Questionnaire" color="accent" class="ml-2" (click)="onSendSurvey()" tabindex="0">
    Team Member Questionnaire
  </button>
</div>

<mat-accordion *ngIf="caseSummary" displayMode="flat" class="accordion" multi>
  <mat-accordion class="accordion" displayMode="flat" multi>
    <mat-expansion-panel #proceduralSafeguardPanel *ngIf="notFinalized" class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="my-0 text-normal" *ngIf="mostRecentProceduralSafeguard">
            The <i>Procedural Safeguards Manual for Parents</i> was last reviewed and provided to the parent(s) on
            {{ mostRecentProceduralSafeguard.providedOn | dateFormat }}. The parent(s)
            {{ mostRecentProceduralSafeguard.acceptedPrintedCopy ? 'accepted' : 'declined' }}
            the printed copy.
          </h3>

          <h3 class="my-0 text-normal" *ngIf="!mostRecentProceduralSafeguard">
            The parent has not yet been provided a printed copy of the Procedural Safeguards Manual for Parents
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-procedural-safeguards-list
        (mostRecentChanged)="onMostRecentProceduralSafeguardChanged($event)"
        [caseId]="caseId"
        [learnerId]="caseSummary.learnerId"
        [showProvidedOn]="false"
        [showTable]="false"
      ></app-procedural-safeguards-list>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion displayMode="flat" class="accordion accordion--short" multi>
    <!-- For future release -->
    <!-- <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex align-items-baseline">
          <span>IEP Alignment</span>

          <span class="ml-2 text-danger text-sm">
            2 areas of need not aligned / 3 goals, 2 services and 1
            accommodation not aligned
          </span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-iep-alignment></app-iep-alignment>
  </mat-expansion-panel> -->

    <mat-expansion-panel *ngIf="iepMeetings?.length > 0" class="mat-elevation-z0">
      <mat-expansion-panel-header
        ><mat-panel-title
          ><h3 class="my-0">{{ iepMeetings?.length === 1 ? 'Meeting' : 'Meetings' }}</h3></mat-panel-title
        >
      </mat-expansion-panel-header>
      <app-iep-meeting [iepMeetings]="iepMeetings" [caseSummary]="caseSummary" (refreshMeetings)="getIepMeetings()"></app-iep-meeting>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="iepSurveysSent > 0 && iep !== undefined && iep.iepType !== iepTypeInitial" class="mat-elevation-z0">
      <mat-expansion-panel-header
        ><mat-panel-title><h3 class="my-0 text-normal">Questionnaires</h3></mat-panel-title>
      </mat-expansion-panel-header>
      <app-iep-survey-results [iepId]="iepId" [iepSurveysSent]="iepSurveysSent"></app-iep-survey-results>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="notFinalized" class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="display-flex justify-content-between align-items-center w-100">
            <h3 class="my-0 text-normal">Incomplete Data Report</h3>

            <div class="display-flex align-items-center">
              <span *ngIf="incompleteItems?.length > 1" class="ml-2 text-danger text-sm position-fix-notice">
                {{ incompleteItems?.length }} Notices
              </span>

              <span *ngIf="incompleteItems?.length === 1" class="ml-2 text-danger text-sm position-fix-notice"> 1 Notice </span>
              <button
                mat-icon-button
                aria-label="Refresh Icon"
                color="primary"
                (click)="refreshIncompleteItems($event)"
                class="ml-2"
                matTooltip="Refresh"
                tabindex="0"
              >
                <mat-icon aria-label="Refresh Icon"> refresh </mat-icon>
              </button>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-iep-completion
        [incompleteItems]="incompleteItems"
        [iepId]="iepId"
        [caseSummary]="caseSummary"
        [allowIvrs]="allowIvrs"
        [iepEndDate]="iep?.endDate"
        (refreshData)="refreshData()"
        (gotoConsent)="scrollAndOpenConsent()"
        (gotoProceduralSafeguards)="scrollAndOpenProceduralSafeguards()"
      ></app-iep-completion>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="notFinalized" class="mat-elevation-z0 hide-print" #consent>
      <mat-expansion-panel-header>
        <mat-panel-title><h3 class="my-0 text-normal">Consent</h3></mat-panel-title>
      </mat-expansion-panel-header>
      <app-iep-consents
        *ngIf="caseSummary"
        [caseSummary]="caseSummary"
        [iepId]="iepId"
        [psgProvided]="!!mostRecentProceduralSafeguard"
        [rollCallDate]="firstIepMeetingRollCallDate"
      ></app-iep-consents>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title> <h3 class="my-0 text-normal">IEP / Reports</h3> </mat-panel-title>
      </mat-expansion-panel-header>
      <app-help-directional-text>
        <p class="mb-2">The IEP is built as PLAAFP, Goals, and Services+ details are added by the IEP team.</p>
      </app-help-directional-text>
      <app-iep-reports
        *ngIf="incompleteItems && iep"
        [pwnId]="caseSummary?.pwnId"
        [canFinalize]="incompleteItems.length === 0 && formGroup.valid"
        [finalized]="!!iep.activatedOn"
        [learnerId]="caseSummary.learnerId"
        [showLockWarning]="caseSummary?.learner?.isMigrated && iep.isFirstIep"
      ></app-iep-reports>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="page-bottom-leeway"></div>
</mat-accordion>
