import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, length: number): string {
    const biggestWord = 50;
    const ellipses = '...';

    if (!value) {
      return value;
    }
    if (value.length <= length) {
      return value;
    }

    let truncatedText = value.slice(0, length + biggestWord);

    while (truncatedText.length > length - ellipses.length) {
      const lastSpace = truncatedText.lastIndexOf(' ');

      if (lastSpace === -1) {
        break;
      }

      truncatedText = truncatedText.slice(0, lastSpace).replace(/[!,.?;:]$/, '');
    }

    return truncatedText + ellipses;
  }
}
