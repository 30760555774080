import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { DataHistoryService } from '../../../../services/data-history.service';

@Component({
  selector: 'app-data-history-part-b-secondary-transition',
  templateUrl: './data-history-part-b-secondary-transition.component.html',
  styleUrls: ['./data-history-part-b-secondary-transition.component.scss'],
})
export class DataHistoryPartBSecondaryTransitionComponent implements OnInit {
  assignmentDisplayedColumns = ['date', 'nameOfAssessment', 'postSecondaryAreas', 'summaryOfAssessmentResults'];
  courseDisplayedColumns = ['courseActivity', 'projectedDateYearOfSchool', 'livingLearningWorking', 'completedDiscontinued'];
  assignmentDataSource = new MatTableDataSource([]);
  courseDataSource = new MatTableDataSource([]);
  shortDateFormat = shortDateFormat;
  @Input() learnerId: string;
  @Input() caseId: string;
  constructor(private dataHistoryService: DataHistoryService) {}

  ngOnInit(): void {
    this.dataHistoryService.getSecondaryTransition(this.caseId, this.learnerId).subscribe((res) => {
      this.assignmentDataSource = new MatTableDataSource(res.transitionAssessments || []);
      this.courseDataSource = new MatTableDataSource(res.coursesOfStudy || []);
    });
  }
}
