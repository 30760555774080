import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { Aea } from 'src/app/shared/models/aea';
import { Building } from 'src/app/shared/models/building';
import { CaseSummary, CaseUserRole, CaseUserSummary, IntakeType } from 'src/app/shared/models/case';
import { District } from 'src/app/shared/models/district';
import { ServiceCoordinator } from 'src/app/shared/models/service-coordinator';
import { User } from 'src/app/shared/models/user';
import { AeaService } from 'src/app/shared/services/aea/aea.service';
import { BuildingService } from 'src/app/shared/services/building/building.service';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { DistrictService } from 'src/app/shared/services/district/district.service';
import { LoggerService } from 'src/app/shared/services/logger/logger.service';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-share-case',
  templateUrl: './share-case.component.html',
  styleUrls: ['./share-case.component.scss'],
})
export class ShareCaseComponent implements OnInit {
  caseId: string;
  currentCase: CaseSummary;
  aeas: Array<Aea>;
  aeaUsers: Array<User>;
  districts: Array<District>;
  buildings: Array<Building>;
  chscUser: User;
  dmpsUser: User;
  serviceCoordinators: Array<ServiceCoordinator>;
  selectedAeaUsers: Array<User> = [];
  selectedScUsers: Array<ServiceCoordinator> = [];
  showSharedUsers: boolean;
  sharedUsersNames: string[];
  activeCall = false;

  get intakeType() {
    return IntakeType;
  }

  get dmpsChscShareHidden() {
    return this.authService.isDmpsUser || this.authService.isChscUser;
  }

  get dmpsChscShareDisabled() {
    return this.selectedAeaUsers?.length > 0 || this.selectedScUsers?.length > 0;
  }

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private caseService: CaseService,
    private aeaService: AeaService,
    private userService: UserService,
    private loggerService: LoggerService,
    private authService: AuthService,
    private districtService: DistrictService,
    private buildingService: BuildingService
  ) {
    this.caseId = data;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.caseService.getCaseSummary(this.caseId).subscribe((caseFile) => {
      this.currentCase = caseFile;

      forkJoin([
        this.currentCase.intakeType === IntakeType.PartB ? this.aeaService.getPublicAeas() : this.aeaService.getAllAeas(),
        this.districtService.getDistricts(),
        this.userService.getDefaultDmpsCaseUser().pipe(
          catchError(() => {
            return of(undefined);
          })
        ),
        this.userService.getDefaultChscCaseUser().pipe(
          catchError(() => {
            return of(undefined);
          })
        ),
        this.buildingService.getAllBuildings(),
      ]).subscribe(([aeas, districts, dmpsUser, chscUser, buildings]) => {
        this.districts = districts;
        this.aeas = aeas;
        this.dmpsUser = dmpsUser;
        this.chscUser = chscUser;
        this.buildings = buildings;
      });
    });
  }

  onAeaSelectionChanged(selectedUsers: Array<User>) {
    this.selectedAeaUsers = selectedUsers;
  }

  onScSelectionChanged(selectedUsers: Array<ServiceCoordinator>) {
    this.selectedScUsers = selectedUsers;
  }

  onSearch(formGroup: FormGroup) {
    const control = formGroup.get('filterBy');
    if (control) {
      control.setValue('');
    }
    this.userService.searchUsers(formGroup.value).subscribe(
      (res) =>
        (this.aeaUsers = res.filter(
          (x) =>
            !this.currentCase.caseUsers
              .filter((x) => x.isOwner)
              .map((x) => x.id)
              .includes(x.id)
        ))
    );
  }

  onShare() {
    this.activeCall = true;
    const selectedUsers = this.selectedAeaUsers.map((user) => ({
      userId: user.id,
      role: CaseUserRole.Shared,
    }));

    this.selectedScUsers.forEach((sc) => {
      const selected = {
        userId: sc.id,
        role: CaseUserRole.Shared,
      };
      selectedUsers.push(selected);
    });

    const observables = [];
    selectedUsers.forEach((user) => {
      observables.push(this.caseService.addCaseUser(this.currentCase.id, user));
    });
    this.sharedUsersNames = [...this.selectedAeaUsers.map((u) => u.fullName), ...this.selectedScUsers.map((u) => u.name)];
    forkJoin(observables).subscribe(
      (res) => {
        this.showSharedUsers = true;
        this.activeCall = false;
      },
      (error) => {
        this.loggerService.error(error);
        this.activeCall = false;
      }
    );
  }

  onRemove(user: CaseUserSummary) {
    this.caseService.removeCaseUser(this.currentCase.id, user.id).subscribe((res) => {
      this.currentCase = res;
    });
  }

  onDMPSShare() {
    const user = {
      userId: this.dmpsUser.id,
      role: CaseUserRole.Shared,
    };
    this.caseService.addCaseUser(this.currentCase.id, user).subscribe((res) => this.onClose());
  }

  onClose() {
    this.dialogRef.close();
  }
}
