import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { IepAccommodationDto } from '../models/iep-accommodation';
@Injectable({
  providedIn: 'root',
})
export class IepAccommodationService {
  public accommodationsUpdated = new Subject<void>();
  accommodationsUpdated$ = this.accommodationsUpdated.asObservable();

  public accommodationClose = new Subject<void>();
  accommodationClose$ = this.accommodationClose.asObservable();

  public accommodationAddNew = new Subject<void>();
  accommodationAddNew$ = this.accommodationAddNew.asObservable();

  public setAmendingAccommodation = new Subject<any>();
  setAmendingAccommodation$ = this.setAmendingAccommodation.asObservable();

  constructor(private readonly http: HttpClient, spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/.*\/iepaccommodation.*/));
  }

  private baseUrl(iepId: string, id = '') {
    return `api/${iepId}/iepaccommodation/${id}`;
  }

  getAllAccommodations(iepId: string) {
    return this.http.get<IepAccommodationDto[]>(this.baseUrl(iepId));
  }

  getAllForAmendment(iepId: string, amendmentId = '', isCompleted?: boolean) {
    amendmentId ||= '';
    let params = new HttpParams();

    if (isCompleted) params = params.set('isCompleted', isCompleted);

    return this.http.get<IepAccommodationDto[]>(this.baseUrl(iepId) + `amend/${amendmentId}`, { params });
  }

  getAllAccommodationsForAssessment(iepId: string, amendmentId = '') {
    return this.http.get<IepAccommodationDto[]>(this.baseUrl(iepId) + `assessment/${amendmentId}`);
  }

  getAuditLogs(iepId: string, id: string) {
    return this.http.get<any[]>(this.baseUrl(iepId) + `${id}/getAuditLogs`);
  }

  update(iepId: string, model: IepAccommodationDto) {
    return this.http.put<any>(this.baseUrl(iepId), model).pipe(tap(() => this.accommodationsUpdated.next()));
  }

  updateProviders(iepId: string, id: string, model: any) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/updateProviders', model);
  }

  updateTrialPlacement(iepId: string, id: string, includedInTrialPlacement: boolean) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/update-trial-placement', { includedInTrialPlacement });
  }

  amend(iepId: string, id: string, amendmentId: string) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/amend/' + amendmentId, null).pipe(tap(() => this.accommodationsUpdated.next()));
  }

  endAccommodation(iepId: string, id: string, model: any) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/end', model).pipe(tap(() => this.accommodationsUpdated.next()));
  }

  deleteAccommodation(iepId: string, id: string, isAmendment = false) {
    return this.http.put(this.baseUrl(iepId, id) + '/' + isAmendment, null).pipe(tap(() => this.accommodationsUpdated.next()));
  }
}
