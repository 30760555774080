<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="display-flex align-items-center justify-content-between">
    <span>Help</span>
    <button mat-icon-button (click)="onClose()" tabindex="0">
      <mat-icon class="cursor-pointer">close</mat-icon>
    </button>
  </div>
</h3>

<div mat-dialog-content aria-describedby="Search for Help">
  <div class="help">
    <aside class="help__search" *ngIf="canBrowse" role="complementary">
      <div class="help__search-input">
        <mat-form-field class="w-100">
          <mat-label>Search for Help</mat-label>
          <input
            matInput
            aria-labelledby="Search for Help"
            type="text"
            placeholder="search term.."
            cdkFocusInitial
            [(ngModel)]="searchText"
            (keyup)="filterDictionary()"
          />
          <span matSuffix [hidden]="searchText.length === 0">
            <button aria-label="Delete Icon" mat-icon-button type="button" color="primary" (click)="clearSearch()" tabindex="0">
              <mat-icon>close</mat-icon>
            </button>
          </span>
        </mat-form-field>
      </div>

      <div class="help__search-container">
        <ul class="help__search-list" [ngClass]="{ 'no-result': section.dictionary.length === 0 }" *ngFor="let section of filteredResults">
          <ng-container *ngIf="section.dictionary.length > 0">
            <h4 class="my-0 text-primary">
              {{ section.title }}
            </h4>
            <li class="help__search-item" *ngFor="let term of section.dictionary">
              <p
                class="my-0"
                (click)="onSelectHelp(term)"
                [ngClass]="{
                  'help__search-item--selected': term.label === selectedItem.label
                }"
                tabindex="0"
              >
                {{ term.label }}
              </p>
            </li>
          </ng-container>
        </ul>
      </div>
    </aside>

    <div class="help__result">
      <mat-card *ngIf="help && (selectedItem | json) !== '{}'">
        <h3 class="help__result-title">
          {{ selectedItem.label }}
        </h3>
        <ng-container *ngIf="selectedItem.descriptor">
          <div [innerHTML]="selectedItem.descriptor"></div>
        </ng-container>
        <ng-container *ngIf="selectedItem.iframeSource">
          <div class="loading__container" *ngIf="!iFrameLoaded">
            <div class="loading"></div>
          </div>
          <iframe
            [src]="sanitizeUrl(selectedItem.iframeSource)"
            class="iframe"
            frameborder="0"
            [ngClass]="{ 'display-none': !iFrameLoaded }"
          ></iframe>
        </ng-container>
      </mat-card>

      <mat-card *ngIf="nestedTerm">
        <app-special-factors-expanded-text
          *ngIf="area === helpArea.SpecialFactors"
          [search]="nestedTerm"
        ></app-special-factors-expanded-text>

        <app-fiie-consent-expanded-text *ngIf="area === helpArea.DomainsToBeEvaluated"></app-fiie-consent-expanded-text>
      </mat-card>

      <mat-card *ngIf="(selectedItem | json) === '{}' && !nestedTerm">
        <h3 class="help__result-title">Try searching for something!</h3>
        <p>There's no content here.</p>
      </mat-card>
    </div>
  </div>
</div>

<div mat-dialog-actions class="display-flex justify-content-end align-items-center">
  <div class="display-flex my-2">
    <button mat-button aria-label="Close" class="background-color--muted" (click)="onClose()" tabindex="0">Close</button>
  </div>
</div>
