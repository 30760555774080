<mat-card class="card__together card__together--bottom p-0">
  <mat-accordion
    class="accordion accordion--close accordion--no-border accordion--short accordion--in-card"
    displayMode="flat"
    *ngFor="let ifsp of ifsps"
    multi
  >
    <ng-container>
      <app-eco-output-by-ifsp [ifsp]="ifsp"> </app-eco-output-by-ifsp>
    </ng-container>
  </mat-accordion>
</mat-card>
