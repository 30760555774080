<nav class="stepper__nav mt-5">
  <ul class="stepper__list">
    <a
      [routerLink]="['/', 'cases', caseId, 'iep', iepId, 'profile']"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="Profile"
    >
      <li class="stepper__item">
        <mat-icon aria-labelledby="Overview">dashboard</mat-icon>
        <p>Profile</p>
      </li>
    </a>

    <a
      *ngIf="notFinalized"
      [routerLink]="['/', 'cases', caseId, 'iep', iepId, 'plaafp']"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="PLAAFP"
    >
      <li class="stepper__item">
        <mat-icon aria-labelledby="PLAAFP">location_on</mat-icon>
        <p>PLAAFP</p>
      </li>
    </a>

    <a
      *ngIf="notFinalized"
      [routerLink]="['/', 'cases', caseId, 'iep', iepId, 'goals']"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="Goals"
    >
      <li class="stepper__item">
        <i class="fas fa-bullseye" aria-labelledby="Goals"></i>
        <p>Goals</p>
      </li>
    </a>

    <a
      *ngIf="notFinalized"
      [routerLink]="['/', 'cases', caseId, 'iep', iepId, 'services']"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="Services +"
    >
      <li class="stepper__item">
        <i class="fas fa-chalkboard-teacher" aria-labelledby="Services +"></i>
        <p>Services +</p>
      </li>
    </a>

    <a
      *ngIf="notFinalized"
      [routerLink]="['/', 'cases', caseId, 'iep', iepId, 'additional-info']"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="Additional Information"
    >
      <li class="stepper__item">
        <mat-icon aria-labelledby="Additional Information">comment_bank</mat-icon>
        <p>Additional Information</p>
      </li>
    </a>
  </ul>
</nav>
