<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Change Provider</h3>
<mat-dialog-content class="mat-typography py-2" [formGroup]="formGroup">
  <app-autocomplete
    [options]="providerOptions"
    formControlName="provider"
    label="Provider"
    [hidden]="formGroup.get('otherProvider').value"
  ></app-autocomplete>
  <app-checkbox-single
    class="mt-2 mb-2"
    formControlName="otherProvider"
    aria-labelledby="Provider is outside of ACHIEVE"
    label=" Provider is outside of ACHIEVE"
  >
  </app-checkbox-single>
  <div [hidden]="!isOtherProvider">
    <app-text-field label="Provider Name" formControlName="otherProviderName"></app-text-field>

    <app-text-field label="Provider Agency" formControlName="otherProviderAgency"></app-text-field>

    <app-text-field label="Provider Role" formControlName="otherProviderRole"></app-text-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button aria-label="Cancel Button" mat-dialog-close tabindex="0">Cancel</button>
  <button
    mat-raised-button
    aria-label="Complete"
    color="primary"
    cdkFocusInitial
    [disabled]="formGroup.invalid"
    (click)="onSubmit()"
    tabindex="0"
  >
    Complete
  </button>
</mat-dialog-actions>
