import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ProgressMonitorComment } from 'src/app/monitoring-progress/models/comment';
import { GoalProgressMonitor } from 'src/app/monitoring-progress/models/goal-progress-monitor';
import { ProgressMonitorPhaseLine } from 'src/app/monitoring-progress/models/phase-line';
import { ProgressMonitorItem } from 'src/app/monitoring-progress/models/progress-monitor-item';
import { ProgressMonitorLookupService } from 'src/app/monitoring-progress/services/progress-monitor-lookup.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { conditionalValidator } from 'src/app/shared/validators';

@Component({
  selector: 'app-add-combined-objective-progress',
  templateUrl: './add-combined-objective-progress.component.html',
  styleUrls: ['./add-combined-objective-progress.component.scss'],
})
export class AddCombinedObjectiveProgressComponent implements OnInit {
  @Input() goal: GoalProgressMonitor;
  @Input() minDate: Date;
  @Output() goalSubmitEvent = new EventEmitter();
  @Output() objectiveSubmitEvent = new EventEmitter();
  @Output() phaseLineSubmitEvent = new EventEmitter();
  @Output() commentSubmitEvent = new EventEmitter();
  today = new Date();
  shortDateFormat = shortDateFormat;
  isReady = false;
  displayedColumns = ['rating', 'description'];

  goalFormGroup = this.fb.group({
    id: '',
    date: [null, Validators.required],
    note: [null, Validators.required],
    commentNote: ['', Validators.required],
    commentNoteOther: ['', conditionalValidator(() => this.isOtherCommentSelected, Validators.required)],
    phaseLineNote: ['', Validators.required],
    phaseLineNoteOther: ['', conditionalValidator(() => this.isOtherPhaseLineSelected, Validators.required)],
  });

  objectiveFormGroup = this.fb.group({
    date: [null, Validators.required],
    objectives: this.fb.array([]),
  });

  get objectiveFormGroupFormArray() {
    return this.objectiveFormGroup.get('objectives') as FormArray;
  }

  get isOtherPhaseLineSelected() {
    const phaseNote = this.goalFormGroup.get('phaseLineNote');
    return phaseNote && phaseNote.value && this.progressMonitorLookupService.lookupOptions.find((x) => x.id === phaseNote.value)?.isOther;
  }

  get isOtherCommentSelected() {
    const commentNote = this.goalFormGroup.get('commentNote');
    return (
      commentNote && commentNote.value && this.progressMonitorLookupService.lookupOptions.find((x) => x.id === commentNote.value)?.isOther
    );
  }

  get phaseLineOptions() {
    return this.progressMonitorLookupService.phaseLineOptions;
  }

  get commentOptions() {
    return this.progressMonitorLookupService.commentOptions;
  }

  constructor(private fb: FormBuilder, private progressMonitorLookupService: ProgressMonitorLookupService) {}

  ngOnInit(): void {
    this.watchGoalFormGroup();
    this.setupObjectiveFormGroupFormArray();
    this.isReady = true;
  }

  onSubmit() {
    if (this.goalFormGroup.get('note').value !== null) {
      this.onSubmitDataPoint();
    }
    if (this.goalFormGroup.get('phaseLineNote').value) {
      this.onPhaseLineSubmit();
    }
    if (this.goalFormGroup.get('commentNote').value) {
      this.onCommentSubmit();
    }
    this.goalFormGroup.reset();
  }

  private onSubmitDataPoint() {
    const primaryDataPoint = {
      date: this.goalFormGroup.get('date').value,
      note: this.goalFormGroup.get('note').value,
      iepGoalQuantifiableMeasurementId: this.goal.primaryMeasurement.iepGoalQuantifiableMeasurementId,
    } as ProgressMonitorItem;

    this.goalSubmitEvent.emit({
      primary: primaryDataPoint,
    });
  }

  private onPhaseLineSubmit() {
    const phaseLine = {
      date: this.goalFormGroup.get('date').value,
      progressMonitorLookupId: this.goalFormGroup.get('phaseLineNote').value,
      noteOther: this.goalFormGroup.get('phaseLineNoteOther').value,
      goalId: !this.goal.isObjective ? this.goal.id : null,
      milestoneId: this.goal.isObjective ? this.goal.id : null,
    } as ProgressMonitorPhaseLine;
    phaseLine.label = this.progressMonitorLookupService.lookupOptions.find((x) => x.id === phaseLine.progressMonitorLookupId)?.label;
    this.phaseLineSubmitEvent.emit(phaseLine);
  }

  private onCommentSubmit() {
    const comment = {
      date: this.goalFormGroup.get('date').value,
      progressMonitorLookupId: this.goalFormGroup.get('commentNote').value,
      noteOther: this.goalFormGroup.get('commentNoteOther').value,
      goalId: !this.goal.isObjective ? this.goal.id : null,
      milestoneId: this.goal.isObjective ? this.goal.id : null,
    } as ProgressMonitorComment;
    comment.label = this.progressMonitorLookupService.lookupOptions.find((x) => x.id === comment.progressMonitorLookupId)?.label;
    this.commentSubmitEvent.emit(comment);
  }

  onObjectivesSubmit() {
    const objectives = this.objectiveFormGroup.get('objectives').value;
    const dataPoints = [];
    objectives.forEach((objective) => {
      dataPoints.push({
        date: this.objectiveFormGroup.get('date').value,
        value: objective.value,
        note: objective.note,
        iepGoalQuantifiableMeasurementId: objective.iepGoalQuantifiableMeasurementId,
        objectiveId: objective.id,
      });
    });
    this.objectiveSubmitEvent.emit(dataPoints);
    this.resetObjectiveFormGroup();
  }

  private resetObjectiveFormGroup() {
    this.objectiveFormGroup.get('date').reset();
    this.objectiveFormGroupFormArray.controls.forEach((control) => {
      control.get('value').reset();
      control.get('note').reset();
    });
  }

  private watchGoalFormGroup() {
    this.goalFormGroup.valueChanges.subscribe((formValue) => {
      this.validateWithPrimaryMeasurement(formValue);
    });
  }

  private validateWithPrimaryMeasurement(formValue) {
    if (formValue.note !== null && formValue.note !== '') {
      this.clearValidators(['phaseLineNote', 'commentNote']);
    } else if (formValue.phaseLineNote) {
      this.clearValidators(['note', 'commentNote']);
    } else if (formValue.commentNote) {
      this.clearValidators(['note', 'phaseLineNote']);
    } else {
      this.goalFormGroup.get('note').setValidators([Validators.required]);
      this.goalFormGroup.get('note').updateValueAndValidity({ emitEvent: false });
      this.goalFormGroup.get('phaseLineNote').setValidators([Validators.required]);
      this.goalFormGroup.get('phaseLineNote').updateValueAndValidity({ emitEvent: false });

      this.goalFormGroup.get('commentNote').setValidators([Validators.required]);
      this.goalFormGroup.get('commentNote').updateValueAndValidity({ emitEvent: false });
    }
  }

  private clearValidators(controls: Array<string>) {
    controls.forEach((control) => {
      this.goalFormGroup.get(control).clearValidators();
      this.goalFormGroup.get(control).updateValueAndValidity({ emitEvent: false });
    });
  }

  private setupObjectiveFormGroupFormArray() {
    this.goal.objectives.forEach((objective) => {
      this.objectiveFormGroupFormArray.push(
        this.fb.group({
          id: objective.id,
          iepGoalQuantifiableMeasurementId: objective.primaryMeasurement.iepGoalQuantifiableMeasurementId,
          name: objective.name,
          value: [null, Validators.required],
          note: null,
        })
      );
    });
  }
}
