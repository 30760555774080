import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService, Roles } from 'src/app/auth/auth.service';
import {
  TeamMemberSearchEntity,
  TeamMemberSearchModalComponent,
  TeamSearchModalData,
} from '../../modals/team-member-search-modal/team-member-search-modal.component';
import { CaseSummary } from '../../models/case';
import { KeyValuePair } from '../../models/key-value-pair';
import { Team } from '../../models/team';
import { RoutingService } from '../../services/routing.service';
import { TeamService } from '../../services/team/team.service';
import { AreYouSureComponent } from '../are-you-sure-modal/are-you-sure.component';
import { PaginatedList } from '../../models/paginated-list';

@Component({
  selector: 'app-team-member-table',
  templateUrl: './team-member-table.component.html',
  styleUrls: ['./team-member-table.component.scss'],
})
export class TeamMemberTableComponent implements OnInit, OnChanges {
  @Input() showScheduler = true;
  @Input() showSearchForm = true;
  @Input() showBuildingFilter = true;
  @Input() caseSummary: CaseSummary;
  @Input() teamMembers: any[] = [];
  @Input() allowedRoles: Roles[];
  @Input() locked = false;
  @Input() displayedColumns = ['actions', 'name', 'jobTitle', 'email', 'phone', 'aea', 'district'];
  @Input() isMultiTeam = true;
  @Input() dontAllowSelfSelection = false;
  @Input() userId: string;
  @Input() getTeamMemberFunc: (config: any) => Promise<PaginatedList<TeamMemberSearchEntity>>;

  @Output() updateTeamUsers = new EventEmitter<any>();
  @Output() removeTeamUser = new EventEmitter<any>();

  formGroup = new FormGroup({
    selectTeam: new FormControl(''),
  });

  teamOptions: KeyValuePair[] = [];
  private teams: Team[];

  dataSource = new MatTableDataSource([]);
  constructor(
    readonly authService: AuthService,
    private teamService: TeamService,
    private routingService: RoutingService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.locked && this.displayedColumns.some((x) => x === 'actions')) {
      this.displayedColumns.shift();
    }
    this.dataSource.data = this.teamMembers;
    if (this.isMultiTeam) {
      this.teamService.getTeams().subscribe((teams) => {
        this.teams = teams;
        this.teamOptions = teams.map((team) => new KeyValuePair(team.id, team.name));
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.teamMembers?.previousValue !== changes.teamMembers?.currentValue) {
      this.teamMembers = changes.teamMembers?.currentValue;
      this.dataSource.data = this.teamMembers;
    }
    if (changes.locked?.currentValue !== changes.locked?.previousValue && changes.locked?.currentValue === true) {
      this.displayedColumns.shift();
    }
  }

  canDelete(member: any): boolean {
    return member.id !== (this.userId ?? this.authService.user.id);
  }

  teamSelected(e: any): void {
    const teamMembers = this.teams.find((x) => x.id === e).users.map((x) => x.userId);
    this.updateTeamUsers.emit(teamMembers);
  }

  onRemoveMember(member: any): void {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        subQuestion: 'Clicking Yes will remove this team member.',
      },
    });
    dialogRef.afterClosed().subscribe((yesImSure) => {
      if (yesImSure) {
        this.removeTeamUser.emit(member.userId);
      }
    });
  }

  onOpenTeamSearch(): void {
    const userIds = this.teamMembers.map((t) => t.userId);

    const config = {
      userId: this.userId ?? this.authService.user.id,
      intakeType: this.caseSummary?.intakeType,
      learnerId: this.caseSummary?.learnerId,
      isMeeting: false,
      userIds,
    };
    const dialogRef = this.dialog.open(TeamMemberSearchModalComponent, {
      width: '728px',
      data: {
        config,
        allowedRoles: this.allowedRoles,
        dontAllowSelfSelection: this.dontAllowSelfSelection,
        showBuildingFilter: this.showBuildingFilter,
        getTeamMemberFunc: this.getTeamMemberFunc,
      } as TeamSearchModalData,
    });
    dialogRef.afterClosed().subscribe((teamMembers) => {
      if (!teamMembers || !teamMembers.newUserIds || teamMembers.newUserIds.length === 0) {
        return;
      }
      this.updateTeamUsers.emit(teamMembers.newUserIds);
    });
  }

  onScheduleMeeting(): void {
    this.routingService.openScheduleMeeting(this.caseSummary?.learnerId);
  }
}
