import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { DialogComingSoonComponent } from 'src/app/shared/components/coming-soon/coming-soon.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { AddTodoComponent } from './add-todo/add-todo.component';
import { EditTodoComponent } from './edit-todo/edit-todo.component';
import { TodoActionType, TodoRead } from './todo-models';
import { TodoService } from './todo.service';

interface TodoItem extends TodoRead {
  daysLeft: number;
  actions: {
    label: string;
    action: (todo: TodoItem) => void;
  }[];
}

@Component({
  selector: 'app-user-todo-list',
  templateUrl: './user-todo-list.component.html',
  styleUrls: ['./user-todo-list.component.scss'],
})
export class UserTodoListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  shortDateFormat = shortDateFormat;

  displayedColumns = ['title', 'learnerName', 'daysLeft', 'dueDate', 'quickActions'];
  dataSource: MatTableDataSource<TodoRead>;
  actionTypes = TodoActionType;
  loading = true;

  constructor(
    private dialog: MatDialog,
    private todoService: TodoService,
    private authService: AuthService,
    private router: Router,
    private routingService: RoutingService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getTodos();
  }

  async getTodos(): Promise<void> {
    try {
      this.loading = true;
      const todos = await this.todoService.get().toPromise();
      this.setDatasource(todos || []);
    } finally {
      this.loading = false;
    }
  }

  setDatasource(todos: TodoRead[]) {
    const now = dayjs();
    const todoItems = todos.map((todo: TodoItem) => {
      if (todo.dueDate) {
        todo.daysLeft = dayjs(todo.dueDate).diff(now, 'day');
      }
      todo.actions = [];
      switch (todo.actionType) {
        case TodoActionType.None:
          break;
        case TodoActionType.User:
          todo.actions.push(
            {
              label: 'Mark Complete',
              action: this.markComplete.bind(this),
            },
            { label: 'Edit', action: this.editItem.bind(this) },
            { label: 'Delete', action: this.deleteItem.bind(this) }
          );
          break;
        case TodoActionType.FamilyMeeting:
          if (!todo.familyMeeting.meetingRollCallDate) {
            todo.actions.push({
              label: 'Reschedule',
              action: this.completeFamilyMeeting.bind(this),
            });
          }

          todo.title = `Meeting: ${todo.familyMeeting?.purposesOfMeeting?.length > 0 ? todo.familyMeeting?.purposesOfMeeting[0] : ''}`;
          break;
        case TodoActionType.AssignServiceCoordinator:
          if (this.authService.isAllowed(AppPermissions.AssignServiceCoordinator)) {
            todo.actions.push({
              label: 'Assign',
              action: this.navigateToActionLink.bind(this),
            });
          }
          break;
        case TodoActionType.ScheduleFamilyMeetingFirstAttempt:
        case TodoActionType.ScheduleFamilyMeetingSecondAttempt:
        case TodoActionType.ScheduleFamilyMeetingThirdAttempt:
          todo.actionLink = this.routingService.familyContactPath(todo.learnerId).join('/');
          todo.actions.push({
            label: 'Record Contact Attempt',
            action: this.navigateToActionLink.bind(this),
          });
          if (this.authService.isAllowed(AppPermissions.ScheduleMeeting)) {
            todo.actions.push({
              label: 'Open Scheduler',
              action: this.onScheduleMeeting.bind(this),
            });
          }
          break;
        case TodoActionType.ScheduleInitialIFSPMeeting:
          if (this.authService.isAllowed(AppPermissions.ScheduleMeeting)) {
            todo.actions.push({
              label: 'Open Scheduler',
              action: this.onScheduleMeeting.bind(this),
            });
          }
          break;
        case TodoActionType.CompleteIFSPModification:
          todo.actions.push({
            label: 'IFSP View Page',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.CompleteIEPAmendment:
          todo.actions.push({
            label: 'IEP View Page',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.CompleteMedicalorOtherService:
          todo.actions.push({
            label: 'IFSP View Page',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.CompleteAnnualReview:
          todo.actions.push({
            label: 'Complete Annual Review',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.CompletePeriodicReview:
          todo.actions.push({
            label: 'Complete Periodic Review',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.SendFamilyLetter:
          todo.actions.push({
            label: 'Family Letter',
            action: this.openPopup.bind(this),
          });
          break;
        case TodoActionType.CloseEarlyAccessReferral:
          break;
        case TodoActionType.StartDisabilitySuspectForm:
        case TodoActionType.CompleteDisabilitySuspectForm:
          todo.actions.push({
            label: 'Disability Suspected',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.PriorWrittenNotice:
        case TodoActionType.FiiePwnCreationConfirmation:
        case TodoActionType.CompletePriorWrittenNoticeDSForm:
          todo.actions.push({
            label: 'Edit PWN',
            action: this.openPopup.bind(this),
          });
          todo.title = `${todo.title} (${dayjs(todo.createdOn).format('MM/DD/YYYY')})`;
          break;
        case TodoActionType.ContactFamilyForDisabilitySuspectCompletion:
        case TodoActionType.ContactFamilyForDisabilitySuspectCompletionSecondAttempt:
          todo.actionLink = this.routingService.familyContactPath(todo.learnerId).join('/');
          todo.actions.push({
            label: 'Family Contact',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.CompleteFiieForm:
        case TodoActionType.SignFiieForm:
          todo.actions.push({
            label: 'Consent for Full and Individual Initial Evaluation',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.EvaluationConsentApproved:
        case TodoActionType.EvaluationAssignmentChange:
          todo.actions.push({
            label: 'Go to Evaluation',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.CompleteInterimIEP:
          todo.actions.push({
            label: 'Complete Interim IEP',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.SendIfspAnnualReviewPoll:
          todo.actions.push({
            label: 'Send Annual Review Poll',
            action: this.openPopup.bind(this),
          });
          break;
        case TodoActionType.SendIfspPeriodicReviewPoll:
          todo.actions.push({
            label: 'Send Periodic Review Poll',
            action: this.openPopup.bind(this),
          });
          break;
        case TodoActionType.StartIfspAnnualReview:
          todo.actions.push({
            label: 'Start Annual Review',
            action: this.openPopup.bind(this),
          });
          break;
        case TodoActionType.StartIfspPeriodicReview:
          todo.actions.push({
            label: 'Start Periodic Review',
            action: this.openPopup.bind(this),
          });
          break;
        case TodoActionType.Reevaluation:
          todo.actions.push({
            label: 'Go to reevaluation',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.AmendIEP:
          todo.actions.push({
            label: 'Amend IEP',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.DevelopIEP:
        case TodoActionType.DevelopInterimIEP:
          todo.actions.push({
            label: 'Go to IEP',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.ReviewFBA:
        case TodoActionType.CompleteFBA:
          todo.actions.push({
            label: 'Go to FBA',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.ReviewBIP:
          todo.actions.push({
            label: 'Review BIP',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.CompleteBIP:
          todo.actions.push({
            label: 'Go to BIP',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.CreateBIP:
          todo.actions.push({
            label: 'Create a BIP',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.CreateIEP:
          todo.actions.push({
            label: 'Create a IEP',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.BipBlockedByEval:
          todo.actions.push({
            label: 'Evaluation Incomplete',
            action: this.openPopup.bind(this),
          });
          break;
        case TodoActionType.IepServiceEndCode:
          todo.actions.push({
            label: 'Go to IEP Details',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.SendContactLetter:
          todo.actions.push(
            {
              label: 'Print Case Closure Letter',
              action: this.navigateToActionLink.bind(this),
            },
            {
              label: 'Family Contact',
              action: this.navigateToActionLink.bind(this),
            }
          );
          break;
        case TodoActionType.ContactFamilyForConsentForFiie:
          todo.actions.push(
            {
              label: 'LM Documentation Page',
              action: this.navigateToActionLink.bind(this),
            },
            {
              label: 'Prior Written Notice',
              action: this.openPwn.bind(this),
            }
          );
          break;
        case TodoActionType.CloseChildFindCase:
          todo.actions.push(
            {
              label: 'LM Documentation Page',
              action: this.navigateToDocumentation.bind(this),
            },
            {
              label: 'Prior Written Notice',
              action: this.openPwn.bind(this),
            },
            {
              label: 'LM Dashboard (Close Case)',
              action: this.navigateToActionLink.bind(this),
            }
          );
          break;
        case TodoActionType.CompleteIEP:
          todo.actions.push({
            label: 'IEP Profile Page',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        case TodoActionType.CompleteIfsp:
          todo.actions.push({
            label: 'IFSP Profile Page',
            action: this.navigateToActionLink.bind(this),
          });
          break;
        default:
          if (todo.actionLink) {
            todo.actions.push({
              label: todo.title,
              action: this.navigateToActionLink.bind(this),
            });
          }
          break;
      }

      return todo;
    });
    this.dataSource = new MatTableDataSource(todoItems);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  addTodo() {
    this.dialog
      .open(AddTodoComponent, { width: '625px' })
      .afterClosed()
      .subscribe((todoCreated) => (todoCreated ? this.getTodos() : undefined));
  }

  markComplete(todo: TodoRead) {
    this.todoService.markComplete(todo).subscribe(() => this.getTodos());
  }

  editItem(todo: TodoRead) {
    this.dialog
      .open(EditTodoComponent, {
        data: todo,
      })
      .afterClosed()
      .subscribe((todoUpdated) => (todoUpdated ? this.getTodos() : undefined));
  }

  deleteItem(todo: TodoRead) {
    this.dialog
      .open(AreYouSureComponent, {
        width: '450px',
        data: {
          question: 'Are you sure?',
          subQuestion: 'Clicking Yes will remove this item.',
        },
      })
      .afterClosed()
      .subscribe((deleteTodo) => (deleteTodo ? this.deleteTodo(todo) : undefined));
  }

  deleteTodo(todo) {
    this.todoService.delete(todo.id).subscribe(() => this.getTodos());
  }

  openPopup(todoItem: TodoItem) {
    const config: NewWindowConfig = {
      path: todoItem.actionLink,
      popup: true,
      width: '1480px',
    };
    openNewWindow(config);
  }

  completeFamilyMeeting(todoItem: TodoItem) {
    this.dialog.open(DialogComingSoonComponent);
    // Saving this commented code for later...
    // this.familyMeetingService
    //   .updateStatus(todoItem.id, todoItem.caseId, MeetingStatus.Complete)
    //   .subscribe(() => this.getTodos());
  }

  onScheduleMeeting(todoItem: TodoItem) {
    this.routingService.openScheduleMeeting(todoItem.learnerId);
  }

  navigateToActionLink(todoItem: TodoItem) {
    this.router.navigate([todoItem.actionLink]).then();
  }

  navigateToDocumentation(todoItem: TodoItem) {
    const link = `learner-management/${todoItem.learnerId}/documentation`;
    this.router.navigate([link]).then();
  }

  openPwn(todoItem: TodoItem) {
    const config: NewWindowConfig = {
      path: `cases/${todoItem.caseId}/pwns`,
      popup: true,
      width: '1480px',
    };
    openNewWindow(config);
  }

  canViewActions(todoItem: TodoItem) {
    return (
      this.authService.isReadOnlyByCaseId(todoItem.caseId, AppPermissions.ViewTodoActions) || todoItem.userId === this.authService.user?.id
    );
  }
}
