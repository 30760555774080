import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-teams-create-modal',
  templateUrl: './user-teams-create-modal.component.html',
  styleUrls: ['./user-teams-create-modal.component.scss'],
})
export class UserTeamsCreateModalComponent {
  teamName: string;
  rename?: boolean;

  constructor(private dialogRef: MatDialogRef<UserTeamsCreateModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.rename = data?.rename;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
