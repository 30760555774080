import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-st-transfer-of-rights',
  templateUrl: './st-transfer-of-rights.component.html',
  styleUrls: ['./st-transfer-of-rights.component.scss'],
})
export class StTransferOfRightsComponent implements OnInit {
  formGroup = new FormGroup({
    familyDate: new FormControl(''),
    familyMethod: new FormControl(''),

    learnerDate: new FormControl(''),
    learnerMethod: new FormControl(''),

    notes: new FormControl(''),
  });

  constructor() {}

  ngOnInit(): void {}
}
