<div class="display-flex align-items-center justify-content-end">
  <button
    class="mt-4 mb-3 mr-2 printPageButton"
    mat-raised-button
    aria-label="Delete Icon"
    (click)="onClose()"
    *ngIf="showClose"
    tabindex="0"
  >
    Close
  </button>
  <button class="mt-4 mb-3 printPageButton" mat-raised-button aria-label="Print" color="primary" (click)="print()" tabindex="0">
    Print
  </button>
</div>

<div class="display-flex align-items-baseline justify-content-between mb-3" *ngIf="showLogo">
  <h2 class="mb-0" *ngIf="title">
    {{ title }}
  </h2>

  <ng-content></ng-content>

  <div class="print__logo mb-0 ml-3">
    <img src="/assets/img/achieve-logo.png" alt="ACHIEVE Logo" class="achieve" [ngClass]="{ 'position-fix': adjustLogo }" />
  </div>
</div>
