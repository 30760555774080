<ng-container *ngIf="dataSource.data.length === 0 && isDataLoadComplete">
  <div class="display-flex align-items-center justify-content-between flex-wrap">
    <p class="my-0">No Outcomes added yet</p>
    <a
      mat-raised-button
      color="primary"
      class="my-0"
      [routerLink]="['../child-family-outcomes']"
      tabindex="0"
      role="link"
      aria-labelledby=" Add New Outcome"
    >
      Add New Outcome
    </a>
  </div>
</ng-container>

<section *ngIf="dataSource.data.length > 0">
  <div class="table-overflow app-table">
    <table mat-table [dataSource]="dataSource" class="w-100" matSort>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="table-actions mat-column-actions--more">Actions</th>
        <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
          <div class="display-flex align-items-center justify-content-start">
            <button mat-icon-button aria-label="View Icon" color="primary" (click)="viewOutcomeDetail(element)" tabindex="0">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button mat-icon-button aria-label="Edit Icon" color="primary" (click)="onOutcomeEdit(element)" tabindex="0">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button aria-label="Delete Icon" color="warn" (click)="removeOutcome(element)" tabindex="0">
              <mat-icon>close</mat-icon>
            </button>
            <div class="fake-icon-button" *ngIf="!element.isComplete">
              <mat-icon class="icon-incomplete" aria-label="Warning">warning</mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="typeOfOutcome">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element">
          {{ element.typeOfOutcome }}
          Outcome<span *ngIf="element?.relatedTo">, Transition Related</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Outcome</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.title | truncate : 150) || '-' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="outcomeEndDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>End Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.outcomeEndDate | dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="tags">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tags</th>
        <td mat-cell *matCellDef="let element">
          <i
            *ngIf="element?.taggedItem?.taggedForServicesC"
            class="fas fa-chalkboard-teacher tag__icon text-success mr-1"
            aria-label="Service Tagging Icon"
          ></i>
          <button class="ml-1" *ngIf="element?.taggedItem?.taggedForPwn" mat-raised-button aria-label="Prior Written Notice">
            Prior Written Notice
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="graph">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="mat-cell--actions text-right">
          <button
            *ngIf="element.displayGraph"
            mat-icon-button
            aria-label="View Graph Icon"
            color="primary"
            class="mx-2 allow-click"
            (click)="onOpenGraph(element)"
            tabindex="0"
          >
            <mat-icon>auto_graph</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [dataSource]="dataSource"></mat-paginator>
  </div>
</section>
