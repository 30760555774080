<nav class="stepper__nav mt-5">
  <ul class="stepper__list">
    <a
      [routerLink]="['/', 'cases', caseId, 'ifsp', ifspId, 'profile']"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="Profile"
    >
      <li class="stepper__item">
        <i class="material-icons"> dashboard </i>
        <p>Profile</p>
      </li>
    </a>

    <a
      *ngIf="notFinalized"
      [routerLink]="['/', 'cases', caseId, 'ifsp', ifspId, 'plod']"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="PLOD"
    >
      <li class="stepper__item">
        <mat-icon aria-labelledby="PLOD">location_on</mat-icon>
        <p>PLOD</p>
      </li>
    </a>

    <a
      *ngIf="notFinalized"
      [routerLink]="['/', 'cases', caseId, 'ifsp', ifspId, 'child-family-outcomes']"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="Outcomes"
    >
      <li class="stepper__item">
        <i class="fas fa-bullseye"></i>
        <p>Outcomes</p>
      </li>
    </a>

    <a
      *ngIf="notFinalized"
      [routerLink]="['/', 'cases', caseId, 'ifsp', ifspId, 'services']"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="Services"
    >
      <li class="stepper__item">
        <i class="fas fa-chalkboard-teacher"></i>
        <p>Services</p>
      </li>
    </a>

    <a
      *ngIf="notFinalized"
      [routerLink]="['/', 'cases', caseId, 'ifsp', ifspId, 'additional-info']"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="Additional Info"
    >
      <li class="stepper__item">
        <mat-icon aria-labelledby="Additional Info">comment_bank</mat-icon>
        <p>Additional Info</p>
      </li>
    </a>
  </ul>
</nav>
