<form class="child-verification-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="mb-0" *ngIf="!questionnaireInvite.verified">
    <h1>Verify Information</h1>
    <app-text-field label="Child's First Name" formControlName="learnerFirstName"></app-text-field>
    <app-text-field label="Child's Last Name" formControlName="learnerLastName"></app-text-field>
    <app-date-picker label="Child's DOB" controlName="learnerDateOfBirth" [startView]="'multi-year'"></app-date-picker>
  </div>
  <div class="mt-2" *ngIf="!questionnaireInvite.familyUserId || questionnaireInvite.hasAnonAccount">
    <app-checkbox-single
      formControlName="skipAccountCreation"
      aria-labelledby="Check Skip Account Creation"
      label="Skip Account Creation"
    ></app-checkbox-single>
  </div>

  <hr class="input__divider" *ngIf="!questionnaireInvite.familyUserId && !skipAccountCreation" />

  <div [ngClass]="{ 'mt-5': skipAccountCreation }" *ngIf="!questionnaireInvite.familyUserId || questionnaireInvite.hasAnonAccount">
    <h1 class="mt-0" *ngIf="!skipAccountCreation">Create a password</h1>
    <app-text-field [readOnly]="true" label="Username" formControlName="username"></app-text-field>

    <ng-container *ngIf="!skipAccountCreation">
      <app-text-field
        label=" Password"
        formControlName="password"
        [inputType]="'password'"
        [disabledState]="skipAccountCreation"
      ></app-text-field>
    </ng-container>

    <ng-container *ngIf="!skipAccountCreation">
      <app-text-field
        label="Confirm Password"
        formControlName="confirmedPassword"
        [inputType]="'password'"
        [disabledState]="skipAccountCreation"
      ></app-text-field>
    </ng-container>
    <mat-error *ngIf="formGroup.touched && formGroup.hasError('passwordMismatch')">Passwords must match</mat-error>
    <button mat-flat-button aria-label="Complete" #submitButton class="buttons w-100 mt-3" type="submit" color="primary" tabindex="0">
      Complete
    </button>
  </div>
  <mat-error *ngIf="registerError">{{ registerError }}</mat-error>
  <div class="mt-5" *ngIf="questionnaireInvite.familyUserId && !questionnaireInvite.hasAnonAccount">
    <app-login-form (submitted)="onLoginSubmit($event)"></app-login-form>
  </div>
</form>
