<div class="container" *ngIf="learner && familyMeeting">
  <app-print-head [showLogo]="true" title="Notice of Meeting" [showClose]="true" (closeEvent)="onClose()"></app-print-head>

  <div class="print__container card-spacing-top">
    <div class="row">
      <div class="col-4">
        <p class="my-0">
          <strong>Name: </strong>
          {{ learner.fullName }}
        </p>
      </div>
      <div class="col-4">
        <p class="my-0">
          <strong>DOB: </strong>
          {{ learner.dateOfBirth | date : shortDateFormat }}
        </p>
      </div>
      <div class="col-4">
        <p class="my-0">
          <strong>Date Notified: </strong>
          {{ currentDate | dateFormat }}
        </p>
      </div>
    </div>
  </div>

  <div class="print__container card-spacing-top">
    <p class="mt-0">
      You are invited to attend a meeting to discuss the educational program for
      <strong>{{ learner.fullName }}</strong
      >.
    </p>
    <p>
      At this meeting, we will review student needs, progress, and possible changes or additions to the current educational program. We will
      be seeking your ideas and information regarding your child’s educational program. You are welcome to have others accompany you.
    </p>
    <p class="mb-0">
      The meeting has been scheduled for
      <strong>{{ familyMeeting.dateAndTime | dateTimeFormat : 'medium' : 'TD' }}</strong>
      at
      <strong *ngIf="familyMeeting.location">{{ familyMeeting.location }}</strong
      >.
    </p>
  </div>

  <h3 class="card-spacing-top mb-2">Learner Information</h3>
  <div class="print__container">
    <p class="mt-0">
      {{ learner.fullName }}, DOB
      {{ learner.dateOfBirth | date : shortDateFormat }}
    </p>
    <p>
      {{ learner.attendingDistrict }}
      {{ learner.gradeDescription ? ', ' + learner.gradeDescription : null }}
    </p>
    <p class="mb-0">
      {{ learner.streetAddress + ', ' + learner.city + ' ' + learner.state + ' ' + learner.zipCode }}
    </p>
  </div>

  <ng-container *ngIf="learner.familyMembers && learner.familyMembers.length > 0">
    <h3 class="card-spacing-top mb-2">Parent Information</h3>
    <div class="print__container">
      <ul class="list">
        <ng-container *ngFor="let member of learner.familyMembers">
          <li class="list__item" *ngIf="member?.fullName">
            <p class="my-0">
              {{ member.fullName }}
              <span *ngIf="member.homePhone"> {{ member.homePhone }} (Home) </span>
              <span *ngIf="member.workPhone"> {{ member.workPhone }} (Work) </span>
              <span *ngIf="member.cellPhone"> {{ member.cellPhone }} (Cell) </span>
            </p>

            <!-- Family Member Address -->
            <p class="mb-0">
              {{ member.streetAddress + ', ' + member.city + ' ' + member.state + ' ' + member.zipCode }}
            </p>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-container>

  <ng-container>
    <h3 class="card-spacing-top mb-2">
      If you need a translator/interpreter at this meeting, if the meeting time is inconvenient, or you do not plan to attend this meeting,
      please contact:
    </h3>
    <div class="print__container">
      <ul class="list">
        <li class="list__item" *ngIf="learner.serviceCoordinator">
          <p class="my-0">{{ learner.serviceCoordinator }}, Service Coordinator</p>
          <p class="my-0">
            {{ learner.serviceCoordinatorPhone | phone }},
            {{ learner.serviceCoordinatorEmail }}
          </p>
        </li>
        <li class="list__item" *ngIf="!learner.serviceCoordinator && !familyMeeting.alternateContactName">
          <p class="my-0">
            {{ familyMeeting.contactPerson.fullName }},
            {{ familyMeeting.contactPerson.agency }}
          </p>
          <p class="my-0">
            {{ familyMeeting.contactPerson.phoneNumber | phone }},
            {{ familyMeeting.contactPerson.email }}
          </p>
        </li>
        <li class="list__item" *ngIf="familyMeeting.alternateContactName && !learner.serviceCoordinator">
          <p class="my-0">
            {{ familyMeeting.alternateContactName }},
            {{ familyMeeting.alternateContactPosition }}
          </p>
          <p class="my-0">
            {{ familyMeeting.alternateContactPhone | phone }},
            {{ familyMeeting.alternateContactEmail }}
          </p>
        </li>
      </ul>
    </div>
  </ng-container>

  <ng-container *ngIf="familyMeeting.purposesOfMeeting && familyMeeting.purposesOfMeeting.length > 0">
    <h3 class="card-spacing-top mb-2">The purpose of this meeting is to discuss and develop or review:</h3>
    <div class="print__container">
      <ul class="list">
        <li class="list__item">
          <p class="my-0">
            <span *ngFor="let purpose of familyMeeting.purposesOfMeeting; last as isLast">
              {{ isLast ? purpose : purpose + ', ' }}
            </span>
          </p>
        </li>
      </ul>
    </div>
  </ng-container>

  <ng-container *ngIf="familyMeeting.participants && familyMeeting.participants.length > 0">
    <h3 class="card-spacing-top mb-2">
      The following individuals are expected to attend this meeting and will be invited by school personnel:
    </h3>
    <div class="print__container">
      <ul class="my-0 px-3">
        <li *ngFor="let participant of familyMeeting.participants">
          {{ participant.fullName }}
          {{ participant.role !== 'Family' ? ', ' + participant.agency : null }}
        </li>
      </ul>
    </div>
  </ng-container>
</div>
