import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { LookupBase } from 'src/app/shared/models/lookup-base';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { ImplementedStrategy, LearnerProgress, LearnerProgressDocument, LearnerProgressItem } from './models';

@Injectable({
  providedIn: 'root',
})
export class LearnerProgressService {
  private baseUrl = 'api/evaluations';
  constructor(private http: HttpClient, private spinnerService: SpinnerService) {}

  get(evaluationId: string, learnerProgressId: string) {
    return this.http.get<LearnerProgress>(`${this.baseUrl}/${evaluationId}/learner-progress/${learnerProgressId}`);
  }

  updateLearnerProgress(learnerProgress: LearnerProgress) {
    return this.http.put(`${this.baseUrl}/${learnerProgress.evaluationId}/learner-progress/${learnerProgress.id}`, learnerProgress);
  }

  getNotEvaluatedReasons(evalId: string) {
    return this.http.get<LookupBase[]>(`${this.baseUrl}/${evalId}/learner-progress/not-evaluated-reasons`);
  }

  addLearnerProgressItem(evaluationId: string, learnerProgressItem: LearnerProgressItem) {
    return this.http.post(`${this.baseUrl}/${evaluationId}/learner-progress-item`, learnerProgressItem);
  }

  updateLearnerProgressItem(evaluationId: string, learnerProgressItem: LearnerProgressItem) {
    return this.http.put(`${this.baseUrl}/${evaluationId}/learner-progress-item/${learnerProgressItem.id}`, learnerProgressItem);
  }

  removeLearnerProgressItem(evaluationId: string, id: string) {
    return this.http.put(`${this.baseUrl}/${evaluationId}/learner-progress-item/delete/${id}`, null);
  }

  updateImplementedStrategy(evaluationId: string, dto: ImplementedStrategy) {
    return this.http.put(`${this.baseUrl}/${evaluationId}/implemented-strategies/${dto.id}`, dto);
  }

  addDocuments(documents: FormData, evalId: string) {
    this.spinnerService.incrementLoading();
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    return this.http
      .post<LearnerProgressDocument[]>(`${this.baseUrl}/${evalId}/learner-progress/documents`, documents, this.getMultipartRequestHeaders())
      .pipe(tap(turnOffSpinner, turnOffSpinner));
  }

  removeDocument(evalId: string, docId: string) {
    return this.http.put<LearnerProgressDocument[]>(`${this.baseUrl}/${evalId}/learner-progress/documents/${docId}`, null);
  }

  // TODO: move this to a shared helper
  protected getMultipartRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      Accept: 'application/json, text/plain, */*',
    });

    return { headers };
  }
}
