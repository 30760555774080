import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { EarlyAccessQuestionnaire } from '../../models/early-access-questionnaire';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  private baseUrl = 'api/early-access-questionnaires';

  constructor(private http: HttpClient, private spinner: SpinnerService) {
    spinner.registerIgnoredRequestUrlMatcher(new RegExp(`^${this.baseUrl}`));
  }

  getQuestionnaire(caseId: string) {
    return this.http.get<EarlyAccessQuestionnaire>(`${this.baseUrl}/${caseId}`);
  }

  saveQuestionnaire(questionnaire: EarlyAccessQuestionnaire) {
    return this.http.put(`${this.baseUrl}/${questionnaire.caseId}`, questionnaire);
  }

  resendQuestionnaire(caseId: string, questionnaireId: string) {
    return this.http.put<any>(`${this.baseUrl}/${caseId}/${questionnaireId}`, null);
  }
}
