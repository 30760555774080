import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { KeyValuePair } from '../../models/key-value-pair';
import { DeactivationService } from '../../services/deactivation.service';
import { HelpSection, HelpTerm } from '../../services/help/help';
import { HelpModalConfig, HelpService } from '../../services/help/help.service';
import { IepGoalHelp } from '../../services/help/models/iep.help';
import { MemoryStorageService } from '../../services/memory-storage/memory-storage.service';
import { AreYouSureComponent } from '../are-you-sure-modal/are-you-sure.component';
import { BaseComponent } from '../base-component/base-component';
import { QuantifiableDataMilestone, QuantifiableDataRating, QuantifiableDataTrial, QuantifiableDatum } from './quantifiable-data';
import { QuantifiableDataService } from './quantifiable-data.service';

const initializeTrial = (trial: QuantifiableDataTrial, fb: FormBuilder) =>
  fb.group({
    id: fb.control(trial?.id),
    score: fb.control(trial?.score),
    date: fb.control(trial?.date),
    priorVersionId: fb.control(trial?.priorVersionId),
    priorVersion: fb.control(trial?.priorVersion),
    isActive: fb.control(true),
  });

const initializeTrials = (trials: QuantifiableDataTrial[], fb: FormBuilder) => fb.array((trials || []).map((t) => initializeTrial(t, fb)));

const initializeMilestone = (milestone: QuantifiableDataMilestone, fb: FormBuilder) =>
  fb.group({
    id: fb.control(milestone?.id),
    // eslint-disable-next-line id-blacklist
    number: fb.control(milestone?.number),
    completionDate: fb.control(milestone?.completionDate),
    benchmarkObjectiveMilestone: fb.control(milestone?.benchmarkObjectiveMilestone),
    value: fb.control(milestone?.value),
    secondary: fb.control(milestone?.secondary),
    priorVersionId: fb.control(milestone?.priorVersionId),
    priorVersion: fb.control(milestone?.priorVersion),
    isActive: fb.control(true),
  });

const initializeMilestones = (milestones: QuantifiableDataMilestone[], fb: FormBuilder) =>
  fb.array((milestones || []).map((t) => initializeMilestone(t, fb)));

const initializeScale = (scale: QuantifiableDataRating, fb: FormBuilder) =>
  fb.group({
    id: fb.control(scale?.id),
    rating: fb.control(scale?.rating),
    description: fb.control(scale?.description),
    secondary: fb.control(scale?.secondary),
    priorVersionId: fb.control(scale?.priorVersionId),
    priorVersion: fb.control(scale?.priorVersion),
    isActive: fb.control(true),
  });

const initializeScales = (scales: QuantifiableDataRating[], fb: FormBuilder) => fb.array((scales || []).map((t) => initializeScale(t, fb)));

@Component({
  selector: 'app-quantifiable-data',
  templateUrl: './quantifiable-data.component.html',
  styleUrls: ['./quantifiable-data.component.scss'],
})
export class QuantifiableDataComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model: QuantifiableDatum;
  @Input() learnerFirstName: string;
  @Input() submitted: boolean;
  @Input() showMilestoneBtn = true;
  @Input() isIfsp = false;
  @Input() typeOfOutcome: string;
  @Input() modificationId: string;

  @Output() formChange = new EventEmitter<{
    value: QuantifiableDatum;
    valid: boolean;
    dirty: boolean;
  }>();

  @Output() formLoaded = new EventEmitter<{
    value: boolean;
  }>();

  @ViewChild('quantDataForm') quantDataForm: NgForm;

  formGroup: FormGroup = this.fb.group(
    {
      areaOfConcern: [null],
      assessmentEvaluationActivity: [null, [Validators.required]],
      measurement: [null, [Validators.required]],
      trials: initializeTrials([], this.fb),
      scaleRatings: initializeScales([], this.fb),
      milestones: initializeMilestones([], this.fb),
      baseline: [null, [Validators.required, Validators.min(0)]],
      isExpectedNarrative: [false],
      expectedPerformanceMin: [null, [Validators.required, Validators.min(0)]],
      expectedPerformanceMax: [null, Validators.min(0)],
      expectedPerformanceNarrative: [null],
      isNarrative: [false],
      performanceMin: [null, [Validators.required, Validators.min(0)]],
      performanceNarrative: [null],
      isAdvanced: [false],
      secondMeasurementDescription: [null],
      secondIsExpectedNarrative: [false],
      secondExpectedPerformanceMin: [null, Validators.min(0)],
      secondExpectedPerformanceMax: [null, Validators.min(0)],
      secondExpectedPerformanceNarrative: [null],
      secondIsNarrative: [false],
      secondPerformanceMin: [null],
      secondPerformanceMax: [null],
      secondPerformanceNarrative: [null],
      standardOfComparisonId: [null, [Validators.required]],
      comments: [null, [Validators.required]],
    },
    { updateOn: 'change' }
  );
  standardsOfComparisonOptions: KeyValuePair[];
  isManualBaseline = true;
  subscriptions: Subscription = new Subscription();
  showScale = false;
  showScaleForm = false;
  showMilestone = false;
  showMilestoneForm = false;
  showSecondaryScale = false;
  showSecondaryScaleForm = false;
  showSecondaryMilestone = false;
  showSecondaryMilestoneForm = false;
  dataPointLimitExceeded = false;
  showTrials = false;
  today = dayjs().startOf('day').toDate();
  helpSection = HelpSection;
  iepGoalHelp = IepGoalHelp;

  constructor(
    private fb: FormBuilder,
    private service: QuantifiableDataService,
    private memoryStorage: MemoryStorageService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private helpService: HelpService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  get firstNamePossessive() {
    if (!this.learnerFirstName) {
      return;
    }
    const lastLetter = this.learnerFirstName.slice(-1);
    return lastLetter === 's' ? `${this.learnerFirstName}'` : `${this.learnerFirstName}'s`;
  }

  get trials() {
    return (this.formGroup.get('trials') as FormArray).controls as FormGroup[];
  }

  get isNarrative() {
    return this.formGroup.get('isNarrative').value;
  }

  get isExpectedNarrative() {
    return this.formGroup.get('isExpectedNarrative').value;
  }

  get scaleRatings() {
    return this.formGroup.get('scaleRatings').value;
  }

  set scaleRatings(val: QuantifiableDataRating[]) {
    this.formGroup.setControl('scaleRatings', initializeScales(val, this.fb));
  }

  get milestones() {
    return this.formGroup.get('milestones').value;
  }

  set milestones(val: QuantifiableDataMilestone[]) {
    this.formGroup.setControl('milestones', initializeMilestones(val, this.fb));
  }

  get secondIsNarrative() {
    return this.formGroup.get('secondIsNarrative').value;
  }

  get secondIsExpectedNarrative() {
    return this.formGroup.get('secondIsExpectedNarrative').value;
  }

  get isAdvanced() {
    return this.formGroup.get('isAdvanced').value;
  }

  ngOnInit(): void {
    this.initializeFormGroup();
    this.subscriptions.add(
      this.formGroup.get('trials').valueChanges.subscribe(() => {
        setTimeout(this.setBaseline.bind(this), 0);
      })
    );

    // Add/remove validators depending on isNarrative
    this.addRemoveNarrativeValidation('isExpectedNarrative', 'expectedPerformanceMin', 'expectedPerformanceNarrative');
    this.addRemoveNarrativeValidation('isNarrative', 'performanceMin', 'performanceNarrative');

    this.addRemoveSecondNarrativeValidation(
      'secondIsExpectedNarrative',
      'secondExpectedPerformanceMin',
      'secondExpectedPerformanceNarrative'
    );

    this.addRemoveSecondNarrativeValidation('secondIsNarrative', 'secondPerformanceMin', 'secondPerformanceNarrative');

    this.subscriptions.add(this.formGroup.valueChanges.subscribe(this.emitForm.bind(this)));
    this.standardsOfComparisonOptions = this.memoryStorage.getKey('standardsOfComparisonOptions', true);
    if (!this.standardsOfComparisonOptions) {
      this.service.getStandardsOfComparison().subscribe((res) => {
        this.standardsOfComparisonOptions = res.map((x) => new KeyValuePair(x.id, x.label));
        this.memoryStorage.setKey('standardsOfComparisonOptions', this.standardsOfComparisonOptions);
        setTimeout(() => this.formGroup.get('standardOfComparisonId').setValue(this.model?.standardOfComparison.id), 0);
      });
    }
    if (this.isIfsp) {
      this.formGroup.get('assessmentEvaluationActivity').clearValidators();
      this.formGroup.get('measurement').clearValidators();
      this.formGroup.get('standardOfComparisonId').clearValidators();
      this.formGroup.get('comments').clearValidators();
      this.formGroup.updateValueAndValidity();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model?.currentValue !== changes.model?.previousValue) {
      this.initializeFormGroup();
    }
    if (changes.submitted?.currentValue && !changes.submitted.previousValue) {
      // this.formGroup.markAllAsTouched();
      this.formGroup.updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  showAddTrials() {
    this.showTrials = true;
  }

  calculateBaseLine() {
    if (this.formGroup.get('trials').value?.length === 0) {
      this.addTrial();
    } else {
      const dataFormArray = this.formGroup.get('trials') as FormArray;
      dataFormArray.clear();
    }
    this.cd.detectChanges();
  }

  addTrial() {
    const trials = this.formGroup.get('trials') as FormArray;
    if (trials.length > 14) {
      this.dataPointLimitExceeded = true;
      return;
    }
    trials.push(this.fb.group({ score: [null, Validators.min(0)], date: null }));
  }

  removeTrial(idx: number) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Clicking Yes will remove this data point.',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const trials = this.formGroup.get('trials') as FormArray;
        trials.removeAt(idx);
        if (trials.length <= 14) {
          this.dataPointLimitExceeded = false;
        }
      }
    });
  }

  toggleNarrative(controlName: string) {
    const ctrl = this.formGroup.get(controlName);
    ctrl.setValue(!ctrl.value);
  }

  showMilestones(isSecondary: boolean, isEdit: boolean) {
    if (isSecondary) {
      this.showSecondaryMilestoneForm = isEdit ? true : !this.showSecondaryMilestoneForm;
      if (this.showSecondaryMilestone) {
        this.showSecondaryMilestone = false;
      } else {
        this.showSecondaryMilestone = true;
      }
    } else {
      this.showMilestoneForm = isEdit ? true : !this.showMilestoneForm;
      this.showMilestone = true;
    }
  }

  showScales(isSecondary: boolean, isEdit: boolean) {
    if (isSecondary) {
      this.showSecondaryScaleForm = isEdit ? true : !this.showSecondaryScaleForm;
      this.showSecondaryScale = true;
    } else {
      this.showScaleForm = isEdit ? true : !this.showScaleForm;
      this.showScale = true;
    }
  }

  onScaleCanceled(e: any, isSecondary: boolean) {
    if (isSecondary) {
      this.showSecondaryScaleForm = false;
    } else {
      this.showScaleForm = false;
    }
  }

  onMilestoneCanceled(e: any, isSecondary: boolean) {
    if (isSecondary) {
      this.showSecondaryMilestoneForm = false;
    } else {
      this.showMilestoneForm = false;
    }
  }

  toggleAdvanced() {
    const isAdvancedCtrl = this.formGroup.get('isAdvanced');
    const secondMeasurementDescriptionCtrl = this.formGroup.get('secondMeasurementDescription');
    const secondExpectedPerformanceMinCtrl = this.formGroup.get('secondExpectedPerformanceMin');
    const secondPerformanceMinCtrl = this.formGroup.get('secondPerformanceMin');
    const secondExpectedPerformanceNarrativeCtrl = this.formGroup.get('secondExpectedPerformanceNarrative');
    const secondPerformanceNarrativeCtrl = this.formGroup.get('secondPerformanceNarrative');
    const secondIsExpectedNarrative = this.formGroup.get('secondIsExpectedNarrative');
    const secondIsNarrative = this.formGroup.get('secondIsNarrative');

    isAdvancedCtrl.setValue(!isAdvancedCtrl.value);
    if (isAdvancedCtrl.value) {
      setTimeout(() => {
        [secondMeasurementDescriptionCtrl, secondExpectedPerformanceMinCtrl, secondPerformanceMinCtrl].forEach((c) => {
          c.setValidators([Validators.required]);
          c.updateValueAndValidity();
        });
      }, 0);
    } else {
      setTimeout(() => {
        [
          secondMeasurementDescriptionCtrl,
          secondExpectedPerformanceMinCtrl,
          secondPerformanceMinCtrl,
          secondExpectedPerformanceNarrativeCtrl,
          secondPerformanceNarrativeCtrl,
        ].forEach((c) => {
          c.clearValidators();
          c.updateValueAndValidity();
        });
        secondIsExpectedNarrative.setValue(false);
        secondIsNarrative.setValue(false);
      }, 0);
    }
    // TODO: toggle validators and clear fields
  }

  onOpenHelp(section: HelpSection, item: HelpTerm) {
    const dictionary = this.helpService.getIepGoalDictionary();

    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  private initializeFormGroup() {
    this.formGroup.patchValue(this.model || {});

    this.formGroup.setControl('trials', initializeTrials(this.model?.trials, this.fb));
    this.formGroup.setControl('milestones', initializeMilestones(this.model?.milestones, this.fb));
    this.formGroup.setControl('scaleRatings', initializeScales(this.model?.scaleRatings, this.fb));

    // Need to re-subscribe since control was reset
    this.subscriptions.add(
      this.formGroup.get('trials').valueChanges.subscribe(() => {
        setTimeout(this.setBaseline.bind(this), 0);
      })
    );

    if (this.isIfsp) {
      this.formGroup.get('standardOfComparisonId').setValue(this.model?.standardOfComparisonId);
    } else {
      this.formGroup.get('standardOfComparisonId').setValue(this.model?.standardOfComparison?.id);
    }

    if (this.formGroup.controls.milestones?.value?.length > 0) {
      this.showMilestone = true;
      this.showSecondaryMilestone = true;
    }
    if (this.formGroup.controls.milestones?.value?.length > 0) {
      this.showScale = true;
      this.showSecondaryScale = true;
    }

    // Toggle baseline calculated vs editable if there's trial data
    this.setBaseline();

    // Emit initial state of form so parents can update themselves
    this.emitForm();
  }

  private emitForm() {
    const val = this.formGroup.value;
    // Don't emit any blank trials
    val.trials = val.trials.filter((t) => t.date && t.score);
    this.formChange.emit({
      value: val,
      valid: this.formGroup.valid,
      dirty: this.formGroup.dirty,
    });
  }

  private setBaseline() {
    const baseline = this.formGroup.get('baseline');
    const trials = this.formGroup.get('trials').value;
    if (trials.some((t) => t.score && t.date)) {
      this.isManualBaseline = false;
      const scores = trials.filter((t) => t.score).map((t) => t.score);
      const avg = scores.reduce((a, b) => a + b) / scores.length;
      baseline.setValue(avg);
    } else {
      this.isManualBaseline = true;
      baseline.setValue(this.formGroup.get('baseline').value);
    }
    this.cd.detectChanges();
    baseline.updateValueAndValidity();
  }

  private addRemoveNarrativeValidation(flagControl: string, minControl: string, narrativeControl: string) {
    const isNarrativeControl = this.formGroup.get(flagControl);
    const performanceMin = this.formGroup.get(minControl);
    const performanceNarrative = this.formGroup.get(narrativeControl);
    this.subscriptions.add(
      isNarrativeControl.valueChanges.pipe(startWith(isNarrativeControl.value)).subscribe((isNarrative: boolean) => {
        if (isNarrative) {
          performanceMin.setValidators([]);
          performanceMin.updateValueAndValidity();
          performanceNarrative.setValidators([Validators.required]);
          performanceNarrative.updateValueAndValidity();
        } else {
          performanceMin.setValidators([Validators.required, Validators.min(0)]);
          performanceMin.updateValueAndValidity();
          performanceNarrative.setValidators([]);
          performanceNarrative.updateValueAndValidity();
        }
      })
    );
  }

  private addRemoveSecondNarrativeValidation(flagControl: string, minControl: string, narrativeControl: string) {
    const isNarrativeControl = this.formGroup.get(flagControl);
    const performanceMin = this.formGroup.get(minControl);
    const performanceNarrative = this.formGroup.get(narrativeControl);
    this.subscriptions.add(
      isNarrativeControl.valueChanges.pipe(startWith(isNarrativeControl.value)).subscribe((isNarrative: boolean) => {
        if (!this.isAdvanced) {
          return;
        }
        if (isNarrative) {
          performanceMin.setValidators([]);
          performanceMin.updateValueAndValidity();
          performanceNarrative.setValidators([Validators.required]);
          performanceNarrative.updateValueAndValidity();
        } else {
          performanceMin.setValidators([Validators.required, Validators.min(0)]);
          performanceMin.updateValueAndValidity();
          performanceNarrative.setValidators([]);
          performanceNarrative.updateValueAndValidity();
        }
      })
    );
  }
}
