<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ data.type }} Removal</h3>
<div mat-dialog-content class="py-3">
  <form [formGroup]="formGroup">
    <h3 class="mt-0 mb-2">{{ data?.title }}</h3>
    <mat-card class="mb-0 card--plain">
      <p class="form__description">
        {{ data?.subTitle }}
      </p>
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <app-date-picker label="{{ data.type }} End Date" controlName="endDate" [min]="data.startDate"></app-date-picker>
        </div>
        <div class="col-md-12 col-lg-6">
          <app-autocomplete
            *ngIf="data.type === 'Service'"
            label="Reason for Ending Early"
            formControlName="endReasonId"
            [options]="reasonOptions"
          ></app-autocomplete>
        </div>
        <div class="col-md-12">
          <app-textarea label="Comments" formControlName="comments" [rows]="4" [maxLength]="stringSizes.extraLarge"></app-textarea>
        </div>
      </div>
    </mat-card>
  </form>
</div>
<div mat-dialog-actions class="actions display-flex justify-content-between">
  <div>
    <button
      (click)="onTag()"
      mat-raised-button
      attr.aria-label="{{ formGroup?.controls?.tagForPwn?.value ? 'Untag for Prior Written Notice' : 'Tag for Prior Written Notice' }}"
      *ngIf="data.type === 'Service'"
    >
      {{ formGroup?.controls?.tagForPwn?.value ? 'Untag for Prior Written Notice' : 'Tag for Prior Written Notice' }}
    </button>
  </div>

  <div>
    <button mat-button aria-label="Cancel Button" (click)="dismiss()" cdkFocusInitial tabindex="0">Cancel</button>
    <button
      mat-raised-button
      aria-label="Complete"
      color="primary"
      (click)="onSubmit()"
      [disabled]="formGroup.invalid"
      [disableAfterBusy]="true"
      [isBusy]="activeCall"
      tabindex="0"
    >
      Complete
    </button>
  </div>
</div>
