<span class="text-semibold text-primary">Quick Dates</span>
<div class="form-group" [formGroup]="quickDateForm">
  <mat-button-toggle-group aria-labelledby="Select an option">
    <mat-button-toggle (change)="setQuickDate('Today')" role="link" aria-labelledby="Today">Today</mat-button-toggle>
    <mat-button-toggle (change)="setQuickDate('Yesterday')" role="link" aria-labelledby="Yesterday">Yesterday</mat-button-toggle>
    <mat-button-toggle (change)="setQuickDate('Last7Days')" role="link" aria-labelledby="Last 7 days">Last 7 days</mat-button-toggle>
    <mat-button-toggle (change)="setQuickDate('Last30Days')" role="link" aria-labelledby="Last 30 days">Last 30 days</mat-button-toggle>
    <mat-button-toggle (change)="setQuickDate('Last90Days')" role="link" aria-labelledby="Last 90 days">Last 90 days</mat-button-toggle>
    <mat-button-toggle (change)="setQuickDate('AllTime')" role="link" aria-labelledby="All Time">All Time</mat-button-toggle>
    <mat-button-toggle (change)="setQuickDate('CustomDate')" role="link" aria-labelledby="Custom">Custom</mat-button-toggle>
  </mat-button-toggle-group>

  <!--
  <a (click)="setQuickDate('Today')" tabindex="0" role="link" aria-labelledby="Today">Today</a>
  <a (click)="setQuickDate('Yesterday')" tabindex="0" role="link" aria-labelledby="Yesterday">Yesterday</a>
  <a (click)="setQuickDate('Last7Days')" tabindex="0" role="link" aria-labelledby="Last 7 days">Last 7 days</a>
  <a (click)="setQuickDate('Last30Days')" tabindex="0" role="link" aria-labelledby="Last 30 days>Last 30 days</a>
  <a (click)="setQuickDate('Last90Days')" tabindex="0" role="link" aria-labelledby="Last 90 days">Last 90 days</a>
  <a (click)="setQuickDate('AllTime')" tabindex="0" role="link" aria-labelledby="All Time">All Time</a>
  <a (click)="setQuickDate('CustomDate')" tabindex="0" role="link" aria-labelledby="Custom">Custom</a> -->

  <mat-error *ngIf="showError"> Ending date cannot be before the starting date. </mat-error>
  <div *ngIf="quickDateForm.value.dateShortcut !== 'CustomDate' && quickDateForm.value.dateShortcut !== 'AllTime'">
    <p class="my-1">
      <span class="text-semibold text-primary mr-2">Dates:</span>
      <em>
        {{ quickDateForm.value?.startDate | dateFormat }} -
        {{ quickDateForm.value?.endDate | dateFormat }}
      </em>
    </p>
  </div>
  <div *ngIf="quickDateForm.value.dateShortcut === 'AllTime'">
    <p class="my-1">
      <span class="text-semibold text-primary mr-2">Dates:</span>
      <em>All Time</em>
    </p>
  </div>
  <div *ngIf="quickDateForm.value.dateShortcut === 'CustomDate'">
    <div class="row my-1">
      <div class="col-md-6">
        <mat-form-field class="quickdate">
          <input
            matInput
            aria-labelledby="Start Date"
            formControlName="startDate"
            name="startDate"
            [matDatepicker]="startDatePicker"
            placeholder="Choose a date"
            required
            min="01/01/1900"
          />
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #startDatePicker disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="quickdate">
          <input
            matInput
            aria-labelledby="End Date"
            formControlName="endDate"
            name="endDate"
            [matDatepicker]="endDatePicker"
            placeholder="Choose a date"
            required
            min="01/01/1900"
          />
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #endDatePicker disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
