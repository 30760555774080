<div class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>

      <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
        <div class="display-flex align-items-center justify-content-start">
          <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewMore(element)" tabindex="0">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="iepDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>IEP Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.iepDate | dateFormat }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actualScore">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Score</th>
      <td mat-cell *matCellDef="let element">
        {{ element.hasSpecialCircumstance ? 'Special Circumstance' : element.actualScore }}
      </td>
    </ng-container>
    <ng-container matColumnDef="level">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Level</th>
      <td mat-cell *matCellDef="let element">
        {{ getLevel(element.weightedMatrixLevelId) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="updatedBy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated By</th>
      <td mat-cell *matCellDef="let element">
        {{ getUpdatedBy(element.updatedBy) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="updatedOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated On</th>
      <td mat-cell *matCellDef="let element">
        {{ element.updatedOn | dateFormat }}
      </td>
    </ng-container>
    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Comments</th>
      <td mat-cell *matCellDef="let element">
        {{ element.comments | truncate : 100 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr [hidden]="dataSource?.data?.length > 0" mat-footer-row *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>

  <hr class="input__divider my-2" />
</div>
