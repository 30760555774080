<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ data.title }}</h3>

<mat-dialog-content class="py-2">
  <mat-card class="mb-0 white-space-pre-line">
    <ng-container *ngIf="!isMessageArray"> {{ data.message }} </ng-container>
    <ng-container *ngIf="isMessageArray">
      <div *ngFor="let message of data.message">
        <p>{{ message }}</p>
      </div>
    </ng-container>
  </mat-card>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="onNoClick()" tabindex="0">{{ data.buttons?.cancel || 'Cancel' }}</button>
  <button mat-raised-button color="primary" (click)="onYesClick()" cdkFocusInitial tabindex="0">{{ data.buttons?.ok || 'Ok' }}</button>
</mat-dialog-actions>
