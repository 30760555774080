import { TaggedItem } from 'src/app/tags/tagged-item';
import { EcoArea } from '../../evaluation/models/evaluation';
import { QuantifiableDatum } from '../../shared/components/quantifiable-data/quantifiable-data';
import { FamilyArea } from '../../shared/models/family-area';
import { IfspModification } from './ifsp-modification';

export interface OutcomeDto {
  id?: string;
  ifspId: string;
  title?: string;
  typeOfOutcome?: TypeOfOutcome;
  ecoAreas?: Array<OutcomeEcoArea>;
  familyAreas?: Array<OutcomeFamilyAreaDto>;
  relatedTo?: boolean;
  strategies?: Array<OutcomeStrategyDto>;
  criterias?: Array<OutcomeCriteriaDto>;
  progresses?: Array<OutcomeProgressDto>;
  timelineNumber?: number;
  timelinePeriod?: string;
  outcomeEndDate?: Date;
  createdOn: Date;
  isComplete?: boolean;
  displayGraph?: boolean;

  modificationId?: string;
  modification?: IfspModification;
  modificationStartDate?: Date;
  modificationEndDate?: Date;
  modificationComments?: string;
  isActive: boolean;
  dateInActive?: Date;
  priorVersionId?: string;
  priorVersion?: OutcomeDto;
  taggedItem?: TaggedItem;
}

export interface OutcomeReviewDto {
  id?: string;
  ifspId: string;
  title?: string;
  typeOfOutcome?: TypeOfOutcome;
}

export interface CreateOutcomeDto {
  ifspId: string;
}

export interface Outcome {
  id?: string;
  ifspId: string;
  title?: string;
  typeOfOutcome?: TypeOfOutcome;
  ecoAreas?: Array<OutcomeEcoArea>;
  familyOutcomes?: Array<OutcomeFamilyArea>;
  relatedTo?: boolean;
  strategies?: Array<OutcomeStrategy>;
  criterias?: Array<OutcomeCriteria>;
  progesses?: Array<OutcomeProgress>;
  timelineNumber?: number;
  timelinePeriod?: string;
  outcomeEndDate?: Date;
  createdOn: Date;
  isComplete: boolean;
  isDeleted: boolean;
}

export interface GetAllOutcomesForServiceDto {
  id: string;
  title: string;
}

export interface OutcomeEcoArea {
  id: string;
  outcomeId: string;
  ecoAreaId: string;
  ecoArea: EcoArea;
}

export interface OutcomeEcoAreaDto {
  ecoAreaId: string;
  EcoArea: string;
}

export interface OutcomeFamilyArea {
  id: string;
  outcomeId: string;
  familyAreaId: string;
  familyArea: FamilyArea;
}

export interface OutcomeFamilyAreaDto {
  familyAreaId: string;
  familyArea: string;
}

export interface OutcomeStrategy {
  id: string;
  outcomeId: string;
  name: string;
}

export interface OutcomeStrategyDto {
  id: string;
  name: string;
}

export interface OutcomeCriteria {
  id: string;
  outcomeId: string;
  criteriaForAccomplishment: string;
  measurementType: string;
  quantifiableData?: QuantifiableDatum;
  isActive: boolean;
  priorVersionId?: string;
  priorVersion?: OutcomeCriteria;
}

export interface OutcomeCriteriaDto {
  id: string;
  outcomeId: string;
  criteriaForAccomplishment: string;
  measurementType: string;
  quantifiableDataId?: string;
  quantifiableData?: QuantifiableDatum;
  isActive: boolean;
  priorVersionId?: string;
  priorVersion?: OutcomeCriteriaDto;
  modificationId?: string;
}

export interface OutcomeProgress {
  id: string;
  outcomeId: string;
  measuringMethod?: string;
  otherMethodDescribe: string;
  frequencyNumber: string;
  frequencyPeriod: string;
  isActive: boolean;
  priorVersionId?: string;
}

export interface OutcomeProgressDto {
  id: string;
  outcomeId: string;
  measuringMethod?: string;
  otherMethodDescribe: string;
  frequencyNumber: string;
  frequencyPeriod: string;
  isActive: boolean;
  priorVersionId?: string;
}

export interface OutcomeProgressEdit extends OutcomeProgress {
  isEditing: boolean;
}

export interface OutcomeReview {
  outcome: OutcomeDto;
  reviewType?: TypeOfOutcomeReview;
}

export enum TypeOfOutcome {
  Child = 'Child',
  Family = 'Family',
}

export enum TypeOfOutcomeReview {
  Continue = 'Continue',
  Discontinue = 'Discontinue',
  Met = 'Met',
  Revise = 'Revise',
}
