import { BipImplementationHelp, BipStrategiesHelp } from './models/bip.help';
import { DsDecisionHelp, DsFormHelp } from './models/ds.help';
import { EvalAssessmentDetailsHelp, EvaluationAdditionalFactorsHelp, EvaluationScHelp, FiieConsentHelp } from './models/evaluation.help';
import { FbaAssessmentDetailsHelp, FbaSummaryHelp } from './models/fba.help';
import { I3IepHelp } from './models/i3.help';
import { IepGoalHelp, IepPlaafpHelp, IepServiceHelp } from './models/iep.help';
import { IntakeDentalVisionHearingHelp, IntakeNutritionGrowthHelp, IntakeHavingEnoughHelp } from './models/intake.help';
import { ReferralReasonHelp, ReferralSourceInfoHelp } from './models/referral.help';
import { InsuranceWaiverServices } from './models/insurace';

export interface Help {
  title: HelpSection;
  dictionary: HelpDictionary[];
}

export interface HelpDictionary {
  label: HelpTerm;
  descriptor?: string;
  iframeSource?: string;
}

export enum HelpArea {
  SpecialFactors = 'Special Factors',
  DomainsToBeEvaluated = 'Domain(s)',
}

export type HelpTerm =
  | DsFormHelp
  | DsDecisionHelp
  | I3IepHelp
  | IepPlaafpHelp
  | IepGoalHelp
  | IepServiceHelp
  | FiieConsentHelp
  | EvaluationScHelp
  | EvaluationAdditionalFactorsHelp
  | EvalAssessmentDetailsHelp
  | FbaAssessmentDetailsHelp
  | FbaSummaryHelp
  | BipStrategiesHelp
  | BipImplementationHelp
  | ReferralSourceInfoHelp
  | ReferralReasonHelp
  | IntakeNutritionGrowthHelp
  | IntakeDentalVisionHearingHelp
  | IntakeHavingEnoughHelp
  | InsuranceWaiverServices;

export interface HelpText {
  question?: string;
  answer?: string | any;
}

export enum HelpSection {
  DsForm = 'Disability Suspected',
  IepPlaafp = 'IEP PLAAFP',
  IepProfile = 'IEP Profile',
  IepGoal = 'IEP Goals',
  IepService = 'IEP Services+',
  I3 = 'I3',
  FiieConsent = 'FIIE Consent',
  EvaluationSc = 'Strengths and Concerns',
  EvaluationAf = 'Additional Factors',
  FbaAssessmentDetails = 'FBA Assessment Details',
  FbaSummary = 'FBA Summary',
  BipStrategies = 'BIP Strategies',
  BipImplementation = 'BIP Implementation',
  Referral = 'Referral',
  Intake = 'Intake',
  Insurance = 'Insurance',
  EvalAssessmentDetails = 'Evaluation/Assessment Details',
}
