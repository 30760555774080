import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AppEvents } from '../../app-events';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private loaderCount = 0;
  private showLoading = new Subject<boolean>();
  private ignoredRequestUrlMatchers: RegExp[] = [];

  showLoading$ = this.showLoading.asObservable();

  constructor() {}

  init() {
    AppEvents.beforeRequest.subscribe((request: HttpRequest<any>): void => {
      if (this.shouldIgnore(request.url)) {
        return;
      }
      this.incrementLoading();
    });
    AppEvents.afterRequest.subscribe((request: HttpRequest<any>): void => {
      if (this.shouldIgnore(request.url)) {
        return;
      }
      this.decrementLoading();
    });
  }

  registerIgnoredRequestUrlMatcher(exp: RegExp) {
    this.ignoredRequestUrlMatchers.push(exp);
  }

  decrementLoading() {
    if (this.loaderCount === 0) {
      return;
    }
    this.loaderCount--;
    this.showLoading.next(this.loaderCount !== 0);
  }

  incrementLoading() {
    this.loaderCount++;
    this.showLoading.next(this.loaderCount !== 0);
  }

  private shouldIgnore(url: string) {
    if (!!this.ignoredRequestUrlMatchers.find((p) => p.test(url))) {
      return true;
    }
    return false;
  }
}
