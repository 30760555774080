import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { QuantifiableTieredMilestone } from 'src/app/shared/components/quantifiable-data/quantifiable-data';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { DateToUtcPipe } from 'src/app/shared/pipes/date-transform.pipe';

@Component({
  selector: 'app-iep-milestones',
  templateUrl: './iep-milestones.component.html',
  styleUrls: ['./iep-milestones.component.scss'],
})
export class IepMilestonesComponent implements OnInit, OnChanges {
  @Input() tieredMilestones: QuantifiableTieredMilestone[];
  @Input() secondary: boolean;
  @Output() updated = new EventEmitter<QuantifiableTieredMilestone[]>();
  @ViewChild(FormGroupDirective) formRef: FormGroupDirective;
  @Input() unitOfMeasurement;
  @Input() priorUnitOfMeasurement;
  @Input() amendmentId: any;
  @Input() amendmentFinalized: boolean;
  @Input() lastFinalizedDate: Date;
  shortDateFormat = shortDateFormat;

  constructor(private fb: FormBuilder, private dialog: MatDialog) {}

  formGroup: FormGroup;

  tieredMilestoneDisplayedColumns = ['actions', 'number', 'target', 'endDate'];
  dataSource = new MatTableDataSource<QuantifiableTieredMilestone>();

  ratingLevels: QuantifiableTieredMilestone[] = [];
  model: QuantifiableTieredMilestone;

  get milestoneNumber() {
    return this.tieredMilestones.filter((x) => x.secondary === this.secondary)?.length;
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      // eslint-disable-next-line id-blacklist
      number: [null],
      target: [null, { validators: [Validators.min(0), Validators.required] }],
      endDate: [null],
      secondary: [null],
    });
    this.dataSource.data = this.tieredMilestones?.filter((x) => x.secondary === this.secondary);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tieredMilestones?.currentValue !== changes.tieredMilestones?.previousValue) {
      this.dataSource.data = changes.tieredMilestones.currentValue.sort((a, b) => (a.number > b.number ? 1 : -1));
      this.tieredMilestones = changes.tieredMilestones?.currentValue;

      this.dataSource.data = this.dataSource.data.filter((x) => x.secondary === this.secondary);
    }
  }

  getMilestoneTarget(element, unitOfMeasurement) {
    return `${element?.target ? element.target : ''} ${unitOfMeasurement ? unitOfMeasurement : ''}`;
  }

  onUpdateModel() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      const modelIdx = this.tieredMilestones.indexOf(this.model);

      this.formGroup.controls.endDate.setValue(new DateToUtcPipe().transform(new Date(this.formGroup.value.endDate)));

      // Adding
      if (modelIdx < 0) {
        // eslint-disable-next-line id-blacklist
        this.formGroup.value.number = this.milestoneNumber + 1;
        this.formGroup.value.secondary = this.secondary;
        this.tieredMilestones.push(this.formGroup.value);
      }
      // Updating
      else {
        this.tieredMilestones[modelIdx].endDate = this.formGroup.value.endDate;
        this.tieredMilestones[modelIdx].target = this.formGroup.value.target;
        this.tieredMilestones[modelIdx].secondary = this.secondary;
      }
      this.tieredMilestones = [...this.tieredMilestones]; // To show up on the table as a change
      this.resetForm();
      this.formRef.resetForm();
      this.updated.emit(this.tieredMilestones);
    }
  }

  onEdit(is: QuantifiableTieredMilestone) {
    this.model = is;
    this.initializeForm();
  }

  onDelete(is: QuantifiableTieredMilestone) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        subQuestion: 'Clicking Yes will remove the rating.',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const modelIdx = this.tieredMilestones.indexOf(is);
        this.tieredMilestones = [...this.tieredMilestones.slice(0, modelIdx), ...this.tieredMilestones.slice(modelIdx + 1)];
        this.updated.emit(this.tieredMilestones);
      }
    });
  }

  private resetForm() {
    this.model = null;
    this.formGroup.controls.endDate.setValue(null);
    this.formGroup.controls.target.setValue(null);
  }

  private initializeForm() {
    this.formGroup.reset(this.model);
  }
}
