<h3 class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>View More</h3>

<div class="mat-dialog-content py-2">
  <mat-card *ngIf="activitySupport">
    <ul class="list">
      <li class="list__item" *ngIf="activitySupport.includesTrialPlacement">
        <h3 class="text-primary mt-0 mb-2">Included in Trial Placement</h3>
      </li>
      <li class="list__item">
        <h3 class="text-primary mt-0 mb-2">Description</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="activitySupport.amendmentEndDate"
          [priorVersionId]="activitySupport.priorVersionId"
          [isActive]="activitySupport.isActive"
          [changedVersionText]="activitySupport.description"
          [currentVersionText]="activitySupport?.priorVersion ? activitySupport?.priorVersion?.description : activitySupport.description"
          [changedPrefixText]="activitySupport?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item">
        <h3 class="text-primary mt-0 mb-2">Frequency</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="activitySupport.amendmentEndDate"
          [priorVersionId]="activitySupport.priorVersionId"
          [isActive]="activitySupport.isActive"
          [changedVersionText]="activitySupport.frequencyPeriod"
          [currentVersionText]="
            activitySupport?.priorVersion ? activitySupport?.priorVersion?.frequencyPeriod : activitySupport.frequencyPeriod
          "
          [changedPrefixText]="activitySupport?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item" *ngIf="activitySupport.otherProvider">
        <h3 class="text-primary mt-0 mb-2">Provider Name</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="activitySupport.amendmentEndDate"
          [priorVersionId]="activitySupport.priorVersionId"
          [isActive]="activitySupport.isActive"
          [changedVersionText]="activitySupport.otherProviderName"
          [currentVersionText]="
            activitySupport?.priorVersion ? activitySupport.priorVersion?.otherProviderName : activitySupport.otherProviderName
          "
          [changedPrefixText]="activitySupport?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>

        <h3 class="text-primary mt-0 mb-2">Provider Agency</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="activitySupport.amendmentEndDate"
          [priorVersionId]="activitySupport.priorVersionId"
          [isActive]="activitySupport.isActive"
          [changedVersionText]="activitySupport.otherProviderAgency"
          [currentVersionText]="
            activitySupport?.priorVersion ? activitySupport.priorVersion?.otherProviderAgency : activitySupport.otherProviderAgency
          "
          [changedPrefixText]="activitySupport?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>

        <h3 class="text-primary mt-0 mb-2">Provider Role</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="activitySupport.amendmentEndDate"
          [priorVersionId]="activitySupport.priorVersionId"
          [isActive]="activitySupport.isActive"
          [changedVersionText]="activitySupport.otherProviderRole"
          [currentVersionText]="
            activitySupport?.priorVersion ? activitySupport.priorVersion?.otherProviderRole : activitySupport.otherProviderRole
          "
          [changedPrefixText]="activitySupport?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item" *ngIf="activitySupport.providers">
        <h3 class="text-primary mt-0 mb-2">Providers(s)</h3>
        <app-compare-amendment-output
          [amendmentId]="data.amendmentId"
          [amendmentFinalized]="data.amendmentIsFinalized"
          [amendmentDate]="data.lastFinalizedDate"
          [amendmentEndReasonValue]="activitySupport.amendmentEndDate"
          [priorVersionId]="activitySupport.priorVersionId"
          [isActive]="activitySupport.isActive"
          [changedVersionText]="getProviders(activitySupport.providers)"
          [currentVersionText]="
            activitySupport?.priorVersion ? getProviders(activitySupport.priorVersion?.providers) : getProviders(activitySupport.providers)
          "
          [changedPrefixText]="activitySupport?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
    </ul>
  </mat-card>
</div>

<div class="mat-dialog-actions justify-content-end">
  <button mat-raised-button aria-label="Delete Icon" (click)="onClose()" cdkFocusInitial tabindex="0">Close</button>
</div>
