<div class="display-flex align-items-center justify-content-between flex-wrap">
  <div class="flex-1 mr-2">
    <app-help-directional-text>
      <p>
        Click on the add new assessment button to document all related transition assessments related to living, learning, and working.
        Include assessments conducted by outside partner agencies such as IVRS and other IEP partners, and select the postsecondary
        expectation area(s) applicable to this assessment.
      </p>
    </app-help-directional-text>
  </div>
</div>

<div>
  <form [formGroup]="formGroup">
    <app-date-picker label="Date of Assessment" class="w-100" controlName="dateOfAssessment" [max]="today"></app-date-picker>

    <app-documentation-upload
      [documents]="documents"
      [baseUrl]="baseUrl"
      [activeCall]="activeCall"
      (upload)="onUpload($event)"
      (deleteDocument)="onDeleteDocument($event)"
    ></app-documentation-upload>

    <div class="row">
      <div class="col-md-12 col-xl-6">
        <app-textarea
          formControlName="assessment"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.secondaryTransition.transitionAssessment.assessment"
          label="Name of Assessment"
          [rows]="4"
          [maxLength]="10000"
        ></app-textarea>
      </div>
      <div class="col-md-12 col-xl-6">
        <app-textarea
          formControlName="notes"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.secondaryTransition.transitionAssessment.notes"
          label="Summary of Assessment Results"
          [rows]="4"
          [maxLength]="10000"
        ></app-textarea>
      </div>
    </div>

    <div class="display-flex align-items-center justify-content-between flex-wrap">
      <div class="display-flex areas-container">
        <mat-button-toggle-group
          [multiple]="true"
          [ngClass]="{
            asterisk_input: formGroup.hasError('atLeastOneRequired')
          }"
          aria-labelledby="Select on option"
        >
          <mat-button-toggle
            (change)="toggleEnvironment($event)"
            [checked]="formGroup.get('living').value"
            value="living"
            aria-labelledby="Living"
          >
            Living
          </mat-button-toggle>
          <mat-button-toggle
            (change)="toggleEnvironment($event)"
            [checked]="formGroup.get('learning').value"
            value="learning"
            aria-labelledby="Learning"
          >
            Learning
          </mat-button-toggle>
          <mat-button-toggle
            (change)="toggleEnvironment($event)"
            [checked]="formGroup.get('working').value"
            value="working"
            aria-labelledby="Working"
          >
            Working
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="display-flex justify-content-end">
        <button *ngIf="isEditing" mat-raised-button aria-label="Cancel Button" type="button" class="mr-2" (click)="onCancel()" tabindex="0">
          Cancel
        </button>
        <button
          mat-raised-button
          aria-label="Save Assessment"
          type="button"
          color="primary"
          class="mr-2"
          [disabled]="formGroup.invalid"
          (click)="onSubmit()"
          [disableAfterBusy]="formGroup.invalid"
          [isBusy]="activeCall"
          tabindex="0"
        >
          Save Assessment
        </button>
      </div>
    </div>
  </form>
</div>

<ng-container *ngIf="dataSource?.data?.length > 0">
  <div class="table-overflow app-table">
    <table mat-table [dataSource]="dataSource" matSort class="w-100">
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="text-right" class="mat-column-actions--more">Actions</th>
        <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
          <ng-container *ngIf="element.id && !isEditing && atLeastOneMenuOption(element)">
            <button aria-label="View Icon" color="primary" mat-icon-button (click)="onViewMore(element)" tabindex="0">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
            <button aria-label="Edit Icon" color="primary" mat-icon-button *ngIf="!readonly" (click)="onEdit(element, true)" tabindex="0">
              <mat-icon>edit</mat-icon>
            </button>
            <button
              color="warn"
              mat-icon-button
              aria-label="Clear Icon"
              *ngIf="!readonly && !element.priorVersionId"
              (click)="onDelete(element)"
              tabindex="0"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="dateOfAssessment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dateOfAssessment | dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="assessment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name of Assessment</th>
        <td class="white-space-pre-line" mat-cell *matCellDef="let element">
          {{ element.assessment | truncate : 150 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="areas">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Postsecondary Area(s)</th>
        <td mat-cell *matCellDef="let element">
          {{ getAssessmentAreas(element) | truncate : 150 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="attachments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Attachments</th>
        <td mat-cell *matCellDef="let element">
          <span *ngFor="let doc of element.documents; let last = last">
            <a target="_blank" [href]="doc.url" tabindex="0" role="link" attr.aria-label="{{ doc.title | truncate : 150 }}">
              {{ doc.title | truncate : 150 }}
            </a>
            <span *ngIf="!last">, </span>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Summary of Assessment Results</th>
        <td class="white-space-pre-line" mat-cell *matCellDef="let element">
          {{ element.notes | truncate : 150 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <i>No results...</i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr
        mat-footer-row
        *matFooterRowDef="dataSource?.data?.length === 0 ? ['noResults'] : []"
        [hidden]="dataSource?.data?.length !== 0"
        class="example-second-footer-row"
      ></tr>
    </table>
    <mat-paginator [dataSource]="dataSource"></mat-paginator>
  </div>
</ng-container>

<ng-container *ngIf="!isFirstSecondaryTransition">
  <mat-slide-toggle class="mt-2" (change)="onToggleHistorical($event)">Display all previous transition assessments</mat-slide-toggle>
</ng-container>
