import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { ConsentModule } from '../consent/consent.module';
import { AppMatModule } from '../shared/app-mat.module';
import { SharedModule } from '../shared/shared.module';
import { IfspHeaderComponent } from './ifsp-header/ifsp-header.component';
import { IfspNavComponent } from './ifsp-header/ifsp-nav/ifsp-nav.component';
import { IfspRoutingModule } from './ifsp-routing.module';
import { IfspComponent } from './ifsp.component';
import { InterimPlanModalComponent } from './interim-plan-modal/interim-plan-modal.component';
import { IfspModificationModalComponent } from './modals/ifsp-modification-modal/ifsp-modification-modal.component';
import { IfspChangeProviderComponent } from './shared/components/ifsp-change-provider/ifsp-change-provider.component';
import { IfspPeriodicPollComponent } from './shared/components/ifsp-periodic-poll/ifsp-periodic-poll.component';
import { IfspOutcomeInfoComponent } from './shared/ifsp-outcome-info/ifsp-outcome-info.component';
import { IfspOutcomeViewMoreComponent } from './shared/ifsp-outcome-view-more/ifsp-outcome-view-more.component';
import { IfspRemovalComponent } from './shared/ifsp-removal/ifsp-removal.component';
import { IfspServiceInfoComponent } from './shared/ifsp-service-info/ifsp-service-info.component';
import { IfspServiceViewMoreComponent } from './shared/ifsp-service-view-more/ifsp-service-view-more.component';
import { IfspAdditionalInfoComponent } from './steps/ifsp-additional-info/ifsp-additional-info.component';
import { IfspChildFamilyOutcomesComponent } from './steps/ifsp-child-family-outcomes/ifsp-child-family-outcomes.component';
import { OutcomeCriteriaViewComponent } from './steps/ifsp-child-family-outcomes/outcome-criteria-view/outcome-criteria-view.component';
import { IfspDetailsModificationSummaryComponent } from './steps/ifsp-details/components/ifsp-details-modification-summary/ifsp-details-modification-summary.component';
import { IfspDetailsOutcomesComponent } from './steps/ifsp-details/components/ifsp-details-outcomes/ifsp-details-outcomes.component';
import { IfspDetailsServicesComponent } from './steps/ifsp-details/components/ifsp-details-services/ifsp-details-services.component';
import { IfspDetailsComponent } from './steps/ifsp-details/ifsp-details.component';
import { IfspCompletionComponent } from './steps/ifsp-profile/ifsp-completion/ifsp-completion.component';
import { IfspConsentsComponent } from './steps/ifsp-profile/ifsp-consents/ifsp-consents.component';
import { IfspMeetingComponent } from './steps/ifsp-profile/ifsp-meeting/ifsp-meeting.component';
import { IfspOutcomesListComponent } from './steps/ifsp-profile/ifsp-outcomes-list/ifsp-outcomes-list.component';
import { IfspProfileComponent } from './steps/ifsp-profile/ifsp-profile.component';
import { IfspOutcomesServicesPrintComponent } from './steps/ifsp-profile/ifsp-reports/ifsp-outcomes-services-print/ifsp-outcomes-services-print.component';
import { IfspReportsComponent } from './steps/ifsp-profile/ifsp-reports/ifsp-reports.component';
import { IfspServiceListComponent } from './steps/ifsp-profile/ifsp-service-list/ifsp-service-list.component';
import { IfspSettingComponent } from './steps/ifsp-profile/ifsp-setting/ifsp-setting.component';
import { LateIfspReasonComponent } from './steps/ifsp-profile/late-ifsp-reason/late-ifsp-reason.component';
import { PastIfspsComponent } from './steps/ifsp-profile/past-ifsps/past-ifsps.component';
import { IfspServiceViewComponent } from './steps/ifsp-services/ifsp-service-view/ifsp-service-view.component';
import { IfspServicesComponent } from './steps/ifsp-services/ifsp-services.component';
import { PlodOutputComponent } from './steps/plod/plod-output/plod-output.component';
import { PlodComponent } from './steps/plod/plod.component';

@NgModule({
  declarations: [
    IfspComponent,
    IfspHeaderComponent,
    IfspNavComponent,
    PlodComponent,
    IfspChildFamilyOutcomesComponent,
    IfspProfileComponent,
    IfspCompletionComponent,
    IfspServiceListComponent,
    IfspMeetingComponent,
    IfspSettingComponent,
    IfspReportsComponent,
    PastIfspsComponent,
    IfspServicesComponent,
    OutcomeCriteriaViewComponent,
    IfspServiceViewComponent,
    PlodOutputComponent,
    IfspOutcomesListComponent,
    IfspConsentsComponent,
    IfspOutcomesServicesPrintComponent,
    IfspDetailsComponent,
    IfspDetailsOutcomesComponent,
    IfspDetailsServicesComponent,
    IfspDetailsModificationSummaryComponent,
    IfspAdditionalInfoComponent,
    IfspRemovalComponent,
    IfspServiceInfoComponent,
    IfspOutcomeInfoComponent,
    InterimPlanModalComponent,
    IfspModificationModalComponent,
    IfspOutcomeViewMoreComponent,
    IfspServiceViewMoreComponent,
    LateIfspReasonComponent,
    IfspPeriodicPollComponent,
    IfspChangeProviderComponent,
  ],
  imports: [CommonModule, IfspRoutingModule, SharedModule, AppMatModule, ReactiveFormsModule, FormsModule, ChartsModule, ConsentModule],
  exports: [IfspServiceListComponent, IfspDetailsServicesComponent, IfspOutcomesListComponent, IfspServiceListComponent],
})
export class IfspModule {}
