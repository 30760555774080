<section class="registration-form">
  <mat-card *ngIf="invitationVerifyDto">
    <mat-card-title><h2 class="confirm-title">ACHIEVE Family Portal</h2></mat-card-title>
    <mat-card-subtitle>Confirm Invitation</mat-card-subtitle>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <ng-container class="child-verification-form" [formGroup]="formGroup">
            <app-text-field label="Your Password" formControlName="password" [inputType]="'password'"></app-text-field>

            <ng-container *ngIf="!invitationVerifyDto.hasPassword">
              <app-text-field label="Confirm Your Password" formControlName="confirmedPassword" [inputType]="'password'"></app-text-field>

              <mat-error *ngIf="formGroup.touched && formGroup.hasError('passwordMismatch')"
                >Password and Confirm Password do not match.</mat-error
              >

              <p>
                <b>Password requirements</b><br />
                <small>
                  A minimum of 8 characters <br />
                  Must have at least one uppercase letter <br />
                  Must have at least one lowercase letter <br />
                  Must have a number <br />
                  Must have any one of these special characters (#?!@$%^&*-)
                </small>
              </p>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="row">
        <div class="col">
          <button
            mat-flat-button
            aria-label="Complete Button"
            class="buttons mt-3"
            color="primary"
            tabindex="0"
            (click)="onRegister()"
            [isBusy]="isBusy"
            [disabled]="!formGroup.valid"
          >
            Submit
          </button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card>
</section>
