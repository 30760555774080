import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LearnerSurveyReportItem } from '../survey-reporting-dashboard.component';

@Component({
  selector: 'app-survey-reporting-table',
  templateUrl: './survey-reporting-table.component.html',
  styleUrls: ['./survey-reporting-table.component.scss'],
})
export class SurveyReportingTableComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @Input() data: LearnerSurveyReportItem[] = [];
  dataSource = new MatTableDataSource<LearnerSurveyReportItem>();
  displayedColumns = ['label', 'sent', 'responses', 'responseRate', 'percentOfAllResponses', 'comparisonToStatePopulation', 'difference'];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.dataSource.data = this.data;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
}
