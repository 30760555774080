<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Summary</h3>

<div mat-dialog-content class="py-3">
  <app-iep-ssaa-summary [iepId]="iepId" [caseId]="caseId" [isPK]="isPK"></app-iep-ssaa-summary>
</div>
<div mat-dialog-actions class="display-flex justify-content-end align-items-center">
  <button mat-raised-button aria-label="Delete Icon" class="background-color--muted" cdkFocusInitial [mat-dialog-close] tabindex="0">
    Close
  </button>
</div>
