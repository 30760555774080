<div class="table-overflow mb-2">
  <table mat-table class="w-100" matSort [dataSource]="dataSource">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more"></th>
      <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
        <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewMore(element)" tabindex="0">
          <mat-icon>remove_red_eye</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.date | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Phase Line</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.noteOther ? element.noteOther : element.label) | truncate : 150 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['actions', 'date', 'note']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['actions', 'date', 'note']"></tr>
    <tr [hidden]="dataSource.data.length > 0" mat-footer-row *matFooterRowDef="dataSource.data.length === 0 ? ['noResults'] : []"></tr>
  </table>

  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
