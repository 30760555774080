<div class="display-flex align-items-center justify-content-between mb-2">
  <div>
    <app-help-directional-text *ngIf="!rollCallDate">
      <p>Consent for initial IEP services can only be added after first IEP meeting roll call</p>
    </app-help-directional-text>
  </div>
  <button
    *ngIf="!isModification && showCreateInitialIepServicesConsent"
    mat-flat-button
    aria-label="Add Consent for Initial IEP Services"
    color="accent"
    (click)="createConsentForSpecialEducationRelatedServices()"
    [disabled]="!rollCallDate"
    tabindex="0"
  >
    Add Consent for Initial IEP Services
  </button>
</div>
<section [ngClass]="{ 'mt-2': !showCreateInitialIepServicesConsent }">
  <app-family-consent
    [isCaseOwner]="isCaseOwner"
    [learnerId]="caseSummary.learnerId"
    [caseId]="caseSummary.id"
    [types]="types"
    (consentFormsLoaded)="onConsentFormsLoaded($event)"
    [canAddNew]="false"
    [psgProvided]="psgProvided"
  ></app-family-consent>
</section>
