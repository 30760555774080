import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { OnInit } from '@angular/core/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { KeyValuePair } from '../../../../shared/models/key-value-pair';
import { IepGoal } from '../../../models/iep';
import { ClpService } from '../../../services/clp.service';
import { GoalPlanService } from '../../../services/goal-plan.service';
import { IepGoalsViewMoreComponent } from '../../../steps/iep-goals/iep-goals-view-more/iep-goals-view-more.component';

@Component({
  selector: 'app-goal-plan',
  templateUrl: 'goal-plan.component.html',
  styleUrls: ['goal-plan.component.scss'],
  providers: [DatePipe],
})
export class GoalPlanComponent implements OnInit {
  @Input() attendingDistrictId: string;
  frequencyOptions: KeyValuePair[];

  goal: IepGoal;
  @Input() goals: IepGoal[];
  @Input() readOnly: boolean;
  @Input() form: FormGroup;

  constructor(
    private readonly clpService: ClpService,
    private readonly goalPlanService: GoalPlanService,
    private readonly dialog: MatDialog,
    private readonly datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.frequencyOptions = this.clpService.frequencyOptions;
    this.goal = this.goals.find((x) => x.id === this.form.value.iepGoalId);
  }

  checkDescriptionVisibility(path: string): boolean {
    return this.form.get(`${path}.action`).value && this.form.get(`${path}.action`).value !== 'PerformOriginal';
  }

  async showGoalInfo(): Promise<void> {
    const modalData = await this.goalPlanService.getModalData(this.goal, this.attendingDistrictId, this.datePipe);
    this.dialog.open(IepGoalsViewMoreComponent, {
      width: '728px',
      data: {
        goal: modalData,
        amendment: {
          amendmentId: null,
          amendmentIsFinalized: null,
          lastFinalizedDate: null,
        },
      },
    });
  }

  onActionSelectionChange(value: string, path: string): void {
    const descriptionControl = this.form.get(`${path}.description`);
    if (value === null || value === 'PerformOriginal') {
      descriptionControl.setValue(null);
    }
  }
}
