import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest } from 'rxjs';
import {
  DomainAssignModalComponent,
  DomainAssignModalData,
} from 'src/app/evaluation/modals/domain-assign-modal/domain-assign-modal.component';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { AuthService } from '../../../../auth/auth.service';
import { IntakeType } from '../../../../shared/models/case';
import { EvaluationDomain } from '../../../../shared/models/evaluation-domain';
import { KeyValuePair } from '../../../../shared/models/key-value-pair';
import { LookupBase } from '../../../../shared/models/lookup-base';
import { EvaluationDomainService } from '../../../../shared/services/evaluation-domains/evaluation-domain.service';
import { MemoryStorageService } from '../../../../shared/services/memory-storage/memory-storage.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { Evaluation } from '../../../models/evaluation';
import { EvaluationAssignment } from '../../../models/evaluation-assignment';
import { EvaluationUser } from '../../../models/evaluation-user';
import { EvaluationAssignmentService } from '../../../services/evaluation-assignment.service';
import { EvaluationProxyService } from '../../../services/evaluation-proxy.service';
import { EvaluationStatusService } from '../../../services/evaluation-status.service';
import { RemoveAssignmentComponent } from '../evaluation-assignments-table/remove-assignment/remove-assignment.component';
const noFutureDatesValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  return control.value > new Date() ? { noFutureDates: true } : null;
};

@Component({
  selector: 'app-evaluation-assignments-table',
  templateUrl: './evaluation-assignments-table.component.html',
  styleUrls: ['./evaluation-assignments-table.component.scss'],
})
export class EvaluationAssignmentsTableComponent implements OnInit {
  evaluation: Evaluation;
  assignments: EvaluationAssignment[] = [];
  teamMembers: EvaluationUser[];
  domains: EvaluationDomain[] = [];
  domainOptions: KeyValuePair[] = [];
  teamMemberOptions: KeyValuePair[];
  statuesOptions: KeyValuePair[] = [];
  isPartB = true;
  today = new Date();
  formGroup = this.formBuilder.group({
    domain: new FormControl('', Validators.required),
    area: new FormControl(null),
    teamMember: new FormControl(''),
    status: new FormControl(''),
    date: new FormControl(''),
    startTime: new FormControl(''),
  });
  isEditing = false;
  addingNew = false;

  // For UI purposes
  me = '';
  myUserName = '';

  displayedColumns: string[] = ['quickActions', 'domain', 'area', 'teamMember', 'startTime', 'status'];
  dataSource = new MatTableDataSource<EvaluationAssignmentRow>();
  partCAssignMyself: FormGroup;
  startTimeOptions: KeyValuePair[];
  shortDateFormat = shortDateFormat;
  academicDomainId: string;
  canEditAssignment = false;

  get ownerOrSuperAdmin() {
    return this.evaluation?.owner.id === this.me || this.authService.isSuperAdmin;
  }

  constructor(
    private dialog: MatDialog,
    private readonly assignmentService: EvaluationAssignmentService,
    private readonly storage: MemoryStorageService,
    private readonly evaluationDomainService: EvaluationDomainService,
    private readonly evaluationStatusService: EvaluationStatusService,
    private readonly authService: AuthService,
    private readonly formBuilder: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly evaluationProxyService: EvaluationProxyService
  ) {}

  ngOnInit(): void {
    this.evaluation = this.storage.getKey('currentEvaluation', true);
    this.me = this.authService.user.id;
    this.myUserName = this.authService.user.fullName;
    this.isPartB = this.evaluation.intakeType === IntakeType.PartB;

    this.evaluationProxyService.teamChange.subscribe((teamMembers) => {
      this.teamMembers = teamMembers;
      this.teamMemberOptions = this.teamMembers.map((x) => new KeyValuePair(x.userId, x.name));

      this.assignmentService.get(this.evaluation.id).subscribe((assignments) => {
        this.assignments = assignments;
        this.dataSource.data = this.initializeAssignmentRows(this.assignments);
      });
      this.canEditAssignment = true;
    });

    if (!this.isPartB) {
      // hide area
      this.displayedColumns.splice(2, 1);
    }

    this.startTimeOptions = [new KeyValuePair('Morning'), new KeyValuePair('Afternoon')];

    combineLatest([
      this.evaluationDomainService.get(),
      this.assignmentService.get(this.evaluation.id),
      this.evaluationStatusService.get(),
    ]).subscribe(([domains, assignments, statues]) => {
      this.domains = domains.filter((x) => x.isPartB === this.isPartB);
      this.academicDomainId = this.domains.find((d) => d.label === 'Academic')?.id;
      this.assignments = assignments;
      this.dataSource.data = this.initializeAssignmentRows(this.assignments);
      this.dataSource.sortingDataAccessor = (assignment, columnId) => {
        switch (columnId) {
          case 'domain':
            return assignment.domainArea?.label;
          case 'teamMember':
            return assignment.teamMemberName;
          case 'status':
            return assignment.status.label;
          default:
            return assignment[columnId];
        }
      };

      this.domainOptions = this.domains
        .map((x) => new KeyValuePair(x.id, x.label))
        .sort((a, b) => {
          const aLabel = a.value;
          const bLabel = b.value;
          if (aLabel < bLabel) {
            return -1;
          }
          return aLabel > bLabel ? 1 : 0;
        });
      this.statuesOptions = statues.map((x) => new KeyValuePair(x.id, x.label));
    });

    this.partCAssignMyself = this.formBuilder.group({
      domain: new FormControl('', Validators.required),
    });
  }

  statusSelected(selectedOption) {
    const completedRow = this.statuesOptions.find((option) => option.value === 'Complete');
    const nameControl = this.formGroup.get('date');
    if (selectedOption === completedRow.key) {
      nameControl.setValidators([noFutureDatesValidator]);
    } else {
      nameControl.setValidators([]);
    }
    nameControl.updateValueAndValidity();
  }

  onOpenAssignEvaluator() {
    const modalRef = this.dialog.open(DomainAssignModalComponent, {
      width: '728px',
      data: {
        teamMembers: this.teamMembers,
        domains: this.domains,
        statuses: this.statuesOptions,
        assignments: (this.dataSource.data as EvaluationAssignment[]).filter((x) => x.id),
      } as DomainAssignModalData,
    });

    modalRef.afterClosed().subscribe((assignments: EvaluationAssignment[]) => {
      if (!assignments) {
        return;
      }

      const assignmentsToAdd: EvaluationAssignment[] = [];
      const assignmentsToUpdate: EvaluationAssignment[] = [];
      assignments.forEach((assignment) => {
        assignment.domainArea = this.assignments.find((x) => x.domainAreaId === assignment.domainAreaId).domainArea;
        if (!assignment.id) {
          assignmentsToAdd.push(assignment);
        }
        if (!assignment.userId) {
          assignmentsToUpdate.push(assignment);
        }
      });

      this.assignmentService.add(this.evaluation.id, [...assignmentsToAdd, ...assignmentsToUpdate]).subscribe((res) => {
        this.assignments = res;
        this.dataSource.data = this.initializeAssignmentRows(res);
      });
    });
  }

  removeAssignment(assignment: EvaluationAssignment) {
    const dialogRef = this.dialog.open(RemoveAssignmentComponent, {
      width: '500px',
      data: 'clear',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response?.canRemove) {
        assignment.teamMemberName = '';
        assignment.teamMemberEmail = '';
        assignment.userId = '';
        assignment.startTime = '';
        assignment.date = null;
        this.saveAssignment(assignment);
      }
    });
  }

  removeDomainAssignment(assignment: EvaluationAssignment) {
    const dialogRef = this.dialog.open(RemoveAssignmentComponent, {
      width: '500px',
      data: 'delete',
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response?.canRemove) {
        this.assignmentService.remove(this.evaluation.id, assignment).subscribe(
          (assignments) => {
            this.assignments = assignments;
            this.dataSource.data = this.initializeAssignmentRows(assignments);
            this.evaluationProxyService.assignmentChange.next([
              this.formGroup.controls.teamMember.dirty ? 'teamMember' : '', // todo might want to change this to array
              assignments.filter((x) => x.id),
            ]);
          },
          (error) => {
            this.notificationService.error(error.error?.map((x) => x.description).join(' '));
          }
        );
      }
    });
  }

  private initializeAssignmentRows(assignments: EvaluationAssignment[] = []) {
    const assignmentRows = assignments.map((x: EvaluationAssignmentRow) => {
      x.isEditing = false;
      // Allow removal of a row if it's either NOT one of the Evaluation's
      // required domains or if there's more than one row with that Domain
      // TODO: evaluationStatus for Complete needs a robust lookup or enum
      if (this.isPartB && !this.evaluation.consentDomains.includes(x.domainArea.evaluationDomain.id) && x.status.label !== 'Complete') {
        x.canRemove = true;
      } else if (
        assignments.filter((a) => a.domainArea.evaluationDomain.id === x.domainArea.evaluationDomain.id).length > 1 &&
        x.status.label !== 'Complete'
      ) {
        x.canRemove = true;
      } else if (!this.isPartB && this.domains.find((d) => d.id === x.domainArea.evaluationDomain.id)?.isRequired === false) {
        x.canRemove = true;
      }
      return x as EvaluationAssignmentRow;
    });

    const assignedToMe = assignmentRows
      .filter((x) => x.userId === this.me)
      .sort((a, b) => a.domainArea.evaluationDomain.label.localeCompare(b.domainArea.evaluationDomain.label));
    const otherAssignments = assignmentRows
      .filter((x) => x.userId !== this.me)
      .sort((a, b) => a.domainArea.evaluationDomain.label.localeCompare(b.domainArea.evaluationDomain.label));
    return [...assignedToMe, ...otherAssignments];
  }

  private saveAssignment(assignment: EvaluationAssignment) {
    if (assignment.userId) {
      const teamMember = this.teamMembers.find((x) => x.userId === assignment.userId);
      assignment.teamMemberName = teamMember?.name;
      assignment.teamMemberEmail = teamMember?.email;
    }

    const evaluationDomain = this.domains.find((x) => x.id === assignment.domainArea.evaluationDomain.id);
    assignment.domainArea.evaluationDomain = {
      id: evaluationDomain.id,
      label: evaluationDomain.label,
    };

    let status = this.statuesOptions.find((x) => x.key === assignment.statusId);
    if (!status) {
      status = this.statuesOptions.find((option) => option.value === 'Pending');
    }

    assignment.statusId = status.key;
    if (!assignment.status) {
      assignment.status = {} as LookupBase;
    }
    assignment.status.id = status.key;
    assignment.status.label = status.value;
    const fieldChange = this.getChangedField();
    if (!assignment.id) {
      this.assignmentService.add(this.evaluation.id, null, assignment).subscribe({
        next: (assignments) => this.handleAddResponse(assignments, assignment),
        error: (error) => this.handleAddError(error.error),
      });
      return;
    }
    this.assignmentService.update(this.evaluation.id, assignment).subscribe({
      next: (assignments) => this.handleUpdateResponse(assignments, assignment, fieldChange),
      error: (error) => this.handleUpdateError(error.error),
    });
  }

  resetForm() {
    this.formGroup.get('domain').enable();
    this.formGroup.get('area').clearValidators();
    this.formGroup.reset();
    this.formGroup.updateValueAndValidity();
  }

  setEdit(assignment: EvaluationAssignmentRow, editing = true) {
    this.addingNew = false;
    this.isEditing = editing;
    if (assignment !== null) {
      assignment.isEditing = editing;
    }
    if (!editing) {
      this.resetForm();
      this.dataSource.data = this.initializeAssignmentRows(this.assignments);
      return;
    }
    this.formGroup.patchValue({
      domain: assignment.domainArea.evaluationDomain.id,
      area: assignment.domainArea.area,
      teamMember: assignment.userId,
      status: assignment.statusId,
      date: assignment.date,
      startTime: assignment.startTime,
    });
    const isConsentDomain =
      this.evaluation.consentDomains.includes(assignment.domainArea.evaluationDomain.id) ||
      this.domains.find((x) => !x.isPartB && x.isRequired && x.id === assignment.domainArea.evaluationDomain.id);
    const hasMultipleAssignments =
      this.assignments.filter((x) => x.domainArea.evaluationDomain.id === assignment.domainArea.evaluationDomain.id).length > 1;
    if (isConsentDomain && !hasMultipleAssignments) {
      this.formGroup.get('domain').disable();
    }
    if (this.formGroup.get('area').value !== '') {
      this.formGroup.get('area').setValidators(Validators.required);
    } else {
      this.formGroup.get('area').clearValidators();
      this.formGroup.get('area').setValue('');
    }
    this.formGroup.updateValueAndValidity();
  }

  onSave(assignment: EvaluationAssignmentRow) {
    if (this.formGroup.invalid) {
      if (this.formGroup.get('area').hasError('required')) {
        this.notificationService.error('Area may be modified but not removed from a Domain once it has been added.', 2000);
        return;
      }
      this.notificationService.error('Please check all fields. Domain is required before other fields can be modified.', 2000);
      return;
    }
    if (assignment === null) {
      assignment = {
        id: null,
        domainArea: {
          area: this.formGroup.get('area').value,
          evaluationDomain: {
            id: this.formGroup.get('domain').value,
          },
        },
        userId: this.formGroup.get('teamMember').value,
        statusId: this.formGroup.get('status').value,
        status: {},
      } as EvaluationAssignmentRow;
    } else {
      assignment.domainArea.area = this.formGroup.controls.area.value;
      assignment.domainArea.evaluationDomain.id = this.formGroup.controls.domain.value;
      assignment.userId = this.formGroup.controls.teamMember.value;
      assignment.statusId = this.formGroup.controls.status.value;
      assignment.startTime = this.formGroup.controls.startTime.value;
      assignment.date = this.formGroup.controls.date.value;
    }

    const isAssigned = this.assignments.find(
      (x) =>
        x.domainArea.evaluationDomain.id === assignment.domainArea.evaluationDomain.id &&
        x.domainArea.area === assignment.domainArea.area &&
        x.userId !== assignment.userId &&
        x.userId !== null
    );

    if (isAssigned && assignment.userId) {
      const assignedTo = this.teamMembers.find((x) => x.userId === isAssigned.userId);
      const domain = this.domains.find((x) => x.id === assignment.domainArea.evaluationDomain.id);
      const label = assignment.domainArea.area ? `${domain.label}: ${assignment.domainArea.area}` : domain.label;

      const question =
        this.evaluation.intakeType === IntakeType.PartC
          ? `${
              assignedTo.name ? assignedTo.name : 'Someone'
            } has already been assigned the ${label} developmental area.\n\nClick yes to proceed and save or no to return.`
          : `${assignedTo.name ? assignedTo.name : 'Someone'} has already been assigned the ${label} domain. Do you want to add ${
              this.myUserName
            } to ${label} as well?`;
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        data: { subQuestion: question },
      });

      dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.saveAssignment(assignment);
        }
      });
    } else {
      this.saveAssignment(assignment);
    }
  }

  atLeastOneMenuOption(assignment) {
    return (
      this.ownerOrSuperAdmin ||
      assignment.userId === this.me ||
      (this.ownerOrSuperAdmin && assignment.teamMemberName) ||
      (this.ownerOrSuperAdmin && assignment.canRemove)
    );
  }

  private getChangedField() {
    let fieldChange = ''; // todo might want to change this to array
    if (this.formGroup.controls.domain.dirty) {
      fieldChange = 'domain';
    }
    if (this.formGroup.controls.area.dirty) {
      fieldChange = 'area';
    }
    if (this.formGroup.controls.startTime.dirty) {
      fieldChange = 'startTime';
    }
    if (this.formGroup.controls.teamMember.dirty) {
      fieldChange = 'teamMember';
    }
    if (this.formGroup.controls.status.dirty) {
      fieldChange = 'status';
    }
    return fieldChange;
  }

  private handleAddResponse(assignments: EvaluationAssignment[], assignment: EvaluationAssignment) {
    this.assignments = assignments;
    this.dataSource.data = this.initializeAssignmentRows(this.assignments);
    this.notificationService.success('Saved');
    this.setEdit(assignment as EvaluationAssignmentRow, false);
    this.evaluationProxyService.assignmentChange.next([
      this.formGroup.controls.teamMember.dirty ? 'teamMember' : '', // todo might want to change this to array
      assignments.filter((x) => x.id),
    ]);
    this.resetForm();
    this.isEditing = false;
  }

  private handleAddError(error) {
    this.notificationService.error(error?.map((x) => x.description)?.join(' '));
  }

  private handleUpdateResponse(assignments: EvaluationAssignment[], assignment: EvaluationAssignment, fieldChange: string) {
    this.assignments = assignments;
    this.notificationService.success('Saved');
    this.setEdit(assignment as EvaluationAssignmentRow, false);
    this.evaluationProxyService.assignmentChange.next([fieldChange, this.dataSource.data.filter((x) => x.id)]);
    this.dataSource.data = this.initializeAssignmentRows(this.assignments);
    this.resetForm();
    this.isEditing = false;
  }

  private handleUpdateError(error) {
    this.notificationService.error(error?.map((x) => x.description)?.join(' '));
    this.assignments = error.assignments as EvaluationAssignment[];
  }
}

interface EvaluationAssignmentRow extends EvaluationAssignment {
  isEditing: boolean;
  canRemove: boolean;
}
