import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IncompleteDataReportItem } from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { SpinnerService } from '../../shared/services/spinner/spinner.service';
import { PseSummary } from '../iep-plaafp/secondary-transition/models/pse-summary';
import { IepActivitySupportDto } from '../models/iep-activity-support';
import { IepServiceDto } from '../models/iep-service';
import { IepESYConsiderationsDto, IepESYGoal } from './esy';

@Injectable({
  providedIn: 'root',
})
export class ESYService {
  public esyGoalsUpdated = new Subject<IepESYGoal[]>();
  esyGoalsUpdated$ = this.esyGoalsUpdated.asObservable();

  constructor(private readonly http: HttpClient, private spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp('^api/iep/([^/]+)/esy/goals'));
  }

  private baseUrl(id = '') {
    return `api/iep/${id}/esy`;
  }

  getSpecialFactors(iepId: string, amendmentId = '') {
    return this.http.get<IepESYConsiderationsDto>(`${this.baseUrl(iepId)}/${amendmentId}`);
  }

  startESYConsiderationsAmendment(id: string, iepId: string, amendmentId: string) {
    return this.http.put<IepESYConsiderationsDto>(`${this.baseUrl(iepId)}/esyConsiderations/${id}/amend/${amendmentId}`, null);
  }

  startESYGoalAmendment(id: string, iepId: string, amendmentId: string, amendDetails: boolean) {
    return this.http.put<any>(`${this.baseUrl(iepId)}/esyGoal/${id}/amend/${amendmentId}/${amendDetails}`, null);
  }

  updateESYConsiderations(model: IepESYConsiderationsDto) {
    return this.http.put<IepESYConsiderationsDto>(`${this.baseUrl(model.iepId)}`, model);
  }

  getESYGoals(iepId: string, amendmentId = '') {
    return this.http.get<IepESYGoal[]>(`${this.baseUrl(iepId)}/goals/${amendmentId}`);
  }

  getESYPse(iepId: string) {
    return this.http.get<PseSummary>(`${this.baseUrl(iepId)}/pse`);
  }

  updateESYGoals(iepId: string, model: IepESYGoal[], amendmentId = '') {
    return this.http.put<IepESYGoal[]>(amendmentId ? `${this.baseUrl(iepId)}/goals/${amendmentId}` : `${this.baseUrl(iepId)}/goals`, model);
  }

  addESYService(iepId: string, model: IepServiceDto) {
    return this.http.post<IepServiceDto>(`${this.baseUrl(iepId)}/service`, model);
  }

  updateESYService(iepId: string, model: IepServiceDto) {
    return this.http.put<IepServiceDto>(`${this.baseUrl(iepId)}/service`, model);
  }

  amendESYService(iepId: string, id: string, esyGoalId: string, amendmentId: string) {
    return this.http.put<any>(`${this.baseUrl(iepId)}/service/${id}/${esyGoalId}/amend/${amendmentId}`, null);
  }

  deleteESYService(iepId: string, serviceId: string) {
    return this.http.put<void>(`${this.baseUrl(iepId)}/service/${serviceId}`, null);
  }

  addESYSupport(iepId: string, model: IepActivitySupportDto) {
    return this.http.post<IepActivitySupportDto>(`${this.baseUrl(iepId)}/support`, model);
  }
  updateESYSupport(iepId: string, model: IepActivitySupportDto) {
    return this.http.put<IepActivitySupportDto>(`${this.baseUrl(iepId)}/support`, model);
  }

  amendESYSupport(iepId: string, id: string, esyGoalId: string, amendmentId: string) {
    return this.http.put<any>(`${this.baseUrl(iepId)}/support/${id}/${esyGoalId}/amend/${amendmentId}`, null);
  }

  deleteESYSupportActivity(iepId: string, activityId: string) {
    return this.http.put<void>(`${this.baseUrl(iepId)}/support/${activityId}`, null);
  }

  getESYIdr(iepId: string, amendmentId = '') {
    return this.http.get<IncompleteDataReportItem[]>(`${this.baseUrl(iepId)}/idr/${amendmentId}`);
  }
}
