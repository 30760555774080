<mat-card class="mt-4" *ngIf="evaluationDataSource">
  <div class="table-overflow">
    <table mat-table [dataSource]="evaluationDataSource" class="w-100">
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let member">{{ member?.fullName }}</td>
      </ng-container>

      <ng-container matColumnDef="jobTitle">
        <th mat-header-cell *matHeaderCellDef>Job Title</th>
        <td mat-cell *matCellDef="let member">{{ member?.jobTitle }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let member">
          <a href="mailto:{{ member.email }}" tabindex="0" aria-labelledby="Member Email">
            {{ member?.email }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="aea">
        <th mat-header-cell *matHeaderCellDef>AEA</th>
        <td mat-cell *matCellDef="let member">{{ member?.aea?.name }}</td>
      </ng-container>

      <ng-container matColumnDef="district">
        <th mat-header-cell *matHeaderCellDef>District</th>
        <td mat-cell *matCellDef="let member">{{ member?.district }}</td>
      </ng-container>

      <ng-container matColumnDef="primaryOffice">
        <!-- TODO This needs to be updated when we get building information -->
        <th mat-header-cell *matHeaderCellDef>Primary Office</th>
        <td mat-cell *matCellDef="let member">
          {{ member?.buildings[0]?.name }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="evaluationDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: evaluationDisplayedColumns"></tr>
    </table>
    <p *ngIf="!evaluationDataSource">No data yet...</p>
  </div>
</mat-card>
