<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Evaluation & Assessment Report</h3>
<div mat-dialog-content>
  <h4>Past Eligibility Decision Dates</h4>
  <ul>
    <li *ngFor="let date of eligibilityDates">
      {{ date.value | dateFormat : 'MM/dd/yyyy' }}
    </li>
  </ul>
  <hr class="input__divider" />
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col">
        <app-date-picker label="Start Date" controlName="startDate"></app-date-picker>
      </div>

      <div class="col">
        <app-date-picker label="End Date" controlName="endDate"></app-date-picker>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button aria-label="Cancel Button" class="mr-1" (click)="onCancel()" tabindex="0">Cancel</button>
  <button
    mat-raised-button
    aria-label="Generate Report Button"
    color="primary"
    class="mr-1"
    [isBusy]="isPrinting"
    [disabled]="formGroup.invalid"
    (click)="onGenerateReport()"
    tabindex="0"
  >
    Generate Report
  </button>
</div>
