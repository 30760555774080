<div *ngIf="!criterionCompleted && !outcomeEnded">
  <form [formGroup]="formGroup">
    <div class="row align-items-center">
      <div class="col-md-12 col-lg-6 col-xl-4">
        <app-date-picker label="Date" controlName="date" [min]="minDate" [max]="today"></app-date-picker>
      </div>
      <ng-container *ngIf="criterion.measurementType === 'numeric'">
        <div class="col-md-12 col-lg-6 col-xl-8">
          <div class="display-flex align-items-baseline">
            <app-number-field label="Current Value" formControlName="value" [min]="0" [idPrefix]="criterion.id"></app-number-field>
            <p class="my-0 mr-2">Target Value: {{ criterion.targetValue }}</p>
            <p class="my-0 mr-2">Baseline: {{ criterion.baseline }}</p>
          </div>
        </div>
      </ng-container>
      <div class="col-md-12 col-lg-6 col-xl-8">
        <app-checkbox-single
          formControlName="completed"
          [ngClass]="{
            asterisk_input: formGroup.get('completed').hasError('required')
          }"
          aria-labelledby="Completed"
          label="Completed"
        ></app-checkbox-single>
        <mat-error *ngIf="formGroup.get('completed').hasError('required') && formGroup.get('completed').touched"
          >Field is <strong>required</strong></mat-error
        >
      </div>
      <div class="col-md-12">
        <app-textarea label="Monitoring Notes" formControlName="note" [maxLength]="10000" [idPrefix]="criterion.id"></app-textarea>
      </div>
    </div>

    <ng-container *ngIf="criterion.measurementType === 'numeric'">
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-autocomplete
            [showAsterisk]="formGroup.get('phaseLineNote').hasError('required')"
            label="Phase Line"
            formControlName="phaseLineNote"
            [options]="phaseLineOptions"
            [idPrefix]="criterion.id"
          ></app-autocomplete>
        </div>
        <div class="col-md-12">
          <div *ngIf="isOtherPhaseLineSelected">
            <app-textarea label="Other" formControlName="phaseLineNoteOther" [maxLength]="10000" [idPrefix]="criterion.id"></app-textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-autocomplete
            [showAsterisk]="formGroup.get('commentNote').hasError('required')"
            label="Comment"
            formControlName="commentNote"
            [options]="commentOptions"
            [idPrefix]="criterion.id"
          ></app-autocomplete>
        </div>
        <div class="col-md-12">
          <div *ngIf="isOtherCommentSelected">
            <app-textarea label="Other" formControlName="commentNoteOther" [maxLength]="10000" [idPrefix]="criterion.id"></app-textarea>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="display-flex justify-content-end">
      <div matTooltip="Cannot enter additional data for criterion that is completed." [matTooltipDisabled]="!criterionCompleted">
        <button
          mat-raised-button
          aria-label="Complete"
          color="primary"
          (click)="onSubmit()"
          [disabled]="formGroup.invalid || criterionCompleted || outcomeEnded"
          tabindex="0"
        >
          Complete
        </button>
      </div>
    </div>
  </form>
</div>
