<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [ngClass]="{ 'mb-0': !subQuestion }">
  {{ question }}
</h3>

<div mat-dialog-content class="py-3">
  <mat-card class="my-0">
    <ng-container *ngIf="subQuestion">
      <p *ngIf="!hasHtml" class="mb-2" style="white-space: pre-line">{{ subQuestion }}</p>
      <p *ngIf="hasHtml" class="mb-2" style="white-space: pre-line" [innerHTML]="subQuestion"></p>
    </ng-container>
    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col-md">
          <app-textarea
            formControlName="cancellationRationale"
            [rows]="4"
            [maxLength]="10000"
            label="Cancellation Rationale"
          ></app-textarea>
        </div>
      </div>
    </form>
  </mat-card>
</div>

<div mat-dialog-actions align="end">
  <button
    *ngIf="showCancel"
    mat-raised-button
    aria-label="Cancel Button"
    class="background-color--muted mr-1"
    (click)="cancel()"
    tabindex="0"
  >
    Cancel
  </button>
  <button mat-raised-button aria-label="No" class="background-color--muted mr-1" (click)="close()" tabindex="0">No</button>
  <button mat-raised-button aria-label="Yes" color="primary" (click)="yesImSure()" tabindex="0" [disabled]="formGroup.invalid">Yes</button>
</div>
