<mat-card class="transfer-case__info" *ngIf="!hideHeaderCard" [formGroup]="formGroup">
  <div class="row display-flex justify-content-between align-items-center">
    <div class="col-4">
      <p class="my-0"><strong>Current Service Coordinator: </strong>{{ currentServiceCoordinator?.fullName || '--' }}</p>
    </div>
    <div class="col-4">
      <p class="my-0"><strong>Date Assigned: </strong>{{ (currentServiceCoordinator?.dateAssigned | dateFormat) || '--' }}</p>
    </div>
    <div class="col-1">
      <strong>End Date: </strong>
    </div>
    <div class="col-3">
      <app-date-picker controlName="transferDate" [min]="today" label="Choose a date" aria-labelledby="Choose a date"></app-date-picker>
    </div>
  </div>
</mat-card>

<mat-card class="transfer-case__table">
  <div class="display-flex justify-content-between">
    <h3>Service Coordinators</h3>

    <form [formGroup]="searchFormGroup">
      <app-text-field label="Search" formControlName="searchCriteria"></app-text-field>
    </form>
  </div>

  <section class="table-overflow">
    <table mat-table matSort [dataSource]="dataSource" class="w-100">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Service Coordinator</th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="numberOfCases">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Number of Current Cases</th>
        <td mat-cell *matCellDef="let element">
          {{ element.numberOfCases }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
    </table>
    <p *ngIf="!dataSource.data">No data yet...</p>
    <app-table-paginator
      *ngIf="currentPageInfo.length > 0"
      [length]="currentPageInfo.length"
      [pageSize]="currentPageInfo.pageSize"
      [pageIndex]="currentPageInfo.pageIndex"
      (page)="pageChanged($event)"
    ></app-table-paginator>
  </section>
</mat-card>
