<mat-accordion displayMode="flat" multi class="accordion accordion--close accordion--short" *ngIf="evaluation">
  <ng-container *ngIf="!evaluation.isFinalized">
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title><h3 class="my-0 text-normal">Evaluation Team</h3> </mat-panel-title>
      </mat-expansion-panel-header>

      <app-help-directional-text>
        <p>Document the team members who are involved in the decision by adding them individually.</p>
      </app-help-directional-text>
      <app-evaluation-team-table (surveyChanged)="onFamilySurveyChanged()"></app-evaluation-team-table>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0" *ngIf="shouldShowSurveyResults">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="display-flex align-items-center">
            <h3 class="my-0 text-normal">Questionnaire Results</h3>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-survey-responses [evaluationId]="evaluation.id" [surveyId]="evaluation.familySurveyId"></app-survey-responses>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title><h3 class="my-0 text-normal">Evaluation Assignments</h3> </mat-panel-title>
      </mat-expansion-panel-header>
      <app-evaluation-assignments-table
        [trackFormChanges]="evaluationAssignmentsTable.formGroup"
        #evaluationAssignmentsTable
      ></app-evaluation-assignments-table>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0" *ngIf="shouldShowRoutines" [expanded]="isExpanded">
      <div #routines></div>
      <mat-expansion-panel-header>
        <mat-panel-title> <h3 class="my-0 text-normal">Routines</h3> </mat-panel-title>
      </mat-expansion-panel-header>

      <app-help-directional-text>
        <p>
          Routines may be manually entered on this page, or they might be automatically pulled from other areas in the evaluation/assessment
          process. Save each routine and short name by clicking the save icon; at least two routines must be submitted.
        </p>
      </app-help-directional-text>
      <app-evaluation-routines-table
        [trackFormChanges]="evaluationRoutinesTable.editFormGroup"
        #evaluationRoutinesTable
      ></app-evaluation-routines-table>
    </mat-expansion-panel>
  </ng-container>
  <mat-expansion-panel class="mat-elevation-z0" *ngIf="evaluation?.intakeType === 'PartB'">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Educational Evaluation Report</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <app-help-directional-text>
      <p>The EER is built as evaluation details are added by the evaluation team.</p>
    </app-help-directional-text>
    <div class="display-flex justify-content-end align-items-center" *ngIf="!evaluation.isFinalized">
      <div>
        <button
          mat-raised-button
          aria-label="View Draft EER"
          [isBusy]="loading.eer"
          color="accent"
          class="mr-2"
          (click)="onViewEer()"
          tabindex="0"
        >
          View Draft EER
        </button>
      </div>
    </div>
    <div class="display-flex justify-content-between align-items-center" *ngIf="evaluation.isFinalized">
      <div>
        <button mat-raised-button aria-label="View Final EER" color="primary" (click)="onViewEer()" tabindex="0">View Final EER</button>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel class="mat-elevation-z0" *ngIf="evaluation?.intakeType === 'PartC' && incompleteItems?.length > 0">
    <mat-expansion-panel-header>
      <div class="display-flex justify-content-between align-items-center w-100">
        <mat-panel-title class="text-primary mt-0 mb-2">Incomplete Data Report</mat-panel-title>

        <div class="display-flex align-items-center">
          <span *ngIf="incompleteItems?.length > 1" class="ml-2 text-danger text-sm position-fix-notice">
            {{ incompleteItems?.length }} Notices
          </span>

          <span *ngIf="incompleteItems?.length === 1" class="ml-2 text-danger text-sm position-fix-notice"> 1 Notice </span>
          <button
            mat-icon-button
            aria-label="Refresh Icon"
            color="primary"
            (click)="$event.stopPropagation(); onGetIncompleteDataReport()"
            class="ml-2 mr-2"
            matTooltip="Refresh"
            tabindex="0"
          >
            <mat-icon aria-label="Refresh Icon"> refresh </mat-icon>
          </button>
        </div>
      </div>
    </mat-expansion-panel-header>
    <app-incomplete-data-report
      [displayHeader]="false"
      [incompleteItems]="incompleteItems"
      (navigateToAction)="onNavigate($event)"
    ></app-incomplete-data-report>
  </mat-expansion-panel>
  <mat-expansion-panel class="mat-elevation-z0" *ngIf="evaluation?.intakeType === 'PartC'">
    <mat-expansion-panel-header>
      <div class="display-flex justify-content-between align-items-center w-100">
        <mat-panel-title class="text-primary mt-0 mb-2">Evaluation & Assessment Reports</mat-panel-title>
      </div>
    </mat-expansion-panel-header>
    <div class="display-flex justify-content-start align-items-center">
      <div>
        <button
          [disabled]="
            incompleteItems?.length > 0 || evaluation.isFinalized || (!evaluation.isInitial && !evaluation.ifspHasActiveAnnualReview)
          "
          mat-raised-button
          aria-label="Finalize Evaluation and Assessment"
          color="primary"
          class="mr-2"
          [disableAfterBusy]="
            incompleteItems?.length > 0 || evaluation.isFinalized || (!evaluation.isInitial && !evaluation.ifspHasActiveAnnualReview)
          "
          [isBusy]="loading.finalization"
          (click)="onFinalizeEvaluationAndAssessment()"
          tabindex="0"
        >
          Finalize Evaluation and Assessment
        </button>
        <button
          *ngIf="outputEvaluationAndAssessmentEnabled"
          mat-raised-button
          aria-label="View Evaluation & Assessment Report"
          color="primary"
          [isBusy]="isPrinting"
          (click)="onViewEvaluationAndAssessment()"
          tabindex="0"
        >
          View Evaluation & Assessment Report
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<div class="page-bottom-leeway"></div>
