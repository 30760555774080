import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { UpdateConsentFormComponent } from 'src/app/consent/family-consent/update-consent-form/update-consent-form.component';
import { CaseSummary } from 'src/app/shared/models/case';
import { ConsentForm, consentFormTitlesByType, ConsentFormType } from 'src/app/shared/models/fiie-consent/consent-form';

@Component({
  selector: 'app-post-referral-consent',
  templateUrl: './post-referral-consent.component.html',
  styleUrls: ['./post-referral-consent.component.scss'],
})
export class PostReferralConsentComponent implements OnInit {
  @Input() caseSummary: CaseSummary;
  @Input() inCard = false;
  @Input() psgProvided: boolean;

  @Output() consentFormsLoaded = new EventEmitter<ConsentForm[]>();

  isCaseOwner: boolean;
  types = [ConsentFormType.PostReferralScreening];
  showCreateConsent: boolean;

  constructor(private authService: AuthService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.isCaseOwner = this.caseSummary.caseOwnerId === this.authService.user.id;
  }

  onConsentFormsLoaded(consentForms: ConsentForm[]) {
    this.showCreateConsent = !consentForms.some((cf) => cf.type === ConsentFormType.PostReferralScreening);
    this.consentFormsLoaded.emit(consentForms);
  }

  createConsent() {
    this.dialog.open(UpdateConsentFormComponent, {
      width: '400px',
      data: {
        caseId: this.caseSummary.id,
        title: consentFormTitlesByType[ConsentFormType.PostReferralScreening],
        consentFormType: ConsentFormType.PostReferralScreening,
        createdOn: this.caseSummary.earlyAccessReferralSubmittedOn,
      },
    });
  }
}
