<h3>Medical Diagnosis(es)</h3>
<p class="requiredLabel" *ngIf="!isQuestionnaire && dataSource.data.length === 1">
  At least <strong>one</strong> diagnosis is <strong>required*</strong>
</p>
<div class="row">
  <div class="col">
    <div class="table-overflow">
      <table mat-table [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let condition">
            <ng-container *ngIf="condition.name && !isEditingMedicalCondition">
              <button mat-icon-button aria-label="Edit Icon" (click)="setMedicalConditionEdit(condition)" tabindex="0">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button aria-label="Delete Icon" (click)="onRemoveMedicalCondition(condition)" tabindex="0">
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="condition.isEditing">
              <button
                mat-icon-button
                aria-label="Save Icon"
                (click)="saveMedicalCondition(condition, conditionFormGroup.value, !condition.name)"
                title="Save"
                tabindex="0"
              >
                <mat-icon>save</mat-icon>
              </button>
              <button
                mat-icon-button
                aria-label="Clear Icon"
                (click)="setMedicalConditionEdit(condition, false)"
                title="Cancel"
                tabindex="0"
              >
                <mat-icon>clear</mat-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="!condition.name && !isEditingMedicalCondition">
              <button mat-icon-button aria-label="Add Icon" (click)="setMedicalConditionEdit(condition, true)" tabindex="0">
                <mat-icon>add_circle</mat-icon>
              </button>
            </ng-container>
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Diagnosis</th>
          <td mat-cell *matCellDef="let condition">
            <app-inline-edit [editing]="condition.isEditing">
              <ng-container viewMode>
                {{ condition.name }}
              </ng-container>
              <ng-container editMode [formGroup]="conditionFormGroup">
                <app-text-field label="Diagnosis" formControlName="name"> </app-text-field>
              </ng-container>
            </app-inline-edit>
          </td>
        </ng-container>

        <!-- ICD Code Column -->
        <ng-container *ngIf="!isQuestionnaire" matColumnDef="icdCode">
          <th mat-header-cell *matHeaderCellDef>ICD Code</th>
          <td mat-cell *matCellDef="let condition">
            <app-inline-edit [editing]="condition.isEditing">
              <ng-container viewMode>
                {{ condition.icdCode }}
              </ng-container>
              <ng-container editMode [formGroup]="conditionFormGroup">
                <app-text-field label="ICD Code" formControlName="icdCode"> </app-text-field>
              </ng-container>
            </app-inline-edit>
          </td>
        </ng-container>

        <!-- Diagnosis Column -->
        <ng-container *ngIf="!isQuestionnaire" matColumnDef="diagnosisQualifies">
          <th mat-header-cell *matHeaderCellDef>Is this a diagnosis that qualifies the child for Early ACCESS?</th>
          <td mat-cell *matCellDef="let condition">
            <app-inline-edit [editing]="condition.isEditing">
              <ng-container viewMode *ngIf="condition.name">
                {{ condition.diagnosisQualifies === true ? 'Yes' : condition.diagnosisQualifies === false ? 'No' : '' }}
              </ng-container>
              <ng-container editMode [formGroup]="conditionFormGroup">
                <fieldset>
                  <app-radio-group
                    label="Is this a diagnosis that qualifies the child for Early ACCESS?"
                    [options]="yesNoOptions"
                    formControlName="diagnosisQualifies"
                  ></app-radio-group>
                </fieldset>
              </ng-container>
            </app-inline-edit>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <p *ngIf="!dataSource.data">No data yet...</p>
    </div>
  </div>
</div>
