<mat-accordion class="accordion accordion--close accordion--short" multi>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Assessments</h3></mat-panel-title>
    </mat-expansion-panel-header>
    <div class="table-overflow app-table">
      <table mat-table [dataSource]="assignmentDataSource" class="w-100" matSort>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.date | dateFormat }}
          </td>
        </ng-container>
        <ng-container matColumnDef="nameOfAssessment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Assessment</th>
          <td mat-cell *matCellDef="let element">
            {{ element.nameOfAssessment }}
          </td>
        </ng-container>
        <ng-container matColumnDef="postSecondaryAreas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Post Secondary Areas</th>
          <td mat-cell *matCellDef="let element">
            {{ element.postSecondaryAreas }}
          </td>
        </ng-container>
        <ng-container matColumnDef="summaryOfAssessmentResults">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Summary</th>
          <td mat-cell *matCellDef="let element">
            {{ element.summaryOfAssessmentResults }}
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No results...</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="assignmentDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: assignmentDisplayedColumns"></tr>
        <tr
          [hidden]="assignmentDataSource?.data?.length > 0"
          mat-footer-row
          *matFooterRowDef="assignmentDataSource.data?.length === 0 ? ['noResults'] : []"
        ></tr>
      </table>
      <mat-paginator [dataSource]="assignmentDataSource"></mat-paginator>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Course of Study</h3></mat-panel-title>
    </mat-expansion-panel-header>
    <div class="table-overflow app-table">
      <table mat-table [dataSource]="courseDataSource" class="w-100" matSort>
        <ng-container matColumnDef="courseActivity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Course / Activity</th>
          <td mat-cell *matCellDef="let element">
            {{ element.courseActivity }}
          </td>
        </ng-container>
        <ng-container matColumnDef="projectedDateYearOfSchool">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Projected Date / Year of School</th>
          <td mat-cell *matCellDef="let element">
            {{ element.projectedDateYearOfSchool }}
          </td>
        </ng-container>
        <ng-container matColumnDef="completedDiscontinued">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed / Discontinued</th>
          <td mat-cell *matCellDef="let element">
            {{ element.completedDiscontinued }}
          </td>
        </ng-container>
        <ng-container matColumnDef="livingLearningWorking">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Living, Learning, Working</th>
          <td mat-cell *matCellDef="let element">
            {{ element.livingLearningWorking }}
          </td>
        </ng-container>
        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No results...</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="courseDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: courseDisplayedColumns"></tr>
        <tr
          [hidden]="courseDataSource?.data?.length > 0"
          mat-footer-row
          *matFooterRowDef="courseDataSource.data?.length === 0 ? ['noResults'] : []"
        ></tr>
      </table>
      <mat-paginator [dataSource]="courseDataSource"></mat-paginator>
    </div>
  </mat-expansion-panel>
</mat-accordion>
