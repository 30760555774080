import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GenericPermissionsGuard, NonVrAuthGuard, PortalPermissionsGuard } from '../auth/auth.guard';
import { FiieConsentFormComponent } from '../child-find/fiie-consent/fiie-consent-form.component';
import { FiieConsentFormResolver } from '../child-find/fiie-consent/fiie-consent-form.resolver';
import { ProgressMonitorLookupsResolver } from '../monitoring-progress/resolvers/progress-monitor-lookups.resolver';
import { NewChildReadOnlyComponent } from '../shared/components/new-child/new-child-read-only/new-child-read-only.component';
import { NewChildReadonlyResolver } from '../shared/components/new-child/new-child-read-only/new-child-readonly.resolver';
import { CanDeactivateGuard } from '../shared/guards/can-deactivate.guard';
import { DataHistoryComponent } from './data-history/data-history.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { EvaluationComponent } from './evaluation/evaluation.component';
import { FamilyContactConsentComponent } from './family-contact-consent/family-contact-consent.component';
import { FamilyContactLetterComponent } from './family-contact-consent/family-contact-letter/family-contact-letter.component';
import { FamilyMeetingComponent } from './family-meeting/family-meeting.component';
import { IepDataComponent } from './iep-data/iep-data.component';
import { IfspAddServiceLogComponent } from './ifsp-data/components/ifsp-add-service-log/ifsp-add-service-log.component';
import { IfspDataComponent } from './ifsp-data/ifsp-data.component';
import { LearnerDashboardComponent } from './learner-dashboard/learner-dashboard.component';
import { LearnerManagementComponent } from './learner-management.component';
import { PostsecondarySummaryComponent } from './postsecondary-summary/postsecondary-summary.component';
import { SecondaryTransitionComponent } from './secondary-transition/secondary-transition.component';
import { IvrsPermissionGuard } from '../ivrs/guards/ivrs-permission.guard';

const routes: Routes = [
  {
    path: ':learnerId',
    component: LearnerManagementComponent,
    canActivate: [PortalPermissionsGuard, IvrsPermissionGuard],
    children: [
      {
        path: 'learner-dashboard',
        component: LearnerDashboardComponent,
        data: { num: 1 },
      },
      {
        path: 'family-contact',
        component: FamilyContactConsentComponent,
        data: { num: 2 },
      },
      {
        path: 'documentation',
        component: DocumentationComponent,
        data: { num: 3 },
      },
      {
        path: 'data-history',
        component: DataHistoryComponent,
        data: { num: 4 },
      },
      {
        path: 'evaluation',
        component: EvaluationComponent,
        data: { num: 5 },
      },
      {
        path: 'ifsp',
        component: IfspDataComponent,
        data: { num: 6 },
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'iep',
        component: IepDataComponent,
        data: { num: 6 },
      },
      {
        path: 'secondary-transition',
        component: SecondaryTransitionComponent,
        data: { num: 7 },
      },
      {
        path: 'postsecondary-summary',
        component: PostsecondarySummaryComponent,
        data: { num: 8 },
        canActivate: [NonVrAuthGuard],
      },
      {
        path: 'cases/:caseId/postsecondary-summary',
        component: PostsecondarySummaryComponent,
        data: { num: 8 },
        canActivate: [NonVrAuthGuard],
      },
      {
        path: 'transition-planning',
        loadChildren: () => import('./transition-planning/transition-planning.module').then((mod) => mod.TransitionPlanningModule),
        data: { num: 8 },
      },
    ],
  },
  {
    path: ':caseId/fiie-consent',
    component: FiieConsentFormComponent,
    resolve: {
      data: FiieConsentFormResolver,
    },
  },
  {
    path: ':learnerId/cases/:caseId/family-contact-letter',
    component: FamilyContactLetterComponent,
  },
  {
    path: ':learnerId/schedule-meeting',
    component: FamilyMeetingComponent,
  },
  {
    path: ':learnerId/schedule-meeting/evaluations/:evaluationId',
    component: FamilyMeetingComponent,
  },
  {
    path: ':learnerId/new-learner-info',
    component: NewChildReadOnlyComponent,
    resolve: {
      learner: NewChildReadonlyResolver,
    },
  },
  {
    path: ':caseId/add-service-log',
    component: IfspAddServiceLogComponent,
    canActivate: [GenericPermissionsGuard],
    data: { permission: 'CreateEditServiceLog' },
    resolve: {
      data: ProgressMonitorLookupsResolver,
    },
  },
  {
    path: ':caseId/update-service-log/:serviceLogId',
    component: IfspAddServiceLogComponent,
    canActivate: [GenericPermissionsGuard],
    data: { permission: 'ViewServiceLog' },
    resolve: {
      data: ProgressMonitorLookupsResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LearnerManagementRoutingModule {}
