import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LearnerSummary } from '../../models/learner';
import { LearnerService } from '../../services/learner/learner.service';

@Component({
  selector: 'app-dynamic-learner-header',
  templateUrl: './dynamic-learner-header.component.html',
  styleUrls: ['./dynamic-learner-header.component.scss'],
})
export class DynamicLearnerHeaderComponent implements OnInit, OnDestroy, OnChanges {
  // Input is for components that are in dialogs/modals
  @Input() learnerId: string = this.route.snapshot.paramMap.get('learnerId');
  @Input() keepCaseSummaryOnDestroy = false;

  get learnerSummary$() {
    return this.learnerService.learnerSummaryChange$;
  }

  constructor(private route: ActivatedRoute, private learnerService: LearnerService) {}

  async ngOnInit(): Promise<void> {
    if (!!this.learnerId && !this.learnerService.learnerSummary) {
      this.learnerService.learnerSummary = await this.loadData(this.learnerId);
    }
    const caseId = this.route.snapshot.paramMap.get('caseId');
    if (!this.learnerId && caseId) {
      this.learnerService.learnerSummary = await this.learnerService.getByCaseId(caseId).toPromise();
    }
  }

  ngOnDestroy() {
    if (!this.keepCaseSummaryOnDestroy) {
      this.learnerService.learnerSummary = undefined;
    }
  }

  private loadData(learnerId): Promise<LearnerSummary> {
    return this.learnerService
      .getLearnerSummary(learnerId)
      .pipe(catchError((err) => (err.status === 404 ? of(null) : throwError(err))))
      .toPromise();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.learnerId.currentValue) {
      if (!!this.learnerId && (!this.learnerService.learnerSummary || this.learnerService?.learnerSummary?.id !== this.learnerId)) {
        this.learnerService.learnerSummary = await this.loadData(this.learnerId);
      }
    }
  }
}
