import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { KeyValuePair } from '../../models/key-value-pair';
import { CaseService } from '../../services/case/case.service';
import { StickyNoteService } from '../../services/sticky-note/sticky-note.service';
import { StringSizes } from '../form/constants/constants';

@Component({
  selector: 'app-create-sticky-note-modal',
  templateUrl: './create-sticky-note-modal.component.html',
  styleUrls: ['./create-sticky-note-modal.component.scss'],
})
export class CreateStickyNoteModalComponent implements OnInit, OnDestroy {
  stickyNoteForm: FormGroup;
  stringSizes = StringSizes;
  activeCall = false;

  learnerOptions: KeyValuePair[] = [];
  private subscriptions: Subscription[] = [];

  get note() {
    return this.stickyNoteForm.get('note') as FormControl;
  }

  get caseId() {
    return this.stickyNoteForm.get('caseId') as FormControl;
  }

  get isFamily() {
    return this.authService.isPortalUser;
  }

  constructor(
    private dialogRef: MatDialogRef<any>,
    private snackBar: MatSnackBar,
    private stickyNoteService: StickyNoteService,
    private caseService: CaseService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.stickyNoteForm = new FormGroup({
      caseId: new FormControl(''),
      note: new FormControl('', Validators.required),
    });

    this.caseService.getCaseList().subscribe((cases) => {
      this.learnerOptions = cases.map((x) => new KeyValuePair(x.id, x.learnerFullName));
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  close() {
    this.dialogRef.close();
  }

  onSave() {
    if (this.note.value) {
      this.activeCall = true;
      this.stickyNoteService.createNote(this.note.value, this.caseId.value).subscribe(
        () => {
          this.stickyNoteCreated();
        },
        (err) =>
          this.snackBar.open('Failed to Create Sticky Note', 'Close', {
            duration: 3000,
          }),
        () => this.close()
      );
    }
  }

  onSaveAndAdd() {
    if (this.note.value) {
      this.activeCall = true;
      this.stickyNoteService.createNote(this.note.value, this.caseId.value).subscribe(() => {
        this.stickyNoteCreated();
      });
    }
  }

  stickyNoteCreated() {
    this.stickyNoteForm.reset();
    this.activeCall = false;
    this.snackBar.open('Sticky Note Created!', 'Close', {
      duration: 3000,
    });
  }
}
