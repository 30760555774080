<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Change Reevaluation Due Date</h3>
<ng-container [formGroup]="formGroup">
  <div mat-dialog-content class="py-3">
    <mat-card class="my-0">
      <div class="row">
        <div class="col-md-12 mt-2">
          <app-date-picker label="New Reevaluation Due Date" controlName="reevaluationDueOn"></app-date-picker>
        </div>
      </div>
    </mat-card>
  </div>

  <div mat-dialog-actions class="display-flex justify-content-end align-items-center">
    <button mat-raised-button aria-label="Cancel Button" (click)="onCancel()" cdkFocusInitial type="button" class="ml-2" tabindex="0">
      Cancel
    </button>
    <button
      mat-raised-button
      aria-label="Submit"
      color="primary"
      class="ml-2"
      [isBusy]="activeCall"
      (click)="onSubmit()"
      tabindex="0"
      [disabled]="formGroup.invalid"
    >
      Submit
    </button>
  </div>
</ng-container>
