import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';
import {
  DialogData,
  UploadDocumentationModalComponent,
} from '../../modals/upload-documentation-modal/upload-documentation-modal.component';
import { FileDocument } from '../../models/file-document';
import { LearnerService } from '../../services/learner/learner.service';

@Component({
  selector: 'app-documentation-upload',
  templateUrl: './documentation-upload.component.html',
  styleUrls: ['./documentation-upload.component.scss'],
})
export class DocumentationUploadComponent implements OnInit, OnChanges {
  @Input() baseUrl: string;
  @Input() title = 'Upload Documentation (optional)';
  @Input() documents: FileDocument[];
  @Input() allowedFileTypes = 'TXT, CSV, PDF, DOC, DOCX, ODT, PPTX, PPT, RTF, XLS, XLSX, PNG, JPEG, JPG, GIF, BMP';
  @Input() multiple = true;
  @Input() alignEnd = false;
  @Input() activeCall = false;
  @Input() isEvaluation = false;
  @Input() readOnly = false;
  @Input() learnersNameOverride;
  @Output() upload = new EventEmitter();
  @Output() deleteDocument = new EventEmitter();
  @ViewChild('verifyDelete') verifyDeleteWarning: TemplateRef<any>;

  permissions = AppPermissions;
  dataSource: MatTableDataSource<any>;
  displayedColumns = ['friendlyName', 'fileName', 'actions'];

  listOfFiles: FileList;
  formGroup: FormGroup = this.fb.group({
    documentTitle: [''],
  });

  get learnersName() {
    return !!this.learnersNameOverride ? this.learnersNameOverride : this.learnerService?.learnerSummary?.fullName;
  }

  constructor(private dialog: MatDialog, private fb: FormBuilder, public authService: AuthService, public learnerService: LearnerService) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.documents);
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.documents);
  }

  confirmDeleteDocument(document: FileDocument) {
    this.dialog
      .open(this.verifyDeleteWarning, {
        width: '400px',
        data: document,
      })
      .afterClosed()
      .subscribe(() => {});
  }

  onDeleteDocument(documentId: string) {
    this.deleteDocument.emit(documentId);
  }

  onOpenUpload() {
    this.activeCall = true;
    const dialogRef = this.dialog.open(UploadDocumentationModalComponent, {
      data: {
        title: 'Upload Documentation',
        allowedFileTypes: this.allowedFileTypes,
        multiple: this.multiple,
        learnersName: this.learnersName,
      } as DialogData,
      width: '728px',
    });

    dialogRef.afterClosed().subscribe((result: Array<any>) => {
      if (result) {
        const formData = new FormData();
        result.forEach((element) => {
          formData.append('titles', element.title);
          formData.append('documents', element.file, element.file.name);
        });
        this.upload.emit(formData);
      } else {
        this.activeCall = false;
      }
    });
  }
}
