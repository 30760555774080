import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ConfirmationDialogComponent } from 'src/app/shared/services/notification.service';
import { CanComponentDeactivate, DeactivationStatus } from '../../../shared/services/deactivation.service';
import { IepAdditionalInfo } from '../../models/iep-additional-info';
import { IepService } from '../../services/iep.service';

@Component({
  selector: 'app-iep-additional-info',
  templateUrl: './iep-additional-info.component.html',
  styleUrls: ['./iep-additional-info.component.scss'],
})
export class IepAdditionalInfoComponent implements OnInit, CanComponentDeactivate {
  @ViewChild('formDirective') private formDirective: NgForm;
  @ViewChild(MatAccordion) additionalInfoAccordion: MatAccordion;
  isAccordionsExpanded = false;
  formGroup = this.fb.group({
    id: '',
    note: [''],
  });
  iepId: string;
  userId: string;
  iepAdditionalInfos: IepAdditionalInfo[];
  isEditing = false;
  isBusy = false;
  shortDateFormat = shortDateFormat;

  constructor(
    private fb: FormBuilder,
    private iepService: IepService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService
  ) {}

  canDeactivate(): DeactivationStatus | Observable<DeactivationStatus> | Promise<DeactivationStatus> {
    return this.formGroup.dirty ? DeactivationStatus.NeedsConfirmation : DeactivationStatus.Accepted;
  }

  ngOnInit(): void {
    this.iepId = this.route.parent?.snapshot.paramMap.get('iepId') || this.route.snapshot.paramMap.get('iepId');
    this.userId = this.authService.user.id;
    this.loadData();
  }

  private loadData() {
    this.iepService.getIepAdditionalInfos(this.iepId).subscribe((response) => {
      this.iepAdditionalInfos = response;
      this.iepAdditionalInfos = this.iepAdditionalInfos.sort((a, b) => {
        const aUpdatedOn = new Date(a.updatedOn);
        const bUpdatedOn = new Date(b.updatedOn);
        return bUpdatedOn.getTime() - aUpdatedOn.getTime();
      });
    });
  }

  onEdit(item: IepAdditionalInfo) {
    this.isEditing = true;
    this.formGroup.patchValue(item);
  }

  onDelete(item: IepAdditionalInfo) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '728px',
      data: {
        title: 'Remove',
        message: 'Are you sure you want to remove this additional information?',
      },
    });

    dialogRef.afterClosed().subscribe((yesImSure) => {
      if (yesImSure) {
        this.iepService.deleteIepAdditionalInfo(this.iepId, item.id).subscribe((res: any) => {
          if (res.succeeded) {
            this.loadData();
          }
        });
      }
    });
  }

  onCancel() {
    this.isEditing = false;
    this.formGroup.reset();
    this.formDirective.resetForm();
  }

  onSubmit() {
    this.isBusy = true;
    if (this.formGroup.controls.note.value === null || this.formGroup.controls.note.value.trim() === '') {
      this.onCancel();
      return;
    }

    this.iepService.addOrUpdateIepAdditionalInfo(this.iepId, this.formGroup.value).subscribe((res: any) => {
      if (res.succeeded) {
        this.isEditing = false;
        this.loadData();
        this.formGroup.reset();
        this.formDirective.resetForm();
        this.isBusy = false;
      }
    });
  }

  toggleAccordion() {
    this.isAccordionsExpanded = !this.isAccordionsExpanded;
    this.isAccordionsExpanded ? this.additionalInfoAccordion.openAll() : this.additionalInfoAccordion.closeAll();
  }
}
