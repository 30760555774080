import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EvaluationDetail } from 'src/app/evaluation/models/evaluation';
import { EvaluationFileDocument } from 'src/app/evaluation/models/filedocument';
import { EvaluationFormService } from '../services/evaluation-form.service';
import { SelectDocumentationModalComponent } from './modals/select-documentation-modal.component';

@Component({
  selector: 'app-evaluation-documentation',
  templateUrl: './evaluation-documentation.component.html',
  styleUrls: ['./evaluation-documentation.component.scss'],
})
export class EvaluationDocumentationComponent implements OnInit, OnDestroy {
  @Input() currentEvaluationDetail: EvaluationDetail;
  @Input() documents: EvaluationFileDocument[] = [];

  @Output() documentationChange = new EventEmitter();
  @Output() documentationValid = new EventEmitter<boolean>();

  subscription: Subscription;
  formGroup: FormGroup = this.fb.group({
    documentationNotes: [''],
    reviewedDocuments: this.fb.array([]),
  });

  get formArray() {
    return (this.formGroup.get('reviewedDocuments') as FormArray).controls as FormGroup[];
  }

  constructor(private fb: FormBuilder, private dialog: MatDialog, private evaluationFormService: EvaluationFormService) {
    this.subscription = evaluationFormService.formSubmitted$.subscribe(() => this.formGroup.markAllAsTouched());
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      this.documentationValid.emit(this.formGroup.valid);
      this.documentationChange.emit(value);
    });
    this.formGroup.patchValue(this.currentEvaluationDetail);
    if (this.currentEvaluationDetail.reviewedDocuments && this.currentEvaluationDetail.reviewedDocuments.length > 0) {
      this.addDocuments(this.currentEvaluationDetail.reviewedDocuments);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formGroup.reset();
  }

  onSelectDocumentation() {
    const formArray = this.formGroup.get('reviewedDocuments') as FormArray;

    const dialogRef = this.dialog.open(SelectDocumentationModalComponent, {
      width: '728px',
      data: { form: formArray },
    });

    dialogRef.afterClosed().subscribe((documents: EvaluationFileDocument[]) => {
      if (documents) {
        this.addDocuments(documents);
      }
    });
  }

  addDocuments(documents: EvaluationFileDocument[]) {
    const formArray = this.formGroup.get('reviewedDocuments') as FormArray;
    formArray.controls = [];
    documents.forEach((document) => {
      formArray.push(
        this.fb.group({
          id: document.id,
          title: document.title,
          fileName: document.fileName,
          url: document.url,
        })
      );
    });
  }
}
