import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import dayjs from 'dayjs';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { LearnerSummary } from '../../../models/learner';

@Component({
  selector: 'app-iep-learner-info-header',
  templateUrl: './iep-learner-info-header.component.html',
  styleUrls: ['./iep-learner-info-header.component.scss'],
})
export class IepLearnerInfoHeaderComponent implements OnChanges {
  @Input() isPrint = false;
  @Input() learnerSummary: LearnerSummary;

  shortDateFormat = shortDateFormat;
  rightsTransferDate: Date;
  fourteenthBirthday: Date;
  reviewByDate: any;
  reevalDue: any;

  constructor(public routingService: RoutingService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.learnerSummary) {
      this.getDates();
    }
  }

  getDates() {
    if (this.learnerSummary?.iepInformation?.rightsWillNotTransfer) {
      if (this.learnerSummary?.iepInformation?.dateRightsWillTransfer) {
        this.rightsTransferDate = dayjs(this.learnerSummary?.iepInformation?.dateRightsWillTransfer).toDate();
      } else {
        this.rightsTransferDate = null;
      }
    } else {
      this.rightsTransferDate = dayjs(this.learnerSummary?.dateOfBirth).add(18, 'year').toDate();
    }

    this.fourteenthBirthday = dayjs(this.learnerSummary?.dateOfBirth).add(14, 'year').toDate();

    this.reviewByDate = this.learnerSummary?.iepInformation?.iepEndDate
      ? dayjs(this.learnerSummary?.iepInformation?.iepEndDate).format(shortDateFormat.toUpperCase())
      : 'Pending';

    this.reevalDue = this.learnerSummary?.iepInformation?.evaluationFinalized
      ? dayjs(this.learnerSummary?.iepInformation?.evaluationFinalized)
          .add(1, 'year')
          .subtract(1, 'day')
          .format(shortDateFormat.toUpperCase())
      : 'Pending';
  }
}
