import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { EarlyAccessQuestionnaire } from 'src/app/evaluation/models/early-access-questionnaire';
import { Intake } from 'src/app/evaluation/models/intake';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { MedicalConditionDto } from 'src/app/shared/models/medical-condition';
import { MedicalConditionService } from 'src/app/shared/services/medical-condition/medical-condition-service';

@Component({
  selector: 'app-referral-medical-diagnosis-table',
  templateUrl: './referral-medical-diagnosis-table.component.html',
  styleUrls: ['./referral-medical-diagnosis-table.component.scss'],
})
export class ReferralMedicalDiagnosisTableComponent implements OnInit, AfterViewChecked {
  showPaginator = false;

  dataSource = new MatTableDataSource<MedicalConditionDto>([]);
  @Input() formGroup: FormGroup;
  @Output() refreshDataSource = new EventEmitter();
  conditionFormGroup: FormGroup;
  isEditingMedicalCondition = false;
  displayedColumns = ['actions', 'name', 'icdCode'];
  diagnosedConditions: MedicalConditionDto[];

  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  private emptyRow: MedicalConditionDto = {
    id: null,
    name: null,
    icdCode: null,
    diagnosisQualifies: null,
    isEditing: false,
  };

  get randomGuid() {
    const generate = (appendDash: boolean) => {
      const part = `${Math.random().toString(16)}000000000`.substr(2, 8);
      return appendDash ? `-${part.substr(0, 4)}-${part.substr(4, 4)}` : part;
    };
    return `${generate(false)}${generate(true)}${generate(true)}${generate(false)}`;
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder,
    private medicalConditionService: MedicalConditionService
  ) {}

  ngOnInit(): void {
    this.diagnosedConditions = this.formGroup.controls.referralReasonDiagnosedConditions.value;
    if (this.diagnosedConditions === null) {
      this.diagnosedConditions = [];
    }

    this.diagnosedConditions = this.diagnosedConditions.filter((x) => x.id !== null);
    this.diagnosedConditions.push(this.emptyRow);

    this.dataSource.data = this.diagnosedConditions;
    this.conditionFormGroup = this.fb.group({
      name: [null, [Validators.required]],
      icdCode: [null],
      diagnosisQualifies: [null, [Validators.required]],
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  addOrUpdateMedicalCondition(_condition: MedicalConditionDto, resetForm = true) {
    const newDiagnosedCondition = this.conditionFormGroup.value as MedicalConditionDto;
    newDiagnosedCondition.id = _condition.id;

    this.diagnosedConditions = this.diagnosedConditions.filter((x) => x.id !== null);
    const diagnosedConditionIndex = this.diagnosedConditions.findIndex((x) => x.id === newDiagnosedCondition.id);

    if (diagnosedConditionIndex === -1) {
      this.diagnosedConditions.push({
        id: this.randomGuid,
        name: this.conditionFormGroup.value.name,
        icdCode: this.conditionFormGroup.value.icdCode,
        diagnosisQualifies: null,
        isEditing: false,
      } as MedicalConditionDto);
    } else {
      this.diagnosedConditions[diagnosedConditionIndex] = {
        id: newDiagnosedCondition.id,
        name: newDiagnosedCondition.name,
        icdCode: this.conditionFormGroup.value.icdCode,
        diagnosisQualifies: null,
        isEditing: false,
      } as MedicalConditionDto;
    }

    this.diagnosedConditions.push({
      id: null,
      name: null,
      icdCode: null,
      diagnosisQualifies: null,
      isEditing: false,
    } as MedicalConditionDto);

    const assignIds = this.diagnosedConditions.filter((x) => x.id === null && x.name !== null);
    assignIds.forEach((row) => {
      row.id = this.randomGuid;
    });

    this.dataSource.data = this.diagnosedConditions;
    this.formGroup.patchValue({
      referralReasonDiagnosedConditions: this.diagnosedConditions,
    });

    this.setMedicalConditionEdit(_condition, false);

    if (resetForm) {
      this.conditionFormGroup.value.resetForm();
    }
  }

  setMedicalConditionEdit(condition: MedicalConditionDto, editing = true) {
    this.isEditingMedicalCondition = editing;
    condition.isEditing = editing;
    if (!editing) {
      this.conditionFormGroup.patchValue({});
      return;
    }

    this.conditionFormGroup.patchValue({
      name: condition.name,
      icdCode: condition.icdCode,
      diagnosisQualifies: condition.diagnosisQualifies,
    });
  }

  saveMedicalCondition(condition: MedicalConditionDto, formDirective: any, isNew = false) {
    const formObj = {
      form: { value: formDirective },
    };
    if (!formObj.form.value.name) {
      this.conditionFormGroup.markAllAsTouched();
      return;
    }
    if (!isNew) {
      this.onRemoveMedicalCondition(condition);
      this.addOrUpdateMedicalCondition(condition, false);
    } else {
      this.addOrUpdateMedicalCondition(condition, false);
    }
    this.isEditingMedicalCondition = false;
  }

  onRemoveMedicalCondition(condition: MedicalConditionDto) {
    this.diagnosedConditions.splice(
      this.diagnosedConditions.findIndex((x) => x.id === condition.id),
      1
    );
    this.dataSource.data = this.diagnosedConditions;
    this.formGroup.patchValue({
      referralReasonDiagnosedConditions: this.diagnosedConditions,
    });
  }

  refreshTableData() {
    this.refreshDataSource.emit(true);
  }
}
