import {
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  PipeTransform,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';
import { IepAmendment } from '../../iep/models/iep-amendment';
import { CompareAmendmentOutputComponent } from '../components/compare-amendment-output/compare-amendment-output.component';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';

export class IepAmendmentsStructuralDirectiveInfo {
  public iepAmendments = new BehaviorSubject<Array<IepAmendment>>([]);
  public associatedControl = new BehaviorSubject<FormControl | string>(null);
  public valueConverter = new BehaviorSubject<PipeTransform>(null);
  public converterArgs = new BehaviorSubject<Array<any> | any>(null);
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[iepAmendments]',
  providers: [IepAmendmentsStructuralDirectiveInfo],
})
export class IepAmendmentsStructuralDirective implements OnChanges {
  private viewRef: ViewRef;

  @Input()
  public set iepAmendments(value: Array<IepAmendment>) {
    this.info.iepAmendments.next(value);
  }

  public get iepAmendments(): Array<IepAmendment> {
    return this.info.iepAmendments.value;
  }

  @Input()
  public set iepAmendmentsTo(value: FormControl | string) {
    this.info.associatedControl.next(value);
  }

  public get iepAmendmentsTo(): FormControl | string {
    return this.info.associatedControl.value;
  }

  @Input()
  public set iepAmendmentsUsing(value: PipeTransform) {
    this.info.valueConverter.next(value);
  }

  public get iepAmendmentsUsing(): PipeTransform {
    return this.info.valueConverter.value;
  }

  @Input()
  public set iepAmendmentsWith(value: Array<any> | any) {
    this.info.converterArgs.next(value);
  }

  public get iepAmendmentsWith(): Array<any> | any {
    return this.info.converterArgs.value;
  }

  constructor(
    private info: IepAmendmentsStructuralDirectiveInfo,
    @Optional() private templateRef: TemplateRef<any>,
    private container: ViewContainerRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.templateRef) {
      return;
    }

    if ('iepAmendments' in changes) {
      const amendments = changes.iepAmendments.currentValue as Array<IepAmendment>;
      if (!amendments?.some((x) => !x.finalizeDate)) {
        this.container.clear();
        this.viewRef = null;
      } else if (!this.viewRef) {
        this.createComponent();
      }
    }
  }

  private createComponent(): void {
    this.viewRef = this.container.createEmbeddedView(this.templateRef);
  }
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'app-compare-amendment-output',
})
export class IepAmendmentsAttributeDirective implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  private associatedControlSubscription: Subscription;

  constructor(
    @Optional()
    private structuralDirectiveInfo: IepAmendmentsStructuralDirectiveInfo,
    @Optional() private formGroupDirective: FormGroupDirective,
    private host: CompareAmendmentOutputComponent
  ) {}

  ngOnInit() {
    if (!this.structuralDirectiveInfo) {
      return;
    }

    this.subscriptions.add(this.structuralDirectiveInfo.iepAmendments.subscribe((x) => this.onAmendmentsChanged(x)));
    this.subscriptions.add(this.structuralDirectiveInfo.associatedControl.subscribe((x) => this.onAssociatedControlChanged(x)));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private onAmendmentsChanged(value: Array<IepAmendment>): void {
    const latestAmendment = this.getLatestAmendment(value);
    this.host.amendmentId = latestAmendment?.id;
    this.host.amendmentFinalized = !!latestAmendment?.finalizeDate;
    this.host.amendmentDate = this.getLatestFinalizedAmendment(value)?.finalizeDate;
  }

  private onAssociatedControlChanged(associatedControl: FormControl | string): void {
    this.associatedControlSubscription?.unsubscribe();

    const control = associatedControl instanceof FormControl ? associatedControl : this.formGroupDirective?.control?.get(associatedControl);

    if (control) {
      this.setCurrentValue(control.value);
    }

    this.associatedControlSubscription = control?.valueChanges.subscribe((value) => this.setCurrentValue(value));
  }

  private setCurrentValue<T>(value: T): void {
    const convertedValue = this.getConvertedValue(value);
    this.host.changedVersionText = convertedValue;
    this.host.currentPrefixText = convertedValue ? 'Current: ' : 'Proposed: ';
  }

  private getLatestAmendment(amendments: Array<IepAmendment>): IepAmendment {
    if (!amendments || amendments.length === 0) {
      return null;
    }
    return amendments?.reduce((p, c) => (p.createdOn > c.createdOn ? p : c));
  }

  private getLatestFinalizedAmendment(amendments: Array<IepAmendment>): IepAmendment {
    const filteredAmendments = amendments?.filter((x) => !!x.finalizeDate);
    if (!filteredAmendments || filteredAmendments.length === 0) {
      return null;
    }
    return filteredAmendments.reduce((p, c) => (p.finalizeDate > c.finalizeDate ? p : c));
  }

  private getConvertedValue<T>(originalValue: any): T {
    const valueConverter = this.structuralDirectiveInfo.valueConverter.value;
    if (!valueConverter) {
      return originalValue;
    }

    const converterArgs = this.structuralDirectiveInfo.converterArgs.value;
    return Array.isArray(converterArgs)
      ? valueConverter.transform(originalValue, ...converterArgs)
      : valueConverter.transform(originalValue, converterArgs);
  }
}
