<h3 class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Graph</h3>

<div mat-dialog-content class="py-2">
  <ng-container *ngIf="isIep">
    <ng-container *ngIf="!iepGoal.isCombinedObjective">
      <app-chart-viewer [params]="{ IepGoalId: iepGoal.id }" [graph]="'iep_graph'"></app-chart-viewer>
    </ng-container>

    <ng-container *ngIf="iepGoal.isCombinedObjective">
      <app-chart-viewer [params]="{ IepGoalId: iepGoal.id }" [graph]="'iep_goal_combined'"></app-chart-viewer>
    </ng-container>

    <ng-container *ngIf="iepGoal.secondaryMeasurement">
      <app-chart-viewer
        [params]="{
          IepGoalQuantifiableMeasurementId: iepGoal.secondaryMeasurement.iepGoalQuantifiableMeasurementId
        }"
        [graph]="'iep_objective'"
      ></app-chart-viewer>
    </ng-container>

    <ng-container *ngFor="let objective of iepGoal.objectives">
      <ng-container *ngIf="iepGoal.isCombinedObjective">
        <app-chart-viewer
          [params]="{
            IepGoalQuantifiableMeasurementId: objective.primaryMeasurement.iepGoalQuantifiableMeasurementId,
            IepGoalId: iepGoal.id
          }"
          [graph]="'combined_iep_objective'"
        ></app-chart-viewer>
      </ng-container>

      <ng-container *ngIf="!iepGoal.isCombinedObjective">
        <app-chart-viewer
          [params]="{
            IepGoalQuantifiableMeasurementId: objective.primaryMeasurement.iepGoalQuantifiableMeasurementId
          }"
          [graph]="'iep_objective'"
        ></app-chart-viewer>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isIfsp">
    <ng-container *ngFor="let criterion of ifspOutcome.criteria">
      <app-chart-viewer [params]="{ OutcomeCriteriaId: criterion.id }" [graph]="'objective'"></app-chart-viewer>
    </ng-container>
  </ng-container>
</div>

<mat-dialog-actions align="end">
  <button type="button" mat-button aria-label="Delete Icon" [mat-dialog-close]="true" tabindex="0">Close</button>
</mat-dialog-actions>
