import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FamilySurveyService } from '../services/family-survey.service';
import { FamilySurveyAnswer, FamilySurveyQuestion, FamilySurveyResponse, QuestionBankCategory } from '../models/family-survey';
import { NotificationService } from '../../shared/services/notification.service';
import { EvaluationService } from '../services/evaluation.service';
import { Evaluation } from '../models/evaluation';

@Component({
  selector: 'app-family-survey',
  templateUrl: './family-survey.component.html',
  styleUrls: ['./family-survey.component.scss'],
})
export class FamilySurveyComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  survey: FamilySurveyResponse;
  formGroup: FormGroup = new FormGroup({});
  evalId: string;
  surveyId: string;
  readOnly = false;
  questionsK12: FamilySurveyQuestion[] = [];
  questionsTransition: FamilySurveyQuestion[] = [];
  questionsPreschool: FamilySurveyQuestion[] = [];
  questionsCustom: FamilySurveyQuestion;
  questionBankCategory = QuestionBankCategory;
  evaluation: Evaluation;
  pageTitle: string;

  constructor(
    private evaluationService: EvaluationService,
    private familySurveyService: FamilySurveyService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.surveyId = this.route.snapshot.paramMap.get('surveyId');
    this.evalId = this.route.snapshot.paramMap.get('evaluationId');

    this.survey = this.route.snapshot.data.survey as FamilySurveyResponse;
    this.readOnly = this.survey.isSubmitted;
    this.initializeForm();
    this.evaluationService.get(this.evalId).subscribe((evaluation) => {
      this.evaluation = evaluation;
      this.pageTitle = 'Questionnaire Survey - ' + evaluation.learner.fullName;
    });
  }

  initializeForm() {
    const group = {};

    this.survey.questions.forEach((q) => {
      const answer = this.survey.answers.find((x) => x.familySurveyQuestionId === q.id)?.answer;
      group[q.id] = [answer];
      switch (q.questionCategory) {
        case QuestionBankCategory.K12:
          this.questionsK12.push(q);
          break;
        case QuestionBankCategory.Transition:
          this.questionsTransition.push(q);
          break;
        case QuestionBankCategory.Preschool:
          this.questionsPreschool.push(q);
          break;
        case QuestionBankCategory.Custom:
          this.questionsCustom = q;
          break;
      }
    });

    this.formGroup = this.fb.group(group, {
      validators: this.answersValidator(),
    });
  }

  onSubmit(submit: boolean) {
    const response: FamilySurveyResponse = {
      familySurveyId: this.surveyId,
      isSubmitted: submit,
      questions: [],
      answers: [],
    };

    Object.keys(this.formGroup.controls).forEach((key) => {
      const answer = this.formGroup.get(key).value;
      if (!answer) {
        return;
      }
      response.answers.push({
        familySurveyQuestionId: key,
        answer,
      } as FamilySurveyAnswer);
    });

    this.familySurveyService.answer(this.evalId, this.surveyId, response).subscribe(
      () => {
        this.notificationService.success('Saved successfully.');
        this.readOnly = submit;
      },
      () => {
        this.notificationService.error('Saved failed.');
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public answersValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const notEmpty = Object.keys(this.formGroup.controls).some((key) => !!this.formGroup.get(key).value);
      return notEmpty ? null : { emptySurvey: true };
    };
  }
}
