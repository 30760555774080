<mat-accordion class="accordion accordion--close accordion--short" multi>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title
        ><h3 class="my-0">District Wide Assessment Iowa Statewide Assessment of Student Progress/Dynamic Learning Maps</h3></mat-panel-title
      >
    </mat-expansion-panel-header>
    <div *ngIf="isaspDataSource" class="table-overflow app-table">
      <table mat-table [dataSource]="isaspDataSource" class="w-100" matSort>
        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Subject</th>
          <td mat-cell *matCellDef="let element">
            {{ element.subject }}
          </td>
        </ng-container>

        <ng-container matColumnDef="averageScaleScore">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Average Scale Score</th>
          <td mat-cell *matCellDef="let element">
            {{ element.averageScaleScore }}
          </td>
        </ng-container>

        <ng-container matColumnDef="proficiency">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Proficiency</th>
          <td mat-cell *matCellDef="let element">
            {{ getProficiencyLabel(element.proficiency) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="schoolYear">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>School Year</th>
          <td mat-cell *matCellDef="let element">
            {{ element.schoolYear }}
          </td>
        </ng-container>

        <ng-container matColumnDef="gradeWhenAssessed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Grade When Assessed</th>
          <td mat-cell *matCellDef="let element">
            {{ getGradeLevelLabel(element.gradeWhenAssessed) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No results...</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          [hidden]="isaspDataSource?.data?.length > 0"
          mat-footer-row
          *matFooterRowDef="isaspDataSource.data?.length === 0 ? ['noResults'] : []"
        ></tr>
      </table>
      <mat-paginator [dataSource]="isaspDataSource"></mat-paginator>
    </div>
  </mat-expansion-panel>

  <!-- Removed as part of https://dev.azure.com/triskelle/IDOE/_workitems/edit/15084 -->
  <!-- <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>Literacy Screening Data</mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel> -->

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title
        ><h3 class="my-0">English Language Proficiency Assessment (for the 21st Century) Summative Assessment</h3></mat-panel-title
      >
    </mat-expansion-panel-header>
    <div *ngIf="elpaDataSource" class="table-overflow app-table">
      <table mat-table [dataSource]="elpaDataSource" class="w-100" matSort>
        <ng-container matColumnDef="domain">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain</th>
          <td mat-cell *matCellDef="let element">
            {{ element.domain }}
          </td>
        </ng-container>

        <ng-container matColumnDef="scaleScore">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Scale Score</th>
          <td mat-cell *matCellDef="let element">
            {{ element.scaleScore }}
          </td>
        </ng-container>

        <ng-container matColumnDef="performanceLevel">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Performance Level</th>
          <td mat-cell *matCellDef="let element">
            {{ getPerformanceLevelLabel(element.performanceLevel) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="schoolYear">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>School Year</th>
          <td mat-cell *matCellDef="let element">
            {{ element.schoolYear }}
          </td>
        </ng-container>

        <ng-container matColumnDef="gradeWhenAssessed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Grade When Assessed</th>
          <td mat-cell *matCellDef="let element">
            {{ getGradeLevelLabel(element.gradeWhenAssessed) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No results...</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="elpaDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: elpaDisplayedColumns"></tr>
        <tr
          [hidden]="elpaDataSource?.data?.length > 0"
          mat-footer-row
          *matFooterRowDef="elpaDataSource.data?.length === 0 ? ['noResults'] : []"
        ></tr>
      </table>
      <mat-paginator [dataSource]="elpaDataSource"></mat-paginator>
    </div>
  </mat-expansion-panel>
</mat-accordion>
