import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { EarlyAccessQuestionnaireInvite } from '../models/early-access-questionnaire-invite';
import { FamilyMemberRegistration } from '../models/family-member-registration';

const passwordsMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password').value;
  const confirmedPassword = control.get('confirmedPassword').value;

  return password !== confirmedPassword ? { passwordMismatch: true } : null;
};

@Component({
  selector: 'app-family-member-registration-form',
  templateUrl: './family-member-registration-form.component.html',
  styleUrls: ['./family-member-registration-form.component.scss'],
})
export class FamilyMemberRegistrationFormComponent implements OnInit {
  @Input() questionnaireInvite: EarlyAccessQuestionnaireInvite;
  @Input() registerError: string;
  @Output() submitted = new EventEmitter<FamilyMemberRegistration>();
  formGroup: FormGroup;

  get skipAccountCreation() {
    return this.formGroup.get('skipAccountCreation').value;
  }

  constructor(private fb: FormBuilder, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.initializeFormGroup();
    this.formGroup.patchValue({
      earlyAccessQuestionnaireId: this.questionnaireInvite.id,
      username: this.questionnaireInvite.email,
    });
  }

  onLoginSubmit(event) {
    this.formGroup.patchValue({
      username: event.email,
      password: event.password,
    });
    this.onSubmit();
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.valid) {
      this.submitted.emit(this.formGroup.value);
    }
  }

  initializeFormGroup() {
    if (this.questionnaireInvite.familyUserId && this.questionnaireInvite.verified) {
      this.formGroup = this.fb.group(
        {
          username: ['', { validators: [Validators.required] }],
          password: ['', { validators: [Validators.required] }],
        },
        { updateOn: 'blur' }
      );
    } else if (this.questionnaireInvite.familyUserId && !this.questionnaireInvite.hasAnonAccount) {
      this.formGroup = this.fb.group(
        {
          learnerFirstName: [
            '',
            {
              validators: [Validators.required],
            },
          ],
          learnerLastName: ['', { validators: [Validators.required] }],
          learnerDateOfBirth: ['', { validators: [Validators.required] }],
          username: ['', { validators: [Validators.required], updateOn: 'change' }],
          password: ['', { validators: [Validators.required], updateOn: 'change' }],
        },
        { updateOn: 'blur' }
      );
    } else {
      this.formGroup = this.fb.group(
        {
          learnerFirstName: [
            '',
            {
              validators: [Validators.required],
            },
          ],
          learnerLastName: ['', { validators: [Validators.required] }],
          learnerDateOfBirth: ['', { validators: [Validators.required] }],
          username: ['', { validators: [Validators.required] }],
          password: ['', { validators: [Validators.required], updateOn: 'change' }],
          confirmedPassword: ['', { validators: [Validators.required], updateOn: 'change' }],
        },
        { updateOn: 'blur', validators: passwordsMatchValidator }
      );
    }
    this.formGroup.addControl('earlyAccessQuestionnaireId', new FormControl(null, [Validators.required]));
    this.formGroup.addControl('skipAccountCreation', new FormControl(false, { updateOn: 'change' }));

    this.formGroup.get('skipAccountCreation').valueChanges.subscribe((skipAccountCreation) => {
      const controls = [this.formGroup.get('password'), this.formGroup.get('confirmedPassword')];

      if (skipAccountCreation) {
        const dialogRef = this.dialog.open(AreYouSureComponent, {
          data: {
            subQuestion:
              'If you continue without creating an account, you will not be able to return to the questionnaire after you close the window.',
          },
          width: '450px',
        });

        dialogRef.afterClosed().subscribe((yesImSure) => {
          if (yesImSure) {
            controls.forEach((c) => {
              c.clearValidators();
              c.updateValueAndValidity();
            });
          } else {
            this.formGroup.get('skipAccountCreation').setValue(false);
          }
        });
      } else {
        controls.forEach((c) => {
          c.setValidators([Validators.required]);
          c.updateValueAndValidity();
        });
      }
    });
  }
}
