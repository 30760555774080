import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IfspMedicalOtherService, IfspMedicalOtherServiceAddUpdateDto } from '../models/ifsp-medicalotherservice-models';
@Injectable({
  providedIn: 'root',
})
export class IfspMedicalOtherServiceService {
  constructor(private readonly http: HttpClient) {}

  private baseUrl(ifspId = '') {
    return `api/${ifspId}/ifspmedicalotherservice`;
  }

  updateService(ifspId: string, model: IfspMedicalOtherServiceAddUpdateDto) {
    return this.http.put<IfspMedicalOtherService>(this.baseUrl(ifspId), model);
  }

  deleteService(ifspId: string, id: string) {
    return this.http.put(this.baseUrl(ifspId) + '/' + id, null);
  }

  getServices(ifspId: string) {
    return this.http.get<Array<IfspMedicalOtherService>>(this.baseUrl(ifspId));
  }

  getServiceById(ifspId: string, id: string) {
    return this.http.get<IfspMedicalOtherService>(this.baseUrl(ifspId) + '/' + id);
  }
}
