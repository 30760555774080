import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MedicalCondition } from '../../../../../../shared/models/medical-condition';
import { DataHistoryService } from '../../../../services/data-history.service';

@Component({
  selector: 'app-data-history-part-c-diagnosis',
  templateUrl: './data-history-part-c-diagnosis.component.html',
  styleUrls: ['./data-history-part-c-diagnosis.component.scss'],
})
export class DataHistoryPartCDiagnosisComponent implements OnInit {
  @Input() dataSource = new MatTableDataSource<MedicalCondition>([]);
  @Input() learnerId: string;
  displayedColumns = ['name', 'icdCode', 'diagnosisQualifies'];
  constructor(private dataHistoryService: DataHistoryService) {}

  async ngOnInit() {
    this.dataHistoryService.getLearnerMedicalHistory(this.learnerId).subscribe((res) => {
      this.dataSource = new MatTableDataSource<MedicalCondition>(res);
    });
  }
}
