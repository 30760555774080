<mat-accordion class="accordion accordion--close accordion--shadow accordion--short" multi *ngIf="dataLoaded">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex align-items-center justify-content-between flex-1">
          <h3 class="my-0 text-normal">Questionnaire Results</h3>
          <span
            >({{ completedSurveys }} of {{ totalSurveysSent }} responded)
            <button
              mat-icon-button
              aria-label="Refresh Icon"
              color="primary"
              (click)="refreshSurveys()"
              class="ml-2"
              matTooltip="Refresh"
              tabindex="0"
            >
              <mat-icon aria-label="Refresh Icon"> refresh </mat-icon>
            </button></span
          >
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <!-- BODY 1 -->
    <div class="display-flex align-items-baseline">
      <label class="text-md horizontal-label"> View By </label>
      <mat-radio-group aria-labelledby="View By" [(ngModel)]="viewById" class="ml-5">
        <ng-container *ngFor="let option of viewByOptions">
          <mat-radio-button [value]="option.key" class="w-100 mb-4" attr.aria-label="{{ option.value }}">
            {{ option.value }}
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>
    </div>

    <div class="divider mt-3"></div>

    <mat-accordion class="accordion accordion--close accordion--short" multi *ngFor="let survey of getSurveys()">
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="display-flex align-items-center justify-content-between flex-1">
              <h3 class="my-0 text-normal">{{ survey.userFullName }}</h3>
              <span *ngIf="viewById === 'teamMember'">Submitted on {{ survey.generalSurveyDto.completedDateTime | dateFormat }}</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- BODY 2 -->

        <div class="table-overflow app-table">
          <table
            mat-table
            [dataSource]="
              getOrCreateSurveyDataSource(survey.generalSurveyDto.id, survey.generalSurveyDto.generalSurveyGeneralSurveyQuestionDtos)
            "
            class="w-100"
            matSort
            #surveyTable="matTable"
          >
            <ng-container matColumnDef="question">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ viewById === 'teamMember' ? 'Question' : 'Team Member' }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.generalSurveyQuestionDto.question }}
              </td>
            </ng-container>

            <ng-container matColumnDef="response">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Response</th>
              <td mat-cell *matCellDef="let element">
                {{ element.generalSurveyAnswerDto.answer }}
              </td>
            </ng-container>

            <ng-container matColumnDef="noResults">
              <td mat-footer-cell *matFooterCellDef colspan="7">
                <i>No results...</i>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr
              [hidden]="dataSource?.data?.length > 0"
              mat-footer-row
              *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
              class="example-second-footer-row"
            ></tr>
          </table>
          <mat-paginator [dataSource]="surveyTable.dataSource"></mat-paginator>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion class="accordion accordion--close accordion--short" multi *ngIf="viewById === 'teamMember'">
      <mat-expansion-panel class="mat-elevation-z0" *ngFor="let userFullName of notSubmittedSurveys">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="display-flex align-items-center justify-content-between flex-1">
              <h3 class="my-0 text-normal">{{ userFullName }}</h3>
              <span>Not Submitted</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-expansion-panel>
  <form [formGroup]="formGroup">
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="display-flex align-items-center justify-content-between flex-1">
            <h3 class="my-0 text-normal">Outcome Status</h3>
            <button
              mat-raised-button
              aria-label="Add a New Outcome"
              (click)="goToOutcomes($event)"
              color="primary"
              *ngIf="isPeriodicReview && !this.latestReview?.closedOn && canView"
              tabindex="0"
            >
              Add a New Outcome
            </button>
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-help-directional-text *ngIf="isPeriodicReview && !this.latestReview?.closedOn && canView">
        <p class="card-spacing-bottom">
          If the outcome will be changed, discontinued, or has been met, the IFSP needs to be manually modified by clicking the "Open IFSP
          for Modifications" button.
        </p>
      </app-help-directional-text>
      <mat-accordion
        class="accordion accordion--close accordion--short"
        multi
        *ngFor="let outcomeReview of latestReview?.ifspPeriodicAnnualReviewOutcomes"
      >
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="display-flex align-items-center justify-content-between flex-1">
                <h3 class="my-0 text-normal">
                  {{ outcomeReview.outcomeTitle
                  }}<span *ngIf="formGroup.controls[outcomeReview.ifspOutcomeId].hasError('required')" class="asterisk_input">&nbsp;</span>
                </h3>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="display-flex align-items-baseline">
            <fieldset>
              <app-radio-group
                formControlName="{{ outcomeReview.ifspOutcomeId }}"
                [options]="outcomeReviewOptions"
                [useUndo]="false"
                [hideAsterisk]="true"
                [column]="true"
              ></app-radio-group>
            </fieldset>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="display-flex align-items-baseline" *ngIf="isPeriodicReview && !latestReview?.closedOn && canView">
        <span class="display-flex align-items-center">
          <button mat-raised-button aria-label="Open IFSP for Modifications" (click)="modifyIfsp()" color="primary" tabindex="0">
            Open IFSP for Modifications
          </button>
        </span>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0" *ngIf="!isPeriodicReview && !hasFinalizedEvaluation">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="my-0 text-normal">Eligibility</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-help-directional-text>
        <p>Review evaluation and assessment data to determine and document continued eligibility.</p>
      </app-help-directional-text>
      <div class="display-flex mt-2">
        <button
          (click)="onReviewEvaluationAndAssessmentData()"
          color="primary"
          mat-raised-button
          aria-label="Review Evaluation and Assessment Data"
          tabindex="0"
        >
          Review Evaluation and Assessment Data
        </button>
      </div>
    </mat-expansion-panel>
  </form>
  <mat-expansion-panel class="mat-elevation-z0" [expanded]="true" [hideToggle]="true" [disabled]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex align-items-center justify-content-between flex-1">
          <div>
            <button
              mat-icon-button
              aria-label="More option"
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
              color="primary"
              *ngIf="!this.latestReview?.closedOn && canView"
              tabindex="0"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button mat-menu-item aria-label="Schedule Meeting" (click)="scheduleMeeting()" tabindex="0">Schedule Meeting</button>
              <button mat-menu-item aria-label="Begin Modifications" (click)="modifyIfsp()" *ngIf="isPeriodicReview" tabindex="0">
                Begin Modifications
              </button>
              <ng-container *appAuthForRoles="{ permission: [permissions.CreateIFSP], caseId: caseId }">
                <button
                  *ngIf="isPeriodicReview"
                  mat-menu-item
                  aria-label="Send Periodic Review Questionnaire"
                  (click)="sendPeriodicReviewPoll()"
                  tabindex="0"
                >
                  Send Periodic Review Questionnaire
                </button>
                <button
                  *ngIf="!isPeriodicReview"
                  mat-menu-item
                  aria-label="Send Annual Review Questionnaire"
                  (click)="sendAnnualReviewPoll()"
                  tabindex="0"
                >
                  Send Annual Review Questionnaire
                </button>
              </ng-container>
              <button
                *ngIf="!isPeriodicReview && !hasFinalizedEvaluation"
                mat-menu-item
                aria-label="Review Evaluation and Assessment Data"
                (click)="onReviewEvaluationAndAssessmentData()"
                color="primary"
                tabindex="0"
              >
                Review Evaluation and Assessment Data
              </button>
              <button mat-menu-item aria-label="Print Periodic Review Questionnaire" (click)="openPartCTeamMembersSurvey()" tabindex="0">
                Print {{ isPeriodicReview ? 'Periodic' : 'Annual' }} Review Questionnaire
              </button>
            </mat-menu>
            <span *ngIf="isPeriodicReview">Periodic Review Actions</span>
            <span *ngIf="!isPeriodicReview">Annual Review Actions</span>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="formGroup">
      <fieldset>
        <app-radio-group
          label="IFSP Modifications"
          [wrap]="true"
          [useUndo]="latestReview?.closedOn === null"
          formControlName="ifspModificationsComplete"
          [options]="periodicReviewOptions"
          *ngIf="isPeriodicReview"
        ></app-radio-group>
      </fieldset>

      <app-textarea label="{{ reviewName }} Review Summary" formControlName="periodicReviewSummary" [rows]="4"></app-textarea>

      <div class="display-flex justify-content-end align-items-center">
        <button
          mat-raised-button
          attr.aria-label="{{ isPeriodicReview ? 'Cancel Periodic Review' : 'Cancel Annual Review' }}"
          color="primary"
          *appAuthForRoles="{ permission: [permissions.CancelAnnualPeriodicReview], caseId: caseId }"
          class="ml-2"
          (click)="onDelete()"
          [disabled]="!!latestReview?.closedOn"
          tabindex="0"
        >
          <span>{{ isPeriodicReview ? 'Cancel Periodic Review' : 'Cancel Annual Review' }}</span>
        </button>
        <button
          *ngIf="canView && !latestReview?.closedOn"
          mat-raised-button
          attr.aria-label="{{ isPeriodicReview ? 'Complete Periodic Review' : 'Complete Annual Review' }}"
          color="primary"
          class="ml-2"
          (click)="closeReview()"
          [disabled]="formGroup.invalid || isSaving || finalizeDisabled"
          tabindex="0"
          [hidden]="this.latestReview?.closedOn"
        >
          <span>{{ isPeriodicReview ? 'Complete Periodic Review' : 'Complete Annual Review' }}</span>
        </button>
      </div>
    </form>
  </mat-expansion-panel>
</mat-accordion>
