import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataHistoryService } from '../../../../services/data-history.service';

@Component({
  selector: 'app-data-history-part-b-attendance-behavior',
  templateUrl: './data-history-part-b-attendance-behavior.component.html',
  styleUrls: ['./data-history-part-b-attendance-behavior.component.scss'],
})
export class DataHistoryPartBAttendanceBehaviorComponent implements OnInit {
  @Input() learnerId: string;
  @Input() caseId: string;

  attendanceDisplayedColumns = ['schoolName', 'schoolYear', 'daysAttended', 'daysInMembership'];
  behaviorDisplayedColumns = ['schoolName', 'numInSchoolSuspensions', 'numOutSchoolSuspensions', 'expulsions'];
  attendanceDataSource: MatTableDataSource<any>;
  behaviorDataSource: MatTableDataSource<any>;

  constructor(private dataHistoryService: DataHistoryService) {
    this.attendanceDataSource = new MatTableDataSource<any>([]);
    this.behaviorDataSource = new MatTableDataSource<any>([]);
  }

  ngOnInit(): void {
    this.dataHistoryService.getBehaviorData(this.caseId, this.learnerId).subscribe(
      (data) => {
        if (!data.message) {
          this.behaviorDataSource = new MatTableDataSource<any>(data?.map((x) => x.summary) || []);
        } else {
          this.behaviorDataSource = new MatTableDataSource<any>([]);
        }
      },
      () => {
        this.behaviorDataSource = new MatTableDataSource<any>([]);
      }
    );
    this.dataHistoryService.getAttendanceData(this.caseId, this.learnerId).subscribe(
      (data) => {
        if (!data.message) {
          this.attendanceDataSource = new MatTableDataSource<any>(data || []);
        } else {
          this.attendanceDataSource = new MatTableDataSource<any>([]);
        }
      },
      () => {
        this.attendanceDataSource = new MatTableDataSource<any>([]);
      }
    );
  }
}
