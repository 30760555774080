<h3 class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>View More</h3>

<div class="mat-dialog-content py-2 white-space-pre-line">
  <mat-card>
    <ul class="list">
      <li class="list__item" *ngFor="let w of data; first as isFirst" [ngClass]="{ 'd-none': !w.value }">
        <ng-container *ngIf="w.value">
          <ng-container *ngIf="w.nested && w.value?.length > 0">
            <h3 class="text-primary mt-0 mb-1" *ngIf="w.name">
              {{ w.name }}
            </h3>
            <ul class="list">
              <li class="list__item" *ngFor="let x of w.value" [ngClass]="{ 'd-none': !x.value }">
                <ng-container *ngIf="x.value">
                  <h4 class="text-primary text-semibold text-md mt-0 mb-1" *ngIf="x.name && !x.isUrl">
                    {{ x.name }}
                  </h4>
                  <ng-container *ngIf="x.nested && x.value?.length > 0">
                    <ul class="list">
                      <li class="list__item" *ngFor="let y of x.value; first as isFirstY" [ngClass]="{ 'd-none': !y.value }">
                        <ng-container *ngIf="y.value">
                          <h5 class="text-primary text-md mt-0 mb-1" *ngIf="y.name">
                            {{ y.name }}
                          </h5>
                          <ng-container *ngIf="checkIfArray(y.value) === false">
                            <p class="my-0">{{ y.value }}</p>
                          </ng-container>
                          <ul class="list" *ngIf="checkIfArray(y.value) === true">
                            <li class="list__item" [ngClass]="{ 'd-none': !z.value }" *ngFor="let z of y.value">
                              <ng-container *ngIf="z.value">
                                <h5 class="text-primary text-md mt-0 mb-1" *ngIf="z.name">
                                  {{ z.name }}
                                </h5>
                                <ng-container *ngIf="checkIfArray(z.value) === false">
                                  <p class="my-0">{{ z.value }}</p>
                                </ng-container>
                                <ul class="list" *ngIf="checkIfArray(z.value) === true">
                                  <li class="list__item" [ngClass]="{ 'd-none': !zz.value }" *ngFor="let zz of z.value">
                                    <ng-container *ngIf="zz.value">
                                      <p class="text-primary text-md mt-0 mb-1" *ngIf="zz.name">
                                        {{ zz.name }}
                                      </p>
                                      <ng-container *ngIf="checkIfArray(zz.value) === false">
                                        <p class="my-0">
                                          {{ zz.value }}
                                        </p>
                                      </ng-container>
                                      <div *ngIf="checkIfArray(zz.value) === true">
                                        <span *ngFor="let zzz of zz.value">
                                          <p class="text-primary text-md mt-0 mb-1" *ngIf="zzz.name">
                                            {{ zzz.name }}
                                          </p>
                                          <p class="my-0">
                                            {{ zzz.value }}
                                          </p>
                                        </span>
                                      </div>
                                    </ng-container>
                                  </li>
                                </ul>
                              </ng-container>
                            </li>
                          </ul>
                        </ng-container>
                      </li>
                    </ul>
                  </ng-container>
                  <p *ngIf="!x.nested && !x.isUrl" class="my-0">
                    {{ x.value }}
                  </p>
                  <a
                    class="my-0"
                    *ngIf="!x.nested && x.isUrl"
                    [href]="x.value"
                    target="_blank"
                    tabindex="0"
                    role="link"
                    attr.aria-label="{{ x.name }}"
                    >{{ x.name }}</a
                  >
                </ng-container>
              </li>
            </ul>
          </ng-container>

          <ng-container *ngIf="!w.nested">
            <h4 class="text-primary mt-0 mb-1" *ngIf="w.name">
              {{ w.name }}
            </h4>
            <p class="my-0">
              {{ w.value }}
            </p>
          </ng-container>
        </ng-container>
      </li>
    </ul>
  </mat-card>
</div>

<div class="mat-dialog-actions justify-content-end">
  <button mat-raised-button aria-label="Delete Icon" (click)="onClose()" cdkFocusInitial tabindex="0">Close</button>
</div>
