import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DeleteReason } from 'src/app/shared/models/delete-reason';
import { IepAmendment } from '../models/iep-amendment';
import { IepCode } from '../models/iep-code';

@Injectable({
  providedIn: 'root',
})
export class IepAmendmentService {
  private amendmentsUpdated = new Subject<void>();
  amendmentUpdated$ = this.amendmentsUpdated.asObservable();

  constructor(private readonly http: HttpClient) {}

  private baseUrl(iepId: string) {
    return `api/iep/${iepId}/amendment/`;
  }

  getAmendment(iepId: string, id: string) {
    return this.http.get<IepAmendment>(this.baseUrl(iepId) + id);
  }

  getAllIepCodes(iepId) {
    return this.http.get<IepCode[]>(this.baseUrl(iepId) + 'getAllIepCodes');
  }

  getAmendmentsByIepId(iepId: string) {
    return this.http.get<IepAmendment[]>(this.baseUrl(iepId));
  }

  updateAmendment(iepId: string, model: any) {
    return this.http.put<any>(this.baseUrl(iepId), model).pipe(tap(() => this.amendmentsUpdated.next()));
  }

  deleteAmendment(iepId: string, reason: string) {
    const deleteReason: DeleteReason = { id: iepId, reason: reason };
    return this.http.put<string>(this.baseUrl(iepId) + 'deleteAmendment', deleteReason);
  }
}
