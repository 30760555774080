<p class="form__description">
  Describe the effect of this individual's disability on involvement and progress in the general education curriculum and the functional
  implications of the student's skill.
</p>

<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-md-12">
      <label class="vertical-label display-block position-fix--label" for="involvement"
        >Describe the student's Involvement and Progress in general education</label
      >
      <app-textarea formControlName="involvement" id="involvement" [hideAsterisk]="true"></app-textarea>
    </div>

    <div class="col-md-12 position-fix--col">
      <label class="vertical-label display-block position-fix--label" for="involvementName">
        Describe how [LEARNER'S NAME] will participate in nonacademic activities with nondisabled peers, and have the same opportunity to
        participate extracurricular activities as nondisabled peers
      </label>
      <app-textarea formControlName="involvement" id="involvementName" [hideAsterisk]="true"></app-textarea>
    </div>
  </div>
</form>
