import { Help, HelpSection } from '../help';
import { EvalAssessmentDetailsHelp, EvaluationAdditionalFactorsHelp, EvaluationScHelp } from '../models/evaluation.help';

export function getEvaluationDictionary(): Help[] {
  return [].concat(getEvalAssessmentDetailsDictionary(), getEvaluationAfDictionary(), getEvaluationScDictionary());
}

export function getEvalAssessmentDetailsDictionary(): Help[] {
  return [
    {
      title: HelpSection.EvalAssessmentDetails,
      dictionary: [
        {
          label: EvalAssessmentDetailsHelp.Tagged,
          descriptor: 'Tagging will compile and track information across the IFSP process for later review.',
        },
        {
          label: EvalAssessmentDetailsHelp.EcoOther,
          descriptor: 'Evaluation details with no routine tagged but are associated with ECO areas',
        },
      ],
    },
  ];
}

export function getEvaluationAfDictionary(): Help[] {
  return [
    {
      title: HelpSection.EvaluationAf,
      dictionary: [
        {
          label: EvaluationAdditionalFactorsHelp.Sld,
          descriptor: `
            “Specific learning disability” means a disorder in one or more of the basic psychological processes involved in understanding or in using language, spoken or written, that may manifest itself in the imperfect ability to listen, think, speak, read, write, spell, or to do mathematical calculations, including conditions such as perceptual disabilities, brain injury, minimal brain dysfunction, dyslexia, and developmental aphasia. A specific learning disability does not include
learning problems that are primarily the result of visual, hearing, or motor disabilities, intellectual disability, emotional disturbance, environmental, cultural, or economic disadvantage.
            `,
        },
      ],
    },
  ];
}

export function getEvaluationScDictionary(): Help[] {
  return [
    {
      title: HelpSection.EvaluationSc,
      dictionary: [
        {
          label: EvaluationScHelp.BackgroundInfo,
          descriptor:
            'Provide a historical synthesis (as opposed to summarizing every piece of evidence) of the concern(s) including but not limited to: history of access to age/grade appropriate learning opportunities and concerns that may have emerged over time, educationally relevant medical information, educationally relevant developmental information, history of interventions/supports put in place through parent(s) and school to address the concerns, educationally relevant family history, and English language acquisition.',
        },
      ],
    },
  ];
}
