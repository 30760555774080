export enum SignedFormTypes {
  PwnEvalConsent = 'PwnEvalConsent',
  LearningMediaPlan = 'LearningMediaPlan',
  IepFinal = 'IepFinal',
  DeterminationOfEligibility = 'DeterminationOfEligibility',
  ElectronicCommunicationConsent = 'ElectronicCommunicationConsent',
  BehaviorInterventionPlan = 'BehaviorInterventionPlan',
  IepAmendment = 'IepAmendment',
  ServiceProvisionConsent = 'ServiceProvisionConsent',
  SldExtension = 'SldExtension',
  StudentTransfer = 'StudentTransfer',
  AgencyInvitation = 'AgencyInvitation',
  PerformanceSummary = 'PerformanceSummary',
  ReleaseOfConfidentialInfo = 'ReleaseOfConfidentialInfo',
  ManifestationDetermination = 'ManifestationDetermination',
  MedicaidAEA = 'MedicaidAEA',
  MedicaidLEA = 'MedicaidLEA',
}
