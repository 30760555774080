import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { Frequency, TimeUnit } from 'src/app/shared/models/time';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { IepServiceHelp } from 'src/app/shared/services/help/models/iep.help';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AppPermissions } from '../../../permissions';
import { AreYouSureComponent } from '../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { yesNoOptions } from '../../../shared/formHelpers';
import { ViewMoreModalData } from '../../../shared/modals/view-more-modal/view-more-modal.component';
import { CaseSummary } from '../../../shared/models/case';
import { PhonePipe } from '../../../shared/pipes/phone.pipe';
import { openViewMore } from '../../../shared/tableHelpers';
import { phoneValidator } from '../../../shared/validators';
import { EarlyChildhoodProgram } from './ec-programs';
import { EcProgramsService } from './ec-programs.service';

interface Row extends EarlyChildhoodProgram {
  howMuch: string;
}

@Component({
  selector: 'app-ec-programs',
  templateUrl: './ec-programs.component.html',
  styleUrls: ['./ec-programs.component.scss'],
})
export class EcProgramsComponent implements OnInit {
  @Input() learnerId: string;
  @Input() kindergartenStartDate: Date;
  @Output() refreshCase = new EventEmitter();
  @Input() learnerHasWorkableCase = true;
  @Input() caseSummary: CaseSummary;

  @ViewChild('ecProgramsPanel') ecProgramsPanel: MatExpansionPanel;
  private phonePipe = new PhonePipe();
  shortDateFormat = shortDateFormat;
  yesNoOptions = yesNoOptions;
  helpSection = HelpSection;
  iepServiceHelp = IepServiceHelp;

  formGroup = new FormGroup({
    id: new FormControl(null),
    programTypeId: new FormControl(null, Validators.required),
    projectedStartDate: new FormControl(null, Validators.required),
    endDate: new FormControl(null),
    timeQuantity: new FormControl(null, Validators.required),
    timeUnit: new FormControl(null, Validators.required),
    frequency: new FormControl(Frequency.Weekly, Validators.required),
    programName: new FormControl(),
    contactName: new FormControl(),
    contactPhone: new FormControl('', phoneValidator),
    contactEmail: new FormControl('', Validators.email),
    inviteToIepMeeting: new FormControl(null, { updateOn: 'change' }),
    attendRecp: new FormControl(null),
  });
  kindergartenFormGroup = new FormGroup({
    startDate: new FormControl(null),
  });

  timeUnitOptions: KeyValuePair[] = [new KeyValuePair(TimeUnit.Minutes, 'minutes'), new KeyValuePair(TimeUnit.Hours, 'hours')];

  dataSource = new MatTableDataSource<Row>();
  displayedColumns = ['actions', 'programType', 'howMuch', 'projectedStartDate', 'attendRECP'];
  typeOptions: KeyValuePair[];
  isEditing = false;

  get canDelete() {
    return this.learnerHasWorkableCase && (this.authService.isSuperAdmin || this.authService.isUberAdmin || this.authService.isDataLead);
  }

  get hasEditPermission() {
    return (
      this.learnerHasWorkableCase &&
      this.authService.isAllowedByCaseId(this.caseSummary?.id, undefined, AppPermissions.EditRegularEarlyChildhoodPrograms)
    );
  }

  get canChangeKindergartenStartDate() {
    return (
      this.learnerHasWorkableCase && this.authService.isAllowedByCaseId(this.caseSummary?.id, null, AppPermissions.EditKindergartenDate)
    );
  }

  constructor(
    private ecProgramsService: EcProgramsService,
    private learnerService: LearnerService,
    private readonly helpService: HelpService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.ecProgramsService
      .getTypes(this.learnerId)
      .subscribe(
        (types) => (this.typeOptions = types.map((t) => new KeyValuePair(t.id, t.label)).sort((a, b) => (a.value < b.value ? -1 : 1)))
      );
    this.getEcPrograms();
    this.kindergartenFormGroup.get('startDate').patchValue(this.kindergartenStartDate);
    if (this.canChangeKindergartenStartDate) {
      this.kindergartenFormGroup
        .get('startDate')
        .valueChanges.pipe(debounceTime(250))
        .subscribe((startDate) => {
          const callback = () => {
            this.learnerService.updateKindergartenStartDate(this.learnerId, startDate, true).subscribe(() => {
              this.refreshCase.emit(true);
            });
          };

          if (this.kindergartenStartDate) {
            const dialogRef = this.dialog.open(AreYouSureComponent, {
              width: '450px',
              data: {
                question: 'Are you sure?',
                subQuestion:
                  'Updating the Kindergarten start date will update the IEP Service start/end dates. Are you sure you want to proceed?',
              },
            });
            dialogRef.afterClosed().subscribe((confirmed) => {
              if (confirmed) {
                callback();
              } else {
                this.kindergartenFormGroup.get('startDate').patchValue(this.kindergartenStartDate, { emitEvent: false });
              }
            });
          } else {
            callback();
          }
        });
    } else {
      setTimeout(() => this.kindergartenFormGroup.disable(), 100);
    }

    this.formGroup.get('inviteToIepMeeting').valueChanges.subscribe((value) => {
      const contactName = this.formGroup.get('contactName');
      const contactEmail = this.formGroup.get('contactEmail');
      if (value) {
        contactName.setValidators(Validators.required);
        contactEmail.setValidators([Validators.email, Validators.required]);
      } else {
        contactName.clearValidators();
        contactEmail.setValidators(Validators.email);
      }
      contactName.updateValueAndValidity();
      contactEmail.updateValueAndValidity();
    });

    if (this.learnerHasWorkableCase === false) {
      setTimeout(() => this.formGroup.disable(), 100);
    }
  }

  onAttendRecpChange(event, ecp: EarlyChildhoodProgram) {
    ecp.attendRecp = event;
    const learnerId = this.learnerId;
    this.ecProgramsService.save({ ...ecp, learnerId }).subscribe(() => {
      this.getEcPrograms();
    });
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm): void {
    e.preventDefault();
    const dictionary = this.helpService.getIepServiceDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  getEcPrograms() {
    this.ecProgramsService.getPrograms(this.learnerId).subscribe((programs) => {
      if (!programs || programs.length === 0) {
        this.ecProgramsPanel.open();
      }
      this.dataSource.data = programs.map((p) => {
        const { timeQuantity, timeUnit, frequency } = p;
        const howMuch = `${timeQuantity} ${timeQuantity === 1 ? timeUnit.slice(0, -1) : timeUnit} ${frequency}`.toLocaleLowerCase();
        return { ...p, howMuch };
      });
    });
  }

  save() {
    if (this.formGroup.valid) {
      const learnerId = this.learnerId;
      this.ecProgramsService.save({ ...this.formGroup.value, learnerId }).subscribe(() => {
        this.getEcPrograms();
        this.isEditing = false;
        this.formGroup.reset();
        this.formGroup.get('frequency').setValue(Frequency.Weekly);
      });
    }
  }

  cancel() {
    this.isEditing = false;
    this.formGroup.reset();
    this.formGroup.get('frequency').setValue(Frequency.Weekly);
  }

  edit(row: Row) {
    this.isEditing = true;
    this.formGroup.patchValue(row as EarlyChildhoodProgram);
  }

  remove(row: Row) {
    const confirmedRemove = () => {
      const learnerId = this.learnerId;
      this.ecProgramsService.remove({ ...row, learnerId }).subscribe(() => this.getEcPrograms());
    };

    this.notificationService.confirmation('Are you sure you want to remove this program?', confirmedRemove);
  }

  onViewMore(row: Row) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Program Name',
        value: row.programName,
      },
      {
        name: 'Contact Name',
        value: row.contactName,
      },
      {
        name: 'Contact phone',
        value: this.phonePipe.transform(row.contactPhone),
      },
      {
        name: 'Contact Email',
        value: row.contactEmail,
      },
      {
        name: 'Invite To IEP Meeting',
        value: row.inviteToIepMeeting,
      },
    ];

    openViewMore(this.dialog, modalData);
  }
}
