<ng-container *ngIf="isReady">
  <app-evaluation-header *ngIf="!isPopup"></app-evaluation-header>

  <div class="animation-container" [@routerAnimation]="getRouteAnimation(routerOutlet)" aria-live="polite">
    <router-outlet #routerOutlet="outlet"></router-outlet>
  </div>
</ng-container>
<ng-container *ngIf="errorMessage && errorMessage === noConsent">
  <app-eer [showActions]="false"></app-eer>
</ng-container>
<ng-container *ngIf="errorMessage && errorMessage !== noConsent">
  <mat-card>
    {{ errorMessage }}
  </mat-card>
</ng-container>
