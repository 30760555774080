import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NonAchieveParticipant, NonAchieveParticipantType } from '../non-achieve-participant';

@Injectable({
  providedIn: 'root',
})
export class NonAchieveParticipantsService {
  constructor(private http: HttpClient) {}

  get(id: string, type: NonAchieveParticipantType) {
    const params = this.createParams(id, type);

    return this.http.get<Array<NonAchieveParticipant>>('api/non-achieve-participants', { params });
  }

  create(id: string, type: NonAchieveParticipantType, participant: NonAchieveParticipant) {
    const params = this.createParams(id, type);
    return this.http.post<NonAchieveParticipant>('api/non-achieve-participants', participant, { params });
  }

  update(participant: NonAchieveParticipant) {
    return this.http.put(`api/non-achieve-participants/${participant.id}`, participant);
  }

  remove(participantId: string) {
    return this.http.put(`api/non-achieve-participants/delete/${participantId}`, null);
  }

  private createParams(id: string, type: NonAchieveParticipantType) {
    // Initialize Params Object
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('id', id);
    params = params.append('participantType', type);
    return params;
  }
}
