<div *ngIf="isReadOnly || !!postSecondarySummary?.finalizedOn">
  <button mat-raised-button aria-label="Finalize" (click)="viewPostsecondarySummary()" color="primary" tabindex="0">
    View Postsecondary Summary
  </button>
</div>
<div *ngIf="!isReadOnly && !postSecondarySummary?.finalizedOn">
  <form [formGroup]="formGroup" *ngIf="caseSummary">
    <h3 class="mt-0 mb-2">Postsecondary Summary for Living, Learning, and Working</h3>
    <mat-card class="card-spacing-bottom">
      <mat-accordion class="accordion accordion--close accordion--short" multi>
        <mat-expansion-panel *ngFor="let iep of ieps" class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="display-flex flex-1 justify-content-between flex-wrap">
                <h3 class="my-0 text-normal">IEP</h3>
                <span class="text-md">{{ iep.activatedOn | dateFormat }}</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ul class="list" *ngFor="let goal of iep?.iepGoalDtos">
            <li class="list__item" *ngIf="goal.goalNickName">
              <p class="my-0">
                <strong>Goal:</strong>
                {{ goal.goalNickName }}
              </p>
            </li>
            <li class="list__item" *ngIf="goal.goalAreas?.length > 0">
              <ul class="list">
                <ng-container *ngFor="let ga of goal.goalAreas; first as isFirst">
                  <p class="my-0 pt-2" *ngIf="isFirst">
                    <strong>Goal Area(s):</strong>
                  </p>
                  <li class="list__item ml-2" [ngClass]="{ 'pt-1': isFirst }">
                    <p class="my-0">
                      {{ ga.label }}
                    </p>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="row">
        <div class="col-md-12">
          <label
            class="vertical-label text-md display-block"
            [ngClass]="{
              asterisk_input: formGroup.get('currentFunctionalAchievement').hasError('required')
            }"
          >
            Describe
            {{ caseSummary?.learner.firstName | firstNamePossessive }} functional or academic achievement on current goal areas.
          </label>
          <app-textarea
            [hideAsterisk]="true"
            formControlName="currentFunctionalAchievement"
            attr.aria-required="{{ formGroup.get('currentFunctionalAchievement').hasError('required') ? 'true' : 'false' }}"
          ></app-textarea>
        </div>
      </div>
    </mat-card>

    <mat-accordion class="accordion accordion--short" multi>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title
            >Postsecondary Expectation for Living from Last IEP:
            {{ !!recentPse ? recentPse?.livingExpectations : 'No data available' }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-post-secondary-summary-llw
          [formGroup]="livingFormGroup"
          [caseSummary]="caseSummary"
          type="living"
        ></app-post-secondary-summary-llw>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title
            >Postsecondary Expectation for Learning from Last IEP:
            {{ !!recentPse ? recentPse?.learningExpectations : 'No data available' }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-post-secondary-summary-llw
          [formGroup]="learningFormGroup"
          [caseSummary]="caseSummary"
          type="learning"
        ></app-post-secondary-summary-llw>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title
            >Postsecondary Expectation for Working from Last IEP:
            {{ !!recentPse ? recentPse?.workingExpectations : 'No data available' }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-post-secondary-summary-llw
          [formGroup]="workingFormGroup"
          [caseSummary]="caseSummary"
          type="working"
        ></app-post-secondary-summary-llw>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-card>
      <h3 class="card-spacing-top mb-2">Postsecondary Adult / Community Support Needs and Service Connections</h3>

      <app-community-contacts
        *ngIf="caseSummary && resourceOptions && postSecondarySummary"
        [communityContacts]="postSecondarySummary?.communityContacts"
        [resourceOptions]="resourceOptions"
        [postSecondarySummary]="postSecondarySummary"
        [isReadonly]="caseExited || authService.isPortalUser"
      ></app-community-contacts>

      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-autocomplete-chips
            label="High school contact"
            formControlName="highSchoolContacts"
            [options]="highSchoolContactsOptions"
          ></app-autocomplete-chips>
        </div>

        <div class="col-md-12 col-lg-6 col-xl-8">
          <app-autocomplete-chips
            label="Additional team members contributing to this summary"
            formControlName="contributingMembers"
            [options]="additionalTeamMemberOptions"
          ></app-autocomplete-chips>
        </div>
      </div>

      <app-non-achieve-member
        *ngIf="!caseExited && !authService.isPortalUser"
        (addNonAchieveMember)="addNonAchieveMember($event)"
      ></app-non-achieve-member>

      <div *ngIf="dataSource?.data?.length > 0" class="table-overflow app-table">
        <table mat-table [dataSource]="dataSource" matSort class="w-100">
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
            <td mat-cell *matCellDef="let teamMember">
              <button (click)="removeMember(teamMember)" mat-icon-button aria-label="Delete Icon" tabindex="0">
                <mat-icon class="text-danger">close</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let teamMember">
              {{ teamMember.fullName }}
            </td>
          </ng-container>

          <!-- Job Title Column -->
          <ng-container matColumnDef="jobTitle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Title</th>
            <td mat-cell *matCellDef="let teamMember">
              {{ teamMember.jobTitle }}
            </td>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let teamMember">
              {{ teamMember.email }}
            </td>
          </ng-container>

          <!-- Phone Column -->
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
            <td mat-cell *matCellDef="let teamMember">
              {{ teamMember.phoneNumber | phone }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [dataSource]="dataSource"></mat-paginator>
      </div>
      <p *ngIf="!dataSource.data">No data yet...</p>
    </mat-card>
    <div class="action__row" *ngIf="!caseExited && !authService.isPortalUser">
      <button mat-raised-button aria-label="Finalize" (click)="viewPostsecondarySummary()" color="primary" tabindex="0">
        View Postsecondary Summary
      </button>
      <button mat-raised-button aria-label="Finalize" (click)="onFinalize()" [disabled]="formGroup.invalid" color="primary" tabindex="0">
        Finalize
      </button>
    </div>
  </form>
</div>

<div class="page-bottom-leeway"></div>
