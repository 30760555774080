import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Building,
  BuildingManagementAddDto,
  BuildingManagementDto,
  BuildingManagementPrincipalDto,
  BuildingManagementUpdateDto,
} from '../../models/building';
import { AppPermissions } from 'src/app/permissions';
import { Observable, of } from 'rxjs';
import { OperationResultWithValue } from '../../models/operation-result';

@Injectable({
  providedIn: 'root',
})
export class BuildingService {
  private baseUrl = 'api/buildings';

  constructor(private http: HttpClient) {}

  getAllBuildings(leas?: string[]) {
    let params = new HttpParams();

    if (leas && leas.length > 0) {
      params = params.set('leas', leas.toString());
    }
    return this.http.get<Building[]>(this.baseUrl, { params });
  }

  getBuildingsForDistrict(districtId: string) {
    return this.http.get<Building[]>(`${this.baseUrl}/${districtId}`);
  }

  getAllBuildingsForManagement() {
    return this.http.get<BuildingManagementDto[]>(this.baseUrl + '/management');
  }

  getAllPrincipals() {
    return this.http.get<BuildingManagementPrincipalDto[]>(this.baseUrl + '/principals');
  }

  update(id: string, dto: BuildingManagementUpdateDto) {
    return this.http.put<BuildingManagementDto>(this.baseUrl + '/management/' + id, dto);
  }

  add(dto: BuildingManagementAddDto) {
    return this.http.post<BuildingManagementDto>(this.baseUrl + '/management/', dto);
  }

  getPrincipalsForBuilding(buildingId: string) {
    return this.http.get<BuildingManagementPrincipalDto[]>(this.baseUrl + `/${buildingId}/principals`);
  }

  getByPermission(...perms: AppPermissions[]): Observable<OperationResultWithValue<any>> {
    if (perms.length === 0) return of({ succeeded: true, value: {} });

    let params = new HttpParams();
    for (const perm of perms) {
      params = params.append('permissions', perm);
    }
    return this.http.get<OperationResultWithValue<any>>(`${this.baseUrl}by-permission`, { params });
  }
}
