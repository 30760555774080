import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { needsLabelOffset, openViewMore } from 'src/app/shared/tableHelpers';
import { AuthService } from '../../../../auth/auth.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { IfspMedicalOtherService, IfspMedicalOtherServiceAddUpdateDto } from '../../../models/ifsp-medicalotherservice-models';
import { IfspServiceSummaryDto } from '../../../models/ifsp-service-models';
import { IfspMedicalOtherServiceService } from '../../../services/ifsp-medicalotherservice.service';
import { IfspServicesService } from '../../../services/ifsp-service.service';
import { IfspServiceViewMoreComponent } from '../../../shared/ifsp-service-view-more/ifsp-service-view-more.component';

@Component({
  selector: 'app-ifsp-service-list',
  templateUrl: './ifsp-service-list.component.html',
  styleUrls: ['./ifsp-service-list.component.scss'],
  providers: [DatePipe],
})
export class IfspServiceListComponent implements OnInit {
  @Input() ifspId: string;
  @Input() isProfilePage = false;
  @Input() isEditing = false;
  @Input() isReadOnly: boolean;
  @Input() finalizedServicesOnly = false;

  @Output() servicesCount = new EventEmitter<number>();
  @Output() editService = new EventEmitter<IfspServiceSummaryDto>();

  services: Array<IfspServiceSummaryDto> = [];
  medicalServices: Array<IfspMedicalOtherService> = [];
  providerFormGroup: FormGroup = this.fb.group({});

  //#region summary
  servicesDisplayedColumns: string[] = ['actions', 'typeOfService', 'startDate', 'provider', 'agency', 'length', 'howOften', 'tags'];

  servicesDataSource: MatTableDataSource<any>;
  //#endregion

  //#region medical
  medicalDisplayedColumns: string[] = [
    'actions',
    'typeOfService',
    'agencyName',
    'dateRequested',
    'estimatedCompletionDate',
    'referralDate',
  ];

  medicalServiceFormGroup: FormGroup;
  isEditingMedicalService = false;
  addingNew = false;
  medicalDataSource: MatTableDataSource<any>;
  //#endregion
  shortDateFormat = shortDateFormat;

  get isPortalUser() {
    return this.authService.isPortalUser;
  }

  constructor(
    private ifspServicesService: IfspServicesService,
    private ifspMedicalOtherServiceService: IfspMedicalOtherServiceService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.isPortalUser) {
      // no action column for Portal users
      this.servicesDisplayedColumns = this.servicesDisplayedColumns.slice(1);
    }

    if (this.isReadOnly) {
      this.medicalDisplayedColumns.shift();
      this.servicesDisplayedColumns.shift();
    }
    if (this.isProfilePage) {
      this.servicesDisplayedColumns.push('familyDeclined');
      this.servicesDisplayedColumns.push('primaryServiceProvider');
    }

    this.servicesDataSource = new MatTableDataSource([]);
    this.getServices();
    setTimeout(
      () =>
        (this.medicalServiceFormGroup = this.fb.group({
          typeOfService: [null, [Validators.required]],
          agencyName: [null, [Validators.required]],
          dateRequested: [null, [Validators.required]],
          referralDate: [null],
          estimatedCompletionDate: [null, [Validators.required]],
        })),
      0
    );
    this.medicalDataSource = new MatTableDataSource([]);
    this.getMedicalServices();

    this.servicesDataSource.sortingDataAccessor = (item: IfspServiceSummaryDto, columnId) => {
      switch (columnId) {
        case 'howOften':
          return `${item.frequencyNumber} times ${item.frequencyPeriod} ${item.who}`;
        default:
          return item[columnId];
      }
    };

    this.ifspServicesService.servicesUpdated$.subscribe(() => {
      this.getServices();
    });
  }

  onServiceView(service) {
    const dialogRef = this.dialog.open(IfspServiceViewMoreComponent, {
      width: '758px',
      data: {
        service,
        modifications: [],
      },
    });
  }

  goToServices() {
    const caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    this.router.navigate(['/', 'cases', caseId, 'ifsp', this.ifspId, 'services']);
  }

  // #region Services
  getServices() {
    if (this.finalizedServicesOnly) {
      this.ifspServicesService.getFinalizedServices(this.ifspId).subscribe((services) => {
        this.services = services;
        this.services.forEach((service) => {
          this.providerFormGroup.addControl(
            'primaryServiceProvider_' + service.id,
            new FormControl(service.primaryServiceProvider ? '1' : null)
          );
        });
        this.servicesDataSource.data = this.services;
        this.servicesCount.emit(this.services.length);
      });
    } else {
      this.ifspServicesService.getServices(this.ifspId).subscribe((services) => {
        this.services = services;
        this.services.forEach((service) => {
          this.providerFormGroup.addControl(
            'primaryServiceProvider_' + service.id,
            new FormControl(service.primaryServiceProvider ? '1' : null)
          );
        });
        this.servicesDataSource.data = this.services;
        this.servicesCount.emit(this.services.length);
      });
    }
  }

  onFamilyDeclinedChange(id, event) {
    this.ifspServicesService.getServiceById(this.ifspId, id).subscribe((service) => {
      service.outcomes = service.outcomes.map((x) => x.outcomeId);
      service.familyDeclined = event.checked;
      this.ifspServicesService.updateService(this.ifspId, service).subscribe(() => {});
    });
  }

  onPSPChanged(id) {
    const keyPrefix = 'primaryServiceProvider_';
    const keyId = keyPrefix + id;
    Object.keys(this.providerFormGroup.controls).forEach((key) => {
      if (key.startsWith(keyPrefix)) {
        if (key === keyId) {
          this.providerFormGroup.get(key).setValue('1');
        } else {
          this.providerFormGroup.get(key).setValue(null);
        }
      }
    });

    this.ifspServicesService.updateServiceProviders(this.ifspId, id, null).subscribe(() => {});
  }

  isDisabled(id: string, isPrimary: boolean) {
    const pspId = 'primaryServiceProvider_' + id;
    const sspId = 'secondaryServiceProvider_' + id;
    return isPrimary ? this.providerFormGroup.get(sspId).value === '1' : this.providerFormGroup.get(pspId).value === '1';
  }

  // onSSPChanged(id) {
  //  const keyPrefix = 'secondaryServiceProvider_';
  //  const keyId = keyPrefix + id;
  //  Object.keys(this.providerFormGroup.controls).forEach((key) => {
  //    if (key.startsWith(keyPrefix)) {
  //      if (key === keyId) {
  //        this.providerFormGroup.get(key).setValue('1');
  //      } else {
  //        this.providerFormGroup.get(key).setValue(null);
  //      }
  //    }
  //  });

  //  this.ifspServicesService
  //    .updateServiceProviders(this.ifspId, null, id)
  //    .subscribe(() => {});
  // }

  onServiceEdit(service) {
    this.editService.emit(service);
  }

  onServiceRemove(service) {
    this.notificationService.confirmation('Are you sure you want to delete this service?', () => {
      this.ifspServicesService.deleteService(this.ifspId, service.id).subscribe(
        () => {
          this.notificationService.success('Service deleted');
          this.getServices();
        },
        (error) => {
          this.notificationService.error(error.error[0]?.description);
        }
      );
    });
  }
  // #endregion
  // #region Medical Services
  getMedicalServices() {
    this.ifspMedicalOtherServiceService.getServices(this.ifspId).subscribe((medicalServices) => {
      this.medicalServices = medicalServices;
      this.medicalDataSource.data = this.medicalServices;
    });
  }

  hasServiceProviders() {
    let hasProvider = false;
    Object.values(this.providerFormGroup.controls).forEach((provider) => {
      if (provider.value === '1') {
        hasProvider = true;
      }
    });
    return hasProvider;
  }

  clearServiceProviders() {
    Object.values(this.providerFormGroup.controls).forEach((provider) => {
      provider.setValue(null);
    });

    this.ifspServicesService.updateServiceProviders(this.ifspId, null, null).subscribe(() => {});
  }

  updateMedicalService(formDirective: any, resetForm = true) {
    this.ifspMedicalOtherServiceService.updateService(this.ifspId, this.medicalServiceFormGroup.value).subscribe(() => {
      this.getMedicalServices();
    });
    if (resetForm) {
      formDirective.resetForm();
    }
  }

  setMedicalServiceEdit(service: IfspMedicalOtherService, editing = true) {
    this.addingNew = false;
    this.isEditingMedicalService = editing;
    if (service !== null) {
      service.isEditing = editing;
    }
    if (!editing) {
      this.medicalServiceFormGroup.patchValue({});
      this.medicalServiceFormGroup.reset();
      return;
    }
    this.medicalServiceFormGroup.patchValue({
      id: service.id,
      typeOfService: service.typeOfService,
      agencyName: service.agencyName,
      dateRequested: service.dateRequested,
      referralDate: service.referralDate,
      estimatedCompletionDate: service.estimatedCompletionDate,
    });
  }

  saveMedicalService(service: IfspMedicalOtherService) {
    if (this.medicalServiceFormGroup.valid) {
      const formValues = this.medicalServiceFormGroup.value;
      formValues.id = service ? service.id : null;
      this.updateMedicalService(formValues, false);
      this.isEditingMedicalService = false;
      this.setMedicalServiceEdit(service, false);
    } else {
      this.medicalServiceFormGroup.markAllAsTouched();
    }
  }

  onMedicalServiceRemove(service: IfspMedicalOtherService) {
    this.notificationService.confirmation('Are you sure you want to delete this Medical or Other Service?', () => {
      this.ifspMedicalOtherServiceService.deleteService(this.ifspId, service.id).subscribe(() => {
        this.getMedicalServices();
      });
    });
  }

  // #endregion

  convertToShortDate(date: Date) {
    return this.datePipe.transform(date, shortDateFormat);
  }

  checkLabelOffset(row): boolean {
    return needsLabelOffset(row);
  }

  onViewMore(row: IfspMedicalOtherServiceAddUpdateDto) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Type of Service',
        value: row.typeOfService,
      },
      {
        name: 'Agency Name',
        value: row.agencyName,
      },
      {
        name: 'Date Requested',
        value: this.convertToShortDate(row.dateRequested),
      },
      {
        name: 'Referral Date',
        value: this.convertToShortDate(row.referralDate),
      },
      {
        name: 'Estimated Completion Date',
        value: this.convertToShortDate(row.estimatedCompletionDate),
      },
    ];

    openViewMore(this.dialog, modalData);
  }
}
