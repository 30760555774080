import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WaiverType } from '../../models/waiver-type';

@Injectable({
  providedIn: 'root',
})
export class WaiverTypeService {
  baseUrl = 'api/waiver-types';

  constructor(private http: HttpClient) {}

  getWaiverTypes(): Observable<WaiverType[]> {
    return this.http.get<WaiverType[]>(this.baseUrl);
  }
}
