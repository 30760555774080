<div class="backdrop">
  <div class="pic"></div>
  <div class="form">
    <ng-container *ngIf="!requestCompleted">
      <form class="forgot-password-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
        <h1 class="mat-display-1">{{ pageTitle }}</h1>
        <p>Please provide your email and we'll send you reset password link.</p>
        <mat-form-field class="form-field">
          <input type="text" placeholder="Email" aria-label="Email" matInput formControlName="email" />
        </mat-form-field>
        <mat-error *ngIf="email.touched && email.invalid">{{ getErrorMessage() }}</mat-error>
        <button mat-flat-button class="buttons" type="submit" color="primary" [isBusy]="isBusy" tabindex="0" [disabled]="!formGroup.valid">
          Submit
        </button>
        <a class="back-to-login" routerLink="../login">Back to login</a>
      </form>
    </ng-container>

    <ng-container *ngIf="requestCompleted">
      <div class="forgot-password-form">
        <h1 class="mat-display-1">{{ pageTitle }}</h1>
        <p class="p-2">
          If an account is found with the provided email address, a message with instructions for resetting your password will be sent
          momentarily. If you do not receive an email within an hour, please try to reset your password again.
        </p>
        <a class="back-to-login" routerLink="../login">Back to login</a>
      </div>
    </ng-container>
  </div>
</div>
