import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { ProgressMonitorLookup, ProgressMonitorLookupType } from '../models/progress-monitor-lookup';

@Injectable({
  providedIn: 'root',
})
export class ProgressMonitorLookupService {
  phaseLineOptions: Array<KeyValuePair> = [];
  commentOptions: Array<KeyValuePair> = [];
  lookupOptions: Array<ProgressMonitorLookup> = [];

  constructor(private http: HttpClient) {}

  get() {
    return this.http.get<ProgressMonitorLookup[]>('api/progress-monitor-lookup');
  }

  setupLookups(lookups: Array<ProgressMonitorLookup>) {
    this.lookupOptions = lookups;
    this.phaseLineOptions = lookups
      .filter((x) => x.type === ProgressMonitorLookupType.PhaseLine)
      .map((x) => new KeyValuePair(x.id, x.label));
    this.commentOptions = lookups.filter((x) => x.type === ProgressMonitorLookupType.Comment).map((x) => new KeyValuePair(x.id, x.label));
  }
}
