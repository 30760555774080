import { LookupBase } from 'src/app/shared/models/lookup-base';

export interface ProgressMonitorLookup extends LookupBase {
  type: string;
  isOther: boolean;
}

export enum ProgressMonitorLookupType {
  PhaseLine = 'PhaseLine',
  Comment = 'Comment',
}
