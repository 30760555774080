import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-st-special-factors',
  templateUrl: './st-special-factors.component.html',
  styleUrls: ['./st-special-factors.component.scss'],
})
export class StSpecialFactorsComponent implements OnInit {
  formGroup = this.fb.group({
    strengths: this.fb.array([]),
    preferences: this.fb.array([]),

    limitedEnglish: new FormControl(''),
    limitedEnglishAddressed: new FormControl(''),

    behavior: new FormControl(''),
    behaviorAddressed: new FormControl(''),

    accessibleMaterials: new FormControl(''),
    accessibleMaterialsAddressed: new FormControl(''),

    vision: new FormControl(''),
    visionAddressed: new FormControl(''),

    communicationLanguage: new FormControl(''),
    communicationLanguageAddressed: new FormControl(''),

    assistiveTechnology: new FormControl(''),
    assistiveTechnologyAddressed: new FormControl(''),

    health: new FormControl(''),
    healthAddressed: new FormControl(''),

    hearing: new FormControl(''),
    hearingAddressed: new FormControl(''),

    allConsideredNoneSelected: new FormControl(null),

    describeStudentInvolvement: new FormControl(''),
    describeHowParticipate: new FormControl(''),
    concerns: this.fb.array([]),
    otherInformation: new FormControl(''),
  });

  outcomesList = [];

  get strengthsSkillsArr(): FormGroup[] {
    return (this.formGroup.get('strengths') as FormArray).controls as FormGroup[];
  }

  get preferencesArr(): FormGroup[] {
    return (this.formGroup.get('preferences') as FormArray).controls as FormGroup[];
  }

  get concernsArr(): FormGroup[] {
    return (this.formGroup.get('concerns') as FormArray).controls as FormGroup[];
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {}
}
