<div class="action__row action__row--between mt-0">
  <div>
    <app-create-output-button
      [removeIfFeatureOff]="'outputIfspEnabled'"
      [output]="pdfOutputs.IfspOutput"
      [apiId]="ifspId"
      [learnerId]="learnerId"
      [documentId]="documentId"
      [isDraft]="true"
      buttonText="View IFSP"
    ></app-create-output-button>

    <button
      [disabled]="!canFinalize"
      *ngIf="!finalized && canFinalizeIfsp"
      mat-raised-button
      color="primary"
      class="ml-2"
      aria-label="Finalize IFSP"
      [disableAfterBusy]="!canFinalize"
      (click)="finalizeIfsp()"
      [isBusy]="activeCall"
      tabindex="0"
    >
      Finalize IFSP
    </button>

    <app-create-output-button
      [output]="pdfOutputs.SummaryIfspOutcomesServices"
      [apiId]="ifspId"
      [learnerId]="learnerId"
      [isDraft]="true"
      buttonText="Outcomes and Services"
    ></app-create-output-button>

    <button
      mat-raised-button
      attr.aria-label="{{ pwnId ? 'Complete' : 'Create' }} Prior Written Notice"
      color="primary"
      (click)="openPWNPopUp()"
      class="ml-2"
      tabindex="0"
    >
      {{ pwnId ? 'Complete' : 'Create' }} Prior Written Notice
    </button>
  </div>
</div>
