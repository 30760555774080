import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DeleteReason } from 'src/app/shared/models/delete-reason';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { NotificationService } from '../../shared/services/notification.service';
import { Bip, OperationResult } from '../models/bip';
import {
  BehaviorGoalCommentDto,
  BehaviorOfConcernDto,
  ExcludedItem,
  HypothesisStatementDto,
  SelectedGoalsDto,
} from '../models/bip-behavior-goals';
import { ImplementationDto } from '../models/bip-implementation';
import { BipNonAchieveMemberDto, BipOverviewDto } from '../models/bip-overview';
import { BipAmendmentDto, BipNonAchieveReviewerDto, BipReviewDto } from '../models/bip-review';
import { SafetyPlanAdditionalDto, SafetyPlanDto } from '../models/bip-safety-plan';
import { BipStrategyBaseDto, StrategyDto } from '../models/bip-strategies';
import { BipSummaryDto } from '../models/bip-summary';

@Injectable({
  providedIn: 'root',
})
export class BipServicesService {
  private bipOverviewUpdated = new Subject<void>();
  bipOverviewUpdated$ = this.bipOverviewUpdated.asObservable();

  private bidBehaviorGoalCommentUpdated = new Subject<void>();
  bidBehaviorGoalCommentUpdated$ = this.bidBehaviorGoalCommentUpdated.asObservable();

  private bipBehaviorOfConcernUpdated = new Subject<void>();
  bipBehaviorOfConcernUpdated$ = this.bipBehaviorOfConcernUpdated.asObservable();

  bipHypothesisStatementUpdated = new Subject<void>();
  bipHypothesisStatementUpdated$ = this.bipHypothesisStatementUpdated.asObservable();

  private bipStrategyFunctionUpdated = new Subject<void>();
  bipStrategyFunctionUpdated$ = this.bipStrategyFunctionUpdated.asObservable();

  private bipImplementationUpdated = new Subject<void>();
  bipImplementationUpdated$ = this.bipImplementationUpdated.asObservable();

  private bipSafetyPlanUpdated = new Subject<void>();
  bipSafetyPlanUpdated$ = this.bipSafetyPlanUpdated.asObservable();

  bipReviewUpdated = new Subject<void>();
  bipReviewUpdated$ = this.bipReviewUpdated.asObservable();

  bipReloadRequest = new Subject<void>();
  bipReloadRequest$ = this.bipReloadRequest.asObservable();

  bipReloadRequestComplete = new Subject<void>();
  bipReloadRequestComplete$ = this.bipReloadRequestComplete.asObservable();

  constructor(
    private readonly http: HttpClient,
    private spinnerService: SpinnerService,
    private readonly notificationService: NotificationService
  ) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/bip\/.*behaviorGoalComment.*/));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/bip\/.*appropriateBehaviorStatements.*/));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/bip\/.*behaviorOfConcern.*/));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/bip\/.*hypothesisStatement.*/));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/bip\/.*implementation.*/));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/bip\/.*iepGoals.*/));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/bip\/.*teamMember.*/));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/bip\/.*nonAchieveMember.*/));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/bip\/.*teamForMentalHealthProvider.*/));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/bip\/.*safetyPlan.*/));
  }

  getBipSummary(bipId: string) {
    return this.http.get<BipSummaryDto>(`api/bip/${bipId}/summary`);
  }

  getIepIdFromBipId(bipId: string) {
    return this.http.get<string>(`api/bip/${bipId}/iep-id`);
  }

  getBip(bipId: string) {
    return this.http.get<Bip>(`api/bip/${bipId}`);
  }

  getAllBips(caseId: string) {
    return this.http.get<Bip[]>(`api/bip/${caseId}/all`);
  }

  getBipByCaseId(caseId: string) {
    return this.http.get<Bip>(`api/bip/${caseId}/byCaseId`);
  }

  getActiveBipByCaseId(caseId: string) {
    return this.http.get<OperationResult>(`api/bip/${caseId}/activeBip`);
  }

  getOverview(bipId: string) {
    return this.http.get<any>(`api/bip/${bipId}/overview`);
  }

  addTeamMembers(bipId: string, userIds: string[]) {
    return this.http.post<any>(`api/bip/${bipId}/teamMembersId`, userIds).pipe(tap(() => this.bipOverviewUpdated.next()));
  }

  removeTeamMember(bipId: string, userId: string) {
    return this.http.put(`api/bip/${bipId}/teamMember/${userId}`, null).pipe(tap(() => this.bipOverviewUpdated.next()));
  }

  addNonAchieveMember(bipId: string, nonAchieveMemberDto: BipNonAchieveMemberDto) {
    return this.http.post<any>(`api/bip/${bipId}/nonAchieveMember`, nonAchieveMemberDto).pipe(tap(() => this.bipOverviewUpdated.next()));
  }

  deleteNonAchieveMember(bipId: string, nonAchieveMemberId: string) {
    return this.http.put(`api/bip/${bipId}/nonAchieveMember/${nonAchieveMemberId}`, null).pipe(tap(() => this.bipOverviewUpdated.next()));
  }

  saveTeamForMentalHealthProvider(bipId: string, overviewDto: BipOverviewDto) {
    return this.http.post<any>(`api/bip/${bipId}/teamForMentalHealthProvider`, overviewDto).pipe(tap(() => this.bipOverviewUpdated.next()));
  }

  getIncompleteDataReport(bipId: string, bipAmendmentId = '') {
    return this.http.get<any>(`api/bip/${bipId}/incompleteDataReport`, {
      params: { amendmentId: bipAmendmentId },
    });
  }

  getBehaviorGoal(bipId: string, bipAmendmentId = '') {
    return this.http.get<OperationResult>(`api/bip/${bipId}/behaviorGoal`, {
      params: { amendmentId: bipAmendmentId },
    });
  }

  saveBehaviorGoalComment(model: BehaviorGoalCommentDto, bipAmendmentId = '') {
    return this.http
      .post<any>('api/bip/behaviorGoalComment', model, {
        params: { amendmentId: bipAmendmentId },
      })
      .pipe(tap(() => this.bidBehaviorGoalCommentUpdated.next()));
  }

  deleteBehaviorGoalComment(behaviorGoalCommentId: string, bipAmendmentId = '', isUndo = false) {
    return this.http
      .put<any>(
        `api/bip/behaviorGoalComment/${behaviorGoalCommentId}/`,
        {},
        {
          params: { amendmentId: bipAmendmentId, isUndo: isUndo.toString() },
        }
      )
      .pipe(tap(() => this.bidBehaviorGoalCommentUpdated.next()));
  }

  saveAppropriateBehaviorStatements(bipId: string, appropriateBehaviorStatements: string, bipAmendmentId = '') {
    return this.http
      .post<any>(`api/bip/${bipId}/appropriateBehaviorStatements`, {
        amendmentId: bipAmendmentId,
        appropriateBehaviorStatements,
      })
      .pipe(tap(() => this.bidBehaviorGoalCommentUpdated.next()));
  }

  saveIepGoals(selectedGoals: SelectedGoalsDto, bipAmendmentId = '') {
    return this.http
      .put<any>('api/bip/iepGoals', selectedGoals, {
        params: { amendmentId: bipAmendmentId },
      })
      .pipe(tap(() => this.bidBehaviorGoalCommentUpdated.next()));
  }

  saveBehaviorOfConcern(model: BehaviorOfConcernDto, bipAmendmentId = '') {
    return this.http
      .post<any>('api/bip/behaviorOfConcern/', model, {
        params: { amendmentId: bipAmendmentId },
      })
      .pipe(tap(() => this.bipBehaviorOfConcernUpdated.next()));
  }

  deleteBehaviorOfConcern(behaviorOfConcernId: string, bipAmendmentId = '', isUndo = false) {
    return this.http
      .put<any>(
        `api/bip/behaviorOfConcern/${behaviorOfConcernId}/`,
        {},
        {
          params: { amendmentId: bipAmendmentId, isUndo: isUndo.toString() },
        }
      )
      .pipe(tap(() => this.bipBehaviorOfConcernUpdated.next()));
  }

  saveHypothesisStatement(model: HypothesisStatementDto, bipAmendmentId = '') {
    return this.http
      .post<any>('api/bip/hypothesisStatement', model, {
        params: { amendmentId: bipAmendmentId },
      })
      .pipe(tap(() => this.bipHypothesisStatementUpdated.next()));
  }

  deleteHypothesisStatement(hypothesisStatementId: string, bipAmendmentId = '', isUndo = false) {
    return this.http
      .put<any>(
        `api/bip/hypothesisStatement/${hypothesisStatementId}/`,
        {},
        {
          params: { amendmentId: bipAmendmentId, isUndo: isUndo.toString() },
        }
      )
      .pipe(tap(() => this.bipHypothesisStatementUpdated.next()));
  }

  saveExcludedItem(excludedItem: ExcludedItem) {
    return this.http.post<any>('api/bip/excludedItem', excludedItem).pipe(tap(() => this.bipHypothesisStatementUpdated.next()));
  }

  deleteExcludedItem(bipId: string, excludedItemId: string) {
    return this.http
      .put<any>(`api/bip/${bipId}/excludedItem/${excludedItemId}`, null)
      .pipe(tap(() => this.bipHypothesisStatementUpdated.next()));
  }

  getStrategyFunctions(bipId: string, bipAmendmentId = '') {
    return this.http.get<OperationResult>(`api/bip/${bipId}/strategyFunction`, {
      params: { amendmentId: bipAmendmentId },
    });
  }

  updateStrategyFunction(bipId: string, model: BipStrategyBaseDto, bipAmendmentId = '') {
    return this.http
      .put<OperationResult>(`api/bip/${bipId}/strategyFunction`, model, {
        params: { amendmentId: bipAmendmentId },
      })
      .pipe(tap(() => this.bipStrategyFunctionUpdated.next()));
  }

  updateStrategy(model: StrategyDto, bipAmendmentId = '') {
    return this.http
      .put<OperationResult>('api/bip/strategy/', model, {
        params: { amendmentId: bipAmendmentId },
      })
      .pipe(tap(() => this.bipStrategyFunctionUpdated.next()));
  }

  deleteStrategy(bipId: string, strategyId: string, bipAmendmentId = '', isUndo = false) {
    return this.http
      .put<any>(
        `api/bip/${bipId}/strategy/${strategyId}`,
        {},
        {
          params: { amendmentId: bipAmendmentId, isUndo: isUndo.toString() },
        }
      )
      .pipe(tap(() => this.bipStrategyFunctionUpdated.next()));
  }

  getImplementation(bipId: string, bipAmendmentId = '') {
    return this.http.get<OperationResult>(`api/bip/${bipId}/implementation`, {
      params: { amendmentId: bipAmendmentId },
    });
  }

  updateImplementation(model: ImplementationDto, bipAmendmentId = '') {
    return this.http
      .put<OperationResult>('api/bip/implementation', model, {
        params: { amendmentId: bipAmendmentId },
      })
      .pipe(tap(() => this.bipImplementationUpdated.next()));
  }

  getSafetyPlanWithAdditionals(bipId: string, bipAmendmentId = '') {
    return this.http.get<OperationResult>(`api/bip/${bipId}/safetyPlan`, {
      params: { amendmentId: bipAmendmentId },
    });
  }

  updateSafetyPlan(bipId: string, model: SafetyPlanDto, bipAmendmentId = '') {
    return this.http
      .put<OperationResult>(`api/bip/${bipId}/safetyPlan`, model, {
        params: { amendmentId: bipAmendmentId },
      })
      .pipe(tap(() => this.bipSafetyPlanUpdated.next()));
  }

  addSafetyPlanAdditional(bipId: string, model: SafetyPlanAdditionalDto, bipAmendmentId = '') {
    return this.http
      .post<OperationResult>(`api/bip/${bipId}/safetyPlanAdditional`, model, {
        params: { amendmentId: bipAmendmentId },
      })
      .pipe(tap(() => this.bipSafetyPlanUpdated.next()));
  }

  updateSafetyPlanAdditional(bipId: string, model: SafetyPlanAdditionalDto, bipAmendmentId = '') {
    return this.http
      .put<OperationResult>(`api/bip/${bipId}/safetyPlanAdditional`, model, {
        params: { amendmentId: bipAmendmentId },
      })
      .pipe(tap(() => this.bipSafetyPlanUpdated.next()));
  }

  deleteSafetyPlanAdditional(bipId: string, safetyPlanAdditionalId: string, bipAmendmentId = '', isUndo = false) {
    return this.http
      .put<any>(
        `api/bip/${bipId}/safetyPlanAdditional/${safetyPlanAdditionalId}`,
        {},
        {
          params: { amendmentId: bipAmendmentId, isUndo: isUndo.toString() },
        }
      )
      .pipe(tap(() => this.bipBehaviorOfConcernUpdated.next()));
  }

  completeBip(bipId: string) {
    return this.http.put<OperationResult>(`api/bip/${bipId}/complete`, null).pipe(tap(() => this.bipOverviewUpdated.next()));
  }

  deleteBip(bipId: string) {
    return this.http.put<any>(`api/bip/delete/${bipId}`, null);
  }

  startBipReview(bipId: string) {
    return this.http.post<OperationResult>(`api/bip/${bipId}/bipReview`, null).pipe(tap(() => this.bipReviewUpdated.next()));
  }

  addAchieveReviewers(bipReviewId: string, userIds: string[]) {
    return this.http.post<any>(`api/bip/${bipReviewId}/achieveReviewers`, userIds).pipe(tap(() => this.bipReviewUpdated.next()));
  }

  removeAchieveReviewer(bipReviewId: string, userId: string) {
    return this.http.put(`api/bip/${bipReviewId}/achieveReviewer/${userId}`, null).pipe(tap(() => this.bipReviewUpdated.next()));
  }

  addNonAchieveReviewer(bipReviewId: string, nonAchieveMemberDto: BipNonAchieveReviewerDto) {
    return this.http
      .post<any>(`api/bip/${bipReviewId}/nonAchieveReviewer`, nonAchieveMemberDto)
      .pipe(tap(() => this.bipReviewUpdated.next()));
  }

  deleteNonAchieveReviewer(bipReviewId: string, nonAchieveReviewerId: string) {
    return this.http
      .put(`api/bip/${bipReviewId}/nonAchieveReviewer/${nonAchieveReviewerId}`, null)
      .pipe(tap(() => this.bipReviewUpdated.next()));
  }

  finalizeBipReview(bipId: string, bipReviewId: string, bipReview: BipReviewDto) {
    return this.http
      .post<OperationResult>(`api/bip/${bipId}/bipReview/${bipReviewId}`, bipReview)
      .pipe(tap(() => this.bipReviewUpdated.next()));
  }

  saveBipAmendment(bipId: string, bipAmendment: BipAmendmentDto) {
    return this.http.put<OperationResult>(`api/bip/${bipId}/amend/`, bipAmendment);
  }

  finalizeBipAmendment(bipId: string, bipAmendment: BipAmendmentDto) {
    return this.http
      .post<OperationResult>(`api/bip/${bipId}/finalize-amendment`, bipAmendment)
      .pipe(tap(() => this.bipReviewUpdated.next()));
  }

  cancelBip(bipId: string, reason: string) {
    const deleteReason: DeleteReason = { id: bipId, reason: reason };
    return this.http.put<DeleteReason>('api/bip/', deleteReason);
  }

  handleError(errorTitle: string, error: any): void {
    let errorMessage = '';
    if (error && error.error && error.error.errors) {
      errorMessage = error.error.errors.map((e) => e.description)?.join(',');
    } else if (error && error.errors) {
      errorMessage = error.errors.map((e) => e.description)?.join(',');
    } else if (error && error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = JSON.stringify(error);
    }

    this.notificationService.errorWithAction(errorTitle, 'Why?', () => {
      this.notificationService.alert(errorMessage, errorTitle);
    });
  }
}
