import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ExpanderService {
  private expandedSubject = new BehaviorSubject<boolean>(false);

  constructor() {
    this.expanded = this.expandedSubject.asObservable();
  }

  public expanded: Observable<boolean>;

  public expandAll(): void {
    if (this.expandedSubject.value) {
      return;
    }

    this.expandedSubject.next(true);
  }

  public collapseAll(): void {
    if (!this.expandedSubject.value) {
      return;
    }

    this.expandedSubject.next(false);
  }

  public toggleAll(): void {
    this.expandedSubject.next(!this.expandedSubject.value);
  }
}
