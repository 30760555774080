import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MemoryStorageService {
  private storage = {};

  /**
   * Retrieves an item from the memory storage and then removes it.
   * @param key use the same key as the "setKey" method
   * @param keep Keep item stored after use.
   */
  getKey(key: string, keep = false) {
    const storageResult = this.storage[key];
    if (!keep) {
      this.clearKey(key);
    }
    return storageResult;
  }

  /**
   * Stores a value TEMPORARILY to an internal object.
   * Values are deleted after they are retrieved.
   * If the user refreshes the string values will be lost.
   * @param key use this with "getKey" method
   * @param value value to store.
   */
  setKey(key: string, value: any) {
    this.storage[key] = value;
  }

  clearKey(key: string) {
    delete this.storage[key];
  }
}
