import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-print-head',
  templateUrl: './print-head.component.html',
  styleUrls: ['./print-head.component.scss'],
})
export class PrintHeadComponent implements OnInit {
  @Input() adjustLogo = true;
  @Input() title: string;
  @Input() showLogo: boolean;
  @Input() showClose = false;
  @Output() closeEvent = new EventEmitter();

  print() {
    window.print();
  }

  ngOnInit() {}

  onClose() {
    this.closeEvent.emit();
  }
}
