import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
})
export class ScrollToTopComponent implements OnInit, OnChanges {
  @Input()
  element: any;

  @Input()
  scrollPosition = 0;

  show = false;

  constructor() {}

  ngOnInit(): void {
    this.isVisible();
  }

  ngOnChanges() {
    this.isVisible();
  }

  isVisible() {
    if (this.scrollPosition <= 77 || !this.scrollPosition) {
      this.show = false;
    } else {
      this.show = true;
    }
  }

  onScrollToTop() {
    this.element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
      alignToTop: true,
    });
  }
}
