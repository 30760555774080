import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IncompleteDataReportItem } from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { PunctuationPipe } from 'src/app/shared/pipes/punctuation.pipe';

@Component({
  selector: 'app-esy-incomplete-data-report',
  templateUrl: './esy-incomplete-data-report.component.html',
  styleUrls: ['./esy-incomplete-data-report.component.scss'],
  providers: [PunctuationPipe],
})
export class EsyIncompleteDataReportComponent implements OnInit, OnChanges {
  @Input() iepId: string;
  @Input() incompleteItems: IncompleteDataReportItem[] = [];
  caseId: string;

  dataSource = new MatTableDataSource<IncompleteDataReportItem>();
  displayedColumns: string[] = ['actions', 'notices'];
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnChanges(): void {
    this.dataSource.data = this.incompleteItems;
  }

  ngOnInit(): void {
    this.caseId = this.route.parent.snapshot.paramMap.get('caseId');
  }

  navigateToAction(item: IncompleteDataReportItem) {
    if (item.id) {
      this.router.navigate(['/cases', this.caseId, 'iep', this.iepId, 'esy', item.action, item.id]);
    } else {
      this.router.navigate(['/cases', this.caseId, 'iep', this.iepId, 'esy', item.action]);
    }
  }
}
