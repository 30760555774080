import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-inline-edit',
  template: `
    <ng-container *ngIf="editing && allowEdit">
      <div class="mr-3">
        <ng-content select="[editMode]"></ng-content>
      </div>
    </ng-container>
    <ng-content select="[viewMode]" *ngIf="!editing"></ng-content>
  `,
})
export class InlineEditComponent implements OnChanges {
  @Input() allowEdit = true; // todo maybe we could pass in the form control and look at the disabledState state?
  @Input() editing = false;

  ngOnChanges(changes: SimpleChanges) {
    if (this.editing && !this.allowEdit) {
      this.editing = false;
    }
  }
}
