import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { LoginModel } from '../../auth-models';
import { AuthService } from '../../auth.service';
import { LearnerInvitationVerifyDto, LearnerPortalRegistrationDto } from '../models/learner-portal-registration-dto';

const passwordsMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password').value;
  const confirmedPassword = control.get('confirmedPassword').value;
  const hasPassword = control.get('hasPassword').value;

  return !hasPassword && password !== confirmedPassword ? { passwordMismatch: true } : null;
};

@Component({
  selector: 'app-learner-portal-registration',
  templateUrl: './learner-portal-registration.component.html',
  styleUrls: ['./learner-portal-registration.component.scss'],
})
export class LearnerPortalRegistrationComponent implements OnInit {
  learnerPortalRegistration: LearnerPortalRegistrationDto;
  isBusy = false;
  formGroup = new FormGroup(
    {
      invitationId: new FormControl('', { validators: [Validators.required] }),
      learnerUserId: new FormControl(''),
      password: new FormControl('', { validators: [Validators.required] }),
      confirmedPassword: new FormControl('', { validators: [Validators.required] }),
      hasPassword: new FormControl(false),
    },
    {
      updateOn: 'blur',
      validators: passwordsMatchValidator,
    }
  );

  invitationVerifyDto: LearnerInvitationVerifyDto;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private learnerService: LearnerService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    const invitationId = this.route.snapshot.paramMap.get('invitationId');

    this.learnerPortalRegistration = {
      invitationId: invitationId,
    } as LearnerPortalRegistrationDto;

    this.learnerService.validateInvitation(invitationId).subscribe(
      (response) => {
        if (response.succeeded) {
          this.invitationVerifyDto = response.value;

          // Redirect user to login page if the invitation has already been accepted
          if (response.value.isConfirmed) {
            this.reddirectToLogin();
            return;
          }

          if (this.invitationVerifyDto.hasPassword) {
            this.formGroup.controls.confirmedPassword.clearValidators();
          } else {
            this.formGroup.controls.confirmedPassword.setValidators(Validators.required);
          }

          this.formGroup.controls.confirmedPassword.updateValueAndValidity();

          this.learnerPortalRegistration.learnerUserId = response.value.learnerUserId;
          this.formGroup.patchValue(this.learnerPortalRegistration);
          this.formGroup.controls.hasPassword.setValue(this.invitationVerifyDto.hasPassword);
        } else {
          this.notificationService.error(response.errors?.map((x) => x.description).join(','));
        }
      },
      () => {
        this.notificationService.error('Unable to verify invitation.');
      }
    );
  }

  onRegister() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      this.notificationService.error('Please complete all required fields.');
      return;
    }

    const registrationDto = this.formGroup.value as LearnerPortalRegistrationDto;

    if (registrationDto.learnerUserId === null && registrationDto.password !== registrationDto.confirmedPassword) {
      this.notificationService.error('Passwords do not match');
      return;
    }

    this.isBusy = true;

    this.learnerService.confirmInvitation(registrationDto).subscribe(
      (response) => {
        if (response.succeeded) {
          this.notificationService.success('Confirmation succeeded');
          const loginModel = {
            password: registrationDto.password,
            email: response.value.email,
          } as LoginModel;
          this.login(loginModel);
        } else {
          this.isBusy = false;
          this.notificationService.error(response.errors?.map((x) => x.description).join(','));
        }
      },
      () => {
        this.isBusy = false;
        this.notificationService.error('Unable to confirm invitation.');
      }
    );
  }

  login(loginModel: LoginModel) {
    this.authService.login(loginModel).subscribe(
      () => {
        if (this.authService.isAuthenticated) {
          this.authService.navigateToPortal();
        } else {
          this.loginFaild();
        }

        this.isBusy = false;
      },
      () => {
        this.isBusy = false;
        this.loginFaild();
      }
    );
  }

  loginFaild() {
    this.notificationService.error('Login failed, redirecting you to login page...');
    setTimeout(() => {
      this.reddirectToLogin();
    }, 3000);
  }

  reddirectToLogin() {
    this.router.navigate(['/auth/login']);
  }
}
