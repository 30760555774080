<ng-container *ngIf="isLearnerInTransitionAge">
  <p>{{ determination }}</p>

  <p>{{ getHelpText }}</p>
</ng-container>

<ng-container *ngIf="ecoAreaForm?.controls">
  <mat-card class="card-spacing-bottom" *ngFor="let ecoArea of ecoAreaForm.controls; let i = index">
    <h3 class="mt-0 mb-2">{{ ecoArea.get('label').value }}</h3>
    <button
      mat-raised-button
      aria-label="Launch ECO Decision Tree"
      color="primary"
      class="mr-2"
      (click)="launchEcoDecisionTree(ecoArea)"
      [disabled]="readOnly || ecoArea.get('isLocked').value"
      tabindex="0"
    >
      Launch ECO Decision Tree
    </button>
    <ng-container *ngIf="ecoArea.get('scoreText').value">
      <hr class="input__divider my-2" />
      <h4 class="mt-0 mb-1">Comparison to Age Expectation</h4>
      <p class="my-0">
        {{ ecoArea.get('scoreText').value }}
      </p>
      <br />
      <label
        class="vertical-label text-md display-block"
        [ngClass]="{
          asterisk_input: progressQuestionRequired(ecoArea.get('madeProgress'))
        }"
      >
        Has {{ caseSummary?.learner?.firstName }} made progress since beginning
        {{ caseSummary?.intakeType === intakeTypes.PartB ? 'Special Education Services?' : 'Early ACCESS Services' }}
      </label>
      <fieldset>
        <app-radio-group
          [formControl]="ecoArea.get('madeProgress') | formControl"
          [options]="yesNoOptions"
          [showAsterisk]="false"
          [disabledState]="readOnly"
        >
        </app-radio-group>
      </fieldset>
    </ng-container>
  </mat-card>
</ng-container>
