<h4 class="text-primary card-spacing-top mb-2">Identify areas of need, including concerns, to be addressed in this IEP</h4>
<div class="table-overflow app-table">
  <table mat-table [dataSource]="preferenceInterestDataSource" matSort class="w-100">
    <ng-container matColumnDef="preferenceInterest">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="column--name">Preferences and Interests</th>
      <td mat-cell *matCellDef="let element" class="column--name">
        {{ element.preferenceInterest }}
      </td>
    </ng-container>

    <ng-container matColumnDef="llwTags">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>L/L/W Tags</th>
      <td mat-cell *matCellDef="let element">
        {{ element.llwTags }}
      </td>
    </ng-container>

    <ng-container matColumnDef="iepTags">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>IEP Tags</th>
      <td mat-cell *matCellDef="let element">
        {{ element.iepTags }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dataSource">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data / Source</th>
      <td mat-cell *matCellDef="let element">
        {{ element.dataSource }}
        <app-autocomplete label="Data/Source" [options]="dataSourceOptions"></app-autocomplete>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="preferenceInterestDisplayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: preferenceInterestDisplayedColumns"></tr>
  </table>
  <mat-paginator [dataSource]="preferenceInterestDataSource"></mat-paginator>
</div>

<section class="mt-2">
  <app-textarea label="Other information essential for the development of this IEP" [rows]="4"></app-textarea>
</section>
