<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Questionnaire Invite Creator</h3>

<div mat-dialog-content class="py-3" [formGroup]="surveyCreateForm" *ngIf="isReady">
  <mat-card>
    <h3 class="text-semibold">Select Team Members to Receive the Questionnaire</h3>
    <div id="survey_user_checkbox_wrapper" class="survey__list-view">
      <app-checkbox [showSelectAll]="true" [options]="teamMembers" [wrap]="true" [column]="true" controlName="users"></app-checkbox>
    </div>
    <hr class="input__divider" />

    <h3 class="text-semibold">Select Questionnaire Questions</h3>
    <mat-accordion displayMode="flat" multi class="accordion accordion--close">
      <mat-expansion-panel class="mat-elevation-z0" *ngIf="!isPk">
        <mat-expansion-panel-header>
          <mat-panel-title><h3 class="my-0 text-normal">K-12 Questions</h3> </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="input__divider mt-0" />
        <app-checkbox
          [showSelectAll]="true"
          [options]="questionBankK12"
          [wrap]="true"
          [column]="true"
          controlName="bankK12"
          [disabled]="readonlyQuestions"
        ></app-checkbox>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0" *ngIf="isSecondaryTransition">
        <mat-expansion-panel-header>
          <mat-panel-title> <h3 class="my-0 text-normal">Transition Questions</h3> </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="input__divider mt-0" />
        <app-checkbox
          [showSelectAll]="true"
          [options]="questionBankTransition"
          [wrap]="true"
          [column]="true"
          controlName="bankTransition"
          [disabled]="readonlyQuestions"
        ></app-checkbox>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0" *ngIf="isPk">
        <mat-expansion-panel-header>
          <mat-panel-title> <h3 class="my-0 text-normal">Preschool Questions</h3> </mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="input__divider mt-0" />
        <app-checkbox
          [showSelectAll]="true"
          [options]="questionBankPreschool"
          [wrap]="true"
          [column]="true"
          controlName="bankPreschool"
          [disabled]="readonlyQuestions"
        ></app-checkbox>
      </mat-expansion-panel>
    </mat-accordion>

    <hr class="input__divider" />

    <h3 class="text-semibold">Add Custom Question</h3>
    <app-textarea label="Custom Question" formControlName="customQuestion" [disabledState]="readonlyQuestions"></app-textarea>
    <hr class="input__divider" />

    <ng-container *ngIf="existingMembers && existingMembers?.length > 0">
      <h3 class="text-semibold">Team Members who have received the Questionnaire</h3>
      <ul>
        <li *ngFor="let member of existingMembers">{{ member }}</li>
      </ul>
    </ng-container>
  </mat-card>
</div>

<div mat-dialog-actions class="actions">
  <div>
    <button mat-button aria-label="Cancel Button" class="background-color--muted" (click)="onCancel()" [disabled]="isSending" tabindex="0">
      Cancel
    </button>
    <!-- Output is not ready yet. https://dev.azure.com/triskelle/IDOE/_workitems/edit/15312 -->
    <!-- <button mat-raised-button aria-label="Print" (click)="print()" tabindex="0">
      <mat-icon *ngIf="isPrinting">
        <mat-spinner color="primary" diameter="20"></mat-spinner>
      </mat-icon>
      Print
    </button> -->
    <button mat-raised-button aria-label="Send" color="primary" (click)="onSend()" [disabled]="isSending" tabindex="0">
      <mat-icon *ngIf="isSending" aria-label="Send">
        <mat-spinner color="primary" diameter="20"></mat-spinner>
      </mat-icon>
      Send
    </button>
  </div>
</div>
