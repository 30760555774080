<nav class="nav">
  <ul class="nav__list">
    <li class="nav__item">
      <a [routerLink]="['/']" tabindex="0" role="link" aria-labelledby="ACHIEVE Iowa Logo">
        <img src="/assets/img/achieve-logo.svg" class="logo" alt="ACHIEVE Iowa Logo" />
      </a>
    </li>
  </ul>
</nav>

<section class="not-found">
  <div class="not-found__container">
    <main class="not-found__content">
      <div class="display-flex flex-column justify-content-between align-items-center">
        <section class="not-found__header">
          <h1 class="mb-0">401</h1>
        </section>

        <section class="not-found__info">
          <h2 class="mt-0">Sorry, you don't have permissions to view this page...</h2>
          <button color="primary" mat-flat-button aria-label="Go Home" class="not-found__cta" (click)="onGoHome()" tabindex="0">
            Go Home
          </button>
        </section>
      </div>
    </main>
  </div>
</section>
