<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Are you sure?</h3>
<div mat-dialog-content class="py-3">
  <mat-card>
    <p class="my-0">Clicking Yes will {{ action }} this assignment.</p>
  </mat-card>
</div>
<div mat-dialog-actions align="end">
  <button class="background-color--muted mr-1" mat-button aria-label="Cancel Button" (click)="selected(false)" cdkFocusInitial tabindex="0">
    Cancel
  </button>

  <button mat-raised-button aria-label="Yes" color="primary" (click)="selected(true)" tabindex="0">Yes</button>
</div>
