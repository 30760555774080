import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable, Subscription, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { KeyValuePair } from './models/key-value-pair';
import { usStates } from './models/us-states';
import { WeightUnits } from './models/weight-units';
import { UnknownYesNo } from './models/yes-no';

export const yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

export const notSureYesNoOptions: KeyValuePair[] = [
  new KeyValuePair(UnknownYesNo.Yes, 'Yes'),
  new KeyValuePair(UnknownYesNo.No, 'No'),
  new KeyValuePair(UnknownYesNo.Unknown, 'Not Sure'),
];

export const weightUnitOptions: KeyValuePair[] = [
  new KeyValuePair(WeightUnits.lbOz, WeightUnits.lbOz),
  new KeyValuePair(WeightUnits.g, WeightUnits.g),
  new KeyValuePair(WeightUnits.kg, WeightUnits.kg),
];

export const usStateOptions = usStates.map((s) => new KeyValuePair(s, s));

export const subscribeToValueChanges = (formGroup: FormGroup, formEventsConfiguration: any): Subscription => {
  const subs = new Subscription();
  for (const controlPath in formEventsConfiguration) {
    if (formEventsConfiguration.hasOwnProperty(controlPath)) {
      const eventHandler = formEventsConfiguration[controlPath];
      subs.add(formGroup.get(controlPath).valueChanges.subscribe((x) => eventHandler(x)));
    }
  }
  return subs;
};

export const debounceFormValueChanges = (control: AbstractControl, delay = 1000): Observable<string> =>
  control.valueChanges.pipe(debounce(() => timer(delay)));
