import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EvaluationDetail } from 'src/app/evaluation/models/evaluation';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { FormService } from 'src/app/shared/services/form.service';
import { EvaluationFormService } from '../services/evaluation-form.service';

@Component({
  selector: 'app-evaluation-observation',
  templateUrl: './evaluation-observation.component.html',
  styleUrls: ['./evaluation-observation.component.scss'],
})
export class EvaluationObservationComponent implements OnInit, OnDestroy {
  @Input() currentEvaluationDetail: EvaluationDetail;
  @Output() observationChange = new EventEmitter();
  @Output() observationValid = new EventEmitter<boolean>();
  formGroup: FormGroup = this.fb.group(
    {
      location: ['', { validators: Validators.required, updateOn: 'change' }],
      descriptionOfLocation: [''],
    },
    { updateOn: 'blur' }
  );
  locationOptions: KeyValuePair[] = [
    new KeyValuePair('home', 'Home'),
    new KeyValuePair('providerAgency', 'Provider Agency'),
    new KeyValuePair('childcare', 'Child Care'),
    new KeyValuePair('other', 'Other'),
  ];
  subscription: Subscription;

  constructor(private fb: FormBuilder, evaluationFormService: EvaluationFormService, private formService: FormService) {
    this.subscription = evaluationFormService.formSubmitted$.subscribe(() => this.formGroup.markAllAsTouched());
  }

  ngOnInit(): void {
    this.formGroup.controls.location.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (value === 'other') {
        this.formGroup.controls.descriptionOfLocation.markAsUntouched();
        this.formGroup.controls.descriptionOfLocation.setValidators([Validators.required]);
      } else {
        this.formGroup.controls.descriptionOfLocation.setValidators([]);
        this.formGroup.controls.descriptionOfLocation.setValue(null);
      }
      this.formGroup.updateValueAndValidity();
    });
    this.formGroup.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      this.observationValid.emit(this.formGroup.valid);
      this.observationChange.emit(value);
    });
    this.formGroup.patchValue(this.currentEvaluationDetail);
    this.formService.showAllErrors$.subscribe(() => this.formGroup.markAllAsTouched());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formGroup.reset();
  }
}
