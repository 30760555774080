<button
  mat-raised-button
  attr.aria-label="{{ expandButtonText$ | async }}"
  color="accent"
  (click)="toggleAccordion()"
  class="mr-2"
  tabindex="0"
>
  {{ expandButtonText$ | async }}
  <mat-icon class="ml-2" aria-labelledby="Expand">
    {{ expandButtonIcon$ | async }}
  </mat-icon>
</button>
