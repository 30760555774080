import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../../shared/services/notification.service';
import { SurveySchedulingService } from '../../services/survey-scheduling.service';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { FamilyMember } from '../../../shared/models/learner';

@Component({
  selector: 'app-survey-admin',
  templateUrl: './survey-admin.component.html',
  styleUrls: ['./survey-admin.component.scss'],
})
export class SurveyAdminComponent implements OnInit {
  formGroup = new FormGroup({
    id: new FormControl(null),
    sendAt: new FormControl(null, Validators.required),
    sendLimit: new FormControl(null),
    surveyCloseDate: new FormControl(null, Validators.required),
  });
  today: Date = new Date();
  surveySchedules: MatTableDataSource<any[]> = new MatTableDataSource<any[]>();
  constructor(
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly surveySchedulingService: SurveySchedulingService,
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.getSurveys();
  }
  getSurveys() {
    this.surveySchedulingService.getSpecialEducationScheduledJobs().subscribe((data) => {
      data.forEach((item) => {
        item.sendLimit = JSON.parse(item.parameters)[0].Value;
        item.surveyCloseDate = JSON.parse(item.parameters)[1]?.Value;
      });
      this.surveySchedules.data = data.sort((a, b) => {
        if (a.scheduledFor < b.scheduledFor) return 1;
        return -1;
      });
    });
  }
  edit(surveySchedule) {
    this.formGroup.patchValue({
      sendAt: surveySchedule.scheduledFor,
      sendLimit: surveySchedule.sendLimit,
      surveyCloseDate: surveySchedule.surveyCloseDate,
      id: surveySchedule.id,
    });
  }
  cancelEdit() {
    this.formGroup.reset();
  }
  delete(surveySchedule) {
    this.surveySchedulingService
      .upsertSpecialEducationScheduledJobs({
        id: surveySchedule.id,
        parameters: surveySchedule.parameters,
        scheduledFor: surveySchedule.scheduledFor,
        isDeleted: true,
      })
      .subscribe(() => {
        this.notificationService.success('Successfully scheduled emails');
        this.getSurveys();
        this.formGroup.reset();
      });
  }
  onSendTestEmail($event: MouseEvent) {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }
    this.surveySchedulingService
      .upsertSpecialEducationScheduledJobs({
        id: this.formGroup.get('id').value || undefined,
        scheduledFor: this.formGroup.get('sendAt').value,
        parameters: JSON.stringify([
          { Attribute: 'sendLimit', Value: this.formGroup.get('sendLimit').value },
          { Attribute: 'surveyCloseDate', Value: this.formGroup.get('surveyCloseDate').value },
        ]),
      })
      .subscribe(() => {
        this.notificationService.success('Successfully scheduled emails');
        this.getSurveys();
        this.formGroup.reset();
      });
  }
}
