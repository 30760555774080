import { Directive, OnInit, OnDestroy } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

import { Subscription } from 'rxjs';

import { ExpanderService } from '../services/expander.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-accordion[captureExpand]',
})
export class CaptureExpandDirective implements OnInit, OnDestroy {
  private expanderSubscription: Subscription;

  constructor(private matAccordion: MatAccordion, private expander: ExpanderService) {}

  ngOnInit(): void {
    this.expanderSubscription = this.expander.expanded.subscribe((expanded) => {
      if (expanded) {
        this.matAccordion.openAll();
      } else {
        this.matAccordion.closeAll();
      }
    });
  }

  ngOnDestroy(): void {
    this.expanderSubscription.unsubscribe();
  }
}
