import { Injectable } from '@angular/core';
import { abbreviationDictionary } from '../models/abbreviation-dictionary';

@Injectable({
  providedIn: 'root',
})
export class AbbreviationService {
  matcher: RegExp;

  constructor() {
    this.matcher = new RegExp('\\b(' + Object.keys(abbreviationDictionary).join('|') + ')\\b', 'g');
  }

  cleanUp(term: string): string {
    return term.replace(/[^a-zA-Z0-9 ]/g, '');
  }

  getValue(key: string): string {
    return abbreviationDictionary[this.cleanUp(key)];
  }

  expand(sentence: string, search: string, replace: string): string {
    const finder = new RegExp(this.cleanUp(search), 'g');
    return this.cleanUp(sentence).replace(finder, replace);
  }
}
