import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EcoArea } from 'src/app/evaluation/models/evaluation';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { EcoAreaService } from 'src/app/shared/services/eco-areas/eco-area.service';
import { EcoRatingType } from '../../../../evaluation/evaluation/steps/eco-wizard/eco-rating';
import { EcoRatingService } from '../../../../evaluation/evaluation/steps/eco-wizard/eco-rating.service';
import { EcoWizardData } from '../../../../evaluation/evaluation/steps/eco-wizard/eco-wizard.component';
import { EcoMatrixModalComponent } from '../../../../evaluation/modals/eco-matrix-modal/eco-matrix-modal.component';
import { TransitionPlanningRead } from '../../../../learner-management/transition-planning/services/transition-planning.service';
import { TransitionIds } from '../../../../learner-management/transition-planning/transition-planning-ids';
import { BaseComponent } from '../../../../shared/components/base-component/base-component';
import { CaseSummary, IntakeType } from '../../../../shared/models/case';
import { NotificationService } from '../../../../shared/services/notification.service';
import { DeactivationService } from '../../../services/deactivation.service';
import { LearnerService } from '../../../services/learner/learner.service';

@Component({
  selector: 'app-transition-planning-determination',
  templateUrl: './transition-planning-determination.component.html',
  styleUrls: ['./transition-planning-determination.component.scss'],
})
export class TransitionPlanningDeterminationComponent extends BaseComponent implements AfterContentInit, OnChanges {
  @Input() transitionPlanning: TransitionPlanningRead;
  @Input() formGroup: FormGroup;
  @Input() caseSummary: CaseSummary;
  @Input() readOnly = false;
  @Input() determinationStatementFromFormData = true;

  @Output() ecoRatingUpdateEvent = new EventEmitter();

  ecoAreas: EcoArea[];
  notMovingOptions: KeyValuePair[] = [];
  yesNoOptions = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];
  hasBeenPrompted = false;
  intakeTypes = IntakeType;

  get ecoAreaForm(): FormArray {
    return this.formGroup.get('ecoAreas') as FormArray;
  }

  get determination(): string {
    return this.getDetermination();
  }

  constructor(
    private ecoAreaService: EcoAreaService,
    private dialog: MatDialog,
    private readonly ecoRatingService: EcoRatingService,
    private readonly notificationService: NotificationService,
    deactivationService: DeactivationService,
    private readonly learnerService: LearnerService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    super(deactivationService);
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.transitionPlanning) {
      this.notMovingOptions = this.transitionPlanning.exitCodes.filter((x) => x.value === 'EOP' || x.value === 'ENR');
    }
  }

  async ngAfterContentInit(): Promise<void> {
    this.ecoAreas = await this.ecoAreaService.get().toPromise();

    if (!this.ecoAreas) return;

    const ecoAreasRatingsPromises = this.ecoAreas.map((ecoArea) =>
      this.ecoRatingService.get(this.transitionPlanning.id, ecoArea.id, EcoRatingType.Transition).toPromise()
    );

    Promise.all(ecoAreasRatingsPromises).then((ecoRatingResults) => {
      this.ecoAreas.forEach((ecoArea) => {
        const ecoRating = ecoRatingResults.find((x) => x?.ecoAreaId === ecoArea.id);
        const madeProgress = this.transitionPlanning.ecoRatingProgresses?.find((x) => x.key === ecoArea.id)?.value;
        const scoreText = ecoRating
          ? this.ecoRatingService.getSuggestedEcoRatingText(ecoRating.score, this.transitionPlanning.firstName)
          : null;

        const ecoRatingFormGroup = new FormGroup({
          id: new FormControl(ecoArea.id),
          score: new FormControl(ecoRating?.score),
          scoreText: new FormControl(scoreText),
          label: new FormControl(ecoArea.label),
          type: new FormControl(ecoArea.type),
          isLocked: new FormControl(ecoRating?.isLocked),
          madeProgress: new FormControl(madeProgress),
        });

        this.ecoAreaForm.insert(ecoArea.order, ecoRatingFormGroup, { emitEvent: false });
        this.changeDetector.detectChanges();
      });
    });
  }

  progressQuestionRequired(madeProgressControl: AbstractControl) {
    if (this.caseSummary?.exiting) {
      return madeProgressControl.value == null;
    }
    return false;
  }

  launchEcoDecisionTree(ecoArea: AbstractControl) {
    // prompt the user once per page load so it's not annoying
    if (this.hasBeenPrompted) {
      this.openEcoWizard(ecoArea);
    } else {
      this.notificationService.confirmation(
        `The ${
          this.caseSummary?.intakeType === this.intakeTypes.PartB ? 'Special Education' : 'Early ACCESS'
        } Team will only determine the exit ECO rating if this child is not eligible for special education and/or will not have an IEP finalized on the child's third birthday.`,
        () => {
          this.hasBeenPrompted = true;
          this.openEcoWizard(ecoArea);
        }
      );
    }
  }

  private async openEcoWizard(ecoArea: AbstractControl): Promise<void> {
    const modalRef = this.dialog.open(EcoMatrixModalComponent, {
      width: '1200px',
      data: {
        ecoAreaId: ecoArea.get('id').value,
        learnersFirstName: this.transitionPlanning.firstName,
        intakeType: this.intakeTypes.PartC,
        type: EcoRatingType.Transition,
        transitionId: this.transitionPlanning.id,
      } as EcoWizardData,
    });

    const result = await modalRef.afterClosed().toPromise();
    if (!result) return;

    const valuesScore = {
      score: result.ecoRatingScore,
      scoreText: this.ecoRatingService.getSuggestedEcoRatingText(result.ecoRatingScore, this.transitionPlanning.firstName),
      madeProgress: null,
    };

    ecoArea.patchValue(valuesScore);
    this.changeDetector.detectChanges();
    this.ecoRatingUpdateEvent.emit();
  }

  private getDetermination(): string {
    const bndDetermination = `When ${this.transitionPlanning.firstName} exited Early ACCESS services at three years of age, eligibility for special education services was not determined. The exit code is BND - Special Education Eligibility Not Determined.`;
    const eopDetermination = `${this.transitionPlanning.firstName} is not transitioning to special education. The family was referred to other programs and services. The exit code is EOP - Not eligible for special education services - referrals made to other programs.`;
    const enrDetermination = `${this.transitionPlanning.firstName} is not transitioning to special education. The family was not interested in referrals to other programs or services. The exit code is ENR - Not eligible for special education services - no referrals to other programs.`;
    const efbDetermination = `When ${this.transitionPlanning.firstName} exited Early ACCESS services at three years of age, ${this.transitionPlanning.firstName} was determined eligible for special education services. The exit code is EFB - Eligible for Special Education.`;

    // Potentially Eligible
    if (this.checkPotentiallyEligible()) {
      // family interested in special education OR family approved special education
      if (this.checkNotSpecialEducation() || this.checkFamilyApproveSpecialEducationStaff() !== true) {
        return bndDetermination;
      }

      // Disability suspect wasn't submitted, No disability suspect
      if (this.transitionPlanning.disabilitySuspected === null) {
        return bndDetermination;
      } else if (this.transitionPlanning.disabilitySuspected === false) {
        // interested in community programs
        if (this.checkCommunityPrograms()) {
          return eopDetermination;
        }
        // not interested in community programs
        return enrDetermination;
      }

      // Parent did not request for part b eval
      if (this.transitionPlanning.parentRequestedEval === false) {
        return bndDetermination;
      }

      // eligibility was not determined prior to learner's 3rd birthday
      if (this.checkEligibilityWasDeterminPriorThirdBirthDay() == false) {
        return bndDetermination;
      }

      // learner is eligible for part b
      if (this.transitionPlanning.partBEvaluationFinalized === true && this.transitionPlanning.partBCaseEligibility === true) {
        return efbDetermination;
      }

      // learner is not eligible for part b and interested in community programs
      if (this.checkCommunityPrograms()) {
        return eopDetermination;
      }

      // learner is not eligible for part b and not interested in community programs
      return enrDetermination;
    } else {
      // interested in community programs
      if (this.checkCommunityPrograms()) {
        return eopDetermination;
      }
      // not interested in community programs
      return enrDetermination;
    }
  }

  get getHelpText(): string {
    return "If this child is being evaluated for special education eligibility and IEP services, do not determine the ECO description statement here. If this child is not eligible for special education or will not have an IEP finalized on the child's third birthday, launch the ECO Ratings Wizard and determine the ECO description statement here.";
  }

  get isLearnerInTransitionAge(): boolean {
    const ageInMonths = this.learnerService.getAgeInMonths(this.transitionPlanning.dateOfBirth);
    return ageInMonths >= 33;
  }

  private checkCommunityPrograms(): boolean {
    if (this.determinationStatementFromFormData) {
      return this.formGroup.controls.nextSteps.value.includes(TransitionIds.communityPrograms);
    } else {
      return this.transitionPlanning.nextSteps.includes(TransitionIds.communityPrograms);
    }
  }

  private checkNotSpecialEducation(): boolean {
    const nextStepsSelection = this.determinationStatementFromFormData
      ? this.formGroup.controls.nextSteps.value
      : this.transitionPlanning.nextSteps;
    return nextStepsSelection?.length == 0 || nextStepsSelection.includes(TransitionIds.notSpecialEd);
  }

  private checkPotentiallyEligible(): boolean {
    if (this.determinationStatementFromFormData) {
      return this.formGroup.controls.potentiallyEligible.value;
    } else {
      return !!(
        this.transitionPlanning.requiresContinuedEffort ||
        this.transitionPlanning.progressionStandards ||
        this.transitionPlanning.uniqueComparedToPeers ||
        this.transitionPlanning.diagnosisOfCondition
      );
    }
  }

  private checkFamilyApproveSpecialEducationStaff(): boolean {
    if (this.determinationStatementFromFormData) {
      return this.formGroup.controls.familyApproveOfSpecialEd.value;
    } else {
      return this.transitionPlanning.familyApproveOfSpecialEd;
    }
  }

  private checkEligibilityWasDeterminPriorThirdBirthDay() {
    return LearnerService.goingToBeUnder(3, this.transitionPlanning.dateOfBirth, this.transitionPlanning.partBEvaluationFinalizedDate)
      .result;
  }
}
