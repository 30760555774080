<div>
  <mat-card class="card-spacing-top">
    <mat-card-content>
      <h3>Family Engagement - Special Education</h3>
      <app-help-directional-text>
        <p>Enter a 0 in "Limit Send Count" for all.</p>
      </app-help-directional-text>
      <form [formGroup]="formGroup">
        <div class="row">
          <div class="col">
            <div class="p-2 flex-fill">
              <app-date-picker label="Date to Send Surveys" controlName="sendAt" [min]="today" [hideAsterisk]="false"></app-date-picker>
            </div>
          </div>
          <div class="col">
            <div class="p-2 flex-fill">
              <app-number-field label="Limit Send Count" formControlName="sendLimit"></app-number-field>
            </div>
          </div>
          <div class="col">
            <div class="p-2 flex-fill">
              <app-date-picker
                label="Survey Close Date"
                controlName="surveyCloseDate"
                [min]="today"
                [hideAsterisk]="false"
              ></app-date-picker>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col display-flex align-items-center justify-content-end">
            <button mat-raised-button aria-label="Cancel Button" (click)="cancelEdit()" tabindex="0">Cancel</button> &nbsp;&nbsp;
            <button color="primary" mat-raised-button (click)="onSendTestEmail($event)" [disabled]="!formGroup.valid">
              Schedule Surveys
            </button>
          </div>
        </div>
      </form>

      <div class="table-overflow w-100">
        <mat-table [dataSource]="surveySchedules" class="mb-5">
          <ng-container matColumnDef="label">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                aria-label="Edit Icon"
                mat-icon-button
                color="primary"
                (click)="edit(element)"
                *ngIf="element.scheduledStatus === 'Scheduled'"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                aria-label="Delete Icon"
                mat-icon-button
                color="warn"
                (click)="delete(element)"
                *ngIf="element.scheduledStatus === 'Scheduled'"
                tabindex="0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="scheduledTime">
            <mat-header-cell *matHeaderCellDef>Date to Send Surveys</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.scheduledFor | date }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="surveyCount">
            <mat-header-cell *matHeaderCellDef>Survey Count to Send</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.sendLimit }} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="surveyCloseDate">
            <mat-header-cell *matHeaderCellDef>Survey Close Date</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.surveyCloseDate | date }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.scheduledStatus }} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="['label', 'scheduledTime', 'surveyCount', 'surveyCloseDate', 'status']"></mat-header-row>
          <mat-row *matRowDef="let element; columns: ['label', 'scheduledTime', 'surveyCount', 'surveyCloseDate', 'status']"></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
