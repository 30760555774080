import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { FileDocument } from 'src/app/shared/models/file-document';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { Eer } from '../models/eer';
import { EvaluationDetail, EvaluationDevelopmentalAreaStatus } from '../models/evaluation';
import { EvaluationDetailInterviewQuestion } from '../models/evaluation-detail-interview-question';

@Injectable({
  providedIn: 'root',
})
export class EvaluationDetailService {
  private baseUrl = 'api/evaluations';

  constructor(private http: HttpClient, private spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(`^${this.baseUrl}`));
  }

  get(evaluationId: string) {
    return this.http.get<EvaluationDetail[]>(`${this.baseUrl}/${evaluationId}/details`);
  }

  getDetail(evaluationId: string, evaluationDetailId: string) {
    return this.http.get<EvaluationDetail>(`${this.baseUrl}/${evaluationId}/details/${evaluationDetailId}`);
  }

  save(evaluationId: string, evaluationDetail: EvaluationDetail) {
    if (evaluationDetail.id) {
      return this.http.put<EvaluationDetail>(`${this.baseUrl}/${evaluationId}/details/${evaluationDetail.id}`, evaluationDetail);
    } else {
      return this.http.post<EvaluationDetail>(`${this.baseUrl}/${evaluationId}/details`, evaluationDetail as EvaluationDetail);
    }
  }

  delete(evaluationId: string, evaluationDetailId: string) {
    return this.http.put(`${this.baseUrl}/${evaluationId}/details/${evaluationDetailId}/delete`, null);
  }

  getInterviewQuestions() {
    return this.http.get<EvaluationDetailInterviewQuestion[]>('api/evaluations/details/interview-questions');
  }

  uploadDocumentation(uploadFile: any, evaluationId: string, evaluationDetailId: string) {
    this.spinnerService.incrementLoading();
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    return this.http
      .post<FileList>(
        `${this.baseUrl}/${evaluationId}/details/${evaluationDetailId}/documents`,
        uploadFile,
        this.getMultipartRequestHeaders()
      )
      .pipe(tap(turnOffSpinner, turnOffSpinner));
  }

  getDocuments(evaluationId: string, evaluationDetailId: string) {
    return this.http.get<FileDocument[]>(`${this.baseUrl}/${evaluationId}/details/${evaluationDetailId}/documents`);
  }

  deleteDocument(evaluationId: string, evaluationDetailId: string, documentId: string) {
    return this.http.put<FileDocument[]>(`${this.baseUrl}/${evaluationId}/details/${evaluationDetailId}/documents/${documentId}`, null);
  }

  getEer(evaluationId: string) {
    return this.http.get<Eer>(`${this.baseUrl}/${evaluationId}/eer`);
  }

  getCompletedEvalDevAreas(evaluationId: string) {
    return this.http.get<EvaluationDevelopmentalAreaStatus>(`api/evaluations/${evaluationId}/details/completed-developmental-areas`);
  }

  getCompletedCaseDevAreas(caseId: string) {
    return this.http.get<boolean>(`api/evaluations/details/completed-developmental-areas/${caseId}`);
  }

  protected getMultipartRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      Accept: 'application/json, text/plain, */*',
    });

    return { headers };
  }
}
