import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OutcomeProgressMonitor } from '../models/outcome-progress-monitor';
import { ProgressMonitorItem } from '../models/progress-monitor-item';

@Injectable({
  providedIn: 'root',
})
export class ProgressMonitorCService {
  outcomeCriterionChanged = new Subject<string>();

  constructor(private http: HttpClient) {}

  get(learnerId: string) {
    return this.http.get<OutcomeProgressMonitor[]>(`api/learners/${learnerId}/progress-monitoring-c`);
  }

  getSingle(learnerId: string, ifspOutcomeId: string) {
    return this.http.get<OutcomeProgressMonitor>(`api/learners/${learnerId}/progress-monitoring-c/${ifspOutcomeId}`);
  }

  add(learnerId: string, dto: ProgressMonitorItem) {
    return this.http.post<ProgressMonitorItem>(`api/learners/${learnerId}/progress-monitoring-c`, dto);
  }

  update(learnerId: string, dto: ProgressMonitorItem) {
    return this.http.put<ProgressMonitorItem>(`api/learners/${learnerId}/progress-monitoring-c/${dto.id}`, dto);
  }

  delete(learnerId: string, id: string) {
    return this.http.put(`api/learners/${learnerId}/progress-monitoring-c/delete/${id}`, null);
  }
}
