import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
// make sure to set default timezone
dayjs.tz.setDefault('America/Chicago');

export function tz(
  d?: string | number | dayjs.Dayjs | Date | null | undefined,
  format?: string | undefined,
  timeZone?: string | undefined
): Dayjs {
  return dayjs.tz(d, format, timeZone);
}

export function toTz(d?: string | number | dayjs.Dayjs | Date | null | undefined, timeZone?: string | undefined): Dayjs {
  return dayjs(d).tz(timeZone);
}

export function fromTz(d?: string | number | dayjs.Dayjs | Date | null | undefined, timeZone?: string | undefined): Dayjs {
  return dayjs.tz(d, timeZone);
}

export function toLocalDate(date: Date): Date {
  if (!date) return null;
  const cst = toTz(date, 'America/Chicago');
  return new Date(cst.year(), cst.month(), cst.date());
}
