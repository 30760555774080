<ng-container *ngIf="!caseSummary?.exitFinalized">
  <h3 class="card-spacing-top mb-2">Test Links - {{ caseDescription }}</h3>
  <mat-card class="py-2">
    <div class="action__row justify-content-start flex-wrap">
      <a
        [routerLink]="['/', 'cases', caseId, 'iep', 1, 'clp']"
        mat-raised-button
        color="primary"
        tabindex="0"
        role="link"
        aria-labelledby="Contingency Learning Plan"
      >
        Contingency Learning Plan</a
      >

      <a
        [routerLink]="['/', 'learner-management', caseSummary?.learnerId, 'postsecondary-summary']"
        mat-raised-button
        color="primary"
        class="mr-2"
        tabindex="0"
        role="link"
        >Postsecondary Summary</a
      >
    </div>

    <div class="action__row justify-content-start flex-wrap">
      <ng-container *appAuthForRoles="{ permission: [permissions.CreateIFSP], caseId: caseSummary.id }">
        <a
          (click)="beginAnnualReview()"
          *ngIf="caseSummary.activeIfspId && !caseSummary.activeIfspAnnualReview"
          class="mr-2"
          mat-raised-button
          color="primary"
        >
          Start Annual Review
        </a>

        <a
          (click)="beginPeriodicReview()"
          *ngIf="caseSummary.activeIfspId && !caseSummary.activeIfspPeriodicReview"
          class="mr-2"
          mat-raised-button
          color="primary"
        >
          Start Periodic Review
        </a>

        <a
          (click)="sendAnnualReviewPoll()"
          *ngIf="caseSummary.activeIfspId && caseSummary.activeIfspAnnualReview"
          class="mr-2"
          mat-raised-button
          color="primary"
        >
          Send Annual Review Questionnaire
        </a>

        <a
          (click)="sendPeriodicReviewPoll()"
          *ngIf="caseSummary.activeIfspId && caseSummary.activeIfspPeriodicReview"
          class="mr-2"
          mat-raised-button
          color="primary"
        >
          Send Periodic Review Questionnaire
        </a>

        <a
          (click)="setAnnualReviewDate()"
          *ngIf="caseSummary.activeIfspId && !caseSummary.activeIfspAnnualReview"
          class="mr-2"
          mat-raised-button
          color="primary"
        >
          Set Annual Review Date
        </a>
      </ng-container>
    </div>
  </mat-card>
</ng-container>
