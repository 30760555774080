import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { TaggedItem } from 'src/app/tags/tagged-item';
import { TaggingService } from 'src/app/tags/taggingService.service';
import { StringSizes } from '../../../shared/components/form/constants/constants';
import { TransitionCodeCategory } from '../../../shared/models/transitionCode';
import { NotificationService } from '../../../shared/services/notification.service';
import { IfspModificationService } from '../../services/ifsp-modification.service';
import { IfspServicesService } from '../../services/ifsp-service.service';
import { OutcomeService } from '../../services/outcome.service';

@Component({
  selector: 'app-ifsp-removal',
  templateUrl: './ifsp-removal.component.html',
  styleUrls: ['./ifsp-removal.component.scss'],
})
export class IfspRemovalComponent implements OnInit {
  formGroup = new FormGroup({
    endDate: new FormControl('', Validators.required),
    endReasonId: new FormControl(''),
    comments: new FormControl(''),
    tagForPwn: new FormControl(null),
  });
  stringSizes = StringSizes;
  reasonOptions: KeyValuePair[] = [];
  activeCall = false;

  constructor(
    private dialogRef: MatDialogRef<IfspRemovalComponent>,
    public ifspModificationService: IfspModificationService,
    public ifspServicesService: IfspServicesService,
    public ifspOutcomeService: OutcomeService,
    public notificationService: NotificationService,
    private readonly taggingService: TaggingService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      id: string;
      ifspId: string;
      modificationId: string;
      startDate: Date;
      type: string;
      title: string;
      subTitle: string;
      entity: any;
      onlyServiceAssociatedToOutcome: boolean;
      caseId: string;
      taggedItemId: string;
    }
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.data.type === 'Service') {
      this.formGroup.get('endReasonId').setValidators(Validators.required);

      this.ifspModificationService.getAllTransitionCodes(this.data.ifspId).subscribe((transitionCodes) => {
        this.reasonOptions = [];
        if (transitionCodes) {
          transitionCodes
            .filter((x) => x.codeCategory === TransitionCodeCategory.ServiceEnd)
            .forEach((transitionCode) => {
              this.reasonOptions.push(new KeyValuePair(transitionCode.id, transitionCode.label));
            });
        }
      });
    }
  }

  dismiss() {
    this.dialogRef.close();
  }

  endService() {
    const model = {
      modificationId: this.data.modificationId,
      ...this.formGroup.value,
    };
    return this.ifspServicesService.endService(this.data.ifspId, this.data.id, model);
  }

  endOutcome(id) {
    const model = {
      modificationId: this.data.modificationId,
      ...this.formGroup.value,
    };
    return this.ifspOutcomeService.endOutcome(this.data.ifspId, id, model);
  }

  private async getIfspModificationTag() {
    const tag = await this.taggingService.getIfspModificationTag(this.data.modificationId).toPromise();
    return tag;
  }

  private updateModificationTag(modificationTag: TaggedItem) {
    modificationTag.taggedForPwn = this.formGroup.value?.tagForPwn;
    this.taggingService.updateTag(modificationTag).subscribe(() => {
      this.endServiceCall();
    });
  }

  private addModificationTag() {
    this.taggingService
      .addTag({
        caseId: this.data.caseId,
        ifspModificationId: this.data.modificationId,
        taggedForPwn: this.formGroup.value?.tagForPwn,
      } as TaggedItem)
      .subscribe(() => {
        this.endServiceCall();
      });
  }

  onSubmit() {
    this.activeCall = true;
    if (this.formGroup.valid) {
      if (this.data.type === 'Service') {
        if (this.data.onlyServiceAssociatedToOutcome) {
          this.notificationService.confirmation(
            'This is the only service associated with an outcome, are you sure you want to end this service?',
            () => {
              if (this.formGroup.value?.tagForPwn) {
                this.getIfspModificationTag()
                  .then((tag) => {
                    this.updateModificationTag(tag);
                  })
                  .catch(() => {
                    this.addModificationTag();
                  });
              } else {
                this.endServiceCall();
              }
            },
            null,
            () => {
              this.activeCall = false;

              this.dialogRef.close();
            }
          );
        } else {
          this.endService().subscribe(() => {
            this.activeCall = false;

            this.dialogRef.close();
          });
        }
      } else if (this.data.type === 'Outcome') {
        this.endOutcome(this.data.id).subscribe(() => {
          this.activeCall = false;

          this.dialogRef.close();
        });
      }
    }
  }

  onTag() {
    this.formGroup.controls.tagForPwn.setValue(!this.formGroup.controls.tagForPwn.value);
  }

  private endServiceCall() {
    this.endService().subscribe(() => {
      this.notificationService.confirmation(
        'Would you like to end the outcome associated with this service?',
        () => {
          this.endOutcome(this.data.entity.outcomes[0].outcomeId).subscribe(() => {
            this.dialogRef.close();
          });
        },
        null,
        () => {
          this.dialogRef.close();
        }
      );
    });
  }
}
