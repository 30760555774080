import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { FirstNamePossessivePipe } from 'src/app/shared/pipes/first-name-possessive.pipe';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { IepPlaafpHelp } from 'src/app/shared/services/help/models/iep.help';
import { PseActivityService } from '../services/pse-activity.service';
import { DeactivationService } from '../../../../shared/services/deactivation.service';

@Component({
  selector: 'app-pse-activities',
  templateUrl: './pse-activities.component.html',
  styleUrls: ['./pse-activities.component.scss'],
})
export class PseActivitiesComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() iepId: string;
  @Input() plaafpId: string;
  @Input() secondaryTransitionId: string;
  @Input() learnerId: string;
  @Input() learnerFirstName: string;
  @Input() isFirstSecondaryTransition: boolean;

  iepPlaafpHelp = IepPlaafpHelp;
  helpSections = HelpSection;

  dataSource = new MatTableDataSource();
  displayedColumns = ['course', 'projectedDate', 'llw', 'completed'];

  activities: KeyValuePair[] = [new KeyValuePair('Diploma', 'Diploma'), new KeyValuePair('Certificate', 'Certificate')];

  formGroup = this.fb.group({
    id: '',
    activity: ['', Validators.required],
    totalCreditsNeeded: null,
    creditsEarned: null,
    comments: '',
    changesAdditions: '',
    targetedDateForGraduation: [null, Validators.required],
    courseListReviewed: [null, { updateOn: 'change' }],
    coursesModified: [null],
  });

  get graduationDateLabel() {
    return `Targeted date for graduation/completion for ${this.learnerFirstName}:`;
  }

  get changesAdditionsLabel() {
    const firstName = new FirstNamePossessivePipe().transform(this.learnerFirstName);
    return `Changes/additions to meet requirements based on ${firstName} disability`;
  }

  get creditsEarnedValidation() {
    const totalCreditsNeeded = this.formGroup.get('totalCreditsNeeded');
    const creditsEarned = this.formGroup.get('creditsEarned');
    return creditsEarned.value > totalCreditsNeeded.value;
  }

  save = new Observable<boolean>((observer) => {
    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };

    if (!this.formGroup.dirty || this.isSaving) {
      done();
      return;
    }

    this.isSaving = true;
    this.pseActivityService.update(this.iepId, this.plaafpId, this.secondaryTransitionId, this.formGroup.value).subscribe(() => done());
  });

  constructor(
    private fb: FormBuilder,
    private pseActivityService: PseActivityService,
    private helpService: HelpService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.load();

    this.formGroup.get('activity').valueChanges.subscribe((value) => {
      this.updateCreditFieldValidators(value);
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private load() {
    this.subscriptions.add(
      this.pseActivityService.get(this.iepId, this.plaafpId, this.secondaryTransitionId).subscribe((res) => {
        this.formGroup.patchValue(res);
        this.saveSubscription = this.formGroup.valueChanges;
        this.startAutosaving();
      })
    );
  }

  updateCreditFieldValidators(value) {
    const totalCreditsControl = this.formGroup.get('totalCreditsNeeded');
    const creditsEarnedControl = this.formGroup.get('creditsEarned');
    if (value === 'Diploma') {
      totalCreditsControl.setValidators([Validators.required, Validators.max(900)]);
      creditsEarnedControl.setValidators([Validators.required, Validators.max(900)]);
    } else {
      totalCreditsControl.setValidators(null);
      totalCreditsControl.reset();
      creditsEarnedControl.setValidators(null);
      creditsEarnedControl.reset();
    }
    this.formGroup.updateValueAndValidity();
  }

  toggleReview() {
    const control = this.formGroup.get('courseListReviewed');
    control.setValue(!control.value);
    control.markAsDirty();
  }

  coursesModified() {
    const control = this.formGroup.get('coursesModified');
    control.setValue(true);
    control.markAsDirty();
  }

  onOpenHelp(e: Event, section: HelpSection, term: HelpTerm) {
    e.preventDefault();

    const dictionary = this.helpService.getIepPlaafpDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      item: term,
      section,
      canBrowse: true,
    } as HelpModalConfig);
  }
}
