import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(dataset: any[], field: string, value: any): any[] {
    if (field.length >= 2) {
      if (field.startsWith('>=')) {
        return dataset.filter((i) => i[field.slice(2)] >= +value);
      }
      if (field.startsWith('<=')) {
        return dataset.filter((i) => i[field.slice(2)] <= +value);
      }
      if (field.startsWith('>')) {
        return dataset.filter((i) => i[field.slice(1)] > +value);
      }
      if (field.startsWith('<')) {
        return dataset.filter((i) => i[field.slice(1)] < +value);
      }
      if (field.startsWith('=')) {
        return dataset.filter((i) => i[field.slice(1)] == value);
      }
      if (field.startsWith('!=')) {
        return dataset.filter((i) => i[field.slice(2)] !== value);
      }
    }
    return dataset.filter((i) => i[field] === value);
  }
}
