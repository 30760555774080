<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Export</h3>

<ng-container mat-dialog-content [formGroup]="formGroup">
  <div class="row">
    <ng-container *ngIf="reportType !== 'raw'">
      <div class="col">
        <mat-form-field>
          <mat-label>Filter by AEA</mat-label>
          <mat-select formControlName="aeaIds" multiple placeholder="AEA">
            <mat-option>
              <ngx-mat-select-search [formControl]="aeaFilterControl" placeholderLabel="" noEntriesFoundLabel=""></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let aea of filteredAeas | async" [value]="aea.id">{{ aea.label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Filter by LEA</mat-label>
          <mat-select formControlName="leaIds" multiple placeholder="LEA">
            <mat-option>
              <ngx-mat-select-search [formControl]="leaFilterControl" placeholderLabel="" noEntriesFoundLabel=""></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let lea of filteredLeas | async" [value]="lea.id">{{ lea.label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col" *ngIf="buildings?.length > 0">
        <mat-form-field>
          <mat-label>Filter by Building</mat-label>
          <mat-select formControlName="buildingIds" multiple placeholder="Building">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="buildingFilterControl"
                placeholderLabel=""
                noEntriesFoundLabel=""
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let building of filteredBuildings | async" [value]="building.id">{{ building.label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>School Year</mat-label>
          <mat-select formControlName="schoolYearId" placeholder="School Year">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="schoolYearsFilterControl"
                placeholderLabel=""
                noEntriesFoundLabel=""
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let schoolYear of filteredSchoolYears | async" [value]="schoolYear.id">{{ schoolYear.label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col" *ngIf="surveyType === surveyTypes.EarlyAccessFamilyEngagementSurvey">
        <mat-form-field>
          <mat-label>Survey Source</mat-label>
          <mat-select formControlName="sourceIds" multiple placeholder="Source">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="sourcesFilterControl"
                placeholderLabel=""
                noEntriesFoundLabel=""
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let source of filteredSources | async" [value]="source.id">{{ source.label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Race</mat-label>
          <mat-select formControlName="raceIds" multiple placeholder="Race">
            <mat-option>
              <ngx-mat-select-search [formControl]="racesFilterControl" placeholderLabel="" noEntriesFoundLabel=""></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let source of filteredRaces | async" [value]="source.id">{{ source.label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field>
          <mat-label>Gender</mat-label>
          <mat-select formControlName="genderIds" multiple placeholder="Gender">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="gendersFilterControl"
                placeholderLabel=""
                noEntriesFoundLabel=""
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let source of filteredGenders | async" [value]="source.id">{{ source.label }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Will be used with SE do not remove -->
      <!-- <div class="col">
      <mat-form-field>
        <mat-label>Age</mat-label>
        <mat-select formControlName="ages" multiple placeholder="Age">
          <mat-option>
            <ngx-mat-select-search [formControl]="agesFilterControl" placeholderLabel="" noEntriesFoundLabel=""></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let source of filteredAges | async" [value]="source.id">{{ source.label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->
    </ng-container>
    <div class="col">
      <mat-form-field>
        <mat-label>Output Type</mat-label>
        <mat-select formControlName="outputType" placeholder="Output Type">
          <mat-option *ngFor="let outputType of outputTypes" [value]="outputType">{{ outputType }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</ng-container>
<div mat-dialog-action class="display-flex justify-content-end align-items-center mt-4">
  <div class="display-flex">
    <button mat-raised-button aria-label="Cancel Button" class="background-color--muted mr-2" mat-dialog-close tabindex="0">Cancel</button>
    <button
      mat-raised-button
      aria-label="Export"
      color="primary"
      (click)="onExport(formGroup.value)"
      [disabled]="formGroup.invalid"
      [disableAfterBusy]="formGroup.invalid"
      [isBusy]="activeCall"
      tabindex="0"
    >
      Export
    </button>
  </div>
</div>
