export interface ElpaDto {
  schoolYear: number;
  gradeLevelWhenAssessed: string;
  listeningScaleScore?: number;
  listeningLevel?: string;
  readingScaleScore?: number;
  readingLevel?: string;
  speakingScaleScore?: number;
  speakingLevel?: string;
  writingScaleScore?: number;
  writingLevel?: string;
}

export enum GradeLevelWhenAssessed {
  KG = 'KG',
  Grade0 = '0',
  Grade1 = '1',
  Grade2 = '2',
  Grade3 = '3',
  Grade4 = '4',
  Grade5 = '5',
  Grade6 = '6',
  Grade7 = '7',
  Grade8 = '8',
  Grade9 = '9',
  Grade10 = '10',
  Grade11 = '11',
  Grade12 = '12',
  T9 = 'T9',
}

export enum PerformanceLevel {
  Level1 = '1',
  Level2 = '2',
  Level3 = '3',
  Level4 = '4',
  Level5 = '5',
  NotAttempted = 'Not Attempted',
  Exempt = 'Exempt',
  PerformanceNotDetermined = 'Performance Not Determined',
}
