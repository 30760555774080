<div class="mb-2 display-flex justify-content-end align-items-center" [ngClass]="{ 'card-spacing-top': viewOverallSummary }">
  <button
    mat-raised-button
    attr.aria-label="{{ isAccordionsExpanded ? 'Collapse All' : 'Expand All' }}"
    color="accent"
    (click)="toggleAccordion()"
    class="mr-2"
    tabindex="0"
  >
    {{ isAccordionsExpanded ? 'Collapse All' : 'Expand All' }}
  </button>
  <button class="mr-2" mat-raised-button aria-label="View Summary" color="primary" (click)="onViewOverallSummary()" tabindex="0">
    View Summary
  </button>
  <button mat-raised-button aria-label="View IEP Service Tags" color="accent" (click)="openTags()" tabindex="0">
    View IEP Service Tags
  </button>
</div>

<mat-accordion class="accordion accordion--short" [multi]="true">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class="my-0 text-normal">{{ iepServiceHelp.Services }}</h3>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-help-directional-text>
      <p>
        Select the regular, purposeful, ongoing set of actions delivered to or on behalf of a student over time. Provide a description of
        the service including the number of minutes and frequency, the setting where
        <a (click)="openIThree()">services</a>
        are provided, and the persons responsible.
      </p>
    </app-help-directional-text>
    <app-iep-ssaa-services
      *ngIf="learner"
      [learner]="learner"
      [isPK]="learner.isPK"
      [caseSummary]="caseSummary"
      [iepIncludesTrialPlacement]="caseSummary.activeIepIncludesTrialPlacement"
      [trialPlacementTarget]="caseSummary.activeIepTrialPlacementTarget"
      [iep]="iepView"
    ></app-iep-ssaa-services>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Activities and Supports</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <app-help-directional-text>
      <p>
        Select and describe the
        <a
          class="text-underline text-lower"
          (click)="onOpenHelp($event, helpSection.IepService, iepServiceHelp.ActivitySupport)"
          tabindex="0"
          role="link"
        >
          {{ iepServiceHelp.ActivitySupport }} </a
        >, including the frequency and provider(s).
      </p>
    </app-help-directional-text>

    <app-iep-ssaa-activities-supports
      *ngIf="learner"
      [iepIncludesTrialPlacement]="caseSummary.activeIepIncludesTrialPlacement"
      [trialPlacementTarget]="caseSummary.activeIepTrialPlacementTarget"
    >
    </app-iep-ssaa-activities-supports>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Accommodations</h3></mat-panel-title>
    </mat-expansion-panel-header>
    <app-help-directional-text>
      <p>
        Describe each
        <a
          (click)="onOpenHelp($event, helpSection.IepService, iepServiceHelp.Accommodation)"
          class="text-lower text-underline"
          tabindex="0"
          role="link"
          >{{ iepServiceHelp.Accommodation }}</a
        >
        including the frequency and provider(s).
      </p>
    </app-help-directional-text>
    <app-iep-ssaa-accommodations
      *ngIf="learner"
      [learnerId]="learner?.id"
      [iepIncludesTrialPlacement]="caseSummary.activeIepIncludesTrialPlacement"
      [trialPlacementTarget]="caseSummary.activeIepTrialPlacementTarget"
    >
    </app-iep-ssaa-accommodations>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Extended School Year (ESY)</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <app-iep-ssaa-extended-school *ngIf="learner" [learner]="learner" [iepMeetings]="iepMeetings"></app-iep-ssaa-extended-school>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Physical Education</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <app-iep-ssaa-physical-education></app-iep-ssaa-physical-education>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Assessment</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <app-iep-ssaa-assessment *ngIf="learner" [learner]="learner"></app-iep-ssaa-assessment>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" #lrePanel>
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Least Restrictive Environment</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <app-iep-ssaa-least-restrictive-env
      *ngIf="caseSummary && learner"
      [learner]="learner"
      [previousIepId]="previousIepId"
      [caseSummary]="caseSummary"
      [iepMeetings]="iepMeetings"
      [isAmendment]="false"
    ></app-iep-ssaa-least-restrictive-env>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Summary</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <app-iep-ssaa-summary
      *ngIf="caseSummary && learner"
      [learner]="learner"
      [caseSummary]="caseSummary"
      [iepId]="iepId"
      [caseId]="caseId"
      [isPK]="learner.isPK"
      [iepMeetings]="iepMeetings"
    ></app-iep-ssaa-summary>
  </mat-expansion-panel>
</mat-accordion>

<div class="page-bottom-leeway"></div>
