import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';

@Directive({
  selector: '[appAuthForRoles]',
})
export class AuthForRolesDirective {
  private hasView = false;

  @Input() set appAuthForRoles(data: { permission: AppPermissions[]; caseId: string; additionalRequirement?: Map<string, string[]> }) {
    if (this.authService.isAllowedByCaseId(data.caseId, data.additionalRequirement, ...data.permission) && !this.hasView) {
      this.vcRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!this.authService.isAllowedByCaseId(data.caseId, data.additionalRequirement, ...data.permission) && this.hasView) {
      this.vcRef.clear();
      this.hasView = false;
    }
  }

  constructor(private templateRef: TemplateRef<any>, private vcRef: ViewContainerRef, private authService: AuthService) {}
}
