<ng-container *ngIf="qaByUsers">
  <mat-card>
    <p><strong>Team Members who have received the Questionnaire: </strong>{{ surveyResponse.responders | join : ', ' }}.</p>
    <p>{{ responded }} of {{ surveyResponse.responders.length }} responded.</p>
    <fieldset>
      <app-radio-group label="View By:" [options]="viewOptions" [formControl]="viewByControl"> </app-radio-group>
    </fieldset>
    <mat-accordion displayMode="flat" multi class="accordion accordion--close">
      <ng-container *ngIf="viewByControl.value === 'team'">
        <mat-expansion-panel class="mat-elevation-z0" *ngFor="let user of qaByUsers">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="my-0 text-normal">{{ user.name }} - submitted on {{ user.answered | dateFormat }}</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <table class="table table-bordered w-100">
            <thead>
              <tr>
                <td>Question</td>
                <td>Response</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let questionAnswer of user.questionAnswers">
                <td>{{ questionAnswer.key }}</td>
                <td>{{ questionAnswer.value }}</td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </ng-container>
      <ng-container *ngIf="viewByControl.value === 'question'">
        <mat-expansion-panel class="mat-elevation-z0" *ngFor="let qa of qaByQuestion">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 class="my-0 text-normal">{{ qa.question }}</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <table class="table table-bordered w-100">
            <thead>
              <tr>
                <td>User</td>
                <td>Response</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let questionAnswer of qa.answers">
                <td>{{ questionAnswer.key }}</td>
                <td>{{ questionAnswer.value }}</td>
              </tr>
            </tbody>
          </table>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </mat-card>
</ng-container>
