import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { Aea } from 'src/app/shared/models/aea';
import { WeightUnits } from 'src/app/shared/models/weight-units';
import { UnknownYesNo } from 'src/app/shared/models/yes-no';
import { FamilyMember, LearnerReadonly } from '../../../models/learner';
@Component({
  selector: 'app-new-child-read-only',
  templateUrl: './new-child-read-only.component.html',
  styleUrls: ['./new-child-read-only.component.scss'],
})
export class NewChildReadOnlyComponent implements OnInit {
  aea: Aea;
  displayAgency: boolean;
  newChild: LearnerReadonly;
  screeningTools: string;
  shortDateFormat = shortDateFormat;
  unknownYesNo = UnknownYesNo;
  weightUnits = WeightUnits;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.newChild = this.route.snapshot.data.learner as LearnerReadonly;
  }

  getBirthWeight() {
    let birthWeight = '';
    switch (this.newChild.referralReasonInfo.referralReasonBirthWeightUnits) {
      case this.weightUnits.lbOz: {
        birthWeight += this.checkForZeroWeight(this.newChild.referralReasonInfo.referralReasonBirthInLb) + 'lb ';
        birthWeight += this.checkForZeroWeight(this.newChild.referralReasonInfo.referralReasonBirthInOz) + 'oz ';
        break;
      }
      case this.weightUnits.g: {
        birthWeight += this.checkForZeroWeight(this.newChild.referralReasonInfo.referralReasonBirthInGrams) + 'g';
        break;
      }
      case this.weightUnits.kg: {
        birthWeight += this.checkForZeroWeight(this.newChild.referralReasonInfo.referralReasonBirthInKilograms) + 'kg';
        break;
      }
    }
    return birthWeight;
  }

  getBestWayToReach(family: FamilyMember) {
    const bestWay = [];
    if (family.bestWayToReachEmail) {
      bestWay.push('Email');
    }
    if (family.bestWayToReachHomePhone) {
      bestWay.push('Home Phone');
    }
    if (family.bestWayToReachCellPhone) {
      bestWay.push('Cell Phone');
    }
    if (family.bestWayToReachWorkPhone) {
      bestWay.push('Work Phone');
    }
    return bestWay.join(', ');
  }

  isADevelopmentalDelay() {
    return (
      this.newChild.referralReasonInfo.referralReasonCognitive ||
      this.newChild.referralReasonInfo.referralReasonMotor ||
      this.newChild.referralReasonInfo.referralReasonCommunication ||
      this.newChild.referralReasonInfo.referralReasonAdaptive ||
      this.newChild.referralReasonInfo.referralReasonSocialEmotional ||
      this.newChild.referralReasonInfo.referralReasonOther
    );
  }

  developmentDelayList() {
    let delList = '';
    if (this.newChild.referralReasonInfo.referralReasonCognitive) {
      delList += 'Cognitive';
    }
    if (this.newChild.referralReasonInfo.referralReasonMotor) {
      delList.length === 0 ? (delList += 'Motor') : (delList += ', Motor');
    }
    if (this.newChild.referralReasonInfo.referralReasonCommunication) {
      delList.length === 0 ? (delList += 'Communication') : (delList += ', Communication');
    }
    if (this.newChild.referralReasonInfo.referralReasonAdaptive) {
      delList.length === 0 ? (delList += 'Adaptive / Self-Help') : (delList += ', Adaptive / Self-Help');
    }
    if (this.newChild.referralReasonInfo.referralReasonSocialEmotional) {
      delList.length === 0 ? (delList += 'Social-Emotional') : (delList += ', Social-Emotional');
    }
    if (this.newChild.referralReasonInfo.referralReasonOther) {
      delList.length === 0 ? (delList += 'Other') : (delList += ', Other');
    }
    return delList;
  }

  shouldShowSection(section: any) {
    for (const key in section) {
      if (section[key] !== null && section[key] !== '' && section[key]?.length > 0) {
        return true;
      }
    }
    return false;
  }

  checkForZeroWeight(weight: string) {
    if (weight === null) {
      return '0';
    }
    return weight;
  }

  onPrint() {
    window.print();
  }
}
