<mat-card
  *ngIf="routeUsers?.length > 1"
  class="page-header"
  [ngClass]="{
    'background--removed': backgroundColor === 'none',
    'background--red': backgroundColor === 'red',
    'background--blue': backgroundColor === 'blue',
    'background--blue-dark': backgroundColor === 'blueDark',
    'background--purple': backgroundColor === 'purple',
    'background--yellow': backgroundColor === 'yellow',
    'page-header--condensed': condensed === true
  }"
>
  <div class="page-header__nav hide-print contact-info">
    <ng-content></ng-content>

    <!-- <ul>
      <ng-container *ngFor="let routeUser of routeUsers">
        <li *ngIf="!isRepeatedRouteUserElement(routeUser)">
          {{ routeUser.name }} is on this page{{ doesUserExistMoreThanOnce(routeUser.userId) ? ', possibly on multiple tabs/devices.' : '.' }}
          <ul *ngIf="routeUser.email || routeUser.phoneNumber">
            <li *ngIf="routeUser.email">Email: {{ routeUser.email }}</li>
            <li *ngIf="routeUser.phoneNumber">Phone: {{ routeUser.phoneNumber }}</li>
          </ul>
        </li>
      </ng-container>
    </ul> -->
  </div>
</mat-card>
