import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageValidationComponent } from './page-validation.component';
import { PageValidationListComponent } from '../page-validation-list/page-validation-list.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PageValidationComponent, PageValidationListComponent],
  exports: [PageValidationComponent, PageValidationListComponent],
})
export class PageValidationModule {}
