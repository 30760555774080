import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GeneralSurveyQuestion } from '../../models/general-survey/general-survey-question';
import { GeneralSurveyAnswer } from '../../models/general-survey/general-survey-answer';

@Injectable({
  providedIn: 'root',
})
export class GeneralSurveyService {
  baseUrl = 'api/GeneralSurveyQuestions';

  constructor(private readonly http: HttpClient) {}

  get(surveyName: string): Observable<GeneralSurveyQuestion[]> {
    return this.http.get<GeneralSurveyQuestion[]>(`${this.baseUrl}/${surveyName}`);
  }

  updateSurveyAnswers(surveyId: string, answers: GeneralSurveyAnswer[]) {
    return this.http.put<void>(`${this.baseUrl}/survey-id/${surveyId}/answers`, answers);
  }

  submitSurvey(surveyId: string, answers: GeneralSurveyAnswer[]) {
    return this.http.put<void>(`${this.baseUrl}/survey-id/${surveyId}/complete`, answers);
  }
}
