import { TaggedItem } from 'src/app/tags/tagged-item';
import { IfspStatus, IfspType } from '../../ifsp/models/ifsp';
import { IepGoalQuantifiableDatum } from '../../shared/components/quantifiable-data/quantifiable-data';
import { GoalArea } from './goal-area';
import { ExtendedSchoolYearOptions } from './iep-extended-school-year';

export enum IepStatus {
  Complete = 'Complete',
  Active = 'Active',
  Draft = 'Draft',
  Legacy = 'Legacy',
}

export enum IepType {
  Initial = 'Initial',
  Interim = 'Interim',
}

export enum TrialPlacementTarget {
  AllItems = 'AllItems',
  SomeItems = 'SomeItems',
}

export interface IepGetByCaseId {
  id: string;
  iepStatus: IepStatus;
  iepType: IepType;
  ifspStatus: IfspStatus;
  ifspType: IfspType;
  createdOn: Date;
  activatedOn: Date;
  showESY: boolean;
  esyType: ExtendedSchoolYearOptions;
  isIep: boolean;
  startDate: Date;
  endDate: Date;
  isModifying: boolean;
  includesTrialPlacement: boolean;
  includesTrialPlacementServices: boolean;
  trialPlacementDate?: Date;
  trialPlacementTarget?: TrialPlacementTarget;
  iepDocumentId?: string;
  latestWeightedLevel?: string;
  showLateEvaluationRationale?: boolean;
  lateEvaluationRationale?: string;
  amendments: any[];
  documents: any[];
  isPK: boolean;
}

export interface IepView {
  id: string;
  iepType: IepType;
  iepStatus: IepStatus;
  createdOn: Date;
  activatedOn: Date;
  includesTrialPlacement: boolean;
  trialPlacementDate?: Date;
  trialPlacementTarget?: TrialPlacementTarget;
  durationDate?: Date;
  delayed?: boolean;
  transitionDelayReasonId?: string;
  iepDocumentId?: string;
  esyDocumentId?: string;
  startDate?: Date;
  endDate?: Date;
  showLateEvaluationRationale?: boolean;
  lateEvaluationRationale?: string;
  isFirstIep?: boolean;
  amendments: any[];
}

export interface IepAddUpdate {
  id?: string;
  caseId: string;
  learnerId: string;
  iepType: IepType;
  iepStatus: IepStatus;
  interimNotes?: string;
  isPKToK: boolean;
  includesTrialPlacement: boolean;
  trialPlacementDate?: Date;
  trialPlacementTarget?: TrialPlacementTarget;
  durationDate?: Date;
  transitionDelayReasonId?: string;
}

export interface IepGoal {
  id?: string;
  iepId: string;
  goalAreaIds?: string[];
  goalAreas?: GoalArea[];
  currentLevelDescription?: string;
  combinedGoal: boolean;
  standardOfComparisonOther: string;
  standardOfComparisonId?: string;
  conditionIndividualWillPerform: string;
  goalNickName: string;
  rubricMax?: number;

  frequencyAmount?: number;
  frequencyPeriod?: string;
  peopleMonitoringGoal: any[];
  collaborators: string;
  monitoringProceduresDescription: string;

  sameRateAsPeersReported?: boolean;
  sameRateFrequency?: number;
  sameRatePeriod: string;
  differentRateFrequency?: number;
  differentRatePeriod: string;
  methodsToReport: string[];
  otherMethodToReport: string;
  primaryQuantifiableData?: IepGoalQuantifiableDatum;
  additionalQuantifiableData?: IepGoalQuantifiableDatum[];
  domain?: string;
  isComplete?: boolean;
  amendmentId?: string;
  priorVersionId?: string;
  priorVersion: IepGoal;
  isActive: boolean;
  taggedItem: TaggedItem;
  displayGraph: boolean;
  amendmentEndDate?: Date;
  clpReadOnly?: boolean;
}

export interface IepGoalAddUpdate {
  id?: string;
  iepId: string;
  goalAreaIds?: string[];
  currentLevelDescription?: string;
  combinedGoal: boolean;
  standardOfComparisonId?: string;
  standardOfComparisonOther: string;
  conditionsIndividualWillPerform: string;
  goalNickName: string;

  frequencyAmount?: number;
  frequencyPeriod?: string;
  peopleMonitoringGoal: any[];
  collaborators: string;
  monitoringProceduresDescription: string;

  sameRateAsPeersReported?: boolean;
  sameRateFrequency?: number;
  sameRatePeriod: string;
  differentRateFrequency?: number;
  differentRatePeriod: string;
  methodsToReport: string;
  otherMethodToReport: string;
  primaryQuantifiableData?: IepGoalQuantifiableDatum;
  additionalQuantifiableData?: IepGoalQuantifiableDatum[];
  domain?: string;
  isComplete?: boolean;
  amendmentId?: string;
  priorVersionId?: string;
  priorVersion: IepGoal;
  isActive: boolean;
}

export interface IepLearnerInfo {
  iepTeamContact: string;
  iepEndDate: Date;
  iepMeetingType: string;
  iepMeetingDate: Date;
  iepMeetingRollCallSubmitted: Date;
  evaluationFinalized: Date;
  reevalDate?: Date;
  rightsWillNotTransfer?: boolean;
  dateRightsWillTransfer?: Date;
}

export interface IepGoalDataPoint {
  score: number;
  date: Date | string;
  secondary: boolean;
}
