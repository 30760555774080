import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SpinnerService } from '../../shared/services/spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class IfspDocumentService {
  constructor(private readonly http: HttpClient, private readonly spinnerService: SpinnerService) {}

  private baseUrl() {
    return 'api/ifsp-document/';
  }

  getAllDocuments(ifspId: any) {
    return this.http.get<any>(this.baseUrl() + `${ifspId}`);
  }

  addDocuments(ifspId: string, model: any) {
    this.spinnerService.incrementLoading();
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    return this.http
      .post<any[]>(this.baseUrl() + `${ifspId}`, model, this.getMultipartRequestHeaders())
      .pipe(tap(turnOffSpinner, turnOffSpinner));
  }

  removeDocument(id: any) {
    return this.http.put(this.baseUrl() + `${id}`, null);
  }

  private getMultipartRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      Accept: 'application/json, text/plain, */*',
    });

    return { headers };
  }
}
