import { Component, Input, OnInit } from '@angular/core';
import { IfspView } from '../../../../../../ifsp/models/ifsp';
import { DataHistoryService } from '../../../../services/data-history.service';

@Component({
  selector: 'app-data-history-part-c-eco-description',
  templateUrl: './data-history-part-c-eco-description.component.html',
  styleUrls: ['./data-history-part-c-eco-description.component.scss'],
})
export class DataHistoryPartCEcoDescriptionComponent implements OnInit {
  @Input() caseId: string;
  ifsps: IfspView[] = [];
  constructor(private dataHistoryService: DataHistoryService) {}

  ngOnInit(): void {
    this.dataHistoryService.getIfspsByCaseId(this.caseId).subscribe((res) => {
      this.ifsps = res;
    });
  }
}
