import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';

import { Trackable } from '../models/trackable';

@Injectable()
export class ChangeTrackerService {
  private readonly changed = new BehaviorSubject<boolean>(false);
  private readonly trackables = new Array<Trackable>();
  private changesSubscription: Subscription;

  public readonly hasChanged: Observable<boolean>;

  constructor() {
    this.hasChanged = this.changed.asObservable();
  }

  public subscribe(trackable: Trackable) {
    if (this.trackables.indexOf(trackable) !== -1) {
      throw new Error('Trackable was already registered.');
    }

    this.trackables.push(trackable);
    this.subscribeToChanges();
  }

  public unsubscribe(trackable: Trackable) {
    const index = this.trackables.indexOf(trackable);
    if (index === -1) {
      throw new Error('Trackable was not found.');
    }

    this.trackables.splice(index, 1);
    this.subscribeToChanges();
  }

  private subscribeToChanges(): void {
    if (this.changesSubscription) {
      this.changesSubscription.unsubscribe();
    }

    this.changesSubscription = combineLatest(this.trackables.map((x) => x.changed)).subscribe((changes) => {
      const changed = changes.some((change) => change);
      if (this.changed.value !== changed) {
        this.changed.next(changed);
      }
    });
  }
}
