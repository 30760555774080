import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-print-page',
  templateUrl: './print-page.component.html',
  styleUrls: ['./print-page.component.scss'],
})
export class PrintPageComponent implements OnInit, AfterViewInit {
  isPreview: boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<PrintPageComponent>, private window: Window) {}

  ngOnInit(): void {
    this.isPreview = this.data.isPreview;
  }

  ngAfterViewInit(): void {
    if (!this.isPreview) {
      this.window.print();
    }
  }

  onCancelPrint(): void {
    this.dialogRef.close();
  }
}
