import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DeleteReason } from '../../../shared/models/delete-reason';
import { MoveInDetailDto, MoveInDetailUpdateDto } from './move-in-detail';

@Injectable({
  providedIn: 'root',
})
export class MoveInDetailService {
  private baseUrl = (caseId: string) => `api/cases/${caseId}/move-in-details/`;

  public moveInDetailsUpdated = new Subject<string>();
  moveInDetailsUpdated$ = this.moveInDetailsUpdated.asObservable();

  constructor(private http: HttpClient) {}

  get(caseId: string): Observable<MoveInDetailDto> {
    return this.http.get<MoveInDetailDto>(`${this.baseUrl(caseId)}`);
  }

  update(caseId: string, model: MoveInDetailUpdateDto): Observable<MoveInDetailDto> {
    return this.http.put<MoveInDetailDto>(`${this.baseUrl(caseId)}`, model);
  }

  cancel(caseId: string, learnerId: string, reason: string) {
    const deleteReason: DeleteReason = { id: learnerId, reason: reason };
    return this.http.post<DeleteReason>(`${this.baseUrl(caseId)}delete`, deleteReason);
  }
}
