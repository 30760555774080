<button
  mat-flat-button
  aria-label="Amend"
  color="primary"
  *ngIf="hasOpenAmendment"
  class="mr-2"
  (click)="$event.stopPropagation(); onAmend()"
  tabindex="0"
>
  Amend
</button>

<div class="alert alert--warning p-3" *ngIf="minutesRecievingInstructionInvalid">
  Minutes receiving instruction must be less than or equal to 2400. The form was not saved.
</div>
<app-iep-ssaa-lre-stats
  *ngIf="!summaryView && caseSummary && learner"
  (stats)="populateStats($event)"
  [lreData]="lreStatsData"
  [ecpsHoursInWeek]="ecpsHoursInWeek"
  [iepId]="iepId"
  [previousIepId]="previousIepId"
  [isPK]="iepIsPK"
  [learner]="learner"
  [caseSummary]="caseSummary"
  [amendmentId]="amendmentId"
  [showK]="showK"
></app-iep-ssaa-lre-stats>
<ng-container [formGroup]="formGroup">
  <div class="row" [ngClass]="{ 'mt-3': !summaryView }">
    <div class="col-md-12 display-flex align-items-baseline" *ngIf="!iepIsPK || startingKDuringIep">
      <ng-container *ngIf="!summaryView && (!isAmendment || (hasAmendment && amendingLRE))">
        <p
          class="mr-3 mb-2 text-semibold"
          [ngClass]="{
            'mb-5': formGroup.get('minutesRecievingInstruction').value > 2400 || formGroup.get('minutesRecievingInstruction').value < 0
          }"
        >
          Minutes in the school week
        </p>
        <app-number-field
          class="input--small"
          *ngIf="showMinutesRecievingInstruction"
          formControlName="minutesRecievingInstruction"
          [hideAsterisk]="true"
          [min]="0"
          [max]="2400"
          id="minutesRecievingInstruction"
          aria-label="Min_School"
        ></app-number-field>
        <span class="ml-2" *ngIf="!summaryView && isAmendment && (!hasAmendment || !amendingLRE)">
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel.isActive"
            [changedVersionText]="formGroup.get('minutesRecievingInstruction').value"
            [currentVersionText]="lreModel.priorVersion?.minutesRecievingInstruction"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </span>
      </ng-container>
      <ng-container *ngIf="summaryView || (isAmendment && (!hasAmendment || !amendingLRE))">
        <p>
          Minutes in the school week
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel?.isActive"
            [changedVersionText]="lreFormData.minutesRecievingInstruction"
            [currentVersionText]="lreModel?.priorVersion?.minutesRecievingInstruction"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText=""
          ></app-compare-amendment-output>
        </p>
      </ng-container>
    </div>
    <div
      class="col-md-12 col-lg-6"
      [ngClass]="{
        'col-lg-12': summaryView
      }"
      *ngIf="showMinutesRecievingInstruction && formGroup.get('minutesRecievingInstruction').value < defaultEducationalMinutes"
    >
      <span class="text-danger" *ngIf="!summaryView">
        Warning: the minutes entered are less than the minutes in the school week for all learners.
      </span>
      <ng-container
        *ngIf="
          !summaryView && showMinutesRecievingInstruction && formGroup.get('minutesRecievingInstruction').value < defaultEducationalMinutes
        "
      >
        <label
          class="vertical-label text-md display-block position-fix"
          [ngClass]="{
            asterisk_input: formGroup.get('minutesRecievingInstructionRationale').hasError('required')
          }"
        >
          Provide a rationale for why
          {{ learner.firstName | firstNamePossessive }} school week is less than the minutes for all learners:
        </label>
        <app-textarea
          formControlName="minutesRecievingInstructionRationale"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.iepSsaaLRE.minutesRecievingInstructionRationale"
          [hideAsterisk]="true"
          attr.aria-required="{{ formGroup.get('minutesRecievingInstructionRationale').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
        <div class="mb-2" *ngIf="hasAmendment && lreModel">
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel.isActive"
            [changedVersionText]="formGroup.get('minutesRecievingInstructionRationale').value"
            [currentVersionText]="lreModel.priorVersion?.minutesRecievingInstructionRationale"
            [currentPrefixText]="lreModel.priorVersion ? 'Current: ' : ': '"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </ng-container>
      <ng-container *ngIf="!summaryView && isAmendment && (!hasAmendment || !amendingLRE)">
        <app-compare-amendment-output
          [amendmentId]="lreModel?.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="lreModel?.priorVersionId"
          [isActive]="lreModel?.isActive"
          [changedVersionText]="lreFormData.minutesRecievingInstruction"
          [currentVersionText]="lreModel?.priorVersion?.minutesRecievingInstruction"
          [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
          changedPrefixText=""
        ></app-compare-amendment-output>
      </ng-container>
      <ng-container *ngIf="summaryView">
        <p class="pl-5">
          Provide a rationale for time less than the recommended {{ defaultEducationalMinutes }} minutes/week:
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel?.isActive"
            [changedVersionText]="lreFormData.minutesRecievingInstructionRationale"
            [currentVersionText]="lreModel?.priorVersion?.minutesRecievingInstructionRationale"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText=""
          ></app-compare-amendment-output>
        </p>
      </ng-container>
    </div>

    <div class="col-md-12" *ngIf="iepIsPK || startingKDuringIep">
      <ng-container *ngIf="!summaryView && (!isAmendment || (hasAmendment && amendingLRE))">
        <div #recpsAccurateAnchor></div>
        <fieldset>
          <app-radio-group
            formControlName="recpsAccurate"
            [options]="yesNoOptions"
            label="Does the Regular Early Childhood Programs (RECP) Summary Table accurately represent all of {{
              learner.firstName | firstNamePossessive
            }} previous, current, and/or new attendance in RECPs based upon the IEP Meeting?"
          ></app-radio-group>
        </fieldset>

        <div class="mb-2" *ngIf="hasAmendment && lreModel">
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel.isActive"
            [changedVersionText]="formGroup.get('recpsAccurate').value | boolToYesNo"
            [currentVersionText]="lreModel.priorVersion?.recpsAccurate | boolToYesNo"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
        <div>
          <button
            mat-raised-button
            aria-label="Update Regular EC Program"
            *ngIf="formGroup.get('recpsAccurate').value === false"
            (click)="goToLearnerManagement()"
            class="mb-2"
            color="accent"
            tabindex="0"
          >
            Update Regular EC Program
          </button>

          <div class="table-overflow app-table mb-3">
            <table mat-table [dataSource]="recpsDataSource" class="w-100" matSort>
              <ng-container matColumnDef="startDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Start Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.projectedStartDate | dateFormat }}
                </td>
              </ng-container>
              <ng-container matColumnDef="program">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Regular EC Program</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.programType }}
                </td>
              </ng-container>
              <ng-container matColumnDef="frequency">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Frequency</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.howMuch }}
                </td>
              </ng-container>
              <ng-container matColumnDef="attendRECP">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Will attend RECP after IEP Meeting</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.attendRecp !== null ? (element.attendRecp | boolToYesNo) : '-' }}
                </td>
              </ng-container>

              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="9999">
                  <small><em>No records</em></small>
                </td>
              </tr>
              <tr mat-header-row *matHeaderRowDef="recpsDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: recpsDisplayedColumns"></tr>
            </table>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="summaryView || (isAmendment && (!hasAmendment || !amendingLRE))">
        <p>
          Does the Regular Early Childhood Programs (RECP) Summary Table accurately represent all of
          {{ learner.firstName | firstNamePossessive }} previous, current, and/or new attendance in RECPs based upon the IEP Meeting?
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel?.isActive"
            [changedVersionText]="lreFormData?.recpsAccurate | nullableBoolToYesNo : ''"
            [currentVersionText]="lreModel?.priorVersion?.recpsAccurate | nullableBoolToYesNo : ''"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText=""
          ></app-compare-amendment-output>
        </p>
        <button
          mat-raised-button
          aria-label="Update Regular EC Program"
          (click)="goToLearnerManagement()"
          class="mb-2"
          color="accent"
          tabindex="0"
        >
          Update Regular EC Program
        </button>
      </ng-container>
    </div>

    <div class="col-md-12" *ngIf="askReasonsCantProvideQuestion">
      <ng-container *ngIf="!summaryView && (!isAmendment || (hasAmendment && amendingLRE))">
        <p class="asterisk_input text-semibold">
          What are the reasons {{ learner.firstName }} cannot be provided all special education services in a
          {{ iepIsPK ? 'regular early childhood program' : 'general education setting' }}?
        </p>
        <app-textarea
          formControlName="reasonsCantProvide"
          [hideAsterisk]="true"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.iepSsaaLRE.reasonsCantProvide"
        ></app-textarea>
        <div class="mb-2" *ngIf="hasAmendment && lreModel">
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel.isActive"
            [changedVersionText]="formGroup.get('reasonsCantProvide').value"
            [currentVersionText]="lreModel.priorVersion?.reasonsCantProvide"
            [currentPrefixText]="lreModel.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </ng-container>
      <ng-container *ngIf="summaryView || (isAmendment && (!hasAmendment || !amendingLRE))">
        <p>
          What are the reasons {{ learner.firstName }} cannot be provided all special education services in a
          {{ iepIsPK ? 'regular early childhood program' : 'general education setting' }}?
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel?.isActive"
            [changedVersionText]="formGroup.get('reasonsCantProvide').value"
            [currentVersionText]="lreModel?.priorVersion?.reasonsCantProvide"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText=""
          ></app-compare-amendment-output>
        </p>
      </ng-container>
    </div>

    <div
      class="col-md-12 col-xl-6"
      [ngClass]="{
        'col-xl-12': summaryView
      }"
      *ngIf="askSpecialServicesProvidedQuestion"
    >
      <ng-container *ngIf="!summaryView && (!isAmendment || (hasAmendment && amendingLRE))">
        <fieldset>
          <app-radio-group
            formControlName="specialServicesProvided"
            label="Will {{
              learner.firstName
            }}'s special education services be provided in the regular early childhood program or school they currently attend?"
            [options]="yesNoOptions"
          ></app-radio-group>
        </fieldset>
        <div class="mb-2" *ngIf="hasAmendment && lreModel">
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel.isActive"
            [changedVersionText]="formGroup.get('specialServicesProvided').value | boolToYesNo"
            [currentVersionText]="lreModel.priorVersion?.specialServicesProvided | boolToYesNo"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </ng-container>
      <ng-container *ngIf="summaryView || (isAmendment && (!hasAmendment || !amendingLRE))">
        <p>
          Will {{ learner.firstName }}'s special education services be provided in the regular early childhood program or school they
          currently attend?
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel?.isActive"
            [changedVersionText]="lreFormData?.specialServicesProvided | nullableBoolToYesNo : ''"
            [currentVersionText]="lreModel?.priorVersion?.specialServicesProvided | nullableBoolToYesNo : ''"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText=""
          ></app-compare-amendment-output>
        </p>
      </ng-container>
    </div>

    <div
      class="col-md-12 col-xl-6"
      [ngClass]="{
        'col-xl-12': summaryView
      }"
      *ngIf="specialServicesProvided === false"
    >
      <ng-container *ngIf="!summaryView && (!isAmendment || (hasAmendment && amendingLRE))">
        <app-textarea
          label="Explain"
          formControlName="specialServicesProvidedExp"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.iepSsaaLRE.specialServicesProvidedExp"
        ></app-textarea>
        <div class="mb-2" *ngIf="hasAmendment && lreModel">
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel.isActive"
            [changedVersionText]="formGroup.get('specialServicesProvidedExp').value"
            [currentVersionText]="lreModel.priorVersion?.specialServicesProvidedExp"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </ng-container>
      <ng-container *ngIf="summaryView || (isAmendment && (!hasAmendment || !amendingLRE))">
        <p class="pl-5">
          Explanation:
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel?.isActive"
            [changedVersionText]="lreFormData?.specialServicesProvidedExp"
            [currentVersionText]="lreModel?.priorVersion?.specialServicesProvidedExp"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText=""
          ></app-compare-amendment-output>
        </p>
      </ng-container>
    </div>

    <div
      class="col-md-12 col-xl-6"
      [ngClass]="{
        'col-xl-12': summaryView
      }"
      *ngIf="askAttendSchoolIfQuestion"
    >
      <ng-container *ngIf="!summaryView && (!isAmendment || (hasAmendment && amendingLRE))">
        <fieldset>
          <app-radio-group
            formControlName="attendSchoolIf"
            label="Will {{ learner.firstName }} attend the {{
              iepIsPK ? 'regular early childhood program or school' : 'school'
            }} they would attend if nondisabled?"
            [options]="yesNoOptions"
          ></app-radio-group>
        </fieldset>
        <div class="mb-2" *ngIf="hasAmendment && lreModel">
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel.isActive"
            [changedVersionText]="formGroup.get('attendSchoolIf').value | boolToYesNo"
            [currentVersionText]="lreModel.priorVersion?.attendSchoolIf | boolToYesNo"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </ng-container>
      <ng-container *ngIf="summaryView || (isAmendment && (!hasAmendment || !amendingLRE))">
        <p>
          Will {{ learner.firstName }} attend the
          {{ iepIsPK ? 'regular early childhood program or school' : 'school' }}
          they would attend if nondisabled?
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel?.isActive"
            [changedVersionText]="lreFormData?.attendSchoolIf | nullableBoolToYesNo : ''"
            [currentVersionText]="lreModel?.priorVersion?.attendSchoolIf | nullableBoolToYesNo : ''"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText=""
          ></app-compare-amendment-output>
        </p>
      </ng-container>
    </div>

    <div
      class="col-md-12 col-xl-6"
      [ngClass]="{
        'col-xl-12': summaryView
      }"
      *ngIf="attendSchoolIf === false"
    >
      <ng-container *ngIf="!summaryView && (!isAmendment || (hasAmendment && amendingLRE))">
        <app-textarea
          label="Explain"
          formControlName="attendSchoolIfExp"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.iepSsaaLRE.attendSchoolIfExp"
        ></app-textarea>
        <div class="mb-2" *ngIf="hasAmendment && lreModel">
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel.isActive"
            [changedVersionText]="formGroup.get('attendSchoolIfExp').value"
            [currentVersionText]="lreModel.priorVersion?.attendSchoolIfExp"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </ng-container>
      <ng-container *ngIf="summaryView || (isAmendment && (!hasAmendment || !amendingLRE))">
        <p class="pl-5">
          Explanation:
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel?.isActive"
            [changedVersionText]="lreFormData?.attendSchoolIfExp"
            [currentVersionText]="lreModel?.priorVersion?.attendSchoolIfExp"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText=""
          ></app-compare-amendment-output>
        </p>
      </ng-container>
    </div>

    <div
      class="col-md-12 col-xl-6"
      [ngClass]="{
        'col-xl-12': summaryView
      }"
      *ngIf="askWhereReceiveSpecialEdQuestion"
    >
      <ng-container *ngIf="!summaryView && (!isAmendment || (hasAmendment && amendingLRE))">
        <app-select
          label="Where does {{ learner.firstName }} receive special education services?"
          formControlName="whereReceiveSpecialEd"
          [options]="whereReceiveSpecialEdOptions"
        >
        </app-select>
        <div class="mb-2" *ngIf="hasAmendment && lreModel">
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel.isActive"
            [changedVersionText]="formGroup.get('whereReceiveSpecialEd').value"
            [currentVersionText]="lreModel.priorVersion?.whereReceiveSpecialEd"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </ng-container>
      <ng-container *ngIf="summaryView || (isAmendment && (!hasAmendment || !amendingLRE))">
        <p>
          Where does {{ learner.firstName }} receive special education services?
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel.isActive"
            [changedVersionText]="formGroup.get('whereReceiveSpecialEd').value"
            [currentVersionText]="lreModel.priorVersion?.whereReceiveSpecialEd"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </p>
      </ng-container>
    </div>
    <div
      *ngIf="!iepIsPK && attendSchoolIf === false && this.formGroup.get('whereReceiveSpecialEd').value !== 'Special School'"
      class="col-md-12"
      [ngClass]="{
        'col-xl-6': attendSchoolIf !== false,
        'col-xl-12': attendSchoolIf === false || summaryView
      }"
    >
      <ng-container *ngIf="!summaryView && (!isAmendment || (hasAmendment && amendingLRE))">
        <fieldset>
          <app-radio-group
            label="Will {{ learner.firstName }} attend a special school?"
            formControlName="attendSpecialSchool"
            [options]="yesNoOptions"
          ></app-radio-group>
        </fieldset>
        <div class="mb-2" *ngIf="hasAmendment && lreModel">
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel.isActive"
            [changedVersionText]="formGroup.get('attendSpecialSchool').value | boolToYesNo"
            [currentVersionText]="lreModel.priorVersion?.attendSpecialSchool | boolToYesNo"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </ng-container>
      <ng-container *ngIf="summaryView || (isAmendment && (!hasAmendment || !amendingLRE))">
        <p class="text-semibold">
          Will {{ learner.firstName }} attend a special school?
          <app-compare-amendment-output
            [amendmentId]="lreModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="lreModel?.priorVersionId"
            [isActive]="lreModel.isActive"
            [changedVersionText]="formGroup.get('attendSpecialSchool').value | boolToYesNo"
            [currentVersionText]="lreModel.priorVersion?.attendSpecialSchool | boolToYesNo"
            [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </p>
      </ng-container>
    </div>

    <ng-container *ngIf="attendSpecialSchool === true || attendSpecialSchoolPK === true">
      <div
        class="col-md-12 col-xl-6"
        [ngClass]="{
          'col-xl-12': summaryView
        }"
      >
        <ng-container *ngIf="!summaryView && (!isAmendment || (hasAmendment && amendingLRE))">
          <label class="vertical-label asterisk_input position-fix" for="supplementaryAids">
            What are the reasons {{ learner.firstName }} cannot be provided an education program in
            {{ iepIsPK ? 'a regular early childhood program or ' : 'an' }}
            integrated school setting?
          </label>
          <app-textarea
            formControlName="supplementaryAids"
            id="supplementaryAids"
            [hideAsterisk]="true"
            [isBankableField]="true"
            [bankableFieldId]="bankableFieldIds.iepSsaaLRE.supplementaryAids"
          ></app-textarea>
          <div class="mb-2" *ngIf="hasAmendment && lreModel">
            <app-compare-amendment-output
              [amendmentId]="lreModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="lreModel?.priorVersionId"
              [isActive]="lreModel.isActive"
              [changedVersionText]="formGroup.get('supplementaryAids').value"
              [currentVersionText]="lreModel.priorVersion?.supplementaryAids"
              [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>
          </div>
        </ng-container>
        <ng-container *ngIf="summaryView || (isAmendment && (!hasAmendment || !amendingLRE))">
          <p class="pl-5">
            What are the reasons {{ learner.firstName }} cannot be provided an education program in
            {{ iepIsPK ? 'a regular early childhood program or ' : 'an' }}
            integrated school setting?
            <app-compare-amendment-output
              [amendmentId]="lreModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="lreModel?.priorVersionId"
              [isActive]="lreModel.isActive"
              [changedVersionText]="formGroup.get('supplementaryAids').value"
              [currentVersionText]="lreModel.priorVersion?.supplementaryAids"
              [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>
          </p>
        </ng-container>
      </div>

      <div
        class="col-md-12 col-xl-6"
        [ngClass]="{
          'col-xl-12': summaryView
        }"
      >
        <ng-container *ngIf="!summaryView && (!isAmendment || (hasAmendment && amendingLRE))">
          <label class="vertical-label asterisk_input position-fix" for="notPossibleForAids">
            What supplementary aids and services are needed to support
            {{ learner.firstName }} in the special education program?
          </label>
          <app-textarea
            formControlName="notPossibleForAids"
            id="notPossibleForAids"
            [hideAsterisk]="true"
            [isBankableField]="true"
            [bankableFieldId]="bankableFieldIds.iepSsaaLRE.notPossibleForAids"
          ></app-textarea>
          <div class="mb-2" *ngIf="hasAmendment && lreModel">
            <app-compare-amendment-output
              [amendmentId]="lreModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="lreModel?.priorVersionId"
              [isActive]="lreModel.isActive"
              [changedVersionText]="formGroup.get('notPossibleForAids').value"
              [currentVersionText]="lreModel.priorVersion?.notPossibleForAids"
              [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </div>
        </ng-container>
        <ng-container *ngIf="summaryView || (isAmendment && (!hasAmendment || !amendingLRE))">
          <p class="pl-5">
            What supplementary aids and services are needed to support
            {{ learner.firstName }} in the special education program?
            <app-compare-amendment-output
              [amendmentId]="lreModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="lreModel?.priorVersionId"
              [isActive]="lreModel.isActive"
              [changedVersionText]="formGroup.get('notPossibleForAids').value"
              [currentVersionText]="lreModel.priorVersion?.notPossibleForAids"
              [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
      </div>

      <div
        class="col-md-12 col-xl-6"
        [ngClass]="{
          'col-xl-12': summaryView
        }"
      >
        <ng-container *ngIf="!summaryView && (!isAmendment || (hasAmendment && amendingLRE))">
          <label class="vertical-label asterisk_input position-fix" for="continuumServiceAvailable">
            Why is it not possible for these aids and services to be provided in an integrated setting?
          </label>
          <app-textarea
            formControlName="continuumServiceAvailable"
            id="continuumServiceAvailable"
            [hideAsterisk]="true"
            [isBankableField]="true"
            [bankableFieldId]="bankableFieldIds.iepSsaaLRE.continuumServiceAvailable"
          ></app-textarea>
          <div class="mb-2" *ngIf="hasAmendment && lreModel">
            <app-compare-amendment-output
              [amendmentId]="lreModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="lreModel?.priorVersionId"
              [isActive]="lreModel.isActive"
              [changedVersionText]="formGroup.get('continuumServiceAvailable').value"
              [currentVersionText]="lreModel.priorVersion?.continuumServiceAvailable"
              [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </div>
        </ng-container>
        <ng-container *ngIf="summaryView || (isAmendment && (!hasAmendment || !amendingLRE))">
          <p class="pl-5">
            Why is it not possible for these aids and services to be provided in an integrated setting?
            <app-compare-amendment-output
              [amendmentId]="lreModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="lreModel?.priorVersionId"
              [isActive]="lreModel.isActive"
              [changedVersionText]="formGroup.get('continuumServiceAvailable').value"
              [currentVersionText]="lreModel.priorVersion?.continuumServiceAvailable"
              [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
      </div>

      <div
        class="col-md-12 col-xl-6"
        [ngClass]="{
          'col-xl-12': summaryView
        }"
        *ngIf="isInitialIep === false"
      >
        <ng-container *ngIf="!summaryView && (!isAmendment || (hasAmendment && amendingLRE))">
          <label class="vertical-label asterisk_input position-fix" for="cannotBeProvided">
            What is the continuum of services available for
            {{ learner.firstName }}?
          </label>
          <app-textarea
            formControlName="cannotBeProvided"
            id="cannotBeProvided"
            [hideAsterisk]="true"
            [isBankableField]="true"
            [bankableFieldId]="bankableFieldIds.iepSsaaLRE.cannotBeProvided"
          ></app-textarea>
          <div class="mb-2" *ngIf="hasAmendment && lreModel">
            <app-compare-amendment-output
              [amendmentId]="lreModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="lreModel?.priorVersionId"
              [isActive]="lreModel.isActive"
              [changedVersionText]="formGroup.get('cannotBeProvided').value"
              [currentVersionText]="lreModel.priorVersion?.cannotBeProvided"
              [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </div>
        </ng-container>
        <ng-container *ngIf="summaryView || (isAmendment && (!hasAmendment || !amendingLRE))">
          <p class="pl-5">
            What is the continuum of services available for
            {{ learner.firstName }}?
            <app-compare-amendment-output
              [amendmentId]="lreModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="lreModel?.priorVersionId"
              [isActive]="lreModel.isActive"
              [changedVersionText]="formGroup.get('cannotBeProvided').value"
              [currentVersionText]="lreModel.priorVersion?.cannotBeProvided"
              [currentPrefixText]="lreModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="!isDetailsView">
    <div class="action__row action__row--between" [ngClass]="{ 'mt-1': attendSchoolIf === false }" *ngIf="!summaryView">
      <div>
        <button
          *ngIf="!hasAmendment"
          type="button"
          mat-raised-button
          aria-label="View Summary"
          color="accent"
          (click)="openSummary()"
          tabindex="0"
        >
          View Summary
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>
