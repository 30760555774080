<table matSort mat-table [dataSource]="meetingDataSource" class="w-100">
  <ng-container matColumnDef="purpose">
    <th mat-sort-header mat-header-cell *matHeaderCellDef class="pr-3">Purpose</th>
    <td mat-cell *matCellDef="let meeting" class="pr-3">
      {{ getPurposesOfMeeting(meeting.purposesOfMeeting) }}
    </td>
  </ng-container>
  <ng-container matColumnDef="location">
    <th mat-sort-header mat-header-cell *matHeaderCellDef class="pr-3">Location</th>
    <td mat-cell *matCellDef="let meeting" class="pr-3">
      <ng-container>
        {{ meeting.location }}
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-sort-header mat-header-cell *matHeaderCellDef class="pr-3">Date and Time</th>
    <td mat-cell *matCellDef="let meeting" class="pr-3">
      {{ meeting.dateAndTime | dateTimeFormat : 'medium' : 'DT' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef class="text-right pr-3">Status</th>
    <td mat-cell *matCellDef="let meeting" class="text-right pr-3">
      {{ meeting.status }}
    </td>
  </ng-container>
  <ng-container matColumnDef="rollCall">
    <th mat-header-cell *matHeaderCellDef class="text-right"></th>
    <td mat-cell *matCellDef="let meeting" class="text-right">
      <div *ngIf="isDateCurrentOrPast(meeting.dateAndTime)">
        <button
          mat-flat-button
          aria-label="Roll Call"
          (click)="openRollCallModal(meeting)"
          color="primary"
          *ngIf="meeting.status !== meetingStatus.Complete"
          tabindex="0"
        >
          Roll Call
        </button>
        <button
          mat-flat-button
          aria-label="Edit Roll Call"
          (click)="openRollCallModal(meeting)"
          color="primary"
          *ngIf="meeting.status === meetingStatus.Complete && forCurrentIep(meeting)"
          tabindex="0"
        >
          Edit Roll Call
        </button>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr [hidden]="!displayFooter" mat-footer-row *matFooterRowDef="displayFooter ? ['noResults'] : []" class="example-second-footer-row"></tr>
</table>
