import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FamilyMember } from 'src/app/shared/models/learner';

@Component({
  selector: 'app-family-contact-info-view-more',
  templateUrl: './family-contact-info-view-more.component.html',
})
export class FamilyContactInfoViewMoreComponent implements OnInit {
  bestWayToContact: string;

  constructor(
    private dialogRef: MatDialogRef<FamilyContactInfoViewMoreComponent>,
    @Inject(MAT_DIALOG_DATA) public familyMember: FamilyMember
  ) {}

  ngOnInit(): void {
    this.getMetadata();
  }

  getMetadata() {
    if (!this.familyMember) throw '{data} param was not provided!';

    this.getBestWayToContact();
  }

  getBestWayToContact() {
    const waysToContact: string[] = [];

    if (this.familyMember.bestWayToReachCellPhone) waysToContact.push('Cell');

    if (this.familyMember.bestWayToReachHomePhone) waysToContact.push('Home');

    if (this.familyMember.bestWayToReachWorkPhone) waysToContact.push('Work');

    if (this.familyMember.bestWayToReachEmail) waysToContact.push('Email');

    if (this.familyMember.bestWayToReachText) waysToContact.push('Texting');

    this.bestWayToContact = waysToContact.join(', ');
  }

  onClose() {
    this.dialogRef.close();
  }
}
