export const downloadBlob = (blob: Blob, fileName: string) => {
  // Create blob URL and hidden anchor element.
  const objectUrl = URL.createObjectURL(blob);
  const a = document.createElement('a');

  // Make anchor link a download link and click it.
  a.href = objectUrl;
  a.download = fileName;
  a.click();

  URL.revokeObjectURL(objectUrl);
  a.remove();
};
