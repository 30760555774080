<div class="container">
  <app-print-head
    [showLogo]="true"
    [showClose]="true"
    (closeEvent)="onClose()"
    title="IFSP Early Intervention Outcomes and Services"
  ></app-print-head>

  <div class="print__container card-spacing-top">
    <section class="display-flex align-items-center justify-content-between avoid-break" *ngIf="learner?.fullName || learner?.dateOfBirth">
      <p class="my-0" *ngIf="learner?.fullName">
        <strong>Name: </strong>
        {{ learner?.fullName }}
      </p>

      <p class="my-0" *ngIf="learner?.dateOfBirth">
        <strong>DOB: </strong>
        {{ learner?.dateOfBirth | date : shortDateFormat }}
      </p>

      <p class="my-0">
        <strong>Current Date: </strong>
        {{ currentDate | dateFormat }}
      </p>
    </section>
  </div>

  <ng-container *ngIf="services && services.length > 0">
    <h3 class="card-spacing-top mb-2">List of Services</h3>
    <div class="print__container">
      <ul class="list">
        <ng-container *ngFor="let service of services; first as isFirst">
          <li class="list__item avoid-break" *ngIf="service.status === 'Complete'">
            <h4 class="text-primary heading--secondary mb-2" [ngClass]="{ 'mt-0': isFirst }">
              {{ service.typeOfService }}
            </h4>
            <div class="row">
              <div class="col-6">
                <p class="my-0">
                  <strong>Provider: </strong>
                  {{ service.provider ? service.provider : '-' }}
                </p>
              </div>
              <div class="col-6">
                <p class="my-0">
                  <strong>Agency: </strong>
                  {{ service.agency ? service.agency : '-' }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <p class="my-0">
                  <strong>Location: </strong>
                  {{ service.location ? service.location : '-' }}
                </p>
              </div>

              <div class="col-6">
                <p class="my-0">
                  <strong>Method: </strong>
                  {{ service.who ? service.who : '-' }}
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <p class="my-0">
                  <strong>Frequency: </strong>
                  {{ service.frequencyNumber }} sessions
                  {{ service.frequencyPeriod }}
                </p>
              </div>
              <div class="col-6">
                <p class="my-0">
                  <strong>Intensity: </strong>
                  {{ service.length }} m.
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <p class="my-0">
                  <strong>Start Date: </strong>
                  {{ service.startDate | dateFormat }}
                </p>
              </div>
              <div class="col-6">
                <p class="my-0"></p>
              </div>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-container>

  <ng-container *ngIf="outcomes && outcomes.length > 0">
    <hr class="input__divider hide-normal" *ngIf="services && services.length > 0" />
    <h3 class="card-spacing-top mb-2">List of Outcomes</h3>
    <div class="print__container">
      <ul class="list">
        <ng-container *ngFor="let outcome of outcomes; first as isFirst">
          <ng-container *ngIf="outcome.isComplete">
            <li class="list__item" *ngIf="ecoAreas && ecoAreas.length > 0 && familyAreas && familyAreas.length > 0">
              <h4 class="text-primary heading--secondary mb-2" [ngClass]="{ 'mt-0': isFirst }">Outcome Areas</h4>
              <div class="row avoid-break">
                <div class="col-6">
                  <p class="my-0">
                    <strong>Outcome area: </strong>
                    {{ outcome.typeOfOutcome }}
                  </p>
                </div>

                <div class="col-6" *ngIf="outcome.strategies && outcome.strategies.length > 0">
                  <p class="my-0">
                    <strong>Strategies: </strong>
                    <span *ngFor="let strategy of outcome.strategies; last as isLast">
                      {{ isLast ? strategy.name : strategy.name + ', ' }}
                    </span>
                  </p>
                </div>

                <div class="col-6" *ngIf="outcome.typeOfOutcome === 'Child' && outcome.ecoAreas && outcome.ecoAreas.length > 0">
                  <p class="my-0">
                    <strong>ECO Areas: </strong>
                    <span *ngFor="let ecoArea of outcome.ecoAreas; last as isLast">
                      {{ isLast ? getEcoAreaValue(ecoArea.ecoAreaId) : getEcoAreaValue(ecoArea.ecoAreaId) + ', ' }}
                    </span>
                  </p>
                </div>

                <div class="col-6" *ngIf="outcome.typeOfOutcome === 'Family' && outcome.familyAreas && outcome.familyAreas.length > 0">
                  <p class="my-0">
                    <strong>Family Outcomes: </strong>
                    <span *ngFor="let familyArea of outcome.familyAreas; last as isLast">
                      {{ isLast ? getFamilyAreasValue(familyArea.familyAreaId) : getFamilyAreasValue(familyArea.familyAreaId) + ', ' }}
                    </span>
                  </p>
                </div>
              </div>

              <ng-container *ngIf="outcome.criterias && outcome.criterias.length > 0">
                <h4 class="text-primary heading--secondary mt-4 mb-2">Criteria</h4>
                <div class="row avoid-break">
                  <div class="col-6">
                    <div class="row" *ngFor="let criteria of outcome.criterias">
                      <div class="col-12" *ngIf="criteria.criteriaForAccomplishment">
                        <p class="text-primary mt-0 mb-1">
                          <strong>{{ criteria.criteriaForAccomplishment }}</strong>
                        </p>
                      </div>
                      <div class="col-6" *ngIf="criteria.measurementType">
                        <p class="my-0">
                          <strong>Measurement Type: </strong>
                          {{ criteria.measurementType }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6" *ngIf="outcome.timelineNumber || outcome.timelinePeriod || outcome.outcomeEndDate">
                    <div class="row">
                      <div class="col-12">
                        <p class="text-primary mt-0 mb-1">
                          <strong>Timeline</strong>
                        </p>
                      </div>

                      <div class="col-6" *ngIf="outcome.timelineNumber">
                        <p class="my-0">
                          <strong>How long:</strong>
                          {{ outcome.timelineNumber }}
                        </p>
                      </div>

                      <div class="col-6" *ngIf="outcome.timelinePeriod">
                        <p class="my-0">
                          <strong>Time Period:</strong>
                          {{ outcome.timelinePeriod }}
                        </p>
                      </div>

                      <div class="col-6" *ngIf="outcome.outcomeEndDate">
                        <p class="my-0">
                          <strong>Outcome Date:</strong>
                          {{ outcome.outcomeEndDate | dateFormat }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="outcome.progresses && outcome.progresses.length > 0">
                  <div class="row avoid-break" *ngFor="let progress of outcome.progresses; first as isFirst">
                    <div class="col-12" *ngIf="isFirst">
                      <p class="text-primary mt-2 mb-1">
                        <strong>Monitoring for Progress</strong>
                      </p>
                    </div>
                    <div class="col-6" *ngFor="let progress of outcome.progresses">
                      <p class="my-0">
                        {{ progress.measuringMethod }} - {{ progress.frequencyNumber }} time(s) per
                        {{ progress.frequencyPeriod }}
                      </p>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </ng-container>
</div>
