import { Pipe, PipeTransform } from '@angular/core';
import { AbbreviationService } from '../services/abbreviation.service';

@Pipe({
  name: 'spellAbbreviationOut',
})
export class AbbreviationPipe implements PipeTransform {
  constructor(readonly abbreviations: AbbreviationService) {}

  private isFormatted = (term: string): boolean => term.includes('(') && term.includes(')');

  transform(term: string): string {
    if (this.isFormatted(term)) return term;
    const found = this.abbreviations.cleanUp(term).match(this.abbreviations.matcher);

    if (found && found.length >= 1) {
      const meaning = this.abbreviations.getValue(found[0]);
      const complete = this.isFormatted(meaning) ? meaning : `${meaning} (${found[0]})`;
      return this.abbreviations.expand(term, found[0], complete);
    }

    return term;
  }
}
