import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { EvaluationNote } from '../models/evaluation';

@Injectable({
  providedIn: 'root',
})
export class EvaluationNoteService {
  private baseUrl = 'api/evaluations';

  constructor(private http: HttpClient, private spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(`^${this.baseUrl}`));
  }

  getDetailNotes(evaluationId: string, evaluationDetailId: string) {
    return this.http.get<EvaluationNote[]>(`${this.baseUrl}/${evaluationId}/details/${evaluationDetailId}/notes`);
  }

  getNote(evaluationId: string, evaluationDetailId: string, evaluationNoteId: string) {
    return this.http.get<EvaluationNote>(`${this.baseUrl}/${evaluationId}/details/${evaluationDetailId}/notes/${evaluationNoteId}`);
  }

  save(evaluationId: string, evaluationDetailId: string, evaluationNote: EvaluationNote) {
    if (evaluationNote.id) {
      return this.http.put<EvaluationNote>(
        `${this.baseUrl}/${evaluationId}/details/${evaluationDetailId}/notes/${evaluationNote.id}`,
        evaluationNote
      );
    } else {
      return this.http.post<EvaluationNote>(
        `${this.baseUrl}/${evaluationId}/details/${evaluationDetailId}/notes`,
        evaluationNote as EvaluationNote
      );
    }
  }

  delete(evaluationId: string, evaluationDetailId: string, evaluationNoteId: string) {
    return this.http.put(`${this.baseUrl}/${evaluationId}/details/${evaluationDetailId}/notes/${evaluationNoteId}/delete`, null);
  }

  getAll(evaluationId: string) {
    return this.http.get<EvaluationNote[]>(`${this.baseUrl}/${evaluationId}/notes`);
  }
}
