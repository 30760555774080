import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EvaluationService } from 'src/app/evaluation/services/evaluation.service';
import { EvaluationDomain } from 'src/app/shared/models/evaluation-domain';
import { FiieConsentReadForm } from 'src/app/shared/models/fiie-consent/fiie-consent-read';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { ConsentFormService } from 'src/app/shared/services/consent-form/consent-form.service';
import { EvaluationDomainService } from 'src/app/shared/services/evaluation-domains/evaluation-domain.service';
import { HelpArea, HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { EvaluationAdditionalFactorsHelp } from 'src/app/shared/services/help/models/evaluation.help';
import { MemoryStorageService } from 'src/app/shared/services/memory-storage/memory-storage.service';
import { Evaluation } from '../../../models/evaluation';
import { EvaluationProxyService } from '../../../services/evaluation-proxy.service';

@Component({
  selector: 'app-evaluation-domains',
  templateUrl: './evaluation-domains.component.html',
  styleUrls: ['./evaluation-domains.component.scss'],
})
export class EvaluationDomainsComponent implements OnInit {
  @Input() evaluation: Evaluation;

  consideringSld = new FormControl('');
  fiieConsentForm: FiieConsentReadForm;
  academicDomain: EvaluationDomain;
  helpSection = HelpSection;
  evaluationAfHelp = EvaluationAdditionalFactorsHelp;

  constructor(
    private readonly evaluationProxyService: EvaluationProxyService,
    private readonly evaluationService: EvaluationService,
    private readonly evaluationDomainService: EvaluationDomainService,
    private readonly consentFormService: ConsentFormService,
    private readonly caseService: CaseService,
    private readonly storage: MemoryStorageService,
    private readonly helpService: HelpService
  ) {}

  showSldCheckbox() {
    if (this.fiieConsentForm && this.evaluation && this.academicDomain) {
      return (
        !this.evaluation.isFinalized &&
        (this.fiieConsentForm.consentDomains.some((x) => x === this.academicDomain.id) ||
          this.evaluation.assignedDomainIds.some((x) => x === this.academicDomain.id))
      );
    }
  }

  ngOnInit(): void {
    this.getAcademicDomain();
    this.getFiieConsentForm();

    this.consideringSld.setValue(this.evaluation.consideringSld);

    this.evaluationProxyService.assignmentChange.subscribe(([field, assignments]) => {
      this.evaluation.assignedDomainIds = [...new Set(assignments.map((x) => x.domainArea.evaluationDomain.id))];
      this.evaluation.assignedDomainNames = [...new Set(assignments.map((x) => x.domainArea.label))].sort((a, b) => a.localeCompare(b));
    });

    this.consideringSld.valueChanges.subscribe((val) => {
      this.evaluationService.updateSld(this.evaluation.id, val).subscribe((evaluation) => {
        this.storage.setKey('currentEvaluation', evaluation);
        this.evaluationProxyService.evaluationChange.next(evaluation);
      });
    });
  }

  getFiieConsentForm() {
    this.caseService.getCaseSummary(this.evaluation.caseId).subscribe((returnedCase) => {
      this.consentFormService.getFiieConsentForm(returnedCase.fiieConsentFormId, this.evaluation.caseId).subscribe((fiieConsentform) => {
        this.fiieConsentForm = fiieConsentform;
      });
    });
  }

  getAcademicDomain() {
    this.evaluationDomainService.get().subscribe((domains) => {
      this.academicDomain = domains.find((x) => x.label === 'Academic');
    });
  }

  onOpenHelp(e: Event) {
    e.preventDefault();
    this.helpService.openHelpModal({
      nestedTerm: 'domains',
      area: HelpArea.DomainsToBeEvaluated,
      canBrowse: false,
      width: '728px',
    } as HelpModalConfig);
  }

  onOpenHelpText(e: Event, section: HelpSection, item: HelpTerm) {
    e.preventDefault();
    const dictionary = this.helpService.getEvaluationAfDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      item,
      section,
      canBrowse: true,
    } as HelpModalConfig);
  }
}
