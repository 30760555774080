<form [formGroup]="formGroup" #quantDataForm="ngForm">
  <div class="card__together-container">
    <mat-card class="mt-2 mb-0 card--plain card__together card__together--top">
      <!-- Trials -->
      <div class="display-flex align-items-center justify-content-between">
        <h3 class="my-0">Outcome</h3>
        <div class="row">
          <div class="col-md-12 display-flex justify-content-end flex-wrap align-items-center">
            <button
              mat-raised-button
              attr.aria-label="{{ this.formGroup.get('trials').value?.length > 0 ? 'Close ' : 'Calculate ' }} Baseline"
              type="button"
              color="primary"
              [ngClass]="{
                'mr-2': formGroup.controls?.trials?.value?.length > 0
              }"
              (click)="calculateBaseLine()"
              tabindex="0"
            >
              {{ this.formGroup.get('trials').value?.length > 0 ? 'Close ' : 'Calculate ' }}
              Baseline
            </button>
            <div *ngIf="formGroup.controls?.trials?.value?.length > 0" class="display-flex align-items-center flex-wrap">
              <button
                [disabled]="dataPointLimitExceeded"
                mat-flat-button
                aria-label="Add Data Point"
                color="primary"
                type="button"
                (click)="addTrial()"
                tabindex="0"
              >
                Add Data Point
              </button>

              <p class="my-0 ml-2" *ngIf="dataPointLimitExceeded">Data Point Limit Exceeded</p>
            </div>
          </div>
        </div>
      </div>

      <div *ngFor="let trial of trials; let idx = index" [formGroup]="trial" class="row mt-0 align-items-center">
        <div class="col-sm-2">
          <label for="">Data Point {{ idx + 1 }}</label>
        </div>
        <div class="col-sm-2">
          <app-number-field label="Score" id="score" formControlName="score"></app-number-field>
        </div>
        <div class="col-sm-2">
          <app-date-picker label="Date" id="date" controlName="date" [max]="today"></app-date-picker>
        </div>
        <div class="col-sm-1">
          <button mat-icon-button aria-label="Delete Icon" color="warn" type="button" (click)="removeTrial(idx)" tabindex="0">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div *ngIf="idx === trials.length - 1" class="col-sm-2 display-flex justify-content-end"></div>
      </div>

      <!-- Baseline -->
      <div class="row align-items-center my-2">
        <div class="col">
          <h4 class="text-primary mb-2" *ngIf="typeOfOutcome === 'Family'">Family's outcomes is...</h4>
          <h4 class="text-primary mb-2" *ngIf="typeOfOutcome === 'Child'">Criterion Measurement</h4>
          <div class="align-items-center display-flex flex-wrap">
            <label class="mr-2" for="baseline"
              >{{ firstNamePossessive }}
              <a
                class="text-underline"
                (click)="onOpenHelp(helpSection.IepGoal, iepGoalHelp.Baseline)"
                tabindex="0"
                role="link"
                attr.aria-label="{{ iepGoalHelp.Baseline }}"
                >{{ iepGoalHelp.Baseline }}</a
              ></label
            >
            <app-number-field
              *ngIf="isManualBaseline"
              label="Baseline"
              id="baseline"
              formControlName="baseline"
              [min]="0"
            ></app-number-field>
            <span *ngIf="!isManualBaseline && !modificationId">{{ formGroup.get('baseline').value | number : '1.0-1' }}</span>
            <app-compare-amendment-output
              *ngIf="model && modificationId"
              [amendmentId]="modificationId"
              [priorVersionId]="model?.priorVersionId"
              [isActive]="model?.isActive"
              [changedVersionText]="formGroup.get('baseline').value"
              [currentVersionText]="model?.priorVersion?.baseline"
              [currentPrefixText]="model?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>
          </div>
        </div>
        <div class="col">
          <h4 class="text-primary mb-2" *ngIf="typeOfOutcome === 'Family'">Family target value</h4>
          <h4 class="text-primary mb-2" *ngIf="typeOfOutcome === 'Child'">{{ firstNamePossessive }} target value</h4>

          <ng-container *ngIf="!isNarrative">
            <app-number-field class="input--small" formControlName="performanceMin" [min]="0" id="performanceMin"></app-number-field>
            <app-compare-amendment-output
              *ngIf="model && modificationId"
              [amendmentId]="modificationId"
              [priorVersionId]="model?.priorVersionId"
              [isActive]="model?.isActive"
              [changedVersionText]="formGroup.get('performanceMin').value"
              [currentVersionText]="model?.priorVersion?.performanceMin"
              [currentPrefixText]="model?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>

            <!-- TODO: Remove from back-end -->

            <!-- <span class="mx-3">to</span>
            <app-number-field
              class="input--small"
              label="Max"
              formControlName="performanceMax"
            ></app-number-field> -->

            <!--<app-compare-amendment-output
              *ngIf="model && modificationId"
              [amendmentId]="modificationId"
              [priorVersionId]="model?.priorVersionId"
              [isActive]="model?.isActive"
              [changedVersionText]="formGroup.get('performanceMax').value"
              [currentVersionText]="model?.priorVersion?.performanceMax"
              [currentPrefixText]="model?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            ></app-compare-amendment-output>-->
          </ng-container>

          <ng-container *ngIf="isNarrative">
            <div class="row">
              <div class="col-md-12">
                <app-textarea
                  label="Description"
                  [isBankableField]="true"
                  [bankableFieldId]="bankableFieldIds.outcomes.quantData.performanceNarrative"
                  formControlName="performanceNarrative"
                ></app-textarea>
                <app-compare-amendment-output
                  *ngIf="model && modificationId"
                  [amendmentId]="modificationId"
                  [priorVersionId]="model?.priorVersionId"
                  [isActive]="model?.isActive"
                  [changedVersionText]="formGroup.get('performanceNarrative').value"
                  [currentVersionText]="model?.priorVersion?.performanceNarrative"
                  [currentPrefixText]="model?.priorVersion ? 'Current: ' : ''"
                  changedPrefixText="Proposed: "
                ></app-compare-amendment-output>
              </div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="showMilestoneBtn" class="col display-flex justify-content-end flex-wrap">
          <button
            mat-flat-button
            aria-label="Add Milestone Button"
            class="my-1"
            color="primary"
            type="button"
            *ngIf="!showMilestoneForm"
            (click)="showMilestones(false, false)"
            tabindex="0"
          >
            Add Milestone
          </button>

          <button
            mat-flat-button
            aria-label="Add Scale Button"
            class="ml-2 my-1"
            color="primary"
            type="button"
            *ngIf="!showScaleForm"
            (click)="showScales(false, false)"
            tabindex="0"
          >
            Add Scale
          </button>
        </div>
      </div>

      <div class="row">
        <ng-container *ngIf="showMilestone">
          <div class="col-xl-7 col-md-12">
            <hr class="input__divider mt-2 mb-1" *ngIf="showMilestoneForm" />
            <app-milestones
              [milestones]="milestones"
              [modificationId]="modificationId"
              [secondary]="false"
              [showForm]="showMilestoneForm"
              (updated)="milestones = $event"
              (canceled)="onMilestoneCanceled($event, false)"
              (edit)="showMilestones(false, true)"
            ></app-milestones>
          </div>
        </ng-container>

        <ng-container *ngIf="showScale || model">
          <div class="col-xl-5 col-md-12">
            <hr class="input__divider mt-2 mb-1" *ngIf="showScaleForm" />

            <app-scale-levels
              class="mt-2"
              [scaleRatings]="scaleRatings"
              [modificationId]="modificationId"
              [secondary]="false"
              [showForm]="showScaleForm"
              (updated)="scaleRatings = $event"
              (canceled)="onScaleCanceled($event, false)"
              (edit)="showScales(false, true)"
            ></app-scale-levels>
          </div>
        </ng-container>
      </div>

      <!--<div *ngIf="!isIfsp" class="position-fix-btn">
        <div class="display-flex justify-content-end align-items-center">
          <button
            mat-flat-button
            attr.aria-label="{{ isAdvanced ? 'Remove Advanced Options' : 'Advanced Options' }}"
            color="primary"
            type="button"
            (click)="toggleAdvanced()"
            tabindex="0"
          >
            {{ isAdvanced ? 'Remove Advanced Options' : 'Advanced Options' }}
          </button>
        </div>
      </div>-->
    </mat-card>

    <mat-card class="card--plain" class="card__together card__together--bottom" *ngIf="isAdvanced">
      <ng-container *ngIf="isAdvanced">
        <!-- Advanced Options -->
        <div
          class="row align-items-center justify-content-between my-2"
          [ngClass]="{
            'mb-2': formGroup.controls.secondMeasurementDescription.invalid && formGroup.controls.secondMeasurementDescription.touched
          }"
        >
          <div class="col-lg-6">
            <app-text-field
              label="Second Measurement"
              [isBankableField]="true"
              [bankableFieldId]="bankableFieldIds.outcomes.quantData.secondMeasurementDescription"
              formControlName="secondMeasurementDescription"
            ></app-text-field>
          </div>
          <div *ngIf="showMilestoneBtn" class="col-xl-5 col-md-12 display-flex justify-content-end flex-wrap">
            <button
              mat-flat-button
              attr.aria-label="{{ showSecondaryMilestone ? 'Cancel' : 'Add' }} Milestones"
              class="my-1"
              color="primary"
              type="button"
              (click)="showMilestones(true, false)"
              tabindex="0"
            >
              {{ showSecondaryMilestone ? 'Cancel' : 'Add' }} Milestones
            </button>

            <button
              mat-flat-button
              attr.aria-label="{{ showSecondaryScaleForm ? 'Cancel' : 'Add' }} Scale"
              class="ml-2 my-1"
              color="primary"
              type="button"
              (click)="showScales(true, false)"
              tabindex="0"
            >
              {{ showSecondaryScaleForm ? 'Cancel' : 'Add' }} Scale
            </button>
          </div>
        </div>

        <div class="row">
          <ng-container *ngIf="showSecondaryMilestone">
            <div class="col-xl-7 col-md-12">
              <hr class="input__divider mt-2 mb-1" *ngIf="showSecondaryMilestoneForm" />

              <app-milestones
                [milestones]="milestones"
                [showForm]="showSecondaryMilestoneForm"
                [secondary]="true"
                (updated)="milestones = $event"
                (canceled)="onMilestoneCanceled($event, true)"
                (edit)="showMilestones(true, true)"
              ></app-milestones>
            </div>
          </ng-container>

          <ng-container *ngIf="showSecondaryScale">
            <div class="col-xl-5 col-md-12">
              <hr class="input__divider mt-2 mb-1" *ngIf="showSecondaryScaleForm" />
              <app-scale-levels
                class="mt-2"
                [secondary]="true"
                [scaleRatings]="scaleRatings"
                [showForm]="showSecondaryScaleForm"
                (updated)="scaleRatings = $event"
                (canceled)="onScaleCanceled($event, true)"
                (edit)="showScales(true, true)"
              ></app-scale-levels>
            </div>
          </ng-container>
        </div>

        <hr class="input__divider mb-3" *ngIf="showSecondaryScaleForm || showSecondaryMilestone" />

        <div class="row">
          <div class="col-xl-6">
            <h4 class="text-primary mb-2">Expected Performance</h4>
            <ng-container *ngIf="!secondIsExpectedNarrative">
              <app-number-field
                class="input--small"
                label="Min"
                formControlName="secondExpectedPerformanceMin"
                id="secondExpectedPerformanceMin"
              ></app-number-field>

              <span class="mx-3" *ngIf="!secondIsExpectedNarrative">to</span>

              <app-number-field
                class="input--small"
                label="Max"
                formControlName="secondExpectedPerformanceMax"
                id="secondExpectedPerformanceMax"
              ></app-number-field>
            </ng-container>

            <ng-container *ngIf="secondIsExpectedNarrative">
              <div class="row">
                <div class="col-md-6">
                  <app-textarea
                    label="Description"
                    [isBankableField]="true"
                    [bankableFieldId]="bankableFieldIds.outcomes.quantData.secondExpectedPerformanceNarrative"
                    formControlName="secondExpectedPerformanceNarrative"
                  ></app-textarea>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-xl-6">
            <!-- Advanced (Second) Actual Performance -->
            <h4 class="text-primary mb-2" *ngIf="typeOfOutcome === 'Family'">Family's outcome is...</h4>
            <h4 class="text-primary mb-2" *ngIf="typeOfOutcome === 'Child'">{{ firstNamePossessive }} Performance</h4>

            <ng-container *ngIf="!secondIsNarrative">
              <app-number-field
                class="input--small"
                label="Min"
                formControlName="secondPerformanceMin"
                id="secondPerformanceMin"
              ></app-number-field>

              <span class="mx-3">to</span>

              <app-number-field
                class="input--small"
                label="Max"
                formControlName="secondPerformanceMax"
                id="secondPerformanceMax"
              ></app-number-field>
            </ng-container>

            <ng-container *ngIf="secondIsNarrative">
              <div class="row">
                <div class="col-md-6">
                  <app-textarea
                    label="Description"
                    [isBankableField]="true"
                    [bankableFieldId]="bankableFieldIds.outcomes.quantData.secondPerformanceNarrative"
                    formControlName="secondPerformanceNarrative"
                  ></app-textarea>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Advanced (Second) Expected Performance -->

        <hr class="input__divider my-2" />
      </ng-container>

      <!-- Standard of Comparison -->
      <!--<div class="row align-items-start">
        <div class="col-lg-6">
          <app-autocomplete
            [options]="standardsOfComparisonOptions"
            label="Standard of Comparison"
            formControlName="standardOfComparisonId"
          ></app-autocomplete>
        </div>
      </div>-->
    </mat-card>
  </div>
</form>
