<div class="display-flex align-items-center flex-wrap">
  <div class="tag" (click)="onSelect(iepTagBarOptions.IepGoals)">
    <button mat-icon-button aria-label="Goal Tagging Icon" class="text-lg mr-1" tabindex="0" type="button">
      <i class="fas fa-bullseye tag__icon" [ngClass]="{ 'text-success': isSelected(iepTagBarOptions.IepGoals) }"></i>
    </button>
    <span class="tag__label" *ngIf="showLabels">{{ isPartC ? 'IFSP - Goals' : 'IEP - Goals' }}</span>
  </div>
  <div class="tag" (click)="onSelect(iepTagBarOptions.IepServices)">
    <button
      mat-icon-button
      attr.aria-label="{{ isPartC ? 'IFSP - Services' : 'IEP - Services' }}"
      class="text-lg mr-1"
      tabindex="0"
      type="button"
    >
      <i
        class="fas fa-chalkboard-teacher tag__icon"
        [ngClass]="{ 'text-success': isSelected(iepTagBarOptions.IepServices) }"
        aria-label="Service Tagging Icon"
      ></i>
    </button>
    <span class="tag__label" *ngIf="showLabels">{{ isPartC ? 'IFSP - Services' : 'IEP - Services' }}</span>
  </div>
</div>
