import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FamilyConsentComponent } from './family-consent/family-consent.component';
import { UpdateConsentFormComponent } from './family-consent/update-consent-form/update-consent-form.component';
import { UpdateConsentStatusNotesComponent } from './family-consent/update-consent-status-notes/update-consent-status-notes.component';
import { FamilyConsentAddComponent } from './family-consent/modals/family-consent-add/family-consent-add.component';
import { AppMatModule } from '../shared/app-mat.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FamilyConsentComponent, UpdateConsentFormComponent, UpdateConsentStatusNotesComponent, FamilyConsentAddComponent],
  imports: [AppMatModule, CommonModule, FormsModule, ReactiveFormsModule, RouterModule, SharedModule],
  exports: [FamilyConsentComponent],
})
export class ConsentModule {}
