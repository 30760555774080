<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Add Participants</h3>

<div mat-dialog-content class="pt-3 pb-5">
  <mat-card>
    <form [formGroup]="formGroup">
      <div class="row">
        <div class="col-10"><app-text-field formControlName="searchTerm" label="Search"></app-text-field></div>
        <div class="col-2"><button mat-raised-button color="primary" (click)="onSearch()" [isBusy]="activeSearch">Search</button></div>
      </div>
      <!-- <app-text-field formControlName="searchTerm" label="Search" (keyup)="onFilter()"></app-text-field> -->
      <!-- <mat-checkbox formControlName="includeResidentInResults" (click)="onFilter()" aria-labelledby="Include Resident AEA/District">
        Include Resident AEA/District
      </mat-checkbox> -->
    </form>
  </mat-card>

  <mat-card class="mt-3 p-0 member__card" *ngIf="availableParticipantContainer?.length > 0 && areAnyParticipants()">
    <ng-container *ngFor="let participantContainer of availableParticipantContainer">
      <ng-container *ngIf="participantContainer.participants && participantContainer.participants.length > 0">
        <ul class="member__list">
          <ng-container *ngFor="let participant of participantContainer.participants">
            <li class="member__item">
              <div class="display-flex justify-content-between align-items-center">
                <span>
                  {{
                    participantContainer.type === participantType.FamilyProvider ? displayProviderName(participant) : participant?.fullName
                  }}
                  <span class="text-sm text-muted">({{ participant.type }})</span>
                </span>
                <button
                  mat-flat-button
                  aria-label="Add Button"
                  color="accent"
                  (click)="addParticipant(participant, participantContainer.type)"
                  tabindex="0"
                >
                  Add
                </button>
              </div>
            </li>
          </ng-container>
        </ul>
      </ng-container>
    </ng-container>
  </mat-card>

  <mat-card class="card-spacing-top p-0 member__card" *ngIf="selectedParticipants && selectedParticipants.length > 0">
    <ul class="member__list">
      <ng-container *ngFor="let participant of selectedParticipants">
        <li class="member__item">
          <div class="display-flex justify-content-between align-items-center">
            <span>
              {{ participant.type === participantType.FamilyProvider ? displayProviderName(participant) : participant?.fullName }}
              <span class="text-sm text-muted">({{ participant.type }})</span>
            </span>
            <button mat-flat-button color="accent" (click)="removeParticipant(participant)" tabindex="0">Remove</button>
          </div>
        </li>
      </ng-container>
    </ul>
  </mat-card>
</div>

<div mat-dialog-actions class="actions">
  <button mat-button aria-label="Cancel Button" class="background-color--muted" (click)="onNoClick()" tabindex="0">Cancel</button>
  <button mat-flat-button aria-label="Add to List" color="primary" *ngIf="selectedParticipants" (click)="onAccept()" tabindex="0">
    Add to List
  </button>
</div>
