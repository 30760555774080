import { Directive, Host, Input, OnInit, OnDestroy, Optional, SkipSelf, Self } from '@angular/core';
import { Subscription } from 'rxjs';
import { ControlContainer, FormControl, FormGroup, NgControl, ValidatorFn, Validators } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[conditionallyRequired]',
})
export class ConditionallyRequiredDirective implements OnInit, OnDestroy {
  private valueChangeSubscription?: Subscription;
  private originalValidator: ValidatorFn | null;

  @Input('conditionallyRequired') public validatorControlName: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('withValue') public validatorValue = true;

  // TODO: Remove this when BaseFieldComponent is removed
  /**
   * @deprecated The input should not be used (only for BaseFieldComponent support)
   */
  @Input() controlName: string;

  constructor(@Optional() @Host() @SkipSelf() private parent: ControlContainer, @Optional() @Host() @Self() private ngControl: NgControl) {}

  ngOnInit(): void {
    if (!this.parent || (!this.ngControl && !this.controlName) || !this.getHostControl()) {
      return;
    }

    this.originalValidator = this.getHostControl().validator;
    this.setRequired();
    this.valueChangeSubscription = this.parent.control?.valueChanges.subscribe((x) => this.setRequired());
  }

  ngOnDestroy(): void {
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
  }

  private setRequired(): void {
    const group = this.parent.control as FormGroup;
    const control = this.getHostControl();
    if (!group || !control) {
      return;
    }

    const validationControl = group.controls[this.validatorControlName] as FormControl;
    if (!validationControl) {
      return;
    }

    if (validationControl.value === this.validatorValue) {
      const validators = [Validators.required];
      if (this.originalValidator) {
        validators.push(this.originalValidator);
      }
      control.setValidators(validators);
    } else {
      control.setValidators(null);
    }
    control.updateValueAndValidity({ emitEvent: false });
  }

  private getHostControl(): FormControl {
    if (this.ngControl) {
      return this.ngControl.control as FormControl;
    } else {
      const formGroup = this.parent.control as FormGroup;
      return formGroup?.controls[this.controlName] as FormControl;
    }
  }
}
