import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-iep-activity-supports-view-more',
  templateUrl: './iep-activity-supports-view-more.component.html',
  styleUrls: ['./iep-activity-supports-view-more.component.scss'],
})
export class IepActivitySupportsViewMoreComponent implements OnInit {
  activitySupport: any;

  constructor(private dialogRef: MatDialogRef<IepActivitySupportsViewMoreComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.activitySupport = this.data.activitySupport;
  }

  getProviders(providers) {
    return providers?.map((x) => `${x.firstName} ${x.lastName || ''}`).join(', ');
  }

  onClose() {
    this.dialogRef.close();
  }
}
