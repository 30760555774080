import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LearnerSurveyReport } from '../components/survey-reporting-dashboard/survey-reporting-dashboard.component';

@Injectable({
  providedIn: 'root',
})
export class SurveyReportingService {
  constructor(private http: HttpClient) {}

  getEAStats(filters) {
    if (!!filters) {
      let params = new HttpParams();
      filters.aeas?.forEach((x) => (params = params.append('aeaIds', x)));
      filters.leas?.forEach((x) => (params = params.append('leaIds', x)));
      filters.buildings?.forEach((x) => (params = params.append('buildingIds', x)));
      params = params.append('schoolYearId', filters.schoolYearId);
      return this.http.get<LearnerSurveyReport>('api/survey-reports/ea-reporting', { params });
    }
    return this.http.get<LearnerSurveyReport>('api/survey-reports/ea-reporting');
  }

  getSEStats(filters) {
    if (!!filters) {
      let params = new HttpParams();
      filters.aeas?.forEach((x) => (params = params.append('aeaIds', x)));
      filters.leas?.forEach((x) => (params = params.append('leaIds', x)));
      filters.buildings?.forEach((x) => (params = params.append('buildingIds', x)));
      params = params.append('schoolYearId', filters.schoolYearId);
      return this.http.get<LearnerSurveyReport>('api/survey-reports/se-reporting', { params });
    }
    return this.http.get<LearnerSurveyReport>('api/survey-reports/se-reporting');
  }

  sendEASurveyReminder(chartType, chartValue) {
    let params = new HttpParams();
    // filters.aeas?.forEach((x) => (params = params.append('aeaIds', x)));
    // filters.leas?.forEach((x) => (params = params.append('leaIds', x)));
    // filters.buildings?.forEach((x) => (params = params.append('buildingIds', x)));
    params = params.append('chartType', chartType);
    params = params.append('chartValue', chartValue);
    return this.http.post<LearnerSurveyReport>(
      'api/survey-reports/sendEASurveyReminder?chartType=' + chartType + '&chartValue=' + chartValue,
      null
    );
  }
  sendSESurveyTest(emailSettings): Observable<any> {
    return this.http.post('api/survey-reports/set-se-send-job', emailSettings);
  }
  sendSESurveyReminder(chartType, chartValue) {
    let params = new HttpParams();
    params = params.append('chartType', chartType);
    params = params.append('chartValue', chartValue);
    return this.http.post<LearnerSurveyReport>(
      'api/survey-reports/sendSESurveyReminder?chartType=' + chartType + '&chartValue=' + chartValue,
      null
    );
  }

  getEASummaryReport(filters): Observable<Blob> {
    return this.http.post('api/survey-reports/ea-summary-report', filters, {
      responseType: 'blob',
    });
  }

  getEADetailReport(filters): Observable<Blob> {
    return this.http.post('api/survey-reports/ea-survey-response-report', filters, {
      responseType: 'blob',
    });
  }

  getEARawReport(filters): Observable<Blob> {
    return this.http.post('api/survey-reports/ea-raw-data-report', filters, {
      responseType: 'blob',
    });
  }

  getSEDetailReport(filters): Observable<Blob> {
    return this.http.post('api/survey-reports/se-survey-response-report', filters, {
      responseType: 'blob',
    });
  }

  getSESummaryReport(filters): Observable<Blob> {
    return this.http.post('api/survey-reports/se-summary-report', filters, {
      responseType: 'blob',
    });
  }

  getSERawReport(filters): Observable<Blob> {
    return this.http.post('api/survey-reports/se-raw-data-report', filters, {
      responseType: 'blob',
    });
  }
}
