import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaseSummary } from '../../../shared/models/case';
import { LearnerService } from '../../../shared/services/learner/learner.service';
import { NotificationService } from '../../../shared/services/notification.service';

@Component({
  selector: 'app-manage-learner-state',
  templateUrl: './manage-learner-state.component.html',
})
export class ManageLearnerStateComponent implements OnInit {
  caseSummary: CaseSummary;
  modalTitle: string;
  formGroup = new FormGroup({
    stateId: new FormControl(null, [Validators.required]),
  });
  activeCall = false;

  constructor(
    public dialogRef: MatDialogRef<ManageLearnerStateComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly notificationService: NotificationService,
    private readonly learnerService: LearnerService
  ) {
    this.caseSummary = data.caseSummary;
    this.modalTitle = data.title;
  }

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (!this.formGroup.valid) {
      this.notificationService.error('Please complete all required fields');
      return;
    }

    this.learnerService.changeStateId(this.caseSummary?.learnerId, this.formGroup.controls.stateId.value).subscribe(
      async (response) => {
        if (response.succeeded) {
          this.learnerService.learnerSummary = await this.learnerService.getLearnerSummary(this.caseSummary.learnerId).toPromise();
          this.dialogRef.close();

          this.notificationService.success('Successfully updated.');
        } else {
          this.notificationService.error(response.errors?.map((e) => e.description).join());
        }
      },
      (error) => {
        this.notificationService.error(error?.message);
      }
    );
  }
}
