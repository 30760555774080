<div class="table-overflow">
  <table mat-table class="w-100" matSort [dataSource]="criterion.data">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more"></th>
      <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
        <ng-container *ngIf="!isEditing && !outcomeEnded">
          <button mat-icon-button aria-label="Edit Icon" color="primary" (click)="onEdit(element)" tabindex="0">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button aria-label="Delete Icon" color="warn" (click)="onDelete(element, criterion)" tabindex="0">
            <mat-icon>close</mat-icon>
          </button>
        </ng-container>
        <button mat-icon-button aria-label="View Icon" color="primary" (click)="onViewMore(element)" tabindex="0">
          <mat-icon>remove_red_eye</mat-icon>
        </button>
        <ng-container *ngIf="element.isEditing">
          <button color="primary" mat-icon-button aria-label="Save Icon" (click)="onUpdate(element, criterion)" title="Save" tabindex="0">
            <mat-icon>save</mat-icon>
          </button>
          <button color="warn" mat-icon-button aria-label="Clear Icon" (click)="onCancelEdit(element)" title="Cancel" tabindex="0">
            <mat-icon>clear</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let element">
        <app-inline-edit [editing]="element.isEditing">
          <ng-container viewMode>
            {{ element.date | dateFormat }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-date-picker [min]="minDate" [max]="today" label="Date" controlName="date"></app-date-picker>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="value" *ngIf="criterion.measurementType === 'numeric'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>
      <td mat-cell *matCellDef="let element">
        <app-inline-edit [editing]="element.isEditing">
          <ng-container viewMode>
            {{ element.value | number }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-number-field label="Value" formControlName="value"></app-number-field>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="completed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">
        <app-inline-edit [editing]="element.isEditing">
          <ng-container viewMode>
            {{ element.completed ? 'Completed' : '' }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <mat-checkbox
              formControlName="completed"
              [ngClass]="{
                asterisk_input: formGroup.get('completed').hasError('required')
              }"
              aria-labelledby="Completed"
              >Completed</mat-checkbox
            >
            <mat-error *ngIf="formGroup.get('completed').hasError('required') && formGroup.get('completed').touched"
              >Field is <strong>required</strong></mat-error
            >
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Monitoring Notes</th>
      <td mat-cell *matCellDef="let element">
        <app-inline-edit [editing]="element.isEditing">
          <ng-container viewMode>
            {{ element.note | truncate : 150 }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-textarea label="Monitoring Notes" formControlName="note"></app-textarea>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="criterion.measurementType === 'numeric' ? numericDisplayedColumns : nonNumericDisplayedColumns"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: criterion.measurementType === 'numeric' ? numericDisplayedColumns : nonNumericDisplayedColumns"
    ></tr>
    <tr
      [hidden]="criterion.data?.data?.length > 0"
      mat-footer-row
      *matFooterRowDef="criterion.data?.data?.length === 0 ? ['noResults'] : []"
    ></tr>
  </table>
  <mat-paginator [dataSource]="criterion.data"></mat-paginator>
</div>
