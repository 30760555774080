import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
})
export class HealthCheckComponent implements OnInit {
  constructor(private utitlityService: UtilityService) {}

  _currentTime: SimpleDateTime = {
    myDateTime: '',
  };

  get currentTime() {
    return this._currentTime;
  }

  ngOnInit(): void {
    this.utitlityService.getPingDb().subscribe((x) => {
      this._currentTime = x;
    });
  }
}

export interface SimpleDateTime {
  myDateTime: string;
}
