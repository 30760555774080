<div class="justify-content-between align-items-center mb-2" *ngIf="ifspPeriodicAnnualReviews.length > 0">
  <h3 class="my-0">IFSP Reviews</h3>
  <div>
    <mat-accordion
      class="accordion accordion--close accordion--shadow accordion--short"
      multi
      *ngFor="let review of ifspPeriodicAnnualReviews"
    >
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="display-flex align-items-center justify-content-between flex-1">
              <h3 class="my-0 text-normal">{{ review.ifspReviewType }} Review {{ review.createdOn | dateFormat }}</h3>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="display-flex align-items-baseline">
          <section class="card-spacing-bottom">
            <app-annual-review-questionnaire
              [learnerName]="caseSummary?.learner?.fullName"
              [ifspId]="review.ifspId"
              [ifspReviewId]="review.id"
              [evaluationId]="caseSummary?.evaluationId"
              [caseId]="caseId"
              [surveysByQuestion]="review.surveysByQuestion"
              [outcomeReviews]="outcomeReviews"
              [learnerId]="caseSummary?.learnerId"
              (annualReviewCompleted)="activeIFSP.annualReviewActive = false"
              [surveys]="review.generalSurveyUserFullNameDtos"
              [isPeriodicReview]="review.ifspReviewType === 'Periodic'"
              [trackFormChanges]="annualReviewQuestionnaire.formGroup"
              [totalSurveysSent]="review.totalSurveysSent"
              (reviewCancelled)="getReviews()"
              #annualReviewQuestionnaire
            >
            </app-annual-review-questionnaire>
          </section>
        </div>
        <div class="divider mt-3"></div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<div class="display-flex justify-content-between align-items-center mb-2">
  <h3 class="my-0">IFSP Management</h3>

  <div *ngIf="isCaseWorkable">
    <ng-container *appAuthForRoles="{ permission: [permissions.CreateIFSP], caseId: caseId }">
      <button
        mat-raised-button
        aria-label="Start New IFSP"
        color="primary"
        class="mr-2"
        [disabled]="!enableStartNewIFSPBtn"
        (click)="createIfsp()"
        tabindex="0"
      >
        Start New IFSP
      </button>

      <button
        *ngIf="enableInterimIFSPBtn"
        mat-raised-button
        aria-label="Start New Interim IFSP"
        color="primary"
        (click)="openInterimPlanModal()"
        tabindex="0"
      >
        Start New Interim IFSP
      </button>
    </ng-container>
  </div>
</div>

<mat-card class="card-spacing-bottom" [ngClass]="{ 'pt-1': ifsps.length }">
  <p *ngIf="!ifsps.length" class="my-0"><i>No IFSPs...</i></p>
  <ng-container *ngIf="ifsps.length">
    <div class="table-overflow app-table">
      <table matSort [dataSource]="dataSource" mat-table class="w-100">
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let ifsp">
            <button
              mat-icon-button
              aria-label="More option"
              color="primary"
              [matMenuTriggerFor]="actionMenu"
              *ngIf="showActions(ifsp)"
              tabindex="0"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu">
              <button *ngIf="canResetDates(ifsp)" mat-menu-item aria-label="Reset Dates" (click)="onResetDates(ifsp)" tabindex="0">
                Reset Dates
              </button>
              <button
                *ngIf="achieveSettings.printAssociatedDocuments && ifsp.documents?.length > 0"
                mat-menu-item
                aria-label="Print Documents"
                (click)="onPrintDocuments(ifsp)"
                tabindex="0"
              >
                Print Documents
              </button>
              <button *ngIf="canDeleteIfsp(ifsp)" mat-menu-item aria-label="Delete" (click)="onDeleteIfsp(ifsp)" tabindex="0">
                Delete IFSP
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="createdOn">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let ifsp">
            {{ ifsp.createdOn | dateFormat }}<br />
            <span *ngIf="ifsp.ifspStatus === ifspStatuses.Active && ifsp.isModifying" [class.modifying]="ifsp.isModifying">
              Active - Modifying
            </span>
            <span *ngIf="ifsp.ifspStatus === ifspStatuses.Active && !ifsp.isModifying">
              <ng-container
                *ngIf="
                  (ifsp.ifspType !== 'Annual' && (!ifsp.modifications || ifsp.modifications.length === 0)) ||
                  (ifsp.ifspType === 'Annual' && ifsp.modifications.length === 1)
                "
              >
                Active
              </ng-container>

              <ng-container
                *ngIf="
                  !!ifsp.modifications &&
                  ((ifsp.modifications.length > 0 && ifsp.ifspType !== 'Annual') ||
                    (ifsp.ifspType === 'Annual' && ifsp.modifications.length > 1))
                "
              >
                Active - Modified on {{ getModificationDates(ifsp) }}
              </ng-container>
            </span>
            <span *ngIf="ifsp.ifspStatus === ifspStatuses.Complete"> Inactive </span>
            <span *ngIf="ifsp.ifspStatus === ifspStatuses.Legacy"> Legacy </span>
            <span *ngIf="ifsp.ifspStatus === ifspStatuses.Draft"> New (Draft) </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="ifspType">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let ifsp">
            {{ ifsp.ifspType }}
          </td>
        </ng-container>

        <ng-container matColumnDef="ifspStart">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>Start Date</th>
          <td mat-cell *matCellDef="let ifsp">
            {{ ifsp.startDate | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="ifspEnd">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>End Date</th>
          <td mat-cell *matCellDef="let ifsp">
            {{ ifsp.endDate | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="familyInfo">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="text-right">
            <button
              mat-raised-button
              aria-label="IFSP Document"
              color="primary"
              *ngIf="element.ifspStatus === 'Active'"
              (click)="onFamilyIfspView(element)"
              tabindex="0"
            >
              IFSP Document
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="info">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let ifsp" class="text-right">
            <button
              mat-raised-button
              aria-label="Modify"
              color="primary"
              class="my-1"
              *ngIf="!ifsp.isModifying && ifsp.ifspStatus === ifspStatuses.Active && canEdit && isCaseWorkable"
              (click)="modifyIfsp(ifsp, false)"
              tabindex="0"
            >
              Modify
            </button>

            <div
              [removeIfFeatureOff]="'outputIfspEnabled'"
              class="ml-2 my-1"
              *ngIf="ifsp.ifspStatus === ifspStatuses.Active || (ifsp.ifspStatus === ifspStatuses.Complete && canView)"
            >
              <app-create-output-button
                [output]="pdfOutputs.IfspOutput"
                [apiId]="ifsp.id"
                [learnerId]="learnerId"
                [documentId]="getLastIfspDocumentId(ifsp)"
                buttonText="IFSP Document"
              ></app-create-output-button>
            </div>

            <button
              mat-raised-button
              aria-label="Complete IFSP"
              color="primary"
              class="ml-2 my-1"
              [ngClass]="{
                'mr-2': ifsp.ifspStatus === ifspStatuses.Active && canViewComplete
              }"
              *ngIf="ifsp.ifspStatus === ifspStatuses.Draft && canEdit && isCaseWorkable"
              [routerLink]="['/', 'cases', caseId, 'ifsp', ifsp.id, 'profile']"
              tabindex="0"
            >
              Complete IFSP
            </button>

            <button
              mat-raised-button
              aria-label="View Draft IFSP"
              color="primary"
              class="ml-2 my-1"
              [isBusy]="loading.finalization"
              [ngClass]="{
                'mr-2': ifsp.ifspStatus === ifspStatuses.Active && canViewComplete
              }"
              *ngIf="ifsp.ifspStatus === ifspStatuses.Draft && canView && isCaseWorkable"
              (click)="onIfspViewOnlyView(ifsp.id)"
              tabindex="0"
            >
              View Draft IFSP
            </button>

            <ng-container
              *appAuthForRoles="{
                permission: [permissions.CreateEditProgressMonitoring],
                caseId: caseSummary.id
              }"
            >
              <button
                mat-raised-button
                aria-label="Enter/View Progress"
                color="primary"
                class="ml-2 my-1"
                *ngIf="ifsp.ifspStatus === ifspStatuses.Active && isCaseWorkable"
                (click)="enterProgress()"
                tabindex="0"
              >
                Enter/View Progress
              </button>
            </ng-container>
            <button
              class="ml-2 my-1"
              mat-raised-button
              aria-label="IFSP View"
              color="primary"
              *ngIf="ifsp.ifspStatus === ifspStatuses.Active && (canViewComplete || canViewCompleteOutput)"
              target="_blank"
              (click)="onIfspView(ifsp)"
              tabindex="0"
            >
              IFSP View
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [dataSource]="dataSource"></mat-paginator>
    </div>
    <p *ngIf="!dataSource.data">No data yet...</p>
  </ng-container>
</mat-card>

<mat-accordion class="accordion accordion--close accordion--short" multi>
  <ng-container
    *appAuthForRoles="{
      permission: [permissions.ViewServiceLog],
      caseId: caseId
    }"
  >
    <mat-expansion-panel class="mat-elevation-z0" *ngIf="caseSummary && caseSummary.activeIfspId">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="display-flex justify-content-between align-items-center flex-1">
            <h3 class="my-0 text-normal">Service Logs</h3>

            <button
              *appAuthForRoles="{ permission: [permissions.CreateEditServiceLog], caseId: caseId }"
              mat-raised-button
              aria-label="Add New Log Entry"
              color="primary"
              (click)="onAddNewServiceLog($event)"
              tabindex="0"
            >
              Add New Log Entry
            </button>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-ifsp-service-logs [caseSummary]="caseSummary"></app-ifsp-service-logs>
    </mat-expansion-panel>
  </ng-container>

  <ng-container
    *appAuthForRoles="{
      permission: [permissions.ViewProgressMonitoring],
      caseId: caseId
    }"
  >
    <mat-expansion-panel class="mat-elevation-z0" *ngIf="caseSummary && caseSummary.activeIfspId">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3 class="my-0 text-normal">Monitoring for Progress</h3>
          <div class="display-flex justify-content-between align-items-center flex-1"></div>
          <button
            mat-raised-button
            color="primary"
            type="button"
            aria-label="Print Progress Report"
            (click)="onPrintProgressReport($event)"
            tabindex="0"
          >
            Print Progress Report
          </button>
          <div
            *appAuthForRoles="{
              permission: [permissions.CreateEditProgressMonitoring],
              caseId: caseSummary.id
            }"
          >
            <button
              mat-raised-button
              class="ml-2"
              color="primary"
              type="button"
              (click)="onAddNewProgress($event)"
              *ngIf="isCaseWorkable"
              tabindex="0"
              aria-label="Add New Progress Data / Notes"
            >
              Add New Progress Data / Notes
            </button>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-ifsp-data-monitoring-progress [caseSummary]="caseSummary"></app-ifsp-data-monitoring-progress>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>

<ng-container *ngIf="isPortalUser && caseSummary && caseSummary.activeIfspId">
  <div class="justify-content-between align-items-center mb-2">
    <h3 class="my-0">Summary of IFSP Outcomes and Services</h3>
    <mat-card>
      <mat-accordion class="accordion accordion--close accordion--shadow accordion--short mb-2" multi>
        <mat-expansion-panel class="mat-elevation-z0 mb-2">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="display-flex align-items-center justify-content-between flex-1">
                <h3 class="my-0 mr-2 text-normal">Outcomes</h3>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-ifsp-outcomes-list
            [caseId]="caseId"
            [finalizedOutcomesOnly]="true"
            [ifspId]="activeIFSP.id"
            [learnerId]="learnerId"
            [displayedColumns]="outcomeDisplayedColumns"
          ></app-ifsp-outcomes-list>
        </mat-expansion-panel>

        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="display-flex align-items-center justify-content-between flex-1">
                <h3 class="my-0 mr-2 text-normal">Services</h3>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-ifsp-service-list [isProfilePage]="false" [ifspId]="activeIFSP.id" [finalizedServicesOnly]="true"></app-ifsp-service-list>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>
  </div>

  <mat-accordion class="accordion accordion--close accordion--short" multi>
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="display-flex justify-content-between align-items-center flex-1">
            <h3 class="my-0 text-normal">Family Guided Routines Based Intervention Plans</h3>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-ifsp-fgi-view [caseSummary]="caseSummary"></app-ifsp-fgi-view>
    </mat-expansion-panel>

    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="display-flex justify-content-between align-items-center flex-1">
            <h3 class="my-0 text-normal">Monitoring for Progress</h3>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-ifsp-data-monitoring-progress [caseSummary]="caseSummary" [enableForms]="false"></app-ifsp-data-monitoring-progress>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

<div class="page-bottom-leeway"></div>
