<h3 class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>View More</h3>

<div class="mat-dialog-content py-2">
  <mat-card *ngIf="data">
    <ul class="list">
      <li class="list__item" *ngIf="goal.goalArea">
        <h3 class="text-primary mt-0 mb-2">Goal Area / Domain</h3>
        <app-compare-amendment-output
          [amendmentId]="amendment.amendmentId"
          [amendmentFinalized]="amendment.amendmentIsFinalized"
          [amendmentDate]="amendment.lastFinalizedDate"
          [amendmentEndReasonValue]="goal.amendmentEndDate"
          [priorVersionId]="goal.priorVersionId"
          [isActive]="goal.isActive"
          [changedVersionText]="goal.goalArea"
          [currentVersionText]="goal?.priorVersion ? goal?.priorVersion?.goalArea : goal.goalArea"
          [changedPrefixText]="goal?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item" *ngIf="goal.currentLevelDescription">
        <h3 class="text-primary mt-0 mb-2">Current Level of Performance Description</h3>
        <app-compare-amendment-output
          [amendmentId]="amendment.amendmentId"
          [amendmentFinalized]="amendment.amendmentIsFinalized"
          [amendmentDate]="amendment.lastFinalizedDate"
          [amendmentEndReasonValue]="goal.amendmentEndDate"
          [priorVersionId]="goal.priorVersionId"
          [isActive]="goal.isActive"
          [changedVersionText]="goal.currentLevelDescription"
          [currentVersionText]="goal?.priorVersion ? goal?.priorVersion?.currentLevelDescription : goal.currentLevelDescription"
          [changedPrefixText]="goal?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item" *ngIf="goal.standardOfComparison">
        <h3 class="text-primary mt-0 mb-2">Standard of Comparison</h3>
        <app-compare-amendment-output
          [amendmentId]="amendment.amendmentId"
          [amendmentFinalized]="amendment.amendmentIsFinalized"
          [amendmentDate]="amendment.lastFinalizedDate"
          [amendmentEndReasonValue]="goal.amendmentEndDate"
          [priorVersionId]="goal.priorVersionId"
          [isActive]="goal.isActive"
          [changedVersionText]="goal.standardOfComparison"
          [currentVersionText]="goal?.priorVersion ? goal?.priorVersion?.standardOfComparison : goal.standardOfComparison"
          [changedPrefixText]="goal?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item" *ngIf="goal.descriptionOfCondition">
        <h3 class="text-primary mt-0 mb-2">Description of Condition and Criterion</h3>
        <app-compare-amendment-output
          [amendmentId]="amendment.amendmentId"
          [amendmentFinalized]="amendment.amendmentIsFinalized"
          [amendmentDate]="amendment.lastFinalizedDate"
          [amendmentEndReasonValue]="goal.amendmentEndDate"
          [priorVersionId]="goal.priorVersionId"
          [isActive]="goal.isActive"
          [changedVersionText]="goal.descriptionOfCondition"
          [currentVersionText]="goal?.priorVersion ? goal?.priorVersion?.descriptionOfCondition : goal.descriptionOfCondition"
          [changedPrefixText]="goal?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <li class="list__item" *ngIf="goal.nickname">
        <h3 class="text-primary mt-0 mb-2">Goal Nickname</h3>
        <app-compare-amendment-output
          [amendmentId]="amendment.amendmentId"
          [amendmentFinalized]="amendment.amendmentIsFinalized"
          [amendmentDate]="amendment.lastFinalizedDate"
          [amendmentEndReasonValue]="goal.amendmentEndDate"
          [priorVersionId]="goal.priorVersionId"
          [isActive]="goal.isActive"
          [changedVersionText]="goal.nickname"
          [currentVersionText]="goal?.priorVersion ? goal?.priorVersion?.nickname : goal.nickname"
          [changedPrefixText]="goal?.priorVersion ? 'Proposed: ' : ''"
        ></app-compare-amendment-output>
      </li>
      <ng-container *ngIf="goal.primaryMilestone && goal.primaryMilestone.length > 0">
        <li class="list__item" *ngFor="let milestone of goal.primaryMilestone">
          <h3 class="text-primary mt-0 mb-2">Annual Measurable Goal</h3>
          <ul class="list" *ngIf="milestone.firstMeasurement">
            <h3 class="mt-0 mb-2">First Measurement</h3>
            <li class="list__item">
              <h4 class="mt-0 mb-2">Baseline</h4>
              <app-compare-amendment-output
                [amendmentId]="amendment.amendmentId"
                [amendmentFinalized]="amendment.amendmentIsFinalized"
                [amendmentDate]="amendment.lastFinalizedDate"
                [amendmentEndReasonValue]="milestone?.firstMeasurement.amendmentEndDate"
                [priorVersionId]="milestone?.firstMeasurement.priorVersionId"
                [isActive]="milestone?.firstMeasurement.isActive"
                [changedVersionText]="milestone?.firstMeasurement?.baseline"
                [currentVersionText]="
                  milestone?.firstMeasurement?.priorVersionId
                    ? getPriorPrimaryMilestone()?.firstMeasurement?.baseline
                    : milestone?.firstMeasurement?.baseline
                "
                [changedPrefixText]="milestone?.firstMeasurement?.priorVersionId ? 'Proposed: ' : ''"
              ></app-compare-amendment-output>
            </li>
            <ng-container *ngIf="milestone.firstMeasurement.dataPoints && milestone.firstMeasurement.dataPoints.length > 0">
              <li class="list__item" *ngFor="let point of milestone.firstMeasurement.dataPoints">
                <h5 class="mt-0 mb-2">{{ point.name }}</h5>
                <div *ngIf="point.value && point.value.length > 0">
                  <span *ngFor="let val of point.value"> {{ val.name }} - {{ val.value }} </span>
                </div>
              </li>
            </ng-container>
            <li class="list__item" *ngIf="milestone?.firstMeasurement?.unitOfMeasurement">
              <h4 class="mt-0 mb-2">Unit of Measurement</h4>
              <app-compare-amendment-output
                [amendmentId]="amendment.amendmentId"
                [amendmentFinalized]="amendment.amendmentIsFinalized"
                [amendmentDate]="amendment.lastFinalizedDate"
                [amendmentEndReasonValue]="milestone?.firstMeasurement.amendmentEndDate"
                [priorVersionId]="milestone?.firstMeasurement.priorVersionId"
                [isActive]="milestone?.firstMeasurement.isActive"
                [changedVersionText]="milestone?.firstMeasurement?.unitOfMeasurement"
                [currentVersionText]="
                  milestone?.firstMeasurement?.priorVersionId
                    ? getPriorPrimaryMilestone()?.firstMeasurement?.unitOfMeasurement
                    : milestone?.firstMeasurement?.unitOfMeasurement
                "
                [changedPrefixText]="milestone?.firstMeasurement?.priorVersionId ? 'Proposed: ' : ''"
              ></app-compare-amendment-output>
            </li>
            <li class="list__item" *ngIf="milestone.firstMeasurement.learnersGoalTarget">
              <h4 class="mt-0 mb-2">Learner's Goal Target</h4>
              <app-compare-amendment-output
                [amendmentId]="amendment.amendmentId"
                [amendmentFinalized]="amendment.amendmentIsFinalized"
                [amendmentDate]="amendment.lastFinalizedDate"
                [amendmentEndReasonValue]="milestone?.firstMeasurement.amendmentEndDate"
                [priorVersionId]="milestone?.firstMeasurement.priorVersionId"
                [isActive]="milestone?.firstMeasurement.isActive"
                [changedVersionText]="milestone?.firstMeasurement?.learnersGoalTarget"
                [currentVersionText]="
                  milestone?.firstMeasurement?.priorVersionId
                    ? getPriorPrimaryMilestone()?.firstMeasurement?.learnersGoalTarget
                    : milestone?.firstMeasurement?.learnersGoalTarget
                "
                [changedPrefixText]="milestone?.firstMeasurement?.priorVersionId ? 'Proposed: ' : ''"
              ></app-compare-amendment-output>
            </li>
            <ng-container *ngIf="milestone.firstMeasurement.tieredMilestones && milestone.firstMeasurement.tieredMilestones.length > 0">
              <li class="list__item" *ngFor="let tier of milestone.firstMeasurement.tieredMilestones">
                <h5 class="mt-0 mb-2">{{ tier.name }}</h5>
                <div *ngIf="tier.value && tier.value.length > 0">
                  <span *ngFor="let val of tier.value"> {{ val.name }} - {{ val.value }} </span>
                </div>
              </li>
            </ng-container>
            <ng-container *ngIf="milestone.firstMeasurement.scale && milestone.firstMeasurement.scale.length > 0">
              <li class="list__item" *ngFor="let scale of milestone.firstMeasurement.scale">
                <h5 class="mt-0 mb-2">{{ scale.name }}</h5>
                <div *ngIf="scale.value && scale.value.length > 0">
                  <span *ngFor="let val of scale.value; let i = index"> {{ val.name }} - {{ val.value }}{{ i === 0 ? ',' : '' }} </span>
                </div>
              </li>
            </ng-container>
          </ul>
          <ul class="list" *ngIf="milestone.secondMeasurement && milestone.secondMeasurement.baseline">
            <h3 class="my-2">Second Measurement</h3>

            <li class="list__item" *ngIf="milestone.secondMeasurement.baseline">
              <h4 class="mt-0 mb-2">Baseline</h4>
              <app-compare-amendment-output
                [amendmentId]="amendment.amendmentId"
                [amendmentFinalized]="amendment.amendmentIsFinalized"
                [amendmentDate]="amendment.lastFinalizedDate"
                [amendmentEndReasonValue]="milestone?.firstMeasurement.amendmentEndDate"
                [priorVersionId]="milestone?.firstMeasurement.priorVersionId"
                [isActive]="milestone?.firstMeasurement.isActive"
                [changedVersionText]="milestone?.secondMeasurement?.baseline"
                [currentVersionText]="
                  milestone?.secondMeasurement?.priorVersionId
                    ? getPriorPrimaryMilestone()?.secondMeasurement?.baseline
                    : milestone?.secondMeasurement?.baseline
                "
                [changedPrefixText]="milestone?.secondMeasurement?.priorVersionId ? 'Proposed: ' : ''"
              ></app-compare-amendment-output>
            </li>
            <ng-container *ngIf="milestone.secondMeasurement.dataPoints && milestone.secondMeasurement.dataPoints.length > 0">
              <li class="list__item" *ngFor="let point of milestone.secondMeasurement.dataPoints">
                <h5 class="mt-0 mb-2">{{ point.name }}</h5>
                <div *ngIf="point.value && point.value.length > 0">
                  <span *ngFor="let val of point.value"> {{ val.name }} - {{ val.value }} </span>
                </div>
              </li>
            </ng-container>
            <li class="list__item" *ngIf="milestone?.secondMeasurement?.unitOfMeasurement">
              <h4 class="mt-0 mb-2">Unit of Measurement</h4>
              <app-compare-amendment-output
                [amendmentId]="amendment.amendmentId"
                [amendmentFinalized]="amendment.amendmentIsFinalized"
                [amendmentDate]="amendment.lastFinalizedDate"
                [amendmentEndReasonValue]="milestone?.firstMeasurement.amendmentEndDate"
                [priorVersionId]="milestone?.firstMeasurement.priorVersionId"
                [isActive]="milestone?.firstMeasurement.isActive"
                [changedVersionText]="milestone?.secondMeasurement?.unitOfMeasurement"
                [currentVersionText]="
                  milestone?.secondMeasurement?.priorVersionId
                    ? getPriorPrimaryMilestone()?.secondMeasurement?.unitOfMeasurement
                    : milestone?.secondMeasurement?.unitOfMeasurement
                "
                [changedPrefixText]="milestone?.secondMeasurement?.priorVersionId ? 'Proposed: ' : ''"
              ></app-compare-amendment-output>
            </li>
            <li class="list__item" *ngIf="milestone.secondMeasurement.learnersGoalsTarget">
              <h4 class="mt-0 mb-2">Learner's Goal Target</h4>
              <app-compare-amendment-output
                [amendmentId]="amendment.amendmentId"
                [amendmentFinalized]="amendment.amendmentIsFinalized"
                [amendmentDate]="amendment.lastFinalizedDate"
                [amendmentEndReasonValue]="milestone?.firstMeasurement.amendmentEndDate"
                [priorVersionId]="milestone?.firstMeasurement.priorVersionId"
                [isActive]="milestone?.firstMeasurement.isActive"
                [changedVersionText]="milestone?.secondMeasurement?.learnersGoalsTarget"
                [currentVersionText]="
                  milestone?.secondMeasurement?.priorVersionId
                    ? getPriorPrimaryMilestone()?.secondMeasurement?.learnersGoalsTarget
                    : milestone?.secondMeasurement?.learnersGoalsTarget
                "
                [changedPrefixText]="milestone?.secondMeasurement?.priorVersionId ? 'Proposed: ' : ''"
              ></app-compare-amendment-output>
            </li>
            <ng-container *ngIf="milestone.secondMeasurement.tieredMilestones && milestone.secondMeasurement.tieredMilestones.length > 0">
              <li class="list__item" *ngFor="let tier of milestone.secondMeasurement.tieredMilestones">
                <h5 class="mt-0 mb-2">{{ tier.name }}</h5>
                <div *ngIf="tier.value && tier.value.length > 0">
                  <span *ngFor="let val of tier.value"> {{ val.name }} - {{ val.value }} </span>
                </div>
              </li>
            </ng-container>
            <ng-container *ngIf="milestone.secondMeasurement.scale && milestone.secondMeasurement.scale.length > 0">
              <li class="list__item" *ngFor="let scale of milestone.secondMeasurement.scale">
                <h5 class="mt-0 mb-2">{{ scale.name }}</h5>
                <div *ngIf="scale.value && scale.value.length > 0">
                  <span *ngFor="let val of scale.value; let i = index"> {{ val.name }} - {{ val.value }}{{ i === 0 ? ',' : '' }} </span>
                </div>
              </li>
            </ng-container>
          </ul>
        </li>
        <li class="list__item" *ngFor="let additional of goal.additionalMilestones">
          <h3 class="text-primary mt-0 mb-2">Additional Objectives</h3>
          {{ additional.name }}
          {{ additional.status }}
          {{ additional.description }}
          <ul class="list">
            <li class="list__item">
              <ul class="list" *ngIf="additional.firstMeasurement">
                <h3 class="my-2">First Measurement</h3>
                <li class="list__item">
                  <h4 class="mt-0 mb-2">Baseline</h4>
                  <app-compare-amendment-output
                    [amendmentId]="amendment.amendmentId"
                    [amendmentFinalized]="amendment.amendmentIsFinalized"
                    [amendmentDate]="amendment.lastFinalizedDate"
                    [amendmentEndReasonValue]="additional?.firstMeasurement.amendmentEndDate"
                    [priorVersionId]="additional?.firstMeasurement.priorVersionId"
                    [isActive]="additional?.firstMeasurement.isActive"
                    [changedVersionText]="additional?.firstMeasurement?.baseline"
                    [currentVersionText]="
                      additional?.firstMeasurement?.priorVersionId
                        ? getPriorAdditionalMilestone(additional?.id)?.firstMeasurement?.baseline
                        : additional?.firstMeasurement?.baseline
                    "
                    [changedPrefixText]="additional?.firstMeasurement?.priorVersionId ? 'Proposed: ' : ''"
                  ></app-compare-amendment-output>
                </li>
                <ng-container *ngIf="additional.firstMeasurement.dataPoints && additional.firstMeasurement.dataPoints.length > 0">
                  <li class="list__item" *ngFor="let point of additional.firstMeasurement.dataPoints">
                    <h5 class="mt-0 mb-2">{{ point.name }}</h5>
                    <div *ngIf="point.value && point.value.length > 0">
                      <span *ngFor="let val of point.value"> {{ val.name }} - {{ val.value }} </span>
                    </div>
                  </li>
                </ng-container>
                <li class="list__item" *ngIf="additional?.firstMeasurement?.unitOfMeasurement">
                  <h4 class="mt-0 mb-2">Unit of Measurement</h4>
                  <app-compare-amendment-output
                    [amendmentId]="amendment.amendmentId"
                    [amendmentFinalized]="amendment.amendmentIsFinalized"
                    [amendmentDate]="amendment.lastFinalizedDate"
                    [amendmentEndReasonValue]="additional?.firstMeasurement.amendmentEndDate"
                    [priorVersionId]="additional?.firstMeasurement.priorVersionId"
                    [isActive]="additional?.firstMeasurement.isActive"
                    [changedVersionText]="additional?.firstMeasurement?.unitOfMeasurement"
                    [currentVersionText]="
                      additional?.firstMeasurement?.priorVersionId
                        ? getPriorAdditionalMilestone(additional?.id)?.firstMeasurement?.unitOfMeasurement
                        : additional?.firstMeasurement?.unitOfMeasurement
                    "
                    [changedPrefixText]="additional?.firstMeasurement?.priorVersionId ? 'Proposed: ' : ''"
                  ></app-compare-amendment-output>
                </li>
                <li class="list__item" *ngIf="additional.firstMeasurement.learnersGoalTarget">
                  <h4 class="mt-0 mb-2">Learner's Goal Target</h4>
                  <app-compare-amendment-output
                    [amendmentId]="amendment.amendmentId"
                    [amendmentFinalized]="amendment.amendmentIsFinalized"
                    [amendmentDate]="amendment.lastFinalizedDate"
                    [amendmentEndReasonValue]="additional?.firstMeasurement.amendmentEndDate"
                    [priorVersionId]="additional?.firstMeasurement.priorVersionId"
                    [isActive]="additional?.firstMeasurement.isActive"
                    [changedVersionText]="additional?.firstMeasurement?.learnersGoalTarget"
                    [currentVersionText]="
                      additional?.firstMeasurement?.priorVersionId
                        ? getPriorAdditionalMilestone(additional?.id)?.firstMeasurement?.learnersGoalTarget
                        : additional?.firstMeasurement?.learnersGoalTarget
                    "
                    [changedPrefixText]="additional?.firstMeasurement?.priorVersionId ? 'Proposed: ' : ''"
                  ></app-compare-amendment-output>
                </li>
                <ng-container
                  *ngIf="additional.firstMeasurement.tieredMilestones && additional.firstMeasurement.tieredMilestones.length > 0"
                >
                  <li class="list__item" *ngFor="let tier of additional.firstMeasurement.tieredMilestones">
                    <h5 class="mt-0 mb-2">{{ tier.name }}</h5>
                    <div *ngIf="tier.value && tier.value.length > 0">
                      <span *ngFor="let val of tier.value"> {{ val.name }} - {{ val.value }} </span>
                    </div>
                  </li>
                </ng-container>
                <ng-container *ngIf="additional.firstMeasurement.scale && additional.firstMeasurement.scale.length > 0">
                  <li class="list__item" *ngFor="let scale of additional.firstMeasurement.scale">
                    <h5 class="mt-0 mb-2">{{ scale.name }}</h5>
                    <div *ngIf="scale.value && scale.value.length > 0">
                      <span *ngFor="let val of scale.value; let i = index"> {{ val.name }} - {{ val.value }}{{ i === 0 ? ',' : '' }} </span>
                    </div>
                  </li>
                </ng-container>
              </ul>
              <ul class="list" *ngIf="additional.secondMeasurement">
                <h3 class="my-2">Second Measurement</h3>

                <li class="list__item" *ngIf="additional.secondMeasurement.baseline">
                  <h4 class="mt-0 mb-2">Baseline</h4>
                  <app-compare-amendment-output
                    [amendmentId]="amendment.amendmentId"
                    [amendmentFinalized]="amendment.amendmentIsFinalized"
                    [amendmentDate]="amendment.lastFinalizedDate"
                    [amendmentEndReasonValue]="additional?.secondMeasurement.amendmentEndDate"
                    [priorVersionId]="additional?.secondMeasurement.priorVersionId"
                    [isActive]="additional?.secondMeasurement.isActive"
                    [changedVersionText]="additional?.secondMeasurement?.baseline"
                    [currentVersionText]="
                      additional?.secondMeasurement?.priorVersionId
                        ? getPriorAdditionalMilestone(additional?.id)?.secondMeasurement?.baseline
                        : additional?.secondMeasurement?.baseline
                    "
                    [changedPrefixText]="additional?.secondMeasurement?.priorVersionId ? 'Proposed: ' : ''"
                  ></app-compare-amendment-output>
                </li>
                <ng-container *ngIf="additional.secondMeasurement.dataPoints && additional.secondMeasurement.dataPoints.length > 0">
                  <li class="list__item" *ngFor="let point of additional.secondMeasurement.dataPoints">
                    <h5 class="mt-0 mb-2">{{ point.name }}</h5>
                    <div *ngIf="point.value && point.value.length > 0">
                      <span *ngFor="let val of point.value"> {{ val.name }} - {{ val.value }} </span>
                    </div>
                  </li>
                </ng-container>
                <li class="list__item" *ngIf="additional.secondMeasurement.unitOfMeasurement">
                  <h4 class="mt-0 mb-2">Unit of Measurement</h4>
                  <app-compare-amendment-output
                    [amendmentId]="amendment.amendmentId"
                    [amendmentFinalized]="amendment.amendmentIsFinalized"
                    [amendmentDate]="amendment.lastFinalizedDate"
                    [amendmentEndReasonValue]="additional?.secondMeasurement.amendmentEndDate"
                    [priorVersionId]="additional?.secondMeasurement.priorVersionId"
                    [isActive]="additional?.secondMeasurement.isActive"
                    [changedVersionText]="additional?.secondMeasurement?.unitOfMeasurement"
                    [currentVersionText]="
                      additional?.secondMeasurement?.priorVersionId
                        ? getPriorAdditionalMilestone(additional?.id)?.secondMeasurement?.unitOfMeasurement
                        : additional?.secondMeasurement?.unitOfMeasurement
                    "
                    [changedPrefixText]="additional?.secondMeasurement?.priorVersionId ? 'Proposed: ' : ''"
                  ></app-compare-amendment-output>
                </li>
                <li class="list__item" *ngIf="additional.secondMeasurement.learnersGoalTarget">
                  <h4 class="mt-0 mb-2">Learner's Goal Target</h4>
                  <app-compare-amendment-output
                    [amendmentId]="amendment.amendmentId"
                    [amendmentFinalized]="amendment.amendmentIsFinalized"
                    [amendmentDate]="amendment.lastFinalizedDate"
                    [amendmentEndReasonValue]="additional?.secondMeasurement.amendmentEndDate"
                    [priorVersionId]="additional?.secondMeasurement.priorVersionId"
                    [isActive]="additional?.secondMeasurement.isActive"
                    [changedVersionText]="additional?.secondMeasurement?.learnersGoalTarget"
                    [currentVersionText]="
                      additional?.secondMeasurement?.priorVersionId
                        ? getPriorAdditionalMilestone(additional?.id)?.secondMeasurement?.learnersGoalTarget
                        : additional?.secondMeasurement?.learnersGoalTarget
                    "
                    [changedPrefixText]="additional?.secondMeasurement?.priorVersionId ? 'Proposed: ' : ''"
                  ></app-compare-amendment-output>
                </li>
                <ng-container
                  *ngIf="additional.secondMeasurement.tieredMilestones && additional.secondMeasurement.tieredMilestones.length > 0"
                >
                  <li class="list__item" *ngFor="let tier of additional.secondMeasurement.tieredMilestones">
                    <h5 class="mt-0 mb-2">{{ tier.name }}</h5>
                    <div *ngIf="tier.value && tier.value.length > 0">
                      <span *ngFor="let val of tier.value"> {{ val.name }} - {{ val.value }} </span>
                    </div>
                  </li>
                </ng-container>
                <ng-container *ngIf="additional.secondMeasurement.scale && additional.secondMeasurement.scale.length > 0">
                  <li class="list__item" *ngFor="let scale of additional.secondMeasurement.scale">
                    <h5 class="mt-0 mb-2">{{ scale.name }}</h5>
                    <div *ngIf="scale.value && scale.value.length > 0">
                      <span *ngFor="let val of scale.value; let i = index"> {{ val.name }} - {{ val.value }}{{ i === 0 ? ',' : '' }} </span>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ul>
        </li>
        <li class="list__item" *ngIf="goal.howOftenProgressMonitored">
          <h3 class="text-primary mt-0 mb-2">How often will progress toward goal target be measured?</h3>
          <app-compare-amendment-output
            [amendmentId]="amendment.amendmentId"
            [amendmentFinalized]="amendment.amendmentIsFinalized"
            [amendmentDate]="amendment.lastFinalizedDate"
            [amendmentEndReasonValue]="goal.amendmentEndDate"
            [priorVersionId]="goal.priorVersionId"
            [isActive]="goal.isActive"
            [changedVersionText]="goal.howOftenProgressMonitored"
            [currentVersionText]="goal?.priorVersion ? goal?.priorVersion?.howOftenProgressMonitored : goal.howOftenProgressMonitored"
            [changedPrefixText]="goal?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </li>
        <li class="list__item" *ngIf="goal.responsibleForMonitoring">
          <h3 class="text-primary mt-0 mb-2">Person(s) responsible for monitoring</h3>
          <app-compare-amendment-output
            [amendmentId]="amendment.amendmentId"
            [amendmentFinalized]="amendment.amendmentIsFinalized"
            [amendmentDate]="amendment.lastFinalizedDate"
            [amendmentEndReasonValue]="goal.amendmentEndDate"
            [priorVersionId]="goal.priorVersionId"
            [isActive]="goal.isActive"
            [changedVersionText]="goal.responsibleForMonitoring"
            [currentVersionText]="goal?.priorVersion ? goal?.priorVersion?.responsibleForMonitoring : goal.responsibleForMonitoring"
            [changedPrefixText]="goal?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </li>
        <li class="list__item" *ngIf="goal.collaborators">
          <h3 class="text-primary mt-0 mb-2">Collaborator(s)</h3>
          <app-compare-amendment-output
            [amendmentId]="amendment.amendmentId"
            [amendmentFinalized]="amendment.amendmentIsFinalized"
            [amendmentDate]="amendment.lastFinalizedDate"
            [amendmentEndReasonValue]="goal.amendmentEndDate"
            [priorVersionId]="goal.priorVersionId"
            [isActive]="goal.isActive"
            [changedVersionText]="goal.collaborators"
            [currentVersionText]="goal?.priorVersion ? goal?.priorVersion?.collaborators : goal.collaborators"
            [changedPrefixText]="goal?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </li>
        <li class="list__item" *ngIf="goal.descriptionOfMonitoring">
          <h3 class="text-primary mt-0 mb-2">Description of monitoring procedures that will be used</h3>
          <app-compare-amendment-output
            [amendmentId]="amendment.amendmentId"
            [amendmentFinalized]="amendment.amendmentIsFinalized"
            [amendmentDate]="amendment.lastFinalizedDate"
            [amendmentEndReasonValue]="goal.amendmentEndDate"
            [priorVersionId]="goal.priorVersionId"
            [isActive]="goal.isActive"
            [changedVersionText]="goal.descriptionOfMonitoring"
            [currentVersionText]="goal?.priorVersion ? goal?.priorVersion?.descriptionOfMonitoring : goal.descriptionOfMonitoring"
            [changedPrefixText]="goal?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </li>
        <li class="list__item" *ngIf="goal.howOftenReported">
          <h3 class="text-primary mt-0 mb-2">How often will progress be reported to family?</h3>
          <app-compare-amendment-output
            [amendmentId]="amendment.amendmentId"
            [amendmentFinalized]="amendment.amendmentIsFinalized"
            [amendmentDate]="amendment.lastFinalizedDate"
            [amendmentEndReasonValue]="goal.amendmentEndDate"
            [priorVersionId]="goal.priorVersionId"
            [isActive]="goal.isActive"
            [changedVersionText]="goal.howOftenReported"
            [currentVersionText]="goal?.priorVersion ? goal?.priorVersion?.howOftenReported : goal.howOftenReported"
            [changedPrefixText]="goal?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </li>
        <li class="list__item" *ngIf="goal.howOftenProgressMeasured">
          <h3 class="text-primary mt-0 mb-2">How often will progress towards targeted value be measured?</h3>
          <app-compare-amendment-output
            [amendmentId]="amendment.amendmentId"
            [amendmentFinalized]="amendment.amendmentIsFinalized"
            [amendmentDate]="amendment.lastFinalizedDate"
            [amendmentEndReasonValue]="goal.amendmentEndDate"
            [priorVersionId]="goal.priorVersionId"
            [isActive]="goal.isActive"
            [changedVersionText]="goal.howOftenProgressMeasured"
            [currentVersionText]="goal?.priorVersion ? goal?.priorVersion?.howOftenProgressMeasured : goal.howOftenProgressMeasured"
            [changedPrefixText]="goal?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </li>
        <li class="list__item" *ngIf="goal.methodsToReport">
          <h3 class="text-primary mt-0 mb-2">Method(s) to report progress to family</h3>
          <app-compare-amendment-output
            [amendmentId]="amendment.amendmentId"
            [amendmentFinalized]="amendment.amendmentIsFinalized"
            [amendmentDate]="amendment.lastFinalizedDate"
            [amendmentEndReasonValue]="goal.amendmentEndDate"
            [priorVersionId]="goal.priorVersionId"
            [isActive]="goal.isActive"
            [changedVersionText]="goal.methodsToReport"
            [currentVersionText]="goal?.priorVersion ? goal?.priorVersion?.methodsToReport : goal.methodsToReport"
            [changedPrefixText]="goal?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </li>
      </ng-container>
    </ul>
  </mat-card>
</div>

<div class="mat-dialog-actions justify-content-end">
  <button mat-raised-button aria-label="Delete Icon" (click)="onClose()" cdkFocusInitial tabindex="0">Close</button>
</div>
