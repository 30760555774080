<ng-container *ngIf="buttonType === 'mat-raised-button'">
  <button
    mat-raised-button
    attr.aria-label="{{ buttonText }}"
    [color]="buttonColor"
    [isBusy]="loading"
    (click)="onOpenOutput()"
    tabindex="0"
  >
    <mat-icon *ngIf="openNewIcon" class="text-lg">open_in_new</mat-icon>
    {{ buttonText }}
  </button>
</ng-container>
<ng-container *ngIf="buttonType === 'mat-flat-button'">
  <button mat-flat-button attr.aria-label="{{ buttonText }}" [color]="buttonColor" [isBusy]="loading" (click)="onOpenOutput()" tabindex="0">
    <mat-icon *ngIf="openNewIcon" class="text-lg">open_in_new</mat-icon>
    {{ buttonText }}
  </button>
</ng-container>
