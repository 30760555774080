import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EvaluationService } from 'src/app/evaluation/services/evaluation.service';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { CaseSummary, EvaluationForLeanerManagement, EvaluationType, IntakeType } from 'src/app/shared/models/case';
import { DeactivationService } from '../../../../shared/services/deactivation.service';
import { openPdfWindow } from '../../../../shared/windowHelpers';
import { AuthService } from '../../../../auth/auth.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { ReevaluationService } from '../../../../reevaluation/services/reevaluation.service';
import { LearnerService } from '../../../../shared/services/learner/learner.service';

@Component({
  selector: 'app-data-history-evaluation',
  templateUrl: './data-history-evaluation.component.html',
  styleUrls: ['./data-history-evaluation.component.scss'],
})
export class DataHistoryEvaluationComponent extends BaseComponent implements OnInit {
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  activeEvaluation: EvaluationForLeanerManagement;
  @Input() case: CaseSummary;
  hasEvaluationsForIntakeType;

  constructor(
    private route: ActivatedRoute,
    private evaluationService: EvaluationService,
    deactivationService: DeactivationService,
    public readonly authService: AuthService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly reevaluationService: ReevaluationService,
    private readonly learnerService: LearnerService
  ) {
    super(deactivationService);
  }

  async ngOnInit(): Promise<void> {
    await this.loadData();
  }

  async loadData(): Promise<void> {
    await this.setup();
  }

  private async setup(): Promise<void> {
    if (!this.case) {
      return;
    }

    const evaluationList = await this.evaluationService.getLearnerEvaluations(this.case.learnerId).toPromise();
    if (!evaluationList) {
      return;
    }

    this.activeEvaluation = evaluationList.find((x) => !x.isFinalized && x.intakeType === this.case.intakeType);
    this.dataSource.data = evaluationList;
    this.hasEvaluationsForIntakeType = evaluationList.filter((x) => x.intakeType === this.case.intakeType).length !== 0;
  }

  onGoToEvaluation(evaluation?: EvaluationForLeanerManagement) {
    if (evaluation && evaluation.id && evaluation.isFinalized && evaluation.intakeType === IntakeType.PartB) {
      if (evaluation.type !== EvaluationType.Reevaluation && !evaluation.eerDocumentId) {
        return;
      }
      openPdfWindow(this.case.learnerId, evaluation.eerDocumentId);
      return;
    }

    const navById = (id, evaluationType) => {
      this.router.navigate(['/cases', evaluation.caseId, evaluationType.toLowerCase(), id, 'overview']).then();
    };

    if (evaluation?.id) {
      navById(evaluation.id, evaluation.type);
      return;
    }
  }
}
