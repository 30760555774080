import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, Optional, Self, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { BaseWrapperComponent } from '../base-wrapper.component';
import { KeyValuePair } from '../../../models/key-value-pair';
import { LoggerService } from '../../../services/logger/logger.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ascend-checkbox',
  templateUrl: './ascend-checkbox.component.html',
  styleUrls: ['./ascend-checkbox.component.scss'],
})
export class AscendCheckboxComponent extends BaseWrapperComponent implements OnInit, OnChanges, OnDestroy {
  private valueChangeSource: 'selection' | 'patch' | 'checkIfOptionsAreDisabled' | null;
  optionsBacking: KeyValuePair[];
  hasInitialized = false;
  alive = true;
  value: any;
  internalFormGroup: FormGroup = new FormGroup({});
  groupArray: FormControl[] = [];
  selectAll = false;
  changeSubscription: Subscription;
  checkAllControl = new FormControl();

  @Input() wrap = false;

  @Input() column = false;

  @Input() showSelectAll = false;

  @Input()
  set options(value: KeyValuePair[]) {
    this.optionsBacking = value;
  }

  getLabel(key: number): string {
    return this.optionsBacking[key]?.value;
  }

  checkAll(event: MatCheckboxChange): void {
    let anyDisabled = false;
    this.groupArray.forEach((x) => {
      if (!x.enabled) {
        anyDisabled = true;
        return;
      }
      x.setValue(event.checked);
    });
    if (anyDisabled) {
      this.selectAll = false;
    }
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    logger: LoggerService,
    @Self()
    @Optional()
    ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(logger, ngControl, changeDetectorRef);
  }

  ngOnInit(): void {
    if (!this.hasInitialized) {
      this.init();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.hasInitialized) {
      this.init();
      this.hasInitialized = true;
    }
    if (changes.readOnly) {
      this.disabledState = changes.readOnly.currentValue;
    }
    super.ngOnChanges(changes);
    if (changes.options?.currentValue && this.control) {
      const value = this.control.value || [];

      const group = {};

      this.optionsBacking.forEach((element) => {
        const control = new FormControl(value.includes(element.value), {
          updateOn: 'change',
        });
        const action = element.enabled ? 'disable' : 'enable';
        control[action]();
        group[element.key] = control;
        this.groupArray.push(control);
      });

      this.internalFormGroup = this.formBuilder.group(group);

      this.checkIfOptionsAreDisabled();

      this.onValueChange();

      if (this.changeSubscription) {
        this.changeSubscription.unsubscribe();
      }
      this.changeSubscription = this.internalFormGroup.valueChanges.pipe(takeWhile(() => this.alive)).subscribe((res) => {
        if (this.valueChangeSource) {
          return;
        }
        this.valueChangeSource = 'selection';
        const selected = Object.keys(res).filter((x) => res[x]) || [];
        if (this.optionsBacking.length > 1) {
          this.control.patchValue(selected);
        } else {
          this.control.patchValue(selected[0]);
        }
        this.control.markAsDirty();
        this.control.markAsTouched();
        this.valueChangeSource = undefined;
        this.checkIfOptionsAreDisabled();
      });
    }
  }

  private init(): void {
    super.ngOnInit();
    this.hasInitialized = true;
    this.control.valueChanges.pipe(takeWhile(() => this.alive)).subscribe((newValues) => {
      this.onValueChange();
    });
    if (this.control.value) {
      this.onValueChange();
    }
    this.checkDisabled(this.disabledState || this.readOnly);
  }

  onValueChange() {
    const newValues = this.control.value;
    // If Select all is checked and an option goes unchecked, it deselects Select all
    if (this.showSelectAll) {
      this.selectAll = !this.groupArray.some((x) => x.value === false);
    }
    if (!this.internalFormGroup || Object.entries(this.internalFormGroup.controls).length === 0 || this.valueChangeSource) {
      return;
    }
    this.checkIfOptionsAreDisabled();
    this.valueChangeSource = 'patch';
    if (!newValues) {
      this.groupArray.forEach((x) => {
        x.setValue(false);
      });
    } else {
      const patch = {};
      this.optionsBacking.forEach((x) => {
        patch[x.key] = false;
      });
      newValues.forEach((y) => {
        patch[y] = true;
      });
      this.internalFormGroup.patchValue(patch);
    }
    this.valueChangeSource = undefined;
  }

  checkIfOptionsAreDisabled() {
    if (!Object.entries(this.internalFormGroup.controls).length) {
      return;
    }
    this.valueChangeSource = 'checkIfOptionsAreDisabled';
    const optionsWithState = this.optionsBacking.filter((x) => x.enabled !== undefined);
    optionsWithState.forEach((element) => {
      const action = element.enabled ? 'enable' : 'disable';
      this.internalFormGroup.controls[element.key][action]();
    });

    if (optionsWithState.filter((x) => !x.enabled)?.length > 0) {
      this.selectAll = false;
      this.checkAllControl.disable();
    } else {
      this.checkAllControl.enable();
    }
    this.valueChangeSource = undefined;
  }

  checkDisabled(disable: boolean): void {
    const action = disable ? 'disable' : 'enable';
    this.checkAllControl[action]();
    this.groupArray.forEach((control) => {
      control[action]();
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
