<div [ngClass]="{ 'container container--not-centered container--form': !alignEnd }">
  <div
    *ngIf="!readOnly"
    [ngClass]="{
      'display-flex align-items-center justify-content-end': alignEnd
    }"
  >
    <button
      mat-raised-button
      aria-label="Open Documentation Upload"
      type="button"
      (click)="onOpenUpload()"
      [isBusy]="activeCall"
      tabindex="0"
    >
      {{ title }}
    </button>
  </div>
  <div class="w-100 mt-2" *ngIf="documents && documents.length > 0">
    <div class="table-overflow w-100">
      <table class="w-100" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="friendlyName">
          <th mat-header-cell *matHeaderCellDef>Title</th>
          <td mat-cell *matCellDef="let file">{{ file.title }}</td>
        </ng-container>

        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef>File</th>
          <td mat-cell *matCellDef="let file">
            <a href="{{ baseUrl }}/documents/{{ file.id }}" target="_blank" *ngIf="baseUrl" tabindex="0" role="link" aria-labelledby="">{{
              file.fileName
            }}</a>
            <span *ngIf="!baseUrl">{{ file.fileName }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let file">
            <button
              mat-icon-button
              aria-label="Delete Icon"
              color="warn"
              type="button"
              aria-label="Delete"
              *ngIf="(authService.isAllowed(permissions.DeleteDocuments) || isEvaluation) && !readOnly"
              (click)="confirmDeleteDocument(file)"
              tabindex="0"
            >
              <mat-icon>close</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <p *ngIf="!dataSource.data">No data yet...</p>
    </div>
  </div>
</div>

<ng-template #verifyDelete let-data>
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Are you sure?</h3>
  <mat-dialog-content class="py-3">
    <mat-card>
      <p>Clicking Yes will remove this file.</p>
      <p>Title: {{ data.title }}</p>
      <p>File Name: {{ data.fileName }}</p>
      <p>Deleted documents cannot be recovered.</p>
    </mat-card>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      class="background-color--muted mr-1"
      type="button"
      mat-raised-button
      aria-label="Cancel Button"
      [mat-dialog-close]="false"
      tabindex="0"
    >
      Cancel
    </button>

    <button
      color="primary"
      type="button"
      mat-raised-button
      aria-label="Yes"
      (click)="onDeleteDocument(data.id)"
      [mat-dialog-close]="true"
      tabindex="0"
    >
      Yes
    </button>
  </mat-dialog-actions>
</ng-template>
