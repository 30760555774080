import { Component, OnInit } from '@angular/core';
import { Evaluation } from '../evaluation/models/evaluation';
import { LearnerSummary } from '../shared/models/learner';

@Component({
  selector: 'app-eligible-family-dashboard',
  templateUrl: './eligible-family-dashboard.component.html',
  styleUrls: ['./eligible-family-dashboard.component.scss'],
})
export class EligibleFamilyDashboardComponent implements OnInit {
  evaluation: Evaluation = {} as Evaluation;
  todoList: any;

  child = {
    fullName: 'Roberto Schmidt',
    dateOfBirth: '12/01/2017',
    residentAea: 'Heartland',
  } as unknown as LearnerSummary;

  assessmentTeam = [
    {
      fullName: 'Pamela Isley',
      jobTitle: 'Regional Director',
      email: 'pisley@aea11.edu',
      aea: { name: 'Heartland' },
      district: 'Adair-Casey',
      primaryOffice: 'Adel Regional Office',
    },
    {
      fullName: 'Felicia Day',
      jobTitle: 'Special Education Consultant',
      email: 'fday@aea11.edu',
      aea: { name: 'Heartland' },
      district: 'Adair-Casey',
      primaryOffice: 'Adel Regional Office',
    },
    {
      fullName: 'Jensen Ackles',
      jobTitle: 'Early Childhood Special Education Consultant',
      email: 'jackles@aea11.edu',
      aea: { name: 'Heartland' },
      district: 'Adair-Casey',
      primaryOffice: 'Adel Regional Office',
    },
    {
      fullName: 'Misha Collins',
      jobTitle: 'Physical Therapist',
      email: 'mcollins@aea11.edu',
      aea: { name: 'Heartland' },
      district: 'Adair-Casey',
      primaryOffice: 'Adel Regional Office',
    },
  ];

  constructor() {}

  ngOnInit() {}

  onAddNewContactLog() {
    console.log('new contact log');
  }

  onAddNewConsent() {
    console.log('new consent');
  }
}
