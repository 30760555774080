<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>IFSP Outcome Criterion</h3>

<div mat-dialog-content class="pt-3 pb-5">
  <label>Criterion for Accomplishment:</label>
  <span *ngIf="!modificationId">
    {{ data.criterion.criteriaForAccomplishment || '-' }}
  </span>
  <app-compare-amendment-output
    *ngIf="modificationId"
    [amendmentId]="modificationId"
    [priorVersionId]="data.criterion?.priorVersionId"
    [isActive]="data.criterion?.isActive"
    [changedVersionText]="data.criterion.criteriaForAccomplishment"
    [currentVersionText]="data?.criterion?.priorVersion?.criteriaForAccomplishment"
    [currentPrefixText]="data.criterion?.priorVersion ? 'Current: ' : ''"
    changedPrefixText="Proposed: "
  ></app-compare-amendment-output>
  <br />
  <label>Measurement Type: </label>
  <span *ngIf="!modificationId">
    {{ getMeasurmentTypeText(data.criterion.measurementType) }}
  </span>
  <app-compare-amendment-output
    *ngIf="modificationId"
    [amendmentId]="modificationId"
    [priorVersionId]="data.criterion?.priorVersionId"
    [isActive]="data.criterion?.isActive"
    [changedVersionText]="getMeasurmentTypeText(data.criterion.measurementType)"
    [currentVersionText]="getMeasurmentTypeText(data?.criterion?.priorVersion?.measurementType)"
    [currentPrefixText]="data.criterion?.priorVersion ? 'Current: ' : ''"
    changedPrefixText="Proposed: "
  ></app-compare-amendment-output>
  <br />

  <div *ngIf="data.criterion.quantifiableData.trials.length === 0">
    <label>Baseline:</label>
    <span *ngIf="!modificationId">
      {{ data.criterion.quantifiableData.baseline === 0 ? 0 : data.criterion.quantifiableData.baseline || '-' }}
    </span>
    <app-compare-amendment-output
      *ngIf="modificationId"
      [amendmentId]="modificationId"
      [priorVersionId]="data.criterion?.priorVersionId"
      [isActive]="data.criterion?.isActive"
      [changedVersionText]="data.criterion.quantifiableData.baseline"
      [currentVersionText]="data?.criterion?.priorVersion?.quantifiableData.baseline"
      [currentPrefixText]="data.criterion?.priorVersion ? 'Current: ' : ''"
      changedPrefixText="Proposed: "
    ></app-compare-amendment-output>
    <br />
  </div>
  <div class="row" *ngIf="data.criterion.quantifiableData.trials.length > 0">
    <div class="col-md-12">
      <h3>Trials</h3>
      <div *ngFor="let trial of data.criterion.quantifiableData.trials">
        <strong>{{ trial.score === 0 ? 0 : trial.score || '-' }}: </strong>{{ (trial.date | dateFormat) || '-' }}<br />
      </div>
      <label>Baseline:</label>
      {{ data.criterion.quantifiableData.baseline || '-' }}
    </div>
  </div>
  <div class="row" *ngIf="milestones.length > 0 || scaleRatings.length > 0">
    <div class="col-md-6" *ngIf="milestones.length > 0">
      <h3>Milestones</h3>
      <ng-container *ngIf="milestones.length === 0">-</ng-container>
      <ng-container *ngIf="milestones.length > 0">
        <div *ngFor="let milestone of milestones">
          <label>Completion Date: </label>
          {{ (milestone.completionDate | dateFormat) || '-' }}<br />
          <label>Description / Notes: </label><br />
          {{ milestone.benchmarkObjectiveMilestone || '-' }}<br />
          <label>Value: </label>
          {{ milestone.value === 0 ? 0 : milestone.value || '-' }}
        </div>
      </ng-container>
    </div>
    <div class="col-md-6" *ngIf="scaleRatings.length > 0">
      <h3>Scales</h3>
      <div *ngFor="let scaleRating of scaleRatings">
        <label>Rating: </label>
        {{ scaleRating.rating === 0 ? 0 : scaleRating.rating || '-' }}<br />
        <label>Description: </label> {{ scaleRating.description || '-' }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <h3>Expected Performance</h3>
      <label>Target Value: </label>
      <span *ngIf="!modificationId">
        {{ data.criterion.quantifiableData.performanceMin === 0 ? 0 : data.criterion.quantifiableData.performanceMin || '-' }}
      </span>
      <app-compare-amendment-output
        *ngIf="modificationId"
        [amendmentId]="modificationId"
        [priorVersionId]="data.criterion?.priorVersionId"
        [isActive]="data.criterion?.isActive"
        [changedVersionText]="data.criterion.quantifiableData.performanceMin"
        [currentVersionText]="data.criterion?.priorVersion?.quantifiableData.performanceMin"
        [currentPrefixText]="data.criterion?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      ></app-compare-amendment-output>
    </div>
  </div>
</div>

<div mat-dialog-actions class="actions text-right">
  <button mat-raised-button aria-label="Delete Icon" color="primary" (click)="closeModal()" tabindex="0">Close</button>
</div>
