<mat-nav-list>
  <a
    mat-list-item
    *ngFor="let help of data.help"
    class="display-block"
    [disableRipple]="true"
    tabindex="0"
    role="link"
    attr.aria-label="{{ help.question }}"
  >
    <span mat-line class="heading mr-3" *ngIf="help.question">{{ help.question }}</span>

    <button mat-icon-button aria-label="Delete Icon" color="primary" class="close" (click)="onClose()" tabindex="0">
      <mat-icon>close</mat-icon>
    </button>
    <ng-container *ngIf="!data.isRaw">
      <span mat-line class="body" *ngIf="help.answer && checkIfArray(help.answer) === false">{{ help.answer }}</span>
      <ng-container *ngIf="checkIfArray(help.answer)">
        <div mat-line class="body">
          <ul class="my-0">
            <li *ngFor="let answer of help.answer">{{ answer }}</li>
          </ul>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="data.isRaw">
      <div [innerHTML]="help.answer"></div>
    </ng-container>
  </a>
</mat-nav-list>

<div class="action__row">
  <button mat-button aria-label="Delete Icon" (click)="onClose()" tabindex="0">Close</button>
</div>
