<ng-container [formGroup]="formGroup">
  <ul class="list mb-0" *ngIf="false">
    <li class="list__item"></li>
  </ul>

  <div class="row">
    <div class="col-md-12">
      <label
        class="vertical-label text-md display-block"
        [ngClass]="{
          asterisk_input: formGroup.get('changesToExpectation').hasError('required')
        }"
      >
        Changes to the Postsecondary Expectation at exit, if any
      </label>
      <app-textarea
        [maxLength]="stringSizes.extraLarge"
        [hideAsterisk]="true"
        formControlName="changesToExpectation"
        attr.aria-required="{{ formGroup.get('changesToExpectation').hasError('required') ? 'true' : 'false' }}"
      ></app-textarea>
    </div>

    <div class="col-md-12">
      <label
        class="vertical-label text-md display-block"
        [ngClass]="{
          asterisk_input: formGroup.get('describeFunctionalImpact').hasError('required')
        }"
        >Describe the functional impact of the disability as related to
        {{ type | titlecase }}
      </label>
      <app-textarea
        [maxLength]="stringSizes.extraLarge"
        [hideAsterisk]="true"
        formControlName="describeFunctionalImpact"
        attr.aria-required="{{ formGroup.get('describeFunctionalImpact').hasError('required') ? 'true' : 'false' }}"
      ></app-textarea>
    </div>

    <div class="col-md-12 mb-2">
      <label
        class="vertical-label text-md display-block"
        [ngClass]="{
          asterisk_input: formGroup.get('describeResponseToInstruction').hasError('required')
        }"
        >Describe {{ caseSummary?.learner.firstName | firstNamePossessive }} response to instruction and accommodations as related to
        {{ type | titlecase }}
      </label>
      <app-textarea
        [maxLength]="stringSizes.extraLarge"
        [hideAsterisk]="true"
        formControlName="describeResponseToInstruction"
        hint="Include type of assessment, date of administration and results"
        attr.aria-required="{{ formGroup.get('describeResponseToInstruction').hasError('required') ? 'true' : 'false' }}"
      ></app-textarea>
    </div>

    <div class="col-md-12 mb-2">
      <label
        class="vertical-label text-md display-block"
        [ngClass]="{
          asterisk_input: formGroup.get('describeCurrentLevelOfPerformance').hasError('required')
        }"
        >Describe {{ caseSummary?.learner.firstName | firstNamePossessive }} current levels of performance, as related to
        {{ type | titlecase }}
      </label>
      <app-textarea
        [maxLength]="stringSizes.extraLarge"
        [hideAsterisk]="true"
        formControlName="describeCurrentLevelOfPerformance"
        attr.aria-required="{{ formGroup.get('describeCurrentLevelOfPerformance').hasError('required') ? 'true' : 'false' }}"
      ></app-textarea>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <app-help-directional-text>
        <p class="my-2">
          When considering specific services and supports, consider the learner’s needs across multiple areas, such as Behavior supports,
          Assistive technology, Modifications, Communication, Accommodations, Specially designed instruction, Braille instruction, ESL
          services, Health, Speech and Language, Occupational therapy, Physical therapy, Accessibility devices, tools, and equipment,
          Transportation, etc.
        </p>
      </app-help-directional-text>
      <label
        class="vertical-label text-md display-block"
        [ngClass]="{
          asterisk_input: formGroup.get('servicesMostEffective').hasError('required')
        }"
        >What specific services and supports were most effective for
        {{ caseSummary?.learner.firstName }}
        in developing their current skills?
      </label>
      <app-textarea
        [maxLength]="stringSizes.extraLarge"
        [hideAsterisk]="true"
        [useInputGroup]="true"
        formControlName="servicesMostEffective"
        #servicesMostEffective
        attr.aria-required="{{ formGroup.get('servicesMostEffective').hasError('required') ? 'true' : 'false' }}"
      >
        <ng-container append>
          <app-sentence-starters-button
            [sentenceStarters]="skillsSentenceStarters"
            [isSuffix]="true"
            (selectedQuestion)="onSelectedQuestion($event, 'servicesMostEffective')"
          ></app-sentence-starters-button>
        </ng-container>
      </app-textarea>
      <!-- Insert Guiding Question -->
    </div>

    <div class="col-md-12">
      <label
        class="vertical-label text-md display-block"
        [ngClass]="{
          asterisk_input: formGroup.get('thoughtsPrepareLeave').hasError('required')
        }"
        >In {{ caseSummary?.learner.firstName | firstNamePossessive }} words, what are their thoughts, as they prepare to leave school,
        about their next steps related to their postsecondary expectation for {{ type | titlecase }}?
      </label>
      <app-textarea
        [maxLength]="stringSizes.extraLarge"
        [hideAsterisk]="true"
        formControlName="thoughtsPrepareLeave"
        [useInputGroup]="true"
        #thoughtsPrepareLeave
        attr.aria-required="{{ formGroup.get('thoughtsPrepareLeave').hasError('required') ? 'true' : 'false' }}"
      >
        <ng-container append>
          <app-sentence-starters-button
            [sentenceStarters]="thoughtSentenceStarters"
            [isSuffix]="true"
            (selectedQuestion)="onSelectedQuestion($event, 'thoughtsPrepareLeave')"
          ></app-sentence-starters-button>
        </ng-container>
      </app-textarea>
      <!-- Insert Guiding Question -->
    </div>

    <div class="col-md-12">
      <app-textarea
        [maxLength]="stringSizes.extraLarge"
        label="Ideas for {{ caseSummary?.learner.firstName | firstNamePossessive }} future steps in {{ type | titlecase }}"
        formControlName="ideaFutureSteps"
        [useInputGroup]="true"
        #ideaFutureSteps
      >
        <ng-container append>
          <app-sentence-starters-button
            [sentenceStarters]="ideasSentenceStarters"
            [isSuffix]="true"
            (selectedQuestion)="onSelectedQuestion($event, 'ideaFutureSteps')"
          ></app-sentence-starters-button> </ng-container
      ></app-textarea>
    </div>

    <div class="col-md-12" *ngIf="formGroup.controls?.workBasedExperience">
      <app-textarea
        [maxLength]="stringSizes.extraLarge"
        label="Describe {{ caseSummary?.learner?.firstName | firstNamePossessive }} work-based experiences."
        formControlName="workBasedExperience"
        hint="Include paid, unpaid, volunteer, and other experiences"
      ></app-textarea>
    </div>
  </div>
</ng-container>
