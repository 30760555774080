import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/auth/auth.service';
import {
  TeamMemberSearchModalComponent,
  TeamSearchModalData,
} from '../../modals/team-member-search-modal/team-member-search-modal.component';
import { UserTeamsCreateModalComponent } from '../../modals/user-teams-create-modal/user-teams-create-modal.component';
import { Team, TeamUser } from '../../models/team';
import { NotificationService } from '../../services/notification.service';
import { TeamService } from '../../services/team/team.service';
import { AreYouSureComponent } from '../are-you-sure-modal/are-you-sure.component';

@Component({
  selector: 'app-user-teams',
  templateUrl: './user-teams.component.html',
  styleUrls: ['./user-teams.component.scss'],
})
export class UserTeamsComponent implements OnInit, OnChanges {
  @Input() userId: string;
  teams: Array<Team>;
  selectedTeam: Team;

  dataSource = new MatTableDataSource<TeamUser>();
  displayedColumns: Array<string> = ['select', 'name', 'jobTitle', 'email', 'phone', 'aea', 'district', 'buildings'];

  constructor(
    private authService: AuthService,
    private teamService: TeamService,
    private dialog: MatDialog,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    if (!this.userId) {
      this.userId = this.authService.user.id;
    }
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userId) {
      this.userId = changes.userId.currentValue;
      this.loadData();
    }
  }

  loadData() {
    this.teamService.getTeams(this.userId).subscribe((res) => {
      this.teams = res;
    });
  }

  onTeamSelectionChange(team: Team) {
    this.selectedTeam = team;
    this.dataSource.data = this.selectedTeam.users;
  }

  onCreateTeam(team: Team) {
    this.teamService.createTeam(this.userId, team).subscribe((res) => {
      this.teams.push(res);
      this.notificationService.success(`${res.name} created!`);
      this.onTeamSelectionChange(res);
    });
  }

  openCreateTeamDialog() {
    const dialogRef = this.dialog.open(UserTeamsCreateModalComponent, {
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((teamName: string) => {
      if (teamName) {
        this.onCreateTeam({ name: teamName });
      }
    });
  }

  openTeamMemberSearchDialog() {
    const userIds = this.selectedTeam.users.map((tm) => tm.userId);

    const config = {
      userId: this.authService.user.id,
      isMeeting: false,
      userIds,
    };

    const dialogRef = this.dialog.open(TeamMemberSearchModalComponent, {
      width: '728px',
      data: {
        config,
      } as TeamSearchModalData,
    });

    dialogRef.afterClosed().subscribe((dialogRes) => {
      if (dialogRes && dialogRes.newUserIds && dialogRes.newUserIds.length > 0) {
        this.teamService.addTeamUser(this.userId, this.selectedTeam.id, dialogRes.newUserIds).subscribe((res) => {
          this.teams[this.teams.indexOf(this.selectedTeam)].users = res.users;
          this.dataSource.data = this.selectedTeam.users;
          this.notificationService.success('Saved');
        });
      }
    });
  }

  onDeleteTeamUser(teamUser: TeamUser, event: Event) {
    event.preventDefault();
    this.teamService.deleteTeamUser(this.userId, this.selectedTeam.id, teamUser).subscribe((res) => {
      this.teams[this.teams.indexOf(this.selectedTeam)].users = res.users;
      this.dataSource.data = this.selectedTeam.users;
      this.notificationService.success(`${teamUser.fullName} deleted!`);
    });
  }

  onDeleteTeam() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion: 'Clicking Yes will delete this team.',
      },
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.teamService.deleteTeam(this.userId, this.selectedTeam.id).subscribe((res) => {
          this.teams = this.teams.filter((x) => x.id !== this.selectedTeam.id);
          this.dataSource.data = [];
          this.notificationService.success(`${this.selectedTeam.name} deleted!`);
        });
      }
    });
  }

  onRenameTeam(team: Team) {
    const dialogRef = this.dialog.open(UserTeamsCreateModalComponent, {
      width: '500px',
      data: { rename: true },
    });

    dialogRef.afterClosed().subscribe((teamName: string) => {
      if (teamName) {
        team.name = teamName;
        this.teamService.updateTeam(this.userId, team).subscribe((res) => {
          this.notificationService.success('Saved');
        });
      }
    });
  }
}
