import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StickyNoteCreate, StickyNoteRead, StickyNoteUpdate } from './../../models/sticky-note';

@Injectable({
  providedIn: 'root',
})
export class StickyNoteService {
  baseUrl = 'api/sticky-notes';
  constructor(private http: HttpClient) {}

  getNotes(): Observable<StickyNoteRead[]> {
    return this.http.get<StickyNoteRead[]>(`${this.baseUrl}`);
  }

  createNote(note: string, caseId: string) {
    const noteToCreate: StickyNoteCreate = {
      note,
      caseId,
    };
    return this.http.post<StickyNoteRead[]>(`${this.baseUrl}`, noteToCreate);
  }

  updateNote(note: StickyNoteUpdate) {
    return this.http.put<StickyNoteRead>(`${this.baseUrl}/${note.id}`, note);
  }

  deleteNote(noteId: string) {
    return this.http.put<StickyNoteRead[]>(`${this.baseUrl}/delete/${noteId}`, null);
  }
}
