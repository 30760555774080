import { Help, HelpSection } from '../help';
import { InsuranceWaiverServices } from '../models/insurace';

export function getInsuranceDictionary(): Help[] {
  return [
    {
      title: HelpSection.Insurance,
      dictionary: [
        {
          label: InsuranceWaiverServices.WaiverServices,
          descriptor:
            'Home and Community-Based Services (HCBS) Waiver information can be found by going to https://dhs.iowa.gov/ime/members/medicaid-a-to-z/hcbs/waivers',
        },
      ],
    },
  ];
}
