import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ReferralService } from 'src/app/child-find/early-access-referral/early-access-referral.service';
import { ReferralSourceCategory } from 'src/app/child-find/early-access-referral/referral-source-category';
import {
  AddConsentDialogResult,
  FamilyConsentAddComponent,
} from 'src/app/consent/family-consent/modals/family-consent-add/family-consent-add.component';
import { TodoCreate } from 'src/app/dashboard/user-todo-list/todo-models';
import { TodoService } from 'src/app/dashboard/user-todo-list/todo.service';
import { IntakeSections } from 'src/app/evaluation/early-access-intake/intake-sections/intake-sections';
import { EarlyAccessQuestionnaire } from 'src/app/evaluation/models/early-access-questionnaire';
import { Intake } from 'src/app/evaluation/models/intake';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { StringSizes } from 'src/app/shared/components/form/constants/constants';
import {
  DialogData,
  UploadDocumentationModalComponent,
} from 'src/app/shared/modals/upload-documentation-modal/upload-documentation-modal.component';
import { ConsentForm, consentFormTitlesByType, ConsentFormType } from 'src/app/shared/models/fiie-consent/consent-form';
import { ConsentStatus } from 'src/app/shared/models/fiie-consent/consent-status';
import { FamilyConsentStatus } from 'src/app/shared/models/fiie-consent/family-consent-status';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { usStates } from 'src/app/shared/models/us-states';
import { ConsentFormService } from 'src/app/shared/services/consent-form/consent-form.service';
import { LocationService } from 'src/app/shared/services/location/location.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { phoneValidator } from 'src/app/shared/validators';
import { openPdfWindow } from 'src/app/shared/windowHelpers';
import { SignedFormService } from 'src/app/signed-forms/signed-form.service';
import { DeactivationService } from '../../../../../../shared/services/deactivation.service';
import { ProviderInfoFormService } from '../provider-info-form.service';
import { AgencyProgramRead } from './agency-program-read';
import { AgencyProgramUpdate } from './agency-program-update';

@Component({
  selector: 'app-agency-program-table',
  templateUrl: './agency-program-table.component.html',
  styleUrls: ['./agency-program-table.component.scss'],
})
export class AgencyProgramTableComponent extends BaseComponent implements OnInit, OnChanges {
  @ViewChild('agencyTable') agencyTable: MatTable<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('formDirective') private formDirective: NgForm;

  @Input() intake: Intake;
  @Input() questionnaire: EarlyAccessQuestionnaire;
  @Input() isQuestionnaire: boolean;
  @Input() locked: boolean;
  @Input() importQuestionnaireSelected: boolean;
  @Input() releaseAndExchangeConsents: ConsentForm[];
  @Output() documentUploaded = new EventEmitter();

  tableInfo: AgencyProgramRead[] = [];
  referralSourceCategories: ReferralSourceCategory[] = [];
  dataSource: MatTableDataSource<AgencyProgramRead>;
  submitAttempted: boolean;
  usStates = usStates.map((x) => new KeyValuePair(x, x));
  questAgenciesNotImported: AgencyProgramRead[];
  isEditing = false;
  stringSizes = StringSizes;

  get isValid() {
    return this.formGroup.valid;
  }

  get childFirstName() {
    return this.isQuestionnaire ? this.questionnaire?.childInfo?.firstName : this.intake?.childInfo?.firstName;
  }

  constructor(
    private fb: FormBuilder,
    private providerService: ProviderInfoFormService,
    private locationService: LocationService,
    private referralService: ReferralService,
    private dialog: MatDialog,
    private consentFormService: ConsentFormService,
    private readonly notificationService: NotificationService,
    private todoService: TodoService,
    private authService: AuthService,
    private reportingService: ReportingService,
    private signedFormService: SignedFormService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  displayedColumns = ['name', 'address', 'zipCode', 'city', 'state', 'contact', 'phoneNumber', 'faxNumber'];

  formGroup = this.fb.group({
    name: [null, [Validators.required]],
    address: [null],
    city: [null],
    state: [null],
    zipCode: [null, Validators.pattern(/(^\d{5}$)|(^\d{5}-\d{4}$)/)],
    contact: [null],
    phoneNumber: [null, phoneValidator],
    faxNumber: [null, phoneValidator],
  });

  private emptyRow = {
    id: null,
    learnerId: null,
    earlyAccessQuestionnaireId: null,
    name: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    contact: '',
    phoneNumber: '',
    faxNumber: '',
    agencyProgramDocument: null,
    receivedSignedConsentForm: false,
    receivedSignedConsentFormUser: '',
    receivedSignedConsentFormDate: null,
    recordsReceived: false,
    isEditing: false,
  };

  get learnersName() {
    return this.isQuestionnaire
      ? this.questionnaire?.childInfo?.firstName + ' ' + this.questionnaire?.childInfo?.lastName
      : this.intake?.childInfo?.firstName + ' ' + this.intake?.childInfo?.lastName;
  }

  ngOnInit() {
    if (!this.locked) {
      this.displayedColumns.unshift('actions');
    }
    this.referralService.getReferralSourceCategories(false).subscribe((cats) => {
      this.referralSourceCategories = cats;
      if (this.isQuestionnaire) {
        this.providerService.getQuestionnaireAgencyOrOtherPrograms(this.questionnaire?.id).subscribe((res) => {
          this.tableInfo = res;
          res.push(this.emptyRow);
          this.dataSource = new MatTableDataSource<AgencyProgramRead>(this.tableInfo);
        });
      } else {
        this.displayedColumns.push('recordsReceived');
        this.providerService.getIntakeAgencyOrOtherPrograms(this.intake?.learner.id).subscribe((res) => {
          this.tableInfo = res;
          res.push(this.emptyRow);
          this.dataSource = new MatTableDataSource<AgencyProgramRead>(this.tableInfo);
          this.getQuestionnaireAgenciesNotImported();
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.importQuestionnaireSelected?.currentValue !== changes.importQuestionnaireSelected?.previousValue &&
      this.importQuestionnaireSelected
    ) {
      // Cannot append questionnaire until it has patched the parent formgroup
      setTimeout(() => {
        this.appendQuestionnaireAgencyOrOtherPrograms();
      }, 1000);
    }
  }

  getConsentToReleaseLabel(specialist: AgencyProgramRead): string {
    return this.hasConsentToRelease(specialist)
      ? 'Download Consent to Release and Exchange Information'
      : 'Create Consent to Release and Exchange Information';
  }

  hasConsentToRelease(agency: AgencyProgramRead): boolean {
    const existing = this.findConsentToRelease(agency);
    return !!existing;
  }

  findConsentToRelease(agency: AgencyProgramRead) {
    const phcp = this.formGroup?.value;
    if (!phcp || !this.releaseAndExchangeConsents) return null;
    //Try to find a soft match for existing consent to release and exchange for the phcp.
    return this.consentFormService
      .findConsentsForAgency(this.releaseAndExchangeConsents, ConsentFormType.ReleaseAndExchangeInformation, {
        name: agency.name,
        contact: agency.contact,
      })
      .find((cf) => cf.signedFormId);
  }

  getOrCreateConsentToRelease(agency: AgencyProgramRead) {
    const rex = ConsentFormType.ReleaseAndExchangeInformation;

    const existing = this.findConsentToRelease(agency);
    if (existing) {
      this.viewSignedForm(existing.signedFormId);
      return;
    }

    const dialogRef: MatDialogRef<FamilyConsentAddComponent, AddConsentDialogResult> = this.dialog.open(FamilyConsentAddComponent, {
      width: '800px',
      data: {
        defaultValues: {
          type: { value: rex },
          agency: {
            name: agency.name,
            contact: agency.contact,
            phone: agency.phoneNumber,
            address: agency.address,
            fax: agency.faxNumber,
            city: agency.city,
            state: agency.state,
            zipCode: agency.zipCode,
          },
        },
        availConsentFormTypeOptions: [new KeyValuePair({ value: rex, caseId: this.intake.caseId }, consentFormTitlesByType[rex])],
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        const consentForm: ConsentForm = {
          caseId: this.intake.caseId,
          type: data.type.value,
          aeaId: data.aea?.aeaId,
          districtId: data.district?.districtId,
          agency: data.agency,
          statuses: [
            {
              status: data.status,
              dateReceived: data.dateReceived,
              dateSigned: data.dateSigned,
              signedBy: data.signedBy,
              documents: [],
              comments: data.notes,
            },
          ],
          createdOn: new Date(),
          isComplete: false,
        };
        this.consentFormService.createConsentForms(this.intake.caseId, consentForm).subscribe(async (consents) => {
          this.releaseAndExchangeConsents.push(...consents);
          const consent = consents[0];
          const signedFormId = consents && consents[0]?.signedFormId;
          if (signedFormId) {
            this.viewSignedForm(consent.signedFormId);
          }
        });
      }
    });
  }

  async viewSignedForm(signedFormId: string) {
    const pdf = await this.signedFormService.getSignedFormPdf(signedFormId);
    window.open(pdf.url);
  }

  getQuestionnaireAgenciesNotImported() {
    if (this.questionnaire?.id) {
      this.providerService.getQuestionnaireAgencyOrOtherPrograms(this.questionnaire?.id).subscribe((questionnaireRes) => {
        if (questionnaireRes.length > 0) {
          this.questAgenciesNotImported = questionnaireRes.filter(
            (program) =>
              !this.dataSource.data.some(
                (agency) =>
                  agency.name === program.name &&
                  agency.address === program.address &&
                  agency.city === program.city &&
                  agency.state === program.state &&
                  agency.zipCode === program.zipCode &&
                  agency.phoneNumber === program.phoneNumber &&
                  agency.faxNumber === program.faxNumber
              )
          );
        }
      });
    }
  }

  updateTouchedAndValidity() {
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();
  }

  addOrUpdate(_agency) {
    this.submitAttempted = true;
    this.updateTouchedAndValidity();
    if (this.isValid) {
      const newAgencyOrProgram = this.formGroup.value as AgencyProgramUpdate;
      newAgencyOrProgram.id = _agency.id;
      const observables = [];
      if (this.isQuestionnaire) {
        newAgencyOrProgram.earlyAccessQuestionnaireId = this.questionnaire.id;
        observables.push(
          this.providerService.addOrUpdateQuestionnaireAgencyOrOtherPrograms(newAgencyOrProgram).pipe(
            catchError(() => {
              console.log('Error');
              return of(undefined);
            })
          )
        );
      } else {
        newAgencyOrProgram.learnerId = this.intake.learner.id;
        observables.push(
          this.providerService.addOrUpdateIntakeAgencyOrOtherPrograms(newAgencyOrProgram).pipe(
            catchError(() => {
              console.log('Error');
              return of(undefined);
            })
          )
        );
      }
      this.resetData(observables, true);
      this.setEdit(_agency, false);
    }
  }

  setEdit(_agency: any, editing = true) {
    this.isEditing = editing;
    _agency.isEditing = editing;
    if (!editing) {
      this.formGroup.patchValue({});
      return;
    }
    this.formGroup.patchValue({
      id: _agency.id,
      name: _agency.name,
      address: _agency.address,
      city: _agency.city,
      state: _agency.state,
      zipCode: _agency.zipCode,
      contact: _agency.contact,
      phoneNumber: _agency.phoneNumber,
      faxNumber: _agency.faxNumber,
      agencyProgramDocument: _agency.agencyProgramDocument,
      receivedSignedConsentForm: _agency.receivedSignedConsentForm,
      receivedSignedConsentFormUser: _agency.receivedSignedConsentFormUser,
      receivedSignedConsentFormDate: _agency.receivedSignedConsentFormDate,
      recordsReceived: _agency.recordsReceived,
    });
  }

  removeRow(agency: AgencyProgramRead) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Clicking Yes will remove the other agency or program the family is involved with.',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        const observables = [];
        observables.push(
          this.providerService.deleteAgencyOrOtherPrograms(agency.id).pipe(
            catchError(() => {
              console.log('Error');
              return of(undefined);
            })
          )
        );
        this.resetData(observables, false);
      }
    });
  }

  resetData(observables, isAdding) {
    forkJoin(observables).subscribe(() => {
      if (this.isQuestionnaire) {
        this.providerService.getQuestionnaireAgencyOrOtherPrograms(this.questionnaire?.id).subscribe((res) => {
          res.push(this.emptyRow);
          this.dataSource.data = res;
          this.resetForm(isAdding);
        });
      } else {
        this.providerService.getIntakeAgencyOrOtherPrograms(this.intake?.learner.id).subscribe((intakeAgency) => {
          intakeAgency.push(this.emptyRow);
          this.dataSource.data = intakeAgency;
          this.resetForm(isAdding);
        });
      }
    });
  }

  resetForm(isAdding) {
    if (isAdding) {
      this.formGroup.reset();
    }
  }

  populateLocationFromZipCode() {
    const zipCodeControl = this.formGroup.get('zipCode');
    const zipCode = zipCodeControl.value;
    if (!zipCodeControl.valid) {
      return;
    }

    this.locationService.getLocationData(zipCode?.substring(0, 5)).subscribe(
      (res) => {
        if (res) {
          this.formGroup.patchValue({
            city: res.city,
            state: 'IA',
          });
        }
      },
      () => {
        this.formGroup.patchValue({
          city: null,
          state: null,
        });
      }
    );
  }

  appendQuestionnaireAgencyOrOtherPrograms() {
    if (this.questAgenciesNotImported) {
      const observables = [];
      this.questAgenciesNotImported.forEach((agencyProgram) => {
        agencyProgram.id = null; // So it doesn't update the existing questionnaire agency
        agencyProgram.learnerId = this.intake.learner.id;
        observables.push(
          this.providerService.addOrUpdateIntakeAgencyOrOtherPrograms(agencyProgram).pipe(
            catchError(() => {
              console.log('Error');
              return of(undefined);
            })
          )
        );
      });
      this.questAgenciesNotImported = null; // Resets import button
      this.resetData(observables, false);
    }
  }

  downloadIcdPhysicianLetter(agency: AgencyProgramRead) {
    if (!agency.agencyProgramDocument) {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        data: {
          question: 'Signed Consent Form',
          subQuestion: 'Have you received the signed consent form?',
        },
        width: '450px',
      });

      dialogRef.afterClosed().subscribe((returnedData) => {
        const answer = returnedData;
        if (answer) {
          // Update agency with records received answer
          const today = new Date();
          agency.receivedSignedConsentForm = answer;
          agency.receivedSignedConsentFormDate = today;
          agency.receivedSignedConsentFormUser = this.authService.user.id;
          agency.learnerId = this.intake.learner.id;
          this.providerService.addOrUpdateIntakeAgencyOrOtherPrograms(agency).subscribe(() => {
            // Add a to do list item to upload the consent form
            const reminder: TodoCreate = {
              userId: this.authService.user.id,
              caseId: this.intake.caseId,
              title: 'Upload Consent to Release and Exchange',
              showOnCalendar: true,
              dueDate: new Date(today.setDate(today.getDate() + 7)),
            };
            this.todoService.create(reminder).subscribe();
          });

          this.reportingService.createIcdPhysicianLetter(agency.id).subscribe({
            next: (documentId: string) => openPdfWindow(this.intake.learner.id, documentId),
            error: (err) =>
              this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
                this.notificationService.alert(err.error, "Couldn't open output")
              ),
          });
        }
      });
    }
  }

  indicateRecordsReceived(agency: AgencyProgramRead) {
    agency.recordsReceived = true;
    agency.learnerId = this.intake.learner.id;
    this.providerService.addOrUpdateIntakeAgencyOrOtherPrograms(agency).subscribe(
      () => {},
      (err) => console.error(err),
      () => this.agencyTable.renderRows()
    );
  }

  onOpenUpload(agency: AgencyProgramRead) {
    const dialogRef = this.dialog.open(UploadDocumentationModalComponent, {
      data: {
        fileName: 'Consent to Release and Exchange Information',
        type: ConsentFormType.ReleaseAndExchangeInformation,
        learnersName: this.learnersName,
      } as DialogData,
      width: '728px',
    });

    dialogRef.afterClosed().subscribe((result: Array<any>) => {
      if (result) {
        const formData = new FormData();
        result.forEach((element) => {
          formData.append('titles', element.title);
          formData.append('documents', element.file, element.file.name);
          formData.append('types', element.type);
          formData.append('sections', IntakeSections.ProviderInfo);
          formData.append('consents', 'true');
          formData.append('isPHCPs', 'false');
        });
        this.createConsent(formData, agency.id);
      }
    });
  }

  createConsent(formData: FormData, agencyId: string) {
    const consentForm = {} as ConsentForm;
    consentForm.type = ConsentFormType.ReleaseAndExchangeInformation;
    consentForm.caseId = this.intake?.caseId;
    this.consentFormService.createConsentForm(this.intake?.caseId, consentForm).subscribe((consentFormAdded) => {
      const consentStatus = {} as ConsentStatus;
      consentStatus.consentFormId = consentFormAdded.id;
      consentStatus.status = FamilyConsentStatus.Approved;
      consentStatus.dateSigned = new Date();
      this.consentFormService.addConsentFormStatus(this.intake?.caseId, consentStatus).subscribe();
      this.onUpload(formData, agencyId);
    });
  }

  onUpload(formData: FormData, agencyId: string) {
    const upload = () =>
      this.providerService.uploadAgencyDocumentation(formData, agencyId, this.intake?.learner.id).subscribe(
        () => {
          this.notificationService.success('Upload complete');

          // Update UI
          this.documentUploaded.emit();
          this.providerService.getIntakeAgencyOrOtherPrograms(this.intake?.learner.id).subscribe((res) => {
            res.push(this.emptyRow);
            this.dataSource.data = res;
            this.resetForm(false);
          });
        },
        (err) => {
          console.log(err);
        }
      );

    if (!!this.intake?.caseId) {
      upload();
    }
  }
}
