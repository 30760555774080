import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExpanderService } from '../../services/expander.service';

@Component({
  selector: 'app-global-expander',
  templateUrl: 'global-expander.component.html',
  styleUrls: ['global-expander.component.scss'],
})
export class GlobalExpanderComponent implements OnInit {
  public expandButtonText$: Observable<string>;
  public expandButtonIcon$: Observable<string>;

  constructor(private expanderService: ExpanderService) {}

  public ngOnInit(): void {
    this.expandButtonText$ = this.expanderService.expanded.pipe(map((expanded) => (expanded ? 'Collapse All' : 'Expand All')));

    this.expandButtonIcon$ = this.expanderService.expanded.pipe(map((expanded) => (expanded ? 'expand_less' : 'expand_more')));
  }

  public toggleAccordion(): void {
    this.expanderService.toggleAll();
  }
}
