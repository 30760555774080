<form [formGroup]="formGroup" class="mt-3" (ngSubmit)="onUpdateModel()" *ngIf="showForm">
  <label class="vertical-label position-fix" for="completionDate">
    Number:
    {{ getNumber() | number }}
  </label>
  <div class="row align-items-center flex-wrap">
    <div class="col">
      <app-date-picker label="Completion Date" controlName="completionDate" id="completionDate"></app-date-picker>
    </div>
    <div class="col">
      <app-text-field
        [isBankableField]="true"
        [bankableFieldId]="bankableFieldIds.outcomes.quantData.benchmarkObjectiveMilestone"
        label="Description / Notes"
        formControlName="benchmarkObjectiveMilestone"
      ></app-text-field>
    </div>
    <div class="col">
      <app-number-field label="Value" formControlName="value"></app-number-field>
      <mat-error *ngIf="formGroup.get('value').hasError('min')"> Value cannot be negative </mat-error>
    </div>

    <div class="col display-flex justify-content-end mb-2">
      <button mat-raised-button aria-label="Cancel Button" type="button" class="mr-2" (click)="onCancel()" tabindex="0">Cancel</button>
      <button mat-raised-button attr.aria-label="{{ this.model ? 'Update' : 'Add' }}" color="primary" type="submit" tabindex="0">
        {{ this.model ? 'Update' : 'Add' }}
      </button>
    </div>
  </div>
</form>

<ng-container *ngIf="milestones && dataSource.data?.length > 0">
  <!-- <hr class="input__divider"> -->
  <h4 class="text-primary mb-0 mt-2">Milestone</h4>
  <div class="table-overflow">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0 w-100">
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="text-right"></th>
        <td mat-cell *matCellDef="let element" class="action--position-fix">
          <button mat-icon-button aria-label="Quick Access Menu" [matMenuTriggerFor]="menu" color="primary" tabindex="0">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button mat-menu-item aria-label="Edit Icon" (click)="onEdit(element)" tabindex="0">Edit</button>
            <button mat-menu-item aria-label="Delete" tabindex="0">
              <span class="text-danger" (click)="onDelete(element)"> Delete </span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <ng-container matColumnDef="number">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Number</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="!modificationId">{{ element.number | number }}</span>
          <app-compare-amendment-output
            *ngIf="modificationId"
            [amendmentId]="modificationId"
            [priorVersionId]="element?.priorVersionId"
            [isActive]="element?.isActive"
            [changedVersionText]="element?.number"
            [currentVersionText]="element?.priorVersion?.number"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="completionDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Completion Date</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="!modificationId">{{ element.completionDate | dateFormat }}</span>
          <app-compare-amendment-output
            *ngIf="modificationId"
            [amendmentId]="modificationId"
            [priorVersionId]="element?.priorVersionId"
            [isActive]="element?.isActive"
            [changedVersionText]="getCompletionDateText(element?.completionDate)"
            [currentVersionText]="getCompletionDateText(element?.priorVersion?.completionDate)"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="value">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Value</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="!modificationId">{{ element.value === 0 ? '-' : element.value }}</span>
          <app-compare-amendment-output
            *ngIf="modificationId"
            [amendmentId]="modificationId"
            [priorVersionId]="element?.priorVersionId"
            [isActive]="element?.isActive"
            [changedVersionText]="element?.value"
            [currentVersionText]="element?.priorVersion?.value"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="benchmarkObjectiveMilestone">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Description / Notes</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="!modificationId">{{ element.benchmarkObjectiveMilestone }}</span>
          <app-compare-amendment-output
            *ngIf="modificationId"
            [amendmentId]="modificationId"
            [priorVersionId]="element?.priorVersionId"
            [isActive]="element?.isActive"
            [changedVersionText]="element?.benchmarkObjectiveMilestone"
            [currentVersionText]="element?.priorVersion?.benchmarkObjectiveMilestone"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <p *ngIf="!dataSource.data">No data yet...</p>
  </div></ng-container
>
