<div class="todo-list">
  <div class="todo-list__header">
    <h3 class="mb-0">My To-Do List</h3>

    <div>
      <button mat-raised-button aria-label="Refresh" color="primary" class="mr-2" (click)="getTodos()" [disabled]="loading" tabindex="0">
        Refresh
      </button>
      <button mat-raised-button aria-label="Add Button" color="primary" [disabled]="loading" (click)="addTodo()" tabindex="0">Add</button>
    </div>
  </div>

  <mat-card class="py-1" *ngIf="!loading">
    <div class="table-overflow app-table">
      <table matSort [dataSource]="dataSource" mat-table class="w-100">
        <ng-container matColumnDef="title">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>Item</th>
          <td mat-cell *matCellDef="let todo">
            <div class="display-flex align-items-center">
              {{ todo.title }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="learnerName">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>Learner Name</th>
          <td mat-cell *matCellDef="let todo">
            <a
              *ngIf="todo.learnerName"
              [routerLink]="['learner-management', todo.learnerId, 'learner-dashboard']"
              tabindex="0"
              role="link"
              aria-labelledby="Learner Name"
            >
              {{ todo.learnerName || '--' }}</a
            >
            <span *ngIf="!todo.learnerName">--</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="daysLeft">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>Days Left</th>
          <td mat-cell *matCellDef="let todo">
            <ng-container *ngIf="!todo.daysLeft || todo.daysLeft >= 0">
              {{ todo.daysLeft || '--' }}
            </ng-container>
            <ng-container *ngIf="todo.daysLeft < 0"> {{ todo.daysLeft * -1 }} day(s) overdue </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="dueDate">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>Due Date</th>
          <td mat-cell *matCellDef="let todo">
            {{ (todo.dueDate | dateFormat) || '--' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="quickActions">
          <th mat-header-cell *matHeaderCellDef class="text-right">Quick Actions</th>
          <td mat-cell *matCellDef="let todo" class="text-right">
            <button
              mat-flat-button
              aria-label="Actions"
              [matMenuTriggerFor]="menu"
              color="accent"
              *ngIf="todo.actions && todo.actions.length > 0 && canViewActions(todo)"
              tabindex="0"
            >
              Actions
            </button>

            <mat-menu #menu="matMenu">
              <button
                *ngFor="let action of todo.actions"
                attr.aria-label="{{ action.label }}"
                mat-menu-item
                (click)="action.action(todo)"
                tabindex="0"
              >
                {{ action.label }}
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No pending to-dos...</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          [hidden]="dataSource?.data.length !== 0"
          mat-footer-row
          *matFooterRowDef="dataSource?.data.length === 0 ? ['noResults'] : []"
          class="example-second-footer-row"
        ></tr>
      </table>
      <mat-paginator [dataSource]="dataSource"></mat-paginator>
    </div>
  </mat-card>
</div>
