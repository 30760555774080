import { CaseClosureReasonService } from './../../../services/case-closure-reason/case-closure-reason.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-close-case-dialog',
  templateUrl: './close-case-dialog.component.html',
  styleUrls: ['./close-case-dialog.component.scss'],
})
export class CloseCaseDialogComponent implements OnInit {
  formGroup = new FormGroup({
    caseClosureReason: new FormControl('', Validators.required),
  });

  caseClousureReasonOptions: KeyValuePair[] = [];

  get caseClosureReasonId() {
    return this.formGroup.get('caseClosureReason').value;
  }

  constructor(public dialogRef: MatDialogRef<any>, private readonly caseClosureReasonService: CaseClosureReasonService) {}

  ngOnInit() {
    this.caseClosureReasonService.get().subscribe((res) => {
      this.caseClousureReasonOptions = res
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((reason) => new KeyValuePair(reason.id, reason.label));
    });
  }

  dismiss() {
    this.dialogRef.close();
  }

  closeCase() {
    this.dialogRef.close({
      closeCase: true,
      caseClosureReasonId: this.caseClosureReasonId,
    });
  }
}
