import { ServiceActivityTypeQuestion } from 'src/app/iep/models/iep-service';

export interface ServiceActivityType {
  id: string;
  label: string;
  code: string;
  partBType: ServiceActivityTypeCategory;
  partCType: ServiceActivityTypeCategory;
  partCMedicaidBillable: boolean;
  partBMedicaidBillable: boolean;
  partBMedicaidBillableConditional: boolean;
  needOutcome: boolean;
  additionalQuestions: ServiceActivityTypeAdditionalQuestion[];
  classification: ServiceActivityTypeClassification;
  iepServiceOrder: number;
  iepActivityOrder: number;
  ifspServiceOrder: number;
  isServiceCoordination: boolean;
  isWeightedService: boolean;
}

export interface ServiceActivityTypeAdditionalQuestion {
  id: string;
  question: ServiceActivityTypeQuestion;
  serviceActivityTypeId: string;
}

export enum ServiceActivityTypeCategory {
  Activity = 'Activity',
  Service = 'Service',
}

export enum ServiceActivityTypeClassification {
  AEA = 'AEA',
  LEA = 'LEA',
  External = 'External',
}
