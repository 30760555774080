import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { forkJoin, Observable } from 'rxjs';
import { GeneralSurveyQuestion } from 'src/app/shared/models/general-survey/general-survey-question';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { IfspService } from 'src/app/ifsp/services/ifsp.service';
import { GeneralSurveyGeneralSurveyQuestion } from 'src/app/shared/models/general-survey/general-survey-general-survey-question';
import { GeneralSurvey } from 'src/app/shared/models/general-survey/general-survey';
import { GeneralSurveyAnswer } from 'src/app/shared/models/general-survey/general-survey-answer';
import { GeneralSurveyService } from 'src/app/shared/services/general-survey/general-survey.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { MatDialog } from '@angular/material/dialog';
import { DeactivationService } from '../../../../shared/services/deactivation.service';
import { Learner } from 'src/app/shared/models/learner';

type QuestionsByCategory = {
  categoryName: string;
  questions: GeneralSurveyQuestion[];
};
const trackByCategory = (index: number, item: QuestionsByCategory) => item.categoryName;

@Component({
  selector: 'app-ifsp-periodic-poll',
  templateUrl: './ifsp-periodic-poll.component.html',
  styleUrls: ['./ifsp-periodic-poll.component.scss'],
})
export class IfspPeriodicPollComponent extends BaseComponent implements OnInit, OnDestroy {
  trackByCategory = trackByCategory;
  ifspReviewId: string;
  caseId: string;
  reviewTitle = 'Early ACCESS Questionnaire';
  formGroup: FormGroup = new FormGroup({});
  generalSurvey: GeneralSurvey;
  learner: Learner;
  questions: GeneralSurveyQuestion[] = [];
  enabledQuestions: QuestionsByCategory[];
  validSurvey = false;
  hideSurvey = false;
  save = new Observable<boolean>((observer) => {
    this.cd.detectChanges();
    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };
    if (!this.formGroup.dirty || this.isSaving) {
      done();
      return;
    }
    this.isSaving = true;
    this.saveForm(false).subscribe(() => {
      done();
    });
  });

  yesNoOptions: KeyValuePair[] = [new KeyValuePair('Yes', 'Yes'), new KeyValuePair('No', 'No')];

  constructor(
    private route: ActivatedRoute,
    private ifspService: IfspService,
    private generalSurveyService: GeneralSurveyService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private readonly cd: ChangeDetectorRef,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.ifspReviewId = this.route.snapshot.paramMap.get('ifspReviewId');
    this.caseId = this.route.snapshot.paramMap.get('caseId');
    forkJoin([this.ifspService.getSurvey(this.ifspReviewId), this.ifspService.getLearnerFromIfspReviewId(this.ifspReviewId)]).subscribe(
      ([survey, learner]) => {
        this.generalSurvey = survey;
        this.learner = learner;
        if (!survey.generalSurveyGeneralSurveyQuestionDtos) {
          this.validSurvey = false;
          return;
        }
        this.generalSurvey.generalSurveyGeneralSurveyQuestionDtos.forEach((x) => {
          x.generalSurveyQuestionDto.question = x.generalSurveyQuestionDto.question.replace('{ChildName}', learner.firstName);
        });
        this.questions = this.generalSurvey.generalSurveyGeneralSurveyQuestionDtos
          .sort((left, right) => this.sortByOrder(left, right))
          .map((x) => x.generalSurveyQuestionDto);
        this.generalSurvey.generalSurveyGeneralSurveyQuestionDtos.forEach((question) => {
          if (question.generalSurveyQuestionDto.required) {
            this.formGroup.addControl(question.generalSurveyQuestionDto.id, new FormControl(null, [Validators.required]));
          } else {
            this.formGroup.addControl(question.generalSurveyQuestionDto.id, new FormControl(null));
          }
        });
        const dictionary = Object.assign(
          {},
          ...this.generalSurvey.generalSurveyGeneralSurveyQuestionDtos.map((x) => ({
            [x.generalSurveyQuestionDto.id]: x.generalSurveyAnswerDto.answer,
          }))
        );
        this.formGroup.patchValue(dictionary);
        this.enabledQuestions = this.getEnabledQuestions();
        this.validSurvey = true;
        if (this.generalSurvey.completedDateTime) {
          this.formGroup.disable();
        } else {
          this.formGroup.valueChanges.subscribe((x) => {
            this.enabledQuestions = this.getEnabledQuestions();
          });
          this.saveSubscription = this.formGroup.valueChanges;
          this.startAutosaving();
        }
      }
    );
  }

  sortByOrder(left: GeneralSurveyGeneralSurveyQuestion, right: GeneralSurveyGeneralSurveyQuestion): number {
    if (left.generalSurveyQuestionDto.order < right.generalSurveyQuestionDto.order) {
      return -1;
    }

    if (left.generalSurveyQuestionDto.order > right.generalSurveyQuestionDto.order) {
      return 1;
    }
    return 0;
  }

  saveForm(complete: boolean): Observable<void> {
    const answers: GeneralSurveyAnswer[] = [];
    Object.keys(this.formGroup.controls).forEach((key) => {
      this.generalSurvey.generalSurveyGeneralSurveyQuestionDtos.forEach((y) => {
        if (key?.toLocaleLowerCase() === y.generalSurveyQuestionDto.id.toLocaleLowerCase()) {
          answers.push({
            id: y.generalSurveyAnswerDto.id,
            answer: this.checkEnabled(y.generalSurveyQuestionDto) // clear answers for non enabled questions
              ? this.formGroup.controls[key].value
              : '',
          });
        }
      });
    });
    if (!complete) {
      return this.generalSurveyService.updateSurveyAnswers(this.generalSurvey.id, answers);
    }
    return this.generalSurveyService.submitSurvey(this.generalSurvey.id, answers);
  }

  getEnabledQuestions(): QuestionsByCategory[] {
    const enabledQuestions = [...this.questions.filter((x) => !x.enableCondition || (x.enableCondition && this.checkEnabled(x)))];

    return enabledQuestions.reduce((ret, eq) => {
      const last = ret.length > 0 ? ret[ret.length - 1] : null;
      if (last == null || last.categoryName !== eq.options?.categoryName) {
        ret.push({
          categoryName: eq.options?.categoryName,
          questions: [eq],
        });
      } else {
        last.questions.push(eq);
      }
      return ret;
    }, [] as QuestionsByCategory[]);
  }

  submit() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion: 'Are you sure you are ready to complete?\nOnce the responses are submitted, editing will no longer be permitted.',
      },
    });
    dialogRef.afterClosed().subscribe((ok) => {
      if (ok) {
        this.isSaving = true;
        this.saveForm(true).subscribe(() => {
          this.stopAutosaving();
          this.generalSurvey.completedDateTime = new Date().toISOString();
          this.formGroup.disable();
          this.closePropmpt('Thank you for completing the Questionnaire! You may close this window.');
        });
      }
    });
  }

  saveAndClose() {
    this.saveForm(false).subscribe(() => {
      this.closePropmpt('Thank you for participating in our questionnaire! You may close this window.');
    });
  }

  closePropmpt(message: string) {
    this.hideSurvey = true;
    this.notificationService.alert(message);
  }

  checkEnabled(question: GeneralSurveyQuestion): boolean {
    if (!question.enableCondition) {
      return true;
    }

    const conditionSplit = question.enableCondition.split('==');
    if (conditionSplit.length !== 2) {
      return true;
    }

    const cont = this.formGroup.get(conditionSplit[0].trim().toLocaleLowerCase());
    if (cont && cont.value && cont.value.toLocaleLowerCase() === conditionSplit[1].trim().toLocaleLowerCase()) {
      const child = this.formGroup.get(question.id);
      return true;
    }
  }

  submitDisabled() {
    if (this.generalSurvey.completedDateTime) {
      return true;
    }
    let disabled = false;
    Object.keys(this.formGroup.controls).forEach((questionId) => {
      const enabledQuestionIds = this.enabledQuestions.reduce(
        (ids, category) => ids.concat(category.questions.filter((q) => q.required).map((q) => q.id)),
        [] as string[]
      );
      if (enabledQuestionIds.find((x) => x === questionId) && !this.formGroup.get(questionId)?.value) {
        disabled = true;
      }
    });
    return disabled;
  }
}
