import { AuthService } from 'src/app/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-management-toggler',
  templateUrl: './user-management-toggler.component.html',
  styleUrls: ['./user-management-toggler.component.scss'],
})
export class UserManagementTogglerComponent implements OnInit {
  ivrsUser = false;

  constructor(private router: Router, public authService: AuthService) {}

  get isSuperAdmin() {
    return this.authService?.isSuperAdmin;
  }

  ngOnInit() {
    this.ivrsUser = this.router.url.toLowerCase().indexOf('/ivrs/') !== -1;
  }

  gotToAchieve = () => this.router.navigate(['users', 'list']);
  gotToIvrs = () => this.router.navigate(['ivrs', 'user-management']);
}
