import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AddOrUpdateQuantifiableDatum } from 'src/app/shared/components/quantifiable-data/quantifiable-data';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { LearnerPerformanceDocument, PKTest } from './learner-performance-edit/learner-performance-edit.component';
import { LearnerPerformance } from './learner-performance.component';

export interface AddOrUpdateLearnerPerformance {
  id?: string;
  evaluationId: string;
  domainIds: string[];
  documentIds: string[];
  quantifiableDatum: AddOrUpdateQuantifiableDatum;
}

@Injectable()
export class LearnerPerformanceService {
  addOrUpdate(lp) {
    if (lp.id) {
      return this.http.put(`${this.baseUrl(lp.evaluationId)}/${lp.id}`, lp);
    } else {
      return this.http.post(this.baseUrl(lp.evaluationId), lp);
    }
  }
  constructor(private http: HttpClient, private spinnerService: SpinnerService) {}

  getAll(evalId: string) {
    return this.http.get<LearnerPerformance[]>(this.baseUrl(evalId));
  }

  remove(lp: LearnerPerformance) {
    return this.http.put<void[]>(`${this.baseUrl(lp.evaluationId)}/delete/${lp.id}`, null);
  }

  addDocuments(documents: FormData, evalId: string) {
    this.spinnerService.incrementLoading();
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    return this.http
      .post<LearnerPerformanceDocument[]>(`${this.baseUrl(evalId)}/documents`, documents, this.getMultipartRequestHeaders())
      .pipe(tap(turnOffSpinner, turnOffSpinner));
  }

  deleteDocument(documentId: string, evalId: string) {
    this.spinnerService.incrementLoading();
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    return this.http
      .put(`${this.baseUrl(evalId)}/documents/${documentId}`, this.getMultipartRequestHeaders())
      .pipe(tap(turnOffSpinner, turnOffSpinner));
  }

  removeDocument(evalId: string, lpId: string, docId: string) {
    return this.http.put<LearnerPerformanceDocument[]>(`${this.baseUrl(evalId)}/${lpId}/documents/${docId}`, null);
  }

  getPKTests() {
    return this.http.get<PKTest[]>('api/evaluation/learner-performance/pktests');
  }

  updateLearnerPerformanceSummary(evaluationId: string, summary: string) {
    return this.http.put(`${this.baseUrl(evaluationId)}/summary`, {
      summary,
    });
  }

  private baseUrl(evalId) {
    return `api/evaluations/${evalId}/learner-performance`;
  }

  // TODO: move this to a shared helper
  protected getMultipartRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      Accept: 'application/json, text/plain, */*',
    });

    return { headers };
  }
}
