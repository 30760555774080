<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <app-date-picker label="Family Notification Date" controlName="familyDate"></app-date-picker>
    </div>
    <div class="col-md-12 col-lg-6">
      <app-autocomplete label="Family Notification Method" formControlName="familyMethod" [options]="[]"></app-autocomplete>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-6">
      <app-date-picker label="Learner Notification Date" controlName="learnerDate"></app-date-picker>
    </div>

    <div class="col-md-12 col-lg-6">
      <app-autocomplete label="Learner Notification Method" formControlName="learnerMethod" [options]="[]"></app-autocomplete>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 position-fix--col">
      <app-textarea label="Notes about transfer of rights conversations" formControlName="notes" [rows]="4"></app-textarea>
    </div>
  </div>
</form>
