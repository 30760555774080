import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogComingSoonComponent } from 'src/app/shared/components/coming-soon/coming-soon.component';
import { IntakeType } from '../../../shared/models/case';
import { MemoryStorageService } from '../../../shared/services/memory-storage/memory-storage.service';
import { NewWindowConfig, openPdfWindow, openNewWindow } from '../../../shared/windowHelpers';
import { Evaluation } from '../../models/evaluation';
import { EvaluationService } from '../../services/evaluation.service';

@Component({
  selector: 'app-evaluation-header',
  templateUrl: './evaluation-header.component.html',
  styleUrls: ['./evaluation-header.component.scss'],
})
export class EvaluationHeaderComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  loading = {
    eer: false,
  };

  @Input()
  closeButton = false;
  evaluation: Evaluation;
  isPartB: boolean;
  onOverviewPage: boolean;

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly activatedRoute: ActivatedRoute,
    private readonly storage: MemoryStorageService,
    private readonly evaluationService: EvaluationService
  ) {
    this.activatedRoute.url.subscribe(() => {
      this.onOverviewPage = router.url.substr(router.url.lastIndexOf('/') + 1, 8) === 'overview';
    });
  }

  ngOnInit(): void {
    this.evaluation = this.storage.getKey('currentEvaluation', true) as Evaluation;
    this.isPartB = this.evaluation.intakeType === IntakeType.PartB;
  }

  helpToggled(e: any) {
    this.dialog.open(DialogTemporaryHelpComponent, { disableClose: false });
  }

  onCloseCase() {
    this.dialog.open(DialogComingSoonComponent);
  }

  onViewEer() {
    if (this.evaluation.isFinalized) {
      openNewWindow(`api/documents/${this.evaluation.learner.id}/${this.evaluation.eerDocumentId}`);
    } else {
      this.loading.eer = true;
      this.evaluationService.draftEer(this.evaluation.id).subscribe((evaluation: Evaluation) => {
        this.evaluation = evaluation;
        openPdfWindow(evaluation.learner.id, evaluation.eerDocumentId);
        this.loading.eer = false;
      });
    }
  }

  getHeaderText(): string {
    if (this.evaluation?.intakeType === 'PartC') {
      return 'Evaluation and Assessment - ' + this.evaluation?.learner.fullName;
    }
    if (this.evaluation?.intakeType === 'PartB') {
      return 'Evaluation - ' + this.evaluation?.learner.fullName;
    }
  }

  onReviewDS() {
    const config: NewWindowConfig = {
      path: `child-find/disability-suspect/${this.evaluation.disabilitySuspectFormId}`,
      popup: true,
      width: '1300px',
    };
    openNewWindow(config);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

@Component({
  selector: 'app-dialog-temporary-help',
  template: `<h3>Help: Evaluation</h3>
    <hr />
    <p>Help content is pending for this feature.</p>
    <p>Click anywhere outside of this box to close.</p>`,
})
export class DialogTemporaryHelpComponent {
  constructor(public dialogRef: MatDialogRef<DialogTemporaryHelpComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
