import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GenericPermissionsGuard } from '../auth/auth.guard';
import { CanDeactivateGuard } from '../shared/guards/can-deactivate.guard';
import { ClpComponent } from './clp/clp.component';
import { EsyComponent } from './esy/esy.component';
import { EsyGoalsComponent } from './esy/steps/esy-goals/esy-goals.component';
import { EsyOverviewComponent } from './esy/steps/esy-overview/esy-overview.component';
import { EsyViewComponent } from './esy/steps/esy-view/esy-view.component';
import { IepFinalizeGuard } from './guards/iep-finalize.guard';
import { IepPlaafpComponent } from './iep-plaafp/iep-plaafp.component';
import { IepComponent } from './iep.component';
import { IepSurveyComponent } from './shared/iep-survey/iep-survey.component';
import { IepAdditionalInfoComponent } from './steps/iep-additional-info/iep-additional-info.component';
import { IepDetailsComponent } from './steps/iep-details/iep-details.component';
import { IepGoalsComponent } from './steps/iep-goals/iep-goals.component';
import { IepProfileComponent } from './steps/iep-profile/iep-profile.component';
import { IepSsaaSummaryComponent } from './steps/iep-services-supports-activities/components/iep-ssaa-summary/iep-ssaa-summary.component';
import { IepServicesSupportsActivitiesComponent } from './steps/iep-services-supports-activities/iep-services-supports-activities.component';

const routes: Routes = [
  {
    path: ':iepId',
    component: IepComponent,
    canActivate: [IepFinalizeGuard, GenericPermissionsGuard],
    data: {
      permission: 'EditIEP',
      additionalRequirement: false,
    },
    canActivateChild: [IepFinalizeGuard],
    children: [
      {
        path: 'plaafp',
        component: IepPlaafpComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'profile',
        component: IepProfileComponent,
      },
      {
        path: 'goals',
        component: IepGoalsComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'goals/service/:serviceId',
        component: IepGoalsComponent,
      },
      {
        path: 'services',
        component: IepServicesSupportsActivitiesComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'services/:section/:element',
        component: IepServicesSupportsActivitiesComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'services/summary',
        component: IepSsaaSummaryComponent,
      },
      {
        path: 'additional-info',
        component: IepAdditionalInfoComponent,
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
  {
    path: ':iepId/esy',
    component: EsyComponent,
    canActivate: [GenericPermissionsGuard],
    data: {
      permission: 'EditIEP',
      additionalRequirement: false,
    },
    children: [
      {
        path: 'overview',
        component: EsyOverviewComponent,
      },
      {
        path: 'goals',
        component: EsyGoalsComponent,
      },
      {
        path: 'goals/:goalId',
        component: EsyGoalsComponent,
      },
      {
        path: 'view',
        component: EsyViewComponent,
      },
    ],
  },
  {
    path: ':iepId/details',
    component: IepDetailsComponent,
    canActivate: [GenericPermissionsGuard],
    data: {
      permission: 'EditIEP',
      additionalRequirement: false,
    },
  },
  {
    path: ':iepId/clp',
    component: ClpComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [GenericPermissionsGuard],
    data: {
      permission: 'EditIEP',
      additionalRequirement: false,
    },
  },
  {
    path: ':iepId/survey',
    component: IepSurveyComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IepRoutingModule {}
