import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ProceduralSafeguardDto, ProceduralSafeguardsRead, ProvidedMethod } from './procedural-safeguards';
import { IntakeType } from '../../models/case';
import { Observable, pipe, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProceduralSafeguardsService {
  private proceduralSafeguardsUpdated = new Subject<void>();
  proceduralSafeguardsUpdated$ = this.proceduralSafeguardsUpdated.asObservable();

  constructor(private http: HttpClient) {}

  baseUrl = 'api/cases/';

  get(caseId: string) {
    return this.http.get<ProceduralSafeguardsRead[]>(this.getBaseUrl(caseId));
  }

  getByLearner(learnerId: string, intakeType: IntakeType): Observable<Array<ProceduralSafeguardsRead>> {
    const params = new HttpParams();
    params.set('intakeType', intakeType);
    return this.http.get<Array<ProceduralSafeguardsRead>>(`api/learners/${learnerId}/procedural-safeguards`, { params });
  }

  getProvidedMethods(caseId: string) {
    return this.http.get<ProvidedMethod[]>(`${this.baseUrl}${caseId}/procedural-safeguards/provided-methods`);
  }

  add(proceduralSafeguards: ProceduralSafeguardDto) {
    return this.http.post(this.getBaseUrl(proceduralSafeguards.caseId), proceduralSafeguards).pipe(
      tap(() => {
        this.proceduralSafeguardsUpdated.next();
      })
    );
  }

  update(proceduralSafeguards: ProceduralSafeguardDto) {
    return this.http.put(this.getBaseUrl(proceduralSafeguards.caseId), proceduralSafeguards).pipe(
      tap(() => {
        this.proceduralSafeguardsUpdated.next();
      })
    );
  }

  private getBaseUrl(caseId: string) {
    return `${this.baseUrl}${caseId}/procedural-safeguards`;
  }
}
