<div *ngIf="dataLoaded">
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Roll Call</h3>

  <div mat-dialog-content class="py-2">
    <ng-container *ngIf="learner">
      <app-dynamic-learner-header [learnerId]="caseSummary.learnerId" [keepCaseSummaryOnDestroy]="true"></app-dynamic-learner-header>
    </ng-container>

    <div *ngIf="meeting">
      <mat-card class="my-0 card--plain">
        <div class="display-flex align-items-center justify-content-between">
          <p class="my-0" *ngIf="meeting.dateAndTime">
            <strong>Date: </strong>
            {{ meeting?.dateAndTime | dateFormat }}
          </p>
          <p class="my-0" *ngIf="meeting.dateAndTime">
            <strong>Time: </strong>
            {{ meeting?.dateAndTime | timeFormat }}
          </p>
          <p class="my-0" *ngIf="meeting.location">
            <strong>Location: </strong>
            {{ meeting?.location }}
          </p>
        </div>
        <div class="display-flex align-items-center justify-content-between pt-2">
          <p class="my-0">
            <strong>Purpose of Meeting: </strong>
            {{ meeting.purposesOfMeeting.join(', ') }}
          </p>
        </div>
      </mat-card>
    </div>
    <div class="card-spacing-top">
      <h4 class="mt-0 mb-2 text-primary">Requested Participants:</h4>
      <mat-card class="my-0 card--plain">
        <table class="w-100" mat-table [dataSource]="participantControlsDataSource">
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let group">
              <ng-template
                [ngIf]="group.get('participant').value.fullName === null || group.get('participant').value.fullName?.length === 0"
              >
                <em>NA</em>
              </ng-template>
              <ng-template [ngIf]="group.get('participant').value.fullName !== null">
                {{ group.get('participant').value.fullName }}</ng-template
              >
            </td>
          </ng-container>

          <!-- Profession / Role / Discipline Column -->
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let group">
              <ng-template [ngIf]="group.get('participant').value.role === null || group.get('participant').value.role?.length === 0">
                <em>NA</em>
              </ng-template>
              <ng-template [ngIf]="group.get('participant').value.role !== null">
                {{ getFormattedRole(group.get('participant').value) }}
              </ng-template>
            </td>
          </ng-container>

          <!-- Agency Column -->
          <ng-container matColumnDef="agency">
            <th mat-header-cell *matHeaderCellDef>Agency</th>
            <td mat-cell *matCellDef="let group">
              <ng-template
                [ngIf]="
                  group.get('participant').value.role === 'Family' ||
                  group.get('participant').value.role === 'Family Friend' ||
                  group.get('participant').value.role === 'Family Advocate' ||
                  group.get('participant').value.agency === null
                "
              >
                <em>NA</em>
              </ng-template>
              <ng-template
                [ngIf]="
                  group.get('participant').value.role !== 'Family' &&
                  group.get('participant').value.role !== 'Family Friend' &&
                  group.get('participant').value.role !== 'Family Advocate' &&
                  group.get('participant').value.agency !== null
                "
              >
                {{ getFormattedAgency(group.get('participant').value.agency, group.get('participant').value.agencyOther) }}
              </ng-template>
            </td>
          </ng-container>

          <!-- Meeting Attendance -->
          <ng-container matColumnDef="attendance">
            <th mat-header-cell *matHeaderCellDef>Meeting Attendance</th>
            <td mat-cell *matCellDef="let control">
              <app-autocomplete
                [options]="attendanceOptions"
                [formControl]="control.get('attendance')"
                (selected)="onAttendanceChange($event, control.get('participant').value.id, null)"
              ></app-autocomplete>
              <app-text-field
                *ngIf="showAttendanceOther(control.get('attendance').value)"
                label="Other"
                [formControl]="control.get('attendanceOther')"
                (change)="onAttendanceChange(control.get('attendanceOther').value, control.get('participant').value.id, true)"
              >
              </app-text-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <p *ngIf="!participantControlsDataSource">No data yet...</p>
        <form
          *ngIf="authService.isAllowedByCaseId(caseSummary.id, null, permissions.AddParticipants) && !isReadOnly"
          #addParticipantFormDirective="ngForm"
          [formGroup]="addParticipantFormGroup"
          (ngSubmit)="addNewParticipant()"
        >
          <div class="mt-1 row align-items-center">
            <div class="col-lg-4 col-md-12">
              <app-text-field label="Name" formControlName="name" (keydown)="$event.stopPropagation()"></app-text-field>
            </div>
            <div class="col-lg-4 col-md-12">
              <app-autocomplete class="input--large" [options]="professionOptions" formControlName="role" label="Role"></app-autocomplete>
            </div>
            <div class="col-lg-4 col-md-12" *ngIf="addParticipantFormGroup.value.role === 'Other'">
              <app-text-field label="Other" formControlName="roleOther" (keydown)="$event.stopPropagation()"></app-text-field>
            </div>
            <div class="col-lg-4 col-md-12">
              <app-autocomplete [options]="agencyOptions" formControlName="agency" label="Agency"></app-autocomplete>
            </div>
            <div class="col-lg-4 col-md-12" *ngIf="addParticipantFormGroup.value.agency === 'Other'">
              <app-text-field label="Other" formControlName="agencyOther" (keydown)="$event.stopPropagation()"></app-text-field>
            </div>

            <div class="col display-flex justify-self-end">
              <button mat-raised-button aria-label="Add Icon" color="accent" [disabled]="!addParticipantFormGroup.valid" tabindex="0">
                Save New Participant
              </button>
            </div>
          </div>
        </form>
      </mat-card>
    </div>
  </div>

  <mat-dialog-actions>
    <div class="display-flex flex-1 justify-content-end align-items-center">
      <button class="mr-2" mat-raised-button aria-label="Cancel Button" (click)="onCancel()" tabindex="0">Cancel</button>
      <!-- TODO: Temporary workaround until Excusal from Roll Call Modal is fixed -->
      <button
        [disabled]="isReadOnly"
        *ngIf="excusalNeeded && !isReadOnly"
        mat-raised-button
        aria-label="Print Agreement to Excuse"
        (click)="printAgreement()"
        class="mr-2"
        tabindex="0"
      >
        Print Agreement to Excuse
      </button>
      <!--  <button
        [disabled]="isReadOnly"
        *ngIf="printExcusalSelected && !isReadOnly"
        mat-raised-button
        aria-label="Upload Agreement to Excuse"
        (click)="uploadAgreement()"
        class="mr-2"
        tabindex="0"
      >
        Upload Agreement to Excuse
      </button>
      <button
        [disabled]="isReadOnly"
        *ngIf="excusalNeeded && !isReadOnly"
        mat-raised-button
        aria-label="Sign Agreement to Excuse"
        (click)="signAgreement()"
        class="mr-2"
        tabindex="0"
      >
        Sign Agreement to Excuse
      </button> -->
      <button
        [disabled]="isReadOnly"
        mat-raised-button
        aria-label="Reschedule Event"
        class="mr-1"
        color="primary"
        (click)="reschedule()"
        *ngIf="authService.isAllowed(permissions.RescheduleMeeting) && excusalNeeded && !isReadOnly"
        tabindex="0"
      >
        Reschedule Event
      </button>
      <!--
      hiding this for now
      <button
        *ngIf="caseSummary.learner.isPK"
        class="ml-1"
        mat-raised-button
        aria-label="Add Early Childhood Program Contact"
        (click)="openEcpDialog()"
        color="primary"
        type="button"
        tabindex="0"
      >
        Add Early Childhood Program Contact
      </button>-->
      <button
        *ngIf="!isReadOnly && canAddParticipants"
        [disabled]="isReadOnly || availableCount === 0"
        class="mr-2"
        mat-raised-button
        aria-label="Search / Add New Participant"
        (click)="onAddExistingParticipants()"
        color="accent"
        type="button"
        tabindex="0"
        [isBusy]="loadingUsers"
      >
        Search / Add New Participant
      </button>
      <button
        *ngIf="!isReadOnly"
        cdkFocusInitial
        mat-raised-button
        aria-label="Complete"
        color="primary"
        [disabled]="!participantFormGroups.valid || isReadOnly"
        (click)="onSubmit(true)"
        tabindex="0"
      >
        Complete
      </button>
    </div>
  </mat-dialog-actions>
</div>
