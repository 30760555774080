import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { EcoArea } from 'src/app/evaluation/models/evaluation';
import { IfspServiceSummaryDto } from 'src/app/ifsp/models/ifsp-service-models';
import { OutcomeDto } from 'src/app/ifsp/models/outcome-models';
import { IfspServicesService } from 'src/app/ifsp/services/ifsp-service.service';
import { OutcomeService } from 'src/app/ifsp/services/outcome.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { FamilyArea } from 'src/app/shared/models/family-area';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { EcoAreaService } from 'src/app/shared/services/eco-areas/eco-area.service';
import { FamilyAreaService } from 'src/app/shared/services/family-areas/family-areas.service';
import { LearnerSummary } from '../../../../../shared/models/learner';

@Component({
  selector: 'app-ifsp-outcomes-services-print',
  templateUrl: './ifsp-outcomes-services-print.component.html',
  styleUrls: ['./ifsp-outcomes-services-print.component.scss'],
})
export class IfspOutcomesServicesPrintComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  ifspId: string;
  learnerId: string;
  caseId: string;
  isPreview: boolean;
  learner: LearnerSummary;
  currentDate = new Date(Date.now());
  outcomes: OutcomeDto[];
  services: IfspServiceSummaryDto[];

  ecoAreas: EcoArea[];
  familyAreas: FamilyArea[];

  shortDateFormat = shortDateFormat;

  constructor(
    private outcomeService: OutcomeService,
    private ifspServicesService: IfspServicesService,
    private ecoAreaService: EcoAreaService,
    private familyAreaService: FamilyAreaService,
    private caseService: CaseService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.ifspId = this.route.snapshot.parent.paramMap.get('ifspId');
    this.caseId = this.route.snapshot.parent.paramMap.get('caseId');
    this.isPreview = !!this.route.snapshot.queryParamMap.get('preview');

    this.getLearner();
    this.getOutcomes();
    this.getServices();

    forkJoin([this.ecoAreaService.get(), this.familyAreaService.get()]).subscribe(([ecoAreas, familyAreas]) => {
      this.ecoAreas = ecoAreas;
      this.familyAreas = familyAreas;
    });
  }

  getLearner() {
    this.subscriptions.add(this.caseService.getCaseSummary(this.caseId).subscribe((res) => (this.learner = res.learner)));
  }

  getOutcomes() {
    this.subscriptions.add(this.outcomeService.getOutcomes(this.ifspId).subscribe((res) => (this.outcomes = res)));
  }

  getServices() {
    this.subscriptions.add(
      this.ifspServicesService.getServices(this.ifspId).subscribe((res) => (this.services = res.filter((s) => s.familyDeclined !== true)))
    );
  }

  getEcoAreaValue(value) {
    const ecoArea = this.ecoAreas.find((x) => x.id === value);
    return ecoArea ? ecoArea.label : null;
  }

  getFamilyAreasValue(value) {
    const familyArea = this.familyAreas.find((x) => x.id === value);
    return familyArea ? familyArea.label : null;
  }

  onClose() {
    window.close();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
