import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { downloadBlob } from 'src/app/shared/downloadHelpers';
import { LookupBase } from 'src/app/shared/models/lookup-base';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SurveyReportingService } from 'src/app/surveys/services/survey-reporting.service';
import { SurveyTypes } from '../../models/survey-types';

@Component({
  selector: 'app-report-export',
  templateUrl: './report-export.component.html',
  styleUrls: ['./report-export.component.scss'],
})
export class ReportExportComponent implements OnInit {
  surveyTypes = SurveyTypes;
  surveyType: string;
  aeas: LookupBase[] = [];
  leas: LookupBase[] = [];
  buildings: LookupBase[] = [];
  sources: LookupBase[] = [];
  schoolYears: LookupBase[] = [];
  races: LookupBase[] = [];
  genders: LookupBase[] = [];
  ages: LookupBase[] = [];

  public aeaFilterControl: FormControl = new FormControl();
  public filteredAeas: ReplaySubject<LookupBase[]> = new ReplaySubject(1);
  public leaFilterControl: FormControl = new FormControl();
  public filteredLeas: ReplaySubject<LookupBase[]> = new ReplaySubject(1);
  public buildingFilterControl: FormControl = new FormControl();
  public filteredBuildings: ReplaySubject<LookupBase[]> = new ReplaySubject(1);
  public schoolYearsFilterControl: FormControl = new FormControl();
  public filteredSchoolYears: ReplaySubject<LookupBase[]> = new ReplaySubject(1);
  public sourcesFilterControl: FormControl = new FormControl();
  public filteredSources: ReplaySubject<LookupBase[]> = new ReplaySubject(1);
  public racesFilterControl: FormControl = new FormControl();
  public filteredRaces: ReplaySubject<LookupBase[]> = new ReplaySubject(1);
  public gendersFilterControl: FormControl = new FormControl();
  public filteredGenders: ReplaySubject<LookupBase[]> = new ReplaySubject(1);
  public agesFilterControl: FormControl = new FormControl();
  public filteredAges: ReplaySubject<LookupBase[]> = new ReplaySubject(1);

  isAeaUser = false;

  formGroup = this.fb.group({
    outputType: ['csv', Validators.required],
    aeaIds: '',
    leaIds: '',
    buildingIds: '',
    schoolYearId: '00000000-0000-0000-0000-000000000000',
    sourceIds: '',
    raceIds: '',
    genderIds: '',
    ages: '',
  });

  outputTypes = ['csv', 'excel'];
  reportType: string;
  activeCall = false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly surveyReportingService: SurveyReportingService,
    private readonly notifyService: NotificationService
  ) {
    this.surveyType = data.surveyType;
    this.aeas = data.aeas;
    this.leas = data.leas;
    this.buildings = data.buildings;
    this.isAeaUser = data.isAeaUser;
    this.sources = data.sources;
    this.schoolYears = data.schoolYears;
    this.races = data.races;
    this.genders = data.genders;
    this.ages = data.ages;
    this.reportType = data.reportType;
  }

  ngOnInit(): void {
    this.filteredAeas.next(this.aeas.slice());
    this.filteredLeas.next(this.leas.slice());
    this.filteredBuildings.next(this.buildings.slice());
    this.filteredSchoolYears.next(this.schoolYears.slice());
    this.filteredSources.next(this.sources.slice());
    this.filteredRaces.next(this.races.slice());
    this.filteredGenders.next(this.genders.slice());
    this.filteredAges.next(this.ages.slice());

    this.aeaFilterControl.valueChanges.subscribe(() => {
      this.filterAeas();
    });

    this.leaFilterControl.valueChanges.subscribe(() => {
      this.filterLeas();
    });

    this.buildingFilterControl.valueChanges.subscribe(() => {
      this.filterBuildings();
    });

    this.schoolYearsFilterControl.valueChanges.subscribe(() => {
      this.filterSchoolYears();
    });

    this.sourcesFilterControl.valueChanges.subscribe(() => {
      this.filterSources();
    });

    this.racesFilterControl.valueChanges.subscribe(() => {
      this.filterRaces();
    });

    this.gendersFilterControl.valueChanges.subscribe(() => {
      this.filterGenders();
    });

    this.agesFilterControl.valueChanges.subscribe(() => {
      this.filterAges();
    });
  }

  onExport(filters) {
    if (this.surveyType === this.surveyTypes.EarlyAccessFamilyEngagementSurvey) {
      if (this.reportType === 'summary') {
        this.surveyReportingService.getEASummaryReport(filters).subscribe({
          next: (blob) => this.downloadReport(blob, filters, 'Early ACCESS Family Engagement Responses'),
          error: (err) => this.getReportError(err),
        });
      }

      if (this.reportType === 'details') {
        this.surveyReportingService.getEADetailReport(filters).subscribe({
          next: (blob) => this.downloadReport(blob, filters, 'Early ACCESS Family Engagement Responses'),
          error: (err) => this.getReportError(err),
        });
      }

      if (this.reportType === 'raw') {
        this.surveyReportingService.getEARawReport(filters).subscribe({
          next: (blob) => this.downloadReport(blob, filters, 'EA Family Engagement Raw Data'),
          error: (err) => this.getReportError(err),
        });
      }
    }

    if (this.surveyType === this.surveyTypes.SpecialEducationFamilyEngagementSurvey) {
      if (this.reportType === 'summary') {
        this.surveyReportingService.getSESummaryReport(filters).subscribe({
          next: (blob) => this.downloadReport(blob, filters, 'Special Education Family Engagement Responses'),
          error: (err) => this.getReportError(err),
        });
      }

      if (this.reportType === 'details') {
        this.surveyReportingService.getSEDetailReport(filters).subscribe({
          next: (blob) => this.downloadReport(blob, filters, 'Special Education Family Engagement Responses'),
          error: (err) => this.getReportError(err),
        });
      }

      if (this.reportType === 'raw') {
        this.surveyReportingService.getSERawReport(filters).subscribe({
          next: (blob) => this.downloadReport(blob, filters, 'SE Family Engagement Raw Data'),
          error: (err) => this.getReportError(err),
        });
      }
    }
  }

  private downloadReport(blob, filters, title) {
    downloadBlob(blob as Blob, `${title}-${this.getReportDate()}.${filters.outputType === 'csv' ? 'csv' : 'xlsx'}`);
    this.activeCall = false;
    this.notifyService.success('Success');
    this.dialogRef.close();
  }

  private getReportError(err) {
    console.log(err);
    this.activeCall = false;
    this.notifyService.error('Error: Please try again');
  }

  private getReportDate() {
    const today = new Date();
    return `${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`;
  }

  private filterAeas() {
    if (!this.aeas) {
      return;
    }

    let search = this.aeaFilterControl.value;
    if (!search) {
      this.filteredAeas.next(this.aeas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredAeas.next(this.aeas.filter((x) => x.label.toLowerCase().indexOf(search) > -1));
  }

  private filterLeas() {
    if (!this.leas) {
      return;
    }

    let search = this.leaFilterControl.value;
    if (!search) {
      this.filteredLeas.next(this.leas.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredLeas.next(this.leas.filter((x) => x.label.toLowerCase().indexOf(search) > -1));
  }

  private filterBuildings() {
    if (!this.buildings) {
      return;
    }

    let search = this.buildingFilterControl.value;
    if (!search) {
      this.filteredBuildings.next(this.buildings.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredBuildings.next(this.buildings.filter((x) => x.label.toLowerCase().indexOf(search) > -1));
  }

  private filterSchoolYears() {
    if (!this.schoolYears) {
      return;
    }

    let search = this.schoolYearsFilterControl.value;
    if (!search) {
      this.filteredSchoolYears.next(this.schoolYears.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredSchoolYears.next(this.schoolYears.filter((x) => x.label.toLowerCase().indexOf(search) > -1));
  }

  private filterSources() {
    if (!this.sources) {
      return;
    }

    let search = this.sourcesFilterControl.value;
    if (!search) {
      this.filteredSources.next(this.sources.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredSources.next(this.sources.filter((x) => x.label.toLowerCase().indexOf(search) > -1));
  }

  private filterRaces() {
    if (!this.races) {
      return;
    }

    let search = this.racesFilterControl.value;
    if (!search) {
      this.filteredRaces.next(this.races.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredRaces.next(this.races.filter((x) => x.label.toLowerCase().indexOf(search) > -1));
  }

  private filterGenders() {
    if (!this.genders) {
      return;
    }

    let search = this.gendersFilterControl.value;
    if (!search) {
      this.filteredGenders.next(this.genders.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredGenders.next(this.genders.filter((x) => x.label.toLowerCase().indexOf(search) > -1));
  }

  private filterAges() {
    if (!this.ages) {
      return;
    }

    let search = this.agesFilterControl.value;
    if (!search) {
      this.filteredAges.next(this.ages.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredAges.next(this.ages.filter((x) => x.label.toLowerCase().indexOf(search) > -1));
  }
}
