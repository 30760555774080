import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-iep-ssaa-summary-modal',
  templateUrl: './iep-ssaa-summary-modal.component.html',
  styleUrls: ['./iep-ssaa-summary-modal.component.scss'],
})
export class IepSsaaSummaryModalComponent implements OnInit {
  caseId: string;
  iepId: string;
  isPK = false;

  constructor(
    public dialogRef: MatDialogRef<IepSsaaSummaryModalComponent>,
    public route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.iepId = this.data.iepId;
    this.caseId = this.data.caseId;
    this.isPK = this.data.isPK;
  }
}
