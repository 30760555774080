import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SurveySchedulingService {
  constructor(private http: HttpClient) {}

  getSpecialEducationScheduledJobs(): Observable<any> {
    return this.http.get('api/survey-schedules/get-scheduled-survey-jobs');
  }
  upsertSpecialEducationScheduledJobs(emailSettings): Observable<any> {
    return this.http.post('api/survey-schedules/upsert-scheduled-job', emailSettings);
  }
}
