import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-evaluation-method',
  templateUrl: './evaluation-method.component.html',
  styleUrls: ['./evaluation-method.component.scss'],
})
export class EvaluationMethodComponent implements OnInit, OnChanges {
  @Input() isEditing = false;
  @Input() isReview: boolean;
  @Output() reviewToggled: EventEmitter<boolean> = new EventEmitter();
  @Input() isInterview = false;
  @Output() interviewToggled: EventEmitter<boolean> = new EventEmitter();
  @Input() isObservation = false;
  @Output() observationToggled: EventEmitter<boolean> = new EventEmitter();
  @Input() isTest = false;
  @Output() testToggled: EventEmitter<boolean> = new EventEmitter();
  @Output() resetDeleteMethod = new EventEmitter();
  @Input() isFba = false;
  reviewDisabled = false;
  interviewDisabled = false;
  observationDisabled = false;
  testDisabled = false;

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {}

  ngOnChanges(changes) {
    if (changes.isEditing?.currentValue) {
      this.reviewDisabled = changes.isReview?.currentValue;
      this.interviewDisabled = changes.isInterview?.currentValue;
      this.observationDisabled = changes.isObservation?.currentValue;
      this.testDisabled = changes.isTest?.currentValue;
    }
    if (changes.isEditing?.currentValue === false) {
      this.reviewDisabled = false;
      this.interviewDisabled = false;
      this.observationDisabled = false;
      this.testDisabled = false;
    }
  }

  toggleMethod(value) {
    if (value) {
      this.notificationService.error('Method cannot be removed');
    }
  }
}
