<ng-container [formGroup]="formGroup">
  <app-autocomplete-chips-groups
    *ngIf="groupedPurposeOptions?.length > 0"
    [options]="groupedPurposeOptions"
    (selected)="onPurposeSelected($event)"
    formControlName="purposes"
    label="Purpose of the Meeting"
  ></app-autocomplete-chips-groups>
  <app-autocomplete-chips
    *ngIf="purposeOptions?.length > 0"
    [options]="purposeOptions"
    formControlName="purposes"
    label="Purpose of the Meeting"
  ></app-autocomplete-chips>
</ng-container>
