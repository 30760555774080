import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { DatePipe } from '@angular/common';
import { BooleanYesNoPipe } from '../../../shared/pipes/boolean-yesno.pipe';
import { CaseService } from '../../../shared/services/case/case.service';

@Component({
  selector: 'app-iep-services-view-more',
  templateUrl: './iep-services-view-more.component.html',
  styleUrls: ['./iep-services-view-more.component.scss'],
  providers: [DatePipe, BooleanYesNoPipe],
})
export class IepServicesViewMoreComponent implements OnInit {
  service: any;
  shortDateFormat = shortDateFormat;

  get showServiceType() {
    return this.caseService.caseSummary?.activeIepIsPKToK;
  }

  constructor(
    private caseService: CaseService,
    private yesNoPipe: BooleanYesNoPipe,
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<IepServicesViewMoreComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.service = this.data.service;
  }

  getProviderString(providers, otherProvider?: string) {
    let returnString = '';
    if (providers) {
      let providerCount = 0;
      providers.forEach((provider) => {
        providerCount++;
        let name = provider.firstName;
        if (provider.lastName) {
          name = name + ' ' + provider.lastName;
        }
        returnString += name;
        if (providers.length !== providerCount) {
          returnString += ', ';
        }
      });
    }

    if (otherProvider) {
      return returnString ? returnString + `, ${otherProvider}` : `${otherProvider}`;
    }
    return returnString;
  }

  getServiceCodes(serviceCodeArr) {
    return serviceCodeArr.map((x) => x.code).join(', ');
  }

  getFrequencyText(element) {
    let returnText = '';
    if (element) {
      returnText += element.frequencyNumber
        ? element.frequencyNumber + ' ' + element.frequencyIncrement + ' ' + element.frequencyPeriod
        : '';
    }

    return returnText;
  }

  getServiceType(serviceType) {
    if (serviceType?.partBType === 'Service' && serviceType?.partCType === 'Service') {
      return 'Both';
    } else {
      if (serviceType?.partBType === 'Service') {
        return 'K';
      }
      if (serviceType?.partCType === 'Service') {
        return 'PK';
      }
      return 'Unknown';
    }
  }

  convertToShortDate(date: Date) {
    return this.datePipe.transform(date, shortDateFormat);
  }

  getOtherInformationText(service: any) {
    const otherInfo = [];

    if (service) {
      otherInfo.push('Start Date - ' + this.convertToShortDate(service.projectedStartDate));
      otherInfo.push('End Date - ' + this.convertToShortDate(service.projectedEndDate));

      if (service.instructionalSupportType) {
        otherInfo.push('Instructional Support Type - ' + service.instructionalSupportType);
      }
      if (service.hasBehaviorInterventionPlan !== null) {
        otherInfo.push('Has Behavior Intervention Plan - ' + this.yesNoPipe.transform(service.hasBehaviorInterventionPlan));
      }
      if (service.hasHealthCarePlan !== null) {
        otherInfo.push('Has Health Care Plan - ' + this.yesNoPipe.transform(service.hasHealthCarePlan));
      }
    }
    return otherInfo.join(', ');
  }

  onClose() {
    this.dialogRef.close();
  }

  getServiceMethod(model: any): string {
    if (model.directConsult && model.toString().length > 3) return model.directConsult;
    return '';
  }

  canServiceMethod(model: any): boolean {
    if (model.directConsult === '' && model.priorVersion === null) return false;
    return true;
  }
}
