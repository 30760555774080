import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { BooleanYesNoPipe } from '../../../shared/pipes/boolean-yesno.pipe';

@Component({
  selector: 'app-iep-plaafp-view-more',
  templateUrl: './iep-plaafp-view-more.component.html',
  styleUrls: ['./iep-plaafp-view-more.component.scss'],
  providers: [DatePipe, BooleanYesNoPipe],
})
export class IepPlaafpViewMoreComponent implements OnInit {
  plaafp: any;
  type: string;
  name: string;
  entity: any;

  constructor(private dialogRef: MatDialogRef<IepPlaafpViewMoreComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.plaafp = this.data.plaafp;
    this.type = this.data.type;
    this.entity = this.data.entity;

    switch (this.type) {
      case 'StrengthSkill':
        this.name = 'Strengths and Skills';
        break;
      case 'Preference':
        this.name = 'Preferences and Interests';
        break;
      case 'Concern':
        this.name = 'Needs and Concerns';
        break;
    }
  }

  getCommentPriorVersion(element) {
    let returnText = '';
    let row = null;

    if (this.type === 'StrengthSkill') {
      row = this.plaafp?.priorVersion?.strengths.find((x) => x.id === element.priorVersionId)?.comments;
    } else if (this.type === 'Preference') {
      row = this.plaafp?.priorVersion?.preferences.find((x) => x.id === element.priorVersionId)?.comments;
    } else if (this.type === 'Concern') {
      row = this.plaafp?.priorVersion?.concerns.find((x) => x.id === element.priorVersionId)?.comments;
    }

    if (row) {
      returnText += row;
    }
    return returnText;
  }

  getDatasourcePriorVersion(element) {
    let returnText = '';
    let row = null;

    if (this.type === 'StrengthSkill') {
      row = this.plaafp?.priorVersion?.strengths.find((x) => x.id === element.priorVersionId)?.dataSource;
    } else if (this.type === 'Preference') {
      row = this.plaafp?.priorVersion?.preferences.find((x) => x.id === element.priorVersionId)?.dataSource;
    } else if (this.type === 'Concern') {
      row = this.plaafp?.priorVersion?.concerns.find((x) => x.id === element.priorVersionId)?.dataSource;
    }

    if (row) {
      returnText += row;
    }
    return returnText;
  }

  onClose() {
    this.dialogRef.close();
  }
}
