<nav class="nav">
  <ul class="nav__list">
    <li class="nav__item">
      <img src="/assets/img/achieve-logo.svg" class="logo" alt="Achieve Iowa Logo" />
    </li>
  </ul>
</nav>

<section class="not-found">
  <div class="not-found__container">
    <main class="not-found__content">
      <div class="display-flex flex-column justify-content-between align-items-center">
        <section class="not-found__info">
          <h2 class="mt-0">Welcome to ACHIEVE!</h2>
          <h2 class="mt-1">
            A user profile matching your email address has not been found in the ACHIEVE system. Please create a new ACHIEVE support request
            (<a href="https://achievesupport.happyfox.com/new" target="_blank">link</a>) or email achievesupport@iowa.gov for assistance
            from the ACHIEVE support team for your AEA.
          </h2>
        </section>
      </div>
    </main>
  </div>
</section>
