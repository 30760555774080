import { Component, Input, OnInit } from '@angular/core';
import { CaseSummary } from '../../../../../../shared/models/case';
import { DataHistoryService } from '../../../../services/data-history.service';
import { MatTableDataSource } from '@angular/material/table';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';

@Component({
  selector: 'app-data-history-part-c-eligibility',
  templateUrl: './data-history-part-c-eligibility.component.html',
  styleUrls: ['./data-history-part-c-eligibility.component.scss'],
})
export class DataHistoryPartCEligibilityComponent implements OnInit {
  @Input() case: CaseSummary;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns = ['eligDate', 'eligible', 'basedOn', 'basedonDesc'];
  shortDateFormat = shortDateFormat;
  constructor(private dataHistoryService: DataHistoryService) {}

  async ngOnInit(): Promise<void> {
    await this.setup();
  }

  private async setup(): Promise<void> {
    if (!this.case) {
      return;
    }

    const evaluationList = await this.dataHistoryService.getEvaluationsByLearnerId(this.case.learnerId, this.case.id).toPromise();
    if (!evaluationList) {
      return;
    }
    this.dataSource.data = evaluationList;
  }
}
