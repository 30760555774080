import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeleteReason } from '../../models/delete-reason';
import { EarlyAccessPriorWrittenNotice } from '../../models/prior-written-notices/early-access-prior-written-notice';
import { PriorWrittenNotice, PwnLinkTo } from '../../models/prior-written-notices/prior-written-notice';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class PriorWrittenNoticeService {
  baseUrl = 'api/cases';

  constructor(private http: HttpClient, private spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(`^${this.baseUrl}/([^\/]+)/pwns/.*`));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(`^${this.baseUrl}/([^\/]+)/early-access-pwns/.*`));
  }

  //#region Prior Written Notice Service Methods

  getPwn(caseId: string, pwnId: string) {
    return this.http.get<PriorWrittenNotice>(`${this.baseUrl}/${caseId}/pwns/${pwnId}`);
  }

  getAllPwns(caseId: string) {
    return this.http.get<Array<PriorWrittenNotice>>(`${this.baseUrl}/${caseId}/pwns`);
  }

  getAllPwnsForLearner(learnerId: string) {
    return this.http.get<Array<PriorWrittenNotice>>(`api/learners/${learnerId}/pwns`);
  }

  createPwn(caseId: string, pwn: PriorWrittenNotice) {
    return this.http.post<PriorWrittenNotice>(`${this.baseUrl}/${caseId}/pwns`, pwn);
  }

  savePwn(caseId: string, pwn: PriorWrittenNotice): Observable<PriorWrittenNotice> {
    if (pwn.id) {
      return this.http.put<PriorWrittenNotice>(`${this.baseUrl}/${caseId}/pwns/${pwn.id}`, pwn);
    } else {
      return this.createPwn(caseId, pwn);
    }
  }

  deletePwn(caseId: string, pwnId: string, reason: string) {
    const deleteReason: DeleteReason = { id: pwnId, reason: reason };
    return this.http.put<DeleteReason>(`${this.baseUrl}/${caseId}/pwns`, deleteReason);
  }

  //#endregion

  //#region Early Access Prior Written Notice Service Methods

  getEarlyAccessPwn(caseId: string, pwnId: string) {
    return this.http.get<EarlyAccessPriorWrittenNotice>(`${this.baseUrl}/${caseId}/early-access-pwns/${pwnId}`);
  }

  getAllEarlyAccessPwns(caseId: string) {
    return this.http.get<Array<EarlyAccessPriorWrittenNotice>>(`${this.baseUrl}/${caseId}/early-access-pwns`);
  }

  getAllEarlyAccessPwnsForLearner(learnerId: string) {
    return this.http.get<Array<EarlyAccessPriorWrittenNotice>>(`api/learners/${learnerId}/early-access-pwns`);
  }

  saveEarlyAccessPwn(caseId: string, pwn: EarlyAccessPriorWrittenNotice) {
    if (pwn.id) {
      return this.http.put<EarlyAccessPriorWrittenNotice>(`${this.baseUrl}/${caseId}/early-access-pwns/${pwn.id}`, pwn);
    } else {
      return this.createEarlyAccessPwn(caseId, pwn);
    }
  }

  createEarlyAccessPwn(caseId: string, pwn: EarlyAccessPriorWrittenNotice) {
    return this.http.post<EarlyAccessPriorWrittenNotice>(`${this.baseUrl}/${caseId}/early-access-pwns`, pwn);
  }

  updateEarlyAccessPwn(caseId: string, pwn: EarlyAccessPriorWrittenNotice) {
    return this.http.put<EarlyAccessPriorWrittenNotice>(`${this.baseUrl}/${caseId}/early-access-pwns`, pwn);
  }

  deleteEarlyAccessPwn(caseId: string, pwnId: string, reason: string) {
    const deleteReason: DeleteReason = { id: pwnId, reason: reason };
    return this.http.put<DeleteReason>(`${this.baseUrl}/${caseId}/early-access-pwns`, deleteReason);
  }

  //#endregion

  getPartBLinkToOptions(caseId: string) {
    return this.http.get<PwnLinkTo[]>(`${this.baseUrl}/${caseId}/pwns/link-to-options`);
  }

  getPartCLinkToOptions(caseId: string, ifspId?: string) {
    if (ifspId) {
      return this.http.get<PwnLinkTo[]>(`${this.baseUrl}/${caseId}/pwns/link-to-options/${ifspId}`);
    } else {
      return this.http.get<PwnLinkTo[]>(`${this.baseUrl}/${caseId}/pwns/link-to-options`);
    }
  }
}
