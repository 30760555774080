import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'dobAge',
})
export class DobAgePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): number {
    return dayjs(dayjs()).diff(value);
  }
}
