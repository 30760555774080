<section class="registration-form">
  <mat-card>
    <mat-card-title><h2 class="confirm-title">ACHIEVE</h2></mat-card-title>
    <mat-card-subtitle>Iowa Vocational Rehabilitation Services (IVRS)</mat-card-subtitle>
    <mat-card-content [ngSwitch]="invitationStatus">
      <ng-container *ngSwitchCase="'NONE'">
        <div class="row">
          <div class="col pb-4">
            <mat-spinner color="primary" class="loader" [diameter]="50"></mat-spinner>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'INVALID'">
        <div class="row">
          <div class="col">
            <p>Unfortunately, this link is invalid.</p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'ACTIVE'">
        <div class="row">
          <div class="col">
            <p>An account has already been created, and this link is no longer valid.</p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'INACTIVE'">
        <p>Confirm Invitation to ACHIEVE Iowa Vocational Rehabilitation Services (IVRS) Access Point:</p>
        <p>Select a password below based on the following requirements:</p>
        <p>
          <strong>Password requirements</strong><br />
          <small>
            Must be between 8 and 32 characters<br />
            Must have at least one upper-case letter<br />
            Must have at least one lower-case letter<br />
            Must have at least one number or special character (#?!@$%^&*-)
          </small>
        </p>
        <form [formGroup]="formGroup">
          <div class="row">
            <div class="col">
              <app-text-field label="Your Password" formControlName="password" [inputType]="'password'"></app-text-field>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col">
              <app-text-field label="Confirm your Password" formControlName="confirmedPassword" [inputType]="'password'"></app-text-field>
            </div>
          </div>
          <div
            class="row pt-3"
            *ngIf="formGroup.touched && (formGroup.hasError('passwordFormat') || formGroup.hasError('passwordMismatch'))"
          >
            <div class="col">
              <mat-error *ngIf="formGroup.hasError('passwordFormat')">Password doesn't follow the requirements.</mat-error>
              <mat-error *ngIf="formGroup.hasError('passwordMismatch')">Password and Confirm Password do not match.</mat-error>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p>
                By selecting "Submit", you will complete the registration process and will be taken to the IVRS Access Point login page. If
                you are not able to successfully log in, please contact your VR Area Location Administrator for assistance.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col text-right">
              <button
                mat-flat-button
                aria-label="Complete Button"
                class="buttons mt-3"
                color="primary"
                tabindex="0"
                (click)="onRegister()"
                [isBusy]="isBusy"
                [disabled]="!formGroup.valid"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </ng-container>
    </mat-card-content>
  </mat-card>
</section>
