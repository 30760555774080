import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { LearnerPhcp } from 'src/app/shared/models/learner-phcp';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { AgencyProgramRead } from './agency-program-table/agency-program-read';
import { AgencyProgramUpdate } from './agency-program-table/agency-program-update';
import { MedicalSpecialistRead } from './medical-specialist-table/medical-specialist-read';
import { MedicalSpecialistUpdate } from './medical-specialist-table/medical-specialist-update';

@Injectable({
  providedIn: 'root',
})
export class ProviderInfoFormService {
  private baseIntakeUrl = 'api/early-access-intakes';
  private baseQuestionnaireUrl = 'api/early-access-questionnaires';

  constructor(private http: HttpClient, private spinnerService: SpinnerService) {}

  //#region Intake Agency & Programs
  getIntakeAgencyOrOtherPrograms(learnerId: string) {
    return this.http.get<AgencyProgramRead[]>(`${this.baseIntakeUrl}/${learnerId}/agencies-programs`);
  }

  addOrUpdateIntakeAgencyOrOtherPrograms(agency: AgencyProgramUpdate) {
    return this.http.post<AgencyProgramUpdate>(`${this.baseIntakeUrl}/agencies-programs`, agency);
  }

  uploadAgencyDocumentation(uploadFile: any, agencyId: string, learnerId?: string) {
    this.spinnerService.incrementLoading();
    const turnOffSpinner = () => this.spinnerService.decrementLoading();

    uploadFile.learnerId = learnerId;
    return this.http
      .post<FileList>(`${this.baseIntakeUrl}/agencies-programs/${agencyId}/documents`, uploadFile, this.getMultipartRequestHeaders())
      .pipe(tap(turnOffSpinner, turnOffSpinner));
  }
  //#endregion

  //#region Questionnaire Agency & Programs
  getQuestionnaireAgencyOrOtherPrograms(questionnaireId: string) {
    return this.http.get<AgencyProgramRead[]>(`${this.baseQuestionnaireUrl}/${questionnaireId}/agencies-programs`);
  }

  addOrUpdateQuestionnaireAgencyOrOtherPrograms(agency: AgencyProgramUpdate) {
    return this.http.post<AgencyProgramUpdate>(`${this.baseQuestionnaireUrl}/agencies-programs`, agency);
  }

  //#endregion

  deleteAgencyOrOtherPrograms(id: string) {
    return this.http.put<string>(`api/agencies-programs/${id}`, null);
  }

  //#region Intake Medical Specialist
  getIntakeMedicalSpecialist(learnerId: string) {
    return this.http.get<MedicalSpecialistRead[]>(`${this.baseIntakeUrl}/${learnerId}/medical-specialist`);
  }

  addOrUpdateIntakeMedicalSpecialist(specialist: MedicalSpecialistUpdate) {
    return this.http.post<MedicalSpecialistUpdate>(`${this.baseIntakeUrl}/medical-specialist`, specialist);
  }

  uploadMedicalDocumentation(uploadFile: any, specialistId: string, learnerId?: string) {
    this.spinnerService.incrementLoading();
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    uploadFile.learnerId = learnerId;
    return this.http
      .post<FileList>(`${this.baseIntakeUrl}/medical-specialist/${specialistId}/documents`, uploadFile, this.getMultipartRequestHeaders())
      .pipe(tap(turnOffSpinner, turnOffSpinner));
  }

  //#endregion

  //#region Questionnaire Medical Specialist
  getQuestionnaireMedicalSpecialist(questionnaireId: string) {
    return this.http.get<MedicalSpecialistRead[]>(`${this.baseQuestionnaireUrl}/${questionnaireId}/medical-specialist`);
  }

  addOrUpdateQuestionnaireMedicalSpecialist(specialist: MedicalSpecialistUpdate) {
    return this.http.post<MedicalSpecialistUpdate>(`${this.baseQuestionnaireUrl}/medical-specialist`, specialist);
  }
  //#endregion

  deleteMedicalSpecialist(id: string) {
    return this.http.put<string>(`api/medical-specialist/${id}`, null);
  }

  getLearnerPhcp(learnerId: string) {
    return this.http.get<LearnerPhcp>(`api/learners/${learnerId}/phcp`);
  }

  protected getMultipartRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      Accept: 'application/json, text/plain, */*',
    });

    return { headers };
  }
}
