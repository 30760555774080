import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Intake } from 'src/app/evaluation/models/intake';
import { IntakeService } from 'src/app/evaluation/services/intake.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { Aea } from 'src/app/shared/models/aea';
import { CaseOwner } from 'src/app/shared/models/case';
import { AeaService } from 'src/app/shared/services/aea/aea.service';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { ProviderSections } from '../consent-release-exchange-document/consent-release-exchange-document.component';
import { ProviderInfoFormService } from '../provider-info-form.service';

@Component({
  selector: 'app-icd-physician-letter',
  templateUrl: './icd-physician-letter.component.html',
  styleUrls: ['./icd-physician-letter.component.scss'],
})
export class ICDPhysicianLetterComponent implements OnInit {
  intake: Intake;
  learnersName: string;
  today: Date;
  agency: any;
  shortDateFormat = shortDateFormat;
  caseOwner: CaseOwner;
  aea: Aea;
  contactName: string;

  constructor(
    private route: ActivatedRoute,
    private intakeService: IntakeService,
    private providerService: ProviderInfoFormService,
    private caseService: CaseService,
    private aeaService: AeaService
  ) {}

  ngOnInit(): void {
    this.today = new Date();

    this.intakeService.getIntake(this.route.snapshot.paramMap.get('caseId')).subscribe((intake) => {
      this.intake = intake;
      this.prePopulateForms(this.route.snapshot.paramMap.get('caseId'));
      this.learnersName = `${intake.childInfo.lastName}, ${intake.childInfo.firstName} ${
        intake.childInfo.middleName ? intake.childInfo.middleName[0] : ''
      }`;

      this.caseService.getCaseSummary(intake.caseId).subscribe((caseRes) => {
        this.caseOwner = caseRes.caseOwner;
        this.aeaService.getAllAeas().subscribe((aeas) => {
          this.aea = aeas.find((aea) => aea.id === this.caseOwner.aeaId);
        });
      });
    });
  }

  prePopulateForms(caseId: string) {
    const routeParameter = this.route.snapshot.firstChild?.params;
    if (routeParameter) {
      switch (routeParameter.section) {
        case ProviderSections.Provider: {
          const phcpInfo = this.intake.healthInfo.providerInformation;
          this.contactName = `${phcpInfo.phcpTitle} ${phcpInfo.phcpName}`;
          this.agency = {
            name: phcpInfo.phcpClinicAgencyName,
            faxNumber: phcpInfo.phcpFax,
            phoneNumber: phcpInfo.phcpPhone,
          };
          break;
        }
        case ProviderSections.Agency: {
          this.providerService.getIntakeAgencyOrOtherPrograms(this.intake.learner.id).subscribe((agencies) => {
            this.agency = agencies.find((agency) => agency.id === routeParameter.id);
            this.contactName = this.agency?.name;
          });
          break;
        }
        case ProviderSections.MedicalSpecialist: {
          this.providerService.getIntakeMedicalSpecialist(this.intake.learner.id).subscribe((specialists) => {
            this.agency = specialists.find((specialist) => specialist.id === routeParameter.id);
            this.contactName = this.agency?.name;
          });
          break;
        }
        default: {
          break;
        }
      }
    }
  }
}
