import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dobNoTz',
})
export class DobNoTzPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): Date | any {
    if (!value) return '';
    const bd = new Date(value);
    return new Date(bd.getTime() + bd.getTimezoneOffset() * 60000);
  }
}
