import { AfterContentInit, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { KeyValuePair } from '../../../../shared/models/key-value-pair';
import { NotificationService } from '../../../../shared/services/notification.service';
import { conditionalValidator } from '../../../../shared/validators';
import { IepAccommodationsViewMoreComponent } from '../../../modals/iep-accommodations-view-more/iep-accommodations-view-more.component';
import { IepActivitySupportsViewMoreComponent } from '../../../modals/iep-activity-supports-view-more/iep-activity-supports-view-more.component';
import { clpIepServiceFrequency } from '../../../models/clp-iep-service-frequency';
import { IepActivitySupportDto } from 'src/app/iep/models/iep-activity-support';
import { IepAccommodationDto } from 'src/app/iep/models/iep-accommodation';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-support-activity-accommodation-plan',
  templateUrl: './support-activity-accommodation-plan.component.html',
  styleUrls: ['./support-activity-accommodation-plan.component.scss'],
})
export class SupportActivityAccommodationPlanComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() frequencyOptions: clpIepServiceFrequency[];
  @Input() activitySupports: IepActivitySupportDto[];
  @Input() accommodations: IepAccommodationDto[];
  @Input() readOnly: boolean;
  frequencyDictionary: KeyValuePair[] = [];
  label: string;
  private isAccommodation: boolean;
  private data: IepActivitySupportDto | IepAccommodationDto;

  private sub = new Subscription();

  constructor(private dialog: MatDialog, private notificationService: NotificationService) {}

  public get isFrequencyDescribeFieldRequired() {
    const frequencyId = this.form.get('serviceClpFrequencyId')?.value;
    if (!frequencyId) return false;

    return this.frequencyOptions?.find((x) => x.id === frequencyId)?.requiresDescription;
  }

  ngOnInit(): void {
    this.isAccommodation = !!this.form.value.iepAccommodationId;
    if (this.isAccommodation) {
      this.data = this.accommodations.find((x) => x.id === this.form.value.iepAccommodationId);
      this.label = this.data.description;
    } else {
      this.data = this.activitySupports.find((x) => x.id === this.form.value.iepActivitySupportId);
      this.label = this.data.activitySupportType?.label;
    }
    this.frequencyDictionary = KeyValuePair.createFromLookups(this.frequencyOptions?.sort((a, b) => a.order - b.order));
    if (!this.readOnly) {
      this.form.get('serviceClpFrequencyId').valueChanges.subscribe(() => this.form.get('frequencyDescription').setValue(null));
    }
  }

  ngAfterContentInit(): void {
    this.setConditionalValidators();
  }

  private setConditionalValidators() {
    const checkFrequenciesDescribeRequired = (id: string) => this.frequencyOptions.find((x) => x.id === id)?.requiresDescription;

    this.form
      .get('frequencyDescription')
      .addValidators(
        conditionalValidator(() => checkFrequenciesDescribeRequired(this.form.get('serviceClpFrequencyId').value), Validators.required)
      );
  }

  viewDetails() {
    this.isAccommodation
      ? this.dialog.open(IepAccommodationsViewMoreComponent, {
          width: '728px',
          data: {
            accommodation: this.data,
          },
        })
      : this.dialog.open(IepActivitySupportsViewMoreComponent, {
          width: '728px',
          data: {
            activitySupport: this.data,
          },
        });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
