import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppMatModule } from 'src/app/shared/app-mat.module';
import { SharedModule } from '../shared/shared.module';
import { NavComponent } from './nav.component';

@NgModule({
  declarations: [NavComponent],
  imports: [AppMatModule, CommonModule, LayoutModule, RouterModule, SharedModule],
  exports: [NavComponent],
})
export class NavModule {}
