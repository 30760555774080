<p *ngIf="goals?.length === 0">No goals selected.</p>
<div *ngFor="let goal of goals">
  <p class="form__description">
    {{ goal.goalType === goalType.PSE ? goal.expectationType : goal.goalArea + ' - ' + goal.conditionIndividualWillPerform }}
  </p>

  <ul class="list">
    <li class="list__item">
      <p>
        In this
        {{ goal.goalType === goalType.PSE ? 'expectation' : 'goal area' }}, has there been, or is there a potential, for significant
        regression during periods of interruption that would require significant recoupment?
      </p>
      <p class="text-semibold">
        {{ goal.significantRegression ? 'Yes' : 'No' }}
      </p>
      <p class="w-100 my-0" *ngIf="goal.significantRegressionExplanation">
        <span class="text-semibold">Explanation</span>:
        {{ goal.significantRegressionExplanation }}
      </p>
    </li>

    <li class="list__item">
      <p>
        In this
        {{ goal.goalType === goalType.PSE ? 'expectation' : 'goal area' }}, are there any rare and unusual circumstances?
      </p>
      <p class="text-semibold">{{ goal.rareCircumstances ? 'Yes' : 'No' }}</p>
      <p class="w-100 my-0" *ngIf="goal.rareCircumstancesExplanation">
        <span class="text-semibold">Explanation</span>:
        {{ goal.rareCircumstancesExplanation }}
      </p>
    </li>

    <li class="list__item">
      <p>
        In this
        {{ goal.goalType === goalType.PSE ? 'expectation' : 'goal area' }}, are there other factors to be considered in determining the
        child's need for ESY services?
      </p>
      <p class="text-semibold">{{ goal.otherFactors ? 'Yes' : 'No' }}</p>
      <p class="w-100 my-0" *ngIf="goal.otherFactorsExplanation">
        <span class="text-semibold">Explanation</span>:
        {{ goal.otherFactorsExplanation }}
      </p>
    </li>

    <li class="list__item">
      <div *ngIf="goal.iepGoal">
        <p>IEP Progress Monitoring: {{ goal.iepGoal.frequencyAmount }} times per {{ goal.iepGoal.frequencyPeriod }}. Adjusted for ESY?</p>
        <p
          *ngIf="
            goal.monitoringFrequencyTime &&
            (goal.iepGoal.frequencyAmount !== goal.monitoringFrequencyTime ||
              goal.iepGoal.frequencyPeriod !== goal.monitoringFrequencyPeriod)
          "
        >
          <span class="text-semibold">Yes</span> - {{ goal.monitoringFrequencyTime }} time per
          {{ goal.monitoringFrequencyPeriod }}
        </p>
        <p
          *ngIf="
            !goal.monitoringFrequencyTime ||
            (goal.iepGoal.frequencyAmount === goal.monitoringFrequencyTime &&
              goal.iepGoal.frequencyPeriod === goal.monitoringFrequencyPeriod)
          "
        >
          <span class="text-semibold">No</span>
        </p>
      </div>
      <div *ngIf="!goal.iepGoal">
        <p>
          ESY Progress Monitoring:
          {{ goal.monitoringFrequencyTime ? goal.monitoringFrequencyTime : '-' }}
          times per
          {{ goal.monitoringFrequencyPeriod ? goal.monitoringFrequencyPeriod : '-' }}.
        </p>
      </div>
    </li>
  </ul>

  <hr class="input__divider" />

  <app-esy-table-display [iepId]="iepId" [viewOnly]="true" [goal]="goal"> </app-esy-table-display>
</div>
