<app-print-head title="" [showLogo]="true"></app-print-head>

<div style="height: 100%">
  <div class="print__container" *ngIf="intake">
    <div class="display-flex justify-content-between align-items-center">
      <p class="mb-0 signature__fill">
        Dear
        <span *ngIf="contactName" class="ml-1">{{ contactName }},</span>
      </p>

      <p class="mb-0">Date: {{ today | dateFormat }}</p>
    </div>

    <p>Re: Please confirm ICD-10 diagnosis for child named below.</p>

    <p>
      The Iowa Department of Education, Iowa Area Education Agencies, and Child Health Specialty Clinics need your support in providing an
      ICD-10 diagnosis for Medicaid billing of infants and toddlers who are provided special education services.
    </p>

    <p>
      Rules were adopted by the Iowa Department of Human Services that allow area education agencies and infant toddler providers to bill
      Medicaid for medically necessary services provided by, or paid for by the agency. This program for area education agencies became
      effective for dates of service on or after July 1, 1988. More specifically, the program for infant toddler providers became effective
      for dates of service on or after March 1, 2001.
    </p>

    <p>
      If the service is documented on the child’s Individualized Family Service Plan (IFSP), area education agencies and the Child Health
      Specialty Clinics are allowed to bill Medicaid for those services.
    </p>

    <p>
      This program allows billing with no cost to the families. The services documented in the child’s IFSP will be provided free,
      regardless of the Medicaid funding. Billing for Infant Toddler program services is outside the Managed Care plans and submitted direct
      to Iowa Medicaid.
    </p>

    <hr class="input__divider" />

    <h3>Diagnosis Request</h3>

    <div class="display-flex flex-wrap">
      <p class="mr-5"><strong>Child's Name:</strong> {{ learnersName }}</p>
      <p class="mr-5">
        <strong>DOB:</strong>
        {{ intake.childInfo.dateOfBirth | dateFormat }}
      </p>
      <p class="mr-4"><strong>Provider Agency: </strong>{{ agency?.name }}</p>
      <p class="mr-4"><strong>Phone Number: </strong>{{ agency?.phoneNumber | phone }}</p>

      <p class="mr-5" *ngIf="agency?.faxNumber"><strong>Fax Number: </strong>{{ agency?.faxNumber | phone }}</p>
    </div>

    <h4>Please provide the following information:</h4>
    <div class="display-flex">
      <div class="mr-5 mb-0 signature">
        <strong>Diagnosis:</strong>
        <div></div>
      </div>

      <div class="mr-5 mb-0 ml-5 signature">
        <strong>ICD-10 Code:</strong>
        <div></div>
      </div>
    </div>

    <hr class="input__divider" />

    <!-- <p class="mt-4 signature mr-4 flex-4-2">Your Signature</p>
    <p class="mt-4 signature flex-4-1">Date</p> -->

    <div class="display-flex flex-column mt-3">
      <div class="display-flex">
        <p class="signature flex-4-2">Physician Signature</p>

        <p class="signature flex-4-1">Date</p>
      </div>

      <h3 class="signature--margin w-100 align-self-center my-0">OR</h3>

      <div class="display-flex">
        <p class="signature flex-4-2">Clinic / Practice / Records Manager Signature / Physician Name</p>

        <p class="signature flex-4-1">Date</p>
      </div>
    </div>

    <hr class="input__divider" />

    <h3 class="mb-2">Thank you.</h3>

    <div class="display-flex flex-column ml-3">
      <div class="display-flex">
        <div *ngIf="caseOwner?.fullName" class="mr-1">
          <p class="mb-0">{{ caseOwner?.fullName }}</p>
        </div>
      </div>
      <div *ngIf="caseOwner?.jobTitle">
        <p class="mb-0">{{ caseOwner?.jobTitle }}</p>
      </div>
      <!-- TODO Needs to be updated with building information -->
      <div *ngIf="caseOwner?.buildings">
        <p class="mb-0">{{ caseOwner.buildings[0]?.name }}</p>
      </div>
      <div *ngIf="aea?.name">
        <p class="mb-0">{{ aea?.name }}</p>
      </div>
      <div *ngIf="aea?.streetAddress">
        <p class="mb-0">{{ aea?.streetAddress }}</p>
      </div>
      <div *ngIf="aea?.city && aea?.state && aea?.zipCode">
        <p class="mb-0">
          {{ aea?.city }}, {{ aea?.state }}
          {{ aea?.zipCode }}
        </p>
      </div>
      <div class="display-flex">
        <div *ngIf="aea?.primaryPhone">
          <p class="mb-0">Phone: {{ aea?.primaryPhone | phone }}</p>
        </div>
        <div *ngIf="aea?.primaryPhone && aea?.secondaryPhone" class="mx-1">|</div>
        <div *ngIf="aea?.secondaryPhone">
          <p class="mb-0">Fax: {{ aea?.secondaryPhone | phone }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
