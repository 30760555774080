import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DeactivationService } from '../../../services/deactivation.service';
import { AreYouSureComponent } from '../../are-you-sure-modal/are-you-sure.component';
import { BaseComponent } from '../../base-component/base-component';
import { QuantifiableDataRating } from '../quantifiable-data';

@Component({
  selector: 'app-scale-levels',
  templateUrl: './scale-levels.component.html',
  styleUrls: ['./scale-levels.component.scss'],
})
export class ScaleLevelsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input()
  scaleRatings: QuantifiableDataRating[];
  @Input()
  showForm: boolean;
  @Input()
  secondary: boolean;
  @Input()
  modificationId: string;
  @Output()
  updated = new EventEmitter<QuantifiableDataRating[]>();
  @Output()
  canceled = new EventEmitter<boolean>();
  @Output() edit = new EventEmitter();
  @ViewChild(FormGroupDirective)
  formRef: FormGroupDirective;
  @ViewChild('ratingField')
  ratingField: any;

  constructor(private fb: FormBuilder, private dialog: MatDialog, deactivationService: DeactivationService) {
    super(deactivationService);
  }

  formGroup = this.fb.group({
    rating: [null, [Validators.required, Validators.min(0)]],
    description: [null, [Validators.required]],
    secondary: [null],
  });

  dataSource = new MatTableDataSource<QuantifiableDataRating>();
  displayedColumns = ['actions', 'rating', 'description'];
  ratingLevels: QuantifiableDataRating[] = [];
  model: QuantifiableDataRating;

  ngOnInit(): void {
    this.dataSource.data = this.scaleRatings?.filter((x) => x.secondary === this.secondary);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scaleRatings?.currentValue !== changes.scaleRatings?.previousValue) {
      this.dataSource.data = changes.scaleRatings.currentValue.sort((a, b) => (a.rating > b.rating ? 1 : -1));

      this.dataSource.data = this.dataSource.data.filter((x) => x.secondary === this.secondary);
    }
  }

  onUpdateModel() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      const modelIdx = this.scaleRatings.indexOf(this.model);
      // Adding
      if (modelIdx < 0) {
        this.formGroup.controls.secondary.setValue(this.secondary);
        // this.formGroup.value.rating = this.dataSource.data?.length + 1;
        this.scaleRatings.push(this.formGroup.value);
      }

      // Updating
      else {
        this.scaleRatings[modelIdx].rating = this.formGroup.value.rating;
        this.scaleRatings[modelIdx].description = this.formGroup.value.description;
      }

      this.scaleRatings = [...this.scaleRatings]; // To show up on the table as a change
      this.resetForm();
      this.formRef.resetForm();
      this.updated.emit(this.scaleRatings);
      this.ratingField?.nativeElement?.focus();
    }
  }

  onEdit(is: QuantifiableDataRating) {
    this.model = is;
    this.initializeForm();
    this.edit.emit();
  }

  onDelete(is: QuantifiableDataRating) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion: 'Clicking Yes will remove the selected scale.',
      },
    });

    dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        const modelIdx = this.scaleRatings.indexOf(is);
        this.scaleRatings = [...this.scaleRatings.slice(0, modelIdx), ...this.scaleRatings.slice(modelIdx + 1)];
        this.updated.emit(this.scaleRatings);
      }
    });
  }

  onCancel() {
    this.resetForm();
    this.canceled.emit(true);
  }

  private resetForm() {
    this.model = null;
    this.formGroup.controls.rating.setValue(null);
    this.formGroup.controls.description.setValue(null);
  }

  private initializeForm() {
    this.formGroup.reset(this.model);
  }
}
