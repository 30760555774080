import { NgModule } from '@angular/core';
import { AscendSurveyToolAdminModule } from '@ascend/survey-tool-admin';
import {
  AscendSurveyToolCommonModule,
  LookupService,
  SurveyService,
  SurveyTemplateQuestionService,
  SurveyTemplateSectionService,
  SurveyTemplateService,
} from '@ascend/survey-tool-common';
import { AscendSurveyToolPublicModule } from '@ascend/survey-tool-public';
import { AscendSurveyToolSurveysModule } from '@ascend/survey-tool-surveys';
import { SurveyToolRoutingModule } from './survey-tool-routing.module';

const config = { baseRoute: 'survey-tool', appName: 'ACHIEVE' };

@NgModule({
  declarations: [],
  imports: [
    SurveyToolRoutingModule,
    AscendSurveyToolCommonModule,
    AscendSurveyToolSurveysModule,
    AscendSurveyToolAdminModule.forRoot(config),
    AscendSurveyToolPublicModule.forRoot(config),
  ],
  exports: [],
  providers: [
    { provide: 'SurveyTemplateService', useClass: SurveyTemplateService },
    { provide: 'SurveyTemplateSectionService', useClass: SurveyTemplateSectionService },
    { provide: 'SurveyService', useClass: SurveyService },
    { provide: 'SurveyTemplateQuestionService', useClass: SurveyTemplateQuestionService },
    { provide: 'LookupService', useClass: LookupService },
  ],
})
export class SurveyToolModule {}
