import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/auth/auth.service';
import { TeamMemberSearchModalComponent } from '../../modals/team-member-search-modal/team-member-search-modal.component';
import { IntakeType } from '../../models/case';
import { Team, TeamUser } from '../../models/team';
import { User } from '../../models/user';
import { ConfirmationDialogComponent } from '../../services/notification.service';
import { TeamService } from '../../services/team/team.service';

@Component({
  selector: 'app-team-member',
  templateUrl: './team-member.component.html',
  styleUrls: ['./team-member.component.scss'],
})
export class TeamMemberComponent implements OnInit {
  @Input() team: Team;
  @Input() learnerId: string;
  @Input() intakeType: IntakeType;
  @Output() teamEvent: EventEmitter<Team> = new EventEmitter();
  @Output() removeTeamUserEvent = new EventEmitter<TeamUser>();
  @ViewChild(MatSort) sort: MatSort;

  teams: Team[];
  displayedColumns: Array<string> = ['actions', 'fullName', 'jobTitle', 'email', 'phone', 'aea', 'district', 'buildings'];
  dataSourceTeamUsers = new MatTableDataSource<TeamUser>();
  selectionTeamUser = new SelectionModel<User>(true, []);

  constructor(private dialog: MatDialog, private teamService: TeamService, private authService: AuthService) {}

  ngOnInit() {
    this.teamService.getTeams(this.authService.user.id).subscribe((res: Team[]) => {
      this.teams = res;
    });

    if (this.team?.users?.length > 0) {
      this.dataSourceTeamUsers.data = this.team.users;
      this.dataSourceTeamUsers.sort = this.sort;
    }
  }

  updateTeamSelection(team: Team) {
    this.team = team;
    this.dataSourceTeamUsers.data = this.team.users;
    this.teamEvent.emit(this.team);
  }

  removeTeamUser(teamUser: TeamUser) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Remove Participant',
        message: 'Are you sure you want to remove this participant?',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const index = this.team.users.findIndex((x) => x.userId === teamUser.userId);
        this.team.users.splice(index, 1);
        this.dataSourceTeamUsers.data = this.team.users;
        this.removeTeamUserEvent.emit(teamUser);
      }
    });
  }

  openSearchDialog(): void {
    const userIds = this.team.users.map((tm) => tm.userId);
    const config = {
      userId: this.authService.user.id,
      intakeType: IntakeType.PartB,
      learnerId: this.learnerId,
      isMeeting: false,
      userIds,
    };

    const dialogRef = this.dialog.open(TeamMemberSearchModalComponent, {
      width: '728px',
      data: {
        config,
        showResident: false,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      const usersToAdd = res.teamUsers.filter((x) => res.newUserIds.map((y) => y).includes(x.userId));
      this.team.users = this.team.users.concat(usersToAdd);
      this.dataSourceTeamUsers.data = this.team.users;
      this.teamEvent.emit(this.team);
    });
  }
}
