import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { OutcomeDto } from 'src/app/ifsp/models/outcome-models';
import { IfspServiceInfoComponent } from 'src/app/ifsp/shared/ifsp-service-info/ifsp-service-info.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { DialogComingSoonComponent } from '../../../../../shared/components/coming-soon/coming-soon.component';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { needsLabelOffset } from '../../../../../shared/tableHelpers';
import { IfspMedicalOtherService } from '../../../../models/ifsp-medicalotherservice-models';
import { IfspModification } from '../../../../models/ifsp-modification';
import { IfspServiceDto } from '../../../../models/ifsp-service-models';
import { IfspMedicalOtherServiceService } from '../../../../services/ifsp-medicalotherservice.service';
import { IfspServicesService } from '../../../../services/ifsp-service.service';
import { OutcomeService } from '../../../../services/outcome.service';
import { IfspChangeProviderComponent } from '../../../../shared/components/ifsp-change-provider/ifsp-change-provider.component';
import { IfspRemovalComponent } from '../../../../shared/ifsp-removal/ifsp-removal.component';
import { IfspServiceViewMoreComponent } from '../../../../shared/ifsp-service-view-more/ifsp-service-view-more.component';

@Component({
  selector: 'app-ifsp-details-services',
  templateUrl: './ifsp-details-services.component.html',
  styleUrls: ['./ifsp-details-services.component.scss'],
})
export class IfspDetailsServicesComponent implements OnInit {
  @Input() modifications: IfspModification[];
  @Input() ifspStartDate;
  @Input() readOnly = false;

  @ViewChild('servicesFormArea', { static: false })
  servicesFormArea: ElementRef;

  get modification() {
    return this.modifications?.find((x) => !x.finalizeDate);
  }

  get modificationIsFinalized() {
    return this.modification?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.modifications && this.modifications.length > 0) {
      const latest = this.modifications.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  @Input() ifspId: string;
  dataSource = new MatTableDataSource([]);
  displayedColumns = [
    'actions',
    'typeOfService',
    'outcomeSupported',
    'provider',
    'agency',
    'location',
    'length',
    'howOften',
    'familyDeclined',
    'primaryServiceProvider',
  ];
  medicalDisplayedColumns: string[] = [
    'actions',
    'typeOfService',
    'agencyName',
    'dateRequested',
    'estimatedCompletionDate',
    'referralDate',
  ];
  medicalDataSource: MatTableDataSource<any>;
  medicalServiceFormGroup: FormGroup;
  isEditingMedicalService = false;
  addingNew = false;
  shortDateFormat = shortDateFormat;
  showServicesForm = false;
  modifyingService: IfspServiceDto;
  isEditing = false;
  outcome: OutcomeDto[];
  services: IfspServiceDto[] = [];
  providerFormGroup: FormGroup;
  medicalServices: Array<IfspMedicalOtherService> = [];

  get hasOpenModification() {
    return this.modification;
  }

  get modificationId() {
    return this.modification?.id;
  }

  constructor(
    private readonly dialog: MatDialog,
    private readonly ifspServiceService: IfspServicesService,
    private readonly ifspOutcomeService: OutcomeService,
    private readonly route: ActivatedRoute,
    private readonly notificationService: NotificationService,
    private readonly datePipe: DatePipe,
    private readonly fb: FormBuilder,
    private readonly ifspMedicalOtherServiceService: IfspMedicalOtherServiceService
  ) {
    this.medicalServiceFormGroup = this.fb.group({
      typeOfService: [null, [Validators.required]],
      agencyName: [null, [Validators.required]],
      dateRequested: [null, [Validators.required]],
      referralDate: [null],
      estimatedCompletionDate: [null, [Validators.required]],
    });
  }

  ngOnInit(): void {
    if (this.readOnly) {
      this.displayedColumns.shift();
      this.medicalDisplayedColumns.shift();
    }

    this.dataSource = new MatTableDataSource([]);
    this.medicalDataSource = new MatTableDataSource([]);
    this.getMedicalServices();
    this.getServices();
    this.ifspServiceService.servicesUpdated$.subscribe(() => this.getServices());
    // This is here so we can update the services when the outcome is updated.
    // Outcomes have services associated to them and they can change during the mod process
    this.ifspOutcomeService.outcomesUpdated$.subscribe(() => this.getServices());
    this.ifspServiceService.serviceAddNew$.subscribe(() => {
      this.showServicesForm = true;
      this.isEditing = true;
      this.modifyingService = null;

      this.servicesFormArea?.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    });
    this.ifspServiceService.serviceClose$.subscribe(() => {
      this.showServicesForm = false;
      this.isEditing = false;
    });
    this.dataSource.sortingDataAccessor = (item: IfspServiceDto, columnId) => {
      switch (columnId) {
        case 'outcomeSupported':
          return this.getOutcomesText(item);
        case 'howOften':
          return this.getFrequencyText(item);
        default:
          return item[columnId];
      }
    };
    this.providerFormGroup = this.fb.group({});
  }
  getMedicalServices() {
    this.ifspMedicalOtherServiceService.getServices(this.ifspId).subscribe((medicalServices) => {
      this.medicalServices = medicalServices;
      this.medicalDataSource.data = this.medicalServices;
    });
  }

  updateMedicalService(formDirective: any, resetForm = true) {
    this.ifspMedicalOtherServiceService.updateService(this.ifspId, this.medicalServiceFormGroup.value).subscribe(() => {
      this.getMedicalServices();
    });
    if (resetForm) {
      formDirective.resetForm();
    }
  }

  setMedicalServiceEdit(service: IfspMedicalOtherService, editing = true) {
    this.addingNew = false;
    this.isEditingMedicalService = editing;
    if (service !== null) {
      service.isEditing = editing;
    }
    if (!editing) {
      this.medicalServiceFormGroup.patchValue({});
      this.medicalServiceFormGroup.reset();
      return;
    }
    this.medicalServiceFormGroup.patchValue({
      id: service.id,
      typeOfService: service.typeOfService,
      agencyName: service.agencyName,
      dateRequested: service.dateRequested,
      referralDate: service.referralDate,
      estimatedCompletionDate: service.estimatedCompletionDate,
    });
  }

  saveMedicalService(service: IfspMedicalOtherService) {
    if (this.medicalServiceFormGroup.valid) {
      const formValues = this.medicalServiceFormGroup.value;
      formValues.id = service ? service.id : null;
      this.updateMedicalService(formValues, false);
      this.isEditingMedicalService = false;
      this.setMedicalServiceEdit(service, false);
    } else {
      this.medicalServiceFormGroup.markAllAsTouched();
    }
  }

  onMedicalServiceRemove(service: IfspMedicalOtherService) {
    this.notificationService.confirmation('Are you sure you want to delete this Medical or Other Service?', () => {
      this.ifspMedicalOtherServiceService.deleteService(this.ifspId, service.id).subscribe(() => {
        this.getMedicalServices();
      });
    });
  }
  checkLabelOffset(row): boolean {
    return needsLabelOffset(row);
  }
  async getServices(): Promise<void> {
    const result = await this.ifspServiceService.getAllForModification(this.ifspId, this.modification?.id).toPromise();
    this.services = result;
    this.ifspServiceService.currentServicesForModification.next(result);
    this.services.forEach((service) => {
      this.providerFormGroup.addControl(
        'primaryServiceProvider_' + service.id,
        new FormControl(service.primaryServiceProvider ? '1' : null)
      );
    });
    this.dataSource.data = this.services;
  }

  addNew() {
    this.ifspServiceService.serviceAddNew.next();
  }

  onEdit(service: IfspServiceDto) {
    const callback = (_service: IfspServiceDto) => {
      this.showServicesForm = true;
      this.modifyingService = _service;
      this.ifspServiceService.setModifyingService.next(_service);
      this.isEditing = true;
    };
    if (service.modificationId === null || service.modificationId !== this.modificationId) {
      this.ifspServiceService.modify(this.ifspId, service.id, this.modification.id).subscribe((modifyResult) => {
        callback(modifyResult);
      });
    } else {
      callback(service);
    }
  }

  canModify(element) {
    return this.hasOpenModification && !element.modificationEndDate && !this.isEditing;
  }

  canEnd(element) {
    return this.hasOpenModification && !this.anyEndDatesInPast(element) && element.modificationId !== this.modificationId;
  }

  canRemove(element) {
    return (
      this.hasOpenModification &&
      !this.anyEndDatesInPast(element) &&
      !element?.priorVersionId &&
      element.modificationId === this.modificationId
    );
  }

  canUndo(element) {
    return (
      this.hasOpenModification &&
      !this.anyEndDatesInPast(element) &&
      element?.priorVersionId &&
      element.modificationId === this.modificationId
    );
  }

  canViewAuditLog(element) {
    return (
      this.hasOpenModification &&
      !this.anyEndDatesInPast(element) &&
      !this.isEditing &&
      (element?.isActive || element?.priorVersionId) &&
      !element?.modificationEndDate
    );
  }

  canViewGraphs(element) {
    return this.hasOpenModification && !this.isEditing && (element.isActive || element?.priorVersionId) && !element.modificationEndDate;
  }

  canAddProgressMonitoring(element) {
    return this.hasOpenModification && !this.isEditing && (element.isActive || element?.priorVersionId) && !element.modificationEndDate;
  }

  anyEndDatesInPast(service) {
    const outcomeModificationDate = dayjs(service.modificationEndDate).toDate().setHours(23, 59, 59, 9999);
    const today = dayjs().toDate().setHours(23, 59, 59, 9999);
    return outcomeModificationDate < today && service.isActive;
  }

  anyEndDatesEqualOrPast(service) {
    const serviceModificationDate = dayjs(service.modificationEndDate).toDate().setHours(23, 59, 59, 9999);
    const today = dayjs().toDate().setHours(23, 59, 59, 9999);
    return serviceModificationDate <= today;
  }

  showMenu(element) {
    return (
      this.canAddProgressMonitoring(element) ||
      this.canViewGraphs(element) ||
      this.canModify(element) ||
      this.canUndo(element) ||
      this.canRemove(element) ||
      !this.anyEndDatesInPast(element) ||
      this.canViewAuditLog(element) ||
      this.canEnd(element)
    );
  }

  onChangeProviders(element) {
    const dialogRef = this.dialog.open(IfspChangeProviderComponent, {
      data: {
        id: element.id,
        ifspId: this.ifspId,
        caseId: this.route.snapshot.paramMap.get('caseId'),
        type: 'IFSPService',
        dataElement: element,
      },
      width: '364px',
    });

    dialogRef.afterClosed().subscribe(() => {
      this.getServices();
    });
  }

  hasServiceProviders() {
    return Object.values(this.providerFormGroup.controls).find((x) => x.value === '1');
  }

  async clearServiceProviders(): Promise<void> {
    Object.values(this.providerFormGroup.controls).forEach((provider) => {
      provider.setValue(null);
    });

    await this.ifspServiceService.updateServiceProviders(this.ifspId, null).toPromise();
  }

  async onFamilyDeclinedChange(id, modificationId, event): Promise<void> {
    const callback = async (modifyResult?: any) => {
      let service: IfspServiceDto;
      if (!modifyResult) {
        service = await this.ifspServiceService.getServiceById(this.ifspId, id).toPromise();
      } else {
        service = modifyResult;
      }
      service.outcomes = service.outcomes.map((x) => x.outcomeId);
      service.familyDeclined = event.checked;
      await this.ifspServiceService.updateService(this.ifspId, service).toPromise();
    };
    if (!modificationId) {
      this.ifspServiceService.modify(this.ifspId, id, this.modification.id).subscribe((modifyResult) => {
        callback(modifyResult);
      });
    } else {
      callback();
    }
  }

  async onPSPChanged(id, modificationId): Promise<void> {
    const keyPrefix = 'primaryServiceProvider_';
    const keyId = keyPrefix + id;
    Object.keys(this.providerFormGroup.controls).forEach((key) => {
      if (key.startsWith(keyPrefix)) {
        if (key === keyId) {
          this.providerFormGroup.get(key).setValue('1');
        } else {
          this.providerFormGroup.get(key).setValue(null);
        }
      }
    });

    await this.ifspServiceService.updateServiceProviders(this.ifspId, id).toPromise();
  }

  onRemove(service, isUndo = false) {
    this.notificationService.confirmation(`Are you sure you want to ${isUndo ? 'undo' : 'delete'} this service?`, () => {
      this.ifspServiceService.deleteService(this.ifspId, service.id, true).subscribe(() => {
        this.notificationService.success(`Service ${isUndo ? 'reverted' : 'deleted'}`);
        this.clearServiceProviders();
        this.getServices();
      });
    });
  }

  onViewMore(el) {
    const dialogRef = this.dialog.open(IfspServiceViewMoreComponent, {
      width: '758px',
      data: {
        service: el,
        modificationId: this.modification?.id,
        isModificationView: true,
      },
    });
  }

  onEnd(service: IfspServiceDto) {
    const serviceOutcomeIds = service.outcomes.map((x) => x.outcomeId);
    const totalOfServicesWithOutcome = this.services.filter(
      (x) =>
        x.modificationEndDate === null && x.outcomes.map((xx) => xx.outcomeId).some((outcomeId) => serviceOutcomeIds.includes(outcomeId))
    ).length;
    const dialogRef = this.dialog.open(IfspRemovalComponent, {
      data: {
        id: service.id,
        modificationId: this.modificationId,
        ifspId: this.ifspId,
        type: 'Service',
        title: service.typeOfService,
        entity: service,
        startDate: this.ifspStartDate,
        subTitle: 'Type - ' + service.outcomes.map((x) => x.outcome.title).join(', '),
        onlyServiceAssociatedToOutcome: totalOfServicesWithOutcome === 1 ? true : false,
        caseId: this.route.snapshot.params?.caseId,
      },
    });
  }

  onViewAuditLog(service: IfspServiceDto) {
    const dialogRef = this.dialog.open(IfspServiceInfoComponent, {
      data: service,
      width: '728px',
    });
  }

  openComingSoon() {
    this.dialog.open(DialogComingSoonComponent, {
      width: '990px',
    });
  }

  getFrequencyText(element) {
    let monitoringProgress = '';
    if (element && element.frequencyNumber && element.frequencyNumber > 1) {
      monitoringProgress += element?.frequencyNumber;
    }
    monitoringProgress += element.frequencyNumber > 1 ? ' times ' : '';
    if (element && element.frequencyPeriod) {
      monitoringProgress += element?.frequencyPeriod;
    }
    return monitoringProgress;
  }

  getLengthText(element) {
    let lengthText = '';
    if (element && element.length) {
      lengthText += element?.length + ' Min';
    }
    return lengthText;
  }

  getServiceProjectedStartDateText(service) {
    return this.datePipe.transform(service?.startDate, shortDateFormat);
  }

  getOutcomesText(service) {
    let outcomes = '';
    if (service && service?.outcomes) {
      service?.outcomes?.forEach((outcome, index) => {
        outcomes += outcome?.outcome?.title;
        if (index + 1 !== service?.outcomes?.length) {
          outcomes += ', ';
        }
      });
    }
    return outcomes;
  }
}
