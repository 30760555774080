import { Pipe, PipeTransform } from '@angular/core';
import { EntityState } from '../models/entity-state';

@Pipe({
  name: 'associatedStateFrom',
})
export class AssociatedStatePipe implements PipeTransform {
  transform(title: string, args?: EntityState): string {
    if (!args) return title;

    const parentState = args.isParentDraft && args.isParentDraft! ? 'Draft ' : '';
    let relatedState = '';

    switch (args.status.toLowerCase()) {
      case 'draft':
        relatedState = ' - Draft';
        break;
      case 'amendment':
        relatedState = ` - ${parentState}Amendment`;
        break;
      case 'modification':
        relatedState = ` - ${parentState}Modification`;
        break;
    }

    return title + relatedState;
  }
}
