<div cdkDropListGroup>
  <section>
    <div class="display-flex justify-content-between align-items-center mb-2 flex-wrap">
      <button mat-raised-button aria-label="Autofill Notes" color="primary" type="button" (click)="autoFillNotes()" tabindex="0">
        Autofill Notes
      </button>

      <div class="display-flex flex-1">
        <app-help-directional-text [outsideCard]="true" direction="right">
          <p>
            Click the button to autofill the matrix below with tagged notes from the evaluation details to organize them by ECO areas and
            routines, or to manually complete the matrix, drag and drop the notes into the matrix.
          </p>
        </app-help-directional-text>
      </div>
    </div>
  </section>
  <section>
    <div class="filter">
      <mat-card class="my-0 filter__actions py-2">
        <h3 class="mb-2 mt-0">Filter Evaluation Assessment Notes by</h3>
        <form [formGroup]="formGroupFilter">
          <div class="row">
            <div class="col-md-6">
              <app-autocomplete-chips-groups [options]="tagOptions" formControlName="tags" label="Tags"></app-autocomplete-chips-groups>
            </div>
            <div class="col-md-3">
              <app-autocomplete label="Team Member" formControlName="teamMember" [options]="evalUsers"></app-autocomplete>
            </div>
            <div class="col-md-3">
              <app-autocomplete label="Hidden/Unhidden" [options]="stateOptions" formControlName="state"></app-autocomplete>
            </div>
          </div>
        </form>
      </mat-card>
      <mat-card class="my-0 filter__results">
        <div *ngIf="notes" id="evalNotes" cdkDropList [cdkDropListData]="notes" (cdkDropListDropped)="dropNote($event)">
          <div *ngFor="let note of notes; last as isLast" cdkDrag class="display-block">
            <mat-card class="eval-card">
              <div class="display-flex align-items-center flex-wrap mb-1">
                <span class="eval-card__tag eval-card__tag--routine" *ngFor="let routine of note.routines">{{ routine.label }}</span>

                <span class="eval-card__tag eval-card__tag--domain" *ngFor="let domain of note.domainAreas">
                  {{ domain.label }}
                </span>

                <span class="eval-card__tag eval-card__tag--eco" *ngFor="let ecoArea of note.ecoAreas">
                  {{ ecoArea.label }}
                </span>
              </div>
              <div class="display-flex justify-content-between align-items-center">
                <div class="display-flex align-items-center">
                  <p class="my-0">
                    {{ note.createdOn | dateFormat }} |
                    {{ note.createdBy.fullName }}
                    <span *ngIf="note?.interviewQuestion">
                      |
                      <span class="text-primary text-italic">{{ note?.interviewQuestion }}</span>
                    </span>
                  </p>
                </div>

                <div class="display-flex align-items-center">
                  <mat-slide-toggle [checked]="!note.hidden" (change)="onToggleHide($event, note.id)">
                    {{ note.hidden ? 'Hidden' : 'Unhidden' }}</mat-slide-toggle
                  >
                  <mat-icon class="ml-2" cdkDragHandle aria-labelledby="Drag and Drop">drag_indicator</mat-icon>
                </div>
              </div>
              <div class="eval-card__note">
                <p class="mb-0 mt-2" style="white-space: pre-line">
                  {{ note.note }}
                </p>
              </div>
            </mat-card>
            <div class="mb-2 display-block" *ngIf="!isLast"></div>
          </div>

          <mat-card *ngIf="areAnyNotes()" class="my-0">
            <p class="my-0">
              <span class="text-italic text-semibold" *ngIf="areAnyNotes().msg">{{ areAnyNotes().msg }}</span>
            </p>
          </mat-card>
        </div>
      </mat-card>
    </div>
  </section>

  <form [formGroup]="formGroupMatrix" class="card-spacing-top">
    <h3 class="mt-0 mb-2">ECO Matrix</h3>
    <mat-card class="card--plain card--shadow">
      <div class="row align-items-center">
        <div class="col-md-3"></div>
        <div class="col-md-3" *ngFor="let ecoArea of ecoAreas">
          <h3 class="mb-2 mt-0">{{ ecoArea.label }}</h3>
        </div>
      </div>

      <div class="row align-items-start drag-drop__row" *ngFor="let routine of routines">
        <div class="col-md-3 align-self-start">
          <h3 class="drag-drop__title--routine display-flex flex-1 align-items-center justify-content-between">
            <ng-container *ngIf="evaluationFormService.getRoutineLabel(routine).toUpperCase() !== 'OTHER'; else otherLabel">
              {{ evaluationFormService.getRoutineLabel(routine) }}
            </ng-container>
            <ng-template #otherLabel>
              <a
                (click)="onOpenHelp($event, helpSection.EvalAssessmentDetails, evalAssessmentDetailsHelp.EcoOther)"
                class="text-underline"
                tabindex="0"
              >
                {{ evalAssessmentDetailsHelp.EcoOther }}
              </a>
            </ng-template>

            <button
              mat-icon-button
              attr.aria-label="Expand {{ !collapsedRoutines.includes(routine.id) ? 'Less' : 'More' }}"
              color="primary"
              (click)="onToggleRoutine(routine.id)"
              tabindex="0"
            >
              <mat-icon *ngIf="!collapsedRoutines.includes(routine.id)" aria-labelledby="Expand less"> expand_less </mat-icon>
              <mat-icon *ngIf="collapsedRoutines.includes(routine.id)" aria-labelledby="Expand more">expand_more</mat-icon>
            </button>
          </h3>
        </div>
        <ng-container *ngIf="!collapsedRoutines.includes(routine.id)">
          <div class="col-md-3" *ngFor="let ecoArea of ecoAreas">
            <mat-card
              class="drag-drop__card card--plain card--shadow"
              [id]="getId(ecoArea, routine)"
              cdkDropList
              (cdkDropListDropped)="dropNote($event, getId(ecoArea, routine))"
              [ngClass]="{
                'background-color--ae': getFormGroup(ecoArea, routine).get('ageExpect').value === 'AE',
                'background-color--if': getFormGroup(ecoArea, routine).get('ageExpect').value === 'IF',
                'background-color--f': getFormGroup(ecoArea, routine).get('ageExpect').value === 'F'
              }"
            >
              <div *ngIf="formGroupMatrix.get(getId(ecoArea, routine)).get('description').value">
                <div class="display-flex justify-content-between align-items-baseline">
                  <p style="overflow: hidden; word-wrap: break-word" class="mb-0">
                    {{ formGroupMatrix.get(getId(ecoArea, routine)).get('description').value }}
                  </p>
                  <button
                    aria-label="Clear Icon"
                    mat-icon-button
                    color="primary"
                    (click)="clearMatrixItem(getId(ecoArea, routine))"
                    tabindex="0"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>

                <hr class="input__divider" />

                <div [formGroup]="getFormGroup(ecoArea, routine)" *ngIf="false">
                  <div class="row">
                    <div class="col">
                      <app-autocomplete
                        label="AE/IF/F"
                        [options]="autoOptions"
                        formControlName="ageExpect"
                        (selected)="onAgeExpectSelected(getFormGroup(ecoArea, routine))"
                      ></app-autocomplete>
                      <div
                        class="display-flex align-items-center"
                        *ngIf="formGroupMatrix.get(getId(ecoArea, routine)).get('ageExpectWarning').value"
                      >
                        <mat-icon matTooltip="Value has changed, please update AE/IF/F selection." color="warn" aria-label="Warning">
                          warning
                        </mat-icon>
                        <span class="ml-2 text-danger text-sm">Note(s) changed after AE/IF/F selection.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </ng-container>
      </div>

      <div class="row align-items-start drag-drop__row" *ngIf="false">
        <div class="col-md-3 align-self-center">
          <h3 class="drag-drop__title--routine">AE / IF / F Summary</h3>
        </div>

        <div class="col-md-3" *ngFor="let ecoArea of ecoAreas">
          <mat-card class="drag-drop__card card--plain card--shadow display-flex justify-content-center align-items-center">
            <p class="text-md text-primary my-0">
              <strong>{{ calculateSummaryTotals(ecoArea.id) }}</strong>
            </p>
          </mat-card>
        </div>
      </div>
      <div class="row align-items-start drag-drop__row">
        <div class="col-md-3 align-self-center">
          <h3 class="drag-drop__title--routine">Summarize Present Levels of Development for ECO Areas</h3>
        </div>

        <div class="col-md-3" [formGroup]="formGroupSummaries">
          <mat-card class="drag-drop__card card--plain card--shadow display-flex justify-content-center align-items-center">
            <div class="w-100">
              <p class="text-md text-primary my-0">
                <app-textarea label="Summary" formControlName="positiveSkillsSummary"></app-textarea>
              </p>
            </div>
          </mat-card>
        </div>

        <div class="col-md-3" [formGroup]="formGroupSummaries">
          <mat-card class="drag-drop__card card--plain card--shadow display-flex justify-content-center align-items-center">
            <div class="w-100">
              <p class="text-md text-primary my-0">
                <app-textarea label="Summary" formControlName="acquisitionSkillsSummary"></app-textarea>
              </p>
            </div>
          </mat-card>
        </div>

        <div class="col-md-3" [formGroup]="formGroupSummaries">
          <mat-card class="drag-drop__card card--plain card--shadow display-flex justify-content-center align-items-center">
            <div class="w-100">
              <p class="text-md text-primary my-0">
                <app-textarea label="Summary" formControlName="appropriateNeedsSummary"></app-textarea>
              </p>
            </div>
          </mat-card>
        </div>
      </div>

      <div class="row align-items-start drag-drop__row">
        <div class="col-md-3">
          <app-help-directional-text>
            <p>Use interactive decision tree buttons to populate the ECO summary statement below.</p>
          </app-help-directional-text>
        </div>

        <div class="col-md-3 text-center" *ngFor="let ecoArea of ecoAreas">
          <button
            mat-raised-button
            color="primary"
            type="button"
            class="pre-wrap"
            aria-label="Launch ECO Decision Tree"
            (click)="launchEcoDecisionTree(ecoArea.id)"
            tabindex="0"
          >
            Launch ECO Decision Tree
          </button>
        </div>
      </div>
      <div class="row align-items-start drag-drop__row">
        <div class="col-md-3 align-self-center">
          <h3 class="drag-drop__title--routine">ECO Decision Description</h3>
        </div>

        <div class="col-md-3" *ngFor="let ecoArea of ecoAreas">
          <mat-card class="drag-drop__card card--plain card--shadow display-flex flex-column">
            <p class="rating__meta">
              {{ getEcoRatingDescription(ecoArea.id)?.date }}
            </p>
            <p class="rating__text">
              {{ getEcoRatingDescription(ecoArea.id)?.text }}
            </p>
          </mat-card>
        </div>
      </div>
    </mat-card>
  </form>
</div>

<div class="page-bottom-leeway"></div>
