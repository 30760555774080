<section *ngIf="isPartB" class="card-spacing-top">
  <div class="header__row">
    <h3>Learner Information</h3>
  </div>
  <div class="row">
    <div class="col-md-12">
      <mat-card class="py-1">
        <div [formGroup]="formGroup">
          <div class="row mt-2 mb-0">
            <div class="col-lg-4">
              <app-autocomplete
                *ngIf="districts"
                [options]="districts"
                formControlName="domicileDistrictId"
                label="Domicile District"
              ></app-autocomplete>
            </div>
            <div class="col-lg-4">
              <app-autocomplete
                *ngIf="buildings"
                [options]="buildings"
                formControlName="domicileBuildingId"
                label="Domicile Building"
                [readOnly]="!isEditable"
              ></app-autocomplete>
            </div>
            <div class="col-lg-4">
              <app-autocomplete
                *ngIf="districts"
                [options]="districts"
                formControlName="hostDistrictId"
                label="Host District"
                [readOnly]="!isEditable"
              ></app-autocomplete>
            </div>
          </div>
          <div class="row mt-0 mb-2">
            <div class="col-lg-12 text-right">
              <button
                *ngIf="formGroup.dirty"
                mat-raised-button
                aria-label="Cancel"
                color=""
                class="contact-button"
                tabindex="0"
                (click)="cancel()"
              >
                Cancel
              </button>
              <button
                *ngIf="formGroup.dirty"
                mat-raised-button
                aria-label="Save"
                color="primary"
                class="contact-button"
                tabindex="0"
                [disabled]="formGroup.invalid"
                (click)="save()"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</section>
