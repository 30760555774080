<div class="row justify-content-end mt-3" [formGroup]="form">
  <div class="col-12">
    <p class="text-semibold">Parent Support</p>
    <label
      class="vertical-label asterisk_input position-fix"
      for="parentSupport"
      [class.asterisk_input]="checkForAsteriskInput('parentSupport')"
    >
      Describe the training and/or support that the parents need/requested during distance learning. Include any needs for increasing
      frequency or types of communications between educators and the family.
    </label>
    <app-textarea
      id="parentSupport"
      formControlName="parentSupport"
      [hideAsterisk]="true"
      [readOnly]="readonly"
      [disabledState]="readonly"
    ></app-textarea>
  </div>
  <div class="col-12">
    <p class="text-semibold">Technology Support</p>
    <label class="vertical-label position-fix" for="technologySupport" [class.asterisk_input]="checkForAsteriskInput('technologySupport')">
      Describe the technology supports the learner will need during distance learning.
    </label>
    <app-textarea
      id="technologySupport"
      formControlName="technologySupport"
      [hideAsterisk]="true"
      [readOnly]="readonly"
      [disabledState]="readonly"
    ></app-textarea>
  </div>
</div>
