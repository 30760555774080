import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { PseSummary } from '../models/pse-summary';
import { SecondaryTransitionDeleteReason } from 'src/app/shared/models/secondary-transition-delete-reason';

@Injectable({
  providedIn: 'root',
})
export class PseSummaryService {
  constructor(private http: HttpClient, private spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/.*\/secondary-transitions.*/));
  }

  getOutcomes(iepId: string, plaafpId: string) {
    return this.http.get(`api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/outcomes`);
  }

  get(iepId: string, plaafpId: string, secondaryTransitionId: string, amendmentId?: string) {
    const url = amendmentId
      ? `api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/pse-summary/${amendmentId}`
      : `api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/pse-summary`;
    return this.http.get<PseSummary>(url);
  }

  getCurrentPse(iepId: string, plaafpId: string, secondaryTransitionId: string, amendmentId?: string) {
    const url = amendmentId
      ? `api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/pse-summary/active-inactive/${amendmentId}`
      : `api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/pse-summary/active-inactive`;
    return this.http.get<PseSummary>(url);
  }

  update(iepId: string, plaafpId: string, secondaryTransitionId: string, dto: PseSummary, amendmentId?: string) {
    const url = amendmentId
      ? `api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/pse-summary/${dto.id}/${amendmentId}`
      : `api/iep/${iepId}/plaafp/${plaafpId}/secondary-transitions/${secondaryTransitionId}/pse-summary/${dto.id}`;
    return this.http.put(url, dto);
  }

  cancel(dto: SecondaryTransitionDeleteReason) {
    return this.http.put('api/secondary-transitions/cancel', dto);
  }
}
