import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { FileDocument } from 'src/app/shared/models/file-document';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';

@Component({
  selector: 'app-st-assessment-expectation',
  templateUrl: './st-assessment-expectation.component.html',
  styleUrls: ['./st-assessment-expectation.component.scss'],
})
export class StAssessmentExpectationComponent implements OnInit {
  shortDateFormat = shortDateFormat;
  newAssessment = false;

  assessmentFormGroup = new FormGroup({
    dateOfAssessment: new FormControl('', Validators.required),
    assessment: new FormControl('', Validators.required),
    assessmentType: new FormControl('', Validators.required),
  });
  assessmentTypeOptions: KeyValuePair[] = [
    new KeyValuePair('living', 'Living'),
    new KeyValuePair('learning', 'Learning'),
    new KeyValuePair('working', 'Working'),
  ];

  documents: FileDocument[];
  get baseUrl() {
    // TODO: Replace with actual baseUrl
    return 'api/';
  }

  displayedColumns = ['date', 'assessment', 'areas', 'attachments'];
  dataSource = new MatTableDataSource([
    {
      date: new Date(Date.now()),
      assessment: 'Assessment ABC',
      areas: ['Working'],
      attachments: [{ title: 'File one' }, { title: 'File two' }],
    },
  ]);

  summaryFormGroup = new FormGroup({
    livingSummary: new FormControl(''),
    livingExpectations: new FormControl(''),
    livingOutcome: new FormControl(''),

    learningSummary: new FormControl(''),
    learningExpectations: new FormControl(''),
    learningOutcome: new FormControl(''),

    workingSummary: new FormControl(''),
    workingExpectations: new FormControl(''),
    workingOutcome: new FormControl(''),
  });
  outcomesOptions: KeyValuePair[] = [new KeyValuePair('something', 'Something')];

  constructor() {}

  ngOnInit(): void {}

  onUpload(e) {}

  onDeleteDocument(e) {}
}
