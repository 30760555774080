import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { BaseFieldComponent } from '../base-field';
import { takeWhile } from 'rxjs/operators';
import { KeyValuePair } from '../../../models/key-value-pair';
import { LoggerService } from '../../../services/logger/logger.service';

@Component({
  selector: 'app-toggle-buttons',
  templateUrl: './toggle-buttons.component.html',
  styleUrls: ['./toggle-buttons.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class ToggleButtonsComponent extends BaseFieldComponent implements OnInit, OnChanges, OnDestroy {
  @Input() className: string;
  @Input() noGroupBorder = false;
  @Input() fullWidth = false;
  @Input() hideAsterisk = false;
  toggling = false;
  optionsBacking: KeyValuePair[];
  hasInitialized = false;
  alive = true;

  @Input()
  set options(value: KeyValuePair[]) {
    // clone options. none of the other clone methods seemed to work for this
    this.optionsBacking = JSON.parse(JSON.stringify(value));
  }

  @Input() multiple = false;

  constructor(parentForm: FormGroupDirective, logger: LoggerService) {
    super(parentForm, logger);
  }

  ngOnInit() {
    if (!this.hasInitialized) {
      this.init();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.hasInitialized) {
      this.init();
      this.hasInitialized = true;
    }
    super.ngOnChanges(changes);
    if (changes.options?.currentValue && this.control) {
      const value = this.control.value || [];

      this.optionsBacking.forEach((element) => {
        element.selected = value.includes(element.key);
      });
    }

    this.checkDisabled(this.disabled || this.readOnly);
  }

  private init() {
    super.ngOnInit();

    this.hasInitialized = true;
    this.control.valueChanges.pipe(takeWhile(() => this.alive)).subscribe((newValues) => {
      if (this.toggling) {
        return;
      }

      this.optionsBacking.forEach((element) => {
        element.selected = newValues && newValues.includes(element.key);
      });
    });

    this.checkDisabled(this.disabled || this.readOnly);
  }

  ngOnDestroy() {
    this.alive = false;
  }

  toggleOption(key: any) {
    if (this.readOnly || this.disabled) {
      return;
    }

    const option = this.optionsBacking.find((x) => x.key === key);
    option.selected = !option.selected;
    this.toggling = true;
    if (!this.multiple) {
      this.optionsBacking
        .filter((x) => x.key !== key)
        .forEach((element) => {
          element.selected = false;
        });
      this.control.patchValue(option.key);
    } else {
      this.control.patchValue(this.optionsBacking.filter((x) => x.selected).map((x) => x.key));
      this.control.markAsTouched();
      this.control.markAsDirty();
    }

    this.toggling = false;
  }
}
