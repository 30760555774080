import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-iep-alignment',
  templateUrl: './iep-alignment.component.html',
  styleUrls: ['./iep-alignment.component.scss'],
})
export class IepAlignmentComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['areasOfNeed', 'goals', 'servicesSupportsActivities', 'comments'];

  constructor() {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.getData());
  }

  getData(): any[] {
    return [
      {
        areasOfNeed: 'Special Factor: Limited English Proficiency',
        goals: null,
        servicesSupportsActivities: null,
        comments: 'This ia a comment',
        aligned: false,
      },
      {
        areasOfNeed: 'Postsecondary Expectation - Working: Learner will work as a welder in an auto-body shop',
        goals: null,
        servicesSupportsActivities: null,
        comments: null,
        aligned: true,
      },
    ];
  }
}
