export enum DsDecisionHelp {
  TeamMembersInvolved = 'Team Members Involved In Decision',
  SelectingTeam = 'Selecting a Team',
}

export enum DsFormHelp {
  DataToSuggest = 'Data to Suggest',
  DiagnosisOfCondition = 'Diagnosis of a Condition',
  DiscernibleEffect = 'Discernible Effect',
  Unique = 'Unique',
  NotMeetingStandards = 'Not Meeting Standards',
  ProvisionOfServices = 'Provision of Special Education and Related Services',
}
