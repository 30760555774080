import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EcoRating, EcoRatingType } from 'src/app/evaluation/evaluation/steps/eco-wizard/eco-rating';
import { EcoRatingService } from 'src/app/evaluation/evaluation/steps/eco-wizard/eco-rating.service';
import { EcoWizardData } from 'src/app/evaluation/evaluation/steps/eco-wizard/eco-wizard.component';
import { EcoMatrixModalComponent } from 'src/app/evaluation/modals/eco-matrix-modal/eco-matrix-modal.component';
import { EcoArea } from 'src/app/evaluation/models/evaluation';
import { CaseSummary, IntakeType } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { FinalEcoRatingPkStatus } from 'src/app/shared/models/learner';
import { EcoAreaService } from 'src/app/shared/services/eco-areas/eco-area.service';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { ConfirmationDialogComponent } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-eco-final-score-pk-part-b',
  templateUrl: './eco-final-score-pk-part-b.component.html',
  styleUrls: ['./eco-final-score-pk-part-b.component.scss'],
})
export class EcoFinalScorePkPartBComponent implements OnInit {
  @Input() case: CaseSummary;

  subs = new Subscription();
  ecoForm: FormArray;
  yesNoOptions = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  existingEcoRatings: EcoRating[] = [];

  get learnerFirstName() {
    return this.case.learner.firstName;
  }

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly ecoAreaService: EcoAreaService,
    private readonly ecoRatingService: EcoRatingService,
    private readonly learnerService: LearnerService,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getEcoAreas();
  }

  getEcoAreas() {
    const sortEcoAreas = (a: EcoArea, b: EcoArea) => (a.order < b.order ? -1 : 1);

    const getEcoScoreText = (ecoRating?: EcoRating) =>
      ecoRating ? this.ecoRatingService.getSuggestedEcoRatingText(ecoRating.score, this.learnerFirstName) : '';

    this.ecoForm = this.formBuilder.array([]);

    const ecoAreasPromise = this.ecoAreaService.get().toPromise();
    const ecoRatingsIepPromise = this.ecoRatingService.getAll(this.case.id, EcoRatingType.FinalEcoPkCase).toPromise();

    Promise.all([ecoAreasPromise, ecoRatingsIepPromise]).then(([ecoAreas, ecoRatings]) => {
      ecoAreas.sort(sortEcoAreas).forEach((ecoArea) => {
        if (ecoRatings) this.existingEcoRatings = ecoRatings;

        // checking if the ecoRating exists
        const ecoRating = ecoRatings?.find((x) => x.ecoAreaId === ecoArea.id);

        const ecoAreaFormGroup = new FormGroup({
          id: new FormControl(ecoRating?.id),
          ecoAreaId: new FormControl(ecoArea.id),
          score: new FormControl(ecoRating?.score || 0),
          scoreText: new FormControl(getEcoScoreText(ecoRating)),
          label: new FormControl(ecoArea.label),
          learnerId: new FormControl(this.case.learnerId),
          ecoRatingProgress: new FormControl(ecoRating?.ecoRatingProgress, Validators.required),
        });

        const sub = ecoAreaFormGroup.valueChanges.pipe(debounceTime(500)).subscribe((x) => this.saveEcoScoreAndProgress(x));
        this.subs.add(sub);
        this.ecoForm.insert(ecoArea.order, ecoAreaFormGroup);
      });
    });
  }

  async OpenEcoMatrix(ecoAreaControl: AbstractControl) {
    const modalRef = this.dialog.open(EcoMatrixModalComponent, {
      width: '1200px',
      data: {
        ecoAreaId: ecoAreaControl.get('ecoAreaId').value,
        learnersFirstName: this.learnerFirstName,
        intakeType: IntakeType.PartB,
        type: EcoRatingType.FinalEcoPkCase,
        finalEcoPkCaseId: this.case.id,
        ecoRatingId: ecoAreaControl.get('id').value,
      } as EcoWizardData,
    });

    const result = await modalRef.afterClosed().toPromise();
    if (!result) return;

    ecoAreaControl.get('score').setValue(result.ecoRatingScore);
    ecoAreaControl.get('scoreText').setValue(this.ecoRatingService.getSuggestedEcoRatingText(result.ecoRatingScore, this.learnerFirstName));
    ecoAreaControl.get('id').setValue(result.ecoRatingId);
  }

  async saveEcoScoreAndProgress(valuesControl) {
    if (!valuesControl?.id) return;

    this.existingEcoRatings = await this.ecoRatingService.getAll(this.case.id, EcoRatingType.FinalEcoPkCase).toPromise();
    const existingEcoRating = this.existingEcoRatings.find((x) => x.id === valuesControl.id);

    if (!existingEcoRating) return;
    existingEcoRating.ecoRatingProgress = valuesControl.ecoRatingProgress;

    await this.ecoRatingService.save(existingEcoRating.finalEcoPkCaseId, null, existingEcoRating, EcoRatingType.FinalEcoPkCase).toPromise();
  }

  finalize() {
    const modalConfirmationText = {
      title: 'Confirmation',
      message: `Finalizing indicates that this is the final collection of the ECO score and 
                progress for this learner. This data will be used as the PK exit data. 
                Once you finalize, you will no longer be able to add or edit this information.
                Are you sure you want to continue?`,
      buttons: {
        ok: 'Continue',
      },
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: modalConfirmationText,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.learnerService.updateLearnerFinalEcoRatingPkStatus(this.case.learnerId, FinalEcoRatingPkStatus.Completed).subscribe((_) => {
        this.case.learner.finalEcoRatingPkStatus = FinalEcoRatingPkStatus.Completed;
      });
    });
  }
}
