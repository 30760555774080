<h3 class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  {{ data.title }}
</h3>

<div class="mat-dialog-content py-3">
  <mat-card class="mb-0">
    <div *ngIf="data.service">
      <h2>Service: {{ data.service.serviceType.label }}</h2>
      <h2>Description: {{ data.service.description }}</h2>
      <h2>Projected Start Date: {{ data.service.projectedStartDate | dateFormat }}</h2>
      <h2>Projected End Date: {{ data.service.projectedEndDate | dateFormat }}</h2>
      <h2>Location: {{ data.service.location }}</h2>
      <h2>
        Frequency: {{ data.service.frequencyNumber }}
        {{ data.service.frequencyPeriod }}
        {{ data.service.frequencyIncrement }}
        Time(s)
      </h2>
      <h2 *ngIf="data.service.scheduleServices">
        Schedule Of Services:
        {{ getScheduleOfServices(data.service.scheduleServices) }}
      </h2>
      <h2>Providers: {{ getProviderNames(data.service) }}</h2>
    </div>
    <div *ngIf="data.activity">
      <h2>Support/Activity: {{ data.activity.activitySupportType.label }}</h2>
      <h2>Description: {{ data.activity.description }}</h2>
      <h2>Frequency Period: {{ data.activity.frequencyPeriod }}</h2>
      <h2>Providers: {{ getProviderNames(data.activity) }}</h2>
    </div>
  </mat-card>
</div>

<div class="mat-dialog-actions justify-content-end">
  <button mat-raised-button aria-label="Delete Icon" color="primary" (click)="onClose()" cdkFocusInitial tabindex="0">Close</button>
</div>
