<div class="display-flex justify-content-end mb-1">
  <button
    mat-raised-button
    attr.aria-label="{{ newAssessment ? 'Cancel' : 'Add' }} New Assessment"
    color="primary"
    (click)="newAssessment = !newAssessment"
    tabindex="0"
  >
    {{ newAssessment ? 'Cancel' : 'Add' }} New Assessment
  </button>
</div>
<form [formGroup]="assessmentFormGroup" *ngIf="newAssessment">
  <div class="row">
    <div class="col-md-12 col-xl-6">
      <app-date-picker label="Date of Assessment" controlName="dateOfAssessment"></app-date-picker>
    </div>
    <div class="col-md-12 col-xl-6">
      <app-documentation-upload
        [documents]="documents"
        [baseUrl]="baseUrl"
        (upload)="onUpload($event)"
        (deleteDocument)="onDeleteDocument($event)"
      ></app-documentation-upload>
    </div>
  </div>

  <app-text-field label="Assessment" formControlName="assessment"></app-text-field>

  <div class="display-flex justify-content-between align-items-center flex-wrap">
    <app-toggle-buttons
      [multiple]="true"
      label="Assessment Type"
      [options]="assessmentTypeOptions"
      controlName="assessmentType"
    ></app-toggle-buttons>

    <button mat-raised-button aria-label="Complete" color="primary" tabindex="0">Complete</button>
  </div>
</form>

<div class="row">
  <div class="col-md-12">
    <div class="table-overflow app-table">
      <table mat-table [dataSource]="dataSource" matSort class="w-100">
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.date | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="assessment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Assessment</th>
          <td mat-cell *matCellDef="let element">
            {{ element.assessment }}
          </td>
        </ng-container>

        <ng-container matColumnDef="areas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Areas</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.areas.length > 0">
              <span *ngFor="let area of element.areas; last as isLast">
                {{ isLast ? area : area + ', ' }}
              </span>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="attachments">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Attachments</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.attachments.length > 0">
              <span *ngFor="let attachment of element.attachments; last as isLast">
                <a
                  href="javascript:void(0)"
                  tabindex="0"
                  role="link"
                  attr.aria-label="{{ isLast ? attachment.title : attachment.title + ', ' }}"
                >
                  {{ isLast ? attachment.title : attachment.title + ', ' }}
                </a>
              </span>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator [dataSource]="dataSource"></mat-paginator>
    </div>
  </div>
</div>

<hr class="input__divider mb-2" />

<form [formGroup]="summaryFormGroup" class="w-100">
  <h4 class="card-spacing-top text-primary mb-2">Summary of Assessments and Postsecondary Expectations</h4>
  <div>
    <div class="row">
      <div class="col-md-12 col-lg-2 col-xl-1">
        <h4 class="text-primary mt-2 mr-2 mb-0">Living</h4>
      </div>
      <div class="col-md-12 col-lg-10 col-xl-11">
        <app-text-field label="Summary of assessments related to Living" formControlName="livingSummary"></app-text-field>
      </div>
      <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
        <div class="position-fix--field">
          <app-text-field label="Summary of assessments related to Living" formControlName="livingExpectations"></app-text-field>
        </div>
      </div>
      <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
        <div class="position-fix--field">
          <app-autocomplete label="Outcome Area" [options]="outcomesOptions" formControlName="livingOutcome"></app-autocomplete>
        </div>
      </div>
    </div>
  </div>

  <hr class="input__divider my-2" />

  <div class="row">
    <div class="col-md-12 col-lg-2 col-xl-1">
      <h4 class="text-primary mt-2 mr-2 mb-0">Learning</h4>
    </div>
    <div class="col-md-12 col-lg-10 col-xl-11">
      <app-text-field label="Summary of assessments" formControlName="learningSummary"></app-text-field>
    </div>
    <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
      <div class="position-fix--field">
        <app-text-field label="Summary of assessments" formControlName="learningExpectations"></app-text-field>
      </div>
    </div>
    <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
      <div class="position-fix--field">
        <app-autocomplete label="Outcome Area" [options]="outcomesOptions" formControlName="learningOutcome"></app-autocomplete>
      </div>
    </div>
  </div>

  <hr class="input__divider my-2" />

  <div>
    <div class="row">
      <div class="col-md-12 col-lg-2 col-xl-1">
        <h4 class="text-primary mt-2 mr-2 mb-0">Working</h4>
      </div>
      <div class="col-md-12 col-lg-10 col-xl-11">
        <app-text-field label="Summary of assessments" formControlName="workingSummary"></app-text-field>
      </div>
      <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
        <div class="position-fix--field">
          <app-text-field label="Summary of assessments" formControlName="workingExpectations"></app-text-field>
        </div>
      </div>
      <div class="col-md-12 col-lg-10 col-xl-11 offset-lg-2 offset-xl-1">
        <div class="position-fix--field">
          <app-autocomplete label="Outcome Area" [options]="outcomesOptions" formControlName="workingOutcome"></app-autocomplete>
        </div>
      </div>
    </div>
  </div>
</form>
