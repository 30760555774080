import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { debounceTime } from 'rxjs/operators';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { CaseSummary, CaseUserSummary } from 'src/app/shared/models/case';
import { ServiceCoordinator } from 'src/app/shared/models/service-coordinator';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-service-coordinators-transfer',
  templateUrl: './service-coordinators-transfer.component.html',
  styleUrls: ['./service-coordinators-transfer.component.scss'],
})
export class ServiceCoordinatorsTransferComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() currentCase: CaseSummary;
  @Input() formGroup: FormGroup;
  @Input() multiSelect: boolean;
  @Input() hideHeaderCard: boolean;
  @Input() serviceCoordinators: ServiceCoordinator[];
  @Input() currentServiceCoordinator: CaseUserSummary;
  @Output() selected = new EventEmitter<ServiceCoordinator[]>();

  dataSource = new MatTableDataSource<ServiceCoordinator>();
  today = dayjs().startOf('day').toDate();
  selection: SelectionModel<ServiceCoordinator>;
  displayedColumns: string[] = ['select', 'fullName', 'numberOfCases'];
  shortDateFormat = shortDateFormat;

  searchFormGroup = this.fb.group({
    searchCriteria: '',
  });

  currentPage = 0;
  pageSize = 20;
  totalRows = 0;
  orderBy = 'fullname';
  orderByDescending = false;
  searchCriteria = '';

  get transferDate() {
    return this.formGroup.get('transferDate');
  }

  get currentPageInfo(): PageEvent {
    return {
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      length: this.totalRows,
    } as PageEvent;
  }

  constructor(private fb: FormBuilder, private userService: UserService) {}

  ngOnInit(): void {
    this.loadData();

    this.selection = new SelectionModel<ServiceCoordinator>(this.multiSelect, []);
    this.selection.changed.subscribe((res) => {
      this.selected.emit(res.source.selected);
    });

    this.sort?.sortChange.subscribe((sort: Sort) => {
      this.orderBy = !!sort.direction ? sort.active : 'fullname';
      this.orderByDescending = sort.direction === 'desc' ? true : false;
      this.loadData();
    });

    this.searchFormGroup
      .get('searchCriteria')
      .valueChanges.pipe(debounceTime(500))
      .subscribe((value) => this.loadData(value, true));
  }

  ngOnDestroy() {
    this.selection.changed.unsubscribe();
  }

  private async loadData(searchCriteria?: string, savedCriteria?: boolean) {
    if (savedCriteria) {
      this.searchCriteria = searchCriteria;
    }

    const res = await this.userService
      .getServiceCoordinators(
        this.currentPage + 1,
        this.pageSize,
        this.orderBy,
        this.orderByDescending,
        this.currentCase.caseUsers
          .filter((x) => x.isOwner)
          .map((x) => x.id)
          .join(','),
        this.currentCase.learnerId,
        this.searchCriteria
      )
      .toPromise();

    this.serviceCoordinators = res.items;
    this.totalRows = res.totalCount;

    this.dataSource.data = this.serviceCoordinators;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async pageChanged(event: PageEvent): Promise<void> {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadData();
  }
}
