import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CriterionProgressMonitor } from 'src/app/monitoring-progress/models/outcome-progress-monitor';
import { ProgressMonitorItem } from 'src/app/monitoring-progress/models/progress-monitor-item';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { AuthService } from '../../../../../../auth/auth.service';

@Component({
  selector: 'app-progress-data',
  templateUrl: './progress-data.component.html',
  styleUrls: ['./progress-data.component.scss'],
})
export class ProgressDataComponent implements OnInit {
  @Input() criterion: CriterionProgressMonitor;
  @ViewChild(MatSort) sort: MatSort;
  shortDateFormat = shortDateFormat;
  dataSource: MatTableDataSource<ProgressMonitorItem>;

  numericDisplayedColumns = ['actions', 'date', 'completed', 'value', 'note'];
  nonNumericDisplayedColumns = ['actions', 'date', 'completed', 'note'];

  get isPortalUser() {
    return this.authService?.isPortalUser;
  }

  constructor(private datePipe: DatePipe, private dialog: MatDialog, private readonly authService: AuthService) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.criterion.progressData);
    this.dataSource.sort = this.sort;
  }

  onViewMore(progressMonitorItem: ProgressMonitorItem) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Date',
        value: this.datePipe.transform(progressMonitorItem.date, shortDateFormat),
      },
      {
        name: 'Value',
        value: progressMonitorItem.value,
      },
      {
        name: 'Note',
        value: progressMonitorItem.note,
      },
      {
        name: 'Completed',
        value: progressMonitorItem.completed ? 'Yes' : 'No',
      },
    ];

    openViewMore(this.dialog, modalData);
  }
}
