import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { FamilyMemberService } from '../../../shared/services/family-member/family-member.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { LoginModel } from '../../auth-models';
import { AuthService, Roles } from '../../auth.service';
import { FamilyMemberPortalRegistrationDto, InvitationVerifyDto } from '../models/family-member-portal-registration-dto';

const passwordsMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password')?.value;
  const confirmedPassword = control.get('confirmedPassword')?.value;
  const hasPassword = control.get('hasPassword')?.value;

  return !hasPassword && password !== confirmedPassword ? { passwordMismatch: true } : null;
};

@Component({
  selector: 'app-family-member-portal-registration',
  templateUrl: './family-member-portal-registration.component.html',
  styleUrls: ['./family-member-portal-registration.component.scss'],
})
export class FamilyMemberPortalRegistrationComponent implements OnInit {
  familyMemberPortalRegistration: FamilyMemberPortalRegistrationDto;
  isBusy = false;
  formGroup: FormGroup;
  accessPreferences: KeyValuePair[] = [
    new KeyValuePair(
      Roles.FamilyMemberEdit,
      'I opt to register for interactive access, which includes the ability to sign documents electronically.'
    ),
    new KeyValuePair(
      Roles.FamilyMemberReadOnly,
      'I opt to register for view only access, without the ability to sign documents electronically.'
    ),
  ];
  invitationVerifyDto: InvitationVerifyDto;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private familyMemberService: FamilyMemberService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    const invitationId = this.route.snapshot.paramMap.get('invitationId');

    this.familyMemberPortalRegistration = {
      invitationId: invitationId,
    } as FamilyMemberPortalRegistrationDto;

    this.familyMemberService.validateInvitation(invitationId).subscribe(
      (response) => {
        if (response.succeeded) {
          this.invitationVerifyDto = response.value;

          // Redirect user to login page if the invitation has already been accepted
          if (response.value.isConfirmed) {
            this.reddirectToLogin();
            return;
          }

          this.initForm();

          if (this.invitationVerifyDto.hasPassword) {
            this.formGroup.controls.confirmedPassword.clearValidators();
            this.formGroup.controls.familyMemberFirstName.clearValidators();
            this.formGroup.controls.familyMemberLastName.clearValidators();

            this.formGroup.controls.confirmedPassword.updateValueAndValidity();
            this.formGroup.controls.familyMemberFirstName.updateValueAndValidity();
            this.formGroup.controls.familyMemberLastName.updateValueAndValidity();
          }

          if (this.invitationVerifyDto.hasPortalAccount) {
            this.formGroup.controls.accessPreference.clearValidators();
            this.formGroup.controls.consentAccepted.clearValidators();

            this.formGroup.controls.accessPreference.updateValueAndValidity();
            this.formGroup.controls.consentAccepted.updateValueAndValidity();
          }

          this.familyMemberPortalRegistration.familyUserId = response.value.familyUserId;
          this.familyMemberPortalRegistration.familyMemberEmail = response.value.email;

          this.formGroup.patchValue(this.familyMemberPortalRegistration);
          this.formGroup.controls.hasPassword.setValue(this.invitationVerifyDto.hasPassword);
        } else {
          this.notificationService.error(response.errors?.map((x) => x.description).join(','));
        }
      },
      () => {
        this.notificationService.error('Unable to verify invitation.');
      }
    );
  }

  initForm() {
    this.formGroup = new FormGroup(
      {
        invitationId: new FormControl('', { validators: [Validators.required] }),
        familyUserId: new FormControl(''),
        learnerFirstName: new FormControl('', { validators: [Validators.required] }),
        learnerLastName: new FormControl('', { validators: [Validators.required] }),
        learnerDateOfBirth: new FormControl('', { validators: [Validators.required] }),
        password: new FormControl('', { validators: [Validators.required] }),
        confirmedPassword: new FormControl('', { validators: [Validators.required] }),
        familyMemberFirstName: new FormControl('', { validators: [Validators.required] }),
        familyMemberLastName: new FormControl('', { validators: [Validators.required] }),
        familyMemberEmail: new FormControl('', { validators: [Validators.required, Validators.email] }),
        accessPreference: new FormControl('', { validators: [Validators.required] }),
        consentAccepted: new FormControl(null, { validators: [Validators.requiredTrue] }),
        hasPassword: new FormControl(false),
      },
      {
        validators: passwordsMatchValidator,
      }
    );
  }

  onRegister() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      this.notificationService.error('Please complete all required fields.');
      return;
    }

    const registrationDto = this.formGroup.value as FamilyMemberPortalRegistrationDto;

    if (registrationDto.consentAccepted !== true && registrationDto.familyUserId === null) {
      this.notificationService.error('You must accept the terms & conditions.');
      return;
    }

    if (registrationDto.familyUserId === null && registrationDto.password !== registrationDto.confirmedPassword) {
      this.notificationService.error('Passwords do not match');
      return;
    }

    this.isBusy = true;

    this.familyMemberService.confirmInvitation(registrationDto).subscribe(
      (response) => {
        if (response.succeeded) {
          this.notificationService.success('Confirmation succeeded');
          const loginModel = {
            password: registrationDto.password,
            email: response.value.email,
          } as LoginModel;
          this.login(loginModel);
        } else {
          this.isBusy = false;
          this.notificationService.error(response.errors?.map((x) => x.description).join(','));
        }
      },
      () => {
        this.isBusy = false;
        this.notificationService.error('Unable to confirm invitation.');
      }
    );
  }

  login(loginModel: LoginModel) {
    this.authService.login(loginModel).subscribe(
      () => {
        if (this.authService.isAuthenticated) {
          this.authService.navigateToPortal();
        } else {
          this.loginFaild();
        }

        this.isBusy = false;
      },
      () => {
        this.isBusy = false;
        this.loginFaild();
      }
    );
  }

  loginFaild() {
    this.notificationService.error('Login failed, redirecting you to login page...');
    setTimeout(() => {
      this.reddirectToLogin();
    }, 3000);
  }

  reddirectToLogin() {
    this.router.navigate(['/auth/login']);
  }
}
