<form [formGroup]="formGroup">
  <mat-card [ngClass]="{ 'card--none mb-0': noCard, 'mb-3': !noCard }">
    <mat-card-title class="mb-1" *ngIf="title" [ngClass]="{ 'text-md': noCard }">{{ title }}</mat-card-title>
    <mat-card-title class="mb-3" *ngIf="!title && !noTitle">Documentation Uploads</mat-card-title>
    <div class="display-flex justify-content-between align-items-center">
      <ng-container *ngIf="documentTitle">
        <a
          class="mb-2 mt-2"
          *ngIf="caseId && route"
          target="_blank"
          (click)="onOpenDocument()"
          tabindex="0"
          role="link"
          aria-labelledby="Document Title"
          >{{ documentTitle }}</a
        >
        <a
          *ngIf="documentLink"
          (click)="onOpenExternalDocument(documentLink)"
          tabindex="0"
          role="link"
          attr.aria-label="{{ documentTitle }}"
        >
          {{ documentTitle }}</a
        >
      </ng-container>
      <ng-container *ngIf="documentsToDisplay?.length > 0">
        <ul class="list mt-0">
          <li class="list__item" *ngFor="let document of documentsToDisplay">
            <a (click)="onOpenExternalDocument(document.link)" tabindex="0" role="link" attr.aria-label="{{ document.title }}">{{
              document.title
            }}</a>
          </li>
        </ul>
      </ng-container>

      <button
        mat-raised-button
        *ngIf="!hideUploadDocButton"
        (click)="onOpenUpload()"
        aria-label="Open Documentation Upload"
        class="mb-2 uploadBtn"
        [disabled]="locked || disableUpload()"
        tabindex="0"
      >
        {{ uploadButtonTitle }}
      </button>
    </div>
    <div class="w-50">
      <table class="table table-bordered">
        <tbody>
          <tr *ngFor="let documentInfo of sectionDocuments">
            <th>{{ documentInfo.document.title }}</th>
            <td>
              <a
                href="/api/early-access-intakes/{{ caseId }}/documents/{{ documentInfo.document.id }}"
                target="_blank"
                tabindex="0"
                role="link"
              >
                {{ documentInfo.document.fileName }}</a
              >
            </td>
            <td *ngIf="documentationTypeOptions">
              {{ documentInfo.document.type }}
            </td>
            <td *ngIf="!documentInfo.consent && !locked">
              <a href="javascript:void(0)" class="text-danger" (click)="confirmDeleteDocument(documentInfo.document)">Remove</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-card>
</form>

<ng-template #verifyDelete let-data>
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Are you sure?</h3>
  <div mat-dialog-content class="py-3">
    <mat-card>
      <p>Clicking Yes will remove this file</p>
      <p>Title: {{ data.title }}</p>
      <p>File Name: {{ data.fileName }}</p>
      <p>Deleted documents cannot be recovered.</p>
    </mat-card>
  </div>
  <mat-dialog-actions align="end">
    <button
      class="background-color--muted mr-1"
      type="button"
      mat-raised-button
      aria-label="Cancel Button"
      [mat-dialog-close]="true"
      tabindex="0"
    >
      Cancel
    </button>

    <button
      color="primary"
      type="button"
      mat-raised-button
      aria-label="Yes"
      (click)="onDeleteDocument(data.id)"
      [mat-dialog-close]="true"
      tabindex="0"
    >
      Yes
    </button>
  </mat-dialog-actions>
</ng-template>
