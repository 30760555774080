import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMatModule } from '../../app-mat.module';
import { SelectSignatoriesComponent } from './select-signatories.component';
import { ElectronicSignatureModule } from '@idoe/electronic-signature';
import { PageValidationModule } from '../page-validation/page-validation.module';

@NgModule({
  imports: [CommonModule, AppMatModule, ElectronicSignatureModule, PageValidationModule],
  declarations: [SelectSignatoriesComponent],
  exports: [SelectSignatoriesComponent, ElectronicSignatureModule],
})
export class SelectSignatoriesModule {}
