import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts a boolean to Effective or Not Effective
 */
@Pipe({ name: 'boolToEffectiveNotEffective' })
export class BooleanEffectivePipe implements PipeTransform {
  transform(value: boolean): string {
    return value ? 'Effective' : 'Not Effective';
  }
}
