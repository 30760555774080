import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IfspView } from '../../models/ifsp';
import { IfspService } from '../../services/ifsp.service';

@Component({
  selector: 'app-ifsp-nav',
  templateUrl: './ifsp-nav.component.html',
  styleUrls: ['./ifsp-nav.component.scss'],
})
export class IfspNavComponent implements OnInit {
  ifspId: string;
  caseId: string;
  ifsp: IfspView;
  notFinalized: boolean;
  constructor(private route: ActivatedRoute, private ifspSvc: IfspService) {}

  ngOnInit(): void {
    this.ifspId = this.route.snapshot.paramMap.get('ifspId');
    this.caseId = this.route.snapshot.paramMap.get('caseId');
    this.getIfsp();
    this.ifspSvc.ifspUpdated$.subscribe(() => this.getIfsp());
  }

  getIfsp() {
    this.ifspSvc.get(this.ifspId).subscribe((ifsp) => {
      this.ifsp = ifsp;
      this.notFinalized = !this.ifsp.activatedOn;
    });
  }
}
