export interface CourseActivity {
  id: string;
  name: string;
  projectedDate: string;
  living?: boolean;
  learning?: boolean;
  working?: boolean;
  status: CourseStatus;
  statusDescription?: string;
  statusDate?: Date;
}

export enum CourseStatus {
  Completed = 'Completed',
  Discontinued = 'Discontinued',
}
