import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, of } from 'rxjs';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { openPdfWindow } from 'src/app/shared/windowHelpers';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { NotificationService } from '../../../shared/services/notification.service';
import { Evaluation } from '../../models/evaluation';
import { FamilySurvey, FamilySurveyQuestionBank, QuestionBankCategory } from '../../models/family-survey';
import { FamilySurveyService } from '../../services/family-survey.service';

@Component({
  selector: 'app-survey-invite-creator-modal',
  templateUrl: './survey-invite-creator-modal.component.html',
  styleUrls: ['./survey-invite-creator-modal.component.scss'],
})
export class SurveyInviteCreatorModalComponent implements OnInit {
  isReady = false;
  teamMembers: KeyValuePair[];
  evaluation: Evaluation;
  questionBank: FamilySurveyQuestionBank[];
  questionBankK12: KeyValuePair[];
  questionBankTransition: KeyValuePair[];
  questionBankPreschool: KeyValuePair[];
  surveyCreateForm: FormGroup;
  questionBankCategory = QuestionBankCategory;
  isPk = false;
  survey: FamilySurvey;
  existingMembers: string[];
  readonlyQuestions = false;
  isSending = false;
  isSecondaryTransition = false;
  isPrinting: boolean;

  constructor(
    private dialogRef: MatDialogRef<SurveyInviteCreatorModalComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly familySurveyService: FamilySurveyService,
    private readonly formBuilder: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly reportingService: ReportingService
  ) {
    this.evaluation = this.data.evaluation;
    this.isPk = this.evaluation.learner.isPK;
    this.isSecondaryTransition = this.evaluation.learner.isSecondaryTransition;
  }

  ngOnInit(): void {
    let getSurvey = of({} as FamilySurvey);
    const getQuestionBank = this.familySurveyService.getQuestionBank(this.evaluation.id);

    if (this.evaluation.familySurveyId) {
      getSurvey = this.familySurveyService.get(this.evaluation.id, this.evaluation.familySurveyId);
    }

    combineLatest([getSurvey, getQuestionBank]).subscribe(([survey, questionBank]) => {
      this.questionBank = questionBank;
      this.questionBankK12 = questionBank
        .filter((x) => x.questionBankCategory === QuestionBankCategory.K12)
        .map((x) => new KeyValuePair(x.id, x.question));
      this.questionBankTransition = questionBank
        .filter((x) => x.questionBankCategory === QuestionBankCategory.Transition)
        .map((x) => new KeyValuePair(x.id, x.question));
      this.questionBankPreschool = questionBank
        .filter((x) => x.questionBankCategory === QuestionBankCategory.Preschool)
        .map((x) => new KeyValuePair(x.id, x.question));
      this.teamMembers = this.data.teamMembers.map((x) => new KeyValuePair(x.userId, x.name));

      this.surveyCreateForm = this.formBuilder.group({
        id: [],
        bankK12: new FormControl([]),
        bankTransition: new FormControl([]),
        bankPreschool: new FormControl([]),
        customQuestion: new FormControl(''),
        users: new FormControl([]),
      });

      if (this.evaluation.familySurveyId) {
        this.survey = survey;
        this.readonlyQuestions = true;
        this.teamMembers = this.teamMembers.filter((x) => !survey.userIds.includes(x.key));
        this.existingMembers = this.data.teamMembers.filter((x) => survey.userIds.includes(x.userId)).map((x) => x.name);

        const surveyQuestions = survey.questions.map((x) => x.questionBankId);

        this.surveyCreateForm.get('bankK12').patchValue(surveyQuestions.filter((x) => this.questionBankK12.map((y) => y.key).includes(x)));

        this.surveyCreateForm
          .get('bankTransition')
          .patchValue(surveyQuestions.filter((x) => this.questionBankTransition.map((y) => y.key).includes(x)));

        this.surveyCreateForm
          .get('bankPreschool')
          .patchValue(surveyQuestions.filter((x) => this.questionBankPreschool.map((y) => y.key).includes(x)));

        const customQuestion = survey.questions.find((x) => x.questionCategory === QuestionBankCategory.Custom);
        if (customQuestion) {
          this.surveyCreateForm.get('customQuestion').setValue(customQuestion.question);
        }
      }

      this.isReady = true;
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  /// Output is not ready yet. https://dev.azure.com/triskelle/IDOE/_workitems/edit/15312
  print() {
    // this.isPrinting = true;
    // this.reportingService.createPartBTeamMembersSurvey(this.iepId).subscribe({
    //   next: (documentId: string) => {
    //     openPdfWindow(this.learnerId, documentId);
    //     this.isPrinting = false;
    //   } ,
    //   error: (err) => {
    //     this.reportingService.handleOutputError(err);
    //     this.isPrinting = false;
    //   },
    // });
  }

  onSend() {
    const familySurvey = {
      isComplete: false,
      evaluationId: this.evaluation.id,
    } as FamilySurvey;

    const selectedIds = [
      ...this.surveyCreateForm.controls.bankK12.value,
      ...this.surveyCreateForm.controls.bankTransition.value,
      ...this.surveyCreateForm.controls.bankPreschool.value,
    ];

    const selectedQuestions = this.questionBank
      .filter((question) => selectedIds.includes(question.id))
      .map((selectedBankQuestion) => {
        return {
          question: selectedBankQuestion.question,
          questionBankId: selectedBankQuestion.id,
          questionCategory: selectedBankQuestion.questionBankCategory,
        };
      });

    const customQuestion = this.surveyCreateForm.get('customQuestion').value;
    if (customQuestion) {
      selectedQuestions.push({
        question: customQuestion,
        questionBankId: this.questionBank.find((x) => x.questionBankCategory === QuestionBankCategory.Custom).id,
        questionCategory: QuestionBankCategory.Custom,
      });
    }

    familySurvey.questions = selectedQuestions as any;
    familySurvey.userIds = this.surveyCreateForm.get('users').value;
    this.isSending = true;
    if (this.evaluation.familySurveyId) {
      familySurvey.id = this.evaluation.familySurveyId;
      familySurvey.evaluationId = this.evaluation.id;
      this.familySurveyService.update(this.evaluation.id, this.evaluation.familySurveyId, familySurvey).subscribe(
        () => {
          this.dialogRef.close(this.evaluation.familySurveyId);
          this.notificationService.success('Questionnaire Saved');
        },
        () => {
          this.isSending = false;
        }
      );
    } else {
      this.familySurveyService.add(this.evaluation.id, familySurvey).subscribe(
        (result) => {
          this.dialogRef.close(result.id);
          this.notificationService.success('Questionnaire Created');
        },
        () => {
          this.isSending = false;
        }
      );
    }
  }
}
