<ng-container *ngIf="caseSummary">
  <app-plan-status
    [enableForms]="enableForms"
    [caseSummary]="caseSummary"
    [outcomes]="outcomes"
    [minDate]="minDate"
    (planStatusAdded)="handlePlanStatusAdd($event)"
    (planStatusUpdated)="handlePlanStatusUpdate($event)"
    (planStatusDeleted)="handlePlanStatusDelete($event)"
  ></app-plan-status>
</ng-container>

<mat-accordion class="accordion accordion--short" multi>
  <mat-expansion-panel class="mat-elevation-z0" *ngFor="let outcome of filteredOutcomes">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex flex-1 flex-wrap justify-content-between align-items-center">
          <h3 class="my-0 text-normal">{{ outcome.ended ? outcome.name + ' (Ended)' : outcome.name }}</h3>
          <span style="font-size: medium" *ngIf="outcome.allCriteriaComplete">
            Based on progress monitoring information, this outcome has been met.
          </span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-plan-status-table *ngIf="showPlanStatus" [outcome]="outcome"></app-plan-status-table>

    <mat-expansion-panel class="mat-elevation-z0" *ngFor="let criterion of outcome.criteria">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="display-flex flex-1 flex-wrap justify-content-between align-items-center">
            <h3 class="my-0 text-normal">{{ criterion.name }}</h3>
            <span style="font-size: medium">
              Status:
              {{ criterionComplete(criterion) ? 'Completed' : 'Incomplete' }}</span
            >
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <app-add-outcome-progress
        *ngIf="enableForms"
        [outcomeEnded]="outcome.ended"
        [criterion]="criterion"
        [minDate]="minDate"
        (submitEvent)="onAdd($event, criterion)"
        (phaseLineSubmitEvent)="onPhaseLineAdd($event, criterion)"
        (commentSubmitEvent)="onCommentAdd($event, criterion)"
      ></app-add-outcome-progress>

      <mat-expansion-panel class="mat-elevation-z0 mt-2" *ngIf="criterion.scaleRatings?.length > 0 || criterion.milestones?.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title> <h3 class="my-0 text-normal">Scale Rating / Milestones</h3> </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col" *ngIf="criterion.scaleRatings?.length > 0">
            <h4>Scale Ratings</h4>
            <table mat-table class="w-100" [dataSource]="criterion.scaleRatings">
              <ng-container matColumnDef="rating">
                <th mat-header-cell *matHeaderCellDef>Rating</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.rating }}
                </td>
              </ng-container>
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.description }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsScaleRatings"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsScaleRatings"></tr>
            </table>
          </div>
          <div class="col" *ngIf="criterion.milestones?.length > 0">
            <h4>Milestones</h4>
            <table mat-table class="w-100" [dataSource]="criterion.milestones">
              <ng-container matColumnDef="completionDate">
                <th mat-header-cell *matHeaderCellDef>Completion Date</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.completionDate | dateFormat }}
                </td>
              </ng-container>
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.description }}
                </td>
              </ng-container>
              <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>Value</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.value }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsMilestones"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsMilestones"></tr>
            </table>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h3 class="my-0 text-normal">
              {{ criterion.measurementType === 'numeric' ? 'Previously Entered Data and Graph' : 'Previously Entered Data' }}
            </h3></mat-panel-title
          >
        </mat-expansion-panel-header>

        <app-progress-data-table
          [caseSummary]="caseSummary"
          [criterion]="criterion"
          [outcomeEnded]="outcome.ended"
          [minDate]="minDate"
        ></app-progress-data-table>

        <ng-container *ngIf="criterion.measurementType === 'numeric'">
          <div class="row">
            <div class="col-md-12 col-lg-9 col-xl-9 chart-viewer">
              <app-chart-viewer [params]="{ OutcomeCriteriaId: criterion.id }" [graph]="'objective'"></app-chart-viewer>
            </div>
          </div>

          <mat-expansion-panel class="mat-elevation-z0 mt-2">
            <mat-expansion-panel-header>
              <mat-panel-title><h3 class="my-0 text-normal">Phase Lines</h3></mat-panel-title>
            </mat-expansion-panel-header>
            <app-phase-line-table
              [caseSummary]="caseSummary"
              [criterion]="criterion"
              [enableForms]="enableForms"
              [minDate]="minDate"
            ></app-phase-line-table>
          </mat-expansion-panel>

          <mat-expansion-panel class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title><h3 class="my-0 text-normal">Comments</h3></mat-panel-title>
            </mat-expansion-panel-header>
            <app-comment-table
              [caseSummary]="caseSummary"
              [criterion]="criterion"
              [enableForms]="enableForms"
              [minDate]="minDate"
            ></app-comment-table>
          </mat-expansion-panel>
        </ng-container>
      </mat-expansion-panel>
    </mat-expansion-panel>
  </mat-expansion-panel>
</mat-accordion>
