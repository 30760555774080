<div class="header__row">
  <h3 *ngIf="showTitle">Family Contact Logs</h3>
  <div *ngIf="!showTitle"></div>
  <ng-container *ngIf="canViewAddButton">
    <button
      mat-raised-button
      aria-labelledby="Add New Family Contact Log"
      color="primary"
      (click)="onAddNewContactLog()"
      [ngClass]="{ 'mb-2': !showTitle }"
      tabindex="0"
    >
      Add New
    </button>
  </ng-container>
</div>
<div class="row">
  <div class="col-md-12">
    <mat-card class="py-1">
      <div class="table-overflow app-table">
        <table mat-table [dataSource]="dataSource" class="w-100" matSort matSortActive="dateTime" matSortDirection="desc">
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="text-center"></th>
            <td mat-cell *matCellDef="let family" class="text-center">
              <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewMore(family)" tabindex="0">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="dateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date/Time</th>
            <td mat-cell *matCellDef="let family">
              {{ family.dateTime | dateTimeFormat }}
            </td>
          </ng-container>

          <ng-container matColumnDef="personMakingContact">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Person Making Contact</th>
            <td mat-cell *matCellDef="let family">
              {{ family.user?.fullName | truncate : 140 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="personBeingContacted">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Person Being Contacted</th>
            <td mat-cell *matCellDef="let family">
              {{ family.contactee | truncate : 140 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="method">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Method</th>
            <td mat-cell *matCellDef="let family">
              {{ getMethodName(family.method) | truncate : 140 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="purpose">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Purpose</th>
            <td mat-cell *matCellDef="let family">
              {{ family.purpose | truncate : 140 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="comments">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="td__comment">Comments</th>
            <td mat-cell *matCellDef="let family" class="td__comment">
              {{ family.comment | truncate : 80 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="noResults">
            <td mat-footer-cell *matFooterCellDef colspan="7">
              <i>No results</i>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            [hidden]="dataSource.data?.length > 0"
            mat-footer-row
            *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
            class="example-second-footer-row"
          ></tr>
        </table>
        <mat-paginator [dataSource]="dataSource"></mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
