import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { KeyValuePair } from '../../shared/models/key-value-pair';
import { FamilySurveyResult } from '../models/family-survey';
import { FamilySurveyService } from '../services/family-survey.service';

@Component({
  selector: 'app-survey-responses',
  templateUrl: './survey-responses.component.html',
  styleUrls: ['./survey-responses.component.scss'],
})
export class SurveyResponsesComponent implements OnInit {
  @Input() evaluationId: string;
  @Input() surveyId: string;

  surveyResponse: FamilySurveyResult;
  qaByUsers: QaByUser[];
  qaByQuestion: QaByQuestion[];
  responded = 0;
  shortDateFormat = shortDateFormat;
  viewOptions = [new KeyValuePair('team', 'Team Member'), new KeyValuePair('question', 'Questionnaire Question')];

  viewByControl = new FormControl();

  constructor(private readonly familySurveyService: FamilySurveyService) {}

  ngOnInit(): void {
    this.reloadData();
  }

  reloadData(): void {
    this.familySurveyService.responses(this.evaluationId, this.surveyId).subscribe((surveyResponse) => {
      this.surveyResponse = surveyResponse;
      this.responsesByUser();
      this.responsesByQuestion();
      this.responded = this.surveyResponse.responses.length;
      this.viewByControl.setValue('team');
    });
  }

  responsesByUser() {
    this.qaByUsers = this.surveyResponse.responses.map((response) => ({
      name: response.user,
      answered: response.answered,
      questionAnswers: response.answers
        .map((y) => new KeyValuePair(this.surveyResponse.questions.find((z) => z.id === y.familySurveyQuestionId)?.question, y.answer))
        .sort((a, b) => {
          if (a.key > b.key) {
            return 1;
          }
          return -1;
        }),
    }));
  }

  responsesByQuestion() {
    this.qaByQuestion = this.surveyResponse.questions
      .map((question) => ({
        question: question.question,
        answers: this.surveyResponse.responses.map(
          (response) =>
            new KeyValuePair(response.user, response.answers.find((answer) => answer.familySurveyQuestionId === question.id).answer)
        ),
      }))
      .sort((a, b) => {
        if (a.question > b.question) {
          return 1;
        }
        return -1;
      });
  }
}

interface QaByUser {
  name: string;
  answered: Date;
  questionAnswers: KeyValuePair[];
}

interface QaByQuestion {
  question: string;
  answers: KeyValuePair[];
}
