import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { FamilyMember } from 'src/app/shared/models/learner';
import { LookupBase } from 'src/app/shared/models/lookup-base';
import { FirstNamePossessivePipe } from 'src/app/shared/pipes/first-name-possessive.pipe';
import { Eer } from '../../models/eer';
import { EvaluationAssignmentService } from '../../services/evaluation-assignment.service';
import { EvaluationDetailService } from '../../services/evaluation-detail.service';
import {
  EducationalNeedsAccommodation,
  EducationalNeedsInstruction,
  EducationalNeedsItem,
} from '../steps/eval-details-part-b/educational-needs/models';
import {
  LearnerPerformance,
  LearnerPerformanceGroup,
} from '../steps/eval-details-part-b/learner-performance/learner-performance.component';
import { LearnerPerformanceService } from '../steps/eval-details-part-b/learner-performance/learner-performance.service';
import { LearnerProgressGroup } from '../steps/eval-details-part-b/learner-progress/learner-progress.component';
import { LearnerProgressService } from '../steps/eval-details-part-b/learner-progress/learner-progress.service';
import { EvaluationTiming, LearnerProgressItem } from '../steps/eval-details-part-b/learner-progress/models';

@Component({
  selector: 'app-eer',
  templateUrl: './eer.component.html',
  styleUrls: ['./eer.component.scss'],
  providers: [FirstNamePossessivePipe],
})
export class EerComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren(MatSort) sorts: QueryList<MatSort>;
  @ViewChildren(MatAccordion) accordions: QueryList<MatAccordion>;
  @Input() showActions = true;
  evaluationId: string;
  eer: Eer = {} as Eer;
  isReady = false;
  learnerPerformances: LearnerPerformance[] = [];
  learnerPerformanceGroups: LearnerPerformanceGroup[] = [];
  learnerProgressGroups: LearnerProgressGroup[] = [];
  domains: LookupBase[] = [];
  allDomains: LookupBase[] = [];
  learnerNeedsLabel: string;
  gradeLabelExpectations: string;
  gradeLabelInstruction: string;
  shortDateFormat = shortDateFormat;
  academicConcerns = '';
  displayedColumns = ['areaOfConcern', 'performanceNarrative', 'expectedPerformanceNarrative'];
  displayedEducationalItemsColumns = ['domains', 'conceptsAndSkillsNeeds', 'conceptsAndSkillsExpectations'];
  displayedEducationalInstructionColumns = ['domains', 'methodAndDeliveryNeeds', 'methodAndDeliveryInstruction'];
  displayedEducationalAccommodationColumns = ['domains', 'accommodations', 'needs', 'routinelyAvailable'];
  displayedColumnsProgress = ['currentProgress', 'expectedProgress'];
  educationalNeedsItemsDataSource: MatTableDataSource<EducationalNeedsItem>;
  educationalNeedsInstructionsDataSource: MatTableDataSource<EducationalNeedsInstruction>;
  educationalNeedsAccommodationsDataSource: MatTableDataSource<EducationalNeedsAccommodation>;

  evaluationTimingOptions: KeyValuePair[] = [];
  notEvaluatedReasonOptions: KeyValuePair[] = [];
  evaluationTimingPriorOptions = [
    new KeyValuePair('prior', 'Intervention completed prior to evaluation'),
    new KeyValuePair('during', 'Intervention started during evaluation'),
  ];
  evaluationTimingEnum = EvaluationTiming;
  strategyColumns = ['name', 'description', 'personsResponsible', 'isMostEffective'];

  getEvaluationTimingOptionLabel(id) {
    return this.evaluationTimingOptions.find((x) => x.key === id)?.value;
  }

  getTimingLabel(id: string) {
    return this.notEvaluatedReasonOptions?.find((x) => x.key === id)?.value;
  }

  get concernsAddressedLabel() {
    return `What is the concern being addressed and how were the intervention strategies designed to meet ${this.eer.learner.firstName}'s needs?`;
  }

  constructor(
    private readonly evaluationDetailService: EvaluationDetailService,
    private readonly learnerPerfService: LearnerPerformanceService,
    private readonly learnerProgressService: LearnerProgressService,
    private readonly assignmentService: EvaluationAssignmentService,
    private readonly authService: AuthService,
    private readonly route: ActivatedRoute,
    private firstNamePossessivePipe: FirstNamePossessivePipe
  ) {}

  ngOnInit(): void {
    this.evaluationId = this.route.parent.snapshot.params.evaluationId;
    this.getDomainsFromAssignments(this.evaluationId);
    this.getEer(this.evaluationId);
    this.getLearnerPerformances(this.evaluationId);
  }

  getDomainsFromAssignments(evaluationId: string) {
    this.assignmentService.get(evaluationId).subscribe((assignments) => {
      const domains = assignments
        .map((a) => {
          return {
            label: a.domainArea.label,
            id: a.id,
            domainAreaId: a.domainAreaId,
          };
        })
        .sort((a, b) => {
          const nameA = a.label.toUpperCase();
          const nameB = b.label.toUpperCase();
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        });
      this.allDomains = domains;
      domains.forEach((domain) => {
        const domainAlreadyExists = this.domains.find((x) => x.id === domain.domainAreaId);
        const labelAlreadyExists = this.domains.find((x) => x.label === domain.label);
        if (!domainAlreadyExists || !labelAlreadyExists) {
          this.domains.push(domain);
        }
      });
    });
  }

  getEer(evaluationId: string) {
    this.evaluationDetailService.getEer(evaluationId).subscribe(
      (data) => {
        this.eer = data;
        this.isReady = true;
        this.learnerNeedsLabel = `${this.eer.learner.firstName}'s Needs`;
        const transformedGrade = this.eer.grade == null ? `Age ${this.eer.learner.age}` : `${this.eer.grade} Grade`;
        this.gradeLabelExpectations = `${transformedGrade} Expectations`;
        this.gradeLabelInstruction = `${transformedGrade} Instruction`;
        this.setupLearnerProgressTableData();
        this.setEducationalNeedsDataSources();
        this.setAcademicConcernsList();
        this.setupEvaluationTimingOptions();
        this.getNotEvaluatedReasons();
      },
      (err) => console.error(err)
    );
  }

  setEducationalNeedsDataSources() {
    this.educationalNeedsItemsDataSource = new MatTableDataSource<EducationalNeedsItem>(this.eer.educationalNeeds?.educationalNeedsItems);
    this.educationalNeedsInstructionsDataSource = new MatTableDataSource<EducationalNeedsInstruction>(
      this.eer.educationalNeeds?.educationalNeedsInstructions
    );
    this.educationalNeedsAccommodationsDataSource = new MatTableDataSource<EducationalNeedsAccommodation>(
      this.eer.educationalNeeds?.accommodations
    );
    this.educationalNeedsItemsDataSource.sort = this.sort;
    this.educationalNeedsInstructionsDataSource.sort = this.sort;
    this.educationalNeedsAccommodationsDataSource.sort = this.sort;
  }

  onPrint() {
    window.print();
  }

  setAcademicConcernsList() {
    let count = 1;
    this.eer.otherFactors.schoolSuccessFactors.forEach((factor) => {
      this.academicConcerns +=
        count === this.eer.otherFactors.schoolSuccessFactors.length
          ? count > 1
            ? `and ${factor.label}`
            : factor.label
          : `${factor.label}, `;
      count++;
    });
  }

  getAddress(member: FamilyMember) {
    let fullAddress = member.streetAddress ? member.streetAddress : '';
    fullAddress += member.city ? `, ${member.city}` : '';
    fullAddress += member.state ? `, ${member.state}` : '';
    fullAddress += member.zipCode ? ` ${member.zipCode}` : '';
    if (fullAddress.length === 0) {
      return null;
    }
    return fullAddress;
  }

  closeWindow(): void {
    window.close();
  }

  getGroupValue(group) {
    const dataSource = new MatTableDataSource<LearnerPerformance>(group);
    dataSource.sort = this.sort;
    return dataSource;
  }

  getLpGroupValue(group): LearnerProgressItem[] {
    return group.value;
  }

  getLearnerPerformanceDataSource(lpDomain: LookupBase): MatTableDataSource<LearnerPerformance> {
    const dataSource = new MatTableDataSource<LearnerPerformance>(
      this.learnerPerformances.filter((lp) => lp.domainAreas.every((domain) => domain.id === lpDomain.id))
    );
    dataSource.sort = this.sort;
    return dataSource;
  }

  getLearnerProgressDataSource(domain: LookupBase, i: number) {
    const dataSource = new MatTableDataSource<LearnerProgressItem>(
      this.eer.learnerProgress?.learnerProgressItems?.filter(
        (lpi) => lpi.domainAreas.every((d) => d.id === domain.id) && (lpi.currentProgress || lpi.expectedProgress)
      )
    );

    dataSource.sort = this.sorts.toArray()[i];
    return dataSource;
  }

  getLearnerProgressByDomain(domain: LookupBase) {
    return this.eer.learnerProgress?.learnerProgressItems?.filter(
      (lp) => lp.domainAreas.every((d) => d.id === domain?.id && d.label === domain?.label) && lp.isSubmitted
    );
  }

  getLearnerProgressGroupDataSource(learnerProgressGroup, i) {
    const dataSource = new MatTableDataSource<LearnerProgressItem>();
    const itemsToAdd = [];
    learnerProgressGroup.value.forEach((group) => {
      if (group.currentProgress || group.expectedProgress) {
        itemsToAdd.push(group);
      }
    });
    dataSource.data = itemsToAdd;
    dataSource.sort = this.sorts.toArray()[i + this.domains.length];
    return dataSource;
  }

  getLearnerProgressDocuments(domain: LookupBase) {
    return this.eer.learnerProgress?.learnerProgressItems
      ?.filter((lpi) => lpi.domainAreas.every((d) => d.id === domain?.id && d.label === domain?.label) && lpi.documents.length > 0)
      .flatMap((x) => x.documents);
  }

  getLearnerProgressGroupDocuments(lpGroup) {
    let result = [];
    lpGroup.value.forEach((group) => {
      result = [...result, ...group.documents];
    });
    return result;
  }

  getDomainLabel(key) {
    const keys = key.split('/');
    let label = '';
    keys.forEach((k, index) => {
      const evalDomainId = k.split('|')[0];
      const evalAssignId = k.split('|')[1];
      label =
        index === keys.length - 1
          ? (label = label.concat(this.allDomains?.find((x) => x.id === evalDomainId && x.id === evalAssignId)?.label))
          : (label = label.concat(this.allDomains?.find((x) => x.id === evalDomainId && x.id === evalAssignId)?.label, ', '));
    });
    return label;
  }

  private getLearnerPerformances(evaluationId: string) {
    this.learnerPerfService.getAll(evaluationId).subscribe((lps) => {
      this.learnerPerformances = lps;
      this.setupLearnerPerfTableData();
    });
  }

  private setupLearnerPerfTableData() {
    this.learnerPerformanceGroups = [];
    this.learnerPerformances.forEach((lp) => {
      if (lp.domainAreas.length > 1) {
        const domainKey = lp.domainAreas
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((x) => `${x.id}`)
          .join('/');
        const group = this.learnerPerformanceGroups[domainKey];
        if (group) {
          this.learnerPerformanceGroups[domainKey].push(lp);
        } else {
          this.learnerPerformanceGroups[domainKey] = [lp];
        }
      }
    });
  }

  private setupLearnerProgressTableData() {
    this.learnerProgressGroups = [];
    this.eer.learnerProgress?.learnerProgressItems?.forEach((lp) => {
      if (lp.domainAreas.length > 1) {
        const domainKey = lp.domainAreas
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((x) => `${x.id}`)
          .join('/');
        const group = this.learnerProgressGroups[domainKey];
        if (group) {
          this.learnerProgressGroups[domainKey].push(lp);
        } else {
          this.learnerProgressGroups[domainKey] = [lp];
        }
      }
    });
  }

  private getNotEvaluatedReasons() {
    this.learnerProgressService.getNotEvaluatedReasons(this.evaluationId).subscribe((reasons) => {
      this.notEvaluatedReasonOptions = reasons
        .map(
          (r) =>
            new KeyValuePair(r.id, r.label.replace("The learner's", this.firstNamePossessivePipe.transform(this.eer.learner.firstName)))
        )
        .sort((a, b) => (b.value.toUpperCase() === 'OTHER' ? -1 : 1));
    });
  }

  private setupEvaluationTimingOptions() {
    this.evaluationTimingOptions.push(
      new KeyValuePair(
        EvaluationTiming.IsPriorToEvaluation.toString(),
        'Review of response to instruction/interventions implemented prior to and/or during the evaluation for this domain.'
      ),
      new KeyValuePair(
        EvaluationTiming.NotBeingEvaluated.toString(),
        `${this.firstNamePossessivePipe.transform(
          this.eer.learner.firstName
        )} progress will be summarized, but response to instruction/interventions is not being evaluated for this domain.`
      )
    );
  }
}
