export enum IntakeSections {
  ChildInfo = 'ChildInfo',
  FamilyInfo = 'FamilyInfo',
  InsuranceInfo = 'InsuranceInfo',
  ProviderInfo = 'ProviderInfo',
  MedicalHistory = 'MedicalHistory',
  Nutrition = 'Nutrition',
  DentalVisionHearing = 'DentalVisionHearing',
  HealthConcerns = 'HealthConcerns',
  NextSteps = 'NextSteps',
  NextStepsInitialConsent = 'NextSteps_InitialConsent',
  NextStepsPostReferralConsent = 'NextSteps_PostReferral',
}
