import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValuePair } from '../../models/key-value-pair';

@Component({
  selector: 'app-signature-modal',
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.scss'],
})
export class SignatureModalComponent implements OnInit {
  formGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    signedOn: new FormControl(new Date()),
    role: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    pin: new FormControl('', Validators.required),
    agree: new FormControl('', Validators.required),
  });

  agreeDisagree: KeyValuePair[] = [
    new KeyValuePair(true, 'I agree with the above findings and results.'),
    new KeyValuePair(false, 'I do not agree with the above findings and results. (Statement of conclusions must be provided).'),
  ];

  settings = {
    button: 'Add Signature',
    title: 'Signature',
  };

  constructor(
    private dialogRef: MatDialogRef<SignatureModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { noPin?: boolean; label?: string }
  ) {}

  ngOnInit(): void {
    Object.assign(this.settings, this.data);

    if (this.data?.noPin) this.formGroup.controls.pin.clearValidators();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close(this.formGroup.value);
  }
}
