import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logged-out-sso',
  templateUrl: './logged-out-sso.component.html',
  styleUrls: ['./logged-out-sso.component.scss'],
})
export class LoggedOutSsoComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => {
      window.location.href = 'https://portal.ed.iowa.gov/';
    }, 5000); //5s
  }

  onGoHome() {
    this.router.navigate(['/']);
  }
}
