import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EcoDataHistory } from '../../../evaluation/evaluation/steps/eco-wizard/eco-rating';
import { OperationResultWithValue } from '../../../shared/models/operation-result';
import { ElpaDto } from '../models/elpa.model';
import { IsaspDto } from '../models/isasp.model';

export interface StudentAttendanceSummary {
  SourceId: string;
  OriginatingBatchId: string;
  Orphaned: any;
  StartDate: string;
  DaysAttended: string;
  LastRequested: number;
  UnexcusedAbsences: number;
  SchoolYear: number;
  Solution: string;
  ExcusedAbsences: number;
  PinnedRefId: boolean;
  Resident: string;
  StudentSchoolEnrollmentRefId: string;
  ZoneId: string;
  EndDay: number;
  RefId: string;
  DaysInMembership: string;
  Timestamp: number;
  Created: number;
  LastReceived: number;
  ContextId: string;
  StartDay: number;

  EndDate: string;
  SchoolInfoRefId: string;
  StudentPersonalRefId: string;
  Deleted: any;
  Domain: string;
  Dmark: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class DataHistoryService {
  private baseUrl = (caseId: string) => `api/DataHistory/${caseId}`;
  private baseLearnerUrl = (learnerId: string) => `api/learners/${learnerId}`;
  private baseIfspUrl = () => 'api/ifsp/';

  constructor(private http: HttpClient) {}

  getElpas(caseId: string, stateAssignedId: string): Observable<ElpaDto[]> {
    return this.http.get<ElpaDto[]>(this.baseUrl(caseId) + `/elpas/${stateAssignedId}`);
  }

  getIsasps(caseId: string, stateAssignedId: string): Observable<IsaspDto[]> {
    return this.http.get<IsaspDto[]>(this.baseUrl(caseId) + `/isasps/${stateAssignedId}`);
  }
  getSecondaryTransition(caseId: string, stateAssignedId: string): Observable<any> {
    return this.http.get<IsaspDto[]>(this.baseUrl(caseId) + `/secondaryTransition/${stateAssignedId}`);
  }
  getAttendanceData(caseId: string, learnerId: string): Observable<any> {
    return this.http.get<StudentAttendanceSummary[]>(this.baseUrl(caseId) + `/StudentAttendanceSummary/${learnerId}`);
  }
  getBehaviorData(caseId: string, learnerId: string): Observable<any> {
    return this.http.get<any[]>(this.baseUrl(caseId) + `/DisciplineSummary/${learnerId}`);
  }
  getLearnerMedicalHistory(learnerId: string): Observable<any> {
    return this.http.get<any[]>(this.baseLearnerUrl(learnerId) + '/medical-history');
  }
  getIfspsByCaseId(caseId: string): Observable<any> {
    return this.http.get<any[]>(this.baseIfspUrl() + `getByCaseId/${caseId}`);
  }
  getEvaluationsByLearnerId(learnerId: string, caseId: string): Observable<any> {
    return this.http.get<any[]>(`${this.baseUrl(caseId)}/eligibility/${learnerId}`);
  }
  getEcoRatings(learnerId: string): Observable<OperationResultWithValue<EcoDataHistory[]>> {
    return this.http.get<OperationResultWithValue<EcoDataHistory[]>>(this.baseLearnerUrl(learnerId) + '/ecoHistory');
  }
}
