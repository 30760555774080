<mat-form-field class="w-100">
  <mat-label
    [ngClass]="{
      asterisk_input: control.hasError('required') || showAsterisk,
      'asterisk_input--hide': hideAsterisk
    }"
    >{{ label }}</mat-label
  >
  <input
    matInput
    type="text"
    [class]="klass"
    id="{{ controlId }}"
    [formControl]="control"
    [placeholder]="placeholder"
    attr.aria-required="{{ control.hasError('required') || showAsterisk ? 'true' : 'false' }}"
    [attr.disabled]="disabledState ? true : null"
    (blur)="onBlurEvt($event)"
  />

  <span matSuffix>
    <mat-icon matSuffix (click)="showPicker()">access_time</mat-icon>
  </span>
</mat-form-field>
<mat-error>
  <app-field-error-display [control]="control"></app-field-error-display>
</mat-error>
