<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Add Family Members or Providers</h3>

<div mat-dialog-content class="pt-3 pb-5">
  <mat-card>
    <form [formGroup]="formGroup">
      <app-text-field formControlName="searchTerm" label="Search" (keyup)="onFilter()"></app-text-field>
    </form>
  </mat-card>
  <mat-card
    class="mt-3 p-0 member__card"
    *ngIf="availableFamilyProviders.length === 0 && availableFamilyMembers.length === 0 && selectedFamilyParticipants.length === 0"
  >
    <div class="display-flex justify-content-between align-items-center">
      <span class="text-sm text-muted">No Family Members or Providers Available. Please add before selecting.</span>
    </div>
  </mat-card>
  <mat-card class="mt-3 p-0 member__card" *ngIf="availableFamilyProviders && availableFamilyProviders.length > 0">
    <ul class="member__list">
      <ng-container *ngFor="let provider of availableFamilyProviders">
        <li class="member__item">
          <div class="display-flex justify-content-between align-items-center">
            <span>
              {{ displayProviderName(provider) }}
              <span class="text-sm text-muted"> (Family Provider)</span>
            </span>
            <button mat-flat-button aria-label="Add Button" color="accent" (click)="addFamilyProvider(provider)" tabindex="0">Add</button>
          </div>
        </li>
      </ng-container>
    </ul>
  </mat-card>

  <mat-card class="card-spacing-top p-0 member__card" *ngIf="availableFamilyMembers && availableFamilyMembers.length > 0">
    <ul class="member__list">
      <ng-container *ngFor="let member of availableFamilyMembers">
        <li class="member__item">
          <div class="display-flex justify-content-between align-items-center">
            <span>
              {{ displayProviderName(member) }}
              <span class="text-sm text-muted"> (Family Member)</span>
            </span>
            <button mat-flat-button aria-label="Add Button" color="accent" (click)="addFamilyMember(member)" tabindex="0">Add</button>
          </div>
        </li>
      </ng-container>
    </ul>
  </mat-card>

  <mat-card class="card-spacing-top p-0 member__card" *ngIf="selectedFamilyParticipants && selectedFamilyParticipants.length > 0">
    <ul class="member__list">
      <ng-container *ngFor="let participant of selectedFamilyParticipants">
        <li class="member__item">
          <div class="display-flex justify-content-between align-items-center">
            <span>
              {{ displayProviderName(participant) }}
              <span class="text-sm text-muted">
                (Family
                {{ participant.agency ? 'Provider' : 'Participant' }})</span
              >
            </span>
            <button mat-flat-button aria-label="Delete Icon" color="accent" (click)="removeFamilyParticipant(participant)" tabindex="0">
              Remove
            </button>
          </div>
        </li>
      </ng-container>
    </ul>
  </mat-card>
</div>

<div mat-dialog-actions class="actions">
  <button mat-button aria-label="Cancel Button" class="background-color--muted" (click)="onNoClick()" tabindex="0">Cancel</button>
  <button mat-flat-button aria-label="Add to List" color="primary" *ngIf="selectedFamilyParticipants" (click)="onAccept()" tabindex="0">
    Add to List
  </button>
</div>
