import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, startWith } from 'rxjs/operators';
import { AlertConfig } from '../shared/components/page-alert/page-alert.component';
import { DateFormatPipe } from '../shared/pipes/date-transform.pipe';
import { LearnerService } from '../shared/services/learner/learner.service';
import { routerAnimation } from './learner-route-animations';

@Component({
  selector: 'app-learner-management',
  templateUrl: './learner-management.component.html',
  styleUrls: ['./learner-management.component.scss'],
  providers: [DateFormatPipe],
  animations: [routerAnimation()],
})
export class LearnerManagementComponent implements OnInit, OnDestroy {
  learnerId: string;
  subscriptions = new Subscription();

  get learnerSummary$() {
    return this.learnerService.learnerSummaryChange$;
  }

  sifUpdateAlert: AlertConfig;

  public routeAnimation$: Observable<number>;

  constructor(
    private route: ActivatedRoute,
    private learnerService: LearnerService,
    private router: Router,
    private readonly dateFormatPipe: DateFormatPipe
  ) {}

  async ngOnInit(): Promise<void> {
    this.learnerId = this.route.snapshot.paramMap.get('learnerId');
    this.subscriptions.add(
      this.learnerService.learnerSummaryChange$.subscribe((x) => {
        this.sifUpdateAlert = null;
        if (!(x && x.sifIdUpdatedOn)) return;

        this.sifUpdateAlert = {
          status: 'info',
          title: '',
          message: `${x.fullName}'s information has been updated to match the details in the SIF as of ${this.dateFormatPipe.transform(
            x.sifIdUpdatedOn
          )}.`,
        };
      })
    );

    this.learnerService.learnerSummary = await this.loadData(this.learnerId);

    if (this.learnerService.learnerSummary === null) {
      await this.router.navigate(['/not-found']);
      return;
    }

    this.routeAnimation$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith(undefined as any),
      map(() => this.route.snapshot),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route.data?.num;
      }),
      map((num) => (num === undefined ? -1 : num))
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private loadData(learnerId): Promise<any> {
    return this.learnerService
      .getLearnerSummary(learnerId)
      .pipe(catchError((err) => (err.status === 404 ? of(null) : throwError(err))))
      .toPromise();
  }
}
