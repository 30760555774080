<mat-card *ngIf="transitionPlanning?.id">
  <mat-card-content>
    <div class="row">
      <div class="col">
        <app-transition-planning-determination
          [formGroup]="formGroup"
          [transitionPlanning]="transitionPlanning"
          [caseSummary]="caseSummary"
          [determinationStatementFromFormData]="false"
          (ecoRatingUpdateEvent)="ecoRatingUpdated()"
        ></app-transition-planning-determination>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<mat-spinner *ngIf="!isPkPartB && !transitionPlanning?.id" [diameter]="20" class="spinner"></mat-spinner>

<mat-card *ngIf="isPkPartB">
  <app-case-eco-rating
    [formGroup]="formGroup"
    [ecoRatingProgresses]="ecoRatingProgresses"
    [caseSummary]="caseSummary"
    (ecoRatingUpdateEvent)="ecoRatingUpdated()"
  ></app-case-eco-rating>
</mat-card>
