import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { MeetingParticipantRead } from 'src/app/learner-management/family-meeting/participants-list/meeting-participants';
import { AreYouSureComponent } from '../../components/are-you-sure-modal/are-you-sure.component';
import { UserService } from '../../services/user/user.service';
import { ParticipantContainer, ParticipantType } from './participant-type';

@Component({
  selector: 'app-participant-search-modal',
  templateUrl: './participant-search-modal.component.html',
  styleUrls: ['./participant-search-modal.component.scss'],
})
export class ParticipantSearchModalComponent implements OnInit {
  initialFamilyProviders: MeetingParticipantRead[] = [];
  initialFamilyMembers: MeetingParticipantRead[] = [];
  initialUsers: MeetingParticipantRead[] = [];

  currentParticipants: MeetingParticipantRead[] = [];

  availableParticipantContainer: ParticipantContainer[] = [];
  selectedParticipants: MeetingParticipantRead[] = [];

  formGroup = new FormGroup({
    searchTerm: new FormControl(''),
    includeResidentInResults: new FormControl(false),
  });
  participantType = ParticipantType;
  users: MeetingParticipantRead[] = [];
  filteredUsers: MeetingParticipantRead[] = [];

  activeSearch = false;

  get availableFamilyProviderParticipants() {
    return this.availableParticipantContainer.find((x) => x.type === ParticipantType.FamilyProvider).participants;
  }

  set availableFamilyProviderParticipants(participants: MeetingParticipantRead[]) {
    this.availableParticipantContainer.find((x) => x.type === ParticipantType.FamilyProvider).participants = participants;
  }

  get availableFamilyMemberParticipants() {
    return this.availableParticipantContainer.find((x) => x.type === ParticipantType.FamilyMember).participants;
  }

  set availableFamilyMemberParticipants(participants: MeetingParticipantRead[]) {
    this.availableParticipantContainer.find((x) => x.type === ParticipantType.FamilyMember).participants = participants;
  }

  get availableUserParticipants() {
    return this.availableParticipantContainer.find((x) => x.type === ParticipantType.User).participants;
  }

  set availableUserParticipants(participants: MeetingParticipantRead[]) {
    this.availableParticipantContainer.find((x) => x.type === ParticipantType.User).participants = participants;
  }

  constructor(
    private readonly userService: UserService,
    private dialogRef: MatDialogRef<ParticipantSearchModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentParticipants: MeetingParticipantRead[];
      learnerId: string;
      providers: MeetingParticipantRead[];
      familyMembers: MeetingParticipantRead[];
      users: MeetingParticipantRead[];
    },
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // this.users = this.data.users;
    this.currentParticipants = this.data.currentParticipants ?? [];
    // this.filteredUsers = this.data.users.filter((x) =>
    //   this.formGroup.get('includeResidentInResults').value ? x.isByResident : !x.isByResident
    // );
    // this.fillAvailableParticipantContainers();
    // this.populateInitialParticipants();
  }

  onSearch() {
    const searchTerm = this.formGroup.get('searchTerm').value;
    if (!!searchTerm) {
      this.activeSearch = true;
      this.availableParticipantContainer = [];
      this.userService
        .searchParticipants(searchTerm, this.data.learnerId)
        .pipe(finalize(() => (this.activeSearch = false)))
        .subscribe((res) => {
          const participants = res.filter((participant) => {
            const index = this.currentParticipants?.findIndex((x) => x.fullName === participant.fullName);
            if (index === -1) return participant;
          });
          const providers = participants.filter((x) => x.type === ParticipantType.FamilyProvider);
          const familyMembers = participants.filter((x) => x.type === ParticipantType.FamilyMember);
          const users = participants.filter((x) => x.type === ParticipantType.User);

          this.fillAvailableParticipantContainers(providers, familyMembers, users);
          this.populateInitialParticipants();
        });
    }
  }

  fillAvailableParticipantContainers(providers, familyMembers, users) {
    this.availableParticipantContainer.push({
      type: ParticipantType.FamilyProvider,
      participants: providers,
    });
    this.availableParticipantContainer.push({
      type: ParticipantType.FamilyMember,
      participants: familyMembers,
    });
    this.availableParticipantContainer.push({
      type: ParticipantType.User,
      participants: users,
    });
  }

  populateInitialParticipants() {
    this.initialFamilyProviders = this.availableFamilyProviderParticipants.slice();
    this.initialFamilyMembers = this.availableFamilyMemberParticipants.slice();
    this.initialUsers = this.availableUserParticipants.slice();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onAccept() {
    const warning = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion: "Clicking Yes will send a new meeting notice. Make sure you've added all necessary participants.",
      },
    });

    warning.afterClosed().subscribe((res) => {
      if (res) {
        this.dialogRef.close(this.selectedParticipants);
      }
    });
  }

  addParticipant(member: MeetingParticipantRead, participantType: ParticipantType) {
    this.selectedParticipants.push(member);
    switch (participantType) {
      case ParticipantType.FamilyProvider: {
        this.availableFamilyProviderParticipants = this.availableFamilyProviderParticipants.filter((fp) => fp.id !== member.id);
        break;
      }
      case ParticipantType.FamilyMember: {
        this.availableFamilyMemberParticipants = this.availableFamilyMemberParticipants.filter((fp) => fp.id !== member.id);
        break;
      }
      case ParticipantType.User: {
        this.availableUserParticipants = this.availableUserParticipants.filter((fp) => fp.userId !== member.userId);
        break;
      }
    }
  }

  removeParticipant(member: MeetingParticipantRead) {
    if (member.familyMemberId) {
      this.availableFamilyMemberParticipants.push(member);
    } else if (member.agencyId || member.medicalSpecialistId || member.learnerId) {
      this.availableFamilyProviderParticipants.push(member);
    } else if (member.userId) {
      this.availableUserParticipants.push(member);
    }

    this.selectedParticipants = this.selectedParticipants.filter((fp) => fp !== member);
  }

  onFilter() {
    this.filterAvailableParticipantsByNotSelected('availableFamilyProviderParticipants', 'initialFamilyProviders');
    this.filterAvailableParticipantsByNotSelected('availableFamilyMemberParticipants', 'initialFamilyMembers');
    this.filterAvailableParticipantsByNotSelected('availableUserParticipants', 'initialUsers');

    if (this.formGroup.controls.includeResidentInResults.value === true) {
      this.filterAvailableParticipantsByResidentAeaDistrict('availableUserParticipants', true);
    } else {
      this.filterAvailableParticipantsByResidentAeaDistrict('availableUserParticipants', false);
    }

    if (this.formGroup.controls.searchTerm.value) {
      const filterValue = this.formGroup.controls.searchTerm.value.toLowerCase();
      this.filterAvailableParticipantsByName('availableFamilyProviderParticipants', filterValue);
      this.filterAvailableParticipantsByName('availableFamilyMemberParticipants', filterValue);
      this.filterAvailableParticipantsByName('availableUserParticipants', filterValue);
    }
  }

  displayProviderName(participant: MeetingParticipantRead) {
    return participant.agency && participant.fullName
      ? `${participant.fullName} - ${participant.agency}`
      : participant.fullName
      ? `${participant.fullName}`
      : `${participant.agency}`;
  }

  areAnyParticipants(): boolean {
    return this.availableParticipantContainer.some((ap) => ap.participants.length > 0);
  }

  private filterAvailableParticipantsByNotSelected(availableParticipants: string, initialParticipants: string) {
    this[availableParticipants] = [];
    this[availableParticipants] = this[initialParticipants].filter((fp) => {
      return !this.selectedParticipants?.includes(fp);
    });
  }

  private filterAvailableParticipantsByName(availableParticipants: string, filterValue: any) {
    this[availableParticipants] = this[availableParticipants].filter(
      (tm) => tm.fullName?.toLowerCase().includes(filterValue) || tm.agency?.toLowerCase().includes(filterValue)
    );
  }

  private filterAvailableParticipantsByResidentAeaDistrict(availableParticipants: string, isByResidentValue: boolean) {
    if (isByResidentValue === false) {
      this[availableParticipants] = this.users.filter((tm) => tm.isByResident === true || tm.isByResident === false);
    } else {
      this[availableParticipants] = this.users.filter((tm) => tm.isByResident === false);
    }
  }
}
