import { ConsentStatus } from 'src/app/shared/models/fiie-consent/consent-status';
import { TaggedItem } from 'src/app/tags/tagged-item';
import { ServiceActivityType } from '../../shared/models/service-activity-type';
import { ProviderUser, User } from '../../shared/models/user';
import { GetAllOutcomesForServiceDto } from '../models/outcome-models';
import { IfspModification } from './ifsp-modification';

export interface IfspServiceDto {
  id: string;
  status?: IfspServiceStatus;
  outcomes?: Array<any>;
  serviceId?: string;
  service?: string;
  typeOfService: string;
  locationId?: string;
  locationText?: string;
  justificationLocation?: string;
  how: IfspServiceHow[];
  howText: string;
  howExplanation: string;
  withId?: string;
  with?: string;
  withText: string;
  agency?: string;
  agencyText: string;
  provider?: string;
  providerText: string;
  length?: number;
  frequencyNumber?: number;
  frequencyPeriod?: string;
  startDate?: Date;
  familyDeclined?: boolean;
  primaryServiceProvider?: boolean;
  secondaryServiceProvider?: boolean;
  isServiceCoordinatorService?: boolean;
  otherProvider: string;
  otherProviderAgency: string;
  otherProviderName: string;
  otherProviderRole: string;
  location?: string;
  modificationId?: string;
  modification?: IfspModification;
  modificationStartDate?: Date;
  modificationEndDate?: Date;
  modificationReasonId?: string;
  modificationComments?: string;
  modificationTaggedItemId?: string;
  isActive: boolean;
  dateInActive?: Date;
  priorVersionId?: string;
  priorVersion: IfspServiceDto;
  taggedItem?: TaggedItem;
  isFirstCall: boolean;
  consent: ConsentStatus;
}

export interface IfspServiceSummaryOutcomeDto {
  id: string;
  title: string;
}

export interface IfspServiceSummaryDto {
  id: string;
  status?: IfspServiceStatus;
  outcomes?: Array<IfspServiceSummaryOutcomeDto>;
  typeOfService?: string;
  location?: string;
  how: IfspServiceHow[];
  howExplanation: string;
  who?: string;
  agency?: string;
  provider?: string;
  length?: number;
  frequencyNumber?: number;
  frequencyPeriod?: string;
  startDate?: Date;
  familyDeclined?: boolean;
  primaryServiceProvider?: boolean;
  secondaryServiceProvider?: boolean;
}

export interface IfspServiceLogFirstDeliveryDto {
  isFirstDelivery: boolean;
  consent: any;
}

export interface IfspServiceAddDto {
  ifspId: string;
  outcomes?: Array<string>;
  service?: string;
  location?: string;
  justificationLocation?: string;
  howIds?: string[];
  howExplanation: string;
  with?: string;
  agency?: string;
  provider?: string;
  length?: number;
  frequencyNumber?: number;
  frequencyPeriod?: string;
  startDate?: Date;
  modificationId?: string;
  isServiceCoordinatorService: boolean;
  deactivateLastIfspConsentForm: boolean;
}

export interface IfspServiceUpdateDto {
  id: string;
  status: IfspServiceStatus;
  outcomes: Array<string>;
  service: string;
  location: string;
  justificationLocation?: string;
  howIds?: string[];
  howExplanation: string;
  with: string;
  agency?: string;
  provider?: string;
  length: number;
  frequencyNumber: number;
  frequencyPeriod: string;
  startDate: Date;
  familyDeclined: boolean;
  primaryServiceProvider: boolean;
  secondaryServiceProvider: boolean;
  isServiceCoordinatorService: boolean;
  // modificationId?: string;
}

export interface IfspServiceHow {
  id: string;
  label: string;
}

export interface IfspServiceWho {
  id: string;
  label: string;
}

export interface IfspServiceLocation {
  id: string;
  label: string;
  showJustificationQuestion: boolean;
}

export interface IfspServiceProvider {
  id: string;
  label: string;
}

export interface IfspServiceAgency {
  id: string;
  label: string;
}

export interface IfspServiceLookupData {
  outcomes: Array<GetAllOutcomesForServiceDto>;
  serviceTypes: Array<ServiceActivityType>;
  hows: Array<IfspServiceHow>;
  whos: Array<IfspServiceWho>;
  locations: Array<IfspServiceLocation>;
  providers: Array<ProviderUser>;
  agencies: Array<User>;
  allIfspOutcomes: Array<GetAllOutcomesForServiceDto>;
}

export enum IfspServiceStatus {
  Complete = 'Complete',
  InComplete = 'InComplete',
}
