import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, finalize, map } from 'rxjs/operators';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { Grade } from 'src/app/shared/models/learner';
import { DateToUtcPipe } from 'src/app/shared/pipes/date-transform.pipe';
import { ChangeTrackerService } from 'src/app/shared/services/change-tracker.service';
import { DeactivationStatus } from 'src/app/shared/services/deactivation.service';
import { AlertDialogComponent, NotificationService } from 'src/app/shared/services/notification.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { GradeBookItem } from '../models/grade-book';
import { GradeBookService } from '../services/grade-book.service';
import { GraphModalComponent } from './graph-modal/graph-modal.component';

@Component({
  selector: 'app-grade-book',
  templateUrl: './grade-book.component.html',
  styleUrls: ['./grade-book.component.scss'],
  providers: [ChangeTrackerService],
})
export class GradeBookComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  today = new Date();
  isBusy = false;
  gradeBookItems: GradeBookItem[] = [];
  gradeBookFormArray = this.fb.array([]);

  filterFormGroup = this.fb.group({
    progressToShow: false,
    district: null,
    building: null,
    grade: null,
    goalArea: null,
    searchTerm: null,
  });

  groupUpdateFormGroup = this.fb.group({
    groupUpdateDate: null,
    groupUpdateNotes: null,
  });

  progressToShowOptions: KeyValuePair[] = [new KeyValuePair(true, 'Goals I am assigned to monitor'), new KeyValuePair(false, 'All')];
  districtOptions: KeyValuePair[] = [];
  buildingOptions: KeyValuePair[] = [];
  gradeOptions: KeyValuePair[] = [];
  goalAreaOptions: KeyValuePair[] = [];

  sortByLearnerAscending = false;
  learnerSortCount = 0;
  sortByGoalNameAscending = false;
  goalNameSortCount = 0;
  sortByMeasurementAscending = false;
  measurementSortCount = 0;
  sortByTargetValueAscending = false;
  targetValueSortCount = 0;

  get gradeBook(): Array<FormGroup> {
    return (this.gradeBookFormArray as FormArray).controls as Array<FormGroup>;
  }

  getObjectivesFormArray(objectiveFormArray) {
    return (objectiveFormArray as FormArray).controls as Array<FormGroup>;
  }

  constructor(
    private readonly gradeBookService: GradeBookService,
    private readonly routingService: RoutingService,
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly notification: NotificationService,
    private readonly changeTracker: ChangeTrackerService
  ) {}

  ngOnInit(): void {
    this.load();

    this.subscriptions.add(
      this.filterFormGroup.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
        this.filterGradeBookItems(value);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    const isDirty = this.gradeBookFormArray.controls.some((x) => x.dirty);
    if (isDirty) {
      return;
    }
  }

  canDeactivate(): DeactivationStatus | Observable<DeactivationStatus> | Promise<DeactivationStatus> {
    return this.changeTracker.hasChanged.pipe(map((x) => (x ? DeactivationStatus.NeedsConfirmation : DeactivationStatus.Accepted)));
  }

  preventToggle(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
  }

  onOpenGraph(e: Event, item: FormGroup, isObjective: boolean) {
    this.preventToggle(e);
    const graphItem = {
      iepGoalId: '',
      iepGoalQuantifiableMeasurementId: '',
      isObjective,
    };

    if (!isObjective) {
      graphItem.iepGoalId = item.get('goal').get('id').value;
    } else {
      const gradeBookItem = this.gradeBookItems.find((x) => x.goal.objectives.some((y) => y.id === item.get('id').value));
      if (gradeBookItem) {
        graphItem.iepGoalId = gradeBookItem.goal.id;
      }
      graphItem.iepGoalQuantifiableMeasurementId = item.get('primaryMeasurement').get('id').value;
    }

    this.dialog.open(GraphModalComponent, {
      data: graphItem,
      width: '728px',
    });
  }

  onNavigateToEnterProgress(formGroup: FormGroup, event: Event, isObjective: boolean) {
    const caseId = !isObjective ? formGroup.get('caseId').value : formGroup.parent.parent.parent.get('caseId').value;
    const goalId = !isObjective ? formGroup.get('goal').get('id').value : formGroup.parent.parent.get('id').value;
    const objectiveId = !isObjective ? null : formGroup.get('id').value;
    this.preventToggle(event);
    const config: NewWindowConfig = {
      path: this.routingService.enterProgressPath(caseId).join('/'),
      popup: true,
      width: '1280px',
      params: { goalId, objectiveId },
    };
    openNewWindow(config);
  }

  clearFilters() {
    this.filterFormGroup.reset({ progressToShow: false });
  }

  sortHeader(column: string) {
    switch (column) {
      case 'learnerName':
        this.goalNameSortCount = 0;
        this.sortByGoalNameAscending = false;
        this.measurementSortCount = 0;
        this.sortByMeasurementAscending = false;
        this.targetValueSortCount = 0;
        this.sortByTargetValueAscending = false;
        if (this.learnerSortCount < 2) {
          this.sortByLearnerAscending = !this.sortByLearnerAscending;
          this.learnerSortCount++;
        } else {
          this.sortByLearnerAscending = false;
          this.learnerSortCount = 0;
          this.defaultColumnSort();
          break;
        }
        this.gradeBook.sort((a, b) =>
          this.sortByLearnerAscending
            ? a.get('learner').get('name').value > b.get('learner').get('name').value
              ? -1
              : 1
            : a.get('learner').get('name').value > b.get('learner').get('name').value
            ? 1
            : -1
        );
        break;
      case 'goalNickname':
        this.learnerSortCount = 0;
        this.sortByLearnerAscending = false;
        this.measurementSortCount = 0;
        this.sortByMeasurementAscending = false;
        this.targetValueSortCount = 0;
        this.sortByTargetValueAscending = false;
        if (this.goalNameSortCount < 2) {
          this.sortByGoalNameAscending = !this.sortByGoalNameAscending;
          this.goalNameSortCount++;
        } else {
          this.sortByGoalNameAscending = false;
          this.goalNameSortCount = 0;
          this.defaultColumnSort();
          break;
        }
        this.gradeBook.sort((a, b) =>
          this.sortByGoalNameAscending
            ? a.get('goal').get('name').value > b.get('goal').get('name').value
              ? -1
              : 1
            : a.get('goal').get('name').value > b.get('goal').get('name').value
            ? 1
            : -1
        );
        break;
      case 'measurement':
        this.learnerSortCount = 0;
        this.sortByLearnerAscending = false;
        this.goalNameSortCount = 0;
        this.sortByGoalNameAscending = false;
        this.targetValueSortCount = 0;
        this.sortByTargetValueAscending = false;
        if (this.measurementSortCount < 2) {
          this.sortByMeasurementAscending = !this.sortByMeasurementAscending;
          this.measurementSortCount++;
        } else {
          this.sortByMeasurementAscending = false;
          this.measurementSortCount = 0;
          this.defaultColumnSort();
          break;
        }
        this.gradeBook.sort((a, b) =>
          this.sortByMeasurementAscending
            ? a.get('goal').get('primaryMeasurement').get('unit').value > b.get('goal').get('primaryMeasurement').get('unit').value
              ? -1
              : 1
            : a.get('goal').get('primaryMeasurement').get('unit').value > b.get('goal').get('primaryMeasurement').get('unit').value
            ? 1
            : -1
        );
        break;
      case 'targetValue':
        this.learnerSortCount = 0;
        this.sortByLearnerAscending = false;
        this.goalNameSortCount = 0;
        this.sortByGoalNameAscending = false;
        this.measurementSortCount = 0;
        this.sortByMeasurementAscending = false;
        if (this.targetValueSortCount < 2) {
          this.sortByTargetValueAscending = !this.sortByTargetValueAscending;
          this.targetValueSortCount++;
        } else {
          this.sortByTargetValueAscending = false;
          this.targetValueSortCount = 0;
          this.defaultColumnSort();
          break;
        }
        this.gradeBook.sort((a, b) =>
          this.sortByTargetValueAscending
            ? parseFloat(a.get('goal').get('primaryMeasurement').get('targetValue').value) -
              parseFloat(b.get('goal').get('primaryMeasurement').get('targetValue').value)
            : parseFloat(b.get('goal').get('primaryMeasurement').get('targetValue').value) -
              parseFloat(a.get('goal').get('primaryMeasurement').get('targetValue').value)
        );
        break;
      default:
        break;
    }
  }

  private defaultColumnSort() {
    this.gradeBook.sort((a, b) => (a.get('learner').get('name').value > b.get('learner').get('name').value ? 1 : -1));
  }

  onSave() {
    // If there's any invalid items, display an error and return
    if (this.gradeBook.some((x) => x.invalid)) {
      this.gradeBook.filter((x) => x.invalid).forEach((x) => x.markAllAsTouched());
      this.dialog.open(AlertDialogComponent, {
        data: {
          title: 'Incomplete Data',
          message: 'Please correct the incomplete rows and save again.',
        },
        width: '728px',
      });
      return;
    }

    const goalProgressMonitorItems = this.gradeBook
      .filter((x) => x.valid && x.get('value').value)
      ?.map((x) => ({
        date: new DateToUtcPipe().transform(new Date(x.get('date').value)),
        value: x.get('value').value,
        note: x.get('note').value,
        iepGoalQuantifiableMeasurementId: x.get('goal').get('primaryMeasurement').get('id').value,
      }));

    const objectives = this.gradeBook.map((x) => x.get('goal')).flatMap((x) => (x.get('objectives') as FormArray).controls);

    const objectiveProgressMonitorItems = objectives
      .filter((x) => x.valid && x.get('value').value)
      .map((x) => ({
        date: new DateToUtcPipe().transform(new Date(x.get('date').value)),
        value: x.get('value').value,
        note: x.get('note').value,
        iepGoalQuantifiableMeasurementId: x.get('primaryMeasurement').get('id').value,
      }));

    const progressMonitorItems = [...goalProgressMonitorItems, ...objectiveProgressMonitorItems];

    // If there's no data to save, return
    if (progressMonitorItems.length === 0) {
      this.notification.error("There's no data to save.");
      return;
    }

    this.isBusy = true;
    this.subscriptions.add(
      this.gradeBookService
        .add({
          items: progressMonitorItems,
        })
        .pipe(finalize(() => (this.isBusy = false)))
        .subscribe((res) => {
          this.filterGradeBookItems(this.filterFormGroup.value);
        })
    );
  }

  private load() {
    this.subscriptions.add(
      this.gradeBookService.get().subscribe((res) => {
        this.gradeBookItems = res;
        this.generateFormArray(res);
        this.generateGoalAreaList(res);
        this.generateDistrictList(res);
        this.generateGradeList(res);
      })
    );
  }

  private generateGoalAreaList(gradeBookItems: Array<GradeBookItem>) {
    const goalAreas = gradeBookItems.flatMap((x) => x.goal.goalAreas);
    goalAreas.forEach((goalArea) => {
      const index = this.goalAreaOptions.findIndex((x) => x.key === goalArea.id);
      if (index === -1) {
        this.goalAreaOptions.push(new KeyValuePair(goalArea.id, goalArea.label));
      }
    });
    this.goalAreaOptions.sort((a, b) => (a.value < b.value ? -1 : 1));
  }

  private generateDistrictList(gradeBookItems: Array<GradeBookItem>) {
    const districts = gradeBookItems.flatMap((x) => x.learner.district);
    districts.forEach((district) => {
      const index = this.districtOptions.findIndex((x) => x.key === district.id);
      if (index === -1) {
        this.districtOptions.push(new KeyValuePair(district.id, district.name));
      }
    });
    this.districtOptions.sort((a, b) => (a.value < b.value ? -1 : 1));
  }

  private generateGradeList(gradeBookItems: Array<GradeBookItem>) {
    const grades = gradeBookItems
      .flatMap((x) => x.learner.grade)
      .filter((x) => !!x)
      .filter((v, i, s) => s.indexOf(v) === i);
    grades.forEach((grade) => {
      if (grade) {
        const index = this.gradeOptions.findIndex((x) => x.key === grade);
        if (index === -1) {
          this.gradeOptions.push(new KeyValuePair(grade, Grade[grade]));
        }
      }
    });
    this.gradeOptions.sort((a, b) => {
      if (/[a-z]/i.test(a.value) && /[a-z]/i.test(b.value)) {
        return a.value.toLowerCase() < b.value.toLowerCase() ? 1 : -1;
      } else if (/[a-z]/i.test(a.value)) {
        return -1;
      } else if (/[a-z]/i.test(b.value)) {
        return 1;
      } else {
        return parseInt(a.value, 10) - parseInt(b.value, 10);
      }
    });
  }

  private filterGradeBookItems(value) {
    let filteredGradeBookItems = JSON.parse(JSON.stringify(this.gradeBookItems));
    if (value.progressToShow) {
      filteredGradeBookItems = filteredGradeBookItems.filter((x) => x.providedByMe);
    }

    if (value.searchTerm) {
      const searchTerm = value.searchTerm.toLowerCase().trim();
      filteredGradeBookItems = filteredGradeBookItems.filter((x) => {
        x.goal.objectives = x.goal.objectives.filter(
          (y) => y.name.toLowerCase().trim().includes(searchTerm) || x.learner.name.toLowerCase().trim().includes(searchTerm)
        );

        return (
          x.learner.name.toLowerCase().trim().includes(searchTerm) ||
          x.goal.name.toLowerCase().trim().includes(searchTerm) ||
          x.goal.objectives.length > 0
        );
      });
    }

    if (value.goalArea) {
      filteredGradeBookItems = filteredGradeBookItems.filter((x) => x.goal.goalAreas.some((y) => y.id === value.goalArea));
    }

    if (value.grade) {
      filteredGradeBookItems = filteredGradeBookItems.filter((x) => x.learner.grade === value.grade);
    }

    if (value.district) {
      filteredGradeBookItems = filteredGradeBookItems.filter((x) => x.learner.district.id === value.district);
    }

    this.generateFormArray(filteredGradeBookItems);
  }

  private generateFormArray(gradeBookItems: Array<GradeBookItem>) {
    this.gradeBookFormArray.clear();

    gradeBookItems.forEach((gradeBookItem) => {
      const formGroup = this.fb.group({
        caseId: gradeBookItem.caseId,
        finalizationDate: gradeBookItem.finalizationDate,
        goal: this.fb.group({
          id: gradeBookItem.goal.id,
          name: gradeBookItem.goal.name,
          primaryMeasurement: this.fb.group(gradeBookItem.goal.primaryMeasurement),
          secondaryMeasurement: gradeBookItem.goal.secondaryMeasurement ? this.fb.group(gradeBookItem.goal.secondaryMeasurement) : null,
          objectives: this.fb.array([]),
        }),
        learner: this.fb.group({
          id: gradeBookItem.learner.id,
          name: gradeBookItem.learner.name,
        }),
        date: null,
        value: null,
        note: null,
      });

      // Add in objectives
      if (gradeBookItem.goal.objectives.length > 0) {
        gradeBookItem.goal.objectives.forEach((objective) => {
          const objectiveFormGroup = this.fb.group({
            id: objective.id,
            name: objective.name,
            finalizationDate: gradeBookItem.finalizationDate,
            primaryMeasurement: this.fb.group(objective.primaryMeasurement),
            date: null,
            value: null,
            note: null,
          });
          (formGroup.get('goal').get('objectives') as FormArray).push(objectiveFormGroup);
        });
      }

      this.gradeBookFormArray.push(formGroup);
    });
  }
}
