<form [formGroup]="formGroup">
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Reschedule Event</h3>

  <div mat-dialog-content class="py-3">
    <mat-card class="my-0 card--plain card--shadow">
      <div class="row mt-2">
        <div class="col-md-12 col-lg-4">
          <app-date-picker label="Meeting Date" controlName="date" [min]="today"></app-date-picker>
        </div>
        <div class="col-md-12 col-lg-4">
          <app-timepicker label="Meeting Time" formControlName="time"></app-timepicker>
        </div>
        <div class="col-md-12 col-lg-4">
          <app-text-field label="Location" formControlName="location"></app-text-field>
        </div>

        <div class="col-lg-12">
          <app-autocomplete
            [options]="rescheduleReasonOptions"
            formControlName="rescheduledReasonId"
            label="Reason for Reschedule"
          ></app-autocomplete>
        </div>
        <div class="col-lg-12" *ngIf="isOther">
          <app-text-field label="Describe Other" formControlName="otherDescription"></app-text-field>
        </div>
      </div>
    </mat-card>
  </div>

  <div mat-dialog-actions class="display-flex justify-content-end align-items-center">
    <button mat-raised-button aria-label="Cancel Button" class="background-color--muted mr-2" (click)="onClose()" tabindex="0">
      Cancel
    </button>
    <button
      mat-raised-button
      aria-label="Complete"
      color="primary"
      [disabled]="!formGroup.valid"
      [disableAfterBusy]="!formGroup.valid"
      [isBusy]="activeCall"
      (click)="onSubmit()"
      tabindex="0"
    >
      Complete
    </button>
  </div>
</form>
