import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IepESYConsiderationsDto } from '../../../../esy';
import { ESYService } from '../../../../esy-service';

@Component({
  selector: 'app-esy-view-overview',
  templateUrl: './esy-view-overview.component.html',
  styleUrls: ['./esy-view-overview.component.scss'],
})
export class EsyViewOverviewComponent implements OnInit {
  factors: IepESYConsiderationsDto;
  showField = {
    FactorsSection: false,
    LimitedEnglish: false,
    Behavior: false,
    AccessibleMaterials: false,
    Braille: false,
    CommunicationLanguage: false,
    AssistiveTechnology: false,
    Health: false,
    Hearing: false,
  };
  constructor(private iepESYService: ESYService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.iepESYService.getSpecialFactors(this.route.parent.snapshot.paramMap.get('iepId'), null).subscribe((specialFactors) => {
      this.factors = specialFactors;
      if (specialFactors.specialFactorsAddressed.length > 0) {
        for (const factor of specialFactors.specialFactorsAddressed) {
          this.showField[factor] = true;
          this.showField.FactorsSection = true;
        }
      }
    });
  }
}
