import { Component, Input, OnInit } from '@angular/core';

enum SpecialFactorsSections {
  AEM = 'Accessible Education Materials',
  AssistiveTech = 'Assistive Technology',
  Vision = 'Vision',
  Communication = 'Communication',
  EnglishLearner = 'English Learner',
  Health = 'Health',
  Hearing = 'Hearing',
  SocialEmotional = 'Social Emotional Behavior',
}

@Component({
  selector: 'app-special-factors-expanded-text',
  templateUrl: './special-factors-expanded-text.component.html',
  styleUrls: ['./special-factors-expanded-text.component.scss'],
})
export class SpecialFactorsExpandedTextComponent implements OnInit {
  @Input() search: string;
  sections = SpecialFactorsSections;

  constructor() {}

  ngOnInit(): void {}

  onClose(): void {
    window.close();
  }
}
