import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin, Subscription } from 'rxjs';
import { CaseSummary, FamilyMeetingRead } from 'src/app/shared/models/case';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { LearnerSummary } from '../../../../../shared/models/learner';
import { IepAccommodationService } from '../../../../services/iep-accommodation.service';
import { IepService } from '../../../../services/iep.service';

@Component({
  selector: 'app-iep-ssaa-summary',
  templateUrl: './iep-ssaa-summary.component.html',
  styleUrls: ['./iep-ssaa-summary.component.scss'],
})
export class IepSsaaSummaryComponent implements OnInit, OnDestroy {
  constructor(
    private iepService: IepService,
    private iepAccommodationService: IepAccommodationService,
    public route: ActivatedRoute,
    private caseService: CaseService
  ) {}
  @Input() learner: LearnerSummary;
  @Input() caseSummary: CaseSummary;
  @Input() caseId: string;
  @Input() iepId: string;
  @Input() isPK = false;
  @Input() iepMeetings: FamilyMeetingRead[];

  esy: string;
  physEd: string;
  assessment: string;
  assessmentBarriers: string;
  assessmentAlternate: string;
  previousIepId: string;
  subscriptions = new Subscription();

  async ngOnInit() {
    if (!this.caseId) {
      this.caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    }
    if (!this.iepId) {
      this.iepId = this.route.parent?.snapshot.paramMap.get('iepId');
    }

    if (!this.learner || !this.caseSummary) {
      await this.caseService.refreshCaseSummary(this.caseId);
      const caseSummary = this.caseService.caseSummary;
      this.learner = caseSummary?.learner;
      this.isPK = caseSummary?.learner.isPK;
      this.caseSummary = caseSummary;
      this.previousIepId = caseSummary?.previousIepId;
      this.initData();
    } else {
      this.isPK = this.learner.isPK;

      this.initData();
    }

    this.addObservers();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private addObservers() {
    const subPhysicalEducation = this.iepService.physicalEducationsUpdated$.subscribe(() => this.initData());
    const subExtendedSchool = this.iepService.extendedSchoolYearUpdated$.subscribe(() => this.initData());
    const subAssessment = this.iepService.assessmentUpdated$.subscribe(() => this.initData());
    const subLre = this.iepService.lreUpdated$.subscribe(() => this.initData());

    this.subscriptions.add(subPhysicalEducation);
    this.subscriptions.add(subExtendedSchool);
    this.subscriptions.add(subAssessment);
    this.subscriptions.add(subLre);
  }

  async initData(): Promise<void> {
    const [esyFromIep, esyOptions, physEdFromIep, physEdOptions, assessment] = await forkJoin([
      this.iepService.getExtendedSchoolYearFromIep(this.iepId),
      this.iepService.getExtendedSchoolYearOptions(),
      this.iepService.getPhysicalEducationFromIep(this.iepId),
      this.iepService.getPhysicalEducations(),
      this.iepService.getAssessmentFromIep(this.iepId),
    ]).toPromise();

    if (esyFromIep) {
      this.esy = esyFromIep.extendedSchoolYearDate
        ? `${esyOptions.find((x) => x.id === esyFromIep.extendedSchoolYear).label}: ${dayjs(esyFromIep.extendedSchoolYearDate).format(
            'MM-DD-YYYY'
          )} `
        : `${esyOptions.find((x) => x.id === esyFromIep.extendedSchoolYear).label}`;
    }
    if (physEdFromIep) {
      this.physEd = physEdFromIep.physicalEdDescription
        ? `${physEdOptions.find((x) => x.id === physEdFromIep.physicalEducation).label} - ${physEdFromIep.physicalEdDescription}`
        : `${physEdOptions.find((x) => x.id === physEdFromIep.physicalEducation).label}`;
    }

    if (assessment) {
      this.assessmentBarriers = null;
      this.assessmentAlternate = null;

      if (assessment.howParticipate === 'noAccommodations') {
        this.assessment = 'Standard administration, no accommodations';
      } else if (assessment.howParticipate === 'withAccommodation') {
        this.setAssessmentWithSelectedAccommodations(assessment.accommodations);
      } else if (assessment.howParticipate === 'alternate') {
        this.assessment = 'Alternate:';

        this.assessmentBarriers = assessment.whatBarriers
          ? `What barriers prevent ${this.learner.firstName} from participating in the standard assessments? - ${assessment.whatBarriers}`
          : '';

        this.assessmentAlternate = assessment.assessmentNecessary
          ? `Why is the alternate assessment necessary for ${this.learner.firstName}? - ${assessment.assessmentNecessary}`
          : '';
      }
    } else {
      this.assessment = '-';
    }
  }

  private setAssessmentWithSelectedAccommodations(selectedAccommodations: any) {
    if (!selectedAccommodations) {
      this.assessment = 'Standard administration with accommodations';
    } else {
      this.iepAccommodationService.getAllAccommodations(this.iepId).subscribe((accommodations) => {
        const selectedAccomodationItems = accommodations.filter((e) => {
          return selectedAccommodations.indexOf(e.id) >= 0;
        });

        this.assessment = 'Standard administration with accommodations: ' + selectedAccomodationItems?.map((x) => x.description).join(', ');
      });
    }
  }
}
