import { Component, Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { AreYouSureComponent } from '../components/are-you-sure-modal/are-you-sure.component';
import { OperationResult } from '../models/operation-result';

// see https://stackblitz.com/edit/angular-material-notification-service?file=src%2Fapp%2Fnotification.service.ts
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private readonly snackBar: MatSnackBar, public dialog: MatDialog) {}

  /**
   * Presents a toast displaying the message with a green background
   * @param message Message to display
   * @example
   * this.notificationService.success("confirm oked");
   */
  success(message: string, duration = 2000, actionText?: string) {
    return this.openSnackBar(message, actionText, 'snackbar--success', duration);
  }

  /**
   * Presents a toast displaying the message with a red background
   * @param message Message to display
   * @param duration how long the message should display
   * @example
   * this.notificationService.error("confirm canceled");
   */
  error(message: string, duration = 2000, actionText?: string) {
    return this.openSnackBar(message, actionText, 'snackbar--error', duration);
  }

  /**
   * Presents a toast displaying the message with a green background and an action button
   * @param message Message to display
   * @param actionText Action to display
   * @param cb Function to call when the user clicks action
   * @param duration Number (in ms) to display message
   * @example
   * this.notificationService.successWithAction("It worked!", 'Ok', () => {});
   */
  successWithAction(message: string, actionText: string, cb: any, duration = 2000) {
    this.success(message, duration, actionText).onAction().subscribe(cb);
  }

  /**
   * Presents a toast displaying the message with a red background and an action button
   * @param message Message to display
   * @param actionText Action to display
   * @param cb Function to call when the user clicks action
   * @param duration Number (in ms) to display message
   * @example
   * this.notificationService.errorWithAction("It didn't work!", 'Ok', () => {});
   */
  errorWithAction(message: string, actionText: string, cb: any, duration = 2000) {
    this.error(message, duration, actionText).onAction().subscribe(cb);
  }

  /**
   * Shows a confirmation modal, presenting the user with
   * an OK and Cancel button.
   * @param message Body of the modal
   * @param okCallback Optional function to call when the user clicks Ok
   * @param title Optional modal title
   * @param cancelCallback Optional function to call when the user clicks Cancel
   * @param buttons Optional choose text to be displayed as confirmation buttons
   * @example
   * //displays a success or error message depending on what button is clicked.
   * this.notificationService.confirmation(
   * 'it will be gone forever', //message body
   * () => { //okCallback
   *   this.notificationService.success("confirm oked");
   * },
   * 'Are you sure?', //title
   * () => { //cancelCallback
   *   this.notificationService.error("confirm canceled");
   * });
   */
  confirmation(
    message: string,
    okCallback: () => void,
    title = 'Are you sure?',
    cancelCallback: () => any = () => {},
    buttons = { cancel: 'Cancel', ok: 'Ok' }
  ) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: { message, title, buttons },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && okCallback) {
        okCallback();
      }
      if (!result && cancelCallback) {
        cancelCallback();
      }
    });
  }

  areYouSure(message: string, okCallback: () => void, title?: string, cancelCallback: () => any = () => {}) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: message,
        subQuestion: title,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && okCallback) {
        okCallback();
      }
      if (!result && cancelCallback) {
        cancelCallback();
      }
    });
  }

  /**
   * Shows a modal, presenting the user with an OK button.
   * @param message Body of the modal
   * @param okCallback Optional function to call when the user clicks Ok
   * @param title Optional modal title
   * @example
   * //displays a success when the Ok button is clicked.
   *  this.notificationService.alert("an alert", "notice", () => {
   *    this.notificationService.success("alert oked");
   *  });
   */
  alert(message: string, title = 'Notice', okCallback: () => void = () => {}) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '600px',
      data: { message, title },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && okCallback) {
        okCallback();
      }
    });
  }

  /**
   * Displays a toast with provided message
   * @param message Message to display
   * @param action Action text, e.g. Close, Done, etc
   * @param className Optional extra css class to apply
   * @param duration Optional number of SECONDS to display the notification for
   */
  openSnackBar(message: string, action: string, className = '', duration = 1000): MatSnackBarRef<TextOnlySnackBar> {
    return this.snackBar.open(message, action, {
      duration,
      panelClass: ['snackbar', className],
    });
  }

  showVersionDialog(versionCheckFailed = false) {
    const dialogRef = this.dialog.open(VersionDialogComponent, {
      width: '600px',
      disableClose: true,
      data: { versionCheckFailed },
    });
    return dialogRef.afterClosed();
  }

  /**
   * Shows a confirmation modal, presenting the user with
   * an OK and Cancel button.
   * @param message Body of the modal
   * @param title Optional modal title
   * @param okButtonLabel Optional label for the ok button
   * @param cancelButtonLabel Optional label for the cancel button
   * @returns A Promise<boolean> with user's selection
   * @example
   * this.notificationService.confirmationPromise(
   * 'it will be gone forever', //message body
   * ).then(response => { });
   * - OR -
   * let response = await this.notificationService.confirmationPromise(
   * 'it will be gone forever', //message body
   * );
   */
  confirmationPromise(
    message: string | string[],
    title = 'Are you sure?',
    okButtonLabel = 'Ok',
    cancelButtonLabel = 'Cancel',
    width = '350px'
  ): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width,
      data: { message, title, buttons: { ok: okButtonLabel, cancel: cancelButtonLabel } },
    });
    return dialogRef.afterClosed().toPromise();
  }

  showResponseMessage(res: OperationResult, successMessage = null): boolean {
    if (res.succeeded) {
      if (!successMessage) return true;

      this.success(successMessage);
    } else {
      const errorMessage = res.errors?.length ? res.errors[0].description : 'Something went wrong!';
      this.error(errorMessage);
    }
    return res.succeeded;
  }
}

export interface DialogData {
  message: string;
  title: string;
  buttons: { cancel: string; ok: string };
}

@Component({
  templateUrl: '../modals/confirmation-dialog.html',
})
export class ConfirmationDialogComponent {
  get isMessageArray(): boolean {
    return Array.isArray(this.data.message);
  }

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}

@Component({
  templateUrl: '../modals/alert-dialog.html',
  styles: ['mat-card { word-wrap: break-word; }'],
})
export class AlertDialogComponent {
  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}

@Component({
  templateUrl: '../modals/version-modal.html',
})
export class VersionDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: any, private dialogRef: MatDialogRef<any>) {}

  refresh() {
    this.dialogRef.close();
  }
}
