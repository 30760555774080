import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import { AuthService } from 'src/app/auth/auth.service';
import { UpdateConsentFormComponent } from 'src/app/consent/family-consent/update-consent-form/update-consent-form.component';
import { TodoService } from 'src/app/dashboard/user-todo-list/todo.service';
import { IntakeSections } from 'src/app/evaluation/early-access-intake/intake-sections/intake-sections';
import { Intake, NextStepsInformation } from 'src/app/evaluation/models/intake';
import { ProceduralSafeguardsRead } from 'src/app/shared/components/procedural-safeguards/procedural-safeguards';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { CaseSummary } from 'src/app/shared/models/case';
import { ConsentForm, ConsentFormType } from 'src/app/shared/models/fiie-consent/consent-form';
import { FamilyConsentStatus } from 'src/app/shared/models/fiie-consent/family-consent-status';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { MedicalCondition } from 'src/app/shared/models/medical-condition';
import {
  EarlyAccessPriorWrittenNotice,
  PriorWrittenNoticeType,
} from 'src/app/shared/models/prior-written-notices/early-access-prior-written-notice';
import { usStates } from 'src/app/shared/models/us-states';
import { UnknownYesNo } from 'src/app/shared/models/yes-no';
import { DateFormatPipe } from 'src/app/shared/pipes/date-transform.pipe';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { ConsentFormService } from 'src/app/shared/services/consent-form/consent-form.service';
import { EvaluationDomainService } from 'src/app/shared/services/evaluation-domains/evaluation-domain.service';
import { LoggerService } from 'src/app/shared/services/logger/logger.service';
import { MedicalConditionService } from 'src/app/shared/services/medical-condition/medical-condition-service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { PriorWrittenNoticeService } from 'src/app/shared/services/prior-written-notices/prior-written-notice.service';
import { PdfOutputs, ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { NewWindowConfig, openNewWindow, openPdfWindow } from 'src/app/shared/windowHelpers';
import { TaggedItem } from 'src/app/tags/tagged-item';
import { TaggingService } from 'src/app/tags/taggingService.service';
import { ReferralService } from '../../../../child-find/early-access-referral/early-access-referral.service';
import { BaseComponent } from '../../../../shared/components/base-component/base-component';
import { AlertConfig } from '../../../../shared/components/page-alert/page-alert.component';
import { DeactivationService } from '../../../../shared/services/deactivation.service';
import { InterpretationOptions } from './next-steps-enum';
import { RecommendationFollowUpService } from './recommendation-follow-up/recommendation-follow-up.service';

@Component({
  selector: 'app-next-steps-form',
  templateUrl: './next-steps-form.component.html',
  styleUrls: ['./next-steps-form.component.scss'],
})
export class NextStepsFormComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  evalAssessmentConsentType = [ConsentFormType.InitialFamilyEvaluationAndAssessment];

  constructor(
    private authService: AuthService,
    private caseService: CaseService,
    private evaluationDomainService: EvaluationDomainService,
    private loggerService: LoggerService,
    private medicalConditionService: MedicalConditionService,
    private pwnService: PriorWrittenNoticeService,
    private todoService: TodoService,
    private dialog: MatDialog,
    public router: Router,
    private recommendationFollowUpService: RecommendationFollowUpService,
    private consentFormService: ConsentFormService,
    private referralService: ReferralService,
    private reportingService: ReportingService,
    private readonly taggingService: TaggingService,
    private readonly notificationService: NotificationService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  @Input() formGroup: FormGroup;
  @Input() parentInfo: FormGroup;
  @Input() familyInfo: FormGroup;
  @Input() intake: Intake;
  @Input() nextSteps: NextStepsInformation;
  @Input() intakeLocked: boolean;
  @Input() documentationInfo: FormGroup;
  @Input() isQuestionnaire: boolean;
  @Input() medicalConditionUpdated: boolean;
  @Output() medicalConditionUpdatedEmit = new EventEmitter();
  @Output() upload = new EventEmitter();
  @Output() deleteDocument = new EventEmitter();
  @Output() formInitialized = new EventEmitter();
  @Output() evalButtonChange = new EventEmitter();

  pdfOutputs = PdfOutputs;

  caseSummary: CaseSummary;
  consentFormTypeInitialFamilyEval = ConsentFormType.InitialFamilyEvaluationAndAssessment;
  consentFormTypePostReferral = ConsentFormType.PostReferralScreening;
  formInitializationFlags = {
    controlsInitialized: false,
    developmentalAreaOptionsDataLoaded: false,
    medicalConditionsDataLoaded: false,
    eventEmitted: false,
  };
  developmentalAreaOptions: KeyValuePair[] = [];
  dataSource = new MatTableDataSource<MedicalCondition>([]);
  intakeSections = IntakeSections;
  isReadOnly: boolean;
  overwriting = false;
  famWithdrew = false;
  permissionFormRequired: boolean;
  pwnReminderSet = false;
  createPwnButtonTitle = 'Create PWN';
  showPrint = false;
  today: Date;
  usStates = usStates;
  eligibilityDecisionValue?: boolean;
  screeningToolOptions: KeyValuePair[] = [];
  postScreeningToolOptions: KeyValuePair[] = [];
  unknownYesNo = UnknownYesNo;
  screeningToolOtherId: string;
  mostRecentProceduralSafeguard: ProceduralSafeguardsRead;
  sentenceStarters: string[];

  yesNoNotSureOptions: KeyValuePair[] = [
    new KeyValuePair(UnknownYesNo.Yes, 'Yes'),
    new KeyValuePair(UnknownYesNo.No, 'No'),
    new KeyValuePair(UnknownYesNo.Unknown, 'Not Sure'),
  ];

  eligibilityOptions: KeyValuePair[] = [
    new KeyValuePair(true, 'Eligible based on Diagnosed Condition'),
    new KeyValuePair(false, 'Additional Evaluation Needed'),
  ];

  legalGuardian = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  exchangeInformation = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  interpretationOptions: KeyValuePair[] = [
    new KeyValuePair(InterpretationOptions.NoConcerns, 'Post-Referral Screening indicates no concerns'),
    new KeyValuePair(
      InterpretationOptions.MayHaveConcerns,
      'The screening indicates child may have concerns in one or more of the following developmental areas, check all that apply'
    ),
  ];

  displayedColumns = ['name', 'icdCode'];
  initialConsentFormId: string = null;
  latestInitialConsentStatus?: FamilyConsentStatus;
  latestInitialConsentDate: Date = null;
  postReferralConsentFormId: string = null;
  latestPostReferralConsentStatus = FamilyConsentStatus.Pending;
  latestPostReferralConsentDate: Date = null;
  familyConsentStatus = FamilyConsentStatus;
  shortDateFormat = shortDateFormat;
  isCaseOwner: boolean;
  eligibleConditionAlert: AlertConfig = {
    status: 'info',
    message: 'If the child is Eligible based on a Diagnosed Condition, the Intake - Medical Diagnosis section must be completed.',
  };

  get childFirstName() {
    return this.intake?.childInfo?.firstName;
  }

  get isMoveInOrEligibleLegacyLearner() {
    return (
      this.caseSummary?.learner?.movingFromOutOfState ||
      this.caseSummary?.learner?.eligibleInLegacySystem ||
      this.caseSummary?.learner?.eligibleInLegacySystemPartC
    );
  }

  get latestInitialConsentStatusText(): string {
    if (this.latestInitialConsentStatus) {
      if (this.latestInitialConsentDate) {
        return `${this.latestInitialConsentStatus} - Date: ${new DateFormatPipe().transform(this.latestInitialConsentDate)}`;
      }
      return this.latestInitialConsentStatus;
    }
    if (this.caseSummary?.learner?.isMigrated || this.caseSummary?.learner?.eligibleInLegacySystemPartC) {
      return 'Legacy';
    }
    return 'Unknown';
  }

  tag(tag: boolean) {
    if (this.nextSteps.taggedItem) {
      this.nextSteps.taggedItem.taggedForPwn = tag;
      this.taggingService.updateTag(this.nextSteps.taggedItem).subscribe((response) => {
        this.nextSteps.taggedItem = response;
      });
    } else {
      this.taggingService
        .addTag({
          caseId: this.caseSummary.id,
          learnerId: this.caseSummary.learnerId,
          taggedForPwn: tag,
        } as TaggedItem)
        .subscribe((response) => {
          this.nextSteps.taggedItem = response;
        });
    }
  }

  ngOnInit() {
    this.today = new Date();
    this.caseService.getCaseSummary(this.intake.caseId).subscribe((caseSummary) => {
      this.caseSummary = caseSummary;
      this.isCaseOwner = this.caseSummary.caseOwnerId === this.authService.user.id;
    });

    this.evaluationDomainService.get().subscribe((areas) => {
      this.developmentalAreaOptions = areas.filter((a) => !a.isPartB && a.isRequired).map((x) => new KeyValuePair(x.id, x.label));
      this.formInitializationFlags.developmentalAreaOptionsDataLoaded = true;
      this.evaluateFormInitialization();
    });

    this.medicalConditionService.getIntakeMedicalConditions(this.intake?.caseId).subscribe((conditions) => {
      this.dataSource.data = conditions.filter((x) => x.diagnosisQualifies === true);
      this.formInitializationFlags.medicalConditionsDataLoaded = true;
      this.eligibilityOptions[0].enabled = conditions.some((x) => x.diagnosisQualifies === true);
      this.evaluateFormInitialization();
    });

    this.initializeControls();

    this.setEligibilityDecision();

    if (this.nextSteps) {
      this.formGroup.patchValue(this.nextSteps);
    }
    this.initializeTools();

    this.recommendationFollowUpService.getRecommendations().subscribe((recommendations) => {
      this.setSentenceStarters(recommendations.map((x) => x.label));
    });

    // Set up elgibility decision events
    this.formGroup.controls.eligibilitySubmittedOnIntake.valueChanges.subscribe((decision) => {
      if (decision !== this.eligibilityDecisionValue) {
        this.eligibilityDecisionValue = decision;
        if (decision) {
          this.eligible();
        } else if (decision === false) {
          this.notEligible();
        }
      }
    });
    this.evaluateConsentStatus();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.medicalConditionUpdated?.previousValue !== changes.medicalConditionUpdated?.currentValue) {
      if (changes.medicalConditionUpdated?.currentValue) {
        this.medicalConditionService.getIntakeMedicalConditions(this.intake?.caseId).subscribe((conditions) => {
          this.dataSource.data = conditions.filter((x) => x.diagnosisQualifies === true);
          this.eligibilityOptions[0].enabled = conditions.some((x) => x.diagnosisQualifies === true);
          const eligibilitySubmittedOnIntakeControl = this.formGroup.get('eligibilitySubmittedOnIntake');
          if (!this.eligibilityOptions[0].enabled && !!eligibilitySubmittedOnIntakeControl.value) {
            eligibilitySubmittedOnIntakeControl.setValue(null);
          }
          this.medicalConditionUpdatedEmit.emit(false);
        });
      }
    }
  }

  overWriteRecommendationSubscription(recommendationSelected: string) {
    // If the field is already blank, then fill in without overwrite warning
    if (!this.formGroup.controls.postReferralScreeningRecommendationFollowUp.value) {
      this.formGroup.controls.postReferralScreeningRecommendationFollowUp.patchValue(recommendationSelected);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initializeControls() {
    this.formGroup.addControl('preReferralScreeningToolUsed', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl(
      'preReferralScreeningToolIds',
      new FormControl(this.nextSteps.preReferralScreeningTools.map((st) => st.id) || [], { updateOn: 'change' })
    );
    this.formGroup.addControl(
      'preReferralScreeningToolOther',
      new FormControl(this.nextSteps.preReferralScreeningToolOther, {
        updateOn: 'change',
      })
    );

    // this is now kept locally as means to use the subscription on valueChanges to
    // set validators to inputs on other postReferralScreening-related controls
    this.formGroup.addControl(
      'postReferralScreening',
      new FormControl(null, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'postReferralScreeningToolIds',
      new FormControl(this.nextSteps.postReferralScreeningTools?.map((st) => st.id) || [], { updateOn: 'change' })
    );
    this.formGroup.addControl(
      'postReferralScreeningToolOther',
      new FormControl(this.nextSteps.postReferralScreeningToolOther, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl('postReferralScreeningCompleted', new FormControl(null));
    this.formGroup.addControl('postReferralScreeningConductedBy', new FormControl(null));
    this.formGroup.addControl(
      'postReferralScreeningResults',
      new FormControl(null, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl('postReferralScreeningConcerns', new FormControl());
    this.formGroup.addControl('postReferralScreeningRecommendationFollowUp', new FormControl(''));
    this.formGroup.addControl('postReferralScreeningSummary', new FormControl(null));
    this.formGroup.addControl('eligibilitySubmittedOnIntake', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.setValidators([]);
    this.formGroup.updateValueAndValidity();
    this.subscribeToFormChanges();
    this.formInitializationFlags.controlsInitialized = true;
    this.evaluateFormInitialization();
  }

  subscribeToFormChanges() {
    this.formGroup.controls.preReferralScreeningToolUsed.valueChanges.subscribe((change) => {
      if (change === UnknownYesNo.No || change === UnknownYesNo.Unknown) {
        this.formGroup.controls.preReferralScreeningToolIds.setValue([]);
        this.formGroup.controls.preReferralScreeningToolOther.setValue(null);
      }
    });

    this.formGroup.get('postReferralScreening').valueChanges.subscribe((change) => {
      if (change) {
        this.formGroup.controls.postReferralScreeningToolIds.setValidators(Validators.required);
        if (this.formGroup.controls.postReferralScreeningToolIds.value.includes(this.screeningToolOtherId)) {
          this.setRequiredValidator('postReferralScreeningToolOther');
        }
        this.setRequiredValidator('postReferralScreeningCompleted');
        this.setRequiredValidator('postReferralScreeningConductedBy');
        this.setRequiredValidator('postReferralScreeningResults');
        this.setRequiredValidator('postReferralScreeningRecommendationFollowUp');
      } else {
        this.resetPostReferralFields(false);
      }
    });

    this.formGroup.get('postReferralScreeningResults').valueChanges.subscribe((change) => {
      if (change === InterpretationOptions.MayHaveConcerns) {
        this.setRequiredValidator('postReferralScreeningConcerns');
      } else {
        this.formGroup.get('postReferralScreeningConcerns').setValidators(null);
        this.formGroup.get('postReferralScreeningConcerns').updateValueAndValidity();
      }
    });
  }

  setControlToNull(controlName: string) {
    if (controlName) {
      this.formGroup.controls[controlName].setValue(null);
      this.formGroup.controls[controlName].setValidators(null);
      this.formGroup.controls[controlName].updateValueAndValidity();
    }
  }

  setRequiredValidator(controlName: string) {
    this.formGroup.controls[controlName].setValidators(Validators.required);
    this.formGroup.controls[controlName].updateValueAndValidity();
  }

  resetPostReferralFields(resetPostReferralScreening: boolean) {
    this.formGroup.controls.postReferralScreeningToolIds.setValue([]);
    this.formGroup.controls.postReferralScreeningToolIds.setValidators(null);
    this.formGroup.controls.postReferralScreeningToolIds.updateValueAndValidity();

    this.setControlToNull('postReferralScreeningToolOther');
    this.setControlToNull('postReferralScreeningCompleted');
    this.setControlToNull('postReferralScreeningConductedBy');
    this.setControlToNull('postReferralScreeningResults');
    this.setControlToNull('postReferralScreeningRecommendationFollowUp');
    this.formGroup.controls.postReferralScreeningConcerns.reset();
    this.formGroup.controls.postReferralScreeningConcerns.updateValueAndValidity();

    if (resetPostReferralScreening) {
      this.formGroup.controls.postReferralScreening.patchValue(null, {
        emitEvent: false,
      });
    }
  }
  documentationFileChange(event: Event) {
    const element = event.target as HTMLInputElement;
    const file = element.files[0];
    const formData = new FormData();
    formData.append('documents', file, file.name);
    formData.append('consentFormTypes', ConsentFormType.InitialFamilyEvaluationAndAssessment);
    formData.append('titles', 'Consent for Early ACCESS Evaluation and Assessment');
    this.upload.emit(formData);
  }

  setEligibilityDecision() {
    this.eligibilityDecisionValue = this.nextSteps.eligibilityDecision;
    this.formGroup.controls.eligibilitySubmittedOnIntake.setValue(this.nextSteps.eligibilitySubmittedOnIntake);
  }

  notEligible() {
    this.formGroup.controls.eligibilitySubmittedOnIntake.setValue(false);
  }

  eligible() {
    this.formGroup.controls.eligibilitySubmittedOnIntake.setValue(true);
  }

  onUndoRadio(formControlName: string) {
    this.formGroup.get(formControlName).setValue(null);
  }

  startPwnProcess(familyWithdrew: boolean) {
    const pwn = {} as EarlyAccessPriorWrittenNotice;
    pwn.pwnType = familyWithdrew ? PriorWrittenNoticeType.FamilyWithdrew : PriorWrittenNoticeType.Ineligible;
    this.pwnService.createEarlyAccessPwn(this.intake.caseId, pwn).subscribe(
      (recPwn) => {
        this.routeToPwn(recPwn.id);
      },
      (error) => {
        // Throws error if there already exists a pwn for this case's pwn type
        this.loggerService.error(error);
        this.routeToPwn(error.id);
      }
    );
  }

  printResults() {
    this.reportingService.createScreeningResultsOutput(this.caseSummary.learnerId).subscribe({
      next: (documentId: string) => openPdfWindow(this.caseSummary.learnerId, documentId),
      error: (err) =>
        this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
          this.notificationService.alert(err.error, "Couldn't open output")
        ),
    });
  }

  routeToPwn(id: string) {
    const config: NewWindowConfig = {
      path: `cases/${this.intake.caseId}/pwns/${id}`,
      popup: true,
    };
    openNewWindow(config);
  }

  initializeTools() {
    this.referralService.getScreeningTools().subscribe((res) => {
      this.screeningToolOptions = res.map((x) => new KeyValuePair(x.id, x.label));

      this.postScreeningToolOptions = res.map((x) => new KeyValuePair(x.id, x.label === 'Other/Unknown' ? 'Other' : x.label));

      this.screeningToolOtherId = res.find((x) => x.isOther).id;
    });
  }

  private evaluateFormInitialization() {
    if (this.formInitializationFlags.eventEmitted) {
      return;
    }
    if (
      this.formInitializationFlags.controlsInitialized &&
      this.formInitializationFlags.medicalConditionsDataLoaded &&
      this.formInitializationFlags.developmentalAreaOptionsDataLoaded
    ) {
      this.formInitializationFlags.eventEmitted = true;
      this.formInitialized.emit();
    }
  }

  onUpload(formData: FormData, consentFormType) {
    formData.append('consentFormTypes', consentFormType);
    this.upload.emit(formData);
  }

  onDeleteDocument(documentId: string) {
    this.deleteDocument.emit(documentId);
  }

  addEvaluationConsent() {
    const dialogRef = this.dialog.open(UpdateConsentFormComponent, {
      width: '400px',
      data: {
        caseId: this.intake.caseId,
        consentFormId: this.initialConsentFormId,
        title: 'Consent for Initial Evaluation and Assessment',
        currentStatus: this.latestInitialConsentStatus,
        createdOn: this.caseSummary.earlyAccessReferralSubmittedOn,
        consentFormType: ConsentFormType.InitialFamilyEvaluationAndAssessment,
      },
    });
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult?.status) {
        const approved = dialogResult.status === FamilyConsentStatus.Approved;
        this.evalButtonChange.emit(!approved);
        this.latestInitialConsentStatus = dialogResult.status;
        this.latestInitialConsentDate = dialogResult.date;
      }
    });
  }

  onConsentFormsLoaded(consentForms: ConsentForm[]) {
    this.updateFormsPerConsent(consentForms);
  }

  updateFormsPerConsent(consentForms: ConsentForm[]) {
    if (consentForms) {
      const initialConsentForm = consentForms.find((c) => c.type === ConsentFormType.InitialFamilyEvaluationAndAssessment);
      if (initialConsentForm) {
        this.initialConsentFormId = initialConsentForm.id;
        if (initialConsentForm.statuses?.length > 0) {
          this.latestInitialConsentStatus = initialConsentForm.statuses[0].status;
          this.latestInitialConsentDate = initialConsentForm.statuses[0].dateSigned;
        }
      }
      const postReferralConsentForm = consentForms.find((c) => c.type === ConsentFormType.PostReferralScreening);
      if (postReferralConsentForm) {
        this.postReferralConsentFormId = postReferralConsentForm.id;
        if (postReferralConsentForm.statuses?.length > 0) {
          this.latestPostReferralConsentStatus = postReferralConsentForm.statuses[0].status;
          this.latestPostReferralConsentDate = postReferralConsentForm.statuses[0].dateSigned;
          this.formGroup.get('postReferralScreening').patchValue(true);
        } else {
          this.formGroup.get('postReferralScreening').patchValue(null);
        }
      }
    }
  }

  evaluateConsentStatus() {
    this.latestPostReferralConsentStatus = FamilyConsentStatus.Pending;
    this.latestPostReferralConsentDate = null;
    if (this.intake?.nextSteps) {
      this.consentFormService.getConsentForms(this.intake.caseId).subscribe(this.updateFormsPerConsent.bind(this));
    }
  }

  openPostReferralConsent() {
    const config: NewWindowConfig = {
      path: `evaluation/${this.caseSummary.id}/partc-postref-consent`,
      popup: true,
      width: '1300px',
    };
    openNewWindow(config);
  }

  onChanged(e) {
    this.mostRecentProceduralSafeguard = e;
    if (!e) {
      this.accordion.openAll();
    } else {
      if (dayjs(e.providedOn).isBefore(dayjs().subtract(1, 'year'))) {
        this.accordion.openAll();
      } else {
        this.accordion.closeAll();
      }
    }
  }

  onSelectedQuestion(value: any, control: AbstractControl) {
    if (value) {
      control.patchValue(control.value ? `${control.value} ${value}` : `${value}`);
      this.formGroup.markAsDirty();
    }
  }

  private setSentenceStarters(labels: string[]) {
    this.sentenceStarters = labels;
  }
}
