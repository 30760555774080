<div class="backdrop">
  <div class="pic"></div>
  <div class="form">
    <form class="reset-password-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
      <h1 class="mat-display-1">Reset password</h1>

      <app-text-field type="password" label="Password" formControlName="password"></app-text-field>

      <app-text-field type="password" label="Confirmed Password" formControlName="confirmedPassword"></app-text-field>

      <mat-error *ngIf="formGroup.touched && formGroup.hasError('passwordMismatch')">Password and Confirm Password do not match.</mat-error>

      <button
        mat-flat-button
        aria-label="Reset Password"
        class="buttons"
        type="submit"
        color="primary"
        [isBusy]="isBusy"
        tabindex="0"
        [disabled]="!formGroup.valid"
      >
        Submit
      </button>
      <a class="back-to-login" routerLink="/auth/login">Back to login</a>

      <p class="mt-4">
        <b>Password requirements</b><br />
        <small>
          A minimum of 8 characters <br />
          Must have at least one uppercase letter <br />
          Must have at least one lowercase letter <br />
          Must have a number <br />
          Must have any one of these special characters (#?!@$%^&*-)
        </small>
      </p>
    </form>
  </div>
</div>
