<nav class="stepper__nav" *ngIf="caseId && iepId">
  <ul class="stepper__list">
    <a
      [routerLink]="getUrl('overview')"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="ESY Overview"
    >
      <li class="stepper__item">
        <i class="material-icons"> dashboard </i>
        <p>ESY Overview</p>
      </li>
    </a>
    <a
      [routerLink]="getUrl('goals')"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="ESY Goals and Services"
    >
      <li class="stepper__item">
        <i class="fas fa-bullseye"></i>
        <p>ESY Goals and Services</p>
      </li>
    </a>
  </ul>
</nav>
