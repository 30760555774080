import { EventEmitter } from '@angular/core';

export class AppEvents {
  static beforeRequest = new EventEmitter();
  static afterRequest = new EventEmitter();
  static autosaving = new EventEmitter<AutosavingStates>();
}

export enum AutosavingStates {
  Off,
  Saving,
  Saved,
  Done,
  OneOff,
  Failed,
}
