import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor(private http: HttpClient) {}

  getTemplates() {
    return this.http.get('/api/surveys/templates');
  }

  getSurveyLink(surveyId: any) {
    return this.http.get<string>(`/api/surveys/${surveyId}/generate`);
  }

  getAllCreatedSurveys() {
    return this.http.get('/api/surveys/all');
  }
}
