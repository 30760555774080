import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-iep-output',
  templateUrl: './iep-output.component.html',
  styleUrls: ['./iep-output.component.scss'],
})
export class IepOutputComponent implements OnInit {
  isPreview: boolean;

  dataSourceService: MatTableDataSource<any>;
  displayedColumnsService = ['section', 'domain', 'service', 'frequency', 'provider', 'serviceProvided'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<IepOutputComponent>) {
    this.isPreview = data.printData.isPreview;
  }

  ngOnInit(): void {
    this.dataSourceService = new MatTableDataSource([
      {
        section: '1a',
        domain: 'Reading',
        service: 'Specially Designed Instruction',
        frequency: '60 min/day',
        provider: 'Special education teacher',
        serviceProvided: 'Yes',
      },
    ]);
  }

  onClose() {
    this.dialogRef.close();
  }
}
