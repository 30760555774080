import { Directive, Host, Input, OnInit, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NgControl } from '@angular/forms';

import { Subscription } from 'rxjs';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[enabledOnly]',
})
export class EnabledOnlyDirective implements OnInit, OnDestroy {
  private valueChangeSubscription?: Subscription;

  @Input('enabledOnly') public validatorControlName: string;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('withValue') public validatorValue = true;
  @Input() public reset = true;
  @Input() public resetValue = null;

  constructor(@Optional() @Host() @SkipSelf() private parent: ControlContainer, @Optional() @Host() private ngControl: NgControl) {}

  ngOnInit(): void {
    if (!this.parent || !this.ngControl) {
      return;
    }

    this.setAvailability();
    this.valueChangeSubscription = this.parent.control?.valueChanges.subscribe((x) => this.setAvailability());
  }

  ngOnDestroy(): void {
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
  }

  private setAvailability(): void {
    const group = this.parent.control as FormGroup;
    const control = this.ngControl.control as FormControl;
    if (!group || !control) {
      return;
    }

    const validationControl = group.controls[this.validatorControlName] as FormControl;
    if (!validationControl) {
      return;
    }

    if (validationControl.value === this.validatorValue) {
      control.enable({ emitEvent: false });
    } else {
      control.disable({ emitEvent: false });
      if (this.reset) {
        control.setValue(this.resetValue, { emitEvent: false });
      }
    }
  }
}
