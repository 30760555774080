<div class="card__together-container">
  <mat-card class="card__together card__together--top pt-2 pb-0">
    <app-help-directional-text>
      <p class="mb-2">
        To alter the display of learners and goals, use the filter feature. You may filter by district, building, grade, goal area or use a
        keyword to search by goal nickname. You may also use the radio button to toggle between all goals and goals that have you have been
        assigned to monitor.
      </p>
    </app-help-directional-text>
    <div class="display-flex align-items-center justify-content-between">
      <h4 class="text-primary my-0">Filter By</h4>

      <button mat-raised-button aria-label="Clear Filters" color="primary" (click)="clearFilters()" tabindex="0">Clear Filters</button>
    </div>
    <form [formGroup]="filterFormGroup">
      <div class="row">
        <div class="col-md-12 col-lg-4 col-xl-3">
          <app-autocomplete label="District" formControlName="district" [options]="districtOptions"></app-autocomplete>
        </div>
        <div class="col-md-12 col-lg-4 col-xl-3">
          <app-autocomplete label="Building" formControlName="building" [options]="buildingOptions"></app-autocomplete>
        </div>
        <div class="col-md-12 col-lg-4 col-xl-3">
          <app-autocomplete label="Grade" formControlName="grade" [options]="gradeOptions"></app-autocomplete>
        </div>
        <div class="col-md-12 col-lg-4 col-xl-3">
          <app-autocomplete label="Goal Area" formControlName="goalArea" [options]="goalAreaOptions"></app-autocomplete>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-text-field label="Search" formControlName="searchTerm"></app-text-field>
        </div>
        <div class="col-md-12 col-lg-12 col-xl-4 display-flex align-items-center">
          <fieldset>
            <app-radio-group formControlName="progressToShow" [options]="progressToShowOptions" [useUndo]="false"></app-radio-group>
          </fieldset>
        </div>
      </div>
    </form>
  </mat-card>
  <mat-card class="card__together card__together--bottom">
    <div class="table-overflow">
      <mat-accordion role="treegrid" displayMode="flat" multi class="mat-table" [togglePosition]="'before'">
        <section role="row" matSort class="mat-header-row">
          <span role="columnheader" class="mat-header-cell" mat-sort-header="learnerName" (click)="sortHeader('learnerName')">
            Learner
          </span>
          <span
            role="columnheader"
            class="mat-header-cell mat-cell--lg"
            mat-sort-header="goalNickname"
            (click)="sortHeader('goalNickname')"
          >
            Goal Nickname
          </span>
          <span role="columnheader" class="mat-header-cell" mat-sort-header="targetValue" (click)="sortHeader('targetValue')">
            Target Value
          </span>
          <span role="columnheader" class="mat-header-cell" mat-sort-header="measurement" (click)="sortHeader('measurement')">
            Measurement
          </span>
          <span role="columnheader" class="mat-header-cell mat-cell--short" mat-sort-header="date"> Date </span>
          <span role="columnheader" class="mat-header-cell mat-cell--shorter" mat-sort-header="value"> Value </span>
          <span role="columnheader" class="mat-header-cell" mat-sort-header="monitoringNotes"> Monitoring Notes </span>
          <span role="columnheader" class="mat-header-cell"> &nbsp; </span>
        </section>
        <ng-container *ngFor="let item of gradeBook; let i = index" [formGroup]="item">
          <mat-expansion-panel
            class="mat-elevation-z border-bottom color-fix"
            [hideToggle]="item.get('goal').get('objectives').value.length === 0"
            [ngClass]="{
              'prevent-click': item.get('goal').get('objectives').value.length === 0
            }"
            role="rowgroup"
            aria-live="polite"
          >
            <mat-expansion-panel-header
              class="mat-row"
              [ngClass]="{
                'expand-pos-fix': item.get('goal').get('objectives').value.length === 0
              }"
              (keydown.Space)="$event.stopImmediatePropagation()"
              role="row"
              aria-level="1"
            >
              <span role="gridcell" class="mat-cell">
                {{ item.get('learner').get('name').value }}
              </span>
              <span role="gridcell" class="mat-cell mat-cell--lg">
                <a
                  class="allow-click"
                  href="javascript:void(0)"
                  (click)="onNavigateToEnterProgress(item, $event, false)"
                  tabindex="0"
                  role="link"
                >
                  {{ item.get('goal').get('name').value }}
                </a>
              </span>
              <span role="gridcell" class="mat-cell">
                {{ item.get('goal').get('primaryMeasurement').get('targetValue').value }}
              </span>
              <span role="gridcell" class="mat-cell">
                {{ item.get('goal').get('primaryMeasurement').get('unit').value }}
              </span>
              <app-add-progress [formGroup]="item" [trackFormChanges]="item" idPrefix="{{ i }}"></app-add-progress>
              <span role="gridcell" aria-colindex="8" class="mat-cell mat-cell--actions text-right">
                <button
                  mat-icon-button
                  aria-label="View Graph Icon"
                  color="primary"
                  class="mx-2 allow-click"
                  (click)="onOpenGraph($event, item, false)"
                  tabindex="0"
                >
                  <mat-icon>auto_graph</mat-icon>
                </button>
              </span>
            </mat-expansion-panel-header>
            <div
              role="row"
              aria-level="2"
              class="mat-row border-top"
              *ngFor="let objective of getObjectivesFormArray(item.get('goal').get('objectives')); let child_i = index"
              [formGroup]="objective"
            >
              <span role="gridcell" class="mat-cell" id="learner"></span>
              <span role="gridcell" class="mat-cell">
                <a href="javascript:void(0)" (click)="onNavigateToEnterProgress(objective, $event, true)">
                  {{ objective.get('name').value }}
                </a>
              </span>
              <span role="gridcell" class="mat-cell">
                {{ objective.get('primaryMeasurement').get('unit').value }}
              </span>
              <span role="gridcell" class="mat-cell">
                {{ objective.get('primaryMeasurement').get('targetValue').value }}
              </span>
              <app-add-progress
                [formGroup]="objective"
                [trackFormChanges]="objective"
                idPrefix="{{ i + '_' + child_i }}"
              ></app-add-progress>
              <span role="gridcell" class="mat-cell">
                <button
                  mat-icon-button
                  aria-label="View Graph Icon"
                  color="primary"
                  (click)="onOpenGraph($event, objective, true)"
                  class="mx-2 allow-click"
                  tabindex="0"
                >
                  <mat-icon>auto_graph</mat-icon>
                </button>
              </span>
            </div>
          </mat-expansion-panel>
        </ng-container>
        <p class="cell--no-results" *ngIf="false">
          <span class="text-sm">
            <i>No results...</i>
          </span>
        </p>
      </mat-accordion>
    </div>
  </mat-card>
</div>
<div class="action__row">
  <button mat-raised-button aria-label="Save All" color="primary" (click)="onSave()" [isBusy]="isBusy" tabindex="0">Save All</button>
</div>
