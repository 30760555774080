import { Component, OnInit } from '@angular/core';
import { SurveyService } from '../../services/survey.service';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.scss'],
})
export class SurveyListComponent implements OnInit {
  surveyTemplates = new MatTableDataSource([]);
  createdSurveys = new MatTableDataSource([]);
  displayedColumns = ['name', 'language', 'actions'];
  displayedColumns2 = ['id', 'survey', 'sharableLink', 'actions'];

  get canDisplayStatus() {
    return (
      this.authService.isSuperAdmin ||
      this.authService.isUberAdmin ||
      this.authService.isAeaStaff ||
      this.authService.isLeaStaff ||
      this.authService.isStateUser
    );
  }

  constructor(private surveyService: SurveyService, private authService: AuthService) {}

  ngOnInit(): void {
    if (this.canDisplayStatus) {
      this.displayedColumns2 = ['id', 'survey', 'sharableLink', 'status', 'actions'];
    }
    this.getTemplates();
  }

  getTemplates() {
    this.surveyService.getTemplates().subscribe((res: any) => {
      this.surveyTemplates.data = res.data;
      this.getSurveys();
    });
  }

  getSurveys() {
    this.surveyService.getAllCreatedSurveys().subscribe((res: any) => {
      console.log('res', res);
      this.createdSurveys.data = res.map((s) => {
        return {
          id: s.id,
          shareableLink: s.shareableLinkId,
          templateName: this.surveyTemplates.data.find((t) => t.id === s.surveyTemplateId).name,
          status: this.getFormattedStatus(s.status),
        };
      });
    });
  }

  getSurveyLink(templateId: string) {
    this.surveyService.getSurveyLink(templateId).subscribe((res: any) => {
      res.templateName = this.surveyTemplates.data.find((t) => t.id === templateId).name;
      const data = this.createdSurveys.data;
      this.createdSurveys.data = [res].concat(data);
    });
  }

  getFormattedStatus(status) {
    switch (status) {
      case 'Completed':
        return 'Completed';
      case 'InProgress':
        return 'In-Progress';
      case 'NotStarted':
        return 'Not Started';
      case 'Locked':
        return 'Locked';
      default:
        return status;
    }
    return status;
  }
}
