import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EcoRatingService } from 'src/app/evaluation/evaluation/steps/eco-wizard/eco-rating.service';
import { EcoMatrixModalComponent } from 'src/app/evaluation/modals/eco-matrix-modal/eco-matrix-modal.component';
import { EcoArea, EcoAreaType } from 'src/app/evaluation/models/evaluation';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { StringSizes } from 'src/app/shared/components/form/constants/constants';
import { CaseSummaryByIepId, IntakeType } from 'src/app/shared/models/case';
import { Gender } from 'src/app/shared/models/learner';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { EcoAreaService } from 'src/app/shared/services/eco-areas/eco-area.service';
import { HelpSection } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { IepPlaafpHelp } from 'src/app/shared/services/help/models/iep.help';
import { EcoRatingType } from '../../evaluation/evaluation/steps/eco-wizard/eco-rating';
import { BaseComponent } from '../../shared/components/base-component/base-component';
import { yesNoOptions } from '../../shared/formHelpers';
import { DeactivationService, DeactivationStatus } from '../../shared/services/deactivation.service';
import { NotificationService } from '../../shared/services/notification.service';
import { IepPlaafpViewMoreComponent } from '../modals/iep-plaafp-view-more/iep-plaafp-view-more.component';
import { IepAmendment } from '../models/iep-amendment';
import { Plaafp } from '../models/plaafp';
import { IepAmendmentService } from '../services/iep-amendment.service';
import { IepService } from '../services/iep.service';

@Component({
  selector: 'app-iep-plaafp',
  templateUrl: './iep-plaafp.component.html',
  styleUrls: ['./iep-plaafp.component.scss'],
})
export class IepPlaafpComponent extends BaseComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(MatSort) sort: MatSort;
  @Input() amendments: IepAmendment[] = [];
  @Input() isAmendment = false;
  @Input() isDetailsView = false;
  @Input() evaluationId: string;

  stringSizes = StringSizes;
  caseSummary: CaseSummaryByIepId;
  iepPlaafpHelp = IepPlaafpHelp;
  yesNoOptions = yesNoOptions;
  activeCall = false;

  get strengths() {
    return this.formGroup.get('strengths') as FormArray;
  }

  get preferences() {
    return this.formGroup.get('preferences') as FormArray;
  }

  get concerns() {
    return this.formGroup.get('concerns') as FormArray;
  }

  get hasOpenAmendment() {
    return !!this.amendment && !this.amendmentIsFinalized;
  }

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentId() {
    return this.amendment?.id;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get showAutofill() {
    return (
      (!this.isAmendment || (this.hasOpenAmendment && this.amendment.amendingPlaafp && this.iepIsPk && this.learnerIsPk)) &&
      !this.caseSummary?.learner?.eligibleInLegacySystem &&
      !this.caseSummary?.learner?.eligibleInLegacySystemPartC &&
      !this.caseSummary?.learner?.movingFromOutOfState
    );
  }

  get lastFinalizedDate() {
    if (this.amendments && this.amendments.length > 0) {
      const latest = this.amendments.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasAmendment() {
    return this.amendmentId && this.amendmentId !== null;
  }

  iepId: string;
  ecoAreas: EcoArea[] = [];
  learnerIsPk = false;
  iepIsPk = false;
  learnerId: string;
  isSecondaryTransition = false;
  firstName: string;
  gender: Gender;
  plaafpId: string;
  secondaryTransitionId: string;
  plaafpModel: Plaafp;
  //#region Strength/Skills Vars
  isEditingStrengthSkill = false;
  displayedStrengthSkillColumns = ['actions', 'comments', 'dataSource', 'tags'];
  strengthSkillDataSource = new MatTableDataSource<any>([]);
  strengthSkillForEdit: any;
  strengthSkillFormGroup = this.fb.group({
    comments: new FormControl('', [Validators.required]),
    dataSource: new FormControl('', [Validators.required]),
    tags: new FormControl([]),
    living: new FormControl(null),
    learning: new FormControl(null),
    working: new FormControl(null),
    removedByAmendment: new FormControl(false),
  });
  @ViewChild('strengthSkillForm') strengthSkillForm: NgForm;
  //#endregion
  //#region Preferences Vars
  isEditingPreference = false;
  displayedPreferenceColumns = ['actions', 'comments', 'dataSource', 'tags'];
  preferenceDataSource = new MatTableDataSource<any>([]);
  preferenceForEdit: any;
  preferenceFormGroup = this.fb.group({
    comments: new FormControl('', [Validators.required]),
    dataSource: new FormControl('', [Validators.required]),
    tags: new FormControl([]),
    living: new FormControl(null),
    learning: new FormControl(null),
    working: new FormControl(null),
    removedByAmendment: new FormControl(false),
  });
  @ViewChild('preferenceForm') preferenceForm: NgForm;
  //#endregion
  //#region Concerns Vars
  isEditingConcern = false;
  displayedConcernColumns = ['actions', 'comments', 'dataSource', 'tags'];
  concernDataSource = new MatTableDataSource<any>([]);
  concernForEdit: any;
  concernFormGroup = this.fb.group({
    comments: new FormControl('', [Validators.required]),
    dataSource: new FormControl('', [Validators.required]),
    tags: new FormControl([]),
    living: new FormControl(null),
    learning: new FormControl(null),
    working: new FormControl(null),
    removedByAmendment: new FormControl(false),
  });
  @ViewChild('concernForm') concernForm: NgForm;
  //#endregion
  preferenceInterestDisplayedColumns = ['preferenceInterest', 'dataSource'];
  preferenceInterestDataSource = new MatTableDataSource([]);

  formGroup = this.fb.group({
    strengths: this.fb.array([]),
    preferences: this.fb.array([]),

    limitedEnglishAddressed: new FormControl(''),

    behaviorAddressed: new FormControl(''),
    behaviorNoLongerFactor: new FormControl(''),
    hasFba: new FormControl(''),
    bipWillBeDeveloped: new FormControl(''),
    fbaWillBeConducted: new FormControl(''),
    hasBip: new FormControl(''),

    accessibleMaterialsAddressed: new FormControl(''),
    visionAddressed: new FormControl(''),
    communicationLanguageAddressed: new FormControl(''),
    assistiveTechnologyAddressed: new FormControl(''),
    healthAddressed: new FormControl(''),
    healthPlan: new FormControl(''),
    healthPlanCreate: new FormControl(''),
    hearingAddressed: new FormControl(''),

    allConsideredNoneSelected: new FormControl(null),

    describeStudentInvolvement: new FormControl('', Validators.required),
    effectOfDisability: new FormControl('', Validators.required),
    describeHowParticipate: new FormControl('', Validators.required),
    concerns: this.fb.array([]),
    otherInformation: new FormControl(''),

    ecoAreas: this.fb.array([], {}),
  });
  strengthsSkillsSentenceStarters: string[];
  preferencesSentenceStarters: string[];
  concernsSentenceStarters: string[];

  get preferencesArr(): FormGroup[] {
    return (this.formGroup.get('preferences') as FormArray).controls as FormGroup[];
  }

  get ecoAreaFormGroups(): FormGroup[] {
    return (this.formGroup.get('ecoAreas') as FormArray).controls as FormGroup[];
  }

  save = new Observable<boolean>((observer) => {
    this.cd.detectChanges();

    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };

    if (!this.formGroup.dirty || this.isSaving) {
      done();
      return;
    }

    this.isSaving = true;
    this.onSave().then(() => {
      done();
    });
  });

  constructor(
    private fb: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly iepService: IepService,
    private readonly route: ActivatedRoute,
    private readonly ecoAreaService: EcoAreaService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private readonly ecoRatingService: EcoRatingService,
    private caseService: CaseService,
    private iepAmendmentService: IepAmendmentService,
    private helpService: HelpService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  canDeactivate(): DeactivationStatus | Observable<DeactivationStatus> | Promise<DeactivationStatus> {
    return (super.canDeactivate() as Observable<DeactivationStatus>).pipe(
      map((status) =>
        status === DeactivationStatus.Accepted &&
        (this.preferenceFormGroup?.dirty || this.concernFormGroup?.dirty || this.strengthSkillFormGroup?.dirty)
          ? DeactivationStatus.NeedsConfirmation
          : status
      )
    );
  }

  async ngOnInit(): Promise<void> {
    this.iepId = this.route.parent?.snapshot.paramMap.get('iepId') ?? this.route.snapshot.paramMap.get('iepId');

    await this.refreshPlaafp(true);

    this.caseService.getCaseSummaryByIep(this.iepId).subscribe((caseSummary) => {
      this.caseSummary = caseSummary;
      this.evaluationId = caseSummary.evaluationId;
      this.isSecondaryTransition = caseSummary.isSecondaryTransition;
      if (this.isSecondaryTransition) {
        this.displayedStrengthSkillColumns.push('llwTags');
        this.displayedPreferenceColumns.push('llwTags');
        this.displayedConcernColumns.push('llwTags');
      }
    });

    this.subscriptions.add(this.iepAmendmentService.amendmentUpdated$.subscribe(() => this.refreshPlaafp()));

    this.saveSubscription = this.formGroup.valueChanges;
    this.startAutosaving();

    // Init Table Sorting
    this.strengthSkillDataSource.sort = this.preferenceDataSource.sort = this.concernDataSource.sort = this.sort;
  }

  async refreshPlaafp(isInit = false): Promise<void> {
    const plaafp = await this.fetchPlaafp().toPromise();
    if (isInit) {
      if (plaafp.iepIsPk) {
        this.setupEcoAreas(plaafp);
      }

      if (this.isSecondaryTransition && !plaafp.secondaryTransitionId) {
        await this.iepService.rebuildSecondaryTransition(this.iepId, plaafp?.priorVersionId, plaafp?.id, this.amendmentId).toPromise();
      }

      this.setupStrengths(plaafp?.strengths);
      this.setupPreferences(plaafp?.preferences);
      this.setupConcerns(plaafp?.concerns);
    }
    this.processPlaafp(plaafp);
  }

  setupStrengths(strengths) {
    if (this.formGroup.get('strengths') as FormArray) {
      (this.formGroup.get('strengths') as FormArray).clear();
    }
    strengths.forEach((x) => {
      (this.formGroup.get('strengths') as FormArray).push(
        this.fb.group({
          id: this.fb.control(x.id),
          comments: this.fb.control(x.comments),
          tags: this.fb.control(x.tags),
          dataSource: this.fb.control(x.dataSource),
          living: this.fb.control(x.living),
          learning: this.fb.control(x.learning),
          working: this.fb.control(x.working),
          priorVersionId: this.fb.control(x.priorVersionId),
          removedByAmendment: this.fb.control(x.removedByAmendment),
        })
      );
    });
  }

  onAmend() {
    if (!this.amendment.amendingPlaafp) {
      this.amendment.amendingPlaafp = true;
      this.iepAmendmentService.updateAmendment(this.iepId, this.amendment).subscribe(() => {});
    }
  }

  setupPreferences(preferences) {
    if (this.formGroup.get('preferences') as FormArray) {
      (this.formGroup.get('preferences') as FormArray).clear();
    }

    preferences.forEach((x) => {
      (this.formGroup.get('preferences') as FormArray).push(
        this.fb.group({
          id: this.fb.control(x.id),
          comments: this.fb.control(x.comments),
          tags: this.fb.control(x.tags),
          dataSource: this.fb.control(x.dataSource),
          living: this.fb.control(x.living),
          learning: this.fb.control(x.learning),
          working: this.fb.control(x.working),
          priorVersionId: this.fb.control(x.priorVersionId),
          removedByAmendment: this.fb.control(x.removedByAmendment),
        })
      );
    });
  }

  setupConcerns(concerns) {
    if (this.formGroup.get('concerns') as FormArray) {
      (this.formGroup.get('concerns') as FormArray).clear();
    }
    concerns.forEach((x) => {
      (this.formGroup.get('concerns') as FormArray).push(
        this.fb.group({
          id: this.fb.control(x.id),
          comments: this.fb.control(x.comments),
          tags: this.fb.control(x.tags),
          dataSource: this.fb.control(x.dataSource),
          living: this.fb.control(x.living),
          learning: this.fb.control(x.learning),
          working: this.fb.control(x.working),
          priorVersionId: this.fb.control(x.priorVersionId),
          removedByAmendment: this.fb.control(x.removedByAmendment),
        })
      );
    });
  }

  fetchPlaafp(): Observable<Plaafp> {
    return this.iepService.getPlaafp(this.iepId, this.amendment ? this.amendment?.id : '');
  }

  processPlaafp(plaafpResult: Plaafp) {
    this.plaafpModel = plaafpResult;
    this.plaafpId = plaafpResult.id;
    this.secondaryTransitionId = plaafpResult.secondaryTransitionId;
    this.firstName = plaafpResult.learnerFirstName;
    this.gender = plaafpResult.learnerGender;
    this.learnerIsPk = plaafpResult.learnerIsPk;
    this.iepIsPk = plaafpResult.iepIsPk;
    this.learnerId = plaafpResult.learnerId;
    this.formGroup.patchValue(plaafpResult, { emitEvent: false });

    if (this.iepIsPk) {
      this.adjustPkFields();
    }

    this.strengthSkillDataSource.data = plaafpResult.strengths;
    this.preferenceDataSource.data = plaafpResult.preferences;
    this.concernDataSource.data = plaafpResult.concerns;

    this.setSentenceStarters();
  }

  async setupEcoAreas(plaafpResult): Promise<void> {
    const ecoAreaResult = await this.ecoAreaService.get().toPromise();
    this.ecoAreas = ecoAreaResult;
    const promises = new Array<Promise<void>>();
    for (const ecoArea of ecoAreaResult) {
      promises.push(this.loadEcoRating(plaafpResult, ecoArea));
    }
    await Promise.all(promises);
  }

  async loadEcoRating(plaafpResult, ecoArea: EcoArea): Promise<void> {
    const result = await this.ecoRatingService.get(this.iepId, ecoArea.id, EcoRatingType.Iep, this.amendment?.id).toPromise();
    const ecoRatingFormGroup = this.fb.group({
      id: [ecoArea.id],
      score: [result ? result.score : null],
      scoreText: [result ? this.ecoRatingService.getSuggestedEcoRatingText(result.score, this.firstName) : null],
      label: [ecoArea.label],
      type: [ecoArea.type],
      savedDescription: [this.getDescriptionValue(ecoArea.type, plaafpResult)],
      description: [this.getDescriptionValue(ecoArea.type, plaafpResult), Validators.required],
      priorScoreText: [
        result && result.priorVersionId ? this.ecoRatingService.getSuggestedEcoRatingText(result.priorVersion.score, this.firstName) : null,
      ],
    });
    (this.formGroup.get('ecoAreas') as FormArray).insert(ecoArea.order, ecoRatingFormGroup);
  }

  getCommentPriorVersion(type, element) {
    let returnText = '';
    let row = null;

    if (type === 'StrengthSkill') {
      row = this.plaafpModel?.priorVersion?.strengths.find((x) => x.id === element.priorVersionId)?.comments;
    } else if (type === 'Preference') {
      row = this.plaafpModel?.priorVersion?.preferences.find((x) => x.id === element.priorVersionId)?.comments;
    } else if (type === 'Concern') {
      row = this.plaafpModel?.priorVersion?.concerns.find((x) => x.id === element.priorVersionId)?.comments;
    }

    if (row) {
      returnText += row;
    }
    return returnText;
  }

  getDatasourcePriorVersion(type, element) {
    let returnText = '';
    let row = null;

    if (type === 'StrengthSkill') {
      row = this.plaafpModel?.priorVersion?.strengths.find((x) => x.id === element.priorVersionId)?.dataSource;
    } else if (type === 'Preference') {
      row = this.plaafpModel?.priorVersion?.preferences.find((x) => x.id === element.priorVersionId)?.dataSource;
    } else if (type === 'Concern') {
      row = this.plaafpModel?.priorVersion?.concerns.find((x) => x.id === element.priorVersionId)?.dataSource;
    }

    if (row) {
      returnText += row;
    }
    return returnText;
  }

  getDescriptionValue(type: EcoAreaType, plaafpResult: Plaafp) {
    if (type === EcoAreaType.AcquisitionSkills) {
      return plaafpResult.acquisitionSkills;
    }

    if (type === EcoAreaType.AppropriateBehaviors) {
      return plaafpResult.appropriateNeeds;
    }

    if (type === EcoAreaType.PositiveSocialEmotionalSkills) {
      return plaafpResult.positiveSkills;
    }
  }

  adjustPkFields() {
    if (!!this.formGroup.controls.strengths) {
      this.formGroup.removeControl('strengths');
    }
  }

  private setSentenceStarters() {
    this.strengthsSkillsSentenceStarters = [
      `At school, ${this.firstName} is good at`,
      `At home, ${this.firstName} is good at`,
      `In community settings, ${this.firstName} is good at`,
      `${this.firstName} is successful with access to`,
      `${this.firstName} performs at or above age expectations when`,
      `${this.firstName} is successful when`,
    ];
    this.preferencesSentenceStarters = [
      `${this.firstName} excels when we are learning about`,
      `${this.firstName} is most engaged in classroom and other activities such as`,
      `${this.firstName} is engaged in community settings such as`,
      `${this.firstName} seems most interested in`,
      `${this.firstName} functions best when working with groups that are`,
      `${this.firstName} appears motivated by`,
      `${this.firstName} is discouraged by`,
      `${this.firstName} engages in group discussions when they are allowed to use`,
      `${this.firstName} responds positively when presented information in a format such as`,
    ];
    // TODO: they didn`t know the "Concerns" sentence starts...update when we do
    this.concernsSentenceStarters = [
      `${this.firstName} loses confidence when we are learning about`,
      `${this.firstName} engages in off-task or disruptive behavior when`,
      `${this.firstName} is discouraged by`,
      `At school, ${this.firstName} struggles with`,
      `At home, ${this.firstName} struggles with`,
      `In community settings, ${this.firstName} needs additional support when`,
      `${this.firstName} is unable to`,
      `${this.firstName} needs support of prompting when`,
      `${this.firstName} is less successful when`,
      `${this.firstName} is unable to complete tasks independently when`,
    ];
  }

  onSelectedQuestion(value: string, targetControl: AbstractControl) {
    if (value) {
      targetControl.value ? targetControl.patchValue(`${targetControl.value} ${value}`) : targetControl.patchValue(value);
    }
  }

  onCancel(section) {
    if (section === 'StrengthSkill') {
      this.strengthSkillForEdit = null;
      this.strengthSkillFormGroup.reset();
      this.isEditingStrengthSkill = false;
    } else if (section === 'Preference') {
      this.preferenceForEdit = null;
      this.preferenceFormGroup.reset();
      this.isEditingPreference = false;
    } else if (section === 'Concern') {
      this.concernForEdit = null;
      this.concernFormGroup.reset();
      this.isEditingConcern = false;
    }
  }

  onViewMore(type, el): void {
    this.dialog.open(IepPlaafpViewMoreComponent, {
      width: '728px',
      data: {
        plaafp: this.plaafpModel,
        entity: el,
        type: type,
        amendmentId: this.amendmentId,
        amendmentIsFinalized: this.amendmentIsFinalized,
        lastFinalizedDate: this.lastFinalizedDate,
      },
    });
    // const modalData: ViewMoreModalData[] = [
    //   {
    //     name: 'Data Source',
    //     value: entity.dataSource,
    //   },
    // ];

    // switch (section) {
    //   case 'StrengthSkill':
    //     modalData.unshift({
    //       name: 'Strengths and Skills',
    //       value: entity.comments,
    //     });
    //     break;
    //   case 'Preference':
    //     modalData.unshift({
    //       name: 'Preferences and Interests',
    //       value: entity.comments,
    //     });
    //     break;
    //   case 'Concern':
    //     modalData.unshift({
    //       name: 'Needs and Concerns',
    //       value: entity.comments,
    //     });
    //     break;
    // }
  }

  onEdit(section, entity) {
    if (section === 'StrengthSkill') {
      this.strengthSkillForEdit = entity;
      this.strengthSkillFormGroup.patchValue(entity);
      this.isEditingStrengthSkill = true;
    } else if (section === 'Preference') {
      this.preferenceForEdit = entity;
      this.preferenceFormGroup.patchValue(entity);
      this.isEditingPreference = true;
    } else if (section === 'Concern') {
      this.concernForEdit = entity;
      this.concernFormGroup.patchValue(entity);
      this.isEditingConcern = true;
    }
  }

  setStrengthValues() {
    const strength = this.strengthSkillFormGroup.value;
    const strengths = (this.formGroup.get('strengths') as FormArray).value;
    if (this.strengthSkillForEdit && this.strengthSkillForEdit.id) {
      const strengthIndex = strengths.findIndex((x) => x.id === this.strengthSkillForEdit.id);
      strengths[strengthIndex].id = this.strengthSkillForEdit.id;
      strengths[strengthIndex].comments = strength.comments;
      strengths[strengthIndex].tags = strength.tags;
      strengths[strengthIndex].dataSource = strength.dataSource;
      strengths[strengthIndex].learning = strength.learning;
      strengths[strengthIndex].living = strength.living;
      strengths[strengthIndex].working = strength.working;
      strengths[strengthIndex].removedByAmendment = strength.removedByAmendment;
    } else {
      (this.formGroup.get('strengths') as FormArray).push(
        this.fb.group({
          id: this.fb.control(strength.id),
          comments: this.fb.control(strength.comments),
          tags: this.fb.control(strength.tags),
          dataSource: this.fb.control(strength.dataSource),
          learning: this.fb.control(strength.learning),
          living: this.fb.control(strength.living),
          working: this.fb.control(strength.working),
          removedByAmendment: this.fb.control(strength.removedByAmendment),
        })
      );
    }
  }

  setPreferenceValues() {
    const preference = this.preferenceFormGroup.value;
    const preferences = (this.formGroup.get('preferences') as FormArray)?.value;
    if (this.preferenceForEdit && this.preferenceForEdit.id) {
      const preferenceIndex = preferences.findIndex((x) => x.id === this.preferenceForEdit.id);
      preferences[preferenceIndex].id = this.preferenceForEdit.id;
      preferences[preferenceIndex].comments = preference.comments;
      preferences[preferenceIndex].tags = preference.tags;
      preferences[preferenceIndex].dataSource = preference.dataSource;
      preferences[preferenceIndex].learning = preference.learning;
      preferences[preferenceIndex].living = preference.living;
      preferences[preferenceIndex].working = preference.working;
      preferences[preferenceIndex].removedByAmendment = preference.removedByAmendment;
    } else {
      (this.formGroup.get('preferences') as FormArray).push(
        this.fb.group({
          id: this.fb.control(preference.id),
          comments: this.fb.control(preference.comments),
          tags: this.fb.control(preference.tags),
          dataSource: this.fb.control(preference.dataSource),
          learning: this.fb.control(preference.learning),
          living: this.fb.control(preference.living),
          working: this.fb.control(preference.working),
          removedByAmendment: this.fb.control(preference.removedByAmendment),
        })
      );
    }
  }

  setConcernValues() {
    const concern = this.concernFormGroup.value;
    const concerns = (this.formGroup.get('concerns') as FormArray).value;
    if (this.concernForEdit && this.concernForEdit.id) {
      const concernIndex = concerns.findIndex((x) => x.id === this.concernForEdit.id);
      concerns[concernIndex].id = this.concernForEdit.id;
      concerns[concernIndex].comments = concern.comments;
      concerns[concernIndex].tags = concern.tags;
      concerns[concernIndex].dataSource = concern.dataSource;
      concerns[concernIndex].learning = concern.learning;
      concerns[concernIndex].living = concern.living;
      concerns[concernIndex].working = concern.working;
      concerns[concernIndex].removedByAmendment = concern.removedByAmendment;
    } else {
      (this.formGroup.get('concerns') as FormArray).push(
        this.fb.group({
          id: this.fb.control(concern.id),
          comments: this.fb.control(concern.comments),
          tags: this.fb.control(concern.tags),
          dataSource: this.fb.control(concern.dataSource),
          learning: this.fb.control(concern.learning),
          living: this.fb.control(concern.living),
          working: this.fb.control(concern.working),
          removedByAmendment: this.fb.control(concern.removedByAmendment),
        })
      );
    }
  }

  onSubmit(section, formGroup, form) {
    if (formGroup.valid) {
      this.isEditingStrengthSkill = false;
      this.isEditingPreference = false;
      this.isEditingConcern = false;
      this.onSave(section, form);
    }
  }

  async onSave(section = null, formForReset = null): Promise<Observable<void>> {
    if (section) {
      if (section === 'StrengthSkill') {
        this.setStrengthValues();
      } else if (section === 'Preference') {
        this.setPreferenceValues();
      } else if (section === 'Concern') {
        this.setConcernValues();
      }
    }

    const formValues = { ...this.formGroup.value };
    formValues.id = this.plaafpId;
    formValues.amendmentId = this.amendment?.id;
    try {
      this.activeCall = true;
      await this.iepService.updatePlaafp(this.iepId, formValues).toPromise();
      if (formForReset) {
        if (section === 'StrengthSkill') {
          this.strengthSkillForEdit = null;
          this.strengthSkillFormGroup.reset({
            tags: [],
            removedByAmendment: false,
          });
        } else if (section === 'Preference') {
          this.preferenceForEdit = null;
          this.preferenceFormGroup.reset({
            tags: [],
            removedByAmendment: false,
          });
        } else if (section === 'Concern') {
          this.concernForEdit = null;
          this.concernFormGroup.reset({ tags: [], removedByAmendment: false });
        }
        formForReset.resetForm({ tags: [], removedByAmendment: false });
      }
      this.activeCall = false;
      await this.refreshPlaafp();
    } catch {
      this.activeCall = false;
      this.notificationService.error('Failed to save.');
    }
    return;
  }

  onDelete(section, entity) {
    const message = `Are you sure you want to ${this.hasOpenAmendment ? 'remove' : 'delete'} this item?`;
    this.notificationService.confirmation(message, () => this.processDelete(section, entity));
  }

  canUndoDelete(entity) {
    if (entity.removedByAmendment && this.hasOpenAmendment) {
      return true;
    }
    return false;
  }

  canDelete(dataSourceLength, entity) {
    if (dataSourceLength > 1 && entity.removedByAmendment === false) {
      return true;
    } else {
      return false;
    }
  }

  async undoDelete(section, entity): Promise<void> {
    if (section === 'StrengthSkill') {
      const strengths = (this.formGroup.get('strengths') as FormArray).value;
      const strengthIndex = strengths.findIndex((x) => x.id === entity.id);
      this.strengths.controls[strengthIndex].get('removedByAmendment').setValue(false);
    } else if (section === 'Preference') {
      const preferences = (this.formGroup.get('preferences') as FormArray).value;
      const preferenceIndex = preferences.findIndex((x) => x.id === entity.id);
      this.preferences.controls[preferenceIndex].get('removedByAmendment').setValue(false);
    } else if (section === 'Concern') {
      const concerns = (this.formGroup.get('concerns') as FormArray).value;
      const concernIndex = concerns.findIndex((x) => x.id === entity.id);
      this.concerns.controls[concernIndex].get('removedByAmendment').setValue(false);
    }
    await this.onSave();
  }

  async processDelete(section, entity): Promise<void> {
    if (section === 'StrengthSkill') {
      const strengths = (this.formGroup.get('strengths') as FormArray).value;
      const strengthIndex = strengths.findIndex((x) => x.id === entity.id);
      if (!this.hasOpenAmendment || (this.hasOpenAmendment && !entity.priorVersionId)) {
        (this.formGroup.get('strengths') as FormArray).removeAt(strengthIndex);
      }

      if (this.hasOpenAmendment && !!entity.priorVersionId) {
        this.strengths.controls[strengthIndex].get('removedByAmendment').setValue(true);
      }
    } else if (section === 'Preference') {
      const preferences = (this.formGroup.get('preferences') as FormArray).value;
      const preferenceIndex = preferences.findIndex((x) => x.id === entity.id);
      if (!this.hasOpenAmendment || (this.hasOpenAmendment && !entity.priorVersionId)) {
        (this.formGroup.get('preferences') as FormArray).removeAt(preferenceIndex);
      }
      if (this.hasOpenAmendment && !!entity.priorVersionId) {
        this.preferences.controls[preferenceIndex].get('removedByAmendment').setValue(true);
      }
    } else if (section === 'Concern') {
      const concerns = (this.formGroup.get('concerns') as FormArray).value;
      const concernIndex = concerns.findIndex((x) => x.id === entity.id);
      if (!this.hasOpenAmendment || (this.hasOpenAmendment && !entity.priorVersionId)) {
        (this.formGroup.get('concerns') as FormArray).removeAt(concernIndex);
      }

      if (this.hasOpenAmendment && !!entity.priorVersionId) {
        this.concerns.controls[concernIndex].get('removedByAmendment').setValue(true);
      }
    }
    await this.onSave();
  }

  findPriorEcoAreaDescription(ecoAreaType: EcoAreaType): string {
    if (ecoAreaType === EcoAreaType.AcquisitionSkills) {
      return this.plaafpModel?.priorVersion?.acquisitionSkills;
    }
    if (ecoAreaType === EcoAreaType.AppropriateBehaviors) {
      return this.plaafpModel?.priorVersion?.appropriateNeeds;
    }
    if (ecoAreaType === EcoAreaType.PositiveSocialEmotionalSkills) {
      return this.plaafpModel?.priorVersion?.positiveSkills;
    }
  }

  async autoFillEcoMatrixSummaries(): Promise<void> {
    if (
      this.caseSummary.learner.eligibleInLegacySystem ||
      this.caseSummary.learner.eligibleInLegacySystemPartC ||
      this.caseSummary.learner.movingFromOutOfState
    ) {
      this.notificationService.alert('This learner was not found eligible in ACHIEVE and there is no data to fill.');
      return;
    }

    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        subQuestion: 'Clicking Yes will overwrite your current responses with the summaries from the ECO Matrix.',
      },
    });

    const yesImSure = await dialogRef.afterClosed().toPromise();
    if (!yesImSure) {
      return;
    }

    const ecoSummaries = await this.iepService.autoFillEcoMatrixTags(this.iepId).toPromise();
    let ecoAreaFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AcquisitionSkills);
    ecoAreaFormGroup.get('description').setValue(ecoSummaries?.acquisitionSkillsSummary);
    ecoAreaFormGroup.get('score').setValue(ecoSummaries?.acquisitionSkillsScore);
    if (ecoSummaries?.acquisitionSkillsScore) {
      ecoAreaFormGroup
        .get('scoreText')
        .setValue(this.ecoRatingService.getSuggestedEcoRatingText(ecoSummaries.acquisitionSkillsScore, this.firstName));
    }

    ecoAreaFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.AppropriateBehaviors);
    ecoAreaFormGroup.get('description').setValue(ecoSummaries?.appropriateNeedsSummary);
    ecoAreaFormGroup.get('score').setValue(ecoSummaries?.appropriateNeedsScore);
    if (ecoSummaries?.appropriateNeedsScore) {
      ecoAreaFormGroup
        .get('scoreText')
        .setValue(this.ecoRatingService.getSuggestedEcoRatingText(ecoSummaries.appropriateNeedsScore, this.firstName));
    }

    ecoAreaFormGroup = this.ecoAreaFormGroups.find((x) => x.get('type').value === EcoAreaType.PositiveSocialEmotionalSkills);
    ecoAreaFormGroup.get('description').setValue(ecoSummaries?.positiveSkillsSummary);
    ecoAreaFormGroup.get('score').setValue(ecoSummaries?.positiveSkillsScore);
    if (ecoSummaries?.positiveSkillsScore) {
      ecoAreaFormGroup
        .get('scoreText')
        .setValue(this.ecoRatingService.getSuggestedEcoRatingText(ecoSummaries.positiveSkillsScore, this.firstName));
    }
    //this.triggerSave();
    this.onSave();
    this.accordion.openAll();
    this.notificationService.success('Auto filled successfully');
  }

  async launchEcoDecisionTree(ecoAreaId: string): Promise<void> {
    const modalRef = this.dialog.open(EcoMatrixModalComponent, {
      width: '1200px',
      data: {
        type: EcoRatingType.Iep,
        iepId: this.iepId,
        amendmentId: this.amendment?.id,
        evaluationId: this.evaluationId,
        ecoAreaId,
        learnersFirstName: this.firstName,
        intakeType: IntakeType.PartB,
      },
    });

    const result = await modalRef.afterClosed().toPromise();
    if (!result) {
      return;
    }
    const ecoArea = this.ecoAreaFormGroups.find((x) => x.get('id').value === result.ecoAreaId);
    ecoArea.get('score').setValue(result.ecoRatingScore);
    ecoArea.get('scoreText').setValue(this.ecoRatingService.getSuggestedEcoRatingText(result.ecoRatingScore, this.firstName));
  }

  toggleEnvironment(event, control) {
    control.setValue(!control.value);
  }

  onOpenHelp(e, item: IepPlaafpHelp) {
    e.preventDefault();

    const dictionary = this.helpService.getIepPlaafpDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      item,
      section: HelpSection.IepPlaafp,
      canBrowse: true,
    } as HelpModalConfig);
  }
}
