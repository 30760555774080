import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DialogData } from '../are-you-sure-modal/are-you-sure.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-are-you-sure-memo-modal',
  templateUrl: './are-you-sure-memo-modal.component.html',
})
export class AreYouSureMemoModalComponent {
  question: string;
  subQuestion: string | SafeHtml;
  showCancel = false;
  hasHtml = false;
  formGroup = new FormGroup({
    cancellationRationale: new FormControl('', Validators.required),
  });

  get reason() {
    return this.formGroup.get('cancellationRationale').value;
  }

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private sanitizer: DomSanitizer) {
    this.question = data?.question ? data.question : 'Are you sure?';
    this.showCancel = data?.showCancel ? data.showCancel : false;
    this.hasHtml = data?.hasHtml ? data.hasHtml : false;
    this.subQuestion =
      data?.subQuestion && this.hasHtml ? sanitizer.bypassSecurityTrustHtml(data.subQuestion) : data?.subQuestion ? data.subQuestion : null;
  }

  yesImSure() {
    this.dialogRef.close({ answer: true, cancellationRationale: this.reason });
  }

  close() {
    this.dialogRef.close({ answer: false, cancellationRationale: this.reason });
  }

  cancel() {
    this.dialogRef.close(undefined);
  }
}
