<form [formGroup]="formGroup">
  <mat-card>
    <mat-card-title>
      Reason for {{ isThreeToFive ? 'Concern' : 'Referral' }}
      <span class="text-muted text-sm">(select all that apply)</span>
    </mat-card-title>
    <div class="row">
      <div class="col-lg">
        <mat-error *ngIf="formGroup.touched && formGroup.hasError('delayValidator')">
          At least one reason is <strong>required</strong>
        </mat-error>
      </div>
    </div>
    <div class="container container--not-centered container--form">
      <mat-label class="mt-1 mb-2 display-block" [ngClass]="{ asterisk_input: formGroup.hasError('delayValidator') }">
        Concerned about a developmental delay in the following area(s):
      </mat-label>
      <div class="row">
        <div class="col-lg-auto">
          <app-checkbox-single
            formControlName="referralReasonCognitive"
            aria-labelledby="Cognitive (Examples: exploring own hands/toys, figuring out simple problems)"
            label="Cognitive (Examples: exploring own hands/toys, figuring out simple problems)"
          >
          </app-checkbox-single>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-auto">
          <app-checkbox-single
            formControlName="referralReasonMotor"
            aria-labelledby="Motor (Examples: grabbing things with fingers and hands, reaching, moving, crawling, walking)"
            label="Motor (Examples: grabbing things with fingers and hands, reaching, moving, crawling, walking)"
          >
          </app-checkbox-single>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-auto">
          <app-checkbox-single
            formControlName="referralReasonCommunication"
            aria-labelledby="Communication (Examples: making sounds, babbling, talking, listening, responding to others, and expressing wants or needs)"
            label="Communication (Examples: making sounds, babbling, talking, listening, responding to others, and expressing wants or needs)"
          >
          </app-checkbox-single>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-auto">
          <app-checkbox-single
            formControlName="referralReasonAdaptive"
            aria-labelledby="Adaptive / Self-Help (Examples: feeding, dressing, bathing, toileting)"
            label="Adaptive / Self-Help (Examples: feeding, dressing, bathing, toileting)"
          >
          </app-checkbox-single>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-auto">
          <app-checkbox-single
            formControlName="referralReasonSocialEmotional"
            aria-labelledby="Social-Emotional (Examples: interacts with family and familiar adults, plays with others, follows simple rules)"
            label="Social-Emotional (Examples: interacts with family and familiar adults, plays with others, follows simple rules)"
          >
          </app-checkbox-single>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-auto">
          <app-checkbox-single formControlName="referralReasonOther" aria-labelledby="Other" label="Other"></app-checkbox-single>
        </div>
      </div>

      <div
        class="row"
        *ngIf="
          formGroup.get('referralReasonCognitive').value === true ||
          formGroup.get('referralReasonMotor').value === true ||
          formGroup.get('referralReasonCommunication').value === true ||
          formGroup.get('referralReasonAdaptive').value === true ||
          formGroup.get('referralReasonSocialEmotional').value === true ||
          formGroup.get('referralReasonOther').value === true
        "
      >
        <div class="col-lg-12">
          <app-textarea
            label="Description of Concern"
            formControlName="referralReasonDesc"
            [showAsterisk]="formGroup.hasError('descRequired')"
            attr.aria-required="{{ formGroup.hasError('descRequired') ? 'true' : 'false' }}"
          ></app-textarea>
          <mat-error *ngIf="formGroup.controls.referralReasonDesc.touched && formGroup.hasError('descRequired')" class="error-position-fix">
            <strong>Required</strong>
          </mat-error>
        </div>
      </div>

      <div
        class="row display-flex items-baseline"
        *ngIf="
          (formGroup.get('referralReasonCognitive').value === true ||
            formGroup.get('referralReasonMotor').value === true ||
            formGroup.get('referralReasonCommunication').value === true ||
            formGroup.get('referralReasonAdaptive').value === true ||
            formGroup.get('referralReasonSocialEmotional').value === true ||
            formGroup.get('referralReasonOther').value === true) &&
          !isThreeToFive
        "
      >
        <div class="col-md-12 col-lg-6 col-xl-4">
          <fieldset>
            <app-radio-group
              label="Was a screening tool used?"
              formControlName="referralReasonScreeningToolUsed"
              [options]="notSureYesNoOptions"
              [showAsterisk]="formGroup.hasError('toolRequired')"
            ></app-radio-group>
          </fieldset>
          <mat-error *ngIf="formGroup.controls.referralReasonScreeningToolUsed.touched && formGroup.hasError('toolRequired')">
            <strong>Required</strong>
          </mat-error>
        </div>
        <div class="col-lg-6">
          <app-autocomplete-chips
            *ngIf="formGroup.get('referralReasonScreeningToolUsed').value === unknownYesNo.Yes"
            [options]="screeningToolOptions"
            formControlName="screeningToolIds"
            label="Name of Tool?"
          ></app-autocomplete-chips>
          <app-text-field
            *ngIf="formGroup.controls.screeningToolIds.value.includes(screeningToolOtherId)"
            label="Enter tool name..."
            formControlName="screeningToolOther"
            attr.aria-required="{{ formGroup.hasError('nameRequired') ? 'true' : 'false' }}"
          ></app-text-field>
          <mat-error *ngIf="formGroup.controls.screeningToolIds.touched && formGroup.hasError('nameRequired')">
            <strong>Required</strong>
          </mat-error>
        </div>
      </div>

      <div *ngIf="!isThreeToFive">
        <hr class="input__divider" />

        <div class="row mb-0">
          <div class="col">
            <app-checkbox-single
              formControlName="referralReasonDiagnosedCondition"
              aria-labelledby="Diagnosed Condition, Including Vision and Hearing"
              label="Diagnosed Condition, Including Vision and Hearing:"
              [showAsterisk]="formGroup.hasError('delayValidator')"
            >
            </app-checkbox-single>
          </div>
        </div>
        <ng-container *ngIf="formGroup.get('referralReasonDiagnosedCondition').value === true">
          <app-help-directional-text>
            <p>Click the + to add, the pencil to edit, and save responses by clicking the save icon.</p>
          </app-help-directional-text>
          <div class="row">
            <div class="col-sm-12 table-position-fix">
              <app-referral-medical-diagnosis-table [formGroup]="formGroup"> </app-referral-medical-diagnosis-table>
            </div>
          </div>
        </ng-container>
        <div class="row mb-0">
          <div class="col-lg-12">
            <div class="row mb-0">
              <div class="col-lg-12">
                <app-checkbox-single
                  class="mb-3"
                  formControlName="referralReasonBornLessThan32Week"
                  aria-labelledby="Child born less than 32 week gestation"
                  label="Child born less than 32 week gestation:"
                  [showAsterisk]="formGroup.hasError('delayValidator')"
                >
                </app-checkbox-single>
                <div class="row" *ngIf="formGroup.get('referralReasonBornLessThan32Week').value === true">
                  <div class="col mt-2">
                    <mat-label
                      [ngClass]="{
                        asterisk_input: formGroup.hasError('ageRequired')
                      }"
                      >Gestational Age:</mat-label
                    >
                    <mat-error
                      *ngIf="
                        (formGroup.controls.referralReasonBornInWeeks.touched || formGroup.controls.referralReasonBornInDays.touched) &&
                        formGroup.hasError('ageInvalid')
                      "
                    >
                      Gestational age is <strong>greater than</strong> max allowed
                    </mat-error>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="formGroup.get('referralReasonBornLessThan32Week').value === true">
              <div class="col-md-4">
                <app-number-field
                  label="Weeks"
                  formControlName="referralReasonBornInWeeks"
                  id="referralReasonBornInWeeks"
                ></app-number-field>
              </div>
              <div class="col-md-4">
                <app-number-field label="Days" formControlName="referralReasonBornInDays" id="referralReasonBornInWeeks"></app-number-field>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="row mb-0">
              <div class="col-lg-12">
                <app-checkbox-single
                  class="mb-3"
                  formControlName="referralReasonBirthWeightLessThan3lb"
                  aria-labelledby="Child's birth weight less than 3lb 5oz (1500g)"
                  label="Child's birth weight less than 3lb 5oz (1500g):"
                  [showAsterisk]="formGroup.hasError('delayValidator')"
                >
                </app-checkbox-single>

                <div class="row" *ngIf="formGroup.get('referralReasonBirthWeightLessThan3lb').value === true">
                  <div class="col">
                    <mat-error
                      *ngIf="
                        (formGroup.controls.referralReasonBirthInLb.touched || formGroup.controls.referralReasonBirthInOz.touched) &&
                        formGroup.hasError('weightInvalid')
                      "
                    >
                      Birth weight is <strong>outside allowed range</strong>
                    </mat-error>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="formGroup.get('referralReasonBirthWeightLessThan3lb').value === true">
              <div class="col-md-4">
                <mat-form-field class="w-100 mr-1">
                  <mat-label>Units:</mat-label>
                  <mat-select (selectionChange)="weightTypeChanged()" formControlName="referralReasonBirthWeightUnits">
                    <mat-option value="lbOz" role="option">Pounds and Ounces</mat-option>
                    <mat-option value="g" role="option">grams</mat-option>
                    <mat-option value="kg" role="option">kilograms</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <ng-container [ngSwitch]="formGroup.get('referralReasonBirthWeightUnits').value">
                <ng-template [ngSwitchCase]="'lbOz'">
                  <div class="col-md-2">
                    <app-number-field label="lb" formControlName="referralReasonBirthInLb" id="referralReasonBirthInLb"></app-number-field>
                  </div>
                  <div class="col-md-2">
                    <app-number-field label="oz" formControlName="referralReasonBirthInOz" id="referralReasonBirthInOz"></app-number-field>
                  </div>
                </ng-template>

                <ng-template [ngSwitchCase]="'g'">
                  <div class="col-md-2">
                    <app-number-field
                      label="g"
                      formControlName="referralReasonBirthInGrams"
                      id="referralReasonBirthInGrams"
                    ></app-number-field>
                  </div>
                </ng-template>

                <ng-template [ngSwitchCase]="'kg'">
                  <div class="col-md-2">
                    <app-number-field
                      label="kg"
                      formControlName="referralReasonBirthInKilograms"
                      id="referralReasonBirthInKilograms"
                    ></app-number-field>
                  </div>
                </ng-template>

                <ng-template *ngSwitchDefault> </ng-template>
              </ng-container>
            </div>
          </div>
        </div>

        <hr class="input__divider" />

        <mat-label [ngClass]="{ asterisk_input: formGroup.hasError('delayValidator') }">
          Child referred for screening or evaluation due to Iowa Department of Human Services involvement?
        </mat-label>
        <div class="my-3">
          <mat-checkbox
            formControlName="referralReasonAbuseOrNeglect"
            attr.aria-label="Substantiated Case of Abuse or Neglect ({{ referralReasonHelp.Capta }})"
          >
            Substantiated Case of Abuse or Neglect (<a
              (click)="onOpenHelp($event, helpSection.Referral, referralReasonHelp.Capta)"
              class="text-underline"
              >{{ referralReasonHelp.Capta }}</a
            >)
          </mat-checkbox>
          <mat-checkbox
            formControlName="referralReasonPIDs"
            attr.aria-label="PIDs 'Presence of illegal drugs' ({{ referralReasonHelp.Capta }}) Referral"
          >
            PIDs "Presence of illegal drugs" (<a
              (click)="onOpenHelp($event, helpSection.Referral, referralReasonHelp.Capta)"
              class="text-underline"
              >{{ referralReasonHelp.Capta }}</a
            >) Referral
          </mat-checkbox>
          <app-checkbox-single
            formControlName="referralReasonNonCAPTA"
            aria-labelledby="Other, non-CAPTA"
            label="Other, non-CAPTA"
          ></app-checkbox-single>
        </div>
      </div>
    </div>
  </mat-card>
</form>
