import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user/user.service';
import { AuthService } from '../../auth.service';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-ivrs-login',
  templateUrl: './ivrs-login.component.html',
  styleUrls: ['./ivrs-login.component.scss'],
})
export class IvrsLoginComponent implements OnInit {
  isBusy = false;
  loginFailed = false;

  loginForm: FormGroup;
  options: string[];
  filteredLogins: Observable<string[]>;

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private breakpointObserver: BreakpointObserver
  ) {}

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', { validators: [Validators.required, Validators.email] }],
      password: ['', { validators: [Validators.required] }],
    });

    this.userService.getIvrsLoginUsers().subscribe((users) => {
      if (users && users.length) {
        this.options = users.map((u) => u.userName).sort();
        this.filteredLogins = this.email.valueChanges.pipe(
          startWith(''),
          map((value) => this.filter(value))
        );
        this.loginForm.get('password').setValue('fake-password');
      }
    });
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }

  onSubmit() {
    this.loginForm.markAllAsTouched();
    this.loginForm.updateValueAndValidity();

    if (this.loginForm.invalid) {
      return;
    }

    this.loginFailed = false;
    this.isBusy = true;
    this.authService.ivrsLogin(this.loginForm.value).subscribe(
      () => {
        if (this.authService.isAuthenticated) {
          if (this.authService.isVrUser) {
            this.authService.navigateToVr();
          } else {
            console.log('Not a vr user');
            this.loginFailed = true;
          }
        }
        this.isBusy = false;
      },
      (err: any) => {
        this.loginFailed = true;
        this.isBusy = false;
      }
    );
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' : this.email.hasError('email') ? 'Not a valid email' : '';
  }
}
