import { ServiceActivityType } from '../../shared/models/service-activity-type';
import { IepESYGoal, IepESYScheduleOfService } from '../esy/esy';
import { IepServiceCode } from './iep-service-code';

export interface IepServiceDto {
  id?: string;
  iepId?: string;
  serviceTypeId: string;
  serviceType?: ServiceActivityType;
  serviceCode?: IepServiceCode[];
  description?: string;
  frequencyNumber?: number;
  frequencyIncrement?: string;
  frequencyPeriod?: string;
  amountRemovedFromGeneralEducation?: number;
  projectedStartDate?: Date;
  projectedEndDate?: Date;
  customServiceDate?: string;
  transitionCodeId?: string;
  providers?: any;
  otherProvider?: boolean;
  otherProviderName?: string;
  otherProviderAgency?: string;
  otherProviderRole?: string;
  isComplete?: boolean;
  attendantServiceType?: string;
  directConsult?: string;
  transportationNeeded: boolean;
  transportationServices?: any;
  transportationServicesDescription: string;
  agencyId: string;
  paraprofessionalSupports?: any;
  instructionalSupportType?: string;
  hasBehaviorInterventionPlan?: boolean;
  hasHealthCarePlan?: boolean;
  amendmentId?: string;
  amendmentTaggedItemId?: string;
  priorVersionId?: string;
  amendmentReasonId?: string;
  isActive?: boolean;
  priorVersion?: IepServiceDto;
  iepESYGoalId?: string;
  iepESYGoal?: IepESYGoal;
  location: string;
  scheduleServices?: IepESYScheduleOfService[];
  includesTrialPlacement?: boolean;
  taggedForPwn?: boolean;
  taggedItemId?: string;
  amendmentEndDate?: Date;
}

export interface IepServiceAddUpdateDto {
  id?: string;
  iepId?: string;
  service: string;
  serviceCode: string[];
  description?: string;
  frequencyNumber?: number;
  frequencyIncrement?: string;
  frequencyPeriod?: string;
  agencyId: string;
  removedFromGeneralEducation?: boolean;
  projectedStartDate?: Date;
  projectedEndDate?: Date;
  providers?: string[];
  isComplete?: boolean;
  directOrConsult: string;
  transportationServices: string[];
  transportationServicesDescription: string;
  paraprofessionalSupports: string[];
  instructionalSupportType: string;
  hasBehaviorInterventionPlan?: boolean;
  hasHealthCarePlan?: boolean;
  isAmendment: boolean;
  includesTrialPlacement?: boolean;
}

export enum ServiceActivityTypeQuestion {
  RequireDeviceType = 'RequireDeviceType',
  RequireDeviceTypesDescription = 'RequireDeviceTypesDescription',
  DeviceRequireAEM = 'DeviceRequireAEM',
  IsDeviceNew = 'IsDeviceNew',
  DeviceYearOfPurchase = 'DeviceYearOfPurchase',
  DeviceEquipmentType = 'DeviceEquipmentType',
  DirectConsult = 'DirectConsult',
  TransportationServices = 'TransportationServices',
  TransportationServicesDescription = 'TransportationServicesDescription',
  ParaprofessionalSupports = 'ParaprofessionalSupports',
  InstructionalSupportType = 'InstructionalSupportType',
  HasBehaviorInterventionPlan = 'HasBehaviorInterventionPlan',
  HasHealthCarePlan = 'HasHealthCarePlan',
  AttendantServiceType = 'AttendantServiceType',
}
