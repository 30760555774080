import { KeyValuePair } from './key-value-pair';

export class AutocompleteGroup {
  constructor(label: string, options: KeyValuePair[]) {
    this.label = label;
    this.options = options;
  }

  label: string;
  options: KeyValuePair[];
}
