import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { CaseService } from '../../shared/services/case/case.service';
import { CaseSummary } from '../../shared/models/case';
import { EvaluationDomain } from '../../shared/models/evaluation-domain';
import { EvaluationDomainService } from '../../shared/services/evaluation-domains/evaluation-domain.service';
import { ProceduralSafeguardsRead } from 'src/app/shared/components/procedural-safeguards/procedural-safeguards';
import { ProceduralSafeguardsService } from 'src/app/shared/components/procedural-safeguards/procedural-safeguards.service';

@Injectable({
  providedIn: 'root',
})
export class FiieConsentFormResolver implements Resolve<Observable<[CaseSummary, EvaluationDomain[], ProceduralSafeguardsRead[]]>> {
  constructor(
    private caseService: CaseService,
    private readonly evaluationDomainService: EvaluationDomainService,
    private proceduralSafeguardService: ProceduralSafeguardsService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<[CaseSummary, EvaluationDomain[], ProceduralSafeguardsRead[]]> {
    return combineLatest([
      this.caseService.getCaseSummary(route.paramMap.get('caseId')),
      this.evaluationDomainService.get(),
      this.proceduralSafeguardService.get(route.paramMap.get('caseId')),
    ]);
  }
}
