<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Add Routine</h3>
<div mat-dialog-content class="py-3" [formGroup]="editFormGroup">
  <mat-card class="my-0">
    <app-autocomplete formControlName="routine" [options]="routines" class="w-25 mr-3" label="Routine" [required]="true">
    </app-autocomplete>
    <app-text-field
      class="mr-3 mb-1"
      formControlName="other"
      label="Other Routine"
      [required]="true"
      *ngIf="editFormGroup.controls.routine.value === otherKey"
    >
    </app-text-field>
    <app-text-field label="Short Name" formControlName="nickName"></app-text-field>
  </mat-card>
</div>
<div mat-dialog-actions class="actions">
  <button mat-raised-button aria-label="Cancel Button" class="mr-2" (click)="cancel()" tabindex="0">Cancel</button>
  <button cdkFocusInitial (click)="saveRoutine()" class="ml-0" mat-raised-button aria-label="Complete" color="primary" tabindex="0">
    Complete
  </button>
</div>
