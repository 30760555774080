import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BooleanYesNoPipe } from 'src/app/shared/pipes/boolean-yesno.pipe';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { openPdfWindow } from 'src/app/shared/windowHelpers';
import { IepSectionDetails } from '../../models/iep-section-details';

@Component({
  selector: 'app-iep-print-modal',
  templateUrl: './iep-print-modal.component.html',
  styleUrls: ['./iep-print-modal.component.scss'],
  providers: [BooleanYesNoPipe],
})
export class IepPrintModalComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = ['actions', 'type'];
  activeCall = false;
  printingDoc = false;
  constructor(
    private dialogRef: MatDialogRef<IepPrintModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reportingService: ReportingService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([
      'PLAAFP',
      'Goals',
      'Services',
      'Activities and Supports',
      'Accommodations',
      'Extended School Year (ESY) Decision',
      'Physical Education',
      'Assessment',
      'Least Restrictive Environment (LRE)',
      'Additional Information',
    ]);
    this.dataSource.sortingDataAccessor = (item, property) => {
      return item[property];
    };
    this.selection.select(...this.dataSource.data);
  }

  onPrint() {
    this.printingDoc = true;
    this.reportingService
      .createIepOutput(
        this.data.iepId,
        {
          HasIepSectionDetails: true,
          ShowPlaafp: !!this.selection.selected.find((x) => x === 'PLAAFP'),
          ShowServices: !!this.selection.selected.find((x) => x === 'Services'),
          ShowGoals: !!this.selection.selected.find((x) => x === 'Goals'),
          ShowActivitiesSupports: !!this.selection.selected.find((x) => x === 'Activities and Supports'),
          ShowAccommodations: !!this.selection.selected.find((x) => x === 'Accommodations'),
          ShowESY: !!this.selection.selected.find((x) => x === 'Extended School Year (ESY) Decision'),
          ShowPE: !!this.selection.selected.find((x) => x === 'Physical Education'),
          ShowAssessment: !!this.selection.selected.find((x) => x === 'Assessment'),
          ShowLRE: !!this.selection.selected.find((x) => x === 'Least Restrictive Environment (LRE)'),
          ShowAdditionalInfo: !!this.selection.selected.find((x) => x === 'Additional Information'),
        },
        false
      )
      .subscribe({
        next: (documentId: string) => this.handleCreateInlineOutput(documentId),
        error: () => this.handleError(),
      });
  }

  private handleError() {
    this.printingDoc = false;
    this.notificationService.error("Couldn't open output");
  }

  private handleCreateInlineOutput(documentId: string) {
    this.printingDoc = false;
    this.dialogRef.close();
    openPdfWindow(this.data.learnerId, documentId);
  }

  onClose() {
    this.dialogRef.close();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }
}
