import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { IDocument } from '../document-upload-list/document-upload-list.models';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent implements OnInit {
  @Input() buttonText = 'Upload';
  @Input() documents: IDocument[];
  @Input() allowedFileTypes = 'TXT, CSV, PDF, DOC, DOCX, ODT, PPTX, PPT, RTF, XLS, XLSX, PNG, JPEG, JPG, GIF, BMP';
  @Input() documentationTypes: KeyValuePair[];
  @Input() requestFiles: boolean;
  @Input() singleFile: boolean;
  @Input() disableUpload: boolean;
  @Input() disableDelete: boolean;

  @Output() uploaded = new EventEmitter<FormData>();
  @Output() deleted = new EventEmitter<string>();
  @Output() fileRequested = new EventEmitter<IDocument>();

  constructor() {}

  ngOnInit(): void {}

  onUploaded(formData: FormData): void {
    this.uploaded.emit(formData);
  }

  onDeleted(id: string): void {
    this.deleted.emit(id);
  }
}
