import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, from, of } from 'rxjs';
import { catchError, concatMap, switchMap, take } from 'rxjs/operators';
import { ConfirmationDialogComponent } from './notification.service';

export enum DeactivationStatus {
  Denied,
  NeedsConfirmation,
  Accepted,
}

export interface CanComponentDeactivate {
  canDeactivate(): Observable<DeactivationStatus> | Promise<DeactivationStatus> | DeactivationStatus;
}

@Injectable({ providedIn: 'root' })
export class DeactivationService {
  private components = new Array<CanComponentDeactivate>();

  constructor(private dialog: MatDialog) {}

  public subscribe(component: CanComponentDeactivate): void {
    this.components.push(component);
  }

  public unsubscribe(component: CanComponentDeactivate): void {
    const componentIndex = this.components.indexOf(component);
    if (componentIndex < 0) {
      return;
    }
    this.components.splice(componentIndex, 1);
  }

  public isSubscribed(component: CanComponentDeactivate): boolean {
    return this.components.indexOf(component) >= 0;
  }

  public canDeactivate(): Observable<boolean> {
    if (this.components.length === 0) {
      return of(true);
    }

    let currentObservable = this.getObservable(this.components[this.components.length - 1]);
    for (let i = this.components.length - 2; i >= 0; i--) {
      const child = currentObservable;
      const parent = this.getObservable(this.components[i]).pipe(concatMap((x) => (x ? child : of(x))));
      currentObservable = parent;
    }

    return currentObservable.pipe(
      catchError((err) => {
        // TODO: Show the app error dialog
        console.error(err);
        return of(false);
      })
    );
  }

  public getObservable(component: CanComponentDeactivate): Observable<boolean> {
    if (!component?.canDeactivate) {
      return of(true);
    }

    const canDeactivate = component?.canDeactivate();

    let canDeactivate$: Observable<DeactivationStatus>;
    if (canDeactivate instanceof Promise) {
      canDeactivate$ = from(canDeactivate);
    } else if (canDeactivate instanceof Observable) {
      canDeactivate$ = canDeactivate.pipe(take(1));
    }

    if (canDeactivate$) {
      return canDeactivate$.pipe(switchMap((cd) => this.checkCanDeactivate(cd)));
    } else if (canDeactivate as DeactivationStatus) {
      return this.checkCanDeactivate(canDeactivate as DeactivationStatus);
    }

    return of(true);
  }

  private checkCanDeactivate(status: DeactivationStatus): Observable<boolean> {
    switch (status) {
      case DeactivationStatus.Denied:
        return of(false);
      case DeactivationStatus.NeedsConfirmation:
        return this.showConfirmationDialog();
      case DeactivationStatus.Accepted:
        return of(true);
    }
  }

  private showConfirmationDialog(): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '728px',
      data: {
        title: 'Unsaved data',
        message:
          'You have entered information that is not saved. Leaving the page will result in a loss of data. Are you sure you want to leave this page?',
        buttons: { cancel: 'No', ok: 'Yes' },
      },
    });

    return dialogRef.afterClosed();
  }
}
