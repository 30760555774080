<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>PK to K Transition</h3>
<div mat-dialog-content class="py-3">
  <mat-card class="my-0">
    <div>
      <p>Please enter the estimated start date for kindergarten below</p>
      <form [formGroup]="kindergartenFormGroup">
        <app-date-picker controlName="startDate" label="Kindergarten Start Date"></app-date-picker>
      </form>
      <app-help-directional-text>
        <p>Subsequent changes to the kindergarten start date for this learner can be made from the Learner Management page.</p>
      </app-help-directional-text>
    </div>
  </mat-card>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button aria-label="No" class="background-color--muted mr-1" (click)="no()" tabindex="0">No</button>
  <button mat-raised-button aria-label="Yes" color="primary" (click)="yesImSure()" [disabled]="kindergartenFormGroup.invalid" tabindex="0">
    Yes
  </button>
</div>
