import { HttpParams } from '@angular/common/http';

export class PaginationRequest {
  pageSize = 10;
  pageNumber = 1;
}

export function addPagination(pagination?: PaginationRequest) {
  let params = new HttpParams();

  if (!pagination) return params;

  Object.keys(pagination).forEach((key) => (params = params.append(key, pagination[key])));
  return params;
}
