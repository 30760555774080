import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FileDocument } from '../../../shared/models/file-document';
import { OperationResult, OperationResultWithValue } from '../../../shared/models/operation-result';
import { NotificationService } from '../../../shared/services/notification.service';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import {
  ServiceLogDto,
  ServiceLogFamilyGuidedInterventionPlanDto,
  ServiceLogFlatDto,
  ServiceLogLookupDto,
  ServiceLogSearchDto,
  ServiceLogServiceDetailDto,
  ServiceLogSessionDto,
} from '../models/service-log';

@Injectable({
  providedIn: 'root',
})
export class ServiceLogService {
  private serviceLogUpdated = new Subject<void>();
  serviceLogUpdated$ = this.serviceLogUpdated.asObservable();

  private serviceLogServiceDetailUpdated = new Subject<void>();
  serviceLogServiceDetailUpdated$ = this.serviceLogServiceDetailUpdated.asObservable();

  constructor(private readonly http: HttpClient, private spinnerService: SpinnerService, private notificationService: NotificationService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/serviceLog\/.*serviceLog.*/));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/serviceLog\/.*serviceDetail.*/));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/serviceLog\/.*familyGuidedInterventionPlan.*/));
  }

  getServiceLogs(serviceLogSearch: ServiceLogSearchDto) {
    return this.http.post<OperationResultWithValue<ServiceLogFlatDto[]>>('api/serviceLog/serviceLogs', serviceLogSearch);
  }

  getServiceLogSessions(caseId: string) {
    return this.http.get<OperationResultWithValue<ServiceLogSessionDto[]>>(`api/serviceLog/${caseId}/serviceLogSessions`);
  }

  getServiceLogLookups(caseId: string) {
    return this.http.get<OperationResultWithValue<ServiceLogLookupDto>>(`api/serviceLog/${caseId}/serviceLoglookups`);
  }

  getServiceLog(caseId: string, serviceLogId: string) {
    return this.http.get<OperationResultWithValue<ServiceLogDto>>(`api/serviceLog/${caseId}/${serviceLogId}`);
  }

  getLatestFamilyGuidedInterventionPlan(caseId: string) {
    return this.http.get<OperationResultWithValue<ServiceLogFamilyGuidedInterventionPlanDto>>(
      `api/serviceLog/${caseId}/latestFamilyGuidedInterventionPlan`
    );
  }

  startNewServiceLog(caseId: string) {
    return this.http
      .post<OperationResultWithValue<ServiceLogDto>>(`api/serviceLog/${caseId}/newServiceLog`, null)
      .pipe(tap(() => this.serviceLogUpdated.next()));
  }

  saveServiceLog(caseId: string, model: ServiceLogDto) {
    return this.http
      .post<OperationResultWithValue<ServiceLogDto>>(`api/serviceLog/${caseId}/serviceLog`, model)
      .pipe(tap(() => this.serviceLogUpdated.next()));
  }

  deleteServiceLog(serviceLogId: string) {
    return this.http.put<OperationResult>(`api/serviceLog/${serviceLogId}/serviceLog`, null).pipe(tap(() => this.serviceLogUpdated.next()));
  }

  saveServiceDetail(serviceLogId: string, model: ServiceLogServiceDetailDto) {
    return this.http
      .post<OperationResultWithValue<ServiceLogServiceDetailDto>>(`api/serviceLog/${serviceLogId}/serviceDetail`, model)
      .pipe(tap(() => this.serviceLogUpdated.next()));
  }

  deleteServiceDetail(serviceDetailId: string) {
    return this.http
      .put<OperationResult>(`api/serviceLog/${serviceDetailId}/serviceDetail`, null)
      .pipe(tap(() => this.serviceLogUpdated.next()));
  }

  saveFamilyGuidedInterventionPlan(serviceLogId: string, model: ServiceLogFamilyGuidedInterventionPlanDto) {
    return this.http
      .post<OperationResultWithValue<ServiceLogFamilyGuidedInterventionPlanDto>>(
        `api/serviceLog/${serviceLogId}/familyGuidedInterventionPlan`,
        model
      )
      .pipe(tap(() => this.serviceLogUpdated.next()));
  }

  uploadInterventionPlanDocument(uploadFile: any, familyGuidedInterventionPlanId: string, learnerId: string) {
    return this.http.post<OperationResultWithValue<FileDocument[]>>(
      `api/serviceLog/${learnerId}/${familyGuidedInterventionPlanId}/documents`,
      uploadFile,
      this.getMultipartRequestHeaders()
    );
  }

  deleteInterventionPlanDocument(familyGuidedInterventionPlanId: string, documentId: string) {
    return this.http.put<OperationResult>(`api/serviceLog/${familyGuidedInterventionPlanId}/documents/${documentId}`, null);
  }

  protected getMultipartRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      Accept: 'application/json, text/plain, */*',
    });

    return { headers };
  }

  handleError(errorTitle: string, error: any): void {
    let errorMessage = '';
    if (error && error.error && error.error.errors) {
      errorMessage = error.error.errors.map((e) => e.description)?.join(',');
    } else if (error && error.errors) {
      errorMessage = error.errors.map((e) => e.description)?.join(',');
    } else if (error && error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = JSON.stringify(error);
    }

    this.notificationService.errorWithAction(errorTitle, 'Why?', () => {
      this.notificationService.alert(errorMessage, errorTitle);
    });
  }
}
