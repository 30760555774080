<h3 class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Graph</h3>

<div mat-dialog-content class="py-2">
  <ng-container *ngIf="!data.isObjective">
    <app-chart-viewer [params]="{ IepGoalId: data.iepGoalId }" [graph]="'iep_graph'"></app-chart-viewer>
  </ng-container>
  <ng-container *ngIf="data.isObjective">
    <app-chart-viewer
      [params]="{
        IepGoalQuantifiableMeasurementId: data.iepGoalQuantifiableMeasurementId,
        IepGoalId: data.iepGoalId
      }"
      [graph]="'iep_objective'"
    ></app-chart-viewer>
  </ng-container>
</div>

<mat-dialog-actions align="end">
  <button type="button" mat-button aria-label="Delete Icon" [mat-dialog-close]="true" tabindex="0">Close</button>
</mat-dialog-actions>
