import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EvalDetailsPartBTagDomainsService {
  private baseUrl = '/api/evaluations/';
  constructor(private http: HttpClient) {}

  get(evalId: string) {
    return this.http.get<{ [domainLabel: string]: boolean }>(`${this.baseUrl}${evalId}/tagged-domains`);
  }
}
