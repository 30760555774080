import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { ConfirmationDialogComponent, NotificationService } from 'src/app/shared/services/notification.service';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { BaseComponent } from '../../../../../shared/components/base-component/base-component';
import { DeactivationService } from '../../../../../shared/services/deactivation.service';
import { CourseActivity, CourseStatus } from '../../models/course-activity';
import { CourseActivityService } from '../../services/course-activity.service';

@Component({
  selector: 'app-course-activities',
  templateUrl: './course-activities.component.html',
  styleUrls: ['./course-activities.component.scss'],
  providers: [DatePipe],
})
export class CourseActivitiesComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() learnerId: string;
  @Input() learnerFirstName: string;
  @Input() courseListReviewed: boolean;
  @Input() readonly: boolean;
  @ViewChild(MatSort) sort: MatSort;
  @Output() coursesModified = new EventEmitter();
  isEditing = false;
  courseStatus = CourseStatus;
  shortDateFormat = shortDateFormat;
  disableModifications = false;
  activeCall = false;

  get livingLearningWorkingChecked() {
    if (
      this.formGroup.get('living').value === false &&
      this.formGroup.get('learning').value === false &&
      this.formGroup.get('working').value === false
    ) {
      return false;
    } else {
      return true;
    }
  }

  dataSource: MatTableDataSource<CourseActivity>;
  displayedColumns = ['actions', 'name', 'projectedDate', 'llw', 'completed'];

  formGroup = this.fb.group({
    id: '',
    name: ['', Validators.required],
    projectedDate: ['', Validators.required],
    living: false,
    learning: false,
    working: false,
    status: '',
    statusDescription: '',
    statusDate: null,
  });

  constructor(
    private fb: FormBuilder,
    private courseActivityService: CourseActivityService,
    private dialog: MatDialog,
    deactivationService: DeactivationService,
    private notificationService: NotificationService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.courseListReviewed?.currentValue) {
      this.disableModifications = true;
    } else {
      this.disableModifications = false;
    }

    if ('readonly' in changes) {
      if (!changes.readonly.currentValue && this.displayedColumns.indexOf('completed') === -1) {
        this.displayedColumns = [...this.displayedColumns, 'completed'];
      } else if (changes.readonly.currentValue && this.displayedColumns.indexOf('completed') !== -1) {
        this.displayedColumns.splice(this.displayedColumns.indexOf('completed'), 1);
        this.displayedColumns = [...this.displayedColumns];
      }
    }
  }

  private load() {
    this.courseActivityService.get(this.learnerId).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res);
      this.configureSorting();
    });
  }

  private configureSorting() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'completed': {
          return item.statusDate;
        }
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  onAddNewActivity() {
    this.isEditing = true;
  }

  onSubmitActivity(close: boolean) {
    this.activeCall = true;
    if (this.formGroup.invalid) {
      return;
    }
    const id = this.formGroup.get('id').value;
    let observable: Observable<any>;
    if (id) {
      observable = this.courseActivityService.update(this.learnerId, this.formGroup.value);
    } else {
      observable = this.courseActivityService.create(this.learnerId, this.formGroup.value);
    }

    observable.subscribe((res) => {
      this.isEditing = close ? false : true;
      this.load();
      this.formGroup.reset();
      this.courseListModified();
      this.activeCall = false;
    });
  }

  onViewMore(element) {
    const llw = [];
    if (element.living) {
      llw.push('Living');
    }
    if (element.learning) {
      llw.push('Learning');
    }
    if (element.working) {
      llw.push('Working');
    }

    const modalData: ViewMoreModalData[] = [
      {
        name: 'Course / Activity',
        value: element.name,
      },
      {
        name: 'Projected Date / Year of School',
        value: element.projectedDate,
      },
    ];

    if (llw.length > 0) {
      modalData.push({
        name: 'Living, Learning, Working',
        value: llw.join(', '),
      });
    }

    openViewMore(this.dialog, modalData);
  }

  onEdit(element, editing) {
    this.isEditing = editing;
    element.isEditing = editing;
    if (!editing) {
      this.formGroup.reset();
      return;
    }
    this.formGroup.patchValue(element);
  }

  async onDelete(element: CourseActivity) {
    const confirm = await this.notificationService.confirmationPromise(
      'Are you sure you want to delete this course/activity?',
      'Delete Course or Activity'
    );
    if (confirm) {
      this.activeCall = true;

      this.courseActivityService.delete(this.learnerId, element).subscribe(
        (res) => {
          if (res?.succeeded) {
            this.dataSource.data = this.dataSource.data.filter((x) => x.id !== element.id);
            this.courseListModified();
          } else {
            this.notificationService.error(res?.errors?.length ? res.errors[0].description : 'Something went wrong!');
          }
          this.activeCall = false;
        },
        () => {
          this.notificationService.error('Something went wrong!');
          this.activeCall = false;
        }
      );
    }
  }

  onCancel() {
    this.isEditing = false;
    this.formGroup.reset();
  }

  onCompleteDiscontinue(element: CourseActivity, status: boolean) {
    this.formGroup.patchValue(element);
    const statusControl = this.formGroup.get('status');
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: status ? 'Complete Course' : 'Discontinue Course',
        message: `Are you sure you want to ${status ? 'complete' : 'discontinue'} this course and activity?`,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if (status) {
          statusControl.setValue(CourseStatus.Completed);
        } else {
          statusControl.setValue(CourseStatus.Discontinued);
        }
        this.formGroup.get('statusDate').setValue(dayjs().toDate());
        this.onSubmitActivity(true);
      }
    });
  }

  toggleEnvironment(event) {
    const control = this.formGroup.get(event.value);
    switch (event.value) {
      case 'living':
        control.setValue(!control.value);
        break;
      case 'learning':
        control.setValue(!control.value);
        break;
      case 'working':
        control.setValue(!control.value);
        break;
    }
  }

  courseListModified() {
    this.coursesModified.emit(true);
  }
}
