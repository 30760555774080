import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { KeyValuePair } from '../../../models/key-value-pair';
import { LoggerService } from '../../../services/logger/logger.service';
import { BaseFieldComponent } from '../base-field';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class CheckboxComponent extends BaseFieldComponent implements OnInit, OnChanges, OnDestroy {
  private valueChangeSource: 'selection' | 'patch' | null;
  optionsBacking: KeyValuePair[];
  hasInitialized = false;
  alive = true;
  value: any;
  internalFormGroup: FormGroup;
  groupArray: FormControl[] = [];
  selectAll = false;
  changeSubscription: Subscription;
  checkAllControl = new FormControl();

  @Input() wrap = false;

  @Input() column = false;

  @Input() showSelectAll = false;

  @Input() showAsterisk = false;
  @Input() hideAsterisk = false;

  @Input()
  set options(value: KeyValuePair[]) {
    this.optionsBacking = value;
  }

  @Input() public noneOptionKey: any;

  getLabel(key: number) {
    return this.optionsBacking[key]?.value;
  }

  checkAll(event: MatCheckboxChange) {
    this.setAll(event.checked, true, this.checkAllControl);
  }

  constructor(parentForm: FormGroupDirective, logger: LoggerService, private readonly formBuilder: FormBuilder) {
    super(parentForm, logger);
  }

  ngOnInit() {
    if (!this.hasInitialized) {
      this.init();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.hasInitialized) {
      this.init();
      this.hasInitialized = true;
    }
    super.ngOnChanges(changes);
    if (changes.options?.currentValue && this.control) {
      const value = this.control.value || [];
      const group = {};

      this.optionsBacking.forEach((element) => {
        const control = new FormControl(value.includes(element.key), {
          updateOn: 'change',
        });
        group[element.key] = control;
        this.groupArray.push(control);
      });
      this.internalFormGroup = this.formBuilder.group(group);

      if (this.changeSubscription) {
        this.changeSubscription.unsubscribe();
      }
      this.changeSubscription = this.internalFormGroup.valueChanges.pipe(takeWhile(() => this.alive)).subscribe((res) => {
        if (this.valueChangeSource === 'patch') {
          return;
        }
        this.valueChangeSource = 'selection';
        this.control.patchValue(Object.keys(res).filter((x) => res[x]));
        this.optionsBacking.forEach((v, i) => this.setControlAvailability(this.groupArray[i], v.enabled, false));

        if (this.noneOptionKey) {
          this.setNoneOption();
        }

        this.control.markAsDirty();
        this.control.markAsTouched();
        this.valueChangeSource = null;
      });
    }

    this.checkDisabled(this.disabled || this.readOnly);
  }

  private init() {
    super.ngOnInit();
    this.hasInitialized = true;
    this.control.valueChanges.pipe(takeWhile(() => this.alive)).subscribe((newValues) => {
      /*
      There's probably a better way to do this but it works/
      The sub here is to listen for a "patchValue" or "setValue" trigger.
      When this happens we want to update the internal formGroup with the new values.
      This results in a endless loop however, because we're also registering a value change
      with the internal formGroup as it needs to set the bound control's value.
      "valueChangeSource" prevents this by allow both types of changes to occur.
      It might be possible to convert the internal formGroup to some other kind of control, but nothing
      I tried worked correctly.
       */
      // If Select all is checked and an option goes unchecked, it deselects Select all
      if (this.showSelectAll) {
        this.selectAll = !this.groupArray.some((x) => x.value === false);
      }
      if (!this.internalFormGroup || this.valueChangeSource === 'selection') {
        return;
      }
      this.valueChangeSource = 'patch';
      this.groupArray.forEach((x) => {
        x.setValue(false);
      });
      if (newValues) {
        const patch = {};
        newValues.forEach((y) => {
          patch[y] = true;
        });
        this.internalFormGroup.patchValue(patch);
        if (this.noneOptionKey) {
          this.setNoneOption();
        }
      }
      this.valueChangeSource = null;
    });
    this.checkDisabled(this.disabled || this.readOnly);
  }

  checkDisabled(disable: boolean) {
    super.checkDisabled(disable);
    this.setControlAvailability(this.checkAllControl, !disable);
    for (const control of this.groupArray) {
      this.setControlAvailability(control, !disable, disable ? false : true);
    }
  }

  ngOnDestroy() {
    this.alive = false;
  }

  private setAll(value: boolean, emitEvent = true, ...controlsToOmit: Array<FormControl>): void {
    if (controlsToOmit.indexOf(this.checkAllControl) === -1 && this.checkAllControl.value !== value) {
      this.checkAllControl.setValue(value, { emitEvent });
    }

    for (const control of this.groupArray.filter((x) => controlsToOmit.indexOf(x) === -1 && x.value !== value)) {
      control.setValue(value, { emitEvent });
    }
  }

  private setNoneOption(): void {
    const noneOption = this.internalFormGroup?.controls[this.noneOptionKey] as FormControl;
    if (!noneOption) {
      return;
    }

    const otherOptions = this.groupArray.filter((x) => x !== noneOption);
    const optionSelected = !noneOption.value && otherOptions.find((x) => x.value);

    this.setControlAvailability(this.checkAllControl, !noneOption.value, false);
    for (const control of otherOptions) {
      this.setControlAvailability(control, !noneOption.value, false);
    }
    this.setControlAvailability(noneOption, !optionSelected, false);
  }

  private setControlAvailability(control: FormControl, available: boolean, emitEvent = true): void {
    if (available) {
      control.enable({ emitEvent });
    } else {
      control.disable({ emitEvent });
    }
  }
}
