import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'punctuation',
})
export class PunctuationPipe implements PipeTransform {
  transform(val: any, defaultPunctuation = '.') {
    if (!val) {
      return val;
    }
    val = val.trim();
    const endChar = val.charAt(val.length - 1);
    if (endChar !== '.' && endChar !== '!' && endChar !== '?') {
      val = `${val}${defaultPunctuation}`;
    }
    return val;
  }
}
