import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, Self, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { LoggerService } from 'src/app/shared/services/logger/logger.service';
import { BaseWrapperComponent } from '../base-wrapper.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends BaseWrapperComponent implements OnInit, OnChanges {
  @Input() addEmpty = false;
  @Input() emptyNullable = false;
  @Input() multiple = false;
  @Output() changeEvent = new EventEmitter<any>();
  optionsBacking: KeyValuePair[];
  @Input()
  set options(value: KeyValuePair[]) {
    this.optionsBacking = value;
  }
  formattedValue = '';
  dataSourceLabel: any;

  constructor(
    logger: LoggerService,
    @Self()
    @Optional()
    ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(logger, ngControl, changeDetectorRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.formatValue();
    if (this.useOverwrite) {
      this.setDataSourceLabel();
    }
    this.control.valueChanges.subscribe(() => {
      this.formatValue();
    });
  }

  onChanged(event: MatSelectChange) {
    if (this.changeEvent) {
      this.changeEvent.emit(event);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  formatValue(): void {
    const controlValue = this.control.value;
    if (!controlValue) {
      return;
    }
    const optionValue = this.optionsBacking[controlValue];
    this.formattedValue = optionValue ? optionValue.value : '';
  }

  private setDataSourceLabel() {
    if (this.dataSourceValue && this.optionsBacking) {
      const match = this.optionsBacking.find((d) => d.key === this.dataSourceValue);

      this.dataSourceLabel = match?.value;
    }
  }
}
