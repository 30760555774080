import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import dayjs from 'dayjs';

@Component({
  selector: 'app-quick-date',
  templateUrl: './quick-date.component.html',
  styleUrls: ['./quick-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: QuickDateComponent,
      multi: true,
    },
  ],
})
export class QuickDateComponent implements OnInit, ControlValueAccessor {
  quickDateForm: FormGroup;

  @Input() showError = false;
  @Input() label: string;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.quickDateForm = this.formBuilder.group(
      {
        dateShortcut: 'AllTime',
        startDate: dayjs().subtract(100, 'year').startOf('day').toDate(),
        endDate: dayjs().add(2, 'day').startOf('day').toDate(),
      },
      { updateOn: 'blur' }
    );
  }

  setQuickDate(quickDateValue) {
    if (quickDateValue === 'Today') {
      const startDate = dayjs().startOf('day');
      const endDate = dayjs().endOf('day');
      this.writeValue({
        dateShortcut: 'Today',
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
      });
    }
    if (quickDateValue === 'Yesterday') {
      const startDate = dayjs().subtract(1, 'day').startOf('day');
      const endDate = startDate.endOf('day');
      this.writeValue({
        dateShortcut: 'Yesterday',
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
      });
    }
    if (quickDateValue === 'LastWeek') {
      const lastW = dayjs().subtract(1, 'week').startOf('week').add(1, 'day');
      const lastWEnd = dayjs().subtract(1, 'week').endOf('week').add(1, 'day').endOf('day');
      this.writeValue({
        dateShortcut: 'LastWeek',
        startDate: lastW.toDate(),
        endDate: lastWEnd.toDate(),
      });
    }
    if (quickDateValue === 'LastMonth') {
      const lastM = dayjs().subtract(1, 'month').startOf('month');
      const lastMEnd = dayjs().subtract(1, 'month').endOf('month').endOf('day');
      this.writeValue({
        dateShortcut: 'LastMonth',
        startDate: lastM.toDate(),
        endDate: lastMEnd.toDate(),
      });
    }
    if (quickDateValue === 'Last3Months') {
      const threeMonthsAgo = dayjs().subtract(3, 'month');
      const lastMonth = dayjs().subtract(1, 'month');
      this.writeValue({
        dateShortcut: 'Last3Months',
        startDate: threeMonthsAgo.startOf('month').toDate(),
        endDate: lastMonth.endOf('month').endOf('day').toDate(),
      });
    }
    if (quickDateValue === 'Last90Days') {
      const lastM = dayjs().subtract(89, 'day');
      const now = dayjs();
      this.writeValue({
        dateShortcut: 'Last90Days',
        startDate: lastM.startOf('day').toDate(),
        endDate: now.endOf('day').toDate(),
      });
    }
    if (quickDateValue === 'Last30Days') {
      const lastM = dayjs().subtract(29, 'day');
      const now = dayjs();
      this.writeValue({
        dateShortcut: 'Last30Days',
        startDate: lastM.startOf('day').toDate(),
        endDate: now.endOf('day').toDate(),
      });
    }
    if (quickDateValue === 'Last7Days') {
      const lastW = dayjs().subtract(6, 'day');
      const now = dayjs();
      this.writeValue({
        dateShortcut: 'Last7Days',
        startDate: lastW.startOf('day').toDate(),
        endDate: now.endOf('day').toDate(),
      });
    }
    if (quickDateValue === 'AllTime') {
      const startDate = dayjs().subtract(100, 'year');
      const endDate = dayjs().add(2, 'day');
      this.writeValue({
        dateShortcut: 'AllTime',
        startDate: startDate.startOf('day').toDate(),
        endDate: endDate.endOf('day').toDate(),
      });
    }
    if (quickDateValue === 'CustomDate') {
      this.writeValue({
        dateShortcut: 'CustomDate',
        startDate: this.quickDateForm.get('startDate').value,
        endDate: this.quickDateForm.get('endDate').value,
      });
    }
  }

  writeValue(value: any) {
    if (value) {
      this.quickDateForm.setValue(value);
    }
  }

  registerOnChange(fn: (value: any) => void) {
    this.quickDateForm.valueChanges.subscribe(fn);
  }

  registerOnTouched() {}
}
