<form [formGroup]="formGroup" (ngSubmit)="onUpdateModel()" class="mt-3" *ngIf="showForm">
  <app-number-field #ratingField formControlName="rating" label="Rating Level" [min]="0" id="rating"></app-number-field>
  <div class="row align-items-center">
    <div class="col-md-12">
      <app-text-field
        class="w-100"
        [isBankableField]="true"
        [bankableFieldId]="bankableFieldIds.outcomes.quantData.scaleLevelDescription"
        label="Description"
        formControlName="description"
      ></app-text-field>
    </div>

    <div class="col-md-12 display-flex justify-content-end mb-2">
      <button mat-raised-button aria-label="Cancel Button" type="button" class="mr-2" (click)="onCancel()" tabindex="0">Cancel</button>
      <button mat-raised-button attr.aria-label="{{ this.model ? 'Update' : 'Add' }}" color="primary" type="submit" tabindex="0">
        {{ this.model ? 'Update' : 'Add' }}
      </button>
    </div>
  </div>
</form>

<ng-container *ngIf="scaleRatings && dataSource.data.length > 0">
  <!-- <hr class="input__divider mb-0" *ngIf="showForm" /> -->
  <h4 class="text-primary mb-0 mt-2">Scale</h4>
  <div class="table-overflow mb-5">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0 w-100">
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="text-right"></th>
        <td mat-cell *matCellDef="let element" class="action--position-fix">
          <button mat-icon-button aria-label="Quick Access Menu" [matMenuTriggerFor]="menu" color="primary" tabindex="0">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button mat-menu-item aria-label="Edit Icon" (click)="onEdit(element)" tabindex="0">Edit</button>
            <button mat-menu-item aria-label="Delete" tabindex="0">
              <span class="text-danger" (click)="onDelete(element)"> Delete </span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <ng-container matColumnDef="rating">
        <th mat-header-cell *matHeaderCellDef>Rating</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="!modificationId">{{ element.rating }}</span>
          <app-compare-amendment-output
            *ngIf="modificationId"
            [amendmentId]="modificationId"
            [priorVersionId]="element?.priorVersionId"
            [isActive]="element?.isActive"
            [changedVersionText]="element?.rating"
            [currentVersionText]="element?.priorVersion?.rating"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="!modificationId">{{ element.description }}</span>
          <app-compare-amendment-output
            *ngIf="modificationId"
            [amendmentId]="modificationId"
            [priorVersionId]="element?.priorVersionId"
            [isActive]="element?.isActive"
            [changedVersionText]="element?.description"
            [currentVersionText]="element?.priorVersion?.description"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <p *ngIf="!dataSource.data">No data yet...</p>
  </div>
</ng-container>
