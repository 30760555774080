import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StringSizes } from '../../../../shared/components/form/constants/constants';
@Component({
  selector: 'app-move-in-details-reason-modal',
  templateUrl: './move-in-details-reason-modal.component.html',
  styleUrls: ['./move-in-details-reason-modal.component.scss'],
})
export class MoveInDetailsReasonModalComponent implements OnInit {
  stringSizes = StringSizes;
  formGroup = new FormGroup({
    reason: new FormControl(null, [Validators.required]),
  });
  constructor(private dialogRef: MatDialogRef<MoveInDetailsReasonModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}

  onClose() {
    this.dialogRef.close({ didSubmit: false });
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.dialogRef.close({ didSubmit: true, reason: this.formGroup.get('reason').value });
    }
  }
}
