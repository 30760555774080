import { Component, Input } from '@angular/core';
import { Evaluation } from 'src/app/evaluation/models/evaluation';
import { EvaluationProxyService } from 'src/app/evaluation/services/evaluation-proxy.service';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';

@Component({
  selector: 'app-eligibility-info',
  templateUrl: './eligibility-info.component.html',
  styleUrls: ['./eligibility-info.component.scss'],
})
export class EligibilityInfoComponent {
  @Input() evaluation: Evaluation;

  constructor(private evaluationProxyService: EvaluationProxyService) {
    evaluationProxyService.eligibilityFinalized.subscribe((value) => {
      this.evaluation.isInitial
        ? (this.evaluation.eligibilitySubmittedOnEvaluation = value)
        : (this.evaluation.eligibilityDecision = value);
      this.evaluation.isEligibilityDecisionFinalized = true;
    });
  }

  onHealthInfoNavigate() {
    const caseId = this.evaluation.caseId;
    const config: NewWindowConfig = {
      path: `cases/${caseId}/evaluation/early-access-intake/${caseId};tab=3`,
    };
    openNewWindow(config);
  }
}
