import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { IncompleteDataReportItem } from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ConsentFormService } from 'src/app/shared/services/consent-form/consent-form.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { AuthService } from '../../../auth/auth.service';
import { TransferCaseComponent } from '../../../child-find/modals/transfer-case/transfer-case.component';
import { AppPermissions } from '../../../permissions';
import { ProceduralSafeguardsRead } from '../../../shared/components/procedural-safeguards/procedural-safeguards';
import { CaseSummaryByIfspId, FamilyMeetingRead } from '../../../shared/models/case';
import { CaseService } from '../../../shared/services/case/case.service';
import { ChangeTrackerService } from '../../../shared/services/change-tracker.service';
import { CanComponentDeactivate, DeactivationStatus } from '../../../shared/services/deactivation.service';
import { IfspType, IfspView } from '../../models/ifsp';
import { IfspDocumentService } from '../../services/ifsp-document.service';
import { IfspService } from '../../services/ifsp.service';

@Component({
  selector: 'app-ifsp-profile',
  templateUrl: './ifsp-profile.component.html',
  styleUrls: ['./ifsp-profile.component.scss'],
  providers: [ChangeTrackerService],
})
export class IfspProfileComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  @ViewChild('consent', { read: ElementRef })
  private consentPanelElem: ElementRef;
  @ViewChild('consent') private consentPanel: MatExpansionPanel;
  @ViewChild('lateReason') private lateReasonPanel: MatExpansionPanel;
  @ViewChild('lateReason', { read: ElementRef })
  private lateReasonPanelElem: ElementRef;
  @ViewChild('proceduralSafeguardsPanel', { read: ElementRef })
  private proceduralSafeguardsPanelElem: ElementRef;
  @ViewChild('proceduralSafeguardsPanel')
  private proceduralSafeguardsPanel: MatExpansionPanel;

  private subscriptions = new Subscription();
  shortDateFormat = shortDateFormat;
  permissions = AppPermissions;
  caseSummary: CaseSummaryByIfspId;
  ifspId: string;
  caseId: string;
  evaluationId: string;
  formGroup = this.fb.group({
    proceduralSafeguards: this.fb.group({}),
  });

  documents: any[] = [];
  pastIfsps: IfspView[] = [];
  providedOn: Date;
  mostRecentProceduralSafeguard: ProceduralSafeguardsRead;
  serviceListCount = 0;
  outcomesListCount = 0;
  incompleteItems: IncompleteDataReportItem[];
  needsNewConsent: boolean;
  ifspMeetings: FamilyMeetingRead[];
  ifsp: IfspView;
  ifspType = IfspType;
  notFinalized: boolean;
  activeCall = false;
  isCaseOwner: boolean;

  get proceduralSafeguards(): FormGroup {
    return this.formGroup.controls.proceduralSafeguards as FormGroup;
  }

  get baseUrl() {
    return `api/ifsp-document/${this.ifspId}`;
  }

  get incompleteItemsAmount() {
    if (!this.incompleteItems) {
      return '';
    }
    return `${this.incompleteItems.length} Notices`;
  }

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private ifspDocumentService: IfspDocumentService,
    private ifspService: IfspService,
    private caseService: CaseService,
    private consentFormService: ConsentFormService,
    public authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private ngZone: NgZone,
    private routingService: RoutingService,
    private changeTracker: ChangeTrackerService
  ) {}

  canDeactivate(): DeactivationStatus | Observable<DeactivationStatus> | Promise<DeactivationStatus> {
    return this.changeTracker.hasChanged.pipe(map((x) => (x ? DeactivationStatus.NeedsConfirmation : DeactivationStatus.Accepted)));
  }

  ngOnInit(): void {
    this.ifspId = this.route.parent?.snapshot.paramMap.get('ifspId');
    this.caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    this.getIfspDocuments();

    this.caseService.getCaseSummaryByIfsp(this.ifspId).subscribe((caseSummary) => {
      this.caseSummary = caseSummary;
      this.isCaseOwner = this.authService.user?.id === caseSummary?.caseOwnerId;
      this.evaluationId = caseSummary.evaluationId;
    });

    this.getIfsp();
    this.ifspService.ifspUpdated$.subscribe(() => this.getIfsp());

    this.subscriptions.add(this.getIfspMeetings());

    this.ifspService.getPastIfsps(this.ifspId).subscribe((result) => {
      this.pastIfsps = result;
    });

    this.getIncompleteItems();

    this.consentFormService.consentsUpdated$.subscribe(() => this.getIncompleteItems());
  }

  getIfsp() {
    this.ifspService.get(this.ifspId).subscribe((result) => {
      this.ifsp = result;
      this.notFinalized = !this.ifsp.activatedOn;
    });
  }

  getIfspMeetings() {
    return this.ifspService.getIfspMeetings(this.caseId).subscribe((res) => {
      this.ifspMeetings = res;
    });
  }

  refreshData() {
    this.getIfsp();
    this.ifspService.getIfspMeetings(this.caseId).subscribe((res) => {
      this.ifspMeetings = res;
      this.getIncompleteItems();
    });
  }

  getIfspDocuments() {
    this.subscriptions.add(
      this.ifspDocumentService.getAllDocuments(this.ifspId).subscribe((result) => {
        this.documents = result;
      })
    );
  }

  onEditService(event) {
    this.router.navigate([`/cases/${this.caseId}/ifsp/${this.ifspId}/services/${event.id}`]);
  }

  onEditOutcome(event) {
    this.router.navigate([`/cases/${this.caseId}/ifsp/${this.ifspId}/child-family-outcomes/${event.id}`]);
  }

  onUpload(formData: any) {
    this.activeCall = true;
    this.subscriptions.add(
      this.ifspDocumentService.addDocuments(this.ifspId, formData).subscribe((docs) => {
        this.activeCall = false;
        this.documents = [...this.documents, ...docs];
      })
    );
  }

  onDeleteDocument(id: string) {
    this.ifspDocumentService.removeDocument(id).subscribe(() => {
      this.getIfspDocuments();
    });
  }

  refreshIncompleteItems(e: MouseEvent) {
    e.stopPropagation();
    this.getIncompleteItems();
  }

  getIncompleteItems() {
    this.ifspService.getIncompleteItemsReport(this.ifspId).subscribe((result) => {
      this.incompleteItems = result;
      this.needsNewConsent = result.filter((x) => x.action === 'consent').length > 0;
    });
  }

  onMostRecentProceduralSafeguardChanged(proceduralSafeguard: ProceduralSafeguardsRead) {
    this.mostRecentProceduralSafeguard = proceduralSafeguard;
    this.getIncompleteItems();
    if (!proceduralSafeguard) {
      this.proceduralSafeguardsPanel.open();
    } else {
      if (dayjs(proceduralSafeguard.providedOn).isBefore(dayjs().subtract(3, 'month'))) {
        this.proceduralSafeguardsPanel.open();
      } else {
        this.proceduralSafeguardsPanel.close();
      }
    }
  }

  onTransferCase() {
    this.dialog.open(TransferCaseComponent, {
      data: this.caseSummary.caseId,
    });
  }

  scrollAndOpenProceduralSafeguards() {
    this.proceduralSafeguardsPanel.open();
    this.proceduralSafeguardsPanelElem.nativeElement.scrollIntoView({
      behavior: 'smooth',
    });
  }

  scrollAndOpenConsent() {
    this.consentPanel.open();
    this.consentPanelElem.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  scrollAndOpenLateReason() {
    this.lateReasonPanel.open();
    this.lateReasonPanelElem.nativeElement.scrollIntoView({
      behavior: 'smooth',
    });
  }

  scheduleMeeting(): void {
    this.routingService.openScheduleMeeting(this.caseSummary.learnerId, (ev) => {
      this.ngZone.run(() => this.refreshData());
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
