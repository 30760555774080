/* eslint-disable no-console */
import { Injectable } from '@angular/core';

import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { ApplicationInsightsService } from '../../../application-insights.service';

export interface ILoggerService {
  log: (message: any) => void;

  info: (
    name: string,
    customProperties?: {
      [key: string]: any;
    }
  ) => void;
  /**
   * Sends message to console.warn.
   */
  warn: (message: any) => void;
  /**
   * Sends message to console.error.
   */
  error: (message: any) => void;
}

@Injectable({
  providedIn: 'root',
})
export class LoggerService implements ILoggerService {
  /**
   * Sends message to console.log.
   */
  log(message: any) {
    console.log(message);
  }

  /**
   * Sends message to console.info.
   */
  info(
    name: string,
    customProperties?: {
      [key: string]: any;
    }
  ) {
    console.info(name);
    console.info(customProperties);
  }

  /**
   * Sends message to console.warn.
   */
  warn(message: any) {
    console.warn(message);
  }

  /**
   * Sends message to console.error.
   */
  error(message: any) {
    console.error(message);
  }
}

@Injectable()
export class AzureLoggerService implements ILoggerService {
  constructor(private readonly appInsightsService: ApplicationInsightsService) {}

  /**
   * Sends message to console.log.
   */
  log(message) {
    console.log(message);
  }

  /**
   * Sends message to console.info and to Application Insights
   * Use like logger.info('my page event', { 'request Url': this.router.url})
   */
  info(
    name: string,
    customProperties?: {
      [key: string]: any;
    }
  ) {
    customProperties.source = 'angular';
    this.appInsightsService.trackEvent({ name }, customProperties);
    console.log(name);
    console.log(customProperties);
  }

  /**
   * Sends message to console.warn and to Application Insights as a Warning
   */
  warn(message: any) {
    this.appInsightsService.trackException({
      exception: message,
      severityLevel: SeverityLevel.Warning,
      properties: {
        source: 'angular',
      },
    });
    console.warn(message);
  }

  /**
   * Sends message to console.error and sends message to Application Insights as an Error
   */
  error(message: any) {
    this.appInsightsService.trackException({
      exception: message,
      severityLevel: SeverityLevel.Error,
      properties: {
        source: 'angular',
      },
    });
    console.error(message);
  }
}
