<h1 mat-dialog-title>Reset Dates</h1>
<div mat-dialog-content>
  <form [formGroup]="formGroup">
    <div class="row">
      <div class="col"><app-date-picker label="Start Date" controlName="startDate"></app-date-picker></div>
      <div class="col">
        <app-date-picker label="End Date" controlName="endDate" [min]="minEndDate" [max]="maxEndDate"></app-date-picker>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span *ngIf="!startDatesEqual">Current Start Date: {{ startDate | dateFormat }}</span>
      </div>
      <div class="col">
        <span *ngIf="!endDatesEqual">Current End Date: {{ endDate | dateFormat }}</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <app-textarea label="Reason for Date Change" formControlName="reason" [rows]="4" [maxLength]="10000"></app-textarea>
      </div>
    </div>
  </form>
</div>
<div class="mat-dialog-actions justify-content-end">
  <button mat-button aria-label="Cancel Button" (click)="onCancel()">Cancel</button>
  <button
    mat-raised-button
    aria-label="Submit"
    color="primary"
    [disabled]="formGroup.invalid || (startDatesEqual && endDatesEqual)"
    (click)="onSubmit()"
  >
    Submit
  </button>
</div>
