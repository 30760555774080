<mat-card>
  <div class="display-flex justify-content-between align-items-center flex-wrap">
    <div class="display-flex align-items-center">
      <p class="mb-0 mt-0 mr-3">
        <strong>{{ isPartB ? 'Facilitator' : 'Service Coordinator' }}:</strong>
        {{ evaluation?.owner.fullName }}
      </p>
      <a
        mat-raised-button
        color="accent"
        *ngIf="evaluation && evaluation.owner.id !== me"
        target="_blank"
        class="mr-2"
        href="mailto:{{ evaluation?.owner.email }}"
      >
        {{ isPartB ? 'Contact Facilitator' : 'Contact Service Coordinator' }}
      </a>
      <ng-container
        *appAuthForRoles="{
          permission: [permissions.ReassignEvaluationFacilitator],
          caseId: evaluation?.caseId
        }"
      >
        <button
          (click)="reassignFacilitator()"
          mat-raised-button
          aria-label="Reassing Facilitator"
          color="accent"
          *ngIf="evaluation && evaluation.intakeType === 'PartB' && !evaluation.isFinalized"
          tabindex="0"
        >
          Reassign Facilitator
        </button></ng-container
      >
    </div>

    <a
      (click)="onOpenLearnerCalendar()"
      class="mr-1"
      target="_blank"
      *ngIf="canViewCalendar()"
      tabindex="0"
      role="link"
      aria-labelledby="Open in new tab"
    >
      <span class="display-flex justify-content-end align-items-center">
        View {{ isPartB ? "Learner's" : "Child's" }} Calendar
        <mat-icon aria-labelledby="Open in new tab">open_in_new</mat-icon>
      </span>
    </a>
  </div>

  <div class="display-flex justify-content-between align-items-center flex-wrap" *ngIf="isPartB || evaluation?.isInitial">
    <p class="mb-0 mt-1"><strong>Evaluation Type:</strong> Initial Evaluation</p>

    <div class="display-flex align-items-center">
      <div class="progress-bar__container" *ngIf="!isPartB">
        <div class="progress-bar">
          <div class="progress-bar__complete" [ngStyle]="{ width: calculateWidth() }"></div>
          <span class="progress-bar__text"
            >{{ evaluation.progressComplete }} of {{ evaluation.total }} Evaluation Assignments Complete</span
          >
        </div>
      </div>
      <p class="my-0 mx-2" *ngIf="!isPartB">|</p>
      <p class="my-0">{{ evaluation.daysLeft }} Days remaining</p>
    </div>
  </div>
</mat-card>
