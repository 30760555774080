import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FamilySurveyService } from '../services/family-survey.service';
import { FamilySurveyResult } from '../models/family-survey';

@Injectable({
  providedIn: 'root',
})
export class FamilySurveyResolver implements Resolve<Observable<FamilySurveyResult>> {
  constructor(private readonly familySurveyService: FamilySurveyService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FamilySurveyResult> | Promise<FamilySurveyResult> | any {
    return this.familySurveyService.response(route.paramMap.get('evaluationId'), route.paramMap.get('surveyId'));
  }
}
