<app-print-head title="Consent to Release and Exchange Information" [showLogo]="true"></app-print-head>

<div style="height: 100%">
  <ng-container *ngIf="intake">
    <div class="print__container">
      <div class="learner-info">
        <div class="display-flex justify-content-between">
          <p class="my-0"><strong>Child Name: </strong> {{ learnersName }}</p>
          <p class="my-0">
            <strong>DOB: </strong>
            {{ intake.childInfo.dateOfBirth | dateFormat }}
          </p>
          <p class="my-0"><strong>Current Date: </strong> {{ today | dateFormat }}</p>
        </div>
      </div>
    </div>

    <div class="print__container card-spacing-top">
      <p class="mb-2">On behalf of the above named child, I authorize:</p>

      <ng-container *ngIf="agency">
        <div class="ml-3">
          <p class="mb-0" *ngIf="agency?.contact">
            {{ agency?.title ? agency.title : '' }}
            {{ agency?.contact }}
          </p>
          <p class="mb-0">
            <span *ngIf="agency?.name">{{ agency?.name }}</span>
          </p>
          <p class="mb-0" *ngIf="agency?.address">
            {{ agency?.address }}
          </p>
          <p class="mb-0" *ngIf="agency?.city || agency?.state || agency?.zipCode">
            <span *ngIf="agency?.city">{{ agency?.city }}, </span>
            <span *ngIf="agency?.state">{{ agency?.state }} </span>
            <span *ngIf="agency?.zipCode">{{ agency?.zipCode }}</span>
          </p>
          <p class="mb-0">
            <span *ngIf="agency?.phoneNumber"> Phone: {{ agency?.phoneNumber | phone }} </span>
            <span class="mx-1" *ngIf="agency?.phoneNumber && agency?.faxNumber">|</span>
            <span *ngIf="agency?.faxNumber"> Fax: {{ agency?.faxNumber | phone }} </span>
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="!agency">
        <div class="display-flex flex-column ml-3">
          <div class="signature__fill">
            <p class="mb-0">Agency:</p>
            <div></div>
          </div>
          <div class="signature__fill">
            <p class="mb-0">Contact:</p>
            <div></div>
          </div>
          <div class="signature__fill">
            <p class="mb-0">Address:</p>
            <div></div>
          </div>
          <div class="signature__fill">
            <p class="mb-0">Phone:</p>
            <div></div>
          </div>
          <div class="signature__fill">
            <p class="mb-0">City, State, Zip:</p>
            <div></div>
          </div>
          <div class="signature__fill">
            <p class="mb-0">Fax:</p>
            <div></div>
          </div>
        </div>
      </ng-container>

      <hr class="input__divider" />

      <p>to <strong>RELEASE</strong> and <strong>EXCHANGE</strong> information to / with the Early ACCESS team and Service Coordinator:</p>

      <div class="display-flex flex-column ml-3">
        <div class="display-flex">
          <div *ngIf="caseOwner?.fullName" class="mr-1">
            <p class="mb-0">{{ caseOwner?.fullName }}</p>
          </div>
        </div>
        <div *ngIf="caseOwner?.jobTitle">
          <p class="mb-0">{{ caseOwner?.jobTitle }}</p>
        </div>
        <!-- TODO This needs to be updated when we get building information -->
        <div *ngIf="caseOwner?.buildings">
          <p class="mb-0">{{ caseOwner.buildings[0]?.name }}</p>
        </div>
        <div *ngIf="aea?.name">
          <p class="mb-0">{{ aea?.name }}</p>
        </div>
        <div *ngIf="aea?.streetAddress">
          <p class="mb-0">{{ aea?.streetAddress }}</p>
        </div>
        <div *ngIf="aea?.city && aea?.state && aea?.zipCode">
          <p class="mb-0">
            {{ aea?.city }}, {{ aea?.state }}
            {{ aea?.zipCode }}
          </p>
        </div>
        <div class="display-flex">
          <div *ngIf="aea?.primaryPhone">
            <p class="mb-0">Phone: {{ aea?.primaryPhone | phone }}</p>
          </div>
          <div *ngIf="aea?.primaryPhone && aea?.secondaryPhone" class="mx-1">|</div>
          <div *ngIf="aea?.secondaryPhone">
            <p class="mb-0">Fax: {{ aea?.secondaryPhone | phone }}</p>
          </div>
        </div>
      </div>

      <hr class="input__divider" />

      <p>
        For the purpose of determining eligibility for and / or provision of early intervention services and / or transition planning. Such
        information may be exchanged in writing, verbally or through electronic communication.
      </p>

      <p>Check the information to be released or exchanged (include dates where indicated)</p>

      <p>
        I understand that the information may be released electronically and may include information in the following categories unless I
        specifically deny the release (<strong><u>initial</u></strong> any category <strong><u>not</u></strong> to be released).
      </p>

      <hr class="input__divider" />

      <div class="row">
        <div class="col-md-4">
          <p>____ Mental health</p>
        </div>
        <div class="col-md-4">
          <p>____ Substance abuse/chemical dependence</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <p class="mb-0">____ Sexually transmitted disease</p>
        </div>
        <div class="col-md-4">
          <p class="mb-0">____ HIV/AIDS</p>
        </div>
        <br />
      </div>

      <hr class="input__divider" />

      <p>I understand that I have the following rights with respect to this Authorization:</p>
      <ul>
        <li>The right to inspect or copy the health information to be disclosed by this form.</li>
        <li>The right to receive a copy of this form.</li>
        <li>
          The right to withdraw this Authorization by written notification at any time (although my withdrawal will not be effective as to
          uses and/or disclosures already made regarding this form).
        </li>
        <li>Eligibility determination and services provided under Early ACCESS are not conditioned on signing of this Authorization.</li>
      </ul>

      <p class="mt-5">I also acknowledge that:</p>

      <ul>
        <li>Recipients of this information may possibly re-release the information without proper authorization, and</li>
        <li>Once information is disclosed it may no longer be protected by federal privacy regulations.</li>
      </ul>

      <p>This Consent is valid until the following date, _____/_____/_____, or until the child’s third birthday, whichever occurs first.</p>
      <br /><br />

      <!-- <div class="mt-5 mb-3 display-flex flex-column">
        <p class="mt-4 signature">Your name (please print)</p>
        <div class="display-flex">
          <p class="mt-4 signature mr-4 flex-4-2">Your Signature</p>
          <p class="mt-4 signature flex-4-1">Date</p>
        </div>
      </div -->

      <div class="display-flex flex-column">
        <div class="display-flex">
          <p class="signature flex-10-5">Parent / Guardian Signature</p>
          <p class="signature flex-10-3">Relationship to Child</p>
          <p class="signature flex-10-2">Date</p>
        </div>

        <p class="signature flex-10-5">Printed Name</p>
      </div>

      <hr class="input__divider" />

      <p class="mt-4">
        <b>Health Insurance Portability and Accountability Act (HIPAA)/ Family Educational Rights and Privacy Act (FERPA) Notice</b>
        Any and all personally identifiable information regarding children and families receiving Early ACCESS services funded under the
        Individuals with Disabilities Education Act (20 U.S.C. §1400 et seq.) is protected from unauthorized disclosure under FERPA.
        Personally identifiable information protected by FERPA is specifically <b>exempted</b> from HIPAA privacy standards. FERPA prohibits
        disclosure of personally identifiable information without parent consent except in limited circumstances, requires notice to be
        provided to the child’s family regarding their privacy rights, requires providers to keep records of access to a child’s records,
        and contains complaint and appeal procedures which apply to disputes over records in possession of Early ACCESS or its providers,
        among other provisions. All Early ACCESS providers comply with these procedures
      </p>
      <p>
        <b> NOTICE TO RECIPIENTS OF MENTAL HEALTH INFORMATION</b>
        In accordance with the Iowa Mental Health Information Disclosure Act (Iowa Code, Chapter 228), a recipient of mental health
        information may redisclose this information only with the written authorization of the subject or the subject's legal representative
        or as otherwise provided in chapter 228 and 220. Unauthorized disclosure is unlawful and civil damages and criminal penalties may
        apply. Federal confidentiality rules (42 CFR Part 2) restrict any use of the information to criminally investigate or prosecute any
        alcohol or drug abuse patient.
      </p>
      <p>
        <b> NOTICE TO RECIPIENTS OF SUBSTANCE ABUSE INFORMATION</b>
        This information has been disclosed from records whose confidentiality is protected by Federal law. Iowa Code, Chapter 125 and
        Federal regulations (42 CFR, Part 2) prohibit any further disclosure without the specific written consent of the person to whom the
        information pertains, or as otherwise permitted by such statute and regulations. A general authorization for the release of medical
        or other information is not for this purpose. Federal rules restrict any use of the information to criminally investigate or
        prosecute any alcohol or drug abuse patient.
      </p>
      <p>
        <b> NOTICE TO RECIPIENT OF HIV RELATED TESTING INFORMATION</b>
        This information has been disclosed to you from records whose confidentiality is protected by state law. State law prohibits you
        from making any further disclosure of the information without specific written consent of the person to whom it pertains, or as
        otherwise permitted by law. A general authorization for the release of medical or other information is not sufficient for this
        purpose. (Iowa Code 141.23) Federal confidentiality rules (42 CFR, Part 2) restrict any use of the information to criminally
        investigate or prosecute any alcohol or drug abuse patient
      </p>
    </div>
  </ng-container>
</div>
