<mat-accordion class="accordion accordion--close accordion--short accordion--shadow">
  <mat-expansion-panel #ecProgramsPanel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Regular Early Childhood Programs</h3> </mat-panel-title>
    </mat-expansion-panel-header>

    <app-help-directional-text>
      <p>
        List all the
        <a
          (click)="onOpenHelp($event, helpSection.IepService, iepServiceHelp.RegularChildhood)"
          class="text-lower text-underline"
          tabindex="0"
          role="link"
          >{{ iepServiceHelp.RegularChildhood }}</a
        >
        (RECP) the child is currently attending.<br />
      </p>
    </app-help-directional-text>

    <ng-container [formGroup]="formGroup" *ngIf="hasEditPermission">
      <div class="row">
        <div class="col-md-6 col-xl-6">
          <app-autocomplete [options]="typeOptions" formControlName="programTypeId" label="Program"></app-autocomplete>
        </div>
        <div class="col-md-6 col-xl-6">
          <app-text-field formControlName="programName" id="" label="Program Name"></app-text-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 col-lg-6">
          <app-date-picker label="Start Date" controlName="projectedStartDate"></app-date-picker>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <app-text-field formControlName="contactName" label="Contact Name"></app-text-field>
        </div>
        <div class="col-md-3">
          <app-text-field formControlName="contactEmail" label="Contact Email"></app-text-field>
        </div>
        <div class="col-md-3">
          <fieldset>
            <app-radio-group [options]="yesNoOptions" formControlName="inviteToIepMeeting" label="Invite to IEP Meeting"></app-radio-group>
          </fieldset>
        </div>
      </div>
      <app-help-directional-text>
        <p class="my-2">Frequency must be reported using whole numbers (ie. 12.5 hours would be recorded as 750 minutes).</p>
      </app-help-directional-text>

      <div class="row align-items-baseline">
        <div class="col-lg-12">
          <div class="display-flex align-items-baseline">
            <span class="mr-3">Frequency</span>
            <app-number-field formControlName="timeQuantity" class="input--small" [min]="0" id="timeQuantity"></app-number-field>
            <div class="mx-2"></div>
            <app-select label="Increments" formControlName="timeUnit" [options]="timeUnitOptions"> </app-select>
            <div class="mx-2"></div>
            <p>per week</p>
          </div>
        </div>
      </div>

      <div class="display-flex align-items-center justify-content-end">
        <button type="button" mat-raised-button aria-label="Cancel Button" (click)="cancel()" class="mr-2" tabindex="0">Cancel</button>
        <button mat-raised-button aria-label="Complete" color="primary" (click)="save()" [disabled]="formGroup.invalid" tabindex="0">
          Complete
        </button>
      </div>
    </ng-container>

    <hr class="input__divider my-2" />

    <div class="table-overflow app-table">
      <table mat-table [dataSource]="dataSource" matSort class="w-100">
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let element">
            <div class="display-flex align-items-center justify-content-start" *ngIf="!isEditing">
              <button aria-label="View Icon" (click)="onViewMore(element)" mat-icon-button color="primary" tabindex="0">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
              <ng-container *ngIf="hasEditPermission">
                <button mat-icon-button aria-label="Edit Icon" color="primary" (click)="edit(element)" tabindex="0">
                  <mat-icon>edit</mat-icon>
                </button>
                <button *ngIf="canDelete" mat-icon-button aria-label="Delete Icon" color="warn" (click)="remove(element)" tabindex="0">
                  <mat-icon>close</mat-icon>
                </button>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="programType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Program</th>
          <td mat-cell *matCellDef="let element">
            {{ element.programType }}
          </td>
        </ng-container>

        <ng-container matColumnDef="howMuch">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Frequency</th>
          <td mat-cell *matCellDef="let element">
            {{ element.howMuch }}
          </td>
        </ng-container>

        <ng-container matColumnDef="projectedStartDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.projectedStartDate | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="attendRECP">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Will attend RECP after IEP Meeting</th>
          <td mat-cell *matCellDef="let element">
            <mat-radio-group (change)="onAttendRecpChange($event.value, element)" aria-labelledby="Select an option" role="radiogroup">
              <mat-radio-button [checked]="element.attendRecp === true" [value]="true" [disabled]="!hasEditPermission" aria-label="Yes"
                >Yes</mat-radio-button
              >
              <mat-radio-button [checked]="element.attendRecp === false" [value]="false" [disabled]="!hasEditPermission" aria-label="No"
                >No</mat-radio-button
              >
            </mat-radio-group>
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No programs entered yet...</i>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          [hidden]="dataSource?.data?.length > 0"
          mat-footer-row
          *matFooterRowDef="!dataSource.data?.length ? ['noResults'] : []"
          class="example-second-footer-row"
        ></tr>
      </table>
      <mat-paginator [dataSource]="dataSource"></mat-paginator>
    </div>

    <section class="mt-2">
      <form [formGroup]="kindergartenFormGroup">
        <app-date-picker controlName="startDate" label="Date Learner Starts Kindergarten"></app-date-picker>
      </form>
    </section>
  </mat-expansion-panel>
</mat-accordion>
