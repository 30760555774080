import { Component, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { IfspService } from '../../../../../../ifsp/services/ifsp.service';
import { IfspView } from '../../../../../../ifsp/models/ifsp';

@Component({
  selector: 'app-data-history-part-c-other-services',
  templateUrl: './data-history-part-c-other-services.component.html',
  styleUrls: ['./data-history-part-c-other-services.component.scss'],
})
export class DataHistoryPartCOtherServicesComponent implements OnInit {
  @Input() caseId: string;
  ifsps: IfspView[] = [];
  constructor(private ifspService: IfspService) {}

  ngOnInit(): void {
    forkJoin([this.ifspService.getByCaseId(this.caseId)]).subscribe(async ([ifspViews]) => {
      this.ifsps = ifspViews;
    });
  }
}
