<button *ngIf="testMode" mat-raised-button aria-label="PK data" (click)="loadCustomDateServices('pk')" tabindex="0">PK data</button>
<button *ngIf="testMode" mat-raised-button aria-label="K data" (click)="loadCustomDateServices('k')" tabindex="0">K data</button>
<button *ngIf="testMode" mat-raised-button aria-label="Get previous LRE" (click)="getPreviousLRE()" tabindex="0">Get previous LRE</button>
<ng-container *ngIf="!startingKDuringIep">
  <table mat-table [dataSource]="dataSource" class="w-100 bordered">
    <ng-container matColumnDef="header-row-first-group">
      <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'" [attr.colspan]="2">
        {{ isPK ? 'Delivery of Special Education Services' : 'General Education' }}
      </th>
    </ng-container>

    <ng-container matColumnDef="generalEducationEnv">
      <th mat-header-cell *matHeaderCellDef>
        {{ isPK ? 'Within a Regular Early Childhood Program' : 'General Education Environment' }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.generalEducationEnv }}
      </td>
    </ng-container>

    <ng-container matColumnDef="specialEducationEnv">
      <th mat-header-cell *matHeaderCellDef>
        {{ isPK ? 'Outside a Regular Early Childhood Program' : 'Special Education Environment' }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.specialEducationEnv }}
      </td>
    </ng-container>

    <ng-container *ngIf="isPK">
      <tr mat-header-row *matHeaderRowDef="['header-row-first-group']"></tr>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div
    class="display-flex align-items-start my-2 flex-wrap"
    [ngClass]="{
      'justify-content-center': showCharts,
      'justify-content-end': !showCharts
    }"
  >
    <div class="charts__container" *ngIf="showCharts">
      <div class="charts__legend-container">
        <div class="charts__legend charts__legend--gen-ed-env">
          <span></span>
          <p>
            {{ isPK ? 'Within a Regular Early Childhood Program' : 'General Education Environment' }}
          </p>
        </div>
        <div class="charts__legend charts__legend--spec-ed-env">
          <span></span>
          <p>
            {{ isPK ? 'Outside a Regular Early Childhood Program' : 'Special Education Environment' }}
          </p>
        </div>
      </div>
      <div class="display-flex align-items-start justify-content-center">
        <div class="charts">
          <div class="charts__pie">
            <p class="charts__label">
              <ng-container *ngIf="hasAmendment">Amended IEP</ng-container>
              <ng-container *ngIf="!hasAmendment">Current IEP</ng-container>
            </p>
            <canvas
              baseChart
              [data]="currentIepChart.data"
              [labels]="pieChart.labels"
              [chartType]="'pie'"
              [colors]="pieChart.colors"
              [legend]="pieChart.legend"
              role="img"
              aria-label="Current IEP"
            ></canvas>
          </div>

          <div *ngIf="previousIepId || hasAmendment" class="charts__pie">
            <p class="charts__label">
              <ng-container *ngIf="hasAmendment">Current IEP</ng-container>
              <ng-container *ngIf="!hasAmendment">Previous IEP</ng-container>
            </p>
            <canvas
              baseChart
              [data]="previousIepChart.data"
              [labels]="pieChart.labels"
              [chartType]="'pie'"
              [colors]="pieChart.colors"
              [legend]="pieChart.legend"
              role="img"
              aria-label="Current IEP"
            ></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div *ngIf="!startingKDuringIep && !isPK">
  <p class="form__description">
    {{ learner?.firstName }} will spend {{ generalEducationEnv }}% of the time in the general education environment and be removed from the
    general education environment {{ specialEducationEnv }}% of the time.
  </p>
</div>
<div *ngIf="!startingKDuringIep && isPK">
  <div class="form__description mb-2">
    <p>
      {{ learner?.firstName }} attends regular early childhood program(s) for a total of
      <strong>({{ ecpsHoursInWeek | number : '1.2-2' }}) hours per week</strong>.
    </p>
    <p class="mb-0">
      {{ learner?.firstName }} receives {{ generalEducationEnv }}% of services in the
      <a
        (click)="onOpenHelp($event, helpSection.IepService, iepServiceHelp.RegularChildhood)"
        class="text-underline text-lower"
        tabindex="0"
        role="link"
        >{{ iepServiceHelp.RegularChildhood }}</a
      >
      program, and {{ specialEducationEnv }}% of services removed from the regular {{ isPK ? ' early ' : '' }} childhood program.
    </p>
  </div>
</div>
<ng-container *ngIf="startingKDuringIep">
  <h3 class="mt-0 mb-2">PK Year</h3>
  <div *ngIf="isPK">
    <table mat-table [dataSource]="dataSourcePKOnly" class="w-100 bordered">
      <ng-container matColumnDef="header-row-first-group">
        <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'" [attr.colspan]="2">Delivery of Special Education Services</th>
      </ng-container>

      <ng-container matColumnDef="specialEducationEnv">
        <th mat-header-cell *matHeaderCellDef>Outside a Regular Early Childhood Program</th>
        <td mat-cell *matCellDef="let element">
          {{ element.specialEducationEnv }}
        </td>
      </ng-container>

      <ng-container matColumnDef="generalEducationEnv">
        <th mat-header-cell *matHeaderCellDef>Within a Regular Early Childhood Program</th>
        <td mat-cell *matCellDef="let element">
          {{ element.generalEducationEnv }}
        </td>
      </ng-container>

      <ng-container *ngIf="isPK">
        <tr mat-header-row *matHeaderRowDef="['header-row-first-group']"></tr>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div
      class="display-flex align-items-start my-2 flex-wrap"
      [ngClass]="{
        'justify-content-between': showCharts,
        'justify-content-end': !showCharts
      }"
    >
      <div class="charts__container" *ngIf="showCharts">
        <div class="charts__legend-container">
          <div class="charts__legend charts__legend--gen-ed-env">
            <span></span>
            <p>Within a Regular Early Childhood Program</p>
          </div>
          <div class="charts__legend charts__legend--spec-ed-env">
            <span></span>
            <p>Outside a Regular Early Childhood Program</p>
          </div>
        </div>
        <div class="display-flex align-items-start justify-content-center">
          <div class="charts">
            <div class="charts__pie">
              <p class="charts__label">
                <ng-container *ngIf="hasAmendment">Amended IEP</ng-container>
                <ng-container *ngIf="!hasAmendment">Current IEP</ng-container>
              </p>
              <canvas
                baseChart
                [data]="currentIepChartPK.data"
                [labels]="pieChart.labels"
                [chartType]="'pie'"
                [colors]="pieChart.colors"
                [legend]="pieChart.legend"
                role="img"
                aria-label="Current IEP"
              ></canvas>
            </div>

            <div *ngIf="previousIepId || hasAmendment" class="charts__pie">
              <p class="charts__label">
                <ng-container *ngIf="hasAmendment">Current IEP</ng-container>
                <ng-container *ngIf="!hasAmendment">Previous IEP</ng-container>
              </p>
              <canvas
                baseChart
                [data]="previousIepChartPK.data"
                [labels]="pieChart.labels"
                [chartType]="'pie'"
                [colors]="pieChart.colors"
                [legend]="pieChart.legend"
                role="img"
                aria-label="Current IEP"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form__description mb-2">
    <p>
      {{ learner?.firstName }} attends regular early childhood program(s) for a total of
      <strong>({{ ecpsHoursInWeek | number : '1.2-2' }}) hours per week</strong>.
    </p>
    <p class="mb-0">
      {{ learner?.firstName }} receives {{ pkGeneralEducationEnv }}% of services in the
      <a (click)="onOpenHelp($event, helpSection.IepService, iepServiceHelp.RegularChildhood)" class="text-underline text-lower">{{
        iepServiceHelp.RegularChildhood
      }}</a>
      program, and {{ pkSpecialEducationEnv }}% of services removed from the regular {{ isPK ? ' early ' : '' }} childhood program.
    </p>
  </div>
  <div *ngIf="showK">
    <h3 class="mt-0 mb-2">K Year</h3>
    <table mat-table [dataSource]="dataSourceKOnly" class="w-100 bordered">
      <ng-container matColumnDef="header-row-first-group">
        <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'" [attr.colspan]="2">Delivery of Special Education Services</th>
      </ng-container>

      <ng-container matColumnDef="generalEducationEnv">
        <th mat-header-cell *matHeaderCellDef>General Education Environment</th>
        <td mat-cell *matCellDef="let element">
          {{ element.generalEducationEnv }}
        </td>
      </ng-container>

      <ng-container matColumnDef="specialEducationEnv">
        <th mat-header-cell *matHeaderCellDef>Special Education Environment</th>
        <td mat-cell *matCellDef="let element">
          {{ element.specialEducationEnv }}
        </td>
      </ng-container>

      <ng-container *ngIf="isPK">
        <tr mat-header-row *matHeaderRowDef="['header-row-first-group']"></tr>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div
      class="display-flex align-items-start my-2 flex-wrap"
      [ngClass]="{
        'justify-content-between': showCharts,
        'justify-content-end': !showCharts
      }"
    >
      <div class="charts__container" *ngIf="showCharts">
        <div class="charts__legend-container">
          <div class="charts__legend charts__legend--gen-ed-env">
            <span></span>
            <p>General Education Environment</p>
          </div>
          <div class="charts__legend charts__legend--spec-ed-env">
            <span></span>
            <p>Special Education Environment</p>
          </div>
        </div>
        <div class="display-flex align-items-start justify-content-center">
          <div class="charts">
            <div class="charts__pie">
              <p class="charts__label">
                <ng-container *ngIf="hasAmendment">Amended IEP</ng-container>
                <ng-container *ngIf="!hasAmendment">Current IEP</ng-container>
              </p>
              <canvas
                baseChart
                [data]="currentIepChartK.data"
                [labels]="pieChart.labels"
                [chartType]="'pie'"
                [colors]="pieChart.colors"
                [legend]="pieChart.legend"
              ></canvas>
            </div>

            <div *ngIf="previousIepId || hasAmendment" class="charts__pie">
              <p class="charts__label">
                <ng-container *ngIf="hasAmendment">Current IEP</ng-container>
                <ng-container *ngIf="!hasAmendment">Previous IEP</ng-container>
              </p>
              <canvas
                baseChart
                [data]="previousIepChartK.data"
                [labels]="pieChart.labels"
                [chartType]="'pie'"
                [colors]="pieChart.colors"
                [legend]="pieChart.legend"
                role="img"
                aria-label="Current IEP"
              ></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form__description mb-2">
      <p>
        {{ learner?.firstName }} will spend {{ kGeneralEducationEnv }}% of the time in the general education environment and be removed from
        the general education environment {{ kSpecialEducationEnv }}% of the time.
      </p>
    </div>
  </div>
</ng-container>

<button
  type="button"
  mat-raised-button
  attr.aria-label="{{ showCharts ? 'Hide Charts' : 'Show Charts' }}"
  color="accent"
  class="mb-2"
  *ngIf="startingKDuringIep || (!startingKDuringIep && !isPK)"
  (click)="showCharts = !showCharts"
  tabindex="0"
>
  {{ showCharts ? 'Hide Charts' : 'Show Charts' }}
</button>
<div class="row">
  <div *ngIf="showIncompatibleDataCombinationError" class="col-md-12">
    <div class="text-danger my-2">
      There is an issue with the percentage of services the learner receives within a Regular Early Childhood Program and the RECP Summary
      Table. There may be incomplete data on the list of RECPs the learner is/will attend. Please resolve to proceed further.
    </div>
  </div>
</div>
