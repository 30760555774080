import { ChangeDetectorRef, Component, Inject, NgZone } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivityWatcher } from '../../../activity-watcher.service';

@Component({
  selector: 'app-global-error-modal',
  templateUrl: './global-error-modal.component.html',
  styleUrls: ['./global-error-modal.component.scss'],
})
export class GlobalErrorModalComponent {
  config: any = {};
  constructor(
    private dialogRef: MatDialogRef<GlobalErrorModalComponent>,
    private ngZone: NgZone,
    private readonly activityWatcher: ActivityWatcher,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private readonly data: any
  ) {
    setTimeout(() => {
      this.config = data;
      this.changeDetectorRef.detectChanges();
    }, 0);
  }

  close(): void {
    // using ngZone because of this: https://github.com/angular/components/issues/13640
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  generateHar() {
    const blobLink = document.getElementById('blobLink');
    const blobUrl = this.activityWatcher.getHar();
    blobLink.setAttribute('href', blobUrl);
    blobLink.setAttribute('download', `${new Date().toISOString()}.har`);
    blobLink.click();
  }
}
