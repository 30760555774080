<main>
  <div class="hide-print">
    <app-page-header [pageTitle]="'New Learner Info'" [helpLinks]="false">
      <ng-container pageActions>
        <button mat-raised-button aria-label="Print" color="primary" (click)="onPrint()" tabindex="0">Print</button>
      </ng-container>
    </app-page-header>
  </div>

  <h1 class="hide-normal">Learner Information</h1>

  <div *ngIf="newChild" class="card-spacing-top padding-fix print__container">
    <h3 class="text-semibold mb-3 mt-0">Child Information</h3>
    <section *ngIf="newChild" class="avoid-break">
      <div>
        <p *ngIf="newChild.firstName">
          <strong>Name:</strong>
          {{ newChild.firstName }}
          {{ newChild.middleName ? newChild.middleName : '' }}
          {{ newChild.lastName }}
        </p>

        <p *ngIf="newChild.dateOfBirth">
          <strong>DOB:</strong>
          {{ newChild.dateOfBirth | dateFormat }}
        </p>

        <p *ngIf="newChild.gender"><strong>Gender:</strong> {{ newChild.gender }}</p>

        <p *ngIf="newChild.languageSpokenInHome">
          <strong>Primary Language Spoken In Home:</strong>
          {{ newChild.languageSpokenInHome }}
        </p>

        <p *ngIf="newChild.interpreterNeeded !== null">
          <strong>Interpreter Needed:</strong>
          {{ newChild.interpreterNeeded | nullableBoolToYesNo }}
        </p>
      </div>
    </section>

    <hr class="input__divider" />

    <section *ngIf="newChild" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Residential Information</h3>
      <div *ngIf="newChild">
        <div>
          <p *ngIf="newChild.streetAddress">
            <strong>Address:</strong>
            {{ newChild.streetAddress }},
            {{ newChild.city }}
            ({{ newChild.county }}),
            {{ newChild.state }}
            {{ newChild.zipCode }}
          </p>
        </div>

        <div class="display-flex flex-wrap">
          <p *ngIf="newChild.attendingDistrict" class="mr-2">
            <strong>Child's Attending School District:</strong>
            {{ newChild.attendingDistrict }}
          </p>
          <p *ngIf="newChild.residentDistrict">
            <strong>Child's Resident School District:</strong>
            {{ newChild.residentDistrict }}
          </p>
        </div>
      </div>
    </section>

    <hr class="input__divider" />

    <section *ngIf="newChild?.attendsChildCare" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Child Care</h3>
      <div>
        <div>
          <p *ngIf="newChild.childCareProviderName">
            <strong>Child Care Provider Name:</strong>
            {{ newChild.childCareProviderName }}
          </p>
          <p *ngIf="newChild.childCareProviderStreetAddress">
            <strong>Address:</strong>
            {{ newChild.childCareProviderStreetAddress }}, {{ newChild.childCareProviderCity }},
            {{ newChild.childCareProviderState }}
            {{ newChild.childCareProviderZipCode }}
          </p>
        </div>
      </div>
      <hr class="input__divider" />
    </section>

    <section *ngIf="newChild.familyInfo.length > 0" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Family Information</h3>
      <div *ngFor="let family of newChild?.familyInfo">
        <p *ngIf="family.fullName"><strong>Name:</strong> {{ family.fullName }}</p>
        <p *ngIf="family.email"><strong>Email:</strong> {{ family.email }}</p>
        <p *ngIf="family.livesWithChild !== null">
          <strong>Lives With Child:</strong>
          {{ family.livesWithChild | nullableBoolToYesNo }}
        </p>
        <p *ngIf="family.homePhone">
          <strong>Home Phone Number:</strong>
          {{ family.homePhone | phone }}
        </p>
        <p *ngIf="family.cellPhone">
          <strong>Cell Phone Number:</strong>
          {{ family.cellPhone | phone }}
        </p>
        <p *ngIf="family.workPhone">
          <strong>Work Phone Number:</strong>
          {{ family.workPhone | phone }}
        </p>
        <p *ngIf="getBestWayToReach(family).length > 0">
          <strong>Best Way To Contact:</strong>
          {{ getBestWayToReach(family) }}
        </p>
        <div *ngIf="!family.livesWithChild">
          <p>
            <strong>Address:</strong>
            {{ family.streetAddress }}, {{ family.city }},
            {{ family.state }}
            {{ family.zipCode }}
          </p>
        </div>
      </div>
    </section>

    <section *ngIf="newChild?.othersInHome" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Others in Home</h3>
      <div>
        <p>
          <strong>Others In Home:</strong>
          {{ newChild.othersInHome }}
        </p>
      </div>
    </section>

    <hr class="input__divider" *ngIf="newChild?.referralSourceInfo && shouldShowSection(newChild?.referralSourceInfo)" />

    <section *ngIf="newChild?.referralSourceInfo && shouldShowSection(newChild?.referralSourceInfo)" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Referral Source Information</h3>
      <div>
        <p *ngIf="newChild.referralSourceInfo.referralSourceName">
          <strong>Name:</strong>
          {{ newChild.referralSourceInfo.referralSourceName }}
        </p>
        <p *ngIf="newChild.referralSourceInfo.referralSourceEmail">
          <strong>Email:</strong>
          {{ newChild.referralSourceInfo.referralSourceEmail }}
        </p>
        <p *ngIf="newChild.referralSourceInfo.referralSourceAgency">
          <strong>Agency/Organization:</strong>
          {{ newChild.referralSourceInfo.referralSourceAgency }}
        </p>
        <p *ngIf="newChild.referralSourceInfo.referralSourceHomePhone">
          <strong>Home Phone:</strong>
          {{ newChild.referralSourceInfo.referralSourceHomePhone | phone }}
        </p>
        <p *ngIf="newChild.referralSourceInfo.referralSourceCellPhone">
          <strong>Cell Phone:</strong>
          {{ newChild.referralSourceInfo.referralSourceCellPhone | phone }}
        </p>
        <p *ngIf="newChild.referralSourceInfo.referralSourceWorkPhone">
          <strong>Work Phone:</strong>
          {{ newChild.referralSourceInfo.referralSourceWorkPhone | phone }}
        </p>
        <p *ngIf="newChild.referralSourceInfo.referralSourceAddress">
          <strong>Address:</strong>
          {{ newChild.referralSourceInfo.referralSourceAddress }}, {{ newChild.referralSourceInfo.referralSourceCity }},
          {{ newChild.referralSourceInfo.referralSourceState }}
          {{ newChild.referralSourceInfo.referralSourceZipCode }}
        </p>

        <p *ngIf="newChild.referralSourceHowHearAboutUs">
          <strong>How did you hear about Early ACCESS:</strong>
          {{ newChild.referralSourceHowHearAboutUs }}
        </p>
        <p *ngIf="newChild.referralSourceEarlyAccessOtherText">
          <strong>How did you hear about Early ACCESS:</strong>
          {{ newChild.referralSourceEarlyAccessOtherText }}
        </p>
        <p *ngIf="newChild.referralSourceInfo.referralSourceCategory">
          <strong>Referral Source Category:</strong>
          {{ newChild.referralSourceInfo.referralSourceCategory }}
        </p>
        <div>
          <p *ngIf="newChild.legalGuardian !== null">
            <strong>Is the parent or legal guardian aware that the referral has been made to Early ACCESS:</strong>
            {{ newChild.legalGuardian | nullableBoolToYesNo }}
          </p>
          <p *ngIf="newChild.exchangeInformation !== null">
            <strong>Do you have a signed release from the family to exchange information about the child with Early ACCESS?:</strong>
            {{ newChild.exchangeInformation | nullableBoolToYesNo }}
          </p>
        </div>
      </div>
    </section>

    <hr class="input__divider" *ngIf="newChild?.referralSourceInfo && shouldShowSection(newChild?.referralReasonInfo)" />

    <section *ngIf="newChild?.referralReasonInfo && shouldShowSection(newChild?.referralReasonInfo)" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Reason For Referral</h3>
      <div>
        <div *ngIf="isADevelopmentalDelay()">
          <p>
            <strong>Areas of Developmental Delay:</strong>
            {{ developmentDelayList() }}
          </p>

          <p *ngIf="newChild.referralReasonInfo">
            <strong>Description Of Concern:</strong>
            {{ newChild.referralReasonInfo.referralReasonDesc }}
          </p>

          <p *ngIf="newChild.referralReasonInfo.referralReasonScreeningToolUsed !== null">
            <strong>Was a screening tool used:</strong>
            {{ newChild.referralReasonInfo.referralReasonScreeningToolUsed }}
          </p>
          <p *ngIf="newChild.referralReasonInfo.referralReasonScreeningToolUsed === unknownYesNo.Yes">
            <strong>Name of Tool(s):</strong>
            {{ screeningTools }}
          </p>
        </div>
        <div *ngIf="newChild.referralReasonInfo.referralReasonDiagnosedCondition !== null">
          <p>
            <strong>Diagnosed Condition, Including Vision and Hearing:</strong>
            {{ newChild.referralReasonInfo.referralReasonDiagnosedCondition | nullableBoolToYesNo }}
          </p>

          <p *ngIf="newChild.referralReasonInfo.referralReasonDiagnosedConditions.length > 0">
            <strong>Medically Diagnosed Conditions:</strong><br />
            <span *ngFor="let condition of newChild.referralReasonInfo.referralReasonDiagnosedConditions">
              <strong>{{ condition.icdCode }} <small>(ICD Code)</small></strong>
              - {{ condition.name }}<br />
            </span>
          </p>
        </div>

        <div *ngIf="newChild.referralReasonInfo.referralReasonBornLessThan32Week !== null">
          <p>
            <strong>Child born less than 32 week gestation:</strong>
            {{ newChild.referralReasonInfo.referralReasonBornLessThan32Week | nullableBoolToYesNo }}
          </p>
          <p *ngIf="newChild.referralReasonInfo.referralReasonBornInWeeks">
            <strong>Weeks:</strong>
            {{ newChild.referralReasonInfo.referralReasonBornInWeeks }}
          </p>

          <p *ngIf="newChild.referralReasonInfo.referralReasonBornInDays">
            <strong>Days:</strong>
            {{ newChild.referralReasonInfo.referralReasonBornInDays }}
          </p>
        </div>
        <div *ngIf="newChild.referralReasonInfo.referralReasonBirthWeightLessThan3lb !== null">
          <p>
            <strong>Child's birth weight less than 3lb 5oz (1500g):</strong>
            {{ newChild.referralReasonInfo.referralReasonBirthWeightLessThan3lb | nullableBoolToYesNo }}
          </p>
          <p>
            <strong>Birth Weight:</strong>
            {{ getBirthWeight() }}
          </p>
        </div>
        <div>
          <p *ngIf="newChild.referralReasonInfo.referralReasonAbuseOrNeglect">
            <strong>Substantiated Case of Abuse or Neglect (CAPTA):</strong>
            {{ newChild.referralReasonInfo.referralReasonAbuseOrNeglect | nullableBoolToYesNo }}
          </p>
          <p *ngIf="newChild.referralReasonInfo.referralReasonPIDs">
            <strong>PIDs "Presence of illegal drugs" (CAPTA) Referral:</strong>
            {{ newChild.referralReasonInfo.referralReasonPIDs | nullableBoolToYesNo }}
          </p>
          <p *ngIf="newChild.referralReasonInfo.referralReasonNonCAPTA">
            <strong>Other, non-CAPTA:</strong>
            {{ newChild.referralReasonInfo.referralReasonNonCAPTA | nullableBoolToYesNo }}
          </p>
        </div>
      </div>
    </section>
  </div>
</main>

<div class="page-bottom-leeway"></div>
