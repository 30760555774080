import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-validation-list',
  templateUrl: './page-validation-list.component.html',
  styleUrls: ['./page-validation-list.component.scss'],
})
export class PageValidationListComponent implements OnInit {
  @Input()
  status = 'warning';

  @Input()
  messages: string[];

  constructor() {}

  ngOnInit(): void {}
}
