import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaggedItem, TaggedItemOutput } from './tagged-item';

@Injectable({
  providedIn: 'root',
})
export class TaggingService {
  constructor(private http: HttpClient) {}

  getTag(id: string): Observable<TaggedItem> {
    return this.http.get<TaggedItem>(`api/tagging/${id}`);
  }

  getTagsFor(caseId: string, tagsFor: string): Observable<TaggedItemOutput> {
    return this.http.get<TaggedItemOutput>(`api/tagging/caseId/${caseId}/tagsFor/${tagsFor}`);
  }

  getIfspModificationTag(ifspModificationId: string) {
    return this.http.get<TaggedItem>(`api/tagging/ifspModification/${ifspModificationId}`);
  }

  getIepAmendmentonTag(iepAmendmentId: string) {
    return this.http.get<TaggedItem>(`api/tagging/iepamendment/${iepAmendmentId}`);
  }

  addTag(tag: TaggedItem) {
    return this.http.post<TaggedItem>('api/tagging/', tag);
  }

  updateTag(tag: TaggedItem) {
    return this.http.put<TaggedItem>(`api/tagging/${tag.id}`, tag);
  }

  untag(id: string) {
    return this.http.put<TaggedItem>(`api/tagging/delete/${id}`, null);
  }
}
