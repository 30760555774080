<div class="table-overflow w-100">
  <mat-table [dataSource]="dataSource" class="mb-5" matSort>
    <ng-container matColumnDef="label">
      <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.label }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="sent">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Surveys Sent</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.sent }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="responses">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Responses</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.responses }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="responseRate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Response Rate</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.responseRate }}% </mat-cell>
    </ng-container>

    <ng-container matColumnDef="percentOfAllResponses">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Percent of all Responses</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.percentOfAllResponses }}% </mat-cell>
    </ng-container>

    <ng-container matColumnDef="comparisonToStatePopulation">
      <mat-header-cell *matHeaderCellDef mat-sort-header>State Population</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ !!element.comparisonToStatePopulation ? element.comparisonToStatePopulation + '%' : '' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="difference">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Difference</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ !!element.difference ? element.difference + '%' : '' }} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let element; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
