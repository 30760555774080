import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { EvaluationUserService } from '../../services/evaluation-user.service';

@Component({
  selector: 'app-reassign-facilitator',
  templateUrl: './reassign-facilitator.component.html',
  styleUrls: ['./reassign-facilitator.component.scss'],
})
export class ReassignFacilitatorComponent implements OnInit {
  evaluationId: string;
  ownerId: string;
  teamUsers: KeyValuePair[];
  formGroup = this.fb.group({
    user: ['', Validators.required],
  });
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private evaluationUserService: EvaluationUserService, private fb: FormBuilder) {
    this.evaluationId = data.evaluationId;
    this.ownerId = data.ownerId;
  }

  ngOnInit(): void {
    this.evaluationUserService
      .get(this.evaluationId)
      .subscribe((res) => (this.teamUsers = res.filter((x) => x.userId !== this.ownerId).map((x) => new KeyValuePair(x.userId, x.name))));
  }
}
