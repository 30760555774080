import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProgressMonitorComment } from '../models/comment';

@Injectable({
  providedIn: 'root',
})
export class ProgressMonitorCommentService {
  constructor(private http: HttpClient) {}

  create(learnerId: string, id: string, comment: ProgressMonitorComment) {
    return this.http.post<ProgressMonitorComment>(`api/learners/${learnerId}/progress-monitoring/${id}/graph-notes`, comment);
  }

  update(learnerId: string, id: string, comment: ProgressMonitorComment) {
    return this.http.put<ProgressMonitorComment>(`api/learners/${learnerId}/progress-monitoring/${id}/graph-notes/${comment.id}`, comment);
  }

  delete(learnerId: string, id: string, commentId: string) {
    return this.http.put(`api/learners/${learnerId}/progress-monitoring/${id}/graph-notes/delete/${commentId}`, null);
  }
}
