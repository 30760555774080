import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PseSummary } from 'src/app/iep/iep-plaafp/secondary-transition/models/pse-summary';
import { StringSizes } from 'src/app/shared/components/form/constants/constants';
import { CaseSummary } from 'src/app/shared/models/case';

@Component({
  selector: 'app-post-secondary-summary-llw',
  templateUrl: './post-secondary-summary-llw.component.html',
  styleUrls: ['./post-secondary-summary-llw.component.scss'],
})
export class PostSecondarySummaryLlwComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() caseSummary: CaseSummary;
  @Input() type: string;
  @Input() recentPse: PseSummary;

  skillsSentenceStarters: string[];
  thoughtSentenceStarters: string[];
  ideasSentenceStarters: string[];

  constructor(private renderer: Renderer2) {}

  stringSizes = StringSizes;

  ngOnInit(): void {
    this.getSentenceStarters();
  }

  onSelectedQuestion(value: string, controlName: string) {
    const targetControl = this.formGroup.get(controlName);
    if (value && targetControl.value) {
      targetControl.patchValue(`${targetControl.value} ${value}`);
    } else if (value) {
      targetControl.patchValue(`${value}`);
    }

    // Todo: Figure out a better way to do below:
    // this.renderer.selectRootElement('#' + controlName).focus();
  }

  private getSentenceStarters(): void {
    switch (this.type) {
      case 'living':
        this.skillsSentenceStarters = [
          `The skills ${this.caseSummary.learner.firstName} has to take care of themselves at home are:`,
          `The following supports/services have been helpful for ${this.caseSummary.learner.firstName} as they take care of themselves at home:`,
          `The following supports/services would be the most helpful for ${this.caseSummary.learner.firstName} at home:`,
          `${this.caseSummary.learner.firstName} uses the following services/supports to help navigate in the community:`,
          `The following training/activities/supports would help ${this.caseSummary.learner.firstName} to feel more independent and successful at home and in the community:`,
        ];
        this.ideasSentenceStarters = [
          `${this.caseSummary.learner.firstName}'s next step toward independent living is`,
          'Recommended supports to achieve this next step are',
        ];
        this.thoughtSentenceStarters = [
          'I think I will need this kind of help to be successful in my community',
          'I am nervous when I think about doing the following things on my own',
          'I need help with the following areas for prepare for being engaged in my community',
          'I have experience with these kinds of activities',
          'These experiences have helped me prepare for life after high school',
          'I need these supports to be independent in my home and/or community',
          'I am good at the following things on my own',
          'Clubs and organizations that I’m currently involved with in my community are',
          'I currently volunteer at',
          'I would like to volunteer at',
          'My hobbies are',
          'At school I’m currently involved in',
          'I’d like to learn more about and/or participate in the following extracurricular activities and/or sports',
          'Here’s what I know about my disability and how it affects my everyday life',
          'I am happiest when I',
          'It helps me to complete tasks when',
          'I work best when I have access to',
        ];
        break;
      case 'learning':
        this.skillsSentenceStarters = [
          `${this.caseSummary.learner.firstName}'s disability affects their learning in the following ways:`,
          `The following activities have helped ${this.caseSummary.learner.firstName} to identify education/training goals for after school:`,
          'Inviting the following people to IEP meetings has been helpful:',
          `${this.caseSummary.learner.firstName} needs the following education to achieve the job they desire:`,
          `${this.caseSummary.learner.firstName} used the following accommodations in the past that might help them in the future:`,
          `${this.caseSummary.learner.firstName} learns best when:`,
        ];
        this.ideasSentenceStarters = [
          `${this.caseSummary.learner.firstName}’s next step toward ongoing learning is`,
          'Recommended supports to achieve this next step are',
        ];
        this.thoughtSentenceStarters = [
          'I may need help in these areas to prepare for my future education and job training',
          'I have used the following helpful device in school',
          'I think I will need the following accommodations at school',
          'I need help with the following areas for prepare for work',
          'I have experience with these kinds of work',
          'These experiences have helped me prepare for life after high school',
          'The following activities have helped me at school',
          'I am good at the following things on my own',
          'I have a hard time focusing when',
          'Clubs and organizations that I’m currently involved with in my community are',
          'I currently volunteer at',
          'I would like to volunteer at',
          'My hobbies are',
          'At school I’m currently involved in',
          'I’d like to learn more about and/or participate in the following extracurricular activities and/or sports',
          'I do my best work when I am',
          'It helps me to focus when',
          'I work best when I have access to',
        ];
        break;
      case 'working':
        this.skillsSentenceStarters = [
          `The job ${this.caseSummary.learner.firstName} wants to have after finishing high school is:`,
          `To be successful at their job, ${this.caseSummary.learner.firstName} needs to access the following:`,
          `${this.caseSummary.learner.firstName} has used the following devices or accommodations that have helped them do their job better:`,
          'Inviting the following people to IEP meetings has been helpful:',
        ];
        this.ideasSentenceStarters = [
          `${this.caseSummary.learner.firstName}’s next step toward work is`,
          'Recommended supports to achieve this next step are',
        ];
        this.thoughtSentenceStarters = [
          'I may need help in these areas to prepare for my future education and job training',
          'I have a hard time focusing when',
          'I have used the following helpful device at my job site',
          'Due to my disability, I think I will need this kind of help to be successful at a job',
          'I need help with the following areas for prepare for work',
          'I have experience with these kinds of work',
          'These experiences have helped me prepare for life after high school',
          'I need these supports to be independent in my home and/or community',
          'I am good at the following things on my own',
          'Clubs and organizations that I”m currently involved with in my community are',
          'I currently volunteer at',
          'I would like to volunteer at',
          'My hobbies are',
          'At school I’m currently involved in',
          'I work best in a place that',
          'It helps me to focus when',
          'I work best when I have access to',
        ];
        break;
    }
  }
}
