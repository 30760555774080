<ng-container *ngIf="selectedExitReason">
  <mat-card class="py-2 card-spacing-bottom">
    <mat-card-content>
      <div class="row display-flex justify-content-between">
        <div class="col-md-4">
          <h3 class="mb-0 mt-0 mr-2">
            Learner Exited
            {{ caseSummary?.intakeType === intakeTypeEnum.PartB ? 'Special Education' : 'Early ACCESS' }}
          </h3>
        </div>

        <div class="col-md-4 display-flex align-items-baseline">
          <h3 class="mb-0 mt-0 mr-2">Exit Date:</h3>
          <span>{{ caseSummary.exitDate | dateFormat }}</span>
        </div>

        <div class="col-md-4 display-flex align-items-baseline">
          <h3 class="mb-0 mt-0 mr-2">Reason:</h3>
          <span>{{ selectedExitReason.value }}</span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
