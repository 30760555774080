export enum EvaluationScHelp {
  BackgroundInfo = 'Background Information',
}

export enum EvaluationAdditionalFactorsHelp {
  Sld = 'Specific Learning Disability',
}

export enum EvalAssessmentDetailsHelp {
  Tagged = 'Tagged',
  EcoOther = 'Other',
}

export enum FiieConsentHelp {
  Domains = 'Domain(s)',
}
