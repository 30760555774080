import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { routerAnimation } from 'src/app/learner-management/learner-route-animations';
import { popupChecker } from 'src/app/shared/windowHelpers';
import { MemoryStorageService } from '../../shared/services/memory-storage/memory-storage.service';
import { EvaluationService } from '../services/evaluation.service';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss'],
  animations: [routerAnimation()],
})
export class EvaluationComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  isReady = false;
  isPopup = false;
  errorMessage: string;
  noConsent = 'Consent not provided';

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private evaluationService: EvaluationService,
    public authService: AuthService,
    private readonly storage: MemoryStorageService
  ) {}

  ngOnInit(): void {
    this.isPopup = popupChecker(this.route);
    this.getEvaluation();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.storage.clearKey('currentEvaluation');
  }

  getEvaluation() {
    this.subscriptions.add(
      this.evaluationService.get(this.route.snapshot.params.evaluationId).subscribe(
        (evaluation) => {
          this.storage.setKey('currentEvaluation', evaluation);
          this.isReady = true;
        },
        (response: HttpErrorResponse) => {
          if (response.error?.find((x) => x.description === this.noConsent)) {
            this.errorMessage = this.noConsent;
          } else {
            this.errorMessage = 'Evaluation could not be loaded.';
          }
        }
      )
    );
  }

  public getRouteAnimation(outlet: RouterOutlet) {
    const res = outlet.activatedRouteData.num === undefined ? -1 : outlet.activatedRouteData.num;

    return res;
  }
}
