import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Location } from 'src/app/shared/models/location';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private baseUrl = 'api/locations/';

  constructor(private http: HttpClient) {}

  getCounties(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + 'counties');
  }

  getLocationData(zipCode: string): Observable<Location> {
    return this.http.get<Location>(`${this.baseUrl}${zipCode}`);
  }
}
