<div class="row justify-content-end mt-3">
  <div class="col-1">
    <mat-icon (click)="viewDetails()">remove_red_eye</mat-icon>
  </div>
  <div class="col-11" [formGroup]="form">
    <div class="row align-items-end">
      <div class="col-md-12 col-lg-6">
        <div class="d-flex justify-content-between">
          <strong> {{ label }} </strong>
        </div>
        <app-autocomplete
          label="Frequency"
          formControlName="serviceClpFrequencyId"
          [options]="frequencyDictionary"
          [readOnly]="readOnly"
          [disabledState]="readOnly"
        >
        </app-autocomplete>
      </div>
      <app-textarea
        *ngIf="isFrequencyDescribeFieldRequired"
        class="col-md-12 col-lg-6"
        label="Describe"
        formControlName="frequencyDescription"
        [showAsterisk]="isFrequencyDescribeFieldRequired"
        [readOnly]="readOnly"
        [disabledState]="readOnly"
      >
      </app-textarea>
    </div>
  </div>
</div>
