import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { LoggerService } from '../../services/logger/logger.service';

// noinspection AngularMissingOrInvalidDeclarationInModule
/**
 * @deprecated The goal is to migrate all controls to use the BaseWrapperComponent instead
 * so that we're using ControlValueAccessor
 */
@Component({ template: '' })
export class BaseFieldComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() controlName = 'notBound';
  @Input() extraClasses: string;
  @Input() readOnly = false;
  @Input() placeholder = '';
  @Input() idPrefix = '';
  @Input() disabled = false;
  @Input() showAsterisk = false;
  @Input() showReadOnlyControl = true;
  control: AbstractControl;
  klass = '';
  controlId = '';

  get isControlValid(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }

  constructor(public readonly parentForm: FormGroupDirective, public readonly logger: LoggerService) {}

  ngOnInit() {
    if (!this.parentForm.form) {
      this.parentForm.form = new FormGroup({});
      this.parentForm.form.addControl(this.controlName, new FormControl(''));
      this.logger.warn('text-field: form was not declared');
    }

    // no formControlName passed in. Likely from UI setup.
    if (this.controlName === 'notBound' || !this.parentForm.form.get(this.controlName)) {
      this.parentForm.form.addControl(this.controlName, new FormControl(''));
      this.logger.warn(`text-field: "${this.controlName}" control was not declared or is unbound`);
    }

    this.control = this.parentForm.form.get(this.controlName) || new FormControl();
    if (!this.klass) {
      this.klass = 'form-control';
    }
    if (this.extraClasses) {
      this.klass = `${this.klass} ${this.extraClasses}`;
    }

    this.controlId = this.controlName;
    if (this.idPrefix) {
      this.controlId = `${this.idPrefix}-${this.controlName}`;
    }

    if (this.control) {
      this.checkDisabled(this.disabled);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkDisabled(this.disabled);
  }

  checkDisabled(disable: boolean) {
    if (!this.control) {
      return;
    }
    if (disable && this.control.enabled) {
      this.control.disable();
    } else if (!disable && !this.control.enabled) {
      this.control.enable();
    }
  }
}
