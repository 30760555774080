import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { openNewWindow } from 'src/app/shared/windowHelpers';
import { IDocument } from './document-upload-list.models';

@Component({
  selector: 'app-document-upload-list',
  templateUrl: './document-upload-list.component.html',
  styleUrls: ['./document-upload-list.component.scss'],
})
export class DocumentUploadListComponent implements OnInit, OnChanges {
  @Input() documents: IDocument[];
  @Input() set canDelete(value: boolean) {
    if (value) {
      this.displayedColumns = [...this.displayColumns, 'actions'];
    } else {
      this.displayedColumns = this.displayColumns;
    }
  }
  @Output() deleted = new EventEmitter<string>();

  @Input() requestFiles: boolean;
  @Output() fileRequested = new EventEmitter<IDocument>();

  dataSource: MatTableDataSource<any>;
  private displayColumns = ['friendlyName', 'fileName'];
  displayedColumns = this.displayColumns;

  @ViewChild('verifyDelete') verifyDeleteWarning: TemplateRef<any>;

  constructor(private dialog: MatDialog) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.documents) {
      this.dataSource = new MatTableDataSource(changes.documents.currentValue);
    }
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.documents);
  }

  confirmDeleteDocument(document: IDocument) {
    this.dialog
      .open(this.verifyDeleteWarning, {
        width: '400px',
        data: document,
      })
      .afterClosed()
      .subscribe(() => {});
  }

  getDocument(file) {
    openNewWindow(`documents/generic/docid/${file.id}`);
  }

  onDeleteDocument(document: IDocument) {
    this.deleted.emit(document.id);
  }
}
