import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-esy-nav',
  templateUrl: './esy-nav.component.html',
  styleUrls: ['./esy-nav.component.scss'],
})
export class EsyNavComponent implements OnInit {
  caseId: string;
  iepId: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.caseId = this.route.snapshot.parent.paramMap.get('caseId');
    this.iepId = this.route.snapshot.paramMap.get('iepId');
  }

  getUrl(path: string): string[] {
    return ['/', 'cases', this.caseId, 'iep', this.iepId, 'esy', path];
  }
}
