<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <app-compare-amendment-output
    [amendmentId]="outcome.modificationId"
    [amendmentFinalized]="modificationIsFinalized"
    [amendmentDate]="lastFinalizedDate"
    [amendmentEndReasonValue]="outcome.modificationEndDate"
    [priorVersionId]="outcome?.priorVersionId"
    [isActive]="outcome.isActive"
    [changedVersionText]="outcome.typeOfOutcome"
    [currentVersionText]="outcome?.priorVersion ? outcome?.priorVersion?.typeOfOutcome : null"
    [changedPrefixText]="outcome?.priorVersion ? 'Proposed: ' : ''"
  ></app-compare-amendment-output>
  Outcome<span *ngIf="outcome?.relatedTo">, Transition Related</span>
</h3>
<div mat-dialog-content class="py-3">
  <div>
    <ul class="list mb-0">
      <li class="list__item">
        <p class="my-0" *ngIf="outcome.title">
          <strong class="text-primary"> Outcome Title: </strong>
          <app-compare-amendment-output
            [amendmentId]="outcome.modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="outcome.modificationEndDate"
            [priorVersionId]="outcome.priorVersionId"
            [isActive]="outcome.isActive"
            [changedVersionText]="outcome.title"
            [currentVersionText]="outcome?.priorVersion ? outcome?.priorVersion?.title : null"
            [changedPrefixText]="outcome?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </p>
      </li>
      <li class="list__item" *ngIf="outcome.typeOfOutcome === 'Child' && outcome.ecoAreas && outcome.ecoAreas.length > 0">
        <p class="my-0">
          <strong class="text-primary">ECO Areas:</strong>
          <app-compare-amendment-output
            [amendmentId]="outcome.modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="outcome.modificationEndDate"
            [priorVersionId]="outcome.priorVersionId"
            [isActive]="outcome.isActive"
            [changedVersionText]="getOutcomeAreaText(outcome)"
            [currentVersionText]="outcome?.priorVersion ? getOutcomeAreaText(outcome.priorVersion) : null"
            [changedPrefixText]="outcome?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </p>
      </li>

      <li class="list__item" *ngIf="outcome.typeOfOutcome === 'Family' && outcome.familyAreas && outcome.familyAreas.length > 0">
        <p class="my-0">
          <strong class="text-primary"> Family Outcomes:</strong>
          <app-compare-amendment-output
            [amendmentId]="outcome.modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="outcome.modificationEndDate"
            [priorVersionId]="outcome.priorVersionId"
            [isActive]="outcome.isActive"
            [changedVersionText]="getOutcomeFamilyAreaText(outcome)"
            [currentVersionText]="outcome?.priorVersion ? getOutcomeFamilyAreaText(outcome.priorVersion) : null"
            [changedPrefixText]="outcome?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </p>
      </li>

      <li class="list__item" *ngIf="outcome.strategies && outcome.strategies.length > 0">
        <p class="my-0">
          <strong class="text-primary">Strategies:</strong>
          <app-compare-amendment-output
            [amendmentId]="outcome.modificationId"
            [amendmentFinalized]="modificationIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [amendmentEndReasonValue]="outcome.modificationEndDate"
            [priorVersionId]="outcome.priorVersionId"
            [isActive]="outcome.isActive"
            [changedVersionText]="getStrategiesText(outcome)"
            [currentVersionText]="outcome?.priorVersion ? getStrategiesText(outcome.priorVersion) : null"
            [changedPrefixText]="outcome?.priorVersion ? 'Proposed: ' : ''"
          ></app-compare-amendment-output>
        </p>
      </li>
    </ul>

    <ng-container *ngIf="outcome.criterias && outcome.criterias.length > 0">
      <hr class="input__divider mt-1 mb-2" />

      <h3 class="mt-0 mb-2">Criteria</h3>
      <div>
        <ul class="list">
          <li class="list__item" *ngFor="let criteria of outcome.criterias; let i = index">
            <h4 class="text-primary mt-0 mb-1">
              <app-compare-amendment-output
                [amendmentId]="outcome.modificationId"
                [amendmentFinalized]="modificationIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [amendmentEndReasonValue]="outcome.modificationEndDate"
                [priorVersionId]="outcome.priorVersionId"
                [isActive]="criteria?.isActive"
                [changedVersionText]="criteria?.criteriaForAccomplishment"
                [currentVersionText]="
                  criteria?.priorVersionId ? getCriteriaPriorVersion(criteria?.priorVersionId)?.criteriaForAccomplishment : null
                "
                [changedPrefixText]="criteria?.priorVersionId ? 'Proposed: ' : ''"
              ></app-compare-amendment-output>
            </h4>
            <p class="my-0">
              <app-compare-amendment-output
                [amendmentId]="outcome.modificationId"
                [amendmentFinalized]="modificationIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [amendmentEndReasonValue]="outcome.modificationEndDate"
                [priorVersionId]="outcome.priorVersionId"
                [isActive]="criteria?.isActive"
                [changedVersionText]="getCriteriaText(criteria)"
                [currentVersionText]="criteria?.priorVersionId ? getCriteriaText(getCriteriaPriorVersion(criteria?.priorVersionId)) : null"
                [changedPrefixText]="criteria?.priorVersionId ? 'Proposed: ' : ''"
              ></app-compare-amendment-output>
            </p>
            <div class="my-0">
              <div *ngIf="criteria?.quantifiableData.trials.length === 0 && criteria?.measurementType === 'numeric'">
                <label>Baseline:</label>
                <span *ngIf="!modificationId">
                  {{ criteria?.quantifiableData.baseline === 0 ? 0 : criteria?.quantifiableData.baseline || '-' }}
                </span>
                <app-compare-amendment-output
                  *ngIf="modificationId"
                  [amendmentId]="modificationId"
                  [priorVersionId]="criteria?.priorVersionId"
                  [isActive]="criteria?.isActive"
                  [changedVersionText]="criteria?.quantifiableData?.baseline"
                  [currentVersionText]="getCriteriaPriorVersion(criteria?.priorVersionId)?.quantifiableData.baseline"
                  [currentPrefixText]="criteria?.priorVersionId ? 'Current: ' : ''"
                  changedPrefixText="Proposed: "
                ></app-compare-amendment-output>
                <br />
              </div>
              <div class="row" *ngIf="criteria?.quantifiableData.trials.length > 0">
                <div class="col-md-12">
                  <h3>Trials</h3>
                  <ng-container *ngIf="criteria?.quantifiableData.trials.length > 0">
                    <div *ngFor="let trial of criteria?.quantifiableData.trials">
                      <strong>{{ trial.score || '-' }}: </strong>{{ (trial.date | dateFormat) || '-' }}<br />
                    </div>
                    <label>Baseline:</label>
                    {{ criteria?.quantifiableData.baseline || '-' }}
                  </ng-container>
                </div>
              </div>
              <div
                class="row"
                *ngIf="criteria?.quantifiableData.milestones.length > 0 || criteria?.quantifiableData.scaleRatings.length > 0"
              >
                <div class="col-md-6" *ngIf="criteria?.quantifiableData.milestones.length > 0">
                  <h3>Milestones</h3>
                  <div *ngFor="let milestone of getCriterionMilestones(criteria?.quantifiableData.milestones)">
                    <label>Completion Date: </label>
                    {{ (milestone.completionDate | dateFormat) || '-' }}<br />
                    <label>Description / Notes: </label>
                    {{ milestone.benchmarkObjectiveMilestone || '-' }}<br />
                    <label>Value:</label>
                    {{ milestone.value === 0 ? 0 : milestone.value || '-' }}
                  </div>
                </div>
                <div class="col-md-6" *ngIf="criteria?.quantifiableData.scaleRatings.length > 0">
                  <h3>Scale</h3>
                  <div class="table-overflow app-table">
                    <table mat-table [dataSource]="sources[i]" matSortActive="rating" matSortDirection="asc" class="w-100" matSort>
                      <ng-container matColumnDef="rating">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Rating</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element?.rating === 0 ? 0 : element?.rating || '-' }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                        <td mat-cell *matCellDef="let element">
                          {{ element?.description || '-' }}
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                      <tr
                        [hidden]="sources[i]?.data?.length > 0"
                        mat-footer-row
                        *matFooterRowDef="sources[i].data?.length === 0 ? ['noResults'] : []"
                      ></tr>
                    </table>
                    <mat-paginator [dataSource]="sources[i]"></mat-paginator>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6" *ngIf="criteria?.measurementType === 'numeric'">
                  <label>Target Value: </label>
                  <span *ngIf="!modificationId">
                    {{ criteria?.quantifiableData.performanceMin || '-' }}
                  </span>
                  <app-compare-amendment-output
                    *ngIf="modificationId"
                    [amendmentId]="modificationId"
                    [priorVersionId]="criteria?.priorVersionId"
                    [isActive]="criteria?.isActive"
                    [changedVersionText]="criteria?.quantifiableData.performanceMin"
                    [currentVersionText]="getCriteriaPriorVersion(criteria?.priorVersionId)?.quantifiableData.performanceMin"
                    [currentPrefixText]="criteria?.priorVersionId ? 'Current: ' : ''"
                    changedPrefixText="Proposed: "
                  ></app-compare-amendment-output>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf="outcome.timelineNumber || outcome.timelinePeriod || outcome.outcomeEndDate">
      <hr class="input__divider mt-1 mb-2" />
      <h3 class="mt-0 mb-2">Timeline</h3>
      <ul class="list">
        <li class="list__item" *ngIf="outcome.timelineNumber">
          <p class="my-0">
            <strong class="text-primary">How long:</strong>
            <app-compare-amendment-output
              [amendmentId]="outcome.modificationId"
              [amendmentFinalized]="modificationIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [amendmentEndReasonValue]="outcome.modificationEndDate"
              [priorVersionId]="outcome.priorVersionId"
              [isActive]="outcome.isActive"
              [changedVersionText]="outcome.timelineNumber"
              [currentVersionText]="outcome?.priorVersion ? outcome.priorVersion.timelineNumber : null"
              [changedPrefixText]="outcome?.priorVersion ? 'Proposed: ' : ''"
            ></app-compare-amendment-output>
          </p>
        </li>
        <li class="list__item" *ngIf="outcome.timelinePeriod">
          <p class="my-0">
            <strong class="text-primary">Time Period:</strong>
            <app-compare-amendment-output
              [amendmentId]="outcome.modificationId"
              [amendmentFinalized]="modificationIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [amendmentEndReasonValue]="outcome.modificationEndDate"
              [priorVersionId]="outcome.priorVersionId"
              [isActive]="outcome.isActive"
              [changedVersionText]="outcome.timelinePeriod"
              [currentVersionText]="outcome?.priorVersion ? outcome.priorVersion.timelinePeriod : null"
              [changedPrefixText]="outcome?.priorVersion ? 'Proposed: ' : ''"
            ></app-compare-amendment-output>
          </p>
        </li>
        <li class="list__item" *ngIf="outcome.outcomeEndDate">
          <p class="my-0">
            <strong class="text-primary">Outcome End Date:</strong>
            <app-compare-amendment-output
              [amendmentId]="outcome.modificationId"
              [amendmentFinalized]="modificationIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [amendmentEndReasonValue]="outcome.modificationEndDate"
              [priorVersionId]="outcome.priorVersionId"
              [isActive]="outcome.isActive"
              [changedVersionText]="getOutcomeEndDateText(outcome)"
              [currentVersionText]="outcome?.priorVersion ? getOutcomeEndDateText(outcome.priorVersion) : null"
              [changedPrefixText]="outcome?.priorVersion ? 'Proposed: ' : ''"
            ></app-compare-amendment-output>
          </p>
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="outcome.progresses && outcome.progresses.length > 0">
      <hr class="input__divider mt-1 mb-2" />

      <h3 class="mt-0 mb-2">Monitoring for Progress</h3>
      <ul class="list">
        <li class="list__item" *ngFor="let progress of outcome.progresses">
          <p class="my-0">
            <app-compare-amendment-output
              [amendmentId]="outcome.modificationId"
              [amendmentFinalized]="modificationIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [amendmentEndReasonValue]="outcome.modificationEndDate"
              [priorVersionId]="outcome.priorVersionId"
              [isActive]="progress.isActive"
              [changedVersionText]="getMonitoringProgressText(progress)"
              [currentVersionText]="
                progress.priorVersionId
                  ? getMonitoringProgressText(getProgressPriorVersion(outcome.priorVersion, progress.priorVersionId))
                  : null
              "
              [changedPrefixText]="progress.priorVersionId ? 'Proposed: ' : ''"
            ></app-compare-amendment-output>
          </p>
        </li>
      </ul>
    </ng-container>
  </div>
</div>
<div mat-dialog-actions class="actions display-flex justify-content-end">
  <div>
    <button mat-button aria-label="Delete Icon" (click)="dismiss()" cdkFocusInitial tabindex="0">Close</button>
  </div>
</div>
