import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { UpdateConsentFormComponent } from 'src/app/consent/family-consent/update-consent-form/update-consent-form.component';
import { CaseSummaryByIfspId } from 'src/app/shared/models/case';
import { ConsentForm, consentFormTitlesByType, ConsentFormType } from 'src/app/shared/models/fiie-consent/consent-form';
import { ConsentFormService } from '../../../../shared/services/consent-form/consent-form.service';
import { FamilyConsentStatus } from '../../../../shared/models/fiie-consent/family-consent-status';

@Component({
  selector: 'app-ifsp-consents',
  templateUrl: './ifsp-consents.component.html',
  styleUrls: ['./ifsp-consents.component.scss'],
})
export class IfspConsentsComponent implements OnInit {
  @Input() ifspId: string;
  @Input() isModification = false;
  @Input() caseSummary: CaseSummaryByIfspId;
  @Input() psgProvided: boolean;
  @Input() maxAmount: number;
  @Input() needsNewConsent: boolean;

  consentFormTypeEnum = ConsentFormType;
  isCaseOwner: boolean;
  types = [ConsentFormType.EarlyAccessServices];
  showCreateInitialIfspServicesConsent: boolean;
  showCreateNewIfspServicesConsent: boolean;

  constructor(
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly consentFormService: ConsentFormService
  ) {}

  ngOnInit(): void {
    this.isCaseOwner = this.caseSummary.caseOwnerId === this.authService.user.id;
  }

  onConsentFormsLoaded(consentForms: ConsentForm[]) {
    const initialIfspServicesForm = this.consentFormService.getLastConsentForm(consentForms, ConsentFormType.EarlyAccessServices);
    const status = initialIfspServicesForm ? this.consentFormService.getLastStatus(initialIfspServicesForm).status : undefined;
    this.showCreateInitialIfspServicesConsent =
      !initialIfspServicesForm || this.needsNewConsent || status === FamilyConsentStatus.Inactive || status === FamilyConsentStatus.Revoked;

    this.showCreateNewIfspServicesConsent = !consentForms.some((cf) => cf.type === ConsentFormType.EarlyAccessServices);
  }

  createConsentForIfspServices(formType: ConsentFormType): void {
    this.dialog.open(UpdateConsentFormComponent, {
      width: '400px',
      data: {
        caseId: this.caseSummary.caseId,
        title: consentFormTitlesByType[formType],
        ifspId: this.ifspId,
        consentFormType: formType,
      },
    });
  }
}
