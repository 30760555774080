<h3 class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Reason for Editing</h3>

<div [formGroup]="formGroup" class="mat-dialog-content py-2">
  <mat-card>
    <p>What is the reason for changing {{ data.type }}?</p>
    <p>
      <label
        class="vertical-label display-block"
        [ngClass]="{
          asterisk_input: formGroup.get('reason').hasError('required')
        }"
      >
        Reason
      </label>
      <app-textarea formControlName="reason" [hideAsterisk]="true" [rows]="4" [maxLength]="stringSizes.extraLarge"> </app-textarea>
    </p>
  </mat-card>
</div>

<div class="mat-dialog-actions justify-content-end">
  <button mat-raised-button aria-label="Delete Icon" (click)="onClose()" tabindex="0">Close</button>
  <button mat-raised-button color="primary" aria-label="Submit" (click)="onSubmit()" cdkFocusInitial tabindex="1">Submit</button>
</div>
