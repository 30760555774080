import { CaseClosure } from './../../models/case-closure';
import { CaseService } from './../../services/case/case.service';
import { CloseCaseDialogComponent } from './close-case-dialog/close-case-dialog.component';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-close-case-button',
  templateUrl: './close-case-button.component.html',
  styleUrls: ['./close-case-button.component.scss'],
})
export class CloseCaseButtonComponent implements OnInit {
  @Input() caseId: string;

  constructor(private dialog: MatDialog, private readonly caseService: CaseService, private router: Router) {}

  ngOnInit(): void {}

  openCloseCaseDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    // TODO: Blaze to decide the right size / styling, as the reasons are long strings.
    dialogConfig.width = '928px';

    const dialogRef = this.dialog.open(CloseCaseDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((response) => {
      if (response?.closeCase) {
        this.closeCase(this.caseId, response.caseClosureReasonId);
      }
    });
  }

  closeCase(caseId: string, caseClosureReasonId: string) {
    const caseToClose: CaseClosure = {
      caseId,
      caseClosureReasonId,
    };
    this.caseService.closeCase(caseToClose).subscribe(() => {
      this.router.navigate(['/']);
    });
  }
}
