import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IepGoalsViewMoreModalData {}

@Component({
  selector: 'app-iep-goals-view-more',
  templateUrl: './iep-goals-view-more.component.html',
  styleUrls: ['./iep-goals-view-more.component.scss'],
})
export class IepGoalsViewMoreComponent implements OnInit {
  goal: any;
  amendment: any;

  constructor(private dialogRef: MatDialogRef<IepGoalsViewMoreComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.goal = this.data.goal;
    this.amendment = this.data.amendment;
  }

  getPriorPrimaryMilestone() {
    return this.goal.priorVersion.primaryMilestone[0];
  }

  getPriorAdditionalMilestone(id) {
    return this.goal.priorVersion.additionalMilestones.find((x) => x.id === id);
  }

  onClose() {
    this.dialogRef.close();
  }
}
