import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeObsoleteTerms',
})
export class RemoveObsoleteTermsPipe implements PipeTransform {
  private regex: RegExp;
  private dict = ['output'];

  transform(content: string): string {
    this.dict.forEach((term) => {
      this.regex = new RegExp(term, 'gi');
      const modified = content.replace(this.regex, '');
      content = modified;
    });
    return content;
  }
}
