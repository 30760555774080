import { Component, Input, OnInit } from '@angular/core';
import { ValidationService } from '../../services/validation.service';

export type AlertConfig = {
  status: 'info' | 'warning' | 'success';
  messages: string[];
};

@Component({
  selector: 'app-page-validation',
  templateUrl: './page-validation.component.html',
  styleUrls: ['./page-validation.component.scss'],
})
export class PageValidationComponent implements OnInit {
  alert: AlertConfig = {
    status: 'warning',
    messages: [],
  };

  constructor(validationService: ValidationService) {
    validationService.onValidationError.subscribe((validationErrors: string[]) => {
      this.alert.messages = [];
      if (Array.isArray(validationErrors)) {
        validationErrors.forEach((m) => {
          this.alert.messages.push(m);
        });
      } else {
        this.alert.messages.push(validationErrors);
      }
    });

    validationService.onClearValidationErrors.subscribe((data) => {
      this.alert.messages = [];
    });
  }

  ngOnInit(): void {}
}
