<div *ngIf="incompleteItems" class="table-overflow app-table">
  <table class="w-100" mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="cell--actions"></th>
      <td mat-cell *matCellDef="let element" class="cell--actions">
        <button
          color="primary"
          (click)="navigateToAction(element)"
          *ngIf="element.action"
          mat-flat-button
          aria-label="Resolve"
          tabindex="0"
        >
          Resolve
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="notices">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl-3">Notices</th>
      <td mat-cell *matCellDef="let element" class="pl-3">
        {{ element.notice | punctuation }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
<p *ngIf="!dataSource?.data">No data yet...</p>
