<section class="display-flex align-items-center">
  <button
    mat-raised-button
    color="primary"
    class="mr-2"
    aria-labelledby="Download IEP PDF"
    (click)="onViewDraftIep()"
    target="blank"
    tabindex="0"
    role="link"
    [isBusy]="generatingDraftIep"
  >
    View New IEP
  </button>
  <button
    [disabled]="!canFinalize"
    [disableAfterBusy]="!canFinalize"
    *ngIf="!finalized && canFinalizeIep"
    mat-raised-button
    aria-label="Finalize IEP"
    color="primary"
    class="mr-2"
    (click)="finalizeIep()"
    tabindex="0"
    [isBusy]="activeCall"
  >
    Finalize IEP
  </button>
  <button
    mat-raised-button
    attr.aria-label="{{ pwnId ? 'Complete' : 'Create' }} Prior Written Notice"
    color="primary"
    (click)="openPWNPopUp()"
    tabindex="0"
  >
    {{ pwnId ? 'Complete' : 'Create' }} Prior Written Notice
  </button>
</section>
