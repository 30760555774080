import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PartCConsentEvalComponent } from '../shared/components/next-steps-form/part-c-consent-eval/part-c-consent-eval.component';
import { PartCConsentEvalResolver } from '../shared/components/next-steps-form/part-c-consent-eval/part-c-consent-eval.resolver';
import { EarlyAccessIntakeComponent } from './early-access-intake.component';
import { PartCConsentPostrefScreeningComponent } from '../shared/components/next-steps-form/part-c-consent-postref-screening/part-c-consent-postref-screening.component';
import { PartCConsentPostrefScreeningResolver } from '../shared/components/next-steps-form/part-c-consent-postref-screening/part-c-consent-postref-screening.resolver';
import { PartCConsentPostrefScreeningResultComponent } from '../shared/components/next-steps-form/part-c-consent-postref-screening-result/part-c-consent-postref-screening-result.component';
import { PartCConsentPostrefScreeningResultResolver } from '../shared/components/next-steps-form/part-c-consent-postref-screening-result/part-c-consent-postref-screening-result.resolver';
import { GenericPermissionsGuard } from 'src/app/auth/auth.guard';

const routes: Routes = [
  {
    path: ':caseId/partc-eval-consent',
    component: PartCConsentEvalComponent,
    resolve: {
      intake: PartCConsentEvalResolver,
    },
  },
  {
    path: ':caseId/partc-postref-screening-result',
    component: PartCConsentPostrefScreeningResultComponent,
    resolve: {
      intake: PartCConsentPostrefScreeningResultResolver,
    },
  },
  {
    path: ':caseId/partc-postref-consent',
    component: PartCConsentPostrefScreeningComponent,
    resolve: {
      intake: PartCConsentPostrefScreeningResolver,
    },
  },
  {
    path: ':caseId',
    canActivate: [GenericPermissionsGuard],
    data: { permission: 'ViewIntake' },
    component: EarlyAccessIntakeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EarlyAccessIntakeRoutingModule {}
