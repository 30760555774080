import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Intake } from 'src/app/evaluation/models/intake';
import { IntakeService } from 'src/app/evaluation/services/intake.service';

@Injectable({
  providedIn: 'root',
})
export class PartCConsentEvalResolver implements Resolve<Intake> {
  constructor(private readonly intakeService: IntakeService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return this.intakeService.getIntake(route.paramMap.get('caseId'));
  }
}
