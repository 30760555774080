import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MeetingParticipantRead } from '../../family-meeting/participants-list/meeting-participants';
import { EarlyChildhoodProgram, EarlyChildhoodProgramType } from './ec-programs';

@Injectable({
  providedIn: 'root',
})
export class EcProgramsService {
  constructor(private httpClient: HttpClient) {}

  getTypes(learnerId: string) {
    return this.httpClient.get<EarlyChildhoodProgramType[]>(`${this.baseUrl(learnerId)}/types`);
  }

  getPrograms(learnerId: string) {
    return this.httpClient.get<EarlyChildhoodProgram[]>(`${this.baseUrl(learnerId)}`);
  }

  save(program: EarlyChildhoodProgram) {
    if (program.id) {
      return this.httpClient.put<void>(`${this.baseUrl(program.learnerId)}/${program.id}`, program);
    } else {
      return this.httpClient.post<void>(`${this.baseUrl(program.learnerId)}/`, program);
    }
  }

  remove(program: EarlyChildhoodProgram) {
    return this.httpClient.put<void>(`${this.baseUrl(program.learnerId)}/delete/${program.id}`, null);
  }

  getContacts(learnerId: string) {
    return this.httpClient.get<MeetingParticipantRead[]>(`${this.baseUrl(learnerId)}/contacts`);
  }

  private baseUrl(learnerId: string) {
    return `api/learners/${learnerId}/early-childhood-programs`;
  }
}
