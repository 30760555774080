<form [formGroup]="formGroup">
  <mat-card *ngIf="shownFields.canDisplayFamilyInfo" aria-live="polite">
    <mat-card-title>Parent Information</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg-4">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Name (first and last)"
            formControlName="parent1Name"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Name (first and last)"
            formControlName="parent1Name"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>

        <div class="col-lg-3">
          <app-text-field [disabledState]="locked" *ngIf="isQuestionnaire" label="Email" formControlName="parent1Email"></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Email"
            formControlName="parent1Email"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>

        <div class="col-lg">
          <div class="vertical-control">
            <fieldset>
              <app-radio-group
                [disabledState]="locked"
                [useOverwrite]="!isQuestionnaire"
                [options]="yesNoOptions"
                formControlName="parent1LivesWithChild"
                label="Lives With Child"
                [sourceData]="questionnaire.familyInfo"
              ></app-radio-group>
            </fieldset>
          </div>
        </div>
      </div>

      <!-- Parent1 Address -->
      <div class="row">
        <div class="col-lg-4">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Street Address"
            formControlName="parent1StreetAddress"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Street Address"
            formControlName="parent1StreetAddress"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Zip Code"
            formControlName="parent1ZipCode"
            (blurMe)="updateAddressFromZipCode('parent1')"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Zip Code"
            formControlName="parent1ZipCode"
            (blurMe)="updateAddressFromZipCode('parent1')"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field [disabledState]="locked" *ngIf="isQuestionnaire" label="City" formControlName="parent1City"></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="City"
            formControlName="parent1City"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-2">
          <app-autocomplete
            [disabledState]="locked"
            [useOverwrite]="!isQuestionnaire"
            label="State"
            formControlName="parent1State"
            [options]="usStateOptions"
            [sourceData]="questionnaire.familyInfo"
          ></app-autocomplete>
        </div>
      </div>

      <!-- Parent1 Phones -->
      <div class="row">
        <div class="col-lg">
          <app-masked-input
            [disabledState]="locked"
            label="Home Phone Number"
            formControlName="parent1HomePhone"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.familyInfo"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input
            [disabledState]="locked"
            label="Work Phone Number"
            formControlName="parent1WorkPhone"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.familyInfo"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input
            [disabledState]="locked"
            label="Cell Phone Number"
            formControlName="parent1CellPhone"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.familyInfo"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-lg">
          <mat-error *ngIf="formGroup.controls.parent1BestWayToReachHomePhone.value && !formGroup.controls.parent1HomePhone.value">
            Home phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent1BestWayToReachWorkPhone.value && !formGroup.controls.parent1WorkPhone.value">
            Work phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent1BestWayToReachCellPhone.value && !formGroup.controls.parent1CellPhone.value">
            Cell phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent1BestWayToReachText.value && !formGroup.controls.parent1CellPhone.value">
            Cell phone number is <strong>required</strong> if texting is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent1BestWayToReachEmail.value && !formGroup.controls.parent1Email.value">
            Email is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.hasError('atLeastOnePhoneRequired')">
            At least one phone number or email address is
            <strong>required</strong>
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <div class="vertical-control">
            <label class="text-md vertical-label" for="parent1BestTimeToContact">Best Way to Contact</label>
            <div>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Home"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="parent1BestWayToReachHomePhone"
                label="Home"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Work"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="parent1BestWayToReachWorkPhone"
                label="Work"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Cell"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="parent1BestWayToReachCellPhone"
                label="Cell"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Email"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="parent1BestWayToReachEmail"
                label="Email"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Texting"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="parent1BestWayToReachText"
                label="Texting"
              >
              </app-checkbox-single>
              <app-text-field
                [disabledState]="locked"
                *ngIf="isQuestionnaire"
                label="Best Time To Contact"
                id="parent1BestTimeToContact"
                formControlName="parent1BestTimeToContact"
              ></app-text-field>
            </div>

            <div>
              <app-checkbox-single
                *ngIf="!isQuestionnaire"
                [disabledState]="locked"
                [useOverwrite]="true"
                aria-labelledby="Home"
                label="Home"
                formControlName="parent1BestWayToReachHomePhone"
                [sourceData]="questionnaire.familyInfo"
              ></app-checkbox-single>

              <app-checkbox-single
                *ngIf="!isQuestionnaire"
                [useOverwrite]="true"
                [disabledState]="locked"
                aria-labelledby="Work"
                label="Work"
                formControlName="parent1BestWayToReachWorkPhone"
                [sourceData]="questionnaire.familyInfo"
              ></app-checkbox-single>

              <app-checkbox-single
                *ngIf="!isQuestionnaire"
                [useOverwrite]="true"
                [disabledState]="locked"
                aria-labelledby="Cell"
                label="Cell"
                formControlName="parent1BestWayToReachCellPhone"
                [sourceData]="questionnaire.familyInfo"
              ></app-checkbox-single>

              <app-checkbox-single
                *ngIf="!isQuestionnaire"
                [useOverwrite]="true"
                [disabledState]="locked"
                aria-labelledby="Email"
                label="Email"
                formControlName="parent1BestWayToReachEmail"
                [sourceData]="questionnaire.familyInfo"
              ></app-checkbox-single>

              <app-checkbox-single
                *ngIf="!isQuestionnaire"
                [useOverwrite]="true"
                [disabledState]="locked"
                aria-labelledby="Texting"
                label="Texting"
                formControlName="parent1BestWayToReachText"
                [sourceData]="questionnaire.familyInfo"
              ></app-checkbox-single>

              <app-overwrite-text-field
                [useOverwrite]="true"
                [disabledState]="locked"
                *ngIf="!isQuestionnaire"
                label="Best Time To Contact"
                formControlName="parent1BestTimeToContact"
                [sourceData]="questionnaire.familyInfo"
              ></app-overwrite-text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top" *ngIf="shownFields.canDisplayFamilyInfo" aria-live="polite">
    <mat-card-title>Parent Information</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg-4">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Name (first and last)"
            formControlName="parent2Name"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Name (first and last)"
            formControlName="parent2Name"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>

        <div class="col-lg-3">
          <app-text-field [disabledState]="locked" *ngIf="isQuestionnaire" label="Email" formControlName="parent2Email"></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Email"
            formControlName="parent2Email"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>

        <div class="col-lg">
          <div class="display-flex justify-between align-items-center">
            <fieldset>
              <app-radio-group
                [disabledState]="locked"
                [useOverwrite]="!isQuestionnaire"
                [options]="yesNoOptions"
                formControlName="parent2LivesWithChild"
                label="Lives With Child"
                [sourceData]="questionnaire.familyInfo"
              ></app-radio-group>
            </fieldset>
          </div>
        </div>
      </div>

      <!-- Parent2 Address -->
      <div class="row">
        <div class="col-lg-4">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Street Address"
            formControlName="parent2StreetAddress"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Street Address"
            formControlName="parent2StreetAddress"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Zip Code"
            formControlName="parent2ZipCode"
            (blurMe)="updateAddressFromZipCode('parent2')"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Zip Code"
            formControlName="parent2ZipCode"
            (blurMe)="updateAddressFromZipCode('parent2')"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field [disabledState]="locked" *ngIf="isQuestionnaire" label="City" formControlName="parent2City"></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="City"
            formControlName="parent2City"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-2">
          <app-autocomplete
            [disabledState]="locked"
            [useOverwrite]="!isQuestionnaire"
            label="State"
            formControlName="parent2State"
            [options]="usStateOptions"
            [sourceData]="questionnaire.familyInfo"
          ></app-autocomplete>
        </div>
      </div>

      <div class="row">
        <div class="col-lg">
          <app-masked-input
            [disabledState]="locked"
            label="Home Phone Number"
            formControlName="parent2HomePhone"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.familyInfo"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input
            [disabledState]="locked"
            label="Work Phone Number"
            formControlName="parent2WorkPhone"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.familyInfo"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input
            [disabledState]="locked"
            label="Cell Phone Number"
            e
            formControlName="parent2CellPhone"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.familyInfo"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-lg">
          <mat-error *ngIf="formGroup.controls.parent2BestWayToReachHomePhone.value && !formGroup.controls.parent2HomePhone.value">
            Home phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent2BestWayToReachWorkPhone.value && !formGroup.controls.parent2WorkPhone.value">
            Work phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent2BestWayToReachCellPhone.value && !formGroup.controls.parent2CellPhone.value">
            Cell phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent2BestWayToReachText.value && !formGroup.controls.parent2CellPhone.value">
            Cell phone number is <strong>required</strong> if texting is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent2BestWayToReachEmail.value && !formGroup.controls.parent2Email.value">
            Email is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.hasError('atLeastOnePhoneRequired')">
            At least one phone number or email address is
            <strong>required</strong>
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <div class="vertical-control">
            <label class="text-md vertical-label" for="parent2BestTimeToContact">Best Way to Contact</label>
            <div>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Home"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="parent2BestWayToReachHomePhone"
                label="Home"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Work"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="parent2BestWayToReachWorkPhone"
                label="Work"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Cell"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="parent2BestWayToReachCellPhone"
                label="Cell"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Email"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="parent2BestWayToReachEmail"
                label="Email"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Texting"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="parent2BestWayToReachText"
                label="Texting"
              >
              </app-checkbox-single>
              <app-text-field
                [disabledState]="locked"
                *ngIf="isQuestionnaire"
                label="Best Time To Contact"
                formControlName="parent2BestTimeToContact"
              ></app-text-field>

              <div>
                <app-checkbox-single
                  *ngIf="!isQuestionnaire"
                  [useOverwrite]="true"
                  [disabledState]="locked"
                  aria-labelledby="Home"
                  label="Home"
                  formControlName="parent2BestWayToReachHomePhone"
                  [sourceData]="questionnaire.familyInfo"
                ></app-checkbox-single>

                <app-checkbox-single
                  *ngIf="!isQuestionnaire"
                  [useOverwrite]="true"
                  [disabledState]="locked"
                  aria-labelledby="Work"
                  label="Work"
                  formControlName="parent2BestWayToReachWorkPhone"
                  [sourceData]="questionnaire.familyInfo"
                ></app-checkbox-single>

                <app-checkbox-single
                  *ngIf="!isQuestionnaire"
                  [useOverwrite]="true"
                  [disabledState]="locked"
                  aria-labelledby="Cell"
                  label="Cell"
                  formControlName="parent2BestWayToReachCellPhone"
                  [sourceData]="questionnaire.familyInfo"
                ></app-checkbox-single>

                <app-checkbox-single
                  *ngIf="!isQuestionnaire"
                  [useOverwrite]="true"
                  [disabledState]="locked"
                  aria-labelledby="Email"
                  label="Email"
                  formControlName="parent2BestWayToReachEmail"
                  [sourceData]="questionnaire.familyInfo"
                ></app-checkbox-single>

                <app-checkbox-single
                  *ngIf="!isQuestionnaire"
                  [useOverwrite]="true"
                  [disabledState]="locked"
                  aria-labelledby="Texting"
                  label="Texting"
                  formControlName="parent2BestWayToReachText"
                  [sourceData]="questionnaire.familyInfo"
                ></app-checkbox-single>

                <app-overwrite-text-field
                  [useOverwrite]="true"
                  [disabledState]="locked"
                  *ngIf="!isQuestionnaire"
                  label="Best Time To Contact"
                  formControlName="parent2BestTimeToContact"
                  [sourceData]="questionnaire.familyInfo"
                ></app-overwrite-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top" *ngIf="shownFields.canDisplayFamilyInfo" aria-live="polite">
    <mat-card-title>Others in Home</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg">
          <label class="vertical-label" for="othersInHome"
            >Please describe the other people in the home. It is helpful to include each person's name, age, and relationship to the
            child.</label
          >
          <app-textarea [disabledState]="locked" *ngIf="isQuestionnaire" label="" formControlName="othersInHome"></app-textarea>
          <app-overwrite-textarea
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label=""
            id="othersInHome"
            formControlName="othersInHome"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-textarea>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top" *ngIf="shownFields.canDisplayFamilyInfo">
    <mat-card-title>Lives With Information</mat-card-title>

    <app-help-directional-text>
      <p class="mb-2">
        If child does not live with any parent listed above, complete information below for the person the child lives with.
      </p>
    </app-help-directional-text>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg-4">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Name (first and last)"
            formControlName="livesWith1Name"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Name (first and last)"
            formControlName="livesWith1Name"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>

        <div class="col-lg-3">
          <app-text-field [disabledState]="locked" *ngIf="isQuestionnaire" label="Email" formControlName="livesWith1Email"></app-text-field>
          <app-overwrite-text-field
            *ngIf="!isQuestionnaire"
            [disabledState]="locked"
            label="Email"
            formControlName="livesWith1Email"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>

        <div class="col-md-3">
          <app-autocomplete
            [disabledState]="locked"
            [useOverwrite]="!isQuestionnaire"
            [options]="relationshipOptions"
            formControlName="livesWith1RelationshipId"
            label="Relationship"
            [sourceData]="questionnaire.familyInfo"
          ></app-autocomplete>
        </div>
      </div>
      <!-- LivesWith1 Address -->
      <div class="row">
        <div class="col-lg-4">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Street Address"
            formControlName="livesWith1StreetAddress"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Street Address"
            formControlName="livesWith1StreetAddress"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Zip Code"
            formControlName="livesWith1ZipCode"
            (blurMe)="updateAddressFromZipCode('livesWith1')"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Zip Code"
            formControlName="livesWith1ZipCode"
            (blurMe)="updateAddressFromZipCode('livesWith1')"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field [disabledState]="locked" *ngIf="isQuestionnaire" label="City" formControlName="livesWith1City"></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="City"
            formControlName="livesWith1City"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-2">
          <app-autocomplete
            [disabledState]="locked"
            [useOverwrite]="!isQuestionnaire"
            label="State"
            formControlName="livesWith1State"
            [options]="usStateOptions"
            [sourceData]="questionnaire.familyInfo"
          ></app-autocomplete>
        </div>
      </div>

      <div class="row">
        <div class="col-lg">
          <app-masked-input
            [disabledState]="locked"
            label="Home Phone Number"
            formControlName="livesWith1HomePhone"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.familyInfo"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input
            [disabledState]="locked"
            label="Work Phone Number"
            formControlName="livesWith1WorkPhone"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.familyInfo"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input
            [disabledState]="locked"
            label="Cell Phone Number"
            formControlName="livesWith1CellPhone"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.familyInfo"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-lg">
          <mat-error *ngIf="formGroup.controls.livesWith1BestWayToReachHomePhone.value && !formGroup.controls.livesWith1HomePhone.value">
            Home phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.livesWith1BestWayToReachWorkPhone.value && !formGroup.controls.livesWith1WorkPhone.value">
            Work phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.livesWith1BestWayToReachCellPhone.value && !formGroup.controls.livesWith1CellPhone.value">
            Cell phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.livesWith1BestWayToReachText.value && !formGroup.controls.livesWith1CellPhone.value">
            Cell phone number is <strong>required</strong> if texting is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.livesWith1BestWayToReachEmail.value && !formGroup.controls.livesWith1Email.value">
            Email is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.hasError('atLeastOnePhoneRequired')">
            At least one phone number or email address is
            <strong>required</strong>
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <div class="vertical-control">
            <label class="text-md vertical-label" for="livesWith1BestTimeToContact">Best Way to Contact</label>
            <div>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Home"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="livesWith1BestWayToReachHomePhone"
                label="Home"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Work"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="livesWith1BestWayToReachWorkPhone"
                label="Work"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Cell"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="livesWith1BestWayToReachCellPhone"
                label="Cell"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Email"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="livesWith1BestWayToReachEmail"
                label="Email"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Texting"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="livesWith1BestWayToReachText"
                label="Texting"
              >
              </app-checkbox-single>
              <app-text-field
                [disabledState]="locked"
                *ngIf="isQuestionnaire"
                id="livesWith1BestTimeToContact"
                label="Best Time To Contact"
                formControlName="livesWith1BestTimeToContact"
              ></app-text-field>
            </div>

            <div>
              <app-checkbox-single
                *ngIf="!isQuestionnaire"
                [useOverwrite]="true"
                [disabledState]="locked"
                aria-labelledby="Home"
                label="Home"
                formControlName="livesWith1BestWayToReachHomePhone"
                [sourceData]="questionnaire.familyInfo"
              ></app-checkbox-single>

              <app-checkbox-single
                *ngIf="!isQuestionnaire"
                [useOverwrite]="true"
                [disabledState]="locked"
                aria-labelledby="Work"
                label="Work"
                formControlName="livesWith1BestWayToReachWorkPhone"
                [sourceData]="questionnaire.familyInfo"
              ></app-checkbox-single>

              <app-checkbox-single
                *ngIf="!isQuestionnaire"
                [useOverwrite]="true"
                [disabledState]="locked"
                aria-labelledby="Cell"
                label="Cell"
                formControlName="livesWith1BestWayToReachCellPhone"
                [sourceData]="questionnaire.familyInfo"
              ></app-checkbox-single>

              <app-checkbox-single
                *ngIf="!isQuestionnaire"
                [useOverwrite]="true"
                [disabledState]="locked"
                aria-labelledby="Email"
                label="Email"
                formControlName="livesWith1BestWayToReachEmail"
                [sourceData]="questionnaire.familyInfo"
              ></app-checkbox-single>

              <app-checkbox-single
                *ngIf="!isQuestionnaire"
                [useOverwrite]="true"
                [disabledState]="locked"
                aria-labelledby="Texting"
                label="Texting"
                formControlName="livesWith1BestWayToReachText"
                [sourceData]="questionnaire.familyInfo"
              ></app-checkbox-single>

              <app-overwrite-text-field
                [disabledState]="locked"
                *ngIf="!isQuestionnaire"
                label="Best Time To Contact"
                formControlName="livesWith1BestTimeToContact"
                [sourceData]="questionnaire.familyInfo"
              ></app-overwrite-text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top" *ngIf="shownFields.canDisplayFamilyInfo">
    <mat-card-title>Lives With Information</mat-card-title>
    <app-help-directional-text>
      <p class="mb-2">
        If child does not live with any parent listed above, complete information below for the person the child lives with.
      </p>
    </app-help-directional-text>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg-4">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Name (first and last)"
            formControlName="livesWith2Name"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Name (first and last)"
            formControlName="livesWith2Name"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>

        <div class="col-lg-3">
          <app-text-field [disabledState]="locked" *ngIf="isQuestionnaire" label="Email" formControlName="livesWith2Email"></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Email"
            formControlName="livesWith2Email"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
          <mat-error *ngIf="formGroup.get('livesWith2Email').touched && formGroup.hasError('livesWith2EmailRequired')"> </mat-error>
        </div>
        <div class="col-md-3">
          <app-autocomplete
            [disabledState]="locked"
            [useOverwrite]="!isQuestionnaire"
            [options]="relationshipOptions"
            formControlName="livesWith2RelationshipId"
            label="Relationship"
            [sourceData]="questionnaire.familyInfo"
          ></app-autocomplete>
        </div>
      </div>
      <!-- LivesWith2 Address -->
      <div class="row">
        <div class="col-lg-4">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Street Address"
            formControlName="livesWith2StreetAddress"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Street Address"
            formControlName="livesWith2StreetAddress"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Zip Code"
            formControlName="livesWith2ZipCode"
            (blurMe)="updateAddressFromZipCode('livesWith2')"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Zip Code"
            formControlName="livesWith2ZipCode"
            (blurMe)="updateAddressFromZipCode('livesWith2')"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field [disabledState]="locked" *ngIf="isQuestionnaire" label="City" formControlName="livesWith2City"></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="City"
            formControlName="livesWith2City"
            [sourceData]="questionnaire.familyInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-2">
          <app-autocomplete
            [disabledState]="locked"
            [useOverwrite]="!isQuestionnaire"
            label="State"
            formControlName="livesWith2State"
            [options]="usStateOptions"
            [sourceData]="questionnaire.familyInfo"
          ></app-autocomplete>
        </div>
      </div>

      <div class="row">
        <div class="col-lg">
          <app-masked-input
            [disabledState]="locked"
            label="Home Phone Number"
            formControlName="livesWith2HomePhone"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.familyInfo"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input
            [disabledState]="locked"
            label="Work Phone Number"
            formControlName="livesWith2WorkPhone"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.familyInfo"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input
            [disabledState]="locked"
            label="Cell Phone Number"
            formControlName="livesWith2CellPhone"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.familyInfo"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-lg">
          <mat-error *ngIf="formGroup.controls.livesWith2BestWayToReachHomePhone.value && !formGroup.controls.livesWith2HomePhone.value">
            Home phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.livesWith2BestWayToReachWorkPhone.value && !formGroup.controls.livesWith2WorkPhone.value">
            Work phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.livesWith2BestWayToReachCellPhone.value && !formGroup.controls.livesWith2CellPhone.value">
            Cell phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.livesWith2BestWayToReachText.value && !formGroup.controls.livesWith2CellPhone.value">
            Cell phone number is <strong>required</strong> if texting is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.livesWith2BestWayToReachEmail.value && !formGroup.controls.livesWith2Email.value">
            Email is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.hasError('atLeastOnePhoneRequired')">
            At least one phone number or email address is
            <strong>required</strong>
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <div class="vertical-control">
            <label class="text-md vertical-label" for="livesWith2BestTimeToContact">Best Way to Contact</label>
            <div>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Home"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="livesWith2BestWayToReachHomePhone"
                label="Home"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Work"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="livesWith2BestWayToReachWorkPhone"
                label="Work"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Cell"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="livesWith2BestWayToReachCellPhone"
                label="Cell"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Email"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="livesWith2BestWayToReachEmail"
                label="Email"
              >
              </app-checkbox-single>
              <app-checkbox-single
                [disabledState]="locked"
                aria-labelledby="Texting"
                tabindex="0"
                *ngIf="isQuestionnaire"
                formControlName="livesWith2BestWayToReachText"
                label="Texting"
              >
              </app-checkbox-single>
              <app-text-field
                [disabledState]="locked"
                *ngIf="isQuestionnaire"
                id="livesWith2BestTimeToContact"
                label="Best Time To Contact"
                formControlName="livesWith2BestTimeToContact"
              ></app-text-field>

              <div>
                <app-checkbox-single
                  *ngIf="!isQuestionnaire"
                  [useOverwrite]="true"
                  [disabledState]="locked"
                  aria-labelledby="Home"
                  label="Home"
                  formControlName="livesWith2BestWayToReachHomePhone"
                  [sourceData]="questionnaire.familyInfo"
                ></app-checkbox-single>

                <app-checkbox-single
                  *ngIf="!isQuestionnaire"
                  [useOverwrite]="true"
                  [disabledState]="locked"
                  aria-labelledby="Work"
                  label="Work"
                  formControlName="livesWith2BestWayToReachWorkPhone"
                  [sourceData]="questionnaire.familyInfo"
                ></app-checkbox-single>

                <app-checkbox-single
                  *ngIf="!isQuestionnaire"
                  [useOverwrite]="true"
                  [disabledState]="locked"
                  aria-labelledby="Cell"
                  label="Cell"
                  formControlName="livesWith2BestWayToReachCellPhone"
                  [sourceData]="questionnaire.familyInfo"
                ></app-checkbox-single>

                <app-checkbox-single
                  *ngIf="!isQuestionnaire"
                  [useOverwrite]="true"
                  [disabledState]="locked"
                  aria-labelledby="Email"
                  label="Email"
                  formControlName="livesWith2BestWayToReachEmail"
                  [sourceData]="questionnaire.familyInfo"
                ></app-checkbox-single>

                <app-checkbox-single
                  *ngIf="!isQuestionnaire"
                  [useOverwrite]="true"
                  [disabledState]="locked"
                  aria-labelledby="Texting"
                  label="Texting"
                  formControlName="livesWith2BestWayToReachText"
                  [sourceData]="questionnaire.familyInfo"
                ></app-checkbox-single>

                <app-overwrite-text-field
                  [disabledState]="locked"
                  [useOverwrite]="true"
                  *ngIf="!isQuestionnaire"
                  label="Best Time To Contact"
                  formControlName="livesWith2BestTimeToContact"
                  [sourceData]="questionnaire.familyInfo"
                ></app-overwrite-text-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</form>
