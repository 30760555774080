import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LearnerService } from '../../../services/learner/learner.service';
import { LearnerReadonly } from '../../../models/learner';

@Injectable({
  providedIn: 'root',
})
export class NewChildReadonlyResolver implements Resolve<LearnerReadonly> {
  constructor(private readonly learnerService: LearnerService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LearnerReadonly> {
    return this.learnerService.getReadonly(route.paramMap.get('learnerId'));
  }
}
