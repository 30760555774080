import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';

@Component({
  selector: 'app-edit-matrix-item',
  templateUrl: './edit-matrix-item.component.html',
  styleUrls: ['./edit-matrix-item.component.scss'],
})
export class EditMatrixItemComponent implements OnInit {
  formGroup = this.fb.group({
    note: [''],
  });

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditMatrixItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.formGroup.get('note').setValue(data);
  }

  ngOnInit(): void {}

  onClear() {
    this.formGroup.get('note').setValue('');
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}
