import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from '../auth/auth.guard';
import { NavComponent } from '../nav/nav.component';

const Routes: Routes = [
  {
    path: '',
    component: NavComponent,
    loadChildren: () => import('@ascend/survey-tool-surveys').then((m) => m.AscendSurveyToolSurveysModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'admin',
    component: NavComponent,
    loadChildren: () => import('@ascend/survey-tool-admin').then((m) => m.AscendSurveyToolAdminModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'public',
    loadChildren: () => import('./survey-tool-public/survey-tool-public.module').then((m) => m.SurveyToolPublicModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(Routes)],
  exports: [RouterModule],
  providers: [],
})
export class SurveyToolRoutingModule {}
