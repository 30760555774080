import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MeetingParticipantRead } from 'src/app/learner-management/family-meeting/participants-list/meeting-participants';

@Component({
  selector: 'app-family-provider-member-search-modal',
  templateUrl: './family-provider-member-search-modal.component.html',
  styleUrls: ['./family-provider-member-search-modal.component.scss'],
})
export class FamilyProviderMemberSearchModalComponent implements OnInit {
  initialFamilyProviders: MeetingParticipantRead[] = [];
  availableFamilyProviders: MeetingParticipantRead[] = [];
  initialFamilyMembers: MeetingParticipantRead[] = [];
  availableFamilyMembers: MeetingParticipantRead[] = [];
  selectedFamilyParticipants: MeetingParticipantRead[] = [];

  formGroup = new FormGroup({
    searchTerm: new FormControl(''),
  });

  constructor(
    private dialogRef: MatDialogRef<FamilyProviderMemberSearchModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      providers: MeetingParticipantRead[];
      familyMembers: MeetingParticipantRead[];
    }
  ) {
    this.availableFamilyProviders = data.providers;
    this.availableFamilyMembers = data.familyMembers;
  }

  ngOnInit() {
    this.initialFamilyProviders = this.availableFamilyProviders.slice();
    this.initialFamilyMembers = this.availableFamilyMembers.slice();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onAccept() {
    this.dialogRef.close(this.selectedFamilyParticipants);
  }

  addFamilyProvider(member: MeetingParticipantRead) {
    this.selectedFamilyParticipants.push(member);
    this.filterOutMember('availableFamilyProviders', member);
  }

  addFamilyMember(member: MeetingParticipantRead) {
    this.selectedFamilyParticipants.push(member);
    this.filterOutMember('availableFamilyMembers', member);
  }

  removeFamilyParticipant(member: MeetingParticipantRead) {
    if (member.familyMemberId) {
      this.availableFamilyMembers.push(member);
    } else if (member.agencyId || member.medicalSpecialistId || member.learnerId) {
      this.availableFamilyProviders.push(member);
    }
    this.filterOutMember('selectedFamilyParticipants', member);
  }

  filterOutMember(containerName: string, member: MeetingParticipantRead) {
    this[containerName] = this[containerName].filter((fp) => fp !== member);
  }

  onFilter() {
    this.availableFamilyProviders = [];
    this.availableFamilyProviders = this.initialFamilyProviders.filter((fp) => {
      return !this.selectedFamilyParticipants?.includes(fp);
    });

    this.availableFamilyMembers = [];
    this.availableFamilyMembers = this.initialFamilyMembers.filter((fp) => {
      return !this.selectedFamilyParticipants?.includes(fp);
    });

    if (this.formGroup.controls.searchTerm.value) {
      const filterValue = this.formGroup.controls.searchTerm.value;
      this.availableFamilyProviders = this.availableFamilyProviders.filter((tm) => tm.fullName.toLowerCase().includes(filterValue));
      this.availableFamilyMembers = this.availableFamilyMembers.filter((tm) => tm.fullName.toLowerCase().includes(filterValue));
    }
  }

  displayProviderName(participant: MeetingParticipantRead) {
    if (participant.agency) {
      if (participant.fullName) {
        return `${participant.fullName} - ${participant.agency}`;
      } else {
        return participant.agency;
      }
    } else {
      return participant.fullName;
    }
  }
}
