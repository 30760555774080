import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { EarlyAccessQuestionnaire } from 'src/app/evaluation/models/early-access-questionnaire';
import { Intake } from 'src/app/evaluation/models/intake';
import { HealthInformation } from '../../models/health-information';

//#region Custom Validation Functions

//#endregion
export interface HealthInfoTabChanged {
  canMovePrevious: boolean;
  canMoveNext: boolean;
}

@Component({
  selector: 'app-health-info-form',
  templateUrl: './health-info-form.component.html',
  styleUrls: ['./health-info-form.component.scss'],
})
export class HealthInfoFormComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() healthInfo: HealthInformation;
  @Input() documentationInfo: FormGroup;
  @Input() intake: Intake;
  @Input() intakeLocked: boolean;
  @Input() questionnaire: EarlyAccessQuestionnaire;
  @Input() isQuestionnaire: boolean;
  @Input() questionnaireLocked: boolean;
  @Input() importQuestionnaireSelected: boolean;
  @Output() deleteDocument = new EventEmitter();
  @Output() upload = new EventEmitter();
  @Output() documentUploaded = new EventEmitter();
  @Output() formInitialized = new EventEmitter();
  @Output() pauseAutosaveEvent = new EventEmitter<boolean>();
  @Output() medicalConditionUpdated = new EventEmitter();
  formInitializationFlags = {
    controlsInitialized: false,
    dataLoaded: false,
    eventEmitted: false,
  };
  locked = false;
  @ViewChild('matTabGroup', { static: false }) private matTabGroup: MatTabGroup;
  private TAB_COUNT = 4;
  lastTabChangedInfo: HealthInfoTabChanged = {
    canMovePrevious: false,
    canMoveNext: true,
  };
  @Output() tabChanged = new EventEmitter<HealthInfoTabChanged>();

  //#region Getters
  get providerInformation() {
    return this.formGroup.controls.providerInformation as FormGroup;
  }
  get providerInformationInvalid() {
    return this.providerInformation.touched && this.providerInformation.invalid;
  }
  get providerInformationComplete() {
    return this.providerInformation.touched && this.providerInformation.valid;
  }
  get medicalHistoryInformation() {
    return this.formGroup.controls.medicalHistoryInformation as FormGroup;
  }
  get medicalHistoryInformationInvalid() {
    return this.medicalHistoryInformation.touched && this.medicalHistoryInformation.invalid;
  }
  get medicalHistoryInformationComplete() {
    return this.medicalHistoryInformation.touched && this.medicalHistoryInformation.valid;
  }
  get nutritionGrowthInformation() {
    return this.formGroup.controls.nutritionGrowthInformation as FormGroup;
  }
  get nutritionGrowthInformationInvalid() {
    return this.nutritionGrowthInformation.touched && this.nutritionGrowthInformation.invalid;
  }
  get nutritionGrowthInformationComplete() {
    return this.nutritionGrowthInformation.touched && this.nutritionGrowthInformation.valid;
  }
  get dentalVisionHearingInformation() {
    return this.formGroup.controls.dentalVisionHearingInformation as FormGroup;
  }
  get dentalVisionHearingInformationInvalid() {
    return this.dentalVisionHearingInformation.touched && this.dentalVisionHearingInformation.invalid;
  }
  get dentalVisionHearingInformationComplete() {
    return this.dentalVisionHearingInformation.touched && this.dentalVisionHearingInformation.valid;
  }
  get additionalConcernsInformation() {
    return this.formGroup.controls.additionalConcernsInformation as FormGroup;
  }
  get additionalConcernsInformationInvalid() {
    return this.additionalConcernsInformation.touched && this.additionalConcernsInformation.invalid;
  }
  get additionalConcernsInformationComplete() {
    return this.additionalConcernsInformation.touched && this.additionalConcernsInformation.valid;
  }
  //#endregion

  constructor() {}

  ngOnInit() {
    this.initializeControls();
    this.formGroup.patchValue(this.healthInfo);
    this.formInitializationFlags.dataLoaded = true;
    this.evaluateFormInitialization();
    this.setLock();
    this.TAB_COUNT = this.isQuestionnaire ? 4 : 5;
  }

  setLock() {
    if (this.isQuestionnaire) {
      this.locked = this.questionnaireLocked;
    } else {
      this.locked = this.intakeLocked;
    }
  }

  onUpload(formData: FormData) {
    this.upload.emit(formData);
  }

  onDeleteDocument(documentId: string) {
    this.deleteDocument.emit(documentId);
  }

  initializeControls() {
    this.formGroup.addControl('providerInformation', new FormGroup({}));
    this.formGroup.addControl('medicalHistoryInformation', new FormGroup({}));
    this.formGroup.addControl('nutritionGrowthInformation', new FormGroup({}));
    this.formGroup.addControl('dentalVisionHearingInformation', new FormGroup({}));
    this.formGroup.addControl('additionalConcernsInformation', new FormGroup({}));
    this.formGroup.setValidators([]);
    this.formGroup.updateValueAndValidity();
    this.formInitializationFlags.controlsInitialized = true;
    this.evaluateFormInitialization();
  }

  selectedTabChange(event: MatTabChangeEvent) {
    // TODO prevent default some how, not sure it's possible currently.
    const tabChangedInfo: HealthInfoTabChanged = {
      canMoveNext: event.index < this.TAB_COUNT - 1,
      canMovePrevious: event.index > 0,
    };
    this.lastTabChangedInfo = tabChangedInfo;
    this.tabChanged.emit(tabChangedInfo);
  }

  moveTab(direction: 'previous' | 'next') {
    if (direction === 'next' && this.lastTabChangedInfo.canMoveNext) {
      this.matTabGroup.selectedIndex++;
    } else {
      if (this.lastTabChangedInfo.canMovePrevious) {
        this.matTabGroup.selectedIndex--;
      }
    }
  }
  prefillSourceForm(event) {}

  private evaluateFormInitialization() {
    if (this.formInitializationFlags.eventEmitted) {
      return;
    }
    if (this.formInitializationFlags.controlsInitialized && this.formInitializationFlags.dataLoaded) {
      this.formInitializationFlags.eventEmitted = true;
      this.formInitialized.emit();
    }
  }

  refreshIntakeAfterDocumentUpload() {
    this.documentUploaded.emit();
  }

  medicalConditionUpdatedEmit(e: boolean) {
    this.medicalConditionUpdated.emit(e);
  }

  pauseAutosaveEmit(e: boolean) {
    this.pauseAutosaveEvent.emit(e);
  }
}
