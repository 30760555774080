import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CaseSummary } from '../../../shared/models/case';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { OperationResultWithValue } from '../../../shared/models/operation-result';

@Injectable({
  providedIn: 'root',
})
export class TransitionPlanningService {
  private baseUrl = (caseId = '') => `api/TransitionPlanning/${caseId}`;
  private childUrl = (caseId: string, id = '') => `${this.baseUrl(caseId)}/ChildNextSteps/${id}`;
  private familyUrl = (caseId: string, id = '') => `${this.baseUrl(caseId)}/FamilyNextSteps/${id}`;
  private optionsUrl = () => `${this.baseUrl()}Options`;

  constructor(private http: HttpClient) {}

  get(caseId: string): Observable<TransitionPlanningRead> {
    return this.http.get<TransitionPlanningRead>(this.baseUrl(caseId));
  }

  post(model: TransitionPlanningEdit): Observable<TransitionPlanningRead> {
    return this.http.post<TransitionPlanningRead>(this.baseUrl(model.caseId), model);
  }

  put(model: TransitionPlanningEdit): Observable<TransitionPlanningRead> {
    return this.http.put<TransitionPlanningRead>(this.baseUrl(model.caseId), model);
  }

  childAdd(caseId: string, model: ChildNextStep): Observable<ChildNextStep> {
    return this.http.post<ChildNextStep>(this.childUrl(caseId), model);
  }

  childUpdate(caseId: string, model: ChildNextStep): Observable<ChildNextStep> {
    return this.http.put<ChildNextStep>(this.childUrl(caseId, model.id), model);
  }

  childDelete(caseId: string, id: string): Observable<void> {
    return this.http.put<void>(`${this.childUrl(caseId, id)}/delete`, null);
  }

  familyAdd(caseId: string, model: FamilyNextStep): Observable<FamilyNextStep> {
    return this.http.post<FamilyNextStep>(this.familyUrl(caseId), model);
  }

  familyUpdate(caseId: string, model: FamilyNextStep): Observable<FamilyNextStep> {
    return this.http.put<FamilyNextStep>(this.familyUrl(caseId, model.id), model);
  }

  familyDelete(caseId: string, id: string): Observable<void> {
    return this.http.put<void>(`${this.familyUrl(caseId, id)}/delete`, null);
  }

  partBContacts(caseId: string) {
    return this.http.get<KeyValuePair[]>(`${this.baseUrl(caseId)}/partBContacts`);
  }

  contactPartB(caseId: string, userId: string) {
    return this.http.post<OperationResultWithValue<CaseSummary>>(`${this.baseUrl(caseId)}/partBContacts/${userId}`, {});
  }

  delayReasons(isMigrated: boolean): Observable<Array<KeyValuePair>> {
    return this.http.get<Array<KeyValuePair>>(`${this.optionsUrl()}/delayReasons/${isMigrated}`);
  }

  getByLearnerId(learnerId: string): Observable<TransitionPlanningRead> {
    return this.http.get<TransitionPlanningRead>(`api/TransitionPlanning/byLearner/${learnerId}`);
  }
}

export enum TransitionCommunityProgramOptionVisibilities {
  CommunityPrograms = 'CommunityPrograms',
  SpecialEducation = 'SpecialEducation',
  All = 'All',
}

export interface TransitionCommunityProgramOption {
  id: string;
  label: string;
  visibility: TransitionCommunityProgramOptionVisibilities;
}

export interface TransitionPlanningRead extends TransitionPlanningEdit {
  dateOfBirth: Date;
  thirdBirthday: Date;
  nineMonthsPrior: Date;
  sixMonthsPrior: Date;
  ninetyDaysPrior: Date;
  learnerFirstName: string;
  learnerName: string;
  firstName: string;
  lastName: string;
  meetingDates: Date[];
  exitCodes: KeyValuePair[];
  contacts: KeyValuePair[];
  isCaseUser: boolean;
  nextOptions: KeyValuePair[];
  notTransitioningOptions: KeyValuePair[];
  transitionCommunityProgramOptions: TransitionCommunityProgramOption[];
  transitionCommunityOptions: KeyValuePair[];
  transitionPotentiallySpecialEdOptions: KeyValuePair[];
  transitionStrategySpecialEdOptions: KeyValuePair[];
  transitionReferredOtherProgramsOptions: KeyValuePair[];
  transitionChildReferredOptions: KeyValuePair[];
  nextSteps: string[];
  childNextSteps: ChildNextStep[];
  familyNextSteps: FamilyNextStep[];
  personToCarryOutOptions: KeyValuePair[];
  // planning
  diagnosisOfCondition: boolean;
  diagnosisOfConditionComments: string;
  uniqueComparedToPeers: boolean;
  uniqueComparedToPeersComments: string;
  progressionStandards: boolean;
  progressionStandardsComments: string;
  requiresContinuedEffort: boolean;
  requiresContinuedEffortComments: string;
  partBCaseId: string;
  partBCaseEligibility?: boolean;
  partBEvaluationFinalized?: boolean;
  partBEvaluationFinalizedDate?: Date;
  withdrawalPartB?: Date;
  delayedTransitionMeeting: boolean;
  noTransitionMeeting: boolean;
  disabilitySuspected?: boolean;
  parentRequestedEval: boolean;
  outputDocumentId: string;
}

export interface TransitionPlanningEdit {
  id?: string;
  caseId: string;
  planDate: Date;
  strengths: string;
  nextStep: string;
  familyApproveOfSpecialEd: boolean;
  familyGoals: string;
  // planning
  diagnosisOfCondition: boolean;
  diagnosisOfConditionComments: string;
  uniqueComparedToPeers: boolean;
  uniqueComparedToPeersComments: string;
  progressionStandards: boolean;
  progressionStandardsComments: string;
  requiresContinuedEffort: boolean;
  requiresContinuedEffortComments: string;
  ecoRatingProgresses: KeyValuePair[];
  reasonForDelayedMeeting: string;
  reasonForDelayedMeetingOther: string;
  reasonForNotHavingMeeting: string;
  reasonForNotHavingMeetingOther: string;
}

export interface TransitionNextStep {
  id: string;
  partCTransitionId: string;
  personToCarryOutId: string;
  projectedStartDate: Date;
  dateActivityCompleted: Date;
  otherSteps: string;
  discontinued: boolean;
}

export interface ChildNextStep extends TransitionNextStep {
  transitionChildReferredId: string;
}

export interface FamilyNextStep extends TransitionNextStep {
  notTransitioningId: string;
  transitionCommunityProgramId: string;
  transitionCommunities: string[];
  transitionCommunityOther: string;
  consentsSignFor: string;
  transitionCommunityProgramOther: string;
}
