import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-help-text-sheet',
  templateUrl: './help-text-sheet.component.html',
  styleUrls: ['./help-text-sheet.component.scss'],
})
export class HelpTextSheetComponent implements OnInit {
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private bottomSheetRef: MatBottomSheetRef<HelpTextSheetComponent>) {}

  ngOnInit(): void {}

  checkIfArray(val: string | any[]): boolean {
    return val instanceof Array ? true : false;
  }

  onClose() {
    this.bottomSheetRef.dismiss();
  }
}
