<mat-card>
  <mat-card-content>
    <div class="row" *ngIf="formGroup" [formGroup]="formGroup">
      <div class="col">
        <app-date-picker
          controlName="exitDate"
          label="End / Exit Date (MM/DD/YYYY)"
          aria-label="End / Exit Date is required"
          [disabled]="!canEdit"
        ></app-date-picker>
        <app-autocomplete label="End / Exit Reason" formControlName="exitReasonId" [options]="exitOptions"></app-autocomplete>
      </div>
    </div>

    <!-- ECO Ratings -->
    <section class="card-spacing-top" *ngIf="displayEcoForm" #ecoSection>
      <app-eco-rating-wrapper
        [learnerId]="case?.learnerId"
        [caseSummary]="case"
        [isPK]="case?.learner?.isPK"
        (ecoRatingUpdateEvent)="ecoRatingUpdated()"
      ></app-eco-rating-wrapper>
    </section>

    <mat-expansion-panel class="card-spacing-top card--form">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4 class="text-primary text-nrm my-0 mr-1">Next steps</h4>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngIf="incompleteItems?.length > 0; else noNextStepsMessage">
        <p *ngFor="let step of incompleteItems">
          <a (click)="goToStep(step)" class="text-underline" tabindex="0" role="link" attr.aria-label="{{ step.description }}">{{
            step.description
          }}</a>
        </p>
      </ng-container>
      <ng-template #noNextStepsMessage>
        <p>None required</p>
      </ng-template>
    </mat-expansion-panel>

    <div class="action__row card-spacing-top" *ngIf="formGroup">
      <button mat-raised-button aria-label="Cancel Button" *ngIf="canEdit" (click)="cancelExit()" tabindex="0">Cancel</button>
      <button
        mat-raised-button
        aria-label="Finalize"
        class="mr-2"
        color="primary"
        *ngIf="canEdit"
        (click)="finalizeExit()"
        [disabled]="!canFinalize || formGroup.invalid || exitInFuture"
        tabindex="0"
      >
        Finalize
      </button>
    </div>
  </mat-card-content>
</mat-card>
