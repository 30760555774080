import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts a boolean to Yes or No
 */
@Pipe({ name: 'boolToYesNo' })
export class BooleanYesNoPipe implements PipeTransform {
  transform(value: boolean): string {
    return value ? 'Yes' : 'No';
  }
}

/**
 * Converts a nullable boolean to Yes or No
 */
@Pipe({ name: 'nullableBoolToYesNo' })
export class NullableBooleanYesNoPipe implements PipeTransform {
  transform(value?: boolean, fallbackValue = 'NA'): string {
    return value === null ? fallbackValue : value === true ? 'Yes' : 'No';
  }
}
