import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { TrialPlacementTarget } from '../models/iep';
import { IepServiceAddUpdateDto, IepServiceDto } from '../models/iep-service';

@Injectable({
  providedIn: 'root',
})
export class IepServiceService {
  public servicesUpdated = new Subject<void>();
  servicesUpdated$ = this.servicesUpdated.asObservable();

  public serviceClose = new Subject<void>();
  serviceClose$ = this.serviceClose.asObservable();

  public serviceAddNew = new Subject<void>();
  serviceAddNew$ = this.serviceAddNew.asObservable();

  public setAmendingService = new Subject<any>();
  setAmendingService$ = this.setAmendingService.asObservable();

  public focusLRERECPBtn = new Subject<any>();
  focusLRERECPBtn$ = this.focusLRERECPBtn.asObservable();

  constructor(private readonly http: HttpClient, spinnerService: SpinnerService, private datePipe: DatePipe) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/.*\/iepservice.*/));
  }

  private baseUrl(iepId: string, id = '') {
    return `api/${iepId}/iepservice/${id}`;
  }

  getAllIepServices(iepId: string) {
    return this.http.get<IepServiceDto[]>(this.baseUrl(iepId));
  }

  getAllForAmendment(iepId: string, amendmentId = '') {
    return this.http.get<IepServiceDto[]>(this.baseUrl(iepId) + `amend/${amendmentId}`);
  }

  getAuditLogs(iepId: string, id: string) {
    return this.http.get<any[]>(this.baseUrl(iepId) + `${id}/getAuditLogs`);
  }

  update(iepId: string, model: IepServiceAddUpdateDto) {
    return this.http.put<any>(this.baseUrl(iepId), model).pipe(tap(() => this.servicesUpdated.next()));
  }

  updateProviders(iepId: string, id: string, model: any) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/updateProviders', model);
  }

  updateTrialPlacement(iepId: string, id: string, includedInTrialPlacement: boolean) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/update-trial-placement', { includedInTrialPlacement });
  }

  amend(iepId: string, id: string, amendmentId: string) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/amend/' + amendmentId, null).pipe(tap(() => this.servicesUpdated.next()));
  }

  endService(iepId: string, id: string, model: any) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/end', model).pipe(tap(() => this.servicesUpdated.next()));
  }

  setEndCode(iepId: string, iepService: IepServiceDto) {
    return this.http.put(`${this.baseUrl(iepId, iepService.id)}/set-end-code/${iepService.transitionCodeId}`, iepService);
  }

  deleteService(iepId: string, id: string, isAmendment = false) {
    return this.http.put(this.baseUrl(iepId, id) + '/' + isAmendment, null).pipe(tap(() => this.servicesUpdated.next()));
  }

  getIepDevelopmentServiceViewMoreData(
    service: IepServiceDto,
    iepIncludesTrialPlacement: boolean,
    iepTrialPlacementTarget: TrialPlacementTarget
  ): ViewMoreModalData[] {
    const getServiceType = (service, instructionType?: string) => {
      if (instructionType) {
        return instructionType;
      }
      if (service?.customServiceDate === 'BothPKAndK') {
        return 'Both';
      } else {
        if (service?.customServiceDate === 'KOnly') {
          return 'K';
        }
        if (service?.customServiceDate === 'PKOnly') {
          return 'PK';
        }
      }
    };

    const getFormattedProjectedDate = (date: Date) => {
      return !!date && date.toString() !== 'Invalid Date' ? this.datePipe.transform(date, shortDateFormat) : null;
    };

    const getProviderNames = (providers, otherProvider?: string) => {
      const providersMapped = providers?.map((x) => `${x.firstName} ${x.lastName || ''}`).join(', ');

      if (otherProvider && providersMapped) {
        return providersMapped + `, ${otherProvider}`;
      } else if (otherProvider) {
        return otherProvider;
      }

      return providersMapped;
    };

    const modalData: ViewMoreModalData[] = [
      {
        name: 'Service',
        value: service.serviceType.label,
      },
      {
        name: 'Service Type',
        value: getServiceType(service.serviceType, service.instructionalSupportType),
      },
      {
        name: 'Description',
        value: service.description,
      },
      {
        name: 'Projected Start Date',
        value: getFormattedProjectedDate(service.projectedStartDate),
      },
      {
        name: 'Projected End Date',
        value: getFormattedProjectedDate(service.projectedEndDate),
      },
      {
        name: 'How Often?',
        value: service.frequencyNumber ? `${service.frequencyNumber} ${service.frequencyIncrement} ${service.frequencyPeriod}` : null,
      },
      {
        name: 'Amount Removed from General Education',
        value: service.amountRemovedFromGeneralEducation
          ? `${service.amountRemovedFromGeneralEducation} ${service.frequencyIncrement} ${service.frequencyPeriod}`
          : null,
      },
    ];

    if (iepIncludesTrialPlacement && (iepTrialPlacementTarget === TrialPlacementTarget.AllItems || service.includesTrialPlacement)) {
      modalData.unshift({
        name: 'Included In Trial Placement',
        value: 'Yes',
      });
    }

    if (service.providers) {
      const data = {
        name: 'Providers',
        value: getProviderNames(service.providers, service.otherProviderName),
      };
      modalData.push(data);
    }

    if (service.paraprofessionalSupports) {
      modalData.push({
        name: 'Paraprofessional Supports',
        value: service.paraprofessionalSupports.join(', '),
      });
    }

    if (service.transportationServices) {
      modalData.push({
        name: 'Transportation Services',
        value: service.transportationServices.join(', '),
      });
    }

    if (service.transportationServicesDescription) {
      modalData.push({
        name: 'Transportation Service Description',
        value: service.transportationServicesDescription,
      });
    }

    if (service.directConsult) {
      modalData.push({
        name: 'Service Method',
        value: service.directConsult,
      });
    }

    if (service.hasBehaviorInterventionPlan) {
      modalData.push({
        name: 'Does the learner have a Behavior Intervention Plan?',
        value: service.hasBehaviorInterventionPlan ? 'Yes' : 'No',
      });
    }

    if (service.hasHealthCarePlan) {
      modalData.push({
        name: 'Does the learner have a Health Care Plan?',
        value: service.hasHealthCarePlan ? 'Yes' : 'No',
      });
    }

    if (service.instructionalSupportType) {
      modalData.push({
        name: 'Instructional Support Type',
        value: service.instructionalSupportType,
      });
    }

    return modalData;
  }
}
