<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Add a To-Do Item</h3>

  <div mat-dialog-content class="py-3">
    <mat-card class="my-0 card--plain">
      <div class="row">
        <div class="col-lg-12">
          <app-text-field label="Description" formControlName="title"></app-text-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <app-date-picker label="Due Date" controlName="dueDate" [min]="today"></app-date-picker>
        </div>
        <div class="col-lg-4 display-flex direction-column justify-content-center">
          <app-checkbox-single
            formControlName="showOnCalendar"
            aria-labelledby="Check Show on calendar"
            label="Show on calendar"
          ></app-checkbox-single>
        </div>
      </div>
    </mat-card>
  </div>

  <div mat-dialog-actions class="display-flex justify-content-end align-items-center">
    <button
      mat-raised-button
      aria-label="Cancel Button"
      class="background-color--muted mr-2"
      (click)="onClose()"
      type="button"
      cdkFocusInitial
      tabindex="0"
    >
      Cancel
    </button>
    <button [isBusy]="activeCall" mat-raised-button aria-label="Add Button" color="primary" [disabled]="formGroup.invalid" tabindex="0">
      Add
    </button>
  </div>
</form>
