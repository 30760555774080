<h4 class="text-primary mt-0 mb-2">Adult / Community Contacts</h4>
<form [formGroup]="formGroup" *ngIf="!isReadonly">
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <app-select label="Resource Options" formControlName="resourceOption" [options]="resourceOptions"></app-select>
    </div>
    <div *ngIf="formGroup.controls.resourceOption.value === resourceOtherKey">
      <app-text-field label="Other Resource" formControlName="resourceOther"></app-text-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-6 col-xl-8">
      <app-text-field label="Organization / Information" formControlName="agencyResource"></app-text-field>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-select label="Status" formControlName="status" [options]="statusOptions"></app-select>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-text-field label="Name / Position" formControlName="namePosition"></app-text-field>
    </div>

    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-masked-input label="Phone" formControlName="phone" mask="(000) 000-0000"></app-masked-input>
    </div>

    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-date-picker label="Date Connected" controlName="dateConnected"></app-date-picker>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-6">
      <app-text-field label="Website" formControlName="website"></app-text-field>
    </div>
  </div>

  <app-textarea label="Focus of Service Needs" formControlName="focusServiceNeeds"></app-textarea>

  <app-textarea label="Identified Next Steps" formControlName="identifiedNextSteps"></app-textarea>

  <div class="action__row my-0">
    <button [isBusy]="activeCall" mat-raised-button aria-label="Complete" color="primary" (click)="submit()" tabindex="0">Complete</button>
  </div>
</form>

<div class="table-overflow app-table" *ngIf="dataSource?.data?.length > 0">
  <table mat-table [dataSource]="dataSource" class="w-100" matSort>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more"></th>
      <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
        <ng-container *ngIf="!isEditing">
          <button aria-label="Edit Icon" mat-icon-button color="primary" (click)="onEdit(element)" tabindex="0">
            <mat-icon>edit</mat-icon>
          </button>
          <button aria-label="Delete Icon" mat-icon-button color="warn" (click)="onDelete(element)" tabindex="0">
            <mat-icon>close</mat-icon>
          </button>
          <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewMore(element)" tabindex="0">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
          <button type="button" mat-icon-button color="warn" *ngIf="!isComplete(element)" class="icon-incomplete" tabindex="0">
            <mat-icon aria-label="Warning">warning</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="isEditing">
          <button aria-label="Save Icon" color="primary" mat-icon-button title="Save" tabindex="0">
            <mat-icon>save</mat-icon>
          </button>
          <button aria-label="Clear Icon" color="warn" mat-icon-button (click)="onCancelEdit()" title="Cancel" tabindex="0">
            <mat-icon>clear</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="resource">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Resource</th>
      <td mat-cell *matCellDef="let element">
        {{ getResourceLabel(element) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="organizationInformation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Organization / Information</th>
      <td mat-cell *matCellDef="let element">
        {{ element.agencyResource | truncate : 150 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dateConnected">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Connected</th>
      <td mat-cell *matCellDef="let element">
        {{ element.dateConnected | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="focusOfService">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Focus of Service Needs</th>
      <td mat-cell *matCellDef="let element">
        {{ element.focusServiceNeeds | truncate : 150 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="contactPerson">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact Person</th>
      <td mat-cell *matCellDef="let element">
        {{ element.namePosition }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">
        {{ communityContactStatus[element.status] }}
      </td>
    </ng-container>

    <ng-container matColumnDef="identifiedSteps">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Identified Next Steps</th>
      <td mat-cell *matCellDef="let element">
        {{ element.identifiedNextSteps | truncate : 150 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr [hidden]="dataSource?.data?.length > 0" mat-footer-row *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
