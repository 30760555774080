import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AutocompleteGroup } from '../../../shared/models/autocomplete-group';
import { IntakeType } from '../../../shared/models/case';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { FamilyMeetingService } from '../family-meeting.service';
import { PurposeOfMeeting } from '../purpose-of-meeting/purpose-of-meeting';

@Component({
  selector: 'app-purpose-of-meeting-list',
  templateUrl: 'purpose-of-meeting-list.component.html',
  styleUrls: ['purpose-of-meeting-list.component.scss'],
})
export class PurposeOfMeetingListComponent implements OnInit, OnChanges {
  purposeOptions: KeyValuePair[] = [];
  groupedPurposeOptions: AutocompleteGroup[] = [];
  otherSelected = false;

  @Input() purposes: Array<PurposeOfMeeting>;
  @Input() allPurposes: Array<PurposeOfMeeting>;
  @Input() formGroup: FormGroup;
  @Output() purposesChanged = new EventEmitter<Array<PurposeOfMeeting>>();

  constructor(private familyMeetingService: FamilyMeetingService) {}

  ngOnInit() {
    this.formGroup.get('purposes').valueChanges.subscribe((value) => {
      if (value.length === 0) {
        this.onPurposeSelected({ value: [], group: '' });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.purposes?.currentValue) {
      this.loadData();
    }
  }

  private loadData(): void {
    if (!this.purposes || this.purposes?.length === 0) {
      return;
    }

    const groups = this.getGroups(this.purposes);
    if (groups.length === 1) {
      this.purposeOptions = groups[0].options;
    } else {
      this.groupedPurposeOptions = groups;
    }
  }

  private getGroups(purposes: Array<PurposeOfMeeting>): Array<AutocompleteGroup> {
    const groups = new Array<AutocompleteGroup>();
    purposes.forEach((x) => {
      if (x.intakeType === IntakeType.Both) {
        groups.forEach((group) => group.options.push(new KeyValuePair(x.id, x.label)));
      } else {
        let group = groups.find((g) => g.label === this.getGroupLabel(x.intakeType));
        if (!group) {
          group = new AutocompleteGroup(this.getGroupLabel(x.intakeType), []);
          groups.push(group);
        }
        group.options.push(new KeyValuePair(x.id, x.label));
      }
    });
    return groups;
  }

  private getGroupLabel(intakeType: IntakeType): string {
    return intakeType === IntakeType.PartB ? 'Special Education' : 'Early ACCESS';
  }

  onPurposeSelected(event: { value: Array<string>; group: string }) {
    const purpose = this.purposes.find((x) => x.id === event.value[0]);
    if (purpose) {
      if (purpose.intakeType === IntakeType.Both) {
        const intakeType = event.group === 'Special Education' ? IntakeType.PartB : IntakeType.PartC;
        this.purposes = this.purposes.filter((x) => x.intakeType === intakeType || x.intakeType === IntakeType.Both);
        // TODO: Add back in once 18790 is figured out
        // if (intakeType === IntakeType.PartB) {
        //   this.familyMeetingService.hasPartBPurposeSelected.next(true);
        // } else {
        //   this.familyMeetingService.hasPartBPurposeSelected.next(false);
        // }
      } else {
        this.purposes = this.purposes.filter(
          (x) => x.intakeType === purpose.intakeType || (x.intakeType === IntakeType.Both && x.id !== purpose.id)
        );
        // TODO: Add back in once 18790 is figured out
        // this.familyMeetingService.hasPartBPurposeSelected.next(purpose.intakeType === IntakeType.PartB);
      }
      this.groupedPurposeOptions = [];
    } else {
      this.purposes = [...this.allPurposes];
      this.purposeOptions = [];
      // TODO: Add back in once 18790 is figured out
      // this.familyMeetingService.hasPartBPurposeSelected.next(false);
    }
    this.loadData();
  }
}
