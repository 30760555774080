<div class="table-overflow app-table" *ngIf="this.dataSource">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">
    <ng-container *ngIf="!locked" matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-left">Actions</th>
      <td mat-cell *matCellDef="let specialist" class="text-left">
        <ng-container *ngIf="specialist.id && !isEditing">
          <button aria-label="Edit Icon" mat-icon-button (click)="setEdit(specialist)" tabindex="0">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button aria-label="Quick Access Menu" [matMenuTriggerFor]="menu" tabindex="0">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              *ngIf="!isQuestionnaire"
              (click)="getOrCreateConsentToRelease(specialist)"
              mat-menu-item
              attr.aria-label="getConsentToReleaseLabel(specialist)"
              tabindex="0"
            >
              {{ getConsentToReleaseLabel(specialist) }}
            </button>
            <button
              *ngIf="!isQuestionnaire"
              (click)="downloadIcdPhysicianLetter(specialist)"
              mat-menu-item
              aria-label="Download ICD 10 Physician Letter"
              tabindex="0"
            >
              Download ICD 10 Physician Letter
            </button>
            <button
              *ngIf="!isQuestionnaire"
              [disabled]="specialist.recordsReceived"
              (click)="indicateRecordsReceived(specialist)"
              mat-menu-item
              aria-label="Indicate Records Received"
              tabindex="0"
            >
              Indicate Records Received
            </button>
            <button
              (click)="removeRow(specialist.id)"
              [disabled]="specialist.medicalSpecialistDocument"
              [ngClass]="specialist.medicalSpecialistDocument === null ? 'text-danger' : null"
              mat-menu-item
              aria-label="Delete Icon"
              tabindex="0"
            >
              Remove
            </button>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="specialist.isEditing">
          <button aria-label="Save Icon" mat-icon-button (click)="addOrUpdate(specialist)" title="Save" tabindex="0">
            <mat-icon>save</mat-icon>
          </button>
          <button aria-label="Clear Icon" mat-icon-button (click)="setEdit(specialist, false)" title="Cancel" tabindex="0">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item aria-label="Save Icon" (click)="addOrUpdate(specialist)" tabindex="0">Save</button>
            <button mat-menu-item aria-label="Cancel Button" (click)="setEdit(specialist, false)" tabindex="0">Cancel</button>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="!specialist.id && !isEditing">
          <mat-icon class="ml-2" (click)="setEdit(specialist)" aria-label="Add Icon">add_circle</mat-icon>
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Specialist Name</th>
      <td mat-cell *matCellDef="let specialist">
        <app-inline-edit [editing]="specialist.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ specialist.name }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <mat-form-field class="w-100">
              <input
                formControlName="name"
                matInput
                aria-labelledby="Name"
                [maxlength]="stringSizes.small"
                placeholder="Name of Specialist"
                (keydown)="$event.stopPropagation()"
              />
              <mat-error *ngIf="formGroup.controls.name.hasError('required') && formGroup.controls.name.touched">
                Name is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="formGroup.controls.name.hasError('pattern')"> Numbers are <strong>not allowed</strong> </mat-error>
            </mat-form-field>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="areaOfSpecialty">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Area Of Specialty</th>
      <td mat-cell *matCellDef="let specialist">
        <app-inline-edit [editing]="specialist.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ specialist.areaOfSpecialty }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <mat-form-field class="w-100">
              <input
                formControlName="areaOfSpecialty"
                matInput
                aria-labelledby="Area of Specialty"
                [maxlength]="stringSizes.small"
                placeholder="Area of Specialty"
                (keydown)="$event.stopPropagation()"
              />
              <mat-error *ngIf="formGroup.controls.areaOfSpecialty.hasError('pattern')">
                Numbers are <strong>not allowed</strong>
              </mat-error>
            </mat-form-field>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
      <td mat-cell *matCellDef="let specialist">
        <app-inline-edit [editing]="specialist.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ specialist.address }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <mat-form-field class="w-100">
              <input
                formControlName="address"
                matInput
                aria-labelledby="Address"
                [maxlength]="stringSizes.small"
                placeholder="Address"
                (keydown)="$event.stopPropagation()"
              />
            </mat-form-field>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>City/Town</th>
      <td mat-cell *matCellDef="let specialist">
        <app-inline-edit [editing]="specialist.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ specialist.city }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <mat-form-field class="w-100">
              <input
                formControlName="city"
                matInput
                aria-labelledby="City"
                [maxlength]="stringSizes.small"
                placeholder="City/Town"
                (keydown)="$event.stopPropagation()"
              />
            </mat-form-field>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
      <td mat-cell *matCellDef="let specialist">
        <app-inline-edit [editing]="specialist.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ specialist.state }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-autocomplete
              [options]="usStates"
              formControlName="state"
              label="State"
              (keydown)="$event.stopPropagation()"
            ></app-autocomplete>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="zipCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Zip Code</th>
      <td mat-cell *matCellDef="let specialist">
        <app-inline-edit [editing]="specialist.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ specialist.zipCode }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <mat-form-field>
              <input
                formControlName="zipCode"
                matInput
                aria-labelledby="Zip Code"
                placeholder="Zip Code"
                (blur)="populateLocationFromZipCode()"
                (keydown)="$event.stopPropagation()"
              />
              <mat-error *ngIf="formGroup.controls.zipCode.hasError('pattern')"> Input not in correct format </mat-error>
            </mat-form-field>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container matColumnDef="phoneNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
      <td mat-cell *matCellDef="let specialist">
        <app-inline-edit [editing]="specialist.isEditing" [allowEdit]="true">
          <ng-container viewMode>
            {{ specialist.phoneNumber | phone }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-masked-input label="Phone Number" formControlName="phoneNumber" (keydown)="$event.stopPropagation()" mask="(000) 000-0000">
            </app-masked-input>
          </ng-container>
        </app-inline-edit>
      </td>
    </ng-container>

    <ng-container *ngIf="!locked && !isQuestionnaire" matColumnDef="recordsReceived">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Record Received</th>
      <td mat-cell *matCellDef="let specialist">
        <svg-icon
          src="assets/img/icons/Record-Received.svg"
          svgClass="icon--complete text-center"
          *ngIf="specialist.recordsReceived"
        ></svg-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr
      [hidden]="dataSource?.data?.length > 0"
      mat-footer-row
      *matFooterRowDef="dataSource.data.length === 0 ? ['noResults'] : []"
      class="example-second-footer-row"
    ></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
  <p *ngIf="!dataSource.data">No data yet...</p>

  <span class="mat-cell position-fix mr-4">
    <button
      class="mr-1 mt-3"
      *ngIf="this.questionnaireMedSpecsNotImported?.length > 0"
      [disabled]="locked"
      type="button"
      mat-raised-button
      aria-label="Import from Questionnaire"
      color="accent"
      (click)="appendQuestionnaireMedicalSpecialist()"
      tabindex="0"
    >
      Import from Questionnaire
    </button>
  </span>
</div>
