<div class="row justify-content-end mt-3">
  <div class="col-1">
    <mat-icon (click)="viewDetails()" class="cursor-pointer">remove_red_eye</mat-icon>
  </div>
  <div class="col-11" [formGroup]="form">
    <div class="row align-items-end">
      <div class="col-md-12 col-xl-6">
        <div class="d-flex justify-content-between">
          <strong> {{ specialEducationService?.serviceType?.label || 'Service type not found' }} </strong>
        </div>
        <app-autocomplete
          label="Frequency"
          formControlName="serviceClpFrequencyId"
          [options]="frequencyDictionary"
          [readOnly]="readonly"
          [disabledState]="readonly"
        >
        </app-autocomplete>
      </div>
      <app-autocomplete-chips
        class="col-md-12 col-xl-6"
        [class.chip-select-position-fix]="readonly"
        label="Delivery Format"
        formControlName="deliveryFormatIds"
        [options]="deliveryFormatDictionary"
        [readOnly]="readonly"
        [disabledState]="readonly"
      ></app-autocomplete-chips>
    </div>
    <div class="row align-items-end">
      <app-textarea
        class="col-md-12 col-lg-6"
        label="Describe"
        formControlName="frequencyDescription"
        [showAsterisk]="isFrequencyDescribeFieldRequired"
        *ngIf="isFrequencyDescribeFieldRequired"
        [readOnly]="readonly"
        [disabledState]="readonly"
      ></app-textarea>
      <app-textarea
        class="col-md-12 col-lg-6 ml-auto"
        label="Delivery Format Details"
        formControlName="deliveryFormatDetails"
        [showAsterisk]="showDeliveryFormatDetailsField"
        *ngIf="showDeliveryFormatDetailsField"
        [readOnly]="readonly"
        [disabledState]="readonly"
      ></app-textarea>
    </div>
  </div>
</div>
