import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-remove-assignment',
  templateUrl: './remove-assignment.component.html',
  styleUrls: ['./remove-assignment.component.scss'],
})
export class RemoveAssignmentComponent implements OnInit {
  action: string;

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: string) {}

  ngOnInit(): void {
    this.action = this.data;
  }

  selected(canRemove: boolean) {
    this.dialogRef.close({
      canRemove,
    });
  }
}
