import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';

@Component({
  selector: 'app-meeting-history',
  templateUrl: './meeting-history.component.html',
  styleUrls: ['./meeting-history.component.scss'],
})
export class MeetingHistoryComponent implements OnInit {
  shortDateFormat = shortDateFormat;

  dataSource: MatTableDataSource<any> = new MatTableDataSource([
    {
      id: '0',
      purposeOfMeeting: 'Some Purpose',
      meetingDate: new Date(Date.now()),
      status: 'Canceled',
    },
  ]);
  displayedColumns = ['purposeOfMeeting', 'meetingDate', 'status'];

  constructor() {}

  ngOnInit(): void {}
}
