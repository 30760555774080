import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { DateToUtcWithDaylightPipe } from '../../../pipes/date-transform.pipe';
import { DeactivationService } from '../../../services/deactivation.service';
import { AreYouSureComponent } from '../../are-you-sure-modal/are-you-sure.component';
import { BaseComponent } from '../../base-component/base-component';
import { QuantifiableDataMilestone } from '../quantifiable-data';

@Component({
  selector: 'app-milestones',
  templateUrl: './milestones.component.html',
  styleUrls: ['./milestones.component.scss'],
  providers: [DatePipe, DateToUtcWithDaylightPipe],
})
export class MilestonesComponent extends BaseComponent implements OnInit, OnChanges {
  @Input()
  milestones: QuantifiableDataMilestone[];
  @Input()
  initialGoalNumber = -1;
  @Input()
  showForm: boolean;
  @Input()
  secondary: boolean;
  @Input()
  modificationId: string;
  @Output()
  updated = new EventEmitter<QuantifiableDataMilestone[]>();
  @Output()
  canceled = new EventEmitter<boolean>();
  @Output() edit = new EventEmitter();
  @ViewChild(FormGroupDirective)
  formRef: FormGroupDirective;
  shortDateFormat = shortDateFormat;

  constructor(
    private fb: FormBuilder,
    public datePipe: DatePipe,
    private dialog: MatDialog,
    deactivationService: DeactivationService,
    private dateToUtcWithDaylightPipe: DateToUtcWithDaylightPipe
  ) {
    super(deactivationService);
  }

  formGroup = this.fb.group({
    // eslint-disable-next-line id-blacklist
    number: [null],
    completionDate: [null],
    benchmarkObjectiveMilestone: [null],
    value: [null, { validators: [Validators.min(0), Validators.required] }],
    secondary: [null],
    isActive: [true],
  });

  dataSource = new MatTableDataSource<QuantifiableDataMilestone>();
  displayedColumns = ['actions', 'number', 'completionDate', 'benchmarkObjectiveMilestone', 'value'];
  ratingLevels: QuantifiableDataMilestone[] = [];
  model: QuantifiableDataMilestone;

  ngOnInit(): void {
    this.dataSource.data = this.milestones?.filter((x) => x.secondary === this.secondary);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.milestones?.currentValue !== changes.milestones?.previousValue) {
      this.dataSource.data = changes.milestones.currentValue.sort((a, b) => (a.number > b.number ? 1 : -1));

      this.dataSource.data = this.dataSource.data.filter((x) => x.secondary === this.secondary);
    }
  }

  onUpdateModel() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      const modelIdx = this.milestones.indexOf(this.model);

      const utcDateInCstEquivalent =
        typeof this.formGroup.value.completionDate === 'string'
          ? this.dateToUtcWithDaylightPipe.transform(new Date(this.formGroup.value.completionDate))
          : this.dateToUtcWithDaylightPipe.transform(this.formGroup.value.completionDate);

      this.formGroup.controls.completionDate.setValue(utcDateInCstEquivalent);

      // Adding
      if (modelIdx < 0) {
        this.formGroup.controls.secondary.setValue(this.secondary);
        // eslint-disable-next-line id-blacklist
        this.formGroup.value.number = this.getNumber();
        this.milestones.push(this.formGroup.value);
      }
      // Updating
      else {
        this.milestones[modelIdx].completionDate = this.formGroup.value.completionDate;
        this.milestones[modelIdx].benchmarkObjectiveMilestone = this.formGroup.value.benchmarkObjectiveMilestone;
        this.milestones[modelIdx].value = this.formGroup.value.value;
      }
      this.milestones = [...this.milestones]; // To show up on the table as a change
      this.resetForm();
      this.formRef.resetForm();
      this.updated.emit(this.milestones);
    }
  }

  onEdit(is: QuantifiableDataMilestone) {
    this.model = is;
    this.initializeForm();
    this.edit.emit();
  }

  onDelete(is: QuantifiableDataMilestone) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion: 'Clicking Yes will remove the selected milestone.',
      },
    });

    dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        const modelIdx = this.milestones.indexOf(is);
        this.milestones = [...this.milestones.slice(0, modelIdx), ...this.milestones.slice(modelIdx + 1)];
        this.updated.emit(this.milestones);
      }
    });
  }

  onCancel() {
    this.resetForm();
    this.canceled.emit(true);
  }

  getNumber() {
    return this.model ? this.model.number : this.initialGoalNumber + 1 + this.dataSource.data?.length;
  }

  getCompletionDateText(date) {
    return this.datePipe.transform(date, this.shortDateFormat);
  }

  private resetForm() {
    this.model = null;
    this.formGroup.controls.number.setValue(null);
    this.formGroup.controls.completionDate.setValue(null);
    this.formGroup.controls.benchmarkObjectiveMilestone.setValue(null);
    this.formGroup.controls.value.setValue(null);
  }

  private initializeForm() {
    this.formGroup.reset(this.model);
  }
}
