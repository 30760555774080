import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { BaseFieldComponent } from '../base-field';
import { FormGroupDirective, ControlContainer } from '@angular/forms';
import { LoggerService } from '../../../services/logger/logger.service';

@Component({
  selector: 'app-overwrite-mat-checkbox',
  templateUrl: './overwrite-mat-checkbox.component.html',
  styleUrls: ['./overwrite-mat-checkbox.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class OverwriteMatCheckboxComponent extends BaseFieldComponent implements OnInit, OnChanges {
  @Input()
  sourceData: { [key: string]: any };

  // Optional input if source field name different from dest field name
  @Input()
  sourceFieldName: string;

  dataSourceValue: any;
  undoValue: string;

  constructor(parentForm: FormGroupDirective, logger: LoggerService) {
    super(parentForm, logger);
  }

  ngOnInit(): void {
    super.ngOnInit();
    const key = this.sourceFieldName || this.controlName;
    this.dataSourceValue = this.sourceData[key];
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  replaceWithSourceValue() {
    this.undoValue = this.control.value;
    this.control.setValue(this.dataSourceValue);
    this.control.markAsTouched();
    this.control.markAsDirty();
    this.control.updateValueAndValidity();

    const undoClearer = this.control.valueChanges.subscribe(() => {
      this.undoValue = null;
      undoClearer.unsubscribe();
    });
  }

  undoReplaceWithSourceValue() {
    this.control.setValue(this.undoValue);
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
    this.undoValue = null;
  }
}
