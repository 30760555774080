import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CourseActivity } from '../models/course-activity';
import { OperationResult } from 'src/app/shared/models/operation-result';

@Injectable({
  providedIn: 'root',
})
export class CourseActivityService {
  constructor(private http: HttpClient) {}

  get(learnerId: string) {
    return this.http.get<CourseActivity[]>(`api/learners/${learnerId}/course-activities`);
  }

  create(learnerId: string, dto) {
    return this.http.post(`api/learners/${learnerId}/course-activities`, dto);
  }

  update(learnerId: string, dto) {
    return this.http.put(`api/learners/${learnerId}/course-activities/${dto.id}`, dto);
  }

  delete(learnerId: string, dto) {
    return this.http.post<OperationResult>(`api/learners/${learnerId}/course-activities/${dto.id}/delete`, {});
  }
}
