import { formatDate } from '@angular/common';
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CalendarOptions, EventClickArg, EventDef } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { MeetingStatus } from '../../shared/models/family-meeting-status';
import { TodoActionType, TodoRead } from '../user-todo-list/todo-models';
import { TodoService } from '../user-todo-list/todo.service';

@Component({
  selector: 'app-user-calendar',
  templateUrl: './user-calendar.component.html',
  styleUrls: ['./user-calendar.component.scss'],
})
export class UserCalendarComponent implements OnInit {
  @Input() readOnly = false;
  @ViewChild('eventDetailContainer', { static: false })
  eventDetailContainer: ElementRef<any>;
  @ViewChild('eventDetailScrollZone', { static: false })
  eventDetailScrollZone: ElementRef<any>;
  @Input()
  uniqueLearnerDistricts: KeyValuePair[];
  todos: TodoRead[];
  currentTodo: TodoRead;
  meetingStatus = MeetingStatus;

  events: { title: string; date: Date }[];

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prevYear, prev, today, next, nextYear',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay',
    },
    plugins: [dayGridPlugin, timeGridPlugin],
    initialView: 'dayGridMonth',
    weekends: true,
    editable: false,
    selectable: false,
    selectMirror: false,
    dayMaxEvents: false,
    allDaySlot: true,
    slotLabelInterval: '24:00',
    allDayText: '',
    height: 'auto',
    eventClick: this.onEventClick.bind(this),
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short',
    },
    timeZone: 'America/Chicago',
    displayEventTime: false,
    validRange: {
      start: '1900-01-01',
    },
  };
  currentEvent: EventDef = null;
  actionTypesForHiddenTimeEvent = [
    TodoActionType.PeriodicReviewDue,
    TodoActionType.AnnualReviewDue,
    TodoActionType.ReevaluationDue,
    TodoActionType.IEPReviewDue,
  ];

  constructor(private todoService: TodoService, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.todoService.todosUpdated$.subscribe(this.getTodos.bind(this));
    this.getTodos();
  }

  onEventClick(arg: EventClickArg) {
    this.currentEvent = null;
    this.currentTodo = null;
    if (arg.event.extendedProps.actionType === TodoActionType.FamilyMeeting) {
      this.currentEvent === arg.event._def ? (this.currentEvent = null) : (this.currentEvent = arg.event._def);
      this.updateCurrentTodo();
      this.scrollToEvent();
    }
  }

  scrollToEvent() {
    if (this.currentEvent) {
      setTimeout(() => {
        this.renderer.setStyle(
          this.eventDetailScrollZone.nativeElement,
          'margin-top',
          this.eventDetailContainer.nativeElement.style.height
        );

        this.eventDetailScrollZone.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
          alignToTop: true,
        });
      }, 200);
    }
  }

  refreshData(removeEvent: boolean) {
    this.getTodos();

    // For when a meeting is cancelled or rescheduled
    if (removeEvent) {
      this.setEventToNull();
    }
  }

  setEventToNull() {
    this.currentEvent = null;
  }

  private updateCurrentTodo() {
    this.currentTodo = this.todos.find((x) => x.familyMeeting?.id === this.currentEvent?.extendedProps?.meeting?.id);
  }

  private async getTodos(): Promise<void> {
    this.todos = await this.todoService.get().toPromise();
    this.filterDistricts('');
  }

  filterDistricts(districtId: string) {
    this.calendarOptions.events = this.todos
      .filter((t) => t.showOnCalendar && (!districtId || t.attendingDistrictId === districtId))
      .map((t) => ({
        title: `${this.getEventTime(t.dueDateCst, t.actionType)} ${t.title ? t.title : ''}
          ${t.title && t.learnerName ? ' - ' : ''}
          ${t.learnerName ? t.learnerName : ''}`,
        date: t.dueDateCst,
        actionType: t.actionType,
        caseId: t.caseId,
        learnerId: t.learnerId,
        intakeType: t.intakeType,
        meeting: t.familyMeeting,
        familyMembers: t.familyMembers,
        className:
          t.status === this.meetingStatus.Cancelled
            ? ['text-line-through']
            : this.isHiddenTimeEvent(t.actionType)
            ? ['disabled-calendar-event']
            : null,
      }));
    this.updateCurrentTodo();
  }

  getEventTime(dateTime: Date, actionType: TodoActionType) {
    if (this.isHiddenTimeEvent(actionType)) {
      return '';
    }
    return formatDate(dateTime, 'h:mma', 'en-US').toLowerCase();
  }

  isHiddenTimeEvent(actionType: TodoActionType): boolean {
    if (this.actionTypesForHiddenTimeEvent.some((x) => x === actionType)) {
      return true;
    }
    return false;
  }

  get currentTodoTitle() {
    return `${this.currentTodo.title ? this.currentTodo.title : ''}
          ${this.currentTodo.title && this.currentTodo.learnerName ? ' - ' : ''}
          ${this.currentTodo.learnerName ? this.currentTodo.learnerName : ''}`;
  }
}
