<form [formGroup]="formGroup" (ngSubmit)="onUpdateModel()" *ngIf="combinedGoal ? model : true">
  <div class="row align-items-start">
    <div class="col-md-12 col-lg-6 col-xl-4">
      <label *ngIf="combinedGoal; else ratingEditor" for="rating"> Rating Level: {{ formGroup.get('rating').value }} </label>
      <ng-template #ratingEditor>
        <app-number-field formControlName="rating" id="rating" label="Rating Level" [min]="0"></app-number-field>
      </ng-template>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-8">
      <app-textarea
        label="Description"
        formControlName="description"
        [isBankableField]="true"
        [bankableFieldId]="bankableFieldIds.iepGoals.quantData.scaleLevelDescription"
      ></app-textarea>
    </div>

    <div class="col-md-12 display-flex justify-content-end mb-2">
      <button mat-raised-button attr.aria-label="{{ model ? 'Update' : 'Add' }}" color="primary" type="submit" tabindex="0">
        {{ model ? 'Update' : 'Add' }}
      </button>
    </div>
  </div>
</form>
<ng-container *ngIf="scaleRatings && dataSource.data.length > 0">
  <div class="table-overflow">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0 w-100">
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="text-right"></th>
        <td mat-cell *matCellDef="let element" class="action--position-fix">
          <button aria-label="Edit Icon" *ngIf="combinedGoal" (click)="onEdit(element)" mat-icon-button color="primary" tabindex="0">
            <mat-icon>edit</mat-icon>
          </button>
          <button
            *ngIf="!combinedGoal"
            aria-label="Quick Access Menu"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            color="primary"
            tabindex="0"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <button
            type="button"
            mat-icon-button
            aria-label="Warning"
            color="warn"
            *ngIf="!isRatingCompleted(element)"
            class="icon-incomplete"
            tabindex="0"
          >
            <mat-icon aria-label="Warning">warning</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item aria-label="Edit Icon" (click)="onEdit(element)" tabindex="0">Edit</button>
            <button *ngIf="!combinedGoal" mat-menu-item aria-label="Delete" (click)="onDelete(element)" tabindex="0">
              <span class="text-danger"> Delete </span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <ng-container matColumnDef="rating">
        <th mat-header-cell *matHeaderCellDef>Rating</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            class="mr-1"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="element?.priorVersionId"
            [isActive]="element?.isActive"
            [changedVersionText]="element.rating"
            [currentVersionText]="element?.priorVersion?.rating"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">
          <app-compare-amendment-output
            class="mr-1"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="element?.priorVersionId"
            [isActive]="element?.isActive"
            [changedVersionText]="element.description"
            [currentVersionText]="element?.priorVersion?.description"
            [currentPrefixText]="element?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </td>
      </ng-container>

      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef colspan="7">
          <i>No results...</i>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr [hidden]="dataSource?.data?.length > 0" mat-footer-row *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"></tr>
    </table>
  </div>
</ng-container>
