import { FamilyMeetingRead, IntakeType } from 'src/app/shared/models/case';
import { MeetingStatus } from '../../shared/models/family-meeting-status';
import { FamilyMember } from '../../shared/models/learner';

export interface TodoRead {
  id: string;
  learnerName: string;
  learnerId: string;
  attendingDistrictId: string;
  intakeType: IntakeType;
  userId: string;
  title?: string;
  showOnCalendar: boolean;
  dueDate: Date;
  dueDateCst?: Date;
  actionType: TodoActionType;
  caseId?: string;
  actionLink: string;
  createdOn: Date;
  familyMeeting: FamilyMeetingRead;
  familyMembers: FamilyMember[];
  status: MeetingStatus;
}

export interface TodoCreate {
  userId: string;
  caseId: string;
  title: string;
  showOnCalendar: boolean;
  dueDate: Date;
}

export interface TodoUpdate {
  id: string;
  isCompleted: boolean;
}

export enum TodoActionType {
  None = 'None',
  User = 'User',
  FamilyMeeting = 'FamilyMeeting',
  PriorWrittenNotice = 'PriorWrittenNotice',
  AssignServiceCoordinator = 'AssignServiceCoordinator',
  ScheduleFamilyMeetingFirstAttempt = 'ScheduleFamilyMeetingFirstAttempt',
  ScheduleFamilyMeetingSecondAttempt = 'ScheduleFamilyMeetingSecondAttempt',
  ScheduleFamilyMeetingThirdAttempt = 'ScheduleFamilyMeetingThirdAttempt',
  SendFamilyLetter = 'SendFamilyLetter',
  CloseEarlyAccessReferral = 'CloseEarlyAccessReferral',
  StartDisabilitySuspectForm = 'StartDisabilitySuspectForm',
  CompleteDisabilitySuspectForm = 'CompleteDisabilitySuspectForm',
  CompletePriorWrittenNoticeDSForm = 'CompletePriorWrittenNoticeDSForm',
  ContactFamilyForDisabilitySuspectCompletion = 'ContactFamilyForDisabilitySuspectCompletion',
  ContactFamilyForDisabilitySuspectCompletionSecondAttempt = 'ContactFamilyForDisabilitySuspectCompletionSecondAttempt',
  CompleteFiieForm = 'CompleteFiieForm',
  SignFiieForm = 'SignFiieForm',
  FiiePwnCreationConfirmation = 'FiiePwnCreationConfirmation',
  EvaluationConsentApproved = 'EvaluationConsentApproved',
  EvaluationAssignmentChange = 'EvaluationAssignmentChange',
  CompleteIEP = 'CompleteIEP',
  CompleteInterimIEP = 'CompleteInterimIEP',
  StartIfspPeriodicReview = 'StartIfspPeriodicReview',
  StartIfspAnnualReview = 'StartIfspAnnualReview',
  SendIfspPeriodicReviewPoll = 'SendIfspPeriodicReviewPoll',
  SendIfspAnnualReviewPoll = 'SendIfspAnnualReviewPoll',
  Reevaluation = 'Reevaluation',
  DevelopIEP = 'DevelopIEP',
  AmendIEP = 'AmendIEP',
  CreateIEP = 'CreateIEP',
  DevelopInterimIEP = 'DevelopInterimIEP',
  ReviewFBA = 'ReviewFBA',
  CompleteFBA = 'CompleteFBA',
  ReviewBIP = 'ReviewBIP',
  CreateBIP = 'CreateBIP',
  BipBlockedByEval = 'BipBlockedByEval',
  CompleteBIP = 'CompleteBIP',
  IepServiceEndCode = 'IepServiceEndCode',
  ReviewTrialPlacement = 'ReviewTrialPlacement',
  ScheduleInitialIFSPMeeting = 'ScheduleInitialIFSPMeeting',
  CompleteIfsp = 'CompleteIfsp',
  CompleteIFSPModification = 'CompleteIFSPModification',
  CompleteIEPAmendment = 'CompleteIEPAmendment',
  CompleteMedicalorOtherService = 'CompleteMedicalorOtherService',
  CompleteAnnualReview = 'CompleteAnnualReview',
  CompletePeriodicReview = 'CompletePeriodicReview',
  SendContactLetter = 'SendContactLetter',
  ContactFamilyForConsentForFiie = 'ContactFamilyForConsentForFiie',
  CloseChildFindCase = 'CloseChildFindCase',
  PeriodicReviewDue = 'PeriodicReviewDue',
  AnnualReviewDue = 'AnnualReviewDue',
  ReevaluationDue = 'ReevaluationDue',
  IEPReviewDue = 'IEPReviewDue',
}
