<div class="w-100 mt-2" *ngIf="documents">
  <div class="table-overflow w-100">
    <table class="w-100" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="friendlyName">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let file">{{ file.title }}</td>
      </ng-container>

      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef>File</th>
        <td mat-cell *matCellDef="let file">
          <a (click)="getDocument(file)" *ngIf="file.url" target="_blank" tabindex="0" role="link" aria-labelledby="">{{
            file.fileName
          }}</a>
          <span *ngIf="!file.url">{{ file.fileName }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let file">
          <button mat-icon-button aria-label="Delete Icon" color="warn" (click)="confirmDeleteDocument(file)" tabindex="0">
            <mat-icon>close</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef colspan="7">
          <i> No Data Uploaded</i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr [hidden]="documents?.length > 0" mat-footer-row *matFooterRowDef="documents?.length === 0 ? ['noResults'] : []"></tr>
    </table>
    <!-- <p *ngIf="!documents || documents.length === 0">No Data Uploaded</p> -->
  </div>
</div>

<ng-template #verifyDelete let-data>
  <h2 mat-dialog-title>Are you sure?</h2>
  <mat-dialog-content>
    <p>Are you sure you wish to delete this file?</p>
    <p>Title: {{ data.title }}</p>
    <p>File Name: {{ data.fileName }}</p>
    <p>Deleted documents cannot be recovered.</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="primary" type="button" mat-raised-button aria-label="No, do not delete" [mat-dialog-close]="true" tabindex="0">
      No, do not delete
    </button>

    <button
      color="primary"
      type="button"
      mat-raised-button
      aria-label="Yes, delete"
      (click)="onDeleteDocument(data)"
      [mat-dialog-close]="true"
      tabindex="0"
    >
      Yes, delete
    </button>
  </mat-dialog-actions>
</ng-template>
