<div class="card__together-container">
  <mat-card class="card__together card__together--top">
    <ng-container [formGroup]="formGroup">
      <div class="row">
        <div class="col-md-12">
          <h4 class="text-primary my-0">Filter By</h4>
        </div>
        <div class="col-md-12 col-lg-4">
          <app-autocomplete label="Service" formControlName="ifspServiceId" [options]="ifspServiceOptions"></app-autocomplete>
        </div>
        <div class="col-md-12 col-lg-4">
          <app-autocomplete label="IFSP" formControlName="ifspId" [options]="ifspOptions"></app-autocomplete>
        </div>
        <div class="col-md-12 col-lg-4">
          <app-autocomplete label="Provider" formControlName="providerId" [options]="ifspServiceProviderOptions"></app-autocomplete>
        </div>

        <div class="col-md-12">
          <app-quick-date
            label="Label"
            formControlName="quickDate"
            [showError]="formGroup.hasError('endDateBeforeStartingDate')"
          ></app-quick-date>
        </div>
      </div>
    </ng-container>
  </mat-card>
  <mat-card class="card__together card__together--bottom">
    <div class="table-overflow app-table" *ngIf="dataSource">
      <table mat-table [dataSource]="dataSource" class="w-100" matSort>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="cell--actions"></th>
          <td mat-cell *matCellDef="let element" class="cell--actions">
            <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewServiceLog(element)" tabindex="0">
              <mat-icon>remove_red_eye</mat-icon>
            </button>

            <ng-container *appAuthForRoles="{ permission: [permissions.CreateEditServiceLog], caseId: element.caseId }">
              <mat-menu #actionMenu="matMenu">
                <ng-container *ngIf="canEdit(element)">
                  <button (click)="onUpdateServiceLog(element)" mat-menu-item aria-label="Edit Icon" tabindex="0">Edit</button>
                  <button (click)="onDeleteServiceLog(element)" mat-menu-item aria-label="Delete" tabindex="0">Delete</button>
                </ng-container>

                <button
                  *ngIf="!canEdit(element)"
                  mat-menu-item
                  aria-label="Add Service to Session"
                  (click)="onUpdateServiceLog(element, true)"
                  tabindex="0"
                >
                  Add Service to Session
                </button>

                <button
                  *ngIf="element.viewInterventionPlan"
                  (click)="onViewOutput(element.serviceLogId)"
                  mat-menu-item
                  aria-label="Print Family Intervention Plan"
                  tabindex="0"
                >
                  Print Family Intervention Plan
                </button>
              </mat-menu>

              <button mat-icon-button aria-label="Quick Access Menu" color="primary" [matMenuTriggerFor]="actionMenu" tabindex="0">
                <mat-icon>more_vert</mat-icon>
              </button>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="sessionDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.sessionDate | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="startTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Time</th>
          <td mat-cell *matCellDef="let element">
            {{ element.startTime }}
          </td>
        </ng-container>

        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
          <td mat-cell *matCellDef="let element">
            {{ getTimeDuration(element) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="serviceId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Service</th>
          <td mat-cell *matCellDef="let element">
            {{ getServiceName(element.serviceId) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="provider">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Provider</th>
          <td mat-cell *matCellDef="let element">
            {{ element.provider }}
          </td>
        </ng-container>

        <ng-container matColumnDef="ifspDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>IFSP Date</th>
          <td mat-cell *matCellDef="let element">
            {{ getIfspDate(element) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No results...</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          [hidden]="dataSource?.data?.length > 0"
          mat-footer-row
          *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
          class="example-second-footer-row"
        ></tr>
      </table>
      <mat-paginator [dataSource]="dataSource"></mat-paginator>
    </div>
  </mat-card>
</div>
