import { AdditionalInformationModalComponent } from 'src/app/shared/modals/additional-information-modal/additional-information-modal.component';
import { openPdfWindow } from 'src/app/shared/windowHelpers';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CaseSummary, QuickLinkDetails } from 'src/app/shared/models/case';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { AppPermissions } from 'src/app/permissions';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { MdService } from 'src/app/behavior-discipline/md/md.service';
import { FamilyConsentStatus } from 'src/app/shared/models/fiie-consent/family-consent-status';
import { FbaService } from 'src/app/behavior-discipline/fba/services/fbaService.service';
import { Fba, FbaStatus } from 'src/app/behavior-discipline/fba/fba';

@Component({
  selector: 'app-downloadable-forms-list',
  templateUrl: './downloadable-forms-list.component.html',
  styleUrls: ['./downloadable-forms-list.component.scss'],
})
export class DownloadableFormsListComponent implements OnInit, OnDestroy {
  @Input() learnerId = '';
  @Input() caseSummary: CaseSummary;

  labels = {
    fba: 'Create an FBA',
    md: 'Manifestation Determination',
    cai: 'Collection of Additional Information',
  };

  canEditMd: boolean;
  canReadMd: boolean;
  canFba: boolean;
  canCai: boolean;
  private quickLinkDetails: QuickLinkDetails;

  constructor(
    private matDialog: MatDialog,
    public authService: AuthService,
    private caseService: CaseService,
    private reportingService: ReportingService,
    private mdService: MdService,
    private fbaService: FbaService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.caseService.getQuickLinkDetails(this.caseSummary?.id).subscribe((result) => {
      this.quickLinkDetails = result;
      this.canEditMd = this.authService.isAllowedByCaseId(this.caseSummary?.id, null, AppPermissions.EditMD);
      this.canReadMd =
        this.authService.isAllowedByCaseId(this.caseSummary?.id, null, AppPermissions.ReadMD) && !!this.quickLinkDetails.activeMdId;

      this.canFba =
        this.caseSummary?.isActive &&
        this.authService.isAllowedByCaseId(this.caseSummary?.id, null, AppPermissions.FbaFullAccess) &&
        ((this.quickLinkDetails?.fiieConsentSubmitted && this.quickLinkDetails?.fiieConsentStatus === FamilyConsentStatus.Approved) ||
          this.caseSummary?.learner?.isMigrated ||
          this.caseSummary?.learner?.eligibleInLegacySystem ||
          this.caseSummary?.learner?.movingFromOutOfState);
    });

    this.canCai = this.authService.isDataLead || this.authService.isDataManager || this.authService.isSuperAdmin;
  }

  onFunctionalBehaviorAssessmentClicked() {
    if (this.caseSummary.mostRecentFbaId) {
      this.fbaService.getFba(this.caseSummary.mostRecentFbaId).subscribe((fba) => {
        if (this.caseSummary.mostRecentFbaId) {
          if (fba.status === FbaStatus.Finalized) {
            this.router.navigate(['/cases', this.caseSummary.id, 'behavior-discipline', 'fba', this.caseSummary.mostRecentFbaId, 'view']);
          } else {
            this.router.navigate([`cases/${this.caseSummary.id}/behavior-discipline/fba/${this.caseSummary.mostRecentFbaId}/overview`]);
          }
        }
      });
    } else {
      const newFba: Fba = {
        id: null,
        caseId: this.caseSummary.id,
        nonAchieveMembers: [],
        fbaAssessment: null,
        hypothesisStatements: [],
        finalizedOn: null,
        status: FbaStatus.Draft,
        dataCollections: [],
        commonResponses: null,
        commonTriggers: null,
        otherInformationLearned: null,
        bipNeeded: null,
        timeLikelyEngaging: null,
        reviews: [],
      };
      this.fbaService.addFba(this.caseSummary.id, newFba).subscribe((fbaRec) => {
        this.router.navigate([`cases/${this.caseSummary.id}/behavior-discipline/fba/${fbaRec.id}/overview`]);
      });
    }
  }

  onManifestationDeterminationClicked() {
    if (this.quickLinkDetails.activeMdId) {
      if (this.canEditMd) {
        this.goToMd(this.quickLinkDetails.activeMdId);
      } else {
        this.createMdDraft();
      }
    } else {
      if (this.canEditMd) {
        this.createMd();
      }
    }
  }

  private createMdDraft() {
    this.reportingService.createMdOutput(this.quickLinkDetails.activeMdId).subscribe((docId) => {
      openPdfWindow(this.learnerId, docId);
    });
  }

  private createMd() {
    const dialogRef = this.matDialog.open(AreYouSureComponent, {
      data: {
        subQuestion: 'Clicking yes will open a Manifestation Determination for this learner.',
      },
    });

    dialogRef.afterClosed().subscribe((proceed) => {
      if (proceed) {
        this.mdService.createMD(this.caseSummary.id).subscribe((md) => {
          this.goToMd(md.id);
        });
      }
    });
  }

  private goToMd(id: string) {
    this.router.navigate(['/', 'cases', this.caseSummary.id, 'behavior-discipline', 'md', id, 'overview']);
  }

  onAdditionalInformationdClicked(event: Event): void {
    event.stopPropagation();
    this.matDialog.open(AdditionalInformationModalComponent, {
      width: '728px',
      data: {
        id: this.learnerId,
        onSuccess: (documentId) => openPdfWindow(this.learnerId, documentId),
      },
    });
  }

  ngOnDestroy(): void {}
}
