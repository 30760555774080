import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ProgressMonitorComment } from 'src/app/monitoring-progress/models/comment';
import { GoalProgressMonitor, ObjectiveProgressMonitor } from 'src/app/monitoring-progress/models/goal-progress-monitor';
import { ProgressMonitorPhaseLine } from 'src/app/monitoring-progress/models/phase-line';
import { ProgressMonitorItem } from 'src/app/monitoring-progress/models/progress-monitor-item';
import { ProgressMonitorLookupService } from 'src/app/monitoring-progress/services/progress-monitor-lookup.service';
import { MilestoneStatus } from 'src/app/shared/components/quantifiable-data/quantifiable-data';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { decimalValidator } from 'src/app/shared/validators';
import { DateToUtcPipe } from '../../../../../../shared/pipes/date-transform.pipe';

@Component({
  selector: 'app-add-goal-progress',
  templateUrl: './add-goal-progress.component.html',
  styleUrls: ['./add-goal-progress.component.scss'],
})
export class AddGoalProgressComponent implements OnInit {
  @Input() goal: GoalProgressMonitor | ObjectiveProgressMonitor;
  @Input() minDate: Date;
  @Output() submitEvent = new EventEmitter();
  @Output() phaseLineSubmitEvent = new EventEmitter();
  @Output() commentSubmitEvent = new EventEmitter();
  @Output() statusChangeEvent = new EventEmitter();

  today = new Date();
  shortDateFormat = shortDateFormat;

  formGroup = this.fb.group({
    id: '',
    date: ['', Validators.required],
    primaryValue: null,
    secondaryValue: null,
    primaryNote: [''],
    secondaryNote: [''],
    commentNote: [''],
    commentNoteOther: [''],
    phaseLineNote: [''],
    phaseLineNoteOther: [''],
  });

  milestoneStatusFormGroup = this.fb.group({
    status: '',
  });

  displayedColumnsScaleRatings = ['rating', 'description'];
  displayedColumnsMilestones = ['endDate', 'target'];

  statusOptions: KeyValuePair[] = [
    new KeyValuePair(MilestoneStatus.inactive, 'Pending/Inactive'),
    new KeyValuePair(MilestoneStatus.active, 'Active'),
    new KeyValuePair(MilestoneStatus.met, 'Complete'),
  ];
  milestoneStatus = MilestoneStatus;

  get isOtherPhaseLineSelected() {
    const phaseNote = this.formGroup.get('phaseLineNote');
    return phaseNote && phaseNote.value && this.progressMonitorLookupService.lookupOptions.find((x) => x.id === phaseNote.value)?.isOther;
  }

  get isOtherCommentSelected() {
    const commentNote = this.formGroup.get('commentNote');
    return (
      commentNote && commentNote.value && this.progressMonitorLookupService.lookupOptions.find((x) => x.id === commentNote.value)?.isOther
    );
  }

  get isActive() {
    return this.goal.status === MilestoneStatus.active;
  }

  get phaseLineOptions() {
    return this.progressMonitorLookupService.phaseLineOptions;
  }

  get commentOptions() {
    return this.progressMonitorLookupService.commentOptions;
  }

  constructor(private fb: FormBuilder, private progressMonitorLookupService: ProgressMonitorLookupService) {}

  ngOnInit(): void {
    this.setupFormGroupValidation();
    this.watchFormGroup();
    if (this.goal.isObjective) {
      this.milestoneStatusFormGroup.patchValue({ status: this.goal.status });
    }
  }

  onSubmit() {
    if (this.formGroup.get('primaryValue').value !== null) {
      this.onDataPointSubmit();
    }
    if (this.formGroup.get('phaseLineNote').value) {
      this.onPhaseLineSubmit();
    }
    if (this.formGroup.get('commentNote').value) {
      this.onCommentSubmit();
    }
    this.formGroup.reset();
  }

  private onDataPointSubmit() {
    const primaryDataPoint = {
      date: new DateToUtcPipe().transform(new Date(this.formGroup.get('date').value)),
      value: this.formGroup.get('primaryValue').value,
      note: this.formGroup.get('primaryNote').value,
      iepGoalQuantifiableMeasurementId: this.goal.primaryMeasurement.iepGoalQuantifiableMeasurementId,
    } as ProgressMonitorItem;
    if (this.goal.secondaryMeasurement) {
      const secondaryDataPoint = {
        date: new DateToUtcPipe().transform(new Date(this.formGroup.get('date').value)),
        value: this.formGroup.get('secondaryValue').value,
        note: this.formGroup.get('secondaryNote').value,
        iepGoalQuantifiableMeasurementId: this.goal.secondaryMeasurement.iepGoalQuantifiableMeasurementId,
      } as ProgressMonitorItem;
      this.submitEvent.emit({
        primary: primaryDataPoint,
        secondary: secondaryDataPoint,
      });
    } else {
      this.submitEvent.emit({
        primary: primaryDataPoint,
      });
    }
  }

  private onPhaseLineSubmit() {
    const phaseLine = {
      date: new DateToUtcPipe().transform(new Date(this.formGroup.get('date').value)),
      progressMonitorLookupId: this.formGroup.get('phaseLineNote').value,
      noteOther: this.formGroup.get('phaseLineNoteOther').value,
      goalId: !this.goal.isObjective ? this.goal.id : null,
      milestoneId: this.goal.isObjective ? this.goal.id : null,
    } as ProgressMonitorPhaseLine;
    phaseLine.label = this.progressMonitorLookupService.lookupOptions.find((x) => x.id === phaseLine.progressMonitorLookupId)?.label;
    this.phaseLineSubmitEvent.emit(phaseLine);
  }

  private onCommentSubmit() {
    const comment = {
      date: new DateToUtcPipe().transform(new Date(this.formGroup.get('date').value)),
      progressMonitorLookupId: this.formGroup.get('commentNote').value,
      noteOther: this.formGroup.get('commentNoteOther').value,
      goalId: !this.goal.isObjective ? this.goal.id : null,
      milestoneId: this.goal.isObjective ? this.goal.id : null,
    } as ProgressMonitorComment;
    comment.label = this.progressMonitorLookupService.lookupOptions.find((x) => x.id === comment.progressMonitorLookupId)?.label;
    this.commentSubmitEvent.emit(comment);
  }

  private setupFormGroupValidation() {
    this.formGroup.get('primaryValue').setValidators([Validators.required, decimalValidator, Validators.min(0)]);
    this.formGroup.get('phaseLineNote').setValidators(Validators.required);
    this.formGroup.get('commentNote').setValidators(Validators.required);

    if (this.goal.secondaryMeasurement) {
      this.formGroup.get('secondaryValue').setValidators([Validators.required, decimalValidator, Validators.min(0)]);
    }
  }

  private watchFormGroup() {
    this.formGroup.valueChanges.subscribe((formValue) => {
      if (this.goal.secondaryMeasurement) {
        this.validateWithSecondaryMeasurement(formValue);
      } else {
        this.validateWithPrimaryMeasurement(formValue);
      }
    });
  }

  private clearValidators(controls: Array<string>) {
    controls.forEach((control) => {
      this.formGroup.get(control).clearValidators();
      this.formGroup.get(control).updateValueAndValidity({ emitEvent: false });
    });
  }

  private validateWithPrimaryMeasurement(formValue) {
    if (formValue.primaryValue !== null) {
      this.clearValidators(['phaseLineNote', 'commentNote']);
    } else if (formValue.phaseLineNote) {
      this.clearValidators(['primaryValue', 'commentNote']);
      if (formValue.phaseLineNote.includes('Other') && !formValue.phaseLineNoteOther) {
        this.formGroup.get('phaseLineNoteOther').setValidators(Validators.required);
        this.formGroup.get('phaseLineNoteOther').updateValueAndValidity({ emitEvent: false });
      } else {
        this.clearValidators(['phaseLineNoteOther']);
      }
    } else if (formValue.commentNote) {
      this.clearValidators(['primaryValue', 'phaseLineNote']);
      if (formValue.commentNote.includes('Other') && !formValue.commentNoteOther) {
        this.formGroup.get('commentNoteOther').setValidators(Validators.required);
        this.formGroup.get('commentNoteOther').updateValueAndValidity({ emitEvent: false });
      } else {
        this.clearValidators(['commentNoteOther']);
      }
    } else {
      this.formGroup.get('primaryValue').setValidators([Validators.required, decimalValidator, Validators.min(0)]);
      this.formGroup.get('primaryValue').updateValueAndValidity({ emitEvent: false });

      this.formGroup.get('phaseLineNote').setValidators([Validators.required]);
      this.formGroup.get('phaseLineNote').updateValueAndValidity({ emitEvent: false });

      this.formGroup.get('commentNote').setValidators([Validators.required]);
      this.formGroup.get('commentNote').updateValueAndValidity({ emitEvent: false });
    }
  }

  private validateWithSecondaryMeasurement(formValue) {
    if (formValue.primaryValue !== null && formValue.secondaryValue !== null) {
      this.clearValidators(['phaseLineNote', 'commentNote']);
    } else if (formValue.primaryValue) {
      this.formGroup.get('secondaryValue').setValidators(Validators.required);
      this.formGroup.get('secondaryValue').updateValueAndValidity({ emitEvent: false });
    } else if (formValue.secondaryValue) {
      this.formGroup.get('primaryValue').setValidators(Validators.required);
      this.formGroup.get('primaryValue').updateValueAndValidity({ emitEvent: false });
    } else if (formValue.phaseLineNote) {
      this.clearValidators(['primaryValue', 'secondaryValue', 'commentNote']);
      if (formValue.phaseLineNote.includes('Other') && !formValue.phaseLineNoteOther) {
        this.formGroup.get('phaseLineNoteOther').setValidators(Validators.required);
        this.formGroup.get('phaseLineNoteOther').updateValueAndValidity({ emitEvent: false });
      } else {
        this.clearValidators(['phaseLineNoteOther']);
      }
    } else if (formValue.commentNote) {
      this.clearValidators(['primaryValue', 'secondaryValue', 'phaseLineNote']);
      if (formValue.commentNote.includes('Other') && !formValue.commentNoteOther) {
        this.formGroup.get('commentNoteOther').setValidators(Validators.required);
        this.formGroup.get('commentNoteOther').updateValueAndValidity({ emitEvent: false });
      } else {
        this.clearValidators(['commentNoteOther']);
      }
    } else {
      this.formGroup.get('primaryValue').setValidators([Validators.required, decimalValidator, Validators.min(0)]);
      this.formGroup.get('primaryValue').updateValueAndValidity({ emitEvent: false });

      if (this.goal.secondaryMeasurement) {
        this.formGroup.get('secondaryValue').setValidators([Validators.required, decimalValidator, Validators.min(0)]);
        this.formGroup.get('secondaryValue').updateValueAndValidity({ emitEvent: false });
      }

      this.formGroup.get('phaseLineNote').setValidators([Validators.required]);
      this.formGroup.get('phaseLineNote').updateValueAndValidity({ emitEvent: false });

      this.formGroup.get('commentNote').setValidators([Validators.required]);
      this.formGroup.get('commentNote').updateValueAndValidity({ emitEvent: false });
    }
  }
}
