<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>IFSP Service</h3>

<div mat-dialog-content class="pt-3 pb-5">
  <div class="row">
    <div class="col-md-12">
      <h3>Outcome(s)</h3>
      <ul>
        <li *ngFor="let outcome of data.service.outcomes">{{ outcome }}</li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h3>Service</h3>
      <p>
        <label>Service</label><br />
        {{ data.service.service || '-' }}
      </p>
      <h3>Service Details</h3>
      <p>
        <label>How services are provided</label><br />
        {{ data.service.how || '-' }}
      </p>
      <p>
        <label>With Whom</label><br />
        {{ data.service.with || '-' }}
      </p>
      <p>
        <label>Agency</label><br />
        {{ data.service.agency || '-' }}
      </p>
      <p>
        <label>Provider</label><br />
        {{ data.service.provider || '-' }}
      </p>
      <p>
        <label>Projected Start Date</label><br />
        {{ (data.service.startDate | dateFormat) || '-' }}
      </p>
      <p>
        <label>Location</label><br />
        {{ data.service.location || '-' }}
      </p>
      <p *ngIf="data.service.justificationLocation">
        <label>Justification</label><br />
        {{ data.service.justificationLocation || '-' }}
      </p>
      <p>
        Length per session
        <strong>{{ data.service.length || '-' }}</strong> minutes
      </p>
      <p>
        How often?
        <span *ngIf="data.service.frequencyNumber > 1">{{ data.service.frequencyNumber || '-' }} time(s)</span>
        <strong>{{ data.service.frequencyPeriod || '-' }}</strong>
      </p>
    </div>
  </div>
</div>

<div mat-dialog-actions class="actions text-right">
  <button mat-raised-button aria-label="Delete Icon" color="primary" (click)="closeModal()" tabindex="0">Close</button>
</div>
