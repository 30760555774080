import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransitionCode } from '../../models/transitionCode';

@Injectable({
  providedIn: 'root',
})
export class TransitionCodesService {
  constructor(private http: HttpClient) {}

  get() {
    return this.http.get<TransitionCode[]>('api/transition-codes');
  }
}
