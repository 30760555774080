<form [formGroup]="formGroup">
  <div class="row align-items-baseline">
    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-date-picker label="Date" controlName="date"></app-date-picker>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-4">
      <p class="my-0">
        <strong> Target Value: {{ 15 }} </strong>
      </p>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-number-field label="Value" formControlName="value" [min]="0"></app-number-field>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-textarea label="Monitoring Notes" formControlName="notes" [rows]="2"></app-textarea>
    </div>
  </div>

  <div class="action__row action__row--between">
    <div>
      <ng-container *ngIf="hasPhaseGraph">
        <button
          mat-raised-button
          attr.aria-label="{{ phaseLineVisible ? 'Close' : 'Add' }} Phase Line"
          color="accent"
          type="button"
          (click)="togglePhaseLineForm()"
          tabindex="0"
        >
          {{ phaseLineVisible ? 'Close' : 'Add' }} Phase Line
        </button>
        <button
          mat-raised-button
          attr.aria-label=" {{ commentsVisible ? 'Close' : 'Add' }} Comments"
          color="accent"
          type="button"
          (click)="toggleCommentsForm()"
          tabindex="0"
        >
          {{ commentsVisible ? 'Close' : 'Add' }} Comments
        </button>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="hasPhaseGraph && phaseLineVisible">
    <form [formGroup]="phaseLineGroup" class="mt-2">
      <div class="row">
        <div class="col-md-12">
          <app-autocomplete-chips label="Phase Line" formControlName="phaseLine" [options]="options"></app-autocomplete-chips>
        </div>
        <div class="col-md-12" *ngIf="isOtherSelected()">
          <app-textarea label="Other" formControlName="other"></app-textarea>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="hasPhaseGraph && commentsVisible">
    <form [formGroup]="commentsGroup">
      <div class="row">
        <div class="col-md-12">
          <app-textarea label="Comments" formControlName="notes"></app-textarea>
        </div>
      </div>
    </form>
  </ng-container>

  <div
    class="display-flex justify-content-end"
    [ngClass]="{
      'position-fix--submit': hasPhaseGraph && !commentsVisible && !phaseLineVisible
    }"
  >
    <button mat-raised-button aria-label="Complete" color="primary" (click)="onSubmit()" tabindex="0">Complete</button>
  </div>
</form>
