<mat-label
  class="display-block mb-1"
  [ngClass]="{
    asterisk_input: control.hasError('required') && !hideAsterisk
  }"
  >{{ label }}</mat-label
>
<div class="display-flex justify-content-between align-items-center">
  <mat-button-toggle-group
    attr.aria-label="label"
    [multiple]="multiple"
    [disabled]="readOnly || disabled"
    [ngClass]="{
      'border-none': noGroupBorder,
      'button-toggle-group--full-width': fullWidth
    }"
  >
    <mat-button-toggle
      *ngFor="let option of optionsBacking"
      [value]="option.value"
      [checked]="option.selected"
      class="{{ className ? className : '' }}"
      (click)="toggleOption(option.key)"
    >
      {{ option.value }}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <ng-content></ng-content>
</div>
<mat-error>
  <app-field-error-display [control]="control"></app-field-error-display>
</mat-error>
