import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EvaluationDomain } from '../../models/evaluation-domain';

@Injectable({
  providedIn: 'root',
})
export class EvaluationDomainService {
  private baseUrl = 'api/evaluationdomain';

  constructor(private http: HttpClient) {}

  get(): Observable<EvaluationDomain[]> {
    return this.http.get<EvaluationDomain[]>(this.baseUrl);
  }
}
