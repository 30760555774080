import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { IepActivitySupportDto } from '../models/iep-activity-support';

@Injectable({
  providedIn: 'root',
})
export class IepActivitySupportService {
  public activitySupportUpdated = new Subject<void>();
  activitySupportUpdated$ = this.activitySupportUpdated.asObservable();

  public activitySupportClose = new Subject<void>();
  activitySupportClose$ = this.activitySupportClose.asObservable();

  public activitySupportAddNew = new Subject<void>();
  activitySupportAddNew$ = this.activitySupportAddNew.asObservable();

  public setAmendingActivitySupport = new Subject<any>();
  setAmendingActivitySupport$ = this.setAmendingActivitySupport.asObservable();

  constructor(private readonly http: HttpClient, spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/.*\/iepactivitysupport.*/));
  }

  private baseUrl(iepId: string, id = '') {
    return `api/${iepId}/iepactivitysupport/${id}`;
  }

  getActivitiesSupports(iepId: string) {
    return this.http.get<IepActivitySupportDto[]>(this.baseUrl(iepId));
  }

  getAllForAmendment(iepId: string, amendmentId = '', isCompleted?: boolean) {
    amendmentId ||= '';
    let params = new HttpParams();

    if (isCompleted) params = params.set('isCompleted', isCompleted);

    return this.http.get<IepActivitySupportDto[]>(this.baseUrl(iepId) + `amend/${amendmentId}`, { params });
  }

  getAuditLogs(iepId: string, id: string) {
    return this.http.get<any[]>(this.baseUrl(iepId) + `${id}/getAuditLogs`);
  }

  update(iepId: string, model: IepActivitySupportDto) {
    return this.http.put<any>(this.baseUrl(iepId), model).pipe(tap(() => this.activitySupportUpdated.next()));
  }

  updateProviders(iepId: string, id: string, model: any) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/updateProviders', model);
  }

  updateTrialPlacement(iepId: string, id: string, includedInTrialPlacement: boolean) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/update-trial-placement', { includedInTrialPlacement });
  }

  amend(iepId: string, id: string, amendmentId: string) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/amend/' + amendmentId, null).pipe(tap(() => this.activitySupportUpdated.next()));
  }

  endActivitySupport(iepId: string, id: string, model: any) {
    return this.http.put<any>(this.baseUrl(iepId, id) + '/end', model).pipe(tap(() => this.activitySupportUpdated.next()));
  }

  deleteActivitySupport(iepId: string, id: string, isAmendment = false) {
    return this.http.put(this.baseUrl(iepId, id) + '/' + isAmendment, null).pipe(tap(() => this.activitySupportUpdated.next()));
  }
}
