import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Intake, ScreeningResult } from 'src/app/evaluation/models/intake';
import { ChildInformation } from 'src/app/evaluation/shared/models/child-information';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { EvaluationDomain } from 'src/app/shared/models/evaluation-domain';
import { EvaluationDomainService } from 'src/app/shared/services/evaluation-domains/evaluation-domain.service';

@Component({
  selector: 'app-part-c-consent-postref-screening-result',
  templateUrl: './part-c-consent-postref-screening-result.component.html',
  styleUrls: ['./part-c-consent-postref-screening-result.component.scss'],
})
export class PartCConsentPostrefScreeningResultComponent implements OnInit {
  childInfo: ChildInformation;
  intake: Intake;
  displayName = '';
  screeningResults = ScreeningResult;
  developmentalAreas: EvaluationDomain[];
  shortDateFormat = shortDateFormat;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly title: Title,
    private evaluationDomainService: EvaluationDomainService
  ) {}

  ngOnInit(): void {
    this.intake = this.route.snapshot.data.intake as Intake;
    this.childInfo = this.intake?.childInfo;
    this.setDisplayName();
    this.title.setTitle('Post-Referral Screening Results');

    this.evaluationDomainService.get().subscribe((areas) => {
      this.developmentalAreas = areas.filter((x) => !x.isPartB);
    });
  }

  getDevelopmentalAreaLabel(id) {
    return this.developmentalAreas?.find((d) => d.id === id)?.label;
  }

  hasConcerns() {
    return (this.intake?.nextSteps?.postReferralScreeningResults as string) === 'MayHaveConcerns';
  }

  hasNoConcerns() {
    return (this.intake?.nextSteps?.postReferralScreeningResults as string) === 'NoConcerns';
  }

  private setDisplayName() {
    if (!this.childInfo || !this.childInfo.firstName || !this.childInfo.lastName) {
      return;
    }

    this.displayName = `${this.childInfo.firstName}${this.childInfo.middleName ? ` ${this.childInfo.middleName}` : ''} ${
      this.childInfo.lastName
    }`;
  }

  print() {
    window.print();
  }
}
