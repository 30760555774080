import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { conditionalValidator } from 'src/app/shared/validators';
import { Goal } from '../../models/grade-book';

@Component({
  selector: 'app-add-progress',
  templateUrl: './add-progress.component.html',
  styleUrls: ['./add-progress.component.scss'],
})
export class AddProgressComponent implements OnInit {
  @Input() goal: Goal;
  @Input() formGroup: FormGroup;
  @Input() idPrefix = '';
  today = new Date();

  get isDateRequired() {
    return this.formGroup.get('value').value || this.formGroup.get('note').value;
  }

  get isValueRequired() {
    return this.formGroup.get('date').value || this.formGroup.get('note').value;
  }

  constructor(private readonly fb: FormBuilder) {}

  ngOnInit(): void {
    this.setValidators();
  }

  preventToggle(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
  }

  private setValidators() {
    this.formGroup.get('date').setValidators(conditionalValidator(() => this.isDateRequired, Validators.required));

    this.formGroup.get('value').setValidators(conditionalValidator(() => this.isValueRequired, Validators.required));

    this.formGroup.get('date').valueChanges.subscribe(() => {
      this.formGroup.get('value').updateValueAndValidity({ emitEvent: false });
    });

    this.formGroup.get('value').valueChanges.subscribe(() => {
      this.formGroup.get('date').updateValueAndValidity({ emitEvent: false });
    });

    this.formGroup.get('note').valueChanges.subscribe(() => {
      this.formGroup.get('value').updateValueAndValidity({ emitEvent: false });
      this.formGroup.get('date').updateValueAndValidity({ emitEvent: false });
    });
  }
}
