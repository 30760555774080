import { CaseClosureReason } from '../../models/case-closure-reason';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CaseClosureReasonService {
  baseUrl = 'api/case-closure-reason';

  constructor(private http: HttpClient) {}

  get(): Observable<CaseClosureReason[]> {
    return this.http.get<CaseClosureReason[]>(this.baseUrl);
  }
}
