import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Race } from '../../models/race';

@Injectable({
  providedIn: 'root',
})
export class RaceService {
  constructor(private http: HttpClient) {}

  getRaces(): Observable<Array<Race>> {
    return this.http.get<Array<Race>>('api/races');
  }
}
