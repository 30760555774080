import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  EarlyACCESSFamilyEngagementReportingGuard,
  FamilyEngagementAdministratorReportingGuard,
  SpecialEducationFamilyEngagementReportingGuard,
} from '../auth/auth.guard';
import { SurveyTypes } from './components/models/survey-types';
import { SurveyListComponent } from './components/survey-list/survey-list.component';
import { SurveyReportingDashboardComponent } from './components/survey-reporting-dashboard/survey-reporting-dashboard.component';
import { SurveyAdminComponent } from './components/suvey-admin/survey-admin.component';

const Routes: Routes = [
  {
    path: '',
    component: SurveyListComponent,
  },
  {
    path: 'early-access-dashboard',
    component: SurveyReportingDashboardComponent,
    canActivate: [EarlyACCESSFamilyEngagementReportingGuard],
    data: { surveyType: SurveyTypes.EarlyAccessFamilyEngagementSurvey },
  },
  {
    path: 'special-education-dashboard',
    component: SurveyReportingDashboardComponent,
    canActivate: [SpecialEducationFamilyEngagementReportingGuard],
    data: { surveyType: SurveyTypes.SpecialEducationFamilyEngagementSurvey },
  },
  {
    path: 'survey-admin',
    component: SurveyAdminComponent,
    canActivate: [FamilyEngagementAdministratorReportingGuard],
    data: { surveyType: SurveyTypes.SpecialEducationFamilyEngagementSurvey },
  },
];

@NgModule({
  imports: [RouterModule.forChild(Routes)],
  exports: [RouterModule],
})
export class SurveyRoutingModule {}
