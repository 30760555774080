import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { forkJoin } from 'rxjs';
import { GoalProgressMonitor } from 'src/app/monitoring-progress/models/goal-progress-monitor';
import { ProgressMonitorItem } from 'src/app/monitoring-progress/models/progress-monitor-item';
import { ProgressMonitorBService } from 'src/app/monitoring-progress/services/progress-monitor-b.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { CaseSummary } from 'src/app/shared/models/case';
import { ConfirmationDialogComponent } from 'src/app/shared/services/notification.service';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { AuthService } from '../../../../../../auth/auth.service';
import { DateFormatPipe, DateToUtcPipe } from '../../../../../../shared/pipes/date-transform.pipe';

@Component({
  selector: 'app-combined-progress-data-table-b',
  templateUrl: './combined-progress-data-table-b.component.html',
  styleUrls: ['./combined-progress-data-table-b.component.scss'],
})
export class CombinedProgressDataTableBComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() goal: GoalProgressMonitor;
  @Input() minDate: Date;
  @Input() caseSummary: CaseSummary;
  @Input() enableForms: boolean;
  today = new Date();
  isEditing: boolean;
  shortDateFormat = shortDateFormat;
  displayedColumns = ['actions', 'date', 'primaryValue', 'primaryNote', 'secondaryValue', 'secondaryNote'];

  formGroup = this.fb.group({
    date: [null, Validators.required],
    primaryId: [''],
    primaryValue: [null],
    primaryNote: [''],
    secondaryId: [''],
    secondaryValue: [null],
    secondaryNote: [''],
  });

  get isPortalUser() {
    return this.authService?.isPortalUser;
  }

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private progressMonitorService: ProgressMonitorBService,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.isPortalUser) {
      this.displayedColumns = ['date', 'primaryValue', 'primaryNote', 'secondaryValue', 'secondaryNote'];
    }
  }

  ngAfterViewInit() {
    this.goal.data.sort = this.sort;
    this.goal.data.paginator = this.paginator;
  }

  onEdit(item) {
    this.isEditing = true;
    item.isEditing = true;
    this.formGroup.patchValue(item);
  }

  onUpdate(item, goal) {
    const primaryDto = {
      id: this.formGroup.get('primaryId').value,
      date: new DateToUtcPipe().transform(new Date(this.formGroup.get('date').value)),
      value: this.formGroup.get('primaryValue').value,
      note: this.formGroup.get('primaryNote').value,
    } as ProgressMonitorItem;

    const secondaryDto = {
      id: this.formGroup.get('secondaryId').value,
      date: new DateToUtcPipe().transform(new Date(this.formGroup.get('date').value)),
      value: this.formGroup.get('secondaryValue').value,
      note: this.formGroup.get('secondaryNote').value,
    } as ProgressMonitorItem;

    this.progressMonitorService.update(this.caseSummary.learnerId, primaryDto, secondaryDto).subscribe((res) => {
      item.date = res.primaryDate;
      item.primaryValue = primaryDto.value;
      item.primaryNote = primaryDto.note;
      item.secondaryValue = secondaryDto.value;
      item.secondaryNote = secondaryDto.note;
      this.onCancelEdit(item);
    });
  }

  onDelete(item, goal) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '728px',
      data: {
        title: 'Remove',
        message: 'Are you sure you want to remove this progress item?',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.handleProgressMonitorItemDelete(item, goal);
      }
    });
  }

  onCancelEdit(item) {
    item.isEditing = false;
    this.isEditing = false;
    this.formGroup.reset();
  }

  onViewMore(item) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Date',
        value: new DateFormatPipe().transform(item.date),
      },
      {
        name: 'Value 1',
        value: item.primaryValue,
      },
      {
        name: 'Note 1',
        value: item.primaryNote,
      },
      {
        name: 'Value 2',
        value: item.secondaryValue,
      },
      {
        name: 'Note 2',
        value: item.secondaryNote,
      },
    ];

    openViewMore(this.dialog, modalData);
  }

  private handleProgressMonitorItemDelete(item, goal: GoalProgressMonitor) {
    forkJoin([
      this.progressMonitorService.delete(this.caseSummary.learnerId, item.primaryId),
      this.progressMonitorService.delete(this.caseSummary.learnerId, item.secondaryId),
    ]).subscribe((res) => {
      goal.primaryMeasurement.progressData = goal.primaryMeasurement.progressData.filter((x) => x.id !== item.primaryId);
      goal.secondaryMeasurement.progressData = goal.secondaryMeasurement.progressData.filter((x) => x.id !== item.secondaryId);
      goal.data.data = goal.data.data.filter((x) => x.primaryId !== item.primaryId && x.secondaryId !== item.secondaryId);
    });
  }
}
