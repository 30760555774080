<div class="display-flex align-items-center justify-content-between flex-wrap">
  <mat-button-toggle-group [multiple]="true" class="my-1" aria-labelledby="Toggle an option">
    <mat-button-toggle [checked]="tagEcoAreaToggled" (change)="onTagEcoAreaToggle()" aria-label="Tag ECO Areas Button Toggle">
      Tag ECO Areas
    </mat-button-toggle>
    <mat-button-toggle
      *ngIf="!isPartB"
      [checked]="tagDomainsToggled"
      (change)="onTagEvaluationDomainToggle()"
      aria-label="Tag Developmental Areas Button Toggle"
      >Tag Developmental Areas</mat-button-toggle
    >
    <mat-button-toggle
      *ngIf="evaluationRoutines?.length > 0 && !isRoutine"
      [checked]="tagRoutineToggled"
      (change)="onTagEvaluationRoutineToggle()"
      aria-label="Tag Routines Button Toggle"
      >Tag Routines</mat-button-toggle
    >
    <div *ngIf="!hideNewTags">
      <button (click)="tag(taggedForCategory.ServicesC)" mat-icon-button aria-label="Tag Routines Button" class="text-lg mr-1" tabindex="0">
        <i
          *ngIf="!showTaggedIcon(taggedForCategory.ServicesC)"
          class="fas fa-chalkboard-teacher tag__icon"
          aria-label="Service Tagging Icon"
        ></i>
        <i
          *ngIf="showTaggedIcon(taggedForCategory.ServicesC)"
          class="fas fa-chalkboard-teacher tag__icon text-success mr-1"
          aria-label="Service Tagging Icon"
        ></i>
      </button>
      <button (click)="tag(taggedForCategory.Outcomes)" mat-icon-button aria-label="Goal Tagging Icon" class="text-lg mr-1" tabindex="0">
        <i *ngIf="!showTaggedIcon(taggedForCategory.Outcomes)" class="fas fa-bullseye tag__icon"></i>
        <i *ngIf="showTaggedIcon(taggedForCategory.Outcomes)" class="fas fa-bullseye tag__icon text-success mr-1"></i>
      </button>
      <button
        *ngIf="!showTaggedIcon(taggedForCategory.Pwn)"
        (click)="tag(taggedForCategory.Pwn)"
        mat-raised-button
        aria-label="Tag for Prior Written Notice Button"
        class="mr-1 mb-1"
        tabindex="0"
      >
        Tag for Prior Written Notice
      </button>
      <button
        *ngIf="showTaggedIcon(taggedForCategory.Pwn)"
        (click)="tag(taggedForCategory.Pwn)"
        mat-raised-button
        aria-label="Tag for Prior Written Notice Button"
        class="mr-1 mb-1"
        tabindex="0"
      >
        Untag for Prior Written Notice
      </button>
    </div>
  </mat-button-toggle-group>

  <button
    *ngIf="!isRoutine"
    mat-raised-button
    aria-label="Add New Routine"
    color="accent"
    class="my-1"
    aria-label="Add New Routine Button"
    type="button"
    (click)="onAddRoutine()"
    tabindex="0"
  >
    Add New Routine
  </button>
</div>

<div class="display-block mt-3">
  <ng-container *ngIf="tagEcoAreaToggled">
    <mat-button-toggle
      *ngFor="let ecoArea of ecoAreas"
      [value]="ecoArea.id"
      (change)="onEcoAreaSelect($event)"
      [checked]="isEcoAreaChecked(ecoArea.id)"
      class="mr-2 mb-2 button-toggle--eco"
      attr.aria-label="{{ ecoArea.label }} Button Toggle"
      >{{ ecoArea.label }}</mat-button-toggle
    >
  </ng-container>
</div>

<div class="display-block mt-2">
  <ng-container *ngIf="tagDomainsToggled">
    <mat-button-toggle
      *ngFor="let evalDomain of evaluationDomains"
      [value]="evalDomain.id"
      (change)="onEvaluationDomainSelect($event)"
      [checked]="isEvaluationDomainChecked(evalDomain.id)"
      class="mr-2 mb-2 button-toggle--development"
      attr.aria-label="{{ evalDomain.label }} Button Toggle"
      >{{ evalDomain.label }}</mat-button-toggle
    >
  </ng-container>
</div>

<div class="display-block mt-2">
  <ng-container *ngIf="tagRoutineToggled">
    <mat-button-toggle
      *ngFor="let evalRoutine of evaluationRoutines"
      [value]="evalRoutine.id"
      (change)="onEvaluationRoutineSelect($event)"
      [checked]="isEvaluationRoutineChecked(evalRoutine.id)"
      class="mr-2 mb-2 button-toggle--routine"
      >{{ evaluationFormService.getRoutineLabel(evalRoutine) }}</mat-button-toggle
    >
  </ng-container>
</div>
