import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { take, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base-component/base-component';
import { CaseSummary, IntakeType } from '../../../shared/models/case';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { CaseService } from '../../../shared/services/case/case.service';
import { DeactivationService } from '../../../shared/services/deactivation.service';
import { conditionalValidator } from '../../../shared/validators';
import {
  TransitionPlanningEdit,
  TransitionPlanningRead,
  TransitionPlanningService,
} from '../../transition-planning/services/transition-planning.service';
import { TransitionIds } from '../../transition-planning/transition-planning-ids';

@Component({
  selector: 'app-eco-rating-wrapper',
  templateUrl: './eco-rating-wrapper.component.html',
  styleUrls: ['./eco-rating-wrapper.component.scss'],
})
export class EcoRatingWrapperComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() learnerId: string;
  @Input() caseSummary: CaseSummary;
  @Input() isPK = false;
  @Output() ecoRatingUpdateEvent = new EventEmitter();

  formGroup = this.formBuild.group({
    id: [''],
    caseId: [''],
    planDate: [new Date(), Validators.required],
    reasonForDelayedMeeting: [''],
    reasonForDelayedMeetingOther: [''],
    reasonForNotHavingMeeting: [''],
    reasonForNotHavingMeetingOther: [''],
    // Reflection and looking ahead
    strengths: ['', Validators.required],
    nextSteps: ['', Validators.required],
    familyApproveOfSpecialEd: [
      '',
      conditionalValidator(() => this.formGroup.controls.nextSteps.value.includes(TransitionIds.specialEd), Validators.required),
    ],
    familyGoals: ['', Validators.required],
    // transition-planning
    diagnosisOfCondition: [''],
    diagnosisOfConditionComments: [''],
    uniqueComparedToPeers: [''],
    uniqueComparedToPeersComments: [''],
    progressionStandards: [''],
    progressionStandardsComments: [''],
    requiresContinuedEffort: [''],
    requiresContinuedEffortComments: [''],
    potentiallyEligible: [''],
    exitCode: [''],
    // eco ratings
    ecoAreas: this.formBuild.array([]),
    delayReasonId: [null],
  });

  transitionPlanning: TransitionPlanningRead;
  ecoRatingProgresses: KeyValuePair[] = [];
  intakeType = IntakeType;

  constructor(
    private readonly formBuild: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    private readonly transitionPlanningService: TransitionPlanningService,
    deactivationService: DeactivationService,
    private readonly caseService: CaseService
  ) {
    super(deactivationService);
  }

  get isPkPartB() {
    return this.isPK && this.caseSummary?.intakeType === this.intakeType.PartB;
  }

  ngOnInit(): void {
    if (this.isPkPartB) {
      this.caseService.getCaseEcoRatingProgress(this.caseSummary?.id).subscribe((ecoRatingResult) => {
        this.ecoRatingProgresses = ecoRatingResult.value;

        this.initializeFromFormGroup(this.formGroup, (value) => this.saveCaseEcoProgress(value), this.cd);
        this.startAutosaving();
      });
    } else {
      this.getTransitionPlanningByLearnerId().subscribe((_) => {
        this.formGroup.patchValue(this.transitionPlanning);

        this.initializeFromFormGroup(this.formGroup, (value) => this.saveTransitionEcoProgress(value), this.cd);
        this.startAutosaving();

        if (!this.formGroup.controls.id.value) {
          this.formGroup.markAsDirty();
          this.triggerSave();
          this.getTransitionPlanningByLearnerId().subscribe();
        }
      });
    }
  }

  private getTransitionPlanningByLearnerId() {
    return this.transitionPlanningService.getByLearnerId(this.learnerId).pipe(
      tap((result) => (this.transitionPlanning = result)),
      take(1)
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private async saveTransitionEcoProgress(transitionPlanningEdit: TransitionPlanningEdit): Promise<void> {
    transitionPlanningEdit.ecoRatingProgresses = this.formGroup.controls.ecoAreas.value.map((x) => {
      return { key: x.id, value: x.madeProgress };
    });
    if (this.formGroup.controls.id.value) {
      await this.transitionPlanningService.put(transitionPlanningEdit).toPromise();
      await this.ecoRatingUpdated();
    } else {
      const transitionPlanning = await this.transitionPlanningService.post(transitionPlanningEdit).toPromise();
      this.formGroup.controls.id.setValue(transitionPlanning.id, {
        emitEvent: false,
      });
      this.formGroup.markAsPristine();
      await this.ecoRatingUpdated();
    }
  }

  private async saveCaseEcoProgress(data: any): Promise<void> {
    let ecoRatingProgresses = this.formGroup.controls.ecoAreas.value.map((x) => {
      return { key: x.ecoRatingId, value: x.madeProgress };
    });

    if (ecoRatingProgresses) {
      ecoRatingProgresses = ecoRatingProgresses.filter((x) => x.key != null);
      await this.caseService.saveCaseEcoRatingProgress(ecoRatingProgresses, this.caseSummary?.id).toPromise();

      await this.ecoRatingUpdated();
    }
  }

  async ecoRatingUpdated() {
    this.ecoRatingUpdateEvent.emit();
  }
}
