import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { BaseComponent } from '../../../../shared/components/base-component/base-component';
import { DeactivationService } from '../../../../shared/services/deactivation.service';
import { IepAmendment } from '../../../models/iep-amendment';
import { TransitionAssessment, TransitionAssessmentDocument } from '../models/transition-assessment';
import { TransitionAssessmentService } from '../services/transition-assessment.service';

const llwRequiredValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  const learning = formGroup.get('learning');
  const living = formGroup.get('living');
  const working = formGroup.get('working');

  return learning && !learning.value && living && !living.value && working && !working.value ? { atLeastOneRequired: true } : null;
};

@Component({
  selector: 'app-transition-assessment',
  templateUrl: './transition-assessment.component.html',
  styleUrls: ['./transition-assessment.component.scss'],
})
export class TransitionAssessmentComponent extends BaseComponent implements OnInit {
  @Input() learnerId: string;
  @Input() iepId: string;
  @Input() plaafpId: string;
  @Input() secondaryTransitionId: string;
  @Input() isFirstSecondaryTransition: boolean;
  @Input() readonly: boolean;
  @Input() amendments: Array<IepAmendment>;
  @ViewChild(MatSort) sort: MatSort;
  documents: TransitionAssessmentDocument[] = [];
  shortDateFormat = shortDateFormat;
  dataSource: MatTableDataSource<TransitionAssessment> = new MatTableDataSource();
  displayedColumns = ['actions', 'dateOfAssessment', 'assessment', 'areas', 'attachments', 'notes'];
  activeCall = false;

  // For UI purposes
  me = '';
  isEditing = false;
  editingAssessment: TransitionAssessment;
  today = dayjs().startOf('day').toDate();

  formGroup = this.fb.group(
    {
      id: '',
      secondaryTransitionId: '',
      dateOfAssessment: [null, Validators.required],
      assessment: ['', Validators.required],
      notes: '',
      living: false,
      learning: false,
      working: false,
      documentIds: [[]],
    },
    { validators: llwRequiredValidator }
  );

  get baseUrl() {
    return `api/iep/${this.iepId}/plaafp/${this.plaafpId}/secondary-transitions/${this.secondaryTransitionId}/transition-assessments`;
  }

  constructor(
    private fb: FormBuilder,
    private transitionAssessmentService: TransitionAssessmentService,
    private authService: AuthService,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.me = this.authService.user.id;
    this.load();
    this.dataSource.sort = this.sort;
  }

  private load() {
    this.transitionAssessmentService.getAll(this.iepId, this.plaafpId, this.secondaryTransitionId).subscribe((res) => {
      this.dataSource.data = res;
    });
  }

  toggleEnvironment(event) {
    const control = this.formGroup.get(event.value);
    switch (event.value) {
      case 'living':
        control.setValue(!control.value);
        break;
      case 'learning':
        control.setValue(!control.value);
        break;
      case 'working':
        control.setValue(!control.value);
        break;
    }
  }

  onCancel() {
    this.isEditing = false;
    this.reset();
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
    this.activeCall = true;
    const id = this.formGroup.get('id').value;
    let observable;
    if (id) {
      observable = this.transitionAssessmentService.update(this.iepId, this.plaafpId, this.secondaryTransitionId, this.formGroup.value);
    } else {
      this.formGroup.get('secondaryTransitionId').setValue(this.secondaryTransitionId);
      observable = this.transitionAssessmentService.create(this.iepId, this.plaafpId, this.secondaryTransitionId, this.formGroup.value);
    }
    observable.subscribe((res) => {
      this.isEditing = false;
      this.reset();
      this.load();
      this.transitionAssessmentService.announceUpdated();
      this.activeCall = false;
    });
  }

  reset() {
    this.formGroup.reset({
      secondaryTransitionId: this.secondaryTransitionId,
      documentIds: [],
    });
    this.documents = [];
    this.editingAssessment = null;
  }

  onToggleHistorical(event: MatSlideToggleChange) {
    if (event.checked) {
      this.transitionAssessmentService.getHistorical(this.learnerId).subscribe((res) => {
        this.dataSource = new MatTableDataSource(res);
      });
    } else {
      this.reset();
      this.load();
    }
  }

  //#region Documentation Uploads
  onUpload(formData: FormData) {
    this.activeCall = true;
    this.transitionAssessmentService.addDocuments(this.iepId, this.plaafpId, this.secondaryTransitionId, formData).subscribe(
      (docs) => {
        this.activeCall = false;
        this.documents = [...this.documents, ...docs];
        this.formGroup.get('documentIds').setValue(docs.map((d) => d.id));
      },
      (err) => {
        this.activeCall = false;
        console.log(err);
      }
    );
  }

  onDeleteDocument(documentId: string) {
    const docIdsControl = this.formGroup.get('documentIds');
    let idx = docIdsControl.value.indexOf(documentId);
    if (idx > -1) {
      docIdsControl.setValue([...docIdsControl.value.slice(0, idx), ...docIdsControl.value.slice(idx + 1)]);
    }
    const doc = this.documents.find((d) => d.id === documentId);
    idx = this.documents.indexOf(doc);
    this.documents = [...this.documents.slice(0, idx), ...this.documents.slice(idx + 1)];
  }
  //#endregion

  //#region Table Methods
  atLeastOneMenuOption(ta: TransitionAssessment) {
    return ta.createdById === this.me;
  }

  onViewMore(ta: TransitionAssessment) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Date',
        value: this.datepipe.transform(ta.dateOfAssessment, this.shortDateFormat),
      },
      {
        name: 'Assessment',
        value: ta.assessment,
      },
      {
        name: 'Notes',
        value: ta.notes,
      },
      {
        name: 'Area(s)',
        value: this.getAssessmentAreas(ta),
      },
    ];

    openViewMore(this.dialog, modalData);
  }

  onEdit(ta, editing) {
    this.isEditing = editing;
    ta.isEditing = editing;
    if (!editing) {
      this.reset();
      return;
    }
    this.formGroup.patchValue(ta);
    this.documents = ta.documents;
    this.editingAssessment = ta;
  }

  onDelete(ta) {
    this.dialog
      .open(AreYouSureComponent, {
        width: '450',
        data: {
          subQuestion: 'Clicking Yes will remove this item.',
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.transitionAssessmentService
            .delete(this.iepId, this.plaafpId, this.secondaryTransitionId, ta.id)
            .subscribe(() => this.load());
        }
      });
  }

  getAssessmentAreas(ta: TransitionAssessment) {
    let result = '';
    if (ta.living) {
      result = 'Living';
    }
    if (ta.learning) {
      if (ta.living) {
        result = result.concat(', Learning');
      } else {
        result = 'Learning';
      }
    }
    if (ta.working) {
      if (ta.living || ta.learning) {
        result = result.concat(', Working');
      } else {
        result = 'Working';
      }
    }
    return result;
  }
  //#endregion
}
