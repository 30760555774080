<div>
  <div class="display-flex justify-content-between align-items-center w-100" *ngIf="displayHeader">
    <h4 class="text-primary mt-0 mb-2">Incomplete Data Report</h4>

    <div class="display-flex align-items-center">
      <span *ngIf="incompleteItems?.length > 1" class="ml-2 text-danger text-sm position-fix-notice">
        {{ incompleteItems?.length }} Notices
      </span>

      <span *ngIf="incompleteItems?.length === 1" class="ml-2 text-danger text-sm position-fix-notice"> 1 Notice </span>
      <button
        mat-icon-button
        aria-label="Refresh Icon"
        color="primary"
        (click)="refreshIncompleteItems($event)"
        class="ml-2"
        matTooltip="Refresh"
        tabindex="0"
      >
        <mat-icon aria-label="Refresh Icon"> refresh </mat-icon>
      </button>
    </div>
  </div>

  <div class="table-overflow app-table">
    <table mat-table [dataSource]="dataSource" matSort class="w-100">
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="cell--actions"></th>
        <td mat-cell *matCellDef="let element" class="cell--actions">
          <button color="primary" (click)="navigate(element)" *ngIf="element.action" mat-flat-button aria-label="Resolve" tabindex="0">
            Resolve
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="notice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Incomplete Data</th>
        <td mat-cell *matCellDef="let element">
          {{ element.notice | punctuation }}
        </td>
      </ng-container>

      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef colspan="7">
          <i>All items complete</i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr [hidden]="dataSource.data?.length > 0" mat-footer-row *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"></tr>
    </table>
    <mat-paginator [dataSource]="dataSource"></mat-paginator>
  </div>
</div>
