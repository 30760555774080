import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { IntakeType } from 'src/app/shared/models/case';
import { FamilyMember, LearnerSummary } from '../../../../shared/models/learner';
import { AchieveConfigService } from '../../../../shared/services/achieve-config-service/achieve-config.service';

@Component({
  selector: 'app-family-contact-log-add',
  templateUrl: './family-contact-log-add.component.html',
  styleUrls: ['./family-contact-log-add.component.scss'],
})
export class FamilyContactLogAddComponent implements OnInit {
  intakeType = IntakeType;
  filteredOptions: Observable<Array<FamilyMember>>;
  formGroup: FormGroup;
  maxDate: Date = new Date();

  get hasPartBActiveCase(): boolean {
    return this.learner.activeCases.some((x) => x.intakeType === IntakeType.PartB);
  }

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public learner: LearnerSummary,
    private authService: AuthService,
    private fb: FormBuilder,
    private readonly achieveConfigService: AchieveConfigService
  ) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group(
      {
        date: new FormControl(null, [Validators.required]),
        time: new FormControl(null, [Validators.required]),
        userId: new FormControl(this.authService.user.id),
        contactee: new FormControl(null, {
          updateOn: 'change',
          validators: [Validators.required],
        }),
        method: new FormControl(null, [Validators.required]),
        purpose: new FormControl(null, [Validators.required]),
        comment: new FormControl(null),
      },
      { updateOn: 'blur' }
    );
    this.filteredOptions = this.formGroup.controls.contactee.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.fullName)),
      map((name) => (name ? this.filter(name) : this.learner.familyMembers.slice()))
    );

    if (this.hasPartBActiveCase) {
      this.formGroup.get('purpose').disable();
    }
  }

  private filter(name: string): FamilyMember[] {
    const filterValue = name.toLowerCase();
    return this.learner.familyMembers.filter((option) => option.fullName.toLowerCase().indexOf(filterValue) === 0);
  }

  async onSave() {
    const dateTime = await this.achieveConfigService
      .getDateTimeFromTimeString(this.formGroup.controls.date.value, this.formGroup.controls.time.value)
      .toPromise();

    const contactAttempt = {
      userId: this.formGroup.controls.userId.value,
      dateTime: dateTime,
      contactee: this.formGroup.controls.contactee.value,
      method: this.formGroup.controls.method.value,
      purpose: this.formGroup.controls.purpose.value,
      comment: this.formGroup.controls.comment.value,
    };
    this.dialogRef.close(contactAttempt);
  }

  onNoClick() {
    this.dialogRef.close();
  }
}
