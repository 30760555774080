<div [formGroup]="formGroup">
  <mat-card>
    <mat-card-title>Referral Source Information</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg-5">
          <app-autocomplete
            [options]="referralSourceCategoryOptions"
            formControlName="referralSourceCategoryId"
            label="Referral Source Category"
          ></app-autocomplete>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-lg">
          <app-text-field label="Name (first and last)" formControlName="referralSourceName"></app-text-field>
        </div>
        <div class="col-lg">
          <app-text-field label="Email" formControlName="referralSourceEmail"></app-text-field>
        </div>
        <div class="col-lg-4">
          <app-text-field label="Agency / organization" formControlName="referralSourceAgency"></app-text-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg">
          <app-masked-input
            label="Home Phone Number"
            [showAsterisk]="formGroup.hasError('referralSourceOnePhoneNumberRequired')"
            formControlName="referralSourceHomePhone"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input
            label="Work Phone Number"
            [showAsterisk]="formGroup.hasError('referralSourceOnePhoneNumberRequired')"
            formControlName="referralSourceWorkPhone"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input
            label="Cell Phone Number"
            [showAsterisk]="formGroup.hasError('referralSourceOnePhoneNumberRequired')"
            formControlName="referralSourceCellPhone"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          (formGroup.controls.referralSourceHomePhone.touched ||
            formGroup.controls.referralSourceWorkPhone.touched ||
            formGroup.controls.referralSourceCellPhone.touched) &&
          formGroup.hasError('referralSourceOnePhoneNumberRequired')
        "
      >
        <div class="col-lg">
          <mat-error> At least one phone number is <strong>required</strong> </mat-error>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-lg">
          <app-text-field
            label="Address"
            formControlName="referralSourceAddress"
            [showAsterisk]="formGroup.hasError('addressRequired')"
          ></app-text-field>
        </div>
        <div class="col-lg">
          <app-text-field
            label="Zip Code"
            formControlName="referralSourceZipCode"
            (blurMe)="populateCityStateFromZipCode()"
            [showAsterisk]="formGroup.hasError('addressRequired')"
          ></app-text-field>
        </div>
        <div class="col-lg">
          <app-text-field
            label="City"
            formControlName="referralSourceCity"
            [showAsterisk]="formGroup.hasError('addressRequired')"
          ></app-text-field>
        </div>
        <div class="col-lg">
          <app-autocomplete
            label="State"
            formControlName="referralSourceState"
            [showAsterisk]="formGroup.hasError('addressRequired')"
            [options]="usStateOptions"
          ></app-autocomplete>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          (formGroup.controls.referralSourceAddress.touched ||
            formGroup.controls.referralSourceCity.touched ||
            formGroup.controls.referralSourceState.touched ||
            formGroup.controls.referralSourceZipCode.touched) &&
          formGroup.hasError('addressRequired')
        "
      >
        <div class="col-lg">
          <mat-error> Address, zip code, city, and state are <strong>required</strong>. </mat-error>
        </div>
      </div>
      <div class="row mt-0 mb-0">
        <div class="col-xl-4 col-lg-6">
          <app-autocomplete
            [options]="referralHowHearAboutUsOptions"
            [formControl]="referralSourceHowHearAboutUsId"
            label="How did you hear about Early ACCESS?"
          ></app-autocomplete>
        </div>
        <div class="col-md-8" *ngIf="referralSourceHowHearAboutUsId.value === howHearAboutUsOtherId">
          <div class="container">
            <label class="mr-1" for="referralSourceEarlyAccessOtherText">
              <mat-form-field class="w-100 clearfix">
                <mat-label>Describe</mat-label>
                <input
                  type="text"
                  matInput
                  aria-labelledby="Referral Source Early Access Other Text"
                  id="referralSourceEarlyAccessOtherText"
                  [formControl]="referralSourceEarlyAccessOtherText"
                />
              </mat-form-field>
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="!isParentReferralSource">
        <div class="row mb-2">
          <div class="col">
            <p
              class="mb-0"
              [ngClass]="{
                asterisk_input: parentForm.hasError('guardianRequired')
              }"
            >
              Is the parent or legal guardian aware that the referral has been made to Early ACCESS?
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <fieldset>
              <app-radio-group [formControl]="legalGuardian" [options]="yesNoOptions"></app-radio-group>
            </fieldset>
            <mat-error *ngIf="legalGuardian.touched && parentForm.hasError('guardianRequired')"
              >Field is <strong>required</strong></mat-error
            >
          </div>
        </div>
        <div class="row" *ngIf="legalGuardian.value === false">
          <div class="col">
            <p>
              <strong style="color: red"
                >Referral sources are responsible for informing the family that a referral has been made. Please contact the family after
                completing this referral.</strong
              >
            </p>
          </div>
        </div>
        <div class="row mt-2 mb-2">
          <div class="col">
            <p
              class="mb-0"
              [ngClass]="{
                asterisk_input: parentForm.hasError('exchangeRequired')
              }"
            >
              Do you have a signed release from the family to exchange information about the child with Early ACCESS?
            </p>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <fieldset>
              <app-radio-group [formControl]="exchangeInformation" [options]="yesNoOptions"></app-radio-group>
            </fieldset>
            <mat-error *ngIf="exchangeInformation.touched && parentForm.hasError('exchangeRequired')"
              >Field is <strong>required</strong></mat-error
            >
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
