import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-esy-view',
  templateUrl: './esy-view.component.html',
  styleUrls: ['./esy-view.component.scss'],
})
export class EsyViewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
