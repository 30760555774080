import { Component, Input, OnInit } from '@angular/core';
import { EcoDataHistory } from '../../../../evaluation/evaluation/steps/eco-wizard/eco-rating';
import { EcoAreaType } from '../../../../evaluation/models/evaluation';
import { NotificationService } from '../../../../shared/services/notification.service';
import { DataHistoryService } from '../../services/data-history.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';

@Component({
  selector: 'app-eco-rating-history',
  templateUrl: './eco-rating-history.component.html',
  styleUrls: ['./eco-rating-history.component.scss'],
})
export class EcoRatingHistoryComponent implements OnInit {
  @Input() learnerId: string;

  ecoDataHistory = new Array<EcoDataHistory>();
  ecoAreaType = EcoAreaType;
  shortDateFormat = shortDateFormat;

  constructor(private dataHistoryService: DataHistoryService, private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.dataHistoryService.getEcoRatings(this.learnerId).subscribe(
      (ecoHistoryResponse) => {
        if (ecoHistoryResponse.succeeded) {
          this.ecoDataHistory = ecoHistoryResponse.value;
        } else {
          this.notificationService.error('There was an error while loading ECO data.');
        }
      },
      (error) => {
        this.notificationService.error('There was an error while loading ECO data.');
      }
    );
  }

  getEcoDataHistory(type: EcoAreaType) {
    return this.ecoDataHistory.filter((e) => e.ecoAreaType === type);
  }
}
