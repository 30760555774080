import { MeetingParticipantRead } from 'src/app/learner-management/family-meeting/participants-list/meeting-participants';

export enum ParticipantType {
  User = 'User',
  FamilyProvider = 'FamilyProvider',
  FamilyMember = 'FamilyMember',
  Other = 'Other',
}

export interface ParticipantContainer {
  type: ParticipantType;
  participants: MeetingParticipantRead[];
}
