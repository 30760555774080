import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest } from 'rxjs';
import { CaseSummaryByIepId } from 'src/app/shared/models/case';
import { GeneralSurveyService } from 'src/app/shared/services/general-survey/general-survey.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { openPdfWindow } from 'src/app/shared/windowHelpers';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { NotificationService } from '../../../shared/services/notification.service';
import { IepUserSurveyCreator } from '../../models/iep-user-survey-creator';
import { IepService } from '../../services/iep.service';

@Component({
  selector: 'app-iep-survey-invite-creator-modal',
  templateUrl: './iep-survey-invite-creator-modal.component.html',
  styleUrls: ['./iep-survey-invite-creator-modal.component.scss'],
})
export class IepSurveyInviteCreatorModalComponent implements OnInit {
  isReady = false;
  teamMembers: KeyValuePair[];
  questionBankK12: KeyValuePair[];
  questionBankTransition: KeyValuePair[];
  questionBankPreschool: KeyValuePair[];
  surveyCreateForm: FormGroup;
  existingMembers: string[];
  readonlyQuestions = false;
  isSending = false;
  private learnerId: string;
  iepId: string;
  existingSurveyCreator: IepUserSurveyCreator;
  caseSummary: CaseSummaryByIepId;
  isPrinting: boolean;

  constructor(
    private dialogRef: MatDialogRef<IepSurveyInviteCreatorModalComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: any,
    private readonly formBuilder: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly generalSurveyService: GeneralSurveyService,
    private readonly iepService: IepService,
    private readonly reportingService: ReportingService
  ) {
    this.teamMembers = this.data.teamMembers;
    this.learnerId = this.data.learnerId;
    this.iepId = this.data.iepId;
    this.caseSummary = this.data.caseSummary;
  }

  ngOnInit(): void {
    combineLatest([
      this.generalSurveyService.get('IepPreschool'),
      this.generalSurveyService.get('IepK12'),
      this.generalSurveyService.get('IepTransition'),
      this.iepService.getSurveyCreator(this.iepId),
    ]).subscribe(([questionsPreschool, questionsK12, questionsTransition, existingSurveyCreator]) => {
      this.existingSurveyCreator = existingSurveyCreator;
      this.questionBankPreschool = questionsPreschool.map((x) => new KeyValuePair(x.id, this.replaceName(x.question)));
      this.questionBankK12 = questionsK12.map((x) => new KeyValuePair(x.id, this.replaceName(x.question)));
      this.questionBankTransition = questionsTransition.map((x) => new KeyValuePair(x.id, this.replaceName(x.question)));
      this.existingMembers = this.teamMembers.filter((x) => existingSurveyCreator.userIds.includes(x.key)).map((x) => x.value);
      if (existingSurveyCreator.userIds && existingSurveyCreator.userIds.length > 0) {
        this.readonlyQuestions = true;
        this.teamMembers = this.teamMembers.filter((x) => !existingSurveyCreator.userIds.includes(x.key));
      }

      this.surveyCreateForm = this.formBuilder.group({
        id: [],
        bankK12: new FormControl([]),
        bankTransition: new FormControl([]),
        bankPreschool: new FormControl([]),
        customQuestion: new FormControl(''),
        users: new FormControl([]),
      });

      if (existingSurveyCreator.questionIdsIncluded && existingSurveyCreator.questionIdsIncluded.length > 0) {
        this.surveyCreateForm
          .get('bankK12')
          .patchValue(existingSurveyCreator.questionIdsIncluded.filter((x) => this.questionBankK12.map((y) => y.key).includes(x)));

        this.surveyCreateForm
          .get('bankTransition')
          .patchValue(existingSurveyCreator.questionIdsIncluded.filter((x) => this.questionBankTransition.map((y) => y.key).includes(x)));

        this.surveyCreateForm
          .get('bankPreschool')
          .patchValue(existingSurveyCreator.questionIdsIncluded.filter((x) => this.questionBankPreschool.map((y) => y.key).includes(x)));
      }

      if (
        existingSurveyCreator.customQuestionTexts &&
        existingSurveyCreator.customQuestionTexts.length > 0 &&
        existingSurveyCreator.customQuestionTexts[0].trim().length > 0
      ) {
        this.surveyCreateForm.get('customQuestion').setValue(existingSurveyCreator.customQuestionTexts[0].trim());
      }

      this.isReady = true;
    });
  }

  replaceName(question: string) {
    return question.replace('{{childFirstName}}', this.caseSummary?.learner?.firstName);
  }

  onCancel() {
    this.dialogRef.close();
  }

  print() {
    this.isPrinting = true;
    this.reportingService.createPartBTeamMembersSurvey(this.iepId).subscribe({
      next: (documentId: string) => {
        openPdfWindow(this.learnerId, documentId);
        this.isPrinting = false;
      },
      error: (err) => {
        this.reportingService.handleOutputError(err);
        this.isPrinting = false;
      },
    });
  }

  onSend() {
    const iepUserSurveyCreator: IepUserSurveyCreator = {
      iepId: this.iepId,
      userIds: this.surveyCreateForm.get('users').value,
      questionIdsIncluded: [
        ...this.surveyCreateForm.controls.bankK12.value,
        ...this.surveyCreateForm.controls.bankTransition.value,
        ...this.surveyCreateForm.controls.bankPreschool.value,
      ],
      customQuestionTexts: [this.surveyCreateForm.get('customQuestion').value],
    };

    this.isSending = true;
    this.iepService.createSurvey(iepUserSurveyCreator).subscribe(
      (data) => {
        this.isSending = false;
        this.enablePrint();
        this.notificationService.success('Questionnaire Saved');
      },
      () => {
        this.isSending = false;
      }
    );
  }

  enablePrint() {
    this.readonlyQuestions = true;
    this.teamMembers = [];
    this.surveyCreateForm.get('users').setValue([]);
  }
}
