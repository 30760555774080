import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OutcomeDto } from 'src/app/ifsp/models/outcome-models';
import { OutcomeService } from 'src/app/ifsp/services/outcome.service';
import { ProgressMonitorCService } from 'src/app/monitoring-progress/services/progress-monitor-c.service';
import { MultiGraphViewModalComponent } from 'src/app/shared/components/multi-graph-view-modal/multi-graph-view-modal.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { IfspOutcomeViewMoreComponent } from '../../../shared/ifsp-outcome-view-more/ifsp-outcome-view-more.component';

@Component({
  selector: 'app-ifsp-outcomes-list',
  templateUrl: './ifsp-outcomes-list.component.html',
  styleUrls: ['./ifsp-outcomes-list.component.scss'],
})
export class IfspOutcomesListComponent implements OnInit {
  @Input() ifspId: string;
  @Input() caseId: string;
  @Input() learnerId: string;
  @Input() displayedColumns: string[] = [];
  @Input() finalizedOutcomesOnly = false;

  @Output() outcomesCount = new EventEmitter<number>();
  @Output() editOutcome = new EventEmitter<OutcomeDto>();

  dataSource = new MatTableDataSource<OutcomeDto>();
  shortDateFormat = shortDateFormat;
  isDataLoadComplete = false;

  constructor(
    private outcomeService: OutcomeService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private progressMonitorCService: ProgressMonitorCService
  ) {}

  ngOnInit(): void {
    if (this.displayedColumns === null || this.displayedColumns.length === 0) {
      this.displayedColumns = ['actions', 'typeOfOutcome', 'title', 'outcomeEndDate', 'tags', 'graph'];
    }

    this.getOutcomes();

    this.outcomeService.outcomesUpdated$.subscribe(() => {
      this.getOutcomes();
    });
  }

  onOutcomeEdit(outcome) {
    this.editOutcome.emit(outcome);
  }

  removeOutcome(outcome: OutcomeDto) {
    this.notificationService.confirmation('Are you sure you want to delete this outcome?', () => {
      this.outcomeService.deleteOutcome(this.ifspId, outcome.id).subscribe(() => {
        this.getOutcomes();
      });
    });
  }

  viewOutcomeDetail(row: OutcomeDto) {
    this.dialog.open(IfspOutcomeViewMoreComponent, {
      width: '758px',
      data: {
        outcome: row,
        modifications: [],
      },
    });
  }

  onOpenGraph(outcome) {
    this.progressMonitorCService.getSingle(this.learnerId, outcome.id).subscribe({
      next: (res) => this.dialog.open(MultiGraphViewModalComponent, { data: { isIfsp: true, ifspOutcome: res }, width: '728px' }),
      error: (err) =>
        this.notificationService.errorWithAction("Couldn't open graph", 'Why?', () =>
          this.notificationService.alert(err.error, "Couldn't open graph")
        ),
    });
  }

  private getOutcomes() {
    if (this.finalizedOutcomesOnly) {
      this.outcomeService.getFinalizedOutcomes(this.ifspId).subscribe(
        (result) => {
          this.isDataLoadComplete = true;
          this.dataSource.data = result;
          this.outcomesCount.emit(result.length);
        },
        () => {
          this.isDataLoadComplete = true;
        }
      );
    } else {
      this.outcomeService.getOutcomes(this.ifspId).subscribe(
        (result) => {
          this.isDataLoadComplete = true;
          this.dataSource.data = result;
          this.outcomesCount.emit(result.length);
        },
        () => {
          this.isDataLoadComplete = true;
        }
      );
    }
  }
}
