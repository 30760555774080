<div class="print-preview" *ngIf="isPreview">
  <h1 class="print-preview__text">DRAFT</h1>
</div>

<div class="print__heading">
  <div class="display-flex justify-content-between align-items-center">
    <img src="../../../../assets/img/achieve-logo.svg" class="print__logo print__logo--main" alt="ACHIEVE Logo" />

    <button
      mat-raised-button
      color="accent"
      title="Close"
      aria-label="Delete Icon"
      class="hide-print"
      (click)="onCancelPrint()"
      tabindex="0"
    >
      Close
    </button>
  </div>
</div>
<ng-container *ngIf="data.childInfoHeader">
  <section class="printable">
    <p class="printable__question">
      <strong>{{ data.childInfoHeader }}</strong>
    </p>
  </section>
  <!-- TODO: Blaze, please review this and fix as needed -->
  <p></p>
</ng-container>

<ng-container *ngIf="data">
  <div class="row">
    <section class="printable" *ngFor="let print of data.printData">
      <ng-container *ngIf="print.answer">
        <div class="col">
          <div class="printable__question">
            <p>
              <strong>{{ print.question }}</strong>
            </p>
            <p class="printable__answer">{{ print.answer }}</p>
          </div>
          <hr class="input__divider" />
        </div>
      </ng-container>
    </section>
  </div>
</ng-container>

<ng-container *ngIf="data.legalese?.length">
  <section class="printable" *ngFor="let print of data.legalese">
    <p class="printable__question">{{ print }}</p>
  </section>
  <hr class="input__divider" />
</ng-container>
