<div class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" class="w-100" matSort>
    <ng-container matColumnDef="areasOfNeed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Areas of Need</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.areasOfNeed">
          {{ element.areasOfNeed }}
        </ng-container>

        <ng-container *ngIf="!element.areasOfNeed">
          <button mat-flat-button aria-label="Select Areas of Need" color="primary" tabindex="0">Select</button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="goals">
      <th mat-header-cell *matHeaderCellDef>Goals</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.goals">
          {{ element.goals }}
        </ng-container>

        <ng-container *ngIf="!element.goals">
          <button mat-flat-button aria-label="Select Goals" color="primary" tabindex="0">Select</button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="servicesSupportsActivities">
      <th mat-header-cell *matHeaderCellDef>Services, Supports and Activities</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.servicesSupportsActivities">
          {{ element.servicesSupportsActivities }}
        </ng-container>

        <ng-container *ngIf="!element.servicesSupportsActivities">
          <button mat-flat-button aria-label="Select Services Supports and Activities" color="primary" tabindex="0">Select</button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Comments</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.comments">
          {{ element.comments }}
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ 'background-color--danger': row.aligned === false }"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
<p *ngIf="!dataSource.data">No data yet...</p>
