import { Component, OnInit, Input } from '@angular/core';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { IfspService } from '../../../services/ifsp.service';
import { ActivatedRoute } from '@angular/router';
import { IfspView } from '../../../models/ifsp';
import { MatDialog } from '@angular/material/dialog';
import { DialogComingSoonComponent } from 'src/app/shared/components/coming-soon/coming-soon.component';

@Component({
  selector: 'app-past-ifsps',
  templateUrl: './past-ifsps.component.html',
  styleUrls: ['./past-ifsps.component.scss'],
})
export class PastIfspsComponent implements OnInit {
  @Input() pastIfsps: IfspView[] = [];

  shortDateFormat = shortDateFormat;

  displayedColumns: string[] = ['createdOn', 'type', 'action'];

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  onComingSoon() {
    this.dialog.open(DialogComingSoonComponent);
  }

  openPDF(ifspId: string) {
    // TODO: Wire up to open the full PDF report once reports are finished
  }
}
