import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';

import { IntakeType } from 'src/app/shared/models/case';
import { DeactivationService } from 'src/app/shared/services/deactivation.service';
import { PdfOutputs } from 'src/app/shared/services/reporting/reporting.service';
@Component({
  selector: 'app-evaluation-history-table',
  templateUrl: './evaluation-history-table.component.html',
  styleUrls: ['./evaluation-history-table.component.scss'],
})
export class EvaluationHistoryTableComponent extends BaseComponent implements OnInit {
  @Input() dataSource: MatTableDataSource<any>;
  @Input() caseId: string;
  @Output() goToEvaluation = new EventEmitter();

  pdfOutputs = PdfOutputs;
  shortDateFormat = shortDateFormat;
  intakeType = IntakeType;
  displayedColumns: string[] = ['date', 'type'];

  constructor(deactivationService: DeactivationService) {
    super(deactivationService);
  }

  ngOnInit(): void {}
}
