<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Review &mdash; Select Documentation</h3>
<mat-dialog-content class="py-3">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 w-100">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Title Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Title</th>
      <td mat-cell *matCellDef="let element">{{ element.title }}</td>
    </ng-container>

    <!-- File Name Column -->
    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef>File Name</th>
      <td mat-cell *matCellDef="let element">
        <a [href]="element.url" target="_blank" tabindex="0" role="link" aria-labelledby="File Name">{{ element.fileName }}</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
  </table>
  <p *ngIf="!dataSource.data">No data yet...</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button aria-label="Cancel Button" mat-dialog-close tabindex="0">Cancel</button>
  <button mat-raised-button aria-label="Complete" [mat-dialog-close]="selection.selected" color="primary" cdkFocusInitial tabindex="0">
    Complete
  </button>
</mat-dialog-actions>
