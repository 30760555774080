import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-manage-reevaluation-due-date',
  templateUrl: './manage-reevaluation-due-date.component.html',
  styleUrls: ['./manage-reevaluation-due-date.component.scss'],
})
export class ManageReevaluationDueDateComponent implements OnInit {
  formGroup: FormGroup;
  activeCall = false;

  constructor(
    public dialogRef: MatDialogRef<ManageReevaluationDueDateComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: { caseId: string; reevaluationDueOn?: Date },
    private readonly notificationService: NotificationService,
    private readonly caseService: CaseService,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      reevaluationDueOn: [this.data.reevaluationDueOn, Validators.required],
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }

    this.activeCall = true;

    const reevaluationDueOn = this.formGroup.controls.reevaluationDueOn.value;

    this.caseService.changeReevaluationDueOn(this.data.caseId, reevaluationDueOn).subscribe(
      (response) => {
        this.activeCall = false;
        if (response.succeeded) {
          this.dialogRef.close(reevaluationDueOn);
          this.notificationService.success('Successfully updated.');
        } else {
          this.notificationService.error(response.errors?.map((e) => e.description).join());
        }
      },
      (error) => {
        this.activeCall = false;
        this.notificationService.error(error?.message);
      }
    );
  }

  onCancel() {
    this.dialogRef.close();
  }
}
