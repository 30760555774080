import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  IepIncompleteDataReportAction,
  IncompleteDataReportItem,
} from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { MeetingRollCallComponent } from 'src/app/shared/components/meeting-roll-call/meeting-roll-call.component';
import { CaseSummary } from 'src/app/shared/models/case';
import { PunctuationPipe } from 'src/app/shared/pipes/punctuation.pipe';
import { AlertDialogComponent } from 'src/app/shared/services/notification.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { DateFormatPipe } from '../../../../shared/pipes/date-transform.pipe';
import { NewWindowConfig, openNewWindow } from '../../../../shared/windowHelpers';
import { IepService } from '../../../services/iep.service';
import { ConsentFormService } from 'src/app/shared/services/consent-form/consent-form.service';
import { ConsentFormType } from 'src/app/shared/models/fiie-consent/consent-form';
import { FamilyConsentStatus } from 'src/app/shared/models/fiie-consent/family-consent-status';
import { toLocalDate } from 'src/app/shared/dayjs-wrapper';
import dayjs from 'dayjs';

@Component({
  selector: 'app-iep-completion',
  templateUrl: './iep-completion.component.html',
  styleUrls: ['./iep-completion.component.scss'],
  providers: [PunctuationPipe],
})
export class IepCompletionComponent implements OnInit, OnChanges {
  @Input() caseSummary: CaseSummary;
  @Input() iepId: string;
  @Input() amendmentId: string;
  @Input() incompleteItems: IncompleteDataReportItem[] = [];
  @Input() iepEndDate?: Date;
  @Input() allowIvrs: boolean;
  @Output() refreshData = new EventEmitter();
  @Output() gotoConsent = new EventEmitter();
  @Output() gotoProceduralSafeguards = new EventEmitter();
  @Output() gotoPanel = new EventEmitter<IncompleteDataReportItem>();

  dataSource = new MatTableDataSource<IncompleteDataReportItem>();
  displayedColumns = ['action', 'notice'];
  showIvrs: boolean;

  constructor(
    private router: Router,
    private iepService: IepService,
    private dialog: MatDialog,
    private routingService: RoutingService,
    private consentFormService: ConsentFormService
  ) {}

  ngOnInit(): void {
    if (this.allowIvrs) {
      if (this.caseSummary?.learner?.age >= 14) {
        this.manageIvrsBanner();
      } else if (this.iepEndDate) {
        const iepEndDate = dayjs(toLocalDate(this.iepEndDate));
        const dob = dayjs(this.caseSummary?.learner?.dateOfBirth);
        if (iepEndDate.diff(dob, 'year') >= 14) {
          this.manageIvrsBanner();
        }
      }
    }
  }

  ngOnChanges(): void {
    this.dataSource.data = this.incompleteItems;
  }

  private manageIvrsBanner() {
    this.consentFormService.getConsentForms(this.caseSummary.caseId, [ConsentFormType.IvrsConsent], 1).subscribe((consents) => {
      this.showIvrs = true;
      if (consents?.length > 0) {
        this.showIvrs = !consents.some(
          (x) =>
            x.lastStatus.status === FamilyConsentStatus.Approved ||
            x.lastStatus.status === FamilyConsentStatus.Override ||
            x.lastStatus.status === FamilyConsentStatus.Declined ||
            x.lastStatus.status === FamilyConsentStatus.Denied
        );
      }
    });
  }

  navigateToAction(item: IncompleteDataReportItem) {
    switch (item.action) {
      case IepIncompleteDataReportAction.Meeting:
        this.routingService.openScheduleMeeting(this.caseSummary.learnerId);
        break;
      case IepIncompleteDataReportAction.Pwn:
        const config: NewWindowConfig = {
          path: `cases/${this.caseSummary.id}/pwns/${item.id || ''}`,
          popup: true,
        };
        openNewWindow(config).addEventListener('beforeunload', () => {
          setTimeout(() => {
            this.refreshData.emit();
          }, 1000);
        });
        break;
      case IepIncompleteDataReportAction.RollCall:
        this.openRollCallModal();
        break;
      case IepIncompleteDataReportAction.RollCallAmendment:
        this.openRollCallModal(true);
        break;
      case IepIncompleteDataReportAction.FutureRollCall:
        this.dialog.open(AlertDialogComponent, {
          data: {
            title: 'Meeting Roll Call',
            message: `Meeting Roll Call cannot be edited until the date of the meeting - ${new DateFormatPipe().transform(
              item.meetingDate
            )}`,
          },
        });
        break;
      case IepIncompleteDataReportAction.Bip:
        this.router.navigate(['/cases', this.caseSummary.id, 'behavior-discipline', 'bip', item.id, 'overview']);
        break;
      case IepIncompleteDataReportAction.Consent:
        this.gotoConsent.emit();
        break;
      case IepIncompleteDataReportAction.ProceduralSafeguards:
        this.gotoProceduralSafeguards.emit();
        break;
      case IepIncompleteDataReportAction.Details:
        this.gotoPanel.emit(item);
        break;
      default:
        if (item.section && item.element) {
          this.navigate(item.action + '/' + item.section + '/' + item.element);
        } else {
          this.navigate(item.action);
        }
        break;
    }
  }

  openRollCallModal(isAmendment = false) {
    this.iepService.getOldestIncompleteIepMeeting(this.caseSummary.id, isAmendment ? true : false).subscribe((meeting) => {
      if (!meeting) {
        return;
      }
      const dialogRef = this.dialog.open(MeetingRollCallComponent, {
        width: '1100px',
        data: {
          meeting,
          caseSummary: this.caseSummary,
        },
      });
      dialogRef.afterClosed().subscribe(() => {
        this.refreshData.emit();
      });
    });
  }

  private navigate(action: string): void {
    this.router.navigate(['/cases', this.caseSummary.id, 'iep', this.iepId, ...action.split('/')]);
  }
}
