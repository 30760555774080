<mat-accordion class="accordion accordion--close accordion--short" multi>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Assessment</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <app-data-history-part-b-assessments [caseId]="caseId" [stateAssignedId]="stateAssignedId"></app-data-history-part-b-assessments>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Attendance and Behavior</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <app-data-history-part-b-attendance-behavior [caseId]="caseId" [learnerId]="learnerId"></app-data-history-part-b-attendance-behavior>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Evaluation</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <app-data-history-evaluation [case]="case"></app-data-history-evaluation>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex flex-1 justify-content-between align-items-center flex-wrap">
          <h3 class="my-0 text-normal">Progress Monitoring</h3>
          <ng-container *appAuthForRoles="{ permission: [permissions.CreateEditProgressMonitoring], caseId: caseId }">
            <button
              mat-raised-button
              aria-label="Enter/View Progress"
              color="primary"
              (click)="enterProgress()"
              *ngIf="isCaseWorkable"
              tabindex="0"
            >
              Enter/View Progress
            </button>
          </ng-container>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-data-history-part-b-progress-monitoring [case]="case"> </app-data-history-part-b-progress-monitoring>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" *ngIf="secondaryTransitionEnabled">
    <mat-expansion-panel-header>
      <mat-panel-title> <h3 class="my-0 text-normal">Secondary Transition</h3> </mat-panel-title>
    </mat-expansion-panel-header>
    <app-data-history-part-b-secondary-transition [caseId]="caseId" [learnerId]="learnerId"></app-data-history-part-b-secondary-transition>
  </mat-expansion-panel>
</mat-accordion>
