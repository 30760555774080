<nav class="stepper__nav mb-2" *ngIf="evaluationId">
  <ul class="stepper__list">
    <a
      [routerLink]="['/', 'cases', caseId, 'evaluation', evaluationId, 'overview']"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="Overview"
    >
      <li class="stepper__item">
        <i class="material-icons"> dashboard </i>
        <p>Overview</p>
      </li>
    </a>

    <a
      [routerLink]="detailsLink"
      *ngIf="!evaluation.isFinalized"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      attr.aria-label="{{ intakeType === 'PartC' ? 'Evaluation/Assessment Details' : 'Evaluation Details' }}"
    >
      <li class="stepper__item">
        <i class="fas fa-list"></i>
        <p *ngIf="intakeType === 'PartC'">Evaluation/Assessment Details</p>
        <p *ngIf="intakeType === 'PartB'">Evaluation Details</p>
      </li>
    </a>

    <a
      [routerLink]="['/', 'cases', caseId, 'evaluation', evaluationId, 'eco-matrix']"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      *ngIf="intakeType === 'PartC' && allDevAreasComplete() && !evaluation.isFinalized"
      tabindex="0"
      role="link"
      aria-labelledby="ECO Matrix"
    >
      <li class="stepper__item">
        <i class="fas fa-border-all"></i>
        <p>ECO Matrix</p>
      </li>
    </a>
    <a
      [routerLink]="['/', 'cases', caseId, 'evaluation', evaluationId, 'eco-matrix-part-b']"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      *ngIf="intakeType === 'PartB' && evaluation.learner.isPK && !evaluation.isFinalized"
      tabindex="0"
      role="link"
      aria-labelledby="ECO Matrix"
    >
      <li class="stepper__item">
        <i class="fas fa-border-all"></i>
        <p>ECO Matrix</p>
      </li>
    </a>
    <a
      [routerLink]="['/', 'cases', caseId, 'evaluation', evaluationId, 'summary-part-c']"
      *ngIf="showEligibilityDecisionStepper"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="Eligibility Decision"
    >
      <li class="stepper__item">
        <i class="material-icons"> search </i>
        <p>Eligibility Decision</p>
      </li>
    </a>

    <a
      [routerLink]="['/', 'cases', caseId, 'evaluation', evaluationId, 'continued-eligibility']"
      *ngIf="!evaluation.isInitial && evaluation.ifspHasActiveAnnualReview"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="Eligibility Decision"
    >
      <li class="stepper__item">
        <i class="material-icons"> search </i>
        <p>Eligibility Decision</p>
      </li>
    </a>

    <a
      [routerLink]="['/', 'cases', caseId, 'evaluation', evaluationId, 'summary-part-b']"
      *ngIf="intakeType === 'PartB' && !evaluation.isFinalized"
      routerLinkActive="stepper__link--active"
      class="stepper__link"
      tabindex="0"
      role="link"
      aria-labelledby="Summary"
    >
      <li class="stepper__item">
        <div class="icon__container">
          <svg-icon src="assets/img/icons/Summary.svg" svgClass="icon--svg"></svg-icon>
        </div>
        <p>Summary</p>
      </li>
    </a>
  </ul>
</nav>
