import { ChangeDetectorRef, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AppPermissions } from '../../../permissions';
import { Observable, Subscription } from 'rxjs';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { StringSizes } from '../form/constants/constants';
import { debounceTime, filter, map } from 'rxjs/operators';
import { AppEvents, AutosavingStates } from '../../app-events';
import { NotifySave } from '../../decorators/notify-save.decorator';
import { FormGroup } from '@angular/forms';
import { CanComponentDeactivate, DeactivationService, DeactivationStatus } from '../../services/deactivation.service';
import { BankableFieldIds } from '../../bankableFieldIds';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({
  template: '',
})
export class BaseComponent implements OnDestroy, CanComponentDeactivate {
  private internalSaveSubscription: Subscription;
  private autoSaveSubscription = new Subscription();

  @Input() readonly = false;
  subscriptions = new Subscription();
  permissions = AppPermissions;
  shortDateFormat = shortDateFormat;
  stringSizes = StringSizes;

  /**
   * This should be your save method.
   * You MUST call observer.next(true) if the save is successful or the user cannot leave the screen
   * @example save = new Observable<boolean>((observer) => { observer.next(true) });
   */
  @Input() save: Observable<boolean>;

  /**
   * This should be your formGroup.valueChanges. Set this AFTER you set your form group
   */
  saveSubscription: Observable<any>;

  /**
   * Set this to true during a save so that the autosave doesn't fire in the middle of a save.
   * Set to false on completion.
   */
  isSaving = false;

  protected debounceSavingTime = 3000;

  public get canSave(): boolean {
    return true;
  }

  public get bankableFieldIds() {
    return new BankableFieldIds();
  }

  constructor(private readonly deactivationService: DeactivationService) {
    if (deactivationService) {
      this.deactivationService.subscribe(this);
    }
  }

  ngOnDestroy() {
    if (this.deactivationService) {
      this.deactivationService.unsubscribe(this);
    }
    this.subscriptions.unsubscribe();
    this.stopAutosaving();
  }

  triggerSave() {
    this.internalSaveSubscription?.unsubscribe();
    this.internalSaveSubscription = this.internalSave().subscribe();
  }

  startAutosaving() {
    if (!this.save || !this.saveSubscription) {
      throw new Error('Autosave was called without a save function defined');
    }
    this.stopAutosaving();
    this.autoSaveSubscription = this.saveSubscription
      .pipe(
        debounceTime(this.debounceSavingTime),
        filter(() => this.canSave)
      )
      .subscribe(() => this.triggerSave());
  }

  stopAutosaving() {
    this.internalSaveSubscription?.unsubscribe();
    AppEvents.autosaving.emit(AutosavingStates.Off);
    this.autoSaveSubscription.unsubscribe();
  }

  canDeactivate(): Observable<DeactivationStatus> | Promise<DeactivationStatus> | DeactivationStatus {
    if (!this.save) {
      return DeactivationStatus.Accepted;
    }

    if (!this.canSave) {
      return DeactivationStatus.NeedsConfirmation;
    }

    return this.save.pipe(map((x) => (x ? DeactivationStatus.Accepted : DeactivationStatus.NeedsConfirmation)));
  }

  protected initializeFromFormGroup<T>(
    formGroup: FormGroup,
    save: (formValue: any) => Promise<T>,
    changeDetectorRef?: ChangeDetectorRef
  ): void {
    this.save = this.createSaveObservableFromFormGroup(formGroup, save, changeDetectorRef);
    this.saveSubscription = formGroup.valueChanges;
  }

  protected createSaveObservableFromFormGroup<T>(
    formGroup: FormGroup,
    save: (formValue: any) => Promise<T>,
    changeDetectorRef?: ChangeDetectorRef
  ): Observable<boolean> {
    return this.createSaveObservable(
      () => formGroup.dirty,
      () => save(formGroup.value),
      changeDetectorRef
    );
  }

  protected createSaveObservable<T>(
    shouldSave: () => boolean,
    save: () => Promise<T>,
    changeDetectorRef?: ChangeDetectorRef
  ): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      changeDetectorRef?.detectChanges();

      const done = (result = true) => {
        this.isSaving = false;
        observer.next(result);
      };

      if (!shouldSave() || this.isSaving) {
        done();
        return;
      }

      this.isSaving = true;
      save()
        .then(() => done(true))
        .catch(() => done(false));
    });
  }

  @NotifySave
  private internalSave(): Observable<boolean> {
    return this.save;
  }
}
