import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ViewMoreModalData {
  name?: string;
  value: any;
  nested?: boolean;
  isUrl?: boolean;
}

@Component({
  selector: 'app-view-more-modal',
  templateUrl: './view-more-modal.component.html',
  styleUrls: ['./view-more-modal.component.scss'],
})
export class ViewMoreModalComponent {
  constructor(private dialogRef: MatDialogRef<ViewMoreModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ViewMoreModalData[]) {}

  onClose() {
    this.dialogRef.close();
  }

  checkIfArray(val: string | any[]): boolean {
    return val instanceof Array;
  }
}
