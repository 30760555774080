import { EcoAreaType } from '../../../models/evaluation';

export interface EcoRating {
  id?: string;
  updatedOn?: Date;
  evaluationId?: string;
  iepId?: string;
  ifspId?: string;
  caseId?: string;
  ecoAreaId?: string;
  score?: number;
  suggestedScore?: number;
  ageExpectedRegardOutcome?: boolean;
  immediateFoundationalSkills?: boolean;
  consideredAgeExpectedSetting?: boolean;
  whatExtentFunctionSetting?: boolean;
  doesAnyoneHaveConcerns?: boolean;
  usesSkillsThatAreNotImmediate?: boolean;
  occasionalUseOfSkill?: boolean;
  usesImmediateSkillsAll?: boolean;
  isLocked: boolean;
  amendmentId?: string;
  priorVersionId?: string;
  priorVersion?: EcoRating;
  finalEcoPkCaseId?: string;
  ecoRatingProgress?: boolean;
}

export enum EcoRatingType {
  Evaluation = 'Evaluation',
  Iep = 'Iep',
  Ifsp = 'Ifsp',
  Transition = 'Transition',
  Case = 'Case',
  FinalEcoPkCase = 'FinalEcoPkCase',
}

export interface EcoDataHistory {
  id: string;
  ecoAreaType: EcoAreaType;
  ecoAreaLabel: string;
  updatedOn: Date;
  madeProgress?: boolean;
  ecoRatingDescription: string;
  score?: number;
}
