import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirstNamePossessivePipe } from 'src/app/shared/pipes/first-name-possessive.pipe';
import { AchieveConfigService } from 'src/app/shared/services/achieve-config-service/achieve-config.service';

@Component({
  selector: 'app-learner-invite-modal',
  templateUrl: './learner-invite-modal.component.html',
  styleUrls: ['./learner-invite-modal.component.scss'],
  providers: [FirstNamePossessivePipe],
})
export class LearnerInviteModalComponent implements OnInit {
  firstNamePossessive = FirstNamePossessivePipe;
  formGroup = new FormGroup({
    learnerEmail: new FormControl(null, [Validators.required, Validators.email]),
    termsAndConditions: new FormControl(null, [Validators.requiredTrue]),
  });

  get learnerName() {
    return this.data.learnerName;
  }

  get familyPortalTermsAndConditionsUrl() {
    return this.achieveConfigService.settings.familyPortalTermsAndConditionsUrl;
  }

  constructor(
    public dialogRef: MatDialogRef<LearnerInviteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly achieveConfigService: AchieveConfigService
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    if (this.formGroup.valid) {
      this.dialogRef.close(this.formGroup);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
