<mat-card class="card--form py-2">
  <form [formGroup]="formGroup">
    <div>
      <div class="display-flex align-items-center">
        <h3 class="mb-0 mr-3" [ngClass]="{ asterisk_input: !formGroup.get('interviewType').value }">Type Of Interview:</h3>
        <mat-button-toggle-group [formControl]="interviewTypeControl" class="mr-4" aria-labelledby="Type of Interview">
          <mat-button-toggle *ngFor="let type of interviewTypes" [value]="type.key" attr.aria-label="{{ type.value }}">
            {{ type.value }}</mat-button-toggle
          >
        </mat-button-toggle-group>
        <div>
          <p class="text-red-error" *ngIf="interviewTypeControl.touched && interviewTypeControl.hasError('required')">
            Field is <strong>required</strong>
          </p>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <app-help-directional-text>
            <p>
              If the child is eligible for Early ACCESS services, a Family Interview with a completed Resources, Priorities, and Concerns
              section is required.
            </p>
          </app-help-directional-text>
        </div>
      </div>

      <div class="row mt-2" *ngIf="formGroup.get('interviewType').value === 'General'">
        <div class="col">
          <app-help-directional-text>
            <p>
              Use the general interview section to document information gathered by asking questions separate from administering a test.
            </p>
          </app-help-directional-text>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12 col-xl-6" *ngIf="formGroup.get('interviewType').value === 'Family'">
          <fieldset>
            <app-radio-group [options]="toolOptions" formControlName="toolUsed" label="Tool Used"></app-radio-group>
          </fieldset>
        </div>

        <div class="col-md-12 col-xl-6">
          <div class="row">
            <div class="col-md-12" *ngIf="formGroup.get('toolUsed').value === 'other'">
              <app-text-field
                label="Other"
                [isBankableField]="true"
                [bankableFieldId]="bankableFieldIds.evaluationInterview.toolOtherDescription"
                [bankableFieldChildFname]="learnerFirstName"
                formControlName="toolOtherDescription"
              >
              </app-text-field>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2 ml-1">
        <div class="col-xl-3 col-md-6">
          <app-text-field label="Interviewee" formControlName="interviewee"></app-text-field>
        </div>
      </div>
    </div>

    <ng-container *ngIf="formGroup.get('interviewType').value === 'Family'">
      <hr class="input__divider" />
      <div class="display-flex align-items-center justify-content-between w-100 py-2">
        <h3
          [ngClass]="{
            asterisk_input: !mainQuestionsHaveNotes,
            'text-red': !mainQuestionsHaveNotes && isEditing
          }"
        >
          Family Interview: Resources, Priorities, and Concerns
        </h3>
        <button
          mat-raised-button
          color="accent"
          aria-label="View Script"
          class="mr-5"
          (click)="viewResourcesPrioritiesConcernsScript($event)"
          tabindex="0"
        >
          View Script
        </button>
      </div>
      <app-help-directional-text>
        <p>Submitting note for each area is required.</p>
      </app-help-directional-text>
      <div class="row">
        <div class="col-md-12">
          <div *ngFor="let question of mainInterviewQuestions; last as isLast">
            <div class="evaluation-note border-bottom" [id]="question.id" #interviewQuestion>
              <div class="row">
                <div class="col-md-12 col-xl-7">
                  <app-evaluation-note
                    [interviewQuestion]="question"
                    [isInterview]="true"
                    [currentEvaluationNote]="getCurrentQuestionNote(question)"
                    [currentEvaluationNotes]="currentEvaluationDetail.notes"
                    [currentEvaluationDetail]="currentEvaluationDetail"
                  ></app-evaluation-note>
                </div>
                <div class="col-md-12 col-xl-5">
                  <div *ngIf="question.prompts?.length > 0">
                    <h3 class="mb-2 text-nrm">Prompts</h3>
                    <ul class="prompt__list">
                      <li class="prompt__item" *ngFor="let prompt of question.prompts">
                        {{ prompt.replace('**childFirstName**', this.learnerFirstName) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="currentEvaluationDetail.toolUsed === 'other'">
            <div *ngFor="let question of additionalInterviewQuestions">
              <div class="row" [id]="question.id" #interviewQuestion>
                <div class="col-md-12">
                  <app-evaluation-note
                    [interviewQuestion]="question"
                    [isInterview]="true"
                    [currentEvaluationNote]="getCurrentQuestionNote(question)"
                    [currentEvaluationNotes]="currentEvaluationDetail.notes"
                    [currentEvaluationDetail]="currentEvaluationDetail"
                  ></app-evaluation-note>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="formGroup.get('interviewType').value === 'Routines'">
      <hr class="input__divider" />
      <div class="display-flex align-items-center justify-content-between w-100 py-2">
        <h3
          [ngClass]="{
            asterisk_input: !routineQuestionsHaveNotes,
            'text-red': !routineQuestionsHaveNotes && isEditing
          }"
        >
          Family Interview: Routines
        </h3>
        <button mat-raised-button color="accent" aria-label="View Script" class="mr-5" (click)="viewRoutinesScript($event)" tabindex="0">
          View Script
        </button>
      </div>
      <div class="row">
        <div class="col-md-12 col-xl-7 mr-4">
          <div *ngFor="let question of routineInterviewQuestions">
            <div class="row" [id]="question.id" #interviewQuestion>
              <div class="col-md-12">
                <app-evaluation-note
                  [interviewQuestion]="question"
                  [isInterview]="true"
                  [currentEvaluationNote]="getCurrentQuestionNote(question)"
                  [currentEvaluationNotes]="currentEvaluationDetail.notes"
                  [currentEvaluationDetail]="currentEvaluationDetail"
                ></app-evaluation-note>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-xl-4 ml-4 pl-0">
          <div class="row">
            <div class="col-md-12">
              <h3 class="mb-2 text-nrm">Prompts</h3>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-12">
              <ul class="prompt__list">
                <li class="prompt__item">What do you do most mornings? or How do you start your day?</li>
                <li class="prompt__item">
                  After hearing about morning routines, ask “what happens next” in order to transition to the next routine. Be careful to
                  cover routines for the entire day (morning, afternoon, evening).
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container>
      <ng-container *ngIf="formGroup.get('interviewType').value === 'Routines'">
        <hr class="input__divider" />

        <div class="display-flex align-items-center justify-content-between mt-3">
          <div class="display-flex align-items-center">
            <h3 class="mb-0 mr-5">Routines and Activities</h3>
          </div>

          <div class="display-flex align-items-center">
            <button
              mat-raised-button
              color="primary"
              aria-label="Add New Routine"
              class="mr-2 mat-elevation-z1"
              (click)="onAddRoutine()"
              tabindex="0"
            >
              Add New Routine
            </button>
          </div>
        </div>
      </ng-container>
      <mat-accordion
        displayMode="flat"
        multi
        class="accordion accordion--close accordion--no-shadow accordion--no-border display-block"
        [ngClass]="{
          'card-spacing-top': formGroup.get('interviewType').value === 'Routines'
        }"
        *ngIf="formGroup.get('interviewType').value === 'Routines'"
      >
        <mat-expansion-panel
          class="mat-elevation-z0"
          *ngFor="let routine of routineFormArray; let i = index"
          [formGroup]="routine"
          [expanded]="routine.get('id').value === routineEditingId"
        >
          <mat-expansion-panel-header>
            <mat-panel-title
              class="align-items-center"
              [ngClass]="{
                asterisk_input: routine.invalid,
                'text-red': routine.invalid && isEditing
              }"
            >
              Routines
              {{ evaluationFormService.getRoutineLabel(routine.value) }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div
            class="row"
            [ngClass]="{
              'pt-3': routine?.get('satisfaction')?.hasError('required') && isEditing
            }"
          >
            <div class="col-md-12 mr-4">
              <div class="row">
                <div class="col-md-12">
                  <mat-label
                    class="mb-2 display-block"
                    [ngClass]="{
                      asterisk_input: routine?.get('satisfaction')?.hasError('required')
                    }"
                    >How satisfied are you with this routine?</mat-label
                  >

                  <mat-button-toggle-group aria-labelledby="How satisfied are you with this routine?" formControlName="satisfaction">
                    <mat-button-toggle [value]="1" aria-labelledby="1 - Very worried / sad">1 - Very worried / sad</mat-button-toggle>
                    <mat-button-toggle [value]="2" aria-labelledby="2 - Thinking about often">2 - Thinking about often</mat-button-toggle>
                    <mat-button-toggle [value]="3" aria-labelledby="3 - Satisfied">3 - Satisfied</mat-button-toggle>
                    <mat-button-toggle [value]="4" aria-labelledby="4 - Happy">4 - Happy</mat-button-toggle>
                    <mat-button-toggle [value]="5" aria-labelledby="5 - Very Happy">5 - Very Happy</mat-button-toggle>
                  </mat-button-toggle-group>
                  <mat-error *ngIf="routine.touched && routine.get('satisfaction').hasError('required')">Field is required.</mat-error>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <app-evaluation-tags
                    [caseId]="caseId"
                    [routine]="routine"
                    [isRoutine]="true"
                    [evaluationId]="currentEvaluationDetail?.evaluationId"
                    [selectedEcoAreas]="(currentEvaluationDetail?.routines)[i]?.ecoAreas || []"
                    [selectedEvaluationDomains]="(currentEvaluationDetail?.routines)[i]?.domainAreas || []"
                    (evalTagsChange)="tagsChanged()"
                  ></app-evaluation-tags>
                </div>
              </div>
              <div class="row">
                <div class="col-md-11">
                  <ng-container *ngIf="routine.get('satisfaction').value > 2">
                    <div class="row">
                      <div class="col">
                        <app-textarea
                          formControlName="workingWell"
                          label="What is working well for you and your child during this routine?"
                        ></app-textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <app-textarea
                          formControlName="routineDifficulty"
                          label="Is there a part of the routine that is difficult for you and your child?"
                        ></app-textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <app-textarea
                          formControlName="routineParticipation"
                          label="Describe how the child assists/participates in this routine. Describe how they are engaged in the routine. What are the other family members doing this routine?"
                        ></app-textarea>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="routine.get('satisfaction').value && routine.get('satisfaction').value < 3">
                    <div class="row mt-3">
                      <div class="col">
                        <app-textarea
                          formControlName="routineLooksLike"
                          label="Tell us what the routine looks like. Does it look the same if it is a different family member?"
                          [rows]="4"
                        ></app-textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <app-textarea
                          formControlName="routineParticipation"
                          label="Describe how the child assists/participates in this routine. Describe how they are engaged in the routine. What are the other family members doing this routine?"
                          [rows]="4"
                        ></app-textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <app-textarea
                          formControlName="partWorkingWell"
                          label="Is there a part of the routine that is going well for you and your child?"
                          [rows]="4"
                        ></app-textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <app-textarea
                          formControlName="routineDifficulty"
                          label="Is there a part of the routine that is difficult for you and your child?"
                          [rows]="4"
                        ></app-textarea>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <app-textarea
                          formControlName="wouldRoutineImproveHelp"
                          label="How would it help your family or child if this routine were improved?"
                          [rows]="4"
                        ></app-textarea>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="routine?.get('satisfaction')?.value">
                    <hr class="input__divider" />

                    <div class="row">
                      <div class="col">
                        <mat-label class="mr-3">Is this something you want to work on together?</mat-label>
                        <app-radio-group
                          aria-labelledby="Is this something you want to work on together?"
                          formControlName="workOnTogether"
                          [options]="yesNoOptions"
                          [hideAsterisk]="true"
                        ></app-radio-group>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
  </form>
</mat-card>

<ng-template #isNotEligibleScript>
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    Family Interview: Resources, Priorities, and Concerns
  </h3>
  <mat-dialog-content class="py-3">
    <mat-card class="mb-0">
      <ul class="script__list">
        <li>
          Early ACCESS provides support and resources in partnership with family members and caregivers to empower the family to help their
          child develop and learn through everyday activities and routines.
        </li>
        <li>
          It is important for us to gather information so that we can get to know your family. In order to identify how we can best support
          your child and family, I am going to ask questions to learn more about your family’s strengths, resources, priorities. I also want
          to hear about your concerns about caring for and supporting your child's development.
        </li>
        <li>
          If your child is determined eligible, the Early ACCESS team, including you, will develop child and family outcomes or goals based
          on your priorities and concerns. Outcomes or goals guide our plan for what we will be working on together so that your child can
          participate in everyday family and community activities.
        </li>
      </ul>
    </mat-card>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="primary" type="button" mat-raised-button aria-label="Delete Icon" [mat-dialog-close]="true" tabindex="0">
      Close
    </button></mat-dialog-actions
  >
</ng-template>

<ng-template #isEligibleScript>
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    Family Interview: Resources, Priorities, and Concerns
  </h3>
  <mat-dialog-content class="py-3">
    <mat-card class="mb-0">
      <ul class="script__list">
        <li>
          Early ACCESS provides support and resources in partnership with family members and caregivers to empower the family to help their
          child develop and learn through everyday activities and routines.
        </li>
        <li>
          It is important for us to gather information so we can get to know your family. In order to identify how we can best support your
          child and family, I am going to ask questions to learn more about your family’s strengths, resources, and priorities. I also want
          to hear about your concerns about caring for and supporting your child's development.
        </li>
        <li>
          The Early ACCESS team, including you, will develop child and family outcomes or goals based on your priorities and concerns.
          Outcomes or goals guide our plan for what we will be working on together so that your child can participate in everyday family and
          community activities.
        </li>
      </ul>
    </mat-card>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="primary" type="button" mat-raised-button aria-label="Delete Icon" [mat-dialog-close]="true" tabindex="0">
      Close
    </button></mat-dialog-actions
  >
</ng-template>

<ng-template #routinesScript>
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Family Interview: Routines</h3>
  <mat-dialog-content class="py-3">
    <mat-card class="mb-0">
      <ul class="script__list">
        <li>
          The focus of Early ACCESS is to support you in helping your child learn from and participate in activities that are meaningful to
          your family. Therefore, it is important for us to understand the things you do most days (such as getting dressed and mealtime)
          and how you, your child and other family members currently participate. By sharing this information, we can identify opportunities
          to practice the skills you want your child to learn.
        </li>
      </ul>
    </mat-card>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="primary" type="button" mat-raised-button aria-label="Delete Icon" [mat-dialog-close]="true" tabindex="0">
      Close
    </button></mat-dialog-actions
  >
</ng-template>
