<ul class="list">
  <li class="list__item">
    <h4 class="text-primary mt-0 mb-2">Academic</h4>
    <p class="my-0">
      Grade level achievement of standards related to basic reading skills (e.g. early literacy, phonics, phonemic awareness, concepts of
      print, decoding, fluency), reading comprehension (e.g. vocabulary, comprehension, sequencing), basic math skills (e.g. early math
      readiness, math calculations, numeracy, number sense), applied math skills (e. g. mathematical problem solving, time, money), written
      expression, listening comprehension, and oral expression.
    </p>
  </li>

  <li class="list__item">
    <h4 class="text-primary mt-0 mb-2">Adaptive Behavior</h4>
    <p class="my-0">
      Everyday living skills (e.g., dressing, eating, toileting), work skills, or school functioning skills (e.g., meeting timelines,
      organization of materials, engagement and persistence) that a child learns in the process of adapting to his or her surroundings.
    </p>
  </li>

  <li class="list__item">
    <h4 class="text-primary mt-0 mb-2">Communication</h4>
    <p class="my-0">
      Receptive and expressive language (understanding, form, content or use). This includes, but is not limited to, language (social
      communication), vocabulary, speech sound production, voice (nasality), or fluency.
    </p>
  </li>

  <li class="list__item">
    <h4 class="text-primary mt-0 mb-2">Hearing</h4>
    <p class="my-0">The ability to perceive sound.</p>
  </li>

  <li class="list__item">
    <h4 class="text-primary mt-0 mb-2">Health</h4>
    <p class="my-0">
      The general condition of the body or mind, especially in terms of the presence or absence of illness, injury, or impairments.
    </p>
  </li>

  <li class="list__item">
    <h4 class="text-primary mt-0 mb-2">Physical</h4>
    <p class="my-0">Gross/large motor skills, fine/small motor skills, and mobility for learning, living, and work.</p>
  </li>

  <li class="list__item">
    <h4 class="text-primary mt-0 mb-2">Social Emotional Behavior</h4>
    <p class="my-0">Awareness of self, identification, and expression of emotions, self-regulation, and interaction with others.</p>
  </li>

  <li class="list__item">
    <h4 class="text-primary mt-0 mb-2">Vision</h4>
    <p class="my-0">The ability to see.</p>
  </li>
</ul>
