import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AppPermissions } from 'src/app/permissions';
import { QuantifiableDataComponent } from 'src/app/shared/components/quantifiable-data/quantifiable-data.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { FormService } from 'src/app/shared/services/form.service';
import { onlyNumberValidator } from 'src/app/shared/validators';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { TaggedForCategory } from 'src/app/tags/tagged-category';
import { TaggedItem } from 'src/app/tags/tagged-item';
import { TaggingService } from 'src/app/tags/taggingService.service';
import { AuthService } from '../../../auth/auth.service';
import { EcoArea } from '../../../evaluation/models/evaluation';
import { AreYouSureComponent } from '../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { BaseComponent } from '../../../shared/components/base-component/base-component';
import { StringSizes } from '../../../shared/components/form/constants/constants';
import { CaseSummaryByIfspId } from '../../../shared/models/case';
import { FamilyArea } from '../../../shared/models/family-area';
import { CaseService } from '../../../shared/services/case/case.service';
import { DeactivationService, DeactivationStatus } from '../../../shared/services/deactivation.service';
import { EcoAreaService } from '../../../shared/services/eco-areas/eco-area.service';
import { FamilyAreaService } from '../../../shared/services/family-areas/family-areas.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { IfspModification } from '../../models/ifsp-modification';
import { IfspServiceSummaryDto } from '../../models/ifsp-service-models';
import { Outcome, OutcomeCriteria, OutcomeDto, OutcomeProgress, OutcomeProgressEdit } from '../../models/outcome-models';
import { IfspServicesService } from '../../services/ifsp-service.service';
import { OutcomeService } from '../../services/outcome.service';
import { OutcomeCriteriaViewComponent } from './outcome-criteria-view/outcome-criteria-view.component';

@Component({
  selector: 'app-ifsp-child-family-outcomes',
  templateUrl: './ifsp-child-family-outcomes.component.html',
  styleUrls: ['./ifsp-child-family-outcomes.component.scss'],
  providers: [DatePipe],
})
export class IfspChildFamilyOutcomesComponent extends BaseComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private titleSubscription: Subscription = new Subscription();

  @ViewChild(MatSort) sort: MatSort;
  @Input() modificationId: string;
  @Input() modifications: IfspModification[];
  @Input() modifyingOutcome: OutcomeDto;

  editInProgress = false;
  isBusy = false;
  taggedForCategory = TaggedForCategory;
  maxLength = StringSizes.large;
  isAddingExistingOutcome: boolean;

  services: Array<IfspServiceSummaryDto> = [];
  servicesKeyValues: Array<KeyValuePair> = [];

  get modification() {
    return this.modifications.find((x) => x.id === this.modificationId);
  }

  get modificationIsFinalized() {
    return this.modification?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.modifications && this.modifications.length > 0) {
      const latest = this.modifications.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasModification() {
    return !!this.modificationId;
  }

  get hasPriorVersion() {
    return !!this.modifyingOutcome?.priorVersionId;
  }

  @ViewChild('editingOutcome') editingOutcome: ElementRef;
  @ViewChild('formDirective') formDirective: NgForm;
  @ViewChild('quantdata') quantData: QuantifiableDataComponent;

  ifspId: string;
  outcomeIdToEditFromRoute: string;
  caseId: string;
  outcomes: Array<OutcomeDto> = [];
  outcome: OutcomeDto;
  unModifiedOutcome: OutcomeDto;
  criterion: OutcomeCriteria;
  caseSummary: CaseSummaryByIfspId;
  criterionSubmitAttempted = false;
  isEditingProgress = false;
  isNew = true;
  progressIndexForEdit: any = null;
  ecoAreas: EcoArea[] = [];
  familyAreas: FamilyArea[] = [];
  progressDataSource = new MatTableDataSource<OutcomeProgressEdit>([]);
  displayedProgressColumns = ['actions', 'method', 'frequency'];
  criterionDataSource = new MatTableDataSource<OutcomeCriteria>([]);
  displayedCriterionColumns = ['actions', 'criterionForAccomplishment', 'measurementType', 'baseline', 'goal', 'measurements'];
  relatedToChecked = false;
  quantifiedDataFormLoaded = false;
  today = dayjs().startOf('day').toDate();
  shortDateFormat = shortDateFormat;
  permissions = AppPermissions;
  formGroup = new FormGroup({
    id: new FormControl(null),
    typeOfOutcome: new FormControl(null, Validators.required),
    ecoAreas: new FormControl([]),
    familyAreas: new FormControl([]),
    relatedTo: new FormControl(null),

    title: new FormControl(null, Validators.required),
    strategies: this.fb.array([]),

    criterias: new FormControl(null),
    outcomeEndDate: new FormControl(null, Validators.required),
    progresses: new FormControl([]),

    ifspId: new FormControl(null),
    isComplete: new FormControl(false),
    modificationId: new FormControl(null),

    currentServicesForOutcome: new FormControl([]),
  });

  criterionFormGroup = new FormGroup(
    {
      id: new FormControl(null),
      priorVersionId: new FormControl(null),
      criteriaForAccomplishment: new FormControl(null, Validators.required),
      measurementType: new FormControl('numeric', {
        validators: [Validators.required],
        updateOn: 'change',
      }),
      quantifiableData: this.fb.group({
        areaOfConcern: [null],
        assessmentEvaluationActivity: [null],
        measurement: [null],
        trials: [null],
        scaleRatings: [[]],
        milestones: [[]],
        baseline: [null],
        isExpectedNarrative: [false],
        expectedPerformanceMin: [null],
        expectedPerformanceMax: [null],
        expectedPerformanceNarrative: [null],
        isNarrative: [false],
        performanceMin: [null, Validators.min(0)],
        performanceMax: [null],
        performanceNarrative: [null],
        isAdvanced: [false],
        secondMeasurementDescription: [null],
        secondIsExpectedNarrative: [false],
        secondExpectedPerformanceMin: [null],
        secondExpectedPerformanceMax: [null],
        secondExpectedPerformanceNarrative: [null],
        secondIsNarrative: [false],
        secondPerformanceMin: [null],
        secondPerformanceMax: [null],
        secondPerformanceNarrative: [null],
        modificationId: [null],
      }),
    },
    { updateOn: 'change' }
  );

  progressFormGroup = new FormGroup(
    {
      measuringMethod: new FormControl(null, Validators.required),
      otherMethodDescribe: new FormControl(null),
      frequencyNumber: new FormControl(null, [Validators.required, onlyNumberValidator, Validators.min(0)]),
      frequencyPeriod: new FormControl(null, Validators.required),
      priorVersionId: new FormControl(null),
    },
    { updateOn: 'blur' }
  );

  progressInlineFormGroup = this.fb.group({
    id: [null],
    measuringMethod: [null, [Validators.required]],
    otherMethodDescribe: [null],
    frequencyNumber: [null, [Validators.required]],
    frequencyPeriod: [null, [Validators.required]],
  });

  measurementTypes: KeyValuePair[] = [new KeyValuePair('numeric', 'Numeric'), new KeyValuePair('nonnumeric', 'Yes/No')];

  measuringMethodOptions: KeyValuePair[] = [
    new KeyValuePair('Parent Report', 'Parent Report'),
    new KeyValuePair('Progress Monitoring Tool', 'Progress Monitoring Tool'),
    new KeyValuePair('Provider Progress Notes', 'Provider Progress Notes'),
    new KeyValuePair('Other', 'Other'),
  ];

  frequencyPeriodOptions: KeyValuePair[] = [
    new KeyValuePair('day', 'per day'),
    new KeyValuePair('week', 'per week'),
    new KeyValuePair('month', 'per month'),
    new KeyValuePair('year', 'per year'),
  ];
  autosaveSubscription = new Subscription();
  showProgressRequiredError = false;

  get strategiesFormArray(): FormGroup[] {
    return (this.formGroup.get('strategies') as FormArray).controls as FormGroup[];
  }

  get showCriteriaSection() {
    return this.formGroup.get('typeOfOutcome').value && (this.hasEcoAreas || this.hasFamilyAreas) && this.formGroup.get('title').value;
  }

  get hasEcoAreas() {
    return this.formGroup.get('ecoAreas').value && this.formGroup.get('ecoAreas').value.length > 0;
  }

  get hasFamilyAreas() {
    return this.formGroup.get('familyAreas').value && this.formGroup.get('familyAreas').value.length > 0;
  }

  save = new Observable<boolean>((observer) => {
    this.cd.detectChanges();

    const done = () => {
      this.isSaving = false;
      this.getOutcomes();
      observer.next(true);
    };

    if (!this.formGroup.dirty || this.isSaving || !this.formGroup.get('title').value) {
      done();
      return;
    }
    this.isSaving = true;
    const outcomeFormValue = this.formGroup.value as Outcome;
    if (!!outcomeFormValue.id) {
      this.outcomeService.updateOutcome(this.ifspId, outcomeFormValue).subscribe(() => done());
    } else {
      this.outcomeService.createOutcome(this.ifspId, outcomeFormValue).subscribe((outcomeResult) => {
        this.outcome = outcomeResult;
        this.formGroup.controls.id.setValue(outcomeResult.id, {
          emitEvent: false,
        });
        this.formGroup.markAsPristine();
        done();
      });
    }
  });

  constructor(
    private fb: FormBuilder,
    private outcomeService: OutcomeService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private caseService: CaseService,
    private cd: ChangeDetectorRef,
    private ecoAreaService: EcoAreaService,
    private familyAreaService: FamilyAreaService,
    public authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private datePipe: DatePipe,
    private readonly formService: FormService,
    private taggingService: TaggingService,
    deactivationService: DeactivationService,
    private ifspServicesService: IfspServicesService
  ) {
    super(deactivationService);
    this.saveSubscription = this.formGroup.valueChanges;
  }

  ngOnInit(): void {
    this.caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    this.ifspId = this.route.parent?.snapshot.paramMap.get('ifspId');
    if (!this.caseId) {
      this.caseId = this.route.snapshot.paramMap.get('caseId');
    }
    if (!this.ifspId) {
      this.ifspId = this.route.snapshot.paramMap.get('ifspId');
    }

    this.formGroup.get('ifspId').setValue(this.ifspId, { emitEvent: false });
    this.formGroup.get('modificationId').setValue(this.modificationId, { emitEvent: false });

    this.outcomeIdToEditFromRoute = this.route.snapshot.paramMap.get('outcomeId');

    this.caseService.getCaseSummaryByIfsp(this.ifspId).subscribe((caseSummary) => {
      this.caseSummary = caseSummary;
    });

    this.addStrategies();

    forkJoin([this.ecoAreaService.get(), this.familyAreaService.get()]).subscribe(([ecoAreas, familyAreas]) => {
      this.ecoAreas = ecoAreas;
      this.familyAreas = familyAreas;
    });

    if (this.modifyingOutcome) {
      this.onEditOutcome(this.modifyingOutcome);
    }

    this.outcomeService.setModifyingOutcome.subscribe((result) => {
      this.modifyingOutcome = result;
      this.onEditOutcome(this.modifyingOutcome);
    });

    this.getOutcomes();

    this.setupFormSubscriptions();

    this.formGroup.setValidators([this.criteriaValidator(), this.strategiesValidator(), this.progressesValidator()]);

    this.formGroup.updateValueAndValidity();

    this.progressDataSource.sort = this.criterionDataSource.sort = this.sort;

    this.subscription.add(
      this.formService.showAllErrors$.subscribe(() => {
        this.formGroup.markAllAsTouched();
        this.criterionFormGroup.markAllAsTouched();
        const control = this.formGroup.get('progresses');
        if (control?.value === null || control?.value?.length === 0) {
          this.progressFormGroup.markAllAsTouched();
          this.showProgressRequiredError = true;
        }
      })
    );

    this.subscription.add(
      this.progressFormGroup.get('measuringMethod').valueChanges.subscribe((value) => {
        const control = this.progressFormGroup.get('otherMethodDescribe');
        if (value === 'Other') {
          control.setValidators(Validators.required);
        } else {
          control.clearValidators();
        }
        control.updateValueAndValidity({ emitEvent: false });
      })
    );

    this.refreshServices();
  }

  refreshServices() {
    this.ifspServicesService.getServices(this.ifspId).subscribe((services) => {
      this.services = services;
      this.servicesKeyValues = services.map((x) => new KeyValuePair(x.id, x.typeOfService));
    });
  }

  onClearControl(control: AbstractControl): void {
    control.patchValue(null);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.autosaveSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  tag(outcome: OutcomeDto, taggedForCategory: TaggedForCategory) {
    if (outcome?.taggedItem) {
      outcome.taggedItem.taggedForPwn =
        taggedForCategory === TaggedForCategory.Pwn ? !outcome.taggedItem.taggedForPwn : outcome.taggedItem.taggedForPwn;
      outcome.taggedItem.taggedForServicesC =
        taggedForCategory === TaggedForCategory.ServicesC ? !outcome.taggedItem.taggedForServicesC : outcome.taggedItem.taggedForServicesC;
      this.taggingService.updateTag(outcome?.taggedItem).subscribe((tagReceived) => {
        outcome.taggedItem = tagReceived;
      });
    } else {
      const newlyTaggedItem = {
        outcomeId: outcome.id,
        caseId: this.caseId,
        taggedForPwn: taggedForCategory === TaggedForCategory.Pwn,
        taggedForServicesC: taggedForCategory === TaggedForCategory.ServicesC,
      } as TaggedItem;
      this.taggingService.addTag(newlyTaggedItem).subscribe((tagReceived) => {
        outcome.taggedItem = tagReceived;
      });
    }
  }

  canDeactivate(): Observable<DeactivationStatus> | Promise<DeactivationStatus> | DeactivationStatus {
    return (super.canDeactivate() as Observable<DeactivationStatus>).pipe(
      map((status) =>
        status === DeactivationStatus.Accepted && (this.criterionFormGroup?.dirty || this.progressFormGroup?.dirty)
          ? DeactivationStatus.NeedsConfirmation
          : status
      )
    );
  }

  getOutcomes() {
    this.outcomeService.getOutcomes(this.ifspId).subscribe((result) => {
      this.outcomes = result;
      if (!!this.outcome) {
        this.outcome = this.outcomes.find((x) => x.id === this.outcome.id);
        this.criterionDataSource.data = this.outcome?.criterias;
        this.formGroup.controls.criterias.setValue(
          this.outcome?.criterias.map((x) => {
            return {
              id: x.id,
              criteriaForAccomplishment: x.criteriaForAccomplishment,
              measurementType: x.measurementType,
              quantifiableData: x.quantifiableData,
              priorVersionId: x.priorVersionId,
              modificationId: this.modificationId,
              priorVersion: x.priorVersion,
            };
          }),
          { emitEvent: false }
        );
      }
      if (this.outcomeIdToEditFromRoute) {
        this.onEditOutcome(this.outcomes.find((o) => o.id === this.outcomeIdToEditFromRoute));
        this.outcomeIdToEditFromRoute = null;
      }
    });
  }

  strategiesValidator(): ValidatorFn {
    return (cc: FormGroup): ValidationErrors => {
      if (
        this.formGroup.get('strategies').value &&
        this.formGroup.get('strategies').value.length > 0 &&
        (this.formGroup.get('strategies').value as any[]).find((x) => x.name !== null && x.name !== '')
      ) {
        return null;
      } else {
        return { strategiesRequired: true };
      }
    };
  }

  progressesValidator(): ValidatorFn {
    return (cc: FormGroup): ValidationErrors => {
      if (this.progressDataSource.data.length > 0) {
        return null;
      } else {
        return { progressesRequired: true };
      }
    };
  }

  criteriaValidator(): ValidatorFn {
    return (cc: FormGroup): ValidationErrors => {
      if (this.criterionDataSource.data.length > 0) {
        return null;
      } else {
        return { criterionRequired: true };
      }
    };
  }

  toggleRelatedTo(event) {
    if (this.relatedToChecked) {
      this.relatedToChecked = false;
    } else {
      this.relatedToChecked = true;
    }
    this.formGroup.controls.relatedTo.setValue(this.relatedToChecked);
  }

  incompleteOutcomes() {
    return this.outcomes.filter((x) => !x.isComplete);
  }

  completeOutcomes() {
    return this.outcomes.filter((x) => x.isComplete);
  }

  patchFormValues(outcome) {
    this.isNew = false;
    this.outcome = outcome;
    const patchedStrategies = this.formGroup.get('strategies') as FormArray;
    patchedStrategies.controls = [];

    outcome.strategies.forEach((row, i) => {
      patchedStrategies.controls.push(this.createStrategiesFormGroup(row.name, row.id, i === 0));
    });

    if (
      outcome.strategies.length === 0 ||
      (outcome.strategies && outcome.strategies.length > 0 && !!outcome.strategies[outcome.strategies.length - 1]?.name)
    ) {
      this.addStrategies();
    }

    this.relatedToChecked = outcome.relatedTo ? true : false;
    this.formGroup.patchValue({
      id: outcome.id,
      typeOfOutcome: outcome.typeOfOutcome,
      ecoAreas: outcome.ecoAreas.map((x) => x.ecoAreaId),
      familyAreas: outcome.familyAreas.map((x) => x.familyAreaId),
      relatedTo: outcome.relatedTo,
      title: outcome.title,
      outcomeEndDate: outcome.outcomeEndDate,
      progresses: outcome.progresses,
      criterias: outcome.criterias,
      ifspId: this.ifspId,
      modificationId: this.modificationId,
      currentServicesForOutcome: this.services.filter((x) => x.outcomes?.some((y) => y.id === this.outcome.id)).map((x) => x.id),
    });
    this.unModifiedOutcome = { ...outcome };
    this.criterionDataSource.data = outcome.criterias;
    this.progressDataSource.data = outcome.progresses;
    this.formGroup.controls.progresses.markAsDirty();
    this.cd.detectChanges();
    this.startAutosaving();
  }

  saveAndClose() {
    this.isBusy = true;
    this.formGroup.controls.isComplete.setValue(false);
    this.save.subscribe((x) => {
      this.outcomeService.outcomeClose.next();
      this.resetForm();
      this.isBusy = false;
    });
    this.isAddingExistingOutcome = false;
  }

  onSubmit(addServices = false) {
    this.isBusy = true;
    if (this.formGroup.valid) {
      this.formGroup.controls.isComplete.setValue(true);
      this.save.subscribe((x) => {
        this.outcomeService.outcomeClose.next();
        if (addServices) {
          this.router.navigate([`cases/${this.caseId}/ifsp/${this.ifspId}/services/outcome/${this.outcome.id}`]);
        } else {
          this.outcome = null;
          this.resetForm();
          this.notificationService.success('Completed Outcome successfully');
          this.scrollToSelectMethod(this.editingOutcome);
        }
        this.isAddingExistingOutcome = false;
        this.isBusy = false;
      });
    }
  }

  onEditOutcome(outcome) {
    this.editInProgress = true;
    this.patchFormValues(outcome);
    this.scrollToSelectMethod(this.editingOutcome);
  }

  async onDeleteOrCancelOutcome(outcomeId) {
    const deleteIt = () => {
      this.outcomeService.deleteOutcome(this.ifspId, outcomeId).subscribe(
        () => {
          this.resetForm();
          this.outcomeService.outcomeClose.next();
          this.getOutcomes();
        },
        (error) => console.log(error)
      );
    };

    if (this.isNew && outcomeId) {
      deleteIt();
    } else {
      this.stopAutosaving();
      if (outcomeId) {
        this.patchFormValues(this.unModifiedOutcome);
        this.formGroup.controls.isComplete.setValue(true);
        const outcomeFormValue = this.formGroup.value as Outcome;
        await this.outcomeService.updateOutcome(this.ifspId, outcomeFormValue).subscribe(() => {
          this.outcomeService.outcomeClose.next();
        });
      }
      this.resetForm();
      this.outcomeService.outcomeClose.next();
      this.startAutosaving();
    }
  }

  resetForm() {
    this.formGroup.controls.criterias.reset();
    this.criterionFormGroup.reset();
    this.outcome = null;
    this.progressDataSource.data = [];
    this.criterionDataSource.data = [];
    (this.formGroup.get('strategies') as FormArray).clear();
    this.addStrategies();
    this.formGroup.reset();
    this.formDirective.resetForm();
    this.editInProgress = false;
    this.formGroup.get('ifspId').setValue(this.ifspId, { emitEvent: false });
    this.formGroup.get('isComplete').setValue(false, { emitEvent: false });
    this.progressFormGroup.reset();
    this.showProgressRequiredError = false;
    this.unModifiedOutcome = null;
  }

  //#region Strategies
  onStrategiesBlur(e: Event, strategy: FormGroup, i: number) {
    const lastStrategy = this.strategiesFormArray[this.strategiesFormArray.length - 1]?.value?.name;
    const strategyFormGroup = (this.formGroup.get('strategies') as FormArray).at(i);
    if (strategy.valid) {
      strategyFormGroup.get('id').setValue(strategy.value.id);
      strategyFormGroup.get('name').setValue(strategy.value.name);
      if (lastStrategy?.length > 0) {
        this.addStrategies();
      }
    } else {
      if (this.strategiesFormArray.length > 1) {
        this.onRemoveStrategies(i);
      }
    }
  }

  addStrategies(): void {
    (this.formGroup.get('strategies') as FormArray).push(this.createStrategiesFormGroup(null, null));
  }

  onRemoveStrategies(i: number): void {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        subQuestion: 'Clicking Yes will remove the selected strategy.',
      },
    });

    dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        const strategiesArr = this.formGroup.controls.strategies as FormArray;
        strategiesArr.removeAt(i);
      }
    });
  }
  //#endregion

  //#region Criterias
  onCriterionView(criterion: OutcomeCriteria) {
    this.dialog.open(OutcomeCriteriaViewComponent, {
      width: '758px',
      data: {
        criterion,
        modificationId: this.modificationId,
      },
    });
  }

  onCriterionSubmit(criterionForm) {
    this.isBusy = true;
    this.criterionSubmitAttempted = true;
    this.criterionFormGroup.markAllAsTouched();
    if (this.criterionFormGroup.valid) {
      if (!this.criterionFormGroup.value.quantifiableData.isExpectedNarrative) {
        this.criterionFormGroup.value.quantifiableData.isExpectedNarrative = false;
      }

      if (!this.criterionFormGroup.value.quantifiableData.isNarrative) {
        this.criterionFormGroup.value.quantifiableData.isNarrative = false;
      }

      if (!this.criterionFormGroup.value.quantifiableData.secondIsExpectedNarrative) {
        this.criterionFormGroup.value.quantifiableData.secondIsExpectedNarrative = false;
      }

      if (!this.criterionFormGroup.value.quantifiableData.secondIsNarrative) {
        this.criterionFormGroup.value.quantifiableData.secondIsNarrative = false;
      }

      if (!this.criterionFormGroup.value.quantifiableData.isAdvanced) {
        this.criterionFormGroup.value.quantifiableData.isAdvanced = false;
      }

      if (this.modificationId) {
        const control = this.criterionFormGroup.get('quantifiableData').get('modificationId');
        if (control) {
          control.patchValue(this.modificationId, { emitEvent: false });
        }
      }

      if (this.criterion) {
        this.outcome.criterias[this.outcome.criterias.findIndex((x) => x.id === this.criterion.id)] = {
          outcomeId: this.criterion.outcomeId,
          ...this.criterionFormGroup.value,
        } as OutcomeCriteria;
      } else {
        if (!this.outcome.criterias) {
          this.outcome.criterias = [];
        }
        this.outcome.criterias.push({
          outcomeId: this.outcome.id,
          ...this.criterionFormGroup.value,
        });
      }

      this.formGroup.controls.criterias.setValue(
        this.outcome.criterias.map((x) => {
          return {
            id: x.id,
            criteriaForAccomplishment: x.criteriaForAccomplishment,
            measurementType: x.measurementType,
            quantifiableData: x.quantifiableData,
            priorVersionId: x.priorVersionId,
            modificationId: this.modificationId,
          };
        })
      );

      this.formGroup.controls.criterias.markAsDirty();
      this.criterionDataSource.data = this.outcome.criterias;
      this.criterion = null;
      this.criterionFormGroup.reset();
      criterionForm.resetForm();
      this.quantData.formGroup.reset();

      while ((this.quantData.formGroup.controls.milestones as FormArray).length !== 0) {
        (this.quantData.formGroup.controls.milestones as FormArray).removeAt(0);
      }

      while ((this.quantData.formGroup.controls.scaleRatings as FormArray).length !== 0) {
        (this.quantData.formGroup.controls.scaleRatings as FormArray).removeAt(0);
      }

      while ((this.quantData.formGroup.controls.trials as FormArray).length !== 0) {
        (this.quantData.formGroup.controls.trials as FormArray).removeAt(0);
      }
      this.quantData.showMilestone = false;
      this.quantData.showMilestoneForm = false;
      this.quantData.showScale = false;
      this.quantData.showScaleForm = false;
      this.quantData.quantDataForm.resetForm();

      if (this.formGroup.get('typeOfOutcome').value === 'Child') {
        this.criterionFormGroup.get('measurementType').setValue('numeric');
      } else {
        this.criterionFormGroup.get('measurementType').setValue('nonnumeric');
      }

      this.formGroup.updateValueAndValidity();
    }
    this.isBusy = false;
  }

  onCriterionEdit(criterion: OutcomeCriteria) {
    this.criterion = criterion;
    this.criterionFormGroup.patchValue(criterion);
  }

  onCriterionDelete(index, criterion: OutcomeCriteria): void {
    this.notificationService.confirmation('Are you sure you want to delete this Criterion?', () => {
      this.outcome.criterias.splice(index, 1);
      this.outcomes[this.outcomes.findIndex((x) => x.id === criterion.outcomeId)].criterias = this.outcome.criterias;
      this.formGroup.controls.criterias.setValue(
        this.outcome.criterias.map((x) => {
          return {
            criteriaForAccomplishment: x.criteriaForAccomplishment,
            measurementType: x.measurementType,
            quantifiableData: x.quantifiableData,
          };
        })
      );
      this.criterionDataSource.data = this.outcome.criterias;
      this.formGroup.markAsDirty();
    });
  }

  onQuantifiedDataFormChange(event) {
    this.criterionFormGroup.get('quantifiableData').patchValue(event.value);
    this.criterionFormGroup.get('quantifiableData').updateValueAndValidity();
    if (event.dirty) {
      this.formGroup.markAsDirty();
    }
  }
  //#endregion

  getEcoAreaValue(value) {
    const ecoArea = this.ecoAreas.find((x) => x.id === value);
    return ecoArea ? ecoArea.label : null;
  }

  getEcoAreasText(ecoAreaValue, isPrior = false) {
    const findLabelEcoArea = (id: string) => this.ecoAreas.find((x) => x.id === id)?.label;

    let ecoAreas = '';
    if (ecoAreaValue)
      ecoAreas = ecoAreaValue
        .map((x) => (isPrior ? findLabelEcoArea(x.ecoAreaId) : findLabelEcoArea(x)))
        .sort()
        .join(', ');

    return ecoAreas;
  }

  getFamilyAreasText(familyAreaValue) {
    let familyAreas = '';
    if (familyAreaValue) {
      familyAreaValue.forEach((area, index) => {
        familyAreas += this.familyAreas.find((x) => x.id === area)?.label;
        if (index + 1 !== familyAreaValue.length) {
          familyAreas += ', ';
        }
      });
      return familyAreas;
    }
    return familyAreas;
  }

  getFamilyAreasValue(value) {
    const familyArea = this.familyAreas.find((x) => x.id === value);
    return familyArea ? familyArea.label : null;
  }

  getMeasurmentTypeText(value) {
    let returnVar = null;
    if (value === 'nonnumeric') {
      returnVar = 'Yes / No';
    }
    if (value === 'numeric') {
      returnVar = 'Numeric';
    }

    return returnVar;
  }

  getScaleMilestoneText(quantData) {
    let returnVar = 'None';
    if (this.criterion?.measurementType === 'nonnumeric') {
      returnVar = 'None';
    } else if (quantData?.milestones.length && quantData?.scaleRatings.length) {
      returnVar = 'Both';
    } else if (quantData?.milestones.length) {
      returnVar = 'Milestone';
    } else if (quantData?.scaleRatings.length) {
      returnVar = 'Scale';
    }
    return returnVar;
  }

  getGoalText(quantData) {
    let returnVar = '-';
    if (!!quantData?.performanceMin && !!quantData?.performanceMax) {
      returnVar = `${quantData.performanceMin} to ${quantData.performanceMax}`;
    } else if (quantData?.performanceMin) {
      returnVar = quantData.performanceMin.toString();
    }
    return returnVar;
  }

  getStrategyText(strategies, isFormArray = false) {
    if (isFormArray) {
      strategies = (strategies as FormGroup[]).map((x) => {
        return { id: x.value?.id, name: x.value?.name };
      });
    }
    if (strategies) {
      return strategies
        .filter((x) => x.name)
        .map((x) => x.name)
        .join(', ');
    }
    return null;
  }

  getOutcomeEndDateText(endDate) {
    let endDateText = '';
    if (endDate) {
      endDateText += this.datePipe.transform(endDate, shortDateFormat);
    }
    return endDateText;
  }

  getProgressPriorVersion(outcomePriorVersion, priorVersionId) {
    let priorVersion = null;
    if (outcomePriorVersion) {
      priorVersion = outcomePriorVersion.progresses.find((x) => x.id === priorVersionId);
    }
    return priorVersion;
  }

  getProgressFrequencyText(progress) {
    let monitoringProgress = null;
    monitoringProgress += progress?.frequencyNumber;
    monitoringProgress += ' time(s) per ';
    monitoringProgress += progress?.frequencyPeriod;
    return monitoringProgress;
  }

  scrollToSelectMethod(element: ElementRef<any>) {
    element.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  showAllErrors() {
    this.formService.showAllErrors();
  }

  // #region Progress
  addProgress(progressForm) {
    if (this.progressFormGroup.valid) {
      this.showProgressRequiredError = false;
      if (!this.outcome.progresses) {
        this.outcome.progresses = [];
      }
      this.outcome.progresses.push({
        measuringMethod: this.progressFormGroup.value.measuringMethod,
        otherMethodDescribe: this.progressFormGroup.value.otherMethodDescribe,
        frequencyNumber: this.progressFormGroup.value.frequencyNumber,
        frequencyPeriod: this.progressFormGroup.value.frequencyPeriod,
        priorVersionId: this.progressFormGroup.value.priorVersionId,
      } as OutcomeProgress);
      progressForm.resetForm();
      this.updateProgressFormValue();
    }
  }

  progressOtherDescribeValidate() {
    if (this.progressInlineFormGroup.controls.measuringMethod.value === 'Other') {
      this.progressInlineFormGroup.controls.otherMethodDescribe.setValidators(Validators.required);
    } else {
      this.progressInlineFormGroup.controls.otherMethodDescribe.clearValidators();
    }
  }

  setEditProgress(progress, editing = true) {
    this.isEditingProgress = editing;
    progress.isEditing = editing;
    if (!editing) {
      this.progressInlineFormGroup.patchValue({});
      return;
    }
    this.progressInlineFormGroup.patchValue({
      id: progress.id,
      measuringMethod: progress.measuringMethod,
      otherMethodDescribe: progress.otherMethodDescribe,
      frequencyNumber: progress.frequencyNumber,
      frequencyPeriod: progress.frequencyPeriod,
      priorVersionId: progress.priorVersionId,
    });
  }

  updateProgress(progress, index) {
    if (this.progressInlineFormGroup.valid) {
      this.outcome.progresses[index].measuringMethod = this.progressInlineFormGroup.value.measuringMethod;
      this.outcome.progresses[index].otherMethodDescribe = this.progressInlineFormGroup.value.otherMethodDescribe;
      this.outcome.progresses[index].frequencyNumber = this.progressInlineFormGroup.value.frequencyNumber;
      this.outcome.progresses[index].frequencyPeriod = this.progressInlineFormGroup.value.frequencyPeriod;
      this.outcome.progresses[index].priorVersionId = progress.priorVersionId;
      this.setEditProgress(progress, false);
      this.updateProgressFormValue();
    }
  }

  removeProgress(index) {
    this.notificationService.confirmation('Are you sure you want to delete this progress?', () => {
      this.outcome.progresses.splice(index, 1);
      this.updateProgressFormValue();
    });
  }

  updateProgressFormValue() {
    this.formGroup.controls.progresses.setValue(
      this.outcome.progresses.map((x) => {
        return {
          id: x.id ? x.id : null,
          measuringMethod: x.measuringMethod,
          otherMethodDescribe: x.otherMethodDescribe,
          frequencyNumber: x.frequencyNumber,
          frequencyPeriod: x.frequencyPeriod,
          priorVersionId: x.priorVersionId,
        };
      })
    );
    this.formGroup.controls.progresses.markAsDirty();
    this.progressDataSource.data = this.outcome.progresses as OutcomeProgressEdit[];

    this.formGroup.updateValueAndValidity();
    this.progressIndexForEdit = null;
    this.progressFormGroup.reset();
  }

  getMonitoringProgressText(element) {
    if (!element?.measuringMethod) {
      return null;
    }
    let monitoringProgress = '';
    monitoringProgress += element?.measuringMethod;
    if (element?.measuringMethod === 'Other') {
      monitoringProgress += ': ' + element?.otherMethodDescribe;
    }
    return monitoringProgress;
  }
  // #endregion

  private setupFormSubscriptions(): void {
    this.titleSubscription.add(
      this.formGroup.get('title').valueChanges.subscribe((titleValue) => {
        if (titleValue && !this.outcome) {
          this.outcome = {} as OutcomeDto;
          this.isNew = true;
          this.startAutosaving();
        }
      })
    );

    this.progressInlineFormGroup.get('measuringMethod').valueChanges.subscribe(() => {
      this.progressOtherDescribeValidate();
    });

    this.formGroup
      .get('typeOfOutcome')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.cd.detectChanges();
        if (value === 'Child') {
          this.formGroup.controls.ecoAreas.markAsUntouched();
          this.formGroup.controls.ecoAreas.setValidators([Validators.required]);
          this.criterionFormGroup.get('measurementType').setValue('numeric');
          this.formGroup.controls.familyAreas.setValidators([]);
          this.formGroup.controls.familyAreas.setValue(null);
        } else if (value === 'Family') {
          this.formGroup.controls.familyAreas.markAsUntouched();
          this.formGroup.controls.familyAreas.setValidators([Validators.required]);
          this.criterionFormGroup.get('measurementType').setValue('nonnumeric');
          this.formGroup.controls.ecoAreas.setValidators([]);
          this.formGroup.controls.ecoAreas.setValue(null);
        } else {
          this.formGroup.controls.ecoAreas.setValidators([]);
          this.formGroup.controls.ecoAreas.setValue(null);
          this.formGroup.controls.familyAreas.setValidators([]);
          this.formGroup.controls.familyAreas.setValue(null);
          this.criterionFormGroup.get('measurementType').setValue('nonnumeric');
        }
        this.formGroup.controls.ecoAreas.updateValueAndValidity();
        this.formGroup.controls.familyAreas.updateValueAndValidity();
      });

    this.criterionFormGroup.get('measurementType').valueChanges.subscribe((result) => {
      if (result === 'numeric') {
        this.criterionFormGroup
          .get('quantifiableData')
          .get('performanceMin')
          .setValidators([Validators.required, Validators.min(0)]);
        this.criterionFormGroup
          .get('quantifiableData')
          .get('baseline')
          .setValidators([Validators.required, Validators.min(0)]);
      } else {
        this.criterionFormGroup.get('quantifiableData').get('performanceMin').clearValidators();
        this.criterionFormGroup.get('quantifiableData').get('baseline').clearValidators();
        if (this.quantData) {
          this.quantData.milestones = [];
          this.quantData.scaleRatings = [];
        }
      }

      this.criterionFormGroup.get('quantifiableData').reset();

      if (this.quantData?.quantDataForm) {
        this.quantData.quantDataForm.resetForm();
      }

      this.criterionFormGroup.get('quantifiableData').get('performanceMin').updateValueAndValidity();
    });
  }

  openTags() {
    const config: NewWindowConfig = {
      path: `tags/cases/${this.route.parent.snapshot.params?.caseId}/outcomes`,
      popup: true,
    };
    openNewWindow(config);
  }

  private createStrategiesFormGroup(value: string, id: string, isRequired = false): FormGroup {
    const control = new FormControl(value ? value : '');
    if (isRequired) control.setValidators(Validators.required);
    return this.fb.group({
      id: new FormControl(id ? id : null),
      name: control,
    });
  }

  saveExistingServicesSelection() {
    this.outcomeService
      .addExistingService(this.ifspId, this.outcome.id, this.modificationId, this.formGroup.get('currentServicesForOutcome').value)
      .subscribe((_) => {
        this.refreshServices();
        this.isAddingExistingOutcome = false;
      });
  }
}
