<div class="display-flex justify-content-between align-items-center mb-2">
  <h3 class="my-0">Meeting History</h3>
</div>

<div class="row">
  <div class="col-md-12">
    <mat-card class="pt-1">
      <div class="table-overflow app-table">
        <table mat-table [dataSource]="dataSource" class="w-100" matSort>
          <ng-container matColumnDef="purposeOfMeeting">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Purpose of Meeting</th>
            <td mat-cell *matCellDef="let element">
              <a href="javascript:void(0)" class="link" tabindex="0" attr.aria-label="{{ element.purposeOfMeeting }}">
                {{ element.purposeOfMeeting }}
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="meetingDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Meeting Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.meetingDate | dateFormat }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let element">
              {{ element.status }}
            </td>
          </ng-container>

          <ng-container matColumnDef="noResults">
            <td mat-footer-cell *matFooterCellDef colspan="7">
              <i>No results...</i>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            [hidden]="dataSource?.data?.length > 0"
            mat-footer-row
            *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
            class="example-second-footer-row"
          ></tr>
        </table>
        <mat-paginator [dataSource]="dataSource"></mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
