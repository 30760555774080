<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  Assign
  {{ this.evaluation.intakeType === 'PartC' ? 'Developmental Areas' : 'Domains' }}
  to Team Members
</h3>

<div mat-dialog-content class="py-3">
  <ng-container *ngIf="domainAssignmentUsers && domainAssignmentUsers.length > 0">
    <mat-card *ngFor="let domainAssignmentUser of domainAssignmentUsers" class="member__card py-3">
      <h3 class="text-semibold">
        Assign
        {{ this.evaluation.intakeType === 'PartC' ? 'Developmental Areas' : 'Domains' }}
        for {{ domainAssignmentUser.name }}:
      </h3>
      <ul class="member__list">
        <ng-container *ngFor="let domain of domainAssignmentUser.domainAreas">
          <li
            *ngIf="domain.canBeToggled"
            class="member__item"
            [ngClass]="{
              'member__item--selected': domain.isAssigned
            }"
            (click)="onToggleDomain(domainAssignmentUser.id, domain)"
          >
            {{ domain.name }}
          </li>
          <li
            *ngIf="!domain.canBeToggled"
            class="member__item"
            [ngClass]="{
              'member__item--selected-blocked': domain.isAssigned
            }"
          >
            {{ domain.name }}
          </li>
        </ng-container>
      </ul>
    </mat-card>
  </ng-container>
</div>

<div mat-dialog-actions class="actions">
  <div>
    <button mat-raised-button aria-label="Add Team Member" color="accent" (click)="onOpenTeamSearch()" tabindex="0">Add Team Member</button>
  </div>

  <div>
    <button mat-button aria-label="Cancel Button" class="background-color--muted" (click)="onCancel()" tabindex="0">Cancel</button>
    <button mat-raised-button aria-label="Complete" color="primary" (click)="onSave()" tabindex="0">Complete</button>
  </div>
</div>
