<div class="display-flex justify-content-end align-items-center mb-2">
  <button mat-button aria-label="Refresh Icon" color="primary" (click)="refresh()" matTooltip="Refresh" tabindex="0">
    <mat-icon aria-label="Refresh Icon"> refresh </mat-icon>
    <span class="text-md position-fix-notice">Refresh</span>
  </button>
</div>

<mat-card class="position-relative">
  <div *ngIf="showSpinner" class="spinning-over-chart">
    <div class="spinning-inner">
      <mat-spinner> </mat-spinner>
    </div>
  </div>

  <div class="app-chart-viewer">
    <div [chart]="chart"></div>
  </div>
</mat-card>
