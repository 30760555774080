import { IepGoal } from '../models/iep';
import { IepActivitySupportDto } from '../models/iep-activity-support';
import { IepServiceDto } from '../models/iep-service';

export interface IepESYConsiderationsDto {
  id?: string;
  iepId: string;
  iepIsFinalized: boolean;
  specialFactorsAddressed: SpecialFactorsType[];
  limitedEnglishConsiderations: string;
  behaviorConsiderations: string;
  accessibleMaterialsConsiderations: string;
  brailleConsiderations: string;
  communicationLanguageConsiderations: string;
  assistiveTechnologyConsiderations: string;
  healthConsiderations: string;
  hearingConsiderations: string;
  amendingESYPlan: boolean;
  amendmentId?: string;
  amendmentStartDate?: Date;
  amendmentEndDate?: Date;
  amendmentComments: string;
  amendmentReasonId: string;
  isActive: boolean;
  priorVersionId?: string;
  priorVersion?: IepESYConsiderationsDto;
}

export interface IepESYGoal {
  id?: string;
  iepId: string;
  iepGoalId: string;
  iepGoal?: IepGoal;
  iepIsFinalized: boolean;
  pseSummaryId: string;
  conditionIndividualWillPerform: string;
  goalArea?: string;
  domain?: string;
  iepESYServices?: IepServiceDto[];
  iepESYSupportActivities?: IepActivitySupportDto[];
  significantRegression: boolean;
  significantRegressionExplanation: string;
  rareCircumstances: boolean;
  rareCircumstancesExplanation: string;
  otherFactors: boolean;
  otherFactorsExplanation: string;
  monitoringFrequencyTime?: number;
  monitoringFrequencyPeriod?: string;
  minimumFrequencyToReportProgress?: boolean;
  frequencyToReportProgressTime?: number;
  frequencyToReportProgressPeriod?: string;
  sameRateAsPeersReported?: boolean;
  sameRateFrequency?: number;
  sameRatePeriod: string;
  differentRateFrequency?: number;
  differentRatePeriod: string;
  methodsToReport?: string;
  otherMethodToReport?: string;
  startDate?: Date;
  endDate?: Date;
  goalType: ESYGoalType;
  expectationType?: ExpectationType;
  expectation?: string;
  hasSDI?: boolean;
  includeGoal: boolean;

  amendingGoal: boolean;
  amendingGoalDetails: boolean;
  amendmentId?: string;
  amendmentStartDate?: Date;
  amendmentEndDate?: Date;
  amendmentComments: string;
  amendmentReasonId: string;
  isActive: boolean;
  priorVersionId?: string;
  priorVersion?: IepESYGoal;
}

export interface IepESYScheduleOfService {
  date: Date;
  time: string;
}

export interface PSEInESY {
  pseSummaryId: string;
  expectationType: ExpectationType;
  expectation: string;
}

export enum ExpectationType {
  Living = 'Living',
  Working = 'Working',
  Learning = 'Learning',
}

export enum ESYGoalType {
  PSE = 'PSE',
  Goal = 'Goal',
}

export enum SpecialFactorsType {
  LimitedEnglish = 'LimitedEnglish',
  Behavior = 'Behavior',
  AccessibleMaterials = 'AccessibleMaterials',
  Braille = 'Braille',
  CommunicationLanguage = 'CommunicationLanguage',
  AssistiveTechnology = 'AssistiveTechnology',
  Health = 'Health',
  Hearing = 'Hearing',
}
