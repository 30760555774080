import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-ifsp-details-modification-summary',
  templateUrl: './ifsp-details-modification-summary.component.html',
  styleUrls: ['./ifsp-details-modification-summary.component.scss'],
})
export class IfspDetailsModificationSummaryComponent implements OnInit {
  dataSource = new MatTableDataSource([
    {
      notice: 'Incomplete Modification to Service: Physical Therapy',
    },
    {
      notice: 'Team indicated Trial Placement, but no items are marked for trial placement',
    },
  ]);

  displayedColumns = ['notice'];

  constructor() {}

  ngOnInit(): void {}
}
