import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { CaseHistoryItem, FamilyMeetingCreate } from '../../../shared/models/case';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { PopupProxy } from '../../../shared/services/popup-proxy';

@Component({
  selector: 'app-activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.scss'],
})
export class ActivityTableComponent implements OnInit {
  @Input() learnerId: string;
  showActivity = false;
  caseItems: CaseHistoryItem[];
  dataSource = new MatTableDataSource<CaseHistoryItem>();
  formGroup: FormGroup;
  shortDateFormat = shortDateFormat;
  displayedColumns: string[] = ['dateTime', 'eventType'];
  typeOptions: KeyValuePair[] = [
    new KeyValuePair('All', 'All'),
    new KeyValuePair('Case', 'Case'),
    new KeyValuePair('Meeting', 'Meeting'),
    new KeyValuePair('Consent', 'Consent'),
    new KeyValuePair('IEP', 'IEP'),
    new KeyValuePair('IFSP', 'IFSP'),
    new KeyValuePair('Form', 'Forms'),
    new KeyValuePair('Evaluation', 'Evaluation'),
  ];
  constructor(private learnerService: LearnerService, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      type: ['All'],
      quickDate: new FormControl({
        dateShortcut: 'AllTime',
        startDate: dayjs().subtract(100, 'year').startOf('day').toDate(),
        endDate: dayjs().add(2, 'day').startOf('day').toDate(),
      }),
    });

    const filters = {
      type: this.formGroup.value.type,
      dateShortcut: this.formGroup.value.quickDate.dateShortcut,
      startDate: this.formGroup.value.quickDate.startDate,
      endDate: this.formGroup.value.quickDate.endDate,
    };

    this.getLearnerHistory(filters);

    this.formGroup.setValidators(endDateAfterStartDateValidator);
    this.formGroup.get('quickDate').valueChanges.subscribe((dates) => this.filterActivity(true, dates));
    this.formGroup.get('type').valueChanges.subscribe((type) => this.filterActivity(false, type));

    PopupProxy.familyMeetingClose.onmessage = (meeting: FamilyMeetingCreate) => {
      this.filterActivity(true, filters);
    };
  }

  filterActivity(isDate, filter) {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.valid) {
      const filters = {
        type: isDate ? this.formGroup.value.type : filter,
        dateShortcut: isDate ? filter.dateShortcut : this.formGroup.value.quickDate.dateShortcut,
        startDate: isDate ? filter.startDate : this.formGroup.value.quickDate.startDate,
        endDate: isDate ? filter.endDate : this.formGroup.value.quickDate.endDate,
      };

      this.getLearnerHistory(filters);
    }
  }

  getLearnerHistory(filters: any) {
    this.learnerService.getLearnerHistory(this.learnerId, filters).subscribe((res) => {
      this.caseItems = res;
      if (!this.showActivity) {
        this.dataSource.data = this.caseItems.slice(0, 3);
      } else {
        this.dataSource.data = this.caseItems;
      }
    });
  }

  toggleActivity() {
    if (this.showActivity) {
      this.showActivity = false;
      this.dataSource.data = this.caseItems.slice(0, 3);
    } else {
      this.showActivity = true;
      this.dataSource.data = this.caseItems;
    }
  }
}

const endDateAfterStartDateValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  const dateShortcut = formGroup.value.quickDate.dateShortcut;
  const startDate = dayjs(formGroup.value.quickDate.startDate);
  const endDate = dayjs(formGroup.value.quickDate.endDate);

  return dateShortcut === 'CustomDate' && startDate.isAfter(endDate) ? { endDateBeforeStartingDate: true } : null;
};
