import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LookupBase } from '../../models/lookup-base';

@Injectable({
  providedIn: 'root',
})
export class GenderService {
  baseUrl = 'api/gender';

  constructor(private readonly http: HttpClient) {}

  get(includeDeleted = false): Observable<LookupBase[]> {
    return this.http.get<LookupBase[]>(`${this.baseUrl}?includeDeleted=${includeDeleted}`);
  }

  add(gender): Observable<LookupBase> {
    return this.http.post<LookupBase>(this.baseUrl, gender);
  }

  edit(gender): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${gender.id}`, gender);
  }

  remove(id: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${id}/delete`, null);
  }
}
