import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpClient } from '@angular/common/http';
import { fromEvent, throwError } from 'rxjs';
import { retryWhen, mapTo, switchMap, tap } from 'rxjs/operators';
import { AppEvents, AutosavingStates } from '../app-events';
import { NotificationService } from '../services/notification.service';
import { MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));
  private snackBarRef: MatSnackBarRef<TextOnlySnackBar>;
  private didDisplayError = false;

  constructor(private http: HttpClient, private notificationService: NotificationService) {}

  displayError() {
    AppEvents.autosaving.emit(AutosavingStates.Failed);

    if (!this.snackBarRef && !this.didDisplayError) {
      this.didDisplayError = true;
      this.snackBarRef = this.notificationService.openSnackBar('Internet connectivity lost...', 'Ok', null, 99999);
    }
  }

  dismissError() {
    this.didDisplayError = false;

    if (this.snackBarRef) {
      this.snackBarRef.dismiss();
      this.snackBarRef = null;
    }
  }

  get isOnline() {
    return navigator.onLine;
  }

  intercept(req, next) {
    return next.handle(req).pipe(
      retryWhen((errors) => {
        if (this.isOnline) {
          return errors.pipe(switchMap((err) => throwError(err)));
        }

        this.displayError();
        return this.onlineChanges$.pipe(
          tap((online) => {
            if (online) {
              this.dismissError();
            }
          })
        );
      })
    );
  }
}
