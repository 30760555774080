import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProgressMonitorPhaseLine } from 'src/app/monitoring-progress/models/phase-line';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { openViewMore } from 'src/app/shared/tableHelpers';

@Component({
  selector: 'app-phase-line',
  templateUrl: './phase-line.component.html',
  styleUrls: ['./phase-line.component.scss'],
})
export class PhaseLineComponent implements OnInit {
  @Input() phaseLines: Array<ProgressMonitorPhaseLine> = [];
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<ProgressMonitorPhaseLine>;
  shortDateFormat = shortDateFormat;

  constructor(private datePipe: DatePipe, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.phaseLines);
    this.dataSource.sort = this.sort;
  }

  onViewMore(comment: ProgressMonitorPhaseLine) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Date',
        value: this.datePipe.transform(comment.date, shortDateFormat),
      },
      {
        name: 'Comment',
        value: comment.label,
      },
    ];

    if (comment.noteOther) {
      modalData.push({ name: 'Other', value: comment.noteOther });
    }

    openViewMore(this.dialog, modalData);
  }
}
