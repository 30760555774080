import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IepService } from 'src/app/iep/services/iep.service';
import { IfspSurvey } from 'src/app/ifsp/models/ifsp-survey';
import { GeneralSurveyGeneralSurveyQuestion } from 'src/app/shared/models/general-survey/general-survey-general-survey-question';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';

@Component({
  selector: 'app-iep-survey-results',
  templateUrl: './iep-survey-results.component.html',
  styleUrls: ['./iep-survey-results.component.scss'],
})
export class IepSurveyResultsComponent implements OnInit {
  private surveyDataSources = new Map<string, MatTableDataSource<GeneralSurveyGeneralSurveyQuestion>>();
  @Input() iepSurveysSent: number;
  @Input() iepId: string;
  surveys: IfspSurvey[];
  surveysByQuestion: IfspSurvey[];

  viewById = 'teamMember';
  displayedColumns = ['question', 'response'];
  dataSource = new MatTableDataSource([
    {
      question: 'Some Question',
      response: 'Some Response',
    },
  ]);
  viewByOptions: KeyValuePair[] = [
    new KeyValuePair('teamMember', 'Team Member', true),
    new KeyValuePair('questionnaireQuestion', 'Question'),
  ];

  constructor(private iepService: IepService) {}

  ngOnInit(): void {
    this.iepService.getSurveyResults(this.iepId).subscribe((data) => {
      this.surveys = data;
      this.surveysByQuestion = this.convertSurveysToByQuestion(this.surveys);
    });
  }

  convertSurveysToByQuestion(surveys: IfspSurvey[]): IfspSurvey[] {
    const newSurveys: IfspSurvey[] = [];
    const questions = new Map<string, number>();
    surveys.forEach((s) => {
      s.generalSurveyDto.generalSurveyGeneralSurveyQuestionDtos.forEach((gs) => {
        const q = gs.generalSurveyQuestionDto.question;
        if (!questions.has(q)) {
          questions[q] = gs.generalSurveyQuestionDto.order;
          const newSurvey: IfspSurvey = {
            userFullName: q,
            userId: null,
            generalSurveyDto: {
              id: '',
              surveyName: '',
              completedDateTime: '',
              generalSurveyGeneralSurveyQuestionDtos: [],
            },
          };
          surveys.forEach((s2) => {
            s2.generalSurveyDto.generalSurveyGeneralSurveyQuestionDtos.forEach((og) => {
              if (
                og.generalSurveyQuestionDto.question === q &&
                og.generalSurveyAnswerDto !== undefined &&
                og.generalSurveyAnswerDto?.answer.trim() !== ''
              ) {
                const newGs: GeneralSurveyGeneralSurveyQuestion = {
                  generalSurveyId: '',
                  generalSurveyAnswerDto: { ...og.generalSurveyAnswerDto },
                  generalSurveyQuestionDto: {
                    ...og.generalSurveyQuestionDto,
                  },
                };
                newGs.generalSurveyQuestionDto.question = s2.userFullName;
                newSurvey.generalSurveyDto.generalSurveyGeneralSurveyQuestionDtos.push(newGs);
              }
            });
          });
          newSurveys.push(newSurvey);
        }
      });
    });
    return newSurveys;
  }

  getSurveys(): IfspSurvey[] {
    return this.viewById === 'teamMember' ? this.surveys : this.surveysByQuestion;
  }

  public getOrCreateSurveyDataSource(
    surveyId: string,
    dtos: GeneralSurveyGeneralSurveyQuestion[]
  ): MatTableDataSource<GeneralSurveyGeneralSurveyQuestion> {
    if (this.surveyDataSources.has(surveyId)) {
      return this.surveyDataSources.get(surveyId);
    } else {
      const ds = this.convertSurveyDtos(dtos);
      this.surveyDataSources.set(surveyId, ds);
      return ds;
    }
  }

  convertSurveyDtos(dtos: GeneralSurveyGeneralSurveyQuestion[]) {
    return new MatTableDataSource(
      dtos.filter((x) => x.generalSurveyAnswerDto !== undefined && x.generalSurveyAnswerDto?.answer.trim() !== '')
    );
  }
}
