<div class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">
    <ng-container matColumnDef="notice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Notices</th>

      <td mat-cell *matCellDef="let element">
        {{ element.notice }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
