import { Component, Input, OnInit } from '@angular/core';
import { CaseSummary } from '../../../../../../shared/models/case';

@Component({
  selector: 'app-data-history-part-c-progress-monitoring',
  templateUrl: './data-history-part-c-progress-monitoring.component.html',
  styleUrls: ['./data-history-part-c-progress-monitoring.component.scss'],
})
export class DataHistoryPartCProgressMonitoringComponent implements OnInit {
  @Input() caseId = '';
  @Input() public case: CaseSummary;
  constructor() {}

  ngOnInit(): void {}
}
