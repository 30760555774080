import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Outcome, OutcomeDto, OutcomeReviewDto } from '../../ifsp/models/outcome-models';
@Injectable({
  providedIn: 'root',
})
export class OutcomeService {
  constructor(private readonly http: HttpClient) {}

  private outcomesUpdated = new Subject<void>();
  outcomesUpdated$ = this.outcomesUpdated.asObservable();

  public setModifyingOutcome = new Subject<any>();
  setModifyingOutcome$ = this.setModifyingOutcome.asObservable();

  public outcomeClose = new Subject<void>();
  outcomeClose$ = this.outcomeClose.asObservable();

  public outcomeAddNew = new Subject<void>();
  outcomeAddNew$ = this.outcomeAddNew.asObservable();

  public openOutcomesAccordion = new Subject<void>();
  openOutcomesAccordion$ = this.openOutcomesAccordion.asObservable();

  private baseUrl(ifspId = '') {
    return `api/ifsp/${ifspId}/outcomes`;
  }

  createOutcome(ifspId: string, model: Outcome) {
    return this.http.post<OutcomeDto>(this.baseUrl(ifspId), model).pipe(tap(() => this.outcomesUpdated.next()));
  }

  updateOutcome(ifspId: string, model: Outcome) {
    return this.http.put<void>(this.baseUrl(ifspId), model).pipe(tap(() => this.outcomesUpdated.next()));
  }

  deleteOutcome(ifspId: string, id: string, isAmendment = false) {
    return this.http.put(this.baseUrl(ifspId) + `/${id}/` + isAmendment, null).pipe(tap(() => this.outcomesUpdated.next()));
  }

  modify(ifspId: string, id: string, modificationId: string) {
    return this.http
      .put<any>(this.baseUrl(ifspId) + '/' + id + '/modify/' + modificationId, null)
      .pipe(tap(() => this.outcomesUpdated.next()));
  }

  addExistingService(ifspId: string, id: string, modificationId: string, services: string[]) {
    return this.http
      .put<any>(this.baseUrl(ifspId) + '/' + id + '/modify/add-existing-service/' + (modificationId || ''), services)
      .pipe(tap(() => this.outcomesUpdated.next()));
  }

  endOutcome(ifspId: string, id: string, model: any) {
    return this.http.put<any>(this.baseUrl(ifspId) + '/' + id + '/end', model).pipe(tap(() => this.outcomesUpdated.next()));
  }

  getAllForModification(ifspId: string, modificationId = '') {
    return this.http.get<OutcomeDto[]>(this.baseUrl(ifspId) + `/modify/${modificationId}`);
  }
  getAuditLogs(ifspId: string, id: string) {
    return this.http.get<any[]>(this.baseUrl(ifspId) + `/${id}/getAuditLogs`);
  }

  getOutcomes(ifspId: string) {
    return this.http.get<Array<OutcomeDto>>(this.baseUrl(ifspId));
  }

  getFinalizedOutcomes(ifspId: string) {
    return this.http.get<Array<OutcomeDto>>(this.baseUrl(ifspId) + '/getFinalized');
  }

  getOutcomesForReview(ifspId: string) {
    return this.http.get<Array<OutcomeReviewDto>>(this.baseUrl(ifspId) + '/getForReview');
  }

  getOutcomeById(ifspId: string, id: string) {
    return this.http.get<OutcomeDto>(this.baseUrl(ifspId) + '/' + id);
  }
}
