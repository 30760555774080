<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Sentence Starters</h3>

<div mat-dialog-content class="py-3">
  <mat-card>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <mat-radio-group aria-labelledby="Select an option" [(ngModel)]="response">
            <ng-container *ngFor="let response of data.responses">
              <mat-radio-button [value]="response" class="w-100 mb-4 display-block wrap" attr.aria-label="{{ response }}">
                {{ response }}
              </mat-radio-button>
            </ng-container>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </mat-card>
</div>

<div mat-dialog-actions class="display-flex justify-content-end align-items-center">
  <div class="display-flex">
    <button mat-raised-button aria-label="Cancel Button" class="background-color--muted mr-2" (click)="onClose()" tabindex="0">
      Cancel
    </button>

    <button mat-raised-button aria-label="Save Icon" color="primary" [mat-dialog-close]="response" tabindex="0">Save</button>
  </div>
</div>
