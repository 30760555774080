import { LookupBase } from './lookup-base';

export interface TransitionCode extends LookupBase {
  order?: number;
  code: string;
  codeCategory: TransitionCodeCategory;
  codeType: TransitionCodeType;
  description: string;
  transitionCodeDesignation: TransitionCodeDesignation;
}

export enum TransitionCodeCategory {
  ServiceEnd = 'ServiceEnd',
  Change = 'Change',
  Exit = 'Exit',
}

export enum TransitionCodeType {
  Service = 'Service',
  Goal = 'Goal',
  ActivitySupport = 'ActivitySupport',
  Accommodation = 'Accommodation',
}

export enum TransitionCodeDesignation {
  PartB = 'PartB',
  PartBExit = 'PartBExit',
  PartC = 'PartC',
  ChildFind = 'ChildFind',
}
