import { Observable, throwError } from 'rxjs';

import { AppEvents, AutosavingStates } from '../app-events';
import { catchError, tap } from 'rxjs/operators';

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const NotifySave = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  target: Object,
  propertyKey: string,
  descriptor: PropertyDescriptor
) => {
  const originalMethod = descriptor.value;

  // eslint-disable-next-line space-before-function-paren
  descriptor.value = function (...args) {
    AppEvents.autosaving.emit(AutosavingStates.Saving);
    try {
      const result = originalMethod.apply(this, args);
      if (result instanceof Promise) {
        return new Promise<any>((resolve, reject) => {
          result.then((x) => {
            AppEvents.autosaving.emit(AutosavingStates.Saved);
            resolve(x);
          });
          result.catch((err) => {
            AppEvents.autosaving.emit(AutosavingStates.Failed);
            reject(err);
          });
        });
      } else if (result instanceof Observable) {
        return result.pipe(
          tap(() => AppEvents.autosaving.emit(AutosavingStates.Saved)),
          catchError((err) => {
            AppEvents.autosaving.emit(AutosavingStates.Failed);
            return throwError(err);
          })
        );
      } else {
        AppEvents.autosaving.emit(AutosavingStates.Saved);
        return result;
      }
    } catch (e) {
      AppEvents.autosaving.emit(AutosavingStates.Failed);
      throw e;
    }
  };
};
