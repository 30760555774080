import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { QuantifiableDataRating } from 'src/app/shared/components/quantifiable-data/quantifiable-data';
import { BaseComponent } from '../../../../../shared/components/base-component/base-component';
import { CaseSummary } from '../../../../../shared/models/case';
import { DeactivationService } from '../../../../../shared/services/deactivation.service';

@Component({
  selector: 'app-iep-scale-levels',
  templateUrl: './iep-scale-levels.component.html',
  styleUrls: ['./iep-scale-levels.component.scss'],
})
export class IepScaleLevelsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() caseSummary: CaseSummary;
  @Input() scaleRatings: QuantifiableDataRating[];
  @Input() secondary: boolean;
  @Input() rubricMax;
  @Input() combinedGoal: boolean;
  @Input() amendmentId: any;
  @Input() amendmentFinalized: boolean;
  @Input() lastFinalizedDate: Date;
  @Output() updated = new EventEmitter<QuantifiableDataRating[]>();
  @ViewChild(FormGroupDirective)
  formRef: FormGroupDirective;

  constructor(private fb: FormBuilder, private dialog: MatDialog, deactivationService: DeactivationService) {
    super(deactivationService);
  }

  formGroup = this.fb.group({
    rating: [null, [Validators.required, Validators.min(0)]],
    description: [null, [Validators.required]],
    secondary: [null],
  });

  dataSource = new MatTableDataSource<QuantifiableDataRating>();
  displayedColumns = ['actions', 'rating', 'description'];
  ratingLevels: QuantifiableDataRating[] = [];
  model: QuantifiableDataRating;

  ngOnInit(): void {
    this.dataSource.data = this.scaleRatings?.filter((x) => x.secondary === this.secondary);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scaleRatings?.currentValue !== changes.scaleRatings?.previousValue) {
      this.dataSource.data = changes.scaleRatings.currentValue.sort((a, b) => (a.rating > b.rating ? 1 : -1));

      this.dataSource.data = this.dataSource.data.filter((x) => x.secondary === this.secondary);
    }
  }

  onUpdateModel() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      const modelIdx = this.scaleRatings.findIndex(
        (x) => x.description === this.model?.description && x.rating === this.model?.rating && x.secondary === this.model?.secondary
      );

      // Adding
      if (modelIdx < 0) {
        this.formGroup.controls.secondary.setValue(this.secondary);
        this.scaleRatings.push(this.formGroup.value);
      }

      // Updating
      else {
        this.scaleRatings[modelIdx].description = this.formGroup.value.description;
        this.scaleRatings[modelIdx].rating = this.formGroup.value.rating;
      }

      this.scaleRatings = [...this.scaleRatings]; // To show up on the table as a change
      this.resetForm();
      this.formRef.resetForm();
      this.updated.emit(this.scaleRatings);
    }
  }

  onEdit(is: QuantifiableDataRating) {
    this.model = is;
    this.initializeForm();
  }

  onDelete(is: QuantifiableDataRating) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        subQuestion: 'Clicking Yes will remove the rating.',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const modelIdx = this.scaleRatings.indexOf(is);
        this.scaleRatings = [...this.scaleRatings.slice(0, modelIdx), ...this.scaleRatings.slice(modelIdx + 1)];

        this.updated.emit(this.scaleRatings);
      }
    });
  }

  isRatingCompleted(rating: QuantifiableDataRating): boolean {
    return rating?.rating >= 0 && !!rating?.description;
  }

  private resetForm() {
    this.model = null;
    this.formGroup.controls.rating.setValue(null);
    this.formGroup.controls.description.setValue(null);
  }

  private initializeForm() {
    this.formGroup.reset(this.model);
  }
}
