import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { AppPermissions } from '../permissions';
import { CaseService } from '../shared/services/case/case.service';
import { popupChecker } from '../shared/windowHelpers';
import { IfspService } from './services/ifsp.service';

@Component({
  selector: 'app-ifsp',
  templateUrl: './ifsp.component.html',
  styleUrls: ['./ifsp.component.scss'],
})
export class IfspComponent implements OnInit {
  isPopup = false;

  constructor(
    private route: ActivatedRoute,
    private caseService: CaseService,
    private ifspService: IfspService,
    private router: Router,
    private authService: AuthService
  ) {
    this.route.params.subscribe(async (params) => {
      await this.caseService.refreshCaseSummary(params.caseId);
      if (!this.authService.isAllowedByCaseId(params.caseId, undefined, AppPermissions.ViewIFSP) || this.authService.IsReadOnly) {
        this.router.navigate(['/learner-management', this.caseService.caseSummary.learnerId, 'ifsp']);
      }
      await this.ifspService
        .get(params.ifspId)
        .toPromise()
        .catch((error: HttpErrorResponse) => {
          if (error.status === HttpStatusCode.NotFound) this.router.navigate(['/not-found']);
        });
    });
  }

  ngOnInit(): void {
    this.isPopup = popupChecker(this.route);
  }
}
