<div class="table-overflow">
  <div *ngIf="isPortalUser && (criterion.targetValue || criterion.baseline)" class="display-flex align-items-baseline">
    <p class="my-0 mr-2">Target Value: {{ criterion.targetValue }}</p>
    <p class="my-0 mr-2">Baseline: {{ criterion.baseline }}</p>
  </div>

  <table mat-table class="w-100" matSort [dataSource]="dataSource">
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more"></th>
      <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
        <button aria-label="View Icon" mat-icon-button color="primary" (click)="onViewMore(element)" tabindex="0">
          <mat-icon>remove_red_eye</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.date | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="value" *ngIf="criterion.measurementType === 'numeric'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>
      <td mat-cell *matCellDef="let element">
        {{ element.value | number }}
      </td>
    </ng-container>

    <ng-container matColumnDef="completed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">
        {{ element.completed ? 'Completed' : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Monitoring Notes</th>
      <td mat-cell *matCellDef="let element">
        {{ element.note | truncate : 150 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="criterion.measurementType === 'numeric' ? numericDisplayedColumns : nonNumericDisplayedColumns"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: criterion.measurementType === 'numeric' ? numericDisplayedColumns : nonNumericDisplayedColumns"
    ></tr>
    <tr [hidden]="dataSource.data.length > 0" mat-footer-row *matFooterRowDef="dataSource.data.length === 0 ? ['noResults'] : []"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
