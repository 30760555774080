import { Component, Input, OnInit } from '@angular/core';

type HelpDirection = 'left' | 'right';

@Component({
  selector: 'app-help-directional-text',
  templateUrl: './help-directional-text.component.html',
  styleUrls: ['./help-directional-text.component.scss'],
})
export class HelpDirectionalTextComponent implements OnInit {
  @Input() flat = false;
  @Input() outsideCard = false;
  @Input() direction: HelpDirection = 'left';

  get directionalClassName(): string {
    return `help-directional-text--${this.direction}`;
  }

  constructor() {}

  ngOnInit(): void {}
}
