import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { OutcomeCriteria } from '../../../models/outcome-models';
@Component({
  selector: 'app-outcome-criteria-view',
  templateUrl: './outcome-criteria-view.component.html',
  styleUrls: ['./outcome-criteria-view.component.scss'],
})
export class OutcomeCriteriaViewComponent implements OnInit {
  shortDateFormat = shortDateFormat;

  get scaleRatings() {
    return this.data.criterion.quantifiableData.scaleRatings.filter((x) => !x.secondary).sort((a, b) => a.rating - b.rating);
  }

  get milestones() {
    return this.data.criterion.quantifiableData.milestones
      .filter((x) => !x.secondary)
      .sort((a, b) => (a.completionDate < b.completionDate ? -1 : 1));
  }

  get modificationId() {
    return this.data.modificationId;
  }

  constructor(
    private dialogRef: MatDialogRef<OutcomeCriteriaViewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      criterion: OutcomeCriteria;
      modificationId: string;
    }
  ) {}

  ngOnInit(): void {}

  getMeasurmentTypeText(value) {
    let returnVar = null;
    if (value === 'nonnumeric') {
      returnVar = 'Yes / No';
    }
    if (value === 'numeric') {
      returnVar = 'Numeric';
    }

    return returnVar;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
