<ul class="list">
  <li *ngIf="!showField.FactorsSection" class="list__item display-flex justify-content-between align-items-center flex-1 flex-wrap">
    <p class="my-0">No ESY Considerations Made.</p>
  </li>
  <li
    *ngIf="showField.AccessibleMaterials && showField.FactorsSection"
    class="list__item display-flex justify-content-between align-items-center flex-1 flex-wrap"
  >
    <p class="my-0">This student has a special factor of s Accessible Education Materials (AEM).</p>
    <p class="my-0" *ngIf="factors.accessibleMaterialsConsiderations">
      ESY Considerations: {{ factors.accessibleMaterialsConsiderations }}
    </p>
  </li>
  <li
    *ngIf="showField.AssistiveTechnology && showField.FactorsSection"
    class="list__item display-flex justify-content-between align-items-center flex-1 flex-wrap"
  >
    <p class="my-0 mr-3">This student has a special factor of Assistive Technology.</p>
    <p class="my-0" *ngIf="factors.assistiveTechnologyConsiderations">
      ESY Considerations: {{ factors.assistiveTechnologyConsiderations }}
    </p>
  </li>
  <li
    *ngIf="showField.Braille && showField.FactorsSection"
    class="list__item display-flex justify-content-between align-items-center flex-1 flex-wrap"
  >
    <p class="my-0">This student has a special factor of Braille Assistance.</p>
    <p class="my-0" *ngIf="factors.brailleConsiderations">ESY Considerations: {{ factors.brailleConsiderations }}</p>
  </li>
  <li
    *ngIf="showField.CommunicationLanguage && showField.FactorsSection"
    class="list__item display-flex justify-content-between align-items-center flex-1 flex-wrap"
  >
    <p class="my-0 mr-3">This student has a special factor of Communication.</p>
    <p class="my-0" *ngIf="factors.communicationLanguageConsiderations">
      ESY Considerations: {{ factors.communicationLanguageConsiderations }}
    </p>
  </li>
  <li
    *ngIf="showField.LimitedEnglish && showField.FactorsSection"
    class="list__item display-flex justify-content-between align-items-center flex-1 flex-wrap"
  >
    <p class="my-0">This student has a special factor of English Learner.</p>
    <p class="my-0" *ngIf="factors.limitedEnglishConsiderations">ESY Considerations: {{ factors.limitedEnglishConsiderations }}</p>
  </li>
  <li
    *ngIf="showField.Health && showField.FactorsSection"
    class="list__item display-flex justify-content-between align-items-center flex-1 flex-wrap"
  >
    <p class="my-0 mr-3">This student has a special factor of Health.</p>
    <p class="my-0" *ngIf="factors.healthConsiderations">ESY Considerations: {{ factors.healthConsiderations }}</p>
  </li>
  <li
    *ngIf="showField.Hearing && showField.FactorsSection"
    class="list__item display-flex justify-content-between align-items-center flex-1 flex-wrap"
  >
    <p class="my-0">This student has a special factor of Hearing.</p>
    <p class="my-0" *ngIf="factors.hearingConsiderations">ESY Considerations: {{ factors.hearingConsiderations }}</p>
  </li>
  <li
    *ngIf="showField.Behavior && showField.FactorsSection"
    class="list__item display-flex justify-content-between align-items-center flex-1 flex-wrap"
  >
    <p class="my-0 mr-3">This student has a special factor of Social Emotional Behavior.</p>
    <p class="my-0" *ngIf="factors.behaviorConsiderations">ESY Considerations: {{ factors.behaviorConsiderations }}</p>
  </li>
</ul>
